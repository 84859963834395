import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as analytics from "../../utils/analytics";


function AboutUs(props) {

  analytics.track();

  return (
    <React.Fragment>
      <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
        About Fundfina
      </h2>

      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            What is Fundfina?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              The company partners with financial institutions to serve more than 150,000 MSMEs
              across India, who otherwise have difficulty accessing credit due to a lack of credit
              history, a need for small dollar loans deemed unprofitable by larger institutions, and
              a slow and complex lending process. Fundfina supports these merchants by curating
              appropriate financial products on its platform, enabling thin-file credit assessments
              through proprietary indices, and offering cash flow management tools.

            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            How does marketplace or peer-to-peer lending marketplace work
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Card.Img
                src={require("../../images/aboutussnap.png")}
                style={{ maxWidth: 700 }}
              ></Card.Img>
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            What is Fundfina business model?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              We believe in democratising finance. Our core mission is to build
              a financial market place that is initially focussed on making
              credit more accessible and affordable; and, investing more
              rewarding. We want to keep that platform open to offer best in
              breed products, while focusing on customer experiences and being
              efficient. Apart from customers directly applying for a loan on
              the platform, we are a trusted lending partners for a number of
              enterprises. We charge a fee for risk intermediation and exchange.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {/* <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            What is the role of Fundfina?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              Fundfina's principal role is to perform introductory functions on
              behalf of borrowers and lenders in order to bring together
              prospective borrowers and lenders, to provide a stream-lined
              process for entering into loans (including the development of
              standard form loan agreements) and to facilitate the payment and
              collection of sums due under or in connection with those loans
              (including taking certain actions on behalf of lenders upon a
              borrower's default or if the borrower becomes, or is likely to
              become, insolvent as set out in these terms and conditions). We
              are party to any Loan Contracts as the facilitator between
              borrowers and lenders as well as an administrative role in
              facilitating payments, collection of sums due and onward
              distribution of funds.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            Is Fundfina regulated?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              Yes.  On 4th October, 2017, Reserve Bank of India brought us under
              the purview of NBFC regulations and established and new category
              named NBFC P2P. We have been provided an interim license by RBI to
              operate as a peer to peer lending platform.{" "}
            </Card.Body>
          </Accordion.Collapse>
        </Card> */}
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Can’t find what I am looking for or I want to complaint?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>Please <Link to="/contactus">
              Contact Us
            </Link></Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </React.Fragment>
  );
}

export default AboutUs;
