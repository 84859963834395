import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, FormCheck } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import CollectionsQueueLoanWiseModel from '../../models/collectionsQueue/collectionsQueueLoanWiseModel';
import ReactExport from "react-export-excel";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import EmiList from "../common/emiList";
import moment from "moment";
import { getMobileNumberArray } from "../../utils/common-utils";

class CollectionsQueueLoanWise extends CollectionsQueueLoanWiseModel {
    getPagedData = () => {
        const { pageSize, currentPage, data, allLoans } = this.state;

        let emiList = data.emiList;

        // const filteredLoans = paginate(emiList, currentPage, pageSize);

        return { totalCount: emiList.length, data: emiList, allLoans };
    };

    exportExcel = () => {
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        const { allLoans } = this.getPagedData();

        return (<ExcelFile
            // element={
            //     <Button
            //         className="ff-button"
            //         type="button"
            //         style={{ marginTop: 10 }}
            //     >
            //         Export to Excel
            //     </Button>
            // }
            hideElement={true}
            filename={`CollectionsQueue_${moment(new Date()).format("DDMMYYYY")}`}
        >
            <ExcelSheet data={allLoans} name="collectionQueue">
                <ExcelColumn
                    label="Loan No."
                    value="loanNo"
                ></ExcelColumn>
                <ExcelColumn
                    label="Name"
                    value="name"
                ></ExcelColumn>

                <ExcelColumn
                    label="Mobile Numbers"
                    value={(col) => {
                        if(col && col.mobileNumbers && col.mobileNumbers.length){
                            let numbers = col.mobileNumbers.map(item => item.mobileNumber);
                            return numbers.join();
                        }
                    }}
                ></ExcelColumn>

                <ExcelColumn
                    label="Partner"
                    value="partnerName"
                ></ExcelColumn>
                <ExcelColumn
                    label="Merchant Code"
                    value="merchantCode"
                ></ExcelColumn>
                <ExcelColumn
                    label="Status"
                    value="status"
                ></ExcelColumn>
                <ExcelColumn
                    label="Disbursal Date"
                    value="disbursalDate"
                ></ExcelColumn>
                <ExcelColumn
                    label="Last Payment Date"
                    value="lastPaymentReceivedDate"
                ></ExcelColumn>
                <ExcelColumn
                    label="Loan Amount"
                    value="amount"
                ></ExcelColumn>
                <ExcelColumn
                    label="Principal Outstanding"
                    value="totalPrincipalOutstanding"
                ></ExcelColumn>
                <ExcelColumn
                    label="Principal Pending"
                    value="principalOutstandingTillDate"
                ></ExcelColumn>
                <ExcelColumn
                    label="Interest Outstanding"
                    value="totalInterestOutstanding"
                ></ExcelColumn>
                <ExcelColumn
                    label="Interest Pending"
                    value="interestOutstandingTillDate"
                ></ExcelColumn>
                <ExcelColumn
                    label="Others Outstanding"
                    value="otherCharges"
                ></ExcelColumn>
                <ExcelColumn
                    label="Total Outstanding"
                    value="totalOutstandingAmount"
                ></ExcelColumn>
                <ExcelColumn
                    label="Total Pending"
                    value="totalOutstandingAmountTillDate"
                ></ExcelColumn>
                <ExcelColumn
                    label="Reason for failure"
                    value="failure_reason"
                ></ExcelColumn>
                <ExcelColumn
                    label="Bucket"
                    value="bucket"
                ></ExcelColumn>
                <ExcelColumn
                    label="Lending Partner"
                    value="lendingPartner"
                ></ExcelColumn>
                <ExcelColumn
                    label="Disbursal Month"
                    value="disbursalShortDate"
                ></ExcelColumn>
                <ExcelColumn
                    label="No of EMIs pending"
                    value="numberOfEmisPending"
                ></ExcelColumn>
                <ExcelColumn
                    label="City"
                    value="city"
                ></ExcelColumn>
                <ExcelColumn
                    label="State"
                    value="state"
                ></ExcelColumn>
                <ExcelColumn
                    label="Pincode"
                    value="pincode"
                ></ExcelColumn>

                    <ExcelColumn
                        label="Home Address"
                        value="homeAddress"
                    ></ExcelColumn>

                    <ExcelColumn
                        label="Shop Address"
                        value="shopAddress"
                    ></ExcelColumn>

                <ExcelColumn
                    label="TrueScore At Disbursal"
                    value={(emi) => {
                        return emi.trueScoreDisbursal ? emi.trueScoreDisbursal : "-"
                    }}
                ></ExcelColumn>
                <ExcelColumn
                    label="Last Transaction Period"
                    value={(emi) => {
                        return emi.lastTransactionPeriod ? emi.lastTransactionPeriod : "-"
                    }}
                ></ExcelColumn>
                <ExcelColumn
                    label="Last Transaction Count"
                    value={(emi) => {
                        return emi.lastTransactionCount ? emi.lastTransactionCount : "-"
                    }}
                ></ExcelColumn>
                <ExcelColumn
                    label="Last Transaction Value"
                    value={(emi) => {
                        return emi.lastTransactionVolume ? emi.lastTransactionVolume : "-"
                    }}
                ></ExcelColumn>

                <ExcelColumn
                    label="Last Active Days"
                    value={(emi) => {
                        return emi.lastActiveDays ? emi.lastActiveDays : "-"
                    }}
                ></ExcelColumn>
                <ExcelColumn
                    label="Earliest Pending Emi"
                    value={(emi) => {
                        return emi.earliestPendingEmi ? moment(emi.earliestPendingEmi).format("DD/MM/yyyy") : "-"
                    }}
                ></ExcelColumn>

                <ExcelColumn
                    label="DPD"
                    value={(col) => {
                        if (col.lastPaymentReceivedDate) {

                            let daysSplit = col.lastPaymentReceivedDate.split('/')
                            return this.getNumberOfDaysDifference(daysSplit[1] + '/' + daysSplit[0] + '/' + daysSplit[2])
                        }
                    }}
                ></ExcelColumn>
            </ExcelSheet>
        </ExcelFile>)
    }

    getNumberOfDaysDifference = (date) => {
        let date1 = new Date(date);
        let date2 = new Date(new Date().toDateString());
        let differenceInTime = date2.getTime() - date1.getTime();
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);

        return '' + differenceInDays
    }
    render() {
        const {
            pageSize,
            currentPage,
            data,
            showSpinner,
            partnerList,
            showRepaymentModal,
            loanNo,
            showErrorModal,
            errorMessage,
            errorHeader,
            loanId,
            lendingPartnerList
        } = this.state;

        const { totalCount, data: emiList, allLoans } = this.getPagedData();

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>Collection's Cases Loan View</h2>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="partnerList">
                            <Form.Label>Partner</Form.Label>
                            <Form.Control
                                as="select"
                                name="partnerId"
                                value={data["partnerId"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="partner-select" value="">
                                    ---Select---
                                </option>
                                {partnerList.map((item, i) => (
                                    <option key={i} value={item._id}>
                                        {item.displayName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="loanNo">
                            <Form.Label>Loan No.</Form.Label>
                            <Form.Control
                                type="text"
                                name="loanNo"
                                value={data["loanNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}

                        <Form.Group as={Col} md="4" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={data["name"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="mobileNo">
                            <Form.Label>Mobile No</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobileNo"
                                value={data["mobileNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="overdueBy">
                            <Form.Label>Overdue By</Form.Label>
                            <Form.Control
                                as="select"
                                name="overdueBy"
                                value={data["overdueBy"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="all" value="">
                                    All
                                </option>
                                <option key="a" value="a">
                                    1 - 7 days
                                </option>
                                <option key="b" value="b">
                                    8 - 15 days
                                </option>
                                <option key="c" value="c">
                                    16 - 30 days
                                </option>
                                <option key="d" value="d">
                                    31 - 60 days
                                </option>
                                <option key="e" value="e">
                                    61 - 90 days
                                </option>
                                <option key="f" value="f">
                                    91 - 120 days
                                </option>
                                <option key="g" value="g">
                                    More than 120 days
                                </option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="lendingPartnerList">
                            <Form.Label>Lending Partner</Form.Label>
                            <Form.Control
                                as="select"
                                name="lendingPartnerId"
                                value={data["lendingPartnerId"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="partner-select" value="">
                                    ---Select---
                                </option>
                                {lendingPartnerList && lendingPartnerList.map((item, i) => (
                                    <option key={i} value={item._id}>
                                        {item.displayName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>

                        <Form.Group as={Col} md="4">
                            <Button
                                className="ff-button"
                                type="submit"
                                style={{ marginTop: 30 }}
                            >
                                Apply Filters
                            </Button>

                            {data && data.overdueBy ? <Button
                                className="ff-button"
                                type="button"
                                style={{ marginTop: 30, marginLeft: 10 }}
                                onClick={() => !this.state.showSpinner && this.downloadExcel()}
                            >
                                Export to Excel
                            </Button> : <></>}
                            {this.state.downloadAll && this.exportExcel()}
                        </Form.Group>

                    </Form.Row>
                </Form>
                {/* <div className="row">
                    <div className="col-md-10">
                        <ExcelFile
                            element={
                                <Button
                                    className="ff-button"
                                    type="button"
                                    style={{ marginTop: 10 }}
                                >
                                    Export to Excel
                                </Button>
                            }
                            filename={`CollectionsQueue_${moment(new Date()).format("DDMMYYYY")}`}
                        >
                            <ExcelSheet data={allLoans} name="collectionQueue">
                                <ExcelColumn
                                    label="Loan No."
                                    value="loanNo"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Name"
                                    value="name"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Partner"
                                    value="partnerName"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Merchant Code"
                                    value="merchantCode"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Status"
                                    value="status"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Disbursal Date"
                                    value="disbursalDate"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Last Payment Date"
                                    value="lastPaymentReceivedDate"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Loan Amount"
                                    value="amount"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Principal Outstanding"
                                    value="totalPrincipalOutstanding"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Principal Pending"
                                    value="principalOutstandingTillDate"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Interest Outstanding"
                                    value="totalInterestOutstanding"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Interest Pending"
                                    value="interestOutstandingTillDate"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Others Outstanding"
                                    value="otherCharges"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Total Outstanding"
                                    value="totalOutstandingAmount"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Total Pending"
                                    value="totalOutstandingAmountTillDate"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Reason for failure"
                                    value="failure_reason"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Bucket"
                                    value="bucket"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Lending Partner"
                                    value="lendingPartner"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Disbursal Month"
                                    value="disbursalShortDate"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="No of EMIs pending"
                                    value="numberOfEmisPending"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="City"
                                    value="city"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="State"
                                    value="state"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Pincode"
                                    value="pincode"
                                ></ExcelColumn>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div> */}
                <br />
                <div>
                    <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0} rightStickyColumnCount={2} leftStickyColumnCount={1} >
                            <Row>
                                <Cell style={{ borderColor: "lightgrey !important", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan #</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Name</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Mobile Numbers</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Partner</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Merchant Code</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Disbursement Date</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Last Payment Date</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Principal Outstanding</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Principal Pending</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Interest Outstanding</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Interest Pending</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Others Outstanding</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Outstanding</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Pending</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reason For Failure</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Bucket</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Lending Partner</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Disbursal Month</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>No of EMIs pending</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>City</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>State</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Pincode</b></Cell>

                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>TrueScore At Disbursal</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Last Transaction Period</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Last Transaction Count</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Last Transaction Value </b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Last Active Days</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Earliest Pending Emi</b></Cell>


                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Collection Amount (EMI)</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Auto Collections</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 250 }}><b>eNach</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Pre-Legal Notice</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 100 }}><b>Mark</b></Cell>
                            </Row>
                            {emiList &&
                                emiList.map((emi, i) => (
                                    <Row key={i}>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid" }}>             <a
                                            key={emi.loanId}
                                            href="#"
                                            onClick={() => this.getRepaymentDetails(emi.loanId)}
                                        >
                                            {emi.loanNo}
                                        </a></Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.name}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.mobileNumbers && emi.mobileNumbers.length ? getMobileNumberArray(emi.mobileNumbers).join() : ""}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.partnerName}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.merchantCode}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.status}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.disbursalDate}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.lastPaymentReceivedDate}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.totalPrincipalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.principalOutstandingTillDate} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.totalInterestOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.interestOutstandingTillDate} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.otherCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.totalOutstandingAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.totalOutstandingAmountTillDate} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {emi.failure_reason}
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.bucket}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.lendingPartner}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.disbursalShortDate}</Cell>

                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.numberOfEmisPending}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.city}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.state}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.pincode}</Cell>

                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.trueScoreDisbursal || "-"}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.lastTransactionPeriod || "-"}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.lastTransactionCount || "-"}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.lastTransactionVolume || "-"}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.lastActiveDays || "-"}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.earliestPendingEmi ? moment(emi.earliestPendingEmi).format("DD/MM/yyyy") : "-"}</Cell>

                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {(emi.primaryCollectionType === "eNach" || emi.secondaryCollectionType === "eNach") && emi.isMandateRegistered && (
                                                <Form.Control
                                                    as="select"
                                                    value={emi.emiCount}
                                                    onChange={this.handleEmiNumberChange}
                                                    name={"emis_" + emi.loanId}
                                                    style={{ maxWidth: 150, marginRight: 20 }}
                                                >
                                                    {" "}
                                                    <option key="emis-select" value="">
                                                        ---Select---
                                                    </option>
                                                    {emi.emiList.map((item, k) => (
                                                        <option key={k} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </Form.Control>)}
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <FormCheck
                                                id={"holdCollection_" + emi.loanId}
                                                type="switch"
                                                checked={emi.holdWalletCollection}
                                                onChange={this.handleHoldCollection}
                                                label="Hold"
                                                name={"holdCollection_" + emi.loanId}
                                                disabled={emi.primaryCollectionType === "eNach" ? true : false}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {(emi.primaryCollectionType === "eNach" || emi.secondaryCollectionType === "eNach") && emi.isMandateRegistered && (
                                                <React.Fragment>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <Form.Control
                                                                as="select"
                                                                value={emi.eNachStatus}
                                                                onChange={this.handleEmiStatusChange}
                                                                name={"eNachSelect_" + emi.loanId}
                                                                style={{ maxWidth: 150, marginRight: 20 }}
                                                            >
                                                                {" "}
                                                                <option key="loanView-select" value="">
                                                                    ---Select---
                                                                </option>
                                                                <option key="loanView-resend" value="Send" disabled={emi.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                                                                    Resend
                                                                </option>
                                                                <option key="loanView-mandate" value="Mandate">
                                                                    New Mandate
                                                                </option>
                                                                <option key="loanView-bankDetails" value="BankDetails">
                                                                    Bank Details
                                                                </option>
                                                                {/* <option key="loanView-hold" value="Hold" disabled={emi.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                                                                    Hold
                                                                </option> */}
                                                            </Form.Control>
                                                            <Button
                                                                className="ff-button"
                                                                type="button"
                                                                name={"btnNachSelect_" + emi.loanId}
                                                                onClick={this.eNachAction}
                                                                style={{ minWidth: 50 }}
                                                                disabled={emi.nachBtnDisabled}
                                                            >
                                                                Go
                                                            </Button></div></div></React.Fragment>)}
                                        </Cell>
                                        <Cell style={{ borderRight: "1px solid !important", textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey" }}>
                                            <Button
                                                className="ff-button"
                                                type="button"
                                                name={"btnPreLegalNotice_" + emi.loanId}
                                                onClick={this.handleSendPreLegalNotice}
                                                disabled={emi.preLegalNoticeBtnDisabled}
                                            >
                                                Send
                                            </Button>
                                        </Cell>
                                        <Cell style={{ borderRight: "1px solid !important", textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey" }}>
                                            <Button
                                                className="ff-button"
                                                type="button"
                                                name={"btnPaid_" + emi.loanId}
                                                onClick={this.handleMarkAsPaid}
                                            >
                                                Paid
                                            </Button>
                                        </Cell>
                                    </Row>))}
                        </StickyTable>
                    </div>
                </div>
                <div style={{ width: "100%", overflowX: "scroll" }}>
                    {/* <Pagination
                        itemsCount={totalCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                        onPagePrevious={this.handlePagePrevious}
                        onPageNext={this.handlePageNext}
                    /> */}
                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPagePrevious={this.onPagePrevious}
                        onPageNext={this.onPageNext}
                    />
                </div>
                <Modal
                    show={showRepaymentModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    aria-labelledby="example-modal-sizes-title-xl"
                    centered
                    style={{ width: "auto !important" }}
                >

                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 400, overflow: "scroll" }}>
                        <EmiList loanId={loanId}></EmiList>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showErrorModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <b>{errorHeader}</b>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} className="ff-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        );
    }
}

export default CollectionsQueueLoanWise;
