import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import MisLogListModal from "../../models/reconciliation/misLogList";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Col, Button, Tabs, Tab } from "react-bootstrap";
import DatePicker from "react-datepicker2";

class MisLogList extends MisLogListModal {

  renderTabs = () => {
    let { headerTabs, headerActiveTab } = this.state;

    let elements = []

    for (let i = 0; i < headerTabs.length; i++) {
      elements.push(
        <Tab eventKey={headerTabs[i].value} title={headerTabs[i].title}></Tab>
      )
    }

    return (<Tabs activeKey={headerActiveTab} id="uncontrolled-tab-example" onSelect={this.onChangeHeaderActiveTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      successMsg,
      errors,
      partners,
      channels,
      statusDropdown
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />

        <div className="mt-3">

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>

              <Form.Group as={Col} md="3" controlId="fromDate">
                <Form.Label>From Date <small className="text-success">(Max Date Range 60 days)</small></Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={data["partner"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="all" value="">
                    All
                  </option>
                  {statusDropdown && statusDropdown.map((item) => (
                    <option key={item.title} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>


              <Form.Group as={Col} md="3">
                <Form.Label> </Form.Label>
                <br />
                <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Apply Filters
                </Button>
              </Form.Group>

            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}

          {this.renderTabs()}
          {data && data.channel == "EMAIL" && <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", borderLeft: "1px solid", textAlign: "center" }}><b>From</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Email Received On</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Subject</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>File Upload Status</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Staging Status</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Processed On</b></Cell>
              </Row>
              {data.misList && data.misList &&
                data.misList.map((mis, i) => (
                  <Row key={mis._id}>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info.emailFrom || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info && mis.info.emailReceivedOn && moment(mis.info.emailReceivedOn).format("DD/MM/YYYY HH:MM:SS") || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info.subject || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info && mis.info.uploadStatus || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.stagingStatus || ""}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis.insertedOn && moment(mis.insertedOn).format("DD/MM/YYYY HH:MM:SS")}</Cell>
                  </Row>
                ))}
              {(!data.misList || !data.misList.length) && (
                <td colSpan={7} className='text-center pt-2 pb-2 border'>No Records Found</td>
              )}

            </StickyTable>
          </div>}

          {data && data.channel == "ENACH" && <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>UTR No</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Staging Status</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Beneficiary Name</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Beneficiary IFSC</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Beneficiary AccountNo</b></Cell>
              </Row>
              {data.misList && data.misList &&
                data.misList.map((mis, i) => (
                  <Row key={mis._id}>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info.utrNo || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info.totalAmount || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.stagingStatus || ""}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info && mis.info.beneficiaryName || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info && mis.info.beneficiaryIfsc || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis && mis.stagingStatus == "FAILED" || mis && mis.stagingStatus == "INVALID_FILE" ? "error-row" : ""}`}>{mis && mis.info && mis.info.beneficiaryAccountNo || "-"}</Cell>
                  </Row>
                ))}
              {(!data.misList || !data.misList.length) && (
                <td colSpan={7} className='text-center pt-2 pb-2 border'>No Records Found</td>
              )}

            </StickyTable>
          </div>}
          <div style={{ width: "100%", overflowX: "scroll" }}>
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.onPageChange}
              onPagePrevious={this.onPagePrevious}
              onPageNext={this.onPageNext}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MisLogList;
