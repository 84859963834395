import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";
import { mode } from "crypto-js";

const apiEndpoint = apiUrl + "/ops/admin";

export async function scrutinyList(model) {
  const { data } = await http.post(apiEndpoint + "/getKycList", {
    kycStatus: model.kycStatus,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    panNo: model.panNo,
    mobileNo: model.mobileNo,
    poaNo: model.poaNo,
    name: model.name,
    merchantCode: model.merchantCode,
    partner: model.partner
  });
  return data;
}

export async function partnerScrutinyList(model) {
  const { data } = await http.post(apiEndpoint + "/getPartnerKycList", {
    kycStatus: model.kycStatus,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    panNo: model.panNo,
    mobileNo: model.mobileNo,
    poaNo: model.poaNo,
    name: model.name,
    merchantCode: model.merchantCode,
    partner: model.partner
  });
  return data;
}

export async function updateKycStatus(model, partnerId = null, merchantCode = null) {
  console.log("model", model);
  const response = await http.put(
    `${apiEndpoint}/updateKycStatus/${model.userId}`,
    {
      kycStatus: model.adminStatus,
      remarks: model.remarks,
      poaDocType: model.poaDocType,
      reviewDoc: model.reviewDoc,
      partnerId,
      merchantCode,
      detailsMismatch: model.detailsMismatch,
      poiName: model.poiName ? model.poiName.trim() : "",
      poaName: model.poaName ? model.poaName.trim() : "",
      poiDob: model.poiDob ? moment(model.poiDob).format("DD/MM/yyyy") : null,
      poaDob: model.poaDob ? moment(model.poaDob).format("DD/MM/yyyy") : null
    }
  );
  return response;
}

export async function getScrutinyInfo(userId, isPartnerUser = false, partnerId = null, merchantCode = null) {
  const response = await http.post(apiEndpoint + "/getScrutinyInfo", {
    userId,
    isPartnerUser,
    partnerId,
    merchantCode
  });

  return response;
}

export default {
  scrutinyList,
};
