import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ManualQueueSettlementModel from "../../models/manualQueueInvoiceModel/manualQueueInvoiceModel";
import SpinnerComponent from "../../components/common/spinner";
import { Tabs, Tab, Modal, Form, Col, Button } from "react-bootstrap";
import moment from "moment";


class ManualQueueInvoice extends ManualQueueSettlementModel {

  renderTabs = () => {
    let { tabs, activeTab } = this.state;
    let elements = []
    for (let i = 0; i < tabs.length; i++) {
      elements.push(
        <Tab eventKey={tabs[i].value} title={tabs[i].title}></Tab>
      )
    }
    return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  render() {
    const {
      showSpinner,
      data,
      partners,
      invoiceList,
      errors
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={true}
          onHide={this.props.close}
          aria-labelledby="example-custom-modal-styling-title"
          dialogClassName="settlement-model"
        >
          <Modal.Header closeButton>
            <h5>Settlement</h5>
          </Modal.Header>

          <Modal.Body>
            <SpinnerComponent showSpinner={showSpinner} />
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="partner">
                <Form.Label>Amount</Form.Label>
                <div>₹{data.amount}</div>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="partner">
                <Form.Label>UTR No</Form.Label>
                <div>{data.utrNo}</div>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" controlId="partnerId">
                <Form.Label>Partner<span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="partnerId"
                  value={data["partnerId"]}
                  onChange={this.handleChangePartners}
                >
                  {" "}
                  <option key="all" value="all">
                    ALL
                  </option>
                  {partners && partners.map((partner) => (
                    <option key={partner.partnerName} value={partner._id}>
                      {partner.displayName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {data["partnerId"] ? <Form.Group as={Col} md="6" controlId="invoiceNo">
                <Form.Label>Invoice<span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="invoiceNo"
                  value={data["invoiceNo"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="select" value="">
                    -- Select --
                  </option>
                  {invoiceList && invoiceList.map((invoice) => (
                    <option key={invoice.invoiceNo} value={invoice.invoiceNo}>
                      {invoice.invoiceNo + (invoice.invoiceDate ? (' - ' + moment(invoice.invoiceDate).format("DD/MM/YYYY")) : "")}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group> : <></>}
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" className="text-right">

                {this.state.settlementTransaction && this.state.settlementTransaction.isSettlementReceived ? <small className="fundfinaColor"><strong>
                  *Settlement Already Received*
                </strong></small> :
                  <Button
                    className="ff-button mr-2 mt-2"
                    onClick={this.saveManualQueueAction}
                    disabled={!data["partnerId"] || !data["invoiceNo"]}
                  >
                    Submit
                  </Button>}
              </Form.Group>
            </Form.Row>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ManualQueueInvoice;
