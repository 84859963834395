
import InputDataForm from "../../components/common/form";
import * as collections from "../../services/collections/collections";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import * as commonService from "../../services/common/commonService";
import { toast } from "react-toastify";

class SettlementPending extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().subtract(6, "day"),
      toDate: momentJalaali(),
      partner: "all"
    },
    showSpinner: false,
    errors: {},
    partners: [],
    actionsList: []
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    partner: Joi.any(),
    isAllAction: Joi.any()
  };
  async getCollectionsOutCome(isAllAction) {

    const data = { ...this.state.data };

    let daysDiff = commonService.numberOfDaysDifferece(new Date(data.fromDate), new Date(data.toDate));
    if (daysDiff > 7) {
      toast.error("Max Date Range 7 days");
      this.setState({ showSpinner: false });
      return
    }

    this.setState({ showSpinner: true });
    try {
      const { data: actionsList } = await collections.getCollectionsOutcomeList(data);

      if (isAllAction) {
        this.setState({ data, showSpinner: false, allActionsList: actionsList, download: true }, () => {
          this.setState({ download: false })
        });
      } else
        this.setState({ data, showSpinner: false, actionsList });
    } catch (ex) {
      this.setState({ data, showSpinner: false, actionsList: [] });
    }
  };

  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  async componentDidMount() {
    await this.getBorroingPartners();
  }

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    await this.getCollectionsOutCome();
  };

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }

  onChangeHeaderActiveTab = (tab) => {
    this.state.data.channel = tab
    this.setState({ headerActiveTab: tab }, () => {
      this.resetPage(() => {
        this.getCollectionsOutCome()
      })
    })
  }

  handleDetailsPage = (action) => {
    window.location.href = `/collectionsOutcomeDetailsList?partnerId=${action.partnerId}&partnerName=${action.partnerName}&fromDate=${action.date}&toDate=${action.date}`
  }

  downloadExcel = () => {
    this.getCollectionsOutCome(true)
  }

}

export default SettlementPending;
