import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class PartnerConfigurationsModel extends InputDataForm {
  state = {
    data: {
      activeTab :"CREDIT_GRID"
    },
    showSpinner: false
  };
  schema = {
    // name : Joi.any()
  };
  
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    let { data } = this.state;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

  };
}

export default PartnerConfigurationsModel;
