import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ManualQueueModel from "../../models/manualQueueModel/manualQueueModel";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Tabs, Tab } from "react-bootstrap";
import ManualQueueSettlement from '../manualQueueSettlement/manualQueueSettlement';
import ManualQueueInvoice from '../manualQueueInvoice/manualQueueInvoice';
import ManualQueueLoanRepayments from '../manualQueueLoanRepayments/manualQueueLoanRepayments';


class ManualQueue extends ManualQueueModel {

  renderTabs = () => {
    let { tabs, activeTab } = this.state;
    let elements = []
    for (let i = 0; i < tabs.length; i++) {
      elements.push(
        <Tab eventKey={tabs[i].value} title={tabs[i].title}></Tab>
      )
    }
    return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  render() {
    const {
      pageSize,
      currentPage,
      showSpinner,
      successMsg,
      errors,
      data,
      statements,
      actions
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Manual Queue
        </h2>
        <Form onSubmit={this.handleSubmit}>

          <Form.Row>
            <Form.Group as={Col} md="4" controlId="valueStartDate">
              <Form.Label>From Date <small className="text-success"></small></Form.Label>
              <DatePicker
                name="valueStartDate"
                id="valueStartDate"
                value={data["valueStartDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleValueFromDateChange(value)}
                placeholder="Enter From Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="valueEndDate">
              <Form.Label>To Date</Form.Label>
              <DatePicker
                name="valueEndDate"
                id="valueEndDate"
                value={data["valueEndDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleValueToDateChange(value)}
                placeholder="Enter To Date"
              />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={data["amount"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>

            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>UTR No</Form.Label>
              <Form.Control
                type="text"
                name="utrNo"
                value={data["utrNo"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <br />
              <Button
                className="ff-button mr-2 mt-2"
                type="submit"
              >
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>

        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        {this.renderTabs()}
        {this.state.activeTab == "PENDING" ? <div style={{ width: '100%' }}>
          <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
            <Row>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", borderLeft: "1px solid", width: 150 }}><b>Transaction Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200, maxWidth: 200 }}><b>Narration Text</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Amount</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>UTR</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Sender Name</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Status</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Action</b></Cell>
            </Row>
            {statements &&
              statements.map((statement, i) => (
                <Row key={statement.narration}>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid" }}>{statement.postingDate && new Date(statement.postingDate) != "Invalid Date" ? new Date(statement.postingDate).toLocaleString() : statement.creditedDate && new Date(statement.creditedDate) != "Invalid Date" ? new Date(statement.creditedDate).toLocaleString() : statement.creditedDate}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", maxWidth: 500, textWrap: 'wrap ' }}>{statement.narration || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>₹{statement.amount}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.utrNo}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.senderName || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.status}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    <Form.Control
                      className="form-control"
                      style={{ width: 180 }}
                      as="select"
                      name="selectedAction"
                      value={statement["selectedAction"]}
                      onChange={(e) => this.onChangeAction(e, statement)}
                    >
                      {" "}
                      <option key={""} value={""}>
                        Select
                      </option>
                      {actions && actions.map((type) => (
                        <option key={type.value} value={type.value}>
                          {type.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Cell>
                </Row>
              ))}
            {!statements || !statements.length && (
              <Row>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }} Col={7}>No record found</Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
              </Row>
            )}

          </StickyTable>
        </div > : <></>}

        {this.state.activeTab == "COMPLETED" ? <div style={{ width: '100%' }}>
          <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
            <Row>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", borderLeft: "1px solid", width: 200 }}><b>Transaction Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Amount</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>UTR</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Sender Name</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Action</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Status</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Updated By</b></Cell>
            </Row>
            {statements &&
              statements.map((statement, i) => (
                <Row key={statement.narration}>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid" }}>{statement.postingDate && new Date(statement.postingDate) != "Invalid Date" ? new Date(statement.postingDate).toLocaleString() : statement.creditedDate && new Date(statement.creditedDate) != "Invalid Date" ? new Date(statement.creditedDate).toLocaleString() : statement.creditedDate}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>₹{statement.amount}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.utrNo}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.senderName}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.action || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.status}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.updatedBy || "-"}</Cell>
                </Row>
              ))}
            {!statements || !statements.length && (
              <Row>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }} Col={7}>No record found</Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
              </Row>
            )}

          </StickyTable>
        </div > : <></>}
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
        </div>

        {this.state.showManualQueueSettlement ? <ManualQueueSettlement
          data={this.state.selectedItem}
          close={this.closeSettlementModal}
          onSuccess={this.onSuccessAction}
        ></ManualQueueSettlement> : <></>}

        {this.state.showManualQueueInvoice ? <ManualQueueInvoice
          data={this.state.selectedItem}
          close={this.closeInvoiceModal}
          onSuccess={this.onSuccessActionInvoice}></ManualQueueInvoice> : <></>}

        {this.state.showManualQueueLoanRepayments ? <ManualQueueLoanRepayments
          data={this.state.selectedItem}
          close={this.closeLoanRepayments}
          onSuccess={this.onSuccessLoanRepayments}></ManualQueueLoanRepayments> : <></>}
      </React.Fragment>
    );
  }
}

export default ManualQueue;
