import InputDataForm from "../../components/common/form";
import * as userService from "../../services/userList/userListService";
import * as commonService from '../../services/common/commonService';
import auth from "../../services/common/authService";
import Joi from "joi-browser";
import momentJalaali from "moment-jalaali";

class UserListTableModel extends InputDataForm {
  state = {
    data: {
      partner: "all",
      mobileNo: "",
      name: "",
      email: "",
      merchantCode: "",
      users: [],
      fromDate: momentJalaali().subtract(3, "days"),
      toDate: momentJalaali(),
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 10,
    partners: []
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    mobileNo: Joi.any(),
    name: Joi.any(),
    email: Joi.any(),
    partner: Joi.any(),
    users: Joi.any(),
    merchantCode: Joi.any()
  };
  async getUserList() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const { data: userList } = await userService.getUserList(data);

      data.users = userList;

      this.setState({ data, showSpinner: false, currentPage: 1 });
    } catch (ex) {
      this.setState({ data, showSpinner: false, currentPage: 1 });
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role !== "Admin") return (window.location = "/");

    await this.getUserList();
    await this.getBorroingPartners();
  }
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getAllPartnerList();

      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  }
  async handleCustomErrors(errors) {
    return errors;
  }
  async doSubmit() {
    await this.getUserList();
  }
}

export default UserListTableModel;
