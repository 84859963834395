import React from 'react';
import DisbursalTable from './disbursalTable';
import SpinnerComponent from "../../components/common/spinner";
import DisbursalListModel from "../../models/disbursalV3/disbursalListModel";
import DisbursalDetail from './disbursalDetail';

class DisbursalListV3 extends DisbursalListModel {
    
    render() { 
        let {  showSpinner,data } = this.state;
        
        return (
            <React.Fragment>
                {" "}
                <SpinnerComponent showSpinner={showSpinner} />
                {data.loanId===""?<DisbursalTable data={data} onRedirect={this.handleRedirect} />:<DisbursalDetail loanId={data.loanId} onBack={this.handleBack}/>}
            </React.Fragment>
        );
    }
}
 
export default DisbursalListV3;