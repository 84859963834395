import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as portfolioService from "../../services/portfolio/portfolioService";
import * as collectionsQueueService from "../../services/collectionsQueue/collectionsQueue";
import moment from "moment";

class EmiListModel extends InputDataForm {
    state = {
        showSpinner: false,
        repaymentList: [],
        emiSummary: {},
        isAdjustedPrinciple: false,
        loanNo: "",
        showErrorModal: false
    };
    async getEmiDetails(loanId) {
        let isAdjustedPrinciple = false;
        this.setState({
            showSpinner: true
        });
        const {
            data: repaymentData,
        } = await portfolioService.getRepaymentDetails(loanId, this.props.showPaymentOptions ? true : false);

        if (repaymentData.emiDetails.length > 1 && (repaymentData.emiDetails[repaymentData.emiDetails.length - 1].principalRecovered !== repaymentData.emiDetails[repaymentData.emiDetails.length - 2].principalRecovered))
            isAdjustedPrinciple = true;

        repaymentData.emiDetails = repaymentData.emiDetails.map(item => ({ ...item, disabled: true }))


        for (let i = 0; i < repaymentData.emiDetails.length; i++) {
            let emi = repaymentData.emiDetails[i]
            if (this.props.showPaymentOptions && (emi.rePaymentStatus == 'Pending' || emi.rePaymentStatus == 'Write Off') && emi.showPaymentOptions) {
                emi.enableCollectionCheckbox = true
                break;
            }
        }

        this.setState({
            repaymentList: repaymentData.emiDetails,
            emiSummary: repaymentData.summary,
            showSpinner: false,
            isAdjustedPrinciple: isAdjustedPrinciple,
            loanNo: repaymentData.loanNo
        });

    }

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        let loanId = "";

        if (this.props.loanId)
            loanId = this.props.loanId;

        else
            loanId = this.props.match.params.id;

        if (loanId)
            await this.getEmiDetails(loanId);
    }

    handleHoldCollection = async (e, index) => {
        let repaymentList = this.state.repaymentList;

        const oldValue = repaymentList[index].holdWalletCollection;
        repaymentList[index].holdWalletCollection = !repaymentList[index].holdWalletCollection;
        this.setState({ repaymentList });

        const holdWalletCollection = await collectionsQueueService.holdWalletCollections(this.props.loanId, repaymentList[index].holdWalletCollection, repaymentList[index].emiNumber);

        if (!holdWalletCollection.success) {
            this.setState({ errorMessage: holdWalletCollection.message, showErrorModal: true, errorHeader: "Error!" });
            repaymentList[index].holdWalletCollection = oldValue;
            this.setState({ repaymentList });
        }
    };

    handleAmountChange = async (e, index) => {
        let repaymentList = this.state.repaymentList;
        repaymentList[index].amountCollected = !repaymentList[index].amountCollected;

        if (repaymentList[index].collectedOn && repaymentList[index].amountCollected)
            repaymentList[index].disabled = false;
        else
            repaymentList[index].disabled = true;

        this.setState({ repaymentList });
    }

    handleDateChange = (date, index) => {
        if (!date)
            return;

        let repaymentList = this.state.repaymentList
        repaymentList[index].collectedOn = date;

        if (repaymentList[index].collectedOn && repaymentList[index].amountCollected)
            repaymentList[index].disabled = false;
        else
            repaymentList[index].disabled = true;

        this.setState({ repaymentList });
    };

    handleEmiStatusChange = (e, index) => {
        let repaymentList = this.state.repaymentList;
        repaymentList[index].eNachStatus = e.target.value;
        this.setState({ repaymentList });
    };

    eNachAction = async (e, index) => {
        let repaymentList = this.state.repaymentList

        if (!repaymentList[index].eNachStatus) { this.setState({ errorMessage: "Please select e-Nach action", showErrorModal: true, errorHeader: "Note!" }); }

        else {

            repaymentList[index].nachBtnDisabled = true;
            this.setState({ showSpinner: true, repaymentList });

            let status = repaymentList[index].eNachStatus;
            let loanId = this.props.loanId
            let emiNumber = repaymentList[index].emiNumber;

            try {
                let response = await collectionsQueueService.updateEnachStatus(loanId, emiNumber, status);

                repaymentList[index].nachBtnDisabled = false;

                if (response.success) {

                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Success!", showSpinner: false, repaymentList });
                }

                else
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false, repaymentList });

            }
            catch (ex) {
                this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false, repaymentList });
            }
        }
    }

    handleMarkAsPaid = async (e, index) => {
        let repaymentList = this.state.repaymentList

        if (!repaymentList[index].amountCollected || !repaymentList[index].collectedOn) { this.setState({ errorMessage: "Please enter amount and date", showErrorModal: true, errorHeader: "Note!" }); }

        else {
            let date = moment(repaymentList[index].collectedOn).format("DD/MM/yyyy");
            let emiNumber = repaymentList[index].emiNumber;
            let loanId = this.props.loanId

            repaymentList[index].disabled = true;

            this.setState({ showSpinner: true, repaymentList })

            try {
                let response = await collectionsQueueService.markAsPaid(loanId, emiNumber, date);

                repaymentList[index].disabled = false;

                if (response.success) {
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Success!", showSpinner: false, repaymentList });
                    this.getEmiDetails(this.props.loanId)
                }

                else
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false, repaymentList });

            }
            catch (ex) {
                this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false, repaymentList });
            }
        }
    }

    closeModal = () => {
        this.setState({
            showRepaymentModal: false,
            showErrorModal: false
        });
    };
}

export default EmiListModel;
