import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as portfolioService from "../../services/portfolio/portfolioService";
import Joi, { wait } from "joi-browser";
import * as commonService from "../../services/common/commonService";
import { getLoanDetailsForTeleCalling, getLoanDetailsForTeleCallingCollectionQueue, updateTeleCallingActions, updateTeleCallingActionsCollectionQueue } from "../../services/loan/loanService";
import { toast } from "react-toastify";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import * as collectionsQueueService from "../../services/collectionsQueue/collectionsQueue";


class TeleCallingDetailsModel extends InputDataForm {
  state = {
    showSpinner: false,
    data: {
      callOutcome: "",
      borrowerResponse: "",
      note: "",
      callbackOn: "",
      willApplyOn: "",
      date: "",
      notConnectedReason: "",
      holdWalletCollection: false,
      amount: "",
      mobileNo: ""
    },
    callOutcomeList: [],
    borrowerResponseList: [],
    notConnectedReasonList: [],
    loan: {},
    userInfo: {},
    tabs: [
      {
        title: "Action",
        value: "ACTION"
      },
      {
        title: "History",
        value: "HISTORY"
      },
      {
        title: "Payment Link History",
        value: "PAYMENT_LINK_HISTORY"
      }
    ],
    activeTab: "ACTION",
    showDate: false,
    showAmount: false
  };

  refreshPaymentLinkMethod = null;

  schema = {
    callOutcome: Joi.any(),
    borrowerResponse: Joi.any(),
    note: Joi.any(),
    callbackOn: Joi.any(),
    willApplyOn: Joi.any(),
    date: Joi.any(),
    notConnectedReason: Joi.any(),
    date: Joi.date(),
    amount: Joi.any(),
    mobileNo: Joi.any()
  };

  async updateLoanTeleCallingAction() {
    let loanId = this.props.match && this.props.match.params && this.props.match.params.id;

    if (!loanId)
      return

    let isValid = this.validatePayload(this.state.data)

    if (!isValid)
      return

    this.setState({ showSpinner: true })
    try {
      let res = await updateTeleCallingActionsCollectionQueue(loanId, this.state.data)

      if (res && res.data && !res.data.success) {
        toast.error("Oops something went wrong");
        this.setState({ showSpinner: false })
        return
      }
      await this.getLoanDetails()
      this.resetPage()
      this.setState({ showSpinner: false })
    } catch (err) {
      this.setState({ showSpinner: false })
    }
  }

  resetPage = () => {

    let data = {
      callOutcome: "",
      borrowerResponse: "",
      note: "",
      callbackOn: "",
      willApplyOn: "",
      date: "",
      amount: "",
      showDate: false,
      showAmount: false,
      mobileNo: ""
    }
    this.setState({ data, reset: true }, () => {
      this.setState({ reset: false })
    })
  }

  async getLoanDetails() {
    let loanId = this.props.match && this.props.match.params && this.props.match.params.id;

    if (!loanId)
      return

    try {
      let loan = await getLoanDetailsForTeleCallingCollectionQueue(loanId)
      loan = loan.data
      let data = { ...this.state.data }

      if (loan.note) {
        data.note = loan.note
        this.setState({ data })
      }

      // this.setDefaultMobileNo(loan)
      this.setState({ loan, userInfo: loan && loan.userInfo ? loan.userInfo : {} }, () => {

      })
    } catch (err) {

    }
  }

  setDefaultMobileNo = (loan) => {

    if (loan && loan.userInfo && loan.userInfo.userDetails && loan.userInfo.userDetails.mobileNo && loan.userInfo.userDetails.mobileNo.length)
      this.state.data.mobileNo = loan.userInfo.userDetails.mobileNo[0].mobileNumber
  }

  validatePayload = (payload) => {

    if (!payload.mobileNo) {
      toast.error("Please select MobileNo");
      return false
    }

    if (!payload.callOutcome) {
      toast.error("Please select call outcome");
      return false
    }

    if (payload.callOutcome == 'Connected Successfully' && !payload.borrowerResponse) {
      toast.error("Please select borrower response");
      return false
    }

    if (payload.borrowerResponse && this.state.showDate && !payload.date) {
      toast.error("Please select date");
      return false
    }

    if (payload.borrowerResponse && this.state.showAmount && (!payload.amount || !payload.amount.trim() || payload.amount <= 0)) {
      toast.error("Please enter amount");
      return false
    }

    if (payload.callOutcome == 'Not Connected' && !payload.notConnectedReason) {
      toast.error("Please select not connected reason");
      return false
    }


    return true
  }

  handleOnChangeNote = (e) => {

    let data = { ...this.state.data }
    data.note = e.target.value;
    this.setState({ data })
  }

  async getCallOutcomeList() {
    let { callOutcomeList } = this.state;
    try {
      const {
        data: callOutcomeListResponse,
      } = await commonService.getRoleWiseDefinitionList("callOutcomeCollectionQueue");

      callOutcomeList = callOutcomeListResponse.data;

      this.setState({
        callOutcomeList,
      });
    } catch (ex) { }
  };

  async getBorrowerResponseList() {
    let { borrowerResponseList } = this.state;
    try {
      const {
        data: borrowerListResponse,
      } = await commonService.getRoleWiseDefinitionList("borrowerResponseCollectionQueue");

      borrowerResponseList = borrowerListResponse.data;

      this.setState({
        borrowerResponseList
      });
    } catch (ex) { }
  };

  async notConnectedReasonList() {
    let { notConnectedReasonList } = this.state;
    try {
      const {
        data: notConnectedReasonListResponse,
      } = await commonService.getRoleWiseDefinitionList("notConnectedReasonsCollectionQueue");

      notConnectedReasonList = notConnectedReasonListResponse.data;

      this.setState({
        notConnectedReasonList
      });
    } catch (ex) { }
  };

  onTabChange = (tab) => {
    this.setState({ activeTab: tab.value })
  }

  handleCalloutcomeChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    if (data.date) {
      data.date = ""
    }

    if (data.amount) {
      data.amount = ""
    }

    let index = null

    for (let i = 0; i < this.state.borrowerResponseList.length; i++) {

      if (this.state.borrowerResponseList[i].defination == input.value) {
        index = i;
      }
    }

    if (index != null) {
      let option = this.state.borrowerResponseList[index]

      if (option.showDate)
        this.state.showDate = true
      else
        this.state.showDate = false

      if (option.showAmount)
        this.state.showAmount = true
      else
        this.state.showAmount = false

    }
    this.setState({ data, errors });
  }

  handleNotConnectedReasonChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  }


  handleMobileNumberChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  }

  async componentDidMount() {
    console.log(this.props)
    console.log('user....', auth.getCurrentUser())
    if (!auth.getCurrentUser()) return (window.location = "/login");

    this.loanId = this.props.match && this.props.match.params && this.props.match.params.id;

    let emi = this.props.location && this.props.location.customProps && this.props.location.customProps.emi || {}

    if (emi.holdWalletCollection) {
      this.state.data.holdWalletCollection = emi.holdWalletCollection
    }

    await this.getLoanDetails()
    await this.getCallOutcomeList()
    await this.getBorrowerResponseList()
    await this.notConnectedReasonList()
  }

  async doSubmit() {
    await this.updateLoanTeleCallingAction()
  }

  async handleCustomErrors() {
  }

  async handleSendReview(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendReview(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }
    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  async handleSendContract(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendLoanContract(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  async handSendBankLink(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendNewMandateOrUpdateBankListRequest(loanId, "BankDetails");

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  handleChangeCheckbox = (callOutcome, value) => {
    let data = { ...this.state.data }

    if (data.date)
      data.date = ""

    if (data.amount)
      data.amount = ""

    if (this.state.showDate)
      this.state.showDate = false

    if (this.state.showAmount)
      this.state.showAmount = false

    data.callOutcome = callOutcome
    this.setState({ data })
  }

  onClickEmi = () => {

  }

  getRepaymentDetails = () => {
    this.setState({
      showRepaymentModal: true,
    })
  }

  closeModal = () => {
    this.setState({
      showRepaymentModal: false
    });
  };

  handleEmiNumberChange = (e) => {
    let data = { ...this.state.data };
    data.emiCount = e.target.value;
    this.setState({ data });
  };

  closeErrorModal = () => {
    this.setState({ showErrorModal: false })
  }

  handleHoldCollection = async (e) => {
    let data = { ...this.state.data };
    const oldValue = data.holdWalletCollection;
    data.holdWalletCollection = !data.holdWalletCollection;
    this.setState({ data });

    const holdWalletCollection = await collectionsQueueService.holdWalletCollections(this.loanId, data.holdWalletCollection);

    if (!holdWalletCollection.success) {
      this.setState({ errorMessage: holdWalletCollection.message, showErrorModal: true, errorHeader: "Error!" });
      data.holdWalletCollection = oldValue;
      this.setState({ data });
    }
  };

  eNachAction = async (e) => {
    let data = { ...this.state.data };

    if (data.eNachStatus === "Send" && !data.emiCount) { this.setState({ errorMessage: "Please select EMI Count", showErrorModal: true, errorHeader: "Note!" }); }
    else if (!data.eNachStatus) { this.setState({ errorMessage: "Please select e-Nach action", showErrorModal: true, errorHeader: "Note!" }); }
    else {

      data.nachBtnDisabled = true;

      this.setState({ showSpinner: true, data });

      let status = data.eNachStatus;
      let loanId = this.loanId;
      let emiCount = data.emiCount;

      try {
        let response = await collectionsQueueService.changeEnachStatusForLoan(loanId, emiCount, status);

        data.nachBtnDisabled = false;
        if (response.success) {
          this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Success!", showSpinner: false, data });
        }
        else
          this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false, data });
      }
      catch (ex) {
        this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false, data });
      }
    }
  }

  handleEmiStatusChange = (e) => {
    let data = { ...this.state.data };
    data.eNachStatus = e.target.value;
    this.setState({ data });
  };

  hanldeAmountChange = (e) => {

    let data = this.state.data
    data.amount = e.target.value;
    this.setState({ data })
  }

  async handleSendEngagementAppLink(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendEngagementAppLink(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
        toast.success("Loan Details Sent Successfully");
      } else {
        errors["globalError"] = response.message;
        toast.error("Oops something went wrong");
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      toast.error("Oops something went wrong");

      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  onClickSendPaymentLink = () => {
    let loan = this.props.location && this.props.location.customProps && this.props.location.customProps.emi || {}
    this.setState({ selectedLoan: loan, showPaymentLinkGenerate: true })
  }

  closePaymentLinkGenerate = () => {
    this.setState({ showPaymentLinkGenerate: false }, () => {

      if (this.state.activeTab == "PAYMENT_LINK_HISTORY") {
        if (this.refreshPaymentLinkMethod)
          this.refreshPaymentLinkMethod()
      }
    })
  }

  refreshPaymentLink = (refresh) => {
    this.refreshPaymentLinkMethod = refresh
  }
}

export default TeleCallingDetailsModel;
