import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as PaymentLinkService from "../../services/paymentLinks/paymentLinksServices"
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import * as collectionsQueueService from "../../services/collectionsQueue/collectionsQueue";

class PaymentLinkGenerateModal extends InputDataForm {
  state = {
    data: {
      agentMobileNo: ""
    },
    loan: {},
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    paymentList: [],
    statusList: [
      {
        title: "INITIATED"
      },
      {
        title: "PAID"
      },
      {
        title: "FAILED"
      },
      {
        title: "CANCELLED"
      }
    ],
    errorMessage: ""
  };
  schema = {
    page: Joi.any(),
    statusList: Joi.any(),
    status: Joi.any(),
    loanNo: Joi.any(),
    mobileNo: Joi.any(),
    emiCount: Joi.any(),
    agentMobileNo: Joi.any()
  };
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    let { data } = this.state;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });
    console.log(this.props)
    if (this.props.loan)
      this.setState({ loan: this.props.loan })
  };

  async handleCustomErrors(errors) {
    return errors;
  };

  /** */
  handleEmiNumberChange = (e) => {
    let data = { ...this.state.data };
    data.emiCount = e.target.value;
    this.setState({ data });
  };

  handlePaymentModeChange = (mode) => {
    let data = { ...this.state.data };
    data.paymentMode = mode;
    this.setState({ data });
  }

  handleMobileNumberChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  }
  onClickSend = () => {

    if (this.state.showSpinner)
      return;

    let isValid = this.validatePayload()

    if (!isValid)
      return;

    if (this.state.data.paymentMode == "UPI") {
      this.onClickUpiSend(this.props.loan)
    }

    if (this.state.data.paymentMode == "ENACH") {
      this.eNachAction();
    }
  }

  validatePayload = () => {

    let data = this.state.data;

    if (!data.emiCount) {
      toast.error("Please Select EDI'S");
      return false
    }
    if (!data.paymentMode) {
      toast.error("Please Select Payment Mode");
      return false
    }
    if (data.paymentMode == "UPI" && !data.mobileNo) {
      toast.error("Please Select MobileNo");
      return false
    }

    return true;
  }

  async onClickUpiSend(loan) {

    let data = this.state.data;
    if (!data.emiCount) { this.setState({ errorMessage: "Please select EMI Count", showErrorModal: true, errorHeader: "Note!" }); }
    else {
      try {
        let payload = { loanId: loan.loanId, emiCount: data.emiCount, mobileNo: data.mobileNo, agentMobileNo: data.agentMobileNo }

        if (data.agentMobileNo && (data.agentMobileNo.length < 10 || !+data.agentMobileNo)) {
          toast.error("Invalid Agent mobile No")
          return;
        }
        this.setState({ showSpinner: true })
        let response = await commonService.generateUpiLink(payload);
        this.setState({ showSpinner: false });

        if (response.success)
          this.setState({ paymentLink: response.data.link }, () => {
            toast.success("Payment Link Successfully sent");
            this.props.close && this.props.close();
          });
        else
          this.setState({ errorMessage: response.message || "Oops Something went Wrong" }, () => {
            this.resetErrorMesage()
          })

      } catch (ex) {
        this.setState({ showSpinner: false });
      }
    }
  }

  resetErrorMesage = () => {
    setTimeout(() => {
      this.setState({ errorMessage: "" })
    }, 3000)
  }

  eNachAction = async () => {
    let data = { ...this.state.data };
    let loan = this.props.loan;
    if (!loan)
      return;
    this.setState({ showSpinner: true });

    let status = "Send";
    let loanId = loan.loanId;
    let emiCount = data.emiCount;

    try {
      let response = await collectionsQueueService.changeEnachStatusForLoan(loanId, emiCount, status);
      this.setState({ showSpinner: false });
      if (response.success) {
        toast.success(response.message);
        this.props.close && this.props.close();
      }
      else {
        toast.error(response.message);
        this.setState({ errorMessage: response.message || "Oops Something went Wrong" }, () => {
          this.resetErrorMesage()
        })
      }
    }
    catch (ex) {
      this.setState({ showSpinner: false });
    }
  }
}


export default PaymentLinkGenerateModal;
