import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import EnachListModal from "../../models/eNachListModal/eNachListModal";
import { Form, Col, Button } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Tabs, Tab } from "react-bootstrap";

class eNachList extends EnachListModal {

  render() {
    const {
      pageSize,
      currentPage,
      showSpinner,
      successMsg,
      errors,
      data,
      eNachList
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <Form onSubmit={this.handleSubmit}>
          {/* {this.state.activeTab == "Upi" ? <Form.Row>

            <Form.Group as={Col} md="2" controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={data["status"]}
                className="form-control-sm"
                onChange={this.handleChange}
              >
                {" "}
                <option key="all" value="">
                  ALL
                </option>
                {statusList && statusList.map((partner) => (
                  <option key={partner.title} value={partner.title}>
                    {partner.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <br />
              <Button
                className="ff-button mt-2 mr-2 btn-sm"
                type="submit"
              >
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row> : <></>} */}
        </Form>

        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        <div style={{ width: '100%' }}>
          <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
            <Row>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", borderLeft: "1px solid", width: 150 }}><b>Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Emis</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Amount</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Status</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Payment Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 150 }}><b>Created By</b></Cell>
            </Row>
            {eNachList &&
              eNachList.map((item, i) => (
                <Row key={item._id}>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{item.date && new Date(item.date).toLocaleString() || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.emis && item.emis.join(',')}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>₹{item.amount || 0}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.status || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.paidOn && new Date(item.paidOn).toLocaleString() || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.createdBy || "-"}</Cell>
                </Row>
              ))}
            {!eNachList || !eNachList.length ? (
              <Row>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }} Col={5}>No record found</Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
              </Row>
            ) : ""}

          </StickyTable>
        </div >
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
        </div>
      </React.Fragment >
    );
  }
}

export default eNachList;
