import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { creditApiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/admin";

export async function markAsPaid(loanId, emiNumber, date) {
    const { data } = await http.post(`${apiEndpoint}/markAsPaid`, {
        loanId: loanId,
        emiNumber: emiNumber,
        date: date
    });
    return data;
}

export async function updateEnachStatus(loanId, emiNumber, status) {
    const { data } = await http.post(`${apiEndpoint}/changeEnachStatus`, {
        loanId: loanId,
        emiNumber: emiNumber,
        status: status
    });
    return data;
}

export async function getPendingEmisLoanWiseTeleCalling({ partnerId, loanNo, name, mobileNo, overdueBy, page, isAllLoans, tab, skip, month, year, lendingPartnerId }) {
    const { data } = await http.post(`${apiEndpoint}/getPendingEmisLoanWiseTeleCalling`, {
        partnerId: partnerId,
        loanNo: loanNo,
        merchantName: name,
        mobileNo: mobileNo,
        overdueBy: overdueBy,
        page,
        isAllLoans,
        tab,
        skip,
        month,
        year,
        lendingPartnerId
    });

    return data;
}

export async function getPendingEmisLoanWise({ partnerId, loanNo, name, mobileNo, overdueBy, page, isAllLoans, lendingPartnerId }) {
    const { data } = await http.post(`${apiEndpoint}/getPendingEmisLoanWise`, {
        partnerId: partnerId,
        loanNo: loanNo,
        merchantName: name,
        mobileNo: mobileNo,
        overdueBy: overdueBy,
        page,
        isAllLoans,
        lendingPartnerId
    });

    return data;
}

export async function holdWalletCollections(loanId, holdFlag, emiNumber = null) {
    const { data } = await http.post(`${apiEndpoint}/holdWalletCollections`, {
        loanId: loanId,
        holdFlag: holdFlag,
        emiNumber: emiNumber
    });
    return data;
}

export async function changeEnachStatusForLoan(loanId, emiCount, status) {
    const { data } = await http.post(`${apiEndpoint}/changeEnachStatusForLoan`, {
        loanId: loanId,
        emiCount: emiCount,
        status: status
    });
    return data;
}

export async function sendPreLegalNotice(loanNo) {
    const { data } = await http.post(`${apiEndpoint}/sendPreLegalNotice`, {
        loanNo: loanNo
    });
    return data;
}