import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/admin";

export async function updateTrancheRecord(model) {
    const {data} = await http.post(`${apiEndpoint}/updateTrancheRecord`, model);
    return data;
}

export async function createTrancheRecord(model) {
    const { data } = await http.post(`${apiEndpoint}/createTrancheRecord`, model);
    return data;
}

export async function getUserTrancheRecords({partnerId,pageNumber}) {
	const {data} = await http.post(`${apiEndpoint}/getPartnerTrancheRecords`, {
		partnerId,
        pageNumber
	});
    
	return data;
}

export async function deleteUserTrancheRecord(model) {
	const { data } = await http.post(`${apiEndpoint}/deletePartnerTrancheRecord`, {
		model,
	});
	return data;
}
