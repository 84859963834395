import Joi from "joi-browser";
import _ from "lodash";
import InputForm from "../../components/common/form";
import * as analytics from "../../utils/analytics";
import AgentService from '../../services/agent/agent'
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";

import { toast } from "react-toastify";

class AgentFormModel extends InputForm {
    state = {
        data: {
            loanNo: "",
            paymentMode: "FULL",
            paymentType: "UPI_COLLECTION",
            fromDate: null
        },
        mobileNumbers: [],
        pendingInstallments: 90,
        errors: {},
        info: {},
        isShowCollectionDetail: false,
        isSentPaymentLink: false,
        files: {
            collectionFile: "",
            collectionFileStream: "",
            collectionFileLabel: ""
        },
        fileErrors: {},
        isAgentProfileUpdated: false
    };

    schema = {
        loanNo: Joi.string(),
        mobileNo: Joi.any(),
        emiCount: Joi.any(),
        paymentType: Joi.any(),
    };

    async componentDidMount() {
        analytics.track();
        this.getGeoAddress()
    }

    getGeoAddress() {

        this.getGeoLocation(position => {
            if (position?.coords) {
                let { latitude, longitude } = position.coords
                this.coordinates = { latitude, longitude }
            }
        })

        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(permission => {
                if (permission.state != "granted") {
                    let errors = this.state.errors
                    errors["globalError"] = "Location permission has been blocked. Please allow access to your location and refresh the page."
                    this.setState({ errors })
                }
            })
        }


    }

    /** */
    handleEmiNumberChange = (e) => {
        let data = { ...this.state.data };
        data.emiCount = e.target.value;
        this.setState({ data }, () => {
            this.getLoanDetail()
        });
    };

    handlePaymentModeChange = (mode) => {
        let data = { ...this.state.data };
        data.paymentMode = mode;
        this.setState({ data }, () => {
            if (data.paymentMode === "FULL") {
                this.getLoanDetail()
            }
        });
    }

    handlePaymentTypeChange = (type) => {
        let data = { ...this.state.data };
        data.paymentType = type;
        this.setState({ data });
    }

    handleMobileNumberChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data, errors });
    }

    showAddMobileNumber = () => {
        this.setState({ showAddMobileNumber: true })
    }

    handleBack = () => {
        this.setState({ showAddMobileNumber: false })
        this.getLoanDetail()
    }

    getLoanDetail = async () => {
        if (!this.coordinates) return
        const errors = { ...this.state.errors };
        const data = { ...this.state.data };
        this.setState({ showSpinner: true });

        let payload = {
            loanNo: data.loanNo,
        }

        if (data.paymentMode === "PARTIAL") payload.emiCount = data.emiCount

        const response = await AgentService.getLoanDetailForAgent(payload);
        if (response?.data?.success) {
            let detail = response?.data?.data || {}

            if (detail.isRequestSentAlready) {
                data.paymentMode = detail.mode || "FULL"
                data.emiCount = detail.emiCount || 0
            }

            this.setState({
                collectionDetail: detail || {},
                isShowCollectionDetail: true,
                mobileNumbers: detail?.userDetails?.mobileNo || [],
                pendingInstallments: detail.pendingInstallments || 90,
                isSentPaymentLink: detail.isRequestSentAlready,
                isAgentProfileUpdated: detail.isAgentProfileUpdated,
                data
            })
        } else {
            let message = response?.data.message
        }

        this.setState({ showSpinner: false });


    }

    sendPaymentLink = async () => {
        let payload = {
            loanNo: this.state.data.loanNo,
            emiCount: this.state.data.paymentMode === "FULL" ? +this.state.pendingInstallments : +this.state.data.emiCount,
            mobileNo: this.state.data.mobileNo,
            mode: this.state.data.paymentMode,
            channel: this.state.data.paymentType,
            coordinates: this.coordinates,
            paymentDate: this.state.data.fromDate
        }

        this.setState({ showSpinner: true });

        try {
            const response = await AgentService.generatePaymentLink(payload);

            if (response?.success) {

                if (payload.channel != "WALLET") {
                    let collectionDetail = this.state.collectionDetail
                    collectionDetail.id = response?.data?.id
                    collectionDetail.paymentLink = response?.data?.paymentLink
                    collectionDetail.accountInfo = response?.data?.accountInfo
                    this.setState({ isSentPaymentLink: true, collectionDetail })
                    toast.success("Payment request sent");
                } else {
                    toast.success("Wallet request saved");
                    this.setState({
                        collectionDetail: null,
                        isShowCollectionDetail: false,
                        data: {
                            loanNo: "",
                            paymentMode: "FULL",
                            paymentType: "UPI_COLLECTION",
                            fromDate: null,
                        }
                    })
                }
            }
        } catch (e) {

        }

        this.setState({ showSpinner: false });

    }

    onClickCancelPaymentLink = () => {
        this.setState({ showCancelConfirmModal: true })
    }

    cancelPaymentLink = async () => {
        let payload = {
            id: this.state.collectionDetail?.id,
            coordinates: this.coordinates
        }

        this.setState({ showSpinner: true, showCancelConfirmModal: false });

        try {
            const response = await AgentService.cancelPaymentLink(payload);
            if (response?.success) {
                this.getLoanDetail()
                toast.success("Payment cancelled");

            } else {
                this.setState({ showSpinner: false });
            }
        } catch (e) {
            this.setState({ showSpinner: false });

        }
    }

    closeConfirm = () => {
        this.setState({ showCancelConfirmModal: false })

    }

    resendPaymentLink = async () => {
        try {
            let payload = {
                id: this.state.collectionDetail?.id,
                coordinates: this.coordinates
            }
            this.setState({ showSpinner: true, })
            const response = await AgentService.resendPaymentLink(payload);
            if (response?.success) {
                toast.success("Payment request send successfully");
            }
            this.setState({ showSpinner: false });
        } catch (e) {
            this.setState({ showSpinner: false });

        }
    }

    sendMyIdentity = async () => {
        try {
            let payload = {
                loanNo: this.state.data?.loanNo,
                coordinates: this.coordinates
            }
            this.setState({ showSpinner: true, })
            const response = await AgentService.sendAgentIdentity(payload);
            if (response?.success) {
                toast.success("Your ID successfully sent to the merchant");
            }
            this.setState({ showSpinner: false });
        } catch (e) {
            this.setState({ showSpinner: false });

        }
    }

    handleCustomErrors() {
        const files = { ...this.state.files };

        let fileErrors = { ...this.state.fileErrors };

        if (!files.collectionFile)
            fileErrors["collectionFile"] = "Please upload collection Image";
        else delete fileErrors["collectionFile"];

        this.setState({ fileErrors });

        return fileErrors;
    }

    onClickFileUpload = async (e) => {
        e.preventDefault();
        let customErrors = this.handleCustomErrors();

        if (_.isEmpty(customErrors)) {

            let fileUrl = this.state.files["collectionFileStream"]
            let [base, url] = fileUrl.split(".com/")

            let payload = {
                id: this.state?.collectionDetail?.id,
                fileUrl: url,
                coordinates: this.coordinates

            }
            this.setState({ showSpinner: true });

            try {
                const response = await AgentService.uploadPaymentDocument(payload);
                if (response?.success) {
                    window.location.reload()
                } else {
                    this.setState({ showSpinner: false });
                }
            } catch (e) {
                this.setState({ showSpinner: false });

            }
        }
    }

    async handleSendEngagementAppLink(loanId) {
        this.setState({ showSpinner: true });
        const errors = { ...this.state.errors };
        errors["globalError"] = null;
        try {
            let response = await AgentService.sendEngagementAppLink({ loanId });

            if (response.success) {
                this.setState({ showSpinner: false, successMsg: response.message, errors });
                toast.success("Loan Details Sent Successfully");
            } else {
                errors["globalError"] = response.message;
                toast.error("Oops something went wrong");
                this.setState({ showSpinner: false, errors, successMsg: null });
            }

        } catch (ex) {
            errors["globalError"] = ex.message;
            toast.error("Oops something went wrong");

            this.setState({ showSpinner: false, errors, successMsg: null });
        }
    };

    onClickRemoveFile = () => {
        let collectionDetail = this.state.collectionDetail
        collectionDetail.file = null
        this.setState({ collectionDetail })
    }

    doSubmit = async () => {
        const errors = { ...this.state.errors };
        const data = { ...this.state.data };
        this.setState({ showSpinner: true });
    }

}

export default AgentFormModel;
