import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import moment from "moment";
import DatePicker from "react-datepicker2";
import TrancheUpdateModel from '../../models/tranche/trancheUpdateModel';

class TrancheUpdate extends TrancheUpdateModel {

    render() {
        const { data, showSpinner, errors, successMsg } = this.state;

        return (<React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>Tranche</h2>
            {" "}
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId="partner">
                        <Form.Label>Partner</Form.Label>
                        <Form.Control
                            type="text"
                            name="partner"
                            value={data["partner"]}
                            onChange={this.handleChange}
                            autoComplete="off"
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="trancheNo">
                        <Form.Label>Tranche No</Form.Label>
                        <Form.Control
                            type="text"
                            name="trancheNo"
                            value={data["trancheNo"]}
                            onChange={this.handleChange}
                            autoComplete="off"
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="transactionDate">
                        <Form.Label>Transaction Date*</Form.Label>
                        <DatePicker
                            name="transactionDate"
                            id="transactionDate"
                            value={data["transactionDate"]}
                            inputFormat="DD/MM/YYYY"
                            max={moment()}
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleTransactionDateChange(value)}
                            placeholder="Enter Transaction Date"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId="depositDate">
                        <Form.Label>Deposit Date*</Form.Label>
                        <DatePicker
                            name="depositDate"
                            id="depositDate"
                            value={data["depositDate"]}
                            inputFormat="DD/MM/YYYY"
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleDepositDateChange(value)}
                            placeholder="Enter Transaction Date"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="amount">
                        <Form.Label>Amount*</Form.Label>
                        <Form.Control
                            type="text"
                            name="amount"
                            value={data["amount"]}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPressDecimal}
                            autoComplete="off"
                        />
                        {errors["amount"] && (
                            <div className="alert alert-danger">{errors["amount"]}</div>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="isOpen">
                        <Form.Label>Status*</Form.Label>
                        <Form.Control
                            as="select"
                            name="isOpen"
                            value={data["isOpen"]}
                            onChange={this.handleChange}
                        >
                            {" "}
                            <option key="true" value="true">
                                Open
                            </option>
                            <option key="false" value="false">
                                Close
                            </option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Button
                        className="ff-button"
                        type="submit"
                        style={{ marginRight: 10 }}
                    >
                        Submit
                    </Button>
                    {"  "}
                    <Button className="ff-button" type="button" onClick={this.goBack}>
                        Back
                    </Button>{" "}
                    <br />
                </Form.Row>
            </Form>
            <br />
            {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
            {successMsg && <div className="alert alert-success">{successMsg}</div>}
            <br />
        </React.Fragment>);
    }
}

export default TrancheUpdate;