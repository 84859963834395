import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import SettlementMISModel from "../../models/settlementMIS/settlementMIS";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker2";
import FileUploader from './fileUploader'


class SettlementMISTable extends SettlementMISModel {
  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      successMsg,
      errors,
      showUploader
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Settlement MIS
        </h2>

        <div className="mt-3">

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>

              <Form.Group as={Col} md="3" controlId="fromDate">
                <Form.Label>From Date</Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>


              <Form.Group as={Col} md="3">
                <Form.Label> </Form.Label>
                <br />
                <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Apply Filters
                </Button>

                <Button
                  className="ff-button mt-2 mr-2"
                  onClick={this.onClickUploadFile}
                >
                  Upload File
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}

          <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid",  borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>File Name</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>File Uploaded On</b></Cell>
                {/* <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Borrowing Partner</b></Cell> */}
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Uploaded By</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b></b></Cell>
              </Row>
              {data.misList && data.misList &&
                data.misList.map((mis, i) => (
                  <Row key={mis.url}>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey",borderLeft: "1px solid", borderRight: "1px solid" , maxWidth : '500px' }}>{mis.fileName || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey",  borderRight: "1px solid" }}>{mis.uploadedOn && moment(mis.uploadedOn).format("DD/MM/yyyy HH:MM:SS") || "-"}</Cell>
                    {/* <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{mis.partnerName}</Cell> */}
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{mis.uploadedBy}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <button onClick={()=>this.download(mis.url)} className="btn btn-sm btn-primary">
                        Download
                      </button>
                    </Cell>
                  </Row>
                ))}
              {(!data.misList || !data.misList.length) && (
                <td colSpan={5} className='text-center pt-2 pb-2 border'>No Records Found</td>
              )}

            </StickyTable>
          </div >
          <div style={{ width: "100%", overflowX: "scroll" }}>
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.onPageChange}
              onPagePrevious={this.onPagePrevious}
              onPageNext={this.onPageNext}
            />
          </div>
        </div>

        {showUploader && <FileUploader onClose={this.onClose}></FileUploader>}
      </React.Fragment>
    );
  }
}

export default SettlementMISTable;
