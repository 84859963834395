import React from "react";
import SpinnerComponent from "../../components/common/spinner";
import { Form, Col, Button, Table } from "react-bootstrap";
import moment from "moment";
import ReactExport from "react-export-excel";
import NumberFormat from 'react-number-format';
import TeleCallingDetailsModel from "../../models/teleCallingDetailsRenewals/teleCallingDetailsRenewals";
import DatePicker from "react-datepicker2"
import { StickyTable, Row, Cell } from 'react-sticky-table';

class TeleCallingDetailsRenewals extends TeleCallingDetailsModel {

  renderTabs = () => {
    let { tabs, activeTab } = this.state;
    let elements = []

    for (let i = 0; i < tabs.length; i++) {
      let tab = tabs[i]
      elements.push(
        <div key={tab.value || i} onClick={() => { this.onTabChange(tab) }} className={`d-inline-block mr-3 cursor-pointer ${tab.value == activeTab ? 'active' : ""}`}>{tab.title}</div>
      )
    }

    return <div className="tele-calling-tab mb-3">{elements}</div>
  }

  renderHistory = () => {

    let { loan } = this.state;

    return (<StickyTable stickyHeaderCount={0} rightStickyColumnCount={1} leftStickyColumnCount={2}>
      <Row>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Called On</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Outcome</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Borrower Response</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reason For Not Connected</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Action On</b></Cell>
        {/* <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center",borderLeft : 0 }}><b>Will Apply On</b></Cell> */}
      </Row>
      {loan.teleCallingActions && loan.teleCallingActions.length ?
        loan.teleCallingActions.map((action, i) => (
          action.isRenewals ? <Row key={loan._id}>
            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{new Date(action.calledOn).toDateString()}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.outcome}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.borrowerResponse ? action.borrowerResponse : "-"}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.notConnectedReason ? action.notConnectedReason : "-"}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.date ? new Date(action.date).toDateString() : "-"}</Cell>
            {/* <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "",borderLeft :0, borderColor: "lightgrey", borderRight: "1px solid" ,borderBottom : "1px solid lightgrey"}}>{action.willApplyOn  ?new Date(action.willApplyOn).toDateString() : "-"}</Cell> */}
          </Row> : ""
        )) : <Row>
          <Cell style={{ border: 0, borderLeft: "1px solid", borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ border: 0, borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ textAlign: 'center', borderBottom: "1px solid", borderColor: "lightgrey" }}>No record found</Cell>
          <Cell style={{ border: 0, borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ border: 0, borderRight: "1px solid", borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
        </Row>}

    </StickyTable>)

  }

  render() {
    const {
      showSpinner,
      callOutcomeList,
      data,
      borrowerResponseList,
      notConnectedReasonList,
      loan,
      userInfo,
      activeTab,
      reset,
      showDate
    } = this.state;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Name :</span> {userInfo && userInfo.userDetails ? (userInfo.userDetails.firstName || "") + (userInfo.userDetails.middleName || "") + ' ' + (userInfo.userDetails.lastName || "") : ""}</div>
          <div className="col"><span className="fundfinaColor">KYC Status :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.kycStatus ? userInfo.userDetails.kycStatus : "-"}</div>
          {userInfo && userInfo.userDetails && userInfo.userDetails.kycStatus == 'Review' ? <div className="col"><a href={`/scrutinyInfoRedirect/${userInfo._id}/${false}/${loan.partnerId}/${loan.merchantCode}`}>Edit KYC</a></div> : <div className="col"></div>}
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Email :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.emailId.length ? userInfo.userDetails.emailId[0] : ""}</div>
          <div className="col"><span className="fundfinaColor">Mobile Number :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.mobileNo.length ? userInfo.userDetails.mobileNo[0].mobileNumber : ""}</div>
          <div className="col"></div>
        </div>

        <div className="row form-group tele-calling-devider">
          <div className="col"><span className="fundfinaColor">Alternate Email :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.emailId.length > 1 ? userInfo.userDetails.emailId[1] : "-"}</div>
          <div className="col"><span className="fundfinaColor">Alternate Mobile Number :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.mobileNo.length > 1 ? userInfo.userDetails.mobileNo[1].mobileNumber : "-"}</div>
          <div className="col"></div>
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Loan Amount :</span> ₹{loan.amount}</div>
          <div className="col"><span className="fundfinaColor">Installment Amount/EDI :</span> ₹{loan.emiAmount}</div>
          <div className="col"><span className="fundfinaColor">Processing Fees :</span> {loan.processingFee ? '₹' + loan.processingFee : "-"}</div>
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Total Interest Amount :</span> ₹{(loan.amount * loan.interestRate) / 100}</div>
          <div className="col"><span className="fundfinaColor"><a href="/calculator">EMI Calculator</a></span></div>
          <div className="col"><span className="fundfinaColor">ROI :</span> {loan.interestRate}%</div>
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Status :</span> {loan.status}</div>
          <div className="col"><span className="fundfinaColor">Last Completed Step :</span> {loan.lastStepCompleted}</div>
          <div className="col"><span className="fundfinaColor">Last Updated On :</span> {loan.updatedOn ? new Date(loan.updatedOn).toDateString() : ""}</div>
        </div>

        <div className="row form-group tele-calling-devider">
          <div className="col"><span className="fundfinaColor">Reason for eNACH Failure :</span> {loan.eNachFailureReason ? loan.eNachFailureReason : "-"}</div>
          <div className="col"><span className="fundfinaColor">eNACH Required :</span> {loan.eNachRequired ? 'Yes' : 'No'}</div>
          <div className="col"><span className="fundfinaColor">eNACH Status :</span> {loan.eNachStatus ? loan.eNachStatus : "-"}</div>
        </div>

        {/* <div className="row form-group tele-calling-devider">
          <div className="col"><span className="fundfinaColor">Application Link : </span><Button
            onClick={() => this.handleSendReview(loan._id)}
            className="ff-button"
            disabled={(loan.status === "Review" || loan.kycStatus === "Review") ? false : true}
          >
            Resend
          </Button>
          </div>

          <div className="col">
            <span className="fundfinaColor">Contract Link : </span>
            <Button
              onClick={() => this.handleSendContract(loan._id)}
              className="ff-button"
              disabled={(loan.status === "Loan Is Funding" || (loan.status === "Draft" && loan.isStp) || (loan.status === "Loan Contract Accepted" && loan.isForceMandate)) ? false : true}
            >
              Resend
            </Button>
          </div>

          <div className="col">
            <span className="fundfinaColor">Bank Link : </span>
            <Button
              onClick={() => this.handSendBankLink(loan._id)}
              className="ff-button"
              disabled={(loan.status === "Draft" || loan.status === "Under Review" || loan.status === "Approved By Admin" || loan.status === "Accepted By Client" || loan.status === "Loan Is Funding") ? false : true}
            >
              Send
            </Button>
          </div>
        </div> */}

        {this.renderTabs()}

        <>
          {activeTab == 'ACTION' && !reset ?
            <Form onSubmit={this.handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="callOutcome">
                  <Form.Label>Call Outcome <span>*</span></Form.Label>

                  <div className="row">
                    <div className="col">
                      <div className="cursor-pointer" onClick={(event) => this.handleChangeCheckbox("Connected Successfully", !(data['callOutcome'] == "Connected Successfully"))}>
                        <input
                          type="radio"
                          id="calloutcome"
                          name="calloutcome"
                          value="Connected Successfully"
                          checked={data['callOutcome'] == "Connected Successfully"}
                          onChange={(event) => this.handleChangeCheckbox("Connected Successfully", !(data['callOutcome'] == "Connected Successfully"))}
                        />
                        <span> Connected Successfully</span>
                      </div>
                    </div>
                    <div className="col">
                      <div className="cursor-pointer" onClick={(event) => this.handleChangeCheckbox("Not Connected", !(data['callOutcome'] == "Not Connected"))}>
                        <input
                          type="radio"
                          id="calloutcome"
                          name="calloutcome"
                          value="Connected Successfully"
                          checked={data['callOutcome'] == "Not Connected"}
                          onChange={(event) => this.handleChangeCheckbox("Not Connected", !(data['callOutcome'] == "Not Connected"))}
                        />
                        <span> Not connected</span>
                      </div>
                    </div>
                  </div>

                  {/* <Form.Control
                    as="select"
                    name="callOutcome"
                    value={data["callOutcome"]}
                    onChange={this.handleCalloutcomeChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {callOutcomeList.map((item, i) => (
                      <option key={i} value={item.defination}>
                        {item.defination}
                      </option>
                    ))}
                  </Form.Control> */}
                </Form.Group>
              </Form.Row>

              {data.callOutcome && data.callOutcome == 'Connected Successfully' ? <Form.Row>
                <Form.Group as={Col} md="4" controlId="borrowerResponse">
                  <Form.Label>Borrower Response <span>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="borrowerResponse"
                    value={data["borrowerResponse"]}
                    onChange={this.handleCalloutcomeChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {borrowerResponseList.map((item, i) => (
                      <option key={i} value={item.defination}>
                        {item.defination}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {data.borrowerResponse && (showDate) ? <Form.Group as={Col} md="4" controlId="fromDate">
                  <Form.Label>{data.borrowerResponse} On <span>*</span></Form.Label>
                  <DatePicker
                    name="date"
                    id="date"
                    value={data["date"]}
                    min={moment().subtract(1, 'days')}
                    inputFormat="DD/MM/YYYY"
                    isGregorian={true}
                    className="form-control"
                    inputReadOnly={true}
                    showTodayButton={false}
                    timePicker={false}
                    onChange={(value) => this.handleDateChange(value)}
                    placeholder="Enter Date"
                    popperProps={{
                      positionFixed: true // use this to make the popper position: fixed
                    }}
                  />
                </Form.Group> : <></>}
              </Form.Row> : <></>}

              {data.callOutcome && data.callOutcome == 'Not Connected' ? <Form.Row>
                <Form.Group as={Col} md="4" controlId="borrowerResponse">
                  <Form.Label>Reason For Not Connected <span>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="notConnectedReason"
                    value={data["notConnectedReason"]}
                    onChange={this.handleNotConnectedReasonChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {notConnectedReasonList.map((item, i) => (
                      <option key={i} value={item.defination}>
                        {item.defination}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row> : <></>}

              <Form.Row>
                <Form.Label>Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="note"
                  value={data["note"]}
                  onChange={this.handleOnChangeNote}
                />
              </Form.Row>

              <Form.Row style={{ marginTop: 20, float: 'right' }}>
                <Form.Group as={Col} md="12">
                  <Button
                    className="ff-button"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form.Row>

            </Form> : this.renderHistory()}
        </>

      </React.Fragment>


    );
  }
}

export default TeleCallingDetailsRenewals;
