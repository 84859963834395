import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi, { errors, schema } from "joi-browser";
import _ from "lodash";
import * as trancheService from "../../services/tranche/trancheService";
import * as commonService from  "../../services/common/commonService";

class TrancheModel extends InputDataForm {
    state = {
        data: {
            lendingPartner: "",
            fromDate: momentJalaali().subtract(5, "months"),
            toDate: momentJalaali(),
        },
        showSpinner: false,
        errors: {},
        info: {},
        lendingPartnerList: [],
        list: [],
        exportList: [],
        loansExportList: [],
        currentPage: 1,
        pageSize: 10,
    };

    schema = {
        lendingPartner: Joi.any().required(),
        fromDate: Joi.any().required(),
        toDate: Joi.any().required()
    };

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();
        if (currentUser.role != "Admin") return (window.location = "/");
        else {
            await this.getLenderList();
            await this.getTrancheList();
        }
    };

    async getTrancheList() {
        const data = { ...this.state.data };
        let list = { ...this.state.list };
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        let exportList = [];
        let loansExportList = [];
        try {
            errors["globalError"] = null;
            const model = {
                partnerId: data.lendingPartner,
                fromDate: data.fromDate,
                toDate: data.toDate
            }
            const trancheList = await trancheService.getTrancheList(model);

            if (trancheList) {
                if (trancheList.success) {
                    list = trancheList.data;
                    exportList = list;
                    this.setState({ exportList, loansExportList, list, errors, successMsg: trancheList.message });
                }
                else {
                    list = [];
                    errors["globalError"] = trancheList.message;
                    this.setState({ exportList, loansExportList, list, errors, successMsg: null });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ exportList, loansExportList, errors, successMsg: null });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ exportList, loansExportList, errors, showSpinner: false, successMsg: null });
        }
    };

    async getLenderList() {
        let lendingPartnerList = { ...this.state.lendingPartnerList };
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        try {
            errors["globalError"] = null;
            // const partnerList = await trancheService.getPartnerList("Lending Partner");
            const partnerList = await commonService.getLendingParterList()

            if (partnerList) {
                if (partnerList.success) {
                    lendingPartnerList = partnerList.data;
                    this.setState({ lendingPartnerList, errors, successMsg: partnerList.message });
                }
                else {
                    lendingPartnerList = [];
                    errors["globalError"] = partnerList.message;
                    this.setState({ lendingPartnerList, errors });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null });
        }
    };

    async handleCustomErrors(errors) {
        console.log(errors);
        return errors;
    };

    handleExport = async (e) => {
        const recordFields = e.target.name.split('&');
        const trancheNo = recordFields[1] == "all" ? "" : recordFields[1];
        let loansExportList = { ...this.state.exportList };
        let errors = { ...this.state.errors };
        this.setState({ loansExportList: [], showSpinner: true });
        try {
            errors["globalError"] = null;

            const loanDetails = await trancheService.getTrancheLoanDetails(trancheNo);

            if (loanDetails) {
                if (loanDetails.success) {
                    loansExportList = loanDetails.data;
                    this.setState({ loansExportList, errors, successMsg: loanDetails.message });
                }
                else {
                    loansExportList = [];
                    errors["globalError"] = loanDetails.message;
                    this.setState({ loansExportList, errors, successMsg: null });
                }

            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null });
        }
    };

    async doSubmit() {
        await this.getTrancheList();
    };

}

export default TrancheModel;