import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as analytics from "../../utils/analytics";

function Msme(props) {
  analytics.track();
  return (
    <React.Fragment>
      <h2>MerchantBrisk – An MSME liquidity solution</h2>
      <p>
        <b>MerchantBrisk Features</b>
      </p>
      <ol>
        <li>
          MerchantBrisk is a micro, small, and medium business financing product
          that enables businesses to buy inventory, increase working capital,
          manage liquidity better.
        </li>
        <li>
          MerchantBrisk lends to businesses who are distributors for bluechip
          retail firms in a closed loop format.
        </li>
        <li>
          MerchantBrisk is typically structured as daily remittance funding; a
          lump sum payment to a business in exchange for an agreed daily payment
          at a fixed interest rate for a fixed period.
        </li>
        <li>
          Interest rate charged and maximum amount loan depend on certain
          heuristic criteria and Fundfina’s TrueScore.
        </li>
        <li>
          TrueScore (Transaction Uncertainty Expectation score) is Fundfina’s
          proprietary transaction-based credit scoring system. Higher the True
          Score, lower the probability of default.
        </li>
      </ol>
      <p className="fundfinaColor">
        <b>TrueScore  - <u> <Link to="/trueScore">Get Your TrueScore</Link></u></b>
      </p>
      <ol className="fundfinaColor">
        <li>Uses monthly transaction volumes, counts, and their variance.</li>
        <li>
          Uses properties of distribution of transaction volumes and counts.
        </li>
        <li>
          Uses advance machine learning techniques to establish probability of
          default based on indicators described above, some demographic data,
          and retailer data.
        </li>
        <li>
          TrueScore is calculated using a simple formula based on probability of
          default and several other criteria based on non transaction data.
        </li>
      </ol>
      <p>
        <b>Eligibility Criteria:</b>
      </p>
      <ol>
        <li>
          Tier 1 retailer distributor with minimum 6 months of relationship.
        </li>
        <li>TrueScore and AccessEase index values above minimum threshold.</li>
        <li>Minimum transaction volume over past 6 months.</li>
        <li>Increasing business volumes.</li>
      </ol>
      <br />
      <Link to="/borrow">
        <Button className="ff-button" type="button">
          Apply Now
        </Button>
      </Link>
    </React.Fragment>
  );
}

export default Msme;
