import React, { Component } from "react";
import InputDataForm from "../../components/common/form";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import leadInfoService from "../../services/partner/leadInfoService";

class CreditLineSummaryRedirect extends InputDataForm {
    state = {
        properties: {
            date: "",
            loanId: "",
            agentName: "",
            agentNumber: "",
            shortauthKey: "",
            partnerId: ""
        },
        errors: {},
        info: {},
        redirectPath: false,
        state: null
    };

    async componentDidMount() {
        const loanId = this.props.match.params.loanId;
        const shortauthKey = this.props.match.params.shortauthKey;
        const agentName = this.props.match.params.agentName;
        const agentNumber = this.props.match.params.agentNumber;

        let properties = { ...this.state.properties };

        properties.loanId = loanId;
        properties.agentName = agentName;
        properties.agentNumber = agentNumber;
        properties.shortauthKey = shortauthKey;

        this.setState({ properties });

        if (!loanId)
            this.setState({ redirectPath: "/invalidData", state: { message: "Invalid parameters provided" } });
        else if (!auth.getCurrentUser()) {
            const result = await leadInfoService.getPartnerId(loanId);

            let redirectionUrl = `/creditLineSummaryRedirect/${loanId}/${shortauthKey}`;

            if (agentName)
                redirectionUrl += `/${agentName}`;
            if (agentNumber)
                redirectionUrl += `/${agentNumber}`;

            if (result.success)
                this.setState({ redirectPath: `/${result.data}/merchantAuthenticate`, state: { loanData: { loanId: loanId, partnerId: result.data, redirectionUrl: redirectionUrl } } });
            else
                this.setState({ redirectPath: "/invalidData", state: { message: result.message } });
        }
        else {
            const result = await leadInfoService.getPartnerId(loanId);

            if (result.success)
                this.setState({ redirectPath: `/${result.data}/creditLineSummary`, state: { userData: properties } });
            else
                this.setState({ redirectPath: "/invalidData", state: { message: result.message } });


        }

    }
    render() {
        const { redirectPath, state } = this.state;
        return (
            redirectPath && (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state
                    }}
                />
            )
        );
    }
}
export default CreditLineSummaryRedirect;
