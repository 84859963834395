import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import * as notificationServices from "../../services/notification/notification";

class NotificationModel extends InputDataForm {
    state = {
        data: {
            criteria: "",
            value: "",
            operator: "",
            selectedPartners: [],
            selectedLoanTypes: [],
            selectedProductTypes: [],
            selectedNotificationType: "Offer Notification",
            fileName: "",

        },
        conditionsList: [],
        showSpinner: false,
        errors: {},
        errorMessage: "",
        successMessage: "",
        partnerList: [],
        showPopUp: false,
        popupMsg: "",
    };
    schema = {
        criteria: Joi.any(),
        value: Joi.any(),
        operator: Joi.any(),
        selectedPartners: Joi.any(),
        selectedLoanTypes: Joi.any(),
        selectedProductTypes: Joi.any(),
        selectedNotificationType: Joi.any(),
        conditionsList: Joi.any()
    }

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();

        const { sortBy } = this.state;

        if (currentUser.role != "Admin")
            return (window.location = "/");

        const { data: partnerList } = await commonService.getBorrowingPartnerList();

        this.setState({ partnerList: partnerList });
    };

    handleAddConditions = async (e) => {
        this.setState({ showSpinner: true });
        let errorMessage = { ...this.state.errorMessage };
        try {
            errorMessage = "";
            const conditionsList = [...this.state.conditionsList];
            let data = { ...this.state.data };
            if (data.criteria === "" || data.value === "" || data.operator === "") {
                this.setState({ showSpinner: false, errorMessage: "All fields are mandatory !!!" });
            } else {
                let isValid = true;
                if (conditionsList && conditionsList.length > 0) {
                    if ((data.criteria === "Amount" || data.criteria === "TrueScore" || data.criteria === "Age")) {
                        if (data.operator === ">=") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === ">=" || x.operator === ">"));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === "<=") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === "<=" || x.operator === "<"));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === ">") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === ">" || x.operator === ">="));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === "<") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === "<" || x.operator === "<="));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === "=") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === "<" || x.operator === "<=" || x.operator === ">" || x.operator === ">="));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        }
                    } else if (data.criteria === "Interest Rate" && data.operator === "=") {
                        const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && x.operator === "=");
                        if (condiFilter && condiFilter.length > 0) isValid = false;
                    } else isValid = false;
                }


                if (isValid) {
                    const id = conditionsList + 1;
                    conditionsList.push({ id: id, criteria: data.criteria, value: data.value, operator: data.operator });
                    data.criteria = "";
                    data.value = "";
                    data.operator = "";
                    this.setState({ data: data, showSpinner: false, conditionsList: conditionsList, errorMessage: "" });
                } else {
                    errorMessage = `Operator ${data.operator} is not allowed for Criteria ${data.criteria} as it is already in list or restricted`;
                    this.setState({ showSpinner: false, errorMessage: errorMessage });
                }
            }
        } catch (ex) {
            this.setState({ showSpinner: false, errorMessage: ex.message });
            console.log(ex);
        }
    };

    handleRemoveConditions = async (id) => {
        this.setState({ showSpinner: true });
        let errorMessage = { ...this.state.errorMessage };
        try {
            errorMessage = "";
            const conditionsList = [...this.state.conditionsList];
            const index = conditionsList.findIndex(e => e.id.toString() === id.toString());
            if (index >= 0) {
                conditionsList.splice(index, 1);
            }
            this.setState({ showSpinner: false, conditionsList: conditionsList, errorMessage: errorMessage });
        } catch (ex) {
            errorMessage = ex.message;
            this.setState({ showSpinner: false, errorMessage: errorMessage });
            console.log(ex);
        }
    };

    async getNotificationRequestModel(isSend) {
        let output = {
            data: null,
            message: "Some Error Occurred",
            success: false,
        };
        try {

            if (this.state.isExcelView) {
                if (!this.state.data?.selectedFile) {
                    output.message = "Please select excel file";
                    return output
                } else {
                    output = {
                        data: {},
                        message: "generated successfully",
                        success: true,
                    }
                    return output
                }
            }

            const conditionsList = [...this.state.conditionsList];
            let data = { ...this.state.data };
            if (data.selectedPartners && data.selectedPartners.length > 0) {
                const model = {
                    partners: data.selectedPartners,
                    loanTypes: data.selectedLoanTypes,
                    productTypes: data.selectedProductTypes,
                    notificationType: data.selectedNotificationType,
                    isSendNotification: isSend,
                    eqAmount: null,
                    gtAmount: null,
                    ltAmount: null,
                    gteAmount: null,
                    lteAmount: null,
                    eqTrueScore: null,
                    gtTrueScore: null,
                    ltTrueScore: null,
                    gteTrueScore: null,
                    lteTrueScore: null,
                    annualIntrestRate: null,
                    eqAge: null,
                    gtAge: null,
                    ltAge: null,
                    gteAge: null,
                    lteAge: null,
                }
                for (let i = 0; i < conditionsList.length; i++) {
                    if (conditionsList[i].criteria === "Amount") {
                        switch (conditionsList[i].operator) {
                            case "=":
                                model.eqAmount = parseFloat(conditionsList[i].value);
                                break;
                            case ">":
                                model.gtAmount = parseFloat(conditionsList[i].value);
                                break;
                            case "<":
                                model.ltAmount = parseFloat(conditionsList[i].value);
                                break;
                            case ">=":
                                model.gteAmount = parseFloat(conditionsList[i].value);
                                break;
                            case "<=":
                                model.lteAmount = parseFloat(conditionsList[i].value);
                                break;
                        }
                    } else if (conditionsList[i].criteria === "TrueScore") {
                        switch (conditionsList[i].operator) {
                            case "=":
                                model.eqTrueScore = parseFloat(conditionsList[i].value);
                                break;
                            case ">":
                                model.gtTrueScore = parseFloat(conditionsList[i].value);
                                break;
                            case "<":
                                model.ltTrueScore = parseFloat(conditionsList[i].value);
                                break;
                            case ">=":
                                model.gteTrueScore = parseFloat(conditionsList[i].value);
                                break;
                            case "<=":
                                model.lteTrueScore = parseFloat(conditionsList[i].value);
                                break;
                        }
                    } else if (conditionsList[i].criteria === "Age") {
                        switch (conditionsList[i].operator) {
                            case "=":
                                model.eqAge = parseFloat(conditionsList[i].value);
                                break;
                            case ">":
                                model.gtAge = parseFloat(conditionsList[i].value);
                                break;
                            case "<":
                                model.ltAge = parseFloat(conditionsList[i].value);
                                break;
                            case ">=":
                                model.gteAge = parseFloat(conditionsList[i].value);
                                break;
                            case "<=":
                                model.lteAge = parseFloat(conditionsList[i].value);
                                break;
                        }
                    } else if (conditionsList[i].criteria === "Interest Rate" && conditionsList[i].operator === "=") {
                        model.annualIntrestRate = conditionsList[i].value.toString() + "%";
                    }
                }
                output = {
                    data: model,
                    message: "genearted successfully",
                    success: true,
                };
            } else {
                output.message = "Please select atleast 1 Partner";
            }
        } catch (ex) {
            output.message = ex.message;
        }
        return output;
    };

    handleOfferQualified = async () => {
        this.setState({ showSpinner: true });
        let errorMessage = "";
        let successMessage = "";
        try {
            const getModel = await this.getNotificationRequestModel(false);
            if (getModel.success) {
                let response = {}
                if (this.state.isExcelView) {
                    let fileData = new FormData();
                    fileData.append('file', this.state?.data.selectedFile);
                    let queryParams = {
                        isFile: true,
                    }
                    response = await notificationServices.sendNotifications(fileData, queryParams);
                } else {
                    response = await notificationServices.sendNotifications(getModel.data);
                }
                if (response.success) {
                    const data = response.data;
                    let msg = "";
                    if (data.length > 0) {
                        this.setState({ showSpinner: false, errorMessage, showPopUp: true, popupMsg: data, successMessage });
                    } else {
                        this.setState({ showSpinner: false, errorMessage: "No record Found", successMessage });
                    }
                } else {
                    this.setState({ showSpinner: false, errorMessage: response.message, successMessage });
                }

            } else {
                this.setState({ showSpinner: false, errorMessage: getModel.message, successMessage });
            }
        } catch (ex) {
            this.setState({ showSpinner: false, errorMessage: ex.message, successMessage });
            console.log(ex);
        }
    };

    handleSendNotifications = async () => {
        this.setState({ showSpinner: true });
        let errorMessage = "";
        let successMessage = "";
        try {
            const getModel = await this.getNotificationRequestModel(true);
            if (getModel.success) {
                let response = {}
                if (this.state.isExcelView) {
                    let fileData = new FormData();
                    fileData.append('file', this.state?.data.selectedFile);
                    let queryParams = {
                        isFile: true,
                        isSendNotification: true
                    }
                    response = await notificationServices.sendNotifications(fileData, queryParams);
                } else {
                    response = await notificationServices.sendNotifications(getModel.data);
                }
                if (response.success) {
                    successMessage = response.message;
                    this.setState({ showSpinner: false, errorMessage, successMessage });
                } else {
                    errorMessage = response.message;
                    this.setState({ showSpinner: false, errorMessage, successMessage });
                }

            } else {
                this.setState({ showSpinner: false, errorMessage: getModel.message });
            }
        } catch (ex) {
            this.setState({ showSpinner: false, errorMessage: ex.message, successMessage });
            console.log(ex);
        }
    };

    handlePopupClose = () => {
        this.setState({ showPopUp: false });
    };

    toggleSwitch = () => {
        this.setState({ isExcelView: !this.state.isExcelView })
    }
}
export default NotificationModel;