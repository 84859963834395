import Joi from "joi-browser";
import auth from "../../services/common/authService";
import InputDataForm from "../../components/common/form";
import * as forgotPasswordFormService from "../../services/login/forgotPasswordFormService";
import * as analytics from "../../utils/analytics";
import leadInfoService from "../../services/partner/leadInfoService";
import SharedCache from "../../SharedCache";
import { toast } from "react-toastify";
import * as commonUtils from '../../utils/common-utils';
import { partnerIdList, environment } from "../../config.json";
class LoginFormModel extends InputDataForm {
  state = {
    data: { username: "", password: "", mobileNo: "", otp: "" },
    isMobileLogIn: false,
    isValidMobile: false,
    isValidOTP: false,
    errors: {},
    optional: {
      userId: "",
      password: "",
      confirmPassword: "",
      isPasswordChanged: false,
    },
    optionalErrors: {},
    showSpinner: false,
    showPasswordChangeModal: false,
    partnerId: null,
    loginPartner: "",
    info: {}
  };
  schema = {
    username: Joi.string().required().label("Username/Email"),
    password: Joi.string().required().label("Password"),
    mobileNo: Joi.number().allow("").label("Mobile No"),
    otp: Joi.any()
  };
  mobileShema = {
    username: Joi.string().allow("").label("Username/Email"),
    password: Joi.string().allow("").label("Password"),
    mobileNo: Joi.number().required().label("Mobile No"),
    otp: Joi.number().required().label("otp")
  }
  validate = () => {
    const options = { abortEarly: false };
    const currentSchema = this.state.isMobileLogIn ? this.mobileShema : this.schema;
    const { error } = Joi.validate(this.state.data, currentSchema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  async handleCustomErrors(errors) {
    return errors;
  }
  closeModal = () => {
    this.setState({
      showPasswordChangeModal: false,
      isPasswordChanged: false,
      optionalErrors: {},
    });
  };
  handleMobileLogin = () => {
    this.setState({
      isMobileLogIn: !this.state.isMobileLogIn,
    });
    let data = { ...this.state.data };
    if (this.state.isMobileLogIn) {
      data["username"] = "";
      data["password"] = "";
    }
    else {
      data["mobileNo"] = "";
      data["otp"] = "";
    }
    this.setState({ data });
  }
  async componentDidMount() {
    if (window.location.pathname.split("/")[1] !== "") {
      if (window.location.pathname.split("/")[1] !== "login") {
        this.setState({ loginPartner: window.location.pathname.split("/")[1] });
        sessionStorage.setItem("loginPartner", window.location.pathname.split("/")[1]);
      }
      else {
        sessionStorage.removeItem("loginPartner");
      }
    }
    else if (auth.getCurrentUser()) return (window.location = "/");
    const pId = this.props.match.params.partnerId;

    if (pId) {
      const userData = await leadInfoService.userData(pId, true);
      SharedCache.init(userData);
      SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
    }

    const partner = auth.getCurrentPartner();

    const partnerId = partner && partner._id ? partner._id : null;
    this.setState({ partnerId });

    analytics.track();

    if (SharedCache.app)
      SharedCache.app.refresh();
  }
  handleMobileChange = async ({ currentTarget: input }) => {
    const { data, errors, info } = this.state;
    if (input.value.length !== 10 || isNaN(input.value)) return;
    data["otp"]="";
    this.setState({ showSpinner: true , data});

    try {
      let partnerName = sessionStorage.getItem("loginPartner")
      let loginPartnerId = partnerIdList?.[environment]?.[partnerName]??null;
      const { success, message } = await auth.fetchOtpForLogin(data.mobileNo, loginPartnerId);
      if (success) {
        this.stopTimer();
        delete errors[input.name];
        delete errors["otp"];
        info[input.name] = "OTP sent successfully";
        this.setState({
          errors,
          data,
          isValidMobile: true,
          showSpinner: false,
        });
        this.startTimer();
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidMobile: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ data, errors, showSpinner: false });
    }
  };

  handleRegenerateOTP = async () => {
    const { data, errors, info } = this.state;
    this.setState({ showSpinner: true });
    try {
      let partnerName = sessionStorage.getItem("loginPartner")
      let loginPartnerId = partnerIdList?.[environment]?.[partnerName]??null;
      const { success, message } = await auth.fetchOtpForLogin(data.mobileNo, loginPartnerId);
      if (success) {
        this.stopTimer();
        delete errors["mobileNo"];
        delete errors["otp"];
        info["mobileNo"] = "OTP sent successfully";
        this.setState({
          errors,
          data,
          isValidMobile: true,
          showSpinner: false,
        });
        this.startTimer();
      } else {
        errors["mobileNo"] = message;
        delete info["mobileNo"];
        this.setState({
          errors,
          data,
          isValidMobile: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors["mobileNo"] = ex.response.data.message;
      delete info["mobileNo"];
      this.setState({ data, errors, showSpinner: false });
    }
  };


  doSubmit = async () => {
    let { data, optional, partnerId, isMobileLogIn } = this.state;
    try {
      this.setState({ showSpinner: true });
      let response;
      if (isMobileLogIn) {
        let partnerName = sessionStorage.getItem("loginPartner")
        let loginPartnerId = partnerIdList?.[environment]?.[partnerName]??null;
        response = await auth.validateOtpForLogin(data.mobileNo, data.otp, loginPartnerId);
      }
      else {
        let partnerName = sessionStorage.getItem("loginPartner")
        let loginPartnerId = partnerIdList?.[environment]?.[partnerName]??null;
        response = await auth.login(data.username, data.password, loginPartnerId ? loginPartnerId : partnerId);
      }
      if (response.success) {


        if (response.message != "password change required") {

          const search = window.location.search;
          const params = new URLSearchParams(search);
          const rd = params.get('rd');

          if (response.user.role.toLowerCase() == "user")
            window.location.href = "/borrow";
          if (response.user.role.toLowerCase() == "admin")
            window.location.href = "/portfolioSummary";
          if (response.user.role.toLowerCase() == "lending partner") {
            if (rd) window.location.href = `${rd}`;
            else window.location.href = "/loanModule";
          }
          if (response.user.role.toLowerCase() == "borrowing partner")
            window.location.href = "/portfolio";
          if (response.user.role.toLowerCase() == "collection agent")
            window.location.href = "/loan-collection";


        } else {
          data.password = "";

          // optional.userId = response.userId;
          optional.password = "";
          optional.confirmPassword = "";
          this.setState({ data, optional, showPasswordChangeModal: true }, () => {
            this.sendOtp()
          });
        }
      }
      else {
        const errors = { ...this.state.errors };
        if (isMobileLogIn) {
          errors["otp"] = response.message;
        }
        this.setState({ showSpinner: false, errors });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.globalError = ex.response.data;
        this.setState({ errors });
      }

      this.setState({ showSpinner: false });
    }
  };
  changePassword = async (e) => {
    e.preventDefault();
    let { optional, optionalErrors } = this.state;

    if (!optional.password) {
      optionalErrors.password = "Please enter password";
      this.setState({ optionalErrors });
      return;
    } else {
      delete optionalErrors["password"];
      this.setState({ optionalErrors });
    }

    if (optional.password.trim().length < 8) {

      optionalErrors.password = "Password must be atleast 8 characters long";
      this.setState({ optionalErrors });
      return;
    } else {
      delete optionalErrors["password"];
      this.setState({ optionalErrors });
    }

    if (!optionalErrors["password"] && optional.password) {
      if (commonUtils.checkPassword(optional.password)) delete optionalErrors["password"];
      else {
        optionalErrors["password"] = `Password should contain Minimum 8 Characters, Combination of Uppercase and Lowercase, Numbers and Special Characters`
        return;
      };
    } else {
      delete optionalErrors["password"];
      this.setState({ optionalErrors });
    }

    if (!optionalErrors["password"] && optional.password) {
      if (this.checkSpecialCharacters(optional.password))
        delete optionalErrors["password"];
      else {
        optionalErrors["password"] =
          "Password must contain a special character";
        this.setState({ optionalErrors });
        return;
      }
    }

    if (!optional.confirmPassword) {
      optionalErrors["confirmPassword"] = "Please re-enter password";
      this.setState({ optionalErrors });
      return;
    } else {
      delete optionalErrors["confirmPassword"];
      this.setState({ optionalErrors });
    }

    if (
      optional.password.trim().toLowerCase() !==
      optional.confirmPassword.trim().toLowerCase()
    ) {
      optionalErrors["confirmPassword"] = "Password do not match";
      this.setState({ optionalErrors });
      return;
    } else {
      delete optionalErrors["confirmPassword"];
      this.setState({ optionalErrors });
    }

    if (!this.state.data.otp) {
      toast.error("Please enter otp")
      return
    }

    this.setState({ showSpinner: true });

    try {
      const {
        success,
        message,
      } = await forgotPasswordFormService.changePassword(
        optional.userId,
        optional.password.trim(),
        this.state.data.otp,
        this.state.data.username
      );

      if (success) {
        optionalErrors = {};
        optional.password = "";
        optional.confirmPassword = "";
        optional.isPasswordChanged = true;
        this.setState({
          showSpinner: false,
          optionalErrors,
          optional,
        });
      } else {
        optionalErrors["globalError"] = message;
        this.setState({ optionalErrors, showSpinner: false });
      }
    } catch (ex) {
      let optionalErrors = { ...this.state.optionalErrors };
      optionalErrors.globalError = ex.response.data.message;
      this.setState({ optionalErrors, showSpinner: false });
    }
  };

  sendOtp = async () => {
    const { data, errors, info } = this.state;

    this.setState({ showModelLoading: true })
    try {
      const {
        data: userId,
        success,
        message,
      } = await forgotPasswordFormService.generateOTPFromUsername(
        data.username,
        "Change Password"
      );

      if (success) {
        info["otp"] = message;
        this.setState({
          info,
          errors,
          isValidOTP: false,
          buttonText: "Validate OTP",
          showModelLoading: false
        });
        this.startTimer();
      } else {
        this.setState({
          errors,
          isValidUserName: false,
          isValidOTP: false,
          showModelLoading: false
        });
      }
    } catch (ex) {
      this.setState({
        errors,
        info,
        isValidUserName: false,
        isValidOTP: false,
        showModelLoading: false
      });
    }
  };
}



export default LoginFormModel;
