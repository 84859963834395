import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import RollbackTableModel from "../../models/rollback/rollbackTableModel";
import { Form, Col, Button, Modal, Table } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';

class RollbackTable extends RollbackTableModel {
    getPagedData = () => {
        const { data } = this.state;
        return { totalCount: data.loans.length, data: data.loans };
    };
    handleSelectALL = (e) => {
        var value = false;

        if (e.target.checked) {
            value = true;
        }

        this.setState({ selectAll: value, successMsg: "" });

        var checkboxes = document.querySelectorAll('input[name^="chkLoans"]');
        for (var checkbox of checkboxes) {

            checkbox.checked = value;
            const chkLoan = checkbox.name.split("_");
            const loanId = chkLoan[1];
            const selectedLoans = this.state.selectedLoans;
            if (!value && selectedLoans.includes(loanId, 0)) {
                const index = selectedLoans.indexOf(loanId);
                if (index != -1) {
                    selectedLoans.splice(index, 1);
                }
            } else if (value && !selectedLoans.includes(loanId, 0)) {
                selectedLoans.push(loanId);
            }
            this.setState({ selectedLoans });
        }
    }
    renderCheckBox = (loan) => {

        if (loan.status.toLowerCase() === "initiated fund transfer") {
            return (<Form.Check
                type="checkbox"
                name={"chkLoans_" + loan._id}
                label=""
                onChange={this.handleChecked(loan._id)}
                color="red"
            />)
        }

        return (<></>);
    }
    render() {
        const {
            pageSize,
            currentPage,
            data,
            showSpinner,
            partners,
            evaluatorRole,
            successMsg,
            errors,
            selectAll
        } = this.state;

        const { totalCount, data: loans } = this.getPagedData();

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>Loan Rollback</h2>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="mobileNo">
                            <Form.Label>Mobile No</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobileNo"
                                value={data["mobileNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={data["name"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}
                        <Form.Group as={Col} md="4" controlId="loanNo">
                            <Form.Label>Loan No.</Form.Label>
                            <Form.Control
                                type="text"
                                name="loanNo"
                                value={data["loanNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}
                    </Form.Row>
                    <Form.Row>

                        <Form.Group as={Col} md="4" controlId="merchantCode">
                            <Form.Label>Merchant Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="merchantCode"
                                value={data["merchantCode"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="panNo">
                            <Form.Label>PAN No</Form.Label>
                            <Form.Control
                                type="text"
                                name="panNo"
                                value={data["panNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="partner">
                            <Form.Label>Partner</Form.Label>
                            <Form.Control
                                as="select"
                                name="partner"
                                value={data["partner"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="all" value="all">
                                    ALL
                                </option>
                                {partners && partners.map((partner) => (
                                    <option key={partner.partnerName} value={partner.partnerName}>
                                        {partner.displayName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12">
                            <Button
                                className="ff-button"
                                type="submit"
                            >
                                Apply Filters
                            </Button>
                            <>
                                <Button
                                    className="ff-button"
                                    type="button"
                                    style={{ marginLeft: 10 }}
                                    onClick={() => this.handlePopupOpen()}
                                >
                                    Rollback
                                </Button>
                            </>

                        </Form.Group>
                    </Form.Row>
                </Form>
                <br />
                {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
                {successMsg && <div className="alert alert-success">{successMsg}</div>}
                <br />
                <div style={{ width: '100% !important' }}>
                    <Table>
                        <thead>
                            <tr>
                                <td style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderTop: "1px solid" }}>
                                    <Form.Check
                                        type="checkbox"
                                        label=""
                                        onChange={this.handleSelectALL}
                                        color="red"
                                        checked={selectAll ? "checked" : ""}
                                    />
                                </td>
                                <td style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan No.</b></td>
                                <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Date</b></td>
                                <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Name</b></td>
                                <td style={{ minWidCell: 100, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Amount</b></td>
                                <td style={{ minWidCell: 80, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>ROI</b></td>
                                <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Maturity</b></td>
                                <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Frequency</b></td>
                                <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan Status</b></td>
                                <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Merchant Code</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            {loans &&
                                loans.map((loan, i) => (
                                    <tr key={loan._id}>
                                        <td style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderLeft: "1px solid" }}>
                                            {this.renderCheckBox(loan)}
                                        </td>
                                        <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{loan.loanNo}</td>
                                        <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.updatedOn && moment(loan.updatedOn).format("DD/MM/yyyy")}</td>
                                        <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.userInfo.userDetails.firstName ? loan.userInfo.userDetails.firstName : ""} {loan.userInfo.userDetails.lastName ? loan.userInfo.userDetails.lastName : ""}</td>
                                        <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </td>
                                        <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {" "}
                                            {loan.interestRate}
                                            {loan.interestRate && (
                                                <i className="fa fa-percent" aria-hidden="true"></i>
                                            )}
                                        </td>
                                        <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {" "}
                                            {loan.maturity &&
                                                loan.repaymentFrequency === "Monthly" &&
                                                `${loan.maturity} months`}
                                            {loan.maturity &&
                                                loan.repaymentFrequency === "Daily" &&
                                                `${loan.maturity} days`}
                                            {loan.maturity &&
                                                loan.repaymentFrequency === "Weekly" &&
                                                `${loan.maturity} weeks`}
                                            {loan.maturity &&
                                                loan.repaymentFrequency === "Forthnightly" &&
                                                `${loan.maturity} forthnights`}
                                            {loan.maturity &&
                                                loan.repaymentFrequency === "Adhoc" &&
                                                `${loan.tenure} days`}
                                        </td>
                                        <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.repaymentFrequency}</td>
                                        <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {loan.status}
                                        </td>
                                        <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.merchantCode}</td>
                                    </tr>
                                ))}
                            {loans.length === 0 && (
                                <tr>
                                    <td colSpan={10} style={{ border: "1px solid" }}>No record found</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div >
                <div style={{ width: "100%", overflowX: "scroll" }}>

                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPagePrevious={this.onPagePrevious}
                        onPageNext={this.onPageNext}
                    />
                </div>
                <Modal show={this.state.showPopUp} onHide={this.handlePopupClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        These loans will be rolled back to Loan Contract Accepted State.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="ff-button" onClick={this.handlePopupOk}>
                            Yes
                        </Button>
                        <Button variant="secondary" className="ff-button" onClick={this.handlePopupClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >

        );
    }
}

export default RollbackTable;
