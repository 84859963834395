import React from "react";
import { Table } from "react-bootstrap";
import * as analytics from "../../utils/analytics";

function ContactUs(props) {

  analytics.track();

  return (
    <React.Fragment>
      <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
        Contact Us
      </h2>
      <p>Can’t find what you are looking for, please get in touch: </p>

      <p>
        <b>General Support:</b> Email{" "}
        <span>
          <u>support@Fundfina.com</u>
        </span>
        ; WhatsApp: +918169570973
      </p>
      <p>
        Investor: For press or investments, contact{" "}
        <span>
          <u>investor@Fundfina.com</u>
        </span>
      </p>
      <p>
        Partner: If you would like to discuss lending partnerships, contact{" "}
        <span>
          <u>service@Fundfina.com</u>
        </span>
      </p>

      <Table bordered responsive>
        <thead>
          <tr>
            <th><b>Mailing Address</b></th>
            <th><b>Registered Office</b></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ maxWidth: 200 }}>
              WorkWise Solutions, Level-16, D - Wing, Trade World, Kamala Mills, Senapati Bapat Marg,  Lower Parel, Mumbai - 400013
            </td>
            <td style={{ maxWidth: 200 }}>HD 16, City Centre, Bokaro Steel City, Jharkhand 827004</td>
          </tr>
        </tbody>
      </Table>

      <p>
        <b>Grievances and Complaints:</b>{" "}
      </p>
      <p>If you want to make a complaint about Fundfina:</p>
      <ul>
        <li>
          you can e-mail complaints@Fundfina.com , with brief details of your
          complaint and your Fundfina UserID. Our customer service staff will
          acknowledge your complaint by e-mail within three working days{" "}
        </li>
        <li>
          They will then investigate and send you an initial response, having
          had access to an officer with the authority to settle the complaint
          (including, where appropriate, an offer of redress)
        </li>
        <li>
          We would like resolve most complaints within five business days after
          acknowledging receipt of complaint
        </li>
        <li>
          If the complaint is not resolved within the timeframe, we will send
          you a copy of this complaints handling procedure with a status update
          on your complaint on regular basis till closure
        </li>
        <li>
          If you are not satisfied by our response, email{" "}
          <span>
            <u>coo@Fundfina.com</u>
          </span>
          . Senior Customer Services Manager will respond by e-mail within five
          business days. If not resolved within those time frame, it will be
          automatically escalated to the Chief Operating Officer
        </li>
      </ul>
      <p>
        Grievance Redressal Officer <br />
        Name: Nishant Bhaskar <br />
        Mobile: +918169570973 <br />
        Email: <a href="mailto:nishant@fundfina.com">nishant@fundfina.com</a>
      </p>
      <p>
        If the complaint/dispute is not redressed within a period of one month,
        the participants may appeal to CEPD of the Bank. <br />
        <a
          href="https://m.rbi.org.in/commonman/English/Scripts/Departments.aspx#CEPD"
          target="_blank"
        >
          https://m.rbi.org.in/commonman/English/Scripts/Departments.aspx#CEPD
        </a>
      </p>
      <Table bordered responsive>
        <tbody>
          <tr>
            <td style={{ maxWidth: 200 }}>
              Chief General Manager <br />
              Consumer Education and Protection Department
              <br />
              Reserve Bank of India 1st Floor,
              <br /> Amar Building Sir P.M. Road,
              <br />
              Mumbai-400 001.
              <br />
            </td>
            <td>Tel: 22630483</td>
            <td>FAX: 22631744</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default ContactUs;
