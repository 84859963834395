import React from "react";
import SpinnerComponent from "../../components/common/spinner";
import { Form, Col, Button, Table, Modal, FormCheck } from "react-bootstrap";
import moment from "moment";
import ReactExport from "react-export-excel";
import NumberFormat from 'react-number-format';
import EmiListModel from "../../models/common/emiListModel";
import DatePicker from "react-datepicker2";

class EmiList extends EmiListModel {
    render() {
        const {
            showSpinner,
            repaymentList,
            emiSummary,
            isAdjustedPrinciple,
            loanNo
        } = this.state;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <div className="col-md-12">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="adminStatus">
                                <span className="fundfinaColor">Loan No:{" "}</span>
                                <span style={{ color: "black" }}>
                                    {loanNo}
                                </span>{" "}
                            </Form.Group>
                        </Form.Row>
                    </Form></div>
                <div className="col-md-12">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="adminStatus">
                                <span className="fundfinaColor">Pending Emi's:{" "}</span>
                                <span style={{ color: "black" }}>
                                    {emiSummary.pendingEmiCount}
                                </span>{" "}
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="adminStatus">
                                <span className="fundfinaColor">Pending Principal Amount:{" "}</span>
                                <span style={{ color: "black" }}>
                                    <NumberFormat value={emiSummary.pendingPricipalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </span>{" "}
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="adminStatus">
                                <span className="fundfinaColor">Pending Interest Amount:{" "}</span>
                                <span style={{ color: "black" }}>
                                    <NumberFormat value={emiSummary.pendingInterestAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </span>{" "}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="adminStatus">
                                <span className="fundfinaColor">Pending Emi Amount:{" "}</span>
                                <span style={{ color: "black" }}>
                                    <NumberFormat value={emiSummary.pendingEmiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </span>{" "}
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="adminStatus">
                                <span className="fundfinaColor">Pending Other Charges:{" "}</span>
                                <span style={{ color: "black" }}>
                                    <NumberFormat value={emiSummary.pendingOtherCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </span>{" "}
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="adminStatus">
                                <span className="fundfinaColor">Pending Total Amount:{" "}</span>
                                <span style={{ color: "black" }}>
                                    <NumberFormat value={emiSummary.pendingTotalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </span>{" "}
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </div>

                <div className="row">
                    <div className="col-md-10">
                        <ExcelFile
                            element={
                                <Button
                                    className="ff-button"
                                    type="button"
                                    style={{ marginTop: 10 }}
                                >
                                    Export to Excel
                                </Button>
                            }
                        >
                            <ExcelSheet data={repaymentList} name="RepaymentData">
                                <ExcelColumn
                                    label="EMI No."
                                    value="emiNumber"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="EMI Amount"
                                    value="rePaymentAmount"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Other Charges"
                                    value="otherCharges"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Total Amount"
                                    value="totalEmiAmount"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Due On"
                                    value={(col) =>
                                        col.rePaymentDate
                                            ? moment(col.rePaymentDate).format("DD/MM/yyyy")
                                            : ""
                                    }
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Status"
                                    value="rePaymentStatus"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Paid On"
                                    value={(col) =>
                                        col.rePaymentReceivedDate
                                            ? moment(col.rePaymentReceivedDate).format(
                                                "DD/MM/yyyy"
                                            )
                                            : ""
                                    }
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Interest Recovered"
                                    value="interestAmount"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Principal Outstanding"
                                    value="principalOutstanding"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Principal Recovered"
                                    value="principalRecovered"
                                ></ExcelColumn>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>
                <br />
                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th>EMI #</th>
                            <th>EMI Amount</th>
                            <th>Other Charges</th>
                            <th>Total Amount</th>
                            <th>Due On</th>
                            <th>Status</th>
                            <th>Paid On</th>
                            <th>Interest Recovered</th>
                            <th>Principal Outstanding</th>
                            <th>Principal Recovered</th>
                            {this.props.showPaymentOptions ?
                                <>
                                    <th>Auto Collections</th>
                                    <th>Collected On</th>
                                    <th>Amount Collected</th>
                                    <th>eNach</th>
                                    <th>Mark</th>
                                </>
                                : ""}
                        </tr>
                    </thead>
                    <tbody>
                        {repaymentList &&
                            repaymentList.map((emi, i) => (
                                <tr key={`${emi._id}-${i}`}>
                                    <td>{emi.emiNumber}</td>
                                    <td style={{ textAlign: "right" }}>
                                        <NumberFormat value={emi.rePaymentAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>
                                    <td>
                                        <NumberFormat value={emi.otherCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>
                                    <td>
                                        <NumberFormat value={emi.totalEmiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>
                                    <td>{moment(emi.rePaymentDate).format("DD/MM/yyyy")}</td>
                                    <td style={{ textAlign: "right" }}>
                                        {emi.rePaymentStatus}
                                    </td>
                                    <td>
                                        {emi.rePaymentReceivedDate &&
                                            moment(emi.rePaymentReceivedDate).format(
                                                "DD/MM/yyyy"
                                            )}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <NumberFormat value={emi.interestAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>
                                    {repaymentList.length - 1 === i && isAdjustedPrinciple &&
                                        <td style={{ textAlign: "right" }}>
                                            <NumberFormat value={emi.principalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /><span>*</span>                                       </td>
                                    }
                                    {repaymentList.length - 1 === i && !isAdjustedPrinciple &&
                                        <td style={{ textAlign: "right" }}>
                                            <NumberFormat value={emi.principalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /><span></span>                                       </td>
                                    }
                                    {repaymentList.length - 1 !== i &&
                                        <td style={{ textAlign: "right" }}>
                                            <NumberFormat value={emi.principalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />                                   </td>
                                    }

                                    <td style={{ textAlign: "right" }}>
                                        {" "}
                                        <NumberFormat value={emi.principalRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>

                                    {this.props.showPaymentOptions && (emi.rePaymentStatus == 'Pending' || emi.rePaymentStatus == 'Write Off') && emi.showPaymentOptions ? <>
                                        <td>
                                            <Form.Group controlId={i}>
                                                <FormCheck
                                                    id={"holdCollection_" + emi.emiNumber}
                                                    type="switch"
                                                    checked={emi.holdWalletCollection}
                                                    onChange={(e) => this.handleHoldCollection(e, i)}
                                                    label="Hold"
                                                    name={"holdCollection_" + emi.emiNumber}
                                                    disabled={emi.primaryCollectionType === "eNach" ? true : false}
                                                />
                                            </Form.Group>
                                        </td>

                                        <td>
                                            <DatePicker
                                                name={"collectedOn_" + emi.emiId}
                                                id={"collectedOn_" + emi.emiId}
                                                value={emi.collectedOn}
                                                max={moment()}
                                                inputFormat="DD/MM/YYYY"
                                                isGregorian={true}
                                                className="form-control"
                                                inputReadOnly={true}
                                                showTodayButton={false}
                                                timePicker={false}
                                                onChange={(date) => this.handleDateChange(date, i)}
                                                disabled={!emi.enableCollectionCheckbox}
                                            />
                                        </td>

                                        <td>
                                            <Form>
                                                <Form.Group controlId={i}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label={<React.Fragment>
                                                            Collected
                                                        </React.Fragment>}
                                                        feedback="test"
                                                        checked={emi.amountCollected}
                                                        onChange={(e) => this.handleAmountChange(e, i)}
                                                        color="red"
                                                        name={"amountCollected_" + emi.emiId}
                                                        disabled={!emi.enableCollectionCheckbox}
                                                    />{" "}
                                                </Form.Group>
                                            </Form>
                                        </td>

                                        <td>
                                            {emi.primaryCollectionType === "eNach" && emi.isMandateRegistered && (
                                                <React.Fragment>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <Form.Control
                                                                as="select"
                                                                value={emi.eNachStatus}
                                                                onChange={this.handleEmiStatusChange}
                                                                name={"eNachSelect_" + emi.emiId}
                                                                style={{ maxWidth: 150, marginRight: 20 }}
                                                            >
                                                                {" "}
                                                                <option key="select" value="">
                                                                    ---Select---
                                                                </option>
                                                                <option key="resend" value="Send" disabled={emi.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                                                                    Resend
                                                                </option>
                                                                <option key="mandate" value="Mandate">
                                                                    New Mandate
                                                                </option>
                                                                <option key="bankDetails" value="BankDetails">
                                                                    Bank Details
                                                                </option>
                                                                {/* <option key="hold" value="Hold" disabled={emi.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                                                                    Hold
                                                                </option> */}

                                                            </Form.Control>
                                                            <Button
                                                                className="ff-button"
                                                                type="button"
                                                                name={"btnNachSelect_" + emi.emiId}
                                                                onClick={(e) => this.eNachAction(e, i)}
                                                                style={{ minWidth: 50 }}
                                                                disabled={emi.nachBtnDisabled}
                                                            >
                                                                Go
                                                            </Button></div></div></React.Fragment>)}
                                        </td>
                                        <td>
                                            <Button
                                                className="ff-button"
                                                type="button"
                                                name={"btnPaid_" + emi.emiId}
                                                onClick={(e) => this.handleMarkAsPaid(e, i)}
                                                disabled={emi.disabled}
                                            >
                                                Paid
                                            </Button>
                                        </td>
                                    </> :
                                        this.props.showPaymentOptions ? <>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </> : <></>}
                                </tr>
                            ))}
                        {!repaymentList ||
                            (repaymentList.length === 0 && (
                                <tr key="no">
                                    <td colSpan="10">No record found</td>
                                </tr>
                            ))}
                    </tbody>
                </Table>{" "}
                {isAdjustedPrinciple && <span>* Adjusted Based On Rounding Off Calculation</span>}
                <Modal
                    show={this.state.showErrorModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <b>{this.state.errorHeader}</b>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} className="ff-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >


        );
    }
}

export default EmiList;
