import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getCollectionsOutcomeList(model) {
  const { data } = await http.post(`${apiEndpoint}/collectionOutcomeList`, {
    partner: model.partner,
    fromDate: model.fromDate,
    toDate: model.toDate
  });
  return data;
}

export async function getCollectionsOutcomeDetailsList(model) {
  const { data } = await http.post(`${apiEndpoint}/collectionOutcomeDetailsList`, {
    partner: model.partner,
    fromDate: model.fromDate,
    toDate: model.toDate,
    outcome : model.outcome,
    page : model.page,
    isAllActionList : model.isAllActionList
  });
  return data;
}