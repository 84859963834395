import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as analytics from "../../utils/analytics";
import * as kycFormService from "../../services/borrow/kycFormService";

class DigilockerModel extends InputDataForm {
    state = {
        loanId: "",
        showSpinner: false,
        isSubmitted: false,
        redirect: false,
        errors: {
        }
    };
    async componentDidMount() {
        analytics.track();

        if (!auth.getCurrentUser()) return (window.location = "/login");

        if (this.props.match.params.id) {
            this.setState({ loanId: this.props.match.params.id, isForceDigilocker: this.props.match.params.force === "y" });
        }
        else
            return (window.location = "/");
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        let { loanId, errors } = this.state;

        this.setState({ showSpinner: true, isSubmitted: true });

        try {

            const { data: response } = await kycFormService.sendDigilockerRequest(loanId);

            if (response.success) {
                if (response.data)
                    window.location = response.data;
                else {
                    errors.globalError = response.message;
                    this.setState({ errors, showSpinner: false, isSubmitted: false });
                }
            }
        } catch (ex) {
            errors.globalError = ex.response.data.message;
            this.setState({ errors, showSpinner: false, isSubmitted: false });
        }
    }
    redirectBack() {
        this.setState({ redirect: true })
    }
}

export default DigilockerModel;
