import InputDataForm from "../../components/common/form";
import _ from "lodash";

class MonthYearPickerModel extends InputDataForm {
    state = {
        monthArray : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"],
        year  : new Date().getFullYear(),
        showPopOver :false
    };
    
    onPressLeft =()=>{
        this.setState({year : this.state.year - 1})
    }

    togglePopOver =()=>{

        if(this.props.year && !this.state.showPopOver)
            this.state.year = this.props.year

        this.setState({showPopOver : !this.state.showPopOver})
    }

    onPressRight =()=>{
        this.setState({year : this.state.year + 1})
    }
}

export default MonthYearPickerModel;
