import React from "react";
import MonthYearPickerModel from "../../models/common/month-picker";

class MonthYearPicker extends MonthYearPickerModel {

    renderPopOver =()=>{
        return <div className="month-card">
            
            <div className="row pl-3 pr-3">
                <div className="col">
                    <i className="fa fa-angle-left cursor-pointer" onClick={this.onPressLeft}></i>
                </div>
                <div className="col text-center">
                    {this.state.year}
                </div>
                <div className="col text-right">
                    <i className="fa fa-angle-right cursor-pointer" onClick={this.onPressRight}></i>
                </div>
            </div>
             <div className="row pl-3 pr-3 pt-2">
            {this.state.monthArray.map((month , i) => (
                <div key={i} className={`col-4 month-array text-center p-2 cursor-pointer ${this.props.month == month ? "active-month" : ""}`}
                onClick={()=> {this.togglePopOver(); this.props.onSelect && this.props.onSelect(month , this.state.year)}}>{month}</div>
              ))}
              </div>
        </div>
    }

    render() {
        const {
        } = this.state;
        return (
            <>
            <div className="month-picker-container">
                <input type={"text"} className='form-control' value={(this.props.month || "") + " " +(this.props.year || "")} onClick={()=> this.togglePopOver()}>
                </input>
                {this.state.showPopOver ? this.renderPopOver() : ""}
                {this.props.month && this.props.year ? <i className="remove-icon-month-year fa fa-times" onClick={()=> this.props.clear && this.props.clear()}></i> : ""}
            </div>
            </>
         );
    }
}

export default MonthYearPicker;
