import React from "react";
import { Form, Col, Button, Table, Modal } from "react-bootstrap";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Tabs, Tab } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import PayInPayOutInfoModel from "../../models/payInPayOut/payInPayOutInfoModel";
import moment from "moment";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/pagination";
import PaginationV2 from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import ReactExport from "react-export-excel";
import TrancheInfo from "../trancheCRUD/trancheInfo";

class PayInPayOutInfo extends PayInPayOutInfoModel {
  getPagedData = () => {
    const { pageSize, currentPage, list } = this.state;

    const filteredList = paginate(list, currentPage, pageSize);

    return { totalCount: list.length, data: filteredList };
  };

  render() {
    const { data, showSpinner, errors, isSubmitted, pageSize, currentPage, successMsg, list: list1, trancheList, currenTrancheDetailsPage, activeTab, isTrancheRecordEdit, editTrancheRecord, trancheListPageSize,isFormEdit } = this.state;

    const { totalCount, data: list } = this.getPagedData();


    let i = 0;

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        {!isTrancheRecordEdit ? <React.Fragment>
          <h2
            className="fundfinaColor"
            style={{ textDecoration: "underline", textAlign: "center" }}
          >
            Pay-In
          </h2>{" "}
          <br />
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="name">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={data["name"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  disabled={true}
                />
                {errors["name"] && (
                  <div className="alert alert-danger">{errors["name"]}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="utrNo">
                <Form.Label>UTR No*</Form.Label>
                <Form.Control
                  type="text"
                  name="utrNo"
                  value={data["utrNo"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
                {errors["utrNo"] && (
                  <div className="alert alert-danger">{errors["utrNo"]}</div>
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="amount">
                <Form.Label>Amount*</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={data["amount"]}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPressDecimal}
                  autoComplete="off"
                />
                {errors["amount"] && (
                  <div className="alert alert-danger">{errors["amount"]}</div>
                )}
              </Form.Group>
              {/* <Form.Group as={Col} md="4" controlId="paymentMode">
              <Form.Label>Payment Mode*</Form.Label>
              <Form.Control
                as="select"
                name="paymentMode"
                value={data["paymentMode"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  -Select-
                </option>
                <option key="payin" value="Payin">
                  Pay-In
                </option>
                <option key="payout" value="Payout">
                  Pay-Out
                </option>
              </Form.Control>
              {errors["paymentMode"] && (
                <div className="alert alert-danger">
                  {errors["paymentMode"]}
                </div>
              )}
            </Form.Group> */}
            </Form.Row>
            <Form.Row>

              <Form.Group as={Col} md="4" controlId="transactionDate">
                <Form.Label>Transaction Date*</Form.Label>
                <DatePicker
                  name="transactionDate"
                  id="transactionDate"
                  value={data["transactionDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleTransactionDateChange(value)}
                  placeholder="Enter Transaction Date"
                />
                {errors["transactionDate"] && (
                  <div className="alert alert-danger">
                    {errors["transactionDate"]}
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="depositDate">
                <Form.Label>Deposit Date*</Form.Label>
                <DatePicker
                  name="depositDate"
                  id="depositDate"
                  value={data["depositDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleDepositDateChange(value)}
                  placeholder="Enter Deposit Date"
                />
                {errors["depositDate"] && (
                  <div className="alert alert-danger">
                    {errors["depositDate"]}
                  </div>
                )}
              </Form.Group>
            </Form.Row>{" "}
            <Button
              className="ff-button"
              type="submit"
              disabled={isSubmitted}
              style={{ marginRight: 10 }}
            >
              Submit
            </Button>
            {"  "}
            <Button className="ff-button" type="button" onClick={this.goBack}>
              Back
            </Button>{" "}
            <br />
          </Form>{" "}
          <br />{" "}
          {errors["globalError"] && (
            <div className="alert alert-danger">{errors["globalError"]}</div>
          )}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}
          <br /> <div className="row">
            <div className="col-md-12">
              <ExcelFile
                element={
                  <Button
                    className="ff-button"
                    type="button"
                    style={{ marginTop: 10 }}
                  >
                    Export to Excel
                  </Button>
                }
              >
                <ExcelSheet data={list1} name="RepaymentData">
                  <ExcelColumn
                    label="UTR No."
                    value="utrNo"
                  ></ExcelColumn>
                  <ExcelColumn
                    label="Type"
                    value="type"
                  ></ExcelColumn>
                  <ExcelColumn
                    label="Amount"
                    value="amount"
                  ></ExcelColumn>
                  <ExcelColumn
                    label="Transaction Date"
                    value={(col) =>
                      col.transactionDate
                        ? moment(col.transactionDate).format("DD/MM/yyyy")
                        : ""
                    }
                  ></ExcelColumn>

                  <ExcelColumn
                    label="Deposit Date"
                    value={(col) =>
                      col.depositDate
                        ? moment(col.depositDate).format(
                          "DD/MM/yyyy"
                        )
                        : ""
                    }
                  ></ExcelColumn>
                </ExcelSheet>
              </ExcelFile></div></div> <br />

          <>
            <Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.handleSelect} className="tab-fixed-top">
              <Tab eventKey="payInTab" title="Pay In">
                <br />
                <h2
                  className="fundfinaColor"
                  style={{ textDecoration: "underline", textAlign: "center" }}
                >
                  Pay In Details
                </h2>{" "}
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>UTR No.</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Transaction Date</th>
                      <th>Deposit Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list &&
                      list.map((item) => {
                        i += 1;
                        return (

                          <tr key={item._id}>
                            <td>{item.utrNo}</td>
                            <td>{item.type}</td>
                            <td>
                              {" "}
                              {item.amount && (
                                <i className="fa fa-inr" aria-hidden="true"></i>
                              )}
                              {item.amount}
                            </td>
                            <td>{moment(item.transactionDate).format("DD/MM/yyyy")}</td>
                            <td>{moment(item.depositDate).format("DD/MM/yyyy")}</td>
                            <td>
                              <a href="#" onClick={() => this.handleRemove(item._id)}>
                                Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    {!list && (
                      <tr key="no">
                        <td colSpan="10">No record found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div>
                  <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                    onPagePrevious={this.handlePagePrevious}
                    onPageNext={this.handlePageNext}
                  />
                </div>
              </Tab>
              <Tab eventKey="trancheDetailsTab" title="Tranche Details">
                <br />
                <div style={{ width: "100%", position: "relative" }}>
                  <h2
                    className="fundfinaColor"
                    style={{ textDecoration: "underline", textAlign: "center" }}
                  >
                    Tranche Details
                  </h2>
                </div>
                <div style={{ width: '100%' }}>
                  <StickyTable stickyHeaderCount={0} leftStickyColumnCount={5}>
                    <Row>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Identifier</b></Cell>
                      <Cell style={{ minWidCell: 100, borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Start Date</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>End Date</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Interest Rate</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Tenure</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Deployment</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Type</b></Cell>
                      <Cell style={{ minWidCell: 100,borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>FLDG Amount</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Transaction Date</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>UTR No.</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Currency Type</b></Cell>
                      <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reverse Residual In Days</b></Cell>
                    </Row>
                    {trancheList &&
                      trancheList.map((tranche, i) => (
                        <Row key={i}>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}><a href="#" onClick={(e) => this.handleEditTrancheRecord(e, tranche)}>{tranche.identifier}</a></Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.amount}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.startDate?moment(tranche.startDate).format("DD/MM/yyyy"):""}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.endDate?moment(tranche.endDate).format("DD/MM/yyyy"):""}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche["isActive"] ? "Active" : "Inactive"}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                            {" "}
                            {tranche.interestRate?tranche.interestRate:""}
                            {tranche.interestRate && (
                              <i className="fa fa-percent" aria-hidden="true"></i>
                            )}
                          </Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.tenure?tranche.tenure.value:""} {tranche.tenure?tranche.tenure.valueType:""}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.deploymentType?tranche.deploymentType:""}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.type?tranche.type:""}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.fldg.amount}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{moment(tranche.fldg.transactionDate).format("DD/MM/yyyy")}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.fldg["utrNumber"]}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.currencyType}</Cell>
                          <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{tranche.reverseResidualInDays?tranche.reverseResidualInDays:""}</Cell>
                        </Row>
                      ))}
                    {!trancheList && (
                      <Row>
                        <Cell Col={13}>No record found</Cell>
                      </Row>
                    )}

                  </StickyTable>
                </div >
                <div>
                  <PaginationV2
                    pageSize={trancheListPageSize}
                    currentPage={currenTrancheDetailsPage}
                    onPageChange={this.handleTrancheDetailsPageChange}
                    onPagePrevious={this.handleTrancheDetailsPagePrevious}
                    onPageNext={this.handleTrancheDetailsPageNext}
                  />
                </div>
              </Tab>
            </Tabs>
          </>
        </React.Fragment> : null
        }
        {
          isTrancheRecordEdit ? <React.Fragment>
            <TrancheInfo partnerId={data.partnerId} isFormEdit={isFormEdit} formData={editTrancheRecord} switchisTrancheRecordEdit={this.switchisTrancheRecordEdit} switchActiveTab={this.switchActiveTab} handleTrancheDetailsPageChange={this.handleTrancheDetailsPageChange} currenTrancheDetailsPage={currenTrancheDetailsPage} />
          </React.Fragment> : null
        }
        

      </React.Fragment >
    );
  }
}

export default PayInPayOutInfo;


