import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/";

export async function insertUpdateLoanDescription({
  loanId,
  additionalDescription,
  name
}) {
  const response = await http.put(
    apiEndpoint + `loan/insertUpdateLoanDescription/${loanId}`,
    {
      additionalDescription,
      name
    }
  );
  return response;
}

export async function getBankInformation(loanId) {
  const response = await http.get(
    apiEndpoint + `loan/getBankInformation/${loanId}`);

  return response;
}
