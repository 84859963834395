import InputDataForm from "../../components/common/form";
import * as reconService from "../../services/recon/reconService";
import Joi from "joi-browser";

class ReconcliationModal extends InputDataForm {
  state = {
    data: {
      transactions: [],
      utrNo: ""
    },
    showSpinner: false,
    date: "",
    lendingPartner: ""
  };
  schema = {
    transactions: Joi.any(),
    utrNo: Joi.any()
  };

  async getSettledInformationDatewise() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      let response = await reconService.getSettledInformationDatewise(data);
      data.transactions = response && response.data || [];
      this.setState({ data, showSpinner: false },()=>{
      });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async componentDidMount() {
    if (this.props.utrNo) {
      this.state.data.utrNo = this.props.utrNo
    }
    await this.getSettledInformationDatewise();

  };
}

export default ReconcliationModal;
