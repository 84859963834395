import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getTrancheList(model) {
    const data = await http.post(`${apiEndpoint}/getTrancheList`, {
        partnerId: model.partnerId,
        fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
        toDate: moment(model.toDate).format("DD/MM/yyyy"),
    })
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function saveTranch(model) {
    const { data } = await http.post(`${apiEndpoint}/saveTranche`, {
        trancheId: model.trancheId,
        amount: model.amount,
        fldgPer: model.fldgPer,
        isOpen: model.isOpen,
        transactionDate: moment(model.transactionDate).format("DD/MM/yyyy"),
        depositDate: moment(model.depositDate).format("DD/MM/yyyy"),
    });
    return data;
}

export async function getPartnerList(partnerType) {
    const data = await http.post(`${apiEndpoint}/getPartnerList`, {
        partnerType: partnerType
    })
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function getTrancheDetails(trancheId) {
    const data = await http.post(`${apiEndpoint}/getTrancheDetails`, {
        trancheId: trancheId
    })
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function getTrancheLoanDetails(trancheNo) {
    const data = await http.post(`${apiEndpoint}/getTrancheLoanDetails`, { trancheNo: trancheNo })
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function getTrancheMIS(model) {
    const data = await http.post(`${apiEndpoint}/getTrancheMIS`,
        {
            partnerId: model.partnerId,
            fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
            toDate: moment(model.toDate).format("DD/MM/yyyy"),
            status: model.status
        })
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}