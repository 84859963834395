import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import { moment } from "moment";
import momentJalaali from "moment-jalaali";

class DisbursalListModel extends InputDataForm {
    state = {
        data: {
            fromDate: momentJalaali().startOf('month'),
            toDate: momentJalaali(),
            partner: "all",
            status: "Pending",
            productId: "",
            loanNo: "",
            loanId: "",
            paymentMode: "",
            fileName: ""
        },
        showSpinner: false
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const user = auth.getCurrentUser();
        if (user.role !== "Admin") return (window.location = "/");
    }

    handleRedirect = async (dataModel) => {
        this.setState({ data: dataModel });
    }

    handleBack = async () => {
        const data = { ...this.state.data };
        data.loanId = "";
        this.setState({ data });
    }
}

export default DisbursalListModel;