// import GoogleAnalytics from "react-ga";
import GoogleAnalytics from "react-ga4";
import { analyticsTrackingCode } from '../config.json';
import auth from "../services/common/authService";

GoogleAnalytics.initialize(analyticsTrackingCode);

export function track() {
    const user = auth.getCurrentUser();

    // GoogleAnalytics.set({
    //     page: window.location.pathname
    // });

    if (user && user.userId)
        GoogleAnalytics.set({
            "userId": user.userId
        });

    GoogleAnalytics.send({ hitType: "pageview", page: window.location.pathname, title: "Page View" });
    // GoogleAnalytics.pageview(window.location.pathname);

    return;
}