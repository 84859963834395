import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi, { errors, schema } from "joi-browser";
import _ from "lodash";
import * as payOutService from "../../services/payOut/payOutService";
import moment from "moment";

class PayOutModel extends InputDataForm {
    state = {
        data: {
            fromDate: momentJalaali().subtract(1, "days"),
            // toDate: momentJalaali(),
        },
        showSpinner: false,
        errors: {},
        info: {},
        list: [],
        exportList: [],
        currentPage: 1,
        pageSize: 0,
    };

    schema = {
        fromDate: Joi.any().required(),
        // toDate: Joi.any().required()
    };

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();
        if (currentUser.role != "Admin") return (window.location = "/");
        else {
            await this.getPayOutList();
        }
    };

    async getPayOutList() {
        const data = { ...this.state.data };
        let exportList = { ...this.state.exportList };
        let list = { ...this.state.list };
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        exportList = [];

        data.page = this.state.currentPage

        try {
            errors["globalError"] = null;
            const payOutList = await payOutService.getPayOutList(data);
            console.log("payOutList", payOutList)
            if (payOutList) {
                if (payOutList.success) {
                    list = payOutList.data;
                    this.setState({ exportList, list, errors, successMsg: payOutList.message, pageSize: payOutList.totalPages });

                    if (payOutList.meta) {
                        let { fromDate, toDate } = payOutList.meta
                        let format = "DD/MM:hh.mm A"
                        let txt = `Date will be from ${moment(fromDate).locale('en').format(format)} to ${moment(toDate).locale('en').format(format)}`
                        this.setState({ dateFilterNoteText: txt, showDateFilterNote: true })
                    }
                }
                else {
                    list = [];
                    errors["globalError"] = payOutList.message;
                    this.setState({ exportList, list, errors });
                }

            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ exportList, errors, successMsg: null });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ exportList, errors, showSpinner: false, successMsg: null });
        }
    }


    onPageNext = () => {

        if (this.state.pageSize < this.state.currentPage)
            return

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getPayOutList()
        })
    }

    onPageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.getPayOutList()
        })
    }

    onPagePrevious = () => {

        if (this.state.currentPage <= 1)
            return

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {
            this.getPayOutList()
        })
    }


    handleRecordChange = async (e) => {
        const list = [...this.state.list];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.lenderId === recordFields[1] && x.depositDate === recordFields[2] && x.partnerCode === recordFields[3]);
        if (tranIndex >= 0) {
            list[tranIndex].utrNo = e.target.value;
            this.setState({ list });
        }
    }

    async handleCustomErrors(errors) {
        console.log(errors);
        return errors;
    };

    async handlePayOut(e) {
        const list = [...this.state.list];
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        try {
            errors["globalError"] = null;
            let recordFields = e.split('_');
            let tranIndex = list.findIndex(x => x.lenderId === recordFields[1] && x.depositDate === recordFields[2] && x.partnerCode === recordFields[3]);
            if (tranIndex >= 0) {
                if (list[tranIndex].utrNo.toString().trim() != "") {
                    let options = {
                        partnerId: list[tranIndex].partnerId,
                        utrNo: list[tranIndex].utrNo,
                        amount: list[tranIndex].amount,
                        depositDate: list[tranIndex].deposit_date,
                        borrowingPartnerId: list[tranIndex].borrowingPartnerId
                    }
                    // console.log(`lender id is ${list[tranIndex].lenderId}`);
                    // console.log(`utrNo id is ${list[tranIndex].utrNo}`);
                    // console.log(`amount id is ${list[tranIndex].amount}`);
                    // console.log(`depositDate is ${momentJalaali(list[tranIndex].deposit_date).format("DD/MM/yyyy")}`);
                    const savePayOutResponse = await payOutService.savePayOut(options);

                    if (savePayOutResponse) {
                        if (savePayOutResponse.success) {
                            this.setState({ showSpinner: false, successMsg: savePayOutResponse.message, errors });
                            await this.getPayOutList();
                        }
                        else {
                            errors["globalError"] = savePayOutResponse.message;
                            this.setState({ showSpinner: false, errors, successMsg: null });
                        }

                    } else {
                        errors["globalError"] = "Some Error Occurred";
                        this.setState({ showSpinner: false, errors, successMsg: null });
                    }
                } else {
                    errors["globalError"] = "Please enter UTR No";
                    this.refs["UTRNO_" + list[tranIndex].lenderId + "_" + list[tranIndex].depositDate + "_" + list[tranIndex].partnerCode].focus();
                    this.setState({ showSpinner: false, errors, successMsg: null });
                }
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null });
        }
    };

    handleExport = async () => {
        let exportList = { ...this.state.exportList };
        const data = { ...this.state.data };
        let errors = { ...this.state.errors };
        this.setState({ exportList: [], showSpinner: true });
        try {
            errors["globalError"] = null;

            const emisList = await payOutService.getEmisCollection(data);

            if (emisList) {
                if (emisList.success) {
                    exportList = emisList.data;
                    this.setState({ exportList, errors, successMsg: emisList.message });
                }
                else {
                    exportList = [];
                    errors["globalError"] = emisList.message;
                    this.setState({ exportList, errors });
                }

            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null });
        }
    }
    async doSubmit() {
        this.setState({ currentPage: 1 }, () => {
            this.getPayOutList();
        })
    };
}

export default PayOutModel;