import InputDataForm from "../../components/common/form";
import * as contractService from "../../services/contract/contractService";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as analytics from "../../utils/analytics";
import * as portfolioService from "../../services/portfolio/portfolioService";

class ViewScheduleModel extends InputDataForm {
  state = {
    staticData: {
      loanId: "",
      loanNo: "",
      amount: "",
      roi: "",
      tenure: "",
      gstCharges: "",
      stampingCharges: "",
      disbursalAmount: "",
      emiAmount: "",
      processingCharges: "",
      isAccepted: false,
      contractFile: "",
      lendingPartner: "",
      isSigned: false,
      emiDetails: [],
      emiSummary: {}
    },
    errors: {},
    info: {},
    numPages: null,
    pageNumber: 1,
    showSpinner: false,
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  async mapDataToState(loan) {
    let staticData = { ...this.state.staticData };

    //User Details
    if (loan.id) staticData.loanId = loan.id;
    if (loan.loanNo) staticData.loanNo = loan.loanNo;
    if (loan.amount) staticData.amount = loan.amount;
    if (loan.roi) staticData.roi = loan.roi;
    if (loan.tenure) staticData.tenure = loan.tenure;
    if (loan.gstCharges) staticData.gstCharges = loan.gstCharges;
    if (loan.stampingCharges) staticData.stampingCharges = loan.stampingCharges;
    if (loan.disbursalAmount) staticData.disbursalAmount = loan.disbursalAmount;
    if (loan.emiAmount) staticData.emiAmount = loan.emiAmount;
    if (loan.partnerName) staticData.lendingPartner = loan.partnerName;
    if (loan.isSigned) staticData.isSigned = loan.isSigned;

    const {
      data: repaymentData,
    } = await portfolioService.getRepaymentDetails(loan.id);

    if (repaymentData.emiDetails) staticData.emiDetails = repaymentData.emiDetails;

    if (repaymentData.summary) staticData.emiSummary = repaymentData.summary;

    if (loan.processingCharges)
      staticData.processingCharges = loan.processingCharges;
    if (loan.contractDetails) {
      if (loan.contractDetails.isAccepted)
        staticData.isAccepted = loan.contractDetails.isAccepted;
      if (loan.contractDetails.contractFile)
        staticData.contractFile = loan.contractDetails.contractFile;
    }

    this.setState({
      staticData,
    });
  }
  async componentDidMount() {
    analytics.track();

    const loanId = this.props.match.params.id;

    if (!loanId) {
      return (window.location = "/");
    } else {
      if (!auth.getCurrentUser()) return (window.location = "/login");
      await this.getLoanData(loanId);
    }
  }
  async getLoanData(loanId) {
    let staticData = { ...this.state.staticData };
    try {
      const { data: loanDetails } = await contractService.getLoanData(loanId);

      if (loanDetails.success) {
        await this.mapDataToState(loanDetails.data);
      }

      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }
}
export default ViewScheduleModel;
