import InputDataForm from "../../components/common/form";
import * as reconService from "../../services/recon/reconService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import { toast } from "react-toastify";

class ReconciliationModel extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      partner: "all"
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 10,
    partners: [],
    headerTabs: [
      {
        title: "Transactions"
      },
      {
        title: "MIS"
      },
      {
        title: "Webhook"
      }
    ],
    headerActiveTab: "Transactions",
    statusDropdown: [
      {
        title: "Pending",
        value: "PENDING"
      },
      {
        title: "Processing",
        value: "PROCESSING"
      },
      {
        title :"Batch Created",
        value : "BATCH_CREATED"
      },
      {
        title: "Completed",
        value: "COMPLETED"
      },
      {
        title: "Failed",
        value: "FAILED"
      }
    ]
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    partner: Joi.any(),
    page: Joi.any(),
    misList: Joi.any(),
    status : Joi.any()
  };
  async getReconList() {

    this.state.data.page = this.state.currentPage

    const data = { ...this.state.data };

    let daysDiff = commonService.numberOfDaysDifferece(new Date(data.fromDate), new Date(data.toDate));

    if (daysDiff > 60) {
      toast.error("Max Date Range 60 days");
      this.setState({ showSpinner: false });
      return
    }

    this.setState({ showSpinner: true });
    try {
      const { data: misList, totalPages } = await reconService.getReconList(data);

      data.misList = misList;

      this.setState({ data, showSpinner: false, pageSize: totalPages });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async componentDidMount() {
    await this.getReconList();
    await this.getBorroingPartners();
  };
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getReconList();
  };

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getReconList()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getReconList()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getReconList()
    })
  }

  exportExcel = () => {
    this.getReconList(true)
  }

  closeReconModal = () => {
    this.setState({ showReconModal: false })
  }

  showReconModal = (utr) => {
    this.setState({ selectedUtrNo: utr, showReconModal: true })
  }
}

export default ReconciliationModel;
