import React from "react";
import auth from "../../services/common/authService";
import { Form, Col, InputGroup, Button, Nav, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import PartnerRegistrationFormModel from "../../models/partner/partnerRegistrationFormModel";
import DatePicker from "react-datepicker2";
import moment from "moment";
import Tnc from "./../companyInfo/tnc";
import PrivacyPolicy from "./../companyInfo/privacyPolicy";
// Render the Calendar

class PartnerRegistrationForm extends PartnerRegistrationFormModel {
  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;
    const {
      data,
      errors,
      info,
      showSpinner,
      counter,
      showTnc,
      tnc,
      businessTypeList,
      showBorrowerPopup,
      showLenderPopup,
      isValidMobile,
      isValidOTP,
      isRegenerateLink,
      isRegenerateText,
      showPrivacyPolicy,
    } = this.state;
    const tncLabel = (
      <React.Fragment>
        I have read and agree to{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewTnc}
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewPrivacyPolicy}
        >
          Privacy Policy
        </a>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 style={{ textAlign: "center" }}>Partner Registration</h2> <br />
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="partnerName">
              <Form.Label>Entity Name (As per PAN)*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter entity name"
                name="partnerName"
                value={data["partnerName"]}
                onChange={this.handleChange}
                onBlur={this.handlePartnerNameChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                autoFocus={true}
              />
              {errors["partnerName"] && (
                <div className="alert alert-danger">
                  {errors["partnerName"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="typeOfEntity">
              <Form.Label>Type of Entity</Form.Label>
              <Form.Control
                as="select"
                name="typeOfEntity"
                value={data["typeOfEntity"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                {businessTypeList.map((item, i) => (
                  <option key={i} value={item.defination}>
                    {item.defination}
                  </option>
                ))}
              </Form.Control>
              {errors["typeOfEntity"] && (
                <div className="alert alert-danger">
                  {errors["typeOfEntity"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="partnerType">
              <Form.Label>Looking For*</Form.Label>
              <Form.Control
                as="select"
                name="partnerType"
                value={data["partnerType"]}
                onChange={this.handlePartnerType}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="bp" value="Borrowing Partner">
                  Borrowing Partner
                </option>{" "}
                <option key="lp" value="Lending Partner">
                  Lending Partner
                </option>
              </Form.Control>
              {errors["partnerType"] && (
                <div className="alert alert-danger">
                  {errors["partnerType"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {/* <Form.Group as={Col} md="4" controlId="userName">
              <Form.Label>Username*</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Enter your username"
                  aria-describedby="inputGroupPrepend"
                  name="userName"
                  value={data["userName"]}
                  onChange={this.handleChange}
                  onBlur={this.handleUserNameChange}
                  autoComplete="off"
                />
              </InputGroup>
              {errors["userName"] && (
                <div className="alert alert-danger">{errors["userName"]}</div>
              )}
              {info["userName"] && (
                <div className="alert alert-success">{info["userName"]}</div>
              )}
            </Form.Group> */}
            <Form.Group as={Col} md="4" controlId="email">
              <Form.Label>Email Id*</Form.Label>{" "}
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Enter your email address"
                  name="email"
                  value={data["email"]}
                  onChange={this.handleChange}
                  onBlur={this.handleEmailChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </InputGroup>
              {errors["email"] && (
                <div className="alert alert-danger">{errors["email"]}</div>
              )}
              {info["email"] && (
                <div className="alert alert-success">{info["email"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="doi">
              <Form.Label>Date of Incorporation</Form.Label>
              <DatePicker
                name="doi"
                id="doi"
                value={data["doi"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleDoiChange(value)}
                placeholder="Enter date of incoporation"
              />
              {errors["doi"] && (
                <div className="alert alert-danger">{errors["doi"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No*</Form.Label>
              <Form.Control
                type="text"
                pattern="[0-9]*"
                placeholder="Enter your mobile number"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                onBlur={this.handleMobileChange}
                autoComplete="off"
                maxLength="10"
                disabled={isValidMobile}
              />
              {errors["mobileNo"] && (
                <div className="alert alert-danger">{errors["mobileNo"]}</div>
              )}
              {info["mobileNo"] && (
                <div className="alert alert-success">{info["mobileNo"]}</div>
              )}
            </Form.Group>
            {isValidMobile && (
              <Form.Group as={Col} md="4" controlId="otp">
                <Form.Label>OTP*</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your OTP"
                  name="otp"
                  value={data["otp"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  disabled={isValidOTP}
                  onKeyUp={this.handleOTPChange}
                  autoFocus={isValidMobile}
                  maxLength="6"
                />
                {errors["otp"] && (
                  <div className="alert alert-danger">{errors["otp"]}</div>
                )}
                {info["otp"] && (
                  <div className="alert alert-success">{info["otp"]}</div>
                )}
                {isRegenerateText && (
                  <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                )}
                {isRegenerateLink && (
                  <Nav.Item id="regenerateOTP">
                    <Nav.Link
                      className="underline"
                      onClick={this.handleRegenerateOTP}
                      style={{
                        padding: 0,
                        float: "right",
                        textDecoration: "underline",
                      }}
                    >
                      Re-generate OTP
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="password">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="password"
                value={data["password"]}
                onChange={this.handleChange}
                onBlur={this.onBlurPassword}
                autoComplete="off"
                autoFocus={isValidOTP}
              />
              {errors["password"] && (
                <div className="alert alert-danger">{errors["password"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="confirmPassword">
              <Form.Label>Re-enter Password*</Form.Label>
              <Form.Control
                type="password"
                placeholder="Re-enter password"
                name="confirmPassword"
                value={data["confirmPassword"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
              {errors["confirmPassword"] && (
                <div className="alert alert-danger">
                  {errors["confirmPassword"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="tnc">
            <Form.Check
              type="checkbox"
              label={tncLabel}
              feedback="You must agree before submitting."
              checked={tnc}
              onChange={() => this.handleTnc(tnc)}
              color="red"
            />{" "}
            {errors["tnc"] && (
              <div className="alert alert-danger">{errors["tnc"]}</div>
            )}
          </Form.Group>{" "}
          <Button
            className="ff-button"
            type="submit"
            disabled={data.isSubmitted}
          >
            Submit
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
        <Modal
          size="lg"
          show={showTnc}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <Tnc />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={showPrivacyPolicy}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <PrivacyPolicy />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showBorrowerPopup} onHide={this.handlePopupClose}>
          <Modal.Header closeButton>
            <Modal.Title>Borrowing Partner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are an enterprise with retail and business customers and want to
            setup a lending program to meet the financing needs of customers.
            Fundfina will provide seamless financing experience through APIs to
            improve customer profitability and maximize retention.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handlePopupClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showLenderPopup} onHide={this.handlePopupClose}>
          <Modal.Header closeButton>
            <Modal.Title>Lending Partner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You are a Bank or NBFC or financial institution exploring means of
            digitizing MSME loan and personal loan origination process. Fundfina
            will help you achieve favorable business outcomes by providing
            superior customer experience and deep analytic insights.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handlePopupClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PartnerRegistrationForm;
