import InputDataForm from "../../components/common/form";

class PaymentLinkWrapperModal extends InputDataForm {
  state = {
    data: {},
    showSpinner: false,
    errors: {},
    tabs: [
      {
        title: "Upi"
      },
      {
        title: "ENach"
      }
    ],
    activeTab: "Upi"
  };
  schema = {};
  onChangeTab = (tab) => {
    this.setState({ activeTab: tab })
  }
}

export default PaymentLinkWrapperModal;
