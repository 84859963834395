import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import auth from "../../services/common/authService";

function IdleTimerContainer() {
  const idleTimerRef = useRef(null);

  const onIdle = () => {
    console.log("user is idle");
    if (auth.getCurrentUser()) {
      auth.logout();
      window.location.href = "/login";
    }
  };

  return (
    <React.Fragment>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 60 * 15}
        onIdle={onIdle}
      ></IdleTimer>
    </React.Fragment>
  );
}

export default IdleTimerContainer;
