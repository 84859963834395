import InputDataForm from "../../components/common/form";
import * as scrutinyUserService from "../../services/scrutiny/scrutinyUserService";
import * as commonService from '../../services/common/commonService';
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class ScrutinyUserTableModel extends InputDataForm {
  state = {
    data: {
      kycStatus: "Under Review",
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      panNo: "",
      mobileNo: "",
      poaNo: "",
      name: "",
      users: [],
      merchantCode: "",
      partner: "all"
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 10,
    evaluatorRole: "",
    partners: []
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    panNo: Joi.any(),
    mobileNo: Joi.any(),
    poaNo: Joi.any(),
    name: Joi.any(),
    kycStatus: Joi.any(),
    users: Joi.any(),
    merchantCode: Joi.any(),
    partner: Joi.any()
  };
  async getUserList() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const { data: userList } = await scrutinyUserService.scrutinyList(data);

      data.users = userList;

      this.setState({ data, showSpinner: false, currentPage: 1 });
    } catch (ex) {
      this.setState({ data, showSpinner: false, currentPage: 1 });
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    const currentUser = auth.getCurrentUser();

    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    await this.getUserList();
    await this.getBorroingPartners();
  }
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();

      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  }
  async handleCustomErrors(errors) {
    return errors;
  }
  async doSubmit() {
    await this.getUserList();
  }

  async handleSendReview(loanNo) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await commonService.sendReview(loanNo);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }
    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  }
}

export default ScrutinyUserTableModel;
