import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/users";
const apiEndPointLoan = apiUrl + "/ops/loan";
const apiEndPointAdmin = apiUrl + "/ops/admin";

export async function getPortfolioListForBorrowingPartner(model, sortBy) {
  const { data } = await http.post(
    `${apiEndpoint}/getPortFolioDetailsForBorrowingPartner`,
    {
      userId: model.userId,
      fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
      toDate: moment(model.toDate).format("DD/MM/yyyy"),
      name: model.name,
      loanNo: model.loanNo,
      productId: model.productId,
      merchantCode: model.merchantCode,
      page: model.page,
      sortBy: sortBy,
    }
  );
  return data;
}

export async function downloadBorrowingPartnerPortfolioList(model, sortBy) {
  const { data } = await http.post(
    `${apiEndpoint}/downloadBorrowingPartnerPortFolioDetails`,
    {
      userId: model.userId,
      fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
      toDate: moment(model.toDate).format("DD/MM/yyyy"),
      name: model.name,
      loanNo: model.loanNo,
      productId: model.productId,
      merchantCode: model.merchantCode,
      isLender: model.isLender
    }
  );
  return data;
}

export async function getRepaymentDetails(loanNo, showPaymentOptions, loanNo1) {
  const { data } = await http.post(`${apiEndpoint}/getRepaymentDetails`, {
    loanId: loanNo,
    showPaymentOptions,
    loanNo: loanNo1
  });
  return data;
}

export async function getPortfolioList({ userId, page, fromDate, toDate }, sortBy) {
  const { data } = await http.post(`${apiEndpoint}/getPortFolioDetails`, {
    userId: userId,
    sortBy: sortBy,
    page,
    fromDate,
    toDate
  });
  return data;
}

export async function getPortfolioForAdmin(model, sortBy) {
  const { data } = await http.post(`${apiEndPointAdmin}/getPortfolioLoanList`, {
    name: model.name,
    loanNo: model.loanNo,
    sortBy: sortBy,
  });
  return data;
}

export async function getPortfolioDoc({ fromDate, toDate, userId }) {
  const { data } = await http.post(`${apiEndPointLoan}/getPortfolioReport`, {
    userId,
    fromDate: moment(fromDate).format("DD/MM/yyyy"),
    toDate: moment(toDate).format("DD/MM/yyyy"),
  });
  return data;
}

export async function getPortFolioSummary({ userId }) {
  const { data } = await http.post(`${apiEndpoint}/getPortFolioSummary`, {
    userId: userId,
  });
  return data;
}

export async function getAdminPortFolioDetails() {
  const { data } = await http.post(`${apiEndPointAdmin}/getPortFolioDetails`);
  return data;
}

export async function getKfsDetails(loanId) {
  const { data } = await http.post(`${apiEndPointLoan}/getKfsDetails`, {
    loanId: loanId
  });
  return data;
}