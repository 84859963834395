import http from "../common/httpService";
import { creditApiUrl, apiUrl } from "../../config.json";
import moment from "moment";

const apiCreditEndPoint = creditApiUrl + "metric/";

export async function sendNotifications(model, queryParams = {}) {
    let params = []

    for (let key in queryParams) {
        params.push(`${key}=${queryParams[key]}`)
    }

    const data = await http.post(apiCreditEndPoint + `saveNotificationForExistingPreApprovals?${params.join("&")}`, model)
        .then(response => { return response.data; })
        .catch(error => { return (error.response.data) ? error.response : error; });
    return data;
};

export async function sendCollectionNotifications(model, queryParams = {}) {

    let params = []

    for (let key in queryParams) {
        params.push(`${key}=${queryParams[key]}`)
    }

    const data = await http.post(apiUrl + `/ops/admin/collection/notification?${params.join("&")}`, model)
        .then(response => { return response.data; })
        .catch(error => { return (error.response.data) ? error.response : error; });
    return data;
};

