import React from "react";
import { Redirect } from "react-router-dom";
import { Form, Col, Button, Table, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import TrueScoreDetailModel from "../../models/trueScore/trueScoreDetailsModel";
import Tnc from "./../companyInfo/tnc";
class TrueScoreDetails extends TrueScoreDetailModel {
  render() {
    const { data, showSpinner, errors, info, isOTPGenerated, tnc, showTnc } = this.state;
    const tncLabel = (
      <React.Fragment>I have read and agree to{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewTnc}
        >Terms and Conditions</a></React.Fragment>);
    return (
      <React.Fragment>
        <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
          TrueScore
        </h2>
        <SpinnerComponent showSpinner={showSpinner} />
        <br />
        <br />
        <Form onSubmit={this.handleSubmit} style={{ paddingTop: 30 }}>
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              style={{ paddingLeft: 50 }}
              className="row"
            >
              <Form.Group as={Col} md="4">
                <Form.Label>* Are you lender or borrower?</Form.Label>
              </Form.Group>
              <Form.Group
                as={Col}
                md="4"
                className="row"
                controlId="partnerType"
              >
                <Form.Check
                  type="radio"
                  id="partnerType"
                  label="Lender"
                  value="Lender"
                  name="partnerType"
                  checked={data.partnerType === "Lender" ? "checked" : ""}
                  onClick={this.handleChange}
                />
                <Form.Check
                  type="radio"
                  id="partnerType"
                  label="Borrower"
                  value="Borrower"
                  name="partnerType"
                  checked={data.partnerType === "Borrower" ? "checked" : ""}
                  onClick={this.handleChange}
                  style={{ paddingLeft: 50 }}
                />
                {errors && errors["partnerType"] && (
                  <div className="alert alert-danger">
                    {errors["partnerType"]}
                  </div>
                )}
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              style={{ paddingLeft: 50 }}
              className="row"
            >
              <Form.Group as={Col} md="4">
                <Form.Label>* Please enter your email address</Form.Label>
              </Form.Group>
              <Form.Group as={Col} md="4" className="row">
                <Form.Control
                  type="text"
                  placeholder="Enter your email address"
                  name="email"
                  ref="email"
                  value={data["email"]}
                  onChange={this.handleChange}
                  onBlur={this.handleEmailBlur}
                  autoComplete="off"
                  maxLength="100"
                />
                {errors && errors["email"] && (
                  <div className="alert alert-danger">{errors["email"]}</div>
                )}
                {info && info["email"] && (
                  <div className="alert alert-success">{info["email"]}</div>
                )}
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              style={{ paddingLeft: 50 }}
              className="row"
            >
              <Form.Group as={Col} md="4">
                <Form.Label>
                  Please enter your mobile number (optional)
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} md="4" className="row" controlId="mobile">
                <Form.Control
                  type="text"
                  pattern="[0-9]*"
                  placeholder="Enter your mobile no"
                  name="mobile"
                  ref="mobile"
                  value={data["mobile"]}
                  onChange={this.handleNumericChange}
                  onBlur={this.handleMobileBlur}
                  autoComplete="off"
                  maxLength="10"
                />
                {errors && errors["mobile"] && (
                  <div className="alert alert-danger">{errors["mobile"]}</div>
                )}
                {info && info["mobile"] && (
                  <div className="alert alert-success">{info["mobile"]}</div>
                )}
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              style={{ paddingLeft: 55 }}
              className="row"
            >
            </Form.Group>
            <Form.Group as={Col} md="4">
            </Form.Group>
            <Form.Group as={Col} md="4" className="row" controlId="tnc" style={{ paddingLeft: 10 }}>
              <Form.Check
                type="checkbox"
                label={tncLabel}
                feedback="You must agree before submitting."
                checked={tnc}
                ref="tnc"
                onChange={() => this.handleTnc(tnc)}
                color="red"
              />{" "}
              {errors["tnc"] && (
                <div className="alert alert-danger">{errors["tnc"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          {/* <Form.Row>
            <Form.Group as={Col} md="4">
              {" "}
            </Form.Group>
            <Form.Group

              as={Col}
              md="4"
              style={{ paddingLeft: 30 }}
              className="row"
            >
              <Button
                className="ff-button"
                style={{ marginTop: 30 }}
                onClick={this.handleGenerateOTP}
              >Generate OTP</Button>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              style={{ paddingLeft: 20 }}
              className="row"
            >
              <Form.Group as={Col} md="4">
                {""}
              </Form.Group>
              <Form.Group as={Col} md="4">
                {errors && errors["otpGeneration"] && (
                  <div className="alert alert-danger">
                    {errors["otpGeneration"]}
                  </div>
                )}
                {info && info["otpGeneration"] && (
                  <div className="alert alert-success">
                    {info["otpGeneration"]}
                  </div>
                )}
              </Form.Group>
            </Form.Group>
          </Form.Row> */}
          {/* {isOTPGenerated && isOTPGenerated === true && ( */}
          <React.Fragment>
            {/* <Form.Row>
              <Form.Group
                as={Col}
                md="12"
                style={{ paddingLeft: 50 }}
                className="row"
              >
                <Form.Group as={Col} md="4">
                  <Form.Label>
                    * Enter OTP <br />
                    (kindly check spam folder if mail not received)
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4" className="row" controlId="otp">
                  <Form.Control
                    type="text"
                    pattern="[0-9]*"
                    placeholder="Enter OTP"
                    name="otp"
                    value={data["otp"]}
                    onChange={this.handleNumericChange}
                    autoComplete="off"
                    maxLength="6"
                  />
                  {errors && errors["otp"] && (
                    <div className="alert alert-danger">{errors["otp"]}</div>
                  )}
                  {info && info["otp"] && (
                    <div className="alert alert-success">{info["otp"]}</div>
                  )}
                </Form.Group>
              </Form.Group>
            </Form.Row> */}
            <Form.Row>
              <Form.Group as={Col} md="4">
                {" "}
              </Form.Group>
              <Form.Group
                as={Col}
                md="4"
                style={{ paddingLeft: 50 }}
                className="row"
              >
                <Button
                  className="ff-button"
                  style={{ marginTop: 30, marginLeft: 10 }}
                  type="submit"
                >
                  Submit
                </Button>
              </Form.Group>
            </Form.Row>
          </React.Fragment>
          {/* )} */}
        </Form>
        <Modal
          size="lg"
          show={showTnc}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <Tnc />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TrueScoreDetails;
