
import InputDataForm from "../../components/common/form";
import leadInfoService from "../../services/partner/leadInfoService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import _ from "lodash";

class ChangeMobileNumberModel extends InputDataForm {
    state = {
        data: {
            loanId: this.props.loanId,
            otp: "",
            loanStatus: "",
            kycStatus: "",
            isDraft: false,
            isWhatsApp: "checked"
        },
        errors: {},
        info: {},
        showSpinner: false,
        isRegenerateLink: false,
        isRegenerateText: false,
        counter: 0,
        timerId: 0,
        timer: "",
        isSubmitted: false,
        messageText: "",
        otpSent: false,
        otpAutoFill: false,
        otpType: "otp_confirmation",
        maskedMobileNo: "",
        showConfirmMobilePopup: false,
        popUpShowedForMobileNo: "",
        redirectionUrl: ""
    };
    schema = {
        loanId: Joi.string().required(),
        otp: Joi.number().required(),
        isWhatsApp: Joi.string().required(),
        mobileNo: Joi.string()
    };
    async componentDidMount() {
    }


    sendOtp = async (loanId) => {
        const { data, errors, info, otpType, popUpShowedForMobileNo, maskedMobileNo } = this.state;

        // if (data.isWhatsApp === "checked") {
        //     if (!popUpShowedForMobileNo) {
        //         this.setState({ popUpShowedForMobileNo: maskedMobileNo, showConfirmMobilePopup: true });
        //         return;
        //     }
        //     else if (popUpShowedForMobileNo !== maskedMobileNo) {
        //         this.setState({ popUpShowedForMobileNo: maskedMobileNo, showConfirmMobilePopup: true });
        //         return;
        //     }
        // }

        delete errors["globalError"];
        delete info["otp"];

        this.setState({ showSpinner: true, errors, showConfirmMobilePopup: false });

        try {
            let response = {}

            response = await leadInfoService.generateOTPForMerchantChangeMobileNumber(
                otpType,
                loanId,
                data.isWhatsApp,
                data.mobileNo,
                true
            );
            if (response.success) {
                data.otp = "";

                this.setState({
                    data,
                    info,
                    errors,
                    showSpinner: false,
                    messageText: response.message,
                    otpSent: true
                });

                this.stopTimer();
                this.startTimer();

                //OTP Auto-Fill
                if ("OTPCredential" in window) {
                    const ac = new AbortController();

                    navigator.credentials
                        .get({
                            otp: { transport: ["sms"] },
                            signal: ac.signal,
                        })
                        .then((otp) => {
                            let { data } = this.state;
                            data.otp = otp.code;
                            this.setState({ data, otpAutoFill: true });
                            this.verifyOTP();
                            ac.abort();
                        })
                        .catch((err) => {
                            ac.abort();
                            console.log(err);
                        });
                }
            } else {
                errors["globalError"] = response.message;
                this.setState({
                    errors,
                    showSpinner: false,
                });
            }
        } catch (ex) {
            errors["globalError"] = ex.response.message;
            delete info["otp"];
            this.setState({
                errors,
                info,
                showSpinner: false,
            });
        }
    };
    verifyOTP = async () => {
        const { data, errors, info, otpType, otpAutoFill, redirectionUrl } = this.state;
        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            const verifyOtpResponse = await leadInfoService.verifyCustomOTPForMerchant(
                data.otp,
                otpType,
                data.loanId,
                otpAutoFill,
                data.isWhatsApp,
                true,
                true,
                data.mobileNo,
                true,
                true
            );

            console.log("verifyOtpResponse", verifyOtpResponse);

            if (verifyOtpResponse.success) {
                this.props.back && this.props.back()
            } else {
                errors["otp"] = verifyOtpResponse.message;
                delete info["otp"];
                this.setState({ errors, data, showSpinner: false, isSubmitted: false });
            }
        } catch (ex) {
            this.stopTimer();
            this.startTimer();
            delete info["otp"];
            this.setState({ data, errors, showSpinner: false, isSubmitted: false });
        }
    };


    async handleCustomErrors() {
        const data = { ...this.state.data };
        let errors = { ...this.state.errors };

        const { otpAutoFill, otpType } = this.state;

        delete errors["globalError"];
        delete errors["otp"];

        if (!data.otp) {
            errors["otp"] = "Please enter OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (data.otp.length !== 4) {
            errors["otp"] = "OTP must me 4-digit";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (isNaN(data.otp)) {
            errors["otp"] = "Invalid OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else delete errors["otp"];

        this.setState({ errors });

        return errors;
    }
    handleWhatsAppConsent = async (isWhatsApp) => {
        const data = { ...this.state.data };

        data.isWhatsApp = isWhatsApp ? "checked" : "";

        this.setState({ data, otpType: isWhatsApp ? "otp_confirmation" : "Merchant_Authentication_OTP2" });
    };

    handleCloseFinalPopUp = () => {
        const data = { ...this.state.data };
        data.isWhatsApp = false;
        this.setState({ showConfirmMobilePopup: false, data, otpType: "Merchant_Authentication_OTP2" });
    };

    // showUpdateMobile() {
    //     const data = { ...this.state.data };
    //     data.mobileNo = "";
    //     this.setState({ showUpdateMobile: true, data, otpSent: false })
    // }

    doSubmit = async (e) => {
        e.preventDefault();
        let errors = await this.handleCustomErrors();

        if (_.isEmpty(errors)) await this.verifyOTP();
    };
};

export default ChangeMobileNumberModel;
