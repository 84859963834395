import React from "react";
import Joi from "joi-browser";
import InputForm from "../../components/common/form";
import * as updateEmailMobileService from "../../services/profile/updateAlternateEmailService";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import * as kycFormService from "../../services/borrow/kycFormService";
import * as analytics from "../../utils/analytics";
import * as loanFormService from "../../services/borrow/loanFormService";
import axios from "axios";

class UpdateBankDetailModel extends InputForm {
    state = {
        data: {
            userId: "",
            accountNo: "",
            ifsc: "",
            accountType: "",
            accountName: "",
            merchantCode: "",
            mandateType: ""
        },
        backUpBankDetail: "",
        isValidIfsc: false,
        isBankSelected: false,
        showSpinner: false,
        showBankSearch: false,
        showIfscSearch: false,
        ifscOptions: [],
        bankOptions: [],
        branchOptions: [],
        bankList: [],
        errors: {},
        isDisabled: false,
        optional: {
            searchBankName: "",
            searchBranchName: "",
        },
        optionalErrors: {},
        loggedInFrom: "",
        info: {},
        isSubmitted: false,
        loggedInFrom: "",
        updateMessage: "",
        updateHeader: "",
        showSaveModal: false,
        loanId: "",
        isMandate: false,
        mandateOptions: [],
        kycStatus: "",
        allowBypassMandate: false,
        showSkipMandatePopUp: false,
        nachError: null,
        showNachErrorPopUp: false,
        isPhysicalMandateGenerated: false,
        isPhysicalNachDownloaded: false,
        files: {
            pNachFile: "",
            pNachFileStream: "",
            pNachFileLabel: ""
        },
        fileErrors: {}
    };
    schema = {
        userId: Joi.string().required(),
        accountNo: Joi.string().min(8).max(18).required().label("account number"),
        ifsc: Joi.string().required().label("ifsc code"),
        accountType: Joi.string().min(1).max(20).required().label("account type"),
        accountName: Joi.string().min(1).max(200).required().label("account name"),
        merchantCode: Joi.string().allow(""),
        mandateType: Joi.string().allow(""),
    };
    async mapDataToState(userData, merchantCode, loanStatus, isForceMandate, isEdit, nachError) {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };
        let { user } = userData;

        let {
            isValidIfsc,
            loanId
        } = this.state;
        let {
            bankDetails,
        } = user;

        //User Details
        if (user._id) data.userId = user._id;

        //Bank Details
        if (bankDetails[0] && bankDetails[0].accountNo)
            data.accountNo = bankDetails[0].accountNo;
        if (bankDetails[0] && bankDetails[0].ifsc) {
            data.ifsc = bankDetails[0].ifsc;
            isValidIfsc = true;
        }
        if (bankDetails[0] && bankDetails[0].accountType)
            data.accountType = bankDetails[0].accountType;

        if (bankDetails[0] && bankDetails[0].accountName)
            data.accountName = bankDetails[0].accountName;

        if (this.state.mandateType) data.mandateType = this.state.mandateType

        data.merchantCode = merchantCode;

        const validatedForLoanId = bankDetails && bankDetails[0] && bankDetails[0].docVerification && bankDetails[0].docVerification.validatedForLoanId ? bankDetails[0].docVerification.validatedForLoanId.toString() : null;

        let isDisabled = (loanStatus === "Draft" || loanStatus === "Under Review" || loanStatus === "Review" || loanStatus === "Approved By Admin" || loanStatus === "Accepted By Client" || loanStatus === "Loan Is Funding" || isForceMandate || (user.userDetails.kycStatus === "Review" && (loanStatus === "Under Review" || loanStatus === "Loan Contract Accepted" || loanStatus === "Recourse Pending"))) ? false : true;

        if (isDisabled)
            errors["globalError"] = "Bank details cannot be updated as loan contract is signed.";

        if (nachError)
            isDisabled = true;
        // else if (isEdit) {
        //     let isDisabled = isEdit;

        //     isDisabled = isDisabled !== "true";

        //     if (!this.state?.data?.accountNo) isDisabled = false;
        //     console.log("b");
        // }
        else if (!data.accountNo) isDisabled = false;
        else if (validatedForLoanId === loanId && user.userDetails.kycStatus !== "Review") isDisabled = true;
        else if (!validatedForLoanId || (validatedForLoanId !== loanId)) isDisabled = false;

        if (!this.state.backUpBankDetail) {
            this.setState({ backUpBankDetail: { ...data } })
        }

        this.setState({
            data,
            isValidIfsc,
            isDisabled,
            loanStatus,
            isForceMandate,
            errors,
            kycStatus: user.userDetails && user.userDetails.kycStatus ? user.userDetails.kycStatus : null

        });
    }

    async componentDidMount() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let mode = params.get('mode');
        let nachError = params.get('error');

        // if (mandate === "true") {
        //     this.state.isMandate = true
        //     this.schema.mandateType = Joi.string().required().label("mandate method")
        // }

        if (nachError)
            this.setState({ showNachErrorPopUp: true, nachError: nachError });

        let loanId = this.props.match.params.loanId;

        if (loanId) {
            let splittedId = loanId.split("?")
            loanId = splittedId[0]
        }

        if (!auth.getCurrentUser()) return (window.location = "/login");

        analytics.track();

        const currentUser = auth.getCurrentUser();

        this.setState({ loggedInFrom: currentUser.loggedInFrom, loanId: loanId ? loanId : null });

        await this.getUserData(loanId, mode, params.get('isEdit'), nachError);
    }

    async getUserData(loanId, mode, isEdit, nachError) {
        const currentUser = auth.getCurrentUser();
        if (!currentUser) return (window.location = "/login");

        const partnerUser = (currentUser.role === "Lending Partner" || currentUser.role === "Borrowing Partner") ? true : false;

        const currentPartner = auth.getCurrentPartner();

        let partnerId = null;

        if (currentPartner && currentPartner._id)
            partnerId = currentPartner._id;

        try {

            // const { data: userDetails } = await kycFormService.getUserDetails(
            //     currentUser.userId, partnerUser, partnerId, merchantCode
            // );

            // if (userDetails.success)
            //     await this.mapDataToState(userDetails.data, merchantCode);

            let merchantCode = "";
            let loanStatus = "";
            let isForceMandate = false;

            if (loanId) {
                const { data: loanDetails } = await loanFormService.getLoanStatus(
                    loanId
                );
                merchantCode = loanDetails.data.merchantCode;
                loanStatus = loanDetails.data.status;
                isForceMandate = loanDetails.data.isForceMandate;
            }

            const { data: userDetails } = await kycFormService.getUserDetails(
                currentUser.userId, partnerUser, partnerId, merchantCode
            );

            let user = null;

            if (userDetails.success)
                user = userDetails.data && userDetails.data.user ? userDetails.data.user : null;

            if (userDetails.success)
                await this.mapDataToState(userDetails.data, merchantCode, loanStatus, isForceMandate, isEdit, nachError);

            if (loanStatus === "Review" || loanStatus === "Loan Is Funding" || loanStatus === "Draft" || isForceMandate ||
                (user.userDetails.kycStatus === "Review" && (loanStatus === "Under Review" || loanStatus === "Loan Contract Accepted" || loanStatus === "Recourse Pending"))) {
                await this.getMandateRequireOrNot("", mode)
            } else {
                this.setState({ isMandate: false })
            }

        } catch (ex) { }
    }

    async getMandateMethodByIFSC(mode = null) {
        if (this.state?.data.ifsc && this.state.isMandate) {
            const response = await commonService.getMandateMethodByIFSC(this.state?.data.ifsc);

            let mandateOptions = []

            let data = response?.data?.data, isApi = false, isAadhaar = false, isPhysical = false;

            if (data) {

                if (data?.netBanking || data?.debitCard) {
                    mandateOptions.push({ title: "Net Banking / Debit Card", value: "NET_BANKING" })
                    isApi = true
                }

                if (data?.esign) {
                    mandateOptions.push({ title: "Aadhaar", value: "E_SIGN" })
                    isAadhaar = true
                }

                if (data?.physical) {
                    mandateOptions.push({ title: "Physical", value: "PHYSICAL" })
                    isPhysical = true
                }

            }

            let accountData = this.state.data
            let errors = this.state.errors

            if (mandateOptions.length) {
                if (mode === "api" && isApi) {
                    accountData.mandateType = "NET_BANKING"
                } else if (mode === "esign" && isAadhaar) {
                    accountData.mandateType = "E_SIGN"
                }
                else if (mode === "physical" && isPhysical) {
                    accountData.mandateType = "PHYSICAL"
                }

                let obj = mandateOptions.find(o => o.value === accountData.mandateType);

                if (!accountData.mandateType || !mode || !obj) {
                    accountData.mandateType = mandateOptions[0].value
                }

                errors.mandateType = ""
            }

            this.setState({ mandateOptions, data: accountData, errors })

        }

    }

    async getMandateRequireOrNot(redirectToContract, mode) {
        if (this.state?.loanId) {
            const response = await commonService.getMandateRequireStatus(this.state?.loanId);
            let data = response?.data?.data;
            this.setState({ isMandate: data?.isMandateRequired, allowBypassMandate: data?.allowBypassMandate }, () => {

                if (!this.state.isMandate && redirectToContract) {
                    window.location = `/verifyLoanContract/${this.state?.loanId}`
                } else {
                    console.log("Data", data);
                    this.getMandateMethodByIFSC(data && data.selectedMode ? data.selectedMode : mode);
                }
            })
        }

    }

    onClickNext = () => {
        window.location = `/verifyLoanContract/${this.state?.loanId}`
    }

    async handleCustomErrors(errors) {
        const data = { ...this.state.data };
        const {
            isValidIfsc
        } = this.state;

        if (!errors["ifsc"] && data.ifsc) {
            if (!errors["ifsc"] && !isValidIfsc) errors["ifsc"] = "Invalid IFSC Code";
            else delete errors["ifsc"];
        }

        this.setState({ errors });

        return errors;
    }
    async insertUpdateBankDetails() {
        let { data, errors, loggedInFrom, loanId, kycStatus } = this.state;
        this.setState({ showSpinner: true, isSubmitted: true });
        try {
            const currentPartner = auth.getCurrentPartner();

            let partnerId = null;

            if (currentPartner && currentPartner._id)
                partnerId = currentPartner._id;

            const response = await updateEmailMobileService.insertUpdateBankDetails(
                data, partnerId, loanId
            );

            if (response.success) {
                // if (loggedInFrom.toLowerCase() == "mail") {
                //this.showSuccessModal()
                if (this.state.loanStatus === "Review" || this.state.loanStatus === "Loan Is Funding" || this.state.loanStatus === "Draft" || this.state.isForceMandate ||
                    ((this.state.loanStatus === "Under Review" || this.state.loanStatus === "Loan Contract Accepted" || this.state.loanStatus === "Recourse Pending") && kycStatus === "Review")) {
                    await this.getMandateRequireOrNot(true)
                }
                this.setState({ showSpinner: false, isDisabled: true, isPhysicalMandateGenerated: false, isPhysicalNachDownloaded: false });
                // }
                // else
                //     window.location = "/myprofile";
            }
            else {
                errors.globalError = response.message;
                this.setState({ data, errors, showSpinner: false });
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                errors.globalError = ex.response.data;
                this.setState({ data, errors, showSpinner: false });
            }
        }
    }
    async createMandateForLoanByUser() {
        let { data, errors, loggedInFrom, loanId } = this.state;
        this.setState({ showSpinner: true, isSubmitted: true });
        try {
            const currentPartner = auth.getCurrentPartner();

            let partnerId = null;

            if (currentPartner && currentPartner._id)
                partnerId = currentPartner._id;

            if (!data.mandateType)
                errors["globalError"] = "Please select mandate type";
            else
                delete errors["globalError"];

            this.setState({ errors });

            const response = await updateEmailMobileService.createMandateForLoanByUser(data, loanId);

            if (response.success) {
                this.setState({ showSpinner: false, isDisabled: true });

                if (response.authMode === "physical") {
                    if (response.isMandateSigned) window.location.href = `/contractRedirect/${loanId}`;
                    else {
                        await this.generatePhysicalNachFile();
                        // this.setState({ isPhysicalMandateGenerated: true });
                    }
                }
                else if (response.eNachLink) window.location.href = `https://${response.eNachLink}`
            }
            else {
                errors.globalError = response.message;
                this.setState({ data, errors, showSpinner: false });
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                errors.globalError = ex.response.data;
                this.setState({ data, errors, showSpinner: false });
            }
        }
    }

    async generatePhysicalNachFile() {
        let { data, errors, loanId } = this.state;
        this.setState({ showSpinner: true, isSubmitted: true });
        try {

            const nachResponse = await updateEmailMobileService.generatePhysicalNachFile(loanId);

            if (nachResponse.success) {
                this.setState({ showSpinner: false, isDisabled: true, isPhysicalMandateGenerated: true, isPhysicalNachDownloaded: true });

                const url = await this.getSignedURLToDownload(nachResponse.data.pNachPath);

                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nachResponse.data.pNachFileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            }
            else {
                errors.globalError = nachResponse.message;
                this.setState({ data, errors, showSpinner: false, isPhysicalMandateGenerated: true, isPhysicalNachDownloaded: false });
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                errors.globalError = ex.response.data;
                this.setState({ data, errors, showSpinner: false, isPhysicalMandateGenerated: true, isPhysicalNachDownloaded: false });
            }
        }
    }

    async uploadPhysicalNachFile() {
        let { data, errors, loanId, files, fileErrors } = this.state;

        try {

            if (!files.pNachFile) {
                fileErrors["pNachFile"] = "Please upload physical NACH document";
                this.setState({ fileErrors, errors });
                return;
            }
            else delete fileErrors["pNachFile"];

            if (files.pNachFile.split('.').pop() !== "jpg" && files.pNachFile.split('.').pop() !== "jpeg" && files.pNachFile.split('.').pop() !== "png") {
                fileErrors["pNachFile"] = "Document must be an image";
                this.setState({ fileErrors, errors });
                return;
            }
            else delete fileErrors["pNachFile"];

            delete errors["globalError"];

            this.setState({ showSpinner: true, isSubmitted: true, fileErrors, errors });

            const response = await updateEmailMobileService.uploadPhysicalNachFile(loanId, files);

            if (response.success) {
                this.setState({ showSpinner: false, isDisabled: true });
                window.location.href = `/contractRedirect/${loanId}`;

            }
            else {
                errors.globalError = response.message;
                this.setState({ data, errors, showSpinner: false });
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                errors.globalError = ex.response.data;
                this.setState({ data, errors, showSpinner: false });
            }
        }
    }


    showSuccessModal() {
        let data = this.state.data, backUpBankDetail = this.state.backUpBankDetail
        if (!backUpBankDetail?.accountNo) return
        if (!this.state.isMandate || !(data.accountName === backUpBankDetail?.accountName && data.accountNo === backUpBankDetail?.accountNo && data.ifsc === backUpBankDetail.ifsc && data.accountType === backUpBankDetail.accountType)) {
            this.setState({ showSaveModal: true, updateHeader: "Success!", updateMessage: "Bank updated successfully!", })
        }
    }

    doSubmit = async (e) => {
        if (!this.state.isDisabled) {
            await this.insertUpdateBankDetails();
        } else if (this.state.isMandate && this.state.data?.mandateType) {
            await this.createMandateForLoanByUser();
        }
        else if (this.state.isMandate && !this.state.data?.mandateType && this.state.allowBypassMandate) {
            this.onClickNext();
        }
    };
    onIfscSuggestionsClearRequested = () => {
        this.setState({
            ifscOptions: [],
        });
    };
    onBankSuggestionsClearRequested = () => {
        this.setState({
            bankOptions: [],
        });
    };
    onBranchSuggestionsClearRequested = () => {
        this.setState({
            branchOptions: [],
        });
    };
    handleClose = () => {
        this.setState({ showBankSearch: false, showIfscSearch: false, showSaveModal: false });
    };
    handleOpenSkipMandatePopUp = () => {
        this.setState({ showSkipMandatePopUp: true });
    };
    handleCloseSkipMandatePopUp = () => {
        this.setState({ showSkipMandatePopUp: false });
    };
    handleOpenNachErrorPopUp = () => {
        this.setState({ showNachErrorPopUp: true });
    };
    handleCloseNachErrorPopUp = () => {
        this.setState({ showNachErrorPopUp: false });
    };
    getSuggestionValue = (suggestion) => suggestion;
    renderSuggestion = (suggestion) => <div>{suggestion}</div>;
    onIfscChange = async (event, { newValue }) => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data };

        data.ifsc = newValue.trim().toUpperCase();
        this.setState({ data, isValidIfsc: false });

        if (newValue.length !== 11) {
            errors["ifsc"] = "Invalid Ifsc Code";
            this.setState({ errors, isValidIfsc: false });
            return;
        }

        this.setState({ showSpinner: true });

        try {
            const { data: response } = await commonService.isValidIfscCode(newValue);

            if (response.success) {
                delete errors["ifsc"];
                this.setState({
                    isValidIfsc: response.success,
                    errors,
                    showSpinner: false,
                });
            } else {
                errors["ifsc"] = "Invalid Ifsc Code";
                this.setState({
                    isValidIfsc: response.success,
                    errors,
                    showSpinner: false,
                });
            }
        } catch (ex) {
            errors["ifsc"] = "Invalid Ifsc Code";
            this.setState({
                isValidIfsc: false,
                errors,
                showSpinner: false,
            });
        }
    };
    onIfscSuggestionsRequested = async ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        try {
            const { data: response } = await commonService.getIfscCode(value);
            this.setState({
                ifscOptions: response.data,
            });
        } catch (ex) { }
    };
    onBankChange = async (event, { newValue }) => {
        const optional = { ...this.state.optional };
        optional["searchBankName"] = newValue.toUpperCase();
        this.setState({ optional });
    };
    onBankSuggestionsRequested = async ({ value }) => {
        const inputValue = value.trim().toUpperCase();
        try {
            const { data: response } = await commonService.getBankList(inputValue);
            this.setState({
                bankOptions: response.data,
            });
        } catch (ex) { }
    };
    onBranchChange = async (event, { newValue }) => {
        const optional = { ...this.state.optional };
        optional["searchBranchName"] = newValue.trim().toUpperCase();
        this.setState({ optional });
    };
    onBranchSuggestionsRequested = async ({ value }) => {
        const inputValue = value.trim();
        const optional = { ...this.state.optional };
        try {
            const { data: response } = await commonService.getBranchList(
                optional.searchBankName,
                inputValue
            );
            this.setState({
                branchOptions: response.data,
            });
        } catch (ex) { }
    };
    showBankSearch = async () => {
        this.setState({
            showBankSearch: true,
            showIfscSearch: false,
            isBankSelected: false,
        });
    };
    onBankSelect = (ifsc) => {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };
        data.ifsc = ifsc;
        delete errors["ifsc"];
        this.setState({ errors, data, isValidIfsc: true, isBankSelected: true });
    };
    getIfscList = async () => {
        const optional = { ...this.state.optional };
        const optionalErrors = { ...this.state.optionalErrors };
        const bankList = [];

        if (!optional.searchBankName)
            optionalErrors["searchBankName"] = "Enter bank name";
        else delete optionalErrors["searchBankName"];

        if (!optional.searchBranchName)
            optionalErrors["searchBranchName"] = "Enter branch name";
        else delete optionalErrors["searchBranchName"];

        this.setState({ optionalErrors });

        if (!optional.searchBankName || !optional.searchBranchName) return;

        try {
            const { data: response } = await commonService.getIfscList(
                optional.searchBankName,
                optional.searchBranchName
            );

            for (const item of response.data) {
                let obj = {
                    ifsc: item.ifsc,
                    bank: item.bank,
                    branch: item.branch,
                };
                bankList.push(obj);
            }
            this.setState({ bankList, showBankSearch: false, showIfscSearch: true });
        } catch (ex) { }
    };
}

export default UpdateBankDetailModel;
