import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PartnerConfigurationModel from "../../models/partnerConfigurations/partnerConfigurationsDetails";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";
import { Tabs, Tab } from "react-bootstrap";
import CreditGrid from './creditGrids'


class PartnerConfigurationsDetails extends PartnerConfigurationModel {

  render() {
    const {
      showSpinner,
      activeTab
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Partner Configurations
        </h2>

        <Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.handleSelect} className="tab-fixed-top pt-3">

        <Tab eventKey="CREDIT_GRID" title="Credit Grid">
          <CreditGrid {...this.props}></CreditGrid>
        </Tab>
        </Tabs>

        </React.Fragment>
    );
  }
}

export default PartnerConfigurationsDetails;
