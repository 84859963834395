import PayInPayOutInfoModel from "../payInPayOut/payInPayOutInfoModel";
import * as trancheInfoService from "../../services/trancheCRUD/trancheInfoService";
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi, { errors } from "joi-browser";

class TrancheInfoModel extends PayInPayOutInfoModel {
  state = {
    formData: {
      partnerId: "",
      reverseResidualInDays: null,
      interestRate: null,
      identifier: "",
      tenure: {
        value: null,
        valueType: "DAYS"
      },
      currencyType: "INR",
      amount: null,
      deploymentType: "",
      type: "",
      startDate: momentJalaali(),
      endDate: momentJalaali(),
      fldg:{
        amount:null,
        transactionDate:momentJalaali(),
        utrNumber:null
      },
      isActive: false
    },
    deploymentTypeList: ["FLAT", "REDUCING"],
    typeList: ["PARTNER", "FUNDFINA"],
    showSpinner: false,
    errors: {},
    successMsg: "",
    isSubmitted: false,
  };
  schema = {
    partnerId: Joi.string(),
    reversalAmountInDays: Joi.number().required(),
    interestRate: Joi.number().required(),
    identifier: Joi.string(),
    tenure: {
      value: Joi.number().required(),
      valueType: Joi.string().required()
    },
    currencyType: Joi.string().required(),
    amount: null,
    deploymentType: Joi.string().required(),
    type: Joi.string().required(),
    startDate: Joi.any().required(),
    endDate: Joi.any().required(),
    fldgAmount: Joi.number().required(),
    isActive: Joi.boolean().required()
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    const currentUser = auth.getCurrentUser();
    if (currentUser.role != "Admin") return (window.location = "/");

    let formData = this.props.formData;
 
    if (formData.fldg.utrNumber) {
      let formData = this.props.formData;
      this.setState({
        formData,
      })
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const isFormEdit=this.props.isFormEdit
  
    if (isFormEdit) {
      this.updateTrancheRecord();
    } else {
      this.createTrancheRecord();
    }
  };

  updateTrancheRecord = async () => {
    const formData = { ...this.state.formData };
    let errors = { ...this.state.errors };

    try {
      formData.partnerId=this.props.partnerId
      const response = await trancheInfoService.updateTrancheRecord(formData);

      if (response.success) {
        this.goBack(true);
      }else{
        errors["globalError"] = response.message;
        this.setState({
          errors,
          successMsg: "",
          showSpinner: false,
        });
      }

    } catch (ex) {
      errors["globalError"] = ex.response.data.message;
      this.setState({
        showSpinner: false,
        errors,
        successMsg: "",
      });
    }
  };

  createTrancheRecord = async () => {
    const formData = { ...this.state.formData };
    let errors = { ...this.state.errors };

    try {
      formData.partnerId = this.props.partnerId
      const response = await trancheInfoService.createTrancheRecord(formData);
      

      if (response.success) {
        this.goBack(true,true);
      }else{
        
        errors["globalError"] = response.message;
        this.setState({
          errors,
          successMsg: "",
          showSpinner: false,
        });
      }

    } catch (ex) {
      errors["globalError"] = ex.response.data.message;
      this.setState({
        showSpinner: false,
        errors,
        successMsg: "",
      });
    }
  };

  handleStartDateChange = (value) => {
    let { formData } = this.state;

    formData.startDate = value;
    this.setState({ formData });
  };

  handleEndDateChange = (value) => {
    let { formData } = this.state;

    formData.endDate = value;
    this.setState({ formData });
  };

  handleTransactionDateChange = (value) => {
    let { formData } = this.state;

    formData.fldg.transactionDate = value;
    this.setState({ formData });
  };

  handleTrancheIdentifierFormChange = ({ currentTarget: input }) => {
    let { formData } = this.state;

    formData[input.name] = input.value;
    this.setState({ formData });
  }

  handleTrancheInfoFormChange = ({ currentTarget: input }) => {
    let { formData } = this.state;

    formData[input.name] = input.value.toUpperCase();
    this.setState({ formData });
  }

  handleTrancheInfoFormTenureChange = ({ currentTarget: input }) => {
    let { formData } = this.state;

    formData.tenure[input.name] = input.value.toUpperCase();
    this.setState({ formData });
  }

  handleTrancheInfoFLDGChange = ({ currentTarget: input }) => {
    let { formData } = this.state;

    formData.fldg[input.name] = input.value;
    this.setState({ formData });
  }

  toggleChange = ({ currentTarget: input }) => {
    let { formData } = this.state;
    formData[input.name] = input.value === "true" ? false : true;
    input.value = formData[input.name];
    this.setState({ formData });
  }

  goBack = (isEditedOrCreated,isCreated) => {
    if (isEditedOrCreated) {
      if(isCreated){
        this.props.switchActiveTab("trancheDetailsTab");
        this.props.handleTrancheDetailsPageChange(1);
        this.props.switchisTrancheRecordEdit(false);
      }else{
        this.props.switchActiveTab("trancheDetailsTab");
        this.props.switchisTrancheRecordEdit(false);
      } 
    }else{
      this.props.switchisTrancheRecordEdit(false)
    }
  };
}

export default TrancheInfoModel;