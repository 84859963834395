import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/";

export async function getUserDetails(userId, isPartnerUser = false, partnerId = null, merchantCode = null) {
  let response = null;

  if (merchantCode)
    response = await http.get(apiEndpoint + `users/${userId}/${isPartnerUser}/${partnerId}/${merchantCode}`);
  else
    response = await http.get(apiEndpoint + `users/${userId}/${isPartnerUser}/${partnerId}`);

  return response;
}

export async function isValidPanNumber(userId, panNumber) {
  const response = await http.get(
    apiEndpoint + `masters/isValidPAN/${panNumber}/${userId}`
  );
  return response;
}

export async function isValidPoaNumber(userId, poaNumber, poaType) {
  const response = await http.post(
    apiEndpoint + "masters/isValidPOANumber",
    {
      poaNumber,
      userId,
      poaType
    }
  );
  return response;
}

export async function isValidUdyogAadhaarNo(userId, udyogAadhaarNo) {
  const response = await http.get(
    apiEndpoint + `masters/isValidUdyogAadhaar/${udyogAadhaarNo}/${userId}`
  );
  return response;
}

export async function isValidGstNumber(userId, gstNumber) {
  const response = await http.get(
    apiEndpoint + `masters/isValidGST/${gstNumber}/${userId}`
  );
  return response;
}

export async function checkGstNoExist(gstNumber) {
  const response = await http.get(
    apiEndpoint + `gst/checkGstNoExist/${gstNumber}`
  );
  return response;
}
export async function createGstEntry(gstNumber) {
  const response = await http.post(
    apiEndpoint + `gst/createGstEntry`,
    {
      gstNo: gstNumber,
    }
  );
  return response;
}

export async function isValidAlternateEmail(userId, emailId) {
  const response = await http.get(
    apiEndpoint + `masters/isValidAlternateEmail/${emailId}/${userId}`
  );
  return response;
}

export async function isValidAlternateMobile(userId, mobileNo) {
  const response = await http.get(
    apiEndpoint + `masters/isValidAlternateMobile/${mobileNo}/${userId}`
  );
  return response;
}

export async function isValidPrimaryMobile(userId, mobileNo) {
  const { data } = await http.get(
    apiEndpoint + `masters/isValidPrimaryMobile/${mobileNo}/${userId}`
  );
  return data;
}

export async function insertUpdateKyc(data, files, currentUserId, currentUserRole, loanId) {

  let payload = {
    firstName: data.firstName ? data.firstName.trim() : "",
    middleName: data.middleName ? data.middleName.trim() : "",
    lastName: data.lastName ? data.lastName.trim() : "",
    add1: data.add1 ? data.add1.trim() : "",
    add2: data.add2 ? data.add2.trim() : "",
    add3: data.add3 ? data.add3.trim() : "",
    perAdd1: data.perAdd1 ? data.perAdd1.trim() : "",
    perAdd2: data.perAdd2 ? data.perAdd2.trim() : "",
    perAdd3: data.perAdd3 ? data.perAdd3.trim() : "",
    city: data.city ? data.city.trim() : "",
    state: data.state ? data.state.trim() : "",
    country: data.country ? data.country.trim() : "",
    addressType: data.addressType ? data.addressType.trim() : "",
    pincode: data.pincode ? data.pincode.trim() : "",
    panNo: data.panNo ? data.panNo.trim() : "",
    poaNo: data.poaNo ? data.poaNo.trim() : "",
    gstNo: data.gstNo ? data.gstNo.trim() : "",
    employment: data.employment ? data.employment.trim() : "",
    isRetailer: data.isRetailer ? true : false,
    poaType: data.poaType ? data.poaType.trim() : "",
    alternateEmail: data.alternateEmail ? data.alternateEmail.trim() : "",
    alternateMobile: data.alternateMobile ? data.alternateMobile.trim() : "",
    accountNo: data.accountNo ? data.accountNo.trim() : "",
    ifsc: data.ifsc ? data.ifsc.trim() : "",
    accountType: data.accountType ? data.accountType.trim() : "",
    panFile: files.panFile,
    panFileStream: files.panFileStream,
    poaFile: files.poaFile,
    poaFileStream: files.poaFileStream,
    poaBackFile: files.poaBackFile,
    poaBackFileStream: files.poaBackFileStream,
    gstFile: files.gstFile,
    gstFileStream: files.gstFileStream,
    additionalDocuments: files.additionalDocuments,
    mobileNo: data.mobileNo ? data.mobileNo.trim() : "",
    dob: moment(data.dob).format("DD/MM/yyyy"),
    merchantCode: data.merchantCode ? data.merchantCode.trim() : "",
    currentUserId: currentUserId ? currentUserId : "",
    currentUserRole: currentUserRole ? currentUserRole : "",
    email: data.email ? data.email.trim() : "",
    loanId: loanId ? loanId : "",
    poaVerificationDocType: data.poaVerificationDocType ? data.poaVerificationDocType.trim() : "",
    poaPassword: data.poaPassword ? data.poaPassword.trim() : "",
    accountName: data.accN ? data.accN.trim() : "",
    otp : data.otp
  }

  if (currentUserRole == "Admin") payload.gender = data.gender

  const response = await http.put(
    apiEndpoint + `users/insertUpdateKyc/${data.userId}`,
    payload
  );
  return response;
}

export async function insertUpdateShopDetails(userId, users, loanId, currentUserId, currentUserRole) {
  const response = await http.post(
    apiEndpoint + `users/updateShopDetails`,
    {
      userId: userId,
      users: users,
      loanId: loanId,
      currentUserId: currentUserId,
      currentUserRole: currentUserRole
    }
  );
  return response;
}

export async function cancelMandate(userId, umrn) {
  const { data } = await http.post(`${apiEndpoint}digio/cancelMandate`, {
    userId: userId,
    umrn: umrn
  });
  return data;
}

export async function getMandateList(userId) {
  const { data } = await http.post(`${apiEndpoint}digio/getMandateList`, {
    userId: userId
  });
  return data;
}

export async function isValidPrimaryEmail(userId, emailId) {
  const response = await http.get(
    apiEndpoint + `masters/isValidPrimaryEmail/${emailId}/${userId}`
  );
  return response;
}

export async function sendDigilockerRequest(loanId,key="eKyc") {
  const response = await http.post(`${apiEndpoint}digio/sendDigilockerRequest`, {
    loanId: loanId,
    key:"eSign"
  });
  console.log("digio",response)
  return response;
}

export async function bankStatementFileUpload(formData) {
  const response = await http.post(
    apiEndpoint + `bankstatement/createNewAnalysis`,
    formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
  );
  return response;
}

