import InputDataForm from "../../components/common/form";
import * as commonService from "../../services/common/commonService";
import * as loanService from "../../services/loan/loanService";
import auth from "../../services/common/authService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import * as foreclosureService from "../../services/foreclosure/foreclosureService";
import moment from "moment";
import Joi from "joi-browser";
import _ from "lodash";
import { url } from "../../config.json";
import * as shopFormService from "../../services/borrow/shopFormService";

class LoanInfoFormModel extends InputDataForm {
  constructor(props) {
    super(props);

    if (
      this.props.match &&
      this.props.match.params &&
      (this.props.match.params.isFrom == "teleCalling" ||
        this.props.match.params.isFrom == "fromCollectionQueue" ||
        this.props.match.params.isFrom == "renewals")
    ) {
      this.state.activeTab = "teleCalling";
    }
  }
  state = {
    data: {
      adminStatus: "",
      rejectReason: "",
      remarks: "",
      maturity: "",
      interestRate: "",
      interestType: "Compound Interest",
      repaymentFrequency: "",
      partnerName: "",
      tenure: "",
      loanAmount: "",
      adhocDays: "",
      trancheType: "",
      lenderComments: "",
      reviewReason: "",
    },
    staticData: {
      loanId: "",
      userId: "",
      userRole: "",
      clientCode: "",
      dob: "",
      firstName: "",
      middleName: "",
      lastName: "",
      fullName: "",
      employment: "",
      panNo: "",
      add1: "",
      add2: "",
      add3: "",
      addressType: "Correspondence",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      isRetailer: false,
      alternateEmail: "",
      alternateMobile: "",
      emailId: "",
      mobileNo: "",
      kycStatus: "",
      entityName: "",
      gstList: [],
      userRole: "",
      partnerName: "",
      udyogAadhaar:{},
      udyamAadhaar:{},
      fullAddress: "",
      panFileName: "",
      panFilePath: "",
      profileFileName: "",
      profileFilePath: "",
      typeOfEntity: "",
      questionnairre: {},
      amount: "",
      loanCategory: "",
      frequency: "",
      loanDescription: "",
      loanType: "",
      loanGenDate: "",
      updationDate: "",
      disbursalDate: "",
      roi: "",
      maturity: "",
      loanStatus: "",
      loanNo: "",
      campaignTime: "",
      additionalDescription: "",
      evaluatorRole: "",
      emi: "",
      instalments: "",
      contractFile: "",
      photoFilePath: "",
      photoFileEncPath: "",
      shopInternalPhotoFileEncPath:"",
      shopInternalPhotoFilePath:"",
      shopExternalPhotoFileEncPath:"",
      shopExternalPhotoFilePath:"",
      isContractSigned: "",
      shopDetails: [],
      transactionDetails: [],
      investments: [],
      loanHistory: [],
      fundfinaLoanHistory: [],
      agentCode: "",
      merchantCode: "",
      initiatedFrom: "",
      postDisbursalStatus: "",
      kycStatusColor: "black",
      poaDetails: [],
      partnerId: "",
      panDocVerification: {},
      mandateStatus: "",
      mandateNumber: "",
      productType: "",
      disbursalBankDetails: {},
      primaryCollectionType: "",
      secondaryCollectionType: "",
      userBankList: [],
      recommandedLendingPartner: "",
      lenderRejectionList: [],
      mandateMode: "",
      mandateRejectReason: "",
      kfsFile: "",
      videoPath: "",
      lenderError: "",
      lenderErrorDate: "",
      nocFile: "",   
    },
    optional: {
      month: moment().month() + 1,
      year: moment().year(),
      tsFromMonth: moment().month() + 1,
      tsFromYear: moment().year(),
      tsToMonth: moment().month() + 1,
      tsToYear: moment().year(),
    },
    optionalGst: {
      month: moment().month() + 1,
      year: moment().year(),
      tsFromMonth: moment().month() + 1,
      tsFromYear: moment().year(),
      tsToMonth: moment().month() + 1,
      tsToYear: moment().year(),
    },
    showSpinner: false,
    optionalDocumentList: [],
    errors: {},
    popUpPath: "",
    showPopUp: false,
    repaymentFrequencyList: [],
    partnerList: [],
    pageSize: 10,
    currentTransactionPage: 1,
    currentInvestmentPage: 1,
    currentTrueScorePage: 1,
    currentTransaction2Page: 1,
    currentLenderRejectionPage: 1,
    isSubmitted: false,
    currentLoanHistoryPage: 1,
    currentFundfinaLoanHistoryPage: 1,
    trueScoreList: [],
    transaction2List: [],
    displayTrueScore: false,
    displayTransaction1: true,
    monthList: [],
    yearList: [],
    rejectReasonList: [],
    bankDocDisplay: false,
    bankDoc: {
      bankName: "",
      branch: "",
      accountNo: "",
      ifsc: "",
      isVerified: "",
      docPath: "",
      nameWithBank: "",
      details: "",
      code: "",
      message: "",
    },
    gstDocDisplay: false,
    gstDoc: {
      gstNo: "",
      isVerified: "",
      corporateName: "",
      tradeName: "",
      legalName: "",
      address: "",
      ctb: "",
      rgdt: "",
      status: "",
      dty: "",
      docPath: "",
      details: "",
      code: "",
      message: "",
    },
    gstTableData:[],
    bankStatementTableData:{},
    panDocDisplay: false,
    panDoc: {
      panNo: "",
      type: "",
      isDocVerified: "",
      isNumberVerified: "",
      isDocMatch: "",
      is_pan_dob_valid: "",
      name_matched: "",
      name: "",
      fathers_name: "",
      id_no: "",
      dob: "",
      docPath: "",
      api: {
        details: "",
        code: "",
        message: "",
      },
      ocr: {
        details: "",
        code: "",
        message: "",
      },
    },
    poaDocDisplay: false,
    poaDoc: {
      poaNo: "",
      poaType: "",
      poaDoc: "",
      poaBackDoc: "",
      docVerification: {
        isDocVerified: "",
        isNumberVerified: "",
        isDocMatch: "",
        type: "",
        docType: "",
        dl: {
          ocr: {
            name: "",
            id_no: "",
            dob: "",
            id_type: "",
          },
          verificationResponse: {
            verified: "",
            Date_Of_Issue: "",
            NT: "",
            Old_New_DL_No: "",
            Holders_Name: "",
            Hazardous_Valid_Till: "",
            NonTransport: "",
            Transport: "",
            CurrentStatus: "",
            LastTransactionAt: "",
          },
        },
        passport: {
          ocr: {
            name: "",
            id_no: "",
            dob: "",
            id_type: "",
            gender: "",
            country_code: "",
            sur_name: "",
            nationality: "",
            place_of_birth: "",
            place_of_issue: "",
            photoPath: "",
          },
        },
        uid: {
          ocr: {
            name: "",
            id_no: "",
            dob: "",
            id_type: "",
            gender: "",
            address: "",
            photoPath: "",
            address_information: {
              address: "",
              locality_or_post_office: "",
              district_or_city: "",
              state: "",
              pincode: "",
            },
          },
        },
        voterId: {
          ocr: {
            name: "",
            id_no: "",
            fathers_name: "",
            id_type: "",
            gender: "",
            address: "",
            date_of_issue: "",
            photoPath: "",
            address_information: {
              address: "",
              locality_or_post_office: "",
              district_or_city: "",
            },
          },
          verificationResponse: {
            verified: "",
            result_id_no: "",
            result_name: "",
            pc_name: "",
            st_code: "",
            ps_lat_long_1_coordinate: "",
            gender: "",
            rln_name_v2: "",
            rln_name_v1: "",
            rln_name_v3: "",
            name_v1: "",
            epic_no: "",
            ac_name: "",
            name_v2: "",
            name_v3: "",
            ps_lat_long: "",
            pc_no: "",
            last_update: "",
            id: "",
            dist_no: "",
            ps_no: "",
            pc_name: "",
            ps_name_v1: "",
            st_name: "",
            dist_name: "",
            rln_type: "",
            pc_name_v1: "",
            part_name_v1: "",
            ac_name_v1: "",
            part_no: "",
            dist_name_v1: "",
            ps_lat_long_0_coordinate: "",
            _version_: "",
            name: "",
            section_no: "",
            ac_no: "",
            slno_inpart: "",
            rln_name: "",
            age: "",
            part_name: "",
            id_no: "",
          },
        },
      },
      api: {
        details: "",
        code: "",
        message: "",
      },
      ocr: {
        details: "",
        code: "",
        message: "",
      },
    },
    panDocVerification: {},
    showEnachPopUp: false,
    isNewMandateSubmitted: false,
    activeTab: "loanTab",
    label: [],
    score: [],
    creditLimit:{
      gstBasedLimit:"",
      bankStatementBasedLimit:""
    },
    gstInferanceDetails:{
      totalRevenue:"",
      averageMonthlyRevenue:"",
      grossMargin:"",
      grossMarginPercentage:""
    },
    trueScoreAmountDetails:{
       hasPreApprovedAmmount:false,
       preApprovedAmount:"",
    },
    bankInferanceDetails:{},
    fromMonth: [],
    fromYear: [],
    toMonth: [],
    toYear: [],
    showTrueScorePopUp: false,
    trueScoreError: "",
    graphToggle: true,
    showBankLinkPopUp: false,
    sendUpdateMandateLink: false,
    showInfoPopup: false,
    infoMessage: "",
    infoHeader: "",
    contractUrl: "",
    settlementMode: "",
    photoMatchDropdownOptions: [
      {
        title: "Face Matches based on POA Document",
        value: "FACE_MATCH_BY_POA",
      },
      {
        title: "User Uploaded another PAN document",
        value: "ANOTHER_PAN",
      },
    ],
    userData: {},
    isDisabledContractButton: false,
    reviewReasonList: [],
    foreclosureDetails: {
      interestAmount: 0,
      edis: 0,
      principalPending: 0,
      inerestPending: 0,
      lateChargesPending: 0,
      pricipalOutstanding: 0,
      interestOutstanding: 0,
      edisOutstanding: 0,
      isWaiveOff: false,
      foreclosureReason: "",
    },
    isDisabledNocButton: false,
  };
  schema = {
    adminStatus: Joi.string().required(),
    rejectReason: Joi.string().allow(""),
    remarks: Joi.string().allow(""),
    maturity: Joi.number().allow(""),
    interestRate: Joi.number().allow(""),
    interestType: Joi.string().allow(""),
    repaymentFrequency: Joi.string().allow(""),
    partnerName: Joi.string().allow(""),
    tenure: Joi.number().allow(""),
    loanAmount: Joi.number().allow(""),
    adhocDays: Joi.number().allow(""),
    trancheType: Joi.string().allow(""),
    lenderComments: Joi.string().allow(""),
    photoMatchStatus: Joi.string().allow(""),
    photoMatchRemarks: Joi.string().allow(""),
    reviewReason: Joi.string().allow(""),
  };
  async loadDropdowns() {
    let {
      repaymentFrequencyList,
      partnerList,
      rejectReasonList,
      reviewReasonList,
    } = this.state;

    try {
      const { data: response } = await commonService.getDefinitionDetailsMaster(
        "RepaymentFrequency"
      );

      repaymentFrequencyList = response.data;

      const { data: partnerResponse } =
        await loanService.getLendingPartnerList();

      partnerList = partnerResponse.data;

      const rejectReasons = await loanService.getRejectReasonList("admin");
      rejectReasonList = rejectReasons.success ? rejectReasons.data : [];

      const { data: photoMatchConfig } =
        await commonService.getDefinitionDetailsMaster("PHOTO_MATCH_REMARK");

      let photoMatchDropdown = [];

      if (photoMatchConfig?.data) {
        photoMatchDropdown = photoMatchConfig.data.map((item) => {
          return { title: item.defination, value: item.value };
        });
      }

      const { data: reviewResponse } =
        await commonService.getDefinitionDetailsMaster("loan review reasons");

      reviewReasonList = reviewResponse.data;

      let stateObj = {
        repaymentFrequencyList,
        partnerList,
        rejectReasonList,
        reviewReasonList,
      };

      if (photoMatchDropdown && photoMatchDropdown?.length) {
        stateObj.photoMatchDropdownOptions = photoMatchDropdown;
      }

      this.setState(stateObj);
    } catch (ex) {}
  }

  handleOptionalValues =(key)=>({ currentTarget: input }) => {
    if(key==="gst"){
      const optionalGst = { ...this.state.optionalGst };
      optionalGst[input.name] = input.value;
  
      this.setState({ optionalGst });
    }
    else{
      const optional = { ...this.state.optional };
      optional[input.name] = input.value;
  
      this.setState({ optional });
    }
  };

  getMonthListFromYear(year) {
    let currentYear = moment().year();

    if (year < currentYear) {
      return [
        {
          text: "January",
          value: 1,
        },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ];
    } else {
      let currentMonth = moment().month() + 1;

      if (currentMonth === 1)
        return [
          {
            text: "January",
            value: 1,
          },
        ];

      if (currentMonth === 2)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
        ];

      if (currentMonth === 3)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
        ];

      if (currentMonth === 4)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
        ];

      if (currentMonth === 5)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
        ];

      if (currentMonth === 6)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
          { text: "June", value: 6 },
        ];

      if (currentMonth === 7)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
          { text: "June", value: 6 },
          { text: "July", value: 7 },
        ];

      if (currentMonth === 8)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
          { text: "June", value: 6 },
          { text: "July", value: 7 },
          { text: "August", value: 8 },
        ];

      if (currentMonth === 9)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
          { text: "June", value: 6 },
          { text: "July", value: 7 },
          { text: "August", value: 8 },
          { text: "September", value: 9 },
        ];

      if (currentMonth === 10)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
          { text: "June", value: 6 },
          { text: "July", value: 7 },
          { text: "August", value: 8 },
          { text: "September", value: 9 },
          { text: "October", value: 10 },
        ];

      if (currentMonth === 11)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
          { text: "June", value: 6 },
          { text: "July", value: 7 },
          { text: "August", value: 8 },
          { text: "September", value: 9 },
          { text: "October", value: 10 },
          { text: "November", value: 11 },
        ];

      if (currentMonth === 12)
        return [
          {
            text: "January",
            value: 1,
          },
          { text: "February", value: 2 },
          { text: "March", value: 3 },
          { text: "April", value: 4 },
          { text: "May", value: 5 },
          { text: "June", value: 6 },
          { text: "July", value: 7 },
          { text: "August", value: 8 },
          { text: "September", value: 9 },
          { text: "October", value: 10 },
          { text: "November", value: 11 },
          { text: "December", value: 12 },
        ];
    }
  }
  async mapDataToState(userData) {
    let staticData = { ...this.state.staticData };
    let files = { ...this.state.files };
    let data = { ...this.state.data };
    let optional = { ...this.state.optional };

    let {
      user,
      loan,
      transactionDetails,
      investments,
      loanHistory,
      mandateNumber,
      mandateStatus,
      displayTrueScore,
      userBankDetails,
      sendUpdateMandateLink,
      recommandedLendingPartner,
      lendingPartnerName,
      settlementMode,
      trancheType,
      lenderRejectionList,
      mandateMode,
      mandateRejectReason,
    } = userData;
    let {
      userDetails,
      addressDetails,
      gstDetails,
      poaDetails,
      panDocDetails,
      additionalDocuments,
      udyogAadhaar,
      udyamAadhaar,
      profileDocDetails,
      questionnairreDetails,
      shopDetails,
      panDocVerification,
    } = user;
    const currentUser = auth.getCurrentUser();
    //User Details
    data.adminStatus = "";
    if (loan.repaymentFrequency)
      data.repaymentFrequency = loan.repaymentFrequency;
    if (loan.tenure) data.tenure = loan.tenure;
    if (loan.amount) data.loanAmount = loan.amount;
    if (loan.adhocDays) data.adhocDays = loan.adhocDays;
    if (loan.interestRate) data.interestRate = loan.interestRate;
    if (loan.maturity) data.maturity = loan.maturity;
    if (loan.merchantType) staticData.merchantType = loan.merchantType;

    if (loan._id) staticData.loanId = loan._id;
    if (user._id) staticData.userId = user._id;
    if (userDetails.firstName) staticData.firstName = userDetails.firstName;
    if (userDetails.middleName) staticData.middleName = userDetails.middleName;
    if (userDetails.lastName) staticData.lastName = userDetails.lastName;
    if (userDetails.role) staticData.userRole = userDetails.role;
    if (userDetails.kycStatus) staticData.kycStatus = userDetails.kycStatus;
    if (userDetails.clientCode) staticData.clientCode = userDetails.clientCode;
    if (loan.photoMatch) staticData.photoMatch = loan.photoMatch;
    staticData.isParentLoan = loan.isParentLoan;
    if (questionnairreDetails)
      staticData.questionnairre = questionnairreDetails;
    if (userDetails.role) staticData.userRole = userDetails.role;
    if (userDetails.dob)
      staticData.dob = moment(userDetails.dob).format("DD/MM/yyyy");
    if (userDetails.doi)
      staticData.doi = moment(userDetails.doi).format("DD/MM/yyyy");
    if (userDetails.typeOfEntity)
      staticData.typeOfEntity = userDetails.typeOfEntity;
    if (loan.partner) staticData.partnerName = loan.partner;
    if (loan.partnerId) staticData.partnerId = loan.partnerId;
    if (recommandedLendingPartner)
      staticData.recommandedLendingPartner = recommandedLendingPartner;
    if (settlementMode) staticData.settlementMode = settlementMode;
    if (trancheType) data.trancheType = trancheType;

    let middleName = staticData.middleName ? ` ${staticData.middleName}` : "";
    staticData.fullName = `${staticData.firstName}${middleName} ${staticData.lastName}`;

    if (userDetails.employment) staticData.employment = userDetails.employment;
    if (userDetails.isRetailer) staticData.isRetailer = staticData.isRetailer;

    if (lendingPartnerName) data.partnerName = lendingPartnerName;

    if (currentUser.role) staticData.evaluatorRole = currentUser.role;
    if (shopDetails && shopDetails.length > 0)
      staticData.shopDetails = shopDetails;

    if (transactionDetails && transactionDetails.length > 0)
      staticData.transactionDetails = transactionDetails;

    if (investments && investments.length > 0)
      staticData.investments = investments;

    if (lenderRejectionList && lenderRejectionList.length > 0)
      staticData.lenderRejectionList = lenderRejectionList;

    if (loanHistory && loanHistory.length > 0)
      staticData.loanHistory = loanHistory;

    if (udyogAadhaar && udyogAadhaar?.aadhaarNo && udyogAadhaar?.addressDetails) {
      staticData.udyogAadhaar.udyogAadhaarNo = udyogAadhaar?.aadhaarNo;
      staticData.udyogAadhaar.addressDetails=udyogAadhaar?.addressDetails;
    }
    if (udyamAadhaar && udyamAadhaar?.aadhaarNo && udyamAadhaar?.officialAddress) {
      staticData.udyamAadhaar.udyamAadhaarNo = udyamAadhaar?.aadhaarNo;
      staticData.udyamAadhaar.officialAddress=udyamAadhaar?.officialAddress;
    }

    let displayTransaction1 = true;
    let valdisplayTrueScore = false;

    if (displayTrueScore) {
      displayTransaction1 = false;
      valdisplayTrueScore = true;
    }

    if (userDetails.kycStatus) {
      if (userDetails.kycStatus === "Approved")
        staticData.kycStatusColor = "green";
      if (userDetails.kycStatus === "Rejected")
        staticData.kycStatusColor = "red";
      if (userDetails.kycStatus === "Under Review")
        staticData.kycStatusColor = "orange";
      if (userDetails.kycStatus === "Review")
        staticData.kycStatusColor = "orange";
    }
    // // //Email and Mobile
    
    if (userDetails.emailId && userDetails.emailId[0]) {
      staticData.emailId = userDetails.emailId[0];
    }
    if (
      userDetails.mobileNo &&
      userDetails.mobileNo[0] &&
      userDetails.mobileNo[0].mobileNumber
    ) {
      staticData.mobileNo = userDetails.mobileNo[0].mobileNumber;
    }
    if (userDetails.emailId && userDetails.emailId[1]) {
      staticData.alternateEmail = userDetails.emailId[1];
    }
    if (
      userDetails.mobileNo &&
      userDetails.mobileNo[1] &&
      userDetails.mobileNo[1].mobileNumber
    ) {
      staticData.alternateMobile = userDetails.mobileNo[1].mobileNumber;
    }
    // // //Address Details
    if (addressDetails[0] && addressDetails[0].add1)
      staticData.add1 = addressDetails[0].add1;
    if (addressDetails[0] && addressDetails[0].add2)
      staticData.add2 = addressDetails[0].add2;
    if (addressDetails[0] && addressDetails[0].add3)
      staticData.add3 = addressDetails[0].add3;
    if (addressDetails[0] && addressDetails[0].city) {
      staticData.city = addressDetails[0].city;
    }
    if (addressDetails[0] && addressDetails[0].state) {
      staticData.state = addressDetails[0].state;
    }
    if (addressDetails[0] && addressDetails[0].pincode)
      staticData.pincode = addressDetails[0].pincode;
    if (user.panNo) {
      staticData.panNo = user.panNo;
    }
    if (staticData.add2) staticData.add2 = ` ${staticData.add2}`;
    staticData.fullAddress = `${staticData.add1}${staticData.add2} ${staticData.add3}`;
    // // //Bank Details
    
    if (loan.disbursalBankDetails)
      staticData.disbursalBankDetails = loan.disbursalBankDetails;

    // // //GST Details
    let gstList = [];

    for (const item of gstDetails) {
      let gst = {
        gstNo: item.gstNo ? item.gstNo : "",
        gstFile:
          item.docDetails[0] && item.docDetails[0].fileName
            ? item.docDetails[0].fileName
            : "",
        gstFilePath:
          item.docDetails[0] && item.docDetails[0].filePath
            ? item.docDetails[0].filePath
            : "",
        docVerification: item.docVerification ? item.docVerification : null,
        errorDetails: item.errorDetails ? item.errorDetails : null,
      };

      gstList.push(gst);
    }

    staticData.gstList = gstList;

    staticData.poaDetails = [];

    let poaVerificationType = "";

    // // //POA Details
    if (poaDetails && poaDetails.length > 0) {
      for (const item of poaDetails) {
        let poaNo = item.poaNo ? item.poaNo : "";
        let poaType = item.poaType ? item.poaType : "";
        poaVerificationType = item.poaVerificationDocType
          ? item.poaVerificationDocType
          : "";

        let obj = {
          poaNo: poaNo,
          poaType: poaType,
          poaDocType: poaVerificationType,
          docDetails: [],
          docVerification: item.docVerification ? item.docVerification : null,
          errorDetails: item.errorDetails ? item.errorDetails : null,
        };

        for (const docItem of item.docDetails) {
          let poaFileName = docItem.fileName ? docItem.fileName : "";
          let poaFilePath = docItem.filePath ? docItem.filePath : "";
          let poaDocPassword = docItem.documentPassword
            ? docItem.documentPassword
            : "";
          obj.docDetails.push({
            poaFileName: poaFileName,
            poaFilePath: poaFilePath,
            poaDocPassword: poaDocPassword,
          });
        }
        staticData.poaDetails.push(obj);
      }
    }

    if (panDocDetails[0] && panDocDetails[0].fileName) {
      staticData.panFileName = panDocDetails[0].fileName;
      staticData.panFilePath = panDocDetails[0].filePath;
    }

    if (
      profileDocDetails &&
      profileDocDetails[0] &&
      profileDocDetails[0].fileName
    ) {
      staticData.profileFileName = profileDocDetails[0].fileName;
      staticData.profileFilePath = profileDocDetails[0].filePath;
    }

    let optionalDocumentList = [];

    if (additionalDocuments && additionalDocuments.length > 0) {
      for (const item of additionalDocuments) {
        if (item.docDetails[0].fileName) {
          const addDocData = {
            fileName:
              item.docDetails[0] && item.docDetails[0].fileName
                ? item.docDetails[0].fileName
                : "",
            filePath:
              item.docDetails[0] && item.docDetails[0].filePath
                ? item.docDetails[0].filePath
                : "",
          };

          optionalDocumentList.push(addDocData);
        }
      }
    }

    //PanDocVerification

    let panDoc = {};

    if (panDocVerification) {
      panDoc = {
        panNo: user.panNo,
        type: panDocVerification.type ? panDocVerification.type : "",
        isDocVerified: panDocVerification.isDocVerified ? "Yes" : "No",
        isNumberVerified: panDocVerification.isNumberVerified ? "Yes" : "No",
        isDocMatch: panDocVerification.isDocMatch ? "Yes" : "No",
        name:
          panDocVerification.type &&
          panDocVerification.details &&
          panDocVerification.details.name
            ? panDocVerification.details.name
            : "",
        fathers_name:
          panDocVerification.type &&
          panDocVerification.details &&
          panDocVerification.details.fathers_name
            ? panDocVerification.details.fathers_name
            : "",
        id_no:
          panDocVerification.type &&
          panDocVerification.details &&
          panDocVerification.details.docNo
            ? panDocVerification.details.docNo
            : "",
        dob:
          panDocVerification.type &&
          panDocVerification.details &&
          panDocVerification.details.dob
            ? panDocVerification.details.dob
            : "",
        api: {
          details:
            panDocVerification.errorDetails &&
            panDocVerification.errorDetails.api &&
            panDocVerification.errorDetails.api.details
              ? panDocVerification.errorDetails.api.details
              : "",
          code:
            panDocVerification.errorDetails &&
            panDocVerification.errorDetails.api &&
            panDocVerification.errorDetails.api.code
              ? panDocVerification.errorDetails.api.code
              : "",
          message:
            panDocVerification.errorDetails &&
            panDocVerification.errorDetails.api &&
            panDocVerification.errorDetails.api.message
              ? panDocVerification.errorDetails.api.message
              : "",
        },
        ocr: {
          details:
            panDocVerification.errorDetails &&
            panDocVerification.errorDetails.ocr &&
            panDocVerification.errorDetails.ocr.details
              ? panDocVerification.errorDetails.ocr.details
              : "",
          code:
            panDocVerification.errorDetails &&
            panDocVerification.errorDetails.ocr &&
            panDocVerification.errorDetails.ocr.code
              ? panDocVerification.errorDetails.ocr.code
              : "",
          message:
            panDocVerification.errorDetails &&
            panDocVerification.errorDetails.ocr &&
            panDocVerification.errorDetails.ocr.message
              ? panDocVerification.errorDetails.ocr.message
              : "",
        },
      };
    }

    if (staticData.panFilePath)
      panDoc.docPath = staticData.panFilePath ? staticData.panFilePath : "";

    if (loan.primaryCollectionType)
      staticData.primaryCollectionType = loan.primaryCollectionType;
    if (loan.secondaryCollectionType)
      staticData.secondaryCollectionType = loan.secondaryCollectionType;

    //Mandate
    if (mandateNumber) staticData.mandateNumber = mandateNumber;
    if (mandateStatus) staticData.mandateStatus = mandateStatus;

    if (mandateStatus) staticData.mandateMode = mandateMode;

    if (mandateRejectReason)
      staticData.mandateRejectReason = mandateRejectReason;

    // //Loan Info

    if (loan.amount) staticData.amount = loan.amount;
    if (loan.merchantCode) staticData.merchantCode = loan.merchantCode;
    if (loan.purpose) staticData.purpose = loan.purpose;
    if (loan.description) staticData.description = loan.description;
    if (loan.campaignTime) staticData.campaignTime = loan.campaignTime;
    if (loan.repaymentFrequency) staticData.frequency = loan.repaymentFrequency;
    if (loan.interestType) staticData.interestType = loan.interestType;
    if (loan.interestType) data.interestType = loan.interestType;
    if (loan.loanNo) staticData.loanNo = loan.loanNo;
    if (loan.partner) staticData.partner = loan.partner;
    if (loan.status) staticData.loanStatus = loan.status;
    if (loan.postDisbursalStatus)
      staticData.postDisbursalStatus = loan.postDisbursalStatus;
    if (loan.insertedOn)
      staticData.loanGenDate = moment(loan.insertedOn).format("DD/MM/yyyy");
    if (loan.updatedOn)
      staticData.updationDate = moment(loan.updatedOn).format("DD/MM/yyyy");
    if (loan.disbursalDetails && loan.disbursalDetails.disbursedOn)
      staticData.disbursalDate = moment(
        loan.disbursalDetails.disbursedOn
      ).format("DD/MM/yyyy");
    if (loan.interestRate) staticData.roi = loan.interestRate;
    if (loan.loanType) staticData.loanType = loan.loanType;
    staticData.loanCategory =
      loan.partner === "Fundfina" ? "FutureSecure" : "MerchantBrisk";

    if (loan.loanContractDetails && loan.loanContractDetails.contractFile)
      staticData.contractFile = loan.loanContractDetails.contractFile;

    if (loan.loanContractDetails && loan.loanContractDetails.kfsFile)
      staticData.kfsFile = loan.loanContractDetails.kfsFile;

    if (loan.loanContractDetails && loan.loanContractDetails.nocFile)
      staticData.nocFile = loan.loanContractDetails.nocFile;

    if (loan.loanContractDetails && loan.loanContractDetails.photoFilePath) {
      let response = await this.getSignedURLToDownload(
        loan.loanContractDetails.photoFilePath
      );
      staticData.photoFileEncPath = response;
      staticData.photoFilePath = loan.loanContractDetails.photoFilePath;
    }

    if (
      loan.loanContractDetails &&
      loan.loanContractDetails.shopDocuments &&
      loan.loanContractDetails.shopDocuments[0] && loan.loanContractDetails.shopDocuments[0].shopInternalPhotoFilePath
    ) {
      let response = await this.getSignedURLToDownload(
        loan.loanContractDetails.shopDocuments[0].shopInternalPhotoFilePath
      );
      staticData.shopInternalPhotoFileEncPath = response;
      staticData.shopInternalPhotoFilePath = loan.loanContractDetails.shopDocuments[0].shopInternalPhotoFilePath
    }

    if (
      loan.loanContractDetails &&
      loan.loanContractDetails.shopDocuments &&
      loan.loanContractDetails.shopDocuments[0] && loan.loanContractDetails.shopDocuments[0].shopExternalPhotoFilePath
    ) {
      let response = await this.getSignedURLToDownload(
        loan.loanContractDetails.shopDocuments[0].shopExternalPhotoFilePath
      );
      staticData.shopExternalPhotoFileEncPath = response;
      staticData.shopExternalPhotoFilePath = loan.loanContractDetails.shopDocuments[0].shopExternalPhotoFilePath
    }

    if (loan.photoMatch?.videoPath) {
      let response = await this.getSignedURLToDownload(
        loan.photoMatch?.videoPath
      );
      staticData.videoPath = response;
    }

    if (loan.loanContractDetails)
      staticData.isContractSigned = loan.loanContractDetails.isAccepted
        ? "Yes"
        : "No";

    if (loan.merchantcode) staticData.agentCode = loan.merchantcode;
    if (loan.merchantcode) staticData.merchantCode = loan.merchantcode;

    if (loan.productType) staticData.productType = loan.productType;

    if (loan.maturity) {
      if (loan.repaymentFrequency === "Monthly")
        staticData.maturity = `${loan.maturity} months`;
      else if (loan.repaymentFrequency === "Daily")
        staticData.maturity = `${loan.maturity} days`;
      else if (loan.repaymentFrequency === "Weekly")
        staticData.maturity = `${loan.maturity} weeks`;
      else if (loan.repaymentFrequency === "Forthnightly")
        staticData.maturity = `${loan.maturity} forthnights`;
      else if (loan.repaymentFrequency === "Adhoc")
        staticData.maturity = `${loan.tenure} days`;
    }

    let months = [
      {
        text: "January",
        value: 1,
      },
      { text: "February", value: 2 },
      { text: "March", value: 3 },
      { text: "April", value: 4 },
      { text: "May", value: 5 },
      { text: "June", value: 6 },
      { text: "July", value: 7 },
      { text: "August", value: 8 },
      { text: "September", value: 9 },
      { text: "October", value: 10 },
      { text: "November", value: 11 },
      { text: "December", value: 12 },
    ];

    let years = [];

    for (let i = moment().year(); i >= 2018; i--) years.push(i);

    const currentDate = moment();
    const from6MonthDate = moment().subtract(6, "months");

    let toMonth = this.getMonthListFromYear(currentDate.year());
    let fromMonth = this.getMonthListFromYear(from6MonthDate.year());

    let fromYear = [];

    for (let i = currentDate.year(); i >= 2018; i--) fromYear.push(i);

    let toYear = [];

    for (let i = currentDate.year(); i >= 2018; i--) toYear.push(i);

    let tsToMonth = currentDate.month() + 1;
    let tsToYear = currentDate.year();
    let tsFromMonth = from6MonthDate.month() + 1;
    let tsFromYear = from6MonthDate.year();

    optional.tsToMonth = tsToMonth;
    optional.tsToYear = tsToYear;
    optional.tsFromMonth = tsFromMonth;
    optional.tsFromYear = tsFromYear;

    let bankList = [];

    if (loan.photoMatch?.isMatched) {
      staticData.isPhotoMatch = true;
    }

    for (const item of userBankDetails) {
      let bank = {
        accountNo: item.accountNo ? item.accountNo : "",
        ifsc: item.ifsc ? item.ifsc : "",
        bankName: item.bankName ? item.bankName : "",
        branch: item.branch ? item.branch : "",
        accountType: item.accountType ? item.accountType : "",
        docVerification: item.docVerification ? item.docVerification : null,
        errorDetails: item.errorDetails ? item.errorDetails : null,
      };

      bankList.push(bank);
    }

    staticData.userBankList = bankList;

    if (
      loan.primaryCollectionType === "eNach" ||
      loan.secondaryCollectionType === "eNach"
    )
      staticData.contractUrl = loan.loanContractDetails.eNachUrl;
    else if (
      loan.primaryCollectionType === "Wallet" &&
      loan.secondaryCollectionType === ""
    )
      staticData.contractUrl = loan.loanContractDetails.contractUrl;

    if (
      loan.lenderDetails &&
      loan.lenderDetails.length > 0 &&
      loan.lenderDetails[0].errorMsg != null
    )
      staticData.lenderError = loan.lenderDetails[0].errorMsg;

    if (
      loan.lenderDetails &&
      loan.lenderDetails.length > 0 &&
      loan.lenderDetails[0].errorDate != null
    )
      staticData.lenderErrorDate = moment(
        loan.lenderDetails[0].errorDate
      ).format("DD/MM/yyyy HH:MM:SS");
   
    this.setState({
      data,
      staticData,
      files,
      optionalDocumentList,
      displayTransaction1: displayTransaction1,
      displayTrueScore: valdisplayTrueScore,
      monthList: months,
      yearList: years,
      panDoc: panDoc,
      panDocVerification: panDocVerification ? panDocVerification : null,
      fromMonth: fromMonth,
      toMonth: toMonth,
      fromYear: fromYear,
      toYear: toYear,
      optional: optional,
      sendUpdateMandateLink,
      userData: userData,
    });

    if (loan.repaymentFrequency && loan.interestRate && loan.maturity)
      await this.calculateEmi(data);
    if (displayTrueScore) {
      // await this.getTransactions();
      // await this.getTrueScoreGraph();
      // await this.getTrueScore();
    }

    // await this.getFundfinaLoans();
  }

fetchBankStatement=async ()=>{
    let bankInferanceDetails={...this.state.bankInferanceDetails};
    let bankStatementTableData={...this.state.bankStatementTableData}
    this.setState({ showSpinner: true });
    try{
        const response=await loanService.fetchBankStatement(this.state.staticData.userId);
        if(response?.success){
            if(response?.bankInferanceDetails){
               bankInferanceDetails={...bankInferanceDetails,...response?.bankInferanceDetails};
            }
            if(response?.monthWiseData){
              bankStatementTableData=response?.monthWiseData;
            }
        }
        this.setState({ bankStatementTableData: bankStatementTableData , bankInferanceDetails : bankInferanceDetails ,showSpinner: false });
    }catch(err){
      this.setState({ showSpinner: false });
    }

  }

  getPreApprovalAmount=async ()=>{
    this.setState({ showSpinner: true });
    let trueScoreAmountDetails={...this.state.trueScoreAmountDetails}
    try{
        const response=await loanService.getPreApprovalAmount(this.state.staticData.partnerId, this.state.staticData.merchantCode);
        if(response?.success && response?.status==200 && response?.data){
           trueScoreAmountDetails={...trueScoreAmountDetails, preApprovedAmount : response?.data?.approvedAmount , hasPreApprovedAmmount:true }
           this.setState({ showSpinner: false , trueScoreAmountDetails: trueScoreAmountDetails});
        }
        this.setState({ showSpinner: false });
    }catch(err){
      this.setState({ showSpinner: false });
    } 
 }

  getTransactions = async () => {
      let staticData = { ...this.state.staticData };
      let optional = { ...this.state.optional };
      this.setState({ showSpinner: true });
      try {
        const { data: transactions } =
          await loanService.getPartnerUserTransactions(staticData, optional);
        this.setState({ showSpinner: false });
        if (transactions.success) {
          this.setState({ transaction2List: transactions.data });
        }
      } catch (ex) {
        this.setState({ transaction2List: [] });
        this.setState({ showSpinner: false });
      }
  };

  getFundfinaLoans = async () => {
    let staticData = { ...this.state.staticData };
    this.setState({ showSpinner: true });
    try {
      const { data: loans } = await loanService.getFundfinaLoans(
        staticData.userId
      );

      staticData.fundfinaLoanHistory = loans;
      this.setState({ staticData });
      this.setState({ showSpinner: false });
    } catch (ex) {
      staticData.fundfinaLoanHistory = [];
      this.setState({ staticData });
      this.setState({ showSpinner: false });
    }
  };

  getGstTransactions= async ()=>{
    this.setState({ showSpinner: true });
    if(!this.state.gstDoc.gstNo){
      console.log("GST Number is not added.");
      this.setState({ showSpinner: false });
      return ;
    }
    try {
      const { data: response } = await shopFormService.fetchGstDetails(
        this.state.staticData.userId,
        this.state.gstDoc.gstNo
      );
     
      if(response?.data?.metrics){
        let gstInferanceDetails={...this.state.gstInferanceDetails}
        let metrics=response?.data?.metrics
        for(let i=0;i< response?.data?.metrics.length;i++){
            let e=metrics[i];
            if(e?.datapoint?.Metric?.kindstr==="TotalRevenue"){
              gstInferanceDetails={...gstInferanceDetails,totalRevenue : e?.datapoint?.Metric?.value?.value}
            }
            else if(e?.datapoint?.Metric?.kindstr==="AverageMonthlyRevenue"){
              gstInferanceDetails={...gstInferanceDetails, averageMonthlyRevenue : e?.datapoint?.Metric?.value?.value}
            }
            else if(e?.datapoint?.Metric?.name==="Gross Margin"){
              gstInferanceDetails={...gstInferanceDetails, grossMargin : e?.datapoint?.Metric?.value?.value}
            }
            else if(e?.datapoint?.Metric?.name==="Gross Margin %"){
              gstInferanceDetails={...gstInferanceDetails, grossMarginPercentage : e?.datapoint?.Metric?.value?.value}
            }
        }
        
        this.setState({ gstInferanceDetails });
      }
      if(response?.data?.entries){
        let optionalGst = { ...this.state.optionalGst };
        let filterEntries= response?.data?.entries.filter((e)=>{
            console.log("eee",e?.period?.start.split("-"),optionalGst) 
            if(e?.period?.start.split("-")[0]==optionalGst.year &&(e?.period?.start.split("-")[1]==optionalGst.month||e?.period?.start.split("-")[1]=="0"+optionalGst.month.toString()) ){
              return true;
            }
            return false;
        })
        this.setState({ gstTableData: filterEntries });
      }
      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false,gstTableData:[] });
    }
  };
  
  async getTrueScore() {
    let staticData = { ...this.state.staticData };
    try {
      const { data: trueScore } = await loanService.getPartnerUserTrueScores(
        staticData
      );

      if (trueScore.success) {
        this.setState({ trueScoreList: trueScore.data });
      }
    } catch (ex) {
      this.setState({ trueScoreList: [] });
    }
  }

  getGstBasedCreditLimit=async () =>{
    let staticData = { ...this.state.staticData };
    let creditLimit={...this.state.creditLimit}
    try {
      const response =await loanService.gstBasedCreditLimit(
          staticData.userId
        );
      console.log("status",response.status)
      if (response?.status===200) {
        creditLimit={...creditLimit, gstBasedLimit : response?.data?.creditLimit};
        this.setState({
          creditLimit
        });
      }
    } catch (ex) {
      this.setState({ creditLimit});
    }
    //this.getBankStatementBasedCreditLimit();
  }

  getBankStatementBasedCreditLimit=async () =>{
    let staticData = { ...this.state.staticData };
    let creditLimit={...this.state.creditLimit};
    try {
      const response =await loanService.bankStatementBasedCreditLimit(
          staticData.userId
        );
      console.log("status",response.status)
      if (response?.status===200) {
        creditLimit={...creditLimit, bankStatementBasedLimit : response?.data?.creditLimit};
        this.setState({
          creditLimit
        });
      }
    } catch (ex) {
      this.setState({ creditLimit });
    }
  }

  getTrueScoreGraph = async () => {
    let staticData = { ...this.state.staticData };
    let optional = { ...this.state.optional };

    if (
      !optional.tsFromMonth ||
      !optional.tsFromYear ||
      !optional.tsToMonth ||
      !optional.tsToYear
    ) {
      this.setState({
        showTrueScorePopUp: true,
        trueScoreError: "Please select all search parameters",
      });
      return;
    }

    let fromDate = moment([optional.tsFromYear, optional.tsFromMonth - 1, 1]);
    let toDate = moment([optional.tsToYear, optional.tsToMonth - 1, 1]);

    const monthDifference = moment(toDate).diff(fromDate, "months", true);

    if (monthDifference < 0) {
      this.setState({
        showTrueScorePopUp: true,
        trueScoreError: "From Date cannot be greater than To Date",
      });
      return;
    }

    if (monthDifference > 12) {
      this.setState({
        showTrueScorePopUp: true,
        trueScoreError: "Date selection range cannot be more than 1 year",
      });
      return;
    }

    this.setState({ showSpinner: true });

    try {
      const { data: trueScore } =
        await loanService.getPartnerUserTrueScoresGraph(
          staticData.partnerId,
          staticData.merchantCode,
          optional
        );

      if (trueScore.success) {
        this.setState({
          label: trueScore.data.months,
          score: trueScore.data.trueScores,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ label: [], score: [], showSpinner: false });
    }
  };
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    const currentUser = auth.getCurrentUser();

    if (currentUser.role !== "Admin" && currentUser.role !== "Lending Partner")
      return (window.location = "/");

    if (this.props.activeTab) this.handleSelect(this.props.activeTab);
    // this.setState({ activeTab: this.props.activeTab });

    let loanId = "";

    if (this.props.loanId) {
      loanId = this.props.loanId;
    } else {
      loanId = this.props.match.params.id;
    }

    if (!loanId) {
      return (window.location = "/loanModule");
    } else {
      await this.getLoanData(loanId);
      await this.loadDropdowns();

      if (
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.isFrom &&
        this.props.match.params.isFrom == "foreclosure"
      ) {
        await this.getForeClosureDetails();
      }
    }
   
  }

  async getLoanData(loanId) {
    this.setState({ showSpinner: true });
    try {
      const { data: loanDetails } = await loanService.getLoanData(loanId);

      if (loanDetails.success) {
        await this.mapDataToState(loanDetails.data);
        this.setState({ showSpinner: false });
      }

      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  handleCustomChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });

    await this.calculateEmi(data);
  };

  handleTrueScoreChange = async ({ currentTarget: input }) => {
    let optional = { ...this.state.optional };
    optional[input.name] = parseInt(input.value);

    if (input.name === "tsFromYear") {
      let fromMonth = this.getMonthListFromYear(parseInt(input.value));
      this.setState({ fromMonth: fromMonth });
      optional.tsFromMonth = "";
    } else if (input.name === "tsToYear") {
      let toMonth = this.getMonthListFromYear(parseInt(input.value));
      this.setState({ toMonth: toMonth });
      optional.tsToMonth = "";
    }

    this.setState({ optional });
  };
  calculateEmi = async (data) => {
    let staticData = { ...this.state.staticData };
    if (
      !staticData.amount ||
      !data.interestRate ||
      !data.repaymentFrequency ||
      !data.tenure
    ) {
      staticData.emi = "";
      staticData.instalments = "";
      data.maturity = 0;
      this.setState({ staticData });
      return;
    }

    try {
      const { data: emi } = await loanService.getEmi(staticData, data);

      if (emi.success) {
        staticData.emi = emi.data[0].rePaymentAmount;
        staticData.instalments = emi.data.length;
        data.maturity = emi.data.length;
        this.setState({ data, staticData, showSpinner: false });
      } else {
        staticData.emi = "";
        staticData.instalments = "";
        data.maturity = 0;
        this.setState({ data, staticData, showSpinner: false });
      }
    } catch (ex) {
      staticData.emi = "";
      staticData.instalments = "";
      data.maturity = 0;
      this.setState({ data, staticData, showSpinner: false });
    }
  };
  async handleCustomErrors() {
    const data = { ...this.state.data };
    let errors = { ...this.state.errors };
    let staticData = { ...this.state.staticData };

    delete errors["globalError"];

    if (!data.adminStatus) errors["adminStatus"] = "Please select loan status";
    else delete errors["adminStatus"];

    if (data.adminStatus) {
      if (
        data.adminStatus !== "Approved By Admin" &&
        data.adminStatus !== "Loan Is Funding" &&
        data.adminStatus !== "Rejected By Admin" &&
        data.rejectReason === "Others" &&
        data.remarks.trim() === ""
      ) {
        errors["remarks"] = "Please enter remarks";
      } else {
        delete errors["remarks"];
      }

      if (data.adminStatus === "Approved By Admin") {
        if (!errors.maturity) {
          if (!data.maturity) errors["maturity"] = "Please select maturity";
          else delete errors["maturity"];
        }

        if (!errors.interestRate) {
          if (!data.interestRate)
            errors["interestRate"] = "Please select rate of interest";
          else delete errors["interestRate"];
        }

        if (!errors.repaymentFrequency) {
          if (!data.repaymentFrequency)
            errors["repaymentFrequency"] = "Please select repayment frequency";
          else delete errors["repaymentFrequency"];
        }
      }

      if (data.adminStatus === "Lender Assigned") {
        if (!errors.partnerName) {
          if (!data.partnerName)
            errors["partnerName"] = "Please select lending partner";
          else delete errors["partnerName"];
        }

        if (!errors.trancheType) {
          if (staticData.settlementMode === "flat" && !data.trancheType)
            errors["trancheType"] = "Please select tranche type";
          else delete errors["trancheType"];
        }
      }

      if (
        data.adminStatus === "Rejected By Lender" &&
        data.lenderComments.trim() === ""
      ) {
        errors["lenderComments"] = "Please enter rejection reason";
      } else {
        delete errors["lenderComments"];
      }

      if (data.adminStatus === "Review") {
        if (!errors.reviewReason) {
          if (!data.reviewReason)
            errors["reviewReason"] = "Please select reason for review";
          else delete errors["reviewReason"];
        }

        if (!errors.remarks && data.remarks.trim() === "") {
          errors["remarks"] = "Please enter remarks";
        } else {
          delete errors["remarks"];
        }
      }
    }

    if (
      staticData.loanStatus === "Loan Contract Accepted" &&
      staticData.kycStatus === "Approved" &&
      !staticData.isPhotoMatch
    ) {
      if (
        data.adminStatus == "Lender Assigned" &&
        data.photoMatchStatus != "YES"
      )
        errors["photoMatchStatus"] = "Invalid user image";
      else delete errors["photoMatchStatus"];

      if (
        data.adminStatus == "Lender Assigned" &&
        data.photoMatchStatus == "YES" &&
        !data.photoMatchRemarks
      )
        errors["photoMatchRemarks"] = "Photo match remark should not be empty";
      else delete errors["photoMatchRemarks"];
    }

    this.setState({ errors });

    return errors;
  }
  async updateLoanStatus() {
    let data = { ...this.state.data };
    let staticData = { ...this.state.staticData };
    let errors = { ...this.state.errors };

    this.setState({ showSpinner: true, isSubmitted: true });

    try {
      const { data: response } = await loanService.updateLoanStatus(
        staticData.loanId,
        data,
        await commonService.getIpAddress()
      );

      if (response.success) {
        //await this.getLoanData(staticData.loanId);
        this.setState({ showSpinner: false, isSubmitted: false });
        window.location.href = "/loanModule";
      } else {
        errors["globalError"] = response.message;
        this.setState({ errors });

        this.setState({ showSpinner: false, isSubmitted: false });
      }
    } catch (ex) {
      errors["globalError"] = ex.response.data.message;
      this.setState({ showSpinner: false, isSubmitted: false, errors });
    }
  }
  handleTransactionPageChange = (page) => {
    this.setState({ currentTransactionPage: page });
  };
  handleTransactionPagePrevious = () => {
    let { currentTransactionPage } = this.state;
    this.setState({ currentTransactionPage: currentTransactionPage - 1 });
  };
  handleTransactionPageNext = () => {
    let { currentTransactionPage } = this.state;
    this.setState({ currentTransactionPage: currentTransactionPage + 1 });
  };
  handleInvestmentPageChange = (page) => {
    this.setState({ currentInvestmentPage: page });
  };
  handleLenderRejectionPageChange = (page) => {
    this.setState({ currentLenderRejectionPage: page });
  };
  handleLenderRejectionListPagePrevious = () => {
    let { currentLenderRejectionPage } = this.state;
    this.setState({
      currentLenderRejectionPage: currentLenderRejectionPage - 1,
    });
  };
  handleLenderRejectionListPageNext = () => {
    let { currentLenderRejectionPage } = this.state;
    this.setState({
      currentLenderRejectionPage: currentLenderRejectionPage + 1,
    });
  };
  handleInvestmentPagePrevious = () => {
    let { currentInvestmentPage } = this.state;
    this.setState({ currentInvestmentPage: currentInvestmentPage - 1 });
  };
  handleInvestmentPageNext = () => {
    let { currentInvestmentPage } = this.state;
    this.setState({ currentInvestmentPage: currentInvestmentPage + 1 });
  };
  handleLoanHistoryPageChange = (page) => {
    this.setState({ currentLoanHistoryPage: page });
  };
  handleLoanHistoryPagePrevious = () => {
    let { currentLoanHistoryPage } = this.state;
    this.setState({ currentLoanHistoryPage: currentLoanHistoryPage - 1 });
  };
  handleLoanHistoryPageNext = () => {
    let { currentLoanHistoryPage } = this.state;
    this.setState({ currentLoanHistoryPage: currentLoanHistoryPage + 1 });
  };
  handleFundfinaLoanHistoryPageChange = (page) => {
    this.setState({ currentFundfinaLoanHistoryPage: page });
  };
  handleFundfinaLoanHistoryPagePrevious = () => {
    let { currentFundfinaLoanHistoryPage } = this.state;
    this.setState({
      currentFundfinaLoanHistoryPage: currentFundfinaLoanHistoryPage - 1,
    });
  };
  handleFundfinaLoanHistoryPageNext = () => {
    let { currentFundfinaLoanHistoryPage } = this.state;
    this.setState({
      currentFundfinaLoanHistoryPage: currentFundfinaLoanHistoryPage + 1,
    });
  };
  handleTrueScorePageChange = (page) => {
    this.setState({ currentTrueScorePage: page });
  };
  handleTrueScorePagePrevious = () => {
    let { currentTrueScorePage } = this.state;
    this.setState({ currentTrueScorePage: currentTrueScorePage - 1 });
  };
  handleTrueScorePageNext = () => {
    let { currentTrueScorePage } = this.state;
    this.setState({ currentTrueScorePage: currentTrueScorePage + 1 });
  };
  handleTransactions2PageChange = (page) => {
    this.setState({ currentTransaction2Page: page });
  };
  handleTransactions2PagePrevious = () => {
    let { currentTransaction2Page } = this.state;
    this.setState({ currentTransaction2Page: currentTransaction2Page - 1 });
  };
  handleTransaction2PageNext = () => {
    let { currentTransaction2Page } = this.state;
    this.setState({ currentTransaction2Page: currentTransaction2Page + 1 });
  };
  showBankDocInfo = (bank) => {
    let bankDoc = {
      bankName: bank.bankName ? bank.bankName : "",
      branch: bank.branch ? bank.branch : "",
      accountNo: bank.accountNo ? bank.accountNo : "",
      ifsc: bank.ifsc ? bank.ifsc : "",
      isVerified:
        bank.docVerification && bank.docVerification.isBankVerified
          ? "Yes"
          : "No",
      docPath: bank.bankFilePath ? bank.bankFilePath : "",
      nameWithBank:
        bank.docVerification && bank.docVerification.beneficiary_name_with_bank
          ? bank.docVerification.beneficiary_name_with_bank
          : "",
      details:
        bank.errorDetails && bank.errorDetails.details
          ? bank.errorDetails.details
          : "",
      code:
        bank.errorDetails && bank.errorDetails.code
          ? bank.errorDetails.code
          : "",
      message:
        bank.errorDetails && bank.errorDetails.message
          ? bank.errorDetails.message
          : "",
    };

    this.setState({ bankDoc: bankDoc, bankDocDisplay: true });
  };
  hideBankDocInfo = () => {
    this.setState({ bankDocDisplay: false });
  };
  showGstDocInfo = async (gst) => {
    let url = gst?.gstFilePath
      ? await this.getSignedURLToDownload(gst?.gstFilePath)
      : "";

    let floor =
      gst.docVerification &&
      gst.docVerification.addr &&
      gst.docVerification.addr.flno
        ? gst.docVerification.addr.flno + ", "
        : "";
    let block =
      gst.docVerification &&
      gst.docVerification.addr &&
      gst.docVerification.addr.bno
        ? gst.docVerification.addr.bno + ", "
        : "";
    let sector =
      gst.docVerification &&
      gst.docVerification.addr &&
      gst.docVerification.addr.st
        ? gst.docVerification.addr.st + ", "
        : "";
    let location =
      gst.docVerification &&
      gst.docVerification.addr &&
      gst.docVerification.addr.loc
        ? gst.docVerification.addr.loc + ", "
        : "";
    let city =
      gst.docVerification &&
      gst.docVerification.addr &&
      gst.docVerification.addr.dst
        ? gst.docVerification.addr.dst + ", "
        : "";
    let state =
      gst.docVerification &&
      gst.docVerification.addr &&
      gst.docVerification.addr.stcd
        ? gst.docVerification.addr.stcd + ", "
        : "";
    let pincode =
      gst.docVerification &&
      gst.docVerification.addr &&
      gst.docVerification.addr.pncd
        ? gst.docVerification.addr.pncd
        : "";

    let address = floor + block + sector + location + city + state + pincode;

    let gstDoc = {
      gstNo: gst.gstNo ? gst.gstNo : "",
      isVerified:
        gst.docVerification && gst.docVerification.isGstVerified ? "Yes" : "No",
      corporateName:
        gst.docVerification && gst.docVerification.corporate_name
          ? gst.docVerification.corporate_name
          : "",
      tradeName:
        gst.docVerification && gst.docVerification.trade_nam
          ? gst.docVerification.trade_nam
          : "",
      legalName:
        gst.docVerification &&
        gst.docVerification.details &&
        gst.docVerification.details.lgnm
          ? gst.docVerification.details.lgnm
          : "",
      address: address ? address : "",
      ctb:
        gst.docVerification &&
        gst.docVerification.details &&
        gst.docVerification.details.ctb
          ? gst.docVerification.details.ctb
          : "",
      rgdt:
        gst.docVerification &&
        gst.docVerification.details &&
        gst.docVerification.details.rgdt
          ? gst.docVerification.details.rgdt
          : "",
      status:
        gst.docVerification && gst.docVerification.sts
          ? gst.docVerification.sts
          : "",
      dty:
        gst.docVerification &&
        gst.docVerification.details &&
        gst.docVerification.details.dty
          ? gst.docVerification.details.dty
          : "",
      docPath: gst.gstFilePath ? gst.gstFilePath : "",
      details:
        gst.errorDetails && gst.errorDetails.details
          ? gst.errorDetails.details
          : "",
      code:
        gst.errorDetails && gst.errorDetails.code ? gst.errorDetails.code : "",
      message:
        gst.errorDetails && gst.errorDetails.message
          ? gst.errorDetails.message
          : "",
      gstDoc: url,
    };

    this.setState({ gstDoc: gstDoc, gstDocDisplay: true });
  };
  hideGstDocInfo = () => {
    this.setState({ gstDocDisplay: false });
  };

  showPanDocInfo = async () => {
    let { panDoc } = this.state;
    let url = panDoc?.docPath
      ? await this.getSignedURLToDownload(panDoc?.docPath)
      : "";
    panDoc.panDoc = url;
    this.setState({ panDocDisplay: true, panDoc });
  };

  hidePanDocInfo = () => {
    this.setState({ panDocDisplay: false });
  };

  showPoaDocInfo = async (poa) => {
    let url1 =
      poa.docDetails && poa.docDetails[0] && poa.docDetails[0].poaFilePath
        ? await this.getSignedURLToDownload(poa.docDetails[0].poaFilePath)
        : "";
    let url2 =
      poa.docDetails && poa.docDetails[1] && poa.docDetails[1].poaFilePath
        ? await this.getSignedURLToDownload(poa.docDetails[1].poaFilePath)
        : "";

    if (poa.docVerification?.verificationType === "DRIVING_LICENSE") {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified:
            poa.docVerification && poa.docVerification.isDocVerified
              ? "Yes"
              : "No",
          isNumberVerified:
            poa.docVerification && poa.docVerification.isNumberVerified
              ? "Yes"
              : "No",
          isDocMatch:
            poa.docVerification && poa.docVerification.isDocMatch
              ? "Yes"
              : "No",
          type:
            poa.docVerification && poa.docVerification.type
              ? poa.docVerification.type
              : "",
          docType:
            poa.docVerification && poa.docVerification.verificationType
              ? poa.docVerification.verificationType
              : "",
          dl: {
            ocr: {
              name:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.ocr &&
                poa.docVerification.dl.ocr.name
                  ? poa.docVerification.dl.ocr.name
                  : "",
              id_no:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.ocr &&
                poa.docVerification.dl.ocr.id_no
                  ? poa.docVerification.dl.ocr.id_no
                  : "",
              dob:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.ocr &&
                poa.docVerification.dl.ocr.dob
                  ? poa.docVerification.dl.ocr.dob
                  : "",
              id_type:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.ocr &&
                poa.docVerification.dl.ocr.id_type
                  ? poa.docVerification.dl.ocr.id_type
                  : "",
            },
            verificationResponse: {
              verified:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.verified
                  ? "Yes"
                  : "No",
              Date_Of_Issue:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.Date_Of_Issue
                  ? poa.docVerification.dl.verificationResponse.Date_Of_Issue
                  : "",
              NT:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.NT
                  ? poa.docVerification.dl.verificationResponse.NT
                  : "",
              Old_New_DL_No:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.Old_New_DL_No
                  ? poa.docVerification.dl.verificationResponse.Old_New_DL_No
                  : "",
              Holders_Name:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.Holders_Name
                  ? poa.docVerification.dl.verificationResponse.Holders_Name
                  : "",
              Hazardous_Valid_Till:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.Hazardous_Valid_Till
                  ? poa.docVerification.dl.verificationResponse
                      .Hazardous_Valid_Till
                  : "",
              NonTransport:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.NonTransport
                  ? poa.docVerification.dl.verificationResponse.NonTransport
                  : "",
              Transport:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.Transport
                  ? poa.docVerification.dl.verificationResponse.Transport
                  : "",
              CurrentStatus:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.CurrentStatus
                  ? poa.docVerification.dl.verificationResponse.CurrentStatus
                  : "",
              LastTransactionAt:
                poa.docVerification &&
                poa.docVerification.dl &&
                poa.docVerification.dl.verificationResponse &&
                poa.docVerification.dl.verificationResponse.LastTransactionAt
                  ? poa.docVerification.dl.verificationResponse
                      .LastTransactionAt
                  : "",
            },
          },
        },
        api: {
          details:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.details
              ? poa.errorDetails.api.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.code
              ? poa.errorDetails.api.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.message
              ? poa.errorDetails.api.message
              : "",
        },
        ocr: {
          details:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.details
              ? poa.errorDetails.ocr.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.code
              ? poa.errorDetails.ocr.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.message
              ? poa.errorDetails.ocr.message
              : "",
        },
      };

      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    } else if (poa.docVerification?.verificationType === "PASSPORT") {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified:
            poa.docVerification && poa.docVerification.isDocVerified
              ? "Yes"
              : "No",
          isNumberVerified:
            poa.docVerification && poa.docVerification.isNumberVerified
              ? "Yes"
              : "No",
          isDocMatch:
            poa.docVerification && poa.docVerification.isDocMatch
              ? "Yes"
              : "No",
          type:
            poa.docVerification && poa.docVerification.type
              ? poa.docVerification.type
              : "",
          docType:
            poa.docVerification && poa.docVerification.verificationType
              ? poa.docVerification.verificationType
              : "",
          passport: {
            ocr: {
              name:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.name
                  ? poa.docVerification.passport.ocr.name
                  : "",
              id_no:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.id_no
                  ? poa.docVerification.passport.ocr.id_no
                  : "",
              dob:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.dob
                  ? poa.docVerification.passport.ocr.dob
                  : "",
              id_type:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.id_type
                  ? poa.docVerification.passport.ocr.id_type
                  : "",
              gender:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.gender
                  ? poa.docVerification.passport.ocr.gender
                  : "",
              country_code:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.country_code
                  ? poa.docVerification.passport.ocr.country_code
                  : "",
              sur_name:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.sur_name
                  ? poa.docVerification.passport.ocr.sur_name
                  : "",
              nationality:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.nationality
                  ? poa.docVerification.passport.ocr.nationality
                  : "",
              place_of_birth:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.place_of_birth
                  ? poa.docVerification.passport.ocr.place_of_birth
                  : "",
              place_of_issue:
                poa.docVerification &&
                poa.docVerification.passport &&
                poa.docVerification.passport.ocr &&
                poa.docVerification.passport.ocr.place_of_issue
                  ? poa.docVerification.passport.ocr.place_of_issue
                  : "",
            },
          },
        },
        api: {
          details:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.details
              ? poa.errorDetails.api.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.code
              ? poa.errorDetails.api.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.message
              ? poa.errorDetails.api.message
              : "",
        },
        ocr: {
          details:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.details
              ? poa.errorDetails.ocr.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.code
              ? poa.errorDetails.ocr.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.message
              ? poa.errorDetails.ocr.message
              : "",
        },
      };
      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    } else if (poa.docVerification?.verificationType === "AADHAAR") {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified:
            poa.docVerification && poa.docVerification.isDocVerified
              ? "Yes"
              : "No",
          isNumberVerified:
            poa.docVerification && poa.docVerification.isNumberVerified
              ? "Yes"
              : "No",
          isDocMatch:
            poa.docVerification && poa.docVerification.isDocMatch
              ? "Yes"
              : "No",
          type:
            poa.docVerification && poa.docVerification.type
              ? poa.docVerification.type
              : "",
          docType:
            poa.docVerification && poa.docVerification.verificationType
              ? poa.docVerification.verificationType
              : "",
          uid: {
            ocr: {
              name:
                poa.docVerification &&
                poa.docVerification.uid &&
                poa.docVerification.uid.details &&
                poa.docVerification.uid.details.name
                  ? poa.docVerification.uid.details.name
                  : "",
              id_no:
                poa.docVerification &&
                poa.docVerification.uid &&
                poa.docVerification.uid.details &&
                poa.docVerification.uid.details.docNo
                  ? poa.docVerification.uid.details.docNo
                  : "",
              dob:
                poa.docVerification &&
                poa.docVerification.uid &&
                poa.docVerification.uid.details &&
                poa.docVerification.uid.details.dob
                  ? poa.docVerification.uid.details.dob
                  : "",
              id_type:
                poa.docVerification &&
                poa.docVerification.uid &&
                poa.docVerification.uid.details &&
                poa.docVerification.uid.details.document_type
                  ? poa.docVerification.uid.details.document_type
                  : "",
              gender:
                poa.docVerification &&
                poa.docVerification.uid &&
                poa.docVerification.uid.details &&
                poa.docVerification.uid.details.gender
                  ? poa.docVerification.uid.details.gender
                  : "",
              address:
                poa.docVerification &&
                poa.docVerification.uid &&
                poa.docVerification.uid.details &&
                poa.docVerification.uid.details.current_address
                  ? poa.docVerification.uid.details.current_address
                  : "",
              address_information: {
                address:
                  poa.docVerification &&
                  poa.docVerification.uid &&
                  poa.docVerification.uid.details &&
                  poa.docVerification.uid.details.current_address_details &&
                  poa.docVerification.uid.details.current_address_details
                    .address
                    ? poa.docVerification.uid.details.current_address_details
                        .address
                    : "",
                locality_or_post_office:
                  poa.docVerification &&
                  poa.docVerification.uid &&
                  poa.docVerification.uid.details &&
                  poa.docVerification.uid.details.current_address_details &&
                  poa.docVerification.uid.details.current_address_details
                    .locality_or_post_office
                    ? poa.docVerification.uid.details.current_address_details
                        .locality_or_post_office
                    : "",
                district_or_city:
                  poa.docVerification &&
                  poa.docVerification.uid &&
                  poa.docVerification.uid.details &&
                  poa.docVerification.uid.details.current_address_details &&
                  poa.docVerification.uid.details.current_address_details
                    .district_or_city
                    ? poa.docVerification.uid.details.current_address_details
                        .district_or_city
                    : "",
                state:
                  poa.docVerification &&
                  poa.docVerification.uid &&
                  poa.docVerification.uid.details &&
                  poa.docVerification.uid.details.current_address_details &&
                  poa.docVerification.uid.details.current_address_details.state
                    ? poa.docVerification.uid.details.current_address_details
                        .state
                    : "",
                pincode:
                  poa.docVerification &&
                  poa.docVerification.uid &&
                  poa.docVerification.uid.details &&
                  poa.docVerification.uid.details.current_address_details &&
                  poa.docVerification.uid.details.current_address_details
                    .pincode
                    ? poa.docVerification.uid.details.current_address_details
                        .pincode
                    : "",
              },
            },
          },
        },
        api: {
          details:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.details
              ? poa.errorDetails.api.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.code
              ? poa.errorDetails.api.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.message
              ? poa.errorDetails.api.message
              : "",
        },
        ocr: {
          details:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.details
              ? poa.errorDetails.ocr.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.code
              ? poa.errorDetails.ocr.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.message
              ? poa.errorDetails.ocr.message
              : "",
        },
      };

      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    } else if (poa.docVerification?.verificationType === "VOTER_ID") {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified:
            poa.docVerification && poa.docVerification.isDocVerified
              ? "Yes"
              : "No",
          isNumberVerified:
            poa.docVerification && poa.docVerification.isNumberVerified
              ? "Yes"
              : "No",
          isDocMatch:
            poa.docVerification && poa.docVerification.isDocMatch
              ? "Yes"
              : "No",
          type:
            poa.docVerification && poa.docVerification.type
              ? poa.docVerification.type
              : "",
          docType:
            poa.docVerification && poa.docVerification.verificationType
              ? poa.docVerification.verificationType
              : "",
          voterId: {
            ocr: {
              name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.ocr &&
                poa.docVerification.voterId.ocr.name
                  ? poa.docVerification.voterId.ocr.name
                  : "",
              id_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.ocr &&
                poa.docVerification.voterId.ocr.id_no
                  ? poa.docVerification.voterId.ocr.id_no
                  : "",
              fathers_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.ocr &&
                poa.docVerification.voterId.ocr.fathers_name
                  ? poa.docVerification.voterId.ocr.fathers_name
                  : "",
              id_type:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.ocr &&
                poa.docVerification.voterId.ocr.id_type
                  ? poa.docVerification.voterId.ocr.id_type
                  : "",
              gender:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.ocr &&
                poa.docVerification.voterId.ocr.gender
                  ? poa.docVerification.voterId.ocr.gender
                  : "",
              address:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.ocr &&
                poa.docVerification.voterId.ocr.address
                  ? poa.docVerification.voterId.ocr.address
                  : "",
              date_of_issue:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.ocr &&
                poa.docVerification.voterId.ocr.date_of_issue
                  ? poa.docVerification.voterId.ocr.date_of_issue
                  : "",
              address_information: {
                address:
                  poa.docVerification &&
                  poa.docVerification.voterId &&
                  poa.docVerification.voterId.ocr &&
                  poa.docVerification.voterId.ocr.address_information &&
                  poa.docVerification.voterId.ocr.address
                    ? poa.docVerification.voterId.ocr.address
                    : "",
                locality_or_post_office:
                  poa.docVerification &&
                  poa.docVerification.voterId &&
                  poa.docVerification.voterId.ocr &&
                  poa.docVerification.voterId.ocr.address_information &&
                  poa.docVerification.voterId.ocr.locality_or_post_office
                    ? poa.docVerification.voterId.ocr.locality_or_post_office
                    : "",
                district_or_city:
                  poa.docVerification &&
                  poa.docVerification.voterId &&
                  poa.docVerification.voterId.ocr &&
                  poa.docVerification.voterId.ocr.address_information &&
                  poa.docVerification.voterId.ocr.district_or_city
                    ? poa.docVerification.voterId.ocr.district_or_city
                    : "",
              },
            },
            verificationResponse: {
              verified:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.verified
                  ? "Yes"
                  : "No",
              result_id_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.result_id_no
                  ? poa.docVerification.voterId.verificationResponse
                      .result_id_no
                  : "",
              result_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.result_name
                  ? poa.docVerification.voterId.verificationResponse.result_name
                  : "",
              pc_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.pc_name
                  ? poa.docVerification.voterId.verificationResponse.pc_name
                  : "",
              st_code:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.st_code
                  ? poa.docVerification.voterId.verificationResponse.st_code
                  : "",
              ps_lat_long_1_coordinate:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse
                  .ps_lat_long_1_coordinate
                  ? poa.docVerification.voterId.verificationResponse
                      .ps_lat_long_1_coordinate
                  : "",
              gender:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.gender
                  ? poa.docVerification.voterId.verificationResponse.gender
                  : "",
              rln_name_v2:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.rln_name_v2
                  ? poa.docVerification.voterId.verificationResponse.rln_name_v2
                  : "",
              rln_name_v1:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.rln_name_v1
                  ? poa.docVerification.voterId.verificationResponse.rln_name_v1
                  : "",
              rln_name_v3:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.rln_name_v3
                  ? poa.docVerification.voterId.verificationResponse.rln_name_v3
                  : "",
              name_v1:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.name_v1
                  ? poa.docVerification.voterId.verificationResponse.name_v1
                  : "",
              epic_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.epic_no
                  ? poa.docVerification.voterId.verificationResponse.epic_no
                  : "",
              ac_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.ac_name
                  ? poa.docVerification.voterId.verificationResponse.ac_name
                  : "",
              name_v2:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.name_v2
                  ? poa.docVerification.voterId.verificationResponse.name_v2
                  : "",
              name_v3:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.name_v3
                  ? poa.docVerification.voterId.verificationResponse.name_v3
                  : "",
              ps_lat_long:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.ps_lat_long
                  ? poa.docVerification.voterId.verificationResponse.ps_lat_long
                  : "",
              pc_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.pc_no
                  ? poa.docVerification.voterId.verificationResponse.pc_no
                  : "",
              last_update:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.last_update
                  ? poa.docVerification.voterId.verificationResponse.last_update
                  : "",
              id:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.id
                  ? poa.docVerification.voterId.verificationResponse.id
                  : "",
              dist_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.dist_no
                  ? poa.docVerification.voterId.verificationResponse.dist_no
                  : "",
              ps_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.ps_no
                  ? poa.docVerification.voterId.verificationResponse.ps_no
                  : "",
              pc_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.pc_name
                  ? poa.docVerification.voterId.verificationResponse.pc_name
                  : "",
              ps_name_v1:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.ps_name_v1
                  ? poa.docVerification.voterId.verificationResponse.ps_name_v1
                  : "",
              st_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.st_name
                  ? poa.docVerification.voterId.verificationResponse.st_name
                  : "",
              dist_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.dist_name
                  ? poa.docVerification.voterId.verificationResponse.dist_name
                  : "",
              rln_type:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.rln_type
                  ? poa.docVerification.voterId.verificationResponse.rln_type
                  : "",
              pc_name_v1:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.pc_name_v1
                  ? poa.docVerification.voterId.verificationResponse.pc_name_v1
                  : "",
              part_name_v1:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.part_name_v1
                  ? poa.docVerification.voterId.verificationResponse
                      .part_name_v1
                  : "",
              ac_name_v1:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.ac_name_v1
                  ? poa.docVerification.voterId.verificationResponse.ac_name_v1
                  : "",
              part_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.part_no
                  ? poa.docVerification.voterId.verificationResponse.part_no
                  : "",
              dist_name_v1:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.dist_name_v1
                  ? poa.docVerification.voterId.verificationResponse
                      .dist_name_v1
                  : "",
              ps_lat_long_0_coordinate:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse
                  .ps_lat_long_0_coordinate
                  ? poa.docVerification.voterId.verificationResponse
                      .ps_lat_long_0_coordinate
                  : "",
              _version_:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse._version_
                  ? poa.docVerification.voterId.verificationResponse._version_
                  : "",
              name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.name
                  ? poa.docVerification.voterId.verificationResponse.name
                  : "",
              section_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.section_no
                  ? poa.docVerification.voterId.verificationResponse.section_no
                  : "",
              ac_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.ac_no
                  ? poa.docVerification.voterId.verificationResponse.ac_no
                  : "",
              slno_inpart:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.slno_inpart
                  ? poa.docVerification.voterId.verificationResponse.slno_inpart
                  : "",
              rln_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.rln_name
                  ? poa.docVerification.voterId.verificationResponse.rln_name
                  : "",
              age:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.age
                  ? poa.docVerification.voterId.verificationResponse.age
                  : "",
              part_name:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.part_name
                  ? poa.docVerification.voterId.verificationResponse.part_name
                  : "",
              id_no:
                poa.docVerification &&
                poa.docVerification.voterId &&
                poa.docVerification.voterId.verificationResponse &&
                poa.docVerification.voterId.verificationResponse.id_no
                  ? poa.docVerification.voterId.verificationResponse.id_no
                  : "",
            },
          },
        },
        api: {
          details:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.details
              ? poa.errorDetails.api.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.code
              ? poa.errorDetails.api.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.message
              ? poa.errorDetails.api.message
              : "",
        },
        ocr: {
          details:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.details
              ? poa.errorDetails.ocr.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.code
              ? poa.errorDetails.ocr.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.message
              ? poa.errorDetails.ocr.message
              : "",
        },
      };
      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    } else {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified:
            poa.docVerification && poa.docVerification.isDocVerified
              ? "Yes"
              : "No",
          isNumberVerified:
            poa.docVerification && poa.docVerification.isNumberVerified
              ? "Yes"
              : "No",
          isDocMatch:
            poa.docVerification && poa.docVerification.isDocMatch
              ? "Yes"
              : "No",
          type:
            poa.docVerification && poa.docVerification.type
              ? poa.docVerification.type
              : "",
          docType:
            poa.docVerification && poa.docVerification.verificationType
              ? poa.docVerification.verificationType
              : "",
        },
        api: {
          details:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.details
              ? poa.errorDetails.api.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.code
              ? poa.errorDetails.api.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.api &&
            poa.errorDetails.api.message
              ? poa.errorDetails.api.message
              : "",
        },
        ocr: {
          details:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.details
              ? poa.errorDetails.ocr.details
              : "",
          code:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.code
              ? poa.errorDetails.ocr.code
              : "",
          message:
            poa.errorDetails &&
            poa.errorDetails.ocr &&
            poa.errorDetails.ocr.message
              ? poa.errorDetails.ocr.message
              : "",
        },
      };

      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    }
  };
  hidePoaDocInfo = () => {
    this.setState({ poaDocDisplay: false });
  };
  doSubmit = async (e) => {
    e.preventDefault();
    let errors = await this.handleCustomErrors();

    if (_.isEmpty(errors)) await this.updateLoanStatus();
  };
  handleEnachPopupOpen = () => {
    this.setState({ showEnachPopUp: true });
  };
  handleEnachPopupClose = () => {
    this.setState({ showEnachPopUp: false });
  };
  toggleChange = () => {
    let { graphToggle } = this.state;
    this.setState({ graphToggle: !graphToggle });
  };
  handleTrueScorePopupClose = () => {
    this.setState({ showTrueScorePopUp: false });
  };
  handleEnachPopupOk = async () => {
    this.setState({ showEnachPopUp: false });
    await this.sendNewMandateRequest();
  };

  handleSelect = async (tab) => {
    if (tab === this.state.activeTab) return;

    this.setState({ activeTab: tab });
    if (tab == "transactionsTab" || tab == "transaction2") {
      this.getTransactions();
      this.getGstTransactions();
     // this.fetchBankStatement();
    }

    if (tab === "trueScore") {
      this.getGstBasedCreditLimit();
      this.getTrueScoreGraph();
      this.getTrueScore();
      this.getGstTransactions();
      //this.fetchBankStatement();
      this.getPreApprovalAmount();
    }

    if (tab === "loanHistoryTab") {
      this.getFundfinaLoans();
    }

    if (tab === "shopDetailsTab")
      await this.getLoanData(this.state.staticData.loanId);
  };
  handleBankPopupOpen = () => {
    this.setState({ showBankLinkPopUp: true });
  };
  handleBankPopupClose = () => {
    this.setState({ showBankLinkPopUp: false });
  };
  handleBankPopupOk = async () => {
    this.setState({ showBankLinkPopUp: false });
    await this.sendUpdateBankLink();
  };
  closeSuccessModal = () => {
    this.setState({ showSuccessModal: false });
  };
  handleInfoPopupClose = () => {
    this.setState({ showInfoPopup: false, infoHeader: "", infoMessage: "" });
  };
  async sendNewMandateRequest() {
    let staticData = { ...this.state.staticData };

    this.setState({ showSpinner: true });

    try {
      const { data: response } =
        await loanService.sendNewMandateOrUpdateBankListRequest(
          staticData.loanId,
          "Mandate"
        );

      if (response.success) {
        await this.getLoanData(staticData.loanId);
        this.setState({
          infoMessage: response.message,
          infoHeader: "Success",
          showInfoPopup: true,
        });
      } else
        this.setState({
          infoMessage: response.message,
          infoHeader: "Error",
          showInfoPopup: true,
          showSpinner: false,
        });
    } catch (ex) {
      this.setState({
        infoMessage: ex.response.data.message,
        infoHeader: "Error",
        showInfoPopup: true,
        showSpinner: false,
      });
    }
  }
  async sendUpdateBankLink() {
    let staticData = { ...this.state.staticData };
    this.setState({ showSpinner: true });

    try {
      const { data: response } =
        await loanService.sendNewMandateOrUpdateBankListRequest(
          staticData.loanId,
          "BankDetails"
        );

      if (response.success)
        this.setState({
          infoMessage: response.message,
          infoHeader: "Success",
          showInfoPopup: true,
          showSpinner: false,
        });
      else
        this.setState({
          infoMessage: response.message,
          infoHeader: "Error",
          showInfoPopup: true,
          showSpinner: false,
        });
    } catch (ex) {
      this.setState({
        infoMessage: ex.response.data.message,
        infoHeader: "Error",
        showInfoPopup: true,
        showSpinner: false,
      });
    }
  }

  handleLenderSelectionChange = async ({ currentTarget: input }) => {
    const lendingPartnerName = input.value;
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    let staticData = { ...this.state.staticData };

    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const { data: response } = await loanService.loanAllocationValidation(
      staticData.loanId,
      lendingPartnerName
    );
    if (response.success) {
      data[input.name] = lendingPartnerName;
      data["trancheType"] = "";
      staticData.settlementMode =
        response.data && response.data.settlementMode
          ? response.data.settlementMode
          : "";
      delete errors["globalError"];
    } else {
      errors["globalError"] = response.message;
      data[input.name] = "";
      data["trancheType"] = "";
    }
    this.setState({ data, errors, staticData });
  };

  onPhotoUploadChange = async ({ currentTarget: input }, type) => {
    const files = { ...this.state.files };
    const fileErrors = { ...this.state.fileErrors };

    if (input.files.length === 0) {
      files[input.name] = "";
      files[`${input.name}Stream`] = "";
      files[`${input.name}Label`] = "";
      this.setState({ files });
      return;
    }
    if (
      input.files[0].type !== "image/png" &&
      input.files[0].type !== "image/x-png" &&
      input.files[0].type !== "image/jpeg" &&
      input.files[0].type !== "image/pjpeg"
    ) {
      fileErrors[input.name] = "Only .jpg .png allowed";
      this.setState({ fileErrors });
      return;
    } else {
      delete fileErrors[input.name];
      this.setState({ fileErrors });
    }

    this.setState({ showSpinner: true });
    let filePath = await this.getSignedURL(input.files[0], type);
    this.setState({ showSpinner: false });

    files[input.name] = input.files[0].name;
    files[`${input.name}Stream`] = filePath;
    if (input.files[0].name.length > 20) {
      const name = `${input.files[0].name.substring(0, 20)}${this.getExtension(
        input.files[0].name
      )}`;
      files[`${input.name}Label`] = name;
    } else files[`${input.name}Label`] = input.files[0].name;

    this.setState({ files });

    await this.getBase64(input.files[0], (result) => {
      files[`${input.name}Src`] = result;
      this.setState({ files });
    });

    let payload = {
      loanId: this.state.staticData.loanId,
      path: filePath,
    };

    await loanService.updateLoanContractImage(payload);
  };

  uploadFile = (signedUrl, file, callback) => {
    commonService
      .uploadFileAwsS3(signedUrl, file)
      .then((response) => {
        let splittedSignedUrl = signedUrl.split("?");
        callback && callback(null, splittedSignedUrl[0]);
      })
      .catch((error) => {
        callback && callback(error, null);
        console.log(error);
      });
  };

  getSignedURL = async (file, type) => {
    let payload = {
      fileName: file.name,
      type,
    };

    this.setState({ uploadLoading: true });
    let response = await commonService.getSignedUrl(payload);

    /** LIMITED FILE SIZE */
    if (response.data.data) {
      response = response.data.data;

      let singedUrlBuild = this.getSingedUrlBuild(response, file);

      let data = await this.uploadFileV2(response["url"], singedUrlBuild);

      if (data) {
        return this.getImageUrlFromSingedUrlResponse(response);
      }
      return data;
    }

    // return new Promise((resolve, reject) => {
    //   this.uploadFile(response?.data?.data?.signedUrl, file, (err, data) => {
    //     if (err) resolve("")
    //     else resolve(data)
    //   })
    // })
  };

  onRemoveProfileFile() {
    let staticData = this.state.staticData;
    staticData.photoFileEncPath = "";
    staticData.photoFilePath = "";
    let files = this.state.files;
    files.photoFileSrc = "";
    this.setState({ staticData });
  }

  async regenerateLoanContract(loanNo) {
    try {
      this.setState({ showSpinner: true, isDisabledContractButton: true });
      const response = await loanApplicationServices.regenerateLoanContract(
        loanNo
      );

      if (response.success)
        this.setState({
          showSuccessModal: true,
          infoHeader: "Success",
          infoMessage: response.message,
          showSpinner: false,
          isDisabledContractButton: false,
        });
      else
        this.setState({
          showSuccessModal: true,
          infoHeader: "Error",
          infoMessage: response.message,
          showSpinner: false,
          isDisabledContractButton: false,
        });
    } catch (ex) {
      this.setState({
        showSuccessModal: true,
        infoHeader: "Error",
        infoMessage: ex.message,
        showSpinner: false,
        isDisabledContractButton: false,
      });
    }
  }
  async sendNocNotification(loanNo) {
    try {
      this.setState({ showSpinner: true, isDisabledNocButton: true });
      const response = await loanApplicationServices.sendNocNotification(
        loanNo
      );

      if (response.success)
        this.setState({
          showSuccessModal: true,
          infoHeader: "Success",
          infoMessage: response.message,
          showSpinner: false,
          isDisabledNocButton: false,
        });
      else
        this.setState({
          showSuccessModal: true,
          infoHeader: "Error",
          infoMessage: response.message,
          showSpinner: false,
          isDisabledNocButton: false,
        });
    } catch (ex) {
      this.setState({
        showSuccessModal: true,
        infoHeader: "Error",
        infoMessage: ex.message,
        showSpinner: false,
        isDisabledNocButton: false,
      });
    }
  }

  handleWaiveLateFee = async () => {
    let foreclosureDetails = { ...this.state.foreclosureDetails };
    foreclosureDetails.isWaiveOff = !foreclosureDetails.isWaiveOff;
    this.setState({ foreclosureDetails });
  };

  handleForeClosureReason = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    let foreclosureDetails = { ...this.state.foreclosureDetails };
    delete errors["foreclosureReason"];
    foreclosureDetails.foreclosureReason = input.value;
    this.setState({ foreclosureDetails, errors });
  };

  async getForeClosureDetails() {
    this.setState({ showSpinner: true });
    try {
      const staticData = { ...this.state.staticData };
      let foreclosureDetails = { ...this.state.foreclosureDetails };

      const { data: foreclosureData } =
        await foreclosureService.getForeclosureDetails(staticData["loanNo"]);

      foreclosureDetails.interestAmount = foreclosureData.interestAmount;
      foreclosureDetails.edis = foreclosureData.totalEdis;
      foreclosureDetails.principalPending = foreclosureData.principalPending;
      foreclosureDetails.inerestPending = foreclosureData.interestPending;
      foreclosureDetails.lateChargesPending =
        foreclosureData.lateChargesPending;
      foreclosureDetails.pricipalOutstanding =
        foreclosureData.principalOutstanding;
      foreclosureDetails.interestOutstanding =
        foreclosureData.interestOutstanding;
      foreclosureDetails.edisOutstanding = foreclosureData.outstandingEdis;
      this.setState({ foreclosureDetails, showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  handleInitiateForeclosure = async () => {
    const foreclosureDetails = { ...this.state.foreclosureDetails };
    const staticData = { ...this.state.staticData };
    const errors = { ...this.state.errors };
    let isValidationFailed = false;
    let successMsgForeclosure = null;
    delete errors["globalForeclosureError"];
    this.setState({ errors, showSpinner: true });

    if (foreclosureDetails.foreclosureReason == "") {
      isValidationFailed = true;
      errors["foreclosureReason"] = "Please Select Foreclosure Reason";
    } else delete errors["foreclosureReason"];

    if (!isValidationFailed) {
      foreclosureDetails.loanNo = staticData["loanNo"];

      const response = await foreclosureService.initiateForeclosure(
        foreclosureDetails
      );

      if (response.success) {
        successMsgForeclosure = response.message;
        delete errors["globalForeclosureError"];
      } else {
        successMsgForeclosure = null;
        errors["globalForeclosureError"] = response.message;
      }

      this.setState({ errors, showSpinner: false, successMsgForeclosure });
    } else this.setState({ errors, showSpinner: false, successMsgForeclosure });
  };
}

export default LoanInfoFormModel;
