import React from "react";
import { Link } from "react-router-dom";
import { Form, Col, Button, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import DatePicker from "react-datepicker2";
import TrancheMISModel from './../../models/tranche/trancheMISModel';
import ReactExport from "react-export-excel";

class TrancheMIS extends TrancheMISModel {
    getPagedData = () => {
        const { pageSize, currentPage, list } = this.state;
        const filteredList = paginate(list, currentPage, pageSize);

        return { totalCount: list.length, data: filteredList };
    };
    render() {
        const { data, list, lendingPartnerList, showSpinner, errors, pageSize, currentPage, successMsg, tranches, role } = this.state;
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        //const { totalCount, data: list } = this.getPagedData();
        let i = pageSize * (currentPage - 1);
        const backgroundColorTotal = "#C70039";
        const backgroundColorVintage = "#E67E22";
        return (<React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>Tranchewise Collection MIS</h2>
            {" "}
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} md="3" controlId="lendingPartner">
                        <Form.Label>Leanding Partner</Form.Label>
                        <Form.Control
                            as="select"
                            name="lendingPartner"
                            value={data["lendingPartner"]}
                            onChange={this.handleChange}
                            disabled={role === "Lending Partner" ? "disabled" : ""}
                        >
                            {" "}
                            <option key="all" value="">
                                ---All---
                            </option>
                            {lendingPartnerList.map((item, i) => (
                                <option key={i} value={item._id}>
                                    {item.displayName}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="status"
                            value={data["status"]}
                            onChange={this.handleChange}
                        >
                            {" "}
                            <option key="all" value="">---All---</option>
                            <option key="Open" value="Open">Open</option>
                            <option key="Close" value="Close">Close</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="fromDate">
                        <Form.Label>From Date</Form.Label>
                        <DatePicker
                            name="fromDate"
                            id="fromDate"
                            value={data["fromDate"]}
                            max={moment()}
                            inputFormat="DD/MM/YYYY"
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleFromDateChange(value)}
                            placeholder="Enter From Date"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="toDate">
                        <Form.Label>To Date</Form.Label>
                        <DatePicker
                            name="toDate"
                            id="toDate"
                            value={data["toDate"]}
                            max={moment()}
                            inputFormat="DD/MM/YYYY"
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleToDateChange(value)}
                            placeholder="Enter To Date"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Button
                            className="ff-button"
                            type="submit"
                            style={{ marginTop: 30, marginLeft: 10 }}
                        >
                            Apply Filters
                        </Button>
                        {
                            <ExcelFile element={<Button
                                className="ff-button"
                                type="button"
                                style={{ marginTop: 30, marginLeft: 10 }}
                            >
                                Export to Excel
                            </Button>}
                                filename={`Tranche_${moment(new Date()).format("DDMMYYYY")}`}
                            >
                                <ExcelSheet data={list} name="Tranche MIS">
                                    <ExcelColumn label="Date" value="Date" />
                                    <ExcelColumn label="Total Collection" value="total_collection" />
                                    {tranches && tranches.map((item) => <ExcelColumn label={item.trancheNo} value={item.trancheNo} />)}

                                </ExcelSheet>
                            </ExcelFile>
                        }
                    </Form.Group>
                </Form.Row>
            </Form>
            <br />
            {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
            {successMsg && <div className="alert alert-success">{successMsg}</div>}
            <br />
            {list && list.length > 0 && (
                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th>Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th>Total Collection &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {tranches && tranches.map((item) => <th>{item.trancheNo}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {list && list.map((item) => {
                            return (

                                (item.Date == "Total" && (
                                    <tr key={item.trancheId} style={{ backgroundColor: backgroundColorTotal }} >
                                        <td > {item.Date}</td>
                                        <td>{item.total_collection}</td>
                                        {tranches && tranches.map((item1) => <td>{item[item1.trancheNo]}</td>)}
                                    </tr>)) ||
                                (item.Date == "Tranche Vintage" && (
                                    <tr key={item.trancheId} style={{ backgroundColor: backgroundColorVintage }} >
                                        <td > {item.Date}</td>
                                        <td>{item.total_collection}</td>
                                        {tranches && tranches.map((item1) => <td>{item[item1.trancheNo]}</td>)}
                                    </tr>)) ||
                                (item.Date != "Total" && item.Date != "Tranche Vintage" && (
                                    <tr key={item.trancheId}  >
                                        <td > {item.Date}</td>
                                        <td>{item.total_collection}</td>
                                        {tranches && tranches.map((item1) => <td>{item[item1.trancheNo]}</td>)}
                                    </tr>))
                            );
                        })}
                        {!list && (
                            <tr key="no">
                                <td colSpan="10">No record found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>)}
            {/* <div>
                <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                    onPagePrevious={this.handlePagePrevious}
                    onPageNext={this.handlePageNext}
                />
            </div> */}
        </React.Fragment >
        )
    }
}

export default TrancheMIS;