import React, { Component } from "react";
import { Form, Col, Row, Image, Button, Modal, Tabs, Tab } from "react-bootstrap";

class ImagePreview extends Component {
    state = {}

    constructor(props) {
        super(props)
        if (this.props.src) {
            let [base, qString] = this.props.src.split("?")
            let isPdf = false
            if (base.includes(".pdf")  || this.props.src.includes("application/pdf")) isPdf = true
            this.state.isPdf = isPdf
        }

    }

    render() {
        return <Modal
            show={this.props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.props.close}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="fundfinaColor">
                    Preview
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
                {
                    this.state.isPdf?
                        <div>
                            <iframe src={this.props.src} height="500px" width="100%" frameBorder="0" />
                        </div>
                        :
                        <div className="row text-center">
                            <div className={`col-12 text-center`}>
                                <Image className="fit-content" src={this.props.src} />
                            </div>
                        </div>

                }

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.close}>Close</Button>
            </Modal.Footer>
        </Modal>

    }
}


export default ImagePreview;