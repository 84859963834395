import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col, Button, Table, FormCheck, Modal, Row } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import DisbursalTableModel from "../../models/disbursalV3/disbursalTableModel";
import LoanInfoForm from "../loan/loanInfoForm"
import NumberFormat from "react-number-format";

class DisbursalTableV3 extends DisbursalTableModel {
  getPagedData = () => {
    const { pageSize, currentPage, data, loansList } = this.state;

    let loans = loansList;

    const filteredloans = paginate(loans, currentPage, pageSize);

    return { totalCount: loans.length, data, loansList: filteredloans };
  };

  handleSelectALL = (e) => {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    var checkboxes = document.querySelectorAll('input[name^="chkLoans"]');
    for (var checkbox of checkboxes) {
      console.log(checkbox.name);
      checkbox.checked = value;
      const chkLoan = checkbox.name.split("_");
      const loanId = chkLoan[1];
      const selectedLoans = this.state.selectedLoans;
      if (!value && selectedLoans.includes(loanId, 0)) {
        const index = selectedLoans.indexOf(loanId);
        if (index != -1) {
          selectedLoans.splice(index, 1);
        }
      } else if (value && !selectedLoans.includes(loanId, 0)) {
        selectedLoans.push(loanId);
      }
      this.setState({ selectedLoans });
    }
  }

  renderCheckBox = (loan) => {

    let checkbox = (<></>);

    if (!loan.isCutOffTimeExpired) {
      if (!this.state.showExport && this.state.data && this.state.data["status"] == "Pending" && loan.loanStatus.toLowerCase() === "lender assigned" && loan.disbursalMode == "MANUAL") {
        return ("")
      }
      else if (this.state.data && this.state.data["status"] == "Pending" && loan.loanStatus.toLowerCase() === "lender assigned") {
        return (<Form.Check
          type="checkbox"
          name={"chkLoans_" + loan.loanNo}
          label=""
          onChange={this.handleChecked(loan.loanNo)}
          color="red"
        />)
      } else if (loan.isRedisbursal && this.state.data && this.state.data["status"] == "Failed" && loan.loanStatus.toLowerCase() !== "lender assigned") {
        return (<Form.Check
          type="checkbox"
          name={"chkLoans_" + loan.loanNo}
          label=""
          onChange={this.handleChecked(loan.loanNo)}
          color="red"
        />)
      }
    }
    return (<></>)


  }


  render() {
    const { pageSize, currentPage, errors, showSpinner, borrowingPartners, lendingPartners, successMsg, isManual, loanId, showLoanDecisionModal } = this.state;

    const { totalCount, data, loansList } = this.getPagedData();

    if (
      !auth.getCurrentUser() ||
      auth.getCurrentUser().role.toLowerCase() !== "admin"
    )
      return <Redirect to="/login" />;
    return (


      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2
          className="fundfinaColor"
          style={{ textDecoration: "underline", textAlign: "center" }}
        >
          Loan Disbursal
        </h2>
        <br />
        <br />
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              <div>
                <div style={{ textAlign: "right" }}>
                  <div className={`filter-icon-wrapper ${this.state.showFilter ? "filter-icon-active" : ""}`} onClick={this.toggleFilter}>
                    {this.filterStatus() && !this.state.showFilter ? <span className="filter-dot"></span> : ""}
                    {this.state.showFilter ? <i className="fa fa-close"></i> : <i className="fa fa-filter"></i>}
                  </div>
                </div>
                {this.state.showFilter ? <div className="filter-list text-selection-none">
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="fromDate">
                      <Form.Label>From Date</Form.Label>
                      <DatePicker
                        name="fromDate"
                        id="fromDate"
                        value={data["fromDate"]}
                        max={moment()}
                        inputFormat="DD/MM/YYYY"
                        isGregorian={true}
                        className="form-control"
                        inputReadOnly={true}
                        showTodayButton={false}
                        timePicker={false}
                        onChange={(value) => this.handleFromDateChange(value)}
                        placeholder="Enter From Date"
                        popperProps={{
                          positionFixed: true // use this to make the popper position: fixed
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="toDate">
                      <Form.Label>To Date</Form.Label>
                      <DatePicker
                        name="toDate"
                        id="toDate"
                        value={data["toDate"]}
                        max={moment()}
                        inputFormat="DD/MM/YYYY"
                        isGregorian={true}
                        className="form-control"
                        inputReadOnly={true}
                        showTodayButton={false}
                        timePicker={false}
                        onChange={(value) => this.handleToDateChange(value)}
                        placeholder="Enter To Date"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="loanNo">
                      <Form.Label>Loan No</Form.Label>
                      <Form.Control
                        type="text"
                        name="loanNo"
                        value={data["loanNo"]}
                        onChange={this.handleChange}
                        autoComplete="off"
                        maxLength="100"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="productId">
                      <Form.Label>Product Id</Form.Label>
                      <Form.Control
                        type="text"
                        name="productId"
                        value={data["productId"]}
                        onChange={this.handleChange}
                        autoComplete="off"
                        maxLength="100"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="7" controlId="partner">
                      <Form.Label>Borrowing Partner</Form.Label>
                      <Form.Control
                        as="select"
                        name="partner"
                        value={data["partner"]}
                        onChange={this.handleChange}
                      >
                        {" "}
                        <option key="all" value="all">
                          ALL
                        </option>
                        {borrowingPartners.map((partner) => (
                          <option key={partner._id} value={partner._id}>
                            {partner.displayName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                      <Button
                        className="ff-button"
                        type="submit"
                        style={{ marginTop: 30, marginLeft: 10 }}
                        disabled={!this.dateStatus() ? true : this.filterStatus() ? false : true}
                      >
                        Apply
                      </Button>
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                      <Button
                        className="ff-button"
                        type="button"
                        onClick={this.handleReset}
                        style={{ marginTop: 30, marginLeft: 10 }}
                      >Reset</Button>
                    </Form.Group>
                  </Form.Row>
                  <hr></hr>
                  <Form.Row className={"py-2"}>
                    {/* <Form.Group as={Col} md="5" controlId="switch">
                      <FormCheck
                        id="switchEnabled"
                        type="switch"
                        checked={isManual}
                        onChange={this.toggleChange}
                        label="Allow Manual"
                      />
                    </Form.Group> */}
                    {/* <Form.Group as={Col} md="7" controlId="switch_via_file">
                      <FormCheck
                        id="switch_via_file"
                        type="switch"
                        checked={this.state.isDisbursalViaFile}
                        onChange={this.toggleDisbursalViaFile}
                        label="Disbursal Via File"
                      />
                    </Form.Group> */}
                  </Form.Row>
                  {this.state.isDisbursalViaFile ? <Form.Row>
                    <Form.Group as={Col} md="8" controlId="paymentMode">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        as="select"
                        name="paymentMode"
                        value={data["paymentMode"]}
                        onChange={this.handleChange}
                      >
                        {" "}
                        <option key="Select" value="">
                          Select
                        </option>
                        <option key="NEFT" value="NEFT">
                          NEFT
                        </option>
                        <option key="IMPS" value="IMPS">
                          IMPS
                        </option>
                      </Form.Control>
                      {errors["paymentMode"] && (
                        <div className="alert alert-danger">
                          {errors["paymentMode"]}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Button
                        className="ff-button"
                        type="button"
                        onClick={this.handleExport}
                        style={{ marginTop: 30, marginLeft: 10 }}
                        disabled={data["paymentMode"] ? false : true}
                      >
                        Export
                      </Button>
                    </Form.Group>
                  </Form.Row> : ""}
                  <Form.Row>
                    <Form.Group as={Col} md="8" controlId="UploadFile">
                      <Form.Label>Upload Excel File</Form.Label>
                      <Form.File
                        label={data.fileName}
                        onChange={this.onExcelFileChange1}
                        id="uploadFile"
                        name="uploadFile"
                        accept=".xls,.xlsx"
                        key={data.fileName || ''}
                        custom
                      />{" "}
                      {errors["uploadFile"] && (
                        <div className="alert alert-danger">
                          {errors["uploadFile"]}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      <Button
                        className="ff-button"
                        type="button"
                        onClick={this.handleUpload}
                        style={{ marginTop: 30, marginLeft: 10 }}
                        disabled={data.fileName ? false : true}
                      >Upload</Button>
                    </Form.Group>
                  </Form.Row>

                </div> : <></>}
              </div>
            </Col>
          </Row>

          <Form.Row>
            <Form.Group as={Col} md="3" controlId="lendingPartner">
              <Form.Label>Lending Partner</Form.Label>
              <Form.Control
                as="select"
                name="lendingPartner"
                value={data["lendingPartner"]}
                onChange={this.handleChangeWithCallback}
              >
                {" "}
                <option key="all" value="all">
                  ALL
                </option>
                {lendingPartners.map((partner) => (
                  <option key={partner.displayName} value={partner._id}>
                    {partner.displayName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={data["status"]}
                onChange={this.handleChangeWithCallback}
              >
                {" "}
                <option key="ALL" value="ALL">
                  ALL
                </option>
                <option key="Pending" value="Pending">
                  Pending
                </option>
                {/* <option key="Initiated Fund Transfer" value="Initiated Fund Transfer">
                  Initiated Fund Transfer
                </option>
                <option key="Ready For Disbursal" value="Ready For Disbursal">
                  Ready For Disbursal
                </option> */}
                <option key="Failed" value="Failed">
                  Failed
                </option>
                <option key="Processing" value="Processing">
                  Processing
                </option>
                <option key="Disbursed" value="Disbursed">
                  Disbursed
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <Button
                className="ff-button"
                type="submit"
                style={{ marginTop: 30, marginLeft: 10 }}>
                Go
              </Button>
              {

                this.state.showExport && this.state.data.status == "Pending" ?

                  <Button
                    className="ff-button"
                    type="button"
                    onClick={this.handleDisburseLoansV2}
                    style={{ marginTop: 30, marginLeft: 10 }}
                    disabled={(this.state.selectedLoans && this.state.selectedLoans.length) ? false : true}
                  >Export Excel</Button>
                  :
                  this.state.showRediburse ?
                    <Button
                      className="ff-button"
                      type="button"
                      onClick={this.handleReDisburseLoans}
                      style={{ marginTop: 30, marginLeft: 10 }}
                      disabled={(this.state.selectedLoans && this.state.selectedLoans.length) ? false : true}
                    >Re-Disburse</Button>
                    :
                    <Button
                      className="ff-button"
                      type="button"
                      onClick={this.handleDisburseLoansV2}
                      style={{ marginTop: 30, marginLeft: 10 }}
                      disabled={(this.state.selectedLoans && this.state.selectedLoans.length) ? false : true}
                    >Disburse
                    </Button>

              }
            </Form.Group>

          </Form.Row>


        </Form>

        <br></br>
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        <Form.Group as={Row} className="mb-0">
          <Form.Label column>
            <small>
              {this.state.disbursalAmount ? <span>
                Disbursal Amount:{" "}
                <span style={{ color: "black" }}>{this.state.disbursalAmount}
                </span>
              </span> : ""}
              {this.state.numberOfLoans ?
                <span className="ml-5">
                  Number of Loans:{" "}
                  <span style={{ color: "black" }}>{this.state.numberOfLoans}</span>
                </span> : ""}
            </small>
          </Form.Label>

        </Form.Group>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th> <Form.Check
                type="checkbox"
                label=""
                onChange={this.handleSelectALL}
                color="red"
              /></th>
              <th>Loan No</th>
              <th>Name</th>
              <th>Borrowing Partner</th>
              <th>Lending Partner</th>
              {/* <th>Product Id</th> */}
              <th>Loan Amount</th>
              <th>Charge</th>
              <th>Disbursal Date</th>
              <th>Disbursal Amount</th>
              {/* <th>Partner Status</th> */}
              <th>Loan Status</th>
              <th>Disbursal Status</th>
              {isManual ? <th>Manual Disbursal</th> : <></>}
              {/* <th>Redisbursal</th> */}
            </tr>
          </thead>
          <tbody>
            {loansList &&
              loansList.map((loan) => (
                <tr key={loan.loanId} className={loan.isRedisbursal ? "error-row" : ""}>
                  <td>
                    {this.renderCheckBox(loan)}
                  </td>
                  <td> <a
                    key={loan._id}
                    href="#"
                    onClick={() => this.openLoanDecision(loan)}
                  >
                    {loan.loanNo}
                  </a></td>
                  <td>{loan.name}</td>
                  <td>{loan.partnerName}</td>
                  <td>{loan.lendingPartnerName}</td>
                  {/* <td>{loan.productId}</td> */}
                  <td><NumberFormat value={loan.loanAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                  <td><NumberFormat value={loan.charge} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                  <td>{loan.disbursedOn && moment(loan.disbursedOn).format("DD/MM/yyyy")}</td>
                  <td><NumberFormat value={loan.disbursalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                  {/* <td>{loan.partnerStatus}</td> */}
                  <td>{loan.loanStatus}</td>
                  <td>{loan.disbursalStatus}</td>
                  {isManual ? <td>
                    {loan.disbursalStatus.toLowerCase() === "pending" && loan.loanStatus.toLowerCase() === "lender assigned" && isManual ? (
                      <Button
                        onClick={() => this.handleDisburse(loan.loanId)}
                        className="ff-button"
                      >
                        Disburse
                      </Button>
                    ) : (
                      ""
                    )}
                  </td> : <></>}
                  {/* <td>
                    {loan.isRedisbursal ? (
                      <Button
                        onClick={() => this.showRedisburseModel(loan.loanId)}
                        className="ff-button"
                      >
                        Redisburse
                      </Button>
                    ) : (
                      ""
                    )}
                  </td> */}
                </tr>
              ))}
            {!loansList && (
              <tr key="no">
                <td colSpan="11">No record found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPagePrevious={this.handlePagePrevious}
            onPageNext={this.handlePageNext}
          />
        </div>
        <Modal
          show={showLoanDecisionModal}
          onHide={this.closeModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="example-modal-sizes-title-xl"
          centered
          style={{ width: "auto !important" }}
        >

          <Modal.Header closeButton>
            <div class="col-md-10">
            </div>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 600, overflow: "scroll" }}>
            <LoanInfoForm loanId={loanId} activeTab="loanTab" />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showRedisburseConfirmation}
          onHide={this.toggleConfirmationModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>Are you sure?</b>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to redisburse the Loan?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => { this.handleReDisburse() }} className="ff-button">
              Yes
            </Button>
            <Button onClick={this.toggleConfirmationModal} className="ff-button">
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    );
  }
}

export default DisbursalTableV3;
