import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import * as reconService from "../../services/recon/reconService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";


class LenderSettlementsModel extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      transactions: [],
      partner: ""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    partners: [],
    lendingPartners: [],
    evaluatorRole: "",
    tabs: [
      { title: "Lending Partners" },
      { title: "Write-Off" },
      { title: "Late Fees" }
    ],
    activeTab: "Lending Partners",
    allLoans: [],
    showDetails: false
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    lendingPartner: Joi.any(),
    borrowingPartner: Joi.any(),
    transactions: Joi.any(),
    page: Joi.any(),
    tab: Joi.any(),
    partner: Joi.any()
  };

  async getPayoutTransactionList(allLoans) {

    this.state.data.page = this.state.currentPage

    const data = { ...this.state.data };


    if (allLoans)
      data.isAllLoans = true

    this.setState({ showSpinner: true });
    try {
      data.tab = this.state.activeTab
      const { data: transactionsList, totalPages } = await loanService.getPayoutTransactionList(data);
      data.transactions = transactionsList;
      this.setState({ data, showSpinner: false, pageSize: totalPages || this.state.pageSize });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    await this.getPayoutTransactionList();
    await this.getBorroingPartners();
    await this.getLendingPartners();

  };
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  handlePartnerChange = ({ currentTarget: input }) => {

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors }, () => {
      this.getLenderSettlementInformation()
    });
  };

  async getLendingPartners() {
    try {
      const { data: partnerList } = await commonService.getLendingParterList();
      this.setState({ lendingPartners: partnerList });
    }
    catch (ex) {
      this.setState({ lendingPartners: [] });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getPayoutTransactionList();
  };
  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }
  onChangeTab = (tab) => {
    const data = { ...this.state.data };
    data['tab'] = tab
    this.setState({ data, activeTab: tab }, () => {
      this.resetPage(() => {
        this.getPayoutTransactionList()
      })
    })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getPayoutTransactionList()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getPayoutTransactionList()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getPayoutTransactionList()
    })
  }

  exportExcel = () => {
    this.getPayoutTransactionList(true)
  }

  onChangeHeaderActiveTab = (tab) => {
    const data = { ...this.state.data };
    data['headerActiveTab'] = tab
    this.setState({ data, headerActiveTab: tab }, () => {

      if (tab == "Applications") {
        this.resetPage(() => {
          this.state.activeTab = "All"
          this.state.data.tab = "All"
          this.getPayoutTransactionList()
        })
      }
    })
  }

  showDetails = (transactionId) => {
    this.setState({ transactionId, showDetails: true })
  }


  getLenderSettlementInformation = async () => {
    try {

      if (!this.state.data.partner) {
        this.setState({ lenderSettlementInformation: null })
        return;
      }

      let payload = { lendingPartnerId: this.state.data.partner };
      const data = await loanService.getLenderSettlementInformation(payload);

      if (data && data.success && data.data)
        this.setState({ lenderSettlementInformation: data.data })
      else
        this.setState({ lenderSettlementInformation: null })
    } catch (ex) {
      this.setState({ lenderSettlementInformation: null })
    }
  };

  initiatePayoutDisbursal = async () => {
    if (!this.state.data.partner) {
      return;
    }

    let payload = { lendingPartnerId: this.state.data.partner };
    let data = await reconService.payoutInit(payload);
  }

  closeDetails = () => {
    this.setState({ showDetails: false })
  }
}

export default LenderSettlementsModel;
