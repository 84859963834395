import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/";

export async function fetchGstDetails(userId,gstNumber) {
  const response = await http.post(
    apiEndpoint + `gst/fetchGstDetails`,
    {
      userId: userId,
      gstNo: gstNumber,
    }
  );
  return response;
}


export async function sendOtpForPrivateData(gstNumber,gstUserName) {
  const response = await http.post(
    apiEndpoint + `gst/sendOtpForPrivateData`,
    {
      gstNo: gstNumber,
      gstUsername:gstUserName
    }
  );
  return response;
}


export async function validateOtp(gstNumber,gstUserName,otp) {
  const response = await http.post(
    apiEndpoint + `gst/validateOtp`,
    {
      gstNo: gstNumber,
      gstUsername:gstUserName,
      otp:otp
    }
  );
  return response;
}

export async function udyamVerification(userId,udyamNumber) {
  const response = await http.post(
    apiEndpoint + `verification/udyamVerification`,
    {
      userId: userId,
      udyamNumber: udyamNumber,
    }
  );
  return response;
}

export async function udyogVerification(userId,udyogNumber) {
  const response = await http.post(
    apiEndpoint + `verification/udyogVerification`,
    {
      userId: userId,
      udyogNumber: udyogNumber,
    }
  );
  return response;
}