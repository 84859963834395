import InputDataForm from "../../components/common/form";
import * as portfolioService from "../../services/portfolio/portfolioService";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import momentJalaali from "moment-jalaali";
import * as analytics from "../../utils/analytics";
import { orderBy } from "lodash";
import moment from "moment";

class PortfolioModel extends InputDataForm {
  state = {
    data: {
      // userId: "",
      name: "",
      loanNo: "",
      productId: "",
      merchantCode: "",
      fromDate: momentJalaali().set("D", 1),
      toDate: momentJalaali(),
    },
    showSpinner: false,
    errors: {},
    userRole: "",
    loanList: [],
    repaymentList: [],
    showRepaymentModal: false,
    loanNo: "",
    summary: {},
    sortBy: "-insertedOn",
    isDisabled: false,
    currentPage: 1,
    pageSize: 0
  };

  schema = {
    name: Joi.any(),
    loanNo: Joi.any(),
    // userId: Joi.any(),
    productId: Joi.any(),
    merchantCode: Joi.any(),
    fromDate: Joi.any(),
    toDate: Joi.any(),
    page: Joi.any(),
  };

  async getPortfolioForBorrower(sortBy) {
    this.setState({ errors: {} })

    const data = { ...this.state.data };
    let dateDiff = moment(data.toDate).diff(data.fromDate, "days")

    if (dateDiff > 30) {
      let errors = this.state.errors
      errors.globalError = "The date difference should be less than or equal to 30 days"
      this.setState({ errors })
      return
    }

    if (!sortBy) sortBy = this.state.sortBy

    data.page = this.state.currentPage

    this.setState({ showSpinner: true });
    try {
      const {
        data: loanList,
        totalPages
      } = await portfolioService.getPortfolioListForBorrowingPartner(data, sortBy);

      this.setState({ data, showSpinner: false, loanList: loanList, pageSize: totalPages });
    } catch (ex) {
      this.setState({ data, showSpinner: false, loanList: [], });
    }
  }

  async onClickExcelExport(isLender) {
    this.setState({ errors: {} })

    const data = { ...this.state.data, isLender: !!isLender };

    let dateDiff = moment(data.toDate).diff(data.fromDate, "days")

    if (dateDiff > 30) {
      let errors = this.state.errors
      errors.globalError = "The date difference should be less than or equal to 30 days"
      this.setState({ errors })
      return
    }

    this.setState({ showSpinner: true });
    try {
      let {
        data: excelList
      } = await portfolioService.downloadBorrowingPartnerPortfolioList(data);

      excelList = orderBy(excelList, "insertedOn", "asc")

      this.setState({ data, showSpinner: false, excelList, downloadExcel: true }, () => {
        this.setState({ downloadExcel: false })
      });
    } catch (ex) {
      this.setState({ data, showSpinner: false, excelList: [], });
    }
  }

  async getPortfolio(sortBy) {
    this.setState({ errors: {} })

    const data = { ...this.state.data };

    let dateDiff = moment(data.toDate).diff(data.fromDate, "days")

    if (dateDiff > 90) {
      let errors = this.state.errors
      errors.globalError = "The date difference should be less than or equal to 90 days"
      this.setState({ errors })
      return
    }

    if (!sortBy) sortBy = this.state.sortBy

    data.page = this.state.currentPage
    this.setState({ showSpinner: true });

    try {
      const { data: loanList, totalPages } = await portfolioService.getPortfolioList(
        data,
        sortBy
      );

      this.setState({
        data,
        showSpinner: false,
        loanList: loanList,
        pageSize: totalPages
      });
    } catch (ex) {
      this.setState({ data, showSpinner: false, loanList: [] });
    }
  }

  async componentDidMount() {
    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location.href = "/login");

    analytics.track();

    let { data, sortBy } = this.state;
    // data.userId = currentUser.userId;

    this.setState({ userRole: currentUser.role, data });

    if (currentUser.role == "Borrowing Partner") {
      await this.getPortfolioForBorrower(sortBy);
    } else {
      await this.getPortfolio(sortBy);
    }
  }

  async getRepaymentDetails(loanNo) {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const {
        data: repaymentData,
      } = await portfolioService.getRepaymentDetails(loanNo);

      this.setState({
        data,
        showSpinner: false,
        repaymentList: repaymentData.emiDetails,
        showRepaymentModal: true,
        loanNo: repaymentData.loanNo,
      });
    } catch (ex) {
      this.setState({ data, showSpinner: false, repaymentList: [] });
    }
  }
  async handleCustomErrors(errors) {
    return errors;
  }
  closeModal = () => {
    this.setState({
      showRepaymentModal: false,
    });
  };

  getPortfolioDoc = async () => {
    let { data } = this.state;
    try {
      this.setState({ showSpinner: true, isDisabled: true });

      const { data: reportData } = await portfolioService.getPortfolioDoc(data);

      let bytes = new Uint8Array(reportData.data); // pass your byte response to this constructor

      let blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes

      this.setState({ showSpinner: false, isDisabled: false });

      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "portfolio.pdf";
      link.click();
    } catch (ex) { }
  };

  submitPortfolio = async (e) => {
    e.preventDefault();
    let { userRole, sortBy } = this.state;
    if (userRole == "Borrowing Partner") {
      this.getPortfolioForBorrower(sortBy);
    } else {
      this.getPortfolio(sortBy);
    }
  };

  sortByLoanNo = async () => {
    let { userRole, sortBy } = this.state;

    sortBy = sortBy == "-loanNo" ? "loanNo" : "-loanNo";
    this.setState({ currentPage: 1 }, () => {

      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower(sortBy);
      } else {
        this.getPortfolio(sortBy);
      }
    })
    this.setState({ sortBy: sortBy });
  };

  sortByMerchantCode = async () => {
    let { userRole, sortBy } = this.state;

    sortBy = sortBy == "-merchantCode" ? "merchantCode" : "-merchantCode";

    this.setState({ currentPage: 1 }, () => {

      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower(sortBy);
      } else {
        this.getPortfolio(sortBy);
      }
    })
    this.setState({ sortBy: sortBy });
  };

  sortByName = async () => {
    let { userRole, sortBy } = this.state;

    sortBy =
      sortBy == "-userDetails.firstName"
        ? "userDetails.firstName"
        : "-userDetails.firstName";

    this.setState({ currentPage: 1 }, () => {

      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower(sortBy);
      } else {
        this.getPortfolio(sortBy);
      }
    })


    this.setState({ sortBy: sortBy });
  };

  sortByAmount = async () => {
    let { userRole, sortBy } = this.state;

    sortBy = sortBy == "-amount" ? "amount" : "-amount";
    this.setState({ currentPage: 1 }, () => {

      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower(sortBy);
      } else {
        this.getPortfolio(sortBy);
      }
    })
    this.setState({ sortBy: sortBy });
  };


  sortByPrincipal = async () => {
    let { userRole, sortBy } = this.state;

    sortBy = sortBy == "-principalAmount.outstanding" ? "principalAmount.outstanding" : "-principalAmount.outstanding";
    this.setState({ currentPage: 1 }, () => {

      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower(sortBy);
      } else {
        this.getPortfolio(sortBy);
      }
    })
    this.setState({ sortBy: sortBy });
  };

  sortByDate = async () => {
    let { userRole, sortBy } = this.state;

    sortBy = sortBy == "-insertedOn" ? "insertedOn" : "-insertedOn";
    this.setState({ currentPage: 1 }, () => {

      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower(sortBy);
      } else {
        this.getPortfolio(sortBy);
      }
    })
    this.setState({ sortBy: sortBy });
  };

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    let { userRole } = this.state;

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower();
      } else {
        this.getPortfolio();
      }
    })
  }

  onPageChange = (page) => {

    let { userRole } = this.state;

    this.setState({ currentPage: page }, () => {
      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower();
      } else {
        this.getPortfolio();
      }
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    let { userRole } = this.state;

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      if (userRole == "Borrowing Partner") {
        this.getPortfolioForBorrower();
      } else {
        this.getPortfolio();
      }
    })
  }
}

export default PortfolioModel;
