import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PaymentLinkGenerateModel from "../../models/paymentLinkGenerate/paymentLinksGenerateModel";
import SpinnerComponent from "../../components/common/spinner";
import { Form, Col, Nav, Row, Button, Modal, Image } from "react-bootstrap";

class paymentLinks extends PaymentLinkGenerateModel {

  render() {
    const {
      showSpinner,
      successMsg,
      errors,
      data,
      loan
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={true}
          onHide={this.props.close}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <span><strong>Payment Link</strong></span>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <SpinnerComponent showSpinner={showSpinner} />
      
              <Form.Row>
              {loan && loan.emiList ? 
                <Form.Group as={Col} md="6" controlId="collect Edi's">
                  <Form.Label>Collect EDI's<span>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    value={data.emiCount}
                    onChange={this.handleEmiNumberChange}
                    name={"emis_" + loan.loanId}
                  >
                    {" "}
                    <option key="emis-select" value="">
                      ---Select---
                    </option>
                    {loan.emiList.map((item, k) => (
                      <option key={k} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group> : ""}
              </Form.Row>
       

            {data && data.emiCount ? <div className="mt-1">
              <Form.Label>Payment Mode<span>*</span></Form.Label>
              <Form.Row>
              {(loan.primaryCollectionType === "eNach" || loan.secondaryCollectionType === "eNach") && loan.isMandateRegistered && (
                <Form.Group as={Col} md="3" controlId="paymentMode">
                  <Form.Check
                    type="radio"
                    id="paymentModeUpi"
                    label="ENach"
                    name="paymentMode"
                    className="paymentMode"
                    checked={data.paymentMode == "ENACH" ? true : false}
                    onChange={() => this.handlePaymentModeChange("ENACH")}
                  />
                </Form.Group>
              )}
                <Form.Group as={Col} md="3" controlId="paymentMode">
                  <Form.Check
                    type="radio"
                    id="paymentModeENach"
                    label="UPI Link"
                    name="paymentMode"
                    className="paymentMode"
                    checked={data.paymentMode == "UPI" ? true : false}
                    onChange={() => this.handlePaymentModeChange("UPI")}
                  />
                </Form.Group>
              </Form.Row>
            </div> : ""}

            {data && data.paymentMode && data.paymentMode == "UPI" ? <Form.Row>
              <Form.Group as={Col} md="6" controlId="mobileNo">
                <Form.Label>Mobile No <span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="mobileNo"
                  value={data["mobileNo"]}
                  onChange={this.handleMobileNumberChange}
                >
                  {" "}
                  <option key="select" value="">
                    ---Select---
                  </option>
                  {loan && loan.mobileNumbers && loan.mobileNumbers.length ?
                    loan.mobileNumbers.map((number) => (
                      <option key={number.mobileNumber} value={number.mobileNumber}>
                        {number.mobileNumber}
                      </option>
                    )) : <option>
                      No data found
                    </option>}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="agentMobileNo">
                <Form.Label>Agent Mobile No</Form.Label>
                <Form.Control
                  type="number"
                  name="agentMobileNo"
                  value={data["agentMobileNo"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </Form.Group>
            </Form.Row> : ""}


            {data.emiCount ? <Form.Row style={{ marginTop: 20, float: 'right' }}>
              <Form.Group as={Col} md="12">
                {this.state.errorMessage && <small className="text-danger pr-2"><strong>{this.state.errorMessage}</strong></small>}
                <Button
                  className="ff-button"
                  onClick={this.onClickSend}
                >
                  Send
                </Button>
              </Form.Group>
            </Form.Row> : ""}

          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default paymentLinks;
