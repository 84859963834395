import InputDataForm from "../../components/common/form";
import Joi from "joi-browser";

class CollectionsQueueMainModel extends InputDataForm {
    constructor(props) {
        super(props);

        this.handler = this.handler.bind(this);
    }
    state = {
        showSpinner: false,
        errors: {},
        emiView: false,
        loanNo: ""
    };
    toggleChange = () => {
        let { emiView } = this.state;
        this.setState({ emiView: !emiView });
    };
    handler(key, value) {
        this.setState({
            [key]: value,
        });
    }
}

export default CollectionsQueueMainModel;
