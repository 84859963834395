import InputDataForm from "../../components/common/form";
import * as manualQueue from "../../services/manualQueue/manualQueue";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import * as commonService from "../../services/common/commonService";
import * as loanService from "../../services/loan/loanService";
import momentJalaali from "moment-jalaali";

class ManualQueueInvoiceModel extends InputDataForm {
  state = {
    data: {
      partnerId: "",
      utrNo: "",
      action: "INVOICE",
      id: ""
    },
    partners: [],
    errors: "",
    showSpinner: false
  };
  schema = {
    partnerId: Joi.any(),
    utrNo: Joi.any(),
    action: Joi.any(),
    id: Joi.any(),
    amount: Joi.any(),
    invoiceNo: Joi.any()
  };

  async componentDidMount() {

    let data = this.props.data;
    if (data) {
      if (data._id)
        this.state.data.id = data._id;

      if (data.utrNo)
        this.state.data.utrNo = data.utrNo;

      if (data.amount)
        this.state.data.amount = data.amount;
    }

    this.getLendingParterList();
  };

  saveManualQueueAction = async () => {
    try {
      let payload = this.state.data;
      this.setState({ showSpinner: true })
      const response = await manualQueue.manualQueueAction(payload);
      toast.success("Settlement Updated Success")
      this.setState({ showSpinner: false });
      this.props.onSuccess && this.props.onSuccess();
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  async getLendingParterList() {
    try {
      const { data: partnerList } = await commonService.getLendingParterList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  getInvoiceList = async () => {
    try {

      if (!this.state.data.partnerId)
        return;

      let payload = {
        partnerId: this.state.data.partnerId,
        fromDate: momentJalaali().startOf('month'),
        toDate: momentJalaali(),
        limit: 500,
        status: "SENT"
      }
      this.setState({ showSpinner: true })
      const response = await loanService.getInvoiceList(payload);

      this.setState({ showSpinner: false })
      if (response && response.data) {
        this.setState({ invoiceList: response.data })
      } else {
        this.setState({ invoiceList: [] })
      }
    } catch (error) {
      this.setState({ showSpinner: false, invoiceList: [] })
    }
  }

  handleChangePartners = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors }, () => {
      this.getInvoiceList()
    });
  };
}

export default ManualQueueInvoiceModel;
