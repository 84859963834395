
import InputDataForm from "../../components/common/form";
import * as reconService from "../../services/recon/reconService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import * as commonService from "../../services/common/commonService";
import { toast } from "react-toastify";

class SettlementPending extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      channel: "WALLET",
      product : "TL_90"
    },
    showSpinner: false,
    errors: {},
    partners: [],
    channels: [
      { title: "Wallet", value: "WALLET" },
      { title: "ENach", value: "ENACH" }
    ],
    productTypes: [
      { title: "TL_90", value: "TL_90" },
      { title: "DL_30", value: "DL_30" }
    ]
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    settlementList: Joi.any(),
    partner: Joi.any(),
    channel: Joi.any(),
    product : Joi.any()
  };
  async getSettlementAlert() {

    const data = { ...this.state.data };

    let daysDiff = commonService.numberOfDaysDifferece(new Date(data.fromDate), new Date(data.toDate));

    if (daysDiff > 30) {
      toast.error("Max Date Range 30 days");
      this.setState({ showSpinner: false });
      return
    }

    this.setState({ showSpinner: true });
    try {
      const { data: settlementList, totalPages } = await reconService.getSettlementPendingDateWise(data);

      data.settlementList = settlementList;

      let totalAmount = 0;
      if (settlementList && settlementList.length) {

        for (let i = 0; i < settlementList.length; i++) {
          totalAmount += settlementList[i].totalPendingAmount || 0;
        }
      }

      this.setState({ data, showSpinner: false, totalAmount });
    } catch (ex) {
      this.setState({ data, showSpinner: false, totalAmount: 0 });
    }
  };

  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  async componentDidMount() {
    await this.getBorroingPartners();
  }

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    await this.getSettlementAlert();
  };

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }

  onChangeHeaderActiveTab = (tab) => {
    this.state.data.channel = tab
    this.setState({ headerActiveTab: tab }, () => {
      this.resetPage(() => {
        this.getSettlementAlert()
      })
    })
  }
}

export default SettlementPending;
