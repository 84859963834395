import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/loan";

export async function getLoanData(loanId) {
  const response = await http.get(
    `${apiEndpoint}/getLoanInformation/${loanId}`
  );
  return response;
}

export async function updateLoanStatus(loanId, model) {
  const response = await http.put(`${apiEndpoint}/updateLoanStatus/${loanId}`, {
    loanStatus: model.status,
    rejectReason: model.rejectReason,
    rejectDescription: model.rejectDescription
  });
  return response;
}


export async function getRejectReasonList(type) {
  const { data } = await http.post(`${apiEndpoint}/getRejectReasonList`, { type: type });
  return data;
}