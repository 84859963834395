import React, { Component } from "react";
import InputDataForm from "../../components/common/form";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import leadInfoService from "../../services/partner/leadInfoService";
// import SharedCache from "../../SharedCache";

class CreditLineRedirect extends InputDataForm {
    state = {
        properties: {
            loanId: "",
            shortauthKey: ""
        },
        errors: {},
        info: {},
        redirectPath: false,
        state: null
    };

    async componentDidMount() {
        const loanId = this.props.match.params.id;

        let properties = { ...this.state.properties };
        properties.loanId = loanId;

        this.setState({ properties });

        if (!loanId)
            this.setState({ redirectPath: "/invalidData", state: { message: "Invalid parameters provided" } });
        else if (!auth.getCurrentUser()) {
            const result = await leadInfoService.getPartnerId(loanId);
            const redirectionUrl = `/creditLineRedirect/${loanId}`;

            if (result.success)
                this.setState({ redirectPath: `/${result.data}/merchantAuthenticate`, state: { loanData: { loanId: loanId, partnerId: result.data, redirectionUrl: redirectionUrl } } });
            else
                this.setState({ redirectPath: "/invalidData", state: { message: result.message } });
        }
        else {
            // await auth.getMenu();
            const currentPartner = auth.getCurrentPartner();
            properties.shortauthKey = currentPartner.shortauthKey;
            this.setState({ redirectPath: `/creditLine`, state: { userData: properties } });
        }
    }
    render() {
        const { redirectPath, state } = this.state;
        return (
            redirectPath && (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state
                    }}
                />
            )
        );
    }
}
export default CreditLineRedirect;
