import React from "react";
import * as analytics from "../../utils/analytics";

function PrivacyPolicy(props) {
  analytics.track();

  return (
    <React.Fragment>
      <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
        Fundfina Privacy Policy
      </h2>
      <p>
        <b>1. Introduction and Summary</b>
      </p>
      <p>
        This is the Privacy Policy of Fundfina Technologies Private Limited and
        its affiliates operating within territory of India (together, “
        <b>Fundfina</b>”, “<b>Fundfina</b>”, “<b>we</b>”, “<b>us</b>” or “
        <b>our</b>”). Fundfina Technologies Private Limited is a company
        incorporated under Companies Act 2013 having Company Identification
        Number U72200JH2016PTC008813. We provide a platform (the “Platform”) via
        www.fundfina.com (the “Website”) for facilitating borrowing and lending
        between our customers. In return for Fundfina services borrowers and
        lenders pay a platform fee.
      </p>
      <p>
        We are committed to protecting and respecting your privacy. This Privacy
        Policy explains and governs:
      </p>
      <ul>
        <li>
          how we collect personal information belonging to visitors to our
          website as well as personal information belonging to Investors,
          Borrowers, persons connected with Investors and Borrowers (such as
          directors, partners, members, shareholders and beneficial owners),
          guarantors, persons connected with guarantors, those representing
          Borrowers and those who use Fundfina’s services (“you” and “your”, as
          appropriate);
        </li>
        <li>how we store and protect your personal information;</li>
        <li>how and why we use your personal information; and</li>
        <li>your rights to control your personal information.</li>
      </ul>
      <p>
        We will only process your personal information in accordance with the
        Information Technology Act 2000, (Indian) Contract Act (1872) and other
        applicable laws and regulations. In particular, we are committed to
        keeping your personal information confidential and will only give it to
        others for the purposes set out in this Privacy Policy.
      </p>
      <p>
        Please read this Privacy Policy carefully. By accessing and using our
        website, Platform and services, you confirm that you have had a proper
        opportunity read this Privacy Policy, that you understand it and that
        you agree to it. If you do not agree to this Privacy Policy in its
        entirety, you must immediately cease use of our website and Platform,
        and any services provided by us.
      </p>
      <p>
        We may amend this Privacy Policy from time to time to comply with
        applicable laws and regulations or to meet our changing business
        requirements. You are encouraged to periodically review this page for
        the latest information on our privacy practices and amendments to our
        Privacy Policy. By continuing to use our website or Platform you agree
        to be bound by all such updates and amendments.
      </p>
      <p>
        Unless stated otherwise, capitalised words and phrases in this Privacy
        Policy shall have the meaning given to them in the Terms and Conditions
        for Investors and in the Terms and Conditions for Borrowers.
      </p>
      <p>
        <b>2. Collecting your Information</b>{" "}
      </p>
      <p>
        <i>How do we collect your information and what do we collect?</i>
      </p>
      <p>
        2.1 If you engage with us in any way, we may collect the following
        information about you in a number of ways:
      </p>
      <ul>
        <li>
          information you provide through our website and through our
          application and verification processes;
        </li>
        <li>
          information you provide through communications with us, whether in
          writing (including email) or on the telephone (including by way of
          recorded calls);
        </li>
        <li>
          information we obtain through your engagement with us on social media,
          including on blogs, forums and through Facebook, Google+, Twitter,
          etc.;
        </li>
        <li>
          information provided on your behalf by your representatives or agents
          (“Agents”) who engage with us on your behalf in the ways described
          above; and
        </li>
        <li>
          information from outside sources such as credit information companies,
          fraud prevention agencies, insolvency practitioners, debt advisers,
          recovery agents, commercial databases, marketing databases, public
          records and other publicly available information sources.
        </li>
      </ul>
      <p>
        2.2 If you wish to become a member of the Platform you will need to
        provide us with the following information, which we may also collect
        from third parties:
      </p>
      <ul>
        <li>
          your personal details (including name, date of birth, current and
          permanent postal addresses);
        </li>
        <li>
          your contact information (including phone, mobile number and e-mail
          details);
        </li>
        <li>
          (for Borrowers, those representing Borrowers and relevant Investors)
          your business name and contact information;
        </li>
        <li>
          financial information (including bank or building society account
          details and details of debit cards used to make transfers on
          Fundfina);
        </li>
        <li>
          information you provide in our registration or application processes
          (including, if you are a Borrower, Agent or relevant Investor, certain
          personal information, identity verification, contact details and
          financial information about directors, partners, members,
          shareholders, beneficial owners and guarantors);
        </li>
        <li>
          information you provide in your dealings with us and through your
          interaction with the Platform;
        </li>
        <li>
          if you are a Borrower or a director, partner, member, shareholder,
          beneficial owner or guarantor of a Borrower:
        </li>
        <ul>
          <li>
            information about your business or company, such as previous credit
            applications and the conduct of your accounts, and similar personal
            credit information;
          </li>
          <li>credit reference checks (see section 3 below for more detail)</li>
          <li>electoral register information; and</li>
          <li>fraud prevention information;</li>
          <li>passwords and security question answers.</li>
        </ul>
      </ul>
      <p>
        2.3 In addition to the personal and financial information you submit or
        we collect as described above, we may also collect information about
        your computer (including, where available, your IP address, operating
        system and browser type), your interaction with the Platform and our
        website, and email performance data, including for the purposes of
        Platform administration and service improvement. See our Cookies Policy
      </p>
      <p>2.4 We also:</p>
      <ul>
        <li>
          retain copies of our correspondence with you as well as other data we
          collect relating to your activities on the Platform and your
          arrangements with Fundfina;
        </li>
        <li>
          collect details about visitors to our website for the purposes of
          aggregating statistics or reporting purposes and to calculate referral
          fees; and
        </li>
        <li>
          retain comments made on blogs and discussion forums in connection with
          the Platform.
        </li>
      </ul>
      <p>
        2.5 We may, particularly in relation to Borrowers, Investors or
        guarantors, be given sensitive personal information (such as medical
        information or criminal records). Where relevant, we will hold and
        process this information to allow us to make decisions about you and
        your accounts with us or with which you are connected. This may involve
        us sharing such information with your Agents. We will process sensitive
        personal information only in accordance with the requirements of the
        Information Technology Act, 2000.
      </p>
      <ul>
        <li>Information you provide about other people</li>
      </ul>
      <p>
        2.6 Where you provide information about other people (for example, if
        you represent a Borrower and you provide information about directors,
        partners, members, shareholders or beneficial owners other than
        yourself) then you confirm:
      </p>
      <ul>
        <li>
          you have provided a copy of this Privacy Policy to such persons and
          you have ensured that they are aware of and understand the contents of
          this Privacy Policy; and
        </li>
        <li>
          by providing such information, that you have all relevant permissions
          and authority (a) to make all such disclosures, (b) to act on their
          behalf and (c) in relation to partners, members, shareholders or
          beneficial owners of Borrowers, to allow us to make credit checks at
          credit reference agencies in respect of such persons.
        </li>
      </ul>
      <p>
        <b>3. Using and disclosing your information</b>
      </p>
      <span>
        <i>Using your information</i>
      </span>
      <p>3.1 We collect, store and use your personal information:</p>
      <ul>
        <li>
          to open accounts with us and to manage and maintain those accounts;
        </li>
        <li>
          to verify your identity and the other information you have provided to
          us, including your bank account information and (if relevant) the
          identity of your business associates;
        </li>
        <li>to update the records we hold about you from time to time;</li>
        <li>
          to provide and administer the Platform and our related services;
        </li>
        <li>
          to inform you of Platform developments and activity and of changes to
          our products and services;
        </li>
        <li>
          to develop and improve our services, products and business, including
          analysing and improving our credit risk models and our customer
          service offering;
        </li>
        <li>
          if you are a Borrower (or a director, partner, member, shareholder,
          beneficial owner or guarantor of a Borrower):
        </li>
        <ul>
          <li>to ascertain your borrowing needs;</li>
          <li>
            to assess your creditworthiness and to make credit-related
            decisions; and
          </li>
          <li>
            if you miss any repayment of your loan, to trace your whereabouts
            and recover debts or enforce a Loan Contract and to verify any
            payment plan you have proposed or income and expenditure form you
            have submitted;
          </li>
        </ul>
        <li>to transfer money;</li>
        <li>to carry out mandatory or other regulatory checks;</li>
        <li>to comply with our legal and regulatory obligations;</li>
        <li>
          to carry out statistical analysis and market research and testing;
        </li>
        <li>where you have given your consent:</li>
        <ul>
          <li>
            to contact you (including by SMS and e-mail) with products and
            services which Fundfina think may interest you; and
          </li>
          <li>
            to disclose your personal information to carefully chosen third
            parties so that they may contact you (including by SMS and e-mail)
            with products and services which they think may interest you or help
            you access credit; and
          </li>
          <li>
            for the prevention and detection of fraud or other illegal or
            criminal activity.
          </li>
        </ul>
      </ul>
      <p>
        <i>Disclosing your information</i>
      </p>
      <p>3.2 We may disclose your personal information:</p>
      <ul>
        <li>
          to other registered members of our Platform or to our affiliates,
          including as follows:
        </li>
        <ul>
          <li>
            to operate the Platform and seek to match Investors and Borrowers;
          </li>
          <li>
            in the Loan Contract and personal guarantee, when Investors and
            Borrowers are matched;
          </li>
          <li>to provide transactional and performance information;</li>
          <li>
            to provide updates (including where there are late repayments or
            where a risk-banding has been downgraded); and
          </li>
          <li>
            if required to enforce (or make preparations to enforce) any Loan
            Contract. in which regard:
          </li>
          <ul>
            <li>
              if you are a recipient of such information then you must only use
              such information (or any other information received about an
              Investor or a Borrower) to communicate with Fundfina about your
              Loan Contract; and
            </li>
            <li>
              you acknowledge that Fundfina is not responsible for misuse of
              transactional or other information by Fundfina members but you
              must inform Fundfina promptly if you are the victim of any misuse
              of such information;
            </li>{" "}
          </ul>
          <li>to companies in our group and our affiliates; </li>
          <li>
            to our suppliers, sub-contractors and third party data processors
            (including card payment and direct debit payment processors,
            marketing and data analytics service providers, collection agents,
            tracing agents, insolvency practitioners, professional advisers and
            persons who provide us with the following services from time to
            time: identification and fraud check; marketing; technology;
            Platform support; and back-up and business continuity). These
            providers are only permitted to process your personal data on our
            behalf and in accordance with our instructions and we will take
            steps to ensure that such transfer and any on-going processing by
            such third parties is carried out securely and in accordance with
            applicable privacy law;
          </li>
          <li>
            with any third party you have asked us to share your personal data
            with, including social media sites if you have asked us to connect
            with your social media account;
          </li>
          <li>
            to Credit Information Companies and fraud prevention agencies (see
            section 4 below for more information); {" "}
          </li>
          <li>
            where we are unable to provide you with credit and where you
            consent, to third parties who may be in a position to arrange credit
            for you;
          </li>
          <li>
            to a third party if it acquires all or part of our business or
            assets in connection with the acquisition, or to a successor in
            interest in the unlikely event of our insolvency, winding up or
            liquidation;
          </li>
          <li>
            where we are required to do so by applicable law and regulation,
            governmental, tax or regulatory body or law enforcement agency;
          </li>
          <li>if you are represented by an Agent, to your Agent; and</li>
          <li>to any other person with your prior consent to do so.</li>
        </ul>
      </ul>
      <p>
        3.3 Save as expressly provided above, or otherwise without your consent,
        Fundfina will not disclose, sell or rent your personal information to
        any third party.
      </p>
      <p>
        <b>
          4. Credit Information Companies (CICs) and Fraud Prevention Agencies
        </b>
      </p>
      <p>
        <i>Credit Information Companies ('CICs')</i>
      </p>
      <p>
        4.1 From time to time we undertake credit reference checks against
        Borrowers and Investors (and against directors, partners, members,
        shareholders and beneficial owners of Borrowers and Investors),
        guarantors and Agents:
      </p>
      <ul>
        <li>as part of the application process; and</li>
        <li>if there are late repayments or default,</li>
      </ul>
      <p>and by proceeding with an application then you consent to this.</p>
      <p>4.2 When CICs receive a search request from Fundfina they will:</p>
      <ul>
        <li>
          place a credit search “footprint” on your business credit file whether
          or not your application proceeds. If the search was for a credit
          application the record of that search may be seen by other
          organisations when you or your business applies for credit in the
          future;
        </li>
        <li>
          place an enquiry or organisational search on the personal credit files
          of directors, partners, members, beneficial owners and shareholders
          that have been searched, as well as an associate enquiry search on
          your personal financial partner’s credit file, if they are a director;
        </li>
        <li>
          link together the previous and subsequent names advised by you of
          anyone that is a party to the account;
        </li>
        <li>
          place an enquiry or identification search on the record of any
          shareholder or beneficial owner and who Fundfina has checked; and
        </li>
        <li>
          create a record of the name and address of your business and its
          proprietors (if there is not one already).
        </li>
      </ul>
      <p>
        4.3 We will give details of all loans taken through the Platform and how
        they are managed to the CICs. If you borrow and do not repay in full and
        on time, the CICs will record the outstanding debt and, in some cases,
        the length of time that the debt remains outstanding; other
        organisations may see these updates and this may affect your ability to
        obtain credit in the future.
      </p>
      <p>
        4.4 Any records shared with CICs will remain on file for seven years
        after your account is closed, whether any outstanding sums have been
        settled by you or following a default.
      </p>
      <p>
        4.5 You can contact the CICs currently operating in India. The
        information they hold may not be the same so you may consider contacting
        them all. They will charge you a small statutory fee. They are:
      </p>
      <ol>
        <li>CIBIL</li>
        <li>Equifax</li>
        <li>Experian</li>
        <li>CRIF High Mark</li>
      </ol>
      <p>False information and Fraud Prevention Agencies ('FPAs')</p>
      <p>
        4.6 If you give us false or inaccurate information or if we suspect or
        identify fraud we may record this and may also pass this information to
        FPAs (such as EOW, FIU, SFIO, etc.) and other organisations involved in
        crime and fraud prevention including law enforcement agencies.
      </p>
      <p>
        4.7 We and other organisations may access and use (a) this information
        in order to prevent fraud, money laundering or other criminal activity
        (for example, by checking details on credit applications, for managing
        credit and recovering debt), (b) information recorded by FPAs from other
        countries, (c) checking details on proposals and claims for all types of
        insurance, and (d) checking details of job applicants and employees.
      </p>
      <p>
        4.8 Please contact us if you want to receive details of the relevant
        fraud prevention agencies.
      </p>
      <p>
        <b>5. Accessing and correcting your information</b>
      </p>
      <p>You may, at any time:</p>
      <ul>
        <li>
          exercise your right to request access to certain personal records we
          hold about you (a subject access request), subject to payment of the
          fee specified by law. Please email contactus@@fundfina.com with the
          subject line “subject access request”;
        </li>
        <li>
          request that we update and correct any out-of-date or inaccurate
          personal information we hold about you. You can email us at
          contactus@@fundfina.com, and also log-in to your Fundfina account and
          make changes yourself;
        </li>
        <li>
          contact us to register your preferences for how we contact you; and
        </li>
        <li>
          request that CICs provide you with information that they hold about
          you. You must contact them directly to do this.
        </li>
      </ul>
      <p>
        <b>6. Opting out </b>
      </p>
      <p>You may, at any time:</p>
      <ul>
        <li>
          opt out of any marketing communications that Fundfina may send you;
          and
        </li>
        <li>
          tell us if you have changed your mind about us referring you to a
          third party broker or lender.
        </li>
      </ul>
      <p>
        Please contact us to tell us if you have changed your mind or, in
        relation to email marketing, unsubscribe following the link on an email
        you have received.
      </p>
      <p>
        <b>7. Security and other Third Parties</b>{" "}
      </p>
      <p>
        7.1 Whilst Fundfina takes appropriate technical and organisational
        measures to safeguard the personal information that you provide to it,
        we accept no liability if communications are intercepted by third
        parties or incorrectly delivered or not delivered.
      </p>
      <p>
        7.2 If Fundfina transfers your information to third parties we will take
        steps to ensure that such transfer and any on-going processing by such
        third parties is carried out securely and in accordance with applicable
        privacy law.
      </p>
      <p>
        7.3 The website and Platform contain links to other websites operated by
        third parties. This Privacy Policy applies only to the personal
        information that Fundfina collects and we are not responsible for
        personal information that third parties may collect, store and use
        through other websites.
      </p>{" "}
      <ul>
        <li>
          You also have a responsibility to ensure that your information is kept
          secure. If you are a member of our Platform, you must:
        </li>
        <ul>
          <li>keep your login details secret;</li>
          <li>log out of your account when not using it;</li>
          <li>
            try and maintain good internet security (for example, be careful
            when using public WiFi or shared access internet connections); and
          </li>
          <li>
            tell us immediately if you think your account has been compromised.
          </li>
        </ul>
      </ul>
      <p>
        <b>8. Contact Us</b>
        <br />
        You can contact us at support@fundfina.com.
      </p>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
