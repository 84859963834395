// import jwtDecode from "jwt-decode";
import http from "./httpService";
import { apiUrl, encryptionKey } from "../../config.json";
import SharedCache from "../../SharedCache";
const CryptoJS = require("crypto-js");

const apiEndpoint = apiUrl + "/ops/auth";
const tokenKey = "token";
const user = "user";
const partner = "partner";
const hideFFLogo = "hideFFLogo";
const menu = "menu"

http.setJwt(getJwt());

export async function login(username, password, partnerId) {
  const { data } = await http.post(apiEndpoint, {
    userName: username ? username.trim() : "",
    password: password ? password.trim() : "",
    partnerId

  }, { headers: { loggedInFrom: "Fundfina" } });

  if (data.success) {
    if (data.message != "password change required") {

      data.user.loggedInFrom = "Fundfina";

      data.user.showHeaderMenu = true;
      data.user.showFooterMenu = true;

      data.user.displayLoanTabs = data.partner && data.partner.data && data.partner.data.displayLoanTabs ? data.partner.data.displayLoanTabs : false;

      // sessionStorage.clear();
      // sessionStorage.setItem(tokenKey, data.user.token);
      // sessionStorage.setItem(user, encrypt(JSON.stringify(data.user)));
      // sessionStorage.setItem(partner, encrypt(JSON.stringify(data.partner.data)));

      SharedCache.init({

        user: data.user,
        partner: data.partner.data
      });
    }
  }

  return data;
}

export async function fetchOtpForLogin(phone, partnerId) {
  const { data } = await http.post(apiEndpoint+`/login/sendOTP`, {
    phone: phone ? phone.trim() : "",
    partnerId
  }, { headers: { loggedInFrom: "Fundfina" } });
  return data;
}

export async function validateOtpForLogin(phone, otp, partnerId) {
  const { data } = await http.post(apiEndpoint+`/login/validateOTP`, {
    phone: phone ? phone.trim() : "",
    otp,
    partnerId
  }, { headers: { loggedInFrom: "Fundfina" } });

  if (data.success) {
    if (data.message == "authentication successful") {

      data.user.loggedInFrom = "Fundfina";

      data.user.showHeaderMenu = true;
      data.user.showFooterMenu = true;

      data.user.displayLoanTabs = data.partner && data.partner.data && data.partner.data.displayLoanTabs ? data.partner.data.displayLoanTabs : false;

      SharedCache.init({

        user: data.user,
        partner: data.partner.data
      });
    }
  }
  return data;
}

export function loginWithJwt(jwt) {
  sessionStorage.setItem(tokenKey, jwt);
}

export async function logout() {

  const { data } = await http.post(apiEndpoint + "/logout", {});
  sessionStorage.removeItem("x-auth-token");
  // sessionStorage.removeItem(user);
  // sessionStorage.removeItem(partner);
}

export function getCurrentUser() {
  try {
    const currentUser = SharedCache.get(user);//sessionStorage.getItem(user);
    return currentUser; //JSON.parse(decrypt(currentUser));
  } catch (ex) {
    return null;
  }
}

export function getCurrentUserMenu() {
  try {
    const currentMenu = SharedCache.get(menu);//sessionStorage.getItem(user);
    return currentMenu; //JSON.parse(decrypt(currentUser));
  } catch (ex) {
    return null;
  }
}

export function getFFLogoDisplay() {
  try {
    const ffLogoDisplay = SharedCache.get(hideFFLogo);//sessionStorage.getItem(hideFFLogo);

    if (ffLogoDisplay)
      return ffLogoDisplay; //JSON.parse(decrypt(ffLogoDisplay));
    else
      return false;

  } catch (ex) {
    return false;
  }
}

export function getJwt() {
  return "";//sessionStorage.getItem(tokenKey);
}

export async function getMenu() {
  const { data } = await http.post(apiEndpoint + "/getMenuList");

  if (data.data)
    SharedCache.set(menu, data.data);

  return data.data;
}

export async function setLoggedInFrom(from) {
  let userObj = {
    loggedInFrom: from,
  };

  SharedCache.set("user", userObj);
  // sessionStorage.clear();
  // sessionStorage.setItem(user, encrypt(JSON.stringify(userObj)));

  return true;
}

export async function clearLoggedInFrom(user) {


  try {
    user.loggedInFrom = "";
    return user;
  } catch (ex) {
    return null;
  }
}

export async function loginWithThirdParty(model) {
  const { data } = await http.post(apiEndpoint + "/socialMediaRegistration", {
    isLogined: model.isLogined,
    accessToken: model.accessToken,
    firstName: model.firstName,
    lastName: model.lastName,
    googleId: model.googleId,
    name: model.name,
    emailId: model.emailId,
    registeredFrom: model.registeredFrom,
    imageUrl: model.imageUrl,
    ipAddress: model.ipAddress,
    partnerName: model.partnerName
  }, { headers: { loggedInFrom: "Fundfina" } });

  data.user.loggedInFrom = "Fundfina";
  data.user.showHeaderMenu = true;
  data.user.showFooterMenu = true;
  data.user.displayLoanTabs = data.partner && data.partner.data && data.partner.data.displayLoanTabs ? data.partner.data.displayLoanTabs : false;

  // sessionStorage.clear();
  // sessionStorage.setItem(tokenKey, data.user.token);
  // sessionStorage.setItem(user, encrypt(JSON.stringify(data.user)));
  // sessionStorage.setItem(partner, encrypt(JSON.stringify(data.partner.data)));

  SharedCache.init({

    user: data.user,
    partner: data.partner.data
  });

  return data;
}

export async function updateMandateDetails(mandate_id, status, message, npci_txn_id) {
  const { data } = await http.post(apiEndpoint + "/updateMandateDetails", {
    mandate_id: mandate_id,
    status: status,
    message: message,
    npci_txn_id: npci_txn_id
  });

  return data;
}

export function getCurrentPartner() {
  try {
    let partnerUser = SharedCache.get(partner);//sessionStorage.getItem(partner);
    return partnerUser; //JSON.parse(decrypt(partnerUser));
  } catch (ex) {
    return null;
  }
}

export function getCurrentVersion() {
  try {
    let version = sessionStorage.getItem(version);
    return JSON.parse(decrypt(version));
  } catch (ex) {
    return null;
  }
}

export function encrypt(plainText) {

  let b64 = CryptoJS.AES.encrypt(plainText, encryptionKey).toString();
  let e64 = CryptoJS.enc.Base64.parse(b64);
  let eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
}

export function decrypt(cipherText) {

  let reb64 = CryptoJS.enc.Hex.parse(cipherText);
  let bytes = reb64.toString(CryptoJS.enc.Base64);
  let decrypt = CryptoJS.AES.decrypt(bytes, encryptionKey);
  let plain = decrypt.toString(CryptoJS.enc.Utf8);
  return plain;
}

export async function setFundfinaLogoDisplay(hideLogo) {
  let value = hideLogo && hideLogo.toLowerCase() === "y" ? true : false;

  let userObj = {
    loggedInFrom: "RedirectAuthLink"
  };

  SharedCache.set("hideFFLogo", hideLogo && hideLogo.toLowerCase() === "y" ? true : false);
  SharedCache.set("user", userObj);

  // sessionStorage.clear();
  // sessionStorage.setItem(user, encrypt(JSON.stringify(userObj)));
  // sessionStorage.setItem(hideFFLogo, encrypt(JSON.stringify(value)));

  return true;
}

export async function getPartnerCodeFromToken(token) {
  const { data } = await http.post(apiEndpoint + "/getPartnerFromToken", {
    token: token
  });

  return data;
}

export async function updateDigilockerResponse(digio_doc_id, status, message) {
  const { data } = await http.post(apiEndpoint + "/updateDigilockerResponse", {
    digio_doc_id: digio_doc_id,
    status: status,
    message: message,
  });

  return data;
}

export function setLogin(user, partner, loggedInFrom) {

  user.loggedInFrom = loggedInFrom;
  user.showHeaderMenu = partner.showHeaderMenu ? true : false;
  user.showFooterMenu = partner.showFooterMenu ? true : false;
  user.displayLoanTabs = partner && partner.data && partner.displayLoanTabs ? partner.displayLoanTabs : false;

  SharedCache.init({
    user: user,
    partner: partner.data
  });

  return true;
}

export async function loanOfferSTPLogin(body) {
  const { data } = await http.post(apiEndpoint + "/loanofferstp", body);

  return data;
}

export default {
  login,
  fetchOtpForLogin,
  validateOtpForLogin,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  getMenu,
  clearLoggedInFrom,
  setLoggedInFrom,
  loginWithThirdParty,
  updateMandateDetails,
  getCurrentPartner,
  setFundfinaLogoDisplay,
  getFFLogoDisplay,
  getPartnerCodeFromToken,
  updateDigilockerResponse,
  encrypt,
  decrypt,
  getCurrentUserMenu,
  setLogin,
  loanOfferSTPLogin
};
