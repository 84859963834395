import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col, Button, Table, FormCheck, Modal } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import CibilReportModel from "../../models/reports/cibilModel";

class CibilReport extends CibilReportModel {
    render() {

        const { data, errors, showSpinner, successMsg, disabled } = this.state;
        if (!auth.getCurrentUser() || auth.getCurrentUser().role.toLowerCase() !== "lending partner") return <Redirect to="/login" />;

        let maxEndDate = moment(data["fromDate"]).add(30, "days")
        let currentDate = moment()

        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>
                    Cibil Report
                </h2>
                <br />
                <br />
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="fromDate">
                            <Form.Label>From Date</Form.Label>
                            <DatePicker
                                name="fromDate"
                                id="fromDate"
                                value={data["fromDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleFromDateChange(value)}
                                placeholder="Enter From Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="toDate">
                            <Form.Label>To Date</Form.Label>
                            <DatePicker
                                name="toDate"
                                id="toDate"
                                value={data["toDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleToDateChange(value)}
                                placeholder="Enter To Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                            {/* <Button className="ff-button" type="button" onClick={(e) => this.handleExport(e, 1)} style={{ marginTop: 30, marginLeft: 10 }} disabled={disabled}>
                                Download V1
                            </Button> */}
                            <Button className="ff-button" type="button" onClick={(e) => this.handleExport(e, 2)} style={{ marginTop: 30, marginLeft: 10 }} disabled={disabled}>
                                Download
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <br />
                {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
                {successMsg && <div className="alert alert-success">{successMsg}</div>}
            </React.Fragment >
        )
    }
}
export default CibilReport;