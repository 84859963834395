import React from "react";
import LoanFormModel from "../../models/borrow/loanFormModel";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import Tnc from "../../views/companyInfo/tnc";
import PrivacyPolicy from "./../companyInfo/privacyPolicy";
import { isMobile } from 'react-device-detect';
import NumberFormat from 'react-number-format';
import Background from '../../images/holi_2.png';

class LoanForm extends LoanFormModel {
  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    const {
      data,
      errors,
      purposeList,
      repaymentFrequencyList,
      campaignTimeList,
      showSpinner,
      tnc,
      showTnc,
      showPrivacyPolicy,
      isSubmitted,
      disabled,
      showTopNxtBtn,
      loanEstimateData,
      productType,
      LC_Tenure,
      discountText,
      amountDisabled,
      displayEstimate,
      showKycExpiredModal,
      setActiveTab
    } = this.state;
    const tncLabel = (
      <React.Fragment>
        I have read and agree to{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewTnc}
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewPrivacyPolicy}
        >
          Privacy Policy
        </a>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} /> <br />
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <h2>Loan Offer   </h2>
            {isMobile && showTopNxtBtn && (<Button
              className="ff-button"
              type="submit"
              disabled={isSubmitted}
              onChange={this.handleTnc}
              style={{ marginLeft: 20, marginTop: 1, marginBottom: 7 }}
            >
              Next
            </Button>)}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={productType !== "LC" ? "3" : "4"} controlId="amount">
              <Form.Label>Amount*</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter loan amount"
                name="amount"
                value={data["amount"]}
                onChange={this.handleAmountChange}
                autoComplete="off"
                disabled={amountDisabled}
              />
              {errors["amount"] && (
                <div className="alert alert-danger">{errors["amount"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={productType !== "LC" ? "3" : "4"} controlId="purpose">
              <Form.Label>Purpose*</Form.Label>
              <Form.Control
                as="select"
                name="purpose"
                value={data["purpose"]}
                onChange={this.handleChange}
                disabled={disabled}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                {purposeList.map((item, i) => (
                  <option key={i} value={item.defination}>
                    {item.defination}
                  </option>
                ))}
              </Form.Control>

              {errors["purpose"] && (
                <div className="alert alert-danger">{errors["purpose"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md={productType !== "LC" ? "3" : "4"} controlId="campaignTime">
              <Form.Label>Tenure*</Form.Label>
              <Form.Control
                as="select"
                name="campaignTime"
                value={data["campaignTime"]}
                onChange={this.handleChange}
                disabled={disabled}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                {campaignTimeList.map((item, i) => (
                  <option key={i} value={item.defination}>
                    {item.defination}
                  </option>
                ))}
              </Form.Control>
              {errors["campaignTime"] && (
                <div className="alert alert-danger">
                  {errors["campaignTime"]}
                </div>
              )}
            </Form.Group>
            {productType !== "LC" && (
              <Form.Group as={Col} md={productType !== "LC" ? "3" : "4"} controlId="repaymentFrequency">
                <Form.Label>Repayment Frequency*</Form.Label>
                <Form.Control
                  as="select"
                  name="repaymentFrequency"
                  value={data["repaymentFrequency"]}
                  onChange={this.handleChange}
                  disabled={disabled}
                >
                  <option key="select" value="">
                    ---Select---
                  </option>
                  {repaymentFrequencyList.map((item, i) => (
                    <option key={i} value={item.defination}>
                      {item.defination}
                    </option>
                  ))}
                </Form.Control>
                {errors["repaymentFrequency"] && (
                  <div className="alert alert-danger">
                    {errors["repaymentFrequency"]}
                  </div>
                )}
              </Form.Group>)}
          </Form.Row>
          {discountText && (
            <Form.Row>
              <Table responsive bordered hover>
                <tbody>
                  {discountText && (
                    <React.Fragment>
                      <tr>
                        <td colSpan={2} style={{ width: "100%", background: `url(${Background})` }}></td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ width: "100%", textAlign: "center", fontSize: isMobile ? 14 : 20, backgroundColor: "#ffffff", fontFamily: "Verdana" }}><b>{discountText}</b></td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ width: "100%", background: `url(${Background})` }}></td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </Table>
            </Form.Row>
          )}
          {displayEstimate && productType !== "LC" && (
            <Form.Row>
              <Table responsive striped bordered hover>
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}><b>Repayment Instalments (Collected {loanEstimateData.frequency}) From {loanEstimateData.bankWallet}</b></td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.emiAmount && <NumberFormat value={loanEstimateData.emiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Processing Fee Rate</td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.processingFeeCharge} {loanEstimateData && loanEstimateData.processingFeeCharge && <i className="fa fa-percent" aria-hidden="true"></i>}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Processing Fee</td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.processingCharges && <NumberFormat value={loanEstimateData.processingCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>
                  </tr>
                  {/* <tr>
                    <td style={{ width: "50%" }}>Rate of Interest</td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.interestRate} {loanEstimateData && loanEstimateData.interestRate && <i className="fa fa-percent" aria-hidden="true"></i>} {loanEstimateData && loanEstimateData.interestRate && "per annum"}</td>
                  </tr> */}
                  <tr>
                    <td style={{ width: "50%" }}>Total Interest Payable</td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.totalInterest && <NumberFormat value={loanEstimateData.totalInterest} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Other Charges</td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.otherCharges && <NumberFormat value={loanEstimateData.otherCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}><b>Total Disbursal Amount to {loanEstimateData.bankWallet}</b></td>
                    <td style={{ width: "50%" }}><b>{loanEstimateData && loanEstimateData.disbursalAmount && <NumberFormat value={loanEstimateData.disbursalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</b></td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Late Payment Charges (If not paid within {loanEstimateData.lateDays} days) per instalment</td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.lateCharges && <NumberFormat value={loanEstimateData.lateCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>
                  </tr>
                </tbody>
              </Table>
            </Form.Row>)}
          {displayEstimate && productType === "LC" && (
            <Form.Row>
              <Table responsive striped bordered hover>
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Processing Fee</td>
                    <td style={{ width: "50%" }}>{loanEstimateData && loanEstimateData.processingCharges && <NumberFormat value={loanEstimateData.processingCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}><b>Total Available Credit Limit</b></td>
                    <td style={{ width: "50%" }}><b>{loanEstimateData && loanEstimateData.disbursalAmount && <NumberFormat value={loanEstimateData.disbursalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</b></td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}>Maximum Tenure for Repayment of Each Invoice</td>
                    <td style={{ width: "50%" }}>{LC_Tenure}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}><b>Mode of Collection </b></td>
                    <td style={{ width: "50%" }}><b>{loanEstimateData.bankWallet}</b></td>
                  </tr>
                </tbody>
              </Table>
            </Form.Row>)}
          <Form.Group controlId="tnc">
            <Form.Check
              type="checkbox"
              label={tncLabel}
              feedback="You must agree before submitting."
              checked={tnc}
              onChange={() => this.handleTnc(tnc)}
              color="red"
            />{" "}
            {errors["tnc"] && (
              <div className="alert alert-danger">{errors["tnc"]}</div>
            )}
          </Form.Group>{" "}
          <Button
            className="ff-button"
            type="submit"
            disabled={isSubmitted}
            onChange={this.handleTnc}
            style={{ width: 150 }}
          >
            Next
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
        <Modal
          size="lg"
          show={showTnc}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <Tnc />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={showPrivacyPolicy}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <PrivacyPolicy />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={showKycExpiredModal}
          onHide={this.handleKYCModalClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title> <span className="text-danger">KYC Expired</span></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <p>Your KYC has expired. Complete your KYC process once again.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleKYCModalClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    );
  }
}

export default LoanForm;
