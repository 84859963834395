import React from "react";
import PartnerTestimonialSlider from './../../components/common/partnerTestimonialSlider';

import {
  Form,
  Col,
  CardDeck,
  Card,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { partnerMailText } from "../../config.json";
import CountUp from "react-countup";

function Partner(props) {
  return (
    <React.Fragment>
      <div className="imgbox">
        <img
          className="img-responsive partnerImage"
          src={require("../../images/partner.jpg")}
          alt="header image"
        />
        <div className="img-title">
          <Form>
            <Form.Row>
              <Form.Label className="imgHeader">PARTNER WITH US</Form.Label>
              <Form.Label className="imgText">
                {" "}
                Fundfina strives to democratize finance by upcycling digital
                footprint of consumers and providing access to affordable
                credit. Partner with us to unlock the untapped value sitting in
                the information silos of your enterprise.
              </Form.Label>
              <Form.Label className="imgText">
                {" "}
                Check{" "}
                <a
                  href="https://developer.fundfina.com"
                  target="_blank"
                  className="developerLink"
                >
                  here
                </a>{" "}
                to view our Open API's
              </Form.Label>
            </Form.Row>
          </Form>
        </div>
        <div className="city-title">
          <Form>
            <Form.Row>
              <Form.Group className="noBottom" as={Col} md="4">
                <Form.Label className="imgText" style={{ fontSize: "20px" }}>
                  <CountUp end={25000} />+ <br />
                  Loans Originated
                </Form.Label>
              </Form.Group>
              <Form.Group className="noBottom" as={Col} md="4">
                <Form.Label className="imgText" style={{ fontSize: "20px" }}>
                  <CountUp end={500} />+
                  <br />
                  Cities Served
                </Form.Label>
              </Form.Group>
              <Form.Group className="noBottom" as={Col} md="4">
                <Form.Label className="imgText" style={{ fontSize: "20px" }}>
                  <CountUp end={20} />+
                  <br />
                  Active Partners
                </Form.Label>
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      </div>{" "}
      <br />
      <CardDeck>
        <Card>
          <Card.Body>
            <Card.Title className="cardHeader">
              Enterprise Lending as a Service
            </Card.Title>
            <Card.Text>
              You are an enterprise with retail and business customers and want
              to setup a lending program to meet the financing needs of
              customers. Fundfina will provide seamless financing experience
              through APIs to improve customer profitability and maximize
              retention.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a href={partnerMailText.txtELS}>
              <Button
                variant="primary"
                className="ff-button"
                style={{ marginRight: 20 }}
              >
                Just Hit Send
              </Button>
            </a>
            <Link to="/login">
              <Button variant="primary" className="ff-button">
                Login
              </Button>
            </Link>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title className="cardHeader">Lend on Marketplace</Card.Title>
            <Card.Text>
              You are a Bank or NBFC or financial institution exploring means of
              digitizing MSME loan and personal loan origination process.
              Fundfina will help you achieve favorable business outcomes by
              providing superior customer experience and deep analytic insights.
            </Card.Text>
          </Card.Body>{" "}
          <Card.Footer>
            <a href={partnerMailText.txtLOM}>
              <Button
                variant="primary"
                className="ff-button"
                style={{ marginRight: 20 }}
              >
                Just Hit Send
              </Button>
            </a>
            <Link to="/login">
              <Button variant="primary" className="ff-button">
                Login
              </Button>
            </Link>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title className="cardHeader">Referral</Card.Title>
            <Card.Text>
              You are a financial advisor, wealth manager, broker, Professional
              with captive audience of MSME business owners and individuals.
              Fundfina works with wide variety of partners who refer traffic to
              Fundfina allowing them to earn commissions while helping your
              audience find suitable financing solution.
            </Card.Text>
          </Card.Body>{" "}
          <Card.Footer>
            <a href={partnerMailText.txtReferral}>
              <Button variant="primary" className="ff-button">
                Just Hit Send
              </Button>
            </a>
          </Card.Footer>
        </Card>
      </CardDeck>
      <React.Fragment>
        <div>
          {" "}
          <br />
          <h3 className="bold" style={{ textAlign: "center" }}>
            SOME OF OUR PARTNERS
          </h3>
          <marquee scrollamount="15">
            <img
              src={require("../../images/eko.png")}
              className="partnerLogo"
            />
            <img
              src={require("../../images/kudos.png")}
              className="partnerLogo"
            />
            <img
              src={require("../../images/oxigen.png")}
              className="partnerLogo"
            />
            <img
              src={require("../../images/fintrees.png")}
              className="partnerLogo"
              style={{ maxHeight: 70 }}
            />
            <img
              src={require("../../images/idfc.png")}
              className="partnerLogo"
            />
            <img
              src={require("../../images/pay1.svg")}
              className="partnerLogo"
              style={{ maxHeight: 70 }}
            />
            <img
              src={require("../../images/payworld.png")}
              className="partnerLogo"
            />
            {/* <img
              src={require("../../images/inditrade.png")}
              className="partnerLogo"
            /> */}
          </marquee>
        </div>
      </React.Fragment>
      <PartnerTestimonialSlider />

    </React.Fragment>
  );
}

export default Partner;
