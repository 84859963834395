import InputDataForm from "../../components/common/form";
import * as portfolioService from "../../services/portfolio/portfolioService";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import momentJalaali from "moment-jalaali";

class PortfolioSummaryModel extends InputDataForm {
  state = {
    data: {
      name: "",
      loanNo: "",
    },
    showSpinner: false,
    errors: {},
    userRole: "",
    loanList: [],
    repaymentList: [],
    showRepaymentModal: false,
    loanNo: "",
    loanId: "",
    loanContractDetails: {},
    summary: {},
    sortBy: "-loanNo",
  };
  schema = {
    name: Joi.any(),
    loanNo: Joi.any(),
  };
  async getPortfolioForAdmin(sortBy) {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });

    try {
      const { data: loanList } = await portfolioService.getPortfolioForAdmin(
        data,
        sortBy
      );

      // const {
      //   data: summary,
      // } = await portfolioService.getAdminPortFolioDetails();

      this.setState({
        data,
        showSpinner: false,
        loanList: loanList,
      });
    } catch (ex) {
      this.setState({ data, showSpinner: false, loanList: [] });
    }
  }
  async componentDidMount() {
    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location.href = "/login");

    let { data, sortBy } = this.state;

    this.setState({ userRole: currentUser.role });

    if (currentUser.role == "Admin") {
      await this.getPortfolioForAdmin(sortBy);
    } else {
      window.location.href = "/";
    }
  }
  async getRepaymentDetails(loanNo) {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const { data: repaymentData } =
        await portfolioService.getRepaymentDetails(loanNo);

      this.setState({
        data,
        showSpinner: false,
        repaymentList: repaymentData.emiDetails,
        showRepaymentModal: true,
        loanNo: repaymentData.loanNo,
        loanContractDetails: repaymentData.loanContractDetails,
        loanId: repaymentData._id,
      });
    } catch (ex) {
      this.setState({ data, showSpinner: false, repaymentList: [] });
    }
  }
  async handleCustomErrors(errors) {
    return errors;
  }
  closeModal = () => {
    this.setState({
      showRepaymentModal: false,
    });
  };
  submitPortfolio = async (e) => {
    e.preventDefault();
    let { userRole, sortBy } = this.state;
    if (userRole == "Admin") {
      await this.getPortfolioForAdmin(sortBy);
    } else {
      window.location.href = "/";
    }
  };
  sortByLoanNo = async () => {
    let { sortBy } = this.state;

    sortBy = sortBy == "-loanNo" ? "loanNo" : "-loanNo";

    await this.getPortfolioForAdmin(sortBy);

    this.setState({ sortBy: sortBy });
  };
  sortByName = async () => {
    let { sortBy } = this.state;

    sortBy =
      sortBy == "-userDetails.firstName"
        ? "userDetails.firstName"
        : "-userDetails.firstName";

    await this.getPortfolioForAdmin(sortBy);

    this.setState({ sortBy: sortBy });
  };
  sortByAmount = async () => {
    let { sortBy } = this.state;

    sortBy = sortBy == "-amount" ? "amount" : "-amount";

    await this.getPortfolioForAdmin(sortBy);

    this.setState({ sortBy: sortBy });
  };
}

export default PortfolioSummaryModel;
