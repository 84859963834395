import React from "react";
import DatePicker from "react-datepicker2";
import moment from "moment";

import { Form, Col, InputGroup, Button, Nav, Modal, Row } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect, Link } from "react-router-dom";
import AgentLoanCollectionModel from "../../models/agent/agent";
import { isMobile } from 'react-device-detect';
import ChangeMobileNumber from "./change-mobile-number";


class AgentLoanCollection extends AgentLoanCollectionModel {

    getEmiListDropdown = () => {
        let arr = []
        let count = this.state.pendingInstallments || 90
        for (let i = 0; i < count; i++) {
            arr.push(
                <option key={i} value={i + 1}>
                    {i + 1}
                </option>
            )
        }
        return arr
    }

    formatAmount(value, n = 2) {
        let number = value ? +value : 0
        number = number.toLocaleString('en-IN', {
            maximumFractionDigits: n,
            style: 'currency',
            currency: 'INR'
        })
        return number
    }
    render() {
        let {
            data,
            errors,
            showSpinner,
            mobileNumbers,
            files,
            fileErrors,
            collectionDetail,
            isAgentProfileUpdated
        } = this.state;
        if (this.state.showAddMobileNumber) return <ChangeMobileNumber back={this.handleBack} loanId={this.state.collectionDetail?.loanId} />

        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2 style={{ textAlign: "center" }}>Loan Collection</h2> <br />
                <div>
                    <div className="row">
                        {
                            !this.state.isSentPaymentLink &&
                            <div className="col-sm-12 col-md-6 col-lg-6">

                                <Form>
                                    <Form.Row>
                                        <Form.Group as={Col} md="8" controlId="loanNo">
                                            <Form.Label>Loan Number*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Loan Number"
                                                name="loanNo"
                                                value={data["loanNo"]}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                                autoFocus={true}
                                                required
                                            />
                                            {errors["loanNo"] && (
                                                <div className="alert alert-danger">{errors["loanNo"]}</div>
                                            )}
                                        </Form.Group>

                                    </Form.Row>

                                    {
                                        !this.state.isShowCollectionDetail ?
                                            <div>
                                                <div className="pt-4">
                                                    <Button
                                                        className="ff-button"
                                                        onClick={this.getLoanDetail}
                                                        disabled={!this.coordinates}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
                                                    {errors["globalError"] && (
                                                        <div className="alert alert-danger">{errors["globalError"]}</div>
                                                    )}
                                                </Form.Group>

                                            </div>
                                            :
                                            <div>

                                                <div className="mt-2">
                                                    <Form.Label>Payment Type<span>*</span></Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="4" controlId="PaymentType">
                                                            <Form.Check
                                                                type="radio"
                                                                id="PaymentType"
                                                                label="UPI"
                                                                name="PaymentType"
                                                                className="PaymentType"
                                                                checked={data.paymentType == "UPI_COLLECTION" ? true : false}
                                                                onChange={() => this.handlePaymentTypeChange("UPI_COLLECTION")}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="PaymentType1">
                                                            <Form.Check
                                                                type="radio"
                                                                id="PaymentType1"
                                                                label="NEFT"
                                                                name="paymentType"
                                                                className="paymentType"
                                                                checked={data.paymentType == "VA_COLLECTION" ? true : false}
                                                                onChange={() => this.handlePaymentTypeChange("VA_COLLECTION")}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="PaymentType2">
                                                            <Form.Check
                                                                type="radio"
                                                                id="PaymentType2"
                                                                label="WALLET"
                                                                name="paymentType"
                                                                className="paymentType"
                                                                checked={data.paymentType == "WALLET" ? true : false}
                                                                onChange={() => this.handlePaymentTypeChange("WALLET")}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </div>

                                                <div className="mt-2">
                                                    <Form.Label>Payment Option<span>*</span></Form.Label>
                                                    <Form.Row>
                                                        <Form.Group as={Col} md="4" controlId="paymentMode">
                                                            <Form.Check
                                                                type="radio"
                                                                id="paymentModeFull"
                                                                label="Full Payment"
                                                                name="paymentMode"
                                                                className="paymentMode"
                                                                checked={data.paymentMode == "FULL" ? true : false}
                                                                onChange={() => this.handlePaymentModeChange("FULL")}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" controlId="paymentMode">
                                                            <Form.Check
                                                                type="radio"
                                                                id="paymentModePartial"
                                                                label="Partial Payment"
                                                                name="paymentMode"
                                                                className="paymentMode"
                                                                checked={data.paymentMode == "PARTIAL" ? true : false}
                                                                onChange={() => this.handlePaymentModeChange("PARTIAL")}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </div>


                                                {
                                                    data.paymentMode == "PARTIAL" ?
                                                        <div className="mt-2">
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="8" controlId="collect Edi's">
                                                                    <Form.Label>Collect EDI's<span>*</span></Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        value={data.emiCount}
                                                                        onChange={this.handleEmiNumberChange}
                                                                        name={"emisCount"}
                                                                    >
                                                                        {" "}
                                                                        <option key="emis-select" value="">
                                                                            ---Select---
                                                                        </option>
                                                                        {this.getEmiListDropdown()}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </div> : ""
                                                }

                                                {
                                                    data.paymentType == "WALLET" ?
                                                        <div className="mt-2">
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="8" controlId="fromDate">
                                                                    <Form.Label>Payment Date <span>*</span></Form.Label>
                                                                    <DatePicker
                                                                        name="fromDate"
                                                                        id="fromDate"
                                                                        value={data["fromDate"]}
                                                                        min={moment().subtract(1, "day")}
                                                                        inputFormat="DD/MM/YYYY"
                                                                        isGregorian={true}
                                                                        className="form-control"
                                                                        inputReadOnly={true}
                                                                        showTodayButton={false}
                                                                        timePicker={false}
                                                                        onChange={(value) => this.handleFromDateChange(value)}
                                                                        placeholder="Expected Payment Date"
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                        :
                                                        <div className="my-2">
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="8" controlId="mobileNo">
                                                                    <Form.Label>Mobile No <span>*</span></Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        name="mobileNo"
                                                                        value={data["mobileNo"]}
                                                                        onChange={this.handleMobileNumberChange}
                                                                    >
                                                                        {" "}
                                                                        <option key="select" value="">
                                                                            ---Select---
                                                                        </option>
                                                                        {mobileNumbers.length ?
                                                                            mobileNumbers.map((number) => (
                                                                                <option key={number.mobileNumber} value={number.mobileNumber}>
                                                                                    {number.mobileNumber}
                                                                                </option>
                                                                            )) : <option>
                                                                                No data found
                                                                            </option>}
                                                                    </Form.Control>
                                                                    <div className="text-right pt-2 ">
                                                                        <Form.Label className="cursor-pointer" onClick={this.showAddMobileNumber}>Add New Mobile No</Form.Label>
                                                                    </div>
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </div>
                                                }

                                            </div>
                                    }

                                </Form>
                            </div>
                        }

                        <div className="col-sm-12 col-md-6 col-lg-6">
                            {
                                this.state.isShowCollectionDetail ?
                                    <div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div>Loan Number : <b>{this.state?.data?.loanNo}</b></div>
                                                <div className="pt-2">Loan Amount : <b>{this.formatAmount(this.state?.collectionDetail?.amount)}</b></div>
                                                <div className="pt-2">Pending Edi's : <b>{this.state.pendingInstallments}</b></div>
                                                <div className="pt-2">Total Pending Amount : <b>{this.formatAmount(this.state.collectionDetail?.totalPendingAmount)}</b></div>

                                                {data.paymentMode == "PARTIAL" ? <div className="pt-2">Selected Edi's : <b>{data.paymentMode == "PARTIAL" ? (this.state.data?.emiCount || "Not Selected") : this.state?.pendingInstallments}</b></div> : ""}
                                                {/* <div className="pt-2">Penalty Amount : <b>{this.formatAmount(this.state.collectionDetail?.totalPenalty)}</b></div> */}
                                                {this.state.isSentPaymentLink ? <div className="pt-2">Status : <b>Request sent {!this.state.collectionDetail?.id ? `by fundfina ${this.state.collectionDetail?.requestedOn ? `at ${moment(this.state.collectionDetail.requestedOn).format("DD/MM/yyyy hh:mm a")}` : ""}` : ""}</b></div> : ""}
                                                {this.state.isSentPaymentLink && this.state.collectionDetail?.id && !this.state.collectionDetail?.accountInfo?.accountNumber ? <div className="pt-2">Payment Link : <b>{this.state.collectionDetail?.paymentLink}</b></div> : ""}
                                                <div className="font-20 pt-2 text-danger"><b>Collection Amount : {this.formatAmount(this.state.collectionDetail?.collectionAmount)}</b></div>

                                                {
                                                    this.state.collectionDetail?.accountInfo?.accountNumber ?
                                                        <div className="pt-4">
                                                            <div>
                                                                <div><strong>Account Details</strong></div>
                                                                <hr />
                                                            </div>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div>Acc No : <b>{collectionDetail?.accountInfo?.accountNumber}</b></div>
                                                                    <div>IFSC : <b>{collectionDetail?.accountInfo?.ifsc}</b></div>
                                                                    <div>Acc Name : <b>{collectionDetail?.accountInfo?.name}</b></div>
                                                                    <div>Acc Type : <b>{collectionDetail?.accountInfo?.accountType}</b></div>
                                                                    <div>Bank : <b>{collectionDetail?.accountInfo?.bank}</b></div>
                                                                </div>
                                                            </div>
                                                        </div> : ""
                                                }

                                                {
                                                    this.state.isSentPaymentLink && this.state.collectionDetail?.id ?
                                                        <div className="pt-4">
                                                            {
                                                                !this.state.collectionDetail?.file ?
                                                                    <div>
                                                                        <Form.Row>
                                                                            <Form.Group as={Col} md="8" controlId="collectionFile">
                                                                                <Form.Label>Upload Payment Confirmation Image*</Form.Label>
                                                                                <Form.File
                                                                                    label={files["collectionFileLabel"]}
                                                                                    onChange={(e) => this.onFileChange(e, true, "")}
                                                                                    id="collectionFile"
                                                                                    name="collectionFile"
                                                                                    accept=".jpg,.png,.jpeg,.pdf"
                                                                                    custom
                                                                                    capture={true}
                                                                                />{" "}
                                                                                {fileErrors["collectionFile"] && (
                                                                                    <div className="alert alert-danger">
                                                                                        {fileErrors["collectionFile"]}
                                                                                    </div>
                                                                                )}
                                                                            </Form.Group>
                                                                        </Form.Row>

                                                                        <Button className="ff-button" onClick={this.onClickFileUpload}>
                                                                            Upload
                                                                        </Button>
                                                                        <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
                                                                            {errors["globalError"] && (
                                                                                <div className="alert alert-danger">{errors["globalError"]}</div>
                                                                            )}
                                                                        </Form.Group>
                                                                    </div> :
                                                                    <div>
                                                                        <div className="pt-2">File :  <Form.Label><a href={this.state?.collectionDetail?.file?.signedUrl}>View </a>  <span className="pl-2">  <i className="fa fa-close cursor-pointer" aria-hidden="true" onClick={this.onClickRemoveFile}></i> </span> </Form.Label></div>
                                                                    </div>
                                                            }
                                                        </div> : ""
                                                }
                                            </div>
                                            {
                                                this.state.isSentPaymentLink && this.state.collectionDetail?.id ?
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col text-right">


                                                                <Button disabled={!isAgentProfileUpdated} className="ff-button mr-2 mt-2" onClick={this.sendMyIdentity}>
                                                                    Send My ID
                                                                </Button>

                                                                <Button
                                                                    className="ff-button mr-2 mt-2"
                                                                    onClick={this.onClickCancelPaymentLink}
                                                                    type="submit"
                                                                >
                                                                    Cancel Payment
                                                                </Button>
                                                                {
                                                                    !this.state.collectionDetail?.accountInfo?.accountNumber ?
                                                                        <Button
                                                                            className="ff-button mr-2 mt-2"
                                                                            onClick={this.resendPaymentLink}
                                                                            type="submit"
                                                                        >
                                                                            Resend Link
                                                                        </Button> : ""
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                    : <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col text-right">
                                                                <Button disabled={!isAgentProfileUpdated} className="ff-button" onClick={this.sendMyIdentity}>
                                                                    Send My ID
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                        {
                                            !isAgentProfileUpdated ? <div className="pt-4">
                                                <div className="alert alert-danger">Please share your ID Card image with Fundfina.</div>
                                            </div> : ""
                                        }
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>


                    {
                        this.state.isShowCollectionDetail && !this.state.isSentPaymentLink ?
                            <div>
                                <div className="pt-4">
                                    <Button
                                        className="ff-button mr-2"
                                        onClick={this.sendPaymentLink}
                                        type="submit"
                                        disabled={((data.paymentType == "VA_COLLECTION" || data.paymentType == "UPI_COLLECTION") && !data["mobileNo"]) || (data.paymentMode == "PARTIAL" && !data.emiCount) || (data.paymentType == "WALLET" && !data.fromDate)}
                                    >
                                        Send Payment Request
                                    </Button>
                                    <Button className="ff-button mr-2" onClick={() => this.handleSendEngagementAppLink(this.state.collectionDetail.loanId)}>
                                        Send Loan Details
                                    </Button>
                                </div>
                                <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
                                    {errors["globalError"] && (
                                        <div className="alert alert-danger">{errors["globalError"]}</div>
                                    )}
                                </Form.Group>

                            </div>
                            : ""
                    }
                </div>
                <Modal
                    show={this.state.showCancelConfirmModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton={false}>
                        <b>   <i className="fa fa-info-circle fa-lg" aria-hidden="true" style={{ color: "blue" }}></i>{" "}Confirmation</b>
                    </Modal.Header>
                    <Modal.Body>
                        Do you want to cancel this payment ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.cancelPaymentLink} className="ff-button" style={{ width: isMobile ? "100%" : 150 }}>
                            Yes
                        </Button>
                        <Button onClick={this.closeConfirm} className="ff-button" style={{ width: isMobile ? "100%" : 150 }}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default AgentLoanCollection;
