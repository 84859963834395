import React from "react";
import { Accordion, Card } from "react-bootstrap";
import * as analytics from "../../utils/analytics";

function InvestContent(props) {
  analytics.track();

  return (
    <React.Fragment>
      <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
        Invest / Lend
      </h2>
      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            What can I invest in on Fundfina?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              An investor can invest in personal unsecured loans and make a
              portfolio of such loans for maximizing returns. Creation of
              portfolio mitigates diversifies risk and optimizes returns.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Am I eligible to invest in Fundfina loans?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              As per the para 4(iv) of the Master Directions issued by RBI, the
              term ’person’ on the platform shall include an individual, a body
              of individuals, a HUF, a firm, a society or any artificial body,
              whether incorporated or not. To be a borrower on Fundfina platform
              (https://alpha.Fundfina.com) you must meet the following criteria:
              <ul>
                <li>
                  you must have and maintain a valid and regulated bank account
                  and/or e-money wallet regulated by Reserve Bank of India to
                  receive sanctioned loan amount and facilitate repayments to
                  lenders;
                </li>
                <li>
                  you have not invested or lent more than aggregated Rs
                  10,00,000/- across across all P2Ps at any point of time;{" "}
                </li>
                <li>
                  you must register, agree to terms of usage and submit details
                  of your KYC on our platform.
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            What kind of returns could I expect?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              On a net basis, lenders can expect a return of 14-24% on an
              annualised basis. As with most forms of investment, marketplace /
              peer-to-peer lending carries a degree of risk to your capital; in
              this case, if borrowers are unable to repay their loans. At
              Fundaura, we seek to reduce the risks to our investors by ensuring
              that only genuine borrowers, appropriately priced for risk, are
              able to launch loan proposals on the platform, and by ensuring
              that investors are made aware of the risks of investing in these
              loans and the benefits of diversifying their investments.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Are there fees associated with investing?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              Yes. Fundfina charges 3% of principal amount invested in any loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            How do I track my investment performance?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              Fundfina sends virtual account statements and portfolio
              performance statements at the beginning of every month. In the
              interim one can request either of the statements by writing to us
              at service@Fundfina.com or sending us a WhatsApp message on the
              numbers provided in Contact Us section. These statements can also
              be accessed through your Fundfina account online.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="5">
            Can I auto-invest?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              es a lender can auto-invest. The purpose of auto-invest feature is
              to save a lender the effort of manually selecting loans to invest
              from a massive universe of investment worthy loans. The minimum
              criteria to avail auto-invest feature is that you should have
              invested minimum of Rs 1, 00,000 through the platform. A lender
              specifies investment style by choosing a range for different
              parameters such as Interest Rates, Tenure, max exposure in a loan,
              Sector of Employment of borrower, Location, etc. Our marketplace
              matching making engine identifies the loan that fits your
              investment style and commits to that loan.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="6">
            Why should I opt for Auto-Invest?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              Auto-Invest helps a lender in the following manner –
              <ul>
                <li>
                  Increases investment efficiency – auto-invest feature doesn’t
                  allow your money to sit idle in your virtual account. It makes
                  your money work round the clock to maximize your returns
                </li>
                <li>
                  Saves lenders the effort of fishing for loans that suits their
                  investment style.
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="7">
            How do you ensure that my monies/ investment is safe with Fundfina
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              Primarily there are two types of risks associated with investment
              –
              <ul>
                <li>
                  Operational Risk – arises out of day to day activities in due
                  course of conducting business. In order to mitigate this risk
                  we have entrusted the operations of lender money through
                  escrow account maintained with IDFC Bank. Due to the very
                  nature of escrow account, Fundfina or any of its
                  representative(s) cannot operate the lender money. These
                  escrow accounts can only be operated by RBI or SEBI registered
                  trustee. In our case the trustee is Beacon Trusteeship
                  Limited. In case Fundfina goes under bankruptcy or shuts
                  operation, the trustee ensures that your money is not affected
                  by any legal proceedings against Fundfina.
                </li>
                <li>
                  Investment Risk – arises out of risk associated with the
                  investment product. Since the underlying asset is an unsecured
                  personal loan, Fundfina has adopted a four pillar approach to
                  risk mitigation.
                  <ul>
                    <li>
                      Pillar I – KYC – Aadhaar and PAN based client
                      identification and tax compliance verification
                    </li>
                    <li>
                      Pillar II – Credit Information Company – Fundfina checks
                      the credit information of borrowers with regulated credit
                      information companies – CIBIL, Experian, Equifax, CRIF
                      Highmark{" "}
                    </li>
                    <li>
                      Pillar III – Machine Learning and Big Data Analytics – In
                      addition to traditional sources of credit assessment, we
                      also utilize alternate sources of data such as bank
                      statements, utility bill payments, mobile bill payments,
                      online shopping, demographic data and many more data
                      sources.
                    </li>
                    <li>
                      Pillar IV – Legal recourse – Each of the loans disbursed
                      to borrowers is backed by legally enforceable loan
                      contract. In the least likely event of a default, the
                      defaulter will be pursued under relevant provisions of
                      Negotiable Instruments act and dues recovered.
                    </li>
                  </ul>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="8">
            To who do we give loans to?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              We give loans to those who show intent and capability to repay the
              loans. In order to check the intent and capability we analyse the
              financial prudence and behaviour analytics of the borrower.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="9">
            What are the minimum credit requirements to get a Fundfina loan?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <Card.Body></Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="10">
            What interest do I pay? How is it calculated?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="10">
            <Card.Body>
              The total cost of your loan through Fundfina range from 14% to 29%
              APR that includes the interest you pay to lenders, and Fundfina
              fees (2% to 3%). We look at a number of factors to determine your
              interest rate APR, including your application, repayment period,
              credit history, relationship history (with our enterprise partners
              if applicable) and estimate the likelihood that you’ll be able to
              pay back your loan. Based on assessment of lower or higher credit
              risk, you will get an offer with lower or higher APR.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="11">
            How are borrowers screened?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="11">
            <Card.Body>
              Since the underlying asset is an unsecured personal loan, Fundfina
              adopts a three pillar approach to assess borrowers.
              <ul>
                Pillar I – KYC – Aadhaar and PAN based client identification and
                tax compliance verification.
                <li>
                  Pillar II – Credit Information Company – Fundfina checks the
                  credit information of borrowers with credit information
                  companies – CIBIL, Experian, Equifax, CRIF Highmark -
                  regulated by Reserve Bank of India.
                </li>
                <li>
                  Pillar III – Machine Learning and Big Data Analytics – In
                  addition to traditional sources of credit assessment, Fundfina
                  has developed algorithms that utilizes alternate sources of
                  data such as bank statements, utility bill payments, mobile
                  bill payments, online shopping, and many more data sources.
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="12">
            Are the loans secured by any collateral?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="12">
            <Card.Body>
              No. As mandated by RBI, the loans are personal unsecured loans.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="13">
            How do I get repayment back from borrowers?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="13">
            <Card.Body>
              The monthly/ weekly repayments due to you on each loan part and
              whole loan can be viewed in the My Account section of the
              platform. The Loan Contract governs the payment of these amounts.
              The amount of the monthly or other scheduled repayments payable to
              lenders in respect of the loans will be set out in the Loan
              Contract, and a detailed payment schedule will be provided to you
              following the Loan Contract being in force (and such information
              is also available through your account on the platform). We will
              collect the repayment amounts each month or as otherwise set out
              in the Loan Contract from borrower’s bank or mobile wallet account
              using direct debit or debit card repayments or pre-authorised
              payment schedule. Note that mobile wallet company might charge a
              fee for each repayment made using a debit card.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="14">
            How do you handle payments?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="14">
            <Card.Body>
              All fund transactions involving monies of lenders and borrowers
              are routed through escrow account maintained with IDFC Bank. Due
              to the very nature of escrow account, Fundfina or any of its
              representative(s) cannot operate the lender/borrower money. These
              escrow accounts can only be operated by RBI or SEBI registered
              trustee. In our case the trustee is Beacon Trusteeship Limited. We
              strongly recommend you to sign auto-debit using NACH mandate with
              your bank as per instructions given by us as part of the money
              transfer to escrow account. This would make seamless and easier
              ongoing transfers.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="15">
            How do I withdraw funds from my Fundfina investor account?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="15">
            <Card.Body>
              A lender can withdraw funds by writing to service@Fundfina.com.
              Any amount lying idle in the virtual account of the lender can be
              withdrawn. You could also pass instruction to return money back to
              your bank account on an ongoing basis or on maturity of next loan.
              In that case the monies will be transferred back to bank account
              from which money was transferred to Fundfina escrow account.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="16">
            Can’t find what I am looking for or I want to complaint?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="16">
            <Card.Body>Please contact us</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </React.Fragment>
  );
}

export default InvestContent;
