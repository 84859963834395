import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ScrutinyListModel from "../../models/scrutiny/scrutinyListModel";
import SpinnerComponent from "../../components/common/spinner";
import ScrutinyUserTable from "./scrutinyUserTable";
import ScrutinyPartnerTable from "./scrutinyPartnerTable";

class ScrutinyList extends ScrutinyListModel {
  render() {
    let { activeTab, showSpinner } = this.state;
    return (
      <React.Fragment>
        {" "}
        <SpinnerComponent showSpinner={showSpinner} />
        <Tabs defaultActiveKey="userTab" id="uncontrolled-tab-example">
          <Tab eventKey="userTab" title="User KYC Scrutiny">
            <ScrutinyUserTable />
          </Tab>
          <Tab eventKey="partnerTab" title="Partner KYC Scrutiny">
            <ScrutinyPartnerTable />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default ScrutinyList;
