import InputDataForm from "../../components/common/form";
import * as borrowFormService from "../../services/borrow/borrowFormService";
import * as loanFormService from "../../services/borrow/loanFormService";
import * as kycFormService from "../../services/borrow/kycFormService";
import auth from "../../services/common/authService";
import * as analytics from "../../utils/analytics";

class ApplyLoanModel extends InputDataForm {
  constructor(props) {
    super(props);

    this.handler = this.handler.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.updateReviewData = this.updateReviewData.bind(this);
  }
  state = {
    activeTab: "loanTab",
    loanTab: false,
    kycTab: true,
    shopTab: true,
    questionairreTab: true,
    reviewTab: true,
    loanData: null,
    userData: null,
    loanEstimateData: null,
    reviewData: null,
    showSpinner: false,
    displayLoanTabs: true,
    isStp: false,
    discountText: "",
    bankText: "",
    offerAmount: null,
    multipleOf: 1,
    gstRequired:false,
  };
  handler(key, value) {
    this.setState({
      [key]: value,
    });
  }
  async setActiveTab(key, value) {
    let reviewData = { ...this.state.reviewData };

    await this.getUserData();

    if (reviewData.loanId)
      await this.getLoanDetails(reviewData.loanId);

    this.setState({
      [key]: value,
    });

  }
  updateReviewData(loanId, loanAmount) {
    let reviewData = { ...this.state.reviewData };
    reviewData.loanId = loanId;
    reviewData.loanAmount = loanAmount;
    this.setState({ reviewData });
  }

  handleTab1Response = (response) => {
    this.setState({ tab1Response: response, showMobileConfirmation: true })
  }

  handleMobileConfirmationResponse = () => {
    this.setState({ showMobileConfirmation: false }, () => {
      let response = this.state.tab1Response
      if (response.isStp && response.url) {
        if (response.eNach)
          window.location = response.url;
        else
          window.location.href = `/verifyLoanContract/${response.data._id}`;
      }
      else if (response.digilocker) {
        let digiLockerUrl = `/digilocker/${response.data._id}${response.isForceDigiLocker ? "/y" : ""}`
        if (response.kycExpired) {
          this.setState({ digiLockerUrl, showKycExpiredModal: true })
        } else {
          window.location.href = digiLockerUrl
        }
      }
      else {
        //set all tabs to show
        this.handler("isStp", false);
        this.handler("activeTab", "kycTab");
      }
    })
  }


  async getLoanDetails(loanId, activeTab) {
    let loanData = { ...this.state.loanData };
    let reviewData = { ...this.state.reviewData };
    let loanEstimateData = { ...this.state.loanEstimateData };

    this.setState({ showSpinner: true });

    try {
      const { data: loanDetails } = await loanFormService.getLoanDetails(
        loanId
      );

      if (loanDetails.success) {
        loanData = loanDetails.data;
        loanEstimateData = loanDetails.estimate.data;

        reviewData = {
          loanId: loanDetails.data._id,
          additionalDescription: loanDetails.data.additionalDescription,
          name: loanDetails.data.signatureDetails && loanDetails.data.signatureDetails.name ? loanDetails.data.signatureDetails.name : ""
        };
        this.setState({ loanData, reviewData, showSpinner: false, loanEstimateData, isStp: loanDetails.isStp, discountText: loanDetails.discountText, bankText: loanDetails.bankText, offerAmount: loanDetails.offerAmount, multipleOf: loanDetails.multipleOf });

        if (!loanDetails?.data?.isMobileNumberConfirmed) activeTab = "loanTab"

        await this.getUserData(loanDetails.data && loanDetails.data.merchantCode ? loanDetails.data.merchantCode : null, activeTab);
      }
    } catch (ex) {
      this.setState({ loanData, reviewData, showSpinner: false, isStp: false });
    }
  }
  async getUserData(merchantCode = null, activeTab) {
    let userData = { ...this.state.userData };

    const currentUser = auth.getCurrentUser();

    if (!currentUser) return (window.location = "/login");

    try {
      if (!currentUser.displayLoanTabs)
        this.setState({ displayLoanTabs: false });
      else
        this.setState({ displayLoanTabs: true });

      const partnerUser = (currentUser.role === "Lending Partner" || currentUser.role === "Borrowing Partner") ? true : false;

      const currentPartner = auth.getCurrentPartner();

      let partnerId = null;

      if (currentPartner && currentPartner._id)
        partnerId = currentPartner._id;

      const { data: userDetails } = await kycFormService.getUserDetails(
        currentUser.userId, partnerUser, partnerId, merchantCode
      );

      if (userDetails.success) {

        userData = userDetails.data;

        this.setState({ userData });

        if (activeTab === "kycTab")
          this.handler("activeTab", activeTab);
        else if (activeTab === "shopTab")
          this.handler("activeTab", activeTab);
        else if (activeTab === "questionairreTab")
          this.handler("activeTab", activeTab);
        if (activeTab === "reviewTab")
          this.handler("activeTab", activeTab);
      }
    } catch (ex) { }
  }
  async isValidForNewLoan() {
    try {

      const currentPartner = auth.getCurrentPartner();

      let partnerId = null;

      if (currentPartner && currentPartner._id)
        partnerId = currentPartner._id;

      const {
        data: isValidForLoan,
      } = await borrowFormService.isValidForNewLoan(partnerId);

      if (!isValidForLoan.success) return (window.location = "/borrow");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        return (window.location = "/borrow");
      }
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    analytics.track();

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    let activeTab = "";

    if (this.props.location.state && this.props.location.state.activeTab)
      activeTab = this.props.location.state.activeTab;

    const loanId = this.props.match.params.id;

    if (!loanId) {
      await this.isValidForNewLoan();
      await this.getUserData("");
    }
    else {
      const continueFrom = await loanFormService.getLastCompletedStep(
        loanId
      );

      if (continueFrom.isCaptureNewDocument) {
        this.setState({ isCaptureNewDocument: true })
      }

      if (activeTab)
        await this.getLoanDetails(loanId, activeTab);
      else if (continueFrom.success) {
        if (continueFrom.data === "bank")
          window.location.href = `/updateBankRedirect/${loanId}`;
        else if (continueFrom.data === "mandate")
          window.location.href = `/updateBankRedirect/${loanId}`
        else if (continueFrom.data === "loan contract")
          window.location.href = `/contractRedirect/${loanId}`;
        else if (continueFrom.data === "digilocker")
          window.location.href = `/digilocker/${loanId}${continueFrom.isForceDigiLocker ? "/y" : ""}`;
        else {
          console.log("continueFrom.data", continueFrom.data);
          await this.getLoanDetails(loanId, continueFrom.data);
        }
      }
      else
        await this.getLoanDetails(loanId, "loanTab");
    }

  }

  async handleCustomErrors(errors) {
    return errors;
  }
  setKey = async (key, activeTab) => {
    let reviewData = { ...this.state.reviewData };

    await this.getUserData();

    if (reviewData.loanId)
      await this.getLoanDetails(reviewData.loanId);
    
    if (activeTab === "kycTab") {
      if (key === "loanTab")
        this.handler("activeTab", key);
    }
    else if (activeTab === "shopTab") {
      if (key === "loanTab" || key === "kycTab")
        this.handler("activeTab", key);
    }
    else if (activeTab === "questionairreTab") {
      if (key === "loanTab" || key === "kycTab" || key === "shopTab")
        this.handler("activeTab", key);
    }
    else if (activeTab === "reviewTab") {
      if (key === "loanTab" || key === "kycTab" || key === "shopTab" || key === "questionairreTab")
        this.handler("activeTab", key);
    }

  }

  handleKYCModalClose = () => {
    this.setState({ showKycExpiredModal: false }, () => {
      window.location.href = this.state.digiLockerUrl
    });

  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
}

export default ApplyLoanModel;
