import React from "react";
import { Form, Col, Row, Modal, Image, Button } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import updateAlternateEmailModel from "../../models/profile/updateAlternateEmailModel";

class UpdateAlternateEmail extends updateAlternateEmailModel {
  render() {
    const { data, errors, showSpinner, info } = this.state;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 style={{ textAlign: "center" }}>Update Alternate Email</h2> <br />
        <Form onSubmit={this.doSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="email">
              <Form.Label>Alternate Email Id*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your alternate email address"
                name="email"
                value={data["email"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
              {errors["email"] && (
                <div className="alert alert-danger">{errors["email"]}</div>
              )}
              {info["email"] && (
                <div className="alert alert-success">{info["email"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Button
            className="ff-button"
            type="submit"
            disabled={data.isSubmitted}
          >
            Submit
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}

export default UpdateAlternateEmail;
