import React from "react";
import SpinnerComponent from "../../components/common/spinner";
import { Form, Col, Button, Table, FormCheck, Modal } from "react-bootstrap";
import moment from "moment";
import ReactExport from "react-export-excel";
import NumberFormat from 'react-number-format';
import TeleCallingDetailsModel from "../../models/collectionQueueTeleCallingDetails/collectionQueueTeleCallingDetails";
import DatePicker from "react-datepicker2"
import { StickyTable, Row, Cell } from 'react-sticky-table';
import EmiList from "../common/emiList";
import PaymentLinkModel from '../paymentLinkGenerate/paymentLinkGenerate'
import PaymentLink from '../paymentLinks-wrapper/paymentLinks-wrapper'

class TeleCallingDetails extends TeleCallingDetailsModel {

  renderTabs = () => {
    let { tabs, activeTab } = this.state;
    let elements = []

    for (let i = 0; i < tabs.length; i++) {
      let tab = tabs[i]
      elements.push(
        <div key={tab.value || i} onClick={() => { this.onTabChange(tab) }} className={`d-inline-block mr-3 cursor-pointer ${tab.value == activeTab ? 'active' : ""}`}>{tab.title}</div>
      )
    }

    return <div className="tele-calling-tab mb-3">{elements}</div>
  }

  getMobileNumberType = (mobileNo) => {

    let { loan } = this.state;
    let primaryMobileNo = loan && loan.userInfo && loan.userInfo.userDetails && loan.userInfo.userDetails.mobileNo && loan.userInfo.userDetails.mobileNo.length ? loan.userInfo.userDetails.mobileNo[0].mobileNumber : "";
    if (!mobileNo || !primaryMobileNo)
      return "-";

    if (mobileNo == primaryMobileNo)
      return "Primary"
    else
      return "Secondary"
  }

  renderPaymentLinkHistory = () => {
    let meta = { loanId: this.state.loan._id, loanNo: this.state.loan.loanNo, loanId: this.state.loan._id }
    return <PaymentLink meta={meta} refresh={this.refreshPaymentLink}></PaymentLink>
  }

  renderHistory = () => {

    let { loan } = this.state;

    return (<StickyTable stickyHeaderCount={0} leftStickyColumnCount={1}>
      <Row>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Mobile No</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Type</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Called On</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Outcome</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Borrower Response</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reason For Not Connected</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Action On</b></Cell>
        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", borderLeft: 0 }}><b>Amount</b></Cell>
      </Row>
      {loan.teleCallingActions && loan.teleCallingActions.length ?
        loan.teleCallingActions.map((action, i) => (
          <Row key={loan._id}>
            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.mobileNo || "-"}</Cell>
            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{this.getMobileNumberType(action.mobileNo)}</Cell>
            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{new Date(action.calledOn).toDateString()}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.outcome}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.borrowerResponse ? action.borrowerResponse : "-"}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.notConnectedReason ? action.notConnectedReason : "-"}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.date ? new Date(action.date).toDateString() : "-"}</Cell>
            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderLeft: 0, borderColor: "lightgrey", borderRight: "1px solid", borderBottom: "1px solid lightgrey" }}>{action.amount ? '₹' + action.amount : "-"}</Cell>
          </Row>
        )) : <Row>
          <Cell style={{ border: 0, borderLeft: "1px solid", borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ border: 0, borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ border: 0, borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ border: 0, borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ textAlign: 'center', borderBottom: "1px solid", borderColor: "lightgrey" }}>No record found</Cell>
          <Cell style={{ border: 0, borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ border: 0, borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
          <Cell style={{ border: 0, borderRight: "1px solid", borderBottom: "1px solid", borderColor: "lightgrey" }}></Cell>
        </Row>}

    </StickyTable>)

  }

  render() {
    const {
      showSpinner,
      callOutcomeList,
      data,
      borrowerResponseList,
      notConnectedReasonList,
      loan,
      userInfo,
      activeTab,
      reset,
      showRepaymentModal,
      showErrorModal,
      errorHeader,
      errorMessage,
      showDate,
      showAmount,
      amount
    } = this.state;
    let emi = this.props.location && this.props.location.customProps && this.props.location.customProps.emi || {}
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />

        <div >
          <div className="text-right">
            <Button onClick={() => this.handleSendEngagementAppLink(loan._id)} className="ff-button" >
              Send Loan Details
            </Button>
          </div>
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Name :</span> {userInfo && userInfo.userDetails ? (userInfo.userDetails.firstName || "") + (userInfo.userDetails.middleName || "") + ' ' + (userInfo.userDetails.lastName || "") : ""}</div>
          <div className="col"><span className="fundfinaColor">KYC Status :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.kycStatus ? userInfo.userDetails.kycStatus : "-"}</div>
          {userInfo && userInfo.userDetails && userInfo.userDetails.kycStatus == 'Review' ? <div className="col"><a href={`/scrutinyInfoRedirect/${userInfo._id}/${false}/${loan.partnerId}/${loan.merchantCode}`}>Edit KYC</a></div> : <div className="col"></div>}

        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Email :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.emailId.length ? userInfo.userDetails.emailId[0] : ""}</div>
          <div className="col"><span className="fundfinaColor">Mobile Number :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.mobileNo.length ? userInfo.userDetails.mobileNo[0].mobileNumber : ""}</div>
          <div className="col"></div>
        </div>

        <div className="row form-group tele-calling-devider">
          <div className="col"><span className="fundfinaColor">Alternate Email :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.emailId.length > 1 ? userInfo.userDetails.emailId[1] : "-"}</div>
          <div className="col"><span className="fundfinaColor">Alternate Mobile Number :</span> {userInfo && userInfo.userDetails && userInfo.userDetails.mobileNo.length > 1 ? userInfo.userDetails.mobileNo[1].mobileNumber : "-"}</div>
          <div className="col"></div>
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Loan Amount :</span> ₹{loan.amount}</div>
          <div className="col"><span className="fundfinaColor">Installment Amount/EDI :</span> ₹{loan.emiAmount}</div>
          <div className="col"><span className="fundfinaColor">Processing Fees :</span> {loan.processingFee ? '₹' + loan.processingFee : "-"}</div>
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Total Interest Amount :</span> ₹{(loan.amount * loan.interestRate) / 100}</div>
          <div className="col"><span className="fundfinaColor"><a href="/calculator">EMI Calculator</a></span></div>
          <div className="col"><span className="fundfinaColor">ROI :</span> {loan.interestRate}%</div>
        </div>

        <div className="row form-group">
          <div className="col"><span className="fundfinaColor">Status :</span> {loan.status}</div>
          <div className="col"><span className="fundfinaColor">Last Completed Step :</span> {loan.lastStepCompleted}</div>
          <div className="col"><span className="fundfinaColor">Last Updated On :</span> {loan.updatedOn ? new Date(loan.updatedOn).toDateString() : ""}</div>
        </div>

        <div className="row form-group tele-calling-devider">
          <div className="col"><span className="fundfinaColor">Reason for eNACH Failure :</span> {loan.eNachFailureReason ? loan.eNachFailureReason : "-"}</div>
          <div className="col"><span className="fundfinaColor">eNACH Required :</span> {loan.eNachRequired ? 'Yes' : 'No'}</div>
          <div className="col"><span className="fundfinaColor">eNACH Status :</span> {loan.eNachStatus ? loan.eNachStatus : "-"}</div>
        </div>

        <div className="row form-group tele-calling-devider  align-center">

          {/* <div className="col-2">
            {(emi.primaryCollectionType === "eNach" || emi.secondaryCollectionType === "eNach") && emi.isMandateRegistered && (
              <Form.Control
                as="select"
                value={data.emiCount}
                onChange={this.handleEmiNumberChange}
                name={"emis_" + emi.loanId}
                style={{ maxWidth: 150, marginRight: 20 }}
              >
                {" "}
                <option key="emis-select" value="">
                  ---Select---
                </option>
                {emi.emiList.map((item, k) => (
                  <option key={k} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>)}
          </div> */}

          <div className="col-4">
            {(emi.primaryCollectionType === "eNach" || emi.secondaryCollectionType === "eNach") && emi.isMandateRegistered && (
              <React.Fragment>
                <div className="col-md-12">
                  <div className="row">
                    <Form.Control
                      as="select"
                      value={data.eNachStatus}
                      onChange={this.handleEmiStatusChange}
                      name={"eNachSelect_" + emi.loanId}
                      style={{ maxWidth: 150, marginRight: 20 }}
                      className='form-control-sm'
                    >
                      {" "}
                      <option key="loanView-select" value="">
                        ---Select---
                      </option>
                      {/* <option key="loanView-resend" value="Send" disabled={data.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                        Resend
                      </option> */}
                      <option key="loanView-mandate" value="Mandate">
                        New Mandate
                      </option>
                      <option key="loanView-bankDetails" value="BankDetails">
                        Bank Details
                      </option>
                      {/* <option key="loanView-hold" value="Hold" disabled={emi.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                                                                    Hold
                                                                </option> */}
                    </Form.Control>
                    <Button
                      className="ff-button btn-sm"
                      type="button"
                      name={"btnNachSelect_" + emi.loanId}
                      onClick={this.eNachAction}
                      style={{ minWidth: 50 }}
                      disabled={data.nachBtnDisabled}
                    >
                      Go
                    </Button></div></div></React.Fragment>)}
          </div>

          <div className="col-4">
            <div className="d-inline-block mr-2">
              <FormCheck
                id={"holdCollection_" + emi.loanId}
                type="switch"
                checked={data.holdWalletCollection}
                onChange={this.handleHoldCollection}
                label="Hold Wallet Collection"
                name={"holdCollection_" + emi.loanId}
                disabled={emi.primaryCollectionType === "eNach" ? true : false}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="d-inline-block">
              <button className="btn btn-success btn-sm" onClick={this.onClickSendPaymentLink}>Send Payment Link</button>
            </div>
          </div>

          <div className="col-4">
            <Button
              className="ff-button btn-sm"
              type="button"
              onClick={this.getRepaymentDetails}
              style={{ minWidth: 50 }}
            >
              Repayment Schedules
            </Button>
            {/* <a className="cursor-pointer" onClick={this.getRepaymentDetails}></a> */}
          </div>


        </div>

        {this.renderTabs()}

        <>
          {activeTab == 'ACTION' && !reset ?
            <Form onSubmit={this.handleSubmit}>

              <Form.Row>
                <Form.Group as={Col} md="3" controlId="mobileNo">
                  <Form.Label>Mobile No <span>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="mobileNo"
                    value={data["mobileNo"]}
                    onChange={this.handleMobileNumberChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {loan && loan.userInfo && loan.userInfo.userDetails && loan.userInfo.userDetails.mobileNo && loan.userInfo.userDetails.mobileNo.length ?
                      loan.userInfo.userDetails.mobileNo.map((number) => (
                        <option key={number.mobileNumber} value={number.mobileNumber}>
                          {number.mobileNumber}
                        </option>
                      )) : <option>
                        No data found
                      </option>}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              {data && data["mobileNo"] ? <Form.Row>
                <Form.Group as={Col} md="6" controlId="callOutcome">
                  <Form.Label>Call Outcome <span>*</span></Form.Label>

                  <div className="row">
                    <div className="col">
                      <div className="cursor-pointer" onClick={(event) => this.handleChangeCheckbox("Connected Successfully", !(data['callOutcome'] == "Connected Successfully"))}>
                        <input
                          type="radio"
                          id="calloutcome"
                          name="calloutcome"
                          value="Connected Successfully"
                          checked={data['callOutcome'] == "Connected Successfully"}
                          onChange={(event) => this.handleChangeCheckbox("Connected Successfully", !(data['callOutcome'] == "Connected Successfully"))}
                        />
                        <span> Connected Successfully</span>
                      </div>
                    </div>
                    <div className="col">
                      <div className="cursor-pointer" onClick={(event) => this.handleChangeCheckbox("Not Connected", !(data['callOutcome'] == "Not Connected"))}>
                        <input
                          type="radio"
                          id="calloutcome"
                          name="calloutcome"
                          value="Connected Successfully"
                          checked={data['callOutcome'] == "Not Connected"}
                          onChange={(event) => this.handleChangeCheckbox("Not Connected", !(data['callOutcome'] == "Not Connected"))}
                        />
                        <span> Not connected</span>
                      </div>
                    </div>
                  </div>

                  {/* <Form.Control
                    as="select"
                    name="callOutcome"
                    value={data["callOutcome"]}
                    onChange={this.handleCalloutcomeChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {callOutcomeList.map((item, i) => (
                      <option key={i} value={item.defination}>
                        {item.defination}
                      </option>
                    ))}
                  </Form.Control> */}
                </Form.Group>
              </Form.Row> : ""}

              {data.callOutcome && data.callOutcome == 'Connected Successfully' ? <Form.Row>
                <Form.Group as={Col} md="4" controlId="borrowerResponse">
                  <Form.Label>Borrower Response <span>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="borrowerResponse"
                    value={data["borrowerResponse"]}
                    onChange={this.handleCalloutcomeChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {borrowerResponseList.map((item, i) => (
                      <option key={i} value={item.defination}>
                        {item.defination}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {showDate ? <Form.Group as={Col} md="4" controlId="fromDate">
                  <Form.Label>Action On <span>*</span></Form.Label>
                  <DatePicker
                    name="date"
                    id="date"
                    value={data["date"]}
                    min={moment().subtract(1, 'days')}
                    inputFormat="DD/MM/YYYY"
                    isGregorian={true}
                    className="form-control"
                    inputReadOnly={true}
                    showTodayButton={false}
                    timePicker={false}
                    onChange={(value) => this.handleDateChange(value)}
                    placeholder="Enter Date"
                    popperProps={{
                      positionFixed: true // use this to make the popper position: fixed
                    }}
                  />
                </Form.Group> : <></>}

                {showAmount ? <Form.Group as={Col} md="4" controlId="amount">
                  <Form.Label>Amount <span>*</span></Form.Label>
                  <Form.Control
                    type="number"
                    name="name"
                    value={data["amount"]}
                    onChange={(e) => { this.hanldeAmountChange(e) }}
                    autoComplete="off"
                  />
                </Form.Group> : <></>}

              </Form.Row> : <></>}

              {data.callOutcome && data.callOutcome == 'Not Connected' ? <Form.Row>
                <Form.Group as={Col} md="4" controlId="borrowerResponse">
                  <Form.Label>Reason For Not Connected <span>*</span></Form.Label>
                  <Form.Control
                    as="select"
                    name="notConnectedReason"
                    value={data["notConnectedReason"]}
                    onChange={this.handleNotConnectedReasonChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {notConnectedReasonList.map((item, i) => (
                      <option key={i} value={item.defination}>
                        {item.defination}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row> : <></>}

              <Form.Row>
                <Form.Label>Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="note"
                  value={data["note"]}
                  onChange={this.handleOnChangeNote}
                />
              </Form.Row>

              <Form.Row style={{ marginTop: 20, float: 'right' }}>
                <Form.Group as={Col} md="12">
                  <Button
                    className="ff-button"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form.Row>

            </Form> : activeTab == "PAYMENT_LINK_HISTORY" ? this.renderPaymentLinkHistory() : this.renderHistory()}
        </>

        <Modal
          show={showRepaymentModal}
          onHide={this.closeModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="example-modal-sizes-title-xl"
          centered
          style={{ width: "auto !important" }}
        >

          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflow: "scroll" }}>
            <EmiList loanId={this.props.match && this.props.match.params && this.props.match.params.id} showPaymentOptions={true}></EmiList>
          </Modal.Body>
        </Modal>

        <Modal
          show={showErrorModal}
          onHide={this.closeErrorModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>{errorHeader}</b>
          </Modal.Header>
          <Modal.Body>
            {errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeErrorModal} className="ff-button">
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.showPaymentLinkGenerate && <PaymentLinkModel loan={this.state.selectedLoan} close={this.closePaymentLinkGenerate}></PaymentLinkModel>}
      </React.Fragment>
    );
  }
}

export default TeleCallingDetails;
