import React, { Component } from "react";
import auth from "../../services/common/authService";
import SharedCache from "../../SharedCache";
class InvalidData extends Component {
  state = {
    message: "",
    redirect: false,
  };
  async componentDidMount() {
    if (this.props.location.state.message) {
      let message = this.props.location.state.message;

      if (!auth.getCurrentUser()) {
        SharedCache.init({});
        SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
        if (SharedCache.app)
          SharedCache.app.refresh();
      }

      this.setState({ message: message });
    }
  }
  render() {
    let { message } = this.state;
    return (
      <div style={{ fontSize: 20, color: "red", fontWeight: "bold" }}>
        {message}
      </div>
    );
  }
}

export default InvalidData;
