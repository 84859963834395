import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import UserListTableModel from "../../models/userList/userListTableModel";
import { Form, Col, Button, Table } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import DatePicker from "react-datepicker2";

class UserListTable extends UserListTableModel {
  getPagedData = () => {
    const { pageSize, currentPage, data } = this.state;

    let users = data.users;

    const filteredUsers = paginate(users, currentPage, pageSize);

    return { totalCount: users.length, data: filteredUsers };
  };
  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      loanStatusList,
      partners
    } = this.state;

    const { totalCount, data: users } = this.getPagedData();

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>User List</h2>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <DatePicker
                name="fromDate"
                id="fromDate"
                value={data["fromDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleFromDateChange(value)}
                placeholder="Enter From Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <DatePicker
                name="toDate"
                id="toDate"
                value={data["toDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleToDateChange(value)}
                placeholder="Enter To Date"
              /></Form.Group>
            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={data["name"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No</Form.Label>
              <Form.Control
                type="text"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
            <Form.Group as={Col} md="4" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={data["email"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
            <Form.Group as={Col} md="4" controlId="merchantCode">
              <Form.Label>Merchant Code</Form.Label>
              <Form.Control
                type="text"
                name="merchantCode"
                value={data["merchantCode"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="partner">
              <Form.Label>Partner</Form.Label>
              <Form.Control
                as="select"
                name="partner"
                value={data["partner"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="all" value="all">
                  ALL
                </option>
                {partners.map((partner) => (
                  <option key={partner.partnerName} value={partner.partnerName}>
                    {partner.displayName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>{" "}
            <Form.Group as={Col} md="4">
              <Button
                className="ff-button"
                type="submit"
                style={{ marginTop: 30, marginLeft: 10 }}
              >
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Creation Date</th>
              <th style={{ minWidth: 200 }}>Name</th>
              <th>Merchant Code</th>
              <th>Partner Code</th>
              <th>User Role</th>
              <th>Username</th>
              <th>KYC Status</th>
              <th>Loan Link</th>
              <th>Email</th>
              <th>Mobile</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr key={user._id}>
                  <td>{moment(user.insertedOn).format("DD/MM/yyyy")}</td>
                  <td>{user.name}</td>
                  <td>{user.actualMerchantCode}</td>
                  <td>{user.partnerCode}</td>
                  <td>{user.role}</td>
                  <td>{user.userName}</td>
                  {/* <td>
                    {user.kycStatus && <a href={`/scrutinyInfo/${user._id}`} style={{ textDecoration: "underline", color: user.kycStatus && user.kycStatus === "Approved" ? "green !important" : user.kycStatus && user.kycStatus === "Rejected" ? "red" : user.kycStatus && user.kycStatus === ("Under Review" || "Review") ? "orange" : "black", fontWeight: "bold" }}>{user.kycStatus}</a>}</td> */}
                  <td>
                    <React.Fragment>
                      {user.kycStatus && (user.kycStatus === "Approved" || user.kycStatus === "Under Review" || user.kycStatus === "Review" || user.kycStatus === "Rejected") && (
                        <Link className={user.kycStatus && user.kycStatus === "Approved" ? "green" : user.kycStatus && user.kycStatus === "Rejected" ? "red" : user.kycStatus && (user.kycStatus === "Under Review" || user.kycStatus === "Review") ? "orange" : "black"} to={`/scrutinyInfoRedirect/${user._id}/${user.isPartnerUser ? user.isPartnerUser : false}/${user.partnerId}/${user.actualMerchantCode}`}>{user.kycStatus}</Link>)}
                      {user.kycStatus && (user.kycStatus === "Initiated" || user.kycStatus === "ReInitiated") && (
                        <span>{user.kycStatus}</span>
                      )}</React.Fragment></td>
                  <td>{user.actualMerchantCode && user.role === "User" && <Link to={{
                    pathname: `/loanModule`,
                    state: {
                      merchantCode: user.actualMerchantCode
                    }
                  }}>Link</Link>}</td>
                  <td>{user.emailId[0]}</td>
                  <td>{user.mobileNo[0].mobileNumber}</td>
                </tr>
              ))}
            {!users && (
              <tr key="no">
                <td colSpan="10">No record found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPagePrevious={this.handlePagePrevious}
            onPageNext={this.handlePageNext}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default UserListTable;
