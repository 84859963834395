import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

class Pagination extends Component {

  constructor(props){
    super(props)
  }

  MAX_PAGES_TO_DISPLAY_PER_VIEW = 10
  
  state = {
    startIndex : 1
  }

  onPagePrevious = ()=>{
    this.props.onPagePrevious && this.props.onPagePrevious()

    if (this.props.currentPage == 1)
      return;

    if (this.props.currentPage == this.state.startIndex) {
      this.state.startIndex = this.state.startIndex - this.MAX_PAGES_TO_DISPLAY_PER_VIEW;
}
  }

  onPageNext =()=>{
    this.props.onPageNext && this.props.onPageNext()

    if (this.props.currentPage == this.props.pageSize)
     return;

    if (this.props.currentPage >= Math.ceil(this.state.startIndex / this.MAX_PAGES_TO_DISPLAY_PER_VIEW) * this.MAX_PAGES_TO_DISPLAY_PER_VIEW) {
      this.state.startIndex = this.state.startIndex + this.MAX_PAGES_TO_DISPLAY_PER_VIEW;
    }
  }

  render(){

    let pagesCount = this.props.pageSize;
    let { currentPage } = this.props;
    let startIndex = this.state.startIndex;

    let pagesToDisplay = pagesCount < (startIndex + this.MAX_PAGES_TO_DISPLAY_PER_VIEW - 1) ? startIndex + (pagesCount % this.MAX_PAGES_TO_DISPLAY_PER_VIEW) : startIndex + this.MAX_PAGES_TO_DISPLAY_PER_VIEW;
    let pages = _.range(startIndex, pagesToDisplay);

    if(!pagesCount || pagesCount == 1)
      return <></>

    return (
    <div>
    <div className="d-inline-block">
    <nav>
      <ul className="pagination">
        {" "}
        {pagesCount > 1 && (
          <li key="previous" className={currentPage === 1 ? "disabled" : "clickable"}>
            <a className="page-link" onClick={() => this.onPagePrevious()}>
              Previous
            </a>
          </li>
        )}
        {pages.map((page) => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item clickable"}
          >
            <a className="page-link" onClick={() => this.props.onPageChange && this.props.onPageChange(page)}>
              {page}
            </a>
          </li>
        ))}{" "}
        {pagesCount > 1 && (
          <li
            key="next"
            className={currentPage === pagesCount ? "disabled" : "clickable"}
          >
            <a className="page-link" onClick={() => this.onPageNext()}>
              Next
            </a>
          </li>
        )}
      </ul>
    </nav>
    </div>
    <div className="d-inline-block pl-2">
      <small className="mt-3 label fundfinaColor">{pagesCount && pagesCount > 10 ? (pagesCount ? ' Of ' + pagesCount + ' Pages' : "") :""}</small>
    </div>
    </div>
    )
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPagePrevious: PropTypes.func.isRequired,
  onPageNext: PropTypes.func.isRequired,
};

export default Pagination;
