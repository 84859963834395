import InputDataForm from "../../components/common/form";
import * as payInPayOutService from "../../services/payInPayOut/payInPayOutService";
import auth from "../../services/common/authService";
import Joi from "joi-browser";

class PayInPayOutTableModel extends InputDataForm {
  state = {
    data: {
      mobileNo: "",
      name: "",
      email: "",
      users: [],
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 10,
  };
  schema = {
    partnerType: Joi.any(),
    mobileNo: Joi.any(),
    email: Joi.any(),
    name: Joi.any(),
    users: Joi.any(),
  };
  async getUserList() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const { data: userList } = await payInPayOutService.getUserList(data);

      data.users = userList;

      this.setState({ data, showSpinner: false, currentPage: 1 });
    } catch (ex) {
      this.setState({ data, showSpinner: false, currentPage: 1 });
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role !== "Admin") return (window.location = "/");

    await this.getUserList();
  }

  async handleCustomErrors(errors) {
    return errors;
  }
  async doSubmit() {
    await this.getUserList();
  }
}

export default PayInPayOutTableModel;
