import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as analytics from "../../utils/analytics";
import * as reviewFormService from "../../services/borrow/reviewFormService";

class MandateInfoModel extends InputDataForm {
    state = {
        loanId: "",
        showSpinner: false,
        isSubmitted: false,
        redirect: false,
        errors: {
        },
        eNach: false,
        eNachType: "",
        accountNo: "",
        bankName: "",
        redirectUrl: ""
    };
    async componentDidMount() {
        analytics.track();

        if (!auth.getCurrentUser()) return (window.location = "/login");

        if (this.props.match.params.id) {
            this.setState({ loanId: this.props.match.params.id });

            await this.getBankInformation(this.props.match.params.id);
        }
        else
            return (window.location = "/");
    }
    async getBankInformation(loanId) {
        let { data, errors } = this.state;
        this.setState({ showSpinner: true });
        try {

            const {
                data: response,
            } = await reviewFormService.getBankInformation(
                loanId
            );

            if (response.success)
                this.setState({ accountNo: response.data.accountNo, bankName: response.data.bankName, eNach: response.eNach, redirectUrl: response.url, eNachType: response.mode, showSpinner: false })
            else {
                errors.globalError = response.message;
                this.setState({ data, errors, showSpinner: false,eNach: response.eNach, redirectUrl: response.url, });
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                errors.globalError = ex.response.data;
                this.setState({ data, errors, showSpinner: false });
            }
        }
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        let { eNach, errors, redirectUrl, loanId } = this.state;

        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            if (eNach)
                window.location = redirectUrl;
            else
                window.location.href = `/contractRedirect/${loanId}`;
        } catch (ex) {
            errors.globalError = ex.response.data.message;
            this.setState({ errors, showSpinner: false, isSubmitted: false });
        }
    }
    redirectBack() {
        this.setState({ redirect: true })
    }
}

export default MandateInfoModel;
