import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getLoanList(model) {
  const { data } = await http.post(`${apiEndpoint}/getEarlyWarningSystem`, {
    loanNo: model.loanNo,
    mobile: model.mobileNo,
    name: model.name,
  });
  return data;
}
