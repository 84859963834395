import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/";

export async function insertUpdateQuestionairre({
  userId,
  whatsApp,
  gender,
  maritalStatus,
  children,
  degree,
  monthlyIncome,
  monthlyOutgoing,
  jobType,
  homeOwnershipType,
  vehicle,
  purchasedInOneYear,
  recommendedBy,
  loanId
}) {
  const response = await http.put(
    apiEndpoint + `users/insertUpdateQuestionnairre/${userId}`,
    {
      whatsApp,
      gender,
      maritalStatus,
      children,
      degree,
      monthlyIncome,
      monthlyOutgoing,
      jobType,
      homeOwnershipType,
      vehicle,
      purchasedInOneYear,
      recommended: recommendedBy ? "Yes" : "No",
      recommendedBy,
      loanId
    }
  );
  return response;
}

export async function getLoanDetails(loanId) {
  const response = await http.get(apiEndpoint + `loan/${loanId}`);
  return response;
}
