import React, { useContext } from "react";
import UserContext from "./../../context/userContext";

function Footer(props) {
  const { user, hideFFLogo } = useContext(UserContext);

  return (
    <footer className="page-footer font-small stylish-color-dark pt-4">
      <hr />
      <div className="container text-center text-md-left" style={{ marginTop: 0 }}>
        <div className="row">
          {(!user ||
            user && !hideFFLogo &&
            ((user.loggedInFrom !== "Mail" &&
              user.loggedInFrom !== "RedirectAuthLink") || (user.loggedInFrom === "RedirectAuthLink" && user.showFooterMenu))) && (
              <div className="col-md-4 mx-auto">
                <a href="https://fundfina.com" className="navbar-brand">
                  <img
                    src={require("../../images/logo.png")}
                    className="d-inline-block align-top"
                    alt="Fundfina Logo"
                  />
                </a> <br />
                <img
                  src={require("../../images/fintech50_1.png")}
                  className="d-inline-block align-top"
                  alt="Fintech50 Logo"
                />
                <p></p>
              </div>
            )}
          {user && !hideFFLogo &&
            ((user.loggedInFrom === "Mail") ||
              user.loggedInFrom === "") && (
              <div className="col-md-4 mx-auto">
                <img
                  src={require("../../images/logo.png")}
                  className="d-inline-block align-top"
                  alt="Fundfina Logo"
                /> <br />
                <img
                  src={require("../../images/fintech50_1.png")}
                  // className="d-inline-block align-top"
                  alt="Fintech50 Logo"
                />
              </div>
            )}

          <React.Fragment>
            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-8 mx-auto">
              {!hideFFLogo && (
                <React.Fragment>
                  <br />
                  <b>Fundfina Technologies Pvt Ltd (trading as “Fundfina”). All rights reserved.</b> <br />
                  <i>a company registered in India with CIN U65100JH2016PTC008813</i>
                </React.Fragment>
              )}
            </div>

          </React.Fragment>
        </div>
      </div>
      {/* {(!user ||
        user &&
        ((user.loggedInFrom !== "Mail" &&
          user.loggedInFrom !== "RedirectAuthLink") || (user.loggedInFrom === "RedirectAuthLink" && user.showFooterMenu))) && (
          <ul className="list-unstyled list-inline text-center">
            <li className="list-inline-item">
              <a className="btn-floating btn-fb mx-2" href="#">
                <i className="fa fa-facebook-official fb" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn-floating btn-tw mx-2"
                href="https://twitter.com/Fundfina?lang=en"
                target="_blank"
              >
                <i className="fa fa-twitter twitter" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a
                className="btn-floating btn-li mx-2"
                href="https://www.linkedin.com/company/fundfina/?viewAsMember=true"
                target="_blank"
              >
                <i className="fa fa-linkedin linkedIn" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        )} */}
      {/* {!hideFFLogo && (
        <div className="footer-copyright text-center py-3">
          <b>Fundfina Technologies Pvt Ltd (trading as “Fundfina”). All rights reserved.</b> <br />
          <i>a company registered in India with CIN U65100JH2016PTC008813</i>
        </div>)} */}
    </footer>
  );
}

export default Footer;
