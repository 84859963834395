import React from "react";
import * as analytics from "../../utils/analytics";


function Tnc(props) {
  analytics.track();

  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center" }}>Terms and Conditions</h2>

      <p>The following terms and conditions explain:</p>

      <ul>
        <li>how Fundfina works</li>
        <li>how to become a member of Fundfina as a borrower and/or lender</li>
        <li>how you may use the platform provided and maintained by Fundfina.</li>
      </ul>

      <p>
        If you decide to become a member of Fundfina, you must comply with these terms and conditions. These terms and conditions should be read alongside, and are in addition to, our privacy policy (accessible online at <a href="http://www.fundfina.com/privacyPolicy">http://www.fundfina.com/privacy</a>). These terms and conditions apply howsoever you decide to access the platform (including through Fundfina's website or through any mobile device application or through Application Programming Interface/ API).
      </p>
      <p>By using the systems and the services provided via the platform, you confirm that:</p>
      <ul>
        <li>you have had a proper opportunity to read these terms and conditions and agree to be bound by them;
        * you have had a proper opportunity to read the privacy policy and agree to be bound by it; and</li>
        <li>where you're acting on behalf of an LLP, company incorporated under Companies Act of India or public body, they have had a proper opportunity to read these terms and conditions and the privacy policy, and agree to be bound by each of them.</li>
      </ul>

      <p style={{ fontSize: 20, fontWeight: "bold" }}>If you do not agree to these terms and conditions or the privacy policy, you must stop using this platform immediately.</p>

      <p>References in these terms and conditions to "Fundfina", "we", "us" and "our" means Fundfina Technologies Private Limited. References to "systems", or "platform" means the platform, the Fundfina API, any other online services provided by us and any data managed by, displayed on or transmitted from such services. References to "you" and "your" mean a user of the platform and the services provided via our systems, whether as an individual or an entity which meets the lender criteria set out below.</p>
      <p>Each agreement between each lender and each borrower is made up of our Loan Conditions and Key Contract Terms (as the same may be amended from time to time in accordance with these terms and conditions) (together the "Loan Contract"). The Loan Contract is a separate agreement and is governed by separate terms and conditions. If there is a conflict between these terms and conditions and the Loan Contract, the Loan Contract will prevail.</p>
      <p>If you are using our systems and the services provided via the platform, agreeing to these terms and conditions or entering into a Loan Contract on behalf of an LLP, company incorporated under Companies Act of India, partnership, public body or other separate entity you warrant that you are duly authorised to act on its behalf.</p>
      <p>Fundfina allows registered introducers to invest monies on a lender's behalf. If a registered introducer makes an investment on your behalf, Fundfina will be entitled to treat the investment as having been made with your full authority. The terms of our privacy policy still apply to lenders who choose to make investments through introducers. If you use an introducer to invest on your behalf, you will not become a member of Fundfina and your application will instead be made and managed through the introducer's account.</p>

      <ol>
        <li style={{ fontSize: 20, fontWeight: "bold" }}>How to become a borrower</li>
        <ol><span>1.1 To be a borrower on Fundfina you must meet the following criteria:</span>
          <ol>
            <li>you must be a resident of India;</li>
            <li>you must be an individual, sole trader, LLP, company incorporated under Companies Act of India, public body or other legal entity;</li>
            <li>you must have and maintain a valid bank account and/or e-money wallet in a Bank and/or non-bank financial entity regulated by Reserve Bank of India to facilitate repayments to lenders;</li>
            <li>you must meet minimum credit and fraud risk criteria which will include obtaining a minimum score from credit reference agencies such as CIBIL, Equifax or Experian or Fundfina's proprietary risk models;</li>
            <li>you must not have any outstanding court judgments of more than Rs 25000.00 (Fundfina reserves the right to list such individuals as borrowers, from time to time and on a case by case basis if it considers it reasonable to do so);</li>
            <li>you must register and submit details of your KYC on our platform (<a href="http://www.fundfina.com">http://www.fundfina.com/</a>). Please note that some (but not all) of those details will be disclosed by us to lenders in accordance with clauses below.<br /><br /></li>
          </ol>
          <p>1.2 Borrowers must be gainfully employed individuals and may not be businesses, public bodies, charities or trusts.</p>
          <p>1.3 As part of the registration process we will need to authenticate the identity and address of the individual. We will do this using Aadhaar based eKYC of UIDAI, credit information companies such as CIBIL, Equifax or Experian and/or PAN verification. We may also require you to send us copies of the relevant passports or other identification documents and anything else that we may need. If you do not provide all appropriate identification documents, you will not be able to borrow on the Fundfina platform.</p>
          <p>1.4 Once you are registered on the platform and have applied to borrow through Fundfina, we will carry out credit and fraud checks on you using credit information companies such as CIBIL, Equifax or Experian. Fundfina uses its own internal guidelines and policies when assessing applications but has complete discretion as to whether we allow you to be a borrower on the Fundfina platform. As part of setting your borrower account, Fundfina will facilitate opening of virtual account with our designated and authorised bank. </p>
          <p>1.5 You warrant and represent to Fundfina that (i) all information provided to us in the course of the application and borrowing process is true and accurate in all respects, and that you do not anticipate or reasonably expect any material change to such information in the 6 months following your acceptance of the loan; and (ii) you will not omit to tell us any information you are aware of that would materially impact on the decision of an investor to lend to you via the Fundfina platform. You agree that you will update us if any of the information you provide to us changes or is likely to materially change. If new or additional information comes to our attention which we believe would materially impact on the decision of a lender to lend to you or which would have resulted in a different outcome under our internal guidelines and policies when assessing your application then we reserve the right to de-list your loan or to otherwise cease to process your loan application at all or any further.</p>
          <p>1.6 You warrant and represent that you have disclosed to us any circumstances that you are aware of (including without limitation any claims, undisclosed liabilities, litigation, arbitration, court proceedings or investigations which are current, threatened, pending or otherwise reasonably likely to occur against you by any third party) which could or might result in a material adverse change in your financial condition, business or assets.</p>
          <p>1.7 You agree with us that you will promptly provide full details to Fundfina upon you becoming aware of or suspecting that any of the circumstances in clauses 1.5 or 1.6 have occurred or may occur, and will also provide Fundfina with such information as it may reasonably require about your financial condition, business and affairs from time to time.</p>
          <p>1.8 You agree that, for as long as there are outstanding amounts owed by you to lenders on the Fundfina platform, you will provide to Fundfina promptly on request by Fundfina (or its agent) bank statements, latest income tax returns, up-to-date balance sheet and cash-flow statements (in case of sole-trader), as well as other financial information as Fundfina may require from time to time;</p>
          <p>1.9 You agree that, for as long as there are outstanding amounts owed by you to lenders on the Fundfina platform, you agree to meet with any employee or agent within 14 days of request by Fundfina, and answer all reasonable queries </p>
          <p>1.10 You agree that, for as long as there are outstanding amounts owed by you to lenders on the Fundfina platform, you agree to allow the money to be debited from your bank account and/or mobile wallet once you have committed to funding the loan. Any additional pre-authorisation required from the bank or mobile wallet provider will be facilitated by Fundfina.</p>
          <p>1.11 If you are, or at any time become, aware of any current or future claim (or potential claim) by Income tax authorities (or any other governmental department or agency or other public body) against you, you may not accept a loan unless you have provided Fundfina with full details of such claim (or potential claim) and we have agreed in writing that you are still eligible to accept loans.</p>
        </ol>
        <li style={{ fontSize: 20, fontWeight: "bold" }}>How to become a lender</li>
        <span>2.1 To be a lender on Fundfina you must meet the following criteria:</span>
        <ol><li>you must be an individual, LLP, company incorporated under Companies Act of India, public body or other legal entity;</li>
          <li>if you are lending in your capacity as an individual you must be 18 years old or above;</li>
          <li>if you are lending in your capacity as an individual you must be a resident of India;</li>
          <li>if you are a company incorporated under Companies Act of India, you must be registered with Registrar of Companies and be incorporated within India;</li>
          <li>if you are an LLP, you must have a permanent place of business in India and be registered with Registrar of Companies; # you have not invested or lent more than aggregated Rs 10,00,000/- across across all P2Ps at any point of time;</li>
          <li>if you are a public body, you must be registered or formed with the relevant authority in India;</li>
          <li>you must have and maintain a valid bank account and/or e-money wallet in a Bank and/or non-bank financial entity regulated by Reserve Bank of India; and</li>
          <li>you must register your details and submit details of your KYC on our platform at <a href="http://www.fundfina.com/">http://www.fundfina.com/</a>.<br /><br /></li>
        </ol>
        <p>Where a prospective lender does not meet the criteria listed above, the lender may be otherwise permitted to become an investor on Fundfina by written confirmation from Fundfina.</p>
        <p>2.2 Fundfina gives each loan an indicative interest rate range at the time a loan request is processed, which is based on information included in the borrower's application and the results of an online check made against records held by credit information companies. Save in certain specified circumstances, Fundfina does not edit the information submitted or uploaded by a borrower.</p>
        <p>2.3 While Fundfina adds value to the credit analysis as part of our loan application review and assessment processes, the provision of an indicative interest rate is intended to be informative only and a lender must form its own opinion regarding the creditworthiness of a borrower and undertake its own research, analysis and assessment of each borrower for each loan and, where appropriate, seek its own independent financial advice.</p>
        <p>2.4 Save in certain specified circumstances, Fundfina does not edit the information submitted or uploaded by borrowers which it then makes available to prospective lenders via the Fundfina platform and, therefore, we cannot guarantee its accuracy. Accordingly, we accept no responsibility or liability for the accuracy of the information provided by borrowers to prospective lenders or the risk band classifications. Fundfina may from time to time, but accepts no obligation to, update or amend borrower's information or the risk band classification (including between when the loan request is first made and when it is entered into, and during the term of any loan).</p>
        <p>2.5 While Fundfina believes that access to the assets of a borrower (via the secured loan, secured property finance or asset finance product) can provide additional comfort as to the likelihood of repayment, Fundfina accepts no additional responsibility for the likelihood of a borrower meeting its financial obligations to investors through the Fundfina platform in circumstances where such recourse to the assets of the borrower is available.</p>
        <p>2.6 As a lender, you cannot also be a borrower on Fundfina with loans outstanding, except where you are acting as the director or the proprietor of a business borrowing money and want to act as a lender in a different capacity.</p>
        <p>2.7 You warrant to Fundfina that all information provided to us in the course of the registration and lending process is true and accurate in all respects. You agree that you will promptly update us if any of the information you provide to us changes.</p>
        <p>2.8 Once you are registered on the platform and have applied to lend through Fundfina, we will carry out identity and fraud checks on you and you as a proprietor, member or director, your business and other members, or directors of the business using credit information companies such as CIBIL, Equifax or Experian. Fundfina uses its own internal guidelines and policies when assessing applications but has complete discretion as to whether to allow you to be a lender on the Fundfina platform. If we cannot get adequate information from the credit information companies then we will ask you to send us copies of the relevant passports or other identification documents plus a utility bill and anything else that we may require. If you do not provide all appropriate identification documents you will not be able to lend on the Fundfina platform. We may suspend or place limitations on trading on your account at any time if we believe it is appropriate in order to comply with our legal obligations. For more details about how we use your information, please refer to our privacy policy at http://www.fundfina.com/privacy. We will then set up a Fundfina lender account for you (your "Lender Account"). As part of setting your lender account, Fundfina will be facilitate opening digital money wallet or a virtual digital money account with our designated and authorised bank and/or mobile wallet company. </p>
        <p>2.9 You can then transfer money into your unique Lender Account on the platform by designated mobile wallet or debit card or bank transfer. Cash deposits up to specified limits set by Reserve Bank of India or other competent statutory authority(ies) may be accepted through a designated partner. Fundfina has complete discretion to specify the method by which you may transfer money into and out of your Lender Account depending on the outcome of the checks set out at clause </p>
        <p>2.10 It is important that you quote your Lender User ID and/or Wallet number and/or Unique Virtual Account in the narrative box if you transfer money using a bank transfer. In the case of transfers by debit cards, a transfer will be deemed to be complete as soon as funds are credited to your Fundfina lender account. We require you to use an account (or a debit card linked to an account) held with a bank in India in the name of the named Fundfina account holder (or, in the case of an individual member, in joint names one of which is the named Fundfina account holder).</p>
        <p>2.11 There are no fees payable to set up a Lender Account. You can start making commitments to any loans immediately after setting up of the your account; once you make the commitment you should transfer the money to your Lender Account.</p>
        <p>2.12 Money in your Lender Account will be held in a segregated client account (or designated mobile wallet provided through an authorised payment partner) until or unless it is lent to a borrower at which time it will be transferred to the relevant borrower's Fundfina account. You agree to allow the money to be debited from your bank account and/or mobile wallet once you have committed to funding the loan. Pre-authorisation required from the bank or mobile wallet provider will be facilitated by Fundfina.</p>
        <p>2.13 You may only withdraw money from your Lender Account in excess of the total value of Bids (as defined in clause 4.3 below) that you have made from your Lender Account. Money must only be paid into an Indian bank account in the name of the named Fundfina account holder (or, in the case of joint names one of which is the named Fundfina account holder).</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Username and password</li>
        <p>3.1 On registering with us, you must provide a username and email address and enter a password. Each time you access your Lender Account you will need to enter your email address and password. Your username and password are unique to your Lender Account and are not transferable without our prior written consent.</p>
        <p>3.2 Your username, password are how we identify you, and so you must keep them secure at all times. You are responsible for all information and activity on the platform by anyone using your username and password whether or not authorised by you. If you authorise an employee, sub-contractor, agent or other representative (including any intermediary) to use your Lender Account you will be responsible for their activity on the platform. Any breach of security, loss, theft or unauthorised use of a username, password or security information must be notified to us immediately using the contact details at clause 22 of these terms and conditions.</p>
        <p>3.3 You agree not to adapt or circumvent the systems in place in connection with the platform, nor access our systems other than by using the credentials assigned to you and by following the instructions that we have provided for that type of connection.</p>
        <p>3.4 We reserve the right not to act on your instructions where we suspect that the person logged into your Lender Account is not you or we suspect illegal or fraudulent activity or unauthorised use.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>The borrowing process</li>

        <p>4.1 Where you are an authorised retailer or distributor of one of our of partner companies and you are seeking loan for use as a working capital, we may work with directors and/or parent organisation or members (as the case may be) to vouch for usage of funds and disbursal of account of release of funds before any funds are released by us to you.</p>
        <p>4.2 If your financial condition materially changes prior to Fundfina releasing funds to you, Fundfina may terminate the loan immediately without notice at its discretion. You will remain liable for any interest incurred between acceptance and termination.</p>
        <p>4.3 Fundfina gives each loan a risk band classification at the time a loan request is processed, which is based on information included in your application and the results of an online check made with credit information companies such as CIBIL, Equifax or Experian. Fundfina does not check or edit the information submitted or uploaded by a borrower.</p>
        <p>4.4 The interest rate for each loan is fixed by Fundfina, on the basis of a number of factors including term and risk band. The fixed interest rates are reviewed regularly by Fundfina and can be changed at any time. If you receive an offer prior to your acceptance of the Key Contract Terms (which set out the specific details of a loan), the interest rate in the offer is subject to change. Once you have accepted the Key Contract Terms, the interest rate will not be changed. If your loan is re-listed on the Fundfina platform at any time, it will be re-listed at the interest rate applicable at the time of the new listing.</p>
        <p>4.5 If we agree to allow your application onto the Fundfina platform, and provided you have satisfied our application and assessment criteria and conditions, you will have the opportunity to review and accept the Loan Contract, comprised of the Key Contract Terms and Loan Conditions (it being understood that a binding Loan Contract shall not be formed unless and until your loan is fully funded and the listing is closed. Provided you accept the terms and conditions of the Loan Contract, and save for the exercise of our discretion as provided for in these Terms, your loan request will be listed on our marketplace. Once you have accepted a Loan Contract it cannot be cancelled by you (though, after funding and once in full force and effect, it can be repaid at any time as described in clause 5.3 of these Terms).</p>
        <p>4.6 If listed by us on our platform, we will allocate your loan to the partial loan marketplace (although we may allocate your loan to a specific market if we consider it reasonable to do so in good faith with regard to our relationship with lenders on the platform). If your loan is allocated to the whole loan market it will be available to investors for one of them to lend the full amount requested.</p>
        <p>4.7 Further to the allocation of the loan to the partial loan marketplace or the whole loan marketplace, a loan request page will be displayed on the platform. The Key Contract Terms forming part of the Loan Contract will contain your name. Please see our privacy policy for more information on which details are provided to lenders and those which are withheld, in particular paragraph 2.2 of our privacy policy. We may amend details you provide on your loan request page (except information identifying you) at our discretion.</p>
        <p>4.8 Once the loan request is listed, investors will start bidding to lend to you parts or the whole of the amount you have requested.</p>
        <p>4.9 The listing process shall expire on the earlier of (i) the date your loan request is fully funded, (ii) the date you have chosen as the campaign end date and (iii) the date on which we de-list a loan where we have exercised our discretion to do so.</p>
        <p>4.10 The information on the loan request page can be edited at any time before the request is listed. After the loan has been listed you cannot amend the loan request page until the listing period expires, or if you accept a loan you will not be able to amend the loan request page until such time as the entire loan has been repaid in full.</p>
        <p>4.11 While Fundfina adds value to the credit analysis as part of our loan application review and credit assessment processes, the provision of a risk classification and associated interest rate is intended to be informative only and a lender must form its own opinion regarding the creditworthiness of a borrower and undertake its own research, analysis and assessment of each borrower for each loan and, where appropriate, seek its own independent financial advice.</p>
        <p>4.12 Save in certain specified circumstances, Fundfina does not edit the information submitted or uploaded by borrowers which it then makes available to prospective lenders via the Fundfina platform and, therefore, we cannot guarantee its accuracy. Accordingly, we accept no responsibility or liability for the accuracy of the information provided by borrowers to prospective lenders or the risk band classifications. Fundfina may from time to time, but accepts no obligation to, update or amend borrower's information or the risk band classification (including between when the loan request is first made and when it is entered into, and during the term of any loan). You agree that you shall inform us if any information we publish about you is untrue, inaccurate or misleading in any material respect.</p>
        <p>4.13 A Loan Contract is not formed unless and until the loan request is fully funded and the listing is closed (the "Listing Close Date") but, if a loan request is fully funded, the Loan Contract with the relevant offering lender(s) for the loan (and each of its respective loan parts) shall automatically come into effect and shall be legally enforceable on and from the Listing Close Date.</p>
        <p>4.14 If the loan is not fully funded within the relevant listing period, or if we have exercised our discretion to de-list a loan, then your loan request will be cancelled, and any funds bid will be released back to investors. If the loan is fully funded, the money will then be transferred from each of the relevant Fundfina lender accounts by us to your Fundfina borrower account where it can be transferred to your bank account. Interest shall start to accrue from the loan contract effective date. You will be sent details of the repayment schedule by email, and you will also be able to view this in your account.</p>
        <p>4.15 Each loan will be for the period specified in the Loan Contract applying to it.</p>
        <p>4.16 In all Loan Contracts and loan transfer documentation we will quote a lender's address as being "c/o Fundfina Technologies Private Limited" and state Fundfina's postal address. Each borrower agrees that all notices and communications to be given to a lender will be sent to Fundfina on that lender's behalf and that this is sufficient to identify the lenders for the purposes of the Loan Contracts. Details of relevant lender are available to you upon request.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>The lending and bidding process</li>
        <span>5.1 There are two ways to lend money on Fundfina:</span>
        <ol>
          <li>By hand-picking the borrowers using a search tool to identify loan requests that have not been completed that you might be interested in and bidding on those loan requests;</li>
          <li>By choosing the criteria (expected interest rate and term of loan) for the types and profiles of borrowers, which have been determined by Fundfina, that you want to lend to; and then allowing Fundfina to automatically select the loan requests that you bid on (where they match your criteria) to purchase those loan parts. Note this processes is not automated at the moment and it has to be requested by writing to Fundfina at <a href="mailto:support@funduara.com">support@funduara.com</a><br /><br /></li>
        </ol>
        <p>5.2 All applications by individuals to borrow at Fundfina are carefully reviewed by our credit assessment teams, and classified at the initial application stage with an indicative interest rate range. Individuals that pass through this screening process create a loan request page, which lenders can review at <a href="http://www.fundfina.com/">http://www.fundfina.com/</a>. The interest rate for each loan is suggested by Fundfina, on the basis of a number of factors including term and risk band. The suggested interest rates are reviewed regularly by Fundfina and can be changed at any time. </p>
        <p>5.3 Once you have decided which borrower(s) you want to lend to you choose the amount of money that you want to lend. Provided that you have sufficient funds in your Lender Account, this offer to lend (known as a "Commitment") then becomes active (a "Bid"). Except for loans with a single lender (we call these "whole loans") (where the minimum loan amount is Rs 3000), the minimum bid per loan is Rs 3000, which can be increased by increments of Rs. 500 to a maximum bid per loan of 25% of loan ask or Rs 50,000/-, whichever is lower. </p>
        <p>5.4 Bids by lenders are taken in sequential order. If the amount that you bid exceeds the amount required by the borrower to fully fund the loan, the borrower will be able to borrow part of the amount that you have bid. This will create a loan part for an amount less than the original bid and means that your bid has partially won.</p>
        <p>5.5 Save where Fundfina has otherwise agreed and in respect of whole loans, a single investor is not permitted to bid for loan parts in a loan which add up in total to more than 20% of the requested amount for that loan. If the total amount of accepted bids on a listed loan is equal to 20% of the loan request, the investor will not be permitted to bid further.</p>
        <span>5.6 All bids made by you on a loan request page are final and cannot be withdrawn unless:</span>
        <ol>
          <li>there are sufficient commitments and bids from other investors to fully fund the total loan requested;</li>
          <li>the loan request period expires and the loan is not fully funded;</li>
          <li>the borrower declines an aggregated loan request offer from you and other investors on Fundfina; or</li>
          <li>we withdraw a listed loan request.<br /><br /></li>
        </ol>
        <p>5.7 A loan must be fully funded to complete. The borrower can then either accept the loan or allow the loan offer to lapse (which will happen 7 days after the listing expires) in which case no loan is made.</p>
        <span>5.8 Fundfina reserves the right to:</span>
        <ol>
          <li>place any restriction or condition on which lenders may become lenders or holders of whole loans; and</li>
          <li>change the maximum or minimum amount a single investor is permitted to bid and lend to a particular borrower (or in aggregate across the Fundfina platform generally).<br /><br /></li>
        </ol>
        <p>5.9 Fundfina may withdraw listed loan requests, or the availability of the AutoInvest tool at any time and for any reason. We may also change the minimum and maximum amounts that you can bid and will tell you about these changes via the platform.</p>
        <p>5.10 You can monitor your loan portfolio in the My Account section of the platform.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>The Loan Contract</li>
        <p>6.1 Once (a) the borrower has accepted the terms and conditions of the aggregated loan request offer [including the Key Contract Terms (that set out the specific details of the loan) and our then standard Loan Conditions] and (b) a loan request is fully funded by bids from you and other lenders, you/lender automatically enter into a Loan Contract (which comprises our Loan Conditions and the relevant Key Contract Terms) with the borrower for your part of the total loan amount borrowed by the borrower at the same time. Your money is then transferred from your Fundfina lender account within 24 hours to the Fundfina account of the borrower. The first time you commit on a listed loan you will be required to accept the Loan Conditions for all your ongoing lending. You will be given the opportunity to review the Loan Conditions before accepting them when prompted. Loan Conditions may be changed from time to time insofar as they relate to Loan Contracts entered into after the date of such change. We will notify you of any such changes and prompt you to log into your account and accept the new Loan Conditions. If you do not do so, you will be deemed to have accepted any changes to the new Loan Contracts entered into after the date of notification.</p>
        <p>6.2 Until the loan request has completed no interest will be earned by the lender.</p>
        <p>6.3 In all Loan Contracts and loan transfer documentation we will quote a lender's address as being "c/o Fundfina Technologies Private Limited" and state Fundfina's postal address. Each lender agrees that all notices and communications given to it by a borrower may be sent to Fundfina on their behalf.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Interest, repayments and Payment Methods</li>
        <p>7.1 The monthly/ weekly repayments due to you on each loan part and whole loan can be viewed in the My Account section of the platform. The Loan Contract governs the payment of these amounts. The amount of the monthly or other scheduled repayments payable to lenders in respect of the loans will be set out in the Loan Contract, and a detailed payment schedule will be provided to you following the Loan Contract being in force (and such information is also available through your account on the platform).</p>
        <p>7.2 We will collect the repayment amounts each month or as otherwise set out in the Loan Contract from your bank or mobile wallet account using direct debit or debit card repayments or pre-authorised payment schedule. Note that mobile wallet company might charge a fee for each repayment made using a debit card. We will collect the repayment amount due up to 5 days before the date specified in the Loan Contract in order to ensure that funds are received and distributed to investors.</p>
        <p>7.3 All repayments and interest accrued will be paid to Lender Account.</p>
        <p>7.4 Each loan will be for the period specified in the Loan Contract applying to it. The monthly or other scheduled repayments payable by you to each lender can be viewed in the My Account section of the platform.</p>
        <p>7.5 All repayments will be made to lenders without deduction of income tax. It is your responsibility to account for any income tax and other personal taxes that may be payable to the appropriate authorities.</p>
        <p>7.6 We will provide an annual statement of interest earned for tax purposes.</p>
        <p>7.7 You can fulfil your commitment or pay Fundfina using payment methods specified by Fundfina, which will be updated from time to time and available by logging into your account on the platform. All transactions are processed in INR.</p>
        <p>7.8 You may repay an entire loan early at any time at no extra cost provided you pay the principal amount outstanding together with the interest due until the end of the month in which the loan is so repaid.</p>
        <p>7.9 In certain circumstances, Fundfina may (but is not obliged to) enforce certain terms of each Loan Contract on behalf of the Lenders under it. In doing so, Fundfina is authorised to act as agent on behalf of the relevant lenders (in accordance with its agreed terms and conditions with the lenders) in taking such action.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Fees</li>
        <p>8.1 We have the right to charge a platform fee of 3% of the principal and/or interest lent on each loan (the "Platform Fee") (or such other amount as shall be notified by us to lenders and borrowers from time to time) to cover our role in providing platform services in relation to the introduction of you and the Borrower and the costs of running the website and administering the loans. The Platform Fee is included in the rate of interest stated in the Key Contract Terms for the relevant loan and will be taken directly from loan repayments. The Platform Fee does not cover the costs of our credit analysis which we carry out as part of our underwriting exercise, which is a no-cost added value arrangement only. In some instances a premier service for external credit reference for underwriting might be required - any charges incurred for the same will be separately informed from Fundfina. </p>
        <p>8.2 If we terminate the Loan Contract (or otherwise if a loan becomes 3 months in arrears) we may apply a collections charge based upon the total outstanding amount of the loan. This collections charge is payable by the borrower immediately. Collection charge is ₹550 plus 2% of the outstanding loan amount. For the avoidance of doubt, if a payment plan is entered into we will allocate only up to a maximum of 20% of each recoveries payment received against the collections charge.</p>
        <p>8.3 We may choose to waive or reduce the above stated fees from time to time.</p>
        <li style={{ fontSize: 20, fontWeight: "bold" }}>The role of Fundfina</li>

        <p>9.1 Fundfina's principal role is to perform introductory functions on behalf of borrowers and lenders in order to bring together prospective borrowers and lenders, to provide a stream-lined process for entering into loans (including the development of standard form loan agreements) and to facilitate the payment and collection of sums due under or in connection with those loans (including taking certain actions on behalf of lenders upon a borrower's default or if the borrower becomes, or is likely to become, insolvent as set out in these terms and conditions). We are party to any Loan Contracts as the facilitator between borrowers and lenders as well as an administrative role in facilitating payments, collection of sums due and onward distribution of funds.</p>
        <p>9.2 We will not perform any management functions on a lender's behalf; the lenders retains complete control and discretion over whether or not to make a loan to a borrower and on what terms and over all other aspects of its participation in Fundfina and loans made through it. Fundfina is not authorised to give advice or make recommendations and nothing Fundfina does, and nothing on the platform, is intended to operate or be construed as advice or a recommendation by Fundfina to enter into a particular loan.</p>
        <p>9.3 If we cease trading, we have arrangements in place with a financially stable third party back-up servicer who would take on our servicing obligations (see clause 15 (Back-up servicer arrangements) for more details. We may pass on to lenders available credit reference data on borrowers to assist each investor's analysis of each loan request and to add value to the credit analysis.</p>


        <li style={{ fontSize: 20, fontWeight: "bold" }}>Missed payments</li>
        <p>10.1 You agree that in the event of missed payments on a loan we will take the administrative steps set out in clauses 10.2 to 10.4 below on your behalf. In certain circumstances, we may act outside and/or ahead of the following process to (a) pursue missed payments, (b) seek information from borrowers or otherwise engage with borrowers or (c) take steps to prevent the occurrence of missed payments or default, in each case where we consider this to be in the best interests of lenders. We may recover our reasonable costs as a priority from any amount recovered.</p>
        <p>10.2 If the borrower misses a payment or only partially pays, the amount due to you and the other lenders, we (ourselves or through an agent acting on our behalf) will contact the borrower to inform them that we will reattempt to collect the outstanding payment in respect of that loan. If our reattempt to collect the funds fails after the payment was due, the borrower's account will be treated as an overdue account and we will continue to attempt to collect the funds. We may refer the missed payment to a debt collections agency (the "Collections Agency"), who will attempt to collect the money on your and any other lenders' behalf and you authorise us to use the Collections Agency on your behalf. Any fee that the Collections Agency charges for this initial debt chasing may be added to the loan amounts that they are seeking to collect</p>
        <p>10.3 If the borrower misses, fails to pay or only partially pays any instalments, or if the borrower is otherwise in breach of any of the Loan Conditions, the loan may be placed into default and we may engage field agents to attempt to collect the total loan amount outstanding. We may deduct up to 40% of the amount recovered from the borrower to cover the costs of third parties (such as field agents) as well as any collections charges that we may apply to cover the costs of the in-house Collections & Recoveries team (see clause 10.2) and the remaining proceeds will be distributed to lenders. Where reasonably possible any deductions for third party costs or collections charges will be added on to the loan amount outstanding so as not to reduce the amount lenders receive</p>
        <p>10.4 If the loan is placed into default, Fundfina will notify you (by way of note in your Fundfina lender account) that all of the outstanding loan parts or the whole loan will be coordinated by Fundfina for recovery. In certain circumstance we might need to assign the debt to an external party to enable us to commence legal proceedings against the borrower for the full amount outstanding. In those circumstances you agree to such assignment. We, acting on behalf of lenders will then investigate the amount of debt that is likely to be successfully recovered through the courts. If we deem it appropriate, we will instruct solicitors to file court claims to recover the debt. Fundfina will facilitate pay back to investors in their proportionate share any funds successfully recovered, less its costs incurred during that recovery and any relevant collections charges (subject to the 40% cap referred to in clause 10.3). We will act in a fair and equitable manner to achieve maximum recovery for all affected investors, which may include agreeing new payment terms or selling the debt to a third party</p>
        <p>10.5 Fundfina will keep you up to date with the progress of its efforts to collect missed payments and to recover defaulted debt, and such progress can be viewed in the My Account section and/or will separately be communicated from Fundfina</p>
        <p>10.6 We may also share your default information with other credit beauerus or other bodies as requested by the regulator.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Terminating your membership of Fundfina</li>

        <p>11.1 If you no longer want to be a member of Fundfina then, provided you have no Loan Contracts, or Commitment or Bids currently in force (see clause 11.2 below), you can let us know and we will end your membership such that your agreement with us shall be terminated with effect from that date. This fee might be lower if we have agreed so as part of enterprise partnership.</p>
        <p>11.2 You are unable to end your membership and terminate your agreement with us, and so cannot close your Lender Account, unless and until you have no Loan Contracts, or Commitment or Bids in force or in place. This means that, if you wish to end your membership and terminate your agreement with us, you could take steps to seek to put yourself in a position where you can do so (including by not making any commitment or bids, or waiting until Loan Contracts have expired and the loan is at an end and/or waiting until proceedings against the relevant borrowers have ceased).</p>
        <p>11.3 Clause 11.2 does not prevent you from withdrawing any cash standing to the credit of your Lender Account (and which is available for withdrawal) in the interim in accordance with clause 1.13.</p>
        <p>11.4 All charges shall continue to apply to your Lender Account until such time as it may be closed and our agreement with you is terminated.</p>
        <span>11.5 We may end your membership of Fundfina at any time if:</span>
        <ol>
          <li>you breach these terms and conditions or are in breach of any regulatory requirements relating to your activity as a lender on the platform;</li>
          <li>you breach any Loan Contract;</li>
          <li>we suspect that you have committed fraud, been involved in money laundering or other criminal activities;</li>
          <li>it comes to our attention that you have provided information to us which we subsequently find to be materially incorrect, inaccurate or false;</li>
          <li>you use the Fundfina platform or any information accessible on or obtained from it for the purpose of canvassing or soliciting any person or enticing any person away from Fundfina;</li>
          <li>you use the Fundfina platform in any of the following ways:</li>
          <ol type="a">
            <li>in any way that causes, or is likely to cause, the platform or access to it to be interrupted or damaged in any way;</li>
            <li>for fraudulent purposes, or in connection with a criminal offence;</li>
            <li>to send, use or reuse any material that is illegal, offensive, abusive, indecent, defamatory, obscene or menacing; or in breach of copyright, trademark, confidence, privacy or any other right; or is otherwise injurious to third parties; or objectionable; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam";</li>
            <li>to cause annoyance, inconvenience or needless anxiety or in a way that restricts or inhibits anyone else's use and enjoyment of the platform;</li>
            <li>for a purpose for which the platform is not intended or other than as a marketplace for lending; or,</li>
          </ol>
          <li>there is no activity in your Account within a 12 month period.<br /><br /></li>
        </ol>
        <p>11.6 On termination we will credit your mobile wallet and/or bank account with any funds left in your Fundfina account or send a cheque to the address last provided by you.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Borrower's agreements with Fundfina</li>

        <p>12.1 You declare that you meet all the borrower's eligibility criteria as per 1.1.</p>
        <p>12.2 Membership of Fundfina does not in any way constitute an obligation on us to procure funding for borrowers or constitute a warranty or other assurance by us that funding will be available.</p>
        <p>12.3 If any personal guarantee is provided of the borrower's obligations, the borrower, directors, shareholders and members represent and warrant that there has been no undue influence or inducement of the guarantor.</p>
        <p>12.4 If you are a borrower through the Fundfina platform, you generally cannot also be a lender while you have loans outstanding. An exception would be where you are lending in your own individual capacity but you happen to be a director or owner of a business borrowing money. We also retain the discretion to grant specific exceptions to this rule.</p>


        <li style={{ fontSize: 20, fontWeight: "bold" }}>Lenders' agreements with Fundfina</li>
        <p>13.1 You declare that you meet all the lender's eligibility criteria as per 2.1.</p>
        <span>You agree that:</span>
        <ol>
          <li>Fundfina is giving no undertaking nor giving any warranty or representation as to (i) the ability of a borrower to pay and/or (ii) a borrower's credit risk;</li>
          <li>Fundfina is in no way liable for the debts of borrowers;</li>
          <li>calculations we may provide of the likely rate of return on loans are for guidance purposes only and are not guaranteed;</li>
          <li>the information on the platform does not constitute advice, recommendation or an endorsement of loan requests or borrowers and that the information is not intended to be relied upon as a sole basis for deciding whether or not to lend;</li>
          <li>Fundfina is making no representation nor giving any warranty as to the accuracy of the data displayed for loan requests, nor whether the information is up to date or error free;</li>
          <li>Fundfina does not guarantee that there will be sufficient commitments to fully fund loans and allow your funds to be lent out, that there will be sufficient borrowers for you to lend;</li>
          <li>you are bidding and lending entirely at your own risk.<br /><br /></li>
        </ol>
        <p>Where Fundfina has implemented any form of personal guarantee in support of a loan on your behalf as an lender, you accept that the enforceability of such personal guarantee shall be subject to normal legal risks and limitations and that Fundfina will not have ensured that any individual providing such a guarantee has been independently advised on the impact of such a personal guarantee. Such guarantees can be open to challenge in circumstances where the individual granting the guarantee has been subject to undue pressure or influence from a third party.</p>
        <p>The Fundfina platform is not intended or designed to be used, nor does Fundfina permit any use of the platform, as a vehicle for generating loan assets (or purchasing interests in loan assets) as part of a lender's (or a member of a lender's group's) securitisation or fund-raising strategy, and shall not be used as such by any lender or a member of a lender's group without the prior written consent of Fundfina (such consent may be withheld in Fundfina's absolute discretion).</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Back-up servicer arrangements</li>

        <span>14.1 If our platform were to fail or we become insolvent, we would transfer our loan servicing obligations to a third party back-up servicer, with whom we would agree a back-up servicing arrangement. The third party will be a non-banking financial company (NBFC) that will be authorised and regulated by the RBI with permission to conduct consumer credit business. We reserve the right to use a back-up servicer prior to insolvency where such action is in the best interests of all of the investors. If this happens:</span>
        <ol>
          <li>you would not be able to sell any loan parts;</li>
          <li>you would receive distributions of funds to your external bank or building society account on a quarterly basis;</li>
          <li>the My Account page on the platform and the rest of the platform would not be available for access by you; and</li>
          <li>you would receive quarterly statements of repayments and continue to receive annual statements (for tax purposes).<br /><br /></li>
        </ol>
        <p>14.2 Our outsourced servicer would continue to collect the Servicing Fee. Our outsourced servicer may charge an additional special situation fee which will be included in the interest rate paid by the borrower. </p>
        <p>14.3 The total annual fees to cover the costs of running the website and administering the loans will not exceed 2% of the principal value of each loan part or whole loan outstanding.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Client Money</li>

        <p>15.1 We do not hold any client monies. All of your money is held in a segregated account or mobile wallet separately from our own funds with a bank or e-money appropriately authorised by an independent trustee, in line with RBI obligations ("Client Bank Account") and in accordance with the Client Money rules and maintained specifically for you or for our members generally. Your money may be held with other members' money in a pooled account and you consent to such pooling. Client Money will be held on an omnibus basis which means that if the bank were to fail, your funds would be affected by any shortfall in proportion to the value of your funds relative to the total sum of Client Money held in this way. We will not be responsible for any acts, omissions or default of any bank with which your money is held and in the event of insolvency of such bank, we would not be liable for any shortfall unless we had failed to comply with any duty of care or obligation to which we were subject. Through application programming interfaces you might be able to view those monies on Fundfina lending account. Virtual (view-only) Client Money will be subject to internal control mechanisms and proper accounting procedures. </p>
        <p>15.2 Fundfina only provides electronic (no paper) statements of client holdings. Additionally, you can log into platform to download a statement of your "available funds" and "invested funds", which together account for the client money we are holding for you.</p>
        <p>15.3 We will not pay interest on balances held as Client Money.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>General terms</li>
        <p>16.1 The information provided on the platform is directed solely at and is for use solely by persons and organisations, including financial institutions, that meet the criteria set out in clause 1 above and the equivalent criteria set out in the terms and conditions applying to borrowers. The content provided through the platform is not intended for distribution to, or use by, any person or entity in any jurisdiction where such distribution or use would be contrary to applicable law or regulation.</p>
        <p>16.2 You are permitted to download and print content from this platform solely for your own personal use or in the course of your business to the extent required to use the services provided on this platform. Fundfina supplied content must not be copied or reproduced, modified, redistributed, used or otherwise dealt with for any other reason without our express written consent.</p>
        <p>16.3 We are not responsible for content downloaded or posted by lenders or borrowers on the platform. We may edit certain content on the platform at our discretion to clarify content or remove offensive material but are not obliged to do so.</p>
        <p>16.4 We will endeavour to ensure that the Fundfina platform is available for you to use at any time. However, this is not something we are able to guarantee and there may be times when the platform is not available. We will do our best to notify you via our website when we know this service will be unavailable. You can contact us when the Fundfina platform is not available by emailing <a href="mailto:support@funduara.com">support@funduara.com</a> or calling our customer service number specified on the website.</p>
        <p>16.5 You agree to use the platform only for lawful purposes and in a way which does not infringe the rights of any anyone else or restrict or inhibit anyone else's use and enjoyment of the platform.</p>
        <p>16.6 You agree not to use the Fundfina platform or any information accessible on or obtained from it for the purpose of canvassing or soliciting any person or enticing any person away from Fundfina.</p>
        <p>16.7 You warrant that you have taken all reasonable precautions to ensure that any data you upload or otherwise submit to the platform is free from viruses and anything else which may have a contaminating or destructive effect on any part of the platform or any other technology.</p>
        <p>16.8 The content and material available through the platform is for informational purposes only and should not be regarded as an offer, solicitation, invitation, advice or recommendation to buy or sell investments, securities or any other financial services or banking product. If you are unsure about whether a product is suitable you should contact an independent financial adviser.</p>
        <p>16.9 You are responsible for all costs incurred by you in accessing the platform.</p>
        <p>16.10 We accept no responsibility or liability for your use of content on the platform and such use is entirely at your own risk. While we take reasonable precautions to prevent the existence of computer viruses or other malicious programs on the platform, we accept no liability for them if they do exist. It is your responsibility to use, update and maintain appropriate antivirus software on your computer.</p>
        <p>16.11 We take reasonable precautions to ensure that our systems are secure. However, information transmitted via our systems will pass over public telecommunications networks. We accept no liability if communications sent via the platform are intercepted by third parties or incorrectly delivered or not delivered.</p>
        <p>16.12 The platform may contain links to websites. We accept no responsibility or liability for any material supplied by or contained on any third party website which is linked from or to the platform, or any use of personal data by such third party.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Privacy</li>
        <p>You agree that any and all personal information you provide to us via this platform may be collected, stored, processed and used in accordance with our then current privacy policy (accessible online at <a href="http://www.fundfina.com/privacyPolicy">http://www.fundfina.com/privacyPolicy</a>).</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Intellectual property (IP) rights</li>
        <p>18.1 We own all present and future copyright, registered and unregistered trade marks (including the goodwill thereon), design rights, unregistered designs, database rights and all other present and future IP rights existing in or in relation to the platform.</p>
        <p>18.2 If and to the extent that any such intellectual property rights vest in you by operation of law or otherwise, you agree to do any and all such acts and execute any and all such documents as we may reasonably request in order to assign such intellectual property rights (and associated goodwill) back to us.</p>
        <p>18.3 You shall retain ownership of all copyright in data you upload or submit to the platform. You grant us a world-wide exclusive, royalty-free, perpetual, irrevocable licence to use, copy, distribute, publish and transmit such data in any manner.</p>
        <p>18.4 We do not warrant or represent that the content of the platform does not infringe the rights of any third party.</p>


        <li style={{ fontSize: 20, fontWeight: "bold" }}>Your liability to us</li>

        <span>19.1 You shall be liable to us for any foreseeable loss or damage suffered by us as a result of:</span>
        <ol>
          <li>any breach of these terms and conditions or any Loan Contract by you;</li>
          <li>any fraudulent use of the Fundfina platform; and</li>
          <li>any and all use of your username and password (including as provided for in clause 2.2 of these terms and conditions).<br /><br /></li>
        </ol>
        <span>19.2 You shall not be liable to us for loss or damage which is not foreseeable.</span>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Our liability to you</li>

        <span>20.1 We shall be liable to you for any foreseeable loss or damage suffered by you as a result of:</span>
        <ol>
          <li>any breach of these terms and conditions by us; and</li>
          <li>our negligence, wilful default or fraud by us or our employees.<br /><br /></li>
        </ol>
        <p>20.2 We shall not be liable to you for loss or damage from circumstances that are beyond our reasonable control.</p>
        <p>20.3 Nothing in these terms and conditions shall limit our liability for personal injury or death caused by our negligence, fraud, nor for any other liability the exclusion or limitation of which is not permitted by applicable law or regulation.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>About us</li>

        <p>21.1 Fundfina Technologies Private Limited is a company incorporated in India, whose registered CIN is U72200JH2016PTC008813 and whose registered office is at HD-16, City Centre, Sector 4, Bokaro Steel City, Jharkhand 827004, and principal place of business is at 18, First Floor, Shreeji Arcade, Almeida Road, Thane West 400602. </p>
        <p>21.2 We act as a facilitator of credit between borrowers and lenders through our peer to peer (P2P) platform that operates under the trade name of Fundfina. We are regulated by Reserve Bank of India as a Non-Banking Financial Company (NBFC) - P2P.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Contacting us and Complaints</li>

        <p>22.1 If you want to make a complaint about Fundfina you can e-mail support@fundfina.com, with brief details of your complaint and your Fundfina UserID. Our customer service staff will acknowledge your complaint by e-mail within three working days. They will then investigate and send you an initial response, having had access to an officer with the authority to settle the complaint (including, where appropriate, an offer of redress). Where appropriate, the member of staff investigating the complaint will not be any staff member who was directly involved in the subject matter of the complaint. This should take no longer than five business days, but most complaints may well be resolved by close of business on the business day after the complaint is received. If the complaint is not resolved by close of business on the business day after the complaint is received, we will send you a copy of this complaints handling procedure with a status update on your complaint. If you are not satisfied by our response, email coo@fundfina.com and your complaint will be escalated to the Customer Services Manager, who will respond by e-mail within a further five business days. If not resolved within those time frame this will be automatically escalated to the Chief Operating Officer.</p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Amendments to these terms and conditions</li>
        <p>23.1 We expect to need to update or amend these terms and conditions from time to time to comply with law or to meet our changing business requirements.</p>
        <p>23.2 We may make changes to terms and conditions without your specific agreement where those updates are, in our reasonable opinion, of an immaterial and routine nature. We may not always be able to give you advanced notice of such updates or amendments but we will always post them on the platform so you can view them when you next log in. In these circumstances, by continuing to use the platform, or our services in allocating funds you have deposited, you agree to be bound by the amended terms and conditions.</p>
        <p>23.3 These terms and conditions were last updated on 2nd February 2018. </p>

        <li style={{ fontSize: 20, fontWeight: "bold" }}>Other important terms</li>
        <p>24.1 If any of these terms and conditions is found to be illegal, invalid or unenforceable by any court of competent jurisdiction, the remainder shall continue in full force and effect.</p>
        <p>24.2 All disclaimers, indemnities and exclusions in these terms and conditions shall survive termination of the agreement between us for any reason.</p>
        <p>24.3 We may, in whole or in part, release, compound, compromise, waive or postpone, in our absolute discretion, any liability owed to us or right granted to us in these terms and conditions without in any way prejudicing or affecting our rights in respect of that or any other liability or right not so released, compounded, compromised, waived or postponed.</p>
        <p>24.4 No single or partial exercise, or failure or delay in exercising any right, power or remedy by us shall constitute a waiver by us of, or impair or preclude any further exercise of, that or any right, power or remedy arising under these terms and conditions.</p>
        <p>24.5 Please ensure that you read these terms and conditions, Loan Contracts and the API Terms of Use (if applicable) carefully as these will bind you and govern your relationship with us and borrowers. If you are uncertain as to your rights under them or would like an explanation, please get in touch with us.</p>
        <p>24.6 Fundfina may exercise any of its rights under these terms and conditions by itself or through any other legal entity which is under the control or ownership of Fundfina and each such entity can rely on these terms as though it were a party to this contract.</p>
        <p>24.7 These terms and conditions are governed by Indian law. In the event of any matter or dispute arising out of or in connection with these terms and conditions, you and we shall submit to the non-exclusive jurisdiction of the Indian courts.</p>




      </ol>
    </React.Fragment >
  );
}

export default Tnc;
