import React from "react";
import { CardDeck, Card, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as analytics from "../../utils/analytics";

function Faq(props) {

  analytics.track();

  return (
    <React.Fragment>
      <h2>FAQ</h2>
      <br />
      <CardDeck>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            Invest / Lend
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/invest.png")}
            className="faqCardsInvestImg"
          />
          <Card.Body></Card.Body>
          <Card.Footer>
            <Link to="investContent">
              <Button className="ff-button" style={{ float: "right" }}>
                Know More
              </Button>
            </Link>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            Borrow
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/borrow.png")}
            className="faqCardsBorrowImg"
          />
          <Card.Body></Card.Body>
          <Card.Footer>
            <Link to="borrowContent">
              <Button className="ff-button" style={{ float: "right" }}>
                Know More
              </Button>
            </Link>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            About Fundfina
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/Fundfina Logo.png")}
            className="faqCardsAboutImg"
          />
          <Card.Body></Card.Body>
          <Card.Footer>
            <Link to="aboutus">
              <Button className="ff-button" style={{ float: "right" }}>
                Know More
              </Button>
            </Link>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            Contact Us
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/contactus.png")}
            className="faqCardsContactImg"
          />
          <Card.Body></Card.Body>
          <Card.Footer>
            <Link
              to="contactus"
              style={{ textAlign: "right", textDecoration: "underline" }}
            >
              <Button className="ff-button" style={{ float: "right" }}>
                Know More
              </Button>
            </Link>
          </Card.Footer>
        </Card>
      </CardDeck>
    </React.Fragment>
  );
}

export default Faq;
