import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import _ from "lodash";
import moment from "moment";

class CreateInvoiceModel extends InputDataForm {
    state = {
        data: {
            fromDate: momentJalaali().startOf('month'),
            toDate: momentJalaali(),
            loans: [],
            page: 1,
            partnerId: "",
            invoiceType: "",
            uploadType: "excel",
            fileName: "",
            selectedFile: null,
            loaded: "",
            invoiceRefNo: ""
        },
        showSpinner: false,
        errors: {},
        currentPage: 1,
        pageSize: 0,
        partners: [],
        evaluatorRole: "",
        selectedLoans: [],
        selectAll: false,
        showPopUp: false,
        invoiceTypeList: [],
        tableShow: false,
        downloadExcel: false,
        showExcel: false
    };
    schema = {
        fromDate: Joi.any().required().disallow("").label("From Date"),
        toDate: Joi.any().required().disallow("").label("To Date"),
        loans: Joi.any(),
        partnerId: Joi.string().required().label("Partner"),
        page: Joi.any(),
        invoiceType: Joi.string().required().label("Invoice Type"),
        uploadType: Joi.string().required().label("Upload Type"),
        fileName: Joi.any(),
        selectedFile: Joi.any(),
        loaded: Joi.any(),
        invoiceRefNo: Joi.any()
    };
    async loadDropdowns() {
        let { invoiceTypeList, data, partners } = this.state;
        try {
            const {
                data: getInvoiceTypeList,
            } = await loanService.getInvoiceListByPartnerId(data.invoiceType, data.partnerId)

            invoiceTypeList = getInvoiceTypeList;

            const {
                data: getPartnerListByInvoiceType,
            } = await loanService.getPartnerListByInvoiceType(data.invoiceType, data.partnerId)

            partners = getPartnerListByInvoiceType;

            this.setState({
                invoiceTypeList,
                partners
            });
        } catch (ex) { }
    };
    async getLoanList() {
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        delete errors["globalError"];
        data.loans = [];

        if (!data.fromDate)
            errors["fromDate"] = "Please select from date";
        else
            delete errors["fromDate"];

        if (!data.toDate)
            errors["toDate"] = "Please select to date";
        else
            delete errors["toDate"];

        if (!data.partnerId)
            errors["partnerId"] = "Please select Partner";
        else
            delete errors["partnerId"];

        if (!data.uploadType)
            errors["uploadType"] = "Please select upload type";
        else
            delete errors["uploadType"];

        if (!data.invoiceType)
            errors["invoiceType"] = "Please select invoiceType";
        else
            delete errors["invoiceType"];

        this.setState({ errors });

        if (!_.isEmpty(errors))
            return;

        this.setState({ showSpinner: true, selectAll: false, selectedLoans: [], data });

        try {
            const { data: loanList, success, message } = await loanService.getLoansListForInvoice(data);

            if (success) {
                data.loans = loanList;
                if (data.uploadType === "excel") {
                    this.setState({ data, downloadExcel: true, errors, tableShow: false, showSpinner: false }, () => {
                        this.setState({
                            downloadExcel: false,
                            showExcel: data.uploadType === "selection" ? false : true
                        });
                    });
                    return;
                }
            }
            else
                errors["globalError"] = message;

            this.setState({ data, showSpinner: false, errors, tableShow: success ? true : false, showExcel: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ data, showSpinner: false, errors, tableShow: false });
        }
    };
    async componentDidMount() {
        if (!auth.getCurrentUser()) return;

        const currentUser = auth.getCurrentUser();

        if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

        if (currentUser.role === "Borrowing Partner") {
            const { data } = this.state;
            const currentPartner = auth.getCurrentPartner();
            console.log("currentPartner", currentPartner);
            data.partnerId = currentPartner._id;
            this.setState({ data });
        }

        await this.loadDropdowns();
    };
    async handleCustomErrors(errors) {
        return errors;
    };
    async doSubmit() {
        await this.getLoanList();
    };
    handleChecked = loanId => e => {
        const selectedLoans = this.state.selectedLoans;

        if (e.target.checked == false && selectedLoans.includes(loanId, 0)) {
            const index = selectedLoans.indexOf(loanId);
            if (index != -1) {
                selectedLoans.splice(index, 1);
            }
        } else if (e.target.checked == true && !selectedLoans.includes(loanId, 0)) {
            selectedLoans.push(loanId);
        }
        this.setState({ selectedLoans });
    }
    createInvoice = async () => {
        const selectedLoans = this.state.selectedLoans;
        const data = { ...this.state.data }
        const errors = { ...this.state.errors };
        delete errors["globalError"];

        if (!data.fromDate)
            errors["fromDate"] = "Please select from date";
        else
            delete errors["fromDate"];

        if (!data.toDate)
            errors["toDate"] = "Please select to date";
        else
            delete errors["toDate"];

        if (!data.partnerId)
            errors["partnerId"] = "Please select Partner";
        else
            delete errors["partnerId"];

        if (!data.uploadType)
            errors["uploadType"] = "Please select upload type";
        else
            delete errors["uploadType"];

        if (!data.invoiceType)
            errors["invoiceType"] = "Please select invoiceType";
        else
            delete errors["invoiceType"];

        if (data.uploadType === "excel" && !data.selectedFile)
            errors["uploadType"] = "Please upload excel file";
        else
            delete errors["uploadType"];

        if (!data.invoiceRefNo && (data.invoiceType === "DISBURSAL_CHARGES" || data.invoiceType === "COLLECTION_CHARGES"))
            errors["invoiceRefNo"] = "Please enter invoice reference number";
        else
            delete errors["invoiceRefNo"];

        this.setState({ errors });

        if (!_.isEmpty(errors))
            return;

        this.setState({ showSpinner: true });

        if (data.uploadType === "SELECTION" && selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        } else {
            try {
                if (errors["globalError"] == null) {
                    let result = null;

                    if (data.uploadType === "selection")
                        result = await loanService.createInvoice(data, selectedLoans);
                    else {
                        let fileData = new FormData();
                        fileData.append('file', data.selectedFile);
                        fileData.append('invoiceType', data.invoiceType);
                        fileData.append('fileName', data.fileName);
                        fileData.append('partnerId', data.partnerId);
                        fileData.append('fromDate', moment(data.fromDate).format("DD/MM/yyyy"));
                        fileData.append('toDate', moment(data.toDate).format("DD/MM/yyyy"));
                        fileData.append('invoiceRefNo', data.invoiceRefNo);

                        result = await loanService.createInvoiceByExcel(fileData);
                    }

                    if (result.success) {
                        data["partnerId"] = "";
                        data["invoiceType"] = "";
                        data.loans = [];
                        data.selectedFile = null;
                        data.uploadType = "";

                        this.setState({
                            data, errors, showSpinner: false, successMsg: result.message, selectAll: false, selectedLoans: [],
                            showExcel: false
                        });
                    } else {
                        errors["globalError"] = result.message;
                        this.setState({ errors, showSpinner: false });
                    }
                }
            }
            catch (ex) {
                errors["globalError"] = ex.message;
                this.setState({ errors, showSpinner: false });
            }

        }
    }
    handlePopupOpen = () => {
        const selectedLoans = this.state.selectedLoans;
        const errors = { ...this.state.errors };
        const data = this.state.data;
        delete errors["globalError"];

        if (data.uploadType === "SELECTION" && selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        }
        else
            this.setState({ showPopUp: true, errors });
    };
    handlePopupClose = () => {
        this.setState({ showPopUp: false });
    };
    handlePopupOk = async () => {
        this.setState({ showPopUp: false });
        await this.createInvoice();
    };
    handlePartnerChange = async ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        const {
            data: getInvoiceTypeList,
        } = await loanService.getInvoiceListByPartnerId(data.invoiceType, data.partnerId)

        data.loans = [];

        this.setState({
            invoiceTypeList: getInvoiceTypeList,
            data, errors, selectedLoans: []
        });

    };
    handleInvoiceTypeChange = async ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        const {
            data: getPartnerListByInvoiceType,
        } = await loanService.getPartnerListByInvoiceType(input.value, data.partnerId)

        data.loans = [];

        this.setState({
            partners: getPartnerListByInvoiceType,
            data, errors, selectedLoans: []
        });
    };
    handleCustomFromDateChange = (value) => {
        const { data } = this.state;

        data.loans = [];

        data.fromDate = value;
        this.setState({ data, showDateFilterNote: false, selectedLoans: [] });
    }
    handleCustomToDateChange = (value) => {
        const { data } = this.state;

        data.loans = [];

        data.toDate = value;
        this.setState({ data, selectedLoans: [] });
    };
    clear = () => {
        const { data, evaluatorRole, errors } = this.state;

        data.invoiceType = "";
        data.invoiceRefNo = "";
        if (evaluatorRole === "Admin")
            data.partnerId = "";
        data.fromDate = momentJalaali().startOf('month');
        data.toDate = momentJalaali();
        errors.globalError = "";
        this.setState({ data, showExcel: false, errors });
    };
}

export default CreateInvoiceModel;
