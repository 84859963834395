import React from "react";
import { Form, Col, Button, Table, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import preApprovalModel from "../../models/preApproval/preApprovalModel";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import { StickyTable, Row, Cell } from 'react-sticky-table';

class PreApproval extends preApprovalModel {
    getPagedData = () => {
        const { list } = this.state;

        return { data: list };
    };
    render() {
        const { data, showSpinner, errors, currentYear, pageSize, currentPage, successMsg, borrowingPartnerList, currentMonth, currentSelectedMonth } = this.state;
        const { totalCount, data: list } = this.getPagedData();

        let i = 0;
        const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const years = [];

        var defaultYear = currentYear - 4;
        for (var j = 0; j < 5; j++) {
            years.push(defaultYear);
            defaultYear++;
        }
        return (<React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>PreApproval</h2>
            <br />
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId="borrowingPartner">
                        <Form.Label>Partner</Form.Label>
                        <Form.Control
                            as="select"
                            name="partnerId"
                            value={data["partnerId"]}
                            onChange={this.handleChange}
                        >
                            <option key="all" value="">
                                ---ALL---
                            </option>
                            {borrowingPartnerList.map((item, i) => (
                                <option key={i} value={item._id}>
                                    {item.displayName}
                                </option>
                            ))}
                        </Form.Control>
                        {errors["borrowingPartner"] && (
                            <div className="alert alert-danger">
                                {errors["borrowingPartner"]}
                            </div>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="merchantCode">
                        <Form.Label>Merchant Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="merchantCode"
                            value={data["merchantCode"]}
                            onChange={this.handleChange}
                            onBlur={this.handleChange}
                            maxLength="100"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="month">
                        <Form.Label>Month</Form.Label>
                        <Form.Control
                            as="select"
                            name="month"
                            value={data["month"]}
                            onChange={this.handleChange}
                        >
                            <option key="select" value="">
                                ---Select---
                            </option>
                            {months.map((value, index) => {
                                return <option key={index} value={value}>{value}</option>;
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="year">
                        <Form.Label>Year</Form.Label>
                        <Form.Control
                            as="select"
                            name="year"
                            value={data["year"]}
                            onChange={this.handleChange}
                        >
                            <option key="select" value="">
                                ---Select---
                            </option>
                            {years.map((value, index) => {
                                return <option key={index} value={value}>{value}</option>;
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="option">
                        <Form.Label>Option</Form.Label>
                        <Form.Control
                            as="select"
                            name="option"
                            value={data["option"]}
                            onChange={this.handleChange}
                        >
                            <option key="pending" value="pending">
                                Pending
                            </option>
                            <option key="preapproved" value="preapproved">
                                PreApproved
                            </option>
                            <option key="expired" value="expired">
                                Expired
                            </option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="trueScoreGT">
                        <Form.Label>trueScore Greater Than</Form.Label>
                        <Form.Control
                            type="text"
                            name="trueScoreGT"
                            value={data["trueScoreGT"]}
                            autoComplete="off"
                            maxLength="4"
                            onKeyPress={this.handleKeyPressNumeric}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="trueScoreLT">
                        <Form.Label>trueScore Less Than</Form.Label>
                        <Form.Control
                            type="text"
                            name="trueScoreLT"
                            value={data["trueScoreLT"]}
                            autoComplete="off"
                            maxLength="4"
                            onKeyPress={this.handleKeyPressNumeric}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="4">
                        <Button
                            className="ff-button"
                            type="submit"
                            style={{ marginTop: 30, marginLeft: 10 }}
                        >
                            Apply Filters
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            <br />
            {errors["globalError"] && (
                <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
            {successMsg && <div className="alert alert-success">{successMsg}</div>}
            <br />
            <div style={{ width: '100%' }}>
                <StickyTable stickyHeaderCount={0} rightStickyColumnCount={1} leftStickyColumnCount={2}>
                    <Row>
                        <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Partner</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Merchant Code</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Merchant Type</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Mobile</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Email</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>OnBoarding Date</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Pincode</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>trueScore</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan Type</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Product Type</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Processing Fee</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Annual Interest Rate</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Tenure In Days</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Approved Amount</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Emi Amount</b></Cell>
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Interest Type</b></Cell>
                        {(data["submittedOption"] != "pending") && <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Send On</b></Cell>}
                        {(data["submittedOption"] != "pending") && <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Transaction/Offer Id</b></Cell>}
                        <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Actions</b></Cell>
                    </Row>
                    {list &&
                        list.map((item, k) => {
                            i += 1;
                            return (
                                <Row key={item.id + "_" + i}>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{item.partner}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.merchantCode}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.merchantType}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.mobileNo}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.emailId}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{moment(item.onboardedDate).format("DD/MM/yyyy")}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.pincode}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.trueScore}</Cell>
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}> <Form.Control
                                        type="text"
                                        name={"loanType_" + item.id + "_" + item.trxnId}
                                        maxLength="1"
                                        onChange={this.handleRecordChange}
                                        value={item.loanType ? item.loanType : ""}
                                        ref={"loanType_" + item.id + "_" + item.trxnId}
                                    /></Cell>
                                    <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}><Form.Control
                                        as="select"
                                        name={"productType_" + item.id + "_" + item.trxnId}
                                        value={item.productType}
                                        onChange={this.handleRecordChange}
                                    >
                                        <option key="select" value="">
                                            --Select--
                                        </option>
                                        <option key="TL" value="TL">
                                            Term Loan
                                        </option>
                                        <option key="DL" value="DL">
                                            Daily Loan
                                        </option>
                                    </Form.Control>
                                    </Cell>
                                    <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}><Form.Control
                                        className={`${item.processingFee && +item.processingFee.split('%')[0] < 0 ? "invalid-input" : ""}`}
                                        type="text"
                                        name={"processingFee_" + item.id + "_" + item.trxnId}
                                        maxLength="10"
                                        onKeyPress={this.handleKeyPressDecimalWithPer}
                                        onChange={this.handleRecordChange}
                                        value={item.processingFee}
                                        pattern="^(\d*\.)?\d+%$"
                                        ref={"processingFee_" + item.id + "_" + item.trxnId}
                                    /></Cell>
                                    <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}><Form.Control
                                        className={`${item.annualIntrestRate && +item.annualIntrestRate.split('%')[0] < 0 ? "invalid-input" : ""}`}
                                        type="text"
                                        name={"annualIntrestRate_" + item.id + "_" + item.trxnId}
                                        maxLength="10"
                                        onKeyPress={this.handleKeyPressDecimalWithPer}
                                        onChange={this.handleRecordChange}
                                        value={item.annualIntrestRate}
                                        pattern="^(\d*\.)?\d+%$"
                                        ref={"annualIntrestRate_" + item.id + "_" + item.trxnId}
                                    /></Cell>
                                    <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}><Form.Control
                                        className={`${item.tenureInDays && +item.tenureInDays < 0 ? "invalid-input" : ""}`}
                                        type="text"
                                        name={"tenureInDays_" + item.id + "_" + item.trxnId}
                                        maxLength="10"
                                        onKeyPress={this.handleKeyPressDecimal}
                                        onChange={this.handleRecordChange}
                                        value={item.tenureInDays}
                                        pattern="^(\d*\.)?\d+%$"
                                        ref={"tenureInDays_" + item.id + "_" + item.trxnId}
                                    /></Cell>
                                    <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}><Form.Control
                                        className={`${item.approvedAmount && +item.approvedAmount < 0 ? "invalid-input" : ""}`}
                                        type="text"
                                        name={"approvedAmount_" + item.id + "_" + item.trxnId}
                                        maxLength="10"
                                        onKeyPress={this.handleKeyPressDecimal}
                                        onChange={this.handleRecordChange}
                                        value={item.approvedAmount}
                                        pattern="^(\d*\.)?\d+$"
                                        ref={"approvedAmount_" + item.id + "_" + item.trxnId}
                                    /></Cell>
                                    <Cell style={{ textAlign: "right", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.emiAmt}</Cell>
                                    <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}><Form.Control
                                        as="select"
                                        name={"interestType_" + item.id + "_" + item.trxnId}
                                        value={item.interestType ? item.interestType : ""}
                                        onChange={this.handleRecordChange}
                                    >
                                        <option key="select" value="">
                                            --Select--
                                        </option>
                                        <option key="simple" value="Simple Interest">
                                            Simple Interest
                                        </option>
                                        <option key="compound" value="Compound Interest">
                                            Compound Interest
                                        </option>
                                    </Form.Control>
                                    </Cell>
                                    {(data["submittedOption"] != "pending") && <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.sentOn ? moment(item.sentOn).format("DD/MM/yyyy") : ""}</Cell>}
                                    {(data["submittedOption"] != "pending") && <Cell style={{ backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.trxnId}</Cell>}
                                    <Cell style={{ textAlign: "center", backgroundColor: k % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                        {currentSelectedMonth == currentMonth && (
                                            <a href="#" onClick={() => this.handleApprove("approved_" + item.id + "_" + item.trxnId)}> {(data["submittedOption"] == "pending") ? ("Approve") : "ReApprove"} </a>
                                        )}
                                    </Cell>
                                </Row>
                            );
                        })}
                    {!list?.length && (
                        <Row key="no">
                            <Cell colSpan="10">No record found</Cell>
                        </Row>
                    )}
                </StickyTable>
            </div >
            <br />
            <div style={{ width: "100%", overflowX: "scroll" }}>
                <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageChange={this.onPageChange}
                    onPagePrevious={this.onPagePrevious}
                    onPageNext={this.onPageNext}
                />
            </div>
        </React.Fragment>);
    }
}

export default PreApproval;