import React from "react";
import { Button, Card, CardColumns } from "react-bootstrap";
import * as analytics from "../../utils/analytics";

function Team(props) {
  analytics.track();

  return (
    <React.Fragment>
      <h2 style={{ paddingTop: 20 }} className="fundfinaColor">
        Key Members
      </h2>
      <CardColumns>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            CEO
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/rahul_tripathy.png")}
            className="cardsImg teamImg"
          />
          <Card.Body>
            <Card.Title className="teamHeader">Rahul Tripathy</Card.Title>
            <Card.Text className="teamText">
              Rahul is a trained navigating officer in merchant navy and MBA
              from Rotman School in Canada. He has over 10 years of
              entrepreneurial experience in financial services including
              starting and exiting two trading brokerage and wealth management
              firm.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a
              className="btn-floating btn-li mx-2"
              href="https://www.linkedin.com/in/rahultripathy/"
              target="_blank"
            >
              <i className="fa fa-linkedin linkedIn" aria-hidden="true"></i>
            </a>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            Director - Finance & Technology
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/nishant_bhaskar.png")}
            className="cardsImg teamImg"
          />
          <Card.Body>
            <Card.Title className="teamHeader">Nishant Bhaskar</Card.Title>
            <Card.Text className="teamText">
              Nishant has over 15 years experience in banking, consulting and
              technology companies. More recently he was Director at Lloyds Bank
              and UBS where he led bank’s participations in fintech innovations.
              He is MBA from Imperial College, London and B. Tech from NIT,
              India.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a
              className="btn-floating btn-li mx-2"
              href="https://www.linkedin.com/in/nbhaskar/"
              target="_blank"
            >
              <i className="fa fa-linkedin linkedIn" aria-hidden="true"></i>
            </a>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            Chief Risk and Data Officer
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/abhijit_naik.png")}
            className="cardsImg teamImg"
          />
          <Card.Body>
            <Card.Title className="teamHeader">Abhijit Naik</Card.Title>
            <Card.Text className="teamText">
              Abhijit has over 13 years of experience within credit assessment,
              financial services, and engineering. He was chief credit officer
              for Nordics at American Express and portfolio manager at Barclays
              and Nomura. He has an MBA from IIM Ahmedabad, B. Tech. from IIT
              Mumbai and CFA charter holder.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a
              className="btn-floating btn-li mx-2"
              href="https://www.linkedin.com/in/abhijit-naik-cfa-732b0a16/"
              target="_blank"
            >
              <i className="fa fa-linkedin linkedIn" aria-hidden="true"></i>
            </a>
          </Card.Footer>
        </Card>
      </CardColumns>
      <CardColumns>
        <Card>
          <Card.Header as="h5" className="teamHeader">
            Consultant - Data and Analytics
          </Card.Header>
          <Card.Img
            variant="top"
            src={require("../../images/Soni_Yatheendradas.png")}
            className="cardsImg teamImg"
          />
          <Card.Body>
            <Card.Title className="teamHeader">Soni Yatheendradas</Card.Title>
            <Card.Text className="teamText">
              Soni has 12 years of professional work experience in the
              development of statistical and mathematical models, machine
              learning, error characterization, information metrics, calibration
              algorithms and sensitivity techniques. He has obtained funding as
              a Co-Investigator for cross disciplinary projects at NASA, and
              reviewed competitive proposals for US Federal grants. He has a PhD
              & MS from The University of Arizona in USA and a B. Tech from IIT
              Bombay.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a
              className="btn-floating btn-li mx-2"
              href="https://www.linkedin.com/in/soni-yatheendradas-9812ba107/"
              target="_blank"
            >
              <i className="fa fa-linkedin linkedIn" aria-hidden="true"></i>
            </a>
          </Card.Footer>
        </Card>
      </CardColumns>
      <h2 style={{ paddingTop: 20 }} className="fundfinaColor">
        Board Advisors
      </h2>
      <CardColumns>
        <Card>
          <Card.Img
            variant="top"
            src={require("../../images/userIcon.png")}
            className="cardsImg teamImg"
          />
          <Card.Body>
            <Card.Title className="teamHeader">Sanjay Agarwal</Card.Title>
            <Card.Text className="teamText">
              Before starting Athena Capital, a FCA approved fund manager,
              Sanjay was heading asset management business for private banks of
              Fortune 500 FIs such as Goldman Sachs, Credit Agricole, Kotak
              Mahindra. He is chartered accountant and CFA charter holder.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a
              className="btn-floating btn-li mx-2"
              href="https://www.linkedin.com/in/sanjay-agarwal-3884167/"
              target="_blank"
            >
              <i className="fa fa-linkedin linkedIn" aria-hidden="true"></i>
            </a>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Img
            variant="top"
            src={require("../../images/birendra_agarwal.png")}
            className="cardsImg teamImg"
          />
          <Card.Body>
            <Card.Title className="teamHeader">Birendra Agarwal</Card.Title>
            <Card.Text className="teamText">
              Most recently Birendra was CIO for Lloyds Commercial Bank. Prior
              to that he spent 20 years as head and then MD of various market
              and wealth technologies at Nomura and Citi. He has a B. Tech. from
              IIT Mumbai and MS from University of Kentucky.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a
              className="btn-floating btn-li mx-2"
              href="https://www.linkedin.com/in/birendra/"
              target="_blank"
            >
              <i className="fa fa-linkedin linkedIn" aria-hidden="true"></i>
            </a>
          </Card.Footer>
        </Card>
      </CardColumns>
    </React.Fragment>
  );
}

export default Team;
