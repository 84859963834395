import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";
import { environment, creditApiUrl } from "../../config.json";
const CryptoJS = require("crypto-js");

axios.interceptors.response.use(response => {
  if (environment === "local" && response.data && response.data.user && response.data.user.token)
    sessionStorage.setItem("x-auth-token", response.data.user.token);

  return response;
}, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    toast.error("An unexpected error occurrred.");
  }

  return Promise.reject(error);
});

axios.interceptors.request.use(config => {
  if (environment === "local")
    config.headers["x-auth-token"] = `${sessionStorage.getItem("x-auth-token")}`;

  if (config && config.url && config.url.includes(creditApiUrl)) {
    let { signature, timestamp } = encrypt();
    config.headers["signature"] = signature;
    config.headers["timestamp"] = timestamp;
  }

  return config;
},
  error => {
    return Promise.reject(error);
  }
);

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

function encrypt() {
  let timeStamp = new Date().getTime();
  let authKey = "FundfinaCreditApiProd";
  let secretKey = "FundfinaDev@";
  let message = `timestamp:${timeStamp}auth-key:${authKey}`;

  const hMac = CryptoJS.HmacSHA256(message, secretKey).toString();
  return { signature: hMac, timestamp: timeStamp };
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};
