import React from "react";
import { Accordion, Card } from "react-bootstrap";
import * as analytics from "../../utils/analytics";

function BorrowContent(props) {

  analytics.track();

  return (
    <React.Fragment>
      <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
        Borrow
      </h2>
      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Types of loan we offer?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              We offer unsecured loans to individuals and micro and small
              enterprises. We offer overdraft facilities, term loans (monthly
              repayment of principal and interest) and/or bullet loans (monthly
              payment of interest with principal paid at the end of loan
              tenure). We offer flexibility of weekly or monthly repayments.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Applying for a loan
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              Once you are registered and approved on the platform, you can
              start a new loan application process on the platform. We will
              carry out credit and fraud checks on you using propriety Fundfina
              credit models that could involve analysing credit bureau reports
              and/or other alternate data sets you have agreed for usage.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Am I eligible to apply for a Fundfina loan?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              As per the para 4(iv) of the Master Directions issued by RBI, the
              term ’person’ on the platform shall include an individual, a body
              of individuals, a HUF, a firm, a society or any artificial body,
              whether incorporated or not. To be a borrower on Fundfina platform
              (https://alpha.Fundfina.com) you must meet the following criteria:
              <ul>
                <li>you must be a resident of India;</li>
                <li>
                  you must have and maintain a valid and regulated bank account
                  and/or Mobile wallet regulated by Reserve Bank of India to
                  receive sanctioned loan amount and facilitate repayments to
                  lenders
                </li>
                <li>
                  you must register, agree to terms of usage and submit details
                  of your KYC on our platform.
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            What are the minimum credit requirements to receive a loan?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              Two of Fundfina’s core missions are to make credit more accessible
              and affordable in India. We believe that traditional credit
              reporting coverage in India leaves out many credit worthy small
              business owners and contract workers. Hence we don’t subscribe to
              minimum credit score for borrowers as reported by credit bureaus.
              Having said that all the fundamentals of responsible lending such
              as affordability, credit discipline, regulatory requirements and
              portfolio control are held robustly.
              <ul>
                <li>
                  you must meet minimum fraud risk criteria which could include
                  obtaining reference from credit reference agencies and/or
                  Fundfina’s propriety risk models
                </li>
                <li>
                  you have not taken aggregated loans of more than Rs 10,00,000
                  at any point of time, across all peer to peer lenders in India
                </li>
                <li>
                  you must not have any outstanding court judgments of more than
                  Rs 25000
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            How is my repayment amount calculated?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              All interest rate displayed on Fundfina loans are quoted on annual
              basis (APR). Fundfina uses simple interest to calculate interest
              payments. A specific interest rate is applied to the unpaid
              principal balance through the life of the loan, but interest does
              not accrue on any unpaid interest. Interest on unpaid principal
              balance accrues daily. An EMI for a loan @@24% for 12 months will
              have an interest component of (24/12 =) 2% on the outstanding
              principal. Payments are owed in equal periodic (weekly/
              fortnightly/ monthly) installments. At the beginning of the loan,
              a larger share of the payment is applied to interest because most
              of the principal balance has not been repaid yet, so a larger
              amount of interest will accrue. As you pay down the principal, a
              smaller amount of interest will accrue each month because the
              principal balance will be less. This will allow a larger share of
              each monthly payment to repay principal over time.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="5">
            Why was my loan rejected?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              If we are not able to offer you a loan at this time, you will be
              given the main reason for rejection, and your options in case
              rejection. If your situation changes in the future, you are
              welcome to reapply. You can do so by using the same login detail
              and applying for the loan again. We advise you to wait 30 days
              before re-applying.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="6">
            Can I apply for a second loan?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>
              If you have already received a loan on Fundfina, in order to be
              eligible for another loan or top-up, you must:
              <ul>
                <li>
                  Have made on-time payments for the six previous instalment
                  amount. On-time payments means that a payment was received
                  during the grace period (no more than 7 days past due)
                </li>
                <li>
                  Have no more than 1 outstanding loan at the time of
                  application
                </li>
              </ul>
              If you have finished paying off an existing Fundfina loan and made
              on-time payments for the 6 previous instalments, you are able to
              apply for a second loan immediately.  If you have finished paying
              off an existing Fundfina loan and any of the 6 most recent payment
              instalments were not on time or you paid off the loan
              before reaching tenure period, there is a 60 day cooling off
              period before reapplying.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="7">
            Cancelling your loan application
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              If you want to cancel your loan application, please contact us as
              soon as possible. Once your loan has been issued, you won't be
              able to cancel or withdraw your application, and we can't stop the
              transfer of funds into your account. You'll then be responsible
              for repaying the loan, fees, and interest.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="8">
            What document we would ask for to verify your income?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <Card.Body>
              Typically we require you to send last Income Tax Returns.
              Additionally, we might require you to furnish 12 months bank
              statements and/or salary payslips and/or invoice statements on a
              case to case basis.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="9">
            What is the format of document?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <Card.Body>
              We would prefer that most of the (data based) electronic documents
              such as bank statements, invoices are sent in XLS or CSV format.
              However, we might accept PDF, XLS, TIFF, JPG, PNG format.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="10">
            Where should we send it?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="10">
            <Card.Body>
              In case on wishes to send additional documents, one may send it as
              attachment mentioning the loan no. in subject line to
              service@Fundfina.com In case the credit admin team has reviewed
              your loan application and returned it to your login for additional
              documents, then you may upload the documents using upload
              documents utility within loan application.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="11">
            How long will it take to get the loan funded?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="11">
            <Card.Body>
              Typically it takes 48-72 hours; however, in some cases it might
              take longer. In long overdue proposals, we might make an offer to
              you on as-funded basis
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="12">
            How long will it take the cash to reach my account once funded?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="12">
            <Card.Body>
              There are five stages of borrowing –
              <ul>
                <li>Loan application (borrower)</li>
                <li>Loan review (Fundfina)</li>
                <li>Loan terms acceptance (borrower)</li>
                <li>Loan Funding (Fundfina)</li>
                <li>Loan Contract Signing (Fundfina)</li>
              </ul>
              After the loan is fully funded, Fundfina assists a borrower with
              loan contract signing process. As soon as we have received the
              loan contract, the loan amount will reach within 24 hours.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="13">
            How do I sign and send the contract back?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="13">
            <Card.Body>
              To expedite loan monies transferred to your account, we would
              require you to send us the scanned electronic copy of the signed
              and executed stamped loan contract copy. The stamped loan contract
              will reach you through our agent directly from us or from our
              enterprise partners. Subsequently (no later than 48 hours) the
              physical copy of the stamped loan contract should be sent to us
              with a signed Post-dated cheque and with any other documents such
              as signed auto-debit/ NACH mandate. We are introducing electronic
              contract signing soon and we will separately inform you of the
              facility.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="14">
            How do I make repayments?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="14">
            <Card.Body>
              The Loan Contract with a detailed payment schedule provided to you
              before taking the loan governs the repayments. This information is
              also available to view after logging into your account on the
              platform. We will collect the repayment amounts each month or as
              otherwise set out in the Loan Contract from your nominated bank or
              mobile wallet account using direct debit or debit card repayments
              or pre-authorised payment method. Note that mobile wallet company
              might charge a fee for each repayment made using a debit card.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="15">
            What are my payment obligations?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="15">
            <Card.Body>
              Your loan is an unsecured debt obligation that requires you to
              repay full principal and accrued interest in agreed periodic
              instalments, depending on the loan term you select. You may also
              prepay your loan at any time without penalty. Failing to meet your
              monthly payment obligation may result in negative credit reporting
              to credit bureaus potentially negatively impacting your credit
              score.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="16">
            What should I do if I'm having financial difficulties, and I can't
            make my loan payment?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="16">
            <Card.Body>
              If you are experiencing hardship and cannot pay, please contact us
              immediately to agree resolution steps, including any interim
              payment arrangements.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="17">
            What happens if I am late on my payment?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="17">
            <Card.Body>
              If we do not receive your payment by your due date we will contact
              you to provide a reminder and offer assistance, if needed. If two
              consecutive periodic payments are missed, you may be charged a
              late fee of 5% of the unpaid amount or ₹550, whichever is greater.
              We may choose to waive or reduce the above stated fees from time
              to time. If you are more than 90 days late then we will report
              your delinquency to a credit reporting agency. Upon an Event of
              Default, we may exercise all the remedies available to us under
              applicable law as per loan contract. If you think you are going to
              be late on any payment, please contact us immediately through
              General Support.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="18">
            Does Fundfina charge a prepayment fee or penalty?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="18">
            <Card.Body>
              You may repay an entire loan early at any time at no extra cost
              provided you pay the principal amount outstanding together with
              the interest due until the end of the month in which the loan is
              so repaid.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="19">
            Do you report late borrower's accounts to credit bureaus?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="19">
            <Card.Body>
              You may repay an entire loan early at any time at no extra cost
              provided you pay the principal amount outstanding together with
              the interest due until the end of the month in which the loan is
              so repaid.Yes. If you a borrower is more than 90 days late on any
              payment, we will report those delinquencies to credit bureau or
              any other regulator mandated bodies.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="20">
            Can’t find what I am looking for or I want to complaint?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="20">
            <Card.Body>Please contact us</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="21">
            Are there fees associated with borrowing?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="21">
            <Card.Body>
              Yes. Fundfina charges 3% of principal amount borrowed.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </React.Fragment>
  );
}

export default BorrowContent;
