import React from "react";
import auth from "../../services/common/authService";
import { Form, Col, InputGroup, Button, Nav, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect, Link } from "react-router-dom";
import RegisterFormModel from "../../models/login/registerFormModel";
import Tnc from "../companyInfo/tnc";
import PrivacyPolicy from "./../companyInfo/privacyPolicy";
// import OtpInput from "react-otp-input";

// Render the Calendar

class RegisterForm extends RegisterFormModel {
  render() {
    // if (auth.getCurrentUser()) return <Redirect to="/" />;
    const {
      data,
      errors,
      info,
      showSpinner,
      isValidMobile,
      isValidOTP,
      isRegenerateText,
      isRegenerateLink,
      showTnc,
      tnc,
      counter,
      showPrivacyPolicy,
    } = this.state;
    const tncLabel = (
      <React.Fragment>
        I have read and agree to{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewTnc}
        >
          Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewPrivacyPolicy}
        >
          Privacy Policy
        </a>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 style={{ textAlign: "center" }}>Registration</h2> <br />
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="firstName">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your first name"
                name="firstName"
                value={data["firstName"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                autoFocus={true}
              />
              {errors["firstName"] && (
                <div className="alert alert-danger">{errors["firstName"]}</div>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              md="4"
              controlId="middleName"
              style={{ display: "none" }}
            >
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your middle name"
                name="middleName"
                value={data["middleName"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
              {errors["middleName"] && (
                <div className="alert alert-danger">{errors["middleName"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="lastName">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your last name"
                name="lastName"
                value={data["lastName"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
              {errors["lastName"] && (
                <div className="alert alert-danger">{errors["lastName"]}</div>
              )}
            </Form.Group>{" "}
          </Form.Row>
          <Form.Row>
            {/* <Form.Group
              as={Col}
              md="4"
              controlId="userName"
              style={{ display: "none" }}
            >
              <Form.Label>Username*</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Enter your username"
                  aria-describedby="inputGroupPrepend"
                  name="userName"
                  value={data["userName"]}
                  onChange={this.handleChange}
                  onBlur={this.handleUserNameChange}
                  autoComplete="off"
                />
              </InputGroup>
              {errors["userName"] && (
                <div className="alert alert-danger">{errors["userName"]}</div>
              )}
              {info["userName"] && (
                <div className="alert alert-success">{info["userName"]}</div>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              md="4"
              controlId="dob"
              style={{ display: "none" }}
            >
              <Form.Label>Date of Birth</Form.Label>
              <DatePicker
                name="dob"
                id="dob"
                value={data["dob"]}
                max={moment()}
                selectedMonth={moment().subtract(100, "years").year}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleDobChange(value)}
                placeholder="Enter your date of birth"
              />
              {errors["dob"] && (
                <div className="alert alert-danger">{errors["dob"]}</div>
              )}
            </Form.Group>{" "} */}{" "}
            <Form.Group as={Col} md="4" controlId="email">
              {" "}
              <Form.Label>Email Id*</Form.Label>{" "}
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Enter your email address"
                  name="email"
                  value={data["email"]}
                  onChange={this.handleChange}
                  onBlur={this.handleEmailChange}
                  autoComplete="off"
                  maxLength="100"
                />{" "}
              </InputGroup>
              {errors["email"] && (
                <div className="alert alert-danger">{errors["email"]}</div>
              )}
              {info["email"] && (
                <div className="alert alert-success">{info["email"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No*</Form.Label>
              <Form.Control
                type="number"
                pattern="[0-9]*"
                placeholder="Enter your mobile number"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                onBlur={this.handleMobileChange}
                autoComplete="off"
                maxLength={10}
                disabled={isValidMobile}
              />
              {errors["mobileNo"] && (
                <div className="alert alert-danger">{errors["mobileNo"]}</div>
              )}
              {info["mobileNo"] && (
                <div className="alert alert-success">{info["mobileNo"]}</div>
              )}
            </Form.Group>
            {isValidMobile && (
              <Form.Group as={Col} md="4" controlId="otp">
                <Form.Label>OTP*</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your OTP"
                  name="otp"
                  value={data["otp"]}
                  onChange={this.handleChange}
                  disabled={isValidOTP}
                  onKeyUp={this.handleOTPChange}
                  autoFocus={isValidMobile}
                  maxLength={6}
                />
                {/* <div>
                  <OtpInput
                    onChange={this.handleOtpChange}
                    numInputs={6}
                    value={data["otp"]}
                    separator={<span style={{ padding: 10 }}>-</span>}
                    inputStyle="form-control"
                    isDisabled={isValidOTP}
                    isInputNum={true}
                    onKeyUp={this.handleOTPChange}
                  />
                </div> */}
                {errors["otp"] && (
                  <div className="alert alert-danger">{errors["otp"]}</div>
                )}
                {info["otp"] && (
                  <div className="alert alert-success">{info["otp"]}</div>
                )}
                {isRegenerateText && (
                  <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                )}
                {isRegenerateLink && (
                  <Nav.Item id="regenerateOTP">
                    <Nav.Link
                      className="underline"
                      onClick={this.handleRegenerateOTP}
                      style={{
                        padding: 0,
                        float: "right",
                        textDecoration: "underline",
                      }}
                    >
                      Re-generate OTP
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="password">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="password"
                value={data["password"]}
                onChange={this.handleChange}
                onBlur={this.onBlurPassword}
                autoComplete="off"
                autoFocus={isValidOTP}
              />
              {errors["password"] && (
                <div className="alert alert-danger">{errors["password"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="confirmPassword">
              <Form.Label>Re-enter Password*</Form.Label>
              <Form.Control
                type="password"
                placeholder="Re-enter password"
                name="confirmPassword"
                value={data["confirmPassword"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
              {errors["confirmPassword"] && (
                <div className="alert alert-danger">
                  {errors["confirmPassword"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="tnc">
            <Form.Check
              type="checkbox"
              label={tncLabel}
              feedback="You must agree before submitting."
              checked={tnc}
              onChange={() => this.handleTnc(tnc)}
              color="red"
            />{" "}
            {errors["tnc"] && (
              <div className="alert alert-danger">{errors["tnc"]}</div>
            )}
          </Form.Group>{" "}
          <Button
            className="ff-button"
            type="submit"
            disabled={data.isSubmitted}
          >
            Submit
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
          <p>
            <b>Are you a Partner?</b>
            <Link
              to="/partnerRegistration"
              style={{ textDecoration: "underline" }}
            >
              {" "}
              Click here to Register
            </Link>
          </p>
        </Form>
        <Modal
          size="lg"
          show={showTnc}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <Tnc />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={showPrivacyPolicy}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <PrivacyPolicy />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RegisterForm;
