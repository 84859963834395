import React from "react";
import _ from "lodash";
import * as userCentricDataService from "../../services/userCentricData/userCentricDataService";

class ShopTypeModel extends React.Component {
    state = {
        userData: {},
        isValidUser: false,
        shopTypeDataExists: false,
        showSpinner: true,
        isSubmitted: false,
        errors: {},
        info: {},

    };

    async componentDidMount() {

        let userData = { ...this.state.userData };

        if (this.props.location.state && this.props.location.state.userData) {
            const model = this.props.location.state.userData;
            const { merchantCode, partnerCode } = model;

            const isMerchantPartnerMappingValid = await this.validateMerchantPartnerMapping(merchantCode, partnerCode);

            if (!isMerchantPartnerMappingValid) {
                this.setState({ isValidUser: false, showSpinner: false });
                return;
            }
            userData = { merchantCode, partnerCode, shopType: '' }

            const doesShopDataExist = await this.checkIfShopDataExists(merchantCode, partnerCode);
            console.log(doesShopDataExist)


            this.setState({ userData, isValidUser: true, shopTypeDataExists: doesShopDataExist });
        }
        this.setState({ showSpinner: false });

    }

    handleChange({ currentTarget: input }) {
        const userData = { ...this.state.userData };

        userData[input['name']] = input['value'];

        this.setState({ userData });

    }

    async handleCustomErrors() {
        const userData = { ...this.state.userData };
        let errors = { ...this.state.errors };

        // const { otpAutoFill, otpType } = this.state;

        console.log(!userData['shopType'])

        // delete errors["globalError"];
        delete errors["shopTypeError"];

        if (!userData['shopType']) {
            errors["shopTypeError"] = "Please select Shop Type";
        }
        else delete errors["shopTypeError"];

        this.setState({ errors });

        return errors;
    }

    async validateMerchantPartnerMapping(merchantCode, partnerCode) {
        const data = { merchantCode, partnerCode }

        const response = await userCentricDataService.validateMerchantPartnerMapping(data);

        return response.success;
    }

    async checkIfShopDataExists(merchantCode, partnerCode) {
        const data = { merchantCode, partnerCode }

        const response = await userCentricDataService.checkIfShopDataExists(data);

        return response.success;
    }

    async doSubmit(e) {
        let { userData, isSubmitted } = this.state;

        e.preventDefault();
        let errors = await this.handleCustomErrors();

        if (_.isEmpty(errors)) {
            const response = await userCentricDataService.submitMerchantShopTypeData(userData);

            if (response.success) {
                // errors["globalError"] = "";
                errors["shopTypeError"] = "";
                isSubmitted = true
                // this.getProductMasterData();
                this.setState({
                    errors, isSubmitted
                });
            } else {
                errors["shopTypeError"] = response.message;
                console.log("err", errors["shopTypeError"]);
                isSubmitted = false;
                this.setState({
                    errors, isSubmitted
                });
                console.log("err", errors)
            }
        }
    };

};

export default ShopTypeModel;
