import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi, { errors, schema } from "joi-browser";
import _ from "lodash";
import * as trancheService from "../../services/tranche/trancheService";

class TrancheUpdateModel extends InputDataForm {
    state = {
        data: {
            _id: "",
            disbursedAmount: "",
            insertedOn: "",
            modifiedOn: "",
            trancheId: "",
            trancheNo: "",
            no: "",
            partnerId: "",
            amount: "",
            transactionDate: momentJalaali(),
            depositDate: momentJalaali(),
            fldgAmount: "",
            expiryDate: momentJalaali(),
            partner: "",
            partnerCode: "",
            fldgPer: "",
            isOpen: true,
        },
        showSpinner: false,
        errors: {},
        info: {}
    };

    schema = {
        _id: Joi.any().required(),
        disbursedAmount: Joi.any().required(),
        insertedOn: Joi.any().required(),
        modifiedOn: Joi.any().required(),
        trancheId: Joi.any().required(),
        trancheNo: Joi.any().required(),
        no: Joi.any().required(),
        partnerId: Joi.any().required(),
        amount: Joi.number().required().min(0),
        transactionDate: Joi.any().required(),
        depositDate: Joi.any().required(),
        fldgAmount: Joi.any().required(),
        expiryDate: Joi.any().required(),
        partner: Joi.any().required(),
        partnerCode: Joi.any().required(),
        fldgPer: Joi.any().required(),
        isOpen: Joi.any().required(),
    };

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();
        if (currentUser.role != "Admin") return (window.location = "/");
        else {
            //console.log(this.props.match.params);
            const trancheId = this.props.match.params.id;
            await this.getTrancheDetails(trancheId);
        }
    };

    async getTrancheDetails(trancheId) {
        //console.log("trancheId is ", trancheId);
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        try {
            errors["globalError"] = null;
            const trancheDetails = await trancheService.getTrancheDetails(trancheId);
            if (trancheDetails) {
                if (trancheDetails.success) {
                    data = trancheDetails.data[0];
                    data.transactionDate = data.transactionDate ? momentJalaali(data.transactionDate) : momentJalaali();
                    data.depositDate = data.depositDate ? momentJalaali(data.depositDate) : momentJalaali();
                    this.setState({ showSpinner: false, data, successMsg: trancheDetails.message });
                }
                else {
                    errors["globalError"] = trancheDetails.message;
                    this.setState({ showSpinner: false, errors, successMsg: null });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ showSpinner: false, errors, successMsg: null });
            }

            //this.setState({ showSpinner: false, });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null });
        }
    }

    async updateTrancheDetails() {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            const model = {
                trancheId: data.trancheId,
                amount: data.amount,
                fldgAmount: data.fldgAmount,
                fldgPer: data.fldgPer,
                isOpen: data.isOpen,
                transactionDate: data.transactionDate,
                depositDate: data.depositDate
            }

            const response = await trancheService.saveTranch(model);
            if (response.success) {
                this.setState({
                    data,
                    successMsg: "Saved Successfully",
                    showSpinner: false,
                    isSubmitted: false,
                });
            } else {
                errors["globalError"] = response.message;
                this.setState({
                    errors,
                    successMsg: "",
                    showSpinner: false,
                    isSubmitted: false,
                });
            }

            this.setState({
                data,
                successMsg: "Saved Successfully",
                showSpinner: false,
                isSubmitted: false,
            });
        } catch (ex) {
            errors["globalError"] = ex.response.data.message;
            this.setState({
                showSpinner: false,
                isSubmitted: false,
                errors,
                successMsg: "",
            });
        }
    }

    handleTransactionDateChange = (value) => {
        const { data } = this.state;
        //alert(value);
        data.transactionDate = value;
        this.setState({ data });
    };

    handleDepositDateChange = (value) => {
        const { data } = this.state;

        data.depositDate = value;
        this.setState({ data });
    };

    goBack = () => {
        window.location.href = "/tranche";
    };



    async handleCustomErrors(errors) {
        delete errors["globalError"];
        this.setState({ errors, successMsg: "" });
        return errors;
    }

    doSubmit = async () => {
        await this.updateTrancheDetails();
    };
}
export default TrancheUpdateModel;