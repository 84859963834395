import React from "react";
import auth from "../../services/common/authService";
import { Form, Col, Button, Table } from "react-bootstrap";
import SpinnerComponent from "./../../components/common/spinner";
import _ from "lodash";
import CreditLineModel from './../../models/partner/creditLineModel';
import NumberFormat from 'react-number-format';
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import { isMobile } from 'react-device-detect';
import moment from "moment";

class CreditLine extends CreditLineModel {
    getPagedData = () => {
        const { pageSize, currentPage, loanList } = this.state;

        let loans = loanList;

        const filteredLoans = paginate(loans, currentPage, pageSize);

        return { totalCount: loans.length, data: filteredLoans };
    };
    render() {
        const {
            data,
            errors,
            showSpinner,
            isSubmitted,
            currentPage,
            pageSize,
            approvedCreditLimit,
            availableCreditLimit,
            isSigned,
            isValidForSigning,
            successMessage,
            errorMessage
        } = this.state;

        const { totalCount, data: loans } = this.getPagedData();

        if (!auth.getCurrentUser()) return (window.location = "/login");

        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <Form
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                >
                    <Form.Row>
                        <h5>New Loan</h5>{" "}
                    </Form.Row>
                    <Form.Row>
                        <Form.Label column sm="4">
                            Approved Credit Limit:{" "}
                            <span style={{ color: "black" }}>{approvedCreditLimit && <NumberFormat value={approvedCreditLimit} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</span>
                        </Form.Label>

                        <Form.Label column sm="4">
                        </Form.Label>
                        <Form.Label column sm="4" style={{ textAlign: isMobile ? null : "right" }}>
                            Available Credit Limit:{" "}
                            <span style={{ color: "black" }}>
                                {availableCreditLimit && <NumberFormat value={availableCreditLimit} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}
                            </span>
                        </Form.Label>
                    </Form.Row>
                    <br />
                    <Form.Row>
                        <Form.Group
                            as={Col}
                            md="3"
                            controlId="date"
                        >
                            <Form.Label>Date*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the date"
                                name="date"
                                value={moment(data["date"]).format("DD/MM/yyyy")}
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={true}
                            />
                            {errors["date"] && (
                                <div className="alert alert-danger">{errors["date"]}</div>
                            )}
                        </Form.Group>{" "}
                        <Form.Group as={Col} md="3" controlId="invoiceId">
                            <Form.Label>Invoice Id*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your Invoice Id"
                                name="invoiceId"
                                value={data["invoiceId"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={true}

                            />
                            {errors["invoiceId"] && (
                                <div className="alert alert-danger">{errors["invoiceId"]}</div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="amount">
                            <Form.Label>Invoice Amount*</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter invoice amount"
                                name="amount"
                                value={data["amount"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={isSigned}
                            />
                            {errors["amount"] && (
                                <div className="alert alert-danger">{errors["amount"]}</div>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="agentName">
                            <Form.Label>Delivery Agent Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter delivery agent name"
                                name="agentName"
                                value={data["agentName"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={isSigned}
                            />
                            {errors["agentName"] && (
                                <div className="alert alert-danger">{errors["agentName"]}</div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="agentNumber">
                            <Form.Label>Delivery Agent Mobile Number</Form.Label>
                            <Form.Control
                                type="text"
                                pattern="[0-9]*"
                                placeholder="Enter delivery mobile number"
                                name="agentNumber"
                                value={data["agentNumber"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="10"
                                disabled={isSigned}
                            />
                            {errors["agentNumber"] && (
                                <div className="alert alert-danger">{errors["agentNumber"]}</div>
                            )}
                        </Form.Group>
                    </Form.Row>
                    {!isSigned && isValidForSigning && (
                        <Button
                            className="ff-button"
                            type="submit"
                            disabled={isSubmitted}
                        >
                            Submit
                        </Button>)}
                    {errors["globalError"] && (
                        <div className="alert alert-danger">{errors["globalError"]}</div>
                    )}
                </Form>
                {successMessage && (
                    <div className="alert alert-success">{successMessage}</div>
                )}
                {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                )}
                {loans && loans.length > 0 && (
                    <React.Fragment>
                        <hr />
                        <h5>Existing Loans</h5>{" "}
                        {!isMobile && (
                            <React.Fragment>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Invoice Id</th>
                                            <th>Invoice Amount</th>
                                            <th>Payment Due On</th>
                                            <th>Payment Collected On</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loans &&
                                            loans.map((loan, i) => (
                                                <tr key={`desktop-${i}`}>
                                                    <td>{moment(loan.insertedOn).format("DD/MM/yyyy")}</td>
                                                    <td>{loan.invoiceId}</td>
                                                    <td>
                                                        <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                                    </td>
                                                    <td>
                                                        {loan.paymentDueOn && moment(loan.paymentDueOn).format("DD/MM/yyyy")}
                                                    </td>
                                                    <td>
                                                        {loan.paymentCollectedOn && moment(loan.paymentCollectedOn).format("DD/MM/yyyy")}
                                                    </td>
                                                    <td>{loan.status}</td>
                                                </tr>
                                            ))}
                                        {loans.length == 0 && (
                                            <tr key="no">
                                                <td colSpan="6">No record found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <div style={{ width: "100%" }}>
                                    <Pagination
                                        itemsCount={totalCount}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        onPageChange={this.handlePageChange}
                                        onPagePrevious={this.handlePagePrevious}
                                        onPageNext={this.handlePageNext}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                        {isMobile && (
                            <div style={{ maxHeight: 600, overflowY: "scroll" }}>
                                <Table responsive striped bordered hover>

                                    {loans &&
                                        loans.map((loanItem, i) => (
                                            <tbody key={`mobileRow-${i}`}>
                                                {i > 0 && <tr><td colSpan="2" className="trBreakColor"></td></tr>}
                                                <tr>
                                                    <td style={{ width: "50%" }}><b>Date</b></td>
                                                    <td style={{ width: "50%" }}>{loanItem.insertedOn && moment(loanItem.insertedOn).format("DD/MM/yyyy")}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "50%" }}><b>Invoice Id</b></td>
                                                    <td style={{ width: "50%" }}>{loanItem.invoiceId}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "50%" }}><b>Amount</b></td>
                                                    <td style={{ width: "50%" }}><NumberFormat value={loanItem.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "50%" }}><b>Payment Due On</b></td>
                                                    <td style={{ width: "50%" }}> {loanItem.paymentDueOn && moment(loanItem.paymentDueOn).format("DD/MM/yyyy")}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "50%" }}><b>Payment Collected On</b></td>
                                                    <td style={{ width: "50%" }}>{loanItem.paymentCollectedOn && moment(loanItem.paymentCollectedOn).format("DD/MM/yyyy")}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: "50%" }}><b>Status</b></td>
                                                    <td style={{ width: "50%" }}>{loanItem.status}</td>
                                                </tr>
                                            </tbody>
                                        ))}
                                </Table>
                            </div>)}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
export default CreditLine;
