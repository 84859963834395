import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ScrutinyUserTableModel from "../../models/scrutiny/scrutinyUserTableModel";
import { Form, Col, Button, Table } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";

class ScrutinyUserTable extends ScrutinyUserTableModel {
  getPagedData = () => {
    const { pageSize, currentPage, data } = this.state;

    let users = data.users;

    const filteredUsers = paginate(users, currentPage, pageSize);

    return { totalCount: users.length, data: filteredUsers };
  };
  render() {
    const { pageSize, currentPage, data, showSpinner, evaluatorRole, partners, successMsg, errors } = this.state;

    const { totalCount, data: users } = this.getPagedData();

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <DatePicker
                name="fromDate"
                id="fromDate"
                value={data["fromDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleFromDateChange(value)}
                placeholder="Enter From Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <DatePicker
                name="toDate"
                id="toDate"
                value={data["toDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleToDateChange(value)}
                placeholder="Enter To Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No</Form.Label>
              <Form.Control
                type="text"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="panNo">
              <Form.Label>PAN No</Form.Label>
              <Form.Control
                type="text"
                aria-describedby="inputGroupPrepend"
                name="panNo"
                value={data["panNo"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="poaNo">
              <Form.Label>POA No</Form.Label>
              <Form.Control
                type="text"
                name="poaNo"
                value={data["poaNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={data["name"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="merchantCode">
              <Form.Label>Merchant Code</Form.Label>
              <Form.Control
                type="text"
                name="merchantCode"
                value={data["merchantCode"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="partner">
              <Form.Label>Partner</Form.Label>
              <Form.Control
                as="select"
                name="partner"
                value={data["partner"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="all" value="all">
                  ALL
                </option>
                {partners.map((partner) => (
                  <option key={partner.partnerName} value={partner.partnerName}>
                    {partner.displayName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="kycStatus">
              <Form.Label>Kyc Status</Form.Label>
              <Form.Control
                as="select"
                name="kycStatus"
                value={data["kycStatus"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="under review" value="Under Review">
                  Under Review
                </option>
                <option key="approved" value="Approved">
                  Approved
                </option>
                <option key="rejected" value="Rejected">
                  Rejected
                </option>
                <option key="review" value="Review">
                  Review
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Button
                className="ff-button"
                type="submit"
                style={{ marginTop: 30, marginLeft: 10 }}
              >
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}
        <br />
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>PAN No</th>
              <th>Mobile No</th>
              <th>Email Id</th>
              <th>Client Code</th>
              <th>Merchant Code</th>
              <th>Application Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, i) => (
                <tr key={`user-${i}`}>
                  <td>{moment(user.insertedOn).format("DD/MM/yyyy")}</td>
                  <td>{user.name} </td>
                  <td>{evaluatorRole === "Admin" && user.panNo ? user.panNo : ""}
                    {evaluatorRole !== "Admin" && user.panNo ? user.panNo.substr(0, 3) + "XXXXXXX" : ""}</td>
                  <td>
                    {user.mobileNo[0]
                      ? user.mobileNo[0].mobileNumber
                      : ""}
                  </td>
                  <td>
                    {user.emailId[0]
                      ? user.emailId[0]
                      : ""}
                  </td>
                  <td>
                    {user.clientCode}
                  </td>
                  <td>{user.merchantCode}</td>
                  <td>
                    <Button
                      onClick={() => this.handleSendReview(user.lastUpdatedByLoanNo)}
                      className="ff-button"
                      disabled={user.kycStatus == "Review" ? false : true}
                    >
                      Resend
                    </Button>
                  </td>
                  <td>
                    <Link to={`/scrutinyInfoRedirect/${user._id}/${false}/${user.partnerId}/${user.merchantCode}`}>View</Link>
                  </td>
                </tr>
              ))}
            {!users && (
              <tr key="no">
                <td colSpan="10">No record found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPagePrevious={this.handlePagePrevious}
            onPageNext={this.handlePageNext}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ScrutinyUserTable;
