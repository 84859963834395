import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PortfolioSummaryModel from "../../models/portfolio/portfolioSummaryModel";
import { Form, Col, Button, Table, Row, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import LoanInfoForm from "../loan/loanInfoForm"
import NumberFormat from 'react-number-format';

class PortfolioSummary extends PortfolioSummaryModel {
  render() {
    const {
      data,
      showSpinner,
      loanList,
      showRepaymentModal,
      loanId,
      summary,
      sortBy,
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 style={{ textAlign: "center" }}>Portfolio Summary</h2>{" "}
        <React.Fragment>
          <Form style={{ display: "none" }}>
            <h5
              className="fundfinaColor"
              style={{ textDecoration: "underline" }}
            >
              Summarized Data
            </h5>{" "}
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Total Lifetime Investment:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.totalLifeTimeInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>
              <Form.Label column sm="4">
                Payout Amount:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.payout} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>{" "}
              <Form.Label column sm="4">
                Active Investment:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.activeInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>
            </Form.Group>{" "}
            <Form.Group as={Row} controlId="name">
              <Form.Label column sm="4">
                Current Investment:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.currentInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>
              <Form.Label column sm="4">
                Principal Recovered:{" "}
                <span style={{ color: "black" }}>
                  <NumberFormat value={summary.totalPrincipleRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>{" "}
              <Form.Label column sm="4">
                Interest Recovered:{" "}
                <span style={{ color: "black" }}>
                  <NumberFormat value={summary.totalInterestRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Amount in Virtual Account:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.totalAmtInVA} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>
              <Form.Label column sm="4">
                Delayed Payment 30 days:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.repaymentDelay30Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>{" "}
              <Form.Label column sm="4">
                Delayed Payment 60 days:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.repaymentDelay60Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>
            </Form.Group>{" "}
            <Form.Group as={Row} controlId="name">
              <Form.Label column sm="4">
                Delayed Payment 90 days:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.repaymentDelay90Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>
              <Form.Label column sm="4">
                Delayed beyond 90 days:{" "}
                <span style={{ color: "black" }}>
                  {" "}
                  <NumberFormat value={summary.repaymentDelay120Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                </span>
              </Form.Label>{" "}
              <Form.Label column sm="4">
                Return on Investment:{" "}
                <span style={{ color: "black" }}>
                  {summary.roi}
                  {summary.roi && (
                    <i className="fa fa-percent" aria-hidden="true"></i>
                  )}
                </span>
              </Form.Label>
            </Form.Group>{" "}
          </Form>{" "}
          <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
            Portfolio (Loan-wise)
          </h5>{" "}
          <Form onSubmit={this.submitPortfolio}>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={data["name"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>{" "}
              <Form.Group as={Col} md="4" controlId="loanNo">
                <Form.Label>Loan No.</Form.Label>
                <Form.Control
                  type="text"
                  name="loanNo"
                  value={data["loanNo"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>{" "}
              <Form.Group as={Col} md="4">
                <Button
                  className="ff-button"
                  type="submit"
                  style={{ marginTop: 30, marginLeft: 10 }}
                >
                  Apply Filters
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </React.Fragment>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                Loan No.{"  "}
                {sortBy == "loanNo" && (
                  <i
                    className="fa fa-sort-asc"
                    aria-hidden="true"
                    onClick={this.sortByLoanNo}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
                {sortBy == "-loanNo" && (
                  <i
                    className="fa fa-sort-desc"
                    aria-hidden="true"
                    onClick={this.sortByLoanNo}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
                {sortBy != "-loanNo" && sortBy != "loanNo" && (
                  <i
                    className="fa fa-sort"
                    aria-hidden="true"
                    onClick={this.sortByLoanNo}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
              </th>
              <th>
                Name{" "}
                {sortBy == "userDetails.firstName" && (
                  <i
                    className="fa fa-sort-asc"
                    aria-hidden="true"
                    onClick={this.sortByName}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
                {sortBy == "-userDetails.firstName" && (
                  <i
                    className="fa fa-sort-desc"
                    aria-hidden="true"
                    onClick={this.sortByName}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
                {sortBy != "-userDetails.firstName" &&
                  sortBy != "userDetails.firstName" && (
                    <i
                      className="fa fa-sort"
                      aria-hidden="true"
                      onClick={this.sortByName}
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
              </th>
              <th style={{ minWidth: 100 }}>
                Amount{" "}
                {sortBy == "amount" && (
                  <i
                    className="fa fa-sort-asc"
                    aria-hidden="true"
                    onClick={this.sortByAmount}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
                {sortBy == "-amount" && (
                  <i
                    className="fa fa-sort-desc"
                    aria-hidden="true"
                    onClick={this.sortByAmount}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
                {sortBy != "-amount" && sortBy != "amount" && (
                  <i
                    className="fa fa-sort"
                    aria-hidden="true"
                    onClick={this.sortByAmount}
                    style={{ cursor: "pointer" }}
                  ></i>
                )}
              </th>
              <th style={{ minWidth: 100 }}>Amount as on date</th>
              <th>Principal Recovered</th>
              <th>Interest Recovered</th>
            </tr>
          </thead>
          <tbody>
            {loanList &&
              loanList.map((loan) => (
                <tr key={loan._id}>
                  <td>
                    <a
                      key={loan._id}
                      href="#"
                      onClick={() => this.getRepaymentDetails(loan._id)}
                    >
                      {loan.loanNo}
                    </a>
                  </td>
                  <td>
                    {loan.name}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.currentInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.principalRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.interestRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                </tr>
              ))}
            {!loanList ||
              (loanList.length == 0 && (
                <tr key="no">
                  <td colSpan="10">No record found</td>
                </tr>
              ))}
          </tbody>
        </Table>{" "}
        <Modal
          show={showRepaymentModal}
          onHide={this.closeModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="example-modal-sizes-title-xl"
          centered
          style={{ width: "auto !important" }}
        >

          <Modal.Header closeButton>
            <div className="col-md-10">
            </div>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 600, overflow: "scroll" }}>
            <LoanInfoForm loanId={loanId} activeTab="schedule" />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PortfolioSummary;
