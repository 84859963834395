import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import DisbursalTransactionsModel from "../../models/disbursalTransactions/disbursalTransactions";
import { Form, Col, Button, Modal, ListGroup, Nav, FormCheck, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import NumberFormat from 'react-number-format';
import moment from "moment";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/pagination";


class DisbursalTransactions extends DisbursalTransactionsModel {
  render() {
    const {
      data,
      showSpinner,
      transactionList,
      itemsCount,
      pageSize,
      currentPage,
      successMsg,
      errors
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2
          className="fundfinaColor"
          style={{ textDecoration: "underline", textAlign: "center" }}>
          Loan Transaction Summary
        </h2>
        <br />
        <br />
        <React.Fragment>
          <Form onSubmit={this.submitPortfolio}>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="loanNo">
                <Form.Label>Loan No.</Form.Label>
                <Form.Control
                  type="text"
                  name="loanNo"
                  value={data["loanNo"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>{" "}
              <Form.Group as={Col} md="4" controlId="fromDate">
                <Form.Label>From Date</Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="status">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={data["status"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="ALL" value="ALL">
                    ALL
                  </option>
                  <option key="Initiated" value="Initiated">
                    Initiated
                  </option>
                  <option key="Processing" value="Processing">
                    Processing
                  </option>
                  <option key="Hold" value="Hold">
                    Hold
                  </option>
                  <option key="Payment Completed" value="Payment Completed">
                    Payment Completed
                  </option>
                  <option key="Completed" value="Completed">
                    Completed
                  </option>

                </Form.Control>
              </Form.Group>{" "}

              <Form.Group as={Col} md="3" controlId="type">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={data["type"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="ALL" value="ALL">
                    ALL
                  </option>
                  <option key="LOAN" value="LOAN">
                    Loan
                  </option>
                  <option key="CHARGES" value="CHARGES">
                    Charges
                  </option>

                </Form.Control>
              </Form.Group>{" "}

              <Form.Group as={Col} md="4">
                <Button
                  className="ff-button"
                  type="submit"
                  style={{ marginTop: 30, marginLeft: 10 }}
                >
                  Apply Filters
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </React.Fragment>

        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}
        <br></br>
        <div className="text-right"> <b>Total:</b> {itemsCount} </div>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>
                Loan No.{"  "}
              </th>
              <th>
                Amount{" "}
              </th>
              <th>
                Customer Ref No{" "}
              </th>
              <th>
                Partner{" "}
              </th>
              <th>
                Lender Client Code{" "}
              </th>
              <th>
                Beneficiary Name{" "}
              </th>
              <th>
                Status {" "}
              </th>
              <th>
                Type {" "}
              </th>
              <th>
                Date {" "}
              </th>
              <th>
                Actions {" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionList &&
              transactionList.map((transaction) => (
                <tr key={transaction._id}>
                  <td>
                    {transaction.loanNo}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={transaction.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td>
                    {transaction.customerRefNo}
                  </td>
                  <td>
                    {transaction.partner}
                  </td>
                  <td>
                    {transaction.lenderClientCode}
                  </td>
                  <td>
                    {transaction.beneficiaryName}
                  </td>
                  <td>
                    {transaction.disbursalStatus}
                  </td>
                  <td>
                    {transaction.disbursalType}
                  </td>
                  <td>
                    {transaction.insertedOn && moment(transaction.insertedOn).format("DD/MM/yyyy")}
                  </td>

                  <td>
                    {transaction.disbursalStatus == "Hold" && transaction.disbursalType != "LOAN" ? (
                      <Button
                        onClick={() => this.showReInitiateModel(transaction)}
                        className="ff-button"
                      >
                        ReInitiate
                      </Button>
                    ) : (
                      ""
                    )}
                  </td>




                </tr>
              ))}
            {!transactionList ||
              (transactionList.length == 0 && (
                <tr key="no">
                  <td colSpan="10">No record found</td>
                </tr>
              ))}
          </tbody>
        </Table>{" "}

        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            itemsCount={itemsCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handleTransactionPageChange}
            onPagePrevious={this.handleTransactionPagePrevious}
            onPageNext={this.handleTransactionPageNext}
            showAllPages={false}
          />
        </div>

        <Modal
          show={this.state.showConfirmation}
          onHide={this.toggleConfirmationModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>Are you sure?</b>
          </Modal.Header>
          <Modal.Body>
            Do you want to Re-Initiate the transaction?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => { this.handleReInitiate() }} className="ff-button">
              Yes
            </Button>
            <Button onClick={this.toggleConfirmationModal} className="ff-button">
              No
            </Button>
          </Modal.Footer>
        </Modal>

      </React.Fragment>
    );
  }
}

export default DisbursalTransactions;
