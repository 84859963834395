
import InputDataForm from "../../components/common/form";
import * as reconService from "../../services/recon/reconService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import { toast } from "react-toastify";

class getSettlementAlert extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      channel: "EMAIL",
      utrNo: "",
      vaAccount: "",
      amount: ""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    partners: [],
    headerTabs: [
      { title: "Email", value: "EMAIL" },
      { title: "ENach", value: "ENACH" }
    ],
    headerActiveTab: "EMAIL"
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    page: Joi.any(),
    settlementList: Joi.any(),
    channel: Joi.any(),
    utrNo : Joi.any(),
    vaAccount : Joi.any(),
    amount : Joi.any()
  };
  async getSettlementAlert() {

    this.state.data.page = this.state.currentPage

    const data = { ...this.state.data };

    let daysDiff = commonService.numberOfDaysDifferece(new Date(data.fromDate), new Date(data.toDate));

    if (daysDiff > 60) {
      toast.error("Max Date Range 60 days");
      this.setState({ showSpinner: false });
      return
    }

    this.setState({ showSpinner: true });
    try {
      const { data: settlementList, totalPages } = await reconService.settlementAlert(data);

      data.settlementList = settlementList;

      this.setState({ data, showSpinner: false, pageSize: totalPages || this.state.pageSize });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async componentDidMount() {
    await this.getSettlementAlert();
  }

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getSettlementAlert();
  };

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getSettlementAlert()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getSettlementAlert()
    })
  }

  onChangeHeaderActiveTab = (tab) => {
    this.state.data.channel = tab
    this.setState({ headerActiveTab: tab }, () => {
      this.getSettlementAlert()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getSettlementAlert()
    })
  }
}

export default getSettlementAlert;
