import * as commonService from "../../services/common/commonService";
import * as questionairreFormService from "../../services/borrow/questionairreFormService";
import Joi from "joi-browser";
import ApplyLoanModel from "./applyLoanModel";
import auth from "../../services/common/authService";

class QuestionairreFormModel extends ApplyLoanModel {
  constructor(props) {
    super(props);
  }
  state = {
    data: {
      userId: "",
      whatsApp: "",
      gender: "",
      maritalStatus: "",
      children: "",
      degree: "",
      monthlyIncome: "",
      monthlyOutgoing: "",
      jobType: "",
      homeOwnershipType: "",
      vehicle: "",
      purchasedInOneYear: "",
      recommendedBy: "",
    },
    degreeList: [],
    showSpinner: false,
    errors: {},
    isSubmitted: false,
    kycStatus: "",
    loanId: "",
    questionnairre: null
  };
  schema = {
    userId: Joi.string().allow("").required(),
    whatsApp: Joi.string().allow("").required(),
    gender: Joi.string().allow("").required(),
    maritalStatus: Joi.string().allow("").required(),
    children: Joi.string().allow("").required(),
    degree: Joi.string().allow("").required(),
    monthlyIncome: Joi.number().allow("").required(),
    monthlyOutgoing: Joi.number().allow("").required(),
    jobType: Joi.string().allow("").required(),
    homeOwnershipType: Joi.string().allow("").required(),
    purchasedInOneYear: Joi.string().allow("").required(),
    vehicle: Joi.string().allow("").required(),
    recommendedBy: Joi.string().allow("").required(),
  };
  async loadDropdowns() {
    let { degreeList } = this.state;
    try {
      const {
        data: degreeResponse,
      } = await commonService.getDefinitionDetailsMaster("degree");

      degreeList = degreeResponse.data;

      this.setState({
        degreeList,
      });
    } catch (ex) { }
  }
  async mapDataToState(userData, reviewData) {
    let data = { ...this.state.data };

    let { user } = userData;

    let {
      questionnairreDetails,
      userDetails
    } = user;

    const kycStatus = userDetails.kycStatus ? userDetails.kycStatus : "";

    let loanId = "";

    let questionnairre = null;

    if (reviewData && reviewData.loanId) loanId = reviewData.loanId;

    if (user._id) data.userId = user._id;

    data.whatsApp = questionnairreDetails && questionnairreDetails.whatsApp
      ? questionnairreDetails.whatsApp
      : "";
    data.gender = questionnairreDetails && questionnairreDetails.gender ? questionnairreDetails.gender : "";
    data.maritalStatus = questionnairreDetails && questionnairreDetails.maritalStatus
      ? questionnairreDetails.maritalStatus
      : "";
    data.children = questionnairreDetails && questionnairreDetails.children
      ? questionnairreDetails.children
      : "";
    data.degree = questionnairreDetails && questionnairreDetails.degree ? questionnairreDetails.degree : "";
    data.monthlyIncome = questionnairreDetails && questionnairreDetails.monthlyIncome
      ? questionnairreDetails.monthlyIncome
      : "";
    data.monthlyOutgoing = questionnairreDetails && questionnairreDetails.monthlyOutgoing
      ? questionnairreDetails.monthlyOutgoing
      : "";
    data.jobType = questionnairreDetails && questionnairreDetails.jobType ? questionnairreDetails.jobType : "";
    data.homeOwnershipType = questionnairreDetails && questionnairreDetails.homeOwnershipType
      ? questionnairreDetails.homeOwnershipType
      : "";
    data.purchasedInOneYear = questionnairreDetails && questionnairreDetails.purchasedInOneYear
      ? questionnairreDetails.purchasedInOneYear
      : "";
    data.recommendedBy = questionnairreDetails && questionnairreDetails.recommendedBy
      ? questionnairreDetails.recommendedBy
      : "";
    data.vehicle = questionnairreDetails && questionnairreDetails.vehicle ? questionnairreDetails.vehicle : "";

    const currentPartner = auth.getCurrentPartner();

    if (currentPartner.questionnairre)
      questionnairre = currentPartner.questionnairre;

    this.setState({ data, kycStatus, loanId, questionnairre });
  }
  async insertUpdateQuestionairre() {
    let { data, errors, showSpinner, loanId } = this.state;
    showSpinner = true;
    this.setState({ showSpinner, isSubmitted: true });
    try {
      const {
        data: response,
      } = await questionairreFormService.insertUpdateQuestionairre(data, loanId);
      if (response.success) {
        delete errors["globalError"];
        showSpinner = false;
        this.setState({ data, errors, showSpinner, isSubmitted: false });
        this.props.handler("activeTab", "reviewTab");
      } else {
        errors.globalError = response.message;
        showSpinner = false;
        this.setState({ errors, showSpinner, isSubmitted: false });
      }
    } catch (ex) {
      errors.globalError = ex.response.data.message;
      showSpinner = false;
      this.setState({ data, errors, showSpinner, isSubmitted: false });
    }
  }
  async checkCurrentUser() {
    let data = { ...this.state.data };
    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location = "/login");

    data.userId = currentUser.userId;
    this.setState({ data });
  }
  async componentDidMount() {
    //await this.checkCurrentUser();
    await this.loadDropdowns();
  }
  async componentWillReceiveProps() {
    let data = { ...this.state.data };

    if (this.props.userData) {
      let reviewData = {};

      if (this.props.reviewData)
        reviewData = this.props.reviewData;


      await this.mapDataToState(this.props.userData, reviewData);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  async handleCustomErrors(errors) {
    const { data, questionnairre } = this.state;

    if (!data.whatsApp && questionnairre && questionnairre.whatsApp) errors["whatsApp"] = "Please select an option";
    else delete errors["whatsApp"];

    if (!data.gender && questionnairre && questionnairre.gender) errors["gender"] = "Please select an option";
    else delete errors["gender"];

    if (!data.maritalStatus && questionnairre && questionnairre.maritalStatus) errors["maritalStatus"] = "Please select an option";
    else delete errors["maritalStatus"];

    if (!data.children && questionnairre && questionnairre.children) errors["children"] = "Please select an option";
    else delete errors["children"];

    if (!data.homeOwnershipType && questionnairre && questionnairre.homeOwnershipType) errors["homeOwnershipType"] = "Please select an option";
    else delete errors["gender"];

    if (!data.vehicle && questionnairre && questionnairre.vehicle) errors["vehicle"] = "Please select an option";
    else delete errors["vehicle"];

    if (data.monthlyOutgoing === "" && questionnairre && questionnairre.monthlyOutgoing) errors["monthlyOutgoing"] = "Please enter monthly outgoing";
    else delete errors["monthlyOutgoing"];

    if (data.monthlyIncome === "" && questionnairre && questionnairre.monthlyIncome) errors["monthlyIncome"] = "Please enter monthly income";
    else delete errors["monthlyIncome"];

    if (!data.jobType && questionnairre && questionnairre.jobType) errors["jobType"] = "Please select an option";
    else delete errors["jobType"];

    if (!data.purchasedInOneYear && questionnairre && questionnairre.purchasedInOneYear) errors["purchasedInOneYear"] = "Please select an option";
    else delete errors["purchasedInOneYear"];

    if (!data.degree && questionnairre && questionnairre.degree) errors["degree"] = "Please select an option";
    else delete errors["degree"];

    if (!data.recommendedBy && questionnairre && questionnairre.recommendedBy) errors["recommendedBy"] = "Please enter recommended by";
    else delete errors["recommendedBy"];

    this.setState({ errors });

    return errors;
  }
  doSubmit = async () => {
    await this.insertUpdateQuestionairre();
  };
}

export default QuestionairreFormModel;
