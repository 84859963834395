import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as analytics from "../../utils/analytics";

function PersonalLoan(props) {
  analytics.track();

  return (
    <React.Fragment>
      <h2>FutureSecure – Unsecured personal loans</h2>
      <p>
        <b>FutureSecure Features:</b>
      </p>
      <ol>
        <ol>
          <li>
            FutureSecure is an unsecured personal lending product for
            individuals to fund new businesses, education and event-driven
            liquidity needs (eg medical, travel, wedding).
          </li>
          <li>
            FutureSecure lends to self employed individuals from Tier 2-5 cities
            in India.
          </li>
          <li>
            FutureSecure is typically structured as daily remittance funding; a
            lump sum payment to an individual in exchange for an agreed daily
            payment at a fixed interest rate for a fixed period.
          </li>
          <li>
            Interest rate charged and maximum amount loan depend on certain
            heuristic criteria and Fundfina’s FactorScore.
          </li>
          <li>
            FactorScore is Fundfina’s proprietary factor based credit scoring
            system for individuals with little to no credit imprint with credit
            agencies. Higher the FactorScore, lower the probability of default.
          </li>
        </ol>
      </ol>
      <p className="fundfinaColor">
        <b>FactorScore</b>
      </p>
      <ol className="fundfinaColor">
        <li>
          Estimates long term default rates in target market and segment for key
          demographic factors.
        </li>
        <li>
          Uses factor based default rates to estimate probability of default
          using advanced machine learning techniques.
        </li>
        <li>
          FactorScore is using a simple formula based on probability of default
          and several other criteria based on non demographic factors such as
          use of funds, transaction levels etc.
        </li>
      </ol>
      <p>
        <b>Eligibility Criteria:</b>
      </p>
      <ol>
        <li>Detailed demographic data available.</li>
        <li>
          FactorScore and AccessEase index values above minimum threshold.
        </li>
        <li>6 months bank statements with minimum transaction levels.</li>
        <li>Strong evidence of responsible use of funds.</li>
      </ol>
      <Link to="/borrow">
        <Button className="ff-button" type="button">
          Apply Now
        </Button>
      </Link>
    </React.Fragment>
  );
}

export default PersonalLoan;
