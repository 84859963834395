import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PartnerConfigurationModel from "../../models/partnerConfigurations/creditGrids";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";
import { Tabs, Tab } from "react-bootstrap";


class CreditGrids extends PartnerConfigurationModel {

  renderPinCodes = () =>{

    let elements = []

    for(let i= 0 ; i< this.state.data.pinCodes.length ; i++){

      let pinCode = this.state.data.pinCodes[i]

      elements.push(
        <div className="d-inline-block pincode-card mt-2">
          <span><small>{pinCode}</small></span>
          <span onClick={()=> this.removePinCode(i)}><i className="fa fa-times-circle text-danger ml-2 cursor-pointer"></i></span>
        </div>
      )
    }

    return elements
  }

  render() {
    const {
      showSpinner,
      activeTab,
      data,
      pinCode,
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <div className="row mt-3">
          <div className="col">
            <Form.Label>Min Monthly Sales Value</Form.Label>
              <Form.Control
                type="number"
                name="minMonthlySalesVol"
                value={data["minMonthlySalesVol"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>

          <div className="col">
            <Form.Label>Min Loan Amount</Form.Label>
              <Form.Control
                type="number"
                name="minLoanAmt"
                value={data["minLoanAmt"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>

          <div className="col">
            <Form.Label>Max Loan Amount</Form.Label>
              <Form.Control
                type="number"
                name="maxLoanAmt"
                value={data["maxLoanAmt"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>

          <div className="col">
            <Form.Label>Merchant Vintage (Months)</Form.Label>
              <Form.Control
                type="number"
                name="merchantVintage"
                value={data["merchantVintage"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>
        </div>

        <div className="row mt-3">
        <div className="col">
            <Form.Label>Min Loan Tenure</Form.Label>
              <Form.Control
                type="number"
                name="minLoanTenure"
                value={data["minLoanTenure"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>

          <div className="col">
            <Form.Label>Max Loan Tenure</Form.Label>
              <Form.Control
                type="number"
                name="maxLoanTenure"
                value={data["maxLoanTenure"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>

          <div className="col">
            <Form.Label>Min Bureau Score</Form.Label>
              <Form.Control
                type="number"
                name="minBureauScore"
                value={data["minBureauScore"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>

          <div className="col">
            <Form.Label>Min TrueScore</Form.Label>
              <Form.Control
                type="number"
                name="minTrueScore"
                value={data["minTrueScore"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-3">
            <Form.Label>NTC Allowed</Form.Label>
              <input type="checkbox" checked={data["ntcAllowed"]} onChange={(e)=> this.onChangeCheckBox('ntcAllowed' ,e)} className='ml-2'></input>
          </div>

          <div className="col-3">
            <Form.Label>Bank Statement Required</Form.Label>
              <input type="checkbox" checked={data["bankStatementReq"]}  onChange={(e)=> this.onChangeCheckBox('bankStatementReq' ,e)} className='ml-2'></input>
          </div>

        </div>


  <div className="mt-3">
      <Form.Label>Pincode Restriction</Form.Label>
      <div className="row">
          <div className="col-6">

            <div className="row">
              <div className="col-6">
              <Form.Control
                type="number"
                name="pinCode"
                value={pinCode}
                onChange={this.handleChangePinCode}
                autoComplete="off"
              />

              </div>

              <div className="col-3">
                <div className="btn btn-primary d-inline-block" onClick={()=> this.addPinCode()}>
                  + ADD
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
            {this.renderPinCodes()}
        </div>

        <div className="text-right mt-3">
          <Button onClick={()=> this.onClickSave()} className="ff-button">Save</Button>
        </div>
  </div>
       
      </React.Fragment>
    );
  }
}

export default CreditGrids;
