import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import LoanDecisionFormModel from "../../models/borrow/loanDecisionFormModel";
import SpinnerComponent from "../../components/common/spinner";
import { Form, Col, Row, Image, Button } from "react-bootstrap";
import NumberFormat from 'react-number-format';

class LoanDecisionForm extends LoanDecisionFormModel {
  render() {
    const {
      staticData,
      data,
      showSpinner,
      optionalDocumentList,
      errors,
      rejectReasonList
    } = this.state;
    return (
      <React.Fragment>
        {" "}
        <SpinnerComponent showSpinner={showSpinner} />
        <br />
        <h2
          className="fundfinaColor"
          style={{ textDecoration: "underline", textAlign: "center" }}
        >
          Loan Decision
        </h2>{" "}
        <Form>
          <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
            Basic Information
          </h5>{" "}
          {(staticData.userRole == "User" ||
            staticData.userRole == "Admin") && (
              <Form.Group as={Row} controlId="name">
                <Form.Label column lg="12">
                  Full Name:{"  "}
                  <span style={{ color: "black", textTransform: "uppercase" }}>
                    {staticData.fullName}
                  </span>
                </Form.Label>
              </Form.Group>
            )}
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Email Address:{" "}
              <span style={{ color: "black" }}>{staticData.emailId}</span>
            </Form.Label>
            <Form.Label column sm="4">
              Mobile Number:{" "}
              <span style={{ color: "black" }}>{staticData.mobileNo}</span>
            </Form.Label>
          </Form.Group>{" "}
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="4">
              Alternate Email:{" "}
              <span style={{ color: "black" }}>
                {staticData.alternateEmail}
              </span>
            </Form.Label>
            <Form.Label column sm="4">
              Alternate Mobile:{" "}
              <span style={{ color: "black" }}>
                {staticData.alternateMobile}
              </span>
            </Form.Label>
          </Form.Group>
          {staticData.panNo && (
            <Form.Group as={Row} controlId="pan">
              <Form.Label column sm="6">
                PAN Number:{" "}
                <span style={{ color: "black", textTransform: "uppercase" }}>
                  {staticData.panNo ? staticData.panNo.substr(0, 3) + "XXXXXXX" : ""}
                </span>{" "}
                {staticData.panFilePath && (
                  <a
                    href="#"
                    onClick={() => this.openFile(staticData.panFilePath)}
                  >
                    {" "}
                    View
                  </a>
                )}
              </Form.Label>
            </Form.Group>
          )}
          {(staticData.userRole == "User" || staticData.userRole == "Admin") &&
            staticData.poaNo && (
              <Form.Group as={Row} controlId="poa">
                <Form.Label column sm="4">
                  POA Number:{" "}
                  <span style={{ color: "black", textTransform: "uppercase" }}>
                    {staticData.poaNo}
                  </span>{" "}
                  {staticData.poaFilePath && (
                    <a
                      href="#"
                      onClick={() => this.openFile(staticData.poaFilePath)}
                    >
                      {" "}
                      View
                    </a>
                  )}
                </Form.Label>
                <Form.Label column sm="4">
                  POA Type:{" "}
                  <span style={{ color: "black" }}>{staticData.poaType}</span>{" "}
                </Form.Label>
              </Form.Group>
            )}
          <Form.Group as={Row} controlId="employment">
            {staticData.employment && (
              <Form.Label column sm="4">
                Employment:{" "}
                <span style={{ color: "black" }}>{staticData.employment}</span>{" "}
              </Form.Label>
            )}
            {staticData.kycStatus && (
              <Form.Label column sm="4">
                Kyc Status:{" "}
                <span style={{ color: "black" }}>{staticData.kycStatus}</span>{" "}
              </Form.Label>
            )}{" "}
            {staticData.clientCode && (
              <Form.Label column sm="4">
                Client Code:{" "}
                <span style={{ color: "black" }}>{staticData.clientCode}</span>{" "}
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group as={Row} controlId="role">
            {staticData.dob && (
              <Form.Label column sm="4">
                Date of Birth:{" "}
                <span style={{ color: "black" }}>{staticData.dob}</span>{" "}
              </Form.Label>
            )}
            {staticData.doi && (
              <Form.Label column sm="4">
                Date of Incorporation:{" "}
                <span style={{ color: "black" }}>{staticData.doi}</span>{" "}
              </Form.Label>
            )}
            {staticData.typeOfEntity && (
              <Form.Label column sm="4">
                Type of Entity:{" "}
                <span style={{ color: "black" }}>
                  {staticData.typeOfEntity}
                </span>{" "}
              </Form.Label>
            )}
            {staticData.userRole && (
              <Form.Label column sm="4">
                User Role:{" "}
                <span style={{ color: "black" }}>{staticData.userRole}</span>{" "}
              </Form.Label>
            )}
          </Form.Group>{" "}
          <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
            Loan Information
          </h5>{" "}
          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Loan No:{" "}
              <span style={{ color: "black" }}>{staticData.loanNo}</span>
            </Form.Label>
            <Form.Label column sm="4">
              Amount:{" "}
              <span style={{ color: "black" }}><NumberFormat value={staticData.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></span>
            </Form.Label>
            <Form.Label column sm="4">
              Loan Category:{" "}
              <span style={{ color: "black" }}>{staticData.loanCategory}</span>
            </Form.Label>
          </Form.Group>{" "}
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="4">
              Frequency:{" "}
              <span style={{ color: "black" }}>{staticData.frequency}</span>
            </Form.Label>
            <Form.Label column sm="4">
              Interest Rate:{" "}
              <span style={{ color: "black" }}>
                {staticData.roi}
                {staticData.roi && (
                  <i className="fa fa-percent" aria-hidden="true"></i>
                )}
              </span>
            </Form.Label>
            <Form.Label column sm="4">
              Maturity:{" "}
              <span style={{ color: "black" }}>{staticData.maturity}</span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="4">
              Loan Status:{" "}
              <span style={{ color: "black" }}>{staticData.loanStatus}</span>
            </Form.Label>
            <Form.Label column sm="4">
              Post Disbursal Status:{" "}
              <span style={{ color: "black" }}>{staticData.postDisbursalStatus}</span>
            </Form.Label>
            <Form.Label column sm="4">
              Loan Type:{" "}
              <span style={{ color: "black" }}>{staticData.loanType}</span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="4">
              Generated On:{" "}
              <span style={{ color: "black" }}>{staticData.loanGenDate}</span>
            </Form.Label>
            <Form.Label column sm="4">
              Purpose:{" "}
              <span style={{ color: "black" }}>{staticData.purpose}</span>
            </Form.Label>
            <Form.Label column sm="4">
              Description:{" "}
              <span style={{ color: "black" }}>{staticData.description}</span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="4">
              Additional Description:{" "}
              <span style={{ color: "black" }}>
                {staticData.additionalDescription}
              </span>
            </Form.Label>
            <Form.Label column sm="4">
              Campaign Type:{" "}
              <span style={{ color: "black" }}>{staticData.campaignTime}</span>
            </Form.Label>
          </Form.Group>
          <React.Fragment>
            <h5
              className="fundfinaColor"
              style={{ textDecoration: "underline" }}
            >
              Address Details
            </h5>{" "}
            <Form.Group as={Row} controlId="address">
              <Form.Label column sm="12">
                Address:{" "}
                <span style={{ color: "black", textTransform: "uppercase" }}>
                  {staticData.fullAddress}
                </span>{" "}
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} controlId="city">
              <Form.Label column sm="4">
                City:{" "}
                <span style={{ color: "black", textTransform: "uppercase" }}>
                  {staticData.city}
                </span>{" "}
              </Form.Label>
              <Form.Label column sm="4">
                State:{" "}
                <span style={{ color: "black", textTransform: "uppercase" }}>
                  {staticData.state}
                </span>{" "}
              </Form.Label>
              <Form.Label column sm="4">
                Pincode:{" "}
                <span style={{ color: "black", textTransform: "uppercase" }}>
                  {staticData.pincode}
                </span>{" "}
              </Form.Label>
            </Form.Group>
          </React.Fragment>
          {/* {staticData.disbursalBankDetails && (
            <React.Fragment>
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                Bank Details
              </h5>
              {staticData.bankList.map((bank) => (
                <React.Fragment key={bank.bankName}>
                  <Form.Group as={Row} controlId="bank">
                    <Form.Label column sm="12">
                      Bank Name:{" "}
                      <span
                        style={{
                          color: "black",
                        }}
                      >
                        {bank.bankName}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Row} controlId="branch">
                    <Form.Label column sm="12">
                      Branch Name:{" "}
                      <span
                        style={{
                          color: "black",
                          textTransform: "uppercase",
                        }}
                      >
                        {bank.branch}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Row} controlId="accountNo">
                    <Form.Label column sm="4">
                      Account No:{" "}
                      <span
                        style={{
                          color: "black",
                        }}
                      >
                        {bank.accountNo}
                      </span>{" "}
                    </Form.Label>
                    <Form.Label column sm="4">
                      IFSC Code:{" "}
                      <span
                        style={{
                          color: "black",
                        }}
                      >
                        {bank.ifsc}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Row} controlId="accountType">
                    <Form.Label column sm="4">
                      Account Type:{" "}
                      <span
                        style={{
                          color: "black",
                          textTransform: "uppercase",
                        }}
                      >
                        {bank.accountType}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                </React.Fragment>
              ))}
            </React.Fragment>
          )} */}
          {staticData.gstList.length > 0 && (
            <React.Fragment>
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                GST Details
              </h5>
              {staticData.gstList.map((gst) => (
                <React.Fragment key={gst.gstNo}>
                  <Form.Group as={Row} controlId="gstNo">
                    <Form.Label column sm="4">
                      Gst No:{" "}
                      <span
                        style={{
                          color: "black",
                          textTransform: "uppercase",
                        }}
                      >
                        {gst.gstNo}
                      </span>{" "}
                      {gst.gstFilePath && (
                        <a
                          href="#"
                          onClick={() => this.openFile(gst.gstFilePath)}
                        >
                          {" "}
                          View
                        </a>
                      )}
                    </Form.Label>
                  </Form.Group>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
          {optionalDocumentList.length > 0 && (
            <React.Fragment>
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                Additional Documents
              </h5>{" "}
              <Form.Group as={Row} controlId="additionalDocuments">
                <Form.Label column sm="12">
                  {optionalDocumentList.map((file) => (
                    <React.Fragment>
                      <a
                        key={file.fileName}
                        href="#"
                        onClick={() => this.openFile(file.filePath)}
                        style={{
                          textDecoration: "underline",
                        }}
                        className="black"
                      >
                        {" "}
                        {file.fileName}
                      </a>{" "}
                      <br />
                    </React.Fragment>
                  ))}
                </Form.Label>
              </Form.Group>
            </React.Fragment>
          )}
          {staticData.userRole == "User" && (
            <React.Fragment>
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                Questionnairre
              </h5>{" "}
              <Form.Group as={Row} controlId="questionnairre">
                <Form.Label column sm="4">
                  WhatsApp in Mobile?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.whatsApp}
                  </span>{" "}
                </Form.Label>
                <Form.Label column sm="4">
                  Gender:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.gender}
                  </span>{" "}
                </Form.Label>
                <Form.Label column sm="4">
                  Marital Status:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.maritalStatus}
                  </span>{" "}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row} controlId="questionnairre">
                <Form.Label column sm="4">
                  Have Children?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.children}
                  </span>{" "}
                </Form.Label>
                <Form.Label column sm="4">
                  Rent or own a home?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.homeOwnershipType}
                  </span>{" "}
                </Form.Label>

                <Form.Label column sm="4">
                  Own a vehicle?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.vehicle}
                  </span>{" "}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row} controlId="questionnairre">
                <Form.Label column sm="4">
                  Monthly Outgoings?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.monthlyOutgoing}
                  </span>{" "}
                </Form.Label>

                <Form.Label column sm="4">
                  Monthly Income?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.monthlyIncome}
                  </span>{" "}
                </Form.Label>

                <Form.Label column sm="4">
                  Public and/or Private Sector Job?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.jobType}
                  </span>{" "}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row} controlId="questionnairre">
                <Form.Label column sm="4">
                  Purchased TV/Fridge in past 1 year?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.purchasedInOneYear}
                  </span>{" "}
                </Form.Label>

                <Form.Label column sm="4">
                  Recommended By:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.recommendedBy}
                  </span>{" "}
                </Form.Label>

                <Form.Label column sm="4">
                  Highest Degree?:{" "}
                  <span style={{ color: "black" }}>
                    {staticData.questionnairre.degree}
                  </span>{" "}
                </Form.Label>
              </Form.Group>
            </React.Fragment>
          )}
        </Form>
        {staticData.evaluatorRole === "User" &&
          staticData.loanStatus === "Approved By Admin" && (
            <Form onSubmit={this.handleSubmit}>
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                Take Action
              </h5>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="status">
                  <Form.Label>Select Action</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={data["status"]}
                    onChange={this.handleChange}
                  >
                    <option key="select" value="">
                      ---Select---
                    </option>
                    <React.Fragment>
                      <option key="accepted" value="Accepted By Client">
                        Accept
                      </option>
                      <option key="rejected" value="Rejected By Client">
                        Reject
                      </option>
                    </React.Fragment>
                  </Form.Control>
                  {errors["status"] && (
                    <div className="alert alert-danger">{errors["status"]}</div>
                  )}
                </Form.Group>
              </Form.Row>
              {data.status === "Rejected By Client" && (
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="rejectReason">
                    <Form.Label>Select Reason*</Form.Label>
                    <Form.Control
                      as="select"
                      name="rejectReason"
                      value={data["rejectReason"]}
                      onChange={this.handleChange}
                    >
                      {" "}
                      <option key="select" value="">
                        ---Select---
                      </option>
                      {rejectReasonList.map((item, i) => (
                        <option
                          key={item.reason}
                          value={item.reason}
                          title={item.reason}
                        >
                          {item.reason}
                        </option>
                      ))}
                    </Form.Control>
                    {errors["rejectReason"] && (
                      <div className="alert alert-danger">{errors["rejectReason"]}</div>
                    )}
                  </Form.Group>
                </Form.Row>
              )}

              {(data.rejectReason === "Others") && (
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="rejectDescription">
                    <Form.Label>Comments*</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="rejectDescription"
                      value={data["rejectDescription"]}
                      onChange={this.handleChange}
                    />
                    {errors["rejectDescription"] && (
                      <div className="alert alert-danger">
                        {errors["rejectDescription"]}
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>
              )}

              <Button className="ff-button" type="submit">
                Submit
              </Button>
              {errors["globalError"] && (
                <div className="alert alert-danger">
                  {errors["globalError"]}
                </div>
              )}
            </Form>
          )}
      </React.Fragment>
    );
  }
}

export default LoanDecisionForm;
