import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import LenderSettlementsModel from "../../models/lenderSettlements/lenderSettlementsModel";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";
import { Tabs, Tab } from "react-bootstrap";
import LenderSettlementDetails from '../lenderSettlementsDetails/lenderSettlementsDetails'


class LenderSettlement extends LenderSettlementsModel {
    getPagedData = () => {
        const { data } = this.state;
        let transactions = data.transactions;
        return { totalCount: transactions.length, data: transactions };
    };

    renderTabs = () => {
        let { tabs, activeTab } = this.state;
        let elements = []
        for (let i = 0; i < tabs.length; i++) {
            elements.push(
                <Tab eventKey={tabs[i].title} title={tabs[i].title}></Tab>
            )
        }
        return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
            {elements}
        </Tabs>
        )
    }

    render() {
        const {
            pageSize,
            currentPage,
            data,
            showSpinner,
            partners,
            lendingPartners,
            successMsg,
            errors,
            activeTab
        } = this.state;

        const { data: transactions } = this.getPagedData();

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>
                    Lender Settlements
                </h2>

                <div className="mt-3">
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Row>
                            {/* {activeTab == "Lending Partners" ?  */}
                            <Form.Group as={Col} md="3" controlId="partner">
                                <Form.Label>Lending Partner</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="partner"
                                    value={data["lendingPartner"]}
                                    onChange={this.handlePartnerChange}
                                >
                                    {" "}
                                    <option key="all" value="all">
                                        ALL
                                    </option>
                                    {lendingPartners && lendingPartners.map((lendingPartner) => (
                                        <option key={lendingPartner.partnerName} value={lendingPartner._id}>
                                            {lendingPartner.displayName}
                                        </option>
                                    ))}
                                </Form.Control>

                                {this.state.lenderSettlementInformation ? 
                                <small 
                                title={`Principal Amount : ${this.state.lenderSettlementInformation.totalPrincipalAmount}\nInterest Amount : ${this.state.lenderSettlementInformation.totalInterestAmount}\nFee Amount : ${this.state.lenderSettlementInformation.totalFeeAmount}\nMoratoriumInterest : ${this.state.lenderSettlementInformation.totalMoratoriumInterest}` }
                                ><b>Amount to be Settle : </b>₹{this.state.lenderSettlementInformation.totalAmount}</small>  : ""}
                            </Form.Group>
                            {/* :

                                <Form.Group as={Col} md="3" controlId="partner">
                                    <Form.Label>Borrowing Partner</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="partner"
                                        value={data["partner"]}
                                        onChange={this.handleChange}
                                    >
                                        {" "}
                                        <option key="all" value="all">
                                            ALL
                                        </option>
                                        {partners && partners.map((partner) => (
                                            <option key={partner.partnerName} value={partner.partnerName}>
                                                {partner.displayName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                } */}

                            <Form.Group as={Col} md="3" controlId="fromDate">
                                <Form.Label>Repayment Date From</Form.Label>
                                <DatePicker
                                    name="fromDate"
                                    id="fromDate"
                                    value={data["fromDate"]}
                                    max={moment()}
                                    inputFormat="DD/MM/YYYY"
                                    isGregorian={true}
                                    className="form-control"
                                    inputReadOnly={true}
                                    showTodayButton={false}
                                    timePicker={false}
                                    onChange={(value) => this.handleFromDateChange(value)}
                                    placeholder="Enter From Date"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="toDate">
                                <Form.Label>Repayment Date To</Form.Label>
                                <DatePicker
                                    name="toDate"
                                    id="toDate"
                                    value={data["toDate"]}
                                    max={moment()}
                                    inputFormat="DD/MM/YYYY"
                                    isGregorian={true}
                                    className="form-control"
                                    inputReadOnly={true}
                                    showTodayButton={false}
                                    timePicker={false}
                                    onChange={(value) => this.handleToDateChange(value)}
                                    placeholder="Enter To Date"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="3" className="pt-2">
                                <Button
                                    className="ff-button mt-4 mr-2"
                                    type="submit"
                                >
                                    Go
                                </Button>

                                {this.state.lenderSettlementInformation ? <Button
                                    onClick={this.initiatePayoutDisbursal}
                                    className="ff-button mr-2 mt-4"
                                >
                                    Settle
                                </Button> : <></>}
                            </Form.Group>
                        </Form.Row>
                    </Form>

                    <br />
                    {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
                    {successMsg && <div className="alert alert-success">{successMsg}</div>}


                    {this.renderTabs()}

                    <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0} leftStickyColumnCount={1}>
                            <Row>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Lending partner</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b># of EDIs to be Settled
                                    {activeTab == "Write-Off" ? " (Written Off)" : ""}</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount to be Settled
                                    {activeTab == "Write-Off" ? " (Written Off)" : ""}</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b># of EDIs Collected
                                    {activeTab == "Write-Off" ? " (Written Off)" : ""}</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount Collected
                                    {activeTab == "Late Fees" ? " (Late Fees)" : ""}</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Shortfall</b></Cell>
                                {activeTab == "Lending Partners" ? <Cell style={{ minWidCell: 100, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Add Money</b></Cell> : <></>}
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Initiate Fund Transfer</b></Cell>
                            </Row>
                            {transactions &&
                                transactions.map((transaction, i) => (
                                    <Row key={transaction._id}>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>
                                            <a className="cursor-pointer" onClick={() => this.showDetails(transaction._id)}>{transaction.lendingPartnerName}</a>
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.edisToBeSettled}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.amountTobeSettled || transaction.amountTobeSettled == 0 ? "₹" : ""}{transaction.amountTobeSettled}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.edisCollected}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.amountCollected || transaction.amountCollected == 0 ? "₹" : ""}{transaction.amountCollected}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.shortFall || transaction.shortFall == 0 ? "₹" : ""}{transaction.shortFall}</Cell>
                                        {activeTab == "Lending Partners" ? <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <Button
                                                onClick={() => { }}
                                                className="ff-button btn-sm">
                                                Add
                                            </Button>
                                        </Cell> : <></>}
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <Button
                                                onClick={() => { }}
                                                className="ff-button btn-sm">
                                                Initiate
                                            </Button>
                                        </Cell>
                                    </Row>
                                ))}
                            {!transactions.length && (
                                <Row>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}>No record found</Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                </Row>
                            )}

                        </StickyTable>
                    </div >
                    <div style={{ width: "100%", overflowX: "scroll" }}>
                        <Pagination
                            currentPage={currentPage}
                            pageSize={pageSize}
                            onPageChange={this.onPageChange}
                            onPagePrevious={this.onPagePrevious}
                            onPageNext={this.onPageNext}
                        />
                    </div>
                </div >
                {this.state.showDetails && <LenderSettlementDetails id={this.state.transactionId} close={this.closeDetails}></LenderSettlementDetails>}
            </React.Fragment >
        );
    }
}

export default LenderSettlement;
