import http from "../common/httpService";
import { apiUrl, reconUrl } from "../../config.json";
import { creditApiUrl } from "../../config.json";
import moment from "moment";
import { mode } from "crypto-js";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getReportLogList(model) {

  let modelKeys = Object.keys(model);
  let url = `${apiEndpoint}/getReportsLogList`;
  modelKeys.map((key, index) => {
    if (index == 0) {
      url += `?${key}=${model[key]}`;
    } else {
      url += `&${key}=${model[key]}`;
    }
  })
  const { data } = await http.post(url);
  return data;
}

export async function getReportDownloadUrls(model) {
  const { data } = await http.post(`${apiEndpoint}/getReportsDownloadUrls`, {
    ...model
  });
  return data;
}

export async function getReportTypes(model) {
  const { data } = await http.post(`${apiEndpoint}/getReportTypes`, {
    ...model
  });
  return data;
}

