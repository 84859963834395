import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import * as portfolioService from "../../services/portfolio/portfolioService";
import * as collectionsQueueService from "../../services/collectionsQueue/collectionsQueue";
import * as loanService from "../../services/loan/loanService";

class CollectionsQueueLoanWiseModel extends InputDataForm {
    state = {
        data: {
            partnerId: "",
            mobileNo: "",
            name: "",
            loanNo: "",
            overdueBy: "",
            emiList: [],
            tab: "All",
            lendingPartnerId: ""
        },
        showSpinner: false,
        errors: {},
        currentPage: 1,
        pageSize: 0,
        partnerList: [],
        showRepaymentModal: false,
        loanNo: "",
        showErrorModal: false,
        errorMessage: "",
        errorHeader: "",
        loanId: "",
        allLoans: [],
        tabs: [
            { title: "All" },
            { title: "Due Today" },
            { title: "Up Coming" },
            { title: "Called" },
            { title: "Not Connected" }
        ],
        activeTab: "All",
        lendingPartnerList: []
    };
    schema = {
        mobileNo: Joi.any(),
        name: Joi.any(),
        partnerId: Joi.any(),
        loanNo: Joi.any(),
        emiList: Joi.any(),
        overdueBy: Joi.any(),
        page: Joi.any(),
        tab: Joi.any(),
        skip: Joi.any(),
        month: Joi.any(),
        year: Joi.any(),
        lendingPartnerId: Joi.any()
    };
    async loadDropdowns() {
        try {
            const {
                data: partnerList,
            } = await commonService.getBorrowingPartnerList();

            this.setState({
                partnerList: partnerList
            });
        } catch (ex) { }
    }
    async getLoanList(isAllLoans, append) {

        this.state.data.page = this.state.currentPage
        this.state.data.skip = this.state.data.emiList.length
        this.state.data.month = this.state.month
        this.state.data.year = this.state.year
        const data = { ...this.state.data };

        if (isAllLoans) {
            data.isAllLoans = true
        }

        this.setState({ showSpinner: true });
        try {
            const emiList = await collectionsQueueService.getPendingEmisLoanWiseTeleCalling(data);

            if (isAllLoans) {
                this.setState({ allLoans: emiList.data, showSpinner: false }, () => {
                    this.setState({ downloadAll: true }, () => {
                        this.setState({ downloadAll: false })
                    })
                });
                return
            }
            data.emiList = append ? data.emiList.concat(emiList.data) : emiList.data;

            if (emiList.totalPages) {
                this.state.pageSize = emiList.totalPages
            }

            this.setState({ data, showSpinner: false });
        } catch (ex) {
            this.setState({ data, showSpinner: false });
        }
    }
    async getRepaymentDetails(loanNo) {
        try {
            this.setState({
                showRepaymentModal: true,
                loanId: loanNo
            });
        } catch (ex) {
        }
    }

    async getLendingPartners() {
        try {
            const {
                data: partnerList,
            } = await loanService.getLendingPartnerList();

            this.setState({
                lendingPartnerList: partnerList.data
            });
        } catch (ex) { }
    }
    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();

        if (currentUser.role != "Admin" && currentUser.role != "Lending Partner")
            return (window.location = "/");

        await this.loadDropdowns();
        await this.getLoanList();
        await this.getLendingPartners()
    }

    async handleCustomErrors(errors) {
        return errors;
    }
    closeModal = () => {
        this.setState({
            showRepaymentModal: false,
            showErrorModal: false
        });
    };
    handleMarkAsPaid = async (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.loanId === recordFields[1]);

        let loanNo = "";

        if (tranIndex >= 0) {
            loanNo = list[tranIndex].loanNo;
        }

        if (loanNo) {
            this.props.handler("loanNo", loanNo);
            this.props.handler("emiView", true);
        }


    }
    handleEmiStatusChange = (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.loanId === recordFields[1]);

        if (tranIndex >= 0) {

            list[tranIndex].eNachStatus = e.target.value;
        }

        data.emiList = list;

        this.setState({ data });
    };

    eNachAction = async (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.loanId === recordFields[1]);

        if (list[tranIndex].eNachStatus == "UPI") {
            await this.onClickUpiSend(list[tranIndex], tranIndex);
            return;
        }

        if (list[tranIndex].eNachStatus === "Send" && !list[tranIndex].emiCount) { this.setState({ errorMessage: "Please select EMI Count", showErrorModal: true, errorHeader: "Note!" }); }
        else if (!list[tranIndex].eNachStatus) { this.setState({ errorMessage: "Please select e-Nach action", showErrorModal: true, errorHeader: "Note!" }); }
        else {

            list[tranIndex].nachBtnDisabled = true;

            data.emiList = list;

            this.setState({ showSpinner: true, data });

            let status = list[tranIndex].eNachStatus;
            let loanId = list[tranIndex].loanId;
            let emiCount = list[tranIndex].emiCount;

            try {
                let response = await collectionsQueueService.changeEnachStatusForLoan(loanId, emiCount, status);

                list[tranIndex].nachBtnDisabled = false;

                data.emiList = list;

                if (response.success) {

                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Success!", showSpinner: false, data });
                    await this.getLoanList();
                }

                else
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false, data });

            }
            catch (ex) {
                this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false, data });
            }
        }
    }
    async doSubmit() {
        this.resetPage()
        await this.getLoanList();
    }
    handleHoldCollection = async (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];

        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.loanId === recordFields[1]);

        if (tranIndex >= 0) {

            const oldValue = list[tranIndex].holdWalletCollection;

            list[tranIndex].holdWalletCollection = !list[tranIndex].holdWalletCollection;

            data.emiList = list;

            this.setState({ data });

            const holdWalletCollection = await collectionsQueueService.holdWalletCollections(recordFields[1], list[tranIndex].holdWalletCollection);

            if (!holdWalletCollection.success) {

                this.setState({ errorMessage: holdWalletCollection.message, showErrorModal: true, errorHeader: "Error!" });

                list[tranIndex].holdWalletCollection = oldValue;

                data.emiList = list;

                this.setState({ data });
            }
        }
    };
    handleEmiNumberChange = (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.loanId === recordFields[1]);

        if (tranIndex >= 0) {

            list[tranIndex].emiCount = e.target.value;
        }

        data.emiList = list;

        this.setState({ data });
    };

    onPageNext = () => {

        if (this.state.pageSize < this.state.currentPage)
            return

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getLoanList()
        })
    }

    onPageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.getLoanList()
        })
    }

    onPagePrevious = () => {

        if (this.state.currentPage <= 1)
            return

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {
            this.getLoanList()
        })
    }

    resetPage = () => {
        this.state.data.page = 1
        this.state.data.emiList = []
        this.setState({ currentPage: 1, pageSize: 1 })
    }

    onClickMore = () => {
        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getLoanList(false, true)
        })
    }

    onSelectMonthYear = (month, year) => {
        this.setState({ month, year })
    }

    clearMonthAndYear = () => {
        this.setState({ month: "", year: "" })
    }

    downloadExcel = () => {
        this.getLoanList(true)
    }

    onChangeTab = (tab) => {
        this.state.data.emiList = []
        const data = { ...this.state.data };
        data['tab'] = tab
        this.resetPage()
        this.setState({ data, activeTab: tab }, () => {
            this.getLoanList()
        })
    }

    async onClickUpiSend(loan, index) {

        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];
        if (!list[index].emiCount) { this.setState({ errorMessage: "Please select EMI Count", showErrorModal: true, errorHeader: "Note!" }); }
        else {
            try {
                let payload = { loanId: loan.loanId }
                let emiCount = list[index].emiCount;
                payload.emiCount = emiCount

                this.setState({ showSpinner: true })
                let response = await commonService.generateUpiLink(payload);

                if (response.success) {
                    this.setState({ errorMessage: response.data.link, showErrorModal: true, errorHeader: "Success!", showSpinner: false, });
                    await this.getLoanList();
                }
                else
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false });

            } catch (ex) {
                this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false });
            }
        }
    }

}

export default CollectionsQueueLoanWiseModel;
