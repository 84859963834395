import InputDataForm from "../../components/common/form";
import * as reviewFormService from "../../services/borrow/reviewFormService";
import auth from "../../services/common/authService";
import Joi from "joi-browser";
import { isMobile } from 'react-device-detect';

class ReviewFormModel extends InputDataForm {
  state = {
    data: {
      loanId: "",
      additionalDescription: "",
      name: ""
    },
    showSpinner: false,
    errors: {},
    isSubmitted: false,
    showTnc: false,
    showPrivacyPolicy: false,
    loggedInFrom: "",
    disabled: false,
    callBackUrl: "",
    isMobileDevice: false,
    redirectionUrl: "",
    isEnach: false,
    showRedirectionPopup: false
  };
  schema = {
    additionalDescription: Joi.string().allow("").label("additional description"),
    loanId: Joi.string().required(""),
    name: Joi.string().min(1).max(100).required().label("name")
  };
  async mapDataToState(reviewData) {
    let data = { ...this.state.data };
    data.loanId = reviewData.loanId;
    data.additionalDescription = reviewData.additionalDescription ? reviewData.additionalDescription : "";
    data.name = reviewData.name ? reviewData.name : "";

    this.setState({ data, isMobileDevice: isMobile });
  }
  async insertUpdateDescription() {
    let { data, errors, callBackUrl } = this.state;
    this.setState({ showSpinner: true, isSubmitted: true });
    try {
      const {
        data: response,
      } = await reviewFormService.insertUpdateLoanDescription(data);
      if (response.success) {
        delete errors["globalError"];
        this.setState({ data, errors, showSpinner: false });

        // if (response.url) {
        //   this.setState({ errors, showSpinner: false, isSubmitted: false });
        //   window.location.href = `/updateBankDetail/${response.data._id}`;
        // }
        // else 
        // if (callBackUrl) {
        //   auth.logout();
        //   this.setState({ errors, showSpinner: false, isSubmitted: false });
        //   window.location = callBackUrl;
        // }
        // else

        if (!response.transitionState)
          window.location.href = `/updateBankDetail/${response.data._id}`;
        else if (callBackUrl) {
          auth.logout();
          this.setState({ errors, showSpinner: false, isSubmitted: false });
          window.location = callBackUrl;
        }
        else
          window.location.href = `/borrow`;

      } else {
        errors.globalError = response.message;
        this.setState({ errors, showSpinner: false, isSubmitted: false });
      }
    } catch (ex) {
      errors.globalError = ex.response.data.message;
      this.setState({ data, errors, showSpinner: false, isSubmitted: false });
    }
  }
  async componentWillReceiveProps() {
    if (this.props.reviewData) {
      const currentUser = auth.getCurrentUser();
      if (!currentUser) window.location.href = "/";
      else if (currentUser && currentUser.loggedInFrom) {
        this.setState({ loggedInFrom: currentUser.loggedInFrom });
      }

      // const currentPartner = this.props.userData && this.props.userData.partner ? this.props.userData.partner : null;

      const currentPartner = auth.getCurrentPartner();

      if (currentPartner && currentPartner.isReviewDisabled)
        this.setState({ disabled: currentPartner.isReviewDisabled });

      if (currentPartner && currentPartner.callBackUrl)
        this.setState({ callBackUrl: currentPartner.callBackUrl });

      await this.mapDataToState(this.props.reviewData);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
  doSubmit = async () => {
    await this.insertUpdateDescription();
  };
  async handleCustomErrors(errors) {
    return errors;
  }
  closeRedirectionModel = () => {
    this.setState({ showRedirectionPopup: false });
  };
  async redirect() {
    let { redirectionUrl, isEnach } = this.state;

    this.setState({ showRedirectionPopup: false, showSpinner: true });

    if (isEnach)
      window.location = redirectionUrl;
    else
      window.location.href = redirectionUrl;
  }
}

export default ReviewFormModel;
