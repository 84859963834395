import React, { Component } from "react";
import axios from "axios";
import { diUrl } from "../../config.json";


class ExcelFilesModel extends Component {

    state = {
        partners: []
    }

    async getDownloadUrl(name) {

        let url = diUrl+"/api/excel/files/download/link?url="+name;
        let data = await axios.get(url);

        fetch(data.data, {
            method: 'GET'
        })
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a); 
            a.click();    
            a.remove();         
        });
        // axios.get(data.data);
    }

    async getExcelList() {

        let url = diUrl+"/api/excel/files";
        let data = await axios.get(url);

        console.log(data);
        this.setState({partners: data.data});
    }
}


export default ExcelFilesModel;