import React from "react";
import QuestionairreFormModel from "../../models/borrow/questionairreFormModel";
import auth from "../../services/common/authService";
import { Form, Col, Button } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import { isMobile } from 'react-device-detect';

class QuestionairreForm extends QuestionairreFormModel {
  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    const { data, errors, degreeList, showSpinner, isSubmitted, kycStatus, questionnairre } = this.state;

    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} /> <br />
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <h2>Additional Details</h2>{" "}
            {isMobile && kycStatus === "Approved" && (
              <Button
                className="ff-button"
                type="submit"
                disabled={isSubmitted}
                style={{ marginLeft: 20, marginTop: 1, marginBottom: 7, float: "right" }}
              >
                Next
              </Button>
            )}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="whatsApp">
              <Form.Label>Do you have WhatsApp in Mobile?{questionnairre && questionnairre.whatsApp && "*"}</Form.Label>
              <Form.Control
                as="select"
                name="whatsApp"
                value={data["whatsApp"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="yes" value="Yes">
                  Yes
                </option>
                <option key="no" value="No">
                  No
                </option>
              </Form.Control>
              {errors["whatsApp"] && (
                <div className="alert alert-danger">
                  {errors["whatsApp"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="gender">
              <Form.Label>Gender{questionnairre && questionnairre.gender && "*"}</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={data["gender"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="male" value="Male">
                  Male
                </option>
                <option key="female" value="Female">
                  Female
                </option>
                <option key="transgender" value="Transgender">
                  Transgender
                </option>
              </Form.Control>
              {errors["gender"] && (
                <div className="alert alert-danger">
                  {errors["gender"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="maritalStatus">
              <Form.Label>Marital Status{questionnairre && questionnairre.maritalStatus && "*"}</Form.Label>
              <Form.Control
                as="select"
                name="maritalStatus"
                value={data["maritalStatus"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="married" value="Married">
                  Married
                </option>
                <option key="single" value="Single">
                  Single
                </option>
              </Form.Control>
              {errors["maritalStatus"] && (
                <div className="alert alert-danger">
                  {errors["maritalStatus"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="children">
              <Form.Label>Do you have children?{questionnairre && questionnairre.children && "*"}</Form.Label>
              <Form.Control
                as="select"
                name="children"
                value={data["children"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="yes" value="Yes">
                  Yes
                </option>
                <option key="no" value="No">
                  No
                </option>
              </Form.Control>
              {errors["children"] && (
                <div className="alert alert-danger">
                  {errors["children"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="homeOwnershipType">
              <Form.Label>Do you rent or own a home?{questionnairre && questionnairre.homeOwnershipType && "*"}</Form.Label>
              <Form.Control
                as="select"
                name="homeOwnershipType"
                value={data["homeOwnershipType"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="rent" value="Rent">
                  Rent
                </option>
                <option key="own" value="Own">
                  Own
                </option>
                <option key="both" value="Both">
                  Both
                </option>
              </Form.Control>
              {errors["homeOwnershipType"] && (
                <div className="alert alert-danger">
                  {errors["homeOwnershipType"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="vehicle">
              <Form.Label>Do you own a vehicle?{questionnairre && questionnairre.vehicle && "*"}</Form.Label>
              <Form.Control
                as="select"
                name="vehicle"
                value={data["vehicle"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="2-wheeler" value="2-Wheeler">
                  2-Wheeler
                </option>
                <option key="4-wheeler" value="4-Wheeler">
                  4-Wheeler
                </option>
                <option key="both" value="Both">
                  Both
                </option>
                <option key="no" value="No">
                  No
                </option>
              </Form.Control>
              {errors["vehicle"] && (
                <div className="alert alert-danger">
                  {errors["vehicle"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="monthlyOutgoing">
              <Form.Label>
                Monthly Expenses/ Expenditure?{questionnairre && questionnairre.monthlyOutgoing && "*"}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Average monthly outgoings"
                name="monthlyOutgoing"
                value={data["monthlyOutgoing"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
              {errors["monthlyOutgoing"] && (
                <div className="alert alert-danger">
                  {errors["monthlyOutgoing"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="monthlyIncome">
              <Form.Label>
                Monthly Income/ Earnings?{questionnairre && questionnairre.monthlyIncome && "*"}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Average Monthly Income"
                name="monthlyIncome"
                value={data["monthlyIncome"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
              {errors["monthlyIncome"] && (
                <div className="alert alert-danger">
                  {errors["monthlyIncome"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="jobType">
              <Form.Label>
                Have you ever had Public and/or Private Sector Job?{questionnairre && questionnairre.jobType && "*"}
              </Form.Label>
              <Form.Control
                as="select"
                name="jobType"
                value={data["jobType"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="private" value="Private">
                  Private
                </option>
                <option key="public" value="Public">
                  Public
                </option>
                <option key="both" value="Both">
                  Both
                </option>
                <option key="no" value="No">
                  No
                </option>
              </Form.Control>
              {errors["jobType"] && (
                <div className="alert alert-danger">
                  {errors["jobType"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="purchasedInOneYear">
              <Form.Label>
                Have you purchased TV or Fridge in last 24 months?{questionnairre && questionnairre.purchasedInOneYear && "*"}
              </Form.Label>
              <Form.Control
                as="select"
                name="purchasedInOneYear"
                value={data["purchasedInOneYear"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="yes" value="Yes">
                  Yes
                </option>
                <option key="no" value="No">
                  No
                </option>
              </Form.Control>
              {errors["purchasedInOneYear"] && (
                <div className="alert alert-danger">
                  {errors["purchasedInOneYear"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="degree">
              <Form.Label>
                Which is the highest degree or level of school you have
                completed?{questionnairre && questionnairre.degree && "*"}
              </Form.Label>
              <Form.Control
                as="select"
                name="degree"
                value={data["degree"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                {degreeList.map((item, i) => (
                  <option key={i} value={item.defination}>
                    {item.defination}
                  </option>
                ))}
              </Form.Control>
              {errors["degree"] && (
                <div className="alert alert-danger">
                  {errors["degree"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="recommendedBy">
              <Form.Label>
                Have you been recommended by a friend? If yes provide Full Name{questionnairre && questionnairre.recommendedBy && "*"}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Recommended By"
                name="recommendedBy"
                value={data["recommendedBy"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
              {errors["recommendedBy"] && (
                <div className="alert alert-danger">
                  {errors["recommendedBy"]}
                </div>
              )}
            </Form.Group>

          </Form.Row>
          <Button className="ff-button" type="button" style={{ marginRight: 10, width: 150 }} onClick={() => this.props.setActiveTab("activeTab", "shopTab")}>
            Back
          </Button>
          <Button
            className="ff-button"
            type="submit"
            disabled={isSubmitted}
            style={{ width: 150 }}
          >
            Next
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}

export default QuestionairreForm;
