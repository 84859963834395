import React from "react";
import { Form, Col, Button, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import DatePicker from "react-datepicker2";
import PayOutModel from "../../models/payOut/payOutModel";
import ReactExport from "react-export-excel";
class PayOut extends PayOutModel {

    render() {
        const { data, exportList, showSpinner, errors, pageSize, currentPage, successMsg, isSubmitExport, list } = this.state;
        // const { totalCount, data: list } = this.getPagedData();
        let i = 0;
        return (<React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>PayOut</h2>
            {" "}
            <br />
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId="fromDate">
                        <Form.Label>Date</Form.Label>
                        <DatePicker
                            name="fromDate"
                            id="fromDate"
                            value={data["fromDate"]}
                            max={moment()}
                            inputFormat="DD/MM/YYYY"
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleFromDateChange(value)}
                            placeholder="Enter From Date"
                        />
                        {this.state.showDateFilterNote ? <Form.Text className="text-muted">{this.state.dateFilterNoteText || ""}</Form.Text> : ""}
                    </Form.Group>
                    {/* <Form.Group as={Col} md="4" controlId="toDate">
                        <Form.Label>To Date</Form.Label>
                        <DatePicker
                            name="toDate"
                            id="toDate"
                            value={data["toDate"]}
                            max={moment()}
                            inputFormat="DD/MM/YYYY"
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleToDateChange(value)}
                            placeholder="Enter To Date"
                        />
                    </Form.Group> */}
                    <Form.Group as={Col} md="4">
                        <Button
                            className="ff-button"
                            type="submit"
                            style={{ marginTop: 30, marginLeft: 10 }}
                        >
                            Apply Filters
                        </Button>
                        <Button
                            className="ff-button"
                            type="button"
                            onClick={this.handleExport}
                            style={{ marginTop: 30, marginLeft: 10 }}
                            disabled={showSpinner}
                        >
                            Export Emis
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            <br />
            {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
            {successMsg && <div className="alert alert-success">{successMsg}</div>}
            <br />
            <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        <th>Lender</th>
                        <th>Borrowing Partner</th>
                        <th>Emi's</th>
                        <th>Amount</th>
                        <th>Deposit Date</th>
                        <th>UTR No</th>
                        <th>Transaction Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {list &&
                        list.map((item) => {
                            i += 1;
                            return (
                                <tr key={item.lenderId + "_" + item.depositDate + "_" + item.partnerCode}>
                                    <td>{item.lenderName == "INDITRADE FINCORP LIMITED" ? "INDITRADE" : item.lenderName}</td>
                                    <td>{item.borrowingPartnerName == "EKO INDIA FINANCIAL SERVICES PRIVATE LIMITED" ? "Eko" : (item.borrowingPartnerName == "CHAMPION SOFTWARE TECHNOLOGIES LTD" ? "MPay" : (item.borrowingPartnerName == "MINDSARRAY NETWORK PRIVATE LIMITED" ? "Pay1" : item.borrowingPartnerName))}</td>
                                    <td>{item.emis}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.depositDate}</td>
                                    <td>{item.isAllowedPayOut == true ?
                                        <Form.Control
                                            type="text"
                                            name={"UTRNO_" + item.lenderId + "_" + item.depositDate + "_" + item.partnerCode}
                                            maxLength="50"
                                            value={item.utrNo}
                                            onChange={this.handleRecordChange}
                                            ref={"UTRNO_" + item.lenderId + "_" + item.depositDate + "_" + item.partnerCode}
                                        /> : item.utrNo}</td>
                                    <td>{item.transactionDate == null ? "" : moment(item.transactionDate).locale('en').format('DD/MM/YYYY hh:mm a')}</td>
                                    <td>
                                        {item.isAllowedPayOut == true ? <a href="#" onClick={() => this.handlePayOut("payOut_" + item.lenderId + "_" + item.depositDate + "_" + item.partnerCode)}>PayOut</a> : ""}
                                    </td>
                                </tr>
                            );
                        })}
                    {!list && (
                        <tr key="no">
                            <td colSpan="10">No record found</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <div style={{ width: "100%", overflowX: "scroll" }}>
                <Pagination
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageChange={this.onPageChange}
                    onPagePrevious={this.onPagePrevious}
                    onPageNext={this.onPageNext}
                />
            </div>
        </React.Fragment>
        )
    }
}

export default PayOut;