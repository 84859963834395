import React from "react";
import SpinnerComponent from "./../../components/common/spinner";
import DigilockerModel from "../../models/borrow/digilockerModel";
import { Form, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { isMobile } from 'react-device-detect';

class Digilocker extends DigilockerModel {
    render() {
        const { showSpinner, isSubmitted, redirect, loanId, isForceDigilocker } = this.state;

        return (
            <React.Fragment>
                {" "}
                <SpinnerComponent showSpinner={showSpinner} />
                <Form onSubmit={this.handleSubmit}>
                    <p>Your <b>Aadhaar</b> and <b>PAN</b> is needed to complete application process! No need to upload any file!!!</p>

                    <p>Make sure you have the mobile number mapped to your Aadhaar Number.</p>

                    <ol>
                        <li>
                            Enter Your <b>Aadhaar Number</b>
                        </li>
                        <li>
                            Enter <b>OTP</b> sent to Aadhaar Linked Mobile Number
                        </li>
                    </ol>

                    <br />
                    <Button
                        className="ff-button"
                        type="Next"
                        disabled={isSubmitted}
                        style={{ width: isMobile ? "100%" : 150 }}
                    >
                        Next
                    </Button>
                    {
                        !isForceDigilocker ? <p style={{ marginTop: 15 }}>
                            <a href="#" style={{ textDecoration: "underline" }} onClick={() => this.redirectBack()}>
                                {" "}
                                Don't have mobile number linked to Aadhaar Number.
                            </a>
                        </p> :""
                    }


                </Form>
                {redirect && (
                    <Redirect
                        to={{
                            pathname: `/applyLoan/${loanId}`,
                            state: { "activeTab": "kycTab" },
                        }}
                    />
                )}
            </React.Fragment >
        );
    }
}

export default Digilocker;
