import React from "react";
import UserContext from "../../context/userContext";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import PieChartComponent from "./pieChart";
import InputDataForm from "./form";
import Joi from "joi-browser";
import SpinnerComponent from "./spinner";
import * as homeService from "../../services/loan/loanService";
import NumberFormat from "react-number-format";

class EmiCalculator extends InputDataForm {
    static contextType = UserContext;
    state = {
        data: { amount: 25000, interest: 15, tenure: 90, frequency: "daily" },
        errors: {},
        showSpinner: false,
        emi: 0,
        interest: 0,
        total: 0,
        principal: 0,
        interestPerc: 0,
        principalPerc: 0,
        showHeader: false
    };
    schema = {
        amount: Joi.number().required().label("Loan Amount"),
        interest: Joi.number().required().label("Interest Rate"),
        tenure: Joi.number().required().label("Tenure"),
        frequency: Joi.string().required().label("Frequency"),
    };
    async componentDidMount() {
        let { data } = this.state;

        const response = await homeService.getEmiCalculatorData(data);

        this.setState({
            emi: response.emi,
            interest: response.interestRecovered,
            principal: response.principalRecovered,
            total: response.total,
            interestPerc: response.interestPerc,
            principalPerc: response.principalPerc,
            showHeader: window.location.pathname.includes("calculator") ? true : false
        });
    }
    async handleCustomErrors() {
        let errors = { ...this.state.errors };

        return errors;
    }
    doSubmit = async () => {
        let { data } = this.state;

        this.setState({ showSpinner: true });

        const response = await homeService.getEmiCalculatorData(data);

        this.setState({
            showSpinner: false,
            emi: response.emi,
            interest: response.interestRecovered,
            principal: response.principalRecovered,
            total: response.total,
            interestPerc: response.interestPerc,
            principalPerc: response.principalPerc,
        });
    };
    render() {
        let {
            data,
            errors,
            showSpinner,
            emi,
            interest,
            principal,
            total,
            interestPerc,
            principalPerc,
            showHeader
        } = this.state;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <div className="col-md-12">
                    {showHeader && (<h2 className="fundfinaColor" style={{ textAlign: "center" }}>
                        EMI Calculator
                    </h2>)}
                    <br />
                    <p>
                        <b>
                            See your personalised loan rates in just a minute. Checking the
                            rates won't affect your credit score.
                        </b>
                    </p>
                    <div
                        className="col-md-4"
                        style={{
                            backgroundColor: "gainsboro",
                            float: "left",
                            padding: 0,
                        }}
                    >
                        {" "}
                        <p
                            style={{
                                textAlign: "center",
                                padding: 10,
                                backgroundColor: "#b51b1f",
                                width: "100%",
                                color: "white",
                            }}
                        >
                            EMI CALCULATOR
                        </p>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group
                                controlId="amount"
                                style={{ paddingLeft: 15, paddingRight: 15 }}
                            >
                                <Form.Label>Loan Amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">
                                            <i className="fa fa-inr" aria-hidden="true"></i>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        placeholder="Enter Loan Amount"
                                        value={data["amount"]}
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        maxLength="6"
                                    />{" "}
                                </InputGroup>{" "}
                                {errors["amount"] && (
                                    <div className="alert alert-danger">{errors["amount"]}</div>
                                )}
                            </Form.Group>
                            <Form.Group
                                controlId="interest"
                                style={{ paddingLeft: 15, paddingRight: 15 }}
                            >
                                <Form.Label>Interest Rate</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">
                                            <i className="fa fa-percent" aria-hidden="true"></i>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        name="interest"
                                        placeholder="Enter Interest Rate"
                                        value={data["interest"]}
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        maxLength="4"
                                    />{" "}
                                </InputGroup>{" "}
                                {errors["interest"] && (
                                    <div className="alert alert-danger">{errors["interest"]}</div>
                                )}
                            </Form.Group>
                            <Form.Group
                                controlId="tenure"
                                style={{ paddingLeft: 15, paddingRight: 15 }}
                            >
                                <Form.Label>Loan Tenure</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">
                                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        name="tenure"
                                        placeholder="Enter Tenure"
                                        value={data["tenure"]}
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        maxLength="3"
                                    />{" "}
                                    <Form.Control
                                        as="select"
                                        name="frequency"
                                        value={data["frequency"]}
                                        onChange={this.handleChange}
                                    >
                                        {" "}
                                        <option key="daily" value="daily">
                                            Days
                                        </option>
                                        <option key="weekly" value="weekly">
                                            Weeks
                                        </option>
                                        <option key="forthnightly" value="fortnightly">
                                            Fortnights
                                        </option>
                                        <option key="months" value="monthly">
                                            Months
                                        </option>
                                    </Form.Control>
                                </InputGroup>{" "}
                                {errors["tenure"] && (
                                    <div className="alert alert-danger">{errors["tenure"]}</div>
                                )}
                            </Form.Group>
                            <Button
                                className="ff-button"
                                variant="primary"
                                type="submit"
                                style={{ margin: 20 }}
                            >
                                Calculate
                            </Button>{" "}
                            <Link to="/borrow">
                                <Button
                                    className="ff-button"
                                    type="button"
                                    style={{ margin: 20, float: "right" }}
                                >
                                    Apply Now
                                </Button>
                            </Link>
                        </Form>
                    </div>
                    <div className="col-md-4" style={{ float: "left" }}>
                        {" "}
                        <Form.Group controlId="amount">
                            <br />
                            <Form.Label style={{ textAlign: "center", width: "100%" }}>
                                Loan EMI
                            </Form.Label>{" "}
                            <br />
                            <Form.Label
                                style={{ fontSize: 20, textAlign: "center", width: "100%" }}
                            >
                                {emi && <i className="fa fa-inr" aria-hidden="true"></i>}
                                {
                                    <NumberFormat
                                        value={emi}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                }
                            </Form.Label>
                        </Form.Group>
                        <Form.Group controlId="amount">
                            <br />
                            <Form.Label style={{ textAlign: "center", width: "100%" }}>
                                Total Interest Payable
                            </Form.Label>{" "}
                            <br />
                            <Form.Label
                                style={{ fontSize: 20, textAlign: "center", width: "100%" }}
                            >
                                {interest && <i className="fa fa-inr" aria-hidden="true"></i>}
                                {
                                    <NumberFormat
                                        value={interest}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                }
                            </Form.Label>
                        </Form.Group>{" "}
                        <Form.Group controlId="amount">
                            <br />
                            <Form.Label style={{ textAlign: "center", width: "100%" }}>
                                Total of Payments <br />
                                Principal + Interest
                            </Form.Label>{" "}
                            <br />
                            <Form.Label
                                style={{ fontSize: 20, textAlign: "center", width: "100%" }}
                            >
                                {total && <i className="fa fa-inr" aria-hidden="true"></i>}
                                {
                                    <NumberFormat
                                        value={total}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                }
                            </Form.Label>{" "}
                            <br />
                        </Form.Group>
                    </div>
                    <div className="col-md-4" style={{ float: "left" }}>
                        {principalPerc != 0 && (
                            <PieChartComponent
                                interestPerc={interestPerc}
                                principalPerc={principalPerc}
                            />
                        )}
                    </div>
                </div>{" "}
                <div
                    className="col-md-12"
                    style={{
                        float: "left",
                        backgroundColor: "#b51b1f",
                        color: "white",
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    Representative example based on your input. The rate and fee you are
                    offered will depend on your individual circumstances.
                </div>
            </React.Fragment>
        );
    }
}

// Movies.contextType = UserContext;

export default EmiCalculator;
