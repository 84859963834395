import React from "react";
import "../../styles.css";

import { Line } from "react-chartjs-2";



const LineChartComponent = ({ label, score,labelName }) => {
    const data = {
        labels: label,
        datasets: [
            {
                label: labelName || "TrueScore",
                data: score,
                fill: false,
                backgroundColor: "#ffcccb",
                borderColor: "#b51b1f",
                tension: 0
            },
        ],

    };

    var options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0
                }
            }]
        }
    };
    return (
        <div className="App">
            <Line data={data} options={options} />
        </div>
    );
};

export default LineChartComponent;