import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Select, { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { debounce } from 'lodash';

const height = 37; // Height of each item
// const width = 300; // Width of each item

const MenuList = ({ options, children, getValue }) => {
  const [value] = getValue();
  const initialOffset = options.findIndex(option => option.value === value) * height;

  return (
    <List
      height={height * 10} // Show 10 items at a time
      itemCount={children.length}
      itemSize={height}
    //   width={width}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
          {children[index]}
        </div>
      )}
    </List>
  );
};

const CustomDropdown = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('Other');

  const fetchOptions = useCallback(debounce(async (input) => {
    const response = await fetch(`https://app.fundfina.com/fetchCompanyList?search=${input}`);
    const data = await response.json();
    setOptions(data.map(option => ({ value: option, label: option })));
  }, 300), []);

  useEffect(() => {
    if (inputValue) {
      fetchOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue, fetchOptions]);

  return (
    <Select
      placeholder="Search your company..."
      components={{ MenuList }}
      options={options}
      value={options.find(option => option.value === value)}
      onChange={selected => onChange(selected.value)}
      onInputChange={(inputValue) => setInputValue(inputValue)}
      styles={{
        control: (provided) => ({
          ...provided,
        //   width: width,
        }),
        menu: (provided) => ({
          ...provided,
        //   width: width,
        }),
      }}
    />
  );
};

export default CustomDropdown;

