import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class LenderSettlementsModel extends InputDataForm {
  state = {
    data: {
      transactions: [],
      id: ""
    },
    showSpinner: false,
    date: "",
    lendingPartner: ""
  };
  schema = {
    transactions: Joi.any(),
    id: Joi.any()
  };

  async getPayoutTransactionDetails() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      let response = await loanService.getPayoutTransactionDetails(data);

      if(response && response.data)
        response = response.data
        
      data.transactions = response && response.data || [];
      this.state.lendingPartner = response.lendingPartner;
      this.state.date = response.transactionDate;
      this.setState({ data, showSpinner: false });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    if (this.props.id) {
      this.state.data.id = this.props.id
    }
    await this.getPayoutTransactionDetails();

  };
  async handleCustomErrors(errors) {
    return errors;
  };
}

export default LenderSettlementsModel;
