import InputDataForm from "../../components/common/form";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import * as reconService from "../../services/recon/reconService";
import { toast } from "react-toastify";

class FileUploaderModel extends InputDataForm {

    constructor(props) {
        super(props);
    }

    state = {
        status: "Drop file here",
        percentage: 0,
        preview: null,
        enableDragDrop: true,
        uploadedURL: "",
        showProgress: false
    }

    initateUpload = async (file, fileName) => {

        try {
            if (!file) {
                return
            }

            this.getSignedURLAndUpdate(file, "SETTLEMENT_MIS", async (err, uploadedFile) => {

                if (err) {
                    toast.error("Oops something went wrong, please retry");
                    this.clearImage()
                    return;
                }

                this.insertMISLog(uploadedFile, fileName)

            })
        } catch (error) {
            toast.error("Oops something went wrong,  please retry");
            this.clearImage()
        }
    }

    getSignedURLAndUpdate = async (file, type, callback) => {

        try {
            let payload = {
                fileName: file.name,
                type
            };

            let response = await commonService.getSignedUrl(payload)

            if (response && response.data && response.data.data && response.data.data.signedUrl) {
                this.uploadFile(response?.data?.data?.signedUrl, file, (err, data) => {
                    callback && callback(null, response?.data?.data?.name)
                })
            } else {
                callback && callback({ message: "Error in singed url get" }, null)
            }
        } catch (error) {
            callback && callback(error, null)
        }
    }

    uploadFile = (signedUrl, file, callback) => {

        this.setState({ showProgress: true })
        commonService.uploadFileAwsS3(signedUrl, file, (progress) => {
            let progressPercentage = ((progress.loaded / progress.total) * 100).toFixed(2);

            if (progressPercentage && progressPercentage == 100)
                progressPercentage = 95;

            this.setProgress(progressPercentage);
        })
            .then(response => {
                let splittedSignedUrl = signedUrl.split("?");
                callback && callback(null, splittedSignedUrl[0])
            })
            .catch(error => {
                callback && callback(error, null)
                console.log(error);
            });
    };

    setProgress(percentage) {
        this.setState({ percentage: percentage });
    }

    onDragEnter = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop' });
        }
        event.stopPropagation();
        event.preventDefault();
    };

    onDragLeave = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop Here' });
        }
        event.preventDefault();
    };

    onDragOver = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop' });
        }
        event.preventDefault();
    };

    onDrop = event => {
        const { type } = event.dataTransfer.files[0];
        const supportedFilesTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
        if (supportedFilesTypes.indexOf(type) > -1 && this.state.enableDragDrop) {
            let file = event.dataTransfer.files[0];
            this.setState({ fileName: file.name })
            this.initateUpload(file);
        }
        event.preventDefault();
    };

    setPreview = (data) => {
        this.setState({ preview: data });
    }

    clearImage = () => {
        this.setState({
            preview: null,
            uploadedURL: "",
            showProgress: false,
            percentage: 0,
            fileName: ""
        });
    }

    insertMISLog = async (uploadedFile, fileName) => {
        try {
            await reconService.insertMISLog({ file: uploadedFile, fileName })
            this.setProgress(100)
        } catch (error) {
            this.clearImage()
            toast.error("Oops something went wrong, please retry");
        }
    }

    onImageUpload(event) {
        let file = event.target.files[0];
        if (!file)
            return;

        const { type } = file
        const supportedFilesTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
        if (supportedFilesTypes.indexOf(type) > -1 && this.state.enableDragDrop) {
            this.setState({ fileName: file.name })
            this.initateUpload(file, file.name);
        }
    }
}

export default FileUploaderModel;