
import React, { useContext } from "react";
import MerchantAuthenticateModel from "../../models/partner/merchantAuthenticateModel";
import { Form, Col, Nav, Button, Modal } from "react-bootstrap";
import SpinnerComponent from "./../../components/common/spinner";
import _ from "lodash";
import { isMobile } from 'react-device-detect';

class MerchantAuthenticate extends MerchantAuthenticateModel {
  render() {
    const {
      data,
      errors,
      info,
      showSpinner,
      isRegenerateLink,
      isRegenerateText,
      isSubmitted,
      counter,
      messageText,
      otpSent,
      maskedMobileNo,
      showConfirmMobilePopup,
      showUpdateMobile
    } = this.state;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <Form onSubmit={this.doSubmit}>
          <h2>Verify mobile number</h2>
          {maskedMobileNo && otpSent && (
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="label">
                <Form.Label>{messageText}</Form.Label>
              </Form.Group>
            </Form.Row>
          )}
          {!showUpdateMobile && maskedMobileNo && !otpSent && (
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="label">
                <Form.Label>{data.isWhatsApp === "checked" ? "WhatsApp" : "SMS"} OTP will be sent to {maskedMobileNo}</Form.Label>
              </Form.Group>
            </Form.Row>)}{" "}
          {showUpdateMobile && !otpSent && (
            <React.Fragment>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="mobileNo">
                  <Form.Label>Enter your Mobile No*</Form.Label>
                  <Form.Control
                    type="text"
                    pattern="[0-9]*"
                    placeholder="Enter your mobile number"
                    name="mobileNo"
                    value={data["mobileNo"]}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPressNumeric}
                    autoComplete="off"
                    maxLength="10"
                    autoFocus={true}
                  // disabled={isValidOTP}
                  />
                  {errors["mobileNo"] && (
                    <div className="alert alert-danger">{errors["mobileNo"]}</div>
                  )}
                  {info["mobileNo"] && (
                    <div className="alert alert-success">{info["mobileNo"]}</div>
                  )}
                </Form.Group>
              </Form.Row>
            </React.Fragment>)}
          {maskedMobileNo && !otpSent && (
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="isWhatsApp">
                <Form.Check
                  type="radio"
                  id="isWhatsApp"
                  label="I confirm that above number is also my WhatsApp number and I also provide consent to Fundfina to sending messages and notifications on the WhatsApp Number"
                  name="isWhatsApp"
                  checked={data.isWhatsApp ? "checked" : ""}
                  onChange={() => this.handleWhatsAppConsent(true)}
                />
                <Form.Check
                  type="radio"
                  id="noWhatsApp"
                  label="I don't have a WhatsApp Number"
                  name="noWhatsApp"
                  checked={data.isWhatsApp ? "" : "checked"}
                  onChange={() => this.handleWhatsAppConsent(false)}
                />
              </Form.Group>
            </Form.Row>)}
          {showUpdateMobile && !otpSent && (
            <Button
              className="ff-button"
              type="button"
              disabled={isSubmitted}
              onClick={() => this.sendOtpForMobile(data.loanId)}
              style={{ width: isMobile ? "100%" : 200 }}
            >
              Send {data.isWhatsApp === "checked" ? "WhatsApp" : "SMS"} OTP
            </Button>
          )}
          {!otpSent && maskedMobileNo && !showUpdateMobile &&
            <Button
              className="ff-button"
              type="button"
              disabled={isSubmitted}
              onClick={() => this.sendOtp(data.loanId)}
              style={{ width: isMobile ? "100%" : 200 }}
            >
              Send {data.isWhatsApp === "checked" ? "WhatsApp" : "SMS"} OTP
            </Button>
          }
          {otpSent && (
            <React.Fragment>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="otp">
                  <Form.Label>Enter OTP*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter OTP"
                    name="otp"
                    value={data["otp"]}
                    onChange={this.handleOTPKeyChange}
                    maxLength="4"
                    autoFocus={true}
                    autoComplete="one-time-code"
                    onKeyPress={this.handleKeyPressNumeric}
                  />{" "}
                  <Form.Text className="text-muted">
                    Enter 4-digit {data.isWhatsApp === "checked" ? "WhatsApp" : "SMS"} OTP sent to your mobile
                  </Form.Text>
                  {info["otp"] && (
                    <Form.Text
                      className="text-muted"
                      style={{ color: "green" }}
                    >
                      {info["otp"]}
                    </Form.Text>
                  )}
                  {errors["otp"] && (
                    <div className="alert alert-danger">{errors["otp"]}</div>
                  )}{" "}
                  {isRegenerateLink && (
                    <Nav.Item id="regenerateOTP">
                      <Nav.Link
                        className="underline"
                        onClick={() => this.sendOtp(data.loanId)}
                        style={{
                          padding: 0,
                          float: "right",
                          textDecoration: "underline",
                        }}
                      >
                        Regenerate OTP
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {isRegenerateText && (
                    <Form.Label>{`Regenerate OTP in ${counter} seconds.`}</Form.Label>
                  )}
                </Form.Group>
              </Form.Row>
              <Button
                className="ff-button"
                type="submit"
                disabled={isSubmitted}
                style={{ width: isMobile ? "100%" : 150 }}
              >
                Authenticate
              </Button>
              <Form.Group
                controlId="globalError"
                style={{ marginTop: 30 }}
              ></Form.Group>{" "}
            </React.Fragment>
          )}
          {this.state.allowEdit && (!showUpdateMobile || otpSent) && (
            <p style={{ marginTop: 10 }}>
              <a href="#" style={{ textDecoration: "underline" }} onClick={() => this.showUpdateMobile()}>
                {" "}
                Change mobile number
              </a>
            </p>
          )}
          {errors["globalError"] && (
            <div className="alert alert-danger" style={{ marginTop: 10 }}>{errors["globalError"]}</div>
          )}
          <Modal
            show={showConfirmMobilePopup}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton={false}>
              <b>   <i className="fa fa-info-circle fa-lg" aria-hidden="true" style={{ color: "blue" }}></i>{" "}Please Note</b>
            </Modal.Header>
            <Modal.Body>
              I confirm that my current <b>WhatsApp</b> mobile number is <b>{maskedMobileNo}</b>.
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.sendOtp(data.loanId)} className="ff-button" style={{ width: isMobile ? "100%" : 150 }}>
                Yes
              </Button>
              <Button onClick={this.handleCloseFinalPopUp} className="ff-button" style={{ width: isMobile ? "100%" : 150 }}>
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      </React.Fragment>
    );
  }
}
export default MerchantAuthenticate;
