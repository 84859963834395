import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import LoanTableModel from "../../models/teleCalling/loanTableModel";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";
import { Tabs, Tab } from "react-bootstrap";
import LoanRenewals from './loanRenewal'


class LoanTable extends LoanTableModel {
  getPagedData = () => {
    const { pageSize, currentPage, data } = this.state;

    let loans = data.loans;

    // const filteredLoans = paginate(loans, currentPage, pageSize);

    return { totalCount: loans.length, data: loans };
  };

  renderExcelExport = (activeTab) => {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let { allLoans } = this.state;

    if (activeTab == "All") {
      return (<ExcelFile hideElement={true} filename={`TeleCallingQueue_${moment(new Date()).format("DDMMYYYY")}`}>
        <ExcelSheet data={allLoans} name="teleCallingQueue">
          <ExcelColumn
            label="Date"
            value={(col) => {
              return col.updatedOn && moment(col.updatedOn).format("DD/MM/yyyy")
            }}
          ></ExcelColumn>
          <ExcelColumn
            label="Loan No."
            value="loanNo"
          ></ExcelColumn>

          <ExcelColumn
            label="Name"
            value={(col) => {
              return (col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.firstName ? col.userInfo.userDetails.firstName : "") + " " + (col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.lastName ? col.userInfo.userDetails.lastName : "")
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Merchant Code"
            value="merchantCode"
          ></ExcelColumn>

          <ExcelColumn
            label="Mobile Number"
            value={(col) => {
              return col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.mobileNo && col.userInfo.userDetails.mobileNo.length ? col.userInfo.userDetails.mobileNo[0].mobileNumber : ""
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Partner"
            value="partner"
          ></ExcelColumn>

          <ExcelColumn
            label="Amount"
            value={(col) => {
              return '₹' + col.amount
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="ROI"
            value={(col) => {
              return col.interestRate ? (col.interestRate) + '%' : "-"
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Tenure"
            value={(col) => this.getTenure(col)}
          ></ExcelColumn>


          <ExcelColumn
            label="EMI Amount"
            value={(col) => {
              return col.emiAmount ? ('₹' + col.emiAmount) : "-"
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Frequency"
            value="repaymentFrequency"
          ></ExcelColumn>

          <ExcelColumn
            label="Loan Status"
            value={(col) => {
              return col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.kycStatus == 'Review' ? 'KYC Review' : col.status
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Last Step"
            value="lastStepCompleted"
          ></ExcelColumn>

          <ExcelColumn
            label="Reasons for Failure"
            value="reasonForFailure"
          ></ExcelColumn>

          <ExcelColumn
            label="Last Step"
            value="lastStepCompleted"
          ></ExcelColumn>

          <ExcelColumn
            label="City"
            value={(col) => {
              return col.userInfo && col.userInfo.addressDetails && col.userInfo.addressDetails.length && col.userInfo.addressDetails[0].city || "-"
            }}
          ></ExcelColumn>
          <ExcelColumn
            label="State"
            value={(col) => {
              return col.userInfo && col.userInfo.addressDetails && col.userInfo.addressDetails.length && col.userInfo.addressDetails[0].state || "-"
            }}
          ></ExcelColumn>
          <ExcelColumn
            label="Pincode"
            value={(col) => {
              return col.userInfo && col.userInfo.addressDetails && col.userInfo.addressDetails.length && col.userInfo.addressDetails[0].pincode || ""
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Called On"
            value={(col) => {
              return col.teleCallingActions && col.teleCallingActions.length && new Date(col.teleCallingActions[col.teleCallingActions.length - 1].calledOn).toDateString() || "-"
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Borrower Response"
            value={(col) => {
              return col.teleCallingActions && col.teleCallingActions.length && col.teleCallingActions[col.teleCallingActions.length - 1].borrowerResponse || "-"
            }}
          ></ExcelColumn>

          <ExcelColumn
            label="Called By"
            value={(col) => {
              return col.calledByInfo && col.calledByInfo.name || "-"
            }}
          ></ExcelColumn>
        </ExcelSheet>
      </ExcelFile>)
    }

    return (<ExcelFile hideElement={true} filename={`TeleCallingQueue_${moment(new Date()).format("DDMMYYYY")}`}>
      <ExcelSheet data={allLoans} name="teleCallingQueue">
        <ExcelColumn
          label="Date"
          value={(col) => {
            return col.updatedOn && moment(col.updatedOn).format("DD/MM/yyyy")
          }}
        ></ExcelColumn>
        <ExcelColumn
          label="Loan No."
          value="loanNo"
        ></ExcelColumn>

        <ExcelColumn
          label="Called On"
          value={(col) => {
            return col.teleCallingActions && col.teleCallingActions.length && new Date(col.teleCallingActions[col.teleCallingActions.length - 1].calledOn).toDateString() || "-"
          }}
          hideElement={true}
        ></ExcelColumn>

        <ExcelColumn
          label={activeTab == 'Not Connected' ? "Call Outcome" : "Borrower Response"}
          value={(col) => {
            return activeTab == 'Not Connected' ? col.teleCallingActions && col.teleCallingActions.length && col.teleCallingActions[col.teleCallingActions.length - 1].notConnectedReason || "-" : col.teleCallingActions && col.teleCallingActions.length && col.teleCallingActions[col.teleCallingActions.length - 1].borrowerResponse || "-"
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Called By"
          value={(col) => {
            return col.calledByInfo && col.calledByInfo.name || "-"
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Name"
          value={(col) => {
            return (col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.firstName ? col.userInfo.userDetails.firstName : "") + " " + (col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.lastName ? col.userInfo.userDetails.lastName : "")
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Merchant Code"
          value="merchantCode"
        ></ExcelColumn>

        <ExcelColumn
          label="Mobile Number"
          value={(col) => {
            return col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.mobileNo && col.userInfo.userDetails.mobileNo.length ? col.userInfo.userDetails.mobileNo[0].mobileNumber : ""
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Partner"
          value="partner"
        ></ExcelColumn>

        <ExcelColumn
          label="Amount"
          value={(col) => {
            return '₹' + col.amount
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="ROI"
          value={(col) => {
            return col.interestRate ? (col.interestRate) + '%' : "-"
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Tenure"
          value={(col) => this.getTenure(col)}
        ></ExcelColumn>


        <ExcelColumn
          label="EMI Amount"
          value={(col) => {
            return col.emiAmount ? ('₹' + col.emiAmount) : "-"
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Frequency"
          value="repaymentFrequency"
        ></ExcelColumn>

        <ExcelColumn
          label="Loan Status"
          value={(col) => {
            return col.userInfo && col.userInfo.userDetails && col.userInfo.userDetails.kycStatus == 'Review' ? 'KYC Review' : col.status
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Last Step"
          value="lastStepCompleted"
        ></ExcelColumn>

        <ExcelColumn
          label="Reasons for Failure"
          value="reasonForFailure"
        ></ExcelColumn>

        <ExcelColumn
          label="Last Step"
          value="lastStepCompleted"
        ></ExcelColumn>

        <ExcelColumn
          label="City"
          value={(col) => {
            return col.userInfo && col.userInfo.addressDetails && col.userInfo.addressDetails.length && col.userInfo.addressDetails[0].city || "-"
          }}
        ></ExcelColumn>
        <ExcelColumn
          label="State"
          value={(col) => {
            return col.userInfo && col.userInfo.addressDetails && col.userInfo.addressDetails.length && col.userInfo.addressDetails[0].state || "-"
          }}
        ></ExcelColumn>
        <ExcelColumn
          label="Pincode"
          value={(col) => {
            return col.userInfo && col.userInfo.addressDetails && col.userInfo.addressDetails.length && col.userInfo.addressDetails[0].pincode || ""
          }}
        ></ExcelColumn>

        {/* <ExcelColumn
          label="Called On"
          value={(col) => {
            return col.teleCallingActions && col.teleCallingActions.length && new Date(col.teleCallingActions[col.teleCallingActions.length - 1].calledOn).toDateString() || "-"
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Call Outcome"
          value={(col) => {
            return col.teleCallingActions && col.teleCallingActions.length && col.teleCallingActions[col.teleCallingActions.length - 1].outcome || "-"
          }}
        ></ExcelColumn>

        <ExcelColumn
          label="Called By"
          value={(col) => {
            return col.calledByInfo && col.calledByInfo.name || "-"
          }}
        ></ExcelColumn> */}
      </ExcelSheet>
    </ExcelFile>)
  }

  renderHeaderTabs = () => {

    let { headerTabs, headerActiveTab } = this.state;

    let elements = []

    for (let i = 0; i < headerTabs.length; i++) {
      elements.push(
        <Tab eventKey={headerTabs[i].title} title={headerTabs[i].title}></Tab>
      )
    }

    return (<Tabs activeKey={headerActiveTab} id="uncontrolled-tab-example" onSelect={this.onChangeHeaderActiveTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  renderTabs = () => {

    let { tabs, activeTab } = this.state;

    let elements = []

    // for (let i = 0; i < tabs.length; i++) {
    //   elements.push(
    //     <div key={tabs[i].title || i} className={`d-inline-block mr-3 cursor-pointer ${activeTab == tabs[i].title ? "active" : ""}`} onClick={() => this.onChangeTab(tabs[i].title)}>{tabs[i].title}</div>
    //   )
    // }

    for (let i = 0; i < tabs.length; i++) {
      elements.push(
        <Tab eventKey={tabs[i].title} title={tabs[i].title}></Tab>
      )
    }

    // return <div className="mb-4 tele-calling-tab">{elements}</div>
    return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  getTenure = (loan) => {

    if (loan.maturity && loan.repaymentFrequency === "Monthly") {
      return `${loan.maturity} months`
    }

    if (loan.maturity && loan.repaymentFrequency === "Daily") {
      return `${loan.maturity} days`
    }

    if (loan.maturity && loan.repaymentFrequency === "Weekly") {
      return `${loan.maturity} weeks`
    }

    if (loan.maturity && loan.repaymentFrequency === "Forthnightly") {
      return `${loan.maturity} forthnights`
    }

    if (loan.maturity && loan.repaymentFrequency === "Adhoc") {
      return `${loan.tenure} days`
    }
  }

  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      loanStatusList,
      lastStepList,
      partners,
      evaluatorRole,
      successMsg,
      errors,
      allLoans,
      downloadExcel,
      activeTab,
      headerActiveTab
    } = this.state;

    const { totalCount, data: loans } = this.getPagedData();

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          TeleCalling Queue
        </h2>

        <div className="mt-3">
          {this.renderHeaderTabs()}
        </div>

        {headerActiveTab == "Renewals" ? <div className="mt-3"><LoanRenewals {...this.props}></LoanRenewals></div> : <div className="mt-3">
          <Form onSubmit={this.handleSubmit}>
            <Form.Row>

              {evaluatorRole === "Admin" && (
                <Form.Group as={Col} md="4" controlId="partner">
                  <Form.Label>Partner</Form.Label>
                  <Form.Control
                    as="select"
                    name="partner"
                    value={data["partner"]}
                    onChange={this.handleChange}
                  >
                    {" "}
                    <option key="all" value="all">
                      ALL
                    </option>
                    {partners && partners.map((partner) => (
                      <option key={partner.partnerName} value={partner.partnerName}>
                        {partner.displayName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>)}

              <Form.Group as={Col} md="4" controlId="mobileNo">
                <Form.Label>Mobile No</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNo"
                  value={data["mobileNo"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="merchantCode">
                <Form.Label>Merchant Code</Form.Label>
                <Form.Control
                  type="text"
                  name="merchantCode"
                  value={data["merchantCode"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="loanStatus">
                <Form.Label>Loan Status</Form.Label>
                <Form.Control
                  as="select"
                  name="loanStatus"
                  value={data["loanStatus"]}
                  onChange={this.handleLoanStatusChange}
                >
                  {" "}
                  <option key="select" value="">
                    ---Select---
                  </option>
                  {loanStatusList.map((item, i) => (
                    <option key={i} value={item.defination}>
                      {item.defination}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="lastStepCompleted">
                <Form.Label>Last Step</Form.Label>
                <Form.Control
                  as="select"
                  name="lastStepCompleted"
                  value={data["lastStepCompleted"]}
                  onChange={this.handleLastStepListChange}
                >
                  {" "}
                  <option key="select" value="">
                    ---Select---
                  </option>
                  {lastStepList.map((item, i) => (
                    <option key={i} value={item.defination}>
                      {item.defination}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={data["email"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                // style={{ textTransform: "uppercase" }}
                />
              </Form.Group>

            </Form.Row>

            <Form.Row>

              <Form.Group as={Col} md="4" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={data["city"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                // style={{ textTransform: "uppercase" }}
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={data["state"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                // style={{ textTransform: "uppercase" }}
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="pincode">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="text"
                  name="pincode"
                  value={data["pincode"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                // style={{ textTransform: "uppercase" }}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                {/* <Form.Label> </Form.Label>
              <br /> */}
                <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Apply Filters
                </Button>

                <Button
                  onClick={() => this.exportExcel()}
                  className="ff-button"
                  type="button"
                  style={{ marginTop: 10 }}
                >
                  Export to Excel
                </Button>

                {downloadExcel ? this.renderExcelExport(activeTab) : ""}

              </Form.Group>
            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}
          {/* <br /> */}

          {this.renderTabs()}

          <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} rightStickyColumnCount={1} leftStickyColumnCount={2}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Date</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan No.</b></Cell>

                {activeTab == 'Due Today' || activeTab == 'Up Coming' || activeTab == 'Called' || activeTab == 'Not Connected' ? <>
                  <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Called On</b></Cell>
                  <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>{activeTab == 'Not Connected' ? 'Call Outcome' : "Borrower Response"}</b></Cell>
                  <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Called By</b></Cell>
                </> : <></>}

                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Name</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Merchant Code</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Mobile Number</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Partner</b></Cell>
                <Cell style={{ minWidCell: 100, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Amount</b></Cell>
                <Cell style={{ minWidCell: 80, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>ROI</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Tenure</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>EMI Amount</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Frequency</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan Status</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Last Step</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reasons for Failure</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>City</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>State</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Pincode</b></Cell>

                {activeTab == 'All' ? <>
                  <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Called On</b></Cell>
                  <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Borrower Response</b></Cell>
                  <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Called By</b></Cell>
                </> : <></>}

                {/* {evaluatorRole === "Admin" && ( */}
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Application Link</b></Cell>
                {/* )} */}

                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Contract Link</b></Cell>

                {/* {evaluatorRole === "Admin" && ( */}
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Bank Link</b></Cell>
                {/* )} */}

                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Actions</b></Cell>
              </Row>
              {loans &&
                loans.map((loan, i) => (
                  <Row key={loan._id}>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{loan.updatedOn && moment(loan.updatedOn).format("DD/MM/yyyy")}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.loanNo}</Cell>

                    {activeTab == 'Due Today' || activeTab == 'Up Coming' || activeTab == 'Called' || activeTab == 'Not Connected' ? <>
                      <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.teleCallingActions && loan.teleCallingActions.length && new Date(loan.teleCallingActions[loan.teleCallingActions.length - 1].calledOn).toDateString() || "-"}</Cell>
                      <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{activeTab == 'Due Today' || activeTab == 'Up Coming' || activeTab == 'Called' ? loan.teleCallingActions && loan.teleCallingActions.length && loan.teleCallingActions[loan.teleCallingActions.length - 1].borrowerResponse || "-" :
                        activeTab == 'Not Connected' ? loan.teleCallingActions && loan.teleCallingActions.length && loan.teleCallingActions[loan.teleCallingActions.length - 1].notConnectedReason || "-" : loan.teleCallingActions && loan.teleCallingActions.length && loan.teleCallingActions[loan.teleCallingActions.length - 1].outcome || "-"}</Cell>
                      <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.calledByInfo && loan.calledByInfo.name || "-"}</Cell>
                    </> : <></>}

                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.userInfo && loan.userInfo.userDetails && loan.userInfo.userDetails.firstName ? loan.userInfo.userDetails.firstName : ""} {loan.userInfo && loan.userInfo.userDetails && loan.userInfo.userDetails.lastName ? loan.userInfo.userDetails.lastName : ""}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.merchantCode}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.userInfo && loan.userInfo.userDetails && loan.userInfo.userDetails.mobileNo && loan.userInfo.userDetails.mobileNo.length ? loan.userInfo.userDetails.mobileNo[0].mobileNumber : ""}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.partner}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                    </Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      {" "}
                      {loan.interestRate}
                      {loan.interestRate && (
                        <i className="fa fa-percent" aria-hidden="true"></i>
                      )}
                    </Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      {" "}
                      {loan.maturity &&
                        loan.repaymentFrequency === "Monthly" &&
                        `${loan.maturity} months`}
                      {loan.maturity &&
                        loan.repaymentFrequency === "Daily" &&
                        `${loan.maturity} days`}
                      {loan.maturity &&
                        loan.repaymentFrequency === "Weekly" &&
                        `${loan.maturity} weeks`}
                      {loan.maturity &&
                        loan.repaymentFrequency === "Forthnightly" &&
                        `${loan.maturity} forthnights`}
                      {loan.maturity &&
                        loan.repaymentFrequency === "Adhoc" &&
                        `${loan.tenure} days`}
                    </Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.emiAmount && <NumberFormat value={loan.emiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.repaymentFrequency}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      {loan.userInfo && loan.userInfo.userDetails && loan.userInfo.userDetails.kycStatus == 'Review' ? 'KYC Review' : loan.status}
                    </Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.lastStepCompleted}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.reasonForFailure}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.userInfo && loan.userInfo.addressDetails && loan.userInfo.addressDetails.length && loan.userInfo.addressDetails[0].city || ""}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.userInfo && loan.userInfo.addressDetails && loan.userInfo.addressDetails.length && loan.userInfo.addressDetails[0].state || ""}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.userInfo && loan.userInfo.addressDetails && loan.userInfo.addressDetails.length && loan.userInfo.addressDetails[0].pincode || ""}</Cell>

                    {activeTab == 'All' ? <>
                      <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.teleCallingActions && loan.teleCallingActions.length && new Date(loan.teleCallingActions[loan.teleCallingActions.length - 1].calledOn).toDateString() || "-"}</Cell>
                      <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.teleCallingActions && loan.teleCallingActions.length && loan.teleCallingActions[loan.teleCallingActions.length - 1].outcome || "-"}</Cell>
                      <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.calledByInfo && loan.calledByInfo.name || "-"}</Cell>
                    </> : <></>}
                    {/* {evaluatorRole === "Admin" && ( */}
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Button
                        onClick={() => this.handleSendReview(loan._id)}
                        className="ff-button"
                        disabled={loan.kycStatus === "Review" ? false : true}
                      >
                        Resend
                      </Button>
                    </Cell>
                    {/* )} */}

                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Button
                        onClick={() => this.handleSendContract(loan._id)}
                        className="ff-button"
                        disabled={(loan.status === "Loan Is Funding" || loan.status === "Review" || (loan.status === "Draft" && loan.isStp) || loan.isForceMandate) ? false : true}
                      >
                        Resend
                      </Button>
                    </Cell>

                    {/* {evaluatorRole === "Admin" && ( */}
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Button
                        onClick={() => this.handSendBankLink(loan._id)}
                        className="ff-button"
                        disabled={(loan.status === "Draft" || loan.status === "Under Review" || loan.status === "Approved By Admin" || loan.status === "Accepted By Client" || loan.status === "Loan Is Funding") &&
                          (loan.loanStepDetails && loan.loanStepDetails.find(x => x.Step === "Review" && x.isCompleted === true)) ? false : true}
                      >
                        Send
                      </Button>
                    </Cell>
                    {/* )} */}

                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Link
                        to={{
                          pathname: `/loanInfo/${loan._id}/teleCalling`,
                          customProps: data
                        }}
                      >
                        View
                      </Link>
                    </Cell>
                  </Row>
                ))}
              {!loans && (
                <Row>
                  <Cell Col={13}>No record found</Cell>
                </Row>
              )}

            </StickyTable>
          </div >
          <div style={{ width: "100%", overflowX: "scroll" }}>
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.onPageChange}
              onPagePrevious={this.onPagePrevious}
              onPageNext={this.onPageNext}
            />
          </div>
        </div >}
      </React.Fragment >
    );
  }
}

export default LoanTable;
