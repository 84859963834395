import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ManualQueueLoanRepaymentModel from "../../models/manualQueueLoanRepaymentsModel/manualQueueLoanRepaymentsModel";
import SpinnerComponent from "../../components/common/spinner";
import { Tabs, Tab, Modal, Form, Col, Button } from "react-bootstrap";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import moment from "moment";
import DatePicker from "react-datepicker2";


class ManualQueueLoanRePayments extends ManualQueueLoanRepaymentModel {

  renderTabs = () => {
    let { tabs, activeTab } = this.state;
    let elements = []
    for (let i = 0; i < tabs.length; i++) {
      elements.push(
        <Tab eventKey={tabs[i].value} title={tabs[i].title}></Tab>
      )
    }
    return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  render() {
    const {
      showSpinner,
      data,
      ediList,
      selectedItemHash,
      channels
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={true}
          onHide={this.props.close}
          aria-labelledby="example-custom-modal-styling-title"
          dialogClassName="settlement-model"
        >
          <Modal.Header closeButton>
            <h5>Loan Repayments</h5>
          </Modal.Header>

          <Modal.Body>
            <SpinnerComponent showSpinner={showSpinner} />
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="partner">
                <Form.Label>Amount</Form.Label>
                <div>₹{data.amount}</div>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="partner">
                <Form.Label>UTR No</Form.Label>
                <div>{data.utrNo}</div>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="4" controlId="loanNo">
                <Form.Label>Loan No <span>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="loanNo"
                  value={data["loanNo"]}
                  placeholder="Enter loan no"
                  onChange={this.handleChange}
                  autoComplete="off"
                  disabled={ediList.length ? true : false}
                />
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Form.Label> </Form.Label>
                <br />
                {!ediList.length ? <Button
                  className="ff-button mt-2 mr-2"
                  onClick={this.onPressGo}
                >
                  Go
                </Button> :
                  <Button
                    className="ff-button mt-2 mr-2"
                    onClick={this.onPressClear}
                  >
                    Clear
                  </Button>
                }

              </Form.Group>
            </Form.Row>

            {ediList && ediList.length ?
              <div className="mt-1">

                <div className="form-row">
                  <div className="col-4">
                    <Form.Label>Paid On<span>*</span></Form.Label>
                    <DatePicker
                      name="paidOn"
                      id="paidOn"
                      value={data["paidOnDate"]}
                      max={moment()}
                      inputFormat="DD/MM/YYYY"
                      isGregorian={true}
                      className="form-control"
                      inputReadOnly={true}
                      showTodayButton={false}
                      timePicker={false}
                      onChange={(value) => this.handleValueDateChange(value)}
                      placeholder="Select Paid On"
                    />
                  </div>

                  <Form.Group as={Col} md="6" controlId="channel">
                    <Form.Label>Channel<span>*</span></Form.Label>
                    <Form.Control
                      as="select"
                      name="channel"
                      value={data["channel"]}
                      onChange={this.handleChannelChange}
                    >
                      {" "}
                      <option key="all" value="">
                        --Select--
                      </option>
                      {channels && channels.map((channel) => (
                        <option key={channel.title} value={channel.value}>
                          {channel.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>

                <Form.Label className="mt-2">Pending EDI's</Form.Label>
                <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0} className={"loan-repayment-table-view"}>
                  <Row>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", borderLeft: "1px solid", textAlign: "center" }}><b>
                      <input type="checkbox" checked={this.state.selectAll} onChange={() => this.toggleSelectAll(!this.state.selectAll)}></input>
                    </b></Cell>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Edi Number</b></Cell>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Due Date</b></Cell>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Principal Amount</b></Cell>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Interest Amount</b></Cell>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Fee Amount</b></Cell>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Amount</b></Cell>
                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
                  </Row>
                  {ediList && ediList &&
                    ediList.map((item, i) => (
                      <Row key={item._id} className='cursor-pointer' onClick={() => { }}>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>
                          <input type="checkbox" checked={selectedItemHash[item.emiNumber]} onChange={() => this.onSelectEdis(item.emiNumber, item)}>
                          </input>
                        </Cell>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.emiNumber}</Cell>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{new Date(item.rePaymentDate).toLocaleDateString()}</Cell>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.principalOutstanding}</Cell>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.interestAmount}</Cell>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.otherCharges}</Cell>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.totalEmiAmount}</Cell>
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.rePaymentStatus}</Cell>
                      </Row>
                    ))}
                  {(!ediList || !ediList.length) && (
                    <td colSpan={9} className='text-center pt-2 pb-2 border'>No Records Found</td>
                  )}

                </StickyTable>
              </div> : <></>}

            {selectedItemHash && Object.keys(selectedItemHash).length && this.state.data.paidOnDate && this.state.data.channel ? <Button
              className="ff-button float-right mt-2"
              onClick={this.onPressUpdate}
            >
              Mark as Paid
            </Button> : <></>}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ManualQueueLoanRePayments;
