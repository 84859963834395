import InputDataForm from "../../components/common/form";
import * as scrutinyUserService from "../../services/scrutiny/scrutinyUserService";
import auth from "../../services/common/authService";
import { moment } from "moment";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class ScrutinyUserTableModel extends InputDataForm {
  state = {
    data: {
      kycStatus: "Under Review",
      fromDate: momentJalaali().subtract(1, "months"),
      toDate: momentJalaali(),
      panNo: "",
      mobileNo: "",
      poaNo: "",
      name: "",
      users: [],
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 10,
    evaluatorRole: ""
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    panNo: Joi.any(),
    mobileNo: Joi.any(),
    poaNo: Joi.any(),
    name: Joi.any(),
    kycStatus: Joi.any(),
    users: Joi.any()
  };
  async getUserList() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const { data: userList } = await scrutinyUserService.partnerScrutinyList(data);

      data.users = userList;

      this.setState({ data, showSpinner: false });
    } catch (ex) {
      this.setState({ data, showSpinner: false });
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    const currentUser = auth.getCurrentUser();

    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    await this.getUserList();
  }

  async handleCustomErrors(errors) {
    return errors;
  }
  async doSubmit() {
    await this.getUserList();
  }
}

export default ScrutinyUserTableModel;
