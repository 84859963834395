import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import moment from "moment";
import momentJalaali from "moment-jalaali";
import axios from "axios";
import Joi from "joi-browser";
import * as reportServices from '../../services/reports/reportServices';


class CibilReportModel extends InputDataForm {
    state = {
        data: {
            fromDate: momentJalaali().subtract(1, "months"),
            toDate: momentJalaali(),
            partnerId: ""
        },
        showSpinner: false,
        disabled: false,
        errors: {}
    };

    schema = {
        fromDate: Joi.any(),
        toDate: Joi.any(),
        partnerId: Joi.any(),
        version: Joi.any(),
    }

    async componentDidMount() {
        const propsData = this.props.data;
        console.log("propsData", propsData);

        if (!auth.getCurrentUser() || auth.getCurrentUser().role.toLowerCase() !== "lending partner") return;
        // console.log("current User", auth.getCurrentUser());
        // console.log("current Partner", auth.getCurrentPartner());
        const partner = auth.getCurrentPartner();
        const data = { ...this.state.data };
        data.partnerId = partner._id;
        this.setState({ data });
    }

    async handleCustomErrors(errors) {
        return errors;
    }

    handleExport = async (e, version) => {
        const data = { ...this.state.data }
        const errors = { ...this.state.errors };

        errors["globalError"] = null;
        errors["paymentMode"] = null;

        data.version = version

        // let dateDiff = moment(data.toDate).diff(data.fromDate, "days")

        // if (dateDiff > 30) {
        //     let errors = this.state.errors
        //     errors.globalError = "The date difference should be less than or equal to 30 days"
        //     this.setState({ errors })
        //     return
        // }

        this.setState({ showSpinner: true, disabled: true, errors });

        const result = await reportServices.getCibilReport(data);
        console.log("result", result);
        if (result.success) {
            if (result.fileURL != "") {
                axios({
                    url: result.fileURL,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', result.fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
                this.setState({ errors, showSpinner: false, disabled: false });
            } else {
                errors["globalError"] = "No Record Exists";
                this.setState({ errors, showSpinner: false, disabled: false });
            }
        } else {
            errors["globalError"] = result.message;
            this.setState({ errors, showSpinner: false, disabled: false });
        }
    }
}
export default CibilReportModel;