import React from "react";
import { Form, Col, Button, Modal} from "react-bootstrap";
import auth from "../../services/common/authService";
import * as kycFormService from "../../services/borrow/kycFormService";
import * as shopFormService from "../../services/borrow/shopFormService";
import Autosuggest from "react-autosuggest";
import * as commonService from "../../services/common/commonService";
import SpinnerComponent from "../../components/common/spinner";
import { isMobile } from 'react-device-detect';
import shopFormModel from "../../models/borrow/shopFormModel";
import styled from "styled-components";
import { Typeahead } from 'react-bootstrap-typeahead';


class ShopDetails extends shopFormModel {
    render() {
        const {
            userId,
            loanId,
            users,
            errors,
            options,
            showSpinner,
            isSubmitted,
            globalError,
            kycStatus,
            isRetailer,
            type,
            currentUserId,
            currentUserRole,
            showSuccessModal,
            infoHeader,
            infoMessage,
            showPrivateGstModal,
            gstDetails,
            gstModleErrors,
            data,
            allErrors,
            info,
            shopSector
        } = this.state;
        
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} /> <br />
                <Form
                    onSubmit={this.handleSubmit}
                    autoCapitalize="on"
                    autoComplete="off"
                >
                    <Form.Row>
                        <h2>Shop Details</h2>{" "}
                        {isMobile && kycStatus === "Approved" && type !== "admin" && (
                            <Button
                                className="ff-button"
                                type="submit"
                                disabled={isSubmitted}
                                style={{ marginLeft: 20, marginTop: 1, marginBottom: 7 }}
                            >
                                Next
                            </Button>)}
                    </Form.Row>
                    {this.state.users.map((el, i) => (
                        <React.Fragment key={i}>
                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="shopName">
                                    <Form.Label>Shop Name*</Form.Label>
                                    <Form.Control
                                        type="shopName"
                                        placeholder="Enter shop name"
                                        name="shopName"
                                        value={el.shopName || ''} onChange={this.handleChange.bind(this, i)}
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                    />
                                    {errors[i].shopName && (
                                        <div className="alert alert-danger">{errors[i].shopName}</div>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="shopType">
                                    <Form.Label>Shop Type*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Eg. General Store"
                                        name="shopType"
                                        value={el.shopType || ''} onChange={this.handleChange.bind(this, i)}
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                    />
                                    {errors[i].shopType && (
                                        <div className="alert alert-danger">{errors[i].shopType}</div>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="shopOwnershipType">
                                    <Form.Label>Shop Ownership Type*</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="shopOwnershipType"
                                        value={el.shopOwnershipType || ''} onChange={this.handleChange.bind(this, i)}
                                    >
                                        {" "}
                                        <option key="select" value="">
                                            ---Select---
                                        </option>
                                        <option key="owned_self" value="owned_self">
                                            Owned-Self
                                        </option>
                                        <option key="owned_family" value="own_family">
                                           Owned-Family
                                        </option>
                                        <option key="owned_company" value="own_company">
                                           Owned-Company
                                        </option>
                                    </Form.Control>
                                    {errors[i].shopOwnershipType && (
                                        <div className="alert alert-danger">
                                            {errors[i].shopOwnershipType}
                                        </div>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="shopSectorType">
                                    <Form.Label>Shop Sector*</Form.Label>
                                    <Typeahead
                                        id="shopSector"
                                        name="shopSector"
                                        options={shopSector}
                                        selected={el.shopSector ? [el.shopSector] : []} 
                                        onChange={this.handleShopSectorChange.bind(this,i)}
                                        placeholder="Choose Shop Sector"
                                        clearButton
                                        minLength={0}
                                        labelKey="label"
                                        paginate
                                    />

                                    {errors[i].shopSector && (
                                        <div className="alert alert-danger">
                                            {errors[i].shopSector}
                                        </div>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="natureOfBussiness">
                                    <Form.Label>Nature of Business*</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="natureOfBussiness"
                                        value={el.natureOfBussiness || ''} onChange={this.handleChange.bind(this, i)}
                                    >
                                        {" "}
                                        <option key="select" value="">
                                            ---Select---
                                        </option>
                                        <option key="trader_retail" value="trader_retail">
                                             Retailer 
                                        </option>
                                        <option key="trader_wholesale" value="trader_wholesale">
                                           Distributor
                                        </option>
                                    </Form.Control>
                                    {errors[i].natureOfBussiness && (
                                        <div className="alert alert-danger">
                                            {errors[i].natureOfBussiness}
                                        </div>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row >
                                <Form.Group as={Col} md="12" controlId="shopAddress">
                                    <Form.Label>Shop Address*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter address"
                                        name="add"
                                        value={el.add || ''} onChange={this.handleChange.bind(this, i)}
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                    />
                                    {errors[i].add && (
                                        <div className="alert alert-danger">{errors[i].add}</div>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>

                                <Form.Group as={Col} md="3" controlId="shopPincode">
                                    <Form.Label>Pincode*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter pincode"
                                        name="pincode"
                                        value={el.pincode || ''} onChange={this.handlePincodeChange.bind(this, i)}
                                        autoComplete="off"
                                        style={{ textTransform: "uppercase" }}
                                        maxLength="6"
                                    />
                                    {errors[i].pincode && (
                                        <div className="alert alert-danger">{errors[i].pincode}</div>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="state">
                                    <Form.Label>State*</Form.Label>
                                    <Autosuggest
                                        suggestions={options[i].stateOptions || []}
                                        onSuggestionsFetchRequested={this.onStateSuggestionsRequested.bind(this, i)}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        onSuggestionsClearRequested={
                                            this.onStateSuggestionsClearRequested.bind(this, i)
                                        }
                                        inputProps={{
                                            placeholder: "Enter your state",
                                            value: el.state || "",
                                            className: "form-control",
                                            onChange: this.onStateChange.bind(this, i),
                                            disabled: false,
                                            name: "state"
                                        }}
                                        style={{ textTransform: "uppercase" }}
                                    />
                                    {errors[i].state && (
                                        <div className="alert alert-danger">{errors[i].state}</div>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="city">
                                    <Form.Label>City*</Form.Label>
                                    <Autosuggest
                                        suggestions={options[i].cityOptions || []}
                                        onSuggestionsFetchRequested={this.onCitySuggestionsRequested.bind(this, i)}
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        onSuggestionsClearRequested={
                                            this.onCitySuggestionsClearRequested.bind(this, i)
                                        }
                                        inputProps={{
                                            placeholder: "Enter your city",
                                            value: el.city || "",
                                            className: "form-control",
                                            onChange: this.onCityChange.bind(this, i),
                                            disabled: false,
                                            name: "city"
                                        }}
                                        style={{ textTransform: "uppercase" }}
                                    />
                                    {errors[i].city && (
                                        <div className="alert alert-danger">{errors[i].city}</div>
                                    )}
                                </Form.Group>
                                <Form.Group as={Col} md="3" controlId="pincode">
                                    {i !== 0 && (
                                        <Button
                                            className="ff-button"
                                            type="button"
                                            onClick={this.removeClick.bind(this, i)}
                                            style={{ marginTop: 30 }}
                                        >
                                            Remove Row
                                        </Button>)} </Form.Group>
                            </Form.Row>
                        </React.Fragment>))}
                    <Form
                        onSubmit={this.handleSubmit}
                        autoCapitalize="on"
                        autoComplete="off"
                    >
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="udyamOrUdyogNo">
                                <Form.Label>Udyam/Udyog Verification*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your Udyam/Udyog Registration number"
                                    name="udyamOrUdyogNo"
                                    value={data.udyamOrUdyogNo}
                                    onChange={this.handleNumberChange}
                                    autoComplete="off"
                                    maxLength="19"
                                    style={{ textTransform: "uppercase" }}
                                    disabled={data?.udyamUdyogVerification === "Completed"}
                                />
                                {allErrors["udyamOrUdyogNo"] && (
                                    <div className="alert alert-danger">{allErrors["udyamOrUdyogNo"]}</div>
                                )}
                                {info["udyamOrUdyogNo"] && (
                                    <div className="alert alert-success">{info["udyamOrUdyogNo"]}</div>
                                )}

                            </Form.Group>
                            <Form.Group as={Col}  style={{display:"flex",flexDirection:'column'}}>
                               <Form.Label style={{opacity:0}}>Action</Form.Label>
                                {data?.udyamUdyogVerification !== "InProgress" && <Button
                                    variant="primary"
                                    disabled={data?.udyamUdyogVerification === "Completed"}
                                    onClick={this.handleUdyamUdyogVerification}
                                    style={{ backgroundColor: data?.udyamUdyogVerification === "Completed" ? "green" : "", maxWidth:"100px" }}
                                >
                                    {data?.udyamUdyogVerification === "Completed" ? "Verified" : "Verify"}
                                </Button>}
                                {data?.udyamUdyogVerification === "InProgress" && <Button
                                    variant="primary"
                                    onClick={this.handleUdyamUdyogVerification}
                                    style={{maxWidth:"100px"}}
                                >
                                    Sync Up
                                </Button>}
                            </Form.Group>

                        </Form.Row>

                    </Form>
                    <br />
                    {(!gstDetails.gstPrivateData && gstDetails.gstNo !== "") && <Form.Row>
                        <Form.Group as={Col} md="7" controlId="consentForGstData">
                            <Form.Check
                                type="switch"
                                id="custom-switch-gst"
                                label="Consent to Fetch GST Private Data"
                                onClick={this.handleOpenPrivateGstModal}
                                checked={showPrivateGstModal}
                            />
                        </Form.Group>
                    </Form.Row>}
                    <Button
                        className="ff-button"
                        type="button"
                        onClick={this.addClick}
                    >
                        Add Row
                    </Button>

                    {type !== "admin" && (
                        <Button className="ff-button" type="button" style={{ marginLeft: 10 }} onClick={() => this.props.setActiveTab("activeTab", "kycTab")}>
                            Back
                        </Button>)}
                    <Button
                        className="ff-button"
                        type="submit"
                        disabled={isSubmitted}
                        style={{ marginLeft: 10 }}
                    >
                        {type === "admin" ? "Submit" : "Next"}
                    </Button>
                </Form>
                <Modal
                    show={showSuccessModal}
                    onHide={this.closeSuccessModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <b>{infoHeader}</b>
                    </Modal.Header>
                    <Modal.Body>
                        {infoMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeSuccessModal} className="ff-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showPrivateGstModal}
                    onHide={this.handleClosePrivateGstModal}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <h3>GST Private Data</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <p>To fetch your private GST data ,you will have to enable the API Integration in GST Portal (Below are the steps .For support write an email to <span style={{ color: "red" }}>service@fundfina.com</span> or Whatsapp to <span style={{ color: "red" }}>918591340160</span>)</p>
                        <p><span style={{ color: "red" }}>Step 1:</span> Please enter your GST Username as per the GST Portal.</p>
                        <Form.Row>
                            <Form.Group as={Col} md="5" controlId="gstNo">
                                <Form.Label>GST Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your GST number"
                                    name="gstNo"
                                    value={gstDetails.gstNo}
                                    autoComplete="off"
                                    style={{ textTransform: "uppercase" }}
                                    disabled={true}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="7" controlId="gstUserName">
                                <Form.Label>GST Username </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="GST Username as per GST Portal "
                                    name="gstUserName"
                                    onChange={this.handleGstDetails}
                                    value={gstDetails.gstUserName}
                                    autoComplete="off"
                                />
                                {gstModleErrors["gstUserName"] && (
                                    <div className="alert alert-danger">
                                        {gstModleErrors['gstUserName']}
                                    </div>
                                )}
                            </Form.Group>

                        </Form.Row>

                        <p><span style={{ color: "red" }}>Step 2:</span> Log in to <a href="https://services.gst.gov.in/services/login" style={{ color: "blue" }} target="blank">GST Portal</a> and navigate to the <b>'My profile'</b> section.</p>
                        <p><span style={{ color: "red" }}>Step 3:</span> Under the quick-links panel, select <b>'Manage API Access'</b>.</p>
                        <p><span style={{ color: "red" }}>Step 4:</span> Click 'Yes' and "Confirm".</p>

                        <Form.Row style={{ marginBottom: "10px", marginLeft: "5px" }}>
                            <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="Confirm that you have Enable API Integration."
                                onClick={this.handleEnableApi}
                                checked={gstDetails.confirmEnableApi}
                            />
                        </Form.Row>
                        {gstDetails.confirmEnableApi && gstDetails.gstUserName !== "" && <p><span style={{ color: "red" }}>Step 5:</span> Get OTP at the Mobile Number Linked with the GST Portal.</p>}
                        {gstDetails.confirmEnableApi && gstDetails.gstUserName !== "" &&
                            <Form>
                                <Form.Row>
                                    <Button
                                        variant="primary"
                                        onClick={() => this.handleRequestOTP()}
                                        disabled={gstDetails.gstUserName === ""}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        {gstDetails.otpSendToMobile ? "Resend OTP" : "Send OTP"}
                                    </Button>
                                </Form.Row>
                                <Form.Row>
                                    {gstModleErrors["otp"] && (
                                        <div className="alert alert-danger">
                                            {gstModleErrors['otp']}
                                        </div>
                                    )}
                                </Form.Row>
                                {gstDetails.otpSendToMobile && <p><span style={{ color: "red" }}>Step 6:</span>Enter the OTP and Submit.</p>}

                                {gstDetails.otpSendToMobile && <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="otp">
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter your OTP"
                                            name="otp"
                                            value={gstDetails.otp}
                                            onChange={this.handleGstDetails}
                                            maxLength={6}
                                        />
                                    </Form.Group>
                                </Form.Row>}
                                {gstDetails.otpSendToMobile && <Button
                                    className="ff-button"
                                    variant="primary"
                                    onClick={() => this.handleOtpSubmit()}
                                    disabled={gstDetails.otp === "" || gstDetails.otp.length < 6 || gstDetails.gstUserName === "" || gstDetails.otpVerifiedSuccessfulMsg}
                                >
                                    Submit
                                </Button>}
                                {gstDetails.otpVerifiedSuccessfulMsg && (
                                    <div className="alert alert-success">{gstDetails.otpVerifiedSuccessfulMsg}</div>
                                )}
                                {gstModleErrors["otpVerifiedSuccessfulMsg"] && (
                                    <div className="alert alert-danger">
                                        {gstModleErrors['otpVerifiedSuccessfulMsg']}
                                    </div>
                                )}
                            </Form>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClosePrivateGstModal} className="ff-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ShopDetails;