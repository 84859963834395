import React from "react";
import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import { Redirect } from "react-router-dom";
import leadInfoService from "../../services/partner/leadInfoService";

class UpdateBankRedirect extends InputDataForm {
    state = {
        redirectPath: false,
        state: null
    }
    async componentDidMount() {
        const loanId = this.props.match.params.id;

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let mandate = params.get('mandate')
        let isEdit = params.get('isEdit')

        if (!loanId)
            this.setState({ redirectPath: "/invalidData", state: { message: "Invalid parameters provided" } });
        else if (!auth.getCurrentUser()) {
            const result = await leadInfoService.getPartnerId(loanId);

            if (result.success)
                this.setState({ redirectPath: `/${result.data}/merchantAuthenticate`, state: { loanData: { loanId: loanId, partnerId: result.data, redirectionUrl: `/updateBankDetail/${loanId}?mandate=${mandate === "true"}&isEdit=${isEdit === "true"}` } } });
            else
                this.setState({ redirectPath: "/invalidData", state: { message: result.message } });
        }
        else {
            // await auth.getMenu();
            this.setState({ redirectPath: `/updateBankDetail/${loanId}?mandate=${mandate === "true"}&isEdit=${isEdit === "true"}` });
        }
    }
    render() {
        const { redirectPath, state } = this.state;
        return (
            redirectPath && (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state
                    }}
                />
            )
        );
    }
}
export default UpdateBankRedirect;
