import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import { Button, Image } from "react-bootstrap";
import _ from "lodash";
import * as commonService from "../../services/common/commonService";
import { url } from "../../config.json";
import SharedCache from "../../SharedCache";
import { maximumFileSize } from "../../config.json";
import imageCompression from "browser-image-compression";
import { saveAs } from "file-saver";
import { Player } from "video-react";
import * as registFormService from "../../services/login/registerFormService";

const jsZip = require("jszip");

const ImageCompressorOptions = {
  maxSizeMB: maximumFileSize,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

class InputForm extends Component {
  state = {
    data: {},
    errors: {},
    fileErrors: {},
    optional: {},
    optionalErrors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    
    const errors = this.validate();
    this.setState({ errors: errors || {} });

    const allErrors = await this.handleCustomErrors(errors || {});
    if (
      !_.isEmpty(allErrors) ||
      !_.isEmpty(this.state.fileErrors) ||
      !_.isEmpty(this.state.optionalErrors)
    )
      return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    if (
      (input.name == "mobileNo" || input.name == "updatedMobileNo") &&
      "" + input.value &&
      "" + input.value.length > 10
    )
      return;

    if (input.name == "otp" && "" + input.value && "" + input.value.length > 6)
      return;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };
  handleMobileChange = async ({ currentTarget: input }) => {
    const { data, errors, info } = this.state;
    if (input.value.length !== 10 || isNaN(input.value)) return;

    this.setState({ showSpinner: true });

    try {
      const {
        success,
        message,
      } = await registFormService.checkMobileAndSendOTP(input.value);

      if (success) {
        delete errors[input.name];
        delete errors["otp"];
        info[input.name] = "OTP sent successfully";
        this.setState({
          errors,
          data,
          isValidMobile: true,
          showSpinner: false,
        });
        this.startTimer();
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidMobile: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ data, errors, showSpinner: false });
    }
  };
  handleOTPChange = async ({ currentTarget: input }) => {

    return

    const { data, errors, info } = this.state;

    if (input.value.length !== 6 || isNaN(input.value)) return;

    this.setState({ showSpinner: true });

    try {
      const { success, message } = await registFormService.authenticateOTP(
        data.mobileNo,
        input.value
      );

      if (success) {
        delete errors[input.name];
        info[input.name] = "OTP Matched";
        this.stopTimer();
        this.setState({
          errors,
          data,
          isRegenerateLink: false,
          isValidOTP: true,
          showSpinner: false,
          isRegenerateText: false,
        });
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({ errors, data, isValidOTP: false, showSpinner: false });
      }
    } catch (ex) {
      this.stopTimer();
      this.startTimer();
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ data, errors, isValidOTP: false, showSpinner: false });
    }
  };
  
  handleCustomChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  toggleChange = () => {
    this.setState({ isDisabled: !this.state.isDisabled });
  };

  handleOTPKeyChange = ({ currentTarget: input }) => {
    if (input.value.length > 4) return;

    if (isNaN(input.value)) return;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };
  handleContractOTPKeyChange = ({ currentTarget: input }) => {
    if (input.value.length > 6) return;

    if (isNaN(input.value)) return;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };
  handleMultipleSelect = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };

    const selected = [];
    let selectedOption = input.selectedOptions;

    for (let i = 0; i < selectedOption.length; i++) {
      selected.push(selectedOption.item(i).value);
    }
    data[input.name] = selected;

    this.setState({ data, errors });
  };

  handleNumericChange = ({ currentTarget: input }) => {
    if (isNaN(input.value)) {
      return;
    }
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleKeyPressNumeric = async (e) => {
    if (e.charCode > 31 && (e.charCode < 48 || e.charCode > 57)) {
      e.preventDefault();
    }
  };

  handleKeyPressDecimal = async (e) => {
    if (e.charCode === 46 && e.target.value.indexOf(".") === -1) {
      return true;
    } else if (e.charCode > 31 && (e.charCode < 48 || e.charCode > 57)) {
      e.preventDefault();
    }
  };

  handleKeyPressDecimalWithPer = async (e) => {
    if (
      e.charCode === 46 &&
      (e.target.value.indexOf("%") === -1 || e.target.value.indexOf(".") === -1)
    ) {
      return true;
    } else if (e.charCode > 31 && (e.charCode < 48 || e.charCode > 57)) {
      e.preventDefault();
    }
  };

  handleOptionalValues = ({ currentTarget: input }) => {
    const optional = { ...this.state.optional };
    optional[input.name] = input.value;

    this.setState({ optional });
  };

  onFileChange = async (
    { currentTarget: input },
    isOnlineUpload,
    type,
    checkSize
  ) => {
    const files = { ...this.state.files };
    const fileErrors = { ...this.state.fileErrors };

    if (input.files.length === 0) {
      files[input.name] = "";
      files[`${input.name}Stream`] = "";
      files[`${input.name}Label`] = "";
      this.setState({ files });
      return;
    }

    // if (checkSize) {
    //   const size = input.files[0].size
    //   const sizeInMB = size / (1024 * 1024)
    //   if (sizeInMB > maximumFileSize) {
    //     fileErrors[input.name] = `File size should be less than ${maximumFileSize || 2} MB`;
    //     this.setState({ fileErrors });
    //     return
    //   }
    // }

    if (
      input.files[0].type !== "application/pdf" &&
      input.files[0].type !== "image/png" &&
      input.files[0].type !== "image/x-png" &&
      input.files[0].type !== "image/jpeg" &&
      input.files[0].type !== "image/pjpeg"
    ) {
      fileErrors[input.name] = "Only .jpg .png and .pdf allowed";
      this.setState({ fileErrors });
      return;
    } else {
      delete fileErrors[input.name];
      this.setState({ fileErrors });
    }

    let file = await this.compressImage(input.files[0]);

    if (isOnlineUpload) {
      this.setState({ showSpinner: true });
      let filePath = await this.getSignedURL(file, type);
      this.setState({ showSpinner: false });

      files[input.name] = file.name;
      files[`${input.name}Stream`] = filePath;
      if (file.name.length > 20) {
        const name = `${file.name.substring(0, 20)}${this.getExtension(
          file.name
        )}`;
        files[`${input.name}Label`] = name;
      } else files[`${input.name}Label`] = file.name;
      this.setState({ files });

      await this.getBase64(file, (result) => {
        files[`${input.name}Src`] = result;
        this.setState({ files });
      });
    } else {
      await this.getBase64(file, (result) => {
        files[input.name] = file.name;
        files[`${input.name}Stream`] = result;
        if (file.name.length > 20) {
          const name = `${file.name.substring(0, 20)}${this.getExtension(
            file.name
          )}`;
          files[`${input.name}Label`] = name;
        } else files[`${input.name}Label`] = file.name;
        this.setState({ files });
      });
    }
  };

  uploadFile = (signedUrl, file, callback) => {
    commonService
      .uploadFileAwsS3(signedUrl, file)
      .then((response) => {
        let splittedSignedUrl = signedUrl.split("?");
        callback && callback(null, splittedSignedUrl[0]);
      })
      .catch((error) => {
        callback && callback(error, null);
        console.log(error);
      });
  };

  getSignedURL = async (file, type) => {
    let payload = {
      fileName: file.name,
      type,
    };

    let response = await commonService.getSignedUrl(payload);

    if (response.data.data) {
      response = response.data.data;

      let singedUrlBuild = this.getSingedUrlBuild(response, file);

      let data = await this.uploadFileV2(response["url"], singedUrlBuild);

      if (data) {
        return this.getImageUrlFromSingedUrlResponse(response);
      }
      return data;
    }

    // return new Promise((resolve, reject) => {
    //   // this.uploadFile(response.url, file, (err, data) => {
    //   //   if (err) resolve("")
    //   //   else resolve(data)
    //   // })
    // })
  };

  uploadFileV2 = async (url, file) => {
    const result = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      body: file,
    });
    console.log(result);
    if (result.status === 204) {
      return result;
    } else return "";
  };

  getImageUrlFromSingedUrlResponse = (response) => {
    let bucketName = response.fields["bucket"];
    let baseUrl = response.url.split(bucketName)[0];
    let baseUrlSplit = baseUrl.split("https://")[1];
    return (
      "https://" + bucketName + "." + baseUrlSplit + response.fields["key"]
    );
  };

  getSingedUrlBuild = (response, file) => {
    let data = new FormData();
    const keys = Object.keys(response.fields);
    for (let i = 0; i < keys.length; i++) {
      data.append(keys[i], response.fields[keys[i]]);
    }
    data.append("file", file);

    return data;
  };

  getExtension = function (path) {
    const lastIndex = path.lastIndexOf("?");
    path = lastIndex === -1 ? path : path.substring(0, lastIndex);
    const firstIndex = path.lastIndexOf(".");
    const extension = path.substring(firstIndex);
    return extension;
  };

  onExcelFileChange = async ({ currentTarget: input }) => {
    let { data, errors } = { ...this.state };
    if (input.files.length === 0) {
      errors[input.name] = "";
      errors[`${input.name}Stream`] = "";
      this.setState({ errors });
      return;
    } else {
      const fileName = input.files[0].name;
      const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (data.uploadType != "Dis_Transfer" && extension !== "xlsx") {
        errors[input.name] = "Only .xlsx allowed";
        this.setState({ errors });
        return;
      } else if (data.uploadType == "Dis_Transfer" && extension !== "xls") {
        errors[input.name] = "Only .xlsx allowed";
        this.setState({ errors });
        return;
      } else {
        delete errors[input.name];
        data.selectedFile = input.files[0];
        data.loaded = 1;
        data.fileName = input.files[0].name;
        this.setState({ data });
      }
    }
  };

  onExcelFileChange1 = async ({ currentTarget: input }) => {
    let { data, errors } = { ...this.state };
    if (input.files.length === 0) {
      errors[input.name] = "";
      errors[`${input.name}Stream`] = "";
      this.setState({ errors });
      return;
    } else {
      const fileName = input.files[0].name;
      const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (extension !== "xls" && extension !== "xlsx") {
        errors[input.name] = "Only .xls or .xlsx allowed";
        this.setState({ errors });
        return;
      } else {
        delete errors[input.name];
        data.selectedFile = input.files[0];
        data.loaded = 1;
        data.fileName = input.files[0].name;
        this.setState({ data });
      }
    }
  };

  onMultipleFileChange = async (
    { currentTarget: input },
    isOnlineUpload,
    type,
    checkSize
  ) => {
    const files = {
      ...this.state.files,
    };
    const fileErrors = {
      ...this.state.fileErrors,
    };

    if (input.files.length === 0) {
      files.additionalDocuments = [];
      this.setState({ files });
      return;
    }

    const fileList = [];

    // for (const file of input.files) {
    //   if (checkSize) {
    //     const size = file.size
    //     const sizeInMB = size / (1024 * 1024)
    //     if (sizeInMB > maximumFileSize) {
    //       fileErrors[input.name] = `File size should be less than ${maximumFileSize || 2} MB`;
    //       this.setState({ fileErrors });
    //       return
    //     }
    //   }
    // }

    if (input.files.length > 0) {
      files.additionalFilesCount = `${input.files.length} Files Selected`;
    } else {
      files.additionalFilesCount = input.files[0].name;
    }

    this.setState({ showSpinner: true });
    for (let file of input.files) {
      if (
        file.type !== "application/pdf" &&
        file.type !== "image/png" &&
        file.type !== "image/x-png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/pjpeg"
      ) {
        fileErrors[input.name] = "Only .jpg .png and .pdf allowed";
        this.setState({ files, fileErrors });
        return;
      } else {
        delete fileErrors[input.name];
        this.setState({ fileErrors });
      }

      file = await this.compressImage(file);

      if (isOnlineUpload) {
        let filePath = await this.getSignedURL(file, type);
        let item = {
          file: file.name,
          fileStream: filePath,
        };

        item.fileSrc = await new Promise((resolve, reject) => {
          this.getBase64(file, (result) => {
            resolve(result);
          });
        });

        fileList.push(item);
      } else {
        await this.getBase64(file, (result) => {
          let item = {
            file: file.name,
            fileStream: result,
          };

          fileList.push(item);
        });
      }
    }

    files.additionalDocuments = fileList;
    this.setState({
      files,
      showSpinner: false,
    });
  };
  startTimer() {
    let { counter } = this.state;
    counter = 60;

    this.setState({ counter, isRegenerateText: true, isRegenerateLink: false });

    this.timerId = setInterval(() => {
      if (counter === 1) {
        this.stopTimer(true);
        return;
      }
      counter = counter - 1;
      this.setState({ counter: counter });
    }, 1000);
  }
  stopTimer() {
    this.setState({ isRegenerateText: false, isRegenerateLink: true });
    clearInterval(this.timerId);
  }
  async getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  handleTnc = async (tnc) => {
    const errors = { ...this.state.errors };

    if (!tnc) {
      delete errors["tnc"];
    }

    this.setState({ tnc: !tnc, errors });
  };
  showPopUp = (path) => {
    this.setState({ popUpPath: path, showPopUp: true });
  };
  hidePopUp = (path) => {
    this.setState({ popUpPath: path, showPopUp: false });
  };
  handleClose = () => {
    this.setState({ showTnc: false, showPrivacyPolicy: false });
  };
  handleViewTnc = () => {
    this.setState({ showTnc: true });
  };
  handleViewPrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: true });
  };
  handleFromDateChange = (value) => {
    const { data } = this.state;

    data.fromDate = value;
    this.setState({ data, showDateFilterNote: false });
  };

  handleDateChange = (value) => {
    const { data } = this.state;

    data.date = value;
    this.setState({ data });
  };
  handleToDateChange = (value) => {
    const { data } = this.state;

    data.toDate = value;
    this.setState({ data });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handlePagePrevious = () => {
    let { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1 });
  };
  handlePageNext = () => {
    let { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 });
  };

  openFile = async (filepath, isOnline = true) => {
    let URL = "";
    if (isOnline) {
      let response = await commonService.getSignedUrlToDownload(filepath);
      URL = response?.data || "";
    } else {
      const urlSuffix = await commonService.getImagePath(filepath);
      URL = `${url}${urlSuffix.encryptedUrl}`;
    }
    SharedCache.app.onClickImagePreview(URL);
  };

  getSignedURLToDownload = async (filepath) => {
    let response = await commonService.getSignedUrlToDownload(filepath);
    return response?.data;
  };

  getBulkSignedURLToDownload = async (filepath) => {
    let response = await commonService.getBulkSignedUrlToDownload(filepath);
    return response?.data;
  };

  checkSpecialCharacters = (stringToValidate) => {
    let pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (
      stringToValidate &&
      stringToValidate.length > 2 &&
      pattern.test(stringToValidate)
    ) {
      return true;
    } else {
      return false;
    }
  };
  renderButton(label, type, ...rest) {
    return (
      <Button
        {...rest}
        disabled={this.validate()}
        variant="primary"
        type={type}
      >
        {label}
      </Button>
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, placeholder, additionalText, type = "text") {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        additionalText={additionalText}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderGlobalError(name) {
    const { errors } = this.state;
    return (
      errors[name] && (
        <div id={name} name={name} className="alert alert-danger">
          {errors[name]}
        </div>
      )
    );
  }

  onClickFocusById(id) {
    let element = document.getElementById(id);
    if (element) element.click();
  }

  onRemoveFile(key, index) {
    let files = this.state.files;
    if (index >= 0) {
      let arr = files[key];
      arr[index].isDeleted = true;
      let count = 0;
      arr.forEach((element) => {
        if (!element.isDeleted) count += 1;
      });
      if (count > 0) {
        files.additionalFilesCount = `${count} Files Selected`;
      } else {
        files.additionalFilesCount = "";
      }

      files[key] = arr;
    } else {
      files[key] = "";
      files[`${key}Stream`] = "";
      files[`${key}Label`] = "";
    }
    this.setState({ files });
  }

  openFileAsTab = async (filepath) => {
    let response = await commonService.getSignedUrlToDownload(filepath);
    let URL = response?.data || "";
    window.open(URL);
  };

  onClickViewImage(url) {
    SharedCache.app.onClickImagePreview(url);
  }

  onClickViewPdf(url) {
    SharedCache.app.onClickImagePreview(url, "PDF");
  }

  openInvoiceFileAsTab = async (filepath) => {
    let response = await commonService.getSignedInvoiceUrlToDownload(filepath);
    let URL = response?.data || "";
    window.open(URL);
  };

  compressImage = async (imageFile) => {
    if (imageFile) {
      if (
        imageFile.type == "image/png" ||
        imageFile.type == "image/x-png" ||
        imageFile.type == "image/jpeg" ||
        imageFile.type !== "image/pjpeg"
      ) {
        const size = imageFile.size;
        const sizeInMB = size / (1024 * 1024);
        if (sizeInMB > maximumFileSize) {
          const compressedFile = await imageCompression(
            imageFile,
            ImageCompressorOptions
          );
          console.log(
            `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
          );
          return compressedFile;
        }
      }
    }
    return imageFile;
  };

  renderThumbnail(src) {
    if (!src) return;
    let [base, qString] = src.split("?");
    let isPdf = false;

    if (base.includes(".pdf") || src.includes("application/pdf")) isPdf = true;

    if (isPdf) {
      return (
        <div className="position-relative">
          <iframe src={src} className="image-thumbnail" />
          <div
            className="embed-click-wrapper cursor-pointer"
            onClick={() => this.onClickViewPdf(src)}
          >
            &nbsp;
          </div>
        </div>
      );
    }

    return (
      <Image
        src={src}
        fluid
        className="image-thumbnail"
        onClick={() => this.onClickViewImage(src)}
      />
    );
  }

  renderVideoThumbnail() {
    // if (!src) return;
    // let [base, qString] = src.split("?");

    return (
      <img
        src={require("../../images/videoThumbnail.jpg")}
        className="image-thumbnail"
        // onClick={() => this.onClickViewImage(src)}
      />
    );
  }

  renderThumbnailAction(key, image, index) {
    return (
      <div className="image-hover-wrapper">
        <div>
          <i
            className="fa fa-times cursor-pointer close-icon"
            onClick={() => this.onRemoveFile(key, index)}
          />
        </div>
      </div>
    );
  }

  renderThumbnailLActionList(key, image, index) {
    return (
      <div className="image-hover-wrapper">
        <div>
          <i
            className="fa fa-times cursor-pointer close-icon"
            onClick={() => this.onRemoveListFile(key, index)}
          />
        </div>
      </div>
    );
  }

  renderFilePreview(src) {
    if (src) {
      let [base, qString] = src.split("?");
      let isPdf = false;
      if (base.includes(".pdf") || src.includes("application/pdf"))
        isPdf = true;

      if (isPdf) {
        return (
          <div>
            <iframe src={src} height="400px" width="100%" frameBorder="0" />
          </div>
        );
      }

      return <Image className="fit-content max-width-250" src={src} />;
    }
    return "";
  }

  downloadBase64 = async (url) => {
    let res = await commonService.downloadFileByUrl(url, {
      responseType: "arraybuffer",
    });
    return Buffer.from(res.data, "binary").toString("base64");
  };

  downloadAllDocuments = async (loanId, loanNo) => {
    this.setState({ downloadAllLoanId: loanId });
    let response = await commonService.downloadAllDocument(loanId);
    let files = response?.data?.data || [];

    let zip = new jsZip();

    for (let i = 0; i < files?.length; i++) {
      let item = files[i];

      let file = await this.downloadBase64(item.url);
      zip.file(item.name, file, { base64: true });
    }

    let content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `FF_${loanNo || new Date().getTime()}.zip`);
    this.setState({ downloadAllLoanId: "" });
  };

  checkAfterLenderAssignment(loanStatus) {
    return (
      loanStatus === "Lender Assigned" ||
      loanStatus === "Onboarding Completed" ||
      loanStatus === "Loan In Progress" ||
      loanStatus === "Ready For Disbursal" ||
      loanStatus === "Initiated Fund Transfer" ||
      loanStatus === "Closed Prepaid" ||
      loanStatus === "Lender Approval" ||
      loanStatus === "Written-Off" ||
      loanStatus === "Close Repaid"
    );
  }

  getGeoLocation(callback) {
    navigator.geolocation.getCurrentPosition(callback);
  }

  handleScroll = (ref) => {
    window.scrollTo({
      behavior: "smooth",
      top: ref.current.offsetTop,
    });
  };

  handleListFileChange = async (
    { currentTarget: input, target },
    isOnlineUpload,
    type,
    checkSize,
    fieldPrefix
  ) => {
    const list = [...this.state.list];
    let recordFields = target.name.split("_");

    let tranIndex = list.findIndex((x) => x.loanId === recordFields[1]);

    if (input.files.length === 0) {
      list[tranIndex].files[`${fieldPrefix}File`] = "";
      list[tranIndex].files[`${fieldPrefix}FileStream`] = "";
      list[tranIndex].files[`${fieldPrefix}FileLabel`] = "";
      this.setState({ list });
      return;
    }

    if (
      input.files[0].type !== "application/pdf" &&
      input.files[0].type !== "image/png" &&
      input.files[0].type !== "image/x-png" &&
      input.files[0].type !== "image/jpeg" &&
      input.files[0].type !== "image/pjpeg"
    ) {
      list[tranIndex].fileErrors[fieldPrefix] =
        "Only .jpg .png and .pdf allowed";
      this.setState({ list });
      return;
    } else {
      delete list[tranIndex].fileErrors[fieldPrefix];
      this.setState({ list });
    }

    let file = await this.compressImage(input.files[0]);

    if (isOnlineUpload) {
      this.setState({ showSpinner: true });
      let filePath = await this.getSignedURL(file, type);
      this.setState({ showSpinner: false });

      list[tranIndex].files[`${input.name}File`] = file.name;
      list[tranIndex].files[`${input.name}FileStream`] = filePath;
      if (file.name.length > 20) {
        const name = `${file.name.substring(0, 20)}${this.getExtension(
          file.name
        )}`;
        list[tranIndex].files[`${input.name}FileLabel`] = name;
      } else list[tranIndex].files[`${input.name}FileLabel`] = file.name;
      this.setState({ list });

      await this.getBase64(file, (result) => {
        list[tranIndex].files[`${input.name}FileSrc`] = result;
        this.setState({ list });
      });
    } else {
      await this.getBase64(file, (result) => {
        list[tranIndex].files[`${fieldPrefix}File`] = file.name;
        list[tranIndex].files[`${fieldPrefix}FileStream`] = result;
        if (file.name.length > 20) {
          const name = `${file.name.substring(0, 20)}${this.getExtension(
            file.name
          )}`;
          list[tranIndex].files[`${fieldPrefix}FileLabel`] = name;
        } else list[tranIndex].files[`${fieldPrefix}FileLabel`] = file.name;
        this.setState({ list });
      });
    }
  };

  onRemoveListFile(key, index) {
    let list = this.state.list;

    list[index].files[key] = "";
    list[index].files[`${key}Stream`] = "";
    list[index].files[`${key}Label`] = "";

    this.setState({ list });
  }

  copyContent(copyContentText) {
    navigator.clipboard.writeText(copyContentText);
  }
}

export default InputForm;
