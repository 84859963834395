import React, { useContext } from "react";
import UserContext from "./../../context/userContext";
import { Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Image } from "react-bootstrap";

function constructRoleBasedMenu(menu, user) {

  let roleMenus = [];
  let parentMenus = {};

  if (menu) {

    for (let m of menu) {
      if (m.parantMenu == null) {
        roleMenus.push(m);
        parentMenus[m.menu] = false;
      }
    }

    menu = menu.filter(m => m.parantMenu != null);
  }

  let map = {};
  if (user && user.entitlements) {

    for (const e of user.entitlements) {

      switch (e.entitlement) {

        case "TS_EXPORT_FILES": {

          let role = menu.find(x => x.menu == "Truescore Excel Files")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "KYC_SCRUTINY": {

          let role = menu.find(x => x.menu == "KYC Scrutiny")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PRE_APPROVAL": {

          let role = menu.find(x => x.menu == "PreApproval")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_DECISION": {

          let role = menu.find(x => x.menu == "Loan Decision")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PERSONAL_LOAN_DECISION": {

          let role = menu.find(x => x.menu == "Personal Loan Decision")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "TELE_CALLING": {

          let role = menu.find(x => x.menu == "Tele Calling")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_DISBURSAL": {

          let role = menu.find(x => x.menu == "Loan Disbursal")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "USER_LIST": {

          let role = menu.find(x => x.menu == "User List")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_AUTO_DISBURSAL": {

          let role = menu.find(x => x.menu == "Loan Auto Disbursal")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PAY_IN": {

          let role = menu.find(x => x.menu == "Pay-In")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PAY_OUT": {

          let role = menu.find(x => x.menu == "PayOut")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "EARLY_WARRNING_SYSTEM": {

          let role = menu.find(x => x.menu == "Early Warning System")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "COLLECTION_QUEUE": {

          let role = menu.find(x => x.menu == "Collection's Cases")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PORTFOLIO_SUMMARY": {

          let role = menu.find(x => x.menu == "Portfolio Summary")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "TRANCHE": {

          let role = menu.find(x => x.menu == "Tranche")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "TRANCHWISE_COLLECTION_MIS": {

          let role = menu.find(x => x.menu == "Tranchewise Collection MIS")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "UPLOAD_EXCEL": {

          let role = menu.find(x => x.menu == "Upload Excel")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "OFFER_NOTIFICATION": {

          let role = menu.find(x => x.menu == "Offer Notification")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LENDER_ACCOUNT_SUMMARY": {

          let role = menu.find(x => x.menu == "Lender Account Summary")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_TRANSACTION_SUMMARY": {

          let role = menu.find(x => x.menu == "Loan Transaction Summary")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_DETAILS": {

          let role = menu.find(x => x.menu == "Loan Details")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "CIBIL": {

          let role = menu.find(x => x.menu == "Cibil")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "MY_PROFILE": {

          let role = menu.find(x => x.menu == "My Profile")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PORTFOLIO": {

          let role = menu.find(x => x.menu == "Portfolio")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "FAQ": {

          let role = menu.find(x => x.menu == "FAQ")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "REPORTS": {

          let role = menu.find(x => x.menu == "Reports")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PARTNER_KYC": {

          let role = menu.find(x => x.menu == "Partner KYC")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PORTFOLIO_SUMMARY": {

          let role = menu.find(x => x.menu == "Portfolio Summary")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PRIVACY_POLICY": {

          let role = menu.find(x => x.menu == "Privacy Policy")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "SIGN_OUT": {

          let role = menu.find(x => x.menu == "Sign Out")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_COLLECTION": {

          let role = menu.find(x => x.menu == "Loan Collection")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PARTNER_KYC": {

          let role = menu.find(x => x.menu == "Partner KYC")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_APPLICATIONS": {

          let role = menu.find(x => x.menu == "Loan Applications")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_APPLICATIONS": {

          let role = menu.find(x => x.menu == "Loan Applications")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "TERMS_AND_CONDITIONS": {

          let role = menu.find(x => x.menu == "Terms & Conditions")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PARTNER_CONFIGURATIONS": {

          let role = menu.find(x => x.menu == "Partner Configurations")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "TELE_CALLING_COLLECTIONS": {

          let role = menu.find(x => x.menu == "Collection's Queue")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "AGENT_COLLECTION": {

          let role = menu.find(x => x.menu == "Agent Collection")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "PRODUCT_MASTER": {

          let role = menu.find(x => x.menu == "Product Master")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "BANK_STATEMENT": {

          let role = menu.find(x => x.menu == "Bank Statement")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LOAN_ROLLBACK": {

          let role = menu.find(x => x.menu == "Loan Rollback")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LENDER_SETTLEMENT": {

          let role = menu.find(x => x.menu == "Lender Settlement")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "SETTLEMENT_MIS": {

          let role = menu.find(x => x.menu == "Settlement MIS")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "RECONCILIATION": {

          let role = menu.find(x => x.menu == "Settlement Reconciliation")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "FORECLOSURE": {

          let role = menu.find(x => x.menu == "Foreclosure")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "MANUAL_QUEUE": {

          let role = menu.find(x => x.menu == "Manual Queue")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "REPORTS_LIST": {

          let role = menu.find(x => x.menu == "Reports List")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "COLLECTIONS_CALL_OUTCOMES": {

          let role = menu.find(x => x.menu == "Collections Call Outcomes")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;


        case "COLLECTION_CASES_NOTIFICATION": {

          let role = menu.find(x => x.menu == "Collection Cases Notification")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "DASHBOARD": {

          let role = menu.find(x => x.menu == "Dashboard")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "CREATE_INVOICE": {

          let role = menu.find(x => x.menu == "Create Invoice")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "LENDER_PAYOUT": {

          let role = menu.find(x => x.menu == "Lender Payout")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;

        case "UPDATE_UPI_PAYMENT": {

          let role = menu.find(x => x.menu == "Payment Update")
          if (role && !map[role.menu]) {
            roleMenus.push(role);
            map[role.menu] = role;
          }
        }
          break;
      }
    }

    menu = roleMenus;


    for (let m of menu) {

      if (m.menu == "Sign Out" && parentMenus[m.menu] == false) {
        parentMenus[m.menu] = true;
      }

      if (m.parantMenu && parentMenus[m.parantMenu] == false) {
        parentMenus[m.parantMenu] = true;
      }
    }

    menu = menu.filter(m => parentMenus[m.menu] != false);


  }


  return menu;
}


function MegaNavBar() {

  let { user, menu, partner } = useContext(UserContext);

  if (user && user.role == 'Admin')
    menu = constructRoleBasedMenu(menu, user);

  return (
    <nav
      className={user && (user.loggedInFrom === "RedirectAuthLink" || user.loggedInFrom === "Mail") ? "navbar navbar-light bg-white fixed-top" : "navbar navbar-light bg-white navbar-expand-lg fixed-top"}
      id="myNavbar"
    >
      {((!user && !partner) ||
        user &&
        ((user.loggedInFrom !== "Mail" &&
          user.loggedInFrom !== "RedirectAuthLink") || (user.loggedInFrom === "RedirectAuthLink" && user.showHeaderMenu))) && (
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mainNav"
            aria-controls="mainNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>)}
      {user && (!partner?.hideFFLogo) && ((user.loggedInFrom === "Mail" || (user.loggedInFrom === "RedirectAuthLink" && !user.showHeaderMenu))) && (
        <><img
          src={require("../../images/logo.png")}
          className="d-inline-block align-top"
          alt="Fundfina Logo"
        />
          {sessionStorage.getItem("loginPartner") && <img
            src={require(`../../images/partner/${sessionStorage.getItem("loginPartner")}.png`)}
            className="d-inline-block align-top"
            style={{ maxHeight: "43px" }}
            alt="Fundfina Logo"
          />}
        </>
      )}
      {(!user ||
        (user && (!partner?.hideFFLogo) &&
          ((user.loggedInFrom !== "Mail" &&
            user.loggedInFrom !== "RedirectAuthLink") || (user.loggedInFrom === "RedirectAuthLink" && user.showHeaderMenu)))) && (
          <a href="https://fundfina.com" className="navbar-brand">
            <img
              src={require("../../images/logo.png")}
              className="d-inline-block align-top"
            />
          </a>

        )}

      {partner?.hideFFLogo && partner && partner.displayLogo && (
        <a className="navbar-brand">

        </a>)}
      {partner && partner.displayLogo && (
        <a className="navbar-brand" >

          <img
            src={require(`../../images/partner/${partner.logoFileName}`)}
            className="d-inline-block align-top"
            alt={partner.partnerName}
            style={{ maxHeight: "43px" }}
          />
        </a>
      )}
      <div
        className="collapse navbar-collapse"
        id="mainNav"
        data-toggle="collapse"
      >
        {/* {!user && !partner && (
          <React.Fragment>
            <NavLink
              className="nav-item nav-link nav-link-fixed"
              activeClassName="activeHeader"
              to="/borrow"
            >
              Borrow
            </NavLink>

            <NavLink
              className="nav-item nav-link nav-link-fixed"
              to="/partner"
              activeClassName="activeHeader"
            >
              Partner
            </NavLink>
            <NavLink
              className="nav-item nav-link nav-link-fixed"
              to="/truescore"
              activeClassName="activeHeader"
            >
              TrueScore<sup style={{ color: "red" }}>New</sup>
            </NavLink>

            <a
              href="https://developer.fundfina.com/?version=latest"
              target="_blank"
              className="nav-item nav-link nav-link-fixed"
            >
              Developer
            </a>

            <NavLink
              className="nav-item nav-link nav-link-fixed"
              to="/insights"
              activeClassName="activeHeader"
            >
              Insights
            </NavLink>
          </React.Fragment>
        )} */}
        {((!user && !partner) ||
          user &&
          ((user.loggedInFrom !== "Mail" &&
            user.loggedInFrom !== "RedirectAuthLink") || (user.loggedInFrom === "RedirectAuthLink" && user.showHeaderMenu))) && (
            <ul className="navbar-nav ml-auto nav-fill">
              <li className="nav-item px-4 dropdown" key="user">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="servicesDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {user ? `Welcome ${user.name}` : "Guest"}{" "}
                  {user && user.imageUrl ? (
                    <Image
                      style={{ width: 30 }}
                      src={user.imageUrl}
                      roundedCircle
                    />
                  ) : (
                    <i className="fa fa-user" aria-hidden="true"></i>
                  )}{" "}
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  id="menuDropdownList"
                  aria-labelledby="servicesDropdown"
                >
                  {user ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <Nav className="mr-auto"></Nav>
                      <NavLink
                        className="nav-item nav-link nav_link"
                        to="/login"
                        key="login"
                        activeClassName="activeHeader"
                      >
                        Login
                      </NavLink>
                      <NavLink
                        className="nav-item nav-link nav_link"
                        to="/register"
                        key="register"
                        activeClassName="activeHeader"
                      >
                        Apply for loan
                      </NavLink>
                      <NavLink
                        className="nav-item nav-link nav_link"
                        to="/partnerRegistration"
                        key="partnerRegistration"
                        activeClassName="activeHeader"
                      >
                        Register as Partner
                      </NavLink>

                      {/* <div className="dropdown-divider"></div> */}
                    </React.Fragment>
                  )}

                  <div className="d-md-flex align-items-start justify-content-start custom-menu">
                    {menu &&
                      menu.map((item, i) =>
                        !item.parantMenu &&
                          item.menu !== "Sign Out" &&
                          item.menu !== "Login" ? (

                          <React.Fragment key={i}>
                            <div>
                              <div className="nav_link_header" key={item.menu}>
                                {item.menu}
                              </div>
                              {menu.map((value) =>
                                value.parantMenu === item.menu ? (
                                  // <a
                                  //   key={value.menu}
                                  //   className="dropdown-item"
                                  //   href={value.path}
                                  // >
                                  //   {value.menu}
                                  // </a>
                                  <NavLink
                                    className="nav-item nav-link nav_link"
                                    to={value.path}
                                    key={value.menu}
                                    activeClassName="activeHeader"
                                  >
                                    {value.menu}
                                  </NavLink>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </React.Fragment>
                        ) : item.menu === "Sign Out" ? (
                          // <div>
                          //   <a className="dropdown-item" href={item.path}>
                          //     {item.menu}
                          //   </a>
                          //   </div>
                          <NavLink
                            className="nav-item nav-link nav_link"
                            to={item.path}
                            key={item.menu}
                            activeClassName="activeHeader"
                          >
                            {item.menu}
                          </NavLink>
                        ) : (
                          ""
                        )
                      )}
                  </div>
                </div>
              </li>
            </ul>

          )}
      </div>
    </nav>
  );
}

export default MegaNavBar;
