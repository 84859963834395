import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Form, Button, InputGroup, Modal, Col, Row , Nav } from "react-bootstrap";
import LoginFormModel from "../../models/login/loginFormModel";
import SpinnerComponent from "../../components/common/spinner";

class Login extends LoginFormModel {
  render() {
    const {
      data,
      isMobileLogIn,
      isValidMobile,
      errors,
      showSpinner,
      showPasswordChangeModal,
      optional,
      optionalErrors,
      partnerId,

      isValidOTP,
      info,
      isValidUserName,
      isRegenerateLink,
      counter,
      isRegenerateText,
      handleMobileLogin
    } = this.state;
    return (


      <div className="row">

        <SpinnerComponent showSpinner={showSpinner} />
        <div className="col-md-6">
          <h2>Login</h2> <br />
          <Form onSubmit={this.handleSubmit}>
            {!isMobileLogIn&&<Form.Group controlId="username">
              <Form.Label>Email ID</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Enter your email"
                  value={data["username"]}
                  autoComplete="off"
                  onChange={this.handleChange}
                  autoFocus={true}
                />{" "}
              </InputGroup>
              {errors["username"] && (
                <div className="alert alert-danger">{errors["username"]}</div>
              )}
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>} 
            {isMobileLogIn&&<Form.Group controlId="mobileNo">
              <Form.Label>Mobile Number</Form.Label>
              <InputGroup>
                <Form.Control
                 type="number"
                 pattern="[0-9]*"
                 placeholder="Enter your mobile number"
                 name="mobileNo"
                 value={data["mobileNo"]}
                 onChange={this.handleChange}
                 onBlur={this.handleMobileChange}
                 autoComplete="off"
                 maxLength={10}
                 autoFocus={true}
               />
              </InputGroup>
              {errors["mobileNo"] && (
                <div className="alert alert-danger">{errors["mobileNo"]}</div>
              )}
              {info["mobileNo"] && (
                <div className="alert alert-success">{info["mobileNo"]}</div>
              )}
               <Form.Text className="text-muted">
                We'll never share your mobile number with anyone else.
              </Form.Text>
            </Form.Group>} 
            {!isMobileLogIn&&<Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={data["password"]}
                onChange={this.handleChange}
              />
              {errors["password"] && (
                <div className="alert alert-danger">{errors["password"]}</div>
              )}
            </Form.Group>}
            {isValidMobile&&isMobileLogIn&& (
              <Form.Group controlId="otp">
                <Form.Label>OTP*</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your OTP"
                  name="otp"
                  value={data["otp"]}
                  onChange={this.handleChange}
                  onKeyUp={this.handleOTPChange}
                  autoFocus={isValidMobile}
                  maxLength={6}
                />
                {/* <div>
                  <OtpInput
                    onChange={this.handleOtpChange}
                    numInputs={6}
                    value={data["otp"]}
                    separator={<span style={{ padding: 10 }}>-</span>}
                    inputStyle="form-control"
                    isDisabled={isValidOTP}
                    isInputNum={true}
                    onKeyUp={this.handleOTPChange}
                  />
                </div> */}
                {errors["otp"] && (
                  <div className="alert alert-danger">{errors["otp"]}</div>
                )}
                {info["otp"] && (
                  <div className="alert alert-success">{info["otp"]}</div>
                )}
                {isRegenerateText && (
                  <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                )}
                {isRegenerateLink && (
                  <Nav.Item id="regenerateOTP">
                    <Nav.Link
                      className="underline"
                      onClick={this.handleRegenerateOTP}
                      style={{
                        padding: 0,
                        float: "right",
                        textDecoration: "underline",
                      }}
                    >
                      Re-generate OTP
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Form.Group>
            )}
             <InputGroup style={{marginBottom:"5px"}}>
              <Form.Check
                  reverse
                  id="custom-switch-login"
                  type="switch"
                  value={isMobileLogIn}
                  label="Login With Mobile Number"
                  onClick={this.handleMobileLogin}
              />
            </InputGroup>
            <Button
              className="ff-button"
              variant="primary"
              type="submit"
              disabled={this.validate()}
            >
              Login
            </Button>
            {!isMobileLogIn&&<Link
              to="/forgotPassword"
              style={{ paddingLeft: 20, textDecoration: "underline" }}
            >
              Forgot Password?
            </Link>}{" "}
            <Form.Group controlId="globalError" style={{ marginTop: 10 }}>
              {errors["globalError"] && (
                <div className="alert alert-danger">
                  {errors["globalError"]}
                </div>
              )}
            </Form.Group>
            {/* <br />
            {!partnerId && (
              <Form.Row>
                <Form.Group as={Col} lg="5" md="12" sm="12" controlId="google">
                  <GoogleBtn />
                </Form.Group>
                <Form.Group as={Col} lg="5" md="12" sm="12" controlId="facebook">
                  <FacebookBtn />
                </Form.Group>
              </Form.Row>)} */}
          </Form>
          <Modal
            show={showPasswordChangeModal}
            onHide={this.closeModal}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title className="fundfinaColor">
                {!optional.isPasswordChanged && (
                  <React.Fragment>Password change required!</React.Fragment>
                )}
                {optional.isPasswordChanged && (
                  <React.Fragment>Password changed!</React.Fragment>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <SpinnerComponent showSpinner={this.state.showModelLoading} />
              {!optional.isPasswordChanged && (
                <React.Fragment>
                  <p className="fundfinaColor">
                    Please change your password as per below guidelines:
                  </p>
                  <p className="fundfinaColor">
                    <i className="fa fa-asterisk" aria-hidden="true"></i>{" "}
                    Password must be minimum 8 characters. <br />
                    <i className="fa fa-asterisk" aria-hidden="true"></i>{" "}
                    Password must contain a special character.
                  </p>
                  <Form onSubmit={this.changePassword}>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="password">
                        <Form.Label>Enter New Password*</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter new password"
                          name="password"
                          value={optional["password"]}
                          onChange={this.handleOptionalValues}
                          autoComplete="off"
                          autoFocus={showPasswordChangeModal}
                        />{" "}
                        {optionalErrors["password"] && (
                          <div className="alert alert-danger">
                            {optionalErrors["password"]}
                          </div>
                        )}{" "}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="confirmPassword">
                        <Form.Label>Re-enter New Password*</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Re-enter new password"
                          name="confirmPassword"
                          value={optional["confirmPassword"]}
                          onChange={this.handleOptionalValues}
                          autoComplete="off"
                        />{" "}
                        {optionalErrors["confirmPassword"] && (
                          <div className="alert alert-danger">
                            {optionalErrors["confirmPassword"]}
                          </div>
                        )}{" "}
                      </Form.Group>
                    </Form.Row>{" "}

                    <Form.Row>
                      <Form.Group as={Col} controlId="otp">
                        <Form.Label>Enter OTP*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter OTP"
                          name="otp"
                          value={data["otp"]}
                          onChange={this.handleChange}
                          autoComplete="off"
                          maxLength="6"
                          disabled={isValidOTP}
                          autoFocus={isValidUserName && !isValidOTP}
                        />{" "}
                        {info["otp"] && (
                          <Form.Text
                            className="text-muted"
                            style={{ color: "green" }}
                          >
                            {info["otp"]}
                          </Form.Text>
                        )}
                        {isRegenerateLink && (
                          <Nav.Item id="regenerateOTP">
                            <Nav.Link
                              className="underline"
                              onClick={this.sendOtp}
                              style={{
                                padding: 0,
                                float: "right",
                                textDecoration: "underline",
                              }}
                            >
                              Re-generate OTP
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        {errors["otp"] && (
                          <div className="alert alert-danger">{errors["otp"]}</div>
                        )}{" "}
                        {isRegenerateText && (
                          <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                        )}
                      </Form.Group>
                    </Form.Row>

                    <Button
                      variant="primary"
                      type="submit"
                      className="ff-button"
                      onClick={this.changePassword}
                    >
                      Change Password
                    </Button>{" "}
                    <Form.Group
                      controlId="globalError"
                      style={{ marginTop: 30 }}
                    >
                      {optionalErrors["globalError"] && (
                        <div className="alert alert-danger">
                          {optionalErrors["globalError"]}
                        </div>
                      )}
                    </Form.Group>
                  </Form>
                </React.Fragment>
              )}
              {optional.isPasswordChanged && (
                <React.Fragment>
                  <p className="fundfinaColor" style={{ fontWeight: "bold" }}>
                    You're password has been changed successfully.
                  </p>
                  <Button
                    variant="primary"
                    type="button"
                    className="ff-button"
                    onClick={this.closeModal}
                  >
                    Go to Login
                  </Button>{" "}
                </React.Fragment>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Login;
