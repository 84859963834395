import InputDataForm from "../../components/common/form";
import * as trueScoreService from "../../services/trueScore/trueScoreService";
import * as analytics from "../../utils/analytics";

class TrueScoreModel extends InputDataForm {
    state = {
        data: {
            partnerType: "",
            panNo: "",
            email: "",
            mobile: "",
            transactions: {}
        },
        totalCount: 0
    }

    async getTrueScoreTotalCount() {
        const data = await trueScoreService.getTrueScoreCount();
        this.setState({ totalCount: data.count });
    }

    async componentDidMount() {
        await this.getTrueScoreTotalCount();
        analytics.track();
    }
}

export default TrueScoreModel;