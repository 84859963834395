import Joi from "joi-browser";
import InputForm from "../../components/common/form";
import * as registFormService from "../../services/login/registerFormService";
import moment from "moment";
import auth from "../../services/common/authService";
import * as analytics from "../../utils/analytics";
import leadInfoService from "../../services/partner/leadInfoService";
import SharedCache from "../../SharedCache";
import * as commonUtils from '../../utils/common-utils';

class RegisterFormModel extends InputForm {
  state = {
    data: {
      firstName: "",
      //middleName: "",
      lastName: "",
      // userName: "",
      email: "",
      mobileNo: "",
      // dob: "",
      password: "",
      confirmPassword: "",
      partnerName: "Fundfina",
      otp: "",
    },
    errors: {},
    info: {},
    isRegenerateLink: false,
    isRegenerateText: false,
    showSpinner: false,
    counter: 0,
    timerId: 0,
    timer: "",
    isValidUserName: false,
    isValidEmail: false,
    isValidMobile: false,
    isValidOTP: false,
    tnc: true,
    showTnc: false,
    showPrivacyPolicy: false,
  };
  schema = {
    firstName: Joi.string().required().label("First Name"),
    // middleName: Joi.string().allow("").label("Middle Name"),
    lastName: Joi.string().required().label("Last Name"),
    // userName: Joi.string().min(5).required().label("Username"),
    email: Joi.string().required().email().label("Email Id"),
    mobileNo: Joi.number().required().label("Mobile No"),
    // dob: Joi.any().required().disallow("").label("Date of Birth"),
    otp: Joi.number().required().label("OTP"),
    password: Joi.string().required().min(8).label("Password"),
    confirmPassword: Joi.string().required().min(8).label("Re-enter password"),
    partnerName: Joi.string(),
  };
  async componentDidMount() {
    if (auth.getCurrentUser()) return (window.location = "/");
    analytics.track();

    let { data } = this.state;

    const partnerId = this.props.match.params.partnerId;

    if (partnerId) {
      const userData = await leadInfoService.userData(partnerId, true);
      SharedCache.init(userData);
      SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
      if (SharedCache.app)
        SharedCache.app.refresh();
    }

    if (!auth.getCurrentPartner()) {
      data.partnerName = "Fundfina";
      this.setState({ data });
    }
    else {
      let getPartner = auth.getCurrentPartner();
      data.partnerName = getPartner.partnerName ? getPartner.partnerName : "Fundfina";
      this.setState({ data });
    }
  }
  handleUserNameChange = async ({ currentTarget: input }) => {
    const { data, errors, info } = this.state;

    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      this.setState({ isValidUserName: false });
      return;
    }

    this.setState({ showSpinner: true });

    try {
      const { success, message } = await registFormService.checkUserName(
        input.value
      );

      if (success) {
        delete errors[input.name];
        info[input.name] = "username is available";
        this.setState({
          errors,
          data,
          isValidUserName: true,
          showSpinner: false,
        });
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidUserName: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ data, errors, showSpinner: false });
    }
  };
  handleEmailChange = async ({ currentTarget: input }) => {
    const { data, errors, info } = this.state;

    const errorMessage = this.validateProperty(input);
    if (errorMessage) {
      this.setState({ isValidEmail: false });
      return;
    }

    this.setState({ showSpinner: true });

    try {
      const { success, message } = await registFormService.checkEmail(
        input.value
      );

      if (success) {
        delete errors[input.name];
        info[input.name] = "email is valid";
        this.setState({ errors, data, showSpinner: false, isValidEmail: true });
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidEmail: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors[input.name] = ex.response.data.message;
      this.setState({ data, errors, showSpinner: false });
    }
  };
  handleMobileChange = async ({ currentTarget: input }) => {
    const { data, errors, info } = this.state;

    if (input.value.length !== 10 || isNaN(input.value)) return;

    this.setState({ showSpinner: true });

    try {
      const {
        success,
        message,
      } = await registFormService.checkMobileAndSendOTP(input.value);

      if (success) {
        delete errors[input.name];
        delete errors["otp"];
        info[input.name] = "OTP sent successfully";
        this.setState({
          errors,
          data,
          isValidMobile: true,
          showSpinner: false,
        });
        this.startTimer();
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidMobile: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ data, errors, showSpinner: false });
    }
  };
  handleOTPChange = async ({ currentTarget: input }) => {

    return

    const { data, errors, info } = this.state;

    if (input.value.length !== 6 || isNaN(input.value)) return;

    this.setState({ showSpinner: true });

    try {
      const { success, message } = await registFormService.authenticateOTP(
        data.mobileNo,
        input.value
      );

      if (success) {
        delete errors[input.name];
        info[input.name] = "OTP Matched";
        this.stopTimer();
        this.setState({
          errors,
          data,
          isRegenerateLink: false,
          isValidOTP: true,
          showSpinner: false,
          isRegenerateText: false,
        });
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({ errors, data, isValidOTP: false, showSpinner: false });
      }
    } catch (ex) {
      this.stopTimer();
      this.startTimer();
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ data, errors, isValidOTP: false, showSpinner: false });
    }
  };
  handleRegenerateOTP = async () => {
    const { data, errors, info } = this.state;

    if (data.mobileNo.length !== 10 || isNaN(data.mobileNo)) return;

    this.setState({ showSpinner: true, isValidOTP: false });

    try {
      const { success, message } = await registFormService.checkMobileAndSendOTP(
        data.mobileNo
      );

      if (success) {
        info["mobileNo"] = "OTP sent successfully";
        delete info["otp"];
        delete errors["otp"];
        delete errors["mobileNo"];
        this.setState({ data, info, errors, showSpinner: false });
        this.startTimer();
      } else {
        errors["mobileNo"] = message;
        delete info["mobileNo"];
        this.setState({ data, errors, info, showSpinner: false });
      }
    } catch (ex) {
      errors["otp"] = ex.response.data.message;
      delete info["otp"];
      this.setState({ data, errors, info, showSpinner: false });
    }
  };
  async handleCustomErrors(errors) {
    const {
      data,
      isValidEmail,
      isValidMobile,
      isValidOTP,
      isValidUserName,
      tnc,
    } = this.state;

    if (!errors["email"] && data.email) {
      if (isValidEmail) delete errors["email"];
      else errors["email"] = "Please Enter Valid Email";
    }

    if (!errors["mobileNo"] && data.mobileNo) {
      if (isValidMobile) delete errors["mobileNo"];
      else errors["mobileNo"] = "Please Enter Valid Email";
    }

    // if (!errors["userName"] && data.userName) {
    //   if (isValidUserName) delete errors["userName"];
    //   else errors["userName"] = "UserName already exists";
    // }

    if (!errors["otp"] && data.otp) {
      // if (isValidOTP) delete errors["otp"];
      // else errors["otp"] = "Invalid OTP";
    }

    if (!errors["password"] && data.password) {
      if (commonUtils.checkPassword(data.password)) delete errors["password"];
      else errors["password"] =  `Password should contain Minimum 8 Characters, Combination of Uppercase and Lowercase, Numbers and Special Characters`;
    }

    if (!errors["confirmPassword"] && data.confirmPassword) {
      if (data.password === data.confirmPassword)
        delete errors["confirmPassword"];
      else errors["confirmPassword"] = "passwords do not match";
    }

    // if (!errors["dob"] && data.dob) {
    //   let age = moment().diff(data.dob, "years");
    //   if (age < 18) errors["dob"] = "Age must be greater than or equal to 18";
    //   else delete errors["dob"];
    // }

    if (!tnc) errors["tnc"] = "Please agree to terms and conditions";
    else delete errors["tnc"];

    this.setState({ errors });

    return errors;
  }
  handleDobChange = (value) => {
    const { data, errors } = this.state;

    data.dob = value;
    delete errors["dob"];
    this.setState({ data, errors });
  };
  doSubmit = async () => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });

    try {
      const { success, message } = await registFormService.registerUser(
        this.state.data
      );

      if (success) window.location = "/login";
      else {
        errors.globalError = message;
        this.setState({ data, errors, showSpinner: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        errors.globalError = ex.response.data.message;
        this.setState({ data, errors, showSpinner: false });
      }
    }
  };

  onBlurPassword = () => {

    let data = this.state.data;
    let errors = this.state.errors;

    if (data.password) {
      let isValid = commonUtils.checkPassword(data.password)

      if (!isValid) {
        errors["password"] = `Password should contain Minimum 8 Characters, Combination of Uppercase and Lowercase, Numbers and Special Characters`
        this.setState({ errors })
      }
    }
  }
}

export default RegisterFormModel;
