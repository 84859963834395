import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import React from "react";
import queryString from 'query-string'
import * as contractService from "../../services/contract/contractService";
import { Redirect } from "react-router-dom";

class NachRedirect extends InputDataForm {
    state = {
        mandateId: "",
        status: "",
        message: "",
        url: "",
        activeTab: "",
        redirectPath: "",
        state: null
    }
    async componentDidMount() {

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let mode = params.get('mode') || "api"

        const values = queryString.parse(this.props.location.search);

        const mandateId = values.digio_doc_id ? values.digio_doc_id : "";
        const status = values.status ? values.status : "";
        const message = values.message ? values.message : "";
        const npci_txn_id = values.npci_txn_id ? values.npci_txn_id : ""

        this.setState({ mandateId: mandateId });

        if (!mandateId) {
            this.setState({ redirectPath: "/invalidData", state: { message: "Invalid parameters provided" } });
        } else {
            const result = await auth.updateMandateDetails(mandateId, status, message, npci_txn_id);

            if (result.success) {
                if (result.isStp) {
                    if (status === "success") {
                        if (!auth.getCurrentUser())
                            this.setState({ redirectPath: `/${result.partnerId}/merchantAuthenticate`, state: { loanData: { loanId: result.loanId, partnerId: result.partnerId, redirectionUrl: `/verifyLoanContract/${result.loanId}` } } });
                        else
                            this.setState({ redirectPath: `/verifyLoanContract/${result.loanId}` });
                    }
                    else {
                        if (!auth.getCurrentUser())
                            this.setState({ redirectPath: `/${result.partnerId}/merchantAuthenticate`, state: { loanData: { loanId: result.loanId, partnerId: result.partnerId, redirectionUrl: `/updateBankDetail/${result.loanId}?mode=${mode}&error=${message}` } } });
                        else
                            this.setState({ redirectPath: `/updateBankDetail/${result.loanId}?mode=${mode}&error=${message}`, state: { activeTab: "loanTab" } });
                    }
                }
                else {
                    if (status === "success") {
                        if (!auth.getCurrentUser())
                            this.setState({ redirectPath: `/${result.partnerId}/merchantAuthenticate`, state: { loanData: { loanId: result.loanId, partnerId: result.partnerId, redirectionUrl: result.redirectUrl ? result.redirectUrl : `/verifyLoanContract/${result.loanId}` } } });
                        else {
                            if (result.redirectUrl) window.location = result.redirectUrl;
                            else
                                this.setState({ redirectPath: `/verifyLoanContract/${result.loanId}` });
                        }
                    }
                    else {
                        if (!auth.getCurrentUser())
                            this.setState({ redirectPath: `/${result.partnerId}/merchantAuthenticate`, state: { loanData: { loanId: result.loanId, partnerId: result.partnerId, redirectionUrl: result.eNachErrorCallBackUrl ? result.eNachErrorCallBackUrl : `/updateBankDetail/${result.loanId}?mode=${mode}&error=${message}` } } });
                        else {
                            if (result.eNachErrorCallBackUrl) window.location = result.eNachErrorCallBackUrl;
                            else
                                this.setState({ redirectPath: `/updateBankDetail/${result.loanId}?mode=${mode}&error=${message}` });
                        }
                    }
                }
            }
            else
                this.setState({ redirectPath: "/invalidData", state: { message: result.message } });
        }
    }
    render() {
        let { redirectPath, status, message, state } = this.state;
        return <React.Fragment>
            {status !== "success" && <p style={{ color: "red", fontSize: 15, fontWeight: "bold" }}>{message}</p>}
            {redirectPath && (
                <Redirect
                    to={{
                        pathname: redirectPath,
                        state
                    }}
                />
            )}
        </React.Fragment>;
    }
}
export default NachRedirect;
