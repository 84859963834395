import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as portfolioService from "../../services/portfolio/portfolioService";

class KfsModel extends InputDataForm {
    state = {
        showSpinner: false,
        today: null,
        name: null,
        amount: null,
        tenure: null,
        loanId: null,
        processingFee: null,
        gstCharges: null,
        insuranceAmount: null,
        applicationCharges: null,
        stampingCharges: null,
        preEmiInterest: null,
        disbursementAmount: null,
        advanceEmiAmount: null,
        roi: null,
        estimatedEmi: null,
        emiDate: null,
        emiDueDate: null,
        repaymentList: [],
        amountInWords: null,
        frequency: null,
        loanNo: null,
        loanStartDate: null,
        isAdjustedPrinciple: false,
        repaymentFrequency: null,
        noOfInstalments: null,
        totalInterest: null,
        totalRepaymentAmount: null,
        apr: null,
        today: null,
        collectionType: null
    };
    async getKfsDetails(loanId) {

        this.setState({
            showSpinner: true
        });

        const {
            data: details,
        } = await portfolioService.getKfsDetails(loanId);

        this.setState({
            today: details.today ? details.today : null,
            name: details.name ? details.name : null,
            amount: details.amount ? details.amount : 0,
            tenure: details.tenure ? details.tenure : null,
            loanId: details.loanId ? details.loanId : null,
            processingFee: details.processingFee ? details.processingFee : 0,
            gstCharges: details.gstCharges ? details.gstCharges : 0,
            insuranceAmount: details.insuranceAmount ? details.insuranceAmount : 0,
            applicationCharges: details.applicationCharges ? details.applicationCharges : 0,
            stampingCharges: details.stampingCharges ? details.stampingCharges : 0,
            preEmiInterest: details.preEmiInterest ? details.preEmiInterest : 0,
            disbursementAmount: details.disbursementAmount ? details.disbursementAmount : 0,
            advanceEmiAmount: details.advanceEmiAmount ? details.advanceEmiAmount : 0,
            roi: details.roi ? details.roi : 0,
            estimatedEmi: details.estimatedEmi ? details.estimatedEmi : 0,
            emiDate: details.emiDate ? details.emiDate : null,
            emiDueDate: details.emiDueDate ? details.emiDueDate : null,
            repaymentList: details.emiSchedule ? details.emiSchedule : [],
            amountInWords: details.amountInWords ? details.amountInWords : null,
            frequency: details.frequency ? details.frequency : null,
            loanNo: details.loanNo ? details.loanNo : null,
            loanStartDate: details.loanStartDate ? details.loanStartDate : null,
            isAdjustedPrinciple: details.isAdjustedPrinciple ? details.isAdjustedPrinciple : false,
            repaymentFrequency: details.repaymentFrequency ? details.repaymentFrequency : null,
            noOfInstalments: details.noOfInstalments ? details.noOfInstalments : null,
            totalInterest: details.totalInterest ? details.totalInterest : null,
            totalRepaymentAmount: details.totalRepaymentAmount ? details.totalRepaymentAmount : 0,
            apr: details.apr ? details.apr : null,
            grievanceOfficer: details.grievanceOfficer ? details.grievanceOfficer : null,
            showSpinner: false,
            today: details.today ? details.today : null,
            collectionType: details.collectionType ? details.collectionType : null
        });

    }

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        let loanId = "";

        if (this.props.loanId)
            loanId = this.props.loanId;

        else
            loanId = this.props.match.params.id;

        if (loanId)
            await this.getKfsDetails(loanId);
    }
}

export default KfsModel;
