import React from "react";
import auth from "../../services/common/authService";
import { Form, Col, Button, Nav } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import UpdateMobileFormModel from "../../models/profile/updateMobileFormModelV2";

// Render the Calendar

class UpdateMobileForm extends UpdateMobileFormModel {
  render() {
    const {
      data,
      errors,
      info,
      showSpinner,
      isValidMobile,
      isValidOTP,
      isRegenerateText,
      isRegenerateLink,
      counter,
      otpSent,
    } = this.state;

    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 style={{ textAlign: "center" }}>Update Mobile No</h2> <br />
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="updatedMobileNo">
              <Form.Label>Mobile No*</Form.Label>
              <Form.Control
                type="text"
                pattern="[0-9]*"
                placeholder="Current Mobile Number"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="10"
                disabled={true}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md="4" controlId="updatedMobileNo">
              <Form.Label>New Mobile No*</Form.Label>
              <Form.Control
                type="number"
                pattern="[0-9]*"
                placeholder="Enter your new mobile number"
                name="updatedMobileNo"
                value={data["updatedMobileNo"]}
                onChange={this.handleChange}
                onBlur={this.handleMobileChange}
                autoComplete="off"
                maxLength={10}
                disabled={otpSent}
                // autoFocus={true}
              />
              {errors["updatedMobileNo"] && (
                <div className="alert alert-danger">
                  {errors["updatedMobileNo"]}
                </div>
              )}
              {info["updatedMobileNo"] && (
                <div className="alert alert-success">
                  {info["updatedMobileNo"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            {otpSent && (
              <Form.Group as={Col} md="4" controlId="otp">
                <Form.Label>OTP*</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your OTP"
                  name="otp"
                  value={data["otp"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  disabled={isValidOTP}
                  autoFocus={otpSent}
                  maxLength="6"
                />
                {errors["otp"] && (
                  <div className="alert alert-danger">{errors["otp"]}</div>
                )}
                {info["otp"] && (
                  <div className="alert alert-success">{info["otp"]}</div>
                )}
                {isRegenerateText && (
                  <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                )}
                {isRegenerateLink && (
                  <Nav.Item id="regenerateOTP">
                    <Nav.Link
                      className="underline"
                      onClick={this.sendOTP}
                      style={{
                        padding: 0,
                        float: "right",
                        textDecoration: "underline",
                      }}
                    >
                      Re-generate OTP
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Form.Group>
            )}
          </Form.Row>

          {!otpSent && (
            <Button className="ff-button" type="button" onClick={this.sendOTP}>
              Generate OTP{" "}
            </Button>
          )}
          {otpSent && (
            <Button
              className="ff-button"
              type="submit"
              disabled={data.isSubmitted}
            >
              Submit
            </Button>
          )}
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}

export default UpdateMobileForm;
