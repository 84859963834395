import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";
import momentJalaali from "moment-jalaali";


const apiEndpoint = apiUrl + "/ops/admin";

export async function getUserList(model) {
	const { data } = await http.post(`${apiEndpoint}/getPayinPayoutUserList`, {
		//mobile: model.mobileNo,
		name: model.name
		//email: model.email,
	});
	return data;
}

export async function getUserInfo(partnerId) {
	const { data } = await http.post(`${apiEndpoint}/getPayInPayOutList`, {
		partnerId,
	});
	return data;
}

export async function updatePayInPayOut(model) {
	const { data } = await http.post(`${apiEndpoint}/savePayInPayOut`, {
		partnerId: model.partnerId,
		utrNo: model.utrNo,
		type: model.paymentMode,
		amount: model.amount,
		transactionDate: moment(model.transactionDate).format("DD/MM/yyyy"),
		depositDate: moment(model.depositDate).format("DD/MM/yyyy"),
	});
	return data;
}

export async function deletePayInPayOut(partnerId, id) {
	const { data } = await http.post(`${apiEndpoint}/deletePayInPayOut`, {
		partnerId,
		id,
	});
	return data;
}


