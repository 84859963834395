import React from "react";
import { Form, Col, Button, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import lenderAccountSummaryModel from "../../models/lenderAccountSummary/lenderAccountSummaryModel";
import ReactExport from "react-export-excel";
import NumberFormat from "react-number-format";

class LenderAccountSummary extends lenderAccountSummaryModel {

    renderExcel() {
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        return (<ExcelFile
            element={
                <Button
                    className="ff-button"
                    type="button"
                >
                    Export to Excel
                </Button>
            }
            filename={`LenderAccountSummary_${moment(new Date()).format("DDMMYYYY")}`}
        >
            <ExcelSheet data={this.state.list} name="RepaymentData">
                <ExcelColumn
                    label="Lending Partner"
                    value="lenderName"
                ></ExcelColumn>
                <ExcelColumn
                    label="Balance in Disbursment Escrow"
                    value="availableBalance"
                ></ExcelColumn>
                <ExcelColumn
                    label="Available Balance for Allocation"
                    value="currentBalance"
                ></ExcelColumn>
                <ExcelColumn
                    label="Last Updated"
                    value={(col) =>
                        col.updatedOn
                            ? moment(col.updatedOn).format("DD/MM/yyyy")
                            : ""
                    }
                ></ExcelColumn>
            </ExcelSheet>
        </ExcelFile>)
    }

    render() {
        let { list, showSpinner, errors, successMsg } = this.state;

        return (<React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>Lender Account Summary</h2>
            {" "}
            <br />
            <br />
            <Form>
                <Form.Row>

                    <Form.Group as={Col} md="12" style={{ textAlign: "right" }}>
                        {this.renderExcel()}
                        <Button
                            className="ff-button"
                            type="button"
                            onClick={this.handleRefreshAll}
                            style={{ marginLeft: 10 }}
                            disabled={!this.state.refreshAllEnabled}>
                            {this.state.refreshAllEnabled ? "Refresh All" : "In Progress"}
                        </Button>
                        {!this.state.refreshAllEnabled ? <Button
                            className="ff-button"
                            type="button"
                            onClick={this.handleJobStatus}
                            style={{ marginLeft: 10 }}
                        >
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                        </Button> : ""}
                    </Form.Group>
                </Form.Row>
            </Form>

            {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
            {successMsg && <div className="alert alert-success">{successMsg}</div>}
            <br />
            <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        <th>Lending Partner</th>
                        <th>Balance in Disbursment Escrow</th>
                        <th>Available Balance for Allocation</th>
                        <th>Last Updated</th>
                        <th>Refresh</th>
                    </tr>
                </thead>
                <tbody>
                    {list &&
                        list.map((item) => {

                            return (
                                <tr>
                                    <td>{item.lenderName}</td>
                                    <td> <NumberFormat value={item.availableBalance} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                                    <td> <NumberFormat value={item.currentBalance} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                                    <td>{moment(item.updatedOn).format("DD/MM/yyyy") + " " + moment(item.updatedOn).format('HH:mm:ss')}</td>
                                    <td>
                                        <Button
                                            className="ff-button"
                                            type="button"
                                            onClick={() => { this.handleAccountRefresh(item.lenderId, item.accountNumber) }}
                                            style={{ float: "right", marginLeft: 10 }}
                                            disabled={this.state.accountRefreshState[item.accountNumber]}>

                                            Refresh
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    {!list && (
                        <tr key="no">
                            <td colSpan="10">No record found</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <div>

            </div>
        </React.Fragment>
        )
    }
}

export default LenderAccountSummary;