import React from "react";
import { Redirect } from "react-router-dom";
import InputDataForm from "../../components/common/form";

class RedirectInvalid extends InputDataForm {
  state = {
    message: "",
    redirect: false,
  };
  async componentDidMount() {
    let message = this.props.match.params.message;
    //await auth.setLoggedInFrom("RedirectAuthLink");

    this.setState({ message: message, redirect: true });
  }
  render() {
    let { message, redirect } = this.state;
    return (
      redirect && (
        <Redirect
          to={{
            pathname: "/invalidData",
            state: { message: message },
          }}
        />
      )
    );
  }
}

export default RedirectInvalid;
