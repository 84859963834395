import React from "react";
import { ListGroup, Form, Col, Button } from "react-bootstrap";
import Parser from 'html-react-parser';
import InputDataForm from "../../components/common/form";
import Joi, { errors } from "joi-browser";
import * as analytics from "../../utils/analytics";

class Insights extends InputDataForm {
    state = {
        data: {
            EMAIL: ""
        }
    }
    schema = {
        EMAIL: Joi.string().allow("").label("Email Id"),
    };
    componentDidMount() {
        analytics.track();
    }
    render() {
        let { data } = this.state;
        var thisIsMyCopy = '<link href = "//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel = "stylesheet" type = "text/css">' +
            '<style type="text/css">' +
            '#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }</style>' +
            '<div id="mc_embed_signup">' +
            '<form action="https://fundfina.us18.list-manage.com/subscribe/post?u=e09ba49760486caa5c4edc5de&amp;id=6c5aeec4e3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>' +
            '<div id="mc_embed_signup_scroll">' +
            '<label for="mce-EMAIL">Subscribe</label>' +
            '<input type="email" value={data["email"]} name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required  onChange={this.handleChange}>' +
            '<div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_e09ba49760486caa5c4edc5de_6c5aeec4e3" tabindex="-1" value=""></div>' +
            '<div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>' +
            '</div></form></div>';
        return (
            <React.Fragment>


                <h2 style={{ textAlign: "center" }}>Insights</h2> <br />
                <div id="mc_embed_signup">
                    <form action="https://fundfina.us18.list-manage.com/subscribe/post?u=e09ba49760486caa5c4edc5de&amp;id=6c5aeec4e3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="mce-EMAIL">
                                <Form.Label>Fundfina Views: Our latest thinking on open banking, small business, credit, economy and technology.</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="EMAIL"
                                    value={data["EMAIL"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    autoFocus="true"
                                    id="mce-EMAIL"
                                    placeholder="Your email address"
                                />
                            </Form.Group>

                        </Form.Row>
                        <Button
                            className="ff-button"
                            type="submit"
                            name="subscribe"
                            id="mc-embedded-subscribe"
                        >
                            Subscribe
                        </Button>

                    </form></div>
                <br />

                {/* <div className="content">{Parser(thisIsMyCopy)}</div> */}
                <ListGroup>
                    <ListGroup.Item>
                        <a href="https://www.provenir.com/resources/blog/ten-companies-using-alternative-data-for-the-greater-good/" target="_blank">Ten companies using alternative data for the Greater Good by Provenir</a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <a href="https://www.microsave.net/2021/08/16/fundfina-a-journey-to-tap-a-hidden-multi-million-msme-market-in-india/" target="_blank">Microsave Blog on Fundfina</a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <a href="https://mailchi.mp/d174a6872cfe/fundfina-views-lending_pledge_inclusive50-8225976" target="_blank">Lending Pledge</a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <a href="https://mailchi.mp/a237bcb31cf9/fundfina-views-khuljasimsim-1332892" target="_blank">August 2020 - NewsLetter</a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <a href="https://mcusercontent.com/e09ba49760486caa5c4edc5de/files/ea6f9604-f8d2-428c-b7f9-227ee49ace52/July_Newsletter.pdf" target="_blank">July 2020 - NewsLetter</a>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <a href="https://us18.campaign-archive.com/?u=e09ba49760486caa5c4edc5de&id=b1d624d62d" target="_blank">June 2020 - TrueScore™</a>
                    </ListGroup.Item>
                </ListGroup></React.Fragment >
        );
    }

}

export default Insights;