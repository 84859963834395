import InputDataForm from "../../components/common/form";
import * as commonService from "../../services/common/commonService";
import * as loanDecisionFormService from "../../services/borrow/loanDecisionFormService";
import auth from "../../services/common/authService";
import moment from "moment";
import Joi, { errors } from "joi-browser";
import _ from "lodash";
import * as analytics from "../../utils/analytics";

class LoanDecisionFormModel extends InputDataForm {
  state = {
    data: {
      status: "",
      rejectReason: "",
      rejectDescription: "",
    },
    staticData: {
      loanId: "",
      userId: "",
      userRole: "",
      clientCode: "",
      dob: "",
      firstName: "",
      middleName: "",
      lastName: "",
      fullName: "",
      employment: "",
      panNo: "",
      add1: "",
      add2: "",
      add3: "",
      addressType: "Correspondence",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      poaNo: "",
      poaType: "",
      isRetailer: false,
      alternateEmail: "",
      alternateMobile: "",
      emailId: "",
      mobileNo: "",
      kycStatus: "",
      entityName: "",
      gstList: [],
      userRole: "",
      partnerName: "",
      udyogAadhaarNo: "",
      udyogAadhaarFileName: "",
      udyogAadhaarFilePath: "",
      poaFileName: "",
      poaFilePath: "",
      fullAddress: "",
      panFileName: "",
      panFilePath: "",
      profileFileName: "",
      profileFilePath: "",
      typeOfEntity: "",
      questionnairre: {},
      amount: "",
      loanCategory: "",
      frequency: "",
      loanDescription: "",
      loanType: "",
      loanGenDate: "",
      roi: "",
      maturity: "",
      loanStatus: "",
      loanNo: "",
      campaignTime: "",
      additionalDescription: "",
      evaluatorRole: "",
      emi: "",
      disbursalBankDetails: {}
    },
    showSpinner: false,
    optionalDocumentList: [],
    rejectReasonList: [],
    errors: {},
    showPopUp: false,
    loggedInFrom: ""
  };

  schema = {
    status: Joi.string().required(),
    rejectReason: Joi.string().allow("", null),
    rejectDescription: Joi.string().allow("", null),
  };

  async mapDataToState(userData) {
    let staticData = { ...this.state.staticData };
    let files = { ...this.state.files };

    let { user, loan } = userData;

    let {
      userDetails,
      addressDetails,
      bankDetails,
      gstDetails,
      poaDetails,
      panDocDetails,
      additionalDocuments,
      udyogAadhaar,
      profileDocDetails,
      questionnairreDetails,
    } = user;

    const currentUser = auth.getCurrentUser();

    //User Details
    if (loan._id) staticData.loanId = loan._id;
    if (user._id) staticData.userId = user._id;
    if (userDetails.firstName) staticData.firstName = userDetails.firstName;
    if (userDetails.middleName) staticData.middleName = userDetails.middleName;
    if (userDetails.lastName) staticData.lastName = userDetails.lastName;
    if (userDetails.role) staticData.userRole = userDetails.role;
    if (userDetails.kycStatus) staticData.kycStatus = userDetails.kycStatus;
    if (userDetails.clientCode) staticData.clientCode = userDetails.clientCode;
    if (questionnairreDetails)
      staticData.questionnairre = questionnairreDetails;
    if (userDetails.role) staticData.userRole = userDetails.role;
    if (userDetails.dob)
      staticData.dob = moment(userDetails.dob).format("DD/MM/yyyy");
    if (userDetails.doi)
      staticData.doi = moment(userDetails.doi).format("DD/MM/yyyy");
    if (userDetails.typeOfEntity)
      staticData.typeOfEntity = userDetails.typeOfEntity;

    let middleName = staticData.middleName ? ` ${staticData.middleName}` : "";
    staticData.fullName = `${staticData.firstName}${middleName} ${staticData.lastName}`;

    if (userDetails.employment) staticData.employment = userDetails.employment;
    if (userDetails.isRetailer) staticData.isRetailer = staticData.isRetailer;

    if (currentUser.role) staticData.evaluatorRole = currentUser.role;

    if (udyogAadhaar && udyogAadhaar.aadhaarNo) {
      staticData.udyogAadhaarNo = udyogAadhaar.aadhaarNo;
      staticData.udyogAadhaarFileName = udyogAadhaar.docDetails[0].fileName;
      staticData.udyogAadhaarFilePath = udyogAadhaar.docDetails[0].filePath;
    }
    // //Email and Mobile

    if (userDetails.emailId && userDetails.emailId[0]) {
      staticData.emailId = userDetails.emailId[0];
    }
    if (userDetails.mobileNo && userDetails.mobileNo[0] && userDetails.mobileNo[0].mobileNumber) {
      staticData.mobileNo = userDetails.mobileNo[0].mobileNumber;
    }
    if (userDetails.emailId && userDetails.emailId[1]) {
      staticData.alternateEmail = userDetails.emailId[1];
    }
    if (userDetails.mobileNo && userDetails.mobileNo[1] && userDetails.mobileNo[1].mobileNumber) {
      staticData.alternateMobile = userDetails.mobileNo[1].mobileNumber;
    }
    // //Address Details
    if (addressDetails[0] && addressDetails[0].add1)
      staticData.add1 = addressDetails[0].add1;
    if (addressDetails[0] && addressDetails[0].add2)
      staticData.add2 = addressDetails[0].add2;
    if (addressDetails[0] && addressDetails[0].add3)
      staticData.add3 = addressDetails[0].add3;
    if (addressDetails[0] && addressDetails[0].city) {
      staticData.city = addressDetails[0].city;
    }
    if (addressDetails[0] && addressDetails[0].state) {
      staticData.state = addressDetails[0].state;
    }
    if (addressDetails[0] && addressDetails[0].pincode)
      staticData.pincode = addressDetails[0].pincode;
    if (user.panNo) {
      staticData.panNo = user.panNo;
    }
    if (staticData.add2) staticData.add2 = ` ${staticData.add2}`;
    staticData.fullAddress = `${staticData.add1}${staticData.add2} ${staticData.add3}`;
    // //Bank Details

    if (loan.disbursalBankDetails)
      staticData.disbursalBankDetails = loan.disbursalBankDetails;

    // //GST Details
    let gstList = [];
    for (const item of gstDetails) {
      let gst = {
        gstNo: item.gstNo ? item.gstNo : "",
        gstFile:
          item.docDetails[0] && item.docDetails[0].fileName
            ? item.docDetails[0].fileName
            : "",
        gstFilePath:
          item.docDetails[0] && item.docDetails[0].filePath
            ? item.docDetails[0].filePath
            : "",
      };

      gstList.push(gst);
    }

    staticData.gstList = gstList;

    // //POA Details
    if (poaDetails[0] && poaDetails[0].poaNo) {
      staticData.poaNo = poaDetails[0].poaNo;
    }
    if (poaDetails[0] && poaDetails[0].poaType)
      staticData.poaType = poaDetails[0].poaType;

    if (panDocDetails[0] && panDocDetails[0].fileName) {
      staticData.panFileName = panDocDetails[0].fileName;
      staticData.panFilePath = panDocDetails[0].filePath;
    }

    if (
      poaDetails[0] &&
      poaDetails[0].docDetails[0] &&
      poaDetails[0].docDetails[0].fileName
    ) {
      staticData.poaFileName = poaDetails[0].docDetails[0].fileName;
      staticData.poaFilePath = poaDetails[0].docDetails[0].filePath;
    }

    if (
      profileDocDetails &&
      profileDocDetails[0] &&
      profileDocDetails[0].fileName
    ) {
      staticData.profileFileName = profileDocDetails[0].fileName;
      staticData.profileFilePath = profileDocDetails[0].filePath;
    }

    let optionalDocumentList = [];

    if (additionalDocuments && additionalDocuments.length > 0) {
      for (const item of additionalDocuments) {
        if (item.docDetails[0].fileName) {
          const addDocData = {
            fileName:
              item.docDetails[0] && item.docDetails[0].fileName
                ? item.docDetails[0].fileName
                : "",
            filePath:
              item.docDetails[0] && item.docDetails[0].filePath
                ? item.docDetails[0].filePath
                : "",
          };

          optionalDocumentList.push(addDocData);
        }
      }
    }

    //Loan Info
    if (loan.amount) staticData.amount = loan.amount;
    if (loan.purpose) staticData.purpose = loan.purpose;
    if (loan.description) staticData.description = loan.description;
    if (loan.campaignTime) staticData.campaignTime = loan.campaignTime;
    if (loan.maturity) {
      if (loan.repaymentFrequency == "Monthly")
        staticData.maturity = `${loan.maturity} months`;
      else if (loan.repaymentFrequency == "Daily")
        staticData.maturity = `${loan.maturity} days`;
      else if (loan.repaymentFrequency == "Weekly")
        staticData.maturity = `${loan.maturity} weeks`;
      else if (loan.repaymentFrequency == "Forthnightly")
        staticData.maturity = `${loan.maturity} forthnights`;
      else if (loan.repaymentFrequency == "Adhoc")
        staticData.maturity = `${loan.tenure} days`;
    }
    if (loan.repaymentFrequency) staticData.frequency = loan.repaymentFrequency;
    if (loan.interestType) staticData.interestType = loan.interestType;
    if (loan.loanNo) staticData.loanNo = loan.loanNo;
    if (loan.partner) staticData.partner = loan.partner;
    if (loan.status) staticData.loanStatus = loan.status;
    if (loan.insertedOn)
      staticData.loanGenDate = moment(loan.insertedOn).format("DD/MM/yyyy");
    if (loan.interestRate) staticData.roi = loan.interestRate;
    if (loan.loanType) staticData.loanType = loan.loanType;
    staticData.loanCategory =
      loan.partner == "Fundfina" ? "FutureSecure" : "MerchantBrisk";

    this.setState({
      staticData,
      files,
      optionalDocumentList,
    });
  }

  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    analytics.track();

    const currentUser = auth.getCurrentUser();

    if (currentUser.role != "User") return (window.location = "/");

    const loanId = this.props.match.params.id;

    if (currentUser && currentUser.loggedInFrom) {
      this.setState({ loggedInFrom: currentUser.loggedInFrom });
    }

    if (!loanId) {
      return (window.location = "/loanModule");
    } else {
      await this.getLoanData(loanId);
      await this.getRejectReasonList();
    }
  }

  async getRejectReasonList() {
    let { rejectReasonList } = this.state;
    try {
      const rejectReasons = await loanDecisionFormService.getRejectReasonList("client");
      rejectReasonList = rejectReasons.success ? rejectReasons.data : [];

      this.setState({ rejectReasonList });

    } catch (ex) { }
  }

  async getLoanData(loanId) {
    let staticData = { ...this.state.staticData };
    try {
      const { data: loanDetails } = await loanDecisionFormService.getLoanData(
        loanId
      );

      if (loanDetails.success) {
        await this.mapDataToState(loanDetails.data);
      }

      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  async handleCustomErrors() {
    const data = { ...this.state.data };
    let errors = { ...this.state.errors };
    if (data.status === "Rejected By Client" && data.rejectReason.trim() == "") {
      errors["rejectReason"] = "Please select Reason";
    } else delete errors["rejectReason"];

    if (data.rejectReason.trim() == "Others" && data.rejectDescription.trim() == "") {
      errors["rejectDescription"] = "Please enter Comments";
    } else delete errors["rejectDescription"];
    this.setState({ errors });
    return errors;
  }

  async updateLoanStatus() {
    let data = { ...this.state.data };
    let staticData = { ...this.state.staticData };
    let errors = { ...this.state.errors };

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await loanDecisionFormService.updateLoanStatus(staticData.loanId, data);

      if (response.success) {
        await this.getLoanData(staticData.loanId);
        errors["globalError"] = "";
        this.setState({ errors, showSpinner: false });
        window.location.href = "/borrow";
      } else {
        errors["globalError"] = response.message;

        this.setState({ errors, showSpinner: false });
      }
    } catch (ex) {
      errors["globalError"] = ex.response.data.message;
      this.setState({ showSpinner: false, errors });
    }
  }

  doSubmit = async () => {
    let errors = await this.handleCustomErrors();

    if (_.isEmpty(errors)) await this.updateLoanStatus();
  };

}

export default LoanDecisionFormModel;
