import React from "react";
import { Spinner } from "react-bootstrap";

function SpinnerComponent({ showSpinner }) {
  return showSpinner ? (
    <Spinner
      size="lg"
      className="spinner"
      animation="border"
      variant="danger"
    />
  ) : (
    ""
  );
}
export default SpinnerComponent;
