import InputDataForm from "../../components/common/form";
import * as collections from "../../services/collections/collections";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import { toast } from "react-toastify";

class ReconciliationModel extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().subtract(6, "days"),
      toDate: momentJalaali(),
      partner: "all",
      outcome: "all"
    },
    actionsList: [],
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    partners: [],
    outcomeDropDown: [
      {
        title: "Connected",
        value: "Connected Successfully"
      },
      {
        title: "Not Connected",
        value: "Not Connected"
      }
    ],
    partnerName: "",
    allActionsList: []
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    partner: Joi.any(),
    page: Joi.any(),
    outcome: Joi.any(),
    isAllActionList: Joi.any()
  };
  async getCollectionsOutcomeDetailsList(isAllActionList) {

    this.state.data.page = this.state.currentPage

    let data = { ...this.state.data };

    if (isAllActionList)
      data.isAllActionList = true
    else
      data.isAllActionList = false

    let daysDiff = commonService.numberOfDaysDifferece(new Date(data.fromDate), new Date(data.toDate));

    if (daysDiff > 7) {
      toast.error("Max Date Range 7 days");
      this.setState({ showSpinner: false });
      return
    }

    this.setState({ showSpinner: true });
    try {
      const { data: actionsList, totalPages } = await collections.getCollectionsOutcomeDetailsList(data);

      if (isAllActionList)
        this.setState({ data, showSpinner: false, allActionsList: actionsList, download: true }, () => {
          this.setState({ download: false })
        });
      else
        this.setState({ data, showSpinner: false, pageSize: this.state.currentPage == 1 ? totalPages : this.state.pageSize, actionsList });

    } catch (ex) {
      this.setState({ data, showSpinner: false, actionsList: [] });
    }
  };

  async componentDidMount() {
    if (this.props && this.props.location && this.props.location.search) {
      let searchParam = new URLSearchParams(this.props.location.search);
      let partnerId = searchParam.get("partnerId")
      let partnerName = searchParam.get("partnerName")
      let fromDate = searchParam.get("fromDate")
      let toDate = searchParam.get("toDate")

      let data = this.state.data;

      if (partnerId)
        data.partner = partnerId;

      if (fromDate)
        data.fromDate = momentJalaali(fromDate)

      if (toDate)
        data.toDate = momentJalaali(toDate);

      this.state.partnerName = partnerName;

      this.setState({ data })
    }

    await this.getCollectionsOutcomeDetailsList();
    //    await this.getBorroingPartners();
  };
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getCollectionsOutcomeDetailsList();
  };

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getCollectionsOutcomeDetailsList()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getCollectionsOutcomeDetailsList()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getCollectionsOutcomeDetailsList()
    })
  }

  exportExcel = () => {
    this.getCollectionsOutcomeDetailsList(true)
  }

  closeReconModal = () => {
    this.setState({ showReconModal: false })
  }

  showReconModal = (utr) => {
    this.setState({ selectedUtrNo: utr, showReconModal: true })
  }

  downloadExcel = () => {
    this.getCollectionsOutcomeDetailsList(true)
  }
}

export default ReconciliationModel;
