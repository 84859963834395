import InputDataForm from "../../components/common/form";
import React from "react";
import { Redirect } from "react-router-dom";

class PartnerRedirect extends InputDataForm {
    state = {
        properties: {
            partnerId: "",
            type: ""
        },
        errors: {},
        info: {},
        redirect: false,
    };
    async componentDidMount() {
        let partnerId = this.props.match.params.id;
        let type = this.props.match.params.type;

        let properties = { ...this.state.properties };

        properties.partnerId = partnerId;
        properties.type = type;

        //const partner = auth.setFundfinaLogoDisplay(hideLogo);

        //if (partner)
        this.setState({ properties, redirect: true });

        // if (!partnerId)
        //     partnerId = "Fundfina";

        // let getPartner = await registFormService.getPartnerUser(partnerId);

        // if (getPartner.success) {

        //     // sessionStorage.setItem("partner", this.encrypt(JSON.stringify(getPartner.data)));
        //     SharedCache.set("partner", getPartner.data);

        //window.location.href = `/${partnerId}/${type}`;
        //}
        // else {
        //     window.location.href = `/${type}`;
        // }

    }
    render() {
        const { properties, redirect } = this.state;
        return (
            redirect && (
                <Redirect
                    to={{
                        pathname: `/${properties.partnerId}/${properties.type}`,
                        state: { userData: properties },
                    }}
                />
            )
        );
    }
}
export default PartnerRedirect;
