import React from "react";
import SpinnerComponent from "../../components/common/spinner";
import { Table } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import KfsModel from "../../models/common/kfsModel";

class Kfs extends KfsModel {
    render() {
        const {
            showSpinner,
            repaymentList,
            isAdjustedPrinciple,
            loanNo,
            name,
            amount,
            processingFee,
            gstCharges,
            insuranceAmount,
            applicationCharges,
            stampingCharges,
            preEmiInterest,
            disbursementAmount,
            advanceEmiAmount,
            tenure,
            roi,
            estimatedEmi,
            emiDate,
            emiDueDate,
            totalInterest,
            totalRepaymentAmount,
            apr,
            repaymentFrequency,
            noOfInstalments,
            today,
            collectionType
        } = this.state;


        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <p style={{ textAlign: "center" }}><b>Annex-II</b></p>
                <p style={{ textAlign: "center" }}><b>(cf. Para 5.2.1 of these Guidelines)</b></p>
                <p style={{ textAlign: "center" }}><b>Illustrative Format of key fact statement/fact sheet
                    (to be provided in a language understood by the borrower)</b></p>
                <div>
                    <p><b>Date:</b> {today}</p>
                    <p><b>Name of the borrower:</b> {name}</p>
                    <p><b>Loan reference Id:</b> {loanNo}</p>

                    <Table responsive striped bordered hover>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td style={{ width: "5%" }}><b>01</b></td>
                                <td style={{ width: "50%" }}><b>Loan Amount</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>02</b></td>
                                <td style={{ width: "50%" }}><b>Processing Fee</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={processingFee} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>03</b></td>
                                <td style={{ width: "50%" }}><b>GST Charges</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={gstCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>04</b></td>
                                <td style={{ width: "50%" }}><b>Insurance Amount</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={insuranceAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>05</b></td>
                                <td style={{ width: "50%" }}><b>Application Charges</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={applicationCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>06</b></td>
                                <td style={{ width: "50%" }}><b>Stamping Charges</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={stampingCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>07</b></td>
                                <td style={{ width: "50%" }}><b>Pre EMI interest</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={preEmiInterest} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>08</b></td>
                                <td style={{ width: "50%" }}><b>Disbursement Amount</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={disbursementAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>09</b></td>
                                <td style={{ width: "50%" }}><b>Advance EMI Amount</b></td>
                                <td style={{ width: "45%" }}><NumberFormat value={advanceEmiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>10</b></td>
                                <td style={{ width: "50%" }}><b>Tenure</b></td>
                                <td style={{ width: "45%" }}>{tenure}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>11</b></td>
                                <td style={{ width: "50%" }}><b>Rate of Interest (Annually)</b></td>
                                <td style={{ width: "45%" }}>{roi} {roi && <i className="fa fa-percent" aria-hidden="true"></i>} per annum</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>12</b></td>
                                <td style={{ width: "50%" }}><b>Estimated EMI</b></td>
                                <td style={{ width: "45%" }}><NumberFormat value={estimatedEmi} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>13</b></td>
                                <td style={{ width: "50%" }}><b>First EMI Date*</b></td>
                                <td style={{ width: "45%" }}>{emiDate}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>14</b></td>
                                <td style={{ width: "50%" }}><b>EMI Due Date</b></td>
                                <td style={{ width: "45%" }}>{emiDueDate}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>15</b></td>
                                <td style={{ width: "50%" }}><b>Mode of Disbursal</b></td>
                                <td style={{ width: "45%" }}>Online Transfer </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>16</b></td>
                                <td style={{ width: "50%" }}><b>Mode of Loan Repayment</b></td>
                                <td>{collectionType}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>17</b></td>
                                <td style={{ width: "50%" }}><b>Total Interest</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={totalInterest} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>18</b></td>
                                <td style={{ width: "50%" }}><b>Total Amount to be paid by the borrower</b></td>
                                <td style={{ width: "45%" }}>
                                    <NumberFormat value={totalRepaymentAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>19</b></td>
                                <td style={{ width: "50%" }}><b>APR (Inclusive of ROI + Processing Fee + Other Charges)</b></td>
                                <td style={{ width: "45%" }}>{apr} {apr && <i className="fa fa-percent" aria-hidden="true"></i>} per annum</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>20</b></td>
                                <td style={{ width: "50%" }}><b>Repayment Frequency</b></td>
                                <td style={{ width: "45%" }}>{repaymentFrequency}</td>
                            </tr>
                            <tr>
                                <td style={{ width: "5%" }}><b>21</b></td>
                                <td style={{ width: "50%" }}><b>Number of Instalments</b></td>
                                <td style={{ width: "45%" }}>{noOfInstalments}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <p>*These values may vary based on actual date of disbursal.</p>
                    <p><b>Fees and charges*</b></p>
                    <Table responsive striped bordered hover>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td><b>01</b></td>
                                <td><b>Charges or Late Payment of EMI</b></td>
                                <td>Rs. 17 for every event of Late payment.</td>
                            </tr>
                            <tr>
                                <td><b>02</b></td>
                                <td><b>Bounce Cheque Charges</b></td>
                                <td>Rs. 500 for every bounced cheque </td>
                            </tr>
                            <tr>
                                <td><b>03</b></td>
                                <td><b>Part Payment</b></td>
                                <td>No Part payment of EMI is allowed in this Loan</td>
                            </tr>
                            <tr>
                                <td><b>04</b></td>
                                <td><b>Legal Expenses</b></td>
                                <td>Stamp duty and other legal charges , present and future to be borne solely by the Borrower </td>
                            </tr>
                            <tr>
                                <td><b>05</b></td>
                                <td><b>Cooling off/look-up period during which borrower shall not be charged any
                                    penalty on prepayment of loan</b></td>
                                <td>5 days</td>
                            </tr>
                            <tr>
                                <td><b>06</b></td>
                                <td><b>Details of LSP acting as recovery agent and authorized to approach the
                                    borrower</b></td>
                                <td>Fundfina Marketplace Private Limited</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <br />
                <p><b>EMI Schedule</b></p>
                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "right" }}>EMI #</th>
                            <th style={{ textAlign: "right" }}>EMI Amount</th>
                            <th style={{ textAlign: "right" }}>Interest Recovered</th>
                            <th style={{ textAlign: "right" }}>Principal Outstanding</th>
                            <th style={{ textAlign: "right" }}>Principal Recovered</th>
                        </tr>
                    </thead>
                    <tbody>
                        {repaymentList &&
                            repaymentList.map((emi, i) => (
                                <tr key={`${emi._id}-${i}`}>
                                    <td style={{ textAlign: "right" }}>{emi.emiNumber}</td>
                                    <td style={{ textAlign: "right" }}>
                                        <NumberFormat value={emi.rePaymentAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        <NumberFormat value={emi.interestAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>
                                    {repaymentList.length - 1 === i && isAdjustedPrinciple &&
                                        <td style={{ textAlign: "right" }}>
                                            <NumberFormat value={emi.principalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /><span>*</span></td>
                                    }
                                    {repaymentList.length - 1 === i && !isAdjustedPrinciple &&
                                        <td style={{ textAlign: "right" }}>
                                            <NumberFormat value={emi.principalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /><span></span></td>
                                    }
                                    {repaymentList.length - 1 !== i &&
                                        <td style={{ textAlign: "right" }}>
                                            <NumberFormat value={emi.principalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                                    }
                                    <td style={{ textAlign: "right" }}>
                                        {" "}
                                        <NumberFormat value={emi.principalRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                    </td>
                                </tr>
                            ))}
                        {!repaymentList ||
                            (repaymentList.length === 0 && (
                                <tr key="no">
                                    <td colSpan="10">No record found</td>
                                </tr>
                            ))}
                    </tbody>
                </Table>{" "}
                {isAdjustedPrinciple && <span>* Adjusted Based On Rounding Off Calculation</span>}
            </React.Fragment >


        );
    }
}

export default Kfs;
