import React from "react";
import KycFormModel from "../../models/borrow/kycFormModel";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, ListGroup, Nav, FormCheck, Image } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import DatePicker from "react-datepicker2";
import moment from "moment";
import { isMobile } from 'react-device-detect';
import FileSizeText from "../../components/common/file-size";
import { Spinner } from 'react-bootstrap';

class KycForm extends KycFormModel {

  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    const {
      data,
      errors,
      files,
      fileErrors,
      showSpinner,
      poaTypeList,
      employmentList,
      stateOptions,
      cityOptions,
      ifscOptions,
      bankOptions,
      branchOptions,
      optional,
      optionalErrors,
      showBankSearch,
      showIfscSearch,
      bankList,
      isBankSelected,
      optionalDocumentList,
      isRegenerateLink,
      isRegenerateText,
      info,
      isValidOTP,
      counter,
      showOTP,
      isSubmitted,
      showDocConsent,
      docConsent,
      merchantCodeDisabled,
      kycStatus,
      showSuccessModal,
      infoHeader,
      infoMessage,
      type,
      showFinalPopUp,
      poaVerificationTypeList,
      currentUserRole,
      bankDetailsMandatory,
      nameDisabled,
      panDisabled,
      bankText,
      panDocDisabled,
      poaDisabled,
      isValidMobile,
      bankStatementError,
      uploading,
      uploadMessage,
      isSameAddress
    } = this.state;

    const inputIfscProps = {
      placeholder: "Enter your IFSC code",
      value: data["ifsc"],
      className: "form-control",
      onChange: this.onIfscChange
    };
    const inputBankProps = {
      placeholder: "Enter your bank",
      value: optional["searchBankName"],
      className: "form-control",
      onChange: this.onBankChange
    };
    const inputBranchProps = {
      placeholder: "Enter your branch",
      value: optional["searchBranchName"],
      className: "form-control",
      onChange: this.onBranchChange
    };
    const inputStateProps = {
      placeholder: "Enter your state",
      value: data["state"],
      className: "form-control",
      onChange: this.onStateChange,
      disabled: poaDisabled
    };
    const inputCityProps = {
      placeholder: "Enter your city",
      value: data["city"],
      className: "form-control",
      onChange: this.onCityChange,
      disabled: poaDisabled
    };

    const docConsentLabel = (
      <React.Fragment>
        I have read and agree to provide{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewDocConsent}
        >
          Document Consent
        </a>{" "}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} /> <br />

        <Form
          onSubmit={this.handleSubmit}
          autoCapitalize="on"
          autoComplete="off"
        >
          <Form.Row>
            <h2>Personal Details</h2>{" "}
            {isMobile && type !== "admin" && kycStatus === "Approved" && (
              <Button
                className="ff-button"
                type="submit"
                disabled={isSubmitted}
                style={{ marginLeft: 20, marginTop: 1, marginBottom: 7 }}
              >
                Next
              </Button>)}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="firstName">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your first name"
                name="firstName"
                value={data["firstName"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={nameDisabled}
              />
              {errors["firstName"] && (
                <div className="alert alert-danger">{errors["firstName"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="middleName">
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your middle name"
                name="middleName"
                value={data["middleName"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={nameDisabled}
              />
              {errors["middleName"] && (
                <div className="alert alert-danger">{errors["middleName"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="lastName">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your last name"
                name="lastName"
                value={data["lastName"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={nameDisabled}
              />
              {errors["lastName"] && (
                <div className="alert alert-danger">{errors["lastName"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="add1">
              <Form.Label>Address Line 1*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address line 1"
                name="add1"
                value={data["add1"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={data["add1"] && poaDisabled}
              />
              {errors["add1"] && (
                <div className="alert alert-danger">{errors["add1"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="add2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address line 2"
                name="add2"
                value={data["add2"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={poaDisabled}
              />
              {errors["add2"] && (
                <div className="alert alert-danger">{errors["add2"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="add3">
              <Form.Label>Address Line 3*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your address line 3"
                name="add3"
                value={data["add3"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={data["add3"] && poaDisabled}
              />
              {errors["add3"] && (
                <div className="alert alert-danger">{errors["add3"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="7" controlId="checkForAddress">
               <Form.Check
                  reverse
                  id="custom-switch-address"
                  type="switch"
                  value={isSameAddress}
                  label="Permanent Address is same as above address"
                  onClick={()=>this.handleSamePermanetAddress(isSameAddress)}
                />
              </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="perAdd1">
              <Form.Label>Permanent Address Line 1*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter permanent address line 1"
                name="perAdd1"
                value={data["perAdd1"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={isSameAddress}
              />
              {errors["perAdd1"] && (
                <div className="alert alert-danger">{errors["perAdd1"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="perAdd2">
              <Form.Label>Permanent Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter permanent address line 2"
                name="perAdd2"
                value={data["perAdd2"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={isSameAddress}
              />
              {errors["perAdd2"] && (
                <div className="alert alert-danger">{errors["perAdd2"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="perAdd3">
              <Form.Label>Permanent Line 3*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your permanent address line 3"
                name="perAdd3"
                value={data["perAdd3"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={ isSameAddress}
              />
              {errors["perAdd3"] && (
                <div className="alert alert-danger">{errors["perAdd3"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>

            <Form.Group as={Col} md="4" controlId="pincode">
              <Form.Label>Pincode*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your pincode"
                name="pincode"
                value={data["pincode"]}
                onChange={this.handlePincodeChange.bind(this)}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                maxLength="6"
                disabled={data["pincode"] && poaDisabled}
              />
              {errors["pincode"] && (
                <div className="alert alert-danger">{errors["pincode"]}</div>
              )}
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="state">
              <Form.Label>State*</Form.Label>
              <Autosuggest
                suggestions={stateOptions}
                onSuggestionsFetchRequested={this.onStateSuggestionsRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionsClearRequested={
                  this.onStateSuggestionsClearRequested
                }
                inputProps={inputStateProps}
                style={{ textTransform: "uppercase" }}
              />
              {errors["state"] && (
                <div className="alert alert-danger">{errors["state"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="city">
              <Form.Label>City*</Form.Label>
              <Autosuggest
                suggestions={cityOptions}
                onSuggestionsFetchRequested={this.onCitySuggestionsRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionsClearRequested={
                  this.onCitySuggestionsClearRequested
                }
                inputProps={inputCityProps}
                style={{ textTransform: "uppercase" }}
              />
              {errors["city"] && (
                <div className="alert alert-danger">{errors["city"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No*</Form.Label>
              <Form.Control
                type="text"
                pattern="[0-9]*"
                placeholder="Enter your mobile number"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                onBlur={this.handleMobileChange}
                autoComplete="off"
                maxLength="10"
                disabled={type !== "admin" || isValidOTP}
              />
              {errors["mobileNo"] && (
                <div className="alert alert-danger">{errors["mobileNo"]}</div>
              )}
              {info["mobileNo"] && (
                <div className="alert alert-success">{info["mobileNo"]}</div>
              )}
            </Form.Group>
            {showOTP && (
              <Form.Group as={Col} md="4" controlId="otp">
                <Form.Label>OTP*</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your OTP"
                  name="otp"
                  value={data["otp"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  disabled={isValidOTP}
                  onKeyUp={this.handleOTPChange}
                  autoFocus={showOTP}
                  maxLength="6"
                />
                {errors["otp"] && (
                  <div className="alert alert-danger">{errors["otp"]}</div>
                )}
                {info["otp"] && (
                  <div className="alert alert-success">{info["otp"]}</div>
                )}
                {isRegenerateText && (
                  <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                )}
                {isRegenerateLink && (
                  <Nav.Item id="regenerateOTP">
                    <Nav.Link
                      className="underline"
                      onClick={this.handleRegenerateOTP}
                      style={{
                        padding: 0,
                        float: "right",
                        textDecoration: "underline",
                      }}
                    >
                      Re-generate OTP
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Form.Group>
            )} <Form.Group
              as={Col}
              md="4"
              controlId="dob"
            >
              <Form.Label>Date of Birth*</Form.Label>
              <DatePicker
                name="dob"
                id="dob"
                value={data["dob"]}
                max={moment()}
                selectedMonth={moment().subtract(100, "years").year}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleDobChange(value)}
                placeholder="Enter your date of birth"
                disabled={data["dob"] && poaDisabled}
              />
              {errors["dob"] && (
                <div className="alert alert-danger">{errors["dob"]}</div>
              )}
            </Form.Group>{" "}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="panNo">
              <Form.Label>PAN Number*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your PAN number"
                name="panNo"
                value={data["panNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="10"
                onBlur={this.handlePANChange}
                style={{ textTransform: "uppercase" }}
                disabled={panDisabled}
              />
              {errors["panNo"] && (
                <div className="alert alert-danger">{errors["panNo"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="panFile">
              <Form.Group>
                <Form.Label>Upload PAN Image*</Form.Label>
                {
                  !files["panFile"] ?
                    <>
                      <Form.File
                        label={files["panFileLabel"]}
                        onChange={(e) => this.onFileChange(e, false, "", true)}
                        id="panFile"
                        name="panFile"
                        accept=".jpg,.png,.jpeg"
                        custom
                        disabled={panDocDisabled}
                      />
                      {/* {!fileErrors["panFile"] ? <FileSizeText /> : ""} */}
                    </>
                    :
                    <div style={!files["panFile"] ? { display: "none" } : {}} className="position-relative" >
                      {this.renderThumbnail(files?.panFileStream || files.panFileSrc)}
                      {!panDocDisabled ? this.renderThumbnailAction("panFile", files?.panFileStream || files.panFileSrc) : ""}
                    </div>

                }
                {" "}
                {fileErrors["panFile"] && (
                  <div className="alert alert-danger">
                    {fileErrors["panFile"]}
                  </div>
                )}
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="poaNo">
              <Form.Label>Address Proof Document Number*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your POA Number"
                name="poaNo"
                value={data["poaNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="30"
                onBlur={this.handlePOAChange}
                disabled={poaDisabled}
              />
              {errors["poaNo"] && (
                <div className="alert alert-danger">{errors["poaNo"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="poaType">
              <Form.Label>Address Proof Document Type*</Form.Label>
              <Form.Control
                as="select"
                name="poaType"
                value={data["poaType"]}
                onChange={this.handleChange}
                onBlur={this.handlePOATypeChange}
                disabled={poaDisabled}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                {poaTypeList.map((item, i) => (
                  <option key={i} value={item.defination}>
                    {item.defination}
                  </option>
                ))}
              </Form.Control>

              {errors["poaType"] && (
                <div className="alert alert-danger">{errors["poaType"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="poaFile">
              <Form.Group>
                <Form.Label>Upload Address Proof Front Image*</Form.Label>
                {
                  !files["poaFile"] ?
                    <>
                      <Form.File
                        label={files["poaFileLabel"]}
                        onChange={(e) => this.onFileChange(e, false, "", true)}
                        id="poaFile"
                        name="poaFile"
                        accept=".jpg,.png,.jpeg"
                        custom
                        disabled={poaDisabled}
                      />
                      {/* {!fileErrors["poaFile"] ? <FileSizeText /> : ""} */}
                    </>
                    :
                    <div style={!files["poaFile"] ? { display: "none" } : {}} className="position-relative" >
                      {this.renderThumbnail(files?.poaFileStream || files.poaFileSrc)}
                      {!poaDisabled ? this.renderThumbnailAction("poaFile", files?.poaFileStream || files.poaFileSrc) : ""}
                    </div>
                }


                {" "}
                {fileErrors["poaFile"] && (
                  <div className="alert alert-danger">
                    {fileErrors["poaFile"]}
                  </div>
                )}
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="poaBackFile">
              <Form.Group>
                <Form.Label>Upload Address Proof Back Image</Form.Label>
                {
                  !files["poaBackFile"] ?
                    <>
                      <Form.File
                        label={files["poaBackFileLabel"]}
                        onChange={(e) => this.onFileChange(e, false, "", true)}
                        id="poaBackFile"
                        name="poaBackFile"
                        accept=".jpg,.png,.jpeg"
                        custom
                        disabled={poaDisabled}
                      />
                      {/* {!fileErrors["poaBackFile"] ? <FileSizeText /> : ""} */}
                    </>
                    :
                    <div style={!files["poaBackFile"] ? { display: "none" } : {}} className="position-relative">
                      {this.renderThumbnail(files?.poaBackFileStream || files.poaBackFileSrc)}
                      {!poaDisabled ? this.renderThumbnailAction("poaBackFile", files?.poaBackFileStream || files.poaBackFileSrc) : ""}
                    </div>
                }
                {" "}
                {fileErrors["poaBackFile"] && (
                  <div className="alert alert-danger">
                    {fileErrors["poaBackFile"]}
                  </div>
                )}
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="poaPassword">
              <Form.Label>Address Proof Document Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your POA Document password"
                name="poaPassword"
                value={data["poaPassword"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="50"
                disabled={poaDisabled}
              />
              {errors["poaPassword"] && (
                <div className="alert alert-danger">{errors["poaPassword"]}</div>
              )}
            </Form.Group>
            {type === "admin" && (
              <Form.Group as={Col} md="4" controlId="poaVerificationType">
                <Form.Label>POA Verification Type</Form.Label>
                <Form.Control
                  as="select"
                  name="poaVerificationDocType"
                  value={data["poaVerificationDocType"]}
                  onChange={this.handleChange}
                  disabled={poaDisabled}
                >
                  {" "}
                  <option key="select" value="">
                    ---Select---
                  </option>
                  {poaVerificationTypeList.map((item, i) => (
                    <option key={i} value={item.defination}>
                      {item.defination}
                    </option>
                  ))}
                </Form.Control>

                {errors["poaVerificationDocType"] && (
                  <div className="alert alert-danger">{errors["poaVerificationDocType"]}</div>
                )}
              </Form.Group>)}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="email">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={data["email"]}
                onChange={this.handleChange}
                autoComplete="off"
                autoCapitalize="words"
                onBlur={this.handlePrimaryEmailChange}
              />
              {errors["email"] && (
                <div className="alert alert-danger">
                  {errors["email"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="employment">
              <Form.Label>Employment*</Form.Label>
              <Form.Control
                as="select"
                name="employment"
                value={data["employment"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                {employmentList.map((item, i) => (
                  <option key={i} value={item.defination}>
                    {item.defination}
                  </option>
                ))}
              </Form.Control>

              {errors["employment"] && (
                <div className="alert alert-danger">{errors["employment"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="merchantCode">
              <Form.Label>Merchant Code*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Merchant Code"
                name="merchantCode"
                value={data["merchantCode"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={merchantCodeDisabled}
              />
              {errors["merchantCode"] && (
                <div className="alert alert-danger">{errors["merchantCode"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="gstNo">
              <Form.Label>GST Number {this.props.gstRequired && "*"}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your GST number"
                name="gstNo"
                value={data["gstNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                onBlur={this.handleGSTChange}
                style={{ textTransform: "uppercase" }}
                disabled={this.state.isGstDisabled}
              />
              {errors["gstNo"] && (
                <div className="alert alert-danger">{errors["gstNo"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="gstFile">
              <Form.Group>
                <Form.Label>Upload GST Certificate Image</Form.Label>
                {
                  !files["gstFile"] ?
                    <>
                      <Form.File
                        label={files["gstFileLabel"]}
                        onChange={(event) => this.onFileChange(event, true, "GST", true)}
                        id="gstFile"
                        name="gstFile"
                        accept=".jpg,.png,.jpeg,.pdf"
                        custom
                      />
                      {/* {!fileErrors["gstFile"] ? <FileSizeText /> : ""} */}
                    </>
                    :
                    <div style={!files["gstFile"] ? { display: "none" } : {}} className="position-relative">
                      {this.renderThumbnail(files.gstFileSrc)}
                      {this.renderThumbnailAction("gstFile", files.gstFileSrc)}
                    </div>
                }

                {" "}
                {fileErrors["gstFile"] && (
                  <div className="alert alert-danger">
                    {fileErrors["gstFile"]}
                  </div>
                )}
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="bankStatementUpload">
              <Form.Label>Upload Bank Statement</Form.Label>
              <Form.File
                label=" "
                onChange={this.handleUploadBankStatement}
                isInvalid={!!this.state.bankStatementError}
                accept=".pdf"
                custom
              />
              <Form.Control.Feedback type="invalid">
                {this.state.bankStatementError}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="alternateEmail">
              <Form.Label>Alternate Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter alternate email"
                name="alternateEmail"
                value={data["alternateEmail"]}
                onChange={this.handleChange}
                autoComplete="off"
                autoCapitalize="words"
                onBlur={this.handleEmailChange}
              />
              {errors["alternateEmail"] && (
                <div className="alert alert-danger">
                  {errors["alternateEmail"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="alternateMobile">
              <Form.Label>Alternate Mobile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter alternate mobile"
                name="alternateMobile"
                value={data["alternateMobile"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                maxLength="10"
                onBlur={this.handleAlternateMobileChange}
              />
              {errors["alternateMobile"] && (
                <div className="alert alert-danger">
                  {errors["alternateMobile"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          {
            this.state.type == "admin" ?
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="accountNo">
                  {bankDetailsMandatory && currentUserRole && currentUserRole.toLowerCase() == "user" && (<Form.Label>Bank Account No.*</Form.Label>)}
                  {(!bankDetailsMandatory || (currentUserRole && currentUserRole.toLowerCase() !== "user")) && (<Form.Label>Bank Account No.</Form.Label>)}
                  <Form.Control
                    type="text"
                    placeholder="Enter your bank account number"
                    name="accountNo"
                    value={data["accountNo"]
                    }
                    onChange={this.handleChange}
                    autoComplete="off"
                  />
                  {bankText && (
                    <Form.Text className="text-muted">
                      {bankText}
                    </Form.Text>)}
                  {errors["accountNo"] && (
                    <div className="alert alert-danger">{errors["accountNo"]}</div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="ifsc">
                  {bankDetailsMandatory && currentUserRole && currentUserRole.toLowerCase() == "user" && (<Form.Label>IFSC Code*</Form.Label>)}
                  {(!bankDetailsMandatory || (currentUserRole && currentUserRole.toLowerCase() !== "user")) && (<Form.Label>IFSC Code</Form.Label>)}
                  <Autosuggest
                    suggestions={ifscOptions}
                    onSuggestionsFetchRequested={this.onIfscSuggestionsRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionsClearRequested={
                      this.onIfscSuggestionsClearRequested
                    }
                    inputProps={inputIfscProps}
                    style={{ textTransform: "uppercase" }}
                  />{" "}
                  {errors["ifsc"] && (
                    <div className="alert alert-danger">{errors["ifsc"]}</div>
                  )}
                  <a
                    onClick={this.showBankSearch}
                    style={{ float: "right", textDecoration: "underline", cursor: "pointer" }}
                  >
                    Search Ifsc Code
                  </a>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="accountType">
                  {bankDetailsMandatory && currentUserRole && currentUserRole.toLowerCase() == "user" && (<Form.Label>Account Type*</Form.Label>)}
                  {(!bankDetailsMandatory || (currentUserRole && currentUserRole.toLowerCase() !== "user")) && (<Form.Label>Account Type</Form.Label>)}
                  <Form.Control
                    as="select"
                    name="accountType"
                    value={data["accountType"]}
                    onChange={this.handleChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    <option key="savings" value="Savings">
                      Savings
                    </option>
                    <option key="current" value="Current">
                      Current
                    </option>
                  </Form.Control>
                  {errors["accountType"] && (
                    <div className="alert alert-danger">{errors["accountType"]}</div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="accN">
                  {bankDetailsMandatory && currentUserRole && currentUserRole.toLowerCase() == "user" && (<Form.Label>Account Holder Name*</Form.Label>)}
                  {(!bankDetailsMandatory || (currentUserRole && currentUserRole.toLowerCase() !== "user")) && (<Form.Label>Account Holder Name</Form.Label>)}
                  <Form.Control
                    type="text"
                    placeholder="Enter account holder name"
                    name="accN"
                    value={data["accN"]}
                    onChange={this.handleChange}
                    autoComplete="off"
                    style={{ textTransform: "uppercase" }}
                  />
                  {errors["accN"] && (
                    <div className="alert alert-danger">{errors["accN"]}</div>
                  )}
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="gender">
                  <Form.Label>Gender*</Form.Label>
                  <Form.Control
                    as="select"
                    name="gender"
                    value={data["gender"]}
                    onChange={this.handleChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    <option key="male" value="Male">
                      Male
                    </option>
                    <option key="female" value="Female">
                      Female
                    </option>
                    <option key="transgender" value="Transgender">
                      Transgender
                    </option>
                  </Form.Control>
                  {errors["gender"] && (
                    <div className="alert alert-danger">
                      {errors["gender"]}
                    </div>
                  )}
                </Form.Group>
              </Form.Row> : ""}
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="additionalDocuments">
              <Form.Group>
                <Form.Label>Upload Additional Documents</Form.Label>
                <Form.File
                  label={files["additionalFilesCount"]}
                  onChange={(e) => this.onMultipleFileChange(e, true, "ADDITIONAL", true)}
                  id="additionalDocuments"
                  name="additionalDocuments"
                  accept=".jpg,.png,.jpeg,.pdf"
                  multiple={true}
                  custom
                />

                {fileErrors["additionalDocuments"] ? (
                  <div className="alert alert-danger">
                    {fileErrors["additionalDocuments"]}
                  </div>
                ) : ""}

                {
                  files["additionalDocuments"]?.length ?
                    <div style={!files["additionalDocuments"]?.length ? { display: "none" } : {}} className="mt-2 horizontal-scrollable">
                      {
                        files["additionalDocuments"]?.map((item, index) => (
                          <>
                            {
                              !item.isDeleted ?
                                <div className="d-inline-block position-relative mt-2 pr-2">
                                  {this.renderThumbnail(item.fileSrc)}
                                  {this.renderThumbnailAction("additionalDocuments", item.fileSrc, index)}
                                </div> : ""
                            }
                          </>

                        ))
                      }
                    </div> : ""
                }
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="docConsent">
            <Form.Check
              type="checkbox"
              label={docConsentLabel}
              feedback="You must agree before submitting."
              checked={docConsent}
              onChange={() => this.handleDocConsent(docConsent)}
              color="red"
            />{" "}
            {errors["docConsent"] && (
              <div className="alert alert-danger">{errors["docConsent"]}</div>
            )}
          </Form.Group>{" "}
          {type !== "admin" && (
            <Button className="ff-button" type="button" style={{ width: 150, marginRight: 10 }} onClick={() => this.props.setActiveTab("activeTab", "loanTab")}>
              Back
            </Button>)}
          <Button
            className="ff-button"
            type="submit"
            disabled={isSubmitted}
            style={{ width: 150 }}
          >
            {type === "admin" && "Submit"}
            {type !== "admin" && "Next"}
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
        <Modal show={showBankSearch} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Search IFSC Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Form style={{ width: "100%" }}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="searchBankName">
                  <Form.Label>Bank Name*</Form.Label>
                  <Autosuggest
                    suggestions={bankOptions}
                    onSuggestionsFetchRequested={
                      this.onBankSuggestionsRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionsClearRequested={
                      this.onBankSuggestionsClearRequested
                    }
                    inputProps={inputBankProps}
                    autoCapitalize="words"
                  />
                  {optionalErrors["searchBankName"] && (
                    <div className="alert alert-danger">
                      {optionalErrors["searchBankName"]}
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                {" "}
                <Form.Group as={Col} md="12" controlId="searchBranchName">
                  <Form.Label>Branch Name*</Form.Label>
                  <Autosuggest
                    suggestions={branchOptions}
                    onSuggestionsFetchRequested={
                      this.onBranchSuggestionsRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionsClearRequested={
                      this.onBranchSuggestionsClearRequested
                    }
                    inputProps={inputBranchProps}
                    autoCapitalize="words"
                  />
                  {optionalErrors["searchBranchName"] && (
                    <div className="alert alert-danger">
                      {optionalErrors["searchBranchName"]}
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={this.getIfscList}>
              Search
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showIfscSearch} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select Bank</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {bankList.length > 0 && (
              <ListGroup>
                {bankList.map((item) => (
                  <ListGroup.Item
                    key={item.ifsc}
                    action
                    onClick={() => this.onBankSelect(item.ifsc)}
                  >
                    IFSC Code: {item.ifsc} <br />
                    Bank: {item.bank} <br />
                    Branch: {item.branch}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
            {bankList.length === 0 && <p>No data found for your search</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.handleClose}
              disabled={!isBankSelected}
            >
              Select
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={showDocConsent}
          onHide={this.handleDocConsentClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Document Consent</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <p>I hereby submit voluntarily at my own discretion, the soft copy of the document, to Fundfina for the purpose of establishing my identity / address proof.  I authorise Fundfina and the lending partner to fetch my data from NSDL, GST, Bureau Report.</p>
            <p>Fundfina has informed me that my document submitted to the company herewith shall not be used for any purpose other than mentioned above, or as per requirements of law. Fundfina has informed me that this consent and my uploaded documents will be stored along with my loan details within the company and the lending partner. I hereby declare that all the information voluntarily furnished by me is true, correct and complete. I will not hold Fundfina and the lending partner or any of its officials responsible in case of any incorrect information provided by me.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDocConsentClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showSuccessModal}
          onHide={this.closeSuccessModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>{infoHeader}</b>
          </Modal.Header>
          <Modal.Body>
            {infoMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeSuccessModal} className="ff-button">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showFinalPopUp}
          onHide={this.handleCloseFinalPopUp}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>   <i className="fa fa-exclamation-triangle fa-lg" aria-hidden="true" style={{ color: "red" }}></i>{" "}Please Note</b>
          </Modal.Header>
          <Modal.Body>
            Your address entered above should be same as the address in the supporting documents. Your application will get rejected if they are not same.
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.insertUpdateKyc()} className="ff-button">
              Same Address
            </Button>
            <Button onClick={this.handleCloseFinalPopUp} className="ff-button">
              Change Address
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default KycForm;
