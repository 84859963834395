import React, { Component } from "react";
import { Tabs, Tab, Modal, Button } from "react-bootstrap";
import LoanForm from "./loanForm";
import ReviewForm from "./reviewForm";
import QuestionairreForm from "./questionnairreForm";
import ApplyLoanModel from "../../models/borrow/applyLoanModel";
import KycForm from "./kycForm";
import ShopDetails from "./shopDetails";
import SpinnerComponent from "../../components/common/spinner";
import { isMobile } from 'react-device-detect';
import ConfirmMobileNumber from "./confirm-mobile-number";

class ApplyLoan extends ApplyLoanModel {
  render() {
    let {
      activeTab,
      loanTab,
      kycTab,
      questionairreTab,
      reviewTab,
      loanData,
      userData,
      shopTab,
      questionairreData,
      reviewData,
      showSpinner,
      loanEstimateData,
      displayLoanTabs,
      isStp,
      discountText,
      bankText,
      offerAmount,
      multipleOf,
      isCaptureNewDocument,
      showMobileConfirmation,
      showKycExpiredModal,
      gstRequired
    } = this.state;

    // if (showMobileConfirmation) return <ConfirmMobileNumber verified={this.state.tab1Response?.data?.isMobileNumberVerified} handleMobileConfirmationResponse={this.handleMobileConfirmationResponse} />
    return (
      <React.Fragment>
        {" "}
        <SpinnerComponent showSpinner={showSpinner} />
        <Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={(k) => this.setKey(k, activeTab)} style={{ display: isMobile && !displayLoanTabs ? "none" : "" }}>
          <Tab eventKey="loanTab" title="Loan Offer" tabClassName={activeTab === "loanTab" ? "navActive" : "navInActive"}>
            <LoanForm
              handler={this.handler}
              updateReviewData={this.updateReviewData}
              handleResponse={this.handleTab1Response}
              disabled={loanTab}
              loanData={loanData}
              userData={userData}
              estimate={loanEstimateData}
              isStp={isStp}
              discountText={discountText}
              offerAmount={offerAmount}
              multipleOf={multipleOf}
              setActiveTab={this.setActiveTab}
              gstRequired={gstRequired}
            />
          </Tab>
          <Tab eventKey="kycTab" title="Personal Details" tabClassName={activeTab === "kycTab" ? "navActive" : "navInActive"}>
            <KycForm
              handler={this.handler}
              setActiveTab={this.setActiveTab}
              disabled={kycTab}
              userData={userData}
              reviewData={reviewData}
              type="user"
              bankText={bankText}
              isCaptureNewDocument={isCaptureNewDocument}
              gstRequired={gstRequired}
            />
          </Tab>
        <Tab eventKey="shopTab" title="Shop Details" tabClassName={activeTab === "shopTab" ? "navActive" : "navInActive"}>
            <ShopDetails
              handler={this.handler}
              setActiveTab={this.setActiveTab}
              disabled={shopTab}
              userData={userData}
              reviewData={reviewData}
            />
          </Tab>
         <Tab
            eventKey="questionairreTab"
            title="Additional Details" tabClassName={activeTab === "questionairreTab" ? "navActive" : "navInActive"}
          >
            <QuestionairreForm
              handler={this.handler}
              setActiveTab={this.setActiveTab}
              disabled={questionairreTab}
              userData={userData}
              reviewData={reviewData}
            />
          </Tab>
         <Tab eventKey="reviewTab" title="Review" tabClassName={activeTab === "reviewTab" ? "navActive" : "navInActive"}>
            <ReviewForm
              handler={this.handler}
              setActiveTab={this.setActiveTab}
              disabled={questionairreTab}
              reviewData={reviewData}
              userData={userData}
            />
          </Tab>
        </Tabs>

        <Modal
          size="md"
          show={showKycExpiredModal}
          onHide={this.handleKYCModalClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title> <span className="text-danger">KYC Expired</span></Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <p>Your KYC has expired. Complete your KYC process once again.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleKYCModalClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ApplyLoan;
