import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { Form, Col, Button, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import TrueScoreModel from '../../models/trueScore/trueScoreModel';

class TrueScore extends TrueScoreModel {
    render() {
        const { totalCount } = this.state;
        return (
            <React.Fragment>
                <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
                    TrueScore
                </h2>

                <Form style={{ paddingTop: 30 }}>
                    <Form.Row>
                        <Form.Group as={Col} lg="9" >
                            <label class="txtlabel fundfinaColorBold">Unleash the power of your Cashflows. Check your FREE Fundfina TrueScore today and start planning to grow your business now!</label>
                            <ul>
                                <li className="fundfinaColor">	Provide your business sales/turnover in Rs for last 12 months</li>
                                <li className="fundfinaColor">	Provide your sales count (number) for last 12 months</li>
                                <li className="fundfinaColor">	Provide means to reach you on email and mobile</li>
                            </ul>
                            <div className="col-lg-12" style={{ textAlign: "center", paddingTop: 30, paddingBottom: 5 }}>
                                <Link to="/trueScoreDetails" className="btn btn-primary trueScore1-button">Get your Free Fundfina TrueScore Now</Link>
                            </div>
                            {/* <div class="col-lg-12" style={{ textAlign: "center" }}> */}
                            <br /><br />
                            <label class="fundfinaColor">TrueScore™ measures the transaction uncertainty expected in sales turnover of small businesses, thereby providing cash-flow based credit worthiness of the business.<a href="https://us18.campaign-archive.com/?u=e09ba49760486caa5c4edc5de&id=b1d624d62d"> <u>Know more..</u></a></label><br /><br />
                            <h4 class="fundfinaColor"><b>Tips to improve TrueScore™</b></h4>
                            <p class="fundfinaColor"><b>How does Fundfina calculate your TrueScore™?</b> <br />
                             Your TrueScore™ is calculated on a scale of 0 to 1000. It is updated every month on basis of transaction data provided by partnering enterprises. TrueScore is the starting point of cashflow based lending program.</p>

                            <p class="fundfinaColor">The score is Fundfina’s interpretation of creditworthiness of your business. Your TrueScore is calculated using –</p>
                            <ul class="fundfinaColor">
                                <li>Transaction values</li>
                                <li>Transaction counts</li>
                                <li>Business vintage</li>
                                <li>Loan history and current outstanding with other lenders</li>
                                <li>Payment history, including any missed payments</li>
                            </ul>

                            <p class="fundfinaColor"><b>Tips to Improve TrueScore – DOs</b></p>
                            <ol class="fundfinaColor">
                                <li>No transaction is too small – Encourage your customers to opt for digital payment
                                 <ol><li>Make transactions using online medium. The more digital transactions you make the more valuable your business becomes. Avoid accepting cash transactions.</li></ol>
                                </li>
                                <li>No transaction is too small – Increase your transaction count</li>
                                <li>Getting repeat customers shows stability of your business</li>
                                <li>Your current address is same as your Aadhaar/ Driving License/ Voter Id</li>
                                <li>Avoid maintaining multiple business banking accounts <ol><li>Maintaining multiple accounts is a drain on your financial resources. Minimum balance accounts tie up liquid funds which is a bad business choice to make.</li>
                                    <li>Close any unused or seldom used accounts to give a better picture of business. Diverting transactions away from primary banking account creates gaps in business evaluation</li></ol></li>
                            </ol>

                            <p class="fundfinaColor"><b>Tips to Improve TrueScore – DONTs</b></p>
                            <ol class="fundfinaColor">
                                <li>Do not miss your payment dates – setup a reminder in your smartphone, if you must, but avoid missing payments. It has adverse effect on your score.</li>
                                <li>Do not apply for loans frequently – too many loan applications in a short span of time makes your business look desperate. It is a sign on weakness in business.</li>
                                <li>Do not overestimate your creditworthiness – nowadays lending companies often ask borrowers for their expected loan amount. Our research has shown that people overestimate their loan amount by nearly 10x. It is a sign of financial indiscipline.</li>
                                <li>Do not overleverage your business – restrict your outstanding business loans to two maximum. Too many loans to run a small business is a sign of financial imprudence.</li>
                            </ol>

                            {/* <Button className="trueScore2-button" style={{ marginTop: 30, marginLeft: 10,marginBottom:30 }} onClick={()=>window.open("https://fundfina.substack.com/p/truescore","_blank")} >TrueScore Know More</Button> */}
                            {/* </div> */}
                        </Form.Group>
                        <Form.Group as={Col} lg="3" style={{ textAlign: "center" }} >
                            <img
                                src={require("../../images/trueScore_Home.png")}
                                alt="header image"
                            />
                            <label className="txtlabel fundfinaColor"><b style={{ fontSize: 22 }}>{totalCount} </b> have generated their TrueScores</label>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </React.Fragment >
        );
    }
}

export default TrueScore;