import React from "react";
import * as analytics from "../../utils/analytics";

function FairPractices(props) {

  analytics.track();

  return (
    <React.Fragment>
      <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
        Fair Practices Code
      </h2>
      <h5>Guidelines on Fair Practices Code for NBFCs </h5>
      <p>
        <b>A. (i) Applications for loans and their processing </b>
      </p>

      <ol>
        <li>
          All communications to users shall, by default, be in English Language.
          In the event where a user requests communication to be provided in
          vernacular language or a language as understood by the user, Fundfina
          will act to fulfil such request on best effort basis.
        </li>
        <li>
          Fundfina provides necessary information, that could be crucial to the
          interest of its user, on its platform. It is our constant endeavour to
          promote transparency so that our Users can make a meaningful
          comparison with the terms and conditions offered by other NBFCs and
          user can make an informed decision. The lending/borrowing forms
          indicate the documents required to be submitted to participate on
          platform.
        </li>
        <li>
          Fundfina provides acknowledgement for receipt of all loan applications
          and lending commitments vide email communication. The time frame
          within which loan applications will be disposed of is also indicated
          in the acknowledgement.
        </li>
      </ol>

      <p>
        <b>(ii) Loan appraisal and terms/conditions </b>
      </p>
      <p>
        Fundfina conveys in writing to the borrower in English, or in the
        vernacular language as understood by the borrower on request, by means
        of sanction email, in the user account on platform and/or other
        electronic media, the amount of loan sanctioned along with the terms and
        conditions including annualised rate of interest thereof and records the
        acceptance of these terms and conditions by the borrower on its record.
        As complaints received against NBFCs generally pertain to charging of
        high interest / penal interest, Fundfina mentions the penal interest
        charged for late repayment in a separate clause in bold in the loan
        agreement.
      </p>
      <p>
        {" "}
        It is understood that in a few cases, borrowers at the time of sanction
        of loans are not fully aware of the terms and conditions of the loans
        including rate of interest, either because the NBFC does not provide
        details of the same or the borrower has no time to look into detailed
        agreement. Hence Fundfina sends a copy of Draft agreement to its users
        along with acknowledgment emails describe earlier in (i)(c) to allow its
        user ample time and opportunity to assess and understand the loan
        agreement.
      </p>
      <p>
        We understand that Not furnishing a copy of the loan agreement or
        enclosures quoted in the loan agreement is an unfair practice and this
        could lead to disputes between Fundfina and the users with regard to the
        terms and conditions on which the loan is granted.
      </p>
      <p>
        Fundfina, therefore, furnishes a copy of the loan agreement in English
        and on request of users in the vernacular language as understood by the
        user along with a copy each of all enclosures quoted in the loan
        agreement to all the users at the time of disbursement of loans.
      </p>
      <p>
        <b>
          (iii) Disbursement of loans including changes in terms and conditions
        </b>
      </p>
      <ul>
        <li>
          Fundfina gives notice to the users in English and on request in the
          vernacular language as understood by the user of any change in the
          terms and conditions including disbursement schedule, interest rates,
          service charges, prepayment charges etc. Fundfina also ensures that
          changes in interest rates and charges are effected only prospectively.{" "}
        </li>
        <li>
          Decision to recall / accelerate payment or performance under the
          agreement is done in consonance with the loan agreement.{" "}
        </li>
        <li>
          Fundfina releases all securities on repayment of all dues or on
          realisation of the outstanding amount of loan subject to any
          legitimate right or lien for any other claim Fundfina may have against
          borrower in accordance with the Loan agreement.
        </li>
      </ul>
      <p>
        <b>(iv) General</b>
      </p>

      <ol>
        <li>
          Fundfina refrains from interference in the affairs of the borrower
          except for the purposes provided in the terms and conditions of the
          loan agreement (unless new information, not earlier disclosed by the
          borrower, has come to the notice of the lender and or platform).{" "}
        </li>
        <li>
          In case of receipt of request from the borrower for transfer of
          borrowal account, the consent or otherwise i.e. objection of the NBFC,
          if any, shall be conveyed within 21 days from the date of receipt of
          request. Such transfer shall be as per transparent contractual terms
          in consonance with law.{" "}
        </li>
        <li>
          In the matter of recovery of loans, Fundfina does not resort to undue
          harassment viz. persistently bothering the borrowers at odd hours, use
          of muscle power for recovery of loans etc. As complaints from
          customers also include rude behaviour from the staff of the companies.
          Fundfina ensures that the staff are adequately trained to deal with
          the customers in an appropriate manner.
        </li>
      </ol>

      <p>
        (v) The Board of Directors of Fundfina also lay down the appropriate
        grievance redressal mechanism within the organization to resolve
        disputes arising in this regard. Such a mechanism ensures that all
        disputes arising out of the decisions of platform’s functionaries and
        participants are heard and disposed of at least at the next higher
        level. The Board of Directors also provides for periodical review of the
        compliance of the Fair Practices Code and the functioning of the
        grievances redressal mechanism at various levels of management. A
        consolidated report of such reviews is submitted to The Board at regular
        intervals.{" "}
      </p>

      <p>(vi) Complaints about excessive interest charged by NBFCs </p>
      <p>
        <i>
          The Reserve Bank has been receiving several complaints regarding
          levying of excessive interest and charges on certain loans and
          advances by NBFCs. Though interest rates are not regulated by the
          Bank, rates of interest beyond a certain level may be seen to be
          excessive and can neither be sustainable nor be conforming to normal
          financial practice.
        </i>
      </p>
      <p>
        Board of Fundfina has, therefore, laid out appropriate internal
        principles and procedures in determining interest rates and processing
        and other charges. The interest charged is capped at 30%.
      </p>
      <p>
        In this regard the guidelines indicated in the Fair Practices Code about
        transparency in respect of terms and conditions of the loans have been
        kept in view.
      </p>

      <p>(vii) Regulation of excessive interest charged by NBFCs </p>
      <ol>
        <li>
          The Board of Fundfina has adopted an interest rate model taking into
          account relevant factors such as, credit worthiness, interest
          coverage, sources of income, quality of income, margin and risk
          premium, etc and determine the rate of interest to be charged for
          loans and advances.{" "}
        </li>
        <li>
          The rates of interest and the approach for gradation of risks is made
          available on the web-site. The information published in the website or
          otherwise published should be updated whenever there is a change in
          the rates of interest.
        </li>
        <li>
          The rate of interest displayed are annualised rates so that the
          borrower is aware of the exact rates that would be charged to the
          account.{" "}
        </li>
      </ol>
    </React.Fragment>
  );
}

export default FairPractices;
