import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as loanService from "../../services/loan/loanService";
import { summaryPercentage } from "../../config.json"
class UserCentricDataModel extends InputDataForm {
    state = {
        lastDisbursalDate: "",
        lastLoanAmount: 0,
        totalActiveLoans: 0,
        totalActiveLoansOutstandingAmount: 0,
        currentBorrowingPartner: {
            name: "",
            activeLoans: 0,
            totalOutstanding: 0
        },
        currentLendingPartner: {
            name: "",
            activeLoans: 0,
            totalOutstanding: 0
        },
        loanInstallmentDetail: {},
        selectedTab: "TRANSACTION_VALUE",
        labels: [],
        transactionVolumeData: [],
        transactionCountData: [],
        activeDaysData: []


    };

    tabs = [
        {
            title: "Transaction Value",
            value: "TRANSACTION_VALUE",
        },
        {
            title: "Transaction Count",
            value: "TRANSACTION_COUNT"
        },
        {
            title: "Active Days",
            value: "ACTIVE_DAYS"
        }
    ]

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();

        if (currentUser.role != "Admin" && currentUser.role != "Lending Partner")
            return (window.location = "/");

        this.getUserCentricData();
        this.getTransactions()

    }

    onClickTab(value) {
        this.setState({ selectedTab: value })
    }

    getChartData() {
        if (this.state.selectedTab === "TRANSACTION_VALUE") {
            return this.state.transactionVolumeData || []
        }
        if (this.state.selectedTab === "TRANSACTION_COUNT") {
            return this.state.transactionCountData || []
        }
        if (this.state.selectedTab === "ACTIVE_DAYS") {
            return this.state.activeDaysData || []
        }
    }


    getChartName() {
        if (this.state.selectedTab === "TRANSACTION_VALUE") {
            return "Transaction Value"
        }
        if (this.state.selectedTab === "TRANSACTION_COUNT") {
            return "Transaction Count"
        }
        if (this.state.selectedTab === "ACTIVE_DAYS") {
            return "Active Days"
        }
    }

    async getUserCentricData() {
        this.setState({ showSpinner1: true });
        let response = await loanService.getUserCentricData(this.props.loanId)
        let data = response?.data?.data || {}
        this.setState({ ...data, showSpinner1: false })
    }

    getTransactions = async () => {

        let payload = {
            merchantCode: this.props?.merchantCode,
            partnerId: this.props?.partnerId,
        }
        let currentDate = new Date()
        let option = {
            currentMonth: currentDate.getMonth() + 1,
            currentYear: currentDate.getFullYear(),
        }

        this.setState({ showSpinner: true });
        try {
            const { data: transactions } = await loanService.getPartnerUserTransactions(payload, option);
            this.setState({ showSpinner: false });
            if (transactions.success) {
                let monthWiseData = transactions.data || []
                if (monthWiseData.length > 6) {
                    monthWiseData.length = 6
                }

                monthWiseData.reverse()


                let labels = [], transactionVolumeData = [], transactionCountData = [], activeDaysData = []

                monthWiseData.forEach(element => {
                    labels.push(`${element.month.substr(0, 3)} - ${element.year}`)
                    transactionVolumeData.push(+element.tranVolume)
                    transactionCountData.push(+element.tranCount)
                    activeDaysData.push(+element.activeDays)
                })


                if (transactionVolumeData.length > 3) {
                    let len = transactionVolumeData.length
                    let baseData = transactionVolumeData[len - 3]
                    if (baseData) {
                        let compareData = transactionVolumeData[len - 2]
                        let percentage = (compareData / baseData) * 100
                        if (percentage < summaryPercentage) {
                            this.tabs[0].isBadge = true
                        }
                    }
                }

                if (transactionCountData.length > 3) {
                    let len = transactionCountData.length
                    let baseData = transactionCountData[len - 3]
                    if (baseData) {
                        let compareData = transactionCountData[len - 2]
                        let percentage = (compareData / baseData) * 100
                        if (percentage < summaryPercentage) {
                            this.tabs[1].isBadge = true
                        }
                    }
                }

                if (activeDaysData.length > 3) {
                    let len = activeDaysData.length
                    let baseData = activeDaysData[len - 3]
                    if (baseData) {
                        let compareData = activeDaysData[len - 2]
                        let percentage = (compareData / baseData) * 100
                        if (percentage < summaryPercentage) {
                            this.tabs[2].isBadge = true
                        }
                    }
                }

                this.setState({ labels, transactionVolumeData, transactionCountData, activeDaysData })

            }


        } catch (ex) {
            this.setState({ transaction2List: [] });
            this.setState({ showSpinner: false });
        }
    }
}

export default UserCentricDataModel;
