import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import moment from "moment";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import * as reportsService from "../../services/reportService/reportService";

class ReportsJobListModel extends InputDataForm {
  state = {
    reportJobsList: [],
    data: {
      type: "",
      startDate: null,
      endDate: null,
      partner: ""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    evaluatorRole: "",
    reportTypes: [
    ],
    reportTypeMapper: {
      SETTLEMENT_MIS_FILE: "Settlement MIS File"
    }
  };
  schema = {
    page: Joi.any(),
    type: Joi.any(),
    startDate: Joi.any(),
    endDate: Joi.any(),
    partner: Joi.any()
  };

  async componentDidMount() {
    await this.getReportLogListApi()
    await this.getBorroingPartners()
    await this.getReportTypes()
  };
  async getReportLogListApi() {

    this.state.data.page = this.state.currentPage

    try {

      this.setState({ showSpinner: true });

      let reqObj = {
        page : this.state.data.page
      }

      if (this.state.data.startDate)
        reqObj.startDate = moment(this.state.data.startDate).format("MM/DD/YYYY");

      if (this.state.data.endDate)
        reqObj.endDate = moment(this.state.data.endDate).format("MM/DD/YYYY");

      if (this.state.data.partner)
        reqObj.partnerId = this.state.data.partner;

      if (this.state.data.type)
        reqObj.type = this.state.data.type

      const { data: reportJobsList, totalPages } = await reportsService.getReportLogList(reqObj);
      this.setState({ reportJobsList: reportJobsList, pageSize: totalPages ? totalPages : this.state.pageSize, showSpinner: false });
    }
    catch (ex) {
      this.setState({ statements: [], showSpinner: false });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getReportLogListApi();
  };

  handleValueFromDateChange = (value) => {
    const { data } = this.state;

    data.startDate = value;
    this.setState({ data, showDateFilterNote: false });
  }

  handleValueToDateChange = (value) => {
    const { data } = this.state;

    data.endDate = value;
    this.setState({ data, showDateFilterNote: false });
  }

  resetPage = () => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getReportLogListApi()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getReportLogListApi()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getReportLogListApi()
    })
  }

  async getReportTypes() {
    try {
      const { data: reportTypes } = await reportsService.getReportTypes();

      let types = reportTypes && reportTypes.map(item => {
        return {
          title: item.eventDisplayName,
          value: item.event
        }
      })
      this.setState({ reportTypes: types });
    }
    catch (ex) {
      this.setState({ reportTypes: [] });
    }
  };

  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  downloadFile = async (item, index) => {
    let reportJobsList = this.state.reportJobsList;
    try {
      reportJobsList[index].showLoading = true
      this.setState({ reportJobsList })
      const downloadURLS = await reportsService.getReportDownloadUrls({ files: item.files });
      reportJobsList[index].showLoading = false

      for (let i = 0; i < downloadURLS.data.length; i++) {
        let fileNameSplit = downloadURLS.data[i].split('/')
        let fileName = fileNameSplit[fileNameSplit.length - 1];
        this.download(downloadURLS.data[i], fileName)
      }
      this.setState({ reportJobsList, downloadedFiles: downloadURLS.data })
    } catch (error) {
      reportJobsList[index].showLoading = false
      this.setState({ reportJobsList })
    }
  }

  download = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  }

  downloadSingleFile = async (file, index) => {
    try {
      const downloadURLS = await reportsService.getReportDownloadUrls({ files: [file] });
      for (let i = 0; i < downloadURLS.data.length; i++) {
        let fileNameSplit = downloadURLS.data[i].split('/')
        let fileName = fileNameSplit[fileNameSplit.length - 1];
        this.download(downloadURLS.data[i], fileName)
      }
    } catch (error) {
      toast.error("Download error");
    }
  }


  onClickMore = (item, index) => {
    let reportJobsList = this.state.reportJobsList;
    reportJobsList[index].expand = !reportJobsList[index].expand;
    this.setState({ reportJobsList })
  }
}



export default ReportsJobListModel;
