import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { getIpAddress } from "../common/commonService";
import moment from "moment";


const apiEndpoint = apiUrl + "/ops/";

export async function changeMobileNo(mobileNo) {
  const { data } = await http.post(apiEndpoint + "verification/changeMobile", {
    mobileNo,
  });
  return data;
}

export default {
    changeMobileNo
};
  