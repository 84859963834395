import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PartnerConfigurationModel from "../../models/partnerConfigurations/partnerConfigurations";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";
import { Tabs, Tab } from "react-bootstrap";


class PartnerConfigurations extends PartnerConfigurationModel {

  render() {
    const {
      pageSize,
      currentPage,
      showSpinner,
      partners,
      partnerTypes,
      successMsg,
      errors,
      data
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Partner Configurations
        </h2>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>

            <Form.Group as={Col} md="4" controlId="partnerType">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="partnerType"
                  value={data["partnerType"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="all" value="all">
                    ALL
                  </option>
                  {partnerTypes && partnerTypes.map((partner) => (
                    <option key={partner.value} value={partner.value}>
                      {partner.title}
                    </option>
                  ))}
                </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={data["name"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group as={Col} md="4">
              <br />
              <Button
                className="ff-button mt-2 mr-2"
                type="submit"
              >
                Apply Filters
              </Button>
        </Form.Group>
          </Form.Row>
        </Form>

        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        <div style={{ width: '100%' }}>
          <StickyTable stickyHeaderCount={0}>
            <Row>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" , borderLeft :"1px solid" }}><b>Partner Type</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Name</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Mobile Number</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Partner Admin User</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
            </Row>
            {partners &&
              partners.map((partner, i) => (
                <Row key={partner._id}>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{partner.partnerType}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                  {partner.partnerType == 'Lending Partner' ? <Link
                      to={{
                        pathname: `/partnerConfigurationsDetails/${partner._id}`,
                        customProps: {}
                      }}
                    >{partner.partnerName}
                     </Link> : <span>{partner.partnerName}</span>}
                     </Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "" ,borderColor: "lightgrey",borderRight : "1px solid" }}>{partner.mobileNumber}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey" , borderRight : "1px solid" }}>{partner.userDetails && partner.userDetails.userDetails && partner.userDetails.userDetails.userName}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey" ,borderRight : "1px solid"}}>{partner.isActive ? "Active" : "In Active"}</Cell>
                </Row>
              ))}
            {!partners || !partners.length && (
              <Row>
                <Cell style={{borderRight : 0 , textAlign :'center'}}></Cell>
                <Cell style={{borderRight : 0 , textAlign :'center'}}></Cell>
                <Cell style={{borderRight : 0 , textAlign :'center'}} Col={5}>No record found</Cell>
                <Cell style={{borderRight : 0 , textAlign :'center'}}></Cell>
                <Cell style={{borderRight : 0 , textAlign :'center'}}></Cell>
              </Row>
            )}

          </StickyTable>
        </div >
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
        </div>
      </React.Fragment >
    );
  }
}

export default PartnerConfigurations;
