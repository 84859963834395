import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import * as notificationServices from "../../services/notification/notification";
import * as loanService from '../../services/loan/loanService'
class CollectionNotificationModel extends InputDataForm {
    state = {
        data: {
            criteria: "",
            value: "",
            operator: "",
            selectedPartners: [],
            selectedLendingPartners: [],
            selectedLoanTypes: [],
            selectedProductTypes: [],
            selectedTemplate: "PRE_LEGAL_NOTICE",
            fileName: "",
        },
        conditionsList: [],
        showSpinner: false,
        errors: {},
        errorMessage: "",
        successMessage: "",
        partnerList: [],
        lendingPartnerList: [],
        showPopUp: false,
        popupMsg: "",
    };
    schema = {
        criteria: Joi.any(),
        value: Joi.any(),
        operator: Joi.any(),
        selectedPartners: Joi.any(),
        selectedLendingPartners: Joi.any(),
        selectedLoanTypes: Joi.any(),
        selectedProductTypes: Joi.any(),
        selectedTemplate: Joi.any(),
        conditionsList: Joi.any()
    }

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();

        const { sortBy } = this.state;

        if (currentUser.role != "Admin")
            return (window.location = "/");

        this.getBorrowingPartnerList()
        this.getLendingParterList()
    };

    getBorrowingPartnerList = async () => {
        const { data: partnerList } = await commonService.getBorrowingPartnerList();
        this.setState({ partnerList: partnerList });


    }
    getLendingParterList = async () => {
        const { data: partnerList } = await loanService.getLendingPartnerList();
        this.setState({ lendingPartnerList: partnerList?.data || [] });

    }

    handleAddConditions = async (e) => {
        this.setState({ showSpinner: true });
        let errorMessage = { ...this.state.errorMessage };
        try {
            errorMessage = "";
            const conditionsList = [...this.state.conditionsList];
            let data = { ...this.state.data };
            if (data.criteria === "" || data.value === "" || data.operator === "") {
                this.setState({ showSpinner: false, errorMessage: "All fields are mandatory !!!" });
            } else {
                let isValid = true;
                if (conditionsList && conditionsList.length > 0) {
                    if ((data.criteria === "overDueBy" || data.criteria === "pendingAmount")) {
                        if (data.operator === ">=") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === ">=" || x.operator === ">"));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === "<=") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === "<=" || x.operator === "<"));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === ">") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === ">" || x.operator === ">="));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === "<") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === "<" || x.operator === "<="));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        } else if (data.operator === "=") {
                            const condiFilter = conditionsList.filter((x) => x.criteria === data.criteria && (x.operator === "=" || x.operator === "<" || x.operator === "<=" || x.operator === ">" || x.operator === ">="));
                            if (condiFilter && condiFilter.length > 0) isValid = false;
                        }
                    } else isValid = false;
                }


                if (isValid) {
                    const id = conditionsList + 1;
                    conditionsList.push({ id: id, criteria: data.criteria, value: data.value, operator: data.operator });
                    data.criteria = "";
                    data.value = "";
                    data.operator = "";
                    this.setState({ data: data, showSpinner: false, conditionsList: conditionsList, errorMessage: "" });
                } else {
                    errorMessage = `Operator ${data.operator} is not allowed for Criteria ${data.criteria} as it is already in list or restricted`;
                    this.setState({ showSpinner: false, errorMessage: errorMessage });
                }
            }
        } catch (ex) {
            this.setState({ showSpinner: false, errorMessage: ex.message });
            console.log(ex);
        }
    };

    handleRemoveConditions = async (id) => {
        this.setState({ showSpinner: true });
        let errorMessage = { ...this.state.errorMessage };
        try {
            errorMessage = "";
            const conditionsList = [...this.state.conditionsList];
            const index = conditionsList.findIndex(e => e.id.toString() === id.toString());
            if (index >= 0) {
                conditionsList.splice(index, 1);
            }
            this.setState({ showSpinner: false, conditionsList: conditionsList, errorMessage: errorMessage });
        } catch (ex) {
            errorMessage = ex.message;
            this.setState({ showSpinner: false, errorMessage: errorMessage });
            console.log(ex);
        }
    };

    async getNotificationRequestModel(isSend) {
        let output = {
            data: null,
            message: "Some Error Occurred",
            success: false,
        };



        try {

            if (this.state.isExcelView) {
                if (!this.state.data?.selectedFile) {
                    output.message = "Please select excel file";
                    return output
                } else {
                    output = {
                        data: {},
                        message: "genearted successfully",
                        success: true,
                    }
                    return output
                }
            }

            const conditionsList = [...this.state.conditionsList];
            let data = { ...this.state.data };
            if ((data.selectedPartners && data.selectedPartners.length > 0) || (data.selectedLendingPartners && data.selectedLendingPartners.length > 0)) {
                const model = {
                    partners: data.selectedPartners,
                    lendingPartners: data.selectedLendingPartners,
                    productTypes: data.selectedProductTypes,
                    selectedTemplate: data.selectedTemplate,
                    isSendNotification: isSend,
                    eqOverDueBy: null,
                    gtOverDueBy: null,
                    ltOverDueBy: null,
                    gteOverDueBy: null,
                    lteOverDueBy: null,
                    eqPendingAmount: null,
                    gtPendingAmount: null,
                    ltPendingAmount: null,
                    gtePendingAmount: null,
                    ltePendingAmount: null,
                }
                for (let i = 0; i < conditionsList.length; i++) {
                    if (conditionsList[i].criteria === "overDueBy") {
                        switch (conditionsList[i].operator) {
                            case "=":
                                model.eqOverDueBy = parseFloat(conditionsList[i].value);
                                break;
                            case ">":
                                model.gtOverDueBy = parseFloat(conditionsList[i].value);
                                break;
                            case "<":
                                model.ltOverDueBy = parseFloat(conditionsList[i].value);
                                break;
                            case ">=":
                                model.gteOverDueBy = parseFloat(conditionsList[i].value);
                                break;
                            case "<=":
                                model.lteOverDueBy = parseFloat(conditionsList[i].value);
                                break;
                        }
                    } else if (conditionsList[i].criteria === "pendingAmount") {
                        switch (conditionsList[i].operator) {
                            case "=":
                                model.eqPendingAmount = parseFloat(conditionsList[i].value);
                                break;
                            case ">":
                                model.gtPendingAmount = parseFloat(conditionsList[i].value);
                                break;
                            case "<":
                                model.ltPendingAmount = parseFloat(conditionsList[i].value);
                                break;
                            case ">=":
                                model.gtePendingAmount = parseFloat(conditionsList[i].value);
                                break;
                            case "<=":
                                model.ltePendingAmount = parseFloat(conditionsList[i].value);
                                break;
                        }
                    }
                }
                output = {
                    data: model,
                    message: "genearted successfully",
                    success: true,
                };
            } else {
                output.message = "Please select at least 1 Partner";
            }
        } catch (ex) {
            output.message = ex.message;
        }
        return output;
    };

    handleOfferQualified = async () => {
        this.setState({ showSpinner: true });
        let errorMessage = "";
        let successMessage = "";
        try {
            const getModel = await this.getNotificationRequestModel(false);
            if (getModel.success) {
                let response = {}
                if (this.state.isExcelView) {
                    let fileData = new FormData();
                    fileData.append('file', this.state?.data.selectedFile);
                    let queryParams = {
                        isFile: true,
                    }
                    response = await notificationServices.sendCollectionNotifications(fileData, queryParams);
                } else {
                    response = await notificationServices.sendCollectionNotifications(getModel.data);
                }

                if (response.success) {
                    const data = response.data;
                    let msg = "";
                    if (data.length > 0) {
                        this.setState({ showSpinner: false, errorMessage, showPopUp: true, popupMsg: data, successMessage });
                    } else {
                        this.setState({ showSpinner: false, errorMessage: "No record Found", successMessage });
                    }
                } else {
                    this.setState({ showSpinner: false, errorMessage: response.message, successMessage });
                }

            } else {
                this.setState({ showSpinner: false, errorMessage: getModel.message, successMessage });
            }
        } catch (ex) {
            this.setState({ showSpinner: false, errorMessage: ex.message, successMessage });
            console.log(ex);
        }
    };

    handleSendNotifications = async () => {
        this.setState({ showSpinner: true });
        let errorMessage = "";
        let successMessage = "";
        try {
            const getModel = await this.getNotificationRequestModel(true);
            if (getModel.success) {

                let response = {}
                if (this.state.isExcelView) {
                    let fileData = new FormData();
                    fileData.append('file', this.state?.data.selectedFile);
                    let queryParams = {
                        isFile: true,
                        isSendNotification: true
                    }
                    response = await notificationServices.sendCollectionNotifications(fileData, queryParams);
                } else {
                    response = await notificationServices.sendCollectionNotifications(getModel.data);
                }

                if (response.success) {
                    successMessage = response.message;
                    this.setState({ showSpinner: false, errorMessage, successMessage });
                } else {
                    errorMessage = response.message;
                    this.setState({ showSpinner: false, errorMessage, successMessage });
                }

            } else {
                this.setState({ showSpinner: false, errorMessage: getModel.message });
            }
        } catch (ex) {
            this.setState({ showSpinner: false, errorMessage: ex.message, successMessage });
            console.log(ex);
        }
    };

    handlePopupClose = () => {
        this.setState({ showPopUp: false });
    };

    toggleSwitch = () => {
        this.setState({ isExcelView: !this.state.isExcelView })
    }
}
export default CollectionNotificationModel;