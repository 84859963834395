import * as kycFormService from "../../services/borrow/kycFormService";
import * as commonService from "../../services/common/commonService";
import { fetchGstDetails } from "../../services/borrow/shopFormService";
import Joi from "joi-browser";
import ApplyLoanModel from "./applyLoanModel";
import bsCustomFileInput from "bs-custom-file-input";
import React from "react";
import auth from "../../services/common/authService";
import * as registFormService from "../../services/login/registerFormService";
import moment from "moment";
import momentJalaali from "moment-jalaali";

class KycFormModel extends ApplyLoanModel {
  constructor(props) {
    super(props);
    if (this.props.type == "admin") {
      if (!this.state.data) this.state.data = {}
      this.state.data.accountNo = ""
      this.state.data.accN = ""
      this.state.data.ifsc = ""
      this.state.data.accountType = ""

      this.schema.accountNo = Joi.string().min(8).max(18).allow("").required().label("account number")
      this.schema.ifsc = Joi.string().allow("").required().label("ifsc code")
      this.schema.accountType = Joi.string().min(1).max(20).allow("").required().label("account type")
      this.schema.accN = Joi.string().allow("").required().label("account name")
    }


  };
  state = {
    data: {
      userId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      employment: "",
      panNo: "",
      add1: "",
      add2: "",
      add3: "",
      perAdd1: "",
      perAdd2: "",
      perAdd3: "",
      addressType: "Correspondence",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      poaNo: "",
      poaType: "",
      poaPassword: "",
      poaVerificationDocType: "",
      gstNo: "",
      bankDocPassword: "",
      alternateEmail: "",
      alternateMobile: "",
      mobileNo: "",
      dob: null,
      otp: "",
      merchantCode: "",
      email: "",
      gender: "",
    },
    files: {
      panFile: "",
      panFileStream: "",
      panFileLabel: "",
      poaFile: "",
      poaFileStream: "",
      poaFileLabel: "",
      poaBackFile: "",
      poaBackFileStream: "",
      poaBackFileLabel: "",
      gstFile: "",
      gstFileStream: "",
      gstFileLabel: "",
      additionalDocuments: [],
      additionalFilesCount: "",
    },
    isValidCity: false,
    isValidState: false,
    isValidIfsc: false,
    isValidGstNumber: false,
    isValidPanNumber: false,
    isValidPoaNumber: false,
    isValidAlternateEmail: false,
    isValidAlternateMobile: false,
    isBankSelected: false,
    employmentList: [],
    poaTypeList: [],
    poaVerificationTypeList: [],
    showSpinner: false,
    showBankSearch: false,
    showIfscSearch: false,
    stateOptions: [],
    cityOptions: [],
    ifscOptions: [],
    bankOptions: [],
    branchOptions: [],
    bankList: [],
    optionalDocumentList: [],
    errors: {},
    isDisabled: false,
    fileErrors: {},
    optional: {
      searchBankName: "",
      searchBranchName: "",
    },
    optionalErrors: {},
    loggedInFrom: "",
    info: {},
    isRegenerateLink: false,
    isRegenerateText: false,
    counter: 0,
    timerId: 0,
    timer: "",
    isValidMobile: false,
    isValidOTP: false,
    showOTP: false,
    isMobileRetrieved: false,
    isSubmitted: false,
    docConsent: true,
    showDocConsent: false,
    merchantCodeDisabled: false,
    kycStatus: "",
    type: "",
    showSuccessModal: false,
    infoHeader: "",
    infoMessage: "",
    currentUserId: "",
    currentUserRole: "",
    existingMobileNo: "",
    isValidPrimaryEmail: false,
    loanId: "",
    showFinalPopUp: false,
    bankDetailsMandatory: true,
    panDisabled: false,
    nameDisabled: false,
    bankText: "",
    panDocDisabled: false,
    poaDisabled: false,
    isGstDisabled: false,
    isSameAddress:false,
  };
  schema = {
    userId: Joi.string().required(),
    firstName: Joi.string().min(1).max(100).required().label("first name"),
    middleName: Joi.string().max(100).allow("").required().label("middle name"),
    lastName: Joi.string().min(1).max(100).required().label("last name"),
    employment: Joi.string().required().label("employment"),
    panNo: Joi.string().required().label("pan number"),
    add1: Joi.string().min(1).max(500).required().label("address line 1"),
    add2: Joi.string().max(500).allow("").required().label("address line 2"),
    add3: Joi.string().min(1).max(500).required().label("address line 3"),
    perAdd1: Joi.string().min(1).max(500).required().label("permanent address line 1"),
    perAdd2: Joi.string().max(500).allow("").required().label("permanent address line 2"),
    perAdd3: Joi.string().min(1).max(500).required().label("permanent address line 3"),
    addressType: Joi.string().min(1).max(100).required().label("address type"),
    city: Joi.string().required().label("city"),
    state: Joi.string().required().label("state"),
    pincode: Joi.number().required().label("pincode"),
    country: Joi.string().required().label("country"),
    poaNo: Joi.string().min(1).max(100).required().label("poa number"),
    poaType: Joi.string().min(1).max(100).required().label("poa file type"),
    gstNo: Joi.string().min(1).max(100).allow("").required().label("gst no"),
    alternateEmail: Joi.string()
      .max(100)
      .allow("")
      .email()
      .label("alternate email"),
    alternateMobile: Joi.string().max(10).allow("").label("alternate mobile"),
    mobileNo: Joi.number().required().label("Mobile No"),
    otp: Joi.number().required().allow("").label("OTP"),
    dob: Joi.any().required().allow("").label("Date of Birth"),
    merchantCode: Joi.any().required().allow("").label("Merchant Code"),
    email: Joi.string()
      .max(100)
      .email()
      .label("email"),
    poaVerificationDocType: Joi.string().max(100).allow("").required().label("poa Verification doc Type"),
    bankDocPassword: Joi.string().max(100).allow("").required().label("bank document password"),
    poaPassword: Joi.string().max(100).allow("").required().label("POA document password"),
    gender: Joi.string().allow("")
  };
  async loadDropdowns() {
    let { employmentList, poaTypeList, poaVerificationTypeList } = this.state;
    try {
      const {
        data: employmentListResponse,
      } = await commonService.getDefinitionDetailsMaster("employment");

      const {
        data: poaTypeListResponse,
      } = await commonService.getDefinitionDetailsMaster("poa doc type");

      const {
        data: poaDocVerificationTypeListResponse,
      } = await commonService.getDefinitionDetailsMaster("poa doc verification type");

      const currentUser = auth.getCurrentUser();


      employmentList = employmentListResponse.data;
      poaTypeList = poaTypeListResponse.data;
      poaVerificationTypeList = poaDocVerificationTypeListResponse.data;

      this.setState({
        employmentList,
        poaTypeList,
        poaVerificationTypeList,
      });
    } catch (ex) { }
  }
  async mapDataToState(userData, type, reviewData, partner, bankText) {
    let data = { ...this.state.data };
    let files = { ...this.state.files };

    let { user } = userData;

    let {
      isDisabled,
      isValidCity,
      isValidIfsc,
      isValidState,
      isValidGstNumber,
      isValidPanNumber,
      isValidPoaNumber,
      isValidAlternateEmail,
      isValidAlternateMobile,
      isValidMobile,
      isValidOTP,
      showOTP,
      isMobileRetrieved,
      kycStatus,
      isValidPrimaryEmail,
    } = this.state;
    let {
      userDetails,
      addressDetails,
      bankDetails,
      gstDetails,
      poaDetails,
      panDocDetails,
      additionalDocuments,
      merchantCode,
      panDocVerification,
      questionnairreDetails,
      issues
    } = user;

    let panDisabled = false;
    let poaDisabled = false;
    let panDocDisabled = false;
    let nameDisabled = false;
    let isGstDisabled = false;

    //User Details
    if (user._id) data.userId = user._id;
    if (userDetails.firstName) data.firstName = userDetails.firstName;
    if (userDetails.middleName) data.middleName = userDetails.middleName;
    if (userDetails.lastName) data.lastName = userDetails.lastName;
    if (userDetails.employment) data.employment = userDetails.employment;

    if (userDetails.firstName && userDetails.lastName && userDetails.clientCode && type !== "admin")
      nameDisabled = true;
    else if (userDetails.firstName && userDetails.lastName && type !== "admin" && user.panNo && user.panDocVerification && user.panDocVerification.isNumberVerified && user.panDocVerification.type === "digilocker")
      nameDisabled = true;

    isDisabled = userDetails.kycStatus === "Approved" ? true : false;
    kycStatus = userDetails.kycStatus ? userDetails.kycStatus : "";

    let loanId = "";

    if (reviewData && reviewData.loanId)
      loanId = reviewData.loanId;

    let bankDetailsMandatory = true;

    if (reviewData && reviewData.loanAmount && partner && partner.bankValidationAmount) {
      if (reviewData.loanAmount >= partner.bankValidationAmount)
        bankDetailsMandatory = true;
      else
        bankDetailsMandatory = false;
    }
    else if (bankDetails && bankDetails.length > 0)
      bankDetailsMandatory = true;

    //Email and Mobile
    if (userDetails.emailId && userDetails.emailId[1]) {
      data.alternateEmail = userDetails.emailId[1];
      isValidAlternateEmail = true;
    }

    if (userDetails.emailId && userDetails.emailId[0]) {
      data.email = userDetails.emailId[0];
      isValidPrimaryEmail = true;
    }

    if (userDetails.mobileNo && userDetails.mobileNo[1] && userDetails.mobileNo[1].mobileNumber) {
      data.alternateMobile = userDetails.mobileNo[1].mobileNumber;
      isValidAlternateMobile = true;
    }

    let existingMobileNo = "";

    if (userDetails.mobileNo && userDetails.mobileNo[0] && userDetails.mobileNo[0].mobileNumber) {
      data.mobileNo = userDetails.mobileNo[0].mobileNumber;
      isValidMobile = true;
      isMobileRetrieved = true;
      existingMobileNo = userDetails.mobileNo[0].mobileNumber;
    }

    if (userDetails.dob) {
      data.dob = momentJalaali(userDetails.dob);
    }
    //Address Details
    if (addressDetails[0] && addressDetails[0].add1)
      data.add1 = addressDetails[0].add1;
    if (addressDetails[0] && addressDetails[0].add2)
      data.add2 = addressDetails[0].add2;
    if (addressDetails[0] && addressDetails[0].add3)
      data.add3 = addressDetails[0].add3;

    if (addressDetails[0] && addressDetails[0].perAdd1)
      data.perAdd1 = addressDetails[0].perAdd1;
    if (addressDetails[0] && addressDetails[0].perAdd2)
      data.perAdd2 = addressDetails[0].perAdd2;
    if (addressDetails[0] && addressDetails[0].perAdd3)
      data.perAdd3 = addressDetails[0].perAdd3;

    if (addressDetails[0] && addressDetails[0].city) {
      data.city = addressDetails[0].city;
      isValidCity = true;
    }
    if (addressDetails[0] && addressDetails[0].state) {
      data.state = addressDetails[0].state;
      isValidState = true;
    }
    if (addressDetails[0] && addressDetails[0].pincode)
      data.pincode = addressDetails[0].pincode;
    if (user.panNo) {
      data.panNo = user.panNo;
      isValidPanNumber = true;

      if (userDetails.clientCode && type !== "admin")
        panDisabled = true;

    }
    //Bank Details

    let errors = {}

    if (this.props.type === "admin") {

      if (bankDetails[0] && bankDetails[0].accountNo)
        data.accountNo = bankDetails[0].accountNo;
      if (bankDetails[0] && bankDetails[0].ifsc) {
        data.ifsc = bankDetails[0].ifsc;
        isValidIfsc = true;
      }

      if (bankDetails[0] && bankDetails[0].accountType)
        data.accountType = bankDetails[0].accountType;

      if (bankDetails[0] && bankDetails[0].accountName)
        data.accN = bankDetails[0].accountName;

      if (questionnairreDetails?.gender) {
        data.gender = questionnairreDetails.gender
      }

      if (issues && issues.length) {
        let genderIssue = issues.find(i => i.code == 701)
        errors.gender = genderIssue.description
      }

    }
    //GST Details
    if (gstDetails[0] && gstDetails[0].gstNo) {
      data.gstNo = gstDetails[0].gstNo;
      isValidGstNumber = true;
    }

    if (gstDetails[0] && gstDetails[0].gstNo && gstDetails[0].docVerification && gstDetails[0].docVerification.isGstVerified)
      isGstDisabled = true;

    //POA Details
    if (poaDetails[0] && poaDetails[0].poaNo) {
      data.poaNo = poaDetails[0].poaNo;
      isValidPoaNumber = true;
    }
    if (poaDetails[0] && poaDetails[0].poaType)
      data.poaType = poaDetails[0].poaType;

    if (poaDetails[0] && poaDetails[0].poaVerificationDocType)
      data.poaVerificationDocType = poaDetails[0].poaVerificationDocType;

    if (panDocDetails[0] && panDocDetails[0].fileName && !this.props.isCaptureNewDocument) {
      files.panFile = panDocDetails[0].fileName;
      files.panFileLabel = panDocDetails[0].fileName;

      let url = await this.getSignedURLToDownload(panDocDetails[0].filePath)
      files.panFileSrc = url;
    }


    if (
      poaDetails[0] &&
      poaDetails[0].docDetails[0] &&
      poaDetails[0].docDetails[0].fileName && !this.props.isCaptureNewDocument
    ) {
      files.poaFile = poaDetails[0].docDetails[0].fileName;
      files.poaFileLabel = poaDetails[0].docDetails[0].fileName;

      let url = await this.getSignedURLToDownload(poaDetails[0].docDetails[0].filePath)
      files.poaFileSrc = url;
    }

    if (
      poaDetails[0] &&
      poaDetails[0].docDetails[0] &&
      poaDetails[0].docDetails[0].documentPassword
    )
      data.poaPassword = poaDetails[0].docDetails[0].documentPassword;

    if (
      poaDetails[0] &&
      poaDetails[0].docDetails[1] &&
      poaDetails[0].docDetails[1].fileName
    ) {
      files.poaBackFile = poaDetails[0].docDetails[1].fileName;
      files.poaBackFileLabel = poaDetails[0].docDetails[1].fileName;

      let url = await this.getSignedURLToDownload(poaDetails[0].docDetails[1].filePath)
      files.poaBackFileSrc = url;
    }


    if (
      gstDetails[0] &&
      gstDetails[0].docDetails[0] &&
      gstDetails[0].docDetails[0].fileName
    ) {
      files.gstFile = gstDetails[0].docDetails[0].fileName;
      files.gstFileLabel = gstDetails[0].docDetails[0].fileName;

      let url = await this.getSignedURLToDownload(gstDetails[0].docDetails[0].filePath)
      files.gstFileSrc = url;
    }


    let optionalDocumentList = [];

    if (additionalDocuments && additionalDocuments.length > 0) {
      let filePaths = []
      for (const item of additionalDocuments) {
        if (item.docDetails[0].fileName)
          filePaths.push(item.docDetails[0].filePath);
      }

      let docs = await this.getBulkSignedURLToDownload(filePaths)
      additionalDocuments.forEach((element, index) => {
        element.fileSrc = docs[index] || ""
        let [base, qString] = element.fileSrc.split("?")
        element.fileStream = base || ""
      })
      files.additionalDocuments = additionalDocuments
    }

    data.merchantCode = merchantCode ? merchantCode : "";

    const currentUser = auth.getCurrentUser();

    if (user.panNo && user.panDocVerification && user.panDocVerification.isNumberVerified && user.panDocVerification.type === "digilocker")
      panDisabled = true;

    if (user.panNo && user.panDocVerification && user.panDocVerification.isNumberVerified && user.panDocVerification.type === "digilocker" && panDocDetails[0] && panDocDetails[0].fileName)
      panDocDisabled = true;

    if (poaDetails[0] && poaDetails[0].poaNo && poaDetails[0].docVerification && poaDetails[0].docVerification.type === "digilocker")
      poaDisabled = true;

    this.setState({
      data,
      files,
      optionalDocumentList,
      isDisabled,
      isValidCity,
      isValidIfsc,
      isValidState,
      isValidGstNumber,
      isValidPanNumber,
      isValidPoaNumber,
      isValidAlternateEmail,
      isValidAlternateMobile,
      isValidOTP,
      isValidMobile,
      showOTP,
      isMobileRetrieved,
      docConsent: true,
      merchantCodeDisabled: merchantCode ? true : false,
      kycStatus,
      type: type,
      currentUserId: currentUser.userId ? currentUser.userId : "",
      currentUserRole: currentUser.role ? currentUser.role : "",
      existingMobileNo: existingMobileNo,
      isValidPrimaryEmail,
      loanId: loanId,
      bankDetailsMandatory: bankDetailsMandatory,
      nameDisabled,
      panDisabled,
      bankText,
      panDocDisabled,
      poaDisabled,
      errors,
      isGstDisabled
    });
  }
  async insertUpdateKyc() {
    let { data, errors, files, type, currentUserId, currentUserRole, showFinalPopUp, loanId, poaDisabled } = this.state;

    if (!showFinalPopUp && currentUserRole && currentUserRole.toLowerCase() === "user" && !poaDisabled) {
      this.setState({ showFinalPopUp: true });
      return;
    }

    this.setState({ showSpinner: true, isSubmitted: true, showFinalPopUp: false });

    try {
      const { data: response } = await kycFormService.insertUpdateKyc(
        data,
        files,
        currentUserId,
        currentUserRole,
        loanId
      );
      if (response.success) {
        delete errors["globalError"];

        if (type === "admin") {
          this.setState({ showSuccessModal: true, infoHeader: "Success", infoMessage: "Updated Successfully" })
        }
        else {
          this.props.handler("activeTab", "shopTab");
        }
        this.setState({ errors, showSpinner: false, isSubmitted: false });

      } else {
        if (type === "admin") {
          errors.globalError = response.message;
          this.setState({ errors, showSuccessModal: true, infoHeader: "Error", infoMessage: response.message, showSpinner: false, isSubmitted: false });
        }
        else {
          errors.globalError = response.message;
          this.setState({ errors, showSpinner: false, isSubmitted: false });
        }

      }
    } catch (ex) {
      errors.globalError = ex.response.data.message;
      this.setState({ errors, showSpinner: false, isSubmitted: false });
    }
  }
  async componentDidMount() {
    await this.loadDropdowns();
    bsCustomFileInput.init();
  }
  async componentWillReceiveProps() {

    if (this.props.gstReqired) {
      this.schema.gstNo = Joi.string().min(1).max(100).required().label("gst no");
    }

    if (this.props.userData) {
      const currentUser = auth.getCurrentUser();
      if (!currentUser) window.location.href = "/";
      else if (currentUser && currentUser.loggedInFrom) {
        this.setState({ loggedInFrom: currentUser.loggedInFrom });
      }

      let partner = {};

      if (this.props.type !== "admin")
        partner = auth.getCurrentPartner();
      else
        partner = this.props.userData && this.props.userData.loanPartner ? this.props.userData.loanPartner : {};

      await this.mapDataToState(this.props.userData, this.props.type, this.props.reviewData, partner, this.props.bankText);

      if (this.props.type !== "admin")
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    }
  }
  handleSamePermanetAddress =(value)=>{
      let isSameAddress={...this.state.isSameAddress}
      let data= { ...this.state.data };
      if(!value){
        data={ ...data, ["perAdd1"]: data.add1, ["perAdd2"]:data.add2, ["perAdd3"]: data.add3}
      }
      this.setState({ isSameAddress : !value , data});
  }
  handlePANChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });

    try {
      if (input.value.match(/(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/)) {
        const { data: response } = await kycFormService.isValidPanNumber(
          data.userId,
          input.value
        );

        if (!response.success) {
          errors["panNo"] = response.message;
          this.setState({
            errors,
            isValidPanNumber: false,
            showSpinner: false,
          });
        } else {
          delete errors["panNo"];
          this.setState({
            errors,
            isValidPanNumber: true,
            showSpinner: false,
          });
        }
      } else {
        errors["panNo"] = "Invalid PAN number";
        this.setState({
          errors,
          isValidPanNumber: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidPanNumber: false, showSpinner: false });
    }
  };
  handlePOAChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (!input.value || !data.poaType)
      return;

    if (data.poaType === "Aadhaar Card" && data.poaNo.length !== 12) {
      errors["poaNo"] = "Aadhaar Card No. must be 12 characters";
      this.setState({
        errors,
        isValidPoaNumber: false
      });
      return;
    }
    else {
      delete errors["poaNo"];
      this.setState({
        errors,
        isValidPoaNumber: true
      });
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await kycFormService.isValidPoaNumber(
        data.userId,
        input.value,
        data.poaType
      );

      if (!response.success) {
        errors["poaNo"] = response.message;
        this.setState({
          errors,
          isValidPoaNumber: false,
          showSpinner: false,
        });
      } else {
        delete errors["poaNo"];
        this.setState({
          errors,
          isValidPoaNumber: true,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidPoaNumber: false, showSpinner: false });
    }
  };
  handlePOATypeChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (!data.poaNo || !input.value)
      return;

    if (data.poaType === "Aadhaar Card" && data.poaNo.length !== 12) {
      errors["poaNo"] = "Aadhaar Card No. must be 12 characters";
      this.setState({
        errors,
        isValidPoaNumber: false
      });
      return;
    }
    else {
      delete errors["poaNo"];
      this.setState({
        errors,
        isValidPoaNumber: true
      });
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await kycFormService.isValidPoaNumber(
        data.userId,
        data.poaNo,
        input.value
      );

      if (!response.success) {
        errors["poaNo"] = response.message;
        this.setState({
          errors,
          isValidPoaNumber: false,
          showSpinner: false,
        });
      } else {
        delete errors["poaNo"];
        this.setState({
          errors,
          isValidPoaNumber: true,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidPoaNumber: false, showSpinner: false });
    }
  };
  handleGSTChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    if (input.value === "") {
      delete errors["gstNo"];
      this.setState({ errors });
      return;
    }
    try {
      if (
        input.value.match(
          /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/
        )
      ) {
        this.setState({ showSpinner: true });

        const { data: response } = await kycFormService.isValidGstNumber(
          data.userId,
          input.value
        );
        if (!response.success) {
          errors["gstNo"] = response.message;
          this.setState({
            errors,
            isValidGstNumber: false,
            showSpinner: false,
          });
        } else {
          const { data: response } = await fetchGstDetails(
            data?.userId,
            input.value
          );
          if (response) {
            delete errors["gstNo"];
            this.setState({
              errors,
              isValidGstNumber: true,
              showSpinner: false,
            });
          }
          else {
            errors["gstNo"] = "Some Error occoured.";
            this.setState({
              errors,
              isValidGstNumber: true,
              showSpinner: false,
            });
          }
        }
      } else {
        errors["gstNo"] = "Invalid GST number";
        this.setState({
          errors,
          isValidGstNumber: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidGstNumber: false, showSpinner: false });
    }
  };
  handleClose = () => {
    this.setState({ showBankSearch: false, showIfscSearch: false });
  };
  closeSuccessModal = () => {
    this.setState({ showSuccessModal: false });
  };
  handleDocConsentClose = () => {
    this.setState({ showDocConsent: false });
  };
  handleCloseFinalPopUp = () => {
    this.setState({ showFinalPopUp: false });
  };
  async handleCustomErrors(errors) {
    const data = { ...this.state.data };
    const files = { ...this.state.files };
    const {
      isValidState,
      isValidCity,
      isValidGstNumber,
      isValidPanNumber,
      isValidPoaNumber,
      isValidAlternateEmail,
      isValidAlternateMobile,
      isValidIfsc,
      isValidMobile,
      isValidOTP,
      isDisabled,
      isMobileRetrieved,
      docConsent,
      isValidPrimaryEmail,
      bankDetailsMandatory,
      currentUserRole,
    } = this.state;
    let fileErrors = { ...this.state.fileErrors };

    const onlyCharacters = /^[a-zA-Z ]+$/;

    if (!files.panFile) fileErrors["panFile"] = "Please upload PAN document";
    else delete fileErrors["panFile"];

    if (!files.poaFile) fileErrors["poaFile"] = "Please upload address proof document";
    else delete fileErrors["poaFile"];


    if (data.gstNo) {
      // if (!files.gstFile) fileErrors["gstFile"] = "GST certificate required if GST number is entered";
      // else delete fileErrors["gstFile"];
      delete fileErrors["gstFile"];
    } else {
      if (this.props.gstRequired) {
        errors["gstNo"] = "GST Number is Required as the amount is more than 2Lakhs";
      }
      else if (files.gstFile) errors["gstNo"] = "GST number required if GST document is uploaded";
      else {
        delete fileErrors["gstFile"];
        delete errors["gstNo"];
      }
    }

    if (data.pincode.length !== 6 || isNaN(data.pincode))
      errors["pincode"] = "Invalid pincode";
    else delete errors["pincode"];

    if (!errors["state"] && data.state) {
      if (!isValidState) errors["state"] = "Invalid State";
      else delete errors["state"];
    }
    if (!errors["city"] && data.city) {
      if (!errors["city"] && !isValidCity) errors["city"] = "Invalid City";
      else delete errors["city"];
    }

    // if (currentUserRole && currentUserRole.toLowerCase() === "user" && bankDetailsMandatory) {
    //   if (!errors["ifsc"] && data.ifsc) {
    //     if (!errors["ifsc"] && !isValidIfsc) errors["ifsc"] = "Invalid IFSC Code";
    //     else delete errors["ifsc"];
    //   }

    //   if (!errors["ifsc"]) {
    //     if (!data.ifsc) {
    //       errors["ifsc"] = "Please enter Ifsc Code";
    //     }
    //     else if (!isValidIfsc)
    //       errors["ifsc"] = "Invalid IFSC Code";
    //     else delete errors["ifsc"];
    //   }

    //   if (!errors["accountNo"]) {
    //     if (!data.accountNo)
    //       errors["accountNo"] = "Please enter account number";
    //     else delete errors["accountNo"];
    //   }

    //   if (!errors["accountType"]) {
    //     if (!data.accountType)
    //       errors["accountType"] = "Please enter account type";
    //     else delete errors["accountType"];
    //   }

    //   if (!errors["accN"]) {
    //     if (!data.accN)
    //       errors["accN"] = "Please enter account holder name";
    //     else if (!onlyCharacters.test(data.accN))
    //       errors["accN"] = "Account Name can only contain characters";
    //     else delete errors["accN"];
    //   }
    // }

    if (this.props.type == "admin") {
      if (data.accountNo || data.ifsc || data.accountType || data.accN) {
        if (!errors["ifsc"] && data.ifsc) {
          if (!errors["ifsc"] && !isValidIfsc) errors["ifsc"] = "Invalid IFSC Code";
          else delete errors["ifsc"];
        }

        if (!errors["ifsc"]) {
          if (!data.ifsc) {
            errors["ifsc"] = "Please enter Ifsc Code";
          }
          else if (!isValidIfsc)
            errors["ifsc"] = "Invalid IFSC Code";
          else delete errors["ifsc"];
        }

        if (!errors["accountNo"]) {
          if (!data.accountNo)
            errors["accountNo"] = "Please enter account number";
          else delete errors["accountNo"];
        }

        if (!errors["accountType"]) {
          if (!data.accountType)
            errors["accountType"] = "Please enter account type";
          else delete errors["accountType"];
        }

        if (!errors["accN"]) {
          if (!data.accN)
            errors["accN"] = "Please enter account holder name";
          else if (!onlyCharacters.test(data.accN))
            errors["accN"] = "Account Name can only contain characters";
          else delete errors["accN"];
        }
      }
    }

    if (!errors["panNo"] && data.panNo) {
      if (!errors["panNo"] && !isValidPanNumber)
        errors["panNo"] = "Invalid PAN Number/PAN already exists";
      else delete errors["panNo"];
    }

    if (!errors["poaNo"] && data.poaNo) {
      if (!errors["poaNo"] && data.poaType === "Aadhaar Card" && data.poaNo.length !== 12)
        errors["poaNo"] = "Aadhaar No must be 12 characters";
      else if (!errors["poaNo"] && !isValidPoaNumber)
        errors["poaNo"] = "Invalid POA Number/POA already exists";
      else delete errors["poaNo"];
    }

    if (!errors["gstNo"] && data.gstNo) {
      if (!errors["gstNo"] && !isValidGstNumber)
        errors["gstNo"] = "Invalid GST Number/GST already exists";
      else delete errors["gstNo"];
    }

    if (!errors["alternateEmail"] && data.alternateEmail) {
      if (!errors["alternateEmail"] && !isValidAlternateEmail) {
        errors["alternateEmail"] = "Invalid Email/Email already exists";
      }
      else if (data.alternateEmail && data.email) {
        if (data.email.trim().toLowerCase() === data.alternateEmail.trim().toLowerCase()) {
          errors["alternateEmail"] = "Email cannot be same as primary email";
        }
        else {
          delete errors["alternateEmail"];
        }
      }
      else delete errors["alternateEmail"];
    }

    if (!errors["email"] && data.email) {
      if (!errors["email"] && !isValidPrimaryEmail)
        errors["email"] = "Invalid Email/Email already exists";
      else delete errors["email"];
    }

    if (!errors["alternateMobile"] && data.alternateMobile) {
      if (!errors["alternateMobile"] && !isValidAlternateMobile)
        errors["alternateMobile"] = "Invalid Mobile/Mobile already exists";
      else delete errors["alternateMobile"];
    }

    if (!isMobileRetrieved) {
      if (!errors["mobileNo"] && data.mobileNo) {
        if (isValidMobile) delete errors["mobileNo"];
        else if (data.mobileNo.length !== 10) errors["mobileNo"] = "Invalid Mobile No";
        else errors["mobileNo"] = "Please Enter Valid Mobile";
      }
    }

    if (!isMobileRetrieved) {
      if (!errors["otp"] && data.otp) {
        // if (isValidOTP) delete errors["otp"];
        // else errors["otp"] = "Invalid OTP";
      }
      else if (!data.otp) errors["otp"] = "Enter OTP";
    }

    if (!isDisabled) {
      if (!errors["dob"] && data.dob) {
        let age = moment().diff(data.dob, "years");
        if (age < 18) errors["dob"] = "Age must be greater than or equal to 18";
        else delete errors["dob"];
      }
      else if (!data.dob) {
        errors["dob"] = "Enter date of birth";
      }
    }

    if (!data.merchantCode)
      errors["merchantCode"] = "Please enter merchant code";
    else
      delete errors["merchantCode"];

    if (!docConsent) errors["docConsent"] = "Please provide document consent";
    else delete errors["docConsent"];

    this.setState({ errors, fileErrors });

    return errors;
  }


  doSubmit = async () => {
    await this.insertUpdateKyc();
  };

  handlePincodeChange = async (e) => {
    const { name, value } = e.target;

    if (isNaN(value)) {
      return;
    }

    let data = this.state.data;
    let errors = this.state.errors;

    data = { ...data, [name]: value, state: "", city: "" };
    let isValidState = false

    if (value.length !== 6)
      errors.pincode = "Pincode must be 6 characters"
    else {
      errors.pincode = ""
      const { data: response } = await commonService.getStatesByPincode(value);
      let state = response?.data && response?.data.length ? response?.data[0] : ""
      data.state = state
      isValidState = true
      errors["state"] = ""
    }

    this.setState({ data, errors, isValidState });

  };

  onIfscChange = async (event, { newValue }) => {
    let errors = { ...this.state.errors };
    let data = { ...this.state.data };

    data.ifsc = newValue.trim().toUpperCase();
    this.setState({ data, isValidIfsc: false });

    if (newValue.length !== 11) {
      errors["ifsc"] = "Invalid Ifsc Code";
      this.setState({ errors, isValidIfsc: false });
      return;
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await commonService.isValidIfscCode(newValue);

      if (response.success) {
        delete errors["ifsc"];
        this.setState({
          isValidIfsc: response.success,
          errors,
          showSpinner: false,
        });
      } else {
        errors["ifsc"] = "Invalid Ifsc Code";
        this.setState({
          isValidIfsc: response.success,
          errors,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors["ifsc"] = "Invalid Ifsc Code";
      this.setState({
        isValidIfsc: false,
        errors,
        showSpinner: false,
      });
    }
  };
  onIfscSuggestionsRequested = async ({ value }) => {
    try {
      const { data: response } = await commonService.getIfscCode(value);
      this.setState({
        ifscOptions: response.data,
      });
    } catch (ex) { }
  };
  onBankChange = async (event, { newValue }) => {
    const optional = { ...this.state.optional };
    optional["searchBankName"] = newValue.toUpperCase();
    this.setState({ optional });
  };
  onBankSuggestionsRequested = async ({ value }) => {
    const inputValue = value.trim().toUpperCase();
    try {
      const { data: response } = await commonService.getBankList(inputValue);
      this.setState({
        bankOptions: response.data,
      });
    } catch (ex) { }
  };
  onBranchChange = async (event, { newValue }) => {
    const optional = { ...this.state.optional };
    optional["searchBranchName"] = newValue.trim().toUpperCase();
    this.setState({ optional });
  };
  onBranchSuggestionsRequested = async ({ value }) => {
    const inputValue = value.trim();
    const optional = { ...this.state.optional };
    try {
      const { data: response } = await commonService.getBranchList(
        optional.searchBankName,
        inputValue
      );
      this.setState({
        branchOptions: response.data,
      });
    } catch (ex) { }
  };
  showBankSearch = async () => {
    this.setState({
      showBankSearch: true,
      showIfscSearch: false,
      isBankSelected: false
    });
  };
  onBankSelect = (ifsc) => {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };
    data.ifsc = ifsc;
    delete errors["ifsc"];
    this.setState({ errors, data, isValidIfsc: true, isBankSelected: true });
  };
  getIfscList = async () => {
    const optional = { ...this.state.optional };
    const optionalErrors = { ...this.state.optionalErrors };
    const bankList = [];

    if (!optional.searchBankName)
      optionalErrors["searchBankName"] = "Enter bank name";
    else delete optionalErrors["searchBankName"];

    if (!optional.searchBranchName)
      optionalErrors["searchBranchName"] = "Enter branch name";
    else delete optionalErrors["searchBranchName"];

    this.setState({ optionalErrors });

    if (!optional.searchBankName || !optional.searchBranchName) return;

    try {
      const { data: response } = await commonService.getIfscList(
        optional.searchBankName,
        optional.searchBranchName
      );

      for (const item of response.data) {
        let obj = {
          ifsc: item.ifsc,
          bank: item.bank,
          branch: item.branch,
        };
        bankList.push(obj);
      }
      this.setState({ bankList, showBankSearch: false, showIfscSearch: true });
    } catch (ex) { }
  };
  onStateChange = async (event, { newValue }) => {
    let errors = { ...this.state.errors };
    let data = { ...this.state.data };

    let pincode = this.state.data.pincode

    if (!pincode || pincode.length != 6) {
      let errors = this.state.errors
      errors["state"] = "Please enter valid pincode before state"
      this.setState({ errors })
      return
    }

    data.state = newValue.trim().toUpperCase();
    data.city = "";
    this.setState({ data, showSpinner: true });

    try {
      const { data: response } = await commonService.isValidState(newValue, pincode);

      if (response.success) {
        delete errors["state"];
        this.setState({
          isValidState: response.success,
          errors,
          showSpinner: false,
        });
      } else {
        errors["state"] = response.message;
        this.setState({
          isValidState: response.success,
          isValidCity: false,
          showSpinner: false,
          errors,
        });
      }
    } catch (ex) {
      this.setState({
        isValidState: false,
        isValidCity: false,
        showSpinner: false,
      });
    }
  };
  onStateSuggestionsRequested = async ({ value }) => {
    let state = value.trim();

    let pincode = this.state.data.pincode

    if (!pincode || pincode.length != 6) {
      let errors = this.state.errors
      errors["state"] = "Please enter valid pincode before state"
      this.setState({ errors })
      return
    }

    try {
      const { data: response } = await commonService.getStates(state, pincode);

      this.setState({
        stateOptions: response.data,
      });
    } catch (ex) { }
  };
  onCityChange = async (event, { newValue }) => {
    let errors = { ...this.state.errors };
    let data = { ...this.state.data };
    let city = newValue.trim().toUpperCase();
    let pincode = this.state.data.pincode
    data.city = newValue.trim();

    if (!this.state.isValidState) {
      errors["city"] = "Please enter a valid state";
      this.setState({ data, errors });
      return;
    } else {
      delete errors["city"];
      this.setState({ data, errors });
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await commonService.isValidCity(
        data.state,
        city,
        // pincode //As per bikram's requirement
      );

      if (response.success) {
        delete errors["city"];
        this.setState({
          isValidCity: response.success,
          errors,
          showSpinner: false,
        });
      } else {
        errors["city"] = "City doesn't belong to state";
        this.setState({
          isValidCity: response.success,
          errors,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors["city"] = "City doesn't belong to state";
      this.setState({
        isValidCity: false,
        errors,
        showSpinner: false,
      });
    }
  };
  onCitySuggestionsRequested = async ({ value }) => {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };

    if (!this.state.isValidState) {
      errors["city"] = "Please select valid state before city";
      this.setState({ errors });
      return;
    } else {
      delete errors["city"];
      this.setState({ errors });
    }

    try {
      const { data: response } = await commonService.getCities(
        data.state,
        value
      );

      this.setState({
        cityOptions: response.data,
      });
    } catch (ex) { }
  };
  handleEmailChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (errors["alternateEmail"] || !input.value) return;

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await kycFormService.isValidAlternateEmail(
        data.userId,
        input.value
      );

      if (!response.success) {
        errors["alternateEmail"] = response.message;
        this.setState({
          errors,
          isValidAlternateEmail: false,
          showSpinner: false,
        });
      } else {
        delete errors["alternateEmail"];
        this.setState({
          errors,
          isValidAlternateEmail: true,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidAlternateEmail: false, showSpinner: false });
    }
  };
  handlePrimaryEmailChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (errors["email"] || !input.value) return;

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await kycFormService.isValidPrimaryEmail(
        data.userId,
        input.value
      );

      if (!response.success) {
        errors["email"] = response.message;
        this.setState({
          errors,
          isValidPrimaryEmail: false,
          showSpinner: false,
        });
      } else {
        delete errors["email"];
        this.setState({
          errors,
          isValidPrimaryEmail: true,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidPrimaryEmail: false, showSpinner: false });
    }
  };
  handleAlternateMobileChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (!input.value) {
      delete errors["alternateMobile"];
      this.setState({ errors });
      return;
    }

    if (input.value.length !== 10 || isNaN(input.value)) {
      errors["alternateMobile"] = "Invalid Mobile";
      this.setState({ errors });
      return;
    } else {
      delete errors["alternateMobile"];
      this.setState({ errors });
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await kycFormService.isValidAlternateMobile(
        data.userId,
        input.value
      );

      if (!response.success) {
        errors["alternateMobile"] = response.message;
        this.setState({
          errors,
          isValidAlternateMobile: false,
          showSpinner: false,
        });
      } else {
        delete errors["alternateMobile"];
        this.setState({
          errors,
          isValidAlternateMobile: true,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidAlternateMobile: false, showSpinner: false });
    }
  };
  handleMobileChange = async ({ currentTarget: input }) => {
    const { data, errors, info, isValidMobile, existingMobileNo } = this.state;

    if (isValidMobile && existingMobileNo === input.value)
      return;

    this.setState({ isValidMobile: false, isValidOTP: false, showOTP: false, isMobileRetrieved: false });

    if (input.value.length !== 10 || isNaN(input.value)) return;

    this.setState({ showSpinner: true });

    try {
      const {
        success,
        message,
      } = await kycFormService.isValidPrimaryMobile(
        data.userId,
        input.value
      );

      if (success) {
        delete errors[input.name];
        delete errors["otp"];
        info[input.name] = "OTP sent successfully";
        this.setState({
          errors,
          data,
          isValidMobile: true,
          showSpinner: false,
          showOTP: true
        });
        this.startTimer();
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidMobile: false,
          showSpinner: false,
          showOTP: false
        });
      }
    } catch (ex) {
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ data, errors, showSpinner: false });
    }
  };
  handleOTPChange = async ({ currentTarget: input }) => {

    return
    const { data, errors, info } = this.state;

    if (input.value.length !== 6 || isNaN(input.value)) return;

    this.setState({ showSpinner: true });

    try {
      const { success, message } = await registFormService.authenticateOTP(
        data.mobileNo,
        input.value,
        "KYC_PAGE"
      );

      if (success) {
        delete errors[input.name];
        info[input.name] = "OTP Matched";
        this.stopTimer();
        this.setState({
          errors,
          data,
          isRegenerateLink: false,
          isValidOTP: true,
          showSpinner: false,
          isRegenerateText: false,
        });
      } else {
        errors[input.name] = message;
        delete info[input.name];
        this.setState({ errors, data, isValidOTP: false, showSpinner: false });
      }
    } catch (ex) {
      this.stopTimer();
      this.startTimer();
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ data, errors, isValidOTP: false, showSpinner: false });
    }
  };
  handleRegenerateOTP = async () => {
    const { data, errors, info } = this.state;

    if (data.mobileNo.length !== 10 || isNaN(data.mobileNo)) return;

    this.setState({ showSpinner: true, isValidOTP: false });

    try {
      const { success, message } = await kycFormService.isValidPrimaryMobile(
        data.userId,
        data.mobileNo,
      );

      if (success) {
        info["mobileNo"] = "OTP sent successfully";
        delete info["otp"];
        delete errors["otp"];
        delete errors["mobileNo"];
        this.setState({ data, info, errors, showSpinner: false });
        this.startTimer();
      } else {
        errors["mobileNo"] = message;
        delete info["mobileNo"];
        this.setState({ data, errors, info, showSpinner: false });
      }
    } catch (ex) {
      errors["otp"] = ex.response.data.message;
      delete info["otp"];
      this.setState({ data, errors, info, showSpinner: false });
    }
  };
  handleDobChange = (value) => {
    const { data, errors } = this.state;

    data.dob = value;
    delete errors["dob"];
    this.setState({ data, errors });
  };
  onStateSuggestionsClearRequested = () => {
    this.setState({
      stateOptions: [],
    });
  };
  onCitySuggestionsClearRequested = () => {
    this.setState({
      cityOptions: [],
    });
  };
  onIfscSuggestionsClearRequested = () => {
    this.setState({
      ifscOptions: [],
    });
  };
  onBankSuggestionsClearRequested = () => {
    this.setState({
      bankOptions: [],
    });
  };
  onBranchSuggestionsClearRequested = () => {
    this.setState({
      branchOptions: [],
    });
  };
  getSuggestionValue = (suggestion) => suggestion;
  renderSuggestion = (suggestion) => <div>{suggestion}</div>;
  handleViewDocConsent = () => {
    this.setState({ showDocConsent: true });
  };
  handleDocConsent = async (docConsent) => {
    const errors = { ...this.state.errors };

    if (!docConsent) {
      delete errors["docConsent"];
    }

    this.setState({ docConsent: !docConsent, errors });
  };
  toggleChange = () => {
    let { isDisabled } = this.state;
    this.setState({ isDisabled: !isDisabled });
  };


  handleUploadBankStatement = async (event) => {
    const bankStatementFile = event.target.files[0];
    const data = { ...this.state.data };
    try {
      const formData = new FormData();
      formData.append('pdfFile', bankStatementFile);
      formData.append('userId', data.userId);
      this.setState({ uploading: true, uploadMessage: 'Uploading, please wait..' });
      const response = await kycFormService.bankStatementFileUpload(formData);

      if (response.status === 200) {
        alert('Bank statement uploaded successfully.');
      } else {
        alert('Error uploading bank statement.');
      }
    } catch (ex) {
      alert('Error uploading bank statement.');
    }
    finally {

      this.setState({ uploading: false, uploadMessage: '' });
    }
  };
}


export default KycFormModel;
