import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ManualQueueSettlementModel from "../../models/manualQueueSettlementModel/manualQueueSettlementModel";
import SpinnerComponent from "../../components/common/spinner";
import { Tabs, Tab, Modal, Form, Col, Button } from "react-bootstrap";


class ManualQueueSettlement extends ManualQueueSettlementModel {

  renderTabs = () => {
    let { tabs, activeTab } = this.state;
    let elements = []
    for (let i = 0; i < tabs.length; i++) {
      elements.push(
        <Tab eventKey={tabs[i].value} title={tabs[i].title}></Tab>
      )
    }
    return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  render() {
    const {
      showSpinner,
      data,
      partners,
      errors,
      misList
    } = this.state;
    console.log(data["selectedMis"])

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={true}
          onHide={this.props.close}
          aria-labelledby="example-custom-modal-styling-title"
          dialogClassName="settlement-model"
        >
          <Modal.Header closeButton>
            <h5>Settlement</h5>
          </Modal.Header>

          <Modal.Body>
            <SpinnerComponent showSpinner={showSpinner} />
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="partner">
                <Form.Label>Amount</Form.Label>
                <div>₹{data.amount}</div>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="partner">
                <Form.Label>UTR No</Form.Label>
                <div>{data.utrNo}</div>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" controlId="partnerId">
                <Form.Label>Partner<span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="partnerId"
                  value={data["partnerId"]}
                  onChange={this.handleChangePartners}
                >
                  {" "}
                  <option key="all" value="all">
                    ALL
                  </option>
                  {partners && partners.map((partner) => (
                    <option key={partner.partnerName} value={partner._id}>
                      {partner.displayName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {!this.state.settlementTransaction || !this.state.settlementTransaction.isMISReceived ?
                <>
                  {data && data["partnerId"] && <Form.Group as={Col} md="6" controlId="selectedMis">
                    <Form.Label>Received MIS Files<span>*</span></Form.Label>
                    <Form.Control
                      as="select"
                      name="selectedMis"
                      value={data["selectedMis"]}
                      onChange={this.handleChangeMISFile}
                    >
                      {" "}
                      <option key="" value="">
                        -- Select --
                      </option>

                      <option key="FILE_UPLOAD" value="FILE_UPLOAD">
                        -- UPLOAD CUSTOM FILE --
                      </option>

                      {misList && misList.map((mis) => (
                        <option key={mis._id} value={mis._id}>
                          {mis && mis.info.fileName} {mis && mis.info && mis.info.emailReceivedOn ? ' - ' + new Date(mis.info.emailReceivedOn).toDateString() : ""}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>}


                  {data && data["selectedMis"] && data["selectedMis"] == "FILE_UPLOAD" ? <Form.Group as={Col} md="6" controlId="UploadFile">
                    <Form.Label>MIS File <span>*</span></Form.Label>
                    <Form.File
                      label={data.fileName || "Please Select File"}
                      onChange={this.onExcelFileChange1}
                      id="uploadFile"
                      name="uploadFile"
                      accept=".xls,.xlsx"
                      key={data.fileName || ''}
                      custom
                    />{" "}
                    {errors["uploadFile"] && (
                      <div className="alert alert-danger">
                        {errors["uploadFile"]}
                      </div>
                    )}
                  </Form.Group> : <></>} </> :
                <Form.Group as={Col} md="6" controlId="UploadFile">
                  <Form.Label>MIS File</Form.Label>
                  <small>
                    <div><strong>MIS processed On : </strong>{this.state.settlementTransaction.insertedOn && new Date(this.state.settlementTransaction.insertedOn).toLocaleString()}</div>
                    <div><strong>MIS Amount : </strong>{this.state.settlementTransaction.misAmount ? '₹' + this.state.settlementTransaction.misAmount : "-"}</div>
                  </small>
                </Form.Group>}
            </Form.Row>

            <Form.Row>

            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" className="text-right">

                {this.state.settlementTransaction && this.state.settlementTransaction.isSettlementReceived ? <small className="fundfinaColor"><strong>
                  *Settlement Already Received*
                </strong></small> :
                  <Button
                    className="ff-button mr-2 mt-2"
                    onClick={this.saveManualQueueAction}
                    disabled={!data["partnerId"] || ((!this.state.settlementTransaction || !this.state.settlementTransaction.isMISReceived) && !data.fileName && (!data["selectedMis"] || data["selectedMis"] == "FILE_UPLOAD")) || !data["selectedMis"]}
                  >
                    Submit
                  </Button>}
              </Form.Group>
            </Form.Row>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ManualQueueSettlement;
