import TrancheInfoModel from "../../models/trancheCRUD/trancheInfoModel";
import React from "react";
import { Form, Col, Button, FormCheck } from "react-bootstrap";
import DatePicker from "react-datepicker2";

class TrancheInfo extends TrancheInfoModel {
    render() {
        const { formData, deploymentTypeList, typeList, errors } = this.state;
        
        return (
            <React.Fragment>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="reverseResidualInDays">
                            <Form.Label>Reverse Residual In Days</Form.Label>
                            <Form.Control required
                                type="text"
                                name="reverseResidualInDays"
                                defaultValue={formData.reverseResidualInDays}
                                onChange={this.handleTrancheInfoFormChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="interestRate">
                            <Form.Label>Interest Rate (%)</Form.Label>
                            <Form.Control required
                                type="text"
                                name="interestRate"
                                defaultValue={formData.interestRate}
                                onChange={this.handleTrancheInfoFormChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="tenure">
                            <Form.Label>Tenure</Form.Label>
                            <Form.Control required
                                type="text"
                                name="value"
                                defaultValue={formData.tenure.value ?formData.tenure.value:""}
                                onChange={this.handleTrancheInfoFormTenureChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="amount">
                            <Form.Label>Amount (₹)</Form.Label>
                            <Form.Control required
                                type="text"
                                name="amount"
                                defaultValue={formData.amount}
                                onChange={this.handleTrancheInfoFormChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="deploymentType">
                            <Form.Label>Deployment Type</Form.Label>
                            <Form.Control required
                                as="select"
                                name="deploymentType"
                                value={formData.deploymentType}
                                onChange={this.handleTrancheInfoFormChange}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {deploymentTypeList.map((item, i) => (
                                    <option key={i} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="type">
                            <Form.Label>Type</Form.Label>
                            <Form.Control required
                                as="select"
                                name="type"
                                value={formData.type}
                                onChange={this.handleTrancheInfoFormChange}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {typeList.map((item, i) => (
                                    <option key={i} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>

                        <Form.Group as={Col} md="4" controlId="fldgAmount">
                            <Form.Label>FLDG Amount</Form.Label>
                            <Form.Control required
                                type="text"
                                name="amount"
                                defaultValue={formData.fldg.amount}
                                onChange={this.handleTrancheInfoFLDGChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="transactionDate">
                            <Form.Label>Transaction Date</Form.Label>
                            <DatePicker required
                                name="transactionDate"
                                id="transactionDate"
                                value={formData.fldg.transactionDate}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleTransactionDateChange(value)}
                                placeholder="Enter Transaction Date"
                            />
                            {errors["transactionDate"] && (
                                <div className="alert alert-danger">
                                    {errors["transactionDate"]}
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="utrNumber">
                            <Form.Label>UTR No.</Form.Label>
                            <Form.Control required
                                type="text"
                                name="utrNumber"
                                defaultValue={formData.fldg["utrNumber"]}
                                onChange={this.handleTrancheInfoFLDGChange}
                                autoComplete="off"
                            />
                            
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>

                        <Form.Group as={Col} md="4" controlId="startDate">
                            <Form.Label>Start Date</Form.Label>
                            <DatePicker required
                                name="startDate"
                                id="startDate"
                                value={formData.startDate}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleStartDateChange(value)}
                                placeholder="Enter Start Date"
                            />
                            {errors["startDate"] && (
                                <div className="alert alert-danger">
                                    {errors["startDate"]}
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="endDate">
                            <Form.Label>End Date</Form.Label>
                            <DatePicker required
                                name="endDate"
                                id="endDate"
                                value={formData.endDate}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleEndDateChange(value)}
                                placeholder="Enter End Date"
                            />
                            {errors["endDate"] && (
                                <div className="alert alert-danger">
                                    {errors["endDate"]}
                                </div>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="isActive">
                            <FormCheck
                                id="isActive"
                                name="isActive"
                                type="switch"
                                value={formData.isActive}
                                checked={formData.isActive}
                                onChange={this.toggleChange}
                                label="Is Active"
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>

                        <Form.Group as={Col} md="4">
                            {errors["globalError"]&&(<React.Fragment>
                                <span style={{color:"red",display:"block"}}>{errors["globalError"]}</span>
                            </React.Fragment>)}
                            
                            <Button
                                onClick={() => this.goBack()}
                                className="ff-button mt-2 mr-2"
                                type="button"
                            >
                                Back
                            </Button>

                            <Button
                                className="ff-button mt-2 mr-2"
                                type="submit"
                            >
                                Save
                            </Button>

                        </Form.Group>
                    </Form.Row>
                </Form>
            </React.Fragment >
        )
    }
}

export default TrancheInfo;