import InputDataForm from "../../components/common/form";
import * as disbursalService from '../../services/disbursal/disbursalService';
import auth from "../../services/common/authService";
import { moment } from "moment";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class DisbursalTableModel extends InputDataForm {
    state = {
        data: {
            fromDate: momentJalaali().subtract(1, "months"),
            toDate: momentJalaali(),
            partner: "all",
            lendingPartner: "all",
            status: "Pending",
            productId: "",
            loanNo: "",
            loanId: "",
            paymentMode: "",
            fileName: "",
            selectedFile: null,
        },
        isManual: false,
        borrowingPartners: [],
        lendingPartners: [],
        loansList: [],
        selectedLoans: [],
        showSpinner: false,
        errors: {},
        currentPage: 1,
        pageSize: 10,
        showLoanDecisionModal: false,
        loanId: "",
        showFilter: false,
        isDisbursalViaFile: false,
        showRedisburseConfirmation: false,
        showExport: false
    };

    schema = {
        fromDate: Joi.any(),
        toDate: Joi.any(),
        partner: Joi.any(),
        lendingPartner: Joi.any(),
        status: Joi.any(),
        productId: Joi.any(),
        loanNo: Joi.any(),
        loanId: Joi.any(),
        paymentMode: Joi.any(),
        fileName: Joi.any()
    }

    async getLoansList() {

        const dataModel = { ...this.state.data };
        this.setState({ showSpinner: true, showFilter: false, successMsg: null });
        try {
            const { data: loanList } = await disbursalService.disbursalList(dataModel);
            this.setLenderInfo(loanList);
            this.setState({ loansList: loanList, showSpinner: false, currentPage: 1 });
        }
        catch (ex) {
            this.setState({ loansList: [], showSpinner: false, currentPage: 1 });
        }
    }

    setLenderInfo(loanList) {

        if (this.state.data["lendingPartner"] && this.state.data["lendingPartner"] != "all") {
            if (loanList && loanList.length) {
                let disbursalAmount = loanList.reduce((prev, curr) => prev + curr.loanAmount, 0);
                let numberOfLoans = loanList.length;
                this.setState({ disbursalAmount, numberOfLoans });
            } else {
                this.setState({ disbursalAmount: 0, numberOfLoans: 0 });
            }
        } else {
            this.setState({ disbursalAmount: 0, numberOfLoans: 0 });
        }


    }

    async getBorroingPartners() {
        const dataModel = { ...this.state.data };
        this.setState({ showSpinner: true });
        try {
            const { data: partnerList } = await disbursalService.borrowingPartners();
            this.setState({ borrowingPartners: partnerList, showSpinner: false });
        }
        catch (ex) {
            this.setState({ borrowingPartners: [], showSpinner: false });
        }
    }

    async getLendingPartners() {
        const dataModel = { ...this.state.data };
        let lendingPartnerHash = {};
        this.setState({ showSpinner: true });
        try {
            const { data: partnerList } = await disbursalService.getLendingPartnerList();

            if (partnerList.data) {
                partnerList.data.map((partner) => {
                    lendingPartnerHash[partner._id] = partner;
                });
            }

            this.setState({ lendingPartners: partnerList.data, showSpinner: false, lendingPartnerHash });
        }
        catch (ex) {
            this.setState({ lendingPartners: [], showSpinner: false });
        }
    }

    async componentDidMount() {
        const propsData = this.props.data;
        //console.log("propsData", propsData);
        this.setState({ data: propsData, selectedLoans: [] });

        if (!auth.getCurrentUser() || auth.getCurrentUser().role.toLowerCase() !== "admin") return;
        //console.log("current User",auth.getCurrentUser());
        await this.getBorroingPartners();
        await this.getLendingPartners();
        await this.getLoansList();
    }

    async handleCustomErrors(errors) {
        return errors;
    }

    async doSubmit() {
        //console.log(this.state);
        await this.getLoansList();

    }

    async handleDisburse(loanId) {
        const data = { ...this.state.data };
        data.loanId = loanId;
        this.props.onRedirect(data);
    }

    toggleConfirmationModal = () => {
        this.setState({ showRedisburseConfirmation: !this.state.showRedisburseConfirmation })
    }

    showRedisburseModel(loanId) {
        let data = { ...this.state.data };
        data.loanId = loanId
        this.setState({ data, showRedisburseConfirmation: true });
    }

    async handleReDisburse() {

        const errors = { ...this.state.errors };
        const data = { ...this.state.data };
        const info = { ...this.state.info };
        this.setState({ showSpinner: true });
        try {
            const { success, message } = await disbursalService.reDisburse(data);

            if (success) {
                info.globalInfo = message;
                await this.getLoansList();
            }
            else {
                errors.globalError = message;
            }
            this.setState({ errors, showSpinner: false, successMsg: message, showRedisburseConfirmation: false });
        }
        catch (ex) {
            errors.globalError = ex.message;
            this.setState({ errors, successMsg: null, showSpinner: false, showRedisburseConfirmation: false });
        }
    }


    handleChecked = loanNo => e => {
        const selectedLoans = this.state.selectedLoans;

        if (e.target.checked == false && selectedLoans.includes(loanNo, 0)) {
            const index = selectedLoans.indexOf(loanNo);
            if (index != -1) {
                selectedLoans.splice(index, 1);
            }
        } else if (e.target.checked == true && !selectedLoans.includes(loanNo, 0)) {
            selectedLoans.push(loanNo);
        }
        this.setState({ selectedLoans });
    }

    handleExport = async (e) => {
        const selectedLoans = this.state.selectedLoans;
        const data = { ...this.state.data }
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        errors["globalError"] = null;
        errors["paymentMode"] = null;
        //console.log(data);
        if (selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        } else if (data.paymentMode === "") {
            errors["paymentMode"] = "Please Select Payment Mode";
            this.setState({ errors, showSpinner: false });
        } else {
            let result = await disbursalService.exportDisbursalFile(selectedLoans, data.paymentMode);
            //console.log(result);
            if (result.success) {
                await this.getLoansList();
                this.setState({ errors, showSpinner: false, successMsg: "Exported Successfully" });
            }
        }
    }

    handleUpload = async (e) => {
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        errors["globalError"] = null;
        errors["paymentMode"] = null;
        errors["uploadFile"] = null;
        try {
            let fileData = new FormData();
            //console.log("selectedFile", data.selectedFile);
            if (data.selectedFile) {
                fileData.append('file', data.selectedFile);
                fileData.append('type', "disbursalUpload");
                fileData.append('fileName', data.fileName);

                let result = await disbursalService.uploadDisbursalExcel(fileData);
                data.uploadType = "";
                data.selectedFile = null;
                data.fileName = "";
                data.loaded = 0;
                if (result.success) {
                    await this.getLoansList();
                    this.setState({ data, errors, successMsg: result.message, showSpinner: false });
                } else {
                    errors["globalError"] = result.message;
                    this.setState({ data, errors, successMsg: null, showSpinner: false });
                }
            } else {
                errors["uploadFile"] = "Please Select File";
                this.setState({ data, errors, successMsg: null, showSpinner: false });
            }

        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, successMsg: null, showSpinner: false });
        }
    };

    handleDisburseLoans = async (e) => {
        const selectedLoans = this.state.selectedLoans;
        const loansList = this.state.loansList;
        const data = { ...this.state.data }
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        errors["globalError"] = null;
        errors["paymentMode"] = null;
        //console.log(data);
        if (selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        } else {
            for (let i = 0; i < selectedLoans.length; i++) {
                const loan = loansList.filter((x) => x.loanNo == selectedLoans[i]);
                if (loan && loan.length > 0 && loan[0].loanStatus != "Ready For Disbursal") {
                    i = selectedLoans.length;
                    errors["globalError"] = "Only Ready For Disbursal loans are allowed for disbursal";
                    this.setState({ errors, showSpinner: false });
                }
            }
            if (errors["globalError"] == null) {
                let result = await disbursalService.disburseLoans(selectedLoans);
                //console.log(result);
                if (result.success) {
                    await this.getLoansList();
                    this.setState({ errors, showSpinner: false, successMsg: result.message });
                } else {
                    errors["globalError"] = result.message;
                    this.setState({ errors, showSpinner: false });
                }
            }

        }
    }

    handleDisburseLoansV2 = async (e) => {
        const selectedLoans = this.state.selectedLoans;
        const loansList = this.state.loansList;
        const data = { ...this.state.data }
        const errors = { ...this.state.errors };
        let disbursalType = this.state.showExport ? "Excel" : "";

        this.setState({ showSpinner: true });
        errors["globalError"] = null;
        errors["paymentMode"] = null;
        //console.log(data);
        if (selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        } else {
            for (let i = 0; i < selectedLoans.length; i++) {
                const loan = loansList.filter((x) => x.loanNo == selectedLoans[i]);
                if (loan && loan.length > 0 && loan[0].loanStatus != "Lender Assigned") {
                    i = selectedLoans.length;
                    errors["globalError"] = "Only Lender Assigned loans are allowed for disbursal";
                    this.setState({ errors, showSpinner: false });
                }
            }
            try {
                if (errors["globalError"] == null) {
                    let result = await disbursalService.disburseLoansV2(selectedLoans, disbursalType);
                    //console.log(result);
                    if (result.success) {
                        await this.getLoansList();
                        this.setState({ errors, showSpinner: false, successMsg: result.message });
                    } else {
                        errors["globalError"] = result.message;
                        this.setState({ errors, showSpinner: false });
                    }
                }
            }
            catch (ex) {
                errors["globalError"] = ex.response.data.message
                this.setState({ errors, showSpinner: false });
            }

        }
    }

    handleReDisburseLoans = async (e) => {
        const selectedLoans = this.state.selectedLoans;
        const loansList = this.state.loansList;
        const data = { ...this.state.data }
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        errors["globalError"] = null;
        errors["paymentMode"] = null;
        //console.log(data);
        if (selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        } else {
            // for (let i = 0; i < selectedLoans.length; i++) {
            //     const loan = loansList.filter((x) => x.loanNo == selectedLoans[i]);
            //     if (loan && loan.length > 0 && loan[0].loanStatus != "Lender Assigned") {
            //         i = selectedLoans.length;
            //         errors["globalError"] = "Only Lender Assigned loans are allowed for disbursal";
            //         this.setState({ errors, showSpinner: false });
            //     }
            // }
            try {
                if (errors["globalError"] == null) {
                    let result = await disbursalService.reDisburseV2(selectedLoans);
                    //console.log(result);
                    if (result.success) {
                        await this.getLoansList();
                        this.setState({ errors, showSpinner: false, successMsg: result.message });
                    } else {
                        errors["globalError"] = result.message;
                        this.setState({ errors, showSpinner: false });
                    }
                }
            }
            catch (ex) {
                errors["globalError"] = ex.response.data.message
                this.setState({ errors, showSpinner: false });
            }

        }
    }

    toggleChange = () => {
        let { isManual } = this.state;
        this.setState({ isManual: !isManual });
    };

    async openLoanDecision(loan) {
        const data = { ...this.state.data };
        this.setState({ showSpinner: true });
        try {
            this.setState({
                showSpinner: false,
                showLoanDecisionModal: true,
                loanId: loan.loanId,
            });
        } catch (ex) {
            this.setState({ data, showSpinner: false });
        }
    }
    closeModal = () => {
        this.setState({
            showLoanDecisionModal: false,
        });
    };

    toggleFilter = () => {
        let { showFilter } = this.state;
        this.setState({ showFilter: !showFilter });
    }

    filterStatus = () => {
        return (this.state.data["productId"] ||
            this.state.data["loanNo"] ||
            (this.state.data["partner"] != "all")) || (this.state.data["fromDate"] && this.state.data["toDate"])
    }

    dateStatus = () => {
        if ((this.state.data["fromDate"] && !this.state.data["toDate"]) || (!this.state.data["fromDate"] && this.state.data["toDate"])) {
            return false
        }
        return true
    }

    toggleDisbursalViaFile = () => {
        this.setState({ isDisbursalViaFile: !this.state.isDisbursalViaFile });
    };

    handleReset = () => {

        let data = this.state.data;

        data["productId"] = "";
        data["loanNo"] = "";
        data["partner"] = "all";
        data["fromDate"] = momentJalaali().startOf('month');
        data["toDate"] = momentJalaali();


        this.setState({ data: data }, () => {
            this.doSubmit();
            this.toggleFilter()
        });

    }

    handleChangeWithCallback = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);

        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        let showRedisburse = false;
        if (input.name == "status" && input.value == "Failed") {
            showRedisburse = true
        }

        let showExport = false;

        let lendingPartner = this.state.lendingPartnerHash[data.lendingPartner];
        if (lendingPartner && lendingPartner.disbursalMode == "MANUAL" && data.status == "Pending") {
            showExport = true;
        }


        this.setState({ data, errors, selectedLoans: [], showRediburse: showRedisburse, showExport: showExport }, () => {
            this.doSubmit();
        });
    }
}

export default DisbursalTableModel;