import React from "react";
import InvoiceDetailsModel from "../../models/invoice/invoiceDetailsModel";
import SpinnerComponent from "../../components/common/spinner";
import { Form, Col, Row, Button, Table } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import ReactExport from "react-export-excel";

class InvoiceDetails extends InvoiceDetailsModel {

    render() {
        const {
            data,
            staticData,
            errors,
            showSpinner,
            isSubmitted
        } = this.state;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <>
                {" "}
                <SpinnerComponent showSpinner={showSpinner} />
                <>
                    <br />
                    <h2
                        className="fundfinaColor"
                        style={{ textDecoration: "underline", textAlign: "center" }}
                    >
                        Invoice Details
                    </h2>{" "} <br />
                    <Form>
                        <Form.Group as={Row}>
                            <Form.Label column sm="4">
                                Invoice No:{" "}
                                <span style={{ color: "black" }}>{staticData.invoiceDetails.invoiceNo}</span>
                            </Form.Label>
                            <Form.Label column sm="4">
                                Invoice Date:{" "}
                                <span style={{ color: "black" }}>
                                    {staticData.invoiceDetails.invoiceDate}
                                </span>
                            </Form.Label>
                            <Form.Label column sm="4">
                                Invoice Type:{" "}
                                <span style={{ color: "black" }}>
                                    {staticData.invoiceDetails.invoiceType}
                                </span>
                            </Form.Label>
                        </Form.Group>{" "}
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm="4">
                                Billed To:{" "}
                                <span style={{ color: "black" }}>
                                    {staticData.invoiceDetails.billedTo}
                                </span>
                            </Form.Label>
                            <Form.Label column sm="4">
                                Billed By:{" "}
                                <span style={{ color: "black" }}>
                                    {staticData.invoiceDetails.billedBy}
                                </span>
                            </Form.Label>
                            <Form.Label column sm="4">
                                Invoice Amount:{" "}
                                <span style={{ color: "black" }}>
                                    <NumberFormat value={staticData.invoiceDetails.totalInvoiceAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                </span>
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="name">
                            <Form.Label column sm="4">
                                Invoice Status:{" "}
                                <span style={{ color: "black" }}>
                                    {staticData.invoiceDetails.status}
                                </span>
                            </Form.Label>
                            {staticData.invoiceDetails.filePath && (
                                <Form.Label column sm="4">
                                    Invoice File:{" "}

                                    <a
                                        href="#"
                                        onClick={() => this.openInvoiceFileAsTab(staticData.invoiceDetails.filePath)}
                                    >
                                        {" "}
                                        View
                                    </a>

                                </Form.Label>
                            )}
                        </Form.Group>
                    </Form>
                    <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                    >
                        Invoice Line Items
                    </h5>{" "}

                    <div className="row">
                        <div className="col-md-12">
                            <ExcelFile
                                element={
                                    <Button
                                        className="ff-button"
                                        type="button"
                                        style={{ marginTop: 10, float: "right" }}
                                    >
                                        Export to Excel
                                    </Button>
                                }
                            >
                                <ExcelSheet data={staticData.itemList} name="InvoiceLineItems">
                                    <ExcelColumn
                                        label="Sr No."
                                        value="srNo"
                                    ></ExcelColumn>
                                    <ExcelColumn
                                        label="Type"
                                        value="type"
                                    ></ExcelColumn>
                                    <ExcelColumn
                                        label="Loan No"
                                        value="loanNo"
                                    ></ExcelColumn>
                                    <ExcelColumn
                                        label="Amount"
                                        value="amount"
                                    ></ExcelColumn>
                                    <ExcelColumn
                                        label="GST"
                                        value="gst"
                                    ></ExcelColumn>
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    </div>
                    <br />
                    {staticData.itemList.length > 0 && (
                        <div style={{ width: '80% !important' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <td style={{ width: "10%", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Sr No.</b></td>
                                        <td style={{ width: "20%", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Type</b></td>
                                        <td style={{ width: "20%", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "right" }}><b>Loan No.</b></td>
                                        <td style={{ width: "20%", textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Amount</b></td>
                                        <td style={{ width: "20%", textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>GST</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {staticData.itemList.length > 0 &&
                                        staticData.itemList.map((invoiceItem, i) => (
                                            <tr key={i}>
                                                <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{i + 1}</td>
                                                <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{invoiceItem.description}</td>
                                                <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{invoiceItem.details.loanNo}</td>
                                                <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                    <NumberFormat value={invoiceItem.details.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                                </td>
                                                <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                    <NumberFormat value={invoiceItem.details.gst} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                                </td>
                                            </tr>
                                        ))}
                                    <tr >
                                        <td style={{ borderTop: "1px solid" }}>

                                        </td>
                                        <td style={{ borderTop: "1px solid" }}>

                                        </td>
                                        <td style={{ textAlign: "right", fontWeight: "bold", borderTop: "1px solid" }}>
                                            Total
                                        </td>
                                        <td style={{ textAlign: "right", fontWeight: "bold", borderTop: "1px solid" }}>
                                            <NumberFormat value={staticData.invoiceDetails.totalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </td>
                                        <td style={{ textAlign: "right", fontWeight: "bold", borderTop: "1px solid" }}>
                                            <NumberFormat value={staticData.invoiceDetails.totalTaxAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </td>
                                    </tr>
                                    {staticData.itemList.length === 0 && (
                                        <tr>
                                            <td colSpan={10} style={{ border: "1px solid" }}>No record found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div >)}
                </>
                <>
                    {this.state.evaluatorRole === "Admin" && staticData.invoiceDetails.status === "Pending For Approval" && (
                        <Form onSubmit={this.doSubmit}>
                            <h5
                                className="fundfinaColor"
                                style={{ textDecoration: "underline" }}
                            >
                                Action
                            </h5>
                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="status">
                                    <Form.Label>Select Action*</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="status"
                                        value={data["status"]}
                                        onChange={this.handleChange}
                                    >
                                        <option key="select" value="">
                                            ---Select---
                                        </option>
                                        <option key="approved" value="APPROVED">
                                            Approve
                                        </option>
                                        <option key="rejected" value="REJECTED">
                                            Reject
                                        </option>
                                    </Form.Control>
                                    {errors["status"] && (
                                        <div className="alert alert-danger">
                                            {errors["status"]}
                                        </div>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="comments">
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        name="comments"
                                        value={data["comments"]}
                                        onChange={this.handleChange}
                                    />
                                    {errors["comments"] && (
                                        <div className="alert alert-danger">
                                            {errors["comments"]}
                                        </div>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Button
                                className="ff-button"
                                type="submit"
                                disabled={isSubmitted}
                            >
                                Submit
                            </Button>
                            {
                                errors["globalError"] && (
                                    <div className="alert alert-danger" style={{ marginTop: 10 }}>
                                        {errors["globalError"]}
                                    </div>
                                )
                            }
                        </Form >)
                    }
                </>
                {/* <>
                    {this.state.evaluatorRole === "Admin" && staticData.invoiceDetails.status === "Sent"
                        && (staticData.invoiceDetails.invoiceType === "Disbursal Charges" ||
                            staticData.invoiceDetails.invoiceType === "Collection Charges") && (
                            <Form onSubmit={this.doSubmit}>
                                <h5
                                    className="fundfinaColor"
                                    style={{ textDecoration: "underline" }}
                                >
                                    Action
                                </h5>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="status">
                                        <Form.Label>Select Action*</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="status"
                                            value={data["status"]}
                                            onChange={this.handleChange}
                                        >
                                            <option key="select" value="">
                                                ---Select---
                                            </option>
                                            <option key="approved" value="INITIATE PAYMENT">
                                                Initiate Payment
                                            </option>
                                        </Form.Control>
                                        {errors["status"] && (
                                            <div className="alert alert-danger">
                                                {errors["status"]}
                                            </div>
                                        )}
                                    </Form.Group>
                                </Form.Row>
                                <Button
                                    className="ff-button"
                                    type="button"
                                    disabled={isSubmitted}
                                    onClick={this.initiatePayment}
                                >
                                    Submit
                                </Button>
                                {
                                    errors["globalError"] && (
                                        <div className="alert alert-danger" style={{ marginTop: 10 }}>
                                            {errors["globalError"]}
                                        </div>
                                    )
                                }
                            </Form >)
                    }
                </> */}
            </>
        );
    }
}

export default InvoiceDetails;
