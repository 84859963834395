import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SharedCache from "./SharedCache";
import { apiUrl, encryptionKey } from "./config.json";
import Login from './views/login/login';
import http from "./services/common/httpService";
import auth from "./services/common/authService";
import UserContext from "./context/userContext";

const apiEndpoint = apiUrl + "/ops/auth";

export default class AuthenticateRoute extends React.Component {


    state = {
        isLoading: true
    }

    constructor(props) {



        super(props)
        this.isAuthenticated();
    }

    isAuthenticated() {

        let user = auth.getCurrentUser();
        if (!user) {
            http.get(apiEndpoint + "/userdata").then(async (res) => {

                SharedCache.init(res.data);
                // let user = auth.getCurrentUser();
                // let partner = auth.getCurrentPartner();
                // let hideFFLogo = auth.getFFLogoDisplay();

                // const menu = await auth.getMenu(user ? user.role : "Guest"); 

                this.setState({
                    isLoading: false
                });

            }).catch(err => {
                this.setState({ isLoading: false });
            })
        }


    }

    componentDidMount() {


    }

    render() {
        let isAuthenticated = SharedCache.get('user') ? true : false;
        let redirectPath = `rd=${window.location.pathname}`
        return (

            <div>
                {this.state.isLoading && <div>Loading...</div>}
                {!this.state.isLoading && isAuthenticated && <Route {...this.props} component={this.props.component} />}
                {!this.state.isLoading && !isAuthenticated && <Redirect to={`/login?${redirectPath}`} />}

            </div>
        );
    }
}