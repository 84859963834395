import React from "react";
import PartnerKycFormModel from "../../models/partner/partnerKycFormModel";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, ListGroup, Image } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import FileSizeText from "../../components/common/file-size";

class PartnerKycForm extends PartnerKycFormModel {

  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    const {
      data,
      errors,
      files,
      fileErrors,
      showSpinner,
      stateOptions,
      cityOptions,
      ifscOptions,
      bankOptions,
      branchOptions,
      optional,
      optionalErrors,
      showBankSearch,
      showIfscSearch,
      bankList,
      isBankSelected,
      isDisabled,
    } = this.state;

    const inputIfscProps = {
      placeholder: "Enter your IFSC code",
      value: data["ifsc"],
      className: "form-control",
      onChange: this.onIfscChange,
      disabled: isDisabled
    };
    const inputBankProps = {
      placeholder: "Enter your bank",
      value: optional["searchBankName"],
      className: "form-control",
      onChange: this.onBankChange,
    };
    const inputBranchProps = {
      placeholder: "Enter your branch",
      value: optional["searchBranchName"],
      className: "form-control",
      onChange: this.onBranchChange,
    };
    const inputStateProps = {
      placeholder: "Enter your state",
      value: data["state"],
      className: "form-control",
      onChange: this.onStateChange,
    };
    const inputCityProps = {
      placeholder: "Enter your city",
      value: data["city"],
      className: "form-control",
      onChange: this.onCityChange,
    };

    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>Partner KYC</h2>{" "}
        <Form
          onSubmit={this.handleSubmit}
          autoCapitalize="on"
          autoComplete="off"
        >
          {data["kycStatus"] && (
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="entityName">
                <Form.Label>Current KYC Status: </Form.Label>
                {data["kycStatus"] && data["kycStatus"] === "Approved" && (
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    {" "}
                    {data["kycStatus"]}
                  </span>
                )}
                {data["kycStatus"] && data["kycStatus"] === "Under Review" && (
                  <span style={{ color: "black", fontWeight: "bold" }}>
                    {" "}
                    {data["kycStatus"]}
                  </span>
                )}
                {data["kycStatus"] &&
                  (data["kycStatus"] === "Rejected" ||
                    data["kycStatus"] === "Review") && (
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {" "}
                      {data["kycStatus"]}
                    </span>
                  )}
              </Form.Group>
            </Form.Row>)}
          {data["kycRemarks"] &&
            data["kycStatus"] !== "Approved" &&
            data["kycStatus"] !== "Under Review" && (
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="entityName">
                  <Form.Label>Admin Remarks: </Form.Label>

                  <span> {data["kycRemarks"]}</span>
                </Form.Group>
              </Form.Row>
            )}
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="entityName">
              <Form.Label>Entity Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter entity name"
                name="entityName"
                value={data["entityName"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                disabled={true}
              />
              {errors["entityName"] && (
                <div className="alert alert-danger">{errors["entityName"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="add1">
              <Form.Label>Address Line 1*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address line 1"
                name="add1"
                value={data["add1"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
              {errors["add1"] && (
                <div className="alert alert-danger">{errors["add1"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="add2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address line 2"
                name="add2"
                value={data["add2"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
              {errors["add2"] && (
                <div className="alert alert-danger">{errors["add2"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="add3">
              <Form.Label>Address Line 3*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your address line 3"
                name="add3"
                value={data["add3"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
              {errors["add3"] && (
                <div className="alert alert-danger">{errors["add3"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="state">
              <Form.Label>State*</Form.Label>
              <Autosuggest
                suggestions={stateOptions}
                onSuggestionsFetchRequested={this.onStateSuggestionsRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionsClearRequested={
                  this.onStateSuggestionsClearRequested
                }
                inputProps={inputStateProps}
                style={{ textTransform: "uppercase" }}
              />
              {errors["state"] && (
                <div className="alert alert-danger">{errors["state"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="city">
              <Form.Label>City*</Form.Label>
              <Autosuggest
                suggestions={cityOptions}
                onSuggestionsFetchRequested={this.onCitySuggestionsRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionsClearRequested={
                  this.onCitySuggestionsClearRequested
                }
                inputProps={inputCityProps}
                style={{ textTransform: "uppercase" }}
              />
              {errors["city"] && (
                <div className="alert alert-danger">{errors["city"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="pincode">
              <Form.Label>Pincode*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your pincode"
                name="pincode"
                value={data["pincode"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                maxLength="6"
              />
              {errors["pincode"] && (
                <div className="alert alert-danger">{errors["pincode"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="panNo">
              <Form.Label>PAN Number*</Form.Label>
              <Form.Control
                type="panNo"
                placeholder="Enter your PAN number"
                name="panNo"
                value={data["panNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="10"
                onBlur={this.handlePANChange}
                style={{ textTransform: "uppercase" }}
                disabled={isDisabled}
              />
              {errors["panNo"] && (
                <div className="alert alert-danger">{errors["panNo"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="panFile">
              <Form.Group>
                <Form.Label>Upload PAN Image*</Form.Label>
                {
                  !files["panFile"] ?
                    <>
                      <Form.File
                        label={files["panFileLabel"]}
                        onChange={e => this.onFileChange(e, false, "", true)}
                        id="panFile"
                        name="panFile"
                        accept=".jpg,.png,.jpeg,.pdf"
                        custom
                        disabled={isDisabled}
                      />
                      {
                        fileErrors["panFile"] ?
                          <div className="alert alert-danger">
                            {fileErrors["panFile"]}
                          </div> :
                          // <FileSizeText /> 
                          ""
                      }
                    </> :
                    <div className="position-relative" >
                      {this.renderThumbnail(files.panFileStream || files.panFileSrc)}
                      {!isDisabled ? this.renderThumbnailAction("panFile", files.panFileStream || files.panFileSrc) : ""}
                    </div>
                }

                {" "}
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="udyogAadhaarNo">
              <Form.Label>Udyog Aadhaar Number</Form.Label>
              <Form.Control
                type="udyogAadhaarNo"
                placeholder="Enter your Udyog Number"
                name="udyogAadhaarNo"
                value={data["udyogAadhaarNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
                maxLength="30"
                onBlur={this.handleUdyogAadhaarChange}
                disabled={isDisabled}
              />
              {errors["udyogAadhaarNo"] && (
                <div className="alert alert-danger">
                  {errors["udyogAadhaarNo"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="udyogAadhaarFile">
              <Form.Group>
                <Form.Label>Upload Udyog Aadhaar Image</Form.Label>
                {
                  !files["udyogAadhaarFile"] ? <>
                    <Form.File
                      label={files["udyogAadhaarFileLabel"]}
                      onChange={(e) => this.onFileChange(e, true, "UDYOG_AADHAAR", true)}
                      id="udyogAadhaarFile"
                      name="udyogAadhaarFile"
                      accept=".jpg,.png,.jpeg,.pdf"
                      custom
                      disabled={isDisabled}
                    />
                    {
                      fileErrors["udyogAadhaarFile"] ? (
                        <div className="alert alert-danger">
                          {fileErrors["udyogAadhaarFile"]}
                        </div>
                      ) :
                        // <FileSizeText />
                        ""
                    }
                  </>
                    :
                    <div className="position-relative" >
                      {this.renderThumbnail(files.udyogAadhaarFileSrc)}
                      {!isDisabled ? this.renderThumbnailAction("udyogAadhaarFile", files.udyogAadhaarFileSrc) : ""}
                    </div>
                }

              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="gstNo">
              <Form.Label>GST Number</Form.Label>
              <Form.Control
                type="gstNo"
                placeholder="Enter your GST number"
                name="gstNo"
                value={data["gstNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                onBlur={this.handleGSTChange}
                style={{ textTransform: "uppercase" }}
                disabled={isDisabled || this.state.isGstDisabled}
              />
              {errors["gstNo"] && (
                <div className="alert alert-danger">{errors["gstNo"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="gstFile">
              <Form.Group>
                <Form.Label>Upload GST Image</Form.Label>
                {
                  !files["gstFile"] ?
                    <>

                      <Form.File
                        label={files["gstFileLabel"]}
                        onChange={(e) => this.onFileChange(e, true, "GST", true)}
                        id="gstFile"
                        name="gstFile"
                        accept=".jpg,.png,.jpeg,.pdf"
                        custom
                        disabled={isDisabled}
                        style={files["gstFile"] ? { display: "none" } : {}}
                      />

                      {fileErrors["gstFile"] ? (
                        <div className="alert alert-danger">
                          {fileErrors["gstFile"]}
                        </div>
                      ) :
                        // <FileSizeText />
                        ""
                      }
                    </> :
                    <div className="position-relative" >
                      {this.renderThumbnail(files.gstFileSrc)}
                      {!isDisabled ? this.renderThumbnailAction("gstFile", files.gstFileSrc) : ""}
                    </div>
                }
                {" "}
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="accountNo">
              <Form.Label>Bank Account No.*</Form.Label>
              <Form.Control
                type="accountNo"
                placeholder="Enter your bank account number"
                name="accountNo"
                value={data["accountNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                disabled={isDisabled}
                style={{ textTransform: "uppercase" }}
              />
              {errors["accountNo"] && (
                <div className="alert alert-danger">{errors["accountNo"]}</div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="ifsc">
              <Form.Label>IFSC Code*</Form.Label>
              <Autosuggest
                suggestions={ifscOptions}
                onSuggestionsFetchRequested={this.onIfscSuggestionsRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionsClearRequested={
                  this.onIfscSuggestionsClearRequested
                }
                inputProps={inputIfscProps}
                style={{ textTransform: "uppercase" }}
                disabled={isDisabled}
              />{" "}
              {errors["ifsc"] && (
                <div className="alert alert-danger">{errors["ifsc"]}</div>
              )}
              {!isDisabled && <a
                href="#"
                onClick={this.showBankSearch}
                style={{ float: "right", textDecoration: "underline" }}
              >
                Search Ifsc Code
              </a>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="accountType">
              <Form.Label>Account Type*</Form.Label>
              <Form.Control
                as="select"
                name="accountType"
                value={data["accountType"]}
                onChange={this.handleChange}
                disabled={isDisabled}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="current" value="Current">
                  Current
                </option>{" "}
                <option key="escrow" value="Escrow">
                  Escrow
                </option>
                <option key="nodal" value="Nodal">
                  Nodal
                </option>
              </Form.Control>

              {errors["accountType"] && (
                <div className="alert alert-danger">
                  {errors["accountType"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="accountName">
              <Form.Label>Account Holder Name*</Form.Label>
              <Form.Control
                type="accountName"
                placeholder="Enter account holder name"
                name="accountName"
                value={data["accountName"]}
                onChange={this.handleChange}
                autoComplete="off"
                disabled={isDisabled}
                style={{ textTransform: "uppercase" }}
              />
              {errors["accountName"] && (
                <div className="alert alert-danger">{errors["accountName"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Button
            className="ff-button"
            type="submit"
            disabled={data.isSubmitted}
          >
            Submit
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
        <Modal show={showBankSearch} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Search IFSC Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Form style={{ width: "100%" }}>
              <Form.Row>
                <Form.Group as={Col} md="12" controlId="searchBankName">
                  <Form.Label>Bank Name*</Form.Label>
                  <Autosuggest
                    suggestions={bankOptions}
                    onSuggestionsFetchRequested={
                      this.onBankSuggestionsRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionsClearRequested={
                      this.onBankSuggestionsClearRequested
                    }
                    inputProps={inputBankProps}
                    autoCapitalize="words"
                  />
                  {optionalErrors["searchBankName"] && (
                    <div className="alert alert-danger">
                      {optionalErrors["searchBankName"]}
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                {" "}
                <Form.Group as={Col} md="12" controlId="searchBranchName">
                  <Form.Label>Branch Name*</Form.Label>
                  <Autosuggest
                    suggestions={branchOptions}
                    onSuggestionsFetchRequested={
                      this.onBranchSuggestionsRequested
                    }
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionsClearRequested={
                      this.onBranchSuggestionsClearRequested
                    }
                    inputProps={inputBranchProps}
                    autoCapitalize="words"
                  />
                  {optionalErrors["searchBranchName"] && (
                    <div className="alert alert-danger">
                      {optionalErrors["searchBranchName"]}
                    </div>
                  )}
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" type="button" onClick={this.getIfscList}>
              Search
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showIfscSearch} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Select Bank</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {bankList.length > 0 && (
              <ListGroup>
                {bankList.map((item) => (
                  <ListGroup.Item
                    key={item.ifsc}
                    action
                    onClick={() => this.onBankSelect(item.ifsc)}
                  >
                    IFSC Code: {item.ifsc} <br />
                    Bank: {item.bank} <br />
                    Branch: {item.branch}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
            {bankList.length === 0 && <p>No data found for your search</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.handleClose}
              disabled={!isBankSelected}
            >
              Select
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PartnerKycForm;
