import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, FormCheck } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import CollectionsQueueModel from './../../models/collectionsQueue/collectionsQueueModel';
import ReactExport from "react-export-excel";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import EmiList from "../common/emiList";
import { getMobileNumberArray } from "../../utils/common-utils";

class CollectionsQueue extends CollectionsQueueModel {
    getPagedData = () => {
        const { pageSize, currentPage, data, allLoans } = this.state;

        let emiList = data.emiList;

        // const filteredLoans = paginate(emiList, currentPage, pageSize);

        return { totalCount: emiList.length, data: emiList, allLoans };
    };

    exportExcel = () => {

        const { allLoans } = this.getPagedData();

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return <ExcelFile
            hideElement={true}
            // element={
            //     <Button
            //         className="ff-button"
            //         type="button"
            //         style={{ marginTop: 10 }}
            //     >
            //         Export to Excel
            //     </Button>
            // }
            filename={`CollectionsQueue_${moment(new Date()).format("DDMMYYYY")}`}
        >
            <ExcelSheet data={allLoans} name="collectionQueue">
                <ExcelColumn
                    label="Loan No."
                    value="loanNo"
                ></ExcelColumn>
                <ExcelColumn
                    label="EMI No."
                    value={(col) =>
                        col.emi.emiNumber
                            ? col.emi.emiNumber
                            : ""
                    }
                ></ExcelColumn>
                <ExcelColumn
                    label="Name"
                    value="name"
                ></ExcelColumn>

                <ExcelColumn
                    label="Mobile Numbers"
                    value={(col) => col.mobileNumbers && col.mobileNumbers.length ? col.mobileNumbers.join() : ""}
                ></ExcelColumn>

                <ExcelColumn
                    label="EMI Amount"
                    value={(col) =>
                        col.emi.rePaymentAmount
                            ? col.emi.rePaymentAmount
                            : ""
                    }
                ></ExcelColumn>
                <ExcelColumn
                    label="Repayment Date"
                    value={(col) =>
                        col.emi.rePaymentDate
                            ? moment(col.emi.rePaymentDate).format("DD/MM/yyyy")
                            : ""
                    }
                ></ExcelColumn>
                <ExcelColumn
                    label="Loan Amount"
                    value="amount"
                ></ExcelColumn>
                <ExcelColumn
                    label="Total Outstanding"
                    value="outstandingAmount"
                ></ExcelColumn>
                <ExcelColumn
                    label="Reason for failure"
                    value="failure_reason"
                ></ExcelColumn>
                <ExcelColumn
                    label="City"
                    value="city"
                ></ExcelColumn>
                <ExcelColumn
                    label="State"
                    value="state"
                ></ExcelColumn>
                <ExcelColumn
                    label="Pincode"
                    value="pincode"
                ></ExcelColumn>
            </ExcelSheet>
        </ExcelFile>
    }

    render() {
        const {
            pageSize,
            currentPage,
            data,
            showSpinner,
            partnerList,
            showRepaymentModal,
            loanNo,
            showErrorModal,
            errorMessage,
            errorHeader,
            sortBy,
            loanId,
            lendingPartnerList
        } = this.state;

        const { totalCount, data: emiList, allLoans } = this.getPagedData();

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        const accountCollectedLabel = (
            <React.Fragment>
                Collected
            </React.Fragment>
        );

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>Collection's Cases EMI View</h2>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="partnerList">
                            <Form.Label>Partner</Form.Label>
                            <Form.Control
                                as="select"
                                name="partnerId"
                                value={data["partnerId"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {partnerList.map((item, i) => (
                                    <option key={i} value={item._id}>
                                        {item.displayName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="loanNo">
                            <Form.Label>Loan No.</Form.Label>
                            <Form.Control
                                type="text"
                                name="loanNo"
                                value={data["loanNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}

                        <Form.Group as={Col} md="4" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={data["name"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}


                    </Form.Row>
                    <Form.Row>

                        <Form.Group as={Col} md="4" controlId="mobileNo">
                            <Form.Label>Mobile No</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobileNo"
                                value={data["mobileNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="overdueBy">
                            <Form.Label>Overdue By</Form.Label>
                            <Form.Control
                                as="select"
                                name="overdueBy"
                                value={data["overdueBy"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="all" value="">
                                    All
                                </option>
                                <option key="a" value="a">
                                    1 - 7 days
                                </option>
                                <option key="b" value="b">
                                    8 - 15 days
                                </option>
                                <option key="c" value="c">
                                    16 - 30 days
                                </option>
                                <option key="d" value="d">
                                    31 - 60 days
                                </option>
                                <option key="e" value="e">
                                    61 - 90 days
                                </option>
                                <option key="f" value="f">
                                    91 - 120 days
                                </option>
                                <option key="g" value="g">
                                    More than 120 days
                                </option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="lendingPartnerList">
                            <Form.Label>Lending Partner</Form.Label>
                            <Form.Control
                                as="select"
                                name="lendingPartnerId"
                                value={data["lendingPartnerId"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="partner-select" value="">
                                    ---Select---
                                </option>
                                {lendingPartnerList && lendingPartnerList.map((item, i) => (
                                    <option key={i} value={item._id}>
                                        {item.displayName}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} md="4">
                            <Button
                                className="ff-button"
                                type="submit"
                                style={{ marginTop: 30 }}
                            >
                                Apply Filters
                            </Button>

                            {/* <Button
                                className="ff-button"
                                type='button'
                                style={{ marginTop: 30, marginLeft: 10 }}
                                onClick={() => !this.state.showSpinner && this.downloadExcel()}
                            >
                                Export to Excel
                            </Button>
                            {this.state.downloadAll && this.exportExcel()} */}
                        </Form.Group>
                    </Form.Row>
                </Form>
                {/* <div className="row">
                    <div className="col-md-10">
                        <ExcelFile
                            element={
                                <Button
                                    className="ff-button"
                                    type="button"
                                    style={{ marginTop: 10 }}
                                >
                                    Export to Excel
                                </Button>
                            }
                            filename={`CollectionsQueue_${moment(new Date()).format("DDMMYYYY")}`}
                        >
                            <ExcelSheet data={allLoans} name="collectionQueue">
                                <ExcelColumn
                                    label="Loan No."
                                    value="loanNo"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="EMI No."
                                    value={(col) =>
                                        col.emi.emiNumber
                                            ? col.emi.emiNumber
                                            : ""
                                    }
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Name"
                                    value="name"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="EMI Amount"
                                    value={(col) =>
                                        col.emi.rePaymentAmount
                                            ? col.emi.rePaymentAmount
                                            : ""
                                    }
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Repayment Date"
                                    value={(col) =>
                                        col.emi.rePaymentDate
                                            ? moment(col.emi.rePaymentDate).format("DD/MM/yyyy")
                                            : ""
                                    }
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Loan Amount"
                                    value="amount"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Total Outstanding"
                                    value="outstandingAmount"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Reason for failure"
                                    value="failure_reason"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="City"
                                    value="city"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="State"
                                    value="state"
                                ></ExcelColumn>
                                <ExcelColumn
                                    label="Pincode"
                                    value="pincode"
                                ></ExcelColumn>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div> */}
                <br />
                <div>
                    <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0} rightStickyColumnCount={1} leftStickyColumnCount={2} >
                            <Row>
                                <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}> {sortBy === "loanNo" && (
                                    <i
                                        className="fa fa-sort-asc"
                                        aria-hidden="true"
                                        onClick={this.sortByLoanNo}
                                        style={{ cursor: "pointer" }}
                                    ></i>
                                )}
                                    {sortBy === "-loanNo" && (
                                        <i
                                            className="fa fa-sort-desc"
                                            aria-hidden="true"
                                            onClick={this.sortByLoanNo}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )}
                                    {sortBy !== "-loanNo" && sortBy !== "loanNo" && (
                                        <i
                                            className="fa fa-sort"
                                            aria-hidden="true"
                                            onClick={this.sortByLoanNo}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )} <b>Loan #</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}>{sortBy === "emiNumber" && (
                                    <i
                                        className="fa fa-sort-asc"
                                        aria-hidden="true"
                                        onClick={this.sortByEmiNumber}
                                        style={{ cursor: "pointer" }}
                                    ></i>
                                )}
                                    {sortBy === "-emiNumber" && (
                                        <i
                                            className="fa fa-sort-desc"
                                            aria-hidden="true"
                                            onClick={this.sortByEmiNumber}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )}
                                    {sortBy !== "-emiNumber" && sortBy !== "emiNumber" && (
                                        <i
                                            className="fa fa-sort"
                                            aria-hidden="true"
                                            onClick={this.sortByEmiNumber}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )} <b>EMI #</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 200 }}>
                                    {/* {sortBy === "name" && (
                                        <i
                                            className="fa fa-sort-asc"
                                            aria-hidden="true"
                                            onClick={this.sortByName}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )}
                                    {sortBy === "-name" && (
                                        <i
                                            className="fa fa-sort-desc"
                                            aria-hidden="true"
                                            onClick={this.sortByName}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )}
                                    {sortBy !== "-name" && sortBy !== "name" && (
                                        <i
                                            className="fa fa-sort"
                                            aria-hidden="true"
                                            onClick={this.sortByName}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )} */}
                                    <b>Name</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 250 }}><b>Mobile Number</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>EMI Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Other Charges</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Total EMI Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Repayment Date</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Loan Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Outstanding</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 150 }}><b>Reason For Failure</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>City</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>State</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Pincode</b></Cell>
                                {/* <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Last Transaction</b></Cell> */}
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 150 }}><b>Auto Collections</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Collected On</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 130 }}><b>Amount Collected</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 250 }}><b>eNach</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 100 }}><b>Mark</b></Cell>
                            </Row>
                            {emiList &&
                                emiList.map((emi, i) => (
                                    <Row key={i}>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>             <a
                                            key={emi._id}
                                            href="#"
                                            onClick={() => this.getRepaymentDetails(emi.loanId)}
                                        >
                                            {emi.loanNo}
                                        </a></Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.emi.emiNumber}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.name}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{emi.mobileNumbers && emi.mobileNumbers.length ? getMobileNumberArray(emi.mobileNumbers).join() : ""}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.emiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.otherCharges} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.totalEmiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {moment(emi.emi.rePaymentDate).format("DD/MM/yyyy")}
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <NumberFormat value={emi.outstandingAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} decimalScale="2" />
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {emi.failure_reason}
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {emi.city}
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {emi.state}
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {emi.pincode}
                                        </Cell>

                                        {/* <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {emi.lastTransaction}
                                        </Cell> */}

                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <Form>
                                                <Form.Group controlId={i}>
                                                    <FormCheck
                                                        id={"holdCollection_" + emi.emiId}
                                                        type="switch"
                                                        checked={emi.holdWalletCollection}
                                                        onChange={this.handleHoldCollection}
                                                        label="Hold"
                                                        name={"holdCollection_" + emi.emiId}
                                                        disabled={emi.primaryCollectionType === "eNach" ? true : false}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <DatePicker
                                                name={"collectedOn_" + emi.emiId}
                                                id={"collectedOn_" + emi.emiId}
                                                value={emi.collectedOn ? emi.collectedOn : null}
                                                max={moment()}
                                                inputFormat="DD/MM/YYYY"
                                                isGregorian={true}
                                                className="form-control"
                                                inputReadOnly={true}
                                                showTodayButton={false}
                                                timePicker={false}
                                                onChange={this.handleDateChange.bind(this, "collectedOn_" + emi.emiId)}
                                                disabled={i > 0 || currentPage != 1}
                                            />
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <Form>
                                                <Form.Group controlId={i}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label={accountCollectedLabel}
                                                        feedback="test"
                                                        checked={emi.amountCollected}
                                                        onChange={this.handleAmountChange}
                                                        color="red"
                                                        name={"amountCollected_" + emi.emiId}
                                                        disabled={i > 0 || currentPage != 1}
                                                    />{" "}
                                                </Form.Group>
                                            </Form>
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            {emi.primaryCollectionType === "eNach" && emi.isMandateRegistered && (
                                                <React.Fragment>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <Form.Control
                                                                as="select"
                                                                value={emi.eNachStatus}
                                                                onChange={this.handleEmiStatusChange}
                                                                name={"eNachSelect_" + emi.emiId}
                                                                style={{ maxWidth: 150, marginRight: 20 }}
                                                            >
                                                                {" "}
                                                                <option key="select" value="">
                                                                    ---Select---
                                                                </option>
                                                                <option key="resend" value="Send" disabled={emi.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                                                                    Resend
                                                                </option>
                                                                <option key="mandate" value="Mandate">
                                                                    New Mandate
                                                                </option>
                                                                <option key="bankDetails" value="BankDetails">
                                                                    Bank Details
                                                                </option>
                                                                {/* <option key="hold" value="Hold" disabled={emi.nachActionDisabled} style={{ backgroundColor: emi.nachActionDisabled ? "#E8E8E8" : "transparent" }}>
                                                                    Hold
                                                                </option> */}

                                                            </Form.Control>
                                                            <Button
                                                                className="ff-button"
                                                                type="button"
                                                                name={"btnNachSelect_" + emi.emiId}
                                                                onClick={this.eNachAction}
                                                                style={{ minWidth: 50 }}
                                                                disabled={emi.nachBtnDisabled}
                                                            >
                                                                Go
                                                            </Button></div></div></React.Fragment>)}
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>  <Button
                                            className="ff-button"
                                            type="button"
                                            name={"btnPaid_" + emi.emiId}
                                            onClick={this.handleMarkAsPaid}
                                            disabled={emi.disabled}
                                        >
                                            Paid
                                        </Button></Cell>
                                    </Row>))}
                        </StickyTable>
                    </div>
                </div>
                <div style={{ width: "100%", overflowX: "scroll" }}>
                    {/* <Pagination
                        itemsCount={totalCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                        onPagePrevious={this.handlePagePrevious}
                        onPageNext={this.handlePageNext}
                    /> */}
                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPagePrevious={this.onPagePrevious}
                        onPageNext={this.onPageNext}
                    />
                </div>
                <Modal
                    show={showRepaymentModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    aria-labelledby="example-modal-sizes-title-xl"
                    centered
                    style={{ width: "auto !important" }}
                >

                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 400, overflow: "scroll" }}>
                        <EmiList loanId={loanId}></EmiList>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showErrorModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <b>{errorHeader}</b>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} className="ff-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default CollectionsQueue;
