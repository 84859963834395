import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class LoanTableModel extends InputDataForm {
  state = {
    data: {
      loanStatus: "",
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      mobileNo: "",
      name: "",
      loanNo: "",
      loans: [],
      merchantCode: "",
      panNo: "",
      partner: "all",
      postDisbursalStatus: "",
      page: 1
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    loanStatusList: [],
    partners: [],
    evaluatorRole: "",
    selectedLoans: [],
    selectAll: false,
    downloadAll: false,
    excelList: []
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    mobileNo: Joi.any(),
    name: Joi.any(),
    loanStatus: Joi.any(),
    loanNo: Joi.any(),
    loans: Joi.any(),
    partner: Joi.any(),
    panNo: Joi.any(),
    merchantCode: Joi.any(),
    postDisbursalStatus: Joi.any(),
    page: Joi.any()
  };
  async loadDropdowns() {
    let { loanStatusList } = this.state;
    try {
      const {
        data: loanListResponse,
      } = await commonService.getRoleWiseDefinitionList("loanStatus");

      loanStatusList = loanListResponse.data;

      this.setState({
        loanStatusList,
      });
    } catch (ex) { }
  };
  async getLoanList() {

    this.state.data.page = this.state.currentPage

    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    errors["globalError"] = null;
    this.setState({ showSpinner: true, errors });

    try {
      const { data: loanList, totalPages } = await loanService.getLoanList(data);

      data.loans = loanList;

      this.setState({ data, showSpinner: false, pageSize: totalPages });
    } catch (ex) {
      this.setState({ data, showSpinner: false });
    }
  };

  async getPersonalLoanList() {

    this.state.data.page = this.state.currentPage

    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    errors["globalError"] = null;
    this.setState({ showSpinner: true, errors });

    try {
      const { data: loanList, totalPages } = await loanService.getPersonalLoanList(data);

      data.loans = loanList;

      this.setState({ data, showSpinner: false, pageSize: totalPages });
    } catch (ex) {
      this.setState({ data, showSpinner: false });
    }
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    let { data } = this.state;

    const currentUser = auth.getCurrentUser();

    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    if (this.props.location.state) {
      data.merchantCode = this.props.location.state.merchantCode;

      this.setState({ data });
      await this.getLoanList();
      await this.loadDropdowns();
      await this.getBorroingPartners();
    }
    else {
      await this.getLoanList();
      await this.loadDropdowns();
      await this.getBorroingPartners();
    }
  };
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };
  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getLoanList();
  };
  async handleSendReview(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendReview(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }
    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };
  async handleSendContract(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendLoanContract(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };
  async handSendBankLink(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendNewMandateOrUpdateBankListRequest(loanId, "BankDetails");

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };
  async regenerateLoanContract(loanNo) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      const response = await loanApplicationServices.regenerateLoanContract(loanNo);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };
  handleChecked = loanId => e => {
    const selectedLoans = this.state.selectedLoans;

    if (e.target.checked == false && selectedLoans.includes(loanId, 0)) {
      const index = selectedLoans.indexOf(loanId);
      if (index != -1) {
        selectedLoans.splice(index, 1);
      }
    } else if (e.target.checked == true && !selectedLoans.includes(loanId, 0)) {
      selectedLoans.push(loanId);
    }
    this.setState({ selectedLoans });
  }
  handleLoanStatusChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.value !== "" && input.value !== "Loan In Progress") {
      data["postDisbursalStatus"] = "";
    }
    this.setState({ data, errors });
  };
  handleLenderStateChange = async (state) => {
    const selectedLoans = this.state.selectedLoans;
    const data = { ...this.state.data }
    const errors = { ...this.state.errors };
    this.setState({ showSpinner: true });
    errors["globalError"] = null;

    if (selectedLoans.length === 0) {
      errors["globalError"] = "Please Select Loan";
      this.setState({ errors, showSpinner: false });
    } else {
      for (let i = 0; i < selectedLoans.length; i++) {
        const loan = data.loans.filter((x) => x._id == selectedLoans[i]);
        if (loan && loan.length > 0 && loan[0].status !== "Lender Approval") {
          i = selectedLoans.length;
          errors["globalError"] = "Please select loans in Lender Approval state";
          this.setState({ errors, showSpinner: false });
        }
      }
      try {
        if (errors["globalError"] == null) {
          let result = await loanService.updateLoanStatusByLender(state, selectedLoans, await commonService.getIpAddress());

          if (result.success) {
            await this.getLoanList()
            this.setState({ errors, showSpinner: false, successMsg: result.message, selectAll: false });
          } else {
            errors["globalError"] = result.message;
            this.setState({ errors, showSpinner: false });
          }
        }
      }
      catch (ex) {
        errors["globalError"] = ex.message;
        this.setState({ errors, showSpinner: false });
      }

    }
  }
  onPageNext = () => {
    this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getLoanList();
    })
  }

  onPageChange = (page) => {
    this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

    this.setState({ currentPage: page }, () => {
      this.getLoanList();
    })
  }

  onPagePrevious = () => {
    this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getLoanList();
    })
  }

  resetPage = () => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 0 })
  }
  async downloadLoanList() {
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };

    this.setState({ showSpinner: true });

    try {
      const list = await loanService.downloadLoanList(data);

      if (list.success) {
        this.setState({ excelList: list.data, showSpinner: false }, () => {
          this.setState({ downloadAll: true }, () => {
            this.setState({ downloadAll: false })
          })
        });
      }
      else {
        errors["globalError"] = list.message;
        this.setState({ errors, showSpinner: false });
      }


    } catch (ex) {
      this.setState({ data, showSpinner: false });
    }
  }
}

export default LoanTableModel;
