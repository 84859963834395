import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ReportsJobListModel from "../../models/ReportsJobList/ReportsJobList";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';

class ReportsJobList extends ReportsJobListModel {

  render() {
    const {
      pageSize,
      currentPage,
      showSpinner,
      partners,
      successMsg,
      errors,
      data,
      transactionTypes,
      accounts,
      reportTypes,
      reportTypeMapper,
      reportJobsList
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Reports Job
        </h2>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="fromDate">
              <Form.Label>Partner</Form.Label>
              <Form.Control
                as="select"
                name="partner"
                value={data["partner"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="all" value="all">
                  ALL
                </option>
                {partners && partners.map((partner) => (
                  <option key={partner.partnerName} value={partner._id}>
                    {partner.displayName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="startDate">
              <Form.Label>From Date</Form.Label>
              <DatePicker
                name="startDate"
                id="startDate"
                value={data["startDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleValueFromDateChange(value)}
                placeholder="Enter From Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="endDate">
              <Form.Label>To Date</Form.Label>
              <DatePicker
                name="endDate"
                id="endDate"
                value={data["endDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleValueToDateChange(value)}
                placeholder="Enter To Date"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="type">
              <Form.Label>Report Type</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={data["type"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="all" value="all">
                  ALL
                </option>
                {reportTypes && reportTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4">


              <br />
              <Button
                className="ff-button mr-2 mt-2"
                type="submit"
              >
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>

        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        <div style={{ width: '100%' }}>
          <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
            <Row>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", borderLeft: "1px solid" }}><b>Report Type</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Partner Name</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>File Names</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Filters</b></Cell>
              {/* <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Action</b></Cell> */}
            </Row>
            {reportJobsList &&
              reportJobsList.map((item, i) => (
                <Row key={item.narration}>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid" }}>{item.event && reportTypeMapper[item.event] || item.event}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.partnerName || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.insertedOn && new Date(item.insertedOn).toLocaleDateString() || "-"}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    {item.files && item.files.length > 4 ?
                      <div>
                        {!item.expand ? [0, 1, 2, 3].map(value => (
                          <div key={item.files[value].fileName}>
                            <div className="row">
                              <div className="col-12">
                                <span>{item.files[value].fileName}</span>
                                <i className="fa fa-download ml-3 text-success cursor-pointer"   onClick={() => { !item.showLoading && this.downloadSingleFile(item.files[value], i) }}></i>
                              </div>
                            </div>
                          </div>
                        )) : item.files && item.files.map(value => (
                          <div key={value.fileName}>
                            <div className="row">
                              <div className="col-12">
                                <span>{value.fileName}</span>
                                <i className="fa fa-download ml-3 text-success cursor-pointer" onClick={() => { !item.showLoading && this.downloadSingleFile(value, i) }}></i>
                              </div>
                            </div>
                          </div>))
                        }

                        {!item.expand ? <span onClick={() => this.onClickMore(item, i)} className="text-primary cursor-pointer value-center"><small>Show More</small> <i className="fa fa-angle-down ml-1"></i></span> : <span onClick={() => this.onClickMore(item, i)} className="text-primary cursor-pointer value-center"><small>Show Less</small> <i className="fa fa-angle-up ml-1"></i></span>}

                      </div>
                      : item.files && item.files.map(value => (
                        <div key={value.fileName}>
                          <div className="row">
                            <div className="col-12">
                              <span>{value.fileName}</span>
                              <i className="fa fa-download ml-3 text-success cursor-pointer" onClick={() => { !item.showLoading && this.downloadSingleFile(value, i) }}></i>
                            </div>
                          </div>
                        </div>))}
                    {/* {item.files && item.files.length ? item.files.map(item => item.fileName).join() : "-"} */}
                  </Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{item.status}</Cell>
                  <Cell style={{ textAlign: "left", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}><pre>{item.meta && JSON.stringify(item.meta, null, 2)}</pre></Cell>
                  {/* <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    {item.files && item.files.length ? <Button
                      className="ff-button btn-sm"
                      type="submit"
                      onClick={() => { !item.showLoading && this.downloadFile(item, i) }}
                    >
                      {item.showLoading ? <i className="fa fa-spinner fa-spin"></i> : <><i class="fa fa-download"></i> Download</>}
                    </Button> : <></>}
                  </Cell> */}
                </Row>
              ))}
            {!reportJobsList || !reportJobsList.length && (
              <Row>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }} Col={5}>No record found</Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
              </Row>
            )}

          </StickyTable>
        </div >
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
        </div>
      </React.Fragment >
    );
  }
}

export default ReportsJobList;
