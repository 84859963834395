import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/";

export async function uploadProfilePic(files) {
  const response = await http.post(apiEndpoint + `users/uploadProfilePic`, {
    fileName: files.profileFile,
    fileStream: files.profileFileStream,
  });
  return response;
}
