import React from "react";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, ListGroup, FormCheck, Nav } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import UpdateBankDetailModel from "./../../models/profile/updateBankDetailModel";
import { isMobile } from 'react-device-detect';
import _ from "lodash";

class UpdateBankDetail extends UpdateBankDetailModel {

    getBankDetailChangeStatus() {
        let { data, backUpBankDetail } = this.state
        return (data?.accountName === backUpBankDetail?.accountName && data?.accountNo === backUpBankDetail?.accountNo && data?.ifsc === backUpBankDetail?.ifsc && data?.accountType === backUpBankDetail?.accountType)
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        
        const errors = this.validate();
        this.setState({ errors: errors || {} });
    
        const allErrors = await this.handleCustomErrors(errors || {});
        if (
          !_.isEmpty(allErrors) ||
          !_.isEmpty(this.state.fileErrors) ||
          !_.isEmpty(this.state.optionalErrors)
        )
          return;
        this.doSubmit();

        const searchParams = new URLSearchParams(this.props.location.search);
        const borrowParam = searchParams.get('borrow');
    
        if (borrowParam === 'true') {
          this.props.history.push('/borrow');
        }

      };

    render() {
        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        const {
            data,
            errors,
            showSpinner,
            ifscOptions,
            bankOptions,
            branchOptions,
            optional,
            optionalErrors,
            showBankSearch,
            showIfscSearch,
            bankList,
            isBankSelected,
            loggedInFrom,
            updateMessage,
            updateHeader,
            showSaveModal,
            isDisabled,
            isMandate,
            isForceMandate,
            mandateOptions,
            loanStatus,
            backUpBankDetail,
            kycStatus,
            allowBypassMandate,
            showSkipMandatePopUp,
            showNachErrorPopUp,
            nachError,
            isPhysicalMandateGenerated,
            isPhysicalNachDownloaded,
            files,
            fileErrors
        } = this.state;
        const inputIfscProps = {
            placeholder: "Enter your IFSC code",
            value: data["ifsc"],
            className: "form-control",
            onChange: this.onIfscChange,
            disabled: isDisabled
        };
        const inputBankProps = {
            placeholder: "Enter your bank",
            value: optional["searchBankName"],
            className: "form-control",
            onChange: this.onBankChange
        };
        const inputBranchProps = {
            placeholder: "Enter your branch",
            value: optional["searchBranchName"],
            className: "form-control",
            onChange: this.onBranchChange
        };

        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2 style={{ textAlign: "center" }}>Update Bank Details</h2> <br />
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="tsFromMonth" className="text-right">
                        <FormCheck
                            id="switchEnabled"
                            type="switch"
                            checked={!isDisabled}
                            onChange={this.toggleChange}
                            label="Allow Edit"
                        />
                    </Form.Group>
                </Form.Row>
                {/* } */}

                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="accountNo">
                            <Form.Label>Bank Account No.*</Form.Label>
                            <Form.Control
                                type="accountNo"
                                placeholder="Enter your bank account number"
                                name="accountNo"
                                value={
                                    data["accountNo"]
                                }
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                            {errors["accountNo"] && (
                                <div className="alert alert-danger">{errors["accountNo"]}</div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="ifsc">
                            <Form.Label>IFSC Code*</Form.Label>
                            <Autosuggest
                                suggestions={ifscOptions}
                                onSuggestionsFetchRequested={this.onIfscSuggestionsRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                onSuggestionsClearRequested={
                                    this.onIfscSuggestionsClearRequested
                                }
                                inputProps={inputIfscProps}
                                style={{ textTransform: "uppercase" }}
                            />{" "}
                            {errors["ifsc"] && (
                                <div className="alert alert-danger">{errors["ifsc"]}</div>
                            )}
                            {!isDisabled && (<a
                                href="#"
                                onClick={this.showBankSearch}
                                style={{ float: "right", textDecoration: "underline" }}
                            >
                                Search Ifsc Code
                            </a>)}
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="accountType">
                            <Form.Label>Account Type*</Form.Label>
                            <Form.Control
                                as="select"
                                name="accountType"
                                value={data["accountType"]}
                                onChange={this.handleChange}
                                disabled={isDisabled}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                <option key="savings" value="Savings">
                                    Savings
                                </option>
                                <option key="current" value="Current">
                                    Current
                                </option>
                            </Form.Control>

                            {errors["accountType"] && (
                                <div className="alert alert-danger">{errors["accountType"]}</div>
                            )}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="accountName">
                            <Form.Label>Account Holder Name.*</Form.Label>
                            <Form.Control
                                type="accountName"
                                placeholder="Enter account holder name"
                                name="accountName"
                                value={
                                    data["accountName"]
                                }
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                            {errors["accountName"] && (
                                <div className="alert alert-danger">{errors["accountName"]}</div>
                            )}
                        </Form.Group>
                    </Form.Row>


                    <Form.Row>
                        <Form.Group as={Col} md="8" className="my-auto pt-4">

                            {!isDisabled && (
                                <Button
                                    className="ff-button mr-2"
                                    type="submit"
                                    disabled={data.isSubmitted}
                                    style={{ width: isMobile ? "100%" : 150 }}
                                >Update</Button>

                            )}

                            {/* {
                                !isMandate && !isForceMandate && backUpBankDetail.accountNo && this.getBankDetailChangeStatus() && (loanStatus === "Loan Is Funding" || loanStatus === "Draft" || (loanStatus === "Under Review" && kycStatus === "Review")) ?
                                    <Button
                                        className="ff-button"
                                        onClick={this.onClickNext}
                                        disabled={data.isSubmitted}
                                        style={{ width: isMobile ? "100%" : 150 }}
                                    >Next</Button>
                                    : ""
                            } */}
                        </Form.Group>
                    </Form.Row>

                    {
                        // isDisabled && isMandate &&
                        // <Form.Row>
                        //     {mandateOptions.length > 0 && (
                        //         <Form.Group as={Col} md="12" className="pt-4">
                        //             {/* <Form.Label>Mandate Method.*</Form.Label> */}
                        //             <div>
                        //                 <div className="text-center">
                        //                     {
                        //                         mandateOptions.length ? mandateOptions.map(item => (
                        //                             <div className={isMobile ? "d-inline-block pr-2 cursor-pointer col-md-4" : "d-inline-block pr-2 cursor-pointer"}>

                        //                                 <Form.Check
                        //                                     type="radio"
                        //                                     id={item.title}
                        //                                     label={item.title}
                        //                                     value={item.value}
                        //                                     name="mandateType"
                        //                                     checked={data["mandateType"] === item.value ? "checked" : ""}
                        //                                     onClick={this.handleCustomChange}
                        //                                     onChange={this.handleCustomChange}
                        //                                 />
                        //                             </div>
                        //                         ))
                        //                             : ""
                        //                     }
                        //                 </div>

                        //                 <div className="pt-2 d-flex justify-content-center">
                        //                     <div className="d-inline-block">
                        //                         <div className="pt-2">
                        //                             {
                        //                                 data["mandateType"] === "E_SIGN" ?
                        //                                     <>
                        //                                         <div>
                        //                                             You will be redirected to eNACH screen and then to your bank's page to complete the process
                        //                                         </div>
                        //                                         <ol>
                        //                                             <li>Enter Bank details mapped to your Aadhaar</li>
                        //                                             <li>Enter Your <b>Aadhaar Number</b> </li>
                        //                                             <li> Enter <b>OTP</b> sent to Aadhaar Linked Mobile Number</li>
                        //                                         </ol>
                        //                                         <p>Please Note: Disbursal will happen post aadhaar mandate registration which could take upto 2-3 days.</p>
                        //                                     </> :
                        //                                     data["mandateType"] === "NET_BANKING" || data["mandateType"] === "DEBIT_CARD" ?
                        //                                         <>
                        //                                             <div>
                        //                                                 You will be redirected to eNACH screen and then to your bank's page to complete the process
                        //                                             </div>
                        //                                             <ol>
                        //                                                 <li> Enter Your <b>NetBanking</b> login details to complete the process</li>
                        //                                                 <li> Fundfina doesn't store any NetBanking details for any action</li>
                        //                                             </ol>
                        //                                             <div className="text-center pb-2"> ( OR ) </div>
                        //                                             <ol>
                        //                                                 <li>Enter Your <b> Debit Card and PIN </b> details to complete the process</li>
                        //                                                 <li>Fundfina doesn't store any Debit Card details for any action</li>
                        //                                             </ol>
                        //                                         </> :
                        //                                         data["mandateType"] === "PHYSICAL" ?
                        //                                             <>
                        //                                                 <ol>
                        //                                                     <li>Download Physical NACH document and take a printout</li>
                        //                                                 </ol>
                        //                                                 <p>Make sure while signing:</p>
                        //                                                 <ol>
                        //                                                     <li>Sign above where your name is printed</li>
                        //                                                     <li>Sign only at one place</li>
                        //                                                     <li>Signature should be <b>same as present in above bank acocunt</b></li>
                        //                                                     <li>Sign with dark ink pen so that it's properly visible</li>
                        //                                                 </ol>
                        //                                                 <p>Make sure while taking picture</p>
                        //                                                 <ol>
                        //                                                     <li>Ensure complete mandate part including <b>all 4 black borders</b> are in the photo</li>
                        //                                                     <li>Photo is clear and text is readable</li>
                        //                                                 </ol>
                        //                                                 <p>Please Note: Disbursal will happen post physical mandate registration which could take upto 5-7 days.</p>
                        //                                             </>
                        //                                             : ""

                        //                             }
                        //                         </div>
                        //                     </div>
                        //                 </div>
                        //                 {!isPhysicalMandateGenerated && (
                        //                     <div className="pt-4 px-0">

                        //                         <Form.Group as={Col} md="4" className="my-auto ">
                        //                             {
                        //                                 data["mandateType"] ?

                        //                                     <Button
                        //                                         className="ff-button"
                        //                                         type="submit"
                        //                                         disabled={data.isSubmitted}
                        //                                         style={{ width: 150, marginRight: 10 }}
                        //                                     >Next</Button>
                        //                                     : ""
                        //                             }
                        //                             {
                        //                                 allowBypassMandate ?

                        //                                     <Button
                        //                                         className="ff-button"
                        //                                         type="button"
                        //                                         disabled={data.isSubmitted}
                        //                                         style={{ width: 150 }}
                        //                                         onClick={this.handleOpenSkipMandatePopUp}
                        //                                     >Skip Mandate</Button>
                        //                                     : ""
                        //                             }
                        //                         </Form.Group>
                        //                     </div>
                        //                 )}
                        //             </div>
                        //         </Form.Group>
                        //     )}
                        //     {mandateOptions.length === 0 && allowBypassMandate && (
                        //         <Form.Group as={Col} md="12" className="pt-4">
                        //             {/* <Form.Label>Mandate Method.*</Form.Label> */}
                        //             <div>
                        //                 <div className="pt-2 d-flex justify-content-center">
                        //                     <div className="d-inline-block">
                        //                         <div>
                        //                             <b>Please Note: </b> <br />
                        //                             You can take loans only after you have submitted the NACH or PDC. <br />
                        //                             Current selected bank doesn't support NACH. <br />
                        //                             You can either change your bank or you will be asked to provide a Physical Nach or PDC post contract signing.
                        //                         </div>
                        //                     </div>
                        //                 </div>

                        //                 <div className="pt-4 px-0">
                        //                     <Form.Group as={Col} md="4" className="my-auto ">
                        //                         <Button
                        //                             className="ff-button"
                        //                             type="submit"
                        //                             disabled={data.isSubmitted}
                        //                             style={{ width: isMobile ? "100%" : 150 }}
                        //                         >Next</Button>
                        //                     </Form.Group>
                        //                 </div>
                        //             </div>
                        //         </Form.Group>)}
                        //     {data["mandateType"] === "PHYSICAL" && isPhysicalMandateGenerated && (
                        //         <>
                        //             {
                        //                 isPhysicalNachDownloaded ?

                        //                     <Form.Group as={Col} md="4" controlId="pNachFile">
                        //                         <Form.Label>Upload NACH Image*</Form.Label>
                        //                         {
                        //                             !files["pNachFile"] ?
                        //                                 <Form.File
                        //                                     label={files["pNachFileLabel"]}
                        //                                     onChange={(e) => this.onFileChange(e, false, "", true)}
                        //                                     id="pNachFile"
                        //                                     name="pNachFile"
                        //                                     accept=".jpg,.png,.jpeg"
                        //                                     custom
                        //                                 />
                        //                                 :
                        //                                 <div style={!files["pNachFile"] ? { display: "none" } : {}} className="position-relative" >
                        //                                     {this.renderThumbnail(files?.pNachFileStream)}
                        //                                     {this.renderThumbnailAction("pNachFile", files?.pNachFileStream)}
                        //                                 </div>}

                        //                         {" "}
                        //                         {fileErrors["pNachFile"] && (
                        //                             <div className="alert alert-danger">
                        //                                 {fileErrors["pNachFile"]}
                        //                             </div>
                        //                         )}
                        //                     </Form.Group>
                        //                     : ""
                        //             }
                        //         </>
                        //     )}
                        // </Form.Row>
                    }

                    {/* <Form.Row>
                        {isDisabled && data["mandateType"] === "PHYSICAL" && isPhysicalMandateGenerated && isPhysicalNachDownloaded && (
                            <div className="pt-0 px-0" style={{ paddingTop: "0px important" }}>
                                <Form.Group as={Col} md="4" className="my-auto " style={{ paddingLeft: 0 }}>
                                    <Button
                                        className="ff-button"
                                        type="button"
                                        style={{ width: 210 }}
                                        onClick={() => this.uploadPhysicalNachFile()}
                                    >Upload NACH Document</Button>
                                </Form.Group>
                                {data["mandateType"] === "PHYSICAL" && isPhysicalMandateGenerated && (

                                    <Nav.Item id="regenerateOTP">
                                        <Nav.Link
                                            className="underline"
                                            onClick={() => this.generatePhysicalNachFile()}
                                            style={{
                                                padding: 0,
                                                textDecoration: "underline"
                                            }}
                                        >
                                            Download mandate form
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </div>
                        )}

                    </Form.Row>
                    <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
                        {errors["globalError"] && (
                            <div className="alert alert-danger">{errors["globalError"]}</div>
                        )}
                    </Form.Group> */}
                </Form>
                <Modal show={showBankSearch} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Search IFSC Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {" "}
                        <Form style={{ width: "100%" }}>
                            <Form.Row>
                                <Form.Group as={Col} md="12" controlId="searchBankName">
                                    <Form.Label>Bank Name*</Form.Label>
                                    <Autosuggest
                                        suggestions={bankOptions}
                                        onSuggestionsFetchRequested={
                                            this.onBankSuggestionsRequested
                                        }
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        onSuggestionsClearRequested={
                                            this.onBankSuggestionsClearRequested
                                        }
                                        inputProps={inputBankProps}
                                        autoCapitalize="words"
                                    />
                                    {optionalErrors["searchBankName"] && (
                                        <div className="alert alert-danger">
                                            {optionalErrors["searchBankName"]}
                                        </div>
                                    )}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                {" "}
                                <Form.Group as={Col} md="12" controlId="searchBranchName">
                                    <Form.Label>Branch Name*</Form.Label>
                                    <Autosuggest
                                        suggestions={branchOptions}
                                        onSuggestionsFetchRequested={
                                            this.onBranchSuggestionsRequested
                                        }
                                        getSuggestionValue={this.getSuggestionValue}
                                        renderSuggestion={this.renderSuggestion}
                                        onSuggestionsClearRequested={
                                            this.onBranchSuggestionsClearRequested
                                        }
                                        inputProps={inputBranchProps}
                                        autoCapitalize="words"
                                    />
                                    {optionalErrors["searchBranchName"] && (
                                        <div className="alert alert-danger">
                                            {optionalErrors["searchBranchName"]}
                                        </div>
                                    )}
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="button" onClick={this.getIfscList}>
                            Search
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showIfscSearch} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select Bank</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {bankList.length > 0 && (
                            <ListGroup>
                                {bankList.map((item) => (
                                    <ListGroup.Item
                                        key={item.ifsc}
                                        action
                                        onClick={() => this.onBankSelect(item.ifsc)}
                                    >
                                        IFSC Code: {item.ifsc} <br />
                                        Bank: {item.bank} <br />
                                        Branch: {item.branch}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                        {bankList.length === 0 && <p>No data found for your search</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.handleClose}
                            disabled={!isBankSelected}
                        >
                            Select
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showSaveModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{updateHeader}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <b>{updateMessage}</b>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="ff-button" onClick={this.handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showSkipMandatePopUp}
                    onHide={this.handleCloseSkipMandatePopUp}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <b>   <i className="fa fa-exclamation-triangle fa-lg" aria-hidden="true" style={{ color: "red" }}></i>{" "}Please Note</b>
                    </Modal.Header>
                    <Modal.Body>
                        You can take loans only after you have submitted the NACH or PDC. If you're unable to complete eNACH please click on Paper NACH to proceed.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseSkipMandatePopUp} className="ff-button">
                            eNACH
                        </Button>
                        <Button onClick={this.onClickNext} className="ff-button">
                            Paper NACH / PDC
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showNachErrorPopUp}
                    onHide={this.handleCloseNachErrorPopUp}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <b>   <i className="fa fa-exclamation-circle fa-lg" aria-hidden="true" style={{ color: "red" }}></i>{" "}Error</b>
                    </Modal.Header>
                    <Modal.Body>
                        {nachError}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleCloseNachErrorPopUp} className="ff-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        );
    }
}

export default UpdateBankDetail;