import InputDataForm from "../../components/common/form";
import * as lenderPayoutService from "../../services/LenderPayout/LenderPayout";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import moment from "moment";
import { toast } from "react-toastify";

class LenderPayoutListModal extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      partnerId: ""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    partners: [],
    lendingPartners: [],
    evaluatorRole: "",
    tabs: [
      { title: "Pending", value: "PENDING" },
      { title: "Processing", value: "PROCESSING" },
      { title: "Completed", value: "COMPLETED" },
      { title: "Failed", value: "FAILED" }
    ],
    activeTab: "PENDING",
    payoutList: []
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    lendingPartner: Joi.any(),
    borrowingPartner: Joi.any(),
    page: Joi.any(),
    tab: Joi.any(),
    partnerId: Joi.any(),
    payoutList: Joi.any()
  };


  payoutReDirsburse = async (data) => {
    console.log(data);

    try {
      let payload = {
       transactionId : data.id
      }
      this.setState({ showSpinner: true });
      const payoutInitRes = await lenderPayoutService.lenderPayoutReDisburse(payload);
      this.setState({ showSpinner: false });
      this.getLenderPayoutList()
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  payoutInit = async (data) => {

    try {

      if (!data.transactionDate) {
        toast.error("Missing transactionDate")
        return
      }

      if (!data.partnerId) {
        toast.error("Missing partnerId");
        return;
      }

      let payload = {
        transactionDate: data.transactionDate,
        partnerId: data.partnerId
      }
      this.setState({ showSpinner: true });
      const payoutInitRes = await lenderPayoutService.lenderPayoutInit(payload);
      this.setState({ showSpinner: false });
      this.getLenderPayoutList()
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }
  async getLenderPayoutList() {
    const data = { ...this.state.data };

    if (!data.partnerId) {
      toast.error("Please Select Partner");
      return
    }
    this.setState({ showSpinner: true });
    try {
      data.tab = this.state.activeTab;

      if (data.fromDate)
        data.fromDate = moment(data.fromDate).format("MM/DD/YYYY");

      if (data.toDate)
        data.toDate = moment(data.toDate).format("MM/DD/YYYY");

      if (this.state.activeTab)
        data.status = this.state.activeTab

      const { data: transactionsList } = await lenderPayoutService.getLenderPayoutList(data);
      this.setState({ payoutList: transactionsList || [], showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false, payoutList: [] });
    }
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });
    await this.getLendingPartners();
  };

  handlePartnerChange = ({ currentTarget: input }) => {

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  async getLendingPartners() {
    try {
      const { data: partnerList } = await commonService.getLendingParterList();
      this.setState({ lendingPartners: partnerList });
    }
    catch (ex) {
      this.setState({ lendingPartners: [] });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    await this.getLenderPayoutList();
  };

  onChangeTab = (tab) => {
    const data = { ...this.state.data };
    data['tab'] = tab
    this.setState({ data, activeTab: tab }, () => {
      this.getLenderPayoutList()
    })
  }

  onChangeHeaderActiveTab = (tab) => {
    const data = { ...this.state.data };
    data['headerActiveTab'] = tab
    this.setState({ data, headerActiveTab: tab }, () => {

      if (tab == "Applications") {
        this.resetPage(() => {
          this.state.activeTab = "All"
          this.state.data.tab = "All"
          this.getLenderPayoutList()
        })
      }
    })
  }
}

export default LenderPayoutListModal;
