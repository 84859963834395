import React, { Component } from "react";
import InputDataForm from "../../components/common/form";
import _ from "lodash";
import { Redirect } from "react-router-dom";

class ScrutinyInfoRedirect extends InputDataForm {
    state = {
        properties: {
            userId: "",
            isPartnerUser: false,
            partnerId: "",
            merchantCode: ""
        },
        errors: {},
        info: {},
        redirect: false,
    };
    componentDidMount() {
        let userId = this.props.match.params.userId;
        let isPartnerUser = this.props.match.params.isPartnerUser;
        let partnerId = this.props.match.params.partnerId;
        let merchantCode = this.props.match.params.merchantCode;

        let properties = { ...this.state.properties };

        properties.userId = userId;
        properties.isPartnerUser = isPartnerUser;
        properties.partnerId = partnerId;
        properties.merchantCode = merchantCode;

        this.setState({ properties, redirect: true });
    }
    render() {
        const { properties, redirect } = this.state;
        return (
            redirect && (
                <Redirect
                    to={{
                        pathname: "/scrutinyInfo",
                        state: { userData: properties },
                    }}
                />
            )
        );
    }
}
export default ScrutinyInfoRedirect;
