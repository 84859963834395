import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col } from "react-bootstrap";
import userCentricDataModel from '../../models/userCentricData/userCentricDataModel'
import moment from "moment";
import LineChartComponent from "../../components/common/lineChart";
import SpinnerComponent from "../../components/common/spinner";
moment.locale('en')

class UserCentricData extends userCentricDataModel {

    renderIrregularPaymentDetail(detail) {
        let arr = [
            <div className="row">
                <div className="col text-center"><b>Loan No</b></div>
                <div className="col text-center">Days</div>
            </div>
        ]
        for (let key in detail) {
            arr.push(<div className="row pt-2">
                <div className="col text-center"><b>{key}</b></div>
                <div className="col text-center">{detail[key].totalDays}</div>
            </div>)
        }
        return arr
    }

    render() {

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={this.state.showSpinner || this.state.showSpinner1} />
                <div className="pb-4">
                    <div>
                        <div className="shadow-sm p-2 bg-white rounded border-lite">
                            <div className="row">
                                <div className="col text-center">
                                    <Form.Label> <i className="fa fa-calendar" /> Last Loan Disbursal Date</Form.Label>
                                    <p className="bold font-24">{this.state.lastDisbursalDate ? moment(this.state.lastDisbursalDate).format("DD MMM, yyyy") : "-"}</p>
                                </div>
                                <div className="col text-center">
                                    <Form.Label> <i className="fa fa-money" /> Last Loan Amount</Form.Label>
                                    <br />
                                    <p className="bold font-24">₹ {this.state.lastLoanAmount ? this.state.lastLoanAmount.toFixed(2) : 0}</p>
                                </div>
                            </div>
                        </div>

                        <div className="shadow-sm p-2 mt-4 bg-white rounded border-lite">
                            <div className="row">
                                <div className="col text-center">
                                    <Form.Label> Total Number of Active Loans</Form.Label>
                                    <p className="bold font-24">{this.state.totalActiveLoans || 0}</p>
                                </div>
                                <div className="col text-center">
                                    <Form.Label> <i className="fa fa-money" /> Total outstanding Amount</Form.Label>
                                    <br />
                                    <p className="bold font-24">₹ {this.state.totalActiveLoansOutstandingAmount ? this.state.totalActiveLoansOutstandingAmount.toFixed(2) : 0}</p>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.currentBorrowingPartner &&
                            <div className="shadow-sm p-2 mt-4 bg-white rounded border-lite">
                                <div className="text-center custom-tab"><span> <i>{this.state.currentBorrowingPartner?.partnerCode}</i></span></div>
                                <div className="row pt-2">
                                    <div className="col text-center">
                                        <Form.Label> Total Number of Active Loans</Form.Label>
                                        <p className="bold font-24">{this.state.currentBorrowingPartner?.activeLoans || 0}</p>
                                    </div>
                                    <div className="col text-center">
                                        <Form.Label> <i className="fa fa-money" /> Total outstanding Amount </Form.Label>
                                        <br />
                                        <p className="bold font-24">₹ {this.state.currentBorrowingPartner?.totalOutstanding ? this.state.currentBorrowingPartner?.totalOutstanding.toFixed(2) : 0}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.currentLendingPartner &&

                            <div className="shadow-sm p-2 mt-4 bg-white rounded border-lite">
                                <div className="text-center custom-tab"><span> <i>{this.state.currentLendingPartner?.partnerCode}</i></span></div>
                                <div className="row pt-2">
                                    <div className="col text-center">
                                        <Form.Label>  Total Number of Active Loans</Form.Label>
                                        <p className="bold font-24">{this.state.currentLendingPartner?.activeLoans || 0}</p>
                                    </div>
                                    <div className="col text-center">
                                        <Form.Label> <i className="fa fa-money" /> Total outstanding Amount</Form.Label>
                                        <br />
                                        <p className="bold font-24">₹ {this.state.currentLendingPartner?.totalOutstanding ? this.state.currentLendingPartner?.totalOutstanding.toFixed(2) : 0}</p>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="shadow-sm p-2 mt-4 bg-white rounded border-lite">
                            <div className="row pt-2">
                                <div className="col text-center">
                                    <Form.Label><i className="fa fa-bell" /> Irregular Loan Repayments</Form.Label>
                                    <p className="bold font-24">{Object.keys(this.state.loanInstallmentDetail).length || 0}</p>
                                </div>
                                <div className="col text-center">
                                    {this.renderIrregularPaymentDetail(this.state.loanInstallmentDetail)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shadow-sm p-4 mt-4 bg-white rounded border-lite">
                        <div className="row p-0 m-0">
                            {
                                this.tabs.map((item, index) => (
                                    <div className={`col py-2 text-center custom-tab ${this.state.selectedTab == item.value ? "custom-active-tab" : ""}`} onClick={() => this.onClickTab(item.value)} >
                                        <span className="mt-2 mr-2">{item.title}</span>
                                        {item.isBadge ? <div className="d-inline-block badge-red"> </div> : ""}
                                    </div>
                                ))}
                        </div>
                        <div className="pt-4 text-center">
                            <div className="d-inline-block chart-wrapper-md">
                                <LineChartComponent label={this.state.labels} score={this.getChartData()} labelName={this.getChartName()} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default UserCentricData;
