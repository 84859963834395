import React from "react";
// import auth from "../../services/common/authService";
// import { Form, Col, InputGroup, Button, Nav, Modal } from "react-bootstrap";
// import SpinnerComponent from "../../components/common/spinner";
// import { Redirect } from "react-router-dom";
// import PartnerRegistrationFormModel from "../../models/partner/partnerRegistrationFormModel";
// import DatePicker from "react-datepicker2";
// import moment from "moment";
// import Tnc from "./../companyInfo/tnc";
// import PrivacyPolicy from "./../companyInfo/privacyPolicy";
// import { useState } from "react";
// import { useLocation,useParams } from 'react-router-dom';
// import axios from "axios";
// // Render the Calendar

function SuccessSubmissions(props) {
//   const params = useParams();

//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [merchantCode, setMerchantCode] = useState(params.merchantCode)
//   const [referrerId, setReferrerId] = useState(params.referrerId)


//   const [pan, setPan] = useState("");
//   const [dob, setDob] = useState(new Date());
//   const [adhaar, setAdhaar] = useState("");
//   const [emailId, setEmailId] = useState("");
//   const [mobileNo, setMobileNo] = useState("");
//   const [occupation, setOccupation] = useState("");

  


//   // if (auth.getCurrentUser()) return <Redirect to="/" />;
//   // const {
//   //   data,
//   //   errors,
//   //   info,
//   //   showSpinner,
//   //   counter,
//   //   showTnc,
//   //   tnc,
//   //   businessTypeList,
//   //   showBorrowerPopup,
//   //   showLenderPopup,
//   //   isValidMobile,
//   //   isValidOTP,
//   //   isRegenerateLink,
//   //   isRegenerateText,
//   //   showPrivacyPolicy,
//   //   panDisabled
//   // } = props;

//   const handleSubmit = async(e) => {
//     e.preventDefault();
//     console.log('data',firstName)
//     console.log('data',moment(dob).format('MM/DD/YYYY'))
//     console.log('data',occupation)

//     let personalDetailsData ={
//       firstName, lastName, emailId, mobileNo, adhaar, pan, occupation, dob:moment(dob).format('MM/DD/YYYY'), merchantCode, referrerId 

//     }
//     console.log('personalDetailsData', personalDetailsData)
//     await axios({ method: 'post',url : 'https://app.fundfina.com/masterpay/addPersonalLoanDetails',data: personalDetailsData , withCredentials: false})
//       .then(res=>{
//         console.log("res here",res);
//         console.log(res.data);
//         if(res?.data?.status == 200){
//         window.location = "/personalLoanDetails/succefullSubmission" 
//         }
//       })

//     window.location = "/InProcess" 
//     }

  

  // let OccupationData =[{
  //   type : "Salaried"

  // }, {
  //   type : "Self Employed"
  // }]

  // console.log('stateee', this.state)
  // const tncLabel = (
  //   <React.Fragment>
  //     I have read and agree to{" "}
  //     <a
  //       href="#"
  //       style={{ textDecoration: "underline" }}
  //       onClick={this.handleViewTnc}
  //     >
  //       Terms and Conditions
  //     </a>{" "}
  //     and{" "}
  //     <a
  //       href="#"
  //       style={{ textDecoration: "underline" }}
  //       onClick={this.handleViewPrivacyPolicy}
  //     >
  //       Privacy Policy
  //     </a>
  //   </React.Fragment>
  // );
  return (
    <React.Fragment>
      {/* <SpinnerComponent  /> */}
      <h2 style={{ textAlign: "center" }}>Form is successfully submitted. We will process the information and reach out you!</h2> <br />

    </React.Fragment>
  );
}


export default SuccessSubmissions;
