import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import LenderPayoutModel from "../../models/lenderPayout/lenderPayout";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';


class LenderPayout extends LenderPayoutModel {

  render() {
    const {
      pageSize,
      currentPage,
      showSpinner,
      partners,
      successMsg,
      errors,
      data,
      transactions,
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Lender Payout
        </h2>
        <Form onSubmit={this.handleSubmit}>

          <Form.Row>
            <Form.Group as={Col} md="3" controlId="partnerId">
              <Form.Label>Partner<span>*</span></Form.Label>
              <Form.Control
                as="select"
                name="partnerId"
                value={data["partnerId"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="all" value="all">
                  ALL
                </option>
                {partners && partners.map((partner) => (
                  <option key={partner.partnerName} value={partner._id}>
                    {partner.displayName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <br />
              <Button
                className="ff-button mr-2 mt-2"
                type="submit"
              >
                Go
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>

        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        <div style={{ width: '100%' }}>
          <StickyTable>
            <Row>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", borderLeft: "1px solid", width: 200 }}><b>Transaction Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Total Amount</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b>Total Installement Count</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", width: 200 }}><b></b></Cell>
            </Row>
            {transactions &&
              transactions.map((transaction, i) => (
                <Row key={transaction.narration}>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid" }}>{transaction.transactionDate}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>₹{(transaction.totalAmount || 0)}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.totalCount}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    <Button
                      className="ff-button btn-sm"
                      type="submit"
                      onClick={() => { this.lenderPayoutInit(transaction) }}
                    >
                      Settle
                    </Button>
                  </Cell>
                </Row>
              ))}
            {!transactions || !transactions.length && (
              <tr>
                <td colSpan={4} align='center'>No Record Found</td>
              </tr>
            )}

          </StickyTable>
        </div >
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
        </div>
      </React.Fragment >
    );
  }
}

export default LenderPayout;
