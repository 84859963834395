import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/disbursal";
const apiEndpoint1 = apiUrl + "/ops/admin";

export async function disbursalList(model) {
    const options = {
        fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
        toDate: moment(model.toDate).format("DD/MM/yyyy"),
        borrowingPartner: model.partner,
        status: model.status,
        productId: model.productId,
        loanNo: model.loanNo,
        lendingPartner: model.lendingPartner
    };
    const data = await http.post(apiEndpoint + "/getBankDisbursalList", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return data;
}

export async function borrowingPartners() {
    const data = await http.post(apiEndpoint + "/getBorrowingPartners", {}).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return data;
}

export async function lendingPartners() {
    const data = await http.post(apiEndpoint + "/getBorrowingPartners", {}).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return data;
}

export async function saveDisbursal(model) {
    const options = {
        loanId: model.loanId,
        bankReferenceNo: model.bankReferenceNo,
        paymentMode: model.paymentMode,
        bankCode: model.bankCode,
        dateOfTransaction: moment(model.dateOfTransaction).format("DD/MM/yyyy")
    };

    const data = await http.post(apiEndpoint + "/saveBankDisbursalDetails", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function saveDisbursalv2(model) {
    const options = {
        loanId: model.loanId,
        bankReferenceNo: model.bankReferenceNo,
        paymentMode: model.paymentMode,
        bankCode: model.bankCode,
        dateOfTransaction: moment(model.dateOfTransaction).format("DD/MM/yyyy")
    };

    const data = await http.post(apiEndpoint + "/saveBankDisbursalDetailsV2", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function reDisburse(model) {
    const options = {
        loanId: model.loanId,
    };

    const data = await http.post(apiEndpoint + "/redisburseLoan", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function reDisburseV2(loans) {

    const { data } = await http.post(apiEndpoint + "/redisburseLoans", { loans: loans });

    return data;
}

export async function getTrancheDetails(loanId) {
    const { data } = await http.post(`${apiEndpoint}/getActiveTrancheDetails`, { loanId: loanId });
    return data;
}

export async function addTranche(loanId) {
    const { data } = await http.post(`${apiEndpoint}/addTranche`, { loanId: loanId });
    return data;
}

export async function exportDisbursalFile(loans, paymentMode) {
    const { data } = await http.post(`${apiEndpoint}/exportDisbursalFile`, { loans: loans, paymentMode: paymentMode });
    return data;
}

export async function uploadPaymentReconExcel(model) {
    const { data } = await http.post(`${apiEndpoint1}/uploadDisbursalExcel`, model);
    return data;
}

export async function uploadDisbursalExcel(model) {
    const { data } = await http.post(`${apiEndpoint}/uploadDisbursalExcel`, model);
    return data;
}

export async function getDisbursalDetails(loanId) {
    const { data } = await http.post(`${apiEndpoint}/getDisbursalDetails`, { loanId: loanId });
    return data;
}

export async function disburseLoans(loans) {
    const { data } = await http.post(`${apiEndpoint}/disburseLoans`, { loans: loans });
    return data;
}

export async function disburseLoansV2(loans, disbursalType) {
    const { data } = await http.post(`${apiEndpoint}/disburseLoans/v2`, { loans: loans, disbursalType });
    return data;
}

export async function getLendingPartnerList() {
    const response = await http.get(`${apiEndpoint1}/getLendingPartnerList`);
    console.log(response);
    return response;
}

export async function disbursalTransactionList(model) {
    const options = {
        fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
        toDate: moment(model.toDate).format("DD/MM/yyyy"),
        status: model.status,
        loanNo: model.loanNo,
        lendingPartner: model.lendingPartner,
        pageSize: model.pageSize,
        currentPage: model.currentPage,
        type: model.type
    };
    const data = await http.post(apiEndpoint + "/disbursalTransactions", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return data;
}

export async function reInitiateTransaction(model) {
    const options = {
        loanId: model.loanId,
        customerRefNo: model.customerRefNo
    };

    const data = await http.post(apiEndpoint + "/reInitiateTransaction", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}