import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getPayOutList(model) {

    let payload =  {
        fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
        toDate: moment(model.toDate).format("DD/MM/yyyy"),
    }

    if(model.page) payload.page= model.page
    
    const data = await http.post(`${apiEndpoint}/getpayOutModuleDetails`,payload)
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function savePayOut(model) {
    const { data } = await http.post(`${apiEndpoint}/savePayOut`, {
        partnerId: model.partnerId,
        utrNo: model.utrNo,
        amount: model.amount,
        depositDate: moment(model.depositDate).format("DD/MM/yyyy"),
        borrowingPartnerId: model.borrowingPartnerId
    });
    return data;
}

export async function getEmisCollection(model) {
    const data = await http.post(`${apiEndpoint}/getEmisCollection`, {
        fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
        toDate: moment(model.toDate).format("DD/MM/yyyy"),
    })
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}