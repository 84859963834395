import InputDataForm from "../../components/common/form";
import Joi from "joi-browser";
import * as analytics from "../../utils/analytics";

class TrueScoreResultModel extends InputDataForm {
    state = {
        data: {
            pan: "",
            trueScore: ""
        }
    }

    componentWillMount() {
        if (!this.props.location || !this.props.location.state || this.props.location.state.pan.trim() === "" || this.props.location.state.trueScore.trim() === "") {
            return (window.location = "/");
        }
        const data = { ...this.state.data };
        data.pan = this.props.location.state.pan;
        data.trueScore = this.props.location.state.trueScore;

        // data.pan="CSXXXXXX3A";
        // data.trueScore="180";

        this.setState({ data });

    }
    componentDidMount() {
        analytics.track();
    }
}

export default TrueScoreResultModel;