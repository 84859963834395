import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import * as portfolioService from "../../services/portfolio/portfolioService";
import * as collectionsQueueService from "../../services/collectionsQueue/collectionsQueue";
import moment from "moment";

class CollectionsQueueModel extends InputDataForm {
    state = {
        data: {
            partnerId: "",
            mobileNo: "",
            name: "",
            loanNo: "",
            overdueBy: "",
            emiList: []
        },
        showSpinner: false,
        errors: {},
        currentPage: 1,
        pageSize: 0,
        partnerList: [],
        showRepaymentModal: false,
        loanNo: "",
        showErrorModal: false,
        errorMessage: "",
        errorHeader: "",
        sortBy: "-loanNo",
        loanId: "",
        allLoans: [],
        lendingPartnerList : []
    };
    schema = {
        mobileNo: Joi.any(),
        name: Joi.any(),
        partnerId: Joi.any(),
        loanNo: Joi.any(),
        emiList: Joi.any(),
        overdueBy: Joi.any(),
        page: Joi.any(),
        lendingPartnerId : Joi.any()
    };
    async loadDropdowns() {
        try {
            const {
                data: partnerList,
            } = await commonService.getBorrowingPartnerList();

            this.setState({
                partnerList: partnerList
            });
        } catch (ex) { }
    }
    async getLoanList(sortBy, isAllLoans) {

        this.state.data.page = this.state.currentPage

        const data = { ...this.state.data };

        if (isAllLoans) {
            data.isAllLoans = true
        }

        this.setState({ showSpinner: true });
        try {
            const { data: emiList, totalPages } = await loanService.getPendingEmis(data, sortBy);

            if (isAllLoans) {
                this.setState({ allLoans: emiList, showSpinner: false }, () => {
                    this.setState({ downloadAll: true }, () => {
                        this.setState({ downloadAll: false })
                    })
                });
                return
            }
            data.emiList = emiList;

            this.setState({ data, showSpinner: false, pageSize: totalPages });
        } catch (ex) {
            this.setState({ data, showSpinner: false });
        }
    }
    async getRepaymentDetails(loanNo) {
        try {
            this.setState({
                showRepaymentModal: true,
                loanId: loanNo
            });
        } catch (ex) {
        }
    }
    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();

        const { sortBy } = this.state;

        if (currentUser.role !== "Admin" && currentUser.role !== "Lending Partner")
            return (window.location = "/");

        let { data } = this.state;

        if (this.props.loanNo) {
            data.loanNo = this.props.loanNo;
            this.setState({ data });
        }

        await this.loadDropdowns();
        await this.getLoanList(sortBy);
        await this.getLendingPartners();
    }

    async getLendingPartners() {
        try {
            const {
                data: partnerList,
            } = await loanService.getLendingPartnerList();

            this.setState({
                lendingPartnerList: partnerList.data
            });
        } catch (ex) { }
    }

    async handleCustomErrors(errors) {
        return errors;
    }
    closeModal = () => {
        this.setState({
            showRepaymentModal: false,
            showErrorModal: false
        });
    };
    handleAmountChange = async (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.emiId === recordFields[1]);

        if (tranIndex >= 0)
            list[tranIndex].amountCollected = !list[tranIndex].amountCollected;

        if (list[tranIndex].collectedOn && list[tranIndex].amountCollected)
            list[tranIndex].disabled = false;
        else
            list[tranIndex].disabled = true;

        data.emiList = list;

        this.setState({ data });
    }
    handleDateChange = (name, date) => {
        if (!date)
            return;

        let data = { ...this.state.data };
        const list = [...this.state.data.emiList];

        let recordFields = name.split('_');

        let tranIndex = list.findIndex(x => x.emiId === recordFields[1]);

        if (tranIndex >= 0) {
            list[tranIndex].collectedOn = date;
        }

        if (list[tranIndex].collectedOn && list[tranIndex].amountCollected)
            list[tranIndex].disabled = false;
        else
            list[tranIndex].disabled = true;

        data.emiList = list;

        this.setState({ data });
    };
    handleMarkAsPaid = async (e) => {
        let data = { ...this.state.data };
        const { sortBy } = this.state;

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.emiId === recordFields[1]);

        if (!list[tranIndex].amountCollected || !list[tranIndex].collectedOn) { this.setState({ errorMessage: "Please enter amount and date", showErrorModal: true, errorHeader: "Note!" }); }

        else {
            let date = moment(list[tranIndex].collectedOn).format("DD/MM/yyyy");
            let emiNumber = list[tranIndex].emi.emiNumber;
            let loanId = list[tranIndex].loanId;

            list[tranIndex].disabled = true;

            data.emiList = list;

            this.setState({ showSpinner: true, data })

            try {
                let response = await collectionsQueueService.markAsPaid(loanId, emiNumber, date);

                list[tranIndex].disabled = false;

                data.emiList = list;

                if (response.success) {
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Success!", showSpinner: false, data });
                    await this.getLoanList(sortBy);
                }

                else
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false, data });

            }
            catch (ex) {
                this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false, data });
            }
        }
    }
    handleEmiStatusChange = (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.emiId === recordFields[1]);

        if (tranIndex >= 0) {

            list[tranIndex].eNachStatus = e.target.value;
        }

        data.emiList = list;

        this.setState({ data });
    };
    eNachAction = async (e) => {
        let data = { ...this.state.data };

        const { sortBy } = this.state;

        const list = [...this.state.data.emiList];
        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.emiId === recordFields[1]);

        if (!list[tranIndex].eNachStatus) { this.setState({ errorMessage: "Please select e-Nach action", showErrorModal: true, errorHeader: "Note!" }); }

        else {

            list[tranIndex].nachBtnDisabled = true;

            data.emiList = list;

            this.setState({ showSpinner: true, data });

            let status = list[tranIndex].eNachStatus;
            let loanId = list[tranIndex].loanId;
            let emiNumber = list[tranIndex].emi.emiNumber;

            try {
                let response = await collectionsQueueService.updateEnachStatus(loanId, emiNumber, status);

                list[tranIndex].nachBtnDisabled = false;

                data.emiList = list;

                if (response.success) {

                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Success!", showSpinner: false, data });
                    await this.getLoanList(sortBy);
                }

                else
                    this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false, data });

            }
            catch (ex) {
                this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false, data });
            }
        }
    }
    sortByLoanNo = async () => {
        let { sortBy } = this.state;

        sortBy = sortBy === "-loanNo" ? "loanNo" : "-loanNo";

        await this.getLoanList(sortBy);

        this.setState({ sortBy: sortBy });
    };
    sortByName = async () => {
        let { sortBy } = this.state;

        sortBy = sortBy === "-name" ? "name" : "-name";

        await this.getLoanList(sortBy);

        this.setState({ sortBy: sortBy });
    };
    sortByEmiNumber = async () => {
        let { sortBy } = this.state;

        sortBy = sortBy === "-emiNumber" ? "emiNumber" : "-emiNumber";

        await this.getLoanList(sortBy);

        this.setState({ sortBy: sortBy });
    };
    async doSubmit() {

        this.resetPage()
        const { sortBy } = this.state;
        await this.getLoanList(sortBy);
    }
    handleHoldCollection = async (e) => {
        let data = { ...this.state.data };

        const list = [...this.state.data.emiList];

        let recordFields = e.target.name.split('_');

        let tranIndex = list.findIndex(x => x.emiId === recordFields[1]);

        if (tranIndex >= 0) {

            const oldValue = list[tranIndex].holdWalletCollection;

            list[tranIndex].holdWalletCollection = !list[tranIndex].holdWalletCollection;

            data.emiList = list;

            this.setState({ data });

            const holdWalletCollection = await collectionsQueueService.holdWalletCollections(list[tranIndex].loanId, list[tranIndex].holdWalletCollection, list[tranIndex].emi.emiNumber);

            if (!holdWalletCollection.success) {

                this.setState({ errorMessage: holdWalletCollection.message, showErrorModal: true, errorHeader: "Error!" });

                list[tranIndex].holdWalletCollection = oldValue;

                data.emiList = list;

                this.setState({ data });
            }
        }
    };

    onPageNext = () => {

        if (this.state.pageSize < this.state.currentPage)
            return

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getLoanList(this.state.sortBy)
        })
    }

    onPageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.getLoanList(this.state.sortBy)
        })
    }

    onPagePrevious = () => {

        if (this.state.currentPage <= 1)
            return

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {
            this.getLoanList(this.state.sortBy)
        })
    }

    resetPage = () => {
        this.state.data.page = 1
        this.setState({ currentPage: 1, pageSize: 1 })
    }

    downloadExcel = () => {
        this.getLoanList(this.state.sortBy, true)
    }
}

export default CollectionsQueueModel;
