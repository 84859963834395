import Joi from "joi-browser";
import InputForm from "../../components/common/form";
import * as updateEmailMobileService from "../../services/profile/updateAlternateEmailService";
import * as registFormService from "../../services/login/registerFormService";
import auth from "../../services/common/authService";
import * as analytics from "../../utils/analytics";

class updateAlternateEmailModel extends InputForm {
  state = {
    data: {
      userId: "",
      email: "",
    },
    errors: {},
    info: {},
    isValidEmail: false,
  };
  schema = {
    userId: Joi.string().required(),
    email: Joi.string().required().email().label("Email Id"),
  };
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    analytics.track();

    await this.getUserData();
  }
  async getUserData() {
    let data = { ...this.state.data };

    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location = "/login");

    data.userId = currentUser.userId;
    this.setState({ data });
  }
  doSubmit = async (e) => {
    e.preventDefault();
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      this.setState({ showSpinner: true });

      try {
        const { success, message } = await registFormService.checkEmail(
          data.email
        );

        if (success) {
          try {
            const {
              success,
              message,
            } = await updateEmailMobileService.updateEmailMobile(
              data.email,
              "alternateemail"
            );

            if (success) window.location = "/myprofile";
            else {
              errors.globalError = message;
              this.setState({ data, errors, showSpinner: false });
            }
          } catch (ex) {
            if (ex.response && ex.response.status === 400) {
              errors.globalError = ex.response.data;
              this.setState({ data, errors, showSpinner: false });
            }
          }
        } else {
          errors["email"] = message;
          this.setState({
            errors,
            data,
            isValidEmail: false,
            showSpinner: false,
          });
        }
      } catch (ex) {
        errors["email"] = ex.response.data.message;
        this.setState({ data, errors, showSpinner: false });
      }
    } else {
      errors["email"] = "Invalid Email";
      this.setState({ errors });
    }
  };
}

export default updateAlternateEmailModel;
