import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import SettlementMISUTRModel from "../../models/reconciliation/setttlementMISUTR";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Col, Button, Tabs, Tab } from "react-bootstrap";
import DatePicker from "react-datepicker2";
import ReconciliationModal from '../reconcliationModal/reconcliationModal'

class SettlementMISUTR extends SettlementMISUTRModel {
  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      successMsg,
      errors,
      partners,
      statusDropdown
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <div className="mt-3">

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>

              <Form.Group as={Col} md="4" controlId="fromDate">
                <Form.Label>Partner</Form.Label>
                <Form.Control
                  as="select"
                  name="partner"
                  value={data["partner"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="all" value="all">
                    ALL
                  </option>
                  {partners && partners.map((partner) => (
                    <option key={partner.partnerName} value={partner._id}>
                      {partner.displayName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="fromDate">
                <Form.Label>From Date <small className="text-success">(Max Date Range 60 days)</small></Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>

            </Form.Row>

            <Form.Row>

              <Form.Group as={Col} md="4">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  value={data["status"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="" value="">
                    All
                  </option>
                  {statusDropdown && statusDropdown.map((item) => (
                    <option key={item.title} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Form.Label> </Form.Label>
                <br />
                <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Apply Filters
                </Button>
              </Form.Group>

            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}

          <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", borderLeft: "1px solid", textAlign: "center" }}><b>Borrowing Partner</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>UTR</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Mis Amount</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Mis Count</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reason</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Created On</b></Cell>
              </Row>
              {data.misList && data.misList &&
                data.misList.map((mis, i) => (
                  <Row key={mis._id} className='cursor-pointer' onClick={() => mis.status == "COMPLETED" && this.showReconModal(mis.utr)}>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }} className={`${mis.delta || mis.status == "FAILED" ? "error-row" : ""}`}>{mis.partnerName}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis.delta || mis.status == "FAILED" ? "error-row" : ""}`}>{mis.utrNo}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis.delta || mis.status == "FAILED" ? "error-row" : ""}`}>{mis.misAmount || mis.misAmount == 0 ? '₹ ' + mis.misAmount : "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis.delta || mis.status == "FAILED" ? "error-row" : ""}`}>{mis.misCount}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis.delta || mis.status == "FAILED" ? "error-row" : ""}`}>{mis.status}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis.delta || mis.status == "FAILED" ? "error-row" : ""}`}>{mis.reason}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} className={`${mis.delta || mis.status == "FAILED" ? "error-row" : ""}`}>{mis.insertedOn && new Date(mis.insertedOn).toLocaleString() || "-"}</Cell>
                  </Row>
                ))}
              {(!data.misList || !data.misList.length) && (
                <td colSpan={9} className='text-center pt-2 pb-2 border'>No Records Found</td>
              )}

            </StickyTable>
          </div >
          <div style={{ width: "100%", overflowX: "scroll" }}>
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.onPageChange}
              onPagePrevious={this.onPagePrevious}
              onPageNext={this.onPageNext}
            />
          </div>
        </div>

        {this.state.showReconModal && <ReconciliationModal close={this.closeReconModal} utrNo={this.state.selectedUtrNo}></ReconciliationModal>}
      </React.Fragment>
    );
  }
}

export default SettlementMISUTR;
