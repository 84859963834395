import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import loanApplicationsModel from "../../models/loan/loanApplicationsModel";
import { Form, Col, Button, Table, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import moment from "moment";
import DatePicker from "react-datepicker2";
import ReactExport from "react-export-excel";
import NumberFormat from 'react-number-format';
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';

class LoanApplications extends loanApplicationsModel {

    renderExcel() {
        let excelList = this.state.excelList || []

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <ExcelFile hideElement={true} filename={`loan_application_${moment(new Date()).format("DDMMYYYY")}`} >
                <ExcelSheet data={excelList} name="list">
                    <ExcelColumn
                        label="Date"
                        value="insertedOn"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Merchant Code"
                        value="merchantCode"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Name"
                        value="name"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Loan No"
                        value="loanNo"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Loan Amount"
                        value="amount"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="EMI Amount"
                        value="emiAmount"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Processing Fee"
                        value="processingFee"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Status"
                        value="fundfinaStatus"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Reason"
                        value="adminRemarks"
                    ></ExcelColumn>
                </ExcelSheet>
            </ExcelFile>
        )
    }

    render() {
        const {
            data,
            showSpinner,
            list,
            pageSize,
            currentPage,
            successMsg,
            errors,
            sortBy
        } = this.state;

        let borrowingPartnerMaxEndDate = moment(data["fromDate"]).add(30, "days")
        let currentDate = new Date()

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2 style={{ textAlign: "center" }}>Loan Applications</h2>{" "}

                <Form onSubmit={this.submitLoanApplicationFilter}>
                    <Form.Row>
                        {" "}
                        <Form.Group as={Col} md="3" controlId="fromDate">
                            <Form.Label>From Date</Form.Label>
                            <DatePicker
                                name="fromDate"
                                id="fromDate"
                                value={data["fromDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleFromDateChange(value)}
                                placeholder="Enter From Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="toDate">
                            <Form.Label>To Date</Form.Label>
                            <DatePicker
                                name="toDate"
                                id="toDate"
                                value={data["toDate"]}
                                max={borrowingPartnerMaxEndDate > currentDate ? currentDate : borrowingPartnerMaxEndDate}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleToDateChange(value)}
                                placeholder="Enter To Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={data["status"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="All" value="">
                                    ALL
                                </option>
                                <option key="Initiated" value="Initiated">
                                    Clicked on Offer
                                </option>
                                <option key="Draft" value="Draft">
                                    Loan Application Incomplete
                                </option>
                                <option key="Under Review" value="Under Review">
                                    Application Under Review
                                </option>
                                <option key="Review" value="Review">
                                    Customer to correct application details
                                </option>
                                <option key="Loan Is Funding" value="Loan Is Funding">
                                    Contract Sent to Customer
                                </option>
                                <option key="Loan Contract Accepted" value="Loan Contract Accepted">
                                    Contract Accepted by Customer
                                </option>
                                <option key="Initiated Fund Transfer" value="Initiated Fund Transfer">
                                    Disbursal Initiated
                                </option>
                                <option key="Recourse Pending" value="Recourse Pending">
                                    Mandate registration pending
                                </option>
                                <option key="Loan In Progress" value="Loan In Progress">
                                    Loan In Progress
                                </option>

                                <option key="Written-Off" value="Written-Off">
                                    Written-Off
                                </option>
                                <option key="WrittenOff Paid" value="WrittenOff Paid">
                                    WrittenOff Paid
                                </option>
                                <option key="Closed" value="Closed">
                                    Closed
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="productId">
                            <Form.Label>Product Id</Form.Label>
                            <Form.Control
                                type="text"
                                name="productId"
                                value={data["productId"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}
                        <Form.Group as={Col} md="3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={data["name"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="merchantCode">
                            <Form.Label>Merchant Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="merchantCode"
                                value={data["merchantCode"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="mobileNo">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobileNo"
                                value={data["mobileNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Button
                                className="ff-button"
                                type="submit"
                                style={{ marginTop: 30, marginLeft: 10 }}
                            >
                                Apply Filters
                            </Button>

                            <Button
                                className="ff-button"
                                type="button"
                                style={{ marginTop: 30, marginLeft: 10 }}
                                onClick={() => this.onClickExcelExport()}
                            >
                                Export
                            </Button>
                            {
                                this.state.downloadExcel ? this.renderExcel() : ""
                            }
                        </Form.Group>
                    </Form.Row>
                </Form>
                <br />
                {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
                {successMsg && <div className="alert alert-success">{successMsg}</div>}
                <br />
                <div style={{ width: '100%' }}>
                    <StickyTable stickyHeaderCount={0} leftStickyColumnCount={2}>
                        <Row>
                            <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}>
                                <b>
                                    Date{" "}
                                </b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}>
                                <b>Name{" "}</b>
                            </Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}>
                                <b> Loan No.{" "}</b>
                            </Cell>

                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}>
                                <b>
                                    Merchant Code{" "}
                                    {/* {sortBy == "merchantCode" && (
                                        <i
                                            class="fa fa-sort-asc"
                                            aria-hidden="true"
                                            onClick={this.sortByMerchantCode}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )}
                                    {sortBy == "-merchantCode" && (
                                        <i
                                            class="fa fa-sort-desc"
                                            aria-hidden="true"
                                            onClick={this.sortByMerchantCode}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )}
                                    {sortBy != "-merchantCode" && sortBy != "merchantCode" && (
                                        <i
                                            class="fa fa-sort"
                                            aria-hidden="true"
                                            onClick={this.sortByMerchantCode}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    )} */}
                                </b>
                            </Cell>

                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}>
                                <b>Loan Amount{" "}</b>
                                {/* {sortBy == "amount" && (
                                    <i
                                        class="fa fa-sort-asc"
                                        aria-hidden="true"
                                        onClick={this.sortByAmount}
                                        style={{ cursor: "pointer" }}
                                    ></i>
                                )}
                                {sortBy == "-amount" && (
                                    <i
                                        class="fa fa-sort-desc"
                                        aria-hidden="true"
                                        onClick={this.sortByAmount}
                                        style={{ cursor: "pointer" }}
                                    ></i>
                                )}
                                {sortBy != "-amount" && sortBy != "amount" && (
                                    <i
                                        class="fa fa-sort"
                                        aria-hidden="true"
                                        onClick={this.sortByAmount}
                                        style={{ cursor: "pointer" }}
                                    ></i>
                                )} */}
                            </Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Emi Amount</b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Processing Fee</b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reason</b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Application Link</b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Contract Link</b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>PNACH Download</b></Cell>
                            <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 400 }}><b>PNACH Upload</b></Cell>
                        </Row>
                        <tbody>
                            {list &&
                                list.map((loan, i) => (
                                    <Row key={loan.loanId}>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderLeft: "1px solid", borderRight: "1px solid" }}>{loan.insertedOn}</Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>{loan.name}</Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>{loan.loanNo}</Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>{loan.merchantCode}</Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>
                                            <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>
                                            <NumberFormat value={loan.emiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>
                                            <NumberFormat value={loan.processingFee} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                        </Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>{loan.fundfinaStatus}</Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>{loan.adminRemarks}</Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>
                                            <Button
                                                onClick={() => this.handleSendReview(loan.loanId)}
                                                className="ff-button"
                                                disabled={loan.fundfinaStatus == "Review" ? "" : "true"}
                                            >
                                                Resend
                                            </Button>
                                        </Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>
                                            <Button
                                                onClick={() => this.handleSendContract(loan.loanId)}
                                                className="ff-button"
                                                disabled={loan.status == "Loan Is Funding" ? "" : "true"}
                                            >
                                                Resend
                                            </Button>
                                        </Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>
                                            <Button
                                                className="ff-button"
                                                type="button"
                                                name={"btnpNachDownload_" + loan.loanId}
                                                onClick={this.generatePhysicalNachFile}
                                                style={{ minWidth: 50 }}
                                                disabled={loan.isPnachUploadDisabled}
                                            >
                                                Download
                                            </Button>
                                        </Cell>
                                        <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderRight: "1px solid" }}>
                                            <React.Fragment>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        {!loan.files["pNachFile"] ?
                                                            <Form.File
                                                                label={loan.files["pNachFileLabel"]}
                                                                onChange={(e) => this.handleListFileChange(e, false, "", true, "pNach")}
                                                                id="pNachFile"
                                                                accept=".jpg,.png,.jpeg"
                                                                custom
                                                                name={"pNachFile_" + loan.loanId}
                                                                style={{ maxWidth: 220 }}
                                                                disabled={loan.isPnachUploadDisabled}
                                                            />
                                                            :
                                                            <div style={!loan.files["pNachFile"] ? { display: "none" } : {}} className="position-relative" >
                                                                {this.renderThumbnail(loan.files?.pNachFileStream)}
                                                                {this.renderThumbnailLActionList("pNachFile", loan.files?.pNachFileStream, i)}
                                                            </div>}
                                                        <Button
                                                            className="ff-button"
                                                            type="button"
                                                            name={"btnPnachSelect_" + loan.loanId}
                                                            onClick={this.uploadPhysicalNachFile}
                                                            style={{ minWidth: 50, marginLeft: 20, height: 40 }}
                                                            disabled={loan.isPnachUploadDisabled}
                                                        >
                                                            Upload
                                                        </Button>
                                                    </div></div>
                                            </React.Fragment>
                                        </Cell>
                                    </Row>
                                ))}
                            {!list ||
                                (list.length == 0 && (
                                    <Row key="no">
                                        <Cell colSpan="11">No record found</Cell>
                                    </Row>
                                ))}
                        </tbody>
                    </StickyTable>
                </div>
                <div style={{ width: "100%", overflowX: "scroll" }}>
                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPagePrevious={this.onPagePrevious}
                        onPageNext={this.onPageNext}
                    />
                </div>
                <Modal
                    show={this.state.showErrorModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <b>{this.state.errorHeader}</b>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.errorMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModal} className="ff-button">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        );
    }
}

export default LoanApplications;
