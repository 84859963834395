
import React, { useContext } from "react";

import { Form, Col, Nav, Button, Modal } from "react-bootstrap";
import SpinnerComponent from "./../../components/common/spinner";
import _ from "lodash";
import ShopTypeModel from "../../models/userCentricData/shopTypeModel";

const shopTypeList = [
    'Mobile / Telecom',
    'Money Transfer',
    'General / Grocery / Supermarket Retailers',
    'Travels and Transport',
    'Agriculture Products',
    'Medical / Chemist',
    'E-Mitra / Seva Kendra'
]

class ShopType extends ShopTypeModel {
    render() {
        const {
            userData,
            isValidUser,
            shopTypeDataExists,
            showSpinner,
            isSubmitted,
            errors
        } = this.state;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                {/* {(!userData || !userData.merchantCode || !userData.partnerCode) && (
                    <Redirect
                        to={{
                            pathname: '/not-found',
                        }}
                    />)} */}
                {!shopTypeDataExists && isValidUser && !isSubmitted &&
                    (<Form onSubmit={(e) => this.doSubmit(e)}>

                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="merchantCode">
                                <Form.Label column sm="6">
                                    Merchant Code:{"  "}
                                    <span
                                        style={{ color: "black", textTransform: "uppercase" }}
                                    >
                                        {userData.merchantCode}
                                    </span>
                                </Form.Label>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="partnerCode">
                                {/* <Form.Label> {messageText}</Form.Label> */}
                                <Form.Label column sm="6">
                                    Partner Code:{"  "}
                                    <span
                                        style={{ color: "black", textTransform: "uppercase" }}
                                    >
                                        {userData.partnerCode}
                                    </span>
                                </Form.Label>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="shopType">
                                <Form.Label>Select Shop Type*</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="shopType"
                                    value={userData['shopType']}
                                    onChange={(e) => this.handleChange(e)}
                                >
                                    <option key="select" value="">
                                        ---Select---
                                    </option>
                                    {shopTypeList && shopTypeList.map(item =>
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    )}
                                </Form.Control>
                                {errors["shopTypeError"] && (
                                    <div className="alert alert-danger">
                                        {errors["shopTypeError"]}
                                    </div>
                                )}
                            </Form.Group>
                        </Form.Row>
                        <Button
                            className="ff-button"
                            type="submit"
                            // disabled={isSubmitted}
                            style={{ marginRight: 10 }}
                        >
                            Submit
                        </Button>

                        {/* {errors["globalError"] && (
                            <div className="alert alert-danger" style={{ marginTop: 10 }}>{errors["globalError"]}</div>
                        )} */}

                    </Form>)}
                {shopTypeDataExists && isValidUser && (
                    <React.Fragment>
                        <div className="alert alert-danger" style={{ margin: '0 auto', width: '50%' }}>You shop type has already been submitted</div>
                    </React.Fragment>)}
                {!isValidUser && (
                    <React.Fragment>
                        <div className="alert alert-danger" style={{ margin: '0 auto', width: '50%' }}>Merchant not found</div>
                    </React.Fragment>)}
                {isSubmitted && (
                    <React.Fragment>
                        <div className="alert alert-danger" style={{ margin: '0 auto', width: '50%' }}>Your data has been submitted</div>
                    </React.Fragment>)}
            </React.Fragment>
        );
    }
}
export default ShopType;
