
import React, { useContext } from "react";
import AgentAuthenticateModel from "../../models/agent/agentAuthenticateModel";
import { Form, Col, Nav, Button, Modal } from "react-bootstrap";
import SpinnerComponent from "./../../components/common/spinner";
import _ from "lodash";
import { isMobile } from 'react-device-detect';

class AgentAuthenticate extends AgentAuthenticateModel {
    render() {
        const {
            data,
            errors,
            info,
            showSpinner,
            isRegenerateLink,
            isRegenerateText,
            isSubmitted,
            counter,
            messageText,
            otpSent,
            maskedMobileNo,
            showConfirmMobilePopup,
            showUpdateMobile
        } = this.state;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <Form onSubmit={this.doSubmit}>
                    <h2>Agent Login</h2>
                    <div className="pt-4">
                        {!otpSent && (
                            <React.Fragment>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="mobileNo">
                                        <Form.Label>Enter your Mobile No*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            pattern="[0-9]*"
                                            placeholder="Enter your mobile number"
                                            name="mobileNo"
                                            value={data["mobileNo"]}
                                            onChange={this.handleChange}
                                            onKeyPress={this.handleKeyPressNumeric}
                                            autoComplete="off"
                                            maxLength="10"
                                            autoFocus={true}
                                        // disabled={isValidOTP}
                                        />
                                        {errors["mobileNo"] && (
                                            <div className="alert alert-danger">{errors["mobileNo"]}</div>
                                        )}
                                        {info["mobileNo"] && (
                                            <div className="alert alert-success">{info["mobileNo"]}</div>
                                        )}
                                    </Form.Group>
                                </Form.Row>
                            </React.Fragment>)}
                        {!otpSent && (
                            <Button
                                className="ff-button"
                                type="button"
                                disabled={isSubmitted}
                                onClick={() => this.sendOtpForMobile()}
                                style={{ width: isMobile ? "100%" : 200 }}
                            >
                                Send OTP
                            </Button>
                        )}
                        {otpSent && (
                            <React.Fragment>
                                <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="otp">
                                        <Form.Label>Enter OTP*</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter OTP"
                                            name="otp"
                                            value={data["otp"]}
                                            onChange={this.handleContractOTPKeyChange}
                                            maxLength="6"
                                            autoFocus={true}
                                            autoComplete="one-time-code"
                                            onKeyPress={this.handleKeyPressNumeric}
                                        />{" "}
                                        <Form.Text className="text-muted">
                                            Enter 6-digit  OTP sent to your mobile
                                        </Form.Text>
                                        {info["otp"] && (
                                            <Form.Text
                                                className="text-muted"
                                                style={{ color: "green" }}
                                            >
                                                {info["otp"]}
                                            </Form.Text>
                                        )}
                                        {errors["otp"] && (
                                            <div className="alert alert-danger">{errors["otp"]}</div>
                                        )}{" "}
                                        {isRegenerateLink && (
                                            <Nav.Item id="regenerateOTP">
                                                <Nav.Link
                                                    className="underline"
                                                    onClick={() => this.sendOtp()}
                                                    style={{
                                                        padding: 0,
                                                        float: "right",
                                                        textDecoration: "underline",
                                                    }}
                                                >
                                                    Regenerate OTP
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                        {isRegenerateText && (
                                            <Form.Label>{`Regenerate OTP in ${counter} seconds.`}</Form.Label>
                                        )}
                                    </Form.Group>
                                </Form.Row>
                                <Button
                                    className="ff-button"
                                    type="submit"
                                    disabled={isSubmitted}
                                    style={{ width: isMobile ? "100%" : 150 }}
                                >
                                    Authenticate
                                </Button>
                                <Form.Group
                                    controlId="globalError"
                                    style={{ marginTop: 30 }}
                                ></Form.Group>{" "}
                            </React.Fragment>
                        )}
                        {errors["globalError"] && (
                            <div className="alert alert-danger" style={{ marginTop: 10 }}>{errors["globalError"]}</div>
                        )}
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}
export default AgentAuthenticate;
