import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import DisbursalDetailsModel from "../../models/disbursalV3/disbursalDetailsModel";

class DisbursalDetailV3 extends DisbursalDetailsModel {

  render() {
    const { data, showSpinner, errors, info, eNachDetails, showPopup } = this.state;

    if (
      !auth.getCurrentUser() ||
      auth.getCurrentUser().role.toLowerCase() !== "admin"
    )
      return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2
          className="fundfinaColor"
          style={{ textDecoration: "underline", textAlign: "center" }}
        >
          Loan Disbursal
        </h2>
        <br />
        <br />
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="bankReferenceNo">
              <Form.Label>Bank Reference No</Form.Label>
              <Form.Control
                type="text"
                name="bankReferenceNo"
                value={data["bankReferenceNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
              {errors["bankReferenceNo"] && (
                <div className="alert alert-danger">
                  {errors["bankReferenceNo"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="dateOfTransaction">
              <Form.Label>Date of Disbursal</Form.Label>
              <DatePicker
                name="dateOfTransaction"
                id="dateOfTransaction"
                value={data["dateOfTransaction"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleTransactionDateChange(value)}
                placeholder="Enter Disbursal Date"
              />
              {errors["dateOfTransaction"] && (
                <div className="alert alert-danger">
                  {errors["dateOfTransaction"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="paymentMode">
              <Form.Label>Payment Mode</Form.Label>
              <Form.Control
                as="select"
                name="paymentMode"
                value={data["paymentMode"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="Select" value="">
                  Select
                </option>
                <option key="NEFT" value="NEFT">
                  NEFT
                </option>
                <option key="IMPS" value="IMPS">
                  IMPS
                </option>
              </Form.Control>
              {errors["paymentMode"] && (
                <div className="alert alert-danger">
                  {errors["paymentMode"]}
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="bankCode">
              <Form.Label>Bank</Form.Label>
              <Form.Control
                as="select"
                name="bankCode"
                value={data["bankCode"]}
                onChange={this.handleChange}
              >
                {" "}
                <option key="Select" value="">
                  Select
                </option>
                <option key="IDFC" value="IDFC">
                  IDFC
                </option>
                <option key="HDFC" value="HDFC">
                  HDFC
                </option>
                <option key="IDBI" value="IDBI">
                  IDBI
                </option>
              </Form.Control>
              {errors["bankCode"] && (
                <div className="alert alert-danger">{errors["bankCode"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          {data.trancheNo !== "" && (
            <Form.Row>
              <Form.Label column sm="3">
                Tranche No:{" "}
                <span style={{ color: "black" }}>
                  {data.trancheNo}
                </span>
              </Form.Label>
              <Form.Label column sm="3">
                Amount:{" "}
                <span style={{ color: "black" }}>
                  {data.trancheAmount}
                </span>
              </Form.Label>
              <Form.Label column sm="3">
                Disbursed Amount:{" "}
                <span style={{ color: "black" }}>
                  {data.disbursedAmount}
                </span>
              </Form.Label>
              <Form.Label column sm="3">
                Available Amount:{" "}
                <span style={{ color: "black" }}>
                  {data.availableAmount}
                </span>
              </Form.Label>
            </Form.Row>
          )}
          {eNachDetails && eNachDetails.isEnachRequired && (
            <Form.Row>
              <Form.Label column sm="6">
                e-Nach No:{" "}
                <span style={{ color: "black" }}>
                  {eNachDetails.eNachNo}
                </span>
              </Form.Label>
              <Form.Label column sm="6">
                e-Nach Status:{" "}
                <span style={{ color: eNachDetails.eNachStatus == "register_success" ? "green" : "black", fontWeight: "bold" }}>
                  {eNachDetails.eNachStatus}
                </span>
              </Form.Label>
            </Form.Row>
          )}
          {eNachDetails && eNachDetails.isEnachRequired && (
            <Form.Row>
              {eNachDetails.eNachErrorCode && eNachDetails.eNachErrorCode != "000" && (
                <Form.Label column sm="6">
                  Error Code:{" "}
                  <span style={{ color: "red" }}>
                    {eNachDetails.eNachErrorCode}
                  </span>
                </Form.Label>)}
              {eNachDetails.eNachErrorMessage && eNachDetails.eNachErrorMessage == "e-Mandate not yet registered" && (
                <Form.Label column sm="6">
                  Info:{" "}
                  <span style={{ color: "red" }}>
                    {eNachDetails.eNachErrorMessage}
                  </span>
                </Form.Label>)}
              {eNachDetails.eNachErrorMessage && eNachDetails.eNachErrorMessage != "e-Mandate not yet registered" && (
                <Form.Label column sm="6">
                  Error Message:{" "}
                  <span style={{ color: "red" }}>
                    {eNachDetails.eNachErrorMessage}
                  </span>
                </Form.Label>)}
            </Form.Row>
          )}
          <Form.Row>
            <Form.Group as={Col} md="1">
              <Button
                className="ff-button"
                onClick={this.props.onBack}
                style={{ marginTop: 30, marginLeft: 10 }}
              >
                Back
              </Button>
            </Form.Group>
            {!data.isSaved && (
              <Form.Group as={Col} md="1">
                <Button
                  className="ff-button"
                  type="submit"
                  style={{ marginTop: 30, marginLeft: 10 }}
                >
                  Submit
                </Button>
              </Form.Group>
            )}
            <Form.Group as={Col} md="10">
              {" "}
            </Form.Group>
          </Form.Row>
          {errors["globalError"] && (
            <Form.Row>
              <Form.Group
                controlId="globalError"
                md="12"
                style={{ marginTop: 30 }}
              >
                <div className="alert alert-danger">
                  {errors["globalError"]}
                </div>
              </Form.Group>
            </Form.Row>
          )}
          {info["globalInfo"] && (
            <Form.Row>
              <Form.Group
                controlId="globalInfo"
                md="12"
                style={{ marginTop: 30 }}
              >
                <div className="alert alert-success">{info["globalInfo"]}</div>
              </Form.Group>
            </Form.Row>
          )}
        </Form>
        <Modal show={showPopup} onHide={this.handlePopupClose}>
          <Modal.Header closeButton>
            <Modal.Title>Fundfina Tranche Decision</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to start new Tranche?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="ff-button" onClick={this.handlePopupOk}>
              OK
            </Button>
            <Button variant="secondary" className="ff-button" onClick={event => window.location.href = '/tranche'}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DisbursalDetailV3;
