import React, { Component } from "react";
import _ from "lodash";
import { Redirect } from "react-router-dom";

class RedirectShopType extends React.Component {
    state = {
        data: {
            merchantCode: "",
            partnerCode: "",
        },
        // errors: {},
        // info: {},
        redirect: false,
    };

    async componentDidMount() {
        const merchantCode = this.props.match.params.merchantCode;
        const partnerCode = this.props.match.params.partnerCode;
        const data = { ...this.state.data };

        data.merchantCode = merchantCode;
        data.partnerCode = partnerCode;

        // if (!hideLogo)
        //     hideLogo = "N";

        // const { partner } = await auth.getPartnerCodeFromToken(token);

        // if (partner) {
        //     properties.partnerId = partner.data._id.toString();
        // }
        this.setState({ data, redirect: true });

    }
    render() {
        const { data, redirect } = this.state;
        return (
            redirect && (
                <Redirect
                    exact
                    to={{
                        pathname: '/shopType',
                        state: { userData: data },
                    }}
                />
            )
        );
    }
}
export default RedirectShopType;
