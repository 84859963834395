import http from "../common/httpService";
import { creditApiUrl, apiUrl } from "../../config.json";

const apiCreditEndPoint = creditApiUrl + "admin/";
const apiEndpoint = apiUrl + "/ops/admin";


export async function getPreApprovedList(model) {
    const data = await http.post(apiCreditEndPoint + "getPreApprovalList", model)
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
};

export async function pushPreApproval(model) {
    const data = await http.post(apiCreditEndPoint + "pushPreApproval", model)
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    //console.log(data);
    return data;
};

export async function getEmi(amount, tenure, interestRate, frequency, interestType, productType) {

    productType = productType === "TL" ? "TL_90" : "DL_30";

    const response = await http.post(`${apiEndpoint}/getEmi`, {
        amount: +amount,
        tenure: +tenure,
        interestRate: +interestRate,
        repaymentFrequency: frequency,
        interestType: interestType,
        productType: productType
    });
    return response;
}