import InputDataForm from "../../components/common/form";
import * as trueScoreService from "../../services/trueScore/trueScoreService";
import Joi from "joi-browser";
import * as analytics from "../../utils/analytics";

class TrueScoreTransactionsModel extends InputDataForm {
    state = {
        data: {
            partnerType: "Lender",
            email: "",
            mobile: "",
            pan: "",
            businessDuration: "",
            transactionList: []
        },
        showSpinner: false,
        errors: {},
        info: {},
        lastYear: "",
        lastMonth: "",
        formIsValid: false,
        isTranEmptyCheked: false,
        tnc: ""
    }

    schema = {
        partnerType: Joi.required().label('Selection'),
        email: Joi.any().label('Email'),
        mobile: Joi.number().allow(null, "").label('Mobile'),
        pan: Joi.any().label('Pan'),
        businessDuration: Joi.number().label('Business Duration'),
        transactionList: Joi.any()
    }
    componentDidMount() {
        analytics.track();
    }
    componentWillMount() {
        if (!this.props.location || !this.props.location.state || this.props.location.state.partnerType.trim() === "" || this.props.location.state.email.trim() === "") {
            return (window.location = "/");
        }

        let { data, lastYear, lastMonth } = { ...this.state };
        let currentDate = new Date();
        let month = currentDate.getMonth();
        currentDate = new Date(currentDate.setMonth(month - 1));
        month = currentDate.getMonth();
        let year = currentDate.getFullYear();

        const mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let transactionList = [];
        for (let i = 0; i < 12; i++) {
            transactionList.push({ month: month, monthName: mlist[month], year: year, tranCount: "", tranValue: "" });

            currentDate = new Date(currentDate.setMonth(month - 1));
            month = currentDate.getMonth();
            year = currentDate.getFullYear();
            lastYear = year;
            lastMonth = month + 1;
        }
        data.transactionList = transactionList;
        data.partnerType = this.props.location.state.partnerType;
        data.email = this.props.location.state.email;
        data.mobile = this.props.location.state.mobile;
        // data.partnerType="Borrower";
        // data.email="darshan@fundfina.com";
        // data.mobile="9769985452";
        this.setState({ data, lastYear, lastMonth });
    }

    handleAddRow = async () => {
        const mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let { data, lastYear, lastMonth } = { ...this.state };
        console.log(lastMonth);
        let currentDate = new Date(lastYear, lastMonth, 1, 0, 1, 0);
        let month = currentDate.getMonth();
        currentDate = new Date(currentDate.setMonth(month - 1));
        month = currentDate.getMonth();
        let year = currentDate.getFullYear();
        let transactionList = data.transactionList;
        transactionList.push({ month: month, monthName: mlist[month], year: year, tranCount: "", tranValue: "" });
        data.transactionList = transactionList;
        lastYear = year;
        lastMonth = month;
        this.setState({ data, lastYear, lastMonth });
    }

    handleTranCountBlur = async (e) => {
        if (e.target.value === "") {
            e.target.style.border = "1px solid #dd1515";
        }
        else {
            e.target.style.border = "1px solid #ccc";
        }
    }

    handleTranValueBlur = async (e) => {
        if (e.target.value === "") {
            e.target.style.border = "1px solid #dd1515";
        }
        else {
            e.target.style.border = "1px solid #ccc";
        }
    }

    handleTranValueChange = async (e) => {
        const re = /^(\d*\.)?\d+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            const data = { ...this.state.data };
            let tranName = e.target.name.split('_');

            let tranIndex = data.transactionList.findIndex(x => x.month === parseInt(tranName[1]) && x.year === parseInt(tranName[2]));
            if (tranIndex >= 0) {
                data.transactionList[tranIndex].tranValue = String(e.target.value);
                this.setState({ data });
            }
        }
    }

    handleTranCountChange = async (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            const data = { ...this.state.data };
            let tranName = e.target.name.split('_');
            let tranIndex = data.transactionList.findIndex(x => x.month === parseInt(tranName[1]) && x.year === parseInt(tranName[2]));
            if (tranIndex >= 0) {
                data.transactionList[tranIndex].tranCount = String(e.target.value);
                this.setState({ data });
            }
        }
    }

    async handleCustomErrors(errors) {
        const { data, tnc } = { ...this.state };
        this.setState({ showSpinner: true });
        try {
            const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
            // console.log(panRegex.test(data.pan));
            if (data.partnerType.toLowerCase() === "borrower" && data.pan.trim() === "") {
                errors["pan"] = "Please enter pan number";
                this.setState({ errors, showSpinner: false, formIsValid: false });
                this.refs.pan.focus();
                return errors;
            }
            else if (data.partnerType.toLowerCase() === "borrower" && !panRegex.test(data.pan)) {
                errors["pan"] = "Invalid pan number";
                this.setState({ errors, showSpinner: false, formIsValid: false });
                this.refs.pan.focus();
                return errors;
            }
            else if (data.businessDuration.toLowerCase() === "") {
                this.setState({ errors, showSpinner: false, formIsValid: false });
                this.refs.businessDuration.focus();
                return errors;
            }
            else if (!tnc) {
                errors["tnc"] = "You must agree before submitting.";
                this.setState({ errors, showSpinner: false, formIsValid: false });
                this.refs.tnc.focus();
                return errors;
            }
            else {
                this.setState({ errors, showSpinner: false, formIsValid: true });
            }
        } catch (ex) {
            errors.globalError = ex.response.data;
            this.setState({ errors, showSpinner: false });
        }
        return errors;
    }

    doSubmit = async () => {
        const { data, isTranEmptyCheked, errors } = { ...this.state };
        this.setState({ showSpinner: true });
        let isTranEmpty = false;
        try {
            if (!isTranEmptyCheked) {
                for (let item of data.transactionList) {

                    const tranValue = "tranValue_" + String(item.month) + "_" + String(item.year);
                    if (item.tranValue.trim() === "") {
                        this.refs[tranValue].style.border = "1px solid #dd1515";
                        isTranEmpty = true;
                    }

                    const tranCount = "tranCount_" + String(item.month) + "_" + String(item.year);
                    if (item.tranCount.trim() === "") {
                        this.refs[tranCount].style.border = "1px solid #dd1515";
                        isTranEmpty = true;
                    }
                }
                if (isTranEmpty) {
                    errors["globalError"] = "Are you sure you want to proceed? We have highlighted the fields that have no value.";
                    this.setState({ errors, isTranEmptyCheked: true, showSpinner: false });
                }
            }

            if (isTranEmptyCheked || !isTranEmpty) {
                // console.log("Submitting the document");
                const mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                let tranCount = [];
                let tranValue = [];
                let months = [];
                let years = [];
                for (let item of data.transactionList) {
                    months.push(mlist[item.month]);
                    years.push(String(item.year));
                    const _tranValue = item.tranValue.trim() === "" ? 0 : parseFloat(item.tranValue.trim());
                    tranValue.push(_tranValue);
                    if (item.tranCount.trim() !== "") {
                        tranCount.push(parseInt(item.tranCount.trim()));
                    }
                    else if (item.tranCount.trim() === "" && _tranValue > 0) {
                        tranCount.push(1);
                    }
                    else { tranCount.push(0); }
                }
                const userPan = data.pan.trim() === "" ? "ABCDE1234F" : data.pan.trim();

                let currentDate = new Date();
                let month = currentDate.getMonth();
                let year = currentDate.getFullYear();

                currentDate = new Date(year, month, 1, 0, 0, 0, 0);
                currentDate = new Date(currentDate.setMonth(month - parseInt(data.businessDuration)));

                let options = {
                    enterprise: "fundfina",
                    userType: data.partnerType,
                    email: data.email,
                    mobileNo: data.mobile,
                    businessStartDate: currentDate,
                    pan: userPan,
                    month: months,
                    year: years,
                    tranValue: tranValue,
                    tranCount: tranCount
                }

                // console.log(options);

                const response = await trueScoreService.getTrueScore(options);
                if (response.success) {
                    errors["globalError"] = response.data[0].TRUEscore;
                    this.props.history.push({
                        pathname: "/trueScoreResult",
                        state: {
                            pan: userPan.substr(0, 2) + "XXXXXX" + userPan.substr(8, 2),
                            trueScore: String(response.data[0].TRUEscore)
                        }
                    });
                }
                else {
                    errors["globalError"] = response.message;
                }

                this.setState({ errors, isTranEmptyCheked: true, showSpinner: false });
            }
        }
        catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false });
        }
    }
}

export default TrueScoreTransactionsModel;