import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/";

export async function generateOTPFromUsername(userName, messageType) {
  const { data } = await http.post(
    `${apiEndpoint}verification/generateOTPFromUsername`,
    {
      value: userName.trim(),
      messageType,
    }
  );
  return data;
}

export async function generateOTP(messageType) {
  const { data } = await http.post(
    `${apiEndpoint}verification/generateOTPFromUserId`,
    {
      messageType,
    }
  );
  return data;
}

export async function verifyCustomOTP(userId, otp, messageType , userName) {
  const { data } = await http.post(
    `${apiEndpoint}verification/verifyCustomOTP`,
    {
      // userId,
      otp,
      messageType,
      value : userName
    }
  );
  return data;
}

export async function changePassword(userId, password , otp , userName) {
  const { data } = await http.put(
    `${apiEndpoint}users/updatePassword`,
    {
      password,
      otp,
      value : userName
    }
  );
  return data;
}
