import * as uploadProfilePicService from "../../services/profile/uploadProfilePicService";
import InputForm from "./../../components/common/form";
import _ from "lodash";
import * as analytics from "../../utils/analytics";

class UploadProfilePicFormModel extends InputForm {
  constructor(props) {
    super(props);
  }
  state = {
    files: {
      profileFile: "",
      profileFileStream: "",
      profileFileLabel: ""
    },
    errors: {},
    fileErrors: {},
  };
  async uploadProfileImage() {
    let { errors, files } = this.state;
    this.setState({ showSpinner: true });
    try {
      const { data: response } = await uploadProfilePicService.uploadProfilePic(
        files
      );
      if (response.success) window.location = "/myprofile";
      else {
        errors.globalError = response.message;
        this.setState({ errors, showSpinner: false });
      }
    } catch (ex) {
      errors.globalError = ex.response.message;
      this.setState({ errors, showSpinner: false });
    }
  }
  handleCustomErrors() {
    const files = { ...this.state.files };

    let fileErrors = { ...this.state.fileErrors };

    if (!files.profileFile)
      fileErrors["profileFile"] = "Please upload Profile Image";
    else delete fileErrors["profileFile"];

    this.setState({ fileErrors });

    return fileErrors;
  }
  componentDidMount() {
    analytics.track();
  }
  uploadPicture = async (e) => {
    e.preventDefault();
    let customErrors = this.handleCustomErrors();
    if (_.isEmpty(customErrors)) await this.uploadProfileImage();
  };
}

export default UploadProfilePicFormModel;
