import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./../../context/userContext";
import { getBackupOfManualPaymentUpdate, updateUpiPayment, generatePayoutForUpdatedPayments, getPendingLoanInstallmentsRecords } from '../../services/updateManualUpiPayment/updateManualUpiPaymentService';

const UpdateManualUPIPayment = () => {
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [payoutLoader, setPayoutLoader] = useState(false);
    const [error, setError] = useState({});
    const [payOutError, setPayError] = useState({});
    const [emisList, setEmisList] = useState({});

    const [loanNo, setloanNo] = useState("");
    const [referenceNo, setReferenceNo] = useState("");
    const [utrNo, setUtrNo] = useState("");
    const [userPaidDate, setUserPaidDate] = useState("");
    const [amount, setAmount] = useState("");
    const [emiList, setEmiList] = useState("");

    const [isFormValid, setIsFormValid] = useState(false);
    const [isPayoutinputValid, setIsPayoutinputValid] = useState(false)

    const [payoutLoanNo, setPayoutLoanNo] = useState("");
    const [paymentUpdateDate, setPaymentUpdateDate] = useState("");

    useEffect(() => {
        const isValid = loanNo && referenceNo && utrNo && userPaidDate && amount && emiList;
        const isPayoutFieldValid = payoutLoanNo && paymentUpdateDate
        setIsFormValid(isValid);
        setIsPayoutinputValid(isPayoutFieldValid)
    }, [loanNo, referenceNo, utrNo, userPaidDate, amount, emiList, payoutLoanNo, paymentUpdateDate]);


    useEffect(() => {
        const timer = setTimeout(async () => {
            const pendingEmis = await getPendingLoanInstallmentsRecords({ loanNo });

            if (pendingEmis.success) {
                setEmisList(pendingEmis);
            }
        }, 500);

        return () => {
            clearTimeout(timer);
        };

    }, [loanNo])

    let { user, menu, partner } = useContext(UserContext);

    const convertEmiListToNumbers = (emiList) => {
        return emiList.split(',').map(Number);
    };

    const handleClick = (event) => {
        event.target.showPicker();
    };

    const updateUpiPaymentUpdate = async (e) => {
        e.preventDefault();
        setPaymentLoading(true);
        const updateUpipayment = await updateUpiPayment({
            loanNo,
            referenceNo,
            utrNo,
            userPaidDate,
            amount: Number(amount),
            emiList: convertEmiListToNumbers(emiList),
            updatedBy: user.userId
        });

        if (updateUpipayment.success) {
            setError({ message: updateUpipayment.message, tag: "success" });
            setloanNo("")
            setReferenceNo("")
            setUtrNo("")
            setUserPaidDate("")
            setAmount("")
            setEmiList("")
        } else {
            setError({ message: updateUpipayment.message, tag: "success" })
        }
        setPaymentLoading(false);
        if (Object.keys(error).length > 0) {
            setTimeout(() => {
                setError({});
            }, 5000);
        }
    }

    const downloadPaymentUpdateBackup = async (e) => {
        e.preventDefault()
        setPaymentLoading(true);
        const paymentBackup = await getBackupOfManualPaymentUpdate({
            loanNo,
            referenceNo,
            utrNo,
            userPaidDate,
            amount: Number(amount),
            emiList: convertEmiListToNumbers(emiList),
            updatedBy: user.userId
        });

        if (paymentBackup.success) {
            setError({ message: paymentBackup.message, tag: "success" });
            const data = paymentBackup.data;

            const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'backup.json');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            setError({ message: paymentBackup.message, tag: "danger" })
        }
        setPaymentLoading(false);
        if (Object.keys(error).length > 0) {
            setTimeout(() => {
                setError({});
            }, 5000);
        }
    }

    const downloadPayoutOfLoan = async (e) => {
        e.preventDefault();
        setPayoutLoader(true);
        const body = { loanNo: payoutLoanNo, paymentUpdateDate: paymentUpdateDate };
        const payoutResponse = await generatePayoutForUpdatedPayments(body);
        if (payoutResponse.success) {
            setPayError({ message: payoutResponse.message, tag: "success" });
            setPayoutLoanNo("");
            setPaymentUpdateDate("");
        } else {
            setPayError({ message: payoutResponse.message, tag: "danger" })
        }
        setPayoutLoader(false);
        if (Object.keys(error).length > 0) {
            setTimeout(() => {
                setPayError({});
            }, 5000);
        }
    }

    return (
        <div className="container">
            <div className="page-header my-5">
                <h1 className='text-center'>Update Loan Payment </h1>
            </div>
            <form className="form">
                <div className="form-group">
                    <div className="row">
                        <div className="col-6 mb-3">
                            <label htmlFor="loanNo" className="form-label">Loan No <span>*</span></label>
                            <input onChange={(e) => setloanNo(e.target.value)} type="number" className="form-control" value={loanNo} id="loanNo" aria-describedby="loanNo" />
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="referenceNo" className="form-label">Reference No <span>*</span></label>
                            <input onChange={(e) => setReferenceNo(e.target.value)} type="text" className="form-control" value={referenceNo} id="referenceNo" aria-describedby="referenceNo" />
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="utrNo" className="form-label">UTR No  <span>*</span></label>
                            <input onChange={(e) => setUtrNo(e.target.value)} type="number" className="form-control" value={utrNo} id="utrNo" aria-describedby="utrNo" />
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="userPaidDate" className="form-label">User Paid Date <span>*</span></label>
                            <input onChange={(e) => setUserPaidDate(e.target.value)} type="datetime-local" className="form-control" value={userPaidDate} id="userPaidDate" aria-describedby="userPaidDate" onClick={handleClick} />
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="amount" className="form-label">Amount <span>*</span></label>
                            <input onChange={(e) => setAmount(e.target.value)} type="number" className="form-control" value={amount} id="amount" aria-describedby="amount" />
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="pendingEmis" className="form-label">Pending EMIS </label>
                            <select
                                name="pendingEmis"
                                id="pendingEmis"
                                className='form-control'
                                aria-readonly={true}
                                defaultValue={"Pending EMis Of This loan"} // Uncontrolled component
                            >
                                <option value="Pending EMis Of This loan" disabled>
                                    {Object.keys(emisList).length > 0 && emisList.message !== "Existing records fetched successfully"
                                        ? emisList.message
                                        : "Pending EMIS Of Loan"}
                                </option>
                                {
                                    emisList.data && emisList.data.map(item => {
                                        return (
                                            <option key={item.installmentNumber} disabled value={item.installmentNumber}>{item.installmentNumber}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="emiList" className="form-label">EMIS <span>*</span></label>
                            <input onChange={(e) => setEmiList(e.target.value)} type="text" className="form-control" value={emiList} id="emiList" aria-describedby="emiList" />
                        </div>
                    </div>
                </div>
                {Object.keys(error).length > 0 ? <div className={`alert alert-${error.tag}`}>{error.message}</div> : ""}
                <div className="button-group text-center">
                    {
                        paymentLoading ?
                            <div className="spinner-border " role="status">
                                <span className="sr-only">Loading...</span>
                            </div>

                            :
                            <>
                                <button className="btn ff-button mx-3" onClick={downloadPaymentUpdateBackup} disabled={!isFormValid}> Download Backup</button>
                                <button className="btn ff-button mx-3" onClick={updateUpiPaymentUpdate} disabled={!isFormValid}> Update Payment </button>
                            </>
                    }

                </div>
            </form>


            <div className="genrate-payout my-5 ">
                <div className="section-header mb-5">
                    <h1 className='text-center'>Generate Payout</h1>
                </div>
                <div className="payout-section-body">
                    <form className='form'>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-4 mb-3">
                                    <label htmlFor="payoutLoanNo" className="form-label">Loan No <span>*</span></label>
                                    <input onChange={(e) => setPayoutLoanNo(e.target.value)} type="number" className="form-control" value={payoutLoanNo} id="payoutLoanNo" aria-describedby="payoutLoanNo" />
                                </div>
                                <div className="col-4 mb-3">
                                    <label htmlFor="paymentUpdateDate" className="form-label">Payment UpdatedOn <span>*</span></label>
                                    <input onChange={(e) => setPaymentUpdateDate(e.target.value)} type="date" className="form-control" value={paymentUpdateDate} id="paymentUpdateDate" aria-describedby="paymentUpdateDate" onClick={handleClick} />
                                </div>
                                <div className="col-4 my-auto">
                                    {paymentLoading ?
                                        <div className="spinner-border " role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        : <button className="mt-3  btn ff-button" disabled={!isPayoutinputValid} onClick={downloadPayoutOfLoan}> Generate Payout </button>}
                                </div>
                                {Object.keys(payOutError).length > 0 ? <div className={`alert alert-${payOutError.tag}`}>{payOutError.message}</div> : ""}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateManualUPIPayment