import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import AgentService from '../../services/agent/agent'

import Joi from "joi-browser";
import { orderBy, sortBy } from "lodash";

class AgentReportModel extends InputDataForm {
    state = {
        data: {

            mobileNo: "",
            name: "",
            partnerName: "",
            loanNo: "",
            status: "",
            channel: "",
            amount: "",
            collectionList: [],
        },
        showSpinner: false,
        errors: {},
        currentPage: 1,
        pageSize: 0,
        partnerList: [],
        loanNo: "",
        showErrorModal: false,
        errorMessage: "",
        errorHeader: "",
        loanId: "",
        allCollections: [],
        activeTab: "All",
    };

    schema = {
        mobileNo: Joi.any(),
        name: Joi.any(),
        partnerName: Joi.any(),
        loanNo: Joi.any(),
        fromDate: Joi.any(),
        toDate: Joi.any(),
        page: Joi.any(),
        status: Joi.any(),
        channel: Joi.any(),
        amount: Joi.any(),

    }

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");
        await this.getAgentCollection();
    }

    async getAgentCollection(isAllLoans) {

        let data = this.state.data
        const filter = {
            page: this.state.currentPage,
        };

        if (this.state.data.mobileNo) filter.mobileNo = this.state.data.mobileNo
        if (this.state.data.name) filter.name = this.state.data.name
        if (this.state.data.partnerName) filter.partnerName = this.state.data.partnerName
        if (this.state.data.loanNo) filter.loanNo = this.state.data.loanNo
        if (this.state.data.fromDate) filter.fromDate = this.state.data.fromDate
        if (this.state.data.toDate) filter.toDate = this.state.data.toDate
        if (this.state.data.status) filter.status = this.state.data.status
        if (this.state.data.channel) filter.channel = this.state.data.channel
        if (this.state.data.amount) filter.amount = this.state.data.amount

        if (isAllLoans) {
            filter.isAllLoans = true
        }

        this.setState({ showSpinner: true });
        try {
            const { data: collectionList } = await AgentService.getCollectionLogs(filter);

            if (isAllLoans) {
                let collectionData = collectionList.data
                collectionData = orderBy(collectionData, "insertedOn", "desc")
                this.setState({ allCollections: collectionData, showSpinner: false }, () => {
                    this.setState({ downloadAll: true }, () => {
                        this.setState({ downloadAll: false })
                    })
                });
                return
            }
            data.collectionList = collectionList.data

            if (collectionList.totalPages) {
                this.state.pageSize = collectionList.totalPages
            }

            this.setState({ data, showSpinner: false });
        } catch (ex) {
            this.setState({ data, showSpinner: false });
        }
    }

    async handleCustomErrors(errors) {
        return errors;
    }

    closeModal = () => {
        this.setState({
            showRepaymentModal: false,
            showErrorModal: false
        });
    };

    doSubmit = () => {
        this.resetPage(() => {
            this.getAgentCollection();
        })
    }

    onPageNext = () => {

        if (this.state.pageSize < this.state.currentPage)
            return

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getAgentCollection()
        })
    }

    onPageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.getAgentCollection()
        })
    }

    onPagePrevious = () => {

        if (this.state.currentPage <= 1)
            return

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {
            this.getAgentCollection()
        })
    }

    resetPage = (callback) => {
        this.state.data.page = 1
        this.setState({ currentPage: 1, pageSize: 1 }, callback)
    }

    onClickMore = () => {
        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getAgentCollection(false, true)
        })
    }


    downloadExcel = () => {
        this.getAgentCollection(true)
    }

    onClickFile = async (files) => {
        let file = files[files.length - 1]
        this.openFile(file.url)
    }

    async openLoanDecision(loanId) {
        this.setState({ showSpinner: true });
        try {
            this.setState({
                showSpinner: false,
                showLoanDecisionModal: true,
                loanId: loanId,
            });
        } catch (ex) {
            this.setState({ showSpinner: false });
        }
    }
    closeModal = () => {
        this.setState({
            showLoanDecisionModal: false,
        });
    };


}

export default AgentReportModel;
