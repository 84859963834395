import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as preApprovalService from "../../services/preApproval/preApprovalService";
import Joi from "joi-browser";
import * as trancheService from "../../services/tranche/trancheService";
import * as commonService from '../../services/common/commonService';
import { toast } from "react-toastify";

class PreApprovalModel extends InputDataForm {
    state = {
        data: {
            partnerId: "",
            merchantCode: "",
            month: 1,
            year: 2021,
            trueScoreGT: 500,
            trueScoreLT: 550,
            option: "pending",
            submittedOption: "pending"
        },
        currentYear: 2020,
        showSpinner: false,
        errors: {},
        info: {},
        list: [],
        borrowingPartnerList: [],
        currentPage: 1,
        pageSize: 0,
        currentMonth: 1,
        currentSelectedMonth: 1
    }
    schema = {
        partnerId: Joi.any().label("Partner"),
        merchantCode: Joi.any().label("Merchant Code"),
        month: Joi.number().allow(null, "").label("Month"),
        year: Joi.number().required().label("Year"),
        trueScoreGT: Joi.number().required().label("TrueScore Greater Than"),
        trueScoreLT: Joi.number().required().label("TrueScore Less Than"),
        option: Joi.required().label("Option"),
        submittedOption: Joi.any(),
        page: Joi.any(),

    };

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();
        //console.log("currentUser", currentUser);
        if (currentUser.role != "Admin") return (window.location = "/");
        else {
            const data = { ...this.state.data };
            let currentYear = this.state.currentYear;
            let today = new Date(Date.now());

            data.month = today.getMonth() + 1;
            data.year = today.getFullYear();
            currentYear = today.getFullYear();

            this.setState({ data, currentYear, currentMonth: today.getMonth() + 1, currentSelectedMonth: today.getMonth() + 1 });

            if (this.props.location.state) {
                data.merchantCode = this.props.location.state.merchantCode;
                data.partnerId = this.props.location.state.partnerId;
                data.option = this.props.location.state.option;
                data.submittedOption = this.props.location.state.option;
                data.trueScoreGT = 1;
                data.trueScoreLT = 1000;
                data.month = this.props.location.state.transactionMonth;
                data.year = this.props.location.state.transactionYear;

                this.setState({ data });
                await this.getPreApprovedList(data);
                await this.getPartnerList();
            }
            else {
                await this.getPreApprovedList(data);
                await this.getPartnerList();
            }

        }
    }

    async getPartnerList() {
        let borrowingPartnerList = { ...this.state.borrowingPartnerList };
        let { role, borrowingPartnerName, errors } = { ...this.state };

        this.setState({ showSpinner: true });
        try {
            errors["globalError"] = null;
            // const partnerList = await trancheService.getPartnerList("Borrowing Partner");
            const partnerList = await commonService.getBorrowingPartnerList();

            if (partnerList) {
                if (partnerList.success) {
                    borrowingPartnerList = partnerList.data;
                    if (role === "Borrowing Partner") {
                        const partner = borrowingPartnerList.filter(x => x.partnerName == borrowingPartnerName);

                        if (partner) {
                            let data = { ...this.state.data };
                            data.userId = partner[0]._id;
                            this.setState({ data });
                        }
                    }
                    this.setState({ borrowingPartnerList, errors, successMsg: "", showSpinner: false });
                } else {
                    borrowingPartnerList = [];
                    errors["globalError"] = partnerList.message;
                    this.setState({ borrowingPartnerList, errors, successMsg: null, showSpinner: false });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null, showSpinner: false });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null, showSpinner: false });
        }
    };

    async getPreApprovedList(data) {

        let list = { ...this.state.list };
        let errors = { ...this.state.errors };

        errors["globalError"] = null;

        this.setState({ errors, successMsg: null, showSpinner: true });
        try {
            //console.log(data);

            data.page = this.state.currentPage

            const preApprovalList = await preApprovalService.getPreApprovedList(data);

            if (preApprovalList) {
                if (preApprovalList.success) {
                    const data = { ...this.state.data };
                    list = preApprovalList.data;
                    data.submittedOption = data.option;
                    this.setState({ data, list, errors, successMsg: preApprovalList.message, currentSelectedMonth: data.month, pageSize: preApprovalList.totalPages });
                }
                else {
                    list = preApprovalList.data;
                    errors["globalError"] = preApprovalList.message;
                    this.setState({ list, errors, currentSelectedMonth: data.month });
                }

            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null, currentSelectedMonth: data.month });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.response.data.message;
            this.setState({ errors, showSpinner: false, successMsg: null, currentSelectedMonth: data.month });
        }
    }

    handleRecordChange = async (e) => {
        //console.log(e.target.name);
        // console.log(e.target.value);
        //const re = /^(\d*\.)?\d+%$/;
        //if (e.target.value === "" || re.test(e.target.value)) {
        const list = [...this.state.list];
        let recordFields = e.target.name.split('_');
        //console.log("recordFields[1]", recordFields[1]);
        //console.log("recordFields[2]", recordFields[2]);
        let tranIndex = list.findIndex(x => x.id === recordFields[1] && (recordFields[2] == "null" ? x.trxnId == null : x.trxnId === recordFields[2]));

        if (tranIndex >= 0) {
            //console.log(recordFields[0].toLowerCase());
            if (recordFields[0].toLowerCase() == "processingfee") {
                const value = e.target.value.toString().includes("%") ? e.target.value.toString().replace("%", "").trim() : e.target.value.toString().trim();
                list[tranIndex].processingFee = value.toString() + "%";
            } else if (recordFields[0].toLowerCase() == "annualintrestrate") {
                const value = e.target.value.toString().includes("%") ? e.target.value.toString().replace("%", "").trim() : e.target.value.toString().trim();
                list[tranIndex].annualIntrestRate = value.toString() + "%";
            } else if (recordFields[0].toLowerCase() == "tenureindays") {
                if (e.target.value)
                    list[tranIndex].tenureInDays = parseInt(e.target.value);
                else
                    list[tranIndex].tenureInDays = "";
            } else if (recordFields[0].toLowerCase() == "approvedamount") {
                list[tranIndex].approvedAmount = e.target.value;
            } else if (recordFields[0].toLowerCase() == "loantype") {
                list[tranIndex].loanType = e.target.value;
            } else if (recordFields[0].toLowerCase() == "producttype") {
                list[tranIndex].productType = e.target.value;
            }
            else if (recordFields[0].toLowerCase() == "interesttype") {
                list[tranIndex].interestType = e.target.value;
            }

            const emiAmount = await this.calculateEmi(list[tranIndex]);

            list[tranIndex].emiAmt = emiAmount;

            this.setState({ list });
        }
    }
    calculateEmi = async (data) => {
        let emiAmount = "";
        if (
            (!data.approvedAmount || data.approvedAmount < 0) ||
            (!data.tenureInDays || data.tenureInDays < 0) ||
            (!data.annualIntrestRate || data.annualIntrestRate < 0) ||
            !data.loanType ||
            !data.productType ||
            !data.interestType
        )
            return emiAmount;

        try {
            let interestRate = data.annualIntrestRate.replace("%", "");

            if (!interestRate)
                return emiAmount;

            this.setState({ showSpinner: true });

            interestRate = parseFloat(interestRate);

            const { data: emi } = await preApprovalService.getEmi(data.approvedAmount, data.tenureInDays, interestRate, "Daily", data.interestType, data.productType);

            if (emi.success) {
                emiAmount = emi.data[0].rePaymentAmount;
                this.setState({ showSpinner: false });
            } else {
                toast.error(emi.message || "Invalid Input")
                emiAmount = "";
                this.setState({ showSpinner: false });
            }

            return emiAmount;
        } catch (ex) {
            emiAmount = "";
            this.setState({ showSpinner: false });
            return emiAmount;

        }
    };
    handleApprove = async (e) => {
        const list = [...this.state.list];
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });

        try {
            errors["globalError"] = null;
            let recordFields = e.split('_');
            let tranIndex = list.findIndex(x => x.id === recordFields[1] && (recordFields[2] == "null" ? x.trxnId == null : x.trxnId === recordFields[2]));
            const currentUser = auth.getCurrentUser();

            if (tranIndex >= 0) {
                if (!list[tranIndex].loanType || !list[tranIndex].productType
                    || !list[tranIndex].tenureInDays || !list[tranIndex].processingFee
                    || !list[tranIndex].annualIntrestRate || !list[tranIndex].approvedAmount
                    || !list[tranIndex].emiAmt || !list[tranIndex].interestType
                ) {
                    errors["globalError"] = "all fields are mandatory";
                    this.setState({ showSpinner: false, errors, successMsg: null });
                } else {
                    let options = {
                        partnerId: list[tranIndex].partnerId,
                        partnerTransactionId: list[tranIndex].id,
                        partnerCode: list[tranIndex].partnerCode,
                        merchantCode: list[tranIndex].merchantCode,
                        merchantType: list[tranIndex].merchantType,
                        tenureInDays: list[tranIndex].tenureInDays,
                        processingFee: list[tranIndex].processingFee,
                        annualIntrestRate: list[tranIndex].annualIntrestRate,
                        approvedAmount: list[tranIndex].approvedAmount,
                        emiAmt: list[tranIndex].emiAmt,
                        loanType: list[tranIndex].loanType,
                        productType: list[tranIndex].productType,
                        approvedBy: currentUser.userId,
                        interestType: list[tranIndex].interestType
                    }

                    const preApprovalResponse = await preApprovalService.pushPreApproval(options);

                    if (preApprovalResponse) {
                        if (preApprovalResponse.success) {
                            this.setState({ showSpinner: false, successMsg: preApprovalResponse.message, errors });
                            const data = { ...this.state.data };
                            await this.getPreApprovedList(data);
                        }
                        else {
                            errors["globalError"] = preApprovalResponse.message;
                            this.setState({ showSpinner: false, errors, successMsg: null });
                        }

                    } else {
                        errors["globalError"] = "Some Error Occurred";
                        this.setState({ showSpinner: false, errors, successMsg: null });
                    }
                }
            }
        } catch (ex) {
            console.log(ex);
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null });
        }
    }

    async handleCustomErrors(errors) {
        console.log(errors);
        return errors;
    }

    async doSubmit() {
        this.setState({ currentPage: 1 }, () => {
            const data = { ...this.state.data };
            this.getPreApprovedList(data);
        })
    }

    onPageNext = () => {

        if (this.state.pageSize < this.state.currentPage)
            return

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {

            const data = { ...this.state.data };
            this.getPreApprovedList(data)
        })
    }

    onPageChange = (page) => {
        this.setState({ currentPage: page }, () => {

            const data = { ...this.state.data };
            this.getPreApprovedList(data)
        })
    }

    onPagePrevious = () => {

        if (this.state.currentPage <= 1)
            return

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {

            const data = { ...this.state.data };
            this.getPreApprovedList(data)
        })
    }


}

export default PreApprovalModel;