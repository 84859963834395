import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import InvoiceDashboardModel from "../../models/invoice/invoiceDashboardModel";
import { Form, Col, Button, Modal } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';

class InvoiceDashboard extends InvoiceDashboardModel {
    getPagedData = () => {
        const { pageSize, currentPage, data } = this.state;
        return { totalCount: data.invoiceList.length, data: data.invoiceList };
    };
    render() {
        const {
            pageSize,
            currentPage,
            data,
            showSpinner,
            invoiceStatusList,
            partners,
            evaluatorRole,
            errors,
            invoiceTypeList
        } = this.state;

        const { totalCount, data: invoiceList } = this.getPagedData();

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (

            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>Invoice Module</h2>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="fromDate">
                            <Form.Label>From Date</Form.Label>
                            <DatePicker
                                name="fromDate"
                                id="fromDate"
                                value={data["fromDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleFromDateChange(value)}
                                placeholder="Enter From Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="toDate">
                            <Form.Label>To Date</Form.Label>
                            <DatePicker
                                name="toDate"
                                id="toDate"
                                value={data["toDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleToDateChange(value)}
                                placeholder="Enter To Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="invoiceNo">
                            <Form.Label>Invoice No.</Form.Label>
                            <Form.Control
                                type="text"
                                name="invoiceNo"
                                value={data["invoiceNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="20"
                            />
                        </Form.Group>{" "}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="invoiceAmount">
                            <Form.Label>Invoice Amount</Form.Label>
                            <Form.Control
                                type="text"
                                name="invoiceAmount"
                                value={data["invoiceAmount"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="20"
                            />
                        </Form.Group>{" "}
                        <Form.Group as={Col} md="4" controlId="status">
                            <Form.Label>Invoice Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={data["status"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {invoiceStatusList.map((item, i) => (
                                    <option key={i} value={item.value}>
                                        {item.defination}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="invoiceType">
                            <Form.Label>Invoice Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="invoiceType"
                                value={data["invoiceType"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {invoiceTypeList.map((item, i) => (
                                    <option key={i} value={item.invoiceType}>
                                        {item.invoiceText}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        {evaluatorRole === "Admin" && (
                            <Form.Group as={Col} md="4" controlId="partnerId">
                                <Form.Label>Partner</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="partnerId"
                                    value={data["partnerId"]}
                                    onChange={this.handleChange}
                                >
                                    {" "}
                                    <option key="all" value="all">
                                        ALL
                                    </option>
                                    {partners && partners.map((partner) => (
                                        <option key={partner._id} value={partner._id}>
                                            {partner.displayName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>)}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12">
                            <Button
                                className="ff-button"
                                type="submit"
                            >
                                Apply Filters
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <br />
                {invoiceList.length > 0 && (
                    <>
                        <div style={{ width: '100% !important' }}>
                            <StickyTable stickyHeaderCount={0} rightStickyColumnCount={1} leftStickyColumnCount={2}>
                                <Row>
                                    <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Invoice Date</b></Cell>
                                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Invoice No.</b></Cell>
                                    <Cell style={{ minWidCell: 80, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Billed To</b></Cell>
                                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Billed By</b></Cell>
                                    <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Invoice Type</b></Cell>
                                    <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Status</b></Cell>
                                    {/* <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Units</b></Cell> */}
                                    <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Invoice Amount</b></Cell>
                                    <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Collection Date</b></Cell>
                                    <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>UTR</b></Cell>
                                    <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Total Taxable Amount</b></Cell>
                                    <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>GST</b></Cell>
                                    <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Other Charges</b></Cell>
                                    <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Invoice File</b></Cell>
                                    {evaluatorRole === "Admin" && (
                                        <Cell style={{ minWidCell: 100, textAlign: "center", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Action</b></Cell>
                                    )}
                                </Row>
                                {invoiceList &&
                                    invoiceList.map((invoice, i) => (
                                        <Row key={i}>
                                            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{invoice.invoiceDate && moment(invoice.invoiceDate).format("DD/MM/yyyy")}</Cell>
                                            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                <Link
                                                    to={{
                                                        pathname: `/invoiceDetails/${invoice._id.toString()}`,
                                                    }}
                                                >
                                                    {invoice.invoiceNo}
                                                </Link>
                                            </Cell>
                                            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{invoice.billedTo}</Cell>
                                            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.billedBy}
                                            </Cell>
                                            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.invoiceType}
                                            </Cell>
                                            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.status}
                                            </Cell>
                                            {/* <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{invoice.units}</Cell> */}
                                            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.amount && <i className="fa fa-inr" aria-hidden="true"></i>}
                                                <NumberFormat
                                                    value={invoice.amount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                            </Cell>
                                            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{invoice.settledOn && moment(invoice.settledOn).format("DD/MM/yyyy")}</Cell>
                                            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{invoice.utr}</Cell>
                                            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.totalTaxableAmount && <i className="fa fa-inr" aria-hidden="true"></i>}
                                                <NumberFormat
                                                    value={invoice.totalTaxableAmount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                            </Cell>
                                            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.totalTaxAmount && <i className="fa fa-inr" aria-hidden="true"></i>}
                                                <NumberFormat
                                                    value={invoice.totalTaxAmount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                            </Cell>
                                            <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.totalOtherAmount && <i className="fa fa-inr" aria-hidden="true"></i>}
                                                <NumberFormat
                                                    value={invoice.totalOtherAmount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                />
                                            </Cell>
                                            <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                {invoice.filePath && (
                                                    <a
                                                        href="#"
                                                        onClick={() => this.openInvoiceFileAsTab(invoice.filePath)}
                                                    >
                                                        {" "}
                                                        View
                                                    </a>
                                                )}
                                            </Cell>
                                            {evaluatorRole === "Admin" && (
                                                <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                                    {invoice.status === "Pending For Approval" && (
                                                        <Button
                                                            onClick={() => this.showActionPopUp(invoice)}
                                                            className="ff-button"
                                                            disabled={invoice.status !== "Pending For Approval"}
                                                        >
                                                            Approve / Reject
                                                        </Button>
                                                    )}
                                                    {(invoice.invoiceType === "Disbursal Charges" || invoice.invoiceType === "Collection Charges") && invoice.isValidForPaymentInitiation && (
                                                        invoice.invoiceType && (
                                                            <Button
                                                                onClick={() => this.showActionPopUp(invoice)}
                                                                className="ff-button"
                                                                disabled={false}
                                                            >
                                                                Initiate Payment
                                                            </Button>
                                                        )
                                                    )}
                                                    {(invoice.invoiceType === "Disbursal Charges" || invoice.invoiceType === "Collection Charges") && invoice.isValidForPaymentReInitiation && (
                                                        invoice.invoiceType && (
                                                            <Button
                                                                onClick={() => this.showActionPopUp(invoice)}
                                                                className="ff-button"
                                                                disabled={false}
                                                            >
                                                                Re-Initiate Payment
                                                            </Button>
                                                        )
                                                    )}
                                                </Cell>
                                            )}
                                        </Row>
                                    ))}
                            </StickyTable>
                        </div >

                        <div style={{ width: "100%", overflowX: "scroll" }}>
                            <Pagination
                                currentPage={currentPage}
                                pageSize={pageSize}
                                onPageChange={this.onPageChange}
                                onPagePrevious={this.onPagePrevious}
                                onPageNext={this.onPageNext}
                            />
                        </div>
                    </>
                )}
                {invoiceList.length === 0 && (
                    <>
                        <div style={{ width: '100% !important' }}>
                            <b>No record found</b>
                        </div>
                    </>
                )}
                <Modal show={this.state.showActionPopup} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {" "}
                        <Form style={{ width: "100%" }}>
                            <Form.Row>
                                <Form.Group as={Col} md="12" controlId="actionStatus">
                                    <Form.Label>Invoice No: </Form.Label>{" "}
                                    <span style={{ color: "black" }}>{this.state.invoiceNo}</span>
                                </Form.Group>
                            </Form.Row>
                            {this.state.paymentError && (
                                <Form.Row>
                                    <Form.Group as={Col} md="12" controlId="actionStatus">
                                        <Form.Label>Error: </Form.Label>{" "}
                                        <span style={{ color: "black" }}>{this.state.paymentError}</span>
                                    </Form.Group>
                                </Form.Row>
                            )}
                            {this.state.invoiceStatus === "Pending For Approval" && (
                                <>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="actionStatus">
                                            <Form.Label>Select Action*</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="actionStatus"
                                                value={data["actionStatus"]}
                                                onChange={this.handleChange}
                                            >
                                                {" "}
                                                <option key="select" value="">
                                                    --Select--
                                                </option>
                                                <option key="approve" value="APPROVED">
                                                    Approve
                                                </option>
                                                <option key="reject" value="REJECTED">
                                                    Reject
                                                </option>
                                            </Form.Control>
                                            {errors["actionStatus"] && (
                                                <div className="alert alert-danger">
                                                    {errors["actionStatus"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        {" "}
                                        <Form.Group as={Col} md="12" controlId="actionComment">
                                            <Form.Label>Comments*</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="3"
                                                name="actionComment"
                                                value={data["actionComment"]}
                                                onChange={this.handleChange}
                                            />
                                            {errors["actionComment"] && (
                                                <div className="alert alert-danger">
                                                    {errors["actionComment"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </>
                            )}
                            {(this.state.invoiceType === "Disbursal Charges" || this.state.invoiceType === "Collection Charges") &&
                                (this.state.isValidForPaymentInitiation || this.state.isValidForPaymentReInitiation) && (
                                    <>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="actionStatus">
                                                <Form.Label><b>Beneficiary Account Details:</b></Form.Label>{" "}
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="actionStatus">
                                                <Form.Label>Account Name:  </Form.Label>{" "}
                                                <span style={{ color: "black" }}>{this.state.accountName}</span>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="actionStatus">
                                                <Form.Label>Bank Name:  </Form.Label>{" "}
                                                <span style={{ color: "black" }}>{this.state.bankName}</span>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="actionStatus">
                                                <Form.Label>Account No.:  </Form.Label>{" "}
                                                <span style={{ color: "black" }}>{this.state.accountNo}</span>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId="actionStatus">
                                                <Form.Label>IFSC Code:  </Form.Label>{" "}
                                                <span style={{ color: "black" }}>{this.state.ifscCode}</span>
                                            </Form.Group>
                                        </Form.Row>
                                    </>
                                )}
                            {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.invoiceStatus === "Pending For Approval" && (
                            <>
                                <Button variant="primary" className="ff-button" type="button" onClick={this.approveInvoice}>
                                    Confirm
                                </Button>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button> <br />
                            </>
                        )}
                        {(this.state.invoiceType === "Disbursal Charges" || this.state.invoiceType === "Collection Charges") && this.state.isValidForPaymentInitiation && (
                            <>
                                <Button variant="primary" className="ff-button" type="button" onClick={this.initiatePayment}>
                                    Initiate Payment
                                </Button>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button> <br />
                            </>
                        )}
                        {(this.state.invoiceType === "Disbursal Charges" || this.state.invoiceType === "Collection Charges") && this.state.isValidForPaymentReInitiation && (
                            <>
                                <Button variant="primary" className="ff-button" type="button" onClick={this.reInitiatePayment}>
                                    Re-Initiate Payment
                                </Button>
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button> <br />
                            </>
                        )}
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        );
    }
}

export default InvoiceDashboard;
