import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getManualQueue(model) {

    let url = apiEndpoint + "/manual/queue";
    let keys = Object.keys(model);
    for (let i = 0; i < keys.length; i++) {

        if (model[keys[i]]) {
            if (i == 0)
                url = `${url}?${keys[i]}=${model[keys[i]]}`;
            else
                url = `${url}&${keys[i]}=${model[keys[i]]}`;
        }
    }

    const response = await http.get(url).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return response;
}

export async function manualQueueAction(payload) {
    const { data } = await http.post(apiEndpoint + "/manual/queue/action", payload);
    return data;
}

export async function getSettlementTransactions(payload) {
    const { data } = await http.post(apiEndpoint + "/settlement/transaction", payload);
    return data;
}

export async function getIgnoredMISList(payload) {
    const { data } = await http.post(apiEndpoint + "/igonored/mislog", payload);
    return data;
}