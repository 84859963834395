import React from "react";
import SpinnerComponent from "./../../components/common/spinner";
import ViewScheduleModel from "./../../models/borrow/viewScheduleModel";
import EmiList from "../common/emiList";

class ViewSchedule extends ViewScheduleModel {
  render() {
    const { showSpinner, staticData } = this.state;

    return (
      <React.Fragment>
        {" "}
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
          Loan Schedule
        </h2>
        <br />
        {staticData.loanId && <EmiList loanId={staticData.loanId}></EmiList>}
      </React.Fragment>
    );
  }
}

export default ViewSchedule;
