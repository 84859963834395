import http from "../common/httpService";
import { apiUrl, reconUrl } from "../../config.json";
import { creditApiUrl } from "../../config.json";
import moment from "moment";
import { mode } from "crypto-js";

const apiEndpoint = apiUrl + "/ops/admin";
const creditApiEndpoint = creditApiUrl + "admin";

export async function getReconList(model) {
  const { data } = await http.post(`${apiEndpoint}/reconList`, {
    partner: model.partner,
    fromDate: model.fromDate,
    toDate: model.toDate,
    page: model.page,
    status : model.status
  });
  return data;
}

export async function insertMISLog(model) {
  const { data } = await http.post(`${apiEndpoint}/misLog`, {
    file: model.file,
    fileName: model.fileName
  });
  return data;
}

export async function misLogList(model) {
  const { data } = await http.post(`${apiEndpoint}/misLogList`, {
    partner: model.partner,
    fromDate: model.fromDate,
    toDate: model.toDate,
    page: model.page
  });
  return data;
}

export async function misLogListV2(model) {
  const { data } = await http.post(`${apiEndpoint}/misloglistV2`, {
    fromDate: moment(model.fromDate).format("MM/DD/YYYY"),
    toDate: moment(model.toDate).format("MM/DD/YYYY"),
    page: model.page,
    channel: model.channel,
    status : model.status
  });
  return data;
}

export async function settlementAlert(model) {
  const { data } = await http.post(`${apiEndpoint}/webhookLogsList`, {
    fromDate: moment(model.fromDate).format("MM/DD/YYYY"),
    toDate: moment(model.toDate).format("MM/DD/YYYY"),
    page: model.page,
    utrNo : model.utrNo,
    amount : model.amount,
    vaAccount : model.vaAccount
  });
  return data;
}

export async function payoutInit(model) {
  const { data } = await http.post(`${reconUrl}/api/payout/init`, {
    lendingPartnerId: model.lendingPartnerId
  });
  return data;
}

export async function getMisDailyReport(model) {
  const { data } = await http.post(`${apiEndpoint}/dailymisreport`, {
    partnerId: model.partner,
    fromDate: moment(model.fromDate).format("MM/DD/YYYY"),
    toDate: moment(model.toDate).format("MM/DD/YYYY"),
    page: model.page
  });
  return data;
}

export async function getSettledInformationDatewise(model) {
  const { data } = await http.post(`${apiEndpoint}/getsettlementinformationdatewise`, {
    utrNo: model.utrNo
  });
  return data;
}

export async function getSettlementPendingDateWise(model) {
  const { data } = await http.post(`${apiEndpoint}/getPendingSettlementDateWise`, {
    partnerId: model.partner,
    fromDate: moment(model.fromDate).format("MM/DD/YYYY"),
    toDate: moment(model.toDate).format("MM/DD/YYYY"),
    channel : model.channel,
    product : model.product
  });
  return data;
}

export async function getSettlementMISUTR(model) {
  const { data } = await http.post(`${apiEndpoint}/getsettlementmisutr`, {
    partner: model.partner,
    fromDate: model.fromDate,
    toDate: model.toDate,
    page: model.page,
    status : model.status
  });
  return data;
}