import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as portfolioService from "../../services/portfolio/portfolioService";
import Joi, { wait } from "joi-browser";
import * as commonService from "../../services/common/commonService";
import { getLoanDetailsForTeleCalling, updateTeleCallingActions } from "../../services/loan/loanService";
import { toast } from "react-toastify";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";

class TeleCallingDetailsRenewalsModel extends InputDataForm {
    state = {
        showSpinner: false,
        data :{
            callOutcome : "",
            borrowerResponse : "",
            note : "",
            callbackOn : "",
            willApplyOn : "",
            date :"",
            notConnectedReason :"",
            isRenewals : true
        },
        callOutcomeList : [],
        borrowerResponseList : [],
        notConnectedReasonList : [],
        loan : {},
        userInfo : {},
        tabs : [
          {
            title : "Action",
            value : "ACTION"
          },
          {
            title : "History",
            value :"HISTORY"
          }
        ],
        activeTab : "ACTION",
        showDate : false
    };

    schema = {
        callOutcome : Joi.any(),
        borrowerResponse : Joi.any(),
        note : Joi.any(),
        callbackOn : Joi.any(),
        willApplyOn : Joi.any(),
        date : Joi.any(),
        notConnectedReason : Joi.any(),
        isRenewals : Joi.any()
    };

    async updateLoanTeleCallingAction(){
      let loanId = this.props.loanId;

      if(!loanId)
        return
       
      if(!this.state.showDate && this.state.data.date){
        this.state.data.date = ""
      }

      if(this.state.data.callOutcome == "Not Connected" && this.state.data.borrowerResponse){
        this.state.data.borrowerResponse = ""
      }
      
      let isValid = this.validatePayload(this.state.data)

      if(!isValid)
        return

      this.setState({showSpinner : true})
      try{
        let res =  await updateTeleCallingActions(loanId , this.state.data)

        if(res && res.data && !res.data.success){
          toast.error("Oops something went wrong");
          this.setState({showSpinner : false})
          return
        }
        await this.getLoanDetails()
        this.resetPage()
        this.setState({showSpinner :false})
      }catch(err){
        this.setState({showSpinner : false})
      }
    }

    resetPage =()=>{

      let data = {
        callOutcome : "",
        borrowerResponse : "",
        note : "",
        callbackOn : "",
        willApplyOn : "",
        date :""
    }
      this.setState({ data , reset : true },()=>{
        this.setState({reset : false})
      })
    }

    async getLoanDetails(){
      let loanId = this.props.loanId;

      if(!loanId)
        return

      try{
      let loan =  await getLoanDetailsForTeleCalling(loanId)
      loan = loan.data
      let data = {...this.state.data}

      if(loan.note){
        data.note = loan.note
        this.setState({ data })
      }
      this.setState({ loan , userInfo : loan && loan.userInfo ? loan.userInfo : {}})
      }catch(err){

      }
    }

    validatePayload =(payload)=>{

      if(!payload.callOutcome){
        toast.error("Please select call outcome");
        return false
      }

      if(payload.callOutcome == 'Connected Successfully' && !payload.borrowerResponse){
        toast.error("Please select borrower response");
        return false
      }

      if(payload.borrowerResponse && (this.state.showDate) && !payload.date){
        toast.error("Please select date");
        return false
      }

      if(payload.callOutcome == 'Not Connected' && !payload.notConnectedReason){
        toast.error("Please select not connected reason");
        return false
      }


      return true
    }

    handleOnChangeNote = (e) =>{

      let data = { ...this.state.data }
      data.note = e.target.value;
      this.setState({ data })
    }
    
    async getBorrowerResponseList() {
        let { borrowerResponseList } = this.state;
        try {
          const {
            data: borrowerListResponse,
          } = await commonService.getRoleWiseDefinitionList("borrowerResponseRenewals");
    
          borrowerResponseList = borrowerListResponse.data;
    
          this.setState({
            borrowerResponseList
          });
        } catch (ex) { }
    };

    async notConnectedReasonList() {
      let { notConnectedReasonList } = this.state;
      try {
        const {
          data: notConnectedReasonListResponse,
        } = await commonService.getRoleWiseDefinitionList("notConnectedReasonsRenewals");
  
        notConnectedReasonList = notConnectedReasonListResponse.data;
  
        this.setState({
          notConnectedReasonList
        });
      } catch (ex) { }
  };

    onTabChange =(tab)=>{
      this.setState({activeTab : tab.value})
    }

    handleCalloutcomeChange =({ currentTarget: input })=> {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
    
        const data = { ...this.state.data };
        data[input.name] = input.value;

        let index = null

        for(let i = 0 ; i < this.state.borrowerResponseList.length ; i++){

          if(this.state.borrowerResponseList[i].defination == input.value){
            index = i;
          }
        }

        if(index != null){
          let option = this.state.borrowerResponseList[index]

          if(option.showDate)
            this.state.showDate = true
          else
            this.state.showDate = false
        }
        
        this.setState({ data, errors });
    }

    handleNotConnectedReasonChange =({ currentTarget: input })=> {
      const errors = { ...this.state.errors };
      const errorMessage = this.validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
  
      const data = { ...this.state.data };
      data[input.name] = input.value;
      this.setState({ data, errors });
  }

    async componentDidMount() {
        console.log('user....',auth.getCurrentUser())
        if (!auth.getCurrentUser()) return (window.location = "/login");

        await this.getLoanDetails()
        await this.getBorrowerResponseList()
        await this.notConnectedReasonList()
    }

    async doSubmit() {
      await this.updateLoanTeleCallingAction()
    }

    async handleCustomErrors() {
    }

    // async handleSendReview(loanId) {
    //   this.setState({ showSpinner: true });
    //   const errors = { ...this.state.errors };
    //   errors["globalError"] = null;
    //   try {
    //     let response = await loanApplicationServices.sendReview(loanId);
  
    //     if (response.success) {
    //       this.setState({ showSpinner: false, successMsg: response.message, errors });
    //     } else {
    //       errors["globalError"] = response.message;
    //       this.setState({ showSpinner: false, errors, successMsg: null });
    //     }
    //   } catch (ex) {
    //     errors["globalError"] = ex.message;
    //     this.setState({ showSpinner: false, errors, successMsg: null });
    //   }
    // };

    // async handleSendContract(loanId) {
    //   this.setState({ showSpinner: true });
    //   const errors = { ...this.state.errors };
    //   errors["globalError"] = null;
    //   try {
    //     let response = await loanApplicationServices.sendLoanContract(loanId);
  
    //     if (response.success) {
    //       this.setState({ showSpinner: false, successMsg: response.message, errors });
    //     } else {
    //       errors["globalError"] = response.message;
    //       this.setState({ showSpinner: false, errors, successMsg: null });
    //     }
  
    //   } catch (ex) {
    //     errors["globalError"] = ex.message;
    //     this.setState({ showSpinner: false, errors, successMsg: null });
    //   }
    // };

    // async handSendBankLink(loanId) {
    //   this.setState({ showSpinner: true });
    //   const errors = { ...this.state.errors };
    //   errors["globalError"] = null;
    //   try {
    //     let response = await loanApplicationServices.sendNewMandateOrUpdateBankListRequest(loanId, "BankDetails");
  
    //     if (response.success) {
    //       this.setState({ showSpinner: false, successMsg: response.message, errors });
    //     } else {
    //       errors["globalError"] = response.message;
    //       this.setState({ showSpinner: false, errors, successMsg: null });
    //     }
  
    //   } catch (ex) {
    //     errors["globalError"] = ex.message;
    //     this.setState({ showSpinner: false, errors, successMsg: null });
    //   }
    // };

    handleChangeCheckbox =(callOutcome , value)=>{

      if(this.state.showDate){
        this.state.showDate = false
      }

      let data = {...this.state.data}
      data.callOutcome = callOutcome
      this.setState({ data })
    }
}

export default TeleCallingDetailsRenewalsModel;
