import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import LoanInfoFormModel from "../../models/loan/loanInfoFormModel";
import SpinnerComponent from "../../components/common/spinner";
import {
  Form,
  Col,
  Row,
  Image,
  Button,
  Table,
  Modal,
  FormCheck,
} from "react-bootstrap";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import NumberFormat from "react-number-format";
import LineChartComponent from "./../../components/common/lineChart";
import TeleCallingDetailsRenewals from "../../views/teleCallingDetailsRenewals/teleCallingDetailsRenewals";
import CollectionQueueTeleCalling from "../collectionQueueTeleCallingDetails/collectionQueueTeleCallingDetails";
import TeleCallingDetails from "../../views/teleCallingDetails/teleCallingDetails";
import EmiList from "../common/emiList";
import UserCentricData from "../userCentricData/userCentricData";
import ShopDetails from "../borrow/shopDetails";

class LoanInfoForm extends LoanInfoFormModel {
  getPagedTransactionData = () => {
    const { pageSize, currentTransactionPage, staticData } = this.state;

    let transactionDetails = staticData.transactionDetails;

    const filteredTransactions = paginate(
      transactionDetails,
      currentTransactionPage,
      pageSize
    );

    return {
      totalTransactionCount: staticData.transactionDetails
        ? staticData.transactionDetails.length
        : 0,
      data: filteredTransactions,
    };
  };
  getPagedInvestmentData = () => {
    const { pageSize, currentInvestmentPage, staticData } = this.state;

    let investments = staticData.investments;

    const filteredInvestments = paginate(
      investments,
      currentInvestmentPage,
      pageSize
    );

    return {
      totalInvestmentCount: staticData.investments
        ? staticData.investments.length
        : 0,
      data: filteredInvestments,
    };
  };
  getPageLoanHistoryData = () => {
    const { pageSize, currentLoanHistoryPage, staticData } = this.state;

    let loanHistory = staticData.loanHistory;

    const filteredHistory = paginate(
      loanHistory,
      currentLoanHistoryPage,
      pageSize
    );

    return {
      totalLoanHistoryCount: staticData.loanHistory
        ? staticData.loanHistory.length
        : 0,
      data: filteredHistory,
    };
  };
  getPageFundfinaLoanHistoryData = () => {
    const { pageSize, currentFundfinaLoanHistoryPage, staticData } = this.state;

    let loanHistory = staticData.fundfinaLoanHistory;

    const filteredHistory = paginate(
      loanHistory,
      currentFundfinaLoanHistoryPage,
      pageSize
    );

    return {
      totalFundfinaLoanHistoryCount: staticData.fundfinaLoanHistory
        ? staticData.fundfinaLoanHistory.length
        : 0,
      data: filteredHistory,
    };
  };
  getPageTrueScoreData = () => {
    const { pageSize, currentTrueScorePage, trueScoreList } = this.state;

    let list = trueScoreList;

    const filteredTrueScoreList = paginate(
      list,
      currentTrueScorePage,
      pageSize
    );

    return {
      totalTrueScoreCount: list ? list.length : 0,
      data: filteredTrueScoreList,
    };
  };
  getTransaction2Count = () => {
    const { pageSize, currentTransaction2Page, transaction2List } = this.state;

    let list = transaction2List;

    const filteredTransaction2Count = paginate(
      list,
      currentTransaction2Page,
      pageSize
    );

    return {
      totalTransaction2Count: list ? list.length : 0,
      data: filteredTransaction2Count,
    };
  };
  getPagedLenderRejectionList = () => {
    const { pageSize, currentLenderRejectionPage, staticData } = this.state;

    let lenderRejectionList = staticData.lenderRejectionList;

    const filteredLenderRejectionList = paginate(
      lenderRejectionList,
      currentLenderRejectionPage,
      pageSize
    );

    return {
      totalLenderRejectionCount: staticData.lenderRejectionList
        ? staticData.lenderRejectionList.length
        : 0,
      data: filteredLenderRejectionList,
    };
  };

  renderSummaryTab() {
    let { staticData } = this.state;
    if (staticData.evaluatorRole !== "Admin") return "";
    return (
      <Tab eventKey="summary" title="Summary">
        <br />
        <h2
          className="fundfinaColor"
          style={{ textDecoration: "underline", textAlign: "center" }}
        >
          Summary
        </h2>{" "}
        <br />
        {staticData.loanId && this.state.activeTab === "summary" && (
          <UserCentricData
            {...this.props}
            loanId={staticData.loanId}
            merchantCode={staticData.merchantCode}
            partnerId={staticData.partnerId}
          />
        )}
      </Tab>
    );
  }

  render() {
    const {
      staticData,
      data,
      showSpinner,
      optionalDocumentList,
      errors,
      repaymentFrequencyList,
      partnerList,
      currentTransactionPage,
      currentInvestmentPage,
      currentLoanHistoryPage,
      currentFundfinaLoanHistoryPage,
      currentTrueScorePage,
      currentTransaction2Page,
      pageSize,
      isSubmitted,
      displayTransaction1,
      displayTrueScore,
      monthList,
      yearList,
      optional,
      optionalGst,
      rejectReasonList,
      bankDoc,
      bankDocDisplay,
      gstDoc,
      gstDocDisplay,
      panDoc,
      panDocDisplay,
      poaDoc,
      poaDocDisplay,
      panDocVerification,
      showEnachPopUp,
      activeTab,
      label,
      score,
      creditLimit,
      gstInferanceDetails,
      bankInferanceDetails,
      fromMonth,
      fromYear,
      toMonth,
      toYear,
      showTrueScorePopUp,
      trueScoreError,
      graphToggle,
      sendUpdateMandateLink,
      showInfoPopup,
      infoHeader,
      infoMessage,
      currentLenderRejectionPage,
      files,
      photoMatchDropdownOptions,
      userData,
      foreclosureDetails,
      successMsgForeclosure,
      gstTableData,
      bankStatementTableData,
      trueScoreAmountDetails
    } = this.state;
    const { totalTransactionCount, data: transactions } =
      this.getPagedTransactionData();

    const { totalInvestmentCount, data: investments } =
      this.getPagedInvestmentData();

    const { totalLoanHistoryCount, data: loanHistory } =
      this.getPageLoanHistoryData();

    const { totalFundfinaLoanHistoryCount, data: fundfinaLoanHistory } =
      this.getPageFundfinaLoanHistoryData();

    const { totalTrueScoreCount, data: trueScoreList } =
      this.getPageTrueScoreData();

    const { totalTransaction2Count, data: transaction2List } =
      this.getTransaction2Count();

    const { totalLenderRejectionCount, data: lenderRejectionList } =
      this.getPagedLenderRejectionList();

    return (
      <React.Fragment>
        {" "}
        <SpinnerComponent showSpinner={showSpinner} />
        <Tabs
          activeKey={activeTab}
          id="uncontrolled-tab-example"
          onSelect={this.handleSelect}
          className="tab-fixed-top"
        >
          {this.props.showSummaryFirst && this.renderSummaryTab()}
          <Tab eventKey="loanTab" title="Overview">
            <React.Fragment>
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                {staticData.evaluatorRole === "Admin" ? "Loan Decision" : ""}{" "}
                {staticData.evaluatorRole !== "Admin" ? "Loan Details" : ""}
              </h2>{" "}
              <Form>
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Basic Information
                </h5>{" "}
                {(staticData.userRole === "User" ||
                  staticData.userRole === "Admin") && (
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column sm="6">
                      Full Name:{"  "}
                      <span
                        style={{ color: "black", textTransform: "uppercase" }}
                      >
                        {staticData.fullName}
                      </span>
                    </Form.Label>

                    <Form.Label column sm="6">
                      {staticData.evaluatorRole === "Admin" &&
                        (staticData.loanStatus === "Lender Assigned" ||
                          staticData.loanStatus === "Initiated Fund Transfer" ||
                          staticData.loanStatus === "Ready For Disbursal" ||
                          staticData.loanStatus === "Loan In Progress" ||
                          staticData.loanStatus === "Written-Off") && (
                          <Button
                            onClick={() =>
                              this.regenerateLoanContract(staticData?.loanNo)
                            }
                            className="ff-button float-right"
                            disabled={this.state.isDisabledContractButton}
                          >
                            {this.state.isDisabledContractButton
                              ? "Generating Contract..."
                              : "Generate Contract"}
                          </Button>
                        )}
                      {(staticData.evaluatorRole === "Admin" ||
                        staticData.evaluatorRole === "Lending Partner") && (
                        <Button
                          onClick={() =>
                            this.downloadAllDocuments(
                              staticData.loanId,
                              staticData?.loanNo
                            )
                          }
                          className="ff-button float-right"
                          disabled={
                            !this.checkAfterLenderAssignment(
                              staticData.loanStatus
                            )
                          }
                          style={{ marginRight: 10 }}
                        >
                          {this.state.downloadAllLoanId != staticData.loanId
                            ? "Download Docs"
                            : "Downloading..."}
                        </Button>
                      )}
                    </Form.Label>
                  </Form.Group>
                )}
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Email Address:{" "}
                    <span style={{ color: "black" }}>{staticData.emailId}</span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Mobile Number:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.mobileNo}
                    </span>
                  </Form.Label>
                </Form.Group>{" "}
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="4">
                    Alternate Email:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.alternateEmail}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Alternate Mobile:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.alternateMobile}
                    </span>
                  </Form.Label>
                </Form.Group>
                {staticData.panNo && (
                  <Form.Group as={Row} controlId="pan">
                    <Form.Label column sm="6">
                      PAN Number:{" "}
                      <span
                        style={{ color: "black", textTransform: "uppercase" }}
                      >
                        {staticData.evaluatorRole === "Admin" &&
                        staticData.panNo
                          ? staticData.panNo
                          : ""}
                        {staticData.evaluatorRole !== "Admin" &&
                        staticData.panNo
                          ? staticData.panNo.substr(0, 3) + "XXXXXXX"
                          : ""}
                      </span>{" "}
                      {panDocVerification &&
                        panDocVerification.isDocVerified &&
                        panDocVerification.isNumberVerified &&
                        panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-check"
                            aria-hidden="true"
                            style={{
                              color: "green",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {panDocVerification &&
                        !panDocVerification.isDocVerified &&
                        !panDocVerification.isNumberVerified &&
                        !panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            style={{
                              color: "red",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {panDocVerification &&
                        !panDocVerification.isDocVerified &&
                        panDocVerification.isNumberVerified &&
                        panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-exclamation"
                            aria-hidden="true"
                            style={{
                              color: "orange",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {panDocVerification &&
                        panDocVerification.isDocVerified &&
                        !panDocVerification.isNumberVerified &&
                        panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-exclamation"
                            aria-hidden="true"
                            style={{
                              color: "orange",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {panDocVerification &&
                        panDocVerification.isDocVerified &&
                        panDocVerification.isNumberVerified &&
                        !panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-exclamation"
                            aria-hidden="true"
                            style={{
                              color: "orange",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {panDocVerification &&
                        !panDocVerification.isDocVerified &&
                        !panDocVerification.isNumberVerified &&
                        panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-exclamation"
                            aria-hidden="true"
                            style={{
                              color: "orange",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {panDocVerification &&
                        !panDocVerification.isDocVerified &&
                        panDocVerification.isNumberVerified &&
                        !panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-exclamation"
                            aria-hidden="true"
                            style={{
                              color: "orange",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {panDocVerification &&
                        panDocVerification.isDocVerified &&
                        !panDocVerification.isNumberVerified &&
                        !panDocVerification.isDocMatch && (
                          <i
                            className="fa fa-exclamation"
                            aria-hidden="true"
                            style={{
                              color: "orange",
                              fontSize: "1.3em",
                              cursor: "pointer",
                            }}
                            onClick={this.showPanDocInfo}
                          ></i>
                        )}
                      {!panDocVerification && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{ color: "red", fontSize: "1.3em" }}
                        ></i>
                      )}
                      {staticData.panFilePath && (
                        <a
                          href="#"
                          onClick={() =>
                            this.openFileAsTab(staticData.panFilePath)
                          }
                        >
                          {" "}
                          View
                        </a>
                      )}
                    </Form.Label>
                  </Form.Group>
                )}
                {(staticData.userRole === "User" ||
                  data.userRole === "Admin") &&
                  staticData.poaDetails.map((item, i) => (
                    <React.Fragment key={i}>
                      <Form.Group as={Row} controlId="poa">
                        <Form.Label column sm="4">
                          POA Number:{" "}
                          <span style={{ color: "black" }}>{item.poaNo}</span>{" "}
                          {item.docVerification &&
                            item.docVerification.isDocVerified &&
                            item.docVerification.isNumberVerified &&
                            item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-check"
                                aria-hidden="true"
                                style={{
                                  color: "green",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {item.docVerification &&
                            !item.docVerification.isDocVerified &&
                            !item.docVerification.isNumberVerified &&
                            !item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {item.docVerification &&
                            !item.docVerification.isDocVerified &&
                            item.docVerification.isNumberVerified &&
                            item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-exclamation"
                                aria-hidden="true"
                                style={{
                                  color: "orange",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {item.docVerification &&
                            item.docVerification.isDocVerified &&
                            !item.docVerification.isNumberVerified &&
                            item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-exclamation"
                                aria-hidden="true"
                                style={{
                                  color: "orange",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {item.docVerification &&
                            item.docVerification.isDocVerified &&
                            item.docVerification.isNumberVerified &&
                            !item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-exclamation"
                                aria-hidden="true"
                                style={{
                                  color: "orange",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {item.docVerification &&
                            !item.docVerification.isDocVerified &&
                            !item.docVerification.isNumberVerified &&
                            item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-exclamation"
                                aria-hidden="true"
                                style={{
                                  color: "orange",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {item.docVerification &&
                            !item.docVerification.isDocVerified &&
                            item.docVerification.isNumberVerified &&
                            !item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-exclamation"
                                aria-hidden="true"
                                style={{
                                  color: "orange",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {item.docVerification &&
                            item.docVerification.isDocVerified &&
                            !item.docVerification.isNumberVerified &&
                            !item.docVerification.isDocMatch && (
                              <i
                                className="fa fa-exclamation"
                                aria-hidden="true"
                                style={{
                                  color: "orange",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showPoaDocInfo(item)}
                              ></i>
                            )}
                          {!item.docVerification && (
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              style={{ color: "red", fontSize: "1.3em" }}
                            ></i>
                          )}{" "}
                          {item.docDetails.map(
                            (docItem, i) =>
                              docItem.poaFilePath && (
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.openFileAsTab(docItem.poaFilePath)
                                  }
                                  key={i}
                                >
                                  {" "}
                                  <span>{`File ${i + 1}`}</span>
                                </a>
                              )
                          )}
                        </Form.Label>
                        <Form.Label column sm="4">
                          POA Type:{" "}
                          <span style={{ color: "black" }}>{item.poaType}</span>{" "}
                        </Form.Label>
                        <Form.Label column sm="4">
                          POA Verification Type:{" "}
                          <span style={{ color: "black" }}>
                            {item.poaDocType}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      {staticData.evaluatorRole === "Admin" &&
                        item.docDetails &&
                        item.docDetails[0] &&
                        item.docDetails[0].poaDocPassword && (
                          <Form.Group as={Row} controlId="poaDocPassword">
                            <Form.Label column sm="4">
                              POA Document Password:{" "}
                              <span style={{ color: "black" }}>
                                {item.docDetails[0].poaDocPassword}
                              </span>{" "}
                            </Form.Label>
                          </Form.Group>
                        )}
                    </React.Fragment>
                  ))}
                <Form.Group as={Row} controlId="employment">
                  {staticData.employment && (
                    <Form.Label column sm="4">
                      Employment:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.employment}
                      </span>{" "}
                    </Form.Label>
                  )}
                  {staticData.kycStatus && (
                    <Form.Label column sm="4">
                      Kyc Status:{" "}
                      <span
                        style={{
                          color: staticData.kycStatusColor,
                          fontWeight: "bold",
                        }}
                      >
                        {staticData.kycStatus}
                      </span>{" "}
                    </Form.Label>
                  )}
                  {staticData.clientCode && (
                    <Form.Label column sm="4">
                      Client Code:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.clientCode}
                      </span>{" "}
                    </Form.Label>
                  )}
                </Form.Group>
                <Form.Group as={Row} controlId="role">
                  {staticData.dob && (
                    <Form.Label column sm="4">
                      Date of Birth:{" "}
                      <span style={{ color: "black" }}>{staticData.dob}</span>{" "}
                    </Form.Label>
                  )}
                  {staticData.doi && (
                    <Form.Label column sm="4">
                      Date of Incorporation:{" "}
                      <span style={{ color: "black" }}>{staticData.doi}</span>{" "}
                    </Form.Label>
                  )}
                  {staticData.typeOfEntity && (
                    <Form.Label column sm="4">
                      Type of Entity:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.typeOfEntity}
                      </span>{" "}
                    </Form.Label>
                  )}
                  {staticData.userRole && (
                    <Form.Label column sm="4">
                      User Role:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.userRole}
                      </span>{" "}
                    </Form.Label>
                  )}
                </Form.Group>{" "}
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Loan Information
                </h5>{" "}
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Loan No:{" "}
                    <span style={{ color: "black" }}>{staticData.loanNo}</span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Amount:{" "}
                    <span style={{ color: "black" }}>
                      <NumberFormat
                        value={staticData.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Product Type:{" "}
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {staticData.productType}
                    </span>
                  </Form.Label>
                </Form.Group>{" "}
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="4">
                    Loan Category:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.loanCategory}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Frequency:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.frequency}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Interest Rate:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.roi}
                      {staticData.roi && (
                        <i className="fa fa-percent" aria-hidden="true"></i>
                      )}
                    </span>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="4">
                    Maturity:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.maturity}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Loan Status:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.loanStatus}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Post Disbursal Status:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.postDisbursalStatus}
                    </span>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="4">
                    Loan Type:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.loanType}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Creation Date:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.loanGenDate}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Purpose:{" "}
                    <span style={{ color: "black" }}>{staticData.purpose}</span>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="4">
                    Description:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.description}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Additional Description:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.additionalDescription}
                    </span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Campaign Type:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.campaignTime}
                    </span>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} controlId="name">
                  {staticData.merchantCode && (
                    <Form.Label column sm="4">
                      Merchant Code:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.merchantCode}
                      </span>
                    </Form.Label>
                  )}
                  <Form.Label column sm="4">
                    Initiated From:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.partnerName}
                    </span>
                  </Form.Label>
                  {staticData.merchantType && (
                    <Form.Label column sm="4">
                      Merchant Type:{" "}
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {staticData.merchantType}
                      </span>
                    </Form.Label>
                  )}
                </Form.Group>
                {staticData.primaryCollectionType && (
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column sm="4">
                      Primary Collection:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.primaryCollectionType}
                      </span>
                    </Form.Label>
                    <Form.Label column sm="4">
                      Secondary Collection:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.secondaryCollectionType
                          ? staticData.secondaryCollectionType
                          : "-"}
                      </span>
                    </Form.Label>
                    {staticData.evaluatorRole === "Admin" &&
                      staticData.primaryCollectionType && (
                        <Form.Label column sm="4">
                          {staticData.primaryCollectionType === "eNach" ||
                          staticData.secondaryCollectionType === "eNach"
                            ? "e-Nach Url: "
                            : "Contract Url: "}
                          <span style={{ color: "black" }}>
                            {staticData.contractUrl}
                          </span>
                        </Form.Label>
                      )}
                  </Form.Group>
                )}
                {staticData.evaluatorRole === "Admin" &&
                  sendUpdateMandateLink && (
                    <Form.Group as={Row} controlId="name">
                      <Form.Label column sm="4">
                        Assign e-Nach:{" "}
                        <a href="#" onClick={() => this.handleEnachPopupOpen()}>
                          {" "}
                          Send Request
                        </a>
                      </Form.Label>
                    </Form.Group>
                  )}
                <Form.Group as={Row} controlId="name">
                  {staticData.disbursalDate && (
                    <Form.Label column sm="4">
                      Disbursal Date:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.disbursalDate}
                      </span>
                    </Form.Label>
                  )}
                  {staticData.evaluatorRole === "Admin" && (
                    <Form.Label column sm="4">
                      Recommended Lending Partner:{" "}
                      <span
                        style={{
                          color: "black",
                        }}
                      >
                        {staticData.recommandedLendingPartner}
                      </span>
                    </Form.Label>
                  )}
                </Form.Group>
                {staticData.city && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Address Details
                    </h5>{" "}
                    <Form.Group as={Row} controlId="address">
                      <Form.Label column sm="12">
                        Address:{" "}
                        <span
                          style={{ color: "black", textTransform: "uppercase" }}
                        >
                          {staticData.fullAddress}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="city">
                      <Form.Label column sm="4">
                        City:{" "}
                        <span
                          style={{ color: "black", textTransform: "uppercase" }}
                        >
                          {staticData.city}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        State:{" "}
                        <span
                          style={{ color: "black", textTransform: "uppercase" }}
                        >
                          {staticData.state}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        Pincode:{" "}
                        <span
                          style={{ color: "black", textTransform: "uppercase" }}
                        >
                          {staticData.pincode}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                  </React.Fragment>
                )}
                {staticData.disbursalBankDetails &&
                  staticData.disbursalBankDetails.bankName && (
                    <React.Fragment>
                      <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                      >
                        Disbursal Bank Details
                      </h5>
                      <Form.Group as={Row} controlId="bank">
                        <Form.Label column sm="12">
                          Bank Name:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {staticData.disbursalBankDetails.bankName}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="branch">
                        <Form.Label column sm="12">
                          Branch Name:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {staticData.disbursalBankDetails.branch}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="accountNo">
                        <Form.Label column sm="4">
                          Account No:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {staticData.disbursalBankDetails.accountNo}
                          </span>{" "}
                        </Form.Label>
                        <Form.Label column sm="4">
                          IFSC Code:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {staticData.disbursalBankDetails.ifsc}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="accountType">
                        <Form.Label column sm="4">
                          Account Type:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {staticData.disbursalBankDetails.accountType}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                    </React.Fragment>
                  )}
                <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    User Bank Details
                  </h5>
                  {staticData.userBankList.map((bank, i) => (
                    <React.Fragment key={`bank-${i}`}>
                      <Form.Group as={Row} controlId="bank">
                        <Form.Label column sm="12">
                          Bank Name:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {bank.bankName}
                          </span>{" "}
                          {bank.docVerification &&
                            bank.docVerification.isBankVerified && (
                              <i
                                className="fa fa-check"
                                aria-hidden="true"
                                style={{
                                  color: "green",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showBankDocInfo(bank)}
                              ></i>
                            )}
                          {bank.docVerification &&
                            !bank.docVerification.isBankVerified && (
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                style={{
                                  color: "red",
                                  fontSize: "1.3em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.showBankDocInfo(bank)}
                              ></i>
                            )}
                          {!bank.docVerification && (
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              style={{ color: "red", fontSize: "1.3em" }}
                            ></i>
                          )}
                          {bank.bankFilePath && (
                            <a
                              href="#"
                              onClick={() =>
                                this.openFileAsTab(bank.bankFilePath)
                              }
                            >
                              {" "}
                              View
                            </a>
                          )}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="branch">
                        <Form.Label column sm="12">
                          Branch Name:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {bank.branch}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="accountNo">
                        <Form.Label column sm="4">
                          Account No:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {bank.accountNo}
                          </span>{" "}
                        </Form.Label>
                        <Form.Label column sm="4">
                          IFSC Code:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {bank.ifsc}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="accountType">
                        <Form.Label column sm="4">
                          Account Type:{" "}
                          <span
                            style={{
                              color: "black",
                              textTransform: "uppercase",
                            }}
                          >
                            {bank.accountType}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                    </React.Fragment>
                  ))}
                  {staticData.userBankList.length === 0 && (
                    <>
                      <span>No record found</span>
                      <br /> <br />
                    </>
                  )}
                </React.Fragment>
                {staticData.gstList.length > 0 && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      GST Details
                    </h5>
                    {staticData.gstList.map((gst) => (
                      <React.Fragment key={gst.gstNo}>
                        <Form.Group as={Row} controlId="gstNo">
                          <Form.Label column sm="4">
                            Gst No:{" "}
                            <span
                              style={{
                                color: "black",
                                textTransform: "uppercase",
                              }}
                            >
                              {gst.gstNo}
                            </span>{" "}
                            {gst.docVerification &&
                              gst.docVerification.isGstVerified && (
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                  style={{
                                    color: "green",
                                    fontSize: "1.3em",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              )}
                            {gst.docVerification &&
                              !gst.docVerification.isGstVerified && (
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  style={{
                                    color: "red",
                                    fontSize: "1.3em",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              )}
                            {!gst.docVerification && (
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                style={{ color: "red", fontSize: "1.3em" }}
                              ></i>
                            )}
                            {gst.gstFilePath && (
                              <a
                                href="#"
                                onClick={() =>
                                  this.openFileAsTab(gst.gstFilePath)
                                }
                              >
                                {" "}
                                View
                              </a>
                            )}
                          </Form.Label>
                        </Form.Group>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
                {optionalDocumentList.length > 0 && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Additional Documents
                    </h5>{" "}
                    <Form.Group as={Row} controlId="additionalDocuments">
                      <Form.Label column sm="12">
                        {optionalDocumentList.map((file) => (
                          <React.Fragment>
                            <a
                              key={file.fileName}
                              href="#"
                              onClick={() => this.openFile(file.filePath)}
                              style={{
                                textDecoration: "underline",
                              }}
                              className="black"
                            >
                              {" "}
                              {file.fileName}
                            </a>{" "}
                            <br />
                          </React.Fragment>
                        ))}
                      </Form.Label>
                    </Form.Group>
                  </React.Fragment>
                )}
                {staticData.userRole === "User" && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Questionnairre
                    </h5>{" "}
                    <Form.Group as={Row} controlId="questionnairre">
                      <Form.Label column sm="4">
                        WhatsApp in Mobile?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.whatsApp}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        Gender:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.gender}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        Marital Status:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.maritalStatus}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="questionnairre">
                      <Form.Label column sm="4">
                        Have Children?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.children}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        Rent or own a home?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.homeOwnershipType}
                        </span>{" "}
                      </Form.Label>

                      <Form.Label column sm="4">
                        Own a vehicle?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.vehicle}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="questionnairre">
                      <Form.Label column sm="4">
                        Monthly Outgoings?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.monthlyOutgoing}
                        </span>{" "}
                      </Form.Label>

                      <Form.Label column sm="4">
                        Monthly Income?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.monthlyIncome}
                        </span>{" "}
                      </Form.Label>

                      <Form.Label column sm="4">
                        Public and/or Private Sector Job?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.jobType}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="questionnairre">
                      <Form.Label column sm="4">
                        Purchased TV/Fridge in past 1 year?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.purchasedInOneYear}
                        </span>{" "}
                      </Form.Label>

                      <Form.Label column sm="4">
                        Recommended By:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.recommendedBy}
                        </span>{" "}
                      </Form.Label>

                      <Form.Label column sm="4">
                        Highest Degree?:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.questionnairre.degree}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                  </React.Fragment>
                )}
                {(staticData.loanStatus === "Lender Assigned" ||
                  staticData.loanStatus === "Loan Contract Accepted" ||
                  staticData.loanStatus === "Loan In Progress" ||
                  staticData.loanStatus === "Onboarding Completed" ||
                  staticData.loanStatus === "Ready For Disbursal" ||
                  staticData.loanStatus === "Initiated Fund Transfer" ||
                  staticData.loanStatus === "Closed Prepaid" ||
                  staticData.loanStatus === "Lender Approval" ||
                  staticData.loanStatus === "Written-Off" ||
                  staticData.loanStatus === "Close Repaid") && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Contract Details
                    </h5>
                    <React.Fragment>
                      <Form.Group as={Row} controlId="contract">
                        <Form.Label column sm="4">
                          Contract Signed:{" "}
                          <span
                            style={{
                              color: "black",
                            }}
                          >
                            {staticData.isContractSigned}
                          </span>{" "}
                          {staticData.contractFile &&
                            staticData.loanStatus !==
                              "Loan Contract Accepted" && (
                              <a
                                href="#"
                                onClick={() =>
                                  this.openFile(staticData.contractFile)
                                }
                              >
                                {" "}
                                View
                              </a>
                            )}
                        </Form.Label>
                        {staticData.kfsFile &&
                          staticData.loanStatus !==
                            "Loan Contract Accepted" && (
                            <Form.Label column sm="4">
                              KFS Document:{" "}
                              {staticData.kfsFile && (
                                <a
                                  href="#"
                                  onClick={() =>
                                    this.openFile(staticData.kfsFile)
                                  }
                                >
                                  {" "}
                                  View
                                </a>
                              )}
                            </Form.Label>
                          )}
                      </Form.Group>
                      <Form.Group as={Row} controlId="contract">
                        <Form.Label column sm="4">
                          Photo:{" "}
                          {
                            staticData.photoFilePath || files?.photoFileSrc ? (
                              <div className="position-relative">
                                {this.renderThumbnail(
                                  staticData.photoFilePath
                                    ? process.env.PUBLIC_URL +
                                        staticData.photoFileEncPath
                                    : files?.photoFileSrc
                                )}
                                {/* <div className="image-hover-wrapper">
                                    <div>
                                      <i className="fa fa-times cursor-pointer close-icon" onClick={() => this.onRemoveProfileFile()} />
                                    </div>
                                  </div> */}
                                <div className="mt-2">
                                  <div className="">
                                    Match:{" "}
                                    <span
                                      style={{
                                        color: staticData.photoMatch?.isMatched
                                          ? "green"
                                          : "",
                                      }}
                                    >
                                      {staticData.photoMatch?.percentage || 0}%
                                    </span>
                                  </div>
                                  {/* <div className="">Liveliness: {staticData.photoMatch?.liveliness}</div> */}
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                            // <Form.File
                            //   label={""}
                            //   onChange={(e) => this.onPhotoUploadChange(e, "LOAN_CONTRACT",)}
                            //   id="photoFile"
                            //   name="photoFile"
                            //   accept="image/*"
                            //   custom
                            // />
                          }
                        </Form.Label>

                        {staticData.videoPath ? (
                          <Form.Label column sm="4">
                            Video:{" "}
                            <div>
                              <video
                                style={{
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                }}
                                width="250"
                                height="120"
                                controls
                                controlsList="nodownload"
                              >
                                <source
                                  src={staticData.videoPath}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          </Form.Label>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group as={Row} controlId="shop">
                        <Form.Label column sm="4">
                          Shop Internal Photo:{" "}
                          {staticData.shopInternalPhotoFilePath ||
                          files?.shopInternalPhotoFileSrc ? (
                            <div className="position-relative">
                              {this.renderThumbnail(
                                staticData.shopInternalPhotoFilePath
                                  ? process.env.PUBLIC_URL +
                                      staticData.shopInternalPhotoFileEncPath
                                  : files?.shopInternalPhotoFileSrc
                              )}
                              {/* <div className="image-hover-wrapper">
                                    <div>
                                      <i className="fa fa-times cursor-pointer close-icon" onClick={() => this.onRemoveProfileFile()} />
                                    </div>
                                  </div> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Label>

                        <Form.Label column sm="4">
                          Shop External Photo:{" "}
                          {staticData.shopExternalPhotoFilePath ||
                          files?.shopExternalPhotoFileSrc ? (
                            <div className="position-relative">
                              {this.renderThumbnail(
                                staticData.shopExternalPhotoFilePath
                                  ? process.env.PUBLIC_URL +
                                      staticData.shopExternalPhotoFileEncPath
                                  : files?.shopExternalPhotoFileSrc
                              )}
                              {/* <div className="image-hover-wrapper">
                                    <div>
                                      <i className="fa fa-times cursor-pointer close-icon" onClick={() => this.onRemoveProfileFile()} />
                                    </div>
                                  </div> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </Form.Label>

                        {/* {staticData.videoPath ? (
                          <Form.Label column sm="4">
                            Video:{" "}
                            <div>
                              <video
                                style={{
                                  border: "1px solid gray",
                                  borderRadius: "5px",
                                }}
                                width="250"
                                height="120"
                                controls
                                controlsList="nodownload"
                              >
                                <source
                                  src={staticData.videoPath}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          </Form.Label>
                        ) : (
                          ""
                        )} */}
                      </Form.Group>
                    </React.Fragment>
                  </React.Fragment>
                )}
                {staticData.nocFile && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      NOC Details
                    </h5>
                    <React.Fragment>
                      <Form.Group as={Row} controlId="contract">
                        <Form.Label column sm="4">
                          NOC File:{" "}
                          <span
                            style={{
                              color: "black",
                            }}
                          >
                            {staticData.nocFile && (
                              <a
                                href="#"
                                onClick={() =>
                                  this.openFile(staticData.nocFile)
                                }
                              >
                                {" "}
                                View
                              </a>
                            )}
                            {staticData.nocFile && (
                              <Button
                                onClick={() =>
                                  this.sendNocNotification(staticData?.loanNo)
                                }
                                className="ff-button"
                                style={{ marginLeft: 10 }}
                                disabled={this.state.isDisabledNocButton}
                              >
                                Send NOC File
                              </Button>
                            )}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                    </React.Fragment>
                  </React.Fragment>
                )}
                {(staticData.primaryCollectionType === "eNach" ||
                  staticData.secondaryCollectionType === "eNach") && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      e-Nach Details
                    </h5>
                    <React.Fragment>
                      <Form.Group as={Row} controlId="mandateNumber">
                        <Form.Label column sm="6">
                          e-Nach Number:{" "}
                          <span
                            style={{
                              color: "black",
                            }}
                          >
                            {staticData.mandateNumber}
                          </span>{" "}
                        </Form.Label>
                        <Form.Label column sm="4">
                          e-Nach Current Status:{" "}
                          <span
                            style={{
                              color: "black",
                            }}
                          >
                            {staticData.mandateStatus}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="mandateNumber">
                        <Form.Label column sm="6">
                          e-Nach Mode:{" "}
                          <span
                            style={{
                              color: "black",
                            }}
                          >
                            {staticData.mandateMode}
                          </span>{" "}
                        </Form.Label>
                        {staticData.mandateRejectReason === "Admin" && (
                          <Form.Label column sm="4">
                            Reject Reason:{" "}
                            <span
                              style={{
                                color: "black",
                              }}
                            >
                              {staticData.mandateRejectReason}
                            </span>{" "}
                          </Form.Label>
                        )}
                      </Form.Group>
                      {/* {staticData.evaluatorRole === "Admin" && staticData.mandateStatus.toLowerCase() == "register_success" &&
                        <Button
                          className="ff-button"
                          type="button"
                          disabled={isNewMandateSubmitted}
                          onClick={() => this.handleEnachPopupOpen()}
                        >
                          Send New Mandate
                        </Button>} */}
                    </React.Fragment>
                  </React.Fragment>
                )}
                {staticData.lenderError != "" && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Lending Partner Error
                    </h5>
                    <React.Fragment>
                      <Form.Group as={Row} controlId="lenderError">
                        <Form.Label column sm="8">
                          <span
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Last Lender Assigned -{" "}
                            {staticData.investments[0].name} -{" "}
                            {staticData.lenderError} -{" "}
                            {staticData.lenderErrorDate}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                    </React.Fragment>
                  </React.Fragment>
                )}
                {/* <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline", marginTop: 10 }}
                >
                  Profile Picture
                </h5>{" "}
                <Form.Group as={Row} controlId="profilePic">
                  <Form.Label column sm="12">
                    <Image
                      src={
                        staticData.profileFilePath
                          ? process.env.PUBLIC_URL + staticData.profileFilePath
                          : require("../../images/userProfile.png")
                      }
                      fluid
                      style={{ maxHeight: 100 }}
                    />{" "}
                  </Form.Label>
                </Form.Group> */}
              </Form>
            </React.Fragment>
          </Tab>
          {!this.props.showSummaryFirst && this.renderSummaryTab()}
          <Tab eventKey="investmentsTab" title="Lender">
            <br />
            <h2
              className="fundfinaColor"
              style={{ textDecoration: "underline", textAlign: "center" }}
            >
              Lender
            </h2>{" "}
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Lender</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {investments &&
                  investments.map((investment, i) => (
                    <tr key={`investment-${i}`}>
                      <td>
                        {moment(investment.investedOn).format("DD/MM/yyyy")}
                      </td>
                      <td>{investment.name}</td>
                      <td>
                        <NumberFormat
                          value={investment.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          thousandsGroupStyle="lakh"
                          prefix={"₹"}
                        />
                      </td>
                    </tr>
                  ))}
                {investments.length === 0 && (
                  <tr key="noInvestment">
                    <td colSpan="3">No record found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div>
              <Pagination
                itemsCount={totalInvestmentCount}
                pageSize={pageSize}
                currentPage={currentInvestmentPage}
                onPageChange={this.handleInvestmentPageChange}
                onPagePrevious={this.handleInvestmentPagePrevious}
                onPageNext={this.handleInvestmentPageNext}
              />
            </div>
            {staticData.evaluatorRole === "Admin" &&
              lenderRejectionList.length > 0 && (
                <>
                  <br />
                  <h2
                    className="fundfinaColor"
                    style={{ textDecoration: "underline", textAlign: "center" }}
                  >
                    Rejected By Lenders
                  </h2>
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th td style={{ width: "10%" }}>
                          Date
                        </th>
                        <th td style={{ width: "45%" }}>
                          Lender
                        </th>
                        <th td style={{ width: "45%" }}>
                          Rejection Reason
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lenderRejectionList &&
                        lenderRejectionList.map((rejection, i) => (
                          <tr key={`rejection-${i}`}>
                            <td>
                              {moment(rejection.date).format("DD/MM/yyyy")}
                            </td>
                            <td>{rejection.displayName}</td>
                            <td>{rejection.comments}</td>
                          </tr>
                        ))}
                      {lenderRejectionList.length === 0 && (
                        <tr key="noRejection">
                          <td colSpan="3">No record found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div>
                    <Pagination
                      itemsCount={totalLenderRejectionCount}
                      pageSize={pageSize}
                      currentPage={currentLenderRejectionPage}
                      onPageChange={this.handleLenderRejectionPageChange}
                      onPagePrevious={
                        this.handleLenderRejectionListPagePrevious
                      }
                      onPageNext={this.handleLenderRejectionListPageNext}
                    />
                  </div>
                </>
              )}
          </Tab>
          {displayTransaction1 && (
            <Tab eventKey="transactionsTab" title="Transactions" on>
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Transactions
              </h2>{" "}
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Agent Code</th>
                    <th>Primary</th>
                    <th>Transaction Type</th>
                    <th>Transaction Value</th>
                    <th>Month/Year</th>
                    <th>Transaction Value Type</th>
                    <th>Primary Count</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions &&
                    transactions.map((transaction, i) => (
                      <tr key={`transactions-${i}`}>
                        <td>{staticData.agentCode}</td>
                        <td>{transaction.primary}</td>
                        <td>{transaction.type}</td>
                        <td>{transaction.value}</td>
                        <td>{transaction.monthYear}</td>
                        <td>{transaction.valueType}</td>
                        <td>{transaction.primaryCount}</td>
                      </tr>
                    ))}
                  {transactions.length === 0 && (
                    <tr key="noTransaction">
                      <td colSpan="10">No record found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div>
                <Pagination
                  itemsCount={totalTransactionCount}
                  pageSize={pageSize}
                  currentPage={currentTransactionPage}
                  onPageChange={this.handleTransactionPageChange}
                  onPagePrevious={this.handleTransactionPagePrevious}
                  onPageNext={this.handleTransactionPageNext}
                />
              </div>
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                GST Details
              </h2>{" "}
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Revenue</th>
                    <th>Tax</th>
                    <th>Period</th>
                  </tr>
                </thead>
                <tbody>
                  {gstTableData &&
                    gstTableData.map((entry,i) => (
                      <tr key={i}>
                        <td>{entry.revenue}</td>
                        <td>{entry.tax}</td>
                        <td>{entry.period}</td>
                      </tr>
                    ))}
                  {gstTableData.length === 0 && (
                    <tr key="noTransaction">
                      <td colSpan="10">No record found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Bank Statement Details
              </h2>{" "}
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Credits Amount Monthwise</th>
                    <th>Debits Amount Monthwise</th>
                    <th>Average EoD Balance Amount Monthwise</th>
                    <th>Month</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  {bankStatementTableData &&
                    Object.values(bankStatementTableData).map((entry,i) => (
                      <tr key={i}>
                        <td>{entry.credits_amount_monthwise}</td>
                        <td>{entry.debits_amount_monthwise}</td>
                        <td>{entry.average_balance_monthwise}</td>
                        <td>{entry.month}</td>
                        <td>{entry.year}</td>
                      </tr>
                    ))}
                  {bankStatementTableData.length === 0 && (
                    <tr key="noTransaction">
                      <td colSpan="10">No record found</td>
                    </tr>
                  )}
                </tbody>
              </Table> */}
            </Tab>
          )}
          {!displayTransaction1 && (
            <Tab eventKey="transaction2" title="Transactions">
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Transactions
              </h2>{" "}
              <Form>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="month">
                    <Form.Label>Month*</Form.Label>
                    <Form.Control
                      as="select"
                      name="month"
                      value={optional["month"]}
                      onChange={this.handleOptionalValues("transaction")}
                    >
                      {" "}
                      {monthList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="year">
                    <Form.Label>Year*</Form.Label>
                    <Form.Control
                      as="select"
                      name="year"
                      value={optional["year"]}
                      onChange={this.handleOptionalValues("transaction")}
                    >
                      {" "}
                      {yearList.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="btn">
                    <Button
                      className="ff-button"
                      type="button"
                      onClick={this.getTransactions}
                      style={{ marginTop: 30 }}
                    >
                      Search
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Month</th>
                    <th>Year</th>
                    <th>Transaction Count</th>
                    <th>Transaction Volume</th>
                    <th>Active Days</th>
                  </tr>
                </thead>
                <tbody>
                  {transaction2List &&
                    transaction2List.map((transaction2Item, i) => (
                      <tr key={`trueScoreItem-${i}`}>
                        <td>{transaction2Item.month}</td>
                        <td>{transaction2Item.year}</td>
                        <td>{transaction2Item.tranCount}</td>
                        <td>
                          <NumberFormat
                            value={transaction2Item.tranVolume}
                            displayType={"text"}
                            thousandSeparator={true}
                            thousandsGroupStyle="lakh"
                          />
                        </td>
                        <td>{transaction2Item.activeDays}</td>
                      </tr>
                    ))}
                  {transaction2List.length === 0 && (
                    <tr key="noTransaction2Item">
                      <td colSpan="10">No record found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div>
                <Pagination
                  itemsCount={totalTransaction2Count}
                  pageSize={pageSize}
                  currentPage={currentTransaction2Page}
                  onPageChange={this.handleTrueScorePageChange}
                  onPagePrevious={this.handleTransactions2PagePrevious}
                  onPageNext={this.handleTransaction2PageNext}
                />
              </div>
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                GST Details
              </h2>{" "}
              <Form>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="month">
                    <Form.Label>Month*</Form.Label>
                    <Form.Control
                      as="select"
                      name="month"
                      value={optionalGst["month"]}
                      onChange={this.handleOptionalValues("gst")}
                    >
                      {" "}
                      {monthList.map((item, i) => (
                        <option key={i} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="year">
                    <Form.Label>Year*</Form.Label>
                    <Form.Control
                      as="select"
                      name="year"
                      value={optionalGst["year"]}
                      onChange={this.handleOptionalValues("gst")}
                    >
                      {" "}
                      {yearList.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="btn">
                    <Button
                      className="ff-button"
                      type="button"
                      onClick={this.getGstTransactions}
                      style={{ marginTop: 30 }}
                    >
                      Search
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Revenue(Rs)</th>
                    <th>Tax(Rs)</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {gstTableData &&
                    gstTableData.map((entry,i) => (
                      <tr key={i}>
                        <td>{entry?.revenue.toLocaleString("en-IN")}</td>
                        <td>{entry?.tax.toLocaleString("en-IN")}</td>
                        <td>{entry?.period?.start}</td>
                        <td>{entry?.period?.end}</td>
                      </tr>
                    ))}
                  {gstTableData.length === 0 && (
                    <tr key="noTransaction">
                      <td colSpan="10">No record found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Bank Statement Details
              </h2>{" "}
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Credits Amount Monthwise(Rs)</th>
                    <th>Debits Amount Monthwise(Rs)</th>
                    <th>Average EoD Balance Amount Monthwise(Rs)</th>
                    <th>Month</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  {bankStatementTableData &&
                    Object.values(bankStatementTableData).map((entry,i) => (
                      <tr key={i}>
                        <td>{entry.credits_amount_monthwise.toLocaleString("en-IN")}</td>
                        <td>{entry.debits_amount_monthwise.toLocaleString("en-IN")}</td>
                        <td>{entry.average_balance_monthwise.toLocaleString("en-IN")}</td>
                        <td>{entry.month}</td>
                        <td>{entry.year}</td>
                      </tr>
                    ))}
                  {bankStatementTableData.length === 0 && (
                    <tr key="noTransaction">
                      <td colSpan="10">No record found</td>
                    </tr>
                  )}
                </tbody>
              </Table> */}
            </Tab>
          )}
          <Tab eventKey="loanHistoryTab" title="Loan History">
            <React.Fragment>
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Loan History
              </h2>{" "}
              <Tabs defaultActiveKey="fundfina" id="uncontrolled-tab-example">
                <Tab eventKey="fundfina" title="Fundfina Loans">
                  <React.Fragment>
                    {" "}
                    <br />
                    <Table responsive striped bordered hover>
                      <thead>
                        <tr>
                          <th>Loan #</th>
                          <th>Borrowing Partner</th>
                          {staticData.evaluatorRole === "Admin" && (
                            <th>Lending Partner</th>
                          )}
                          <th>Disbursal Date</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Outstanding Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fundfinaLoanHistory &&
                          fundfinaLoanHistory.map((fundfinaLoanHistory, i) => (
                            <tr key={`FundfinaHistoryItem-${i}`}>
                              <td>
                                <a
                                  key={fundfinaLoanHistory.loanId}
                                  href={`/loanInfo/${fundfinaLoanHistory.loanId}`}
                                >
                                  {fundfinaLoanHistory.loanNo}
                                </a>
                              </td>
                              <td>{fundfinaLoanHistory.partnerName}</td>
                              {staticData.evaluatorRole === "Admin" && (
                                <td>{fundfinaLoanHistory.lendingPartner}</td>
                              )}
                              <td>{fundfinaLoanHistory.disbursalDate}</td>
                              <td>
                                <NumberFormat
                                  value={fundfinaLoanHistory.amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  thousandsGroupStyle="lakh"
                                  prefix={"₹"}
                                />
                              </td>
                              <td>{fundfinaLoanHistory.status}</td>
                              <td>
                                <NumberFormat
                                  value={fundfinaLoanHistory.outstandingAmount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  thousandsGroupStyle="lakh"
                                  prefix={"₹"}
                                />
                              </td>
                            </tr>
                          ))}
                        {fundfinaLoanHistory.length === 0 && (
                          <tr key="NoFundfinaHistoryItem">
                            <td colSpan="10">No record found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <div>
                      <Pagination
                        itemsCount={totalFundfinaLoanHistoryCount}
                        pageSize={pageSize}
                        currentPage={currentFundfinaLoanHistoryPage}
                        onPageChange={this.handleFundfinaLoanHistoryPageChange}
                        onPagePrevious={
                          this.handleFundfinaLoanHistoryPagePrevious
                        }
                        onPageNext={this.handleFundfinaLoanHistoryPageNext}
                      />
                    </div>
                  </React.Fragment>
                </Tab>
                {staticData.evaluatorRole === "Admin" && (
                  <Tab eventKey="other" title="Other Loans">
                    <React.Fragment>
                      {" "}
                      <br />
                      <Table responsive striped bordered hover>
                        <thead>
                          <tr>
                            <th>Agent Code</th>
                            <th>Loan Provider Name</th>
                            <th>Loan Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loanHistory &&
                            loanHistory.map((historyItem, i) => (
                              <tr key={`historyItem-${i}`}>
                                <td>{staticData.agentCode}</td>
                                <td>{historyItem.providerName}</td>
                                <td>
                                  <NumberFormat
                                    value={historyItem.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="lakh"
                                    prefix={"₹"}
                                  />
                                </td>
                                <td>{historyItem.status}</td>
                              </tr>
                            ))}
                          {loanHistory.length === 0 && (
                            <tr key="noHistoryItem">
                              <td colSpan="10">No record found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <div>
                        <Pagination
                          itemsCount={totalLoanHistoryCount}
                          pageSize={pageSize}
                          currentPage={currentLoanHistoryPage}
                          onPageChange={this.handleLoanHistoryPageChange}
                          onPagePrevious={this.handleLoanHistoryPagePrevious}
                          onPageNext={this.handleLoanHistoryPageNext}
                        />
                      </div>
                    </React.Fragment>
                  </Tab>
                )}
              </Tabs>
            </React.Fragment>
          </Tab>
          {staticData.evaluatorRole === "Admin" && (
            <Tab eventKey="shopDetailsTab" title="Shop Details">
              <ShopDetails userData={userData} type="admin" /> <br />
            </Tab>
          )}
          {displayTrueScore && (
            <Tab eventKey="trueScore" title="TrueScore">
              <br/>
              <Form>
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Credit Limit Based On GST
                </h5>{" "}
                <Form.Group as={Row}>
                  {creditLimit?.gstBasedLimit && <Form.Label column sm="4"  style={{  fontSize:"15px", fontWeight:"600" }}>
                    Credit Limit :{" "}
                    <span style={{ color: "black" }}>Rs {parseInt(creditLimit?.gstBasedLimit).toLocaleString("en-IN")}</span>
                  </Form.Label>}
                  {!creditLimit?.gstBasedLimit &&<Form.Label column  style={{  fontSize:"15px", fontWeight:"500" }}>
                    <span style={{ color: "black" }}>No Limit found based  on GST </span></Form.Label>}
                </Form.Group>
                {(gstInferanceDetails?.totalRevenue||gstInferanceDetails?.averageMonthlyRevenue) &&<Form.Group as={Row}>
                {gstInferanceDetails?.totalRevenue &&<Form.Label column sm="4">
                    Total Revenue :{" "}
                    <span style={{ color: "black" }}>Rs {gstInferanceDetails?.totalRevenue.toLocaleString("en-IN")}</span>
                  </Form.Label>}
                  {gstInferanceDetails?.averageMonthlyRevenue&&<Form.Label column sm="4">
                    Average Monthly Revenue :{" "}
                    <span style={{ color: "black" }}>
                      Rs {gstInferanceDetails?.averageMonthlyRevenue.toLocaleString("en-IN")}
                    </span>
                  </Form.Label>}
                </Form.Group>}
                {(gstInferanceDetails?.grossMargin||gstInferanceDetails?.grossMarginPercentage)&&<Form.Group as={Row}>
               {gstInferanceDetails?.grossMargin&&<Form.Label column sm="4">
                    Gross Margin :{" "}
                    <span style={{ color: "black" }}>{gstInferanceDetails?.grossMargin.toLocaleString("en-IN")}</span>
                  </Form.Label>}
                  {gstInferanceDetails?.grossMarginPercentage &&<Form.Label column sm="4">
                    Gross Margin % :{" "}
                    <span style={{ color: "black" }}>
                      {gstInferanceDetails?.grossMarginPercentage}
                    </span>
                  </Form.Label>}
                </Form.Group>}
                {/* <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Credit Limit Based On Bank Statement
                </h5>{" "}
                <Form.Group as={Row}>
                  {creditLimit?.bankStatementBasedLimit && <Form.Label column sm="4" style={{  fontSize:"15px", fontWeight:"600" }}>
                  Credit Limit :{" "}
                    <span style={{ color: "black" }}>
                      Rs {parseInt(creditLimit?.bankStatementBasedLimit).toLocaleString("en-IN")}
                    </span>
                  </Form.Label>}
                  {!creditLimit?.bankStatementBasedLimit && <Form.Label column  style={{  fontSize:"15px", fontWeight:"500" }}>
                    <span style={{ color: "black" }}>No Limit found based  on Bank Statment</span></Form.Label>}
                  
                </Form.Group> */}
                {(bankInferanceDetails?.averageMonthlyBalanceAmount||bankInferanceDetails?.totalCreditTransactionsVolume||bankInferanceDetails?.totalDebitTransactionsVolume)&&<Form.Group as={Row}>
                {bankInferanceDetails?.averageMonthlyBalanceAmount&&<Form.Label column sm="4">
                    Average Monthly Balance Amount :{" "}
                    <span style={{ color: "black" }}>Rs {bankInferanceDetails?.averageMonthlyBalanceAmount.toLocaleString("en-IN")}</span>
                  </Form.Label>}
                  {bankInferanceDetails?.totalCreditTransactionsVolume&&<Form.Label column sm="4">
                    Total Credit Transactions Volume :{" "}
                    <span style={{ color: "black" }}>
                      Rs {bankInferanceDetails?.totalCreditTransactionsVolume.toLocaleString("en-IN")}
                    </span>
                  </Form.Label>}
                  {bankInferanceDetails?.totalDebitTransactionsVolume&&<Form.Label column sm="4">
                    Total Debit Transactions Volume :{" "}
                    <span style={{ color: "black" }}>
                      Rs {bankInferanceDetails?.totalDebitTransactionsVolume.toLocaleString("en-IN")}
                    </span>
                  </Form.Label>}
                </Form.Group>}
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Credit Limit Based On True Score 
                </h5>{" "}
                <Form.Group as={Row}>
                  {trueScoreAmountDetails?.preApprovedAmount && <Form.Label column sm="4"  style={{  fontSize:"15px", fontWeight:"600" }}>
                    Approved Limit :{" "}
                    <span style={{ color: "black" }}>Rs {parseInt(trueScoreAmountDetails?.preApprovedAmount).toLocaleString("en-IN")}</span>
                  </Form.Label>}
                  {!trueScoreAmountDetails?.preApprovedAmount &&<Form.Label column  style={{  fontSize:"15px", fontWeight:"500" }}>
                    <span style={{ color: "black" }}>No Limit found based  on True Score </span></Form.Label>}
                </Form.Group>
              </Form>

              <br />
              <Form>
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="tsFromMonth">
                    <FormCheck
                      id="switchEnabled"
                      type="switch"
                      checked={graphToggle}
                      onChange={this.toggleChange}
                      label="Show Graph"
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
              {graphToggle && (
                <React.Fragment>
                  <Form>
                    <Form.Row>
                      <Form.Group as={Col} md="2" controlId="tsFromMonth">
                        <Form.Label>From Month</Form.Label>
                        <Form.Control
                          as="select"
                          name="tsFromMonth"
                          value={optional["tsFromMonth"]}
                          onChange={this.handleTrueScoreChange}
                        >
                          {" "}
                          <option key="select" value="">
                            ---Select---
                          </option>
                          {fromMonth.map((item, i) => (
                            <option key={`frequency-${i}`} value={item.value}>
                              {item.text}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="2" controlId="tsFromYear">
                        <Form.Label>From Year</Form.Label>
                        <Form.Control
                          as="select"
                          name="tsFromYear"
                          value={optional["tsFromYear"]}
                          onChange={this.handleTrueScoreChange}
                        >
                          {" "}
                          <option key="select" value="">
                            ---Select---
                          </option>
                          {fromYear.map((item, i) => (
                            <option key={`frequency-${i}`} value={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="2" controlId="tsToMonth">
                        <Form.Label>To Month</Form.Label>
                        <Form.Control
                          as="select"
                          name="tsToMonth"
                          value={optional["tsToMonth"]}
                          onChange={this.handleTrueScoreChange}
                        >
                          {" "}
                          <option key="select" value="">
                            ---Select---
                          </option>
                          {toMonth.map((item, i) => (
                            <option key={`frequency-${i}`} value={item.value}>
                              {item.text}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="2" controlId="tsToYear">
                        <Form.Label>To Year</Form.Label>
                        <Form.Control
                          as="select"
                          name="tsToYear"
                          value={optional["tsToYear"]}
                          onChange={this.handleTrueScoreChange}
                        >
                          {" "}
                          <option key="select" value="">
                            ---Select---
                          </option>
                          {toYear.map((item, i) => (
                            <option key={`frequency-${i}`} value={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md="3" controlId="btnTrueScore">
                        <Button
                          className="ff-button"
                          type="button"
                          style={{ marginTop: 30, marginLeft: 10 }}
                          onClick={this.getTrueScoreGraph}
                        >
                          Select
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                  <LineChartComponent label={label} score={score} />
                  <br />
                </React.Fragment>
              )}
              {!graphToggle && (
                <React.Fragment>
                  <Table responsive striped bordered hover>
                    <thead>
                      <tr>
                        <th>Month</th>
                        <th>Year</th>
                        <th>TrueScore</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trueScoreList &&
                        trueScoreList.map((trueScoreItem, i) => (
                          <tr key={`trueScoreItem-${i}`}>
                            <td>{trueScoreItem.month}</td>
                            <td>{trueScoreItem.year}</td>
                            <td>{trueScoreItem.trueScore}</td>
                          </tr>
                        ))}
                      {trueScoreList.length === 0 && (
                        <tr key="noTrueScoreItem">
                          <td colSpan="10">No record found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div>
                    <Pagination
                      itemsCount={totalTrueScoreCount}
                      pageSize={pageSize}
                      currentPage={currentTrueScorePage}
                      onPageChange={this.handleTrueScorePageChange}
                      onPagePrevious={this.handleTrueScorePagePrevious}
                      onPageNext={this.handleTrueScorePageNext}
                    />
                  </div>
                  <br />
                </React.Fragment>
              )}
            </Tab>
          )}
          <Tab eventKey="schedule" title="EMI Schedule">
            <br />
            <h2
              className="fundfinaColor"
              style={{ textDecoration: "underline", textAlign: "center" }}
            >
              EMI Schedule
            </h2>{" "}
            <br />
            {staticData.loanId && (
              <EmiList loanId={staticData.loanId}></EmiList>
            )}
          </Tab>
          {this.props &&
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.isFrom &&
          this.props.match.params.isFrom == "foreclosure" ? (
            <Tab eventKey="foreclosure" title="Foreclosure">
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Foreclosure
              </h2>{" "}
              <br />
              <Form>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label column sm="12">
                      Loan No:{" "}
                      <span style={{ color: "black" }}>
                        {staticData.loanNo}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label column sm="4">
                      Loan Amount:{" "}
                      <span style={{ color: "black" }}>
                        {data["loanAmount"]}
                      </span>
                    </Form.Label>
                    <Form.Label column sm="4">
                      Interest Amount:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.interestAmount}
                      </span>
                    </Form.Label>
                    <Form.Label column sm="4">
                      Total # of EDI's:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.edis}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label column sm="4">
                      Principal Pending:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.principalPending}
                      </span>
                    </Form.Label>
                    <Form.Label column sm="4">
                      Interest Pending:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.inerestPending}
                      </span>
                    </Form.Label>
                    <Form.Label column sm="4">
                      Late Charges Pending:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.lateChargesPending}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label column sm="4">
                      Principal Outstanding:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.pricipalOutstanding}
                      </span>
                    </Form.Label>
                    <Form.Label column sm="4">
                      Interest Outstanding:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.interestOutstanding}
                      </span>
                    </Form.Label>
                    <Form.Label column sm="4">
                      # of EDIs Outstanding:{" "}
                      <span style={{ color: "black" }}>
                        {foreclosureDetails.edisOutstanding}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="4">
                    <Form.Group as={Col} sm="12">
                      <Form.Check
                        type="checkbox"
                        label="Waive Late Fee"
                        //checked="checked"
                        checked={foreclosureDetails.isWaiveOff}
                        onChange={this.handleWaiveLateFee}
                        color="red"
                        name="Waive Late Fee"
                      />{" "}
                    </Form.Group>
                  </Form.Group>
                  {/* <Form.Group as={Col} sm="3">
                  <Form.Label>Collection Mode*</Form.Label>
                  <Form.Control
                    as="select"
                    name="collectionMode"
                    value={foreclosureDetails.collectionMode}
                    onChange={this.handleCollectionMode}
                  >
                    {" "}
                    <option key="select" value="">
                      Select
                    </option>
                    <option key="single" value="single">
                      Single Request
                    </option>
                    <option key="installment" value="installment">
                      Installment Wise
                    </option>
                  </Form.Control>
                  {errors.collectionMode && (
                    <div className="alert alert-danger">{errors.collectionMode}</div>
                  )}
                </Form.Group> */}
                  {/* <Form.Group as={Col} sm="1"><></></Form.Group> */}
                  <Form.Group as={Col} md="4">
                    <Form.Group as={Col} sm="10">
                      <Form.Label>Foreclosure Reason*</Form.Label>
                      <Form.Control
                        as="select"
                        name="foreclosureReason"
                        value={foreclosureDetails.foreclosureReason}
                        onChange={this.handleForeClosureReason}
                      >
                        {" "}
                        <option key="select" value="">
                          Select
                        </option>
                        <option key="borrower" value="Requested By Borrower">
                          Requested By Borrower
                        </option>
                        <option key="partner" value="Requested By Partner">
                          Requested By Partner
                        </option>
                      </Form.Control>
                      {errors.foreclosureReason && (
                        <div className="alert alert-danger">
                          {errors.foreclosureReason}
                        </div>
                      )}
                    </Form.Group>
                  </Form.Group>
                </Form.Row>
                {errors["globalForeclosureError"] && (
                  <div className="alert alert-danger">
                    {errors["globalForeclosureError"]}
                  </div>
                )}
                {successMsgForeclosure && (
                  <div className="alert alert-success">
                    {successMsgForeclosure}
                  </div>
                )}
                <br />

                <Form.Row>
                  <Form.Group as={Col} md="12" align="center">
                    <Form.Group as={Col} sm="4" controlId="btn">
                      <Button
                        className="ff-button"
                        type="button"
                        onClick={this.handleInitiateForeclosure}
                        style={{ marginTop: 30 }}
                      >
                        Initiate Foreclosure
                      </Button>
                    </Form.Group>
                  </Form.Group>
                </Form.Row>
              </Form>
              <br />
            </Tab>
          ) : (
            <></>
          )}

          {(this.props &&
            this.props.location &&
            this.props.location.customProps &&
            this.props.location.customProps.fromTeleCalling) ||
          (this.props.match &&
            this.props.match.params &&
            this.props.match.params.isFrom == "teleCalling") ? (
            <Tab eventKey="teleCalling" title="Tele Calling">
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Tele Calling
              </h2>{" "}
              <br />
              {staticData.loanId && (
                <TeleCallingDetails
                  loanId={staticData.loanId}
                ></TeleCallingDetails>
              )}
            </Tab>
          ) : (
            <></>
          )}

          {(this.props &&
            this.props.location &&
            this.props.location.customProps &&
            this.props.location.customProps.fromRenewals) ||
          (this.props.match &&
            this.props.match.params &&
            this.props.match.params.isFrom == "renewals") ? (
            <Tab eventKey="teleCalling" title="Tele Calling">
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Tele Calling
              </h2>{" "}
              <br />
              {staticData.loanId && (
                <TeleCallingDetailsRenewals
                  loanId={staticData.loanId}
                ></TeleCallingDetailsRenewals>
              )}
            </Tab>
          ) : (
            <></>
          )}

          {(this.props &&
            this.props.location &&
            this.props.location.customProps &&
            this.props.location.customProps.fromCollectionQueue) ||
          (this.props.match &&
            this.props.match.params &&
            this.props.match.params.isFrom == "fromCollectionQueue") ? (
            <Tab eventKey="teleCalling" title="Tele Calling">
              <br />
              <h2
                className="fundfinaColor"
                style={{ textDecoration: "underline", textAlign: "center" }}
              >
                Tele Calling
              </h2>{" "}
              <br />
              {staticData.loanId && (
                <CollectionQueueTeleCalling
                  loanId={staticData.loanId}
                  {...this.props}
                ></CollectionQueueTeleCalling>
              )}
            </Tab>
          ) : (
            <></>
          )}
        </Tabs>
        <React.Fragment>
          {staticData.evaluatorRole === "Admin" &&
            staticData.kycStatus === "Approved" &&
            (staticData.loanStatus === "Under Review" ||
              staticData.loanStatus === "Accepted By Client" ||
              staticData.loanStatus === "Loan Is Funding" ||
              staticData.loanStatus === "Loan Contract Accepted" ||
              staticData.loanStatus === "Approved By Admin" ||
              staticData.loanStatus === "Lender Assigned" ||
              staticData.loanStatus === "Lender Approval") &&
            ((this.props &&
              this.props.location &&
              this.props.location.customProps &&
              this.props.location.customProps.fromTeleCalling) ||
            (this.props.match &&
              this.props.match.params &&
              this.props.match.params.isFrom == "teleCalling") ? (
              <></>
            ) : (
              <Form onSubmit={this.doSubmit}>
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Scrutinize
                </h5>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="adminStatus">
                    <Form.Label>Select Action*</Form.Label>
                    <Form.Control
                      as="select"
                      name="adminStatus"
                      value={data["adminStatus"]}
                      onChange={this.handleChange}
                    >
                      <option key="select" value="">
                        ---Select---
                      </option>
                      {staticData.loanStatus === "Under Review" &&
                        staticData.kycStatus === "Approved" && (
                          <React.Fragment>
                            <option key="approved" value="Approved By Admin">
                              Approve
                            </option>
                            <option key="rejected" value="Rejected By Admin">
                              Reject
                            </option>
                            <option key="review" value="Review">
                              Review
                            </option>
                          </React.Fragment>
                        )}
                      {staticData.loanStatus === "Accepted By Client" &&
                        staticData.kycStatus === "Approved" && (
                          <React.Fragment>
                            <option key="makeLive" value="Loan Is Funding">
                              Make Live
                            </option>
                            <option key="rejected" value="Rejected By Admin">
                              Reject
                            </option>
                            <option key="review" value="Review">
                              Review
                            </option>
                            <option key="cancelLoan" value="Loan Cancelled">
                              Cancel Loan
                            </option>
                          </React.Fragment>
                        )}
                      {(staticData.loanStatus === "Loan Contract Accepted" ||
                        staticData.loanStatus === "Lender Assigned" ||
                        staticData.loanStatus === "Lender Approval") &&
                        staticData.kycStatus === "Approved" && (
                          <React.Fragment>
                            <option
                              key="lenderAssigned"
                              value="Lender Assigned"
                            >
                              Assign Lender
                            </option>
                            <option key="rejected" value="Rejected By Admin">
                              Reject
                            </option>
                            <option key="review" value="Review">
                              Review
                            </option>
                            <option key="cancelLoan" value="Loan Cancelled">
                              Cancel Loan
                            </option>
                          </React.Fragment>
                        )}
                      {(staticData.loanStatus === "Approved By Admin" ||
                        staticData.loanStatus === "Loan Is Funding") && (
                        <React.Fragment>
                          <option key="cancelLoan" value="Loan Cancelled">
                            Cancel Loan
                          </option>
                        </React.Fragment>
                      )}
                      {/* {staticData.loanStatus === "Loan In Progress" && (
                        <React.Fragment>
                          <option key="closePrepaid" value="Close Prepaid">
                            Close Prepaid
                          </option>
                        </React.Fragment>
                      )} */}
                    </Form.Control>
                    {errors["adminStatus"] && (
                      <div className="alert alert-danger">
                        {errors["adminStatus"]}
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>
                {data.adminStatus === "Lender Assigned" &&
                  (staticData.loanStatus === "Loan Contract Accepted" ||
                    staticData.loanStatus === "Lender Assigned" ||
                    staticData.loanStatus === "Lender Approval") && (
                    <>
                      <Form.Row>
                        <Form.Group as={Col} md="4" controlId="partnerName">
                          <Form.Label>Select Lending Partner*</Form.Label>
                          <Form.Control
                            as="select"
                            name="partnerName"
                            value={data["partnerName"]}
                            onChange={this.handleLenderSelectionChange}
                          >
                            {" "}
                            <option key="select" value="">
                              ---Select---
                            </option>
                            {partnerList.map((item, i) => (
                              <option
                                key={`partnerName-${i}`}
                                value={item.partnerName}
                              >
                                {item.displayName}
                              </option>
                            ))}
                          </Form.Control>
                          {errors["partnerName"] && (
                            <div className="alert alert-danger">
                              {errors["partnerName"]}
                            </div>
                          )}
                        </Form.Group>
                      </Form.Row>
                      {staticData.settlementMode === "flat" && (
                        <Form.Row>
                          <Form.Group as={Col} md="4" controlId="trancheType">
                            <Form.Label>Tranche Type*</Form.Label>
                            <Form.Control
                              as="select"
                              name="trancheType"
                              value={data["trancheType"]}
                              onChange={this.handleChange}
                            >
                              {" "}
                              <option key="select" value="">
                                ---Select---
                              </option>
                              <option key="primary" value="primary">
                                Primary
                              </option>
                              <option key="redeployment" value="redeployment">
                                Redeployment
                              </option>
                            </Form.Control>
                            {errors["trancheType"] && (
                              <div className="alert alert-danger">
                                {errors["trancheType"]}
                              </div>
                            )}
                          </Form.Group>
                        </Form.Row>
                      )}
                    </>
                  )}
                {(data.adminStatus === "Approved By Admin" ||
                  data.adminStatus === "Lender Assigned") && (
                  <Form.Row>
                    <Form.Group as={Col} md="3" controlId="loanAmount">
                      <Form.Label>Amount*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Loan Amount"
                        name="loanAmount"
                        value={data["loanAmount"]}
                        onChange={this.handleCustomChange}
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                        disabled={true}
                      />
                      {errors["loanAmount"] && (
                        <div className="alert alert-danger">
                          {errors["loanAmount"]}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="2" controlId="interestRate">
                      <Form.Label>Interest*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter interest rate"
                        name="interestRate"
                        value={data["interestRate"]}
                        onChange={this.handleCustomChange}
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                      />
                      {errors["interestRate"] && (
                        <div className="alert alert-danger">
                          {errors["interestRate"]}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="2" controlId="tenure">
                      <Form.Label>Tenure*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Tenure In Days"
                        name="tenure"
                        value={data["tenure"]}
                        onChange={this.handleCustomChange}
                        autoComplete="off"
                        style={{ textTransform: "uppercase" }}
                      />
                      {errors["tenure"] && (
                        <div className="alert alert-danger">
                          {errors["tenure"]}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="repaymentFrequncy">
                      <Form.Label>Frequency*</Form.Label>
                      <Form.Control
                        as="select"
                        name="repaymentFrequency"
                        value={data["repaymentFrequency"]}
                        onChange={this.handleCustomChange}
                        disabled={
                          data.adminStatus === "Approved By Admin"
                            ? false
                            : true
                        }
                      >
                        {" "}
                        <option key="select" value="">
                          ---Select---
                        </option>
                        {repaymentFrequencyList.map((item, i) => (
                          <option
                            key={`frequency-${i}`}
                            value={item.defination}
                          >
                            {item.defination}
                          </option>
                        ))}
                      </Form.Control>
                      {errors["repaymentFrequency"] && (
                        <div className="alert alert-danger">
                          {errors["repaymentFrequency"]}
                        </div>
                      )}
                    </Form.Group>
                    {data["repaymentFrequency"] === "Adhoc" && (
                      <Form.Group as={Col} md="2" controlId="adhocDays">
                        <Form.Label>Adhoc Days </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter adhocDays In Days"
                          name="adhocDays"
                          value={data["adhocDays"]}
                          onChange={this.handleCustomChange}
                          autoComplete="off"
                          style={{ textTransform: "uppercase" }}
                          disabled={
                            data.adminStatus === "Approved By Admin"
                              ? false
                              : true
                          }
                        />
                        {errors["adhocDays"] && (
                          <div className="alert alert-danger">
                            {errors["adhocDays"]}
                          </div>
                        )}
                      </Form.Group>
                    )}
                  </Form.Row>
                )}
                {staticData.emi &&
                  (data.adminStatus === "Approved By Admin" ||
                    data.adminStatus === "Lender Assigned") && (
                    <Form.Row>
                      <Form.Group as={Col} md="2">
                        <Form.Label>Interest Type*: </Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md="6">
                        <Form.Check
                          type="radio"
                          id="interestType"
                          label="Compound"
                          value="Compound Interest"
                          name="interestType"
                          checked={
                            data["interestType"] === "Compound Interest"
                              ? "checked"
                              : ""
                          }
                          onClick={this.handleCustomChange}
                          onChange={this.handleCustomChange}
                        />
                        <Form.Check
                          type="radio"
                          id="interestType"
                          label="Simple"
                          value="Simple Interest"
                          name="interestType"
                          checked={
                            data["interestType"] === "Simple Interest"
                              ? "checked"
                              : ""
                          }
                          onClick={this.handleCustomChange}
                          onChange={this.handleCustomChange}
                        />
                      </Form.Group>

                      {staticData.productType &&
                      (!staticData.productType.startsWith("LC") ||
                        !staticData.isParentLoan) ? (
                        <Form.Group as={Col} md="4" controlId="emi1">
                          <Form.Label>
                            # of Instalments:{" "}
                            <span style={{ color: "black" }}>
                              {staticData.instalments}
                            </span>
                          </Form.Label>
                          <br />
                          <Form.Label>
                            EMI:{" "}
                            {staticData.emi && (
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"
                                style={{ color: "black" }}
                              ></i>
                            )}
                            <span style={{ color: "black" }}>
                              {staticData.emi}
                            </span>
                          </Form.Label>
                        </Form.Group>
                      ) : (
                        ""
                      )}
                    </Form.Row>
                  )}
                {data.adminStatus === "Rejected By Admin" && (
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="rejectReason">
                      <Form.Label>Select Reason*</Form.Label>
                      <Form.Control
                        as="select"
                        name="rejectReason"
                        value={data["rejectReason"]}
                        onChange={this.handleChange}
                      >
                        {" "}
                        <option key="select" value="">
                          ---Select---
                        </option>
                        {rejectReasonList.map((item, i) => (
                          <option
                            key={item.reason}
                            value={item.reason}
                            title={item.description}
                          >
                            {item.reason}
                          </option>
                        ))}
                      </Form.Control>
                      {errors["rejectReason"] && (
                        <div className="alert alert-danger">
                          {errors["rejectReason"]}
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                )}

                {data.adminStatus === "Review" && (
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="reviewReason">
                      <Form.Label>Review reason*</Form.Label>
                      <Form.Control
                        as="select"
                        name="reviewReason"
                        value={data["reviewReason"]}
                        onChange={this.handleChange}
                      >
                        <option key="select" value="">
                          ---Select---
                        </option>
                        {this.state.reviewReasonList.map((item, i) => (
                          <option key={i} value={item.defination}>
                            {item.defination}
                          </option>
                        ))}
                      </Form.Control>
                      {errors["reviewReason"] && (
                        <div className="alert alert-danger">
                          {errors["reviewReason"]}
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                )}

                {(data.adminStatus !== "Rejected By Admin" ||
                  data.rejectReason === "Others") && (
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="remarks">
                      <Form.Label>Admin Comments</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="remarks"
                        value={data["remarks"]}
                        onChange={this.handleChange}
                      />
                      {errors["remarks"] && (
                        <div className="alert alert-danger">
                          {errors["remarks"]}
                        </div>
                      )}
                    </Form.Group>
                  </Form.Row>
                )}

                {staticData.loanStatus === "Loan Contract Accepted" &&
                  staticData.kycStatus === "Approved" &&
                  !staticData.isPhotoMatch &&
                  data.adminStatus === "Lender Assigned" && (
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="photoMatchStatus"
                        className="pt-2"
                      >
                        <Form.Label>Photo Match*</Form.Label>
                        <Form.Control
                          as="select"
                          name="photoMatchStatus"
                          value={data["photoMatchStatus"]}
                          onChange={this.handleChange}
                        >
                          <React.Fragment>
                            <option key="no" value="NO">
                              No
                            </option>
                            <option key="yes" value="YES">
                              Yes
                            </option>
                          </React.Fragment>
                        </Form.Control>
                        {errors["photoMatchStatus"] && (
                          <div className="alert alert-danger">
                            {errors["photoMatchStatus"]}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="photoMatchRemarks"
                        className="pt-2"
                      >
                        <Form.Label>Photo Match Remarks*</Form.Label>
                        <Form.Control
                          as="select"
                          name="photoMatchRemarks"
                          value={data["photoMatchRemarks"]}
                          onChange={this.handleChange}
                        >
                          <React.Fragment>
                            <option key="default" value="">
                              ---Select---
                            </option>
                            {photoMatchDropdownOptions?.length
                              ? photoMatchDropdownOptions.map((item) => (
                                  <option key={item.title} value={item.value}>
                                    {item.title}
                                  </option>
                                ))
                              : ""}
                          </React.Fragment>
                        </Form.Control>
                        {errors["photoMatchRemarks"] && (
                          <div className="alert alert-danger">
                            {errors["photoMatchRemarks"]}
                          </div>
                        )}
                      </Form.Group>
                    </Form.Row>
                  )}

                <Button
                  className="ff-button"
                  type="submit"
                  disabled={isSubmitted}
                >
                  Submit
                </Button>
                {errors["globalError"] && (
                  <div className="alert alert-danger" style={{ marginTop: 10 }}>
                    {errors["globalError"]}
                  </div>
                )}
              </Form>
            ))}
          {staticData.evaluatorRole === "Lending Partner" &&
            staticData.kycStatus === "Approved" &&
            staticData.loanStatus === "Lender Approval" &&
            ((this.props &&
              this.props.location &&
              this.props.location.customProps &&
              this.props.location.customProps.fromTeleCalling) ||
            (this.props.match &&
              this.props.match.params &&
              this.props.match.params.isFrom == "teleCalling") ? (
              <></>
            ) : (
              <Form onSubmit={this.doSubmit}>
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Scrutinize
                </h5>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="adminStatus">
                    <Form.Label>Select Action*</Form.Label>
                    <Form.Control
                      as="select"
                      name="adminStatus"
                      value={data["adminStatus"]}
                      onChange={this.handleChange}
                    >
                      <option key="select" value="">
                        ---Select---
                      </option>
                      {staticData.loanStatus === "Lender Approval" &&
                        staticData.kycStatus === "Approved" && (
                          <React.Fragment>
                            <option key="approved" value="Approved By Lender">
                              Approve
                            </option>
                            <option key="rejected" value="Rejected By Lender">
                              Reject
                            </option>
                          </React.Fragment>
                        )}
                    </Form.Control>
                    {errors["adminStatus"] && (
                      <div className="alert alert-danger">
                        {errors["adminStatus"]}
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>

                {staticData.loanStatus === "Lender Approval" &&
                  (data.adminStatus === "Approved By Lender" ||
                    data.adminStatus === "Rejected By Lender") && (
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="lenderComments">
                        <Form.Label>Comments</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="lenderComments"
                          value={data["lenderComments"]}
                          onChange={this.handleChange}
                        />
                        {errors["lenderComments"] && (
                          <div className="alert alert-danger">
                            {errors["lenderComments"]}
                          </div>
                        )}
                      </Form.Group>
                    </Form.Row>
                  )}
                <Button
                  className="ff-button"
                  type="submit"
                  disabled={isSubmitted}
                >
                  Submit
                </Button>
                {errors["globalError"] && (
                  <div className="alert alert-danger">
                    {errors["globalError"]}
                  </div>
                )}
              </Form>
            ))}
          <Modal
            show={bankDocDisplay}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.hideBankDocInfo}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="fundfinaColor"
              >
                Bank Verification
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
              <Form>
                <Form.Row>
                  {bankDoc.bankName && (
                    <Form.Group as={Col} md="6" controlId="bank">
                      <Form.Label>
                        Bank Name:{" "}
                        <span style={{ color: "black" }}>
                          {bankDoc.bankName}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  )}
                  {bankDoc.branch && (
                    <Form.Group as={Col} md="6" controlId="bank">
                      <Form.Label>
                        Branch Name:{" "}
                        <span style={{ color: "black" }}>{bankDoc.branch}</span>
                      </Form.Label>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  {bankDoc.accountNo && (
                    <Form.Group as={Col} md="6" controlId="bank">
                      <Form.Label>
                        Account No:{" "}
                        <span style={{ color: "black" }}>
                          {bankDoc.accountNo}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  )}
                  {bankDoc.ifsc && (
                    <Form.Group as={Col} md="6" controlId="bank">
                      <Form.Label>
                        Ifsc Code:{" "}
                        <span style={{ color: "black" }}>{bankDoc.ifsc}</span>
                      </Form.Label>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="bank">
                    <Form.Label>
                      Verified:{" "}
                      <span
                        style={{
                          color: bankDoc.isVerified === "Yes" ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {bankDoc.isVerified}
                      </span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="bank">
                    <Form.Label>
                      Document:{" "}
                      {bankDoc.docPath && (
                        <a
                          href="#"
                          onClick={() => this.openFile(bankDoc.docPath)}
                        >
                          {" "}
                          View
                        </a>
                      )}
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                {bankDoc.nameWithBank && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="bank">
                      <Form.Label>
                        Name With Bank:{" "}
                        <span style={{ color: "black" }}>
                          {bankDoc.nameWithBank}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {(bankDoc.code || bankDoc.message) && (
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Error Information
                  </h5>
                )}
                {bankDoc.code && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Error Code:{" "}
                        <span style={{ color: "black" }}>{bankDoc.code}</span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {bankDoc.message && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Error Message:{" "}
                        <span style={{ color: "black" }}>
                          {bankDoc.message}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideBankDocInfo}>Close</Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={gstDocDisplay}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.hideGstDocInfo}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="fundfinaColor"
              >
                GST Verification
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
              {gstDoc?.gstDoc ? (
                <>
                  <div className="row text-center">
                    <div className={`col-12 text-center`}>
                      {this.renderFilePreview(gstDoc.gstDoc)}
                    </div>
                  </div>
                  <div className="border-dot-line">&nbsp; </div>
                </>
              ) : (
                ""
              )}

              <Form className="pt-4">
                {gstDoc.corporateName && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Corporate Name:{" "}
                        <span style={{ color: "black" }}>
                          {gstDoc.corporateName}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {gstDoc.legalName && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Legal Name:{" "}
                        <span style={{ color: "black" }}>
                          {gstDoc.legalName}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                <Form.Row>
                  {gstDoc.gstNo && (
                    <Form.Group as={Col} md="6" controlId="gst">
                      <Form.Label>
                        GST No:{" "}
                        <span style={{ color: "black" }}>{gstDoc.gstNo}</span>
                      </Form.Label>
                    </Form.Group>
                  )}
                  {/* <Form.Group as={Col} md="6" controlId="gst">
                    <Form.Label>Document: {gstDoc.docPath && (
                      <a
                        href="#"
                        onClick={() => this.openFile(bankDoc.docPath)}
                      >
                        {" "}
                        View
                      </a>
                    )}</Form.Label>
                  </Form.Group> */}
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="gst">
                    <Form.Label>
                      Verified:{" "}
                      <span
                        style={{
                          color: gstDoc.isVerified === "Yes" ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {gstDoc.isVerified}
                      </span>
                    </Form.Label>
                  </Form.Group>
                  {gstDoc.status && (
                    <Form.Group as={Col} md="6" controlId="gst">
                      <Form.Label>
                        Status:{" "}
                        <span style={{ color: "black" }}>{gstDoc.status}</span>
                      </Form.Label>
                    </Form.Group>
                  )}
                </Form.Row>

                <Form.Row>
                  {gstDoc.ctb && (
                    <Form.Group as={Col} md="6" controlId="gst">
                      <Form.Label>
                        Constitution of Business:{" "}
                        <span style={{ color: "black" }}>{gstDoc.ctb}</span>
                      </Form.Label>
                    </Form.Group>
                  )}
                  {gstDoc.dty && (
                    <Form.Group as={Col} md="6" controlId="gst">
                      <Form.Label>
                        Type of Registration:{" "}
                        <span style={{ color: "black" }}>{gstDoc.dty}</span>
                      </Form.Label>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  {gstDoc.rgdt && (
                    <Form.Group as={Col} md="6" controlId="gst">
                      <Form.Label>
                        Valid From:{" "}
                        <span style={{ color: "black" }}>{gstDoc.rgdt}</span>
                      </Form.Label>
                    </Form.Group>
                  )}
                </Form.Row>
                {gstDoc.tradeName && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Trade Name:{" "}
                        <span style={{ color: "black" }}>
                          {gstDoc.tradeName}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {gstDoc.address && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Address:{" "}
                        <span style={{ color: "black" }}>{gstDoc.address}</span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {(gstDoc.code || gstDoc.message) && (
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Error Information
                  </h5>
                )}
                {gstDoc.code && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Error Code:{" "}
                        <span style={{ color: "black" }}>{gstDoc.code}</span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {gstDoc.message && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        Error Message:{" "}
                        <span style={{ color: "black" }}>{gstDoc.message}</span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideGstDocInfo}>Close</Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={panDocDisplay}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.hidePanDocInfo}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="fundfinaColor"
              >
                PAN Verification
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
              <div className="row text-center">
                <div className={`col-12 text-center`}>
                  {this.renderFilePreview(panDoc.panDoc)}
                </div>
              </div>
              <div className="border-dot-line">&nbsp; </div>

              <Form className="pt-4">
                <Form.Row>
                  {panDoc.panNo && (
                    <Form.Group as={Col} md="4" controlId="pan">
                      <Form.Label>
                        PAN Number:{" "}
                        <span style={{ color: "black" }}>
                          {staticData.evaluatorRole === "Admin" && panDoc.panNo
                            ? panDoc.panNo
                            : ""}
                          {staticData.evaluatorRole !== "Admin" && panDoc.panNo
                            ? panDoc.panNo.substr(0, 3) + "XXXXXXX"
                            : ""}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  )}
                  {panDoc.type && (
                    <Form.Group as={Col} md="4" controlId="pan">
                      <Form.Label>
                        Verification Type:{" "}
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          {panDoc.type}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  )}
                  {panDoc.isNumberVerified && (
                    <Form.Group as={Col} md="4" controlId="pan">
                      <Form.Label>
                        Number Verified:{" "}
                        <span
                          style={{
                            color:
                              panDoc.isNumberVerified === "Yes"
                                ? "green"
                                : "red",
                            fontWeight: "bold",
                          }}
                        >
                          {panDoc.isNumberVerified}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  )}
                </Form.Row>
                <Form.Row>
                  {panDoc.isDocVerified && (
                    <Form.Group as={Col} md="4" controlId="pan">
                      <Form.Label>
                        Document Verified:{" "}
                        <span
                          style={{
                            color:
                              panDoc.isDocVerified === "Yes" ? "green" : "red",
                            fontWeight: "bold",
                          }}
                        >
                          {panDoc.isDocVerified}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  )}
                  {panDoc.isDocMatch && (
                    <Form.Group as={Col} md="4" controlId="pan">
                      <Form.Label>
                        Document Match:{" "}
                        <span
                          style={{
                            color:
                              panDoc.isDocMatch === "Yes" ? "green" : "red",
                            fontWeight: "bold",
                          }}
                        >
                          {panDoc.isDocMatch}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  )}

                  {/* {panDoc.docPath && (
                    <Form.Group as={Col} md="4" controlId="pan">
                      <Form.Label>Document: {panDoc.docPath && (
                        <a
                          href="#"
                          onClick={() => this.openFile(panDoc.docPath, true)}
                        >
                          {" "}
                          View
                        </a>
                      )}</Form.Label>
                    </Form.Group>)} */}
                </Form.Row>

                {panDoc.isDocVerified === "Yes" && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Information
                    </h5>{" "}
                    {panDoc.name && (
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>
                            Name:{" "}
                            <span style={{ color: "black" }}>
                              {panDoc.name}
                            </span>
                          </Form.Label>
                        </Form.Group>
                      </Form.Row>
                    )}
                    {panDoc.fathers_name && (
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>
                            Father's Name:{" "}
                            <span style={{ color: "black" }}>
                              {panDoc.fathers_name}
                            </span>
                          </Form.Label>
                        </Form.Group>
                      </Form.Row>
                    )}
                    <Form.Row>
                      {panDoc.id_no && (
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>
                            ID No:{" "}
                            <span style={{ color: "black" }}>
                              {staticData.evaluatorRole === "Admin" &&
                              panDoc.id_no
                                ? panDoc.id_no
                                : ""}
                              {staticData.evaluatorRole !== "Admin" &&
                              panDoc.id_no
                                ? panDoc.id_no.substr(0, 3) + "XXXXXXX"
                                : ""}
                            </span>
                          </Form.Label>
                        </Form.Group>
                      )}
                      {panDoc.dob && (
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>
                            DOB:{" "}
                            <span style={{ color: "black" }}>{panDoc.dob}</span>
                          </Form.Label>
                        </Form.Group>
                      )}
                    </Form.Row>
                  </React.Fragment>
                )}
                {(panDoc.api || panDoc.ocr) &&
                  (panDoc.api.code ||
                    panDoc.api.message ||
                    panDoc.ocr.code ||
                    panDoc.ocr.message) && (
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Error Information
                    </h5>
                  )}
                {panDoc.api && panDoc.api.code && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        API Error Code:{" "}
                        <span style={{ color: "black" }}>
                          {panDoc.api && panDoc.api.code}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {panDoc.api && panDoc.api.message && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        API Error Message:{" "}
                        <span style={{ color: "black" }}>
                          {panDoc.api && panDoc.api.message}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {panDoc.ocr && panDoc.ocr.code && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        OCR Error Code:{" "}
                        <span style={{ color: "black" }}>
                          {panDoc.ocr && panDoc.ocr.code}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
                {panDoc.ocr && panDoc.ocr.message && (
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="gst">
                      <Form.Label>
                        OCR Error Message:{" "}
                        <span style={{ color: "black" }}>
                          {panDoc.ocr && panDoc.ocr.message}
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Form.Row>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hidePanDocInfo}>Close</Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={poaDocDisplay}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.hidePoaDocInfo}
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="fundfinaColor"
              >
                POA Verification
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
              <div className="row text-center">
                <div className={`${poaDoc.poaBackDoc ? "col-6" : "col"}`}>
                  {this.renderFilePreview(poaDoc.poaDoc)}
                </div>
                {poaDoc.poaBackDoc ? (
                  <div className="col-6">
                    {" "}
                    {this.renderFilePreview(poaDoc.poaBackDoc)}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="border-dot-line">&nbsp; </div>

              <Form className="pt-4">
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Data Entered
                </h5>{" "}
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="pan">
                    <Form.Label>
                      POA Number:{" "}
                      <span style={{ color: "black" }}>{poaDoc.poaNo}</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="pan">
                    <Form.Label>
                      POA Type:{" "}
                      <span style={{ color: "black", fontWeight: "bold" }}>
                        {poaDoc.poaType}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                {/* <Form.Row>
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>Document: {poaDoc.poaDoc && (
                      <a
                        href="#"
                        onClick={() => this.openFile(poaDoc.poaDoc)}
                      >
                        {" "}
                        File 1
                      </a>
                    )}
                      {poaDoc.poaBackDoc && (
                        <a
                          href="#"
                          onClick={() => this.openFile(poaDoc.poaBackDoc)}
                        >
                          {" "}
                          File 2
                        </a>
                      )}
                    </Form.Label>
                  </Form.Group>
                </Form.Row> */}
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Verification Information:
                </h5>{" "}
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>
                      Number Verified:{" "}
                      <span
                        style={{
                          color:
                            poaDoc.docVerification &&
                            poaDoc.docVerification.isNumberVerified === "Yes"
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {poaDoc.docVerification &&
                          poaDoc.docVerification.isNumberVerified}
                      </span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>
                      Document Verified:{" "}
                      <span
                        style={{
                          color:
                            poaDoc.docVerification &&
                            poaDoc.docVerification.isDocVerified === "Yes"
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {poaDoc.docVerification &&
                          poaDoc.docVerification.isDocVerified}
                      </span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>
                      Document Match:{" "}
                      <span
                        style={{
                          color:
                            poaDoc.docVerification &&
                            poaDoc.docVerification.isDocMatch === "Yes"
                              ? "green"
                              : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {poaDoc.docVerification &&
                          poaDoc.docVerification.isDocMatch}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>
                      Type:{" "}
                      <span style={{ color: "black" }}>
                        {poaDoc.docVerification && poaDoc.docVerification.type}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
                {poaDoc.docVerification &&
                  poaDoc.docVerification.docType &&
                  poaDoc.docVerification.docType === "DRIVING_LICENSE" && (
                    <React.Fragment>
                      {poaDoc.docVerification.isDocVerified === "Yes" && (
                        <React.Fragment>
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            OCR Information:
                          </h5>{" "}
                          <Form.Row>
                            <Form.Group as={Col} md="12" controlId="pan">
                              <Form.Label>
                                Name:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification.dl.ocr &&
                                    poaDoc.docVerification.dl.ocr.name}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                ID No:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification.dl.ocr &&
                                    poaDoc.docVerification.dl.ocr.id_no}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                ID Type:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification.dl.ocr &&
                                    poaDoc.docVerification.dl.ocr.id_type}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                DOB:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification &&
                                    poaDoc.docVerification.dl &&
                                    poaDoc.docVerification.dl.ocr &&
                                    poaDoc.docVerification.dl.ocr.dob}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                        </React.Fragment>
                      )}
                      {poaDoc.docVerification &&
                        (poaDoc.docVerification.isNumberVerified === "Yes" ||
                          (poaDoc.docVerification.isDocVerified === "Yes" && (
                            <React.Fragment>
                              <h5
                                className="fundfinaColor"
                                style={{ textDecoration: "underline" }}
                              >
                                Data:
                              </h5>{" "}
                              <Form.Row>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    Holder's Name:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse.Holders_Name}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    Date of Issue:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse.Date_Of_Issue}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    NT:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse.NT}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    Old_New_DL_No:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse.Old_New_DL_No}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    Hazardous_Valid_Till:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse
                                          .Hazardous_Valid_Till}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    NonTransport:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse.NonTransport}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    Transport:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse.Transport}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    CurrentStatus:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse.CurrentStatus}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    LastTransactionAt:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification &&
                                        poaDoc.docVerification.dl &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse &&
                                        poaDoc.docVerification.dl
                                          .verificationResponse
                                          .LastTransactionAt}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                            </React.Fragment>
                          )))}
                      {(poaDoc.api || poaDoc.ocr) &&
                        (poaDoc.api.code ||
                          poaDoc.api.message ||
                          poaDoc.ocr.code ||
                          poaDoc.ocr.message) && (
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            Error Information:
                          </h5>
                        )}
                      {poaDoc.api && poaDoc.api.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.api && poaDoc.api.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                    </React.Fragment>
                  )}
                {poaDoc.docVerification &&
                  poaDoc.docVerification.docType &&
                  poaDoc.docVerification.docType === "PASSPORT" && (
                    <React.Fragment>
                      {poaDoc.docVerification.type === "Ocr" && (
                        <React.Fragment>
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            OCR Information:
                          </h5>{" "}
                          <Form.Row>
                            <Form.Group as={Col} md="12" controlId="pan">
                              <Form.Label>
                                Name:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr.name}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                ID No:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr.id_no}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                ID Type:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr.id_type}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                DOB:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr.dob}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                Gender:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr.gender}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                Country Code:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr
                                      .country_code}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                Surname:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr
                                      .sur_name}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                Nationality:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr
                                      .nationality}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                Place of Birth:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr
                                      .place_of_birth}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                Place of Issue:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.passport &&
                                    poaDoc.docVerification.passport.ocr &&
                                    poaDoc.docVerification.passport.ocr
                                      .place_of_issue}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                        </React.Fragment>
                      )}
                      {(poaDoc.api || poaDoc.ocr) &&
                        (poaDoc.api.code ||
                          poaDoc.api.message ||
                          poaDoc.ocr.code ||
                          poaDoc.ocr.message) && (
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            Error Information
                          </h5>
                        )}
                      {poaDoc.api && poaDoc.api.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.api && poaDoc.api.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                    </React.Fragment>
                  )}
                {poaDoc.docVerification &&
                  poaDoc.docVerification.docType &&
                  poaDoc.docVerification.docType === "AADHAAR" && (
                    <React.Fragment>
                      {(poaDoc.docVerification.type === "Ocr" ||
                        poaDoc.docVerification.type === "digilocker") && (
                        <React.Fragment>
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            Details
                          </h5>{" "}
                          <Form.Row>
                            <Form.Group as={Col} md="12" controlId="pan">
                              <Form.Label>
                                Name:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.uid &&
                                    poaDoc.docVerification.uid.ocr &&
                                    poaDoc.docVerification.uid.ocr.name}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                ID No:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.uid &&
                                    poaDoc.docVerification.uid.ocr &&
                                    poaDoc.docVerification.uid.ocr.id_no}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                ID Type:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.uid &&
                                    poaDoc.docVerification.uid.ocr &&
                                    poaDoc.docVerification.uid.ocr.id_type}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                DOB:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.uid &&
                                    poaDoc.docVerification.uid.ocr &&
                                    poaDoc.docVerification.uid.ocr.dob}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="pan">
                              <Form.Label>
                                Gender:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.uid &&
                                    poaDoc.docVerification.uid.ocr &&
                                    poaDoc.docVerification.uid.ocr.gender}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row></Form.Row>
                          {poaDoc.docVerification &&
                            poaDoc.docVerification.uid &&
                            poaDoc.docVerification.uid.ocr &&
                            poaDoc.docVerification.uid.ocr.address && (
                              <Form.Row>
                                <Form.Group as={Col} md="12" controlId="pan">
                                  <Form.Label>
                                    Address:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.uid &&
                                        poaDoc.docVerification.uid.ocr &&
                                        poaDoc.docVerification.uid.ocr.address}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                            )}
                          <Form.Row>
                            {poaDoc.docVerification &&
                              poaDoc.docVerification.uid &&
                              poaDoc.docVerification.uid.ocr &&
                              poaDoc.docVerification.uid.ocr
                                .address_information &&
                              poaDoc.docVerification.uid.ocr.address_information
                                .locality_or_post_office && (
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    Locality:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.uid &&
                                        poaDoc.docVerification.uid.ocr &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information
                                          .locality_or_post_office}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              )}
                            {poaDoc.docVerification &&
                              poaDoc.docVerification.uid &&
                              poaDoc.docVerification.uid.ocr &&
                              poaDoc.docVerification.uid.ocr
                                .address_information &&
                              poaDoc.docVerification.uid.ocr.address_information
                                .district_or_city && (
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    District:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.uid &&
                                        poaDoc.docVerification.uid.ocr &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information.district_or_city}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              )}
                          </Form.Row>
                          <Form.Row>
                            {poaDoc.docVerification &&
                              poaDoc.docVerification.uid &&
                              poaDoc.docVerification.uid.ocr &&
                              poaDoc.docVerification.uid.ocr
                                .address_information &&
                              poaDoc.docVerification.uid.ocr.address_information
                                .state && (
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    State:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.uid &&
                                        poaDoc.docVerification.uid.ocr &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information.state}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              )}
                            {poaDoc.docVerification &&
                              poaDoc.docVerification.uid &&
                              poaDoc.docVerification.uid.ocr &&
                              poaDoc.docVerification.uid.ocr
                                .address_information &&
                              poaDoc.docVerification.uid.ocr.address_information
                                .pincode && (
                                <Form.Group as={Col} md="6" controlId="pan">
                                  <Form.Label>
                                    Pincode:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.uid &&
                                        poaDoc.docVerification.uid.ocr &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information &&
                                        poaDoc.docVerification.uid.ocr
                                          .address_information.pincode}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              )}
                          </Form.Row>
                        </React.Fragment>
                      )}
                      {(poaDoc.api || poaDoc.ocr) &&
                        (poaDoc.api.code ||
                          poaDoc.api.message ||
                          poaDoc.ocr.code ||
                          poaDoc.ocr.message) && (
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            Error Information
                          </h5>
                        )}
                      {poaDoc.api && poaDoc.api.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.api && poaDoc.api.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                    </React.Fragment>
                  )}
                {poaDoc.docVerification &&
                  poaDoc.docVerification.docType &&
                  poaDoc.docVerification.docType === "VOTER_ID" && (
                    <React.Fragment>
                      {poaDoc.docVerification.isDocVerified === "Yes" && (
                        <React.Fragment>
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            OCR Information:
                          </h5>{" "}
                          <Form.Row>
                            <Form.Group as={Col} md="12" controlId="pan">
                              <Form.Label>
                                Name:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.voterId &&
                                    poaDoc.docVerification.voterId.ocr &&
                                    poaDoc.docVerification.voterId.ocr.name}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="12" controlId="pan">
                              <Form.Label>
                                Father's Name:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.voterId &&
                                    poaDoc.docVerification.voterId.ocr &&
                                    poaDoc.docVerification.voterId.ocr
                                      .fathers_name}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="4" controlId="pan">
                              <Form.Label>
                                Id No:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.voterId &&
                                    poaDoc.docVerification.voterId.ocr &&
                                    poaDoc.docVerification.voterId.ocr.id_no}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="pan">
                              <Form.Label>
                                Document Type:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.voterId &&
                                    poaDoc.docVerification.voterId.ocr &&
                                    poaDoc.docVerification.voterId.ocr.id_type}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="4" controlId="pan">
                              <Form.Label>
                                Gender:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.voterId &&
                                    poaDoc.docVerification.voterId.ocr &&
                                    poaDoc.docVerification.voterId.ocr.gender}
                                </span>
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="pan">
                              <Form.Label>
                                Issue date:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.voterId &&
                                    poaDoc.docVerification.voterId.ocr &&
                                    poaDoc.docVerification.voterId.ocr
                                      .date_of_issue}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="12" controlId="pan">
                              <Form.Label>
                                Address:{" "}
                                <span style={{ color: "black" }}>
                                  {poaDoc.docVerification &&
                                    poaDoc.docVerification.voterId &&
                                    poaDoc.docVerification.voterId.ocr &&
                                    poaDoc.docVerification.voterId.ocr.address}
                                </span>
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                        </React.Fragment>
                      )}
                      {poaDoc.docVerification &&
                        (poaDoc.docVerification.isNumberVerified === "Yes" ||
                          (poaDoc.docVerification.isDocVerified === "Yes" && (
                            <React.Fragment>
                              <h5
                                className="fundfinaColor"
                                style={{ textDecoration: "underline" }}
                              >
                                Data:
                              </h5>{" "}
                              <Form.Row>
                                <Form.Group as={Col} md="12" controlId="pan">
                                  <Form.Label>
                                    Name:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.name}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="12" controlId="pan">
                                  <Form.Label>
                                    Father's Name:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.rln_name}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="4" controlId="pan">
                                  <Form.Label>
                                    Id No:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.id_no}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="pan">
                                  <Form.Label>
                                    Age:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.age}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="pan">
                                  <Form.Label>
                                    Gender:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.gender}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="12" controlId="pan">
                                  <Form.Label>
                                    Address:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.part_name}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                              <Form.Row>
                                <Form.Group as={Col} md="4" controlId="pan">
                                  <Form.Label>
                                    District:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.dist_name}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="pan">
                                  <Form.Label>
                                    State:{" "}
                                    <span style={{ color: "black" }}>
                                      {poaDoc.docVerification &&
                                        poaDoc.docVerification.voterId &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse &&
                                        poaDoc.docVerification.voterId
                                          .verificationResponse.st_name}
                                    </span>
                                  </Form.Label>
                                </Form.Group>
                              </Form.Row>
                            </React.Fragment>
                          )))}
                      {(poaDoc.api || poaDoc.ocr) &&
                        (poaDoc.api.code ||
                          poaDoc.api.message ||
                          poaDoc.ocr.code ||
                          poaDoc.ocr.message) && (
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            Error Information:
                          </h5>
                        )}
                      {poaDoc.api && poaDoc.api.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.api && poaDoc.api.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                    </React.Fragment>
                  )}
                {poaDoc.docVerification &&
                  poaDoc.docVerification.docType === "" && (
                    <React.Fragment>
                      {(poaDoc.api || poaDoc.ocr) &&
                        (poaDoc.api.code ||
                          poaDoc.api.message ||
                          poaDoc.ocr.code ||
                          poaDoc.ocr.message) && (
                          <h5
                            className="fundfinaColor"
                            style={{ textDecoration: "underline" }}
                          >
                            Error Information
                          </h5>
                        )}
                      {poaDoc.api && poaDoc.api.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.api && poaDoc.api.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              API Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.api && poaDoc.api.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.code && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Code:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.code}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                      {poaDoc.ocr && poaDoc.ocr.message && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="gst">
                            <Form.Label>
                              OCR Error Message:{" "}
                              <span style={{ color: "black" }}>
                                {poaDoc.ocr && poaDoc.ocr.message}
                              </span>
                            </Form.Label>
                          </Form.Group>
                        </Form.Row>
                      )}
                    </React.Fragment>
                  )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hidePoaDocInfo}>Close</Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
        <Modal show={showEnachPopUp} onHide={this.handleEnachPopupClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            A mandate link will be sent to the user which will be assigned to
            this loan. Do you want to proceed?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="ff-button"
              onClick={this.handleEnachPopupOk}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              className="ff-button"
              onClick={this.handleEnachPopupClose}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showTrueScorePopUp}
          onHide={this.handleTrueScorePopupClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Search Parameters</Modal.Title>
          </Modal.Header>
          <Modal.Body>{trueScoreError}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="ff-button"
              onClick={this.handleTrueScorePopupClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showInfoPopup} onHide={this.handleInfoPopupClose}>
          <Modal.Header closeButton>
            <Modal.Title
              style={{ color: infoHeader === "Error" ? "red" : "green" }}
            >
              {infoHeader}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{ color: infoHeader === "Error" ? "red" : "green" }}
          >
            {infoMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="ff-button"
              onClick={this.handleInfoPopupClose}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showSuccessModal}
          onHide={this.closeSuccessModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>{this.state.infoHeader}</b>
          </Modal.Header>
          <Modal.Body>{this.state.infoMessage}</Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeSuccessModal} className="ff-button">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default LoanInfoForm;
