import React, { Component } from 'react';
import {
    Modal
} from "react-bootstrap";
import * as commonService from "../../services/common/commonService";
import FileUploaderModel from "../../models/settlementMIS/fileUploader";

class FileUploader extends FileUploaderModel {

    render() {
        return (

            <Modal show={true} onHide={this.props.onClose} size={'md'}>
                <Modal.Header onHide={this.props.onClose} className="custom-z-index" closeButton>
                    Upload Settlement MIS
                </Modal.Header>
                <Modal.Body>
                    <div className="image-uploader">
                        {!this.state.fileName ? <div
                            onDragEnter={this.onDragEnter}
                            onDragLeave={this.onDragLeave}
                            onDragOver={this.doNothing}
                            onDrop={this.onDragLeave}>

                            <div className={`drop-area ${this.state.status === 'Drop' ? 'over' : ''}`}
                                onDragOver={this.onDragOver}
                                onDragLeave={this.onDragEnter}
                                onDrop={this.onDrop}>


                                {!this.state.showProgress ? <div className="row mt-3">
                                    <div className="col">
                                        <span className="status">{this.state.status}
                                            <label className="m-0 ml-1 cursor-pointer" style={{ "cursor": "pointer" }}>
                                                <input
                                                    type="file" id="file-uploader-1" style={{ "display": "none" }}
                                                    onChange={(event) => this.onImageUpload(event)} /> (or) <span>Click here to upload</span>
                                            </label>
                                        </span>
                                    </div>
                                </div> : <></>}

                            </div>
                        </div> : ""}


                        {this.state.fileName && this.state.showProgress ? <div>
                            <div className="row">
                                <div className="col">
                                    <div style={{ "display": this.state.fileName ? 'block' : 'none' }}>
                                        <strong>File : </strong> <span>{this.state.fileName}</span>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="progress-wrapper">
                                        <div className="progress-info">
                                            <div className="progress-percentage text-right">
                                                <small><strong>{`${this.state.percentage}%`}</strong></small>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar bg-primary" style={{ width: `${this.state.percentage}%` }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.showProgress && this.state.percentage == 100 && <div className="row mt-3">
                                <div className="col text-right">
                                    <button type="button" className="btn btn-sm btn-primary ff-button" onClick={this.clearImage}>
                                        Upload More
                                    </button>
                                </div>
                            </div>}
                        </div> : ""}
                    </div>
                </Modal.Body>
            </Modal >
        );
    }
}

export default FileUploader;