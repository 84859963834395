import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PayInPayOutTableModel from "../../models/payInPayOut/payInPayOutTableModel";
import { Form, Col, Button, Table } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";

class PayInPayOutTable extends PayInPayOutTableModel {
  getPagedData = () => {
    const { pageSize, currentPage, data } = this.state;

    let users = data.users;

    const filteredUsers = paginate(users, currentPage, pageSize);

    return { totalCount: users.length, data: filteredUsers };
  };
  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      loanStatusList,
    } = this.state;

    const { totalCount, data: users } = this.getPagedData();

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 className="fundfinaColor"
          style={{ textDecoration: "underline", textAlign: "center" }}>Pay-In</h2>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={data["name"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
            {/* <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No</Form.Label>
              <Form.Control
                type="text"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
            <Form.Group as={Col} md="4" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                name="email"
                value={data["email"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "} */}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4">
              <Button
                className="ff-button"
                type="submit"
                style={{ marginTop: 30, marginLeft: 10 }}
              >
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Creation Date</th>
              <th>Name</th>
              <th>Balance</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr key={user._id}>
                  <td>{moment(user.insertedOn).format("DD/MM/yyyy")}</td>
                  <td>{user.name}</td>
                  <td>{user.balance}</td>
                  <td>
                    <Link to={`/payInPayOutInfo/${user._id}`}>Update</Link>
                  </td>
                </tr>
              ))}
            {!users && (
              <tr key="no">
                <td colSpan="10">No record found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onPagePrevious={this.handlePagePrevious}
            onPageNext={this.handlePageNext}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PayInPayOutTable;
