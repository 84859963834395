import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import BankStatementsModel from "../../models/BankStatements/BankStatements";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";


class PartnerConfigurations extends BankStatementsModel {

  render() {
    const {
      pageSize,
      currentPage,
      showSpinner,
      partners,
      successMsg,
      errors,
      data,
      statements,
      transactionTypes,
      accounts
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Bank Statement
        </h2>
        <Form onSubmit={this.handleSubmit}>

          <Form.Row>
            <Form.Group as={Col} md="4" controlId="valueStartDate">
              <Form.Label>From Date <small className="text-success">(Max Date Range 60 days)</small></Form.Label>
              <DatePicker
                name="valueStartDate"
                id="valueStartDate"
                value={data["valueStartDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleValueFromDateChange(value)}
                placeholder="Enter From Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="valueEndDate">
              <Form.Label>To Date</Form.Label>
              <DatePicker
                name="valueEndDate"
                id="valueEndDate"
                value={data["valueEndDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleValueToDateChange(value)}
                placeholder="Enter To Date"
              />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                name="amount"
                value={data["amount"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>

            <Form.Group as={Col} md="4" controlId="partnerType">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={data["type"]}
                onChange={this.handleChange}
              >
                {" "}
                {transactionTypes && transactionTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>UTR No</Form.Label>
              <Form.Control
                type="text"
                name="utrNo"
                value={data["utrNo"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Narration Text</Form.Label>
              <Form.Control
                type="text"
                name="narration"
                value={data["narration"]}
                onChange={this.handleChange}
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="account">
              <Form.Label>Account</Form.Label>
              <Form.Control
                as="select"
                name="account"
                value={data["account"]}
                onChange={this.handleChange}
              >
                {" "}
                {accounts && accounts.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md="4">
              <br />
              <Button
                className="ff-button mr-2 mt-2"
                type="submit"
              >
                Apply Filters
              </Button>

              <Button className="ff-button mt-2" onClick={() => this.getBankStatementDownload()}>
                Request Download
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>

        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        <div style={{ width: '100%' }}>
          <StickyTable>
            <Row>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", borderLeft: "1px solid" }}><b>Amount</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Transaction Type</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>UTR</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Value Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Posting Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Balance</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Narration Text</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Manual Queue</b></Cell>
            </Row>
            {statements &&
              statements.map((statement, i) => (
                <Row key={statement.narration}>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid" }}>{statement.amount}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.transactionType}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.utrNo}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.valueDate && new Date(statement.valueDate).toLocaleDateString()}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.postingDate && new Date(statement.postingDate).toLocaleDateString()}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.balance}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{statement.narration}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                   {!statement.isMovedManualQueue ? <Button
                      className="ff-button btn-sm"
                      type="submit"
                      onClick={()=> {this.moveBankStatementToManualQueueApi(statement)}}
                    >
                      Move
                    </Button> : <></>}
                  </Cell>
                </Row>
              ))}
            {!statements || !statements.length && (
              <Row>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }} Col={5}>No record found</Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
              </Row>
            )}

          </StickyTable>
        </div >
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
        </div>
      </React.Fragment >
    );
  }
}

export default PartnerConfigurations;
