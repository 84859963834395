import * as kycFormService from "../../services/borrow/kycFormService";
import * as commonService from "../../services/common/commonService";
import * as shopFormService from "../../services/borrow/shopFormService";
import Joi from "joi-browser";
import ApplyLoanModel from "./applyLoanModel";
import bsCustomFileInput from "bs-custom-file-input";
import React from "react";
import auth from "../../services/common/authService";
import * as registFormService from "../../services/login/registerFormService";
import moment from "moment";
import momentJalaali from "moment-jalaali";
import { Form, Col, Button, Modal } from "react-bootstrap";
import Autosuggest from "react-autosuggest";


class shopFormModel extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        userId: "",
        loanId: "",
        users: [{ shopName: "", shopType: "", shopOwnershipType: "", shopSector:"", natureOfBussiness:"", add: "", city: "", state: "", pincode: "", partner: "", merchantCode: "", trxnId: "", productId: "", shopLocation: "", shopVintage: "", companyType: "", businessVintage: "", contactNo: "", insertedOn: "" }],
        errors: [{ shopName: "", shopType: "", shopOwnershipType: "", shopSector:"", natureOfBussiness:"", add: "", city: "", state: "", pincode: "", isValidState: false, isValidCity: false }],
        options: [{ stateOptions: [], cityOptions: [] }],
        showSpinner: false,
        isSubmitted: false,
        globalError: "",
        kycStatus: "",
        isRetailer: true,
        type: "",
        currentUserId: "",
        currentUserRole: "",
        showSuccessModal: false,
        infoHeader: "",
        infoMessage: "",
        gstDetails: {
            gstNo: "",
            gstPrivateData: false,
            gstUserName: "",
            confirmEnableApi: false,
            otpSendToMobile: false,
            otp: "",
            otpVerifiedSuccessfulMsg: "",
        },
        showPrivateGstModal: false,
        gstModleErrors: {},
        data: {
            udyamOrUdyogNo: "",
            udyamUdyogVerification: "NotStarted",
            verificationType: "",
        },
        allErrors: {},
        info: {},
        shopSector:[
            { "label": "Chemicals", "value": "Chemicals" },
            { "label": "Consumer durables/White goods and electronics", "value": "Consumer durables/White goods and electronics" },
            { "label": "Mobile phones", "value": "Mobile phones" },
            { "label": "FMCG including frozen food", "value": "FMCG including frozen food" },
            { "label": "Pharmacy (not ayurvedic) and medical/surgical equipment", "value": "Pharmacy (not ayurvedic) and medical/surgical equipment" },
            { "label": "IT hardware and peripherals incl. automation devices", "value": "IT hardware and peripherals incl. automation devices" },
            { "label": "Industrial hardware/machinery tools", "value": "Industrial hardware/machinery tools" },
            { "label": "E-commerce", "value": "E-commerce" },
            { "label": "Plastic", "value": "Plastic" },
            { "label": "Electrical items", "value": "Electrical items" },
            { "label": "Ceramic, Glass and Hardware (including paints)", "value": "Ceramic, Glass and Hardware (including paints)" },
            { "label": "Cosmetics and personal healthcare items, home gift items including utensils and toys and sport products", "value": "Cosmetics and personal healthcare items, home gift items including utensils and toys and sport products" },
            { "label": "Textile and apparels (including job work)", "value": "Textile and apparels (including job work)" },
            { "label": "Stationery items (Not more than 50% supply to schools)", "value": "Stationery items (Not more than 50% supply to schools)" },
            { "label": "Footwears", "value": "Footwears" },
            { "label": "Home furnishings, furniture and décor", "value": "Home furnishings, furniture and décor" },
            { "label": "Automobile and auto components", "value": "Automobile and auto components" },
            { "label": "Paper and printing (except Books, publishing house)", "value": "Paper and printing (except Books, publishing house)" },
            { "label": "Metals manufacturing/trading with stock", "value": "Metals manufacturing/trading with stock" },
            { "label": "Agri commodity/ Dairy business", "value": "Agri commodity/ Dairy business" },
            { "label": "Food and Restaurants (including bakery)", "value": "Food and Restaurants (including bakery)" },
            { "label": "Manpower supply (skilled only)", "value": "Manpower supply (skilled only)" },
            { "label": "IT software and solutions", "value": "IT software and solutions" },
            { "label": "Logistics/Transportation with more than 3 owned vehicles", "value": "Logistics/Transportation with more than 3 owned vehicles" },
            { "label": "Oil and Natural gas (including Petrol pumps)", "value": "Oil and Natural gas (including Petrol pumps)" },
            { "label": "Advertising and digital marketing", "value": "Advertising and digital marketing" },
            { "label": "Packaging", "value": "Packaging" },
            { "label": "Hospitals & Diagnostic centers", "value": "Hospitals & Diagnostic centers" },
            { "label": "Consultancy and service providers (non-contractors)", "value": "Consultancy and service providers (non-contractors)" },
            { "label": "Civil and construction contractors (both government and private)", "value": "Civil and construction contractors (both government and private)" },
            { "label": "Security agencies", "value": "Security agencies" },
            { "label": "Educational/Training institutes (professional courses except schools)", "value": "Educational/Training institutes (professional courses except schools)" },
            { "label": "Telecom", "value": "Telecom" },
            { "label": "Travel related/ ticketing/ travel agents etc.", "value": "Travel related/ ticketing/ travel agents etc." },
            { "label": "Hotels (except restaurants)", "value": "Hotels (except restaurants)" },
            { "label": "Real estate business or services to any real estate business", "value": "Real estate business or services to any real estate business" },
            { "label": "Commission/ MLM business", "value": "Commission/ MLM business" },
            { "label": "Chit fund/private finance business", "value": "Chit fund/private finance business" },
            { "label": "Massage parlors", "value": "Massage parlors" },
            { "label": "Cable TV business/operators", "value": "Cable TV business/operators" },
            { "label": "Courier companies", "value": "Courier companies" },
            { "label": "Film industry", "value": "Film industry" },
            { "label": "Collection agencies", "value": "Collection agencies" },
            { "label": "DSA", "value": "DSA" },
            { "label": "Jewellery business (both real and imitation)", "value": "Jewellery business (both real and imitation)" },
            { "label": "Metal traders- without stock", "value": "Metal traders- without stock" },
            { "label": "Scrap dealers/traders", "value": "Scrap dealers/traders" },
            { "label": "Building material suppliers", "value": "Building material suppliers" },
            { "label": "Petrol Pumps", "value": "Petrol Pumps" },
            { "label": "Others", "value": "Others" }
        ]
        
    };
    handleOpenPrivateGstModal = () => {
        this.setState({ showPrivateGstModal: true })
    }
    handleClosePrivateGstModal = () => {
        this.setState({ showPrivateGstModal: false })
    }
    handleEnableApi = () => {
        let gstDetails = { ...this.state.gstDetails };
        let gstModleErrors = { ...this.state.gstModleErrors };
        gstDetails = { ...gstDetails, confirmEnableApi: !gstDetails.confirmEnableApi }
        if (gstDetails.gstUserName === "") {
            gstModleErrors["gstUserName"] = "GST User Name Can't be Empty."
        }
        else {
            delete gstModleErrors["gstUserName"];
        }
        this.setState({ gstDetails, gstModleErrors });
    }

    handleRequestOTP = async () => {
        let gstDetails = { ...this.state.gstDetails };
        const response = await shopFormService.sendOtpForPrivateData(gstDetails.gstNo, gstDetails.gstUserName)
        if (response.status === 200) {
            gstDetails = { ...gstDetails, otpSendToMobile: true }
            let gstModleErrors = { ...this.state.gstModleErrors };
            delete gstModleErrors["otp"];
            this.setState({ gstModleErrors, gstDetails })
        }
        else {
            let gstModleErrors = { ...this.state.gstModleErrors };
            gstModleErrors["otp"] = response.message ? response.message : "Error Occoured.";
            console.log(response.message)
            this.setState({ gstModleErrors })
        }
    }

    handleOtpSubmit = async () => {
        let gstDetails = { ...this.state.gstDetails }
        const response = await shopFormService.validateOtp(gstDetails.gstNo, gstDetails.gstUserName, gstDetails.otp)
        if (response.status === 200) {
            let gstModleErrors = { ...this.state.gstModleErrors };
            delete gstModleErrors["otpVerifiedSuccessfulMsg"];
            gstDetails = { ...gstDetails, otpVerifiedSuccessfulMsg: response.message ? response.message : "Otp verified and GST private data pulled Successfully", gstPrivateData: true };
            this.setState({ gstModleErrors, gstDetails })
        }
        else {
            let gstModleErrors = { ...this.state.gstModleErrors };
            gstModleErrors["otpVerifiedSuccessfulMsg"] = response.message;
            this.setState({ gstModleErrors })
        }
    }
    addClick = () => {

        this.setState(prevState => ({
            users: [...prevState.users, { shopName: "", shopType: "", shopOwnershipType: "",shopSector:"", natureOfBussiness:"", add: "", state: "", city: "", pincode: "", partner: "", merchantCode: "", trxnId: "", productId: "", shopLocation: "", shopVintage: "", companyType: "", businessVintage: "", contactNo: "", insertedOn: "" }],
            options: [...prevState.options, { stateOptions: [], cityOptions: [] }],
            errors: [...prevState.errors, { shopName: "", shopType: "", shopOwnershipType: "",shopSector:"", natureOfBussiness:"", add: "", city: "", state: "", pincode: "", isValidState: false, isValidCity: false }]
        }))
    }
    handleGstDetails = (e) => {
        const { name, value } = e.target;
        if (name === "otp") {
            if (/^\d{0,6}$/.test(value)) {
                let gstDetails = { ...this.state.gstDetails };
                gstDetails = { ...gstDetails, [name]: value };
                this.setState({ gstDetails });
            }
        }
        else {
            let gstModleErrors = { ...this.state.gstModleErrors }
            let gstDetails = { ...this.state.gstDetails };
            gstDetails = { ...gstDetails, [name]: value };
            if (name === "gstUserName") {
                delete gstModleErrors["gstUserName"];
            }
            this.setState({ gstDetails, gstModleErrors });
        }
    }
    handleNumberChange = (e) => {
        let { allErrors } = { ...this.state };
        delete allErrors["udyamOrUdyogNo"];
        const { name, value } = e.target;
        let data = { ...this.state.data };
        data = { ...data, [name]: value , udyamUdyogVerification :"NotStarted" } 
        this.setState({ data , allErrors});
    }
    handleUdyamUdyogVerification = async () => {
        let { data, info, allErrors } = { ...this.state }
        if (/^UDYAM-[A-Z]{2}-\d{2}-\d{7}$/.test(this.state.data.udyamOrUdyogNo)) {
            data = { ...data, verificationType: "udyam" }
            delete info["udyamOrUdyogNo"];
            delete allErrors["udyamOrUdyogNo"];
            this.setState({ allErrors, data , info });
        }
        else if (/^[A-Z]{2}\d{2}[A-Z0-9]\d{7}$/.test(this.state.data.udyamOrUdyogNo)) {
            data = { ...data, verificationType: "udyog" };
            delete info["udyamOrUdyogNo"];
            delete allErrors["udyamOrUdyogNo"];
            this.setState({ allErrors, data , info });
        }
        else {
            allErrors["udyamOrUdyogNo"] = "Not a valid Udyam/Udyog Registration Number";
            delete info["udyamOrUdyogNo"];
            this.setState({ allErrors, info });
            return
        }
        try {
            const { data: response } = this.state.data.verificationType === "udyam" ? await shopFormService.udyamVerification(
                this.state.type === "admin" ? this.state.userId : this.state.currentUserId,
                this.state.data.udyamOrUdyogNo
            ) : await shopFormService.udyogVerification(
                this.state.type === "admin" ? this.state.userId : this.state.currentUserId,
                this.state.data.udyamOrUdyogNo
            );
            if (response?.data) {
                delete allErrors["udyamOrUdyogNo"];
                info["udyamOrUdyogNo"] = response?.message;
                data = { ...data, udyamUdyogVerification: "Completed" }
                this.setState({ info, data, allErrors });
            }
            else {
                delete allErrors["udyamOrUdyogNo"];
                info["udyamOrUdyogNo"] = response?.message;
                data = { ...data, udyamUdyogVerification: "InProgress" }
                this.setState({ info, data, allErrors });
            }
        } catch (ex) {
            delete info["udyamOrUdyogNo"];
            let allErrors = { ...this.state.allErrors };
            allErrors["udyamOrUdyogNo"] = `Unable to fetch the ${this.state.data.verificationType} details`;
            this.setState({ allErrors, info });
        }

    }
    handleShopSectorChange=(i,e)=>{
        if(e.length===0){
            const name="shopSector";
            let users = [...this.state.users];
            users[i] = { ...users[i], [name]: "" };
            this.setState({ users });
        }
        else{
            const { value } = e?.[0];
            const name="shopSector";
            let users = [...this.state.users];
            users[i] = { ...users[i], [name]: value };
            let errors = [...this.state.errors];
    
            if (value==="")
                errors[i] = { ...errors[i], [name]: `Enter ${[name]}` };
            else
                errors[i] = { ...errors[i], [name]: "" };
            this.setState({ users, errors });
        }

    }

    handleChange = (i, e) => {
        const { name, value } = e.target;
        let users = [...this.state.users];
        users[i] = { ...users[i], [name]: value };

        let errors = [...this.state.errors];

        if (!value)
            errors[i] = { ...errors[i], [name]: `Enter ${[name]}` };
        else
            errors[i] = { ...errors[i], [name]: "" };

       
        this.setState({ users, errors });
    }
    handlePincodeChange = async (i, e) => {
        const { name, value } = e.target;

        if (isNaN(value)) {
            return;
        }

        let users = [...this.state.users];
        let errors = [...this.state.errors];

        users[i] = { ...users[i], [name]: value, state: "", city: "" };

        if (value.length !== 6)
            errors[i] = { ...errors[i], pincode: "Pincode must be 6 characters" };
        else {
            errors[i] = { ...errors[i], pincode: "" };
            const { data: response } = await commonService.getStatesByPincode(value);
            let state = response?.data && response?.data?.length ? response?.data[0] : ""
            users[i].state = state
            errors[i].isValidState = true
        }

        this.setState({ users, errors });

    };
    removeClick = (i) => {
        let users = [...this.state.users];
        users.splice(i, 1);
        this.setState({ users });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        // alert('A name was submitted: ' + JSON.stringify(this.state.users));

        let { userId, users, errors, loanId, isRetailer, type, currentUserId, currentUserRole, data, info, allErrors } = this.state;
        let isError = false;

        for (let i = 0; i < users.length; i++) {

            const shopName = users[i].shopName ? users[i].shopName.trim() : "";
            const shopType = users[i].shopType ? users[i].shopType.trim() : "";
            const shopOwnershipType = users[i].shopOwnershipType ? users[i].shopOwnershipType.trim() : "";
            const add = users[i].add ? users[i].add.trim() : "";
            const city = users[i].city ? users[i].city.trim() : "";
            const state = users[i].state ? users[i].state.trim() : "";
            const pincode = users[i].pincode ? users[i].pincode.trim() : "";

            let validate = true;

            if (i === 0 && !isRetailer && users.length === 1 && !shopName && !shopType && !shopOwnershipType && !add && !state && !city && !pincode)
                validate = false;

            if (validate && !users[i].shopName) {
                errors[i].shopName = "Enter shop name";
                isError = true;
            }
            else {
                errors[i].shopName = "";
            }


            if (validate && !users[i].shopType) { errors[i].shopType = "Enter shop type"; isError = true; }
            else { errors[i].shopType = ""; }
            
            if (validate && !users[i].shopOwnershipType) { errors[i].shopOwnershipType = "Enter shop OwnerShip type"; isError = true; }
            else { errors[i].shopOwnershipType = ""; }
            
            if (validate && !users[i].shopSector) { errors[i].shopSector = "Enter shop sector"; isError = true; }
            else { errors[i].shopSector = ""; }

            if (validate && !users[i].natureOfBussiness) { errors[i].natureOfBussiness = "Enter nature of bussiness"; isError = true; }
            else { errors[i].natureOfBussiness = ""; }

            if (validate && !users[i].add) { errors[i].add = "Enter shop address"; isError = true; }
            else { errors[i].add = ""; }

            if (validate && !users[i].city) { errors[i].city = "Enter city"; isError = true; }
            else { errors[i].city = ""; }

            if (validate && !users[i].state) { errors[i].state = "Enter state"; isError = true; }
            else {
                errors[i].state = "";
            }
            if (validate && !users[i].pincode) { errors[i].pincode = "Enter pincode"; isError = true; }
            else {
                if (validate && users[i].pincode.length !== 6) { errors[i].pincode = "Pincode must be 6 characters"; isError = true; }
                else { errors[i].pincode = ""; }
            }

            if (validate && !errors[i].state && users[i].state) {
                if (validate && !errors[i].isValidState) { errors[i].state = "Invalid State"; isError = true; }
                else { errors[i].state = ""; }
            }
            if (!errors[i].city && users[i].city) {
                if (validate && !errors[i].isValidCity) { errors[i].city = "Invalid City"; isError = true; }
                else { errors[i].city = ""; }
            }
        }

        
        if (!data?.udyamOrUdyogNo) { allErrors.udyamOrUdyogNo = "Enter Udyam/Udyog Number"; isError = true; }
        else if(data.udyamUdyogVerification==="NotStarted"){
           allErrors.udyamOrUdyogNo = ""
           info.udyamOrUdyogNo = "Verify Udyam/Udyog Number"; 
           isError = true;
        }
        else {
             allErrors.udyamOrUdyogNo = ""; 
        }

        this.setState({ errors: errors , allErrors});

        if (isError)
            return;

        this.setState({ isSubmitted: true, showSpinner: true });

        try {
            const { data: response } = await kycFormService.insertUpdateShopDetails(
                userId,
                users,
                loanId,
                currentUserId,
                currentUserRole
            );
            if (response.success) {
                if (type === "admin")
                    this.setState({ showSuccessModal: true, infoHeader: "Success", infoMessage: "Updated Successfully" })
                else
                    this.props.handler("activeTab", "questionairreTab");

                this.setState({ showSpinner: false, isSubmitted: false, globalError: "" });
            } else {
                if (type === "admin")
                    this.setState({ globalError: response.message, showSuccessModal: true, infoHeader: "Error", infoMessage: response.message, showSpinner: false, isSubmitted: false });
                else
                    this.setState({ showSpinner: false, isSubmitted: false, globalError: response.message });

            }
        } catch (ex) {
            this.setState({ showSpinner: false, isSubmitted: false, globalError: ex.response.data.message });
        }


    }

    async componentWillReceiveProps() {
        if (this.props.userData) {
            const currentUser = auth.getCurrentUser();
            if (!currentUser) window.location.href = "/";
            else if (currentUser && currentUser.loggedInFrom) {
                this.setState({ loggedInFrom: currentUser.loggedInFrom });
            }
            let reviewData = {};

            if (this.props.reviewData)
                reviewData = this.props.reviewData;

            await this.mapDataToState(this.props.userData, reviewData, this.props.type);
            if (this.props.userData?.user?.gstDetails[0]?.gstNo && this.state.currentUserRole==="User") {
                const { data: response } = await shopFormService.fetchGstDetails(
                    currentUser.userId,
                    this.props.userData?.user?.gstDetails[0]?.gstNo
                );
                if (response?.data) {
                    let users = [...this.state.users];
                    let shopData = response.data.business
                    users[0] = { ...users[0], ["shopType"]: response.data.gstrbusiness.kind, ["shopName"]: shopData.name, ["add"]: shopData.address.line1 + " , " + shopData.address.line2, ['pincode']: shopData.address.postal_code, ["state"]: shopData.address.state.toUpperCase(), ["city"]: shopData.address.city.toUpperCase() };
                    let gstDetails = { ...this.state.gstDetails };
                    gstDetails = { ...gstDetails, gstNo: response.data.gstNo, gstPrivateData: response.data.gstPrivateData }
                    this.setState({ users, gstDetails })
                }
            }

            if (this.props.type !== "admin")
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
        }
    }
    mapDataToState =  async (userData, reviewData, type) => {
        let data = { ...this.state.data };
        let { user } = userData;
        let { shopDetails, userDetails } = user;

        let userId = "";

        let loanId = "";
        if (user?.udyamAadhaar?.requestId && user?.udyamAadhaar?.aadhaarNo) {
            if (user?.udyamAadhaar?.officialAddress) {
                data = { ...data, udyamUdyogVerification: "Completed", udyamOrUdyogNo: user?.udyamAadhaar?.aadhaarNo, verificationType: "udyam" }
            }
            else {
                data = { ...data, udyamUdyogVerification: "InProgress", udyamOrUdyogNo: user?.udyamAadhaar?.aadhaarNo, verificationType: "udyam" }
            }
        }

        else if (user?.udyogAadhaar?.requestId && user?.udyogAadhaar?.aadhaarNo) {
            if (user?.udyogAadhaar?.addressDetails) {
                data = { ...data, udyamUdyogVerification: "Completed", udyamOrUdyogNo: user?.udyogAadhaar?.aadhaarNo, verificationType: "udyog" }
            }
            else {
                data = { ...data, udyamUdyogVerification: "InProgress", udyamOrUdyogNo: user?.udyogAadhaar?.aadhaarNo , verificationType: "udyog"}
            }
        }

        //User Details
        if (user._id) userId = user._id;

        if (reviewData && reviewData.loanId) loanId = reviewData.loanId;

        let users = [];
        let options = [];
        let errors = [];

        const kycStatus = userDetails.kycStatus ? userDetails.kycStatus : "";

        if (shopDetails.length < 1) {
            users.push({ shopName: "", shopType: "", shopOwnershipType: "", shopSector:"", natureOfBussiness:"", add: "", city: "", state: "", pincode: "", partner: "", merchantCode: "", trxnId: "", productId: "", shopLocation: "", shopVintage: "", companyType: "", businessVintage: "", contactNo: "", insertedOn: "" });
            let optionsItem = { stateOptions: [], cityOptions: [] }
            let errorItem = { shopName: "", shopType: "", shopOwnershipType: "", shopSector:"", natureOfBussiness:"", add: "", state: "", city: "", pincode: "", isValidCity: false, isValidState: false }

            options.push(optionsItem);
            errors.push(errorItem);


        }

        else {
            for (const item of shopDetails) {
                let shop = { shopName: item.shopName ? item.shopName : "", shopType: item.shopType ? item.shopType : "", shopOwnershipType: item.shopOwnershipType ? item.shopOwnershipType : "", shopSector: item?.shopSector? item?.shopSector:"", natureOfBussiness: item?.natureOfBussiness ? item?.natureOfBussiness : "", add: item.shopAddress ? item.shopAddress : "", city: item.city ? item.city : "", state: item.state ? item.state : "", pincode: item.pincode ? item.pincode : "", partner: item.partner ? item.partner : "", merchantCode: item.merchantCode ? item.merchantCode : "", trxnId: item.trxnId ? item.trxnId : "", productId: item.productId ? item.productId : "", shopLocation: item.shopLocation ? item.shopLocation : "", shopVintage: item.shopVintage ? item.shopVintage : "", companyType: item.companyType ? item.companyType : "", businessVintage: item.businessVintage ? item.businessVintage : "", contactNo: item.contactNo ? item.contactNo : "", insertedOn: item.insertedOn ? item.insertedOn : "" };
                let optionsItem = { stateOptions: [], cityOptions: [] }
                let errorItem = { shopName: "", shopType: "", shopOwnershipType: "", add: "", state: "", city: "", pincode: "", isValidCity: true, isValidState: true }

                users.push(shop);
                options.push(optionsItem);
                errors.push(errorItem);

            }
        }

        const currentUser = auth.getCurrentUser();

        this.setState({
            userId: userId,
            loanId: loanId,
            users: users,
            options: options,
            errors: errors,
            kycStatus: kycStatus,
            isRetailer: user.isRetailer ? true : false,
            type: type,
            currentUserId: currentUser.userId ? currentUser.userId : "",
            currentUserRole: currentUser.role ? currentUser.role : "",
            data
        });
    }
    onStateSuggestionsRequested = async (i, e) => {
        let state = e.value.trim();
        let pincode = this.state.users[i].pincode

        if (!pincode || pincode.length != 6) {
            let errors = this.state.errors
            errors[i].state = "Please enter valid pincode before state"
            this.setState({ errors })
            return
        }

        try {
            const { data: response } = await commonService.getStates(state, pincode);

            let users = [...this.state.users];
            let options = [...this.state.options];

            users[i] = { ...users[i], city: "" };

            options[i] = { ...options[i], stateOptions: response.data };

            this.setState({
                users: users,
                options: options
            });
        } catch (ex) { }
    };
    onCitySuggestionsRequested = async (i, e) => {
        let city = e.value.trim();

        let users = [...this.state.users];
        let errors = [...this.state.errors];
        let options = [...this.state.options];

        if (!errors[i].isValidState) {
            errors[i] = { ...errors[i], city: "Please select valid state before city" };
            this.setState({ errors });
            return;
        } else {
            // errors[i] = { ...errors[i], city: "" };
            // this.setState({ errors });
        }

        try {
            const { data: response } = await commonService.getCities(
                users[i].state,
                city,
                // users[i].pincode,
            );

            options[i] = { ...options[i], cityOptions: response.data };

            this.setState({
                options
            });
        } catch (ex) { }
    };
    onStateChange = async (i, e, { newValue }) => {

        let users = [...this.state.users];
        let errors = [...this.state.errors];
        let value = newValue.trim().toUpperCase();

        // if (e.target.innerText) {
        //     value = e.target.innerText.trim().toUpperCase();
        //     users[i] = { ...users[i], state: value, city: "" };
        //     this.setState({ users, showSpinner: true });
        // }
        // else {
        //     value = e.target.value.trim().toUpperCase();
        //     users[i] = { ...users[i], state: value, city: "" };
        //     this.setState({ users, showSpinner: true });

        // }

        users[i] = { ...users[i], state: value, city: "" };
        this.setState({ users, showSpinner: true });

        let pincode = this.state.users[i].pincode

        if (!pincode || pincode.length != 6) {
            errors[i].state = "Please enter valid pincode before state"
            this.setState({ errors, showSpinner: false })
            return
        }


        try {
            const { data: response } = await commonService.isValidState(value, pincode);

            if (response.success) {
                errors[i] = { ...errors[i], isValidState: response.success, state: "" };
                this.setState({
                    errors: errors,
                    showSpinner: false,
                });
            } else {
                errors[i] = { ...errors[i], isValidState: response.success, isValidCity: false, state: response.message };
                this.setState({
                    errors: errors,
                    showSpinner: false
                });
            }
        } catch (ex) {
            errors[i] = { ...errors[i], isValidState: false, isValidCity: false };
            this.setState({
                errors: errors,
                showSpinner: false,
            });
        }
    };
    onCityChange = async (i, e, { newValue }) => {

        let users = [...this.state.users];
        let errors = [...this.state.errors];
        let value = newValue.trim().toUpperCase();

        if (!errors[i].isValidState) {
            errors[i] = { ...errors[i], city: "Please enter valid state" };
            this.setState({ errors });
            return;
        } else {
            errors[i] = { ...errors[i], city: "" };
            this.setState({ errors });
        }

        // if (e.target.innerText) {
        //     value = e.target.innerText.trim().toUpperCase();
        //     users[i] = { ...users[i], city: value };
        //     this.setState({ users });
        // }
        // else {
        //     value = e.target.value.trim().toUpperCase();
        //     users[i] = { ...users[i], city: value };
        //     this.setState({ users });

        // }

        users[i] = { ...users[i], city: value };
        this.setState({ users, showSpinner: true });

        try {
            const { data: response } = await commonService.isValidCity(
                users[i].state,
                value
            );

            if (response.success) {
                errors[i] = { ...errors[i], city: "", isValidCity: response.success };
                this.setState({
                    errors,
                    showSpinner: false,
                });
            } else {
                errors[i] = { ...errors[i], city: "City does not belong to state", isValidCity: response.success };
                this.setState({
                    errors,
                    showSpinner: false,
                });
            }
        } catch (ex) {
            errors[i] = { ...errors[i], city: "City does not belong to state", isValidCity: false };
            this.setState({
                errors,
                showSpinner: false,
            });
        }
    };
    onStateSuggestionsClearRequested = (i, e) => {
        let options = [...this.state.options];

        options[i] = { ...options[i], stateOptions: [] };

        this.setState({ options });
    };
    onCitySuggestionsClearRequested = (i, e) => {
        let options = [...this.state.options];

        options[i] = { ...options[i], cityOptions: [] };

        this.setState({ options });

    };
    getSuggestionValue = (suggestion) => suggestion;
    renderSuggestion = (suggestion) => suggestion;
    closeSuccessModal = () => {
        this.setState({ showSuccessModal: false });
    };

}


export default shopFormModel;
