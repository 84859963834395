import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ReconciliationModel from "../../models/reconciliation-wrapper/reconciliation-wrapper";
import { Tabs, Tab } from "react-bootstrap";
import ReconciliationTransactions from '../reconciliation/reconciliation';
import MisLogList from '../reconciliation/mislogList';
import SettlementAlert from '../reconciliation/SettlementAlert';
// import MisDailyReport from '../reconciliation/misDailyReport'
import SettlementPending from '../reconciliation/settlementPending';
import SettlementMISUTR from '../reconciliation/settlementMISUTR'

class ReconTable extends ReconciliationModel {

  renderTabs = () => {
    let { headerTabs, headerActiveTab } = this.state;

    let elements = []

    for (let i = 0; i < headerTabs.length; i++) {
      elements.push(
        <Tab eventKey={headerTabs[i].title} title={headerTabs[i].title}></Tab>
      )
    }

    return (<Tabs activeKey={headerActiveTab} id="uncontrolled-tab-example" onSelect={this.onChangeHeaderActiveTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  renderComponents = () => {
    let activeTab = this.state.headerActiveTab;
    if (activeTab == "Settlement Transactions")
      return <ReconciliationTransactions></ReconciliationTransactions>

    if (activeTab == "Settlement MIS")
      return <MisLogList></MisLogList>

    if (activeTab == "Settlement Alert")
      return <SettlementAlert></SettlementAlert>

    // if (activeTab == "Settlement Report")
    //   return <MisDailyReport></MisDailyReport>

    if (activeTab == "Settlement Pending")
      return <SettlementPending></SettlementPending>

    if (activeTab == "Settlement MIS UTR")
      return <SettlementMISUTR></SettlementMISUTR>
  }

  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <h2 className="mb-3">
          Settlement Reconciliation
        </h2>

        {this.renderTabs()}
        {this.renderComponents()}
      </React.Fragment>
    );
  }
}

export default ReconTable;
