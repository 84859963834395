import http from "../common/httpService";
import { apiUrl, creditApiUrl } from "../../config.json";
import moment from "moment";

const apiPartnerEndpoint = creditApiUrl + "partner";
const apiAdminEndpoint = apiUrl + "/ops/admin";

export async function uploadExcel(model) {
    const data = await http.post(`${apiPartnerEndpoint}/uploadExcel`, model)
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function uploadEmiCollectionExcel(model) {
    const data = await http.post(`${apiAdminEndpoint}/uploadExcel`, model)
        .then(response => { return response.data; })
        .catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}