import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import LenderPayoutListModal from "../../models/lenderPayoutListModal-v2/lenderPayoutListModal-v2";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Tabs, Tab } from "react-bootstrap";
import Pagination from "../../components/common/paginationV2";
import PayoutDetails from '../payoutDetails/payoutDetails'

class LenderPayoutListV2 extends LenderPayoutListModal {
    renderTabs = () => {
        let { tabs, activeTab } = this.state;
        let elements = []
        for (let i = 0; i < tabs.length; i++) {
            elements.push(
                <Tab eventKey={tabs[i].value} title={tabs[i].title}></Tab>
            )
        }
        return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
            {elements}
        </Tabs>
        )
    }

    render() {
        const {
            data,
            showSpinner,
            lendingPartners,
            successMsg,
            errors,
            activeTab,
            payoutList,
            borrowingPartners,
            pageSize,
            currentPage,
            selectedHash,
            statusMapper
        } = this.state;

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>
                    Lender Payout
                </h2>

                <div className="mt-3">
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Row>

                            {activeTab == "ORDER_SPLIT_PENDING" ||
                                activeTab == "ORDER_SPLIT_PROCESSING" ||
                                activeTab == "ORDER_SPLIT_COMPLETED" ||
                                activeTab == "ORDER_SPLIT_FAILED"
                                ? <Form.Group as={Col} md="3" controlId="borrowingPartnerId">
                                    <Form.Label>Borrowing Partner</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="borrowingPartnerId"
                                        value={data["borrowingPartnerId"]}
                                        onChange={this.handlePartnerChange}
                                    >
                                        {" "}
                                        <option key="all" value="">
                                            -- Select --
                                        </option>
                                        {borrowingPartners && borrowingPartners.map((borrowingPartner) => (
                                            <option key={borrowingPartner.partnerName} value={borrowingPartner._id}>
                                                {borrowingPartner.displayName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group> : ""}

                            {activeTab == "DISBURSAL_PROCESSING" ||
                                activeTab == "DISBURSAL_COMPLETED" ||
                                activeTab == "DISBURSAL_FAILED"
                                ? <Form.Group as={Col} md="3" controlId="partnerId">
                                    <Form.Label>Lending Partner</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="partnerId"
                                        value={data["partnerId"]}
                                        onChange={this.handlePartnerChange}
                                    >
                                        {" "}
                                        <option key="all" value="">
                                            -- Select --
                                        </option>
                                        {lendingPartners && lendingPartners.map((lendingPartner) => (
                                            <option key={lendingPartner.partnerName} value={lendingPartner._id}>
                                                {lendingPartner.displayName}
                                            </option>
                                        ))}
                                    </Form.Control>

                                    {this.state.lenderSettlementInformation ?
                                        <small
                                            title={`Principal Amount : ${this.state.lenderSettlementInformation.totalPrincipalAmount}\nInterest Amount : ${this.state.lenderSettlementInformation.totalInterestAmount}\nFee Amount : ${this.state.lenderSettlementInformation.totalFeeAmount}\nMoratoriumInterest : ${this.state.lenderSettlementInformation.totalMoratoriumInterest}`}
                                        ><b>Amount to be Settle : </b>₹{this.state.lenderSettlementInformation.totalAmount}</small> : ""}
                                </Form.Group> : ""}

                            <Form.Group as={Col} md="3" controlId="fromDate">
                                <Form.Label>From Date</Form.Label>
                                <DatePicker
                                    name="fromDate"
                                    id="fromDate"
                                    value={data["fromDate"]}
                                    max={moment()}
                                    inputFormat="DD/MM/YYYY"
                                    isGregorian={true}
                                    className="form-control"
                                    inputReadOnly={true}
                                    showTodayButton={false}
                                    timePicker={false}
                                    onChange={(value) => this.handleFromDateChange(value)}
                                    placeholder="Enter From Date"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="toDate">
                                <Form.Label>To Date</Form.Label>
                                <DatePicker
                                    name="toDate"
                                    id="toDate"
                                    value={data["toDate"]}
                                    max={moment()}
                                    inputFormat="DD/MM/YYYY"
                                    isGregorian={true}
                                    className="form-control"
                                    inputReadOnly={true}
                                    showTodayButton={false}
                                    timePicker={false}
                                    onChange={(value) => this.handleToDateChange(value)}
                                    placeholder="Enter To Date"
                                />
                            </Form.Group>

                            <Form.Group as={Col} md="3" className="pt-2">
                                <Button
                                    className="ff-button mt-4 mr-2"
                                    type="submit"
                                >
                                    Go
                                </Button>

                                {this.state.lenderSettlementInformation ? <Button
                                    onClick={this.initiatePayoutDisbursal}
                                    className="ff-button mr-2 mt-4"
                                >
                                    Settle
                                </Button> : <></>}

                                {Object.keys(this.state.selectedHash).length && activeTab == "ORDER_SPLIT_PENDING"  ? <Button
                                    className="ff-button mt-4 mr-2"
                                    onClick={this.onClickInitiate}
                                >
                                    Initiate
                                </Button> : <></>}

                                {Object.keys(this.state.selectedHash).length && activeTab == "ORDER_SPLIT_FAILED" ? <Button
                                    className="ff-button mt-4 mr-2"
                                    onClick={this.payoutReDirsburse}
                                >
                                    Re Initiate
                                </Button> : <></>}

                            </Form.Group>
                        </Form.Row>
                    </Form>

                    <br />
                    {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
                    {successMsg && <div className="alert alert-success">{successMsg}</div>}

                    {this.renderTabs()}

                    {activeTab == "ORDER_SPLIT_PENDING" ||
                        activeTab == "ORDER_SPLIT_PROCESSING" ||
                        activeTab == "ORDER_SPLIT_COMPLETED" ||
                        activeTab == "ORDER_SPLIT_FAILED" ? <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
                            <Row>
                                {(activeTab == "ORDER_SPLIT_PENDING" || activeTab == "ORDER_SPLIT_FAILED") && <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 10 }}>
                                    <input type={"checkbox"} onChange={() => this.selectAll(!this.state.isSelectedAll)} checked={this.state.isSelectedAll}></input>
                                </Cell>}
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215, borderLeft: (activeTab != "ORDER_SPLIT_PENDING" && activeTab != "ORDER_SPLIT_FAILED") ? "1px solid" : "" }}><b>Borrowing partner</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>UTR</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Status</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Created On</b></Cell>
                                {/* {activeTab == "ORDER_SPLIT_PENDING" && <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 100 }}><b>Payout</b></Cell>} */}
                            </Row>
                            {payoutList &&
                                payoutList.map((transaction, i) => (
                                    <Row key={transaction._id} onClick={() => { this.showPayoutDetails({ utrNo: transaction.utrNo }) }}>
                                        {(activeTab == "ORDER_SPLIT_PENDING" || activeTab == "ORDER_SPLIT_FAILED") && <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>
                                            <input type={"checkbox"} onClick={(e) => { e.stopPropagation(); this.onChangeCheckBox(selectedHash[transaction._id] ? false : true, transaction) }} checked={selectedHash[transaction._id]} name={transaction._id + "checkbox"}></input>
                                        </Cell>}
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: (activeTab != "ORDER_SPLIT_PENDING" && activeTab != "ORDER_SPLIT_FAILED") ? "1px solid" : "" }}>
                                            {transaction.partnerName}
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.utrNo}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.amount || transaction.amount == 0 ? "₹" : ""}{transaction.amount}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.status && statusMapper[transaction.status] ? statusMapper[transaction.status] : transaction.status}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{new Date(transaction.insertedOn).toLocaleString()}</Cell>
                                        {/* {activeTab == "ORDER_SPLIT_PENDING" && <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <Button
                                                onClick={() => { this.payoutInit(transaction) }}
                                                className="ff-button btn-sm">
                                                Initiate
                                            </Button>
                                        </Cell>} */}
                                    </Row>
                                ))}
                            {!payoutList.length && (
                                <td colSpan={6} className='text-center pt-2 pb-2 border'>No Records Found</td>
                            )}

                        </StickyTable>
                    </div> : <></>}

                    {activeTab == "DISBURSAL_PROCESSING" ||
                        activeTab == "DISBURSAL_COMPLETED" ||
                        activeTab == "DISBURSAL_FAILED" ? <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
                            <Row>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Lending partner</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Settlement UTR</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Status</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center", minWidth: 215 }}><b>Created On</b></Cell>
                            </Row>
                            {payoutList &&
                                payoutList.map((transaction, i) => (
                                    <Row key={transaction._id} onClick={() => activeTab == "DISBURSAL_COMPLETED" && this.showPayoutDetails({ disbursalTransactionId: transaction.id })}>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>
                                            {transaction.partnerName}
                                        </Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.amount || transaction.amount == 0 ? "₹" : ""}{transaction.amount}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.utrNo}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{transaction.status && statusMapper[transaction.status] ? statusMapper[transaction.status] : transaction.status}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{new Date(transaction.insertedOn).toLocaleString()}</Cell>
                                    </Row>
                                ))}
                            {!payoutList.length && (
                                <td colSpan={5} className='text-center pt-2 pb-2 border'>No Records Found</td>
                            )}

                        </StickyTable>
                    </div> : <></>}
                </div>

                <div style={{ width: "100%", overflowX: "scroll" }}>
                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPagePrevious={this.onPagePrevious}
                        onPageNext={this.onPageNext}
                    />
                </div>

                {this.state.showPayoutDetails && <PayoutDetails close={this.hidePayoutDetails} data={this.state.selectedObj}></PayoutDetails>}
            </React.Fragment>
        );
    }
}

export default LenderPayoutListV2;
