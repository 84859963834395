import InputDataForm from "../../components/common/form";
import * as PaymentLinkService from "../../services/paymentLinks/paymentLinksServices"
import Joi from "joi-browser";
import { toast } from "react-toastify";

class eNachListModal extends InputDataForm {
  state = {
    data: {
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    paymentList: [],
    statusList: [
      {
        title: "INITIATED"
      },
      {
        title: "PAID"
      },
      {
        title: "FAILED"
      },
      {
        title: "CANCELLED"
      }
    ]
  };
  schema = {
    page: Joi.any(),
    statusList: Joi.any(),
    status: Joi.any(),
    loanNo: Joi.any(),
    loanId: Joi.any()
  };
  async componentDidMount() {
    if (this.props.meta && this.props.meta.loanNo) {
      let data = this.state.data;
      data.loanNo = this.props.meta.loanNo
      data.loanId = this.props.meta.loanId
      this.setState({ data })
    }
    await this.getENachList()
    if (this.props.refresh)
      this.props.refresh(this.refresh)
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getENachList();
  };
  resetPage = () => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getENachList()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getENachList()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getENachList()
    })
  }

  resendPaymentLink = async (item) => {
    try {
      this.setState({ showSpinner: true });

      let payload = {
        referenceNo: item.referenceNo
      }
      const response = await PaymentLinkService.resendPaymentLink(payload);
      this.setState({ showSpinner: false }, () => {
        toast.success("Payment Link Successfully resent");
      });
    }
    catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  cancelLink = async (item) => {
    try {
      this.setState({ showSpinner: true });

      let payload = {
        referenceNo: item.referenceNo
      }
      const response = await PaymentLinkService.cancelPaymentLink(payload);
      this.setState({ showSpinner: false }, () => {
        this.getENachList()
      });
    }
    catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  onChangeTab = (tab) => {
    this.setState({ activeTab: tab }, () => {
      this.resetPage()
      this.getENachList()
    })
  }

  refresh = () => {
    this.resetPage();
    this.getENachList();
  }

  async getENachList() {

    this.state.data.page = this.state.currentPage

    try {
      this.setState({ showSpinner: true });
      const { data: paymentList, totalPages } = await PaymentLinkService.getEnachList(this.state.data);
      this.setState({ eNachList: paymentList, pageSize: totalPages, showSpinner: false });
    }
    catch (ex) {
      this.setState({ eNachList: [], showSpinner: false });
    }
  };
}

export default eNachListModal;
