import React, { Component } from "react";
import { Form, } from "react-bootstrap";
import { maximumFileSize } from "../../config.json"

class FileSizeText extends Component {
    state = {
        text: `File size should be less than ${maximumFileSize || 2} MB`
    }

    constructor(props) {
        super(props)
    }

    render() {
        return (<Form.Text className="text-muted">{this.state.text}</Form.Text>)
    }
}


export default FileSizeText;