import http from "../common/httpService";
import { apiUrl } from "../../config.json";


const userEndpoint = apiUrl + "/ops/user";

export async function getBackupOfManualPaymentUpdate(body) {
  const { data } = await http.post(
    `${userEndpoint}/getExistingManualPaymentUpdateRecords`,
    body
  );
  return data;
}

export async function updateUpiPayment(body) {
  const { data } = await http.post(
    `${userEndpoint}/manualUpdateUpiPaymentRecords`,
    body
  );
  return data;
}

export async function getPendingLoanInstallmentsRecords(body) {
  const { data } = await http.post(
    `${userEndpoint}/getPendingLoanInstallmentsRecords`,
    body
  );
  return data;
}

export async function generatePayoutForUpdatedPayments(body) {
  const { data } = await http.post(
    `${userEndpoint}/v2/getPayoutForUpdatedPayment`,
    body
  );
  return data;
}