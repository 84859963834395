import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getUserList(model) {
  const { data } = await http.post(`${apiEndpoint}/getUsersList`, {
    partnerType: model.partnerType,
    mobile: model.mobileNo,
    name: model.name,
    email: model.email,
    balance: model.balance,
    merchantCode: model.merchantCode,
    partner: model.partner,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy")
  });
  return data;
}
