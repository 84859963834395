import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import SettlementPendingModel from "../../models/reconciliation/settlementPending";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Col, Button, Tabs, Tab } from "react-bootstrap";
import DatePicker from "react-datepicker2";

class SettlementPending extends SettlementPendingModel {

  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      successMsg,
      errors,
      partners,
      channels,
      productTypes
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />

        <div className="mt-3">

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>

              <Form.Group as={Col} md="2" controlId="fromDate">
                <Form.Label>Partner<span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="partner"
                  value={data["partner"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="all" value="all">
                    --Select--
                  </option>
                  {partners && partners.map((partner) => (
                    <option key={partner.partnerName} value={partner._id}>
                      {partner.displayName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="channel">
                <Form.Label>Channel<span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="channel"
                  value={data["channel"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  {channels && channels.map((channel) => (
                    <option key={channel.title} value={channel.value}>
                      {channel.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>


              <Form.Group as={Col} md="3" controlId="fromDate">
                <Form.Label>From Date <small className="text-success">(Max Date Range 30 days)</small></Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>

            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="2" controlId="product">
                <Form.Label>Product Type<span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="product"
                  value={data["product"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  {productTypes && productTypes.map((product) => (
                    <option key={product.title} value={product.value}>
                      {product.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="1">
                <Form.Label> </Form.Label>
                <br />
                {data && data['partner'] && data['partner'] != "all" ? <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Go
                </Button> : ""}
              </Form.Group>

            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}
          {this.state.totalAmount ? <div>
            <small>
              <strong>Total Pending Amount : </strong> ₹{this.state.totalAmount}
            </small>
          </div> : ""}

          <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Partner</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Transaction Date</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Expected Count</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Expected Amount</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Pending Count</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Pending Amount</b></Cell>
              </Row>
              {data.settlementList && data.settlementList &&
                data.settlementList.map((settlement, i) => (
                  <Row key={settlement._id}>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: `${data.settlementList.length <= i + 1 ? '1px solid' : ""}` }}>{settlement.partnerName || "-"}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${data.settlementList.length <= i + 1 ? '1px solid' : ""}` }}>{settlement.transactionDate ? moment(settlement.transactionDate).format("DD/MM/YYYY") : 0}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${data.settlementList.length <= i + 1 ? '1px solid' : ""}` }}>{settlement.expectedCount || 0}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${data.settlementList.length <= i + 1 ? '1px solid' : ""}` }}>{settlement.expectedAmount != "NA" && "₹"}{settlement.expectedAmount || 0}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${data.settlementList.length <= i + 1 ? '1px solid' : ""}` }}>{settlement.pendingCount || 0}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${data.settlementList.length <= i + 1 ? '1px solid' : ""}` }}>₹{settlement.totalPendingAmount || 0}</Cell>
                  </Row>
                ))}
              {(!data.settlementList || !data.settlementList.length) && (
                <td colSpan={10} className='text-center pt-2 pb-2 border'><small>{'No Records Found'}</small></td>
              )}

            </StickyTable>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SettlementPending;
