import Joi from "joi-browser";
import InputForm from "../../components/common/form";
import * as updateEmailMobileService from "../../services/profile/updateAlternateEmailService";
import * as registFormService from "../../services/login/registerFormService";
import auth from "../../services/common/authService";
import * as analytics from "../../utils/analytics";

class UpdateAlternateMobileModel extends InputForm {
  state = {
    data: {
      userId: "",
      mobileNo: "",
    },
    errors: {},
    info: {},
    showSpinner: false,
    isValidMobile: false,
  };
  schema = {
    userId: Joi.string().required(),
    mobileNo: Joi.number().required().label("Mobile No"),
  };
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    analytics.track();

    await this.getUserData();
  }
  async getUserData() {
    let data = { ...this.state.data };

    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location = "/login");

    data.userId = currentUser.userId;
    this.setState({ data });
  }
  doSubmit = async (e) => {
    e.preventDefault();
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    const info = { ...this.state.info };

    if (data.mobileNo.length !== 10 || isNaN(data.mobileNo)) {
      errors.mobileNo = "Invalid Mobile No";
      this.setState({ errors });
      return;
    } else {
      delete errors["mobileNo"];
      this.setState({ errors });
    }

    this.setState({ showSpinner: true });

    try {
      const { success, message } = await registFormService.checkMobile(
        data.mobileNo
      );

      if (success) {
        delete errors["mobileNo"];
        this.setState({
          errors,
          data,
          isValidMobile: true,
        });

        try {
          const {
            success,
            message,
          } = await updateEmailMobileService.updateEmailMobile(
            data.mobileNo,
            "alternatemobile"
          );

          if (success) window.location = "/myprofile";
          else {
            errors.globalError = message;
            this.setState({ data, errors, showSpinner: false });
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            errors.globalError = ex.response.data;
            this.setState({ data, errors, showSpinner: false });
          }
        }
      } else {
        errors["mobileNo"] = message;
        delete info["mobileNo"];
        this.setState({
          errors,
          data,
          isValidMobile: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors["mobileNo"] = ex.response.data.message;
      delete info["mobileNo"];
      this.setState({ data, errors, showSpinner: false });
    }
  };
}

export default UpdateAlternateMobileModel;
