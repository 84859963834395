import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import SettlementAlertModal from "../../models/reconciliation/SettlementAlert";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker2";
import { displayAmount } from '../../utils/common-utils'

class WebhookList extends SettlementAlertModal {

  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      successMsg,
      errors
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />

        <div className="mt-3">

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>

              <Form.Group as={Col} md="3" controlId="fromDate">
                <Form.Label>From Date <small className="text-success">(Max Date Range 60 days)</small></Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="utrNo">
                <Form.Label>UTR No</Form.Label>
                <Form.Control
                  type="text"
                  name="utrNo"
                  value={data["utrNo"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="vaAccount">
                <Form.Label>Virtual Account</Form.Label>
                <Form.Control
                  type="text"
                  name="vaAccount"
                  value={data["vaAccount"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </Form.Group>

            </Form.Row>

            <Form.Row>

              <Form.Group as={Col} md="3" controlId="name">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={data["amount"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                />
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Form.Label> </Form.Label>
                <br />
                <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Apply Filters
                </Button>
              </Form.Group>

            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}
          {data && data.channel == "EMAIL" && <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>UTR No</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Sender Name</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Credited Date</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Virtual Account</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Sender Account Number</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Sender IFSC Code</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Customer Name</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Customer Code</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Product Code</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Transaction Type</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Created Date</b></Cell>
              </Row>
              {data.settlementList && data.settlementList &&
                data.settlementList.map((settlement, i) => (
                  <Row key={settlement._id}>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", }}>{settlement.utrNo || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.remitterName || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.batchAmt && displayAmount(settlement.batchAmt) || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.creditDate || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.vaNumber || "-"}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.remitterAccountNumber || "-"}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.ifscCode || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.customerName || "-"}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.customerCode || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.productCode || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.transactionType || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{settlement.insertedOn ? moment(settlement.insertedOn).format("DD/MM/YYYY HH:MM:SS") : "-"}</Cell>
                  </Row>
                ))}
              {(!data.settlementList || !data.settlementList.length) && (
                <td colSpan={10} className='text-center pt-2 pb-2 border'>No Records Found</td>
              )}

            </StickyTable>
          </div>}
          <div style={{ width: "100%", overflowX: "scroll" }}>
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.onPageChange}
              onPagePrevious={this.onPagePrevious}
              onPageNext={this.onPageNext}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WebhookList;
