import React from "react";
import { Redirect } from "react-router-dom";
import ExcelFilesModel from "../../models/excel-files/ExcelFilesModel";
import { Form, Col, Button, Table } from "react-bootstrap";
import moment from "moment";

class ExcelFiles extends ExcelFilesModel {

    componentDidMount() {
        this.getExcelList();
    }

    render() {

        return (
            <div>
                <h2>Truescore Exported Files</h2>
                <div className="p-4"></div>
                <Table responsive striped bordered hover>
                    <thead>
                        <tr>
                            <th>Creation Date</th>  
                            <th>Partner Name</th>                          
                            <th style={{ minWidth: 200 }}>File Name</th>     
                            <th>Links</th>                                              
                        </tr>
                    </thead>
                    <tbody>
                        
                        {
                            this.state.partners.map( partner =>
                                partner?.files.map(file => (
                                    <tr key={file}>
                                        <td>{moment(partner.date).format("DD/MM/yyyy")}</td>
                                        <td>{partner.partnerName}</td> 
                                        <td>{file}</td>                                     
                                        <td>
                                            <Button variant="secondary" type="button" 
                                            onClick={() => this.getDownloadUrl(file)}>
                                                Download
                                            </Button>
                                        </td>                          
                                    </tr>
                            )))
                        }

                    </tbody>
                </Table>
            </div>
        )
    }

}

export default ExcelFiles;