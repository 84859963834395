import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import LenderSettlementsModel from "../../models/lenderSettlementsDetails/lenderSettlementsDetailsModel";
import { Form, Col, Button } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";
import { Tabs, Tab, Modal } from "react-bootstrap";


class LenderSettlementDetails extends LenderSettlementsModel {
    getPagedData = () => {
        const { data } = this.state;
        let transactions = data.transactions;
        return { totalCount: transactions.length, data: transactions };
    };
    render() {
        const {
            showSpinner,
            date,
            lendingPartner
        } = this.state;

        const { data: transactions } = this.getPagedData();

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <Modal
                size="lg"
                show={true}
                onHide={this.props.close}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="settlement-model"
            >

                <Modal.Header closeButton>
                    <Modal.Title> <span className="text-danger">Settlement Details</span></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <SpinnerComponent showSpinner={showSpinner} />
                    <div className="row mt-4 mb-3">
                        <div className="col-4">
                            <b>Date : </b> <span>{date ? moment(date).format("DD/MM/YYYY") : "-"}</span>
                        </div>
                        <div className="col-4">
                            <b>Lending Partner : </b> <span>{lendingPartner || "-"}</span>
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0}>
                            <Row>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Borrowing partner</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b># of EDIs to be Settled</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b># of EDIs Collected</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount to be Settled</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount Settled</b></Cell>
                            </Row>
                            {transactions &&
                                transactions.map((transaction, i) => (
                                    <Row key={transaction._id}>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.partnerName}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.totalEdi}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.totalEdiSettled}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.totalAmount || transaction.totalAmount == 0 ? "₹" : ""}{transaction.totalAmount}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.totalEdiSettledAmount || transaction.totalEdiSettledAmount == 0 ? "₹" : ""}{transaction.totalEdiSettledAmount}</Cell>
                                    </Row>
                                ))}
                            {!transactions.length && (
                                <Row>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}>No record found</Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                    <Cell style={{ borderRight: 0, textAlign: 'center' }}></Cell>
                                </Row>
                            )}

                        </StickyTable>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default LenderSettlementDetails;
