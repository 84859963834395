import * as loanFormService from "../../services/borrow/loanFormService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import ApplyLoanModel from "./applyLoanModel";
import auth, { getCurrentPartner } from "../../services/common/authService";
import { Redirect } from "react-router-dom";

class LoanFormModel extends ApplyLoanModel {
  constructor(props) {
    super(props);
  }
  state = {
    data: {
      loanId: "",
      amount: "",
      purpose: "",
      campaignTime: "",
      repaymentFrequency: "",
      loanDescription: ""
    },
    purposeList: [],
    repaymentFrequencyList: [],
    campaignTimeList: [],
    showSpinner: false,
    tnc: true,
    showTnc: false,
    showPrivacyPolicy: false,
    errors: {},
    loggedInFrom: "",
    isSubmitted: false,
    disabled: false,
    showTopNxtBtn: false,
    loanEstimateData: {},
    partnerId: "",
    productType: "",
    LC_Tenure: "",
    isStp: false,
    discountText: "",
    amountDisabled: false,
    offerAmount: 0,
    displayEstimate: false,
    multipleOf: 500,
  };
  schema = {
    loanId: Joi.string().allow("").required(),
    amount: Joi.number().min(10000).required().label("amount"),
    purpose: Joi.string().required().label("purpose"),
    repaymentFrequency: Joi.string().required().label("repayment frequency"),
    campaignTime: Joi.string().required().label("tenure"),
    loanDescription: Joi.string().allow("").required().label("loan description")
  };
  async loadDropdowns() {
    let { purposeList, repaymentFrequencyList, campaignTimeList } = this.state;
    try {
      const {
        data: purposeResponse,
      } = await commonService.getDefinitionDetailsMaster("purpose");

      const {
        data: repaymentFrequencyResponse,
      } = await commonService.getDefinitionDetailsMaster("repaymentfrequency");

      const {
        data: campaignTimeResponse,
      } = await commonService.getDefinitionDetailsMaster("campaign");

      purposeList = purposeResponse.data;
      repaymentFrequencyList = repaymentFrequencyResponse.data;
      campaignTimeList = campaignTimeResponse.data;

      this.setState({
        purposeList,
        repaymentFrequencyList,
        campaignTimeList
      });
    } catch (ex) { }
  }
  async mapDataToState(loanData, estimate, partner, isStp, discountText, offerAmount, multipleOf = 500) {
    let data = { ...this.state.data };
    data.loanId = loanData._id;
    data.purpose = loanData.purpose;
    data.repaymentFrequency = loanData.repaymentFrequency;
    data.loanDescription = loanData.description ? loanData.description : loanData.purpose;
    data.amount = loanData.amount;
    data.campaignTime = loanData.campaignTime;
    if(Number(loanData.amount)>200000 && !this.props.gstRequired){
      this.handleGstRequired(loanData.amount);
    }
    this.setState({
      data,
      tnc: true,
      showTopNxtBtn: loanData.insertedOn ? true : false,
      loanEstimateData: estimate,
      partnerId: partner && partner._id ? partner._id : "",
      productType: estimate.productType ? estimate.productType : "",
      LC_Tenure: estimate.LC_Tenure ? estimate.LC_Tenure : "",
      isStp: isStp ? true : false,
      discountText: discountText ? discountText : "",
      amountDisabled: estimate.productType === "LC" ? true : false,
      offerAmount: offerAmount ? offerAmount : null,
      displayEstimate: estimate.display,
      multipleOf: multipleOf ? multipleOf : 1,
    });
  }
  async insertUpdateLoan() {
    let { data, errors, partnerId, isStp } = this.state;
    this.setState({ showSpinner: true, isSubmitted: true });
    try {
      const ipAddress = await commonService.getIpAddress();
      const { data: response } = await loanFormService.insertUpdateLoan(data, ipAddress, partnerId, isStp);
      if (response.success) {
        delete errors["globalError"];
        this.props.updateReviewData(response.data._id, response.data.amount);
        this.setState({ data, errors, showSpinner: false, isSubmitted: false });
        // if (this.props.handleResponse && !response?.data?.isMobileNumberConfirmed) {
        if (this.props.handleResponse && response?.data?.isMobileNumberConfirmed) {
          this.props.handleResponse(response)
          return
        }

        if (response.isStp && response.url) {
          if (response.eNach)
            window.location = response.url;
          else
            window.location.href = `/verifyLoanContract/${response.data._id}`;
        }
        else if (response.digilocker) {
          let digiLockerUrl = `/digilocker/${response.data._id}${response.isForceDigiLocker ? "/y" : ""}`
          if (response.kycExpired) {
            this.setState({ digiLockerUrl, showKycExpiredModal: true })
          } else {
            window.location.href = digiLockerUrl
          }
        }
        else {
          //set all tabs to show
          this.props.handler("isStp", false);
          this.props.handler("activeTab", "kycTab");
        }
      } else {
        errors.globalError = response.message;
        this.setState({ errors, showSpinner: false, isSubmitted: false });
      }
    } catch (ex) {
      errors.globalError = ex.response.data.message;
      this.setState({ data, errors, showSpinner: false, isSubmitted: false });
    }
  }
  async componentDidMount() {
    const currentPartner = getCurrentPartner();

    if (currentPartner && currentPartner._id)
      this.setState({ partnerId: currentPartner._id });

    await this.loadDropdowns();
  }
  async componentWillReceiveProps() {
    if (this.props.loanData) {
      const currentUser = auth.getCurrentUser();

      // const currentPartner = this.props.userData && this.props.userData.partner ? this.props.userData.partner : null;

      const currentPartner = getCurrentPartner();

      if (!currentUser) window.location.href = "/";
      else if (currentUser && currentUser.loggedInFrom) {
        this.setState({ loggedInFrom: currentUser.loggedInFrom });
      }

      if (currentPartner && currentPartner.isloanDisabled)
        this.setState({ disabled: currentPartner.isloanDisabled });
      await this.mapDataToState(this.props.loanData, this.props.estimate, currentPartner, this.props.isStp, this.props.discountText, this.props.offerAmount);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
  async handleCustomErrors(errors) {
    const data = { ...this.state.data };
    const { tnc, offerAmount, multipleOf } = this.state;

    if (!errors["amount"] && data["amount"]) {
      if (parseInt(data["amount"]) % multipleOf !== 0)
        errors["amount"] = `Amount must be multiple of ${multipleOf}`;
      else if (offerAmount && parseInt(data["amount"]) > offerAmount)
        errors["amount"] = `Loan amount cannot be greater than ${offerAmount}`;
      else
        delete errors["amount"];
    }

    if (!tnc) errors["tnc"] = "Please agree to terms and conditions";
    else delete errors["tnc"];

    this.setState({ errors });
    return errors;
  }
  handleGstRequired=(amount)=>{
    if(Number(amount)>200000){
      this.props.handler("gstRequired",true)
    }
    else{
      this.props.handler("gstRequired",false)
    }
  }
  handleAmountChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    const { offerAmount } = this.state;
    data[input.name] = input.value;

    this.setState({ data, errors });

    const { multipleOf } = this.state;

    if (!errors[input.name] && input.value) {
      if (parseInt(input.value) % multipleOf !== 0) {
        errors[input.name] = `Amount must be multiple of ${multipleOf}`;
        this.setState({ data, errors, displayEstimate: false });
      }
      else if (offerAmount && parseInt(input.value) > offerAmount) {
        errors[input.name] = `Loan amount cannot be greater than ${offerAmount}`;
        this.setState({ data, errors, displayEstimate: false });
      }
      else {
        this.handleGstRequired(data.amount)
        const estimate = await loanFormService.getLoanEstimation(data.loanId, data.amount);
        delete errors[input.name];
        this.setState({ data, errors, loanEstimateData: estimate.data, displayEstimate: estimate.data.display });
      }
    }
    else
      this.setState({ displayEstimate: false });
  };
  doSubmit = async () => {
    await this.insertUpdateLoan();
  };


  handleKYCModalClose = () => {
    this.setState({ showKycExpiredModal: false }, () => {
      window.location.href = this.state.digiLockerUrl
    });

  }


}

export default LoanFormModel;
