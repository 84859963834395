import { apiUrl } from "../../config.json";
import http from "../common/httpService";
const apiEndpoint = apiUrl + "/ops/admin";


export async function getAllProductMasterProducts(currentPage) {
    let output = {
        success: false,
        data: []
    }
    try {
        console.log("Page num: ", currentPage);
        const { data } = await http.post(`${apiEndpoint}/getAllProductMasterProducts`, { pageNumber: currentPage });
        console.log("RESP: ", data)
        output.data = data.data;
        output.success = data.success;
        output.totalPages = data.totalPages;

    } catch (error) {
        console.log(error);
    }
    return output;
}

export async function createProductMasterProduct(newProductRecord) {
    let output = {
        success: false,
        message: "Create Product Failed"

    }
    try {
        console.log("newProductRecord: ", newProductRecord)
        const { data } = await http.post(`${apiEndpoint}/createProductMasterProduct`,
            newProductRecord
        );
        console.log("data: ", data);
        output.message = data.message;

        output.success = data.success;

    } catch (error) {
        console.log(error);
    }
    return output;
}

export async function editProductMasterProduct(editedProductRecord) {
    let output = {
        success: false,
        message: "Edit Product Failed"

    }
    try {
        const { data } = await http.post(`${apiEndpoint}/editProductMasterProduct`,
            editedProductRecord
        );
        output.message = data.message;

        output.success = data.success;

    } catch (error) {
        console.log(error);
    }
    return output;
}

export async function deleteProductMasterProduct(productName) {
    let output = {
        success: false,
        data: [],
        message: "Product delete failed"
    }
    try {

        const { data } = await http.post(`${apiEndpoint}/deleteProductMasterProduct`, {
            name: productName
        });

        output.data = data.data;
        output.success = data.success;
        output.message = data.message;

    } catch (error) {
        console.log(error);
    }
    return output;
}

export async function searchProductMasterProductByName(productName) {
    let output = {
        success: false,
        data: [],
        message: "Product search failed"
    }
    try {

        const { data } = await http.post(`${apiEndpoint}/searchProductMasterProduct`, {
            name: productName
        });

        output.data = data.data;
        output.success = data.success;
        output.message = data.message;

    } catch (error) {
        console.log(error);
    }
    return output;
}

export async function getLendingPartnerListProductMaster() {
    let output = {
        success: false,
        data: [],
        message: "Some error occured"
    }
    try {

        const { data } = await http.get(`${apiEndpoint}/getLendingPartnerListProductMaster`);

        output.data = data.data;
        output.success = data.success;
        output.message = data.message;

    } catch (error) {
        console.log(error);
    }
    return output;
}