import React from "react";
// import auth from "../../services/common/authService";
import { Form, Col, InputGroup, Button, Nav, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";
import PartnerRegistrationFormModel from "../../models/partner/partnerRegistrationFormModel";
import DatePicker from "react-datepicker2";
import moment from "moment";
import Tnc from "./../companyInfo/tnc";
import PrivacyPolicy from "./../companyInfo/privacyPolicy";
import { useState } from "react";
import { useLocation,useParams } from 'react-router-dom';
import axios from "axios";
import MegaNavBar from "../../components/common/megaNavBar";

import { FixedSizeList as List } from 'react-window';
import CustomDropdown from "../CustomDropdown";

import { apiUrl, encryptionKey } from "../../config.json"


// Render the Calendar

function PersonalLoanDetails(props) {
  
  

  const params = useParams();
  const resultLimit = 10
let i = 0

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [merchantCode, setMerchantCode] = useState(params.merchantCode)
  const [referrerId, setReferrerId] = useState(params.referrerId)
  const [partnerId, setPartnerId] = useState(params.partnerId)

  const [pan, setPan] = useState("");
  const [dob, setDob] = useState(new Date());
  const [adhaar, setAdhaar] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [occupation, setOccupation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [educationQualification, setEducationQualification] = useState("");
  const [residenceOwnership, setResidenceOwnership] = useState("");

  const [pincode, setPincode] = useState("");
  const [loanAmount, setLoanAmount] = useState("");



  

  



  


  // if (auth.getCurrentUser()) return <Redirect to="/" />;
  // const {
  //   data,
  //   errors,
  //   info,
  //   showSpinner,
  //   counter,
  //   showTnc,
  //   tnc,
  //   businessTypeList,
  //   showBorrowerPopup,
  //   showLenderPopup,
  //   isValidMobile,
  //   isValidOTP,
  //   isRegenerateLink,
  //   isRegenerateText,
  //   showPrivacyPolicy,
  //   panDisabled
  // } = props;

  // "firstName": "Hello",
  // "middleName": "habeeb",
  // "lastName": "Habeeb",
  // "emailId": "email@gmail.com",
  // "occupation": "selfemployed",
  // "mobileNo": 9638471118,
  // "adhaar": "sdsadasdsa",
  // "pan": "HQBPK7227M",
  // "dob": "2000-12-11T00:00:00.000Z",
  // "merchantCode": "12",
  // "referrerId": "121212",
  // "pincode": "431001",  //add
  // "companyName": "A g industries pvt ltd",//add
  // "educationQualification": "Graduate",//add
  // "residenceOwnership": "Owned",//add
  // "companyCategory": "Super CAT A"//add
  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log('data',firstName)
    console.log('data',moment(dob).format('MM/DD/YYYY'))
    console.log('data',occupation)

    let personalDetailsData ={
      firstName, lastName, emailId, mobileNo, adhaar, pan: String(pan).toUpperCase(), occupation, dob:moment(dob).format('MM/DD/YYYY'), referrerId, merchantCode,  companyName ,
      educationQualification,residenceOwnership, pincode, loanAmount, partnerId
    }
    console.log('personalDetailsData', personalDetailsData)
    await axios({ method: 'post',url : `${apiUrl}/masterpay/addPersonalLoanDetails`,data: personalDetailsData , withCredentials: false})
      .then(res=>{
        console.log("res here",res);
        console.log(res.data);
        if(res?.data?.status == 200){
        window.location = "/personalAndMSMELoans/successfullSubmission" 
        }else{
          alert("Form submission failed." + " " +res?.data?.message );

        }
      })
    }

  

  // let OccupationData =[{
  //   type : "Salaried"

  // }, {
  //   type : "Self Employed"
  // }]

  // console.log('stateee', this.state)
  // const tncLabel = (
  //   <React.Fragment>
  //     I have read and agree to{" "}
  //     <a
  //       href="#"
  //       style={{ textDecoration: "underline" }}
  //       onClick={this.handleViewTnc}
  //     >
  //       Terms and Conditions
  //     </a>{" "}
  //     and{" "}
  //     <a
  //       href="#"
  //       style={{ textDecoration: "underline" }}
  //       onClick={this.handleViewPrivacyPolicy}
  //     >
  //       Privacy Policy
  //     </a>
  //   </React.Fragment>
  // );


 
  return (
    
    <React.Fragment>
       <MegaNavBar  />
      {/* <SpinnerComponent  /> */}
      <h2 style={{ textAlign: "center" }}>Personal Loan Details</h2> <br />
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md="4" controlId="firstName">
            <Form.Label>First Name (As per PAN)*</Form.Label>
            <Form.Control
            required
              type="text"
              placeholder="Enter firstName"
              name="firstName"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              disabled={false}
              // onBlur={handleMerchantNameChange}
              autoComplete="off"
              style={{ textTransform: "uppercase" }}
              autoFocus={true}
            />
            {/* {errors["firstName"] && (
                    <div className="alert alert-danger">
                      {errors["firstName"]}
                    </div>
                  )} */}
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="lastName">
            <Form.Label>Last Name (As per PAN)*</Form.Label>
            <Form.Control
            required
              type="text"
              placeholder="Enter lastName"
              name="lastName"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              disabled={false}
              // onBlur={handleMerchantNameChange}
              autoComplete="off"
              style={{ textTransform: "uppercase" }}
              autoFocus={true}
            />
            {/* {errors["firstName"] && (
                    <div className="alert alert-danger">
                      {errors["firstName"]}
                    </div>
                  )} */}
          </Form.Group>
          <Form.Group
            as={Col}
            md="4"
            controlId="dob"
          >
            <Form.Label>Date of Birth*</Form.Label>
            <DatePicker
              name="dob"
              id="dob"
              
              max={moment()}
            
              selectedMonth={moment().subtract(100, "years").year}
              inputFormat="DD/MM/YYYY"
              isGregorian={true}
              className="form-control"
              inputReadOnly={true}
              showTodayButton={false}
              timePicker={false}
              onChange={date => setDob(date)}
              placeholder="Enter your date of birth"
              // disabled={dob}
            />
            {/* {errors["dob"] && (
                <div className="alert alert-danger">{errors["dob"]}</div>
              )} */}
          </Form.Group>{" "}
          {/* <Form.Group as={Col} md="4" controlId="typeOfEntity">
                  <Form.Label>Type of Entity</Form.Label>
             
                  <Form.Control
                    as="select"
                    name="typeOfEntity"
                    value={data["typeOfEntity"]}
                    onChange={this.handleChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {businessTypeList.map((item, i) => (
                      <option key={i} value={item.defination}>
                        {item.defination}
                      </option>
                    ))}
                  </Form.Control>
                  {errors["typeOfEntity"] && (
                    <div className="alert alert-danger">
                      {errors["typeOfEntity"]}
                    </div>
                  )}
                </Form.Group> */}
          <Form.Group as={Col} md="4" controlId="partnerType">
                  <Form.Label>Occupation*</Form.Label>
                  <Form.Control
                  required
                    as="select"
                    name="partnerType"
                    value={occupation}
                    onChange={(e) =>setOccupation(e.target.value)}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    <option key="sal" value="salaried">
                      Salaried
                    </option>{" "}
                    <option key="self" value="selfemployed">
                      Self employed
                    </option>
                  </Form.Control>
                  
                  {/* {errors["partnerType"] && (
                    <div className="alert alert-danger">
                      {errors["partnerType"]}
                    </div>
                  )} */}
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="CompanyName">
            <Form.Label>Company Name*</Form.Label>
            <CustomDropdown
              value={companyName}
              onChange={setCompanyName}
            />
          </Form.Group>


                  <Form.Group as={Col} md="4" controlId="educationQualification">
                  <Form.Label>Education*</Form.Label>
                  <Form.Control
                  required
                    as="select"
                    name="educationQualification"
                    value={educationQualification}
                    onChange={(e) =>setEducationQualification(e.target.value)}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    <option key="Doctor" value="Doctor">
                      Doctor
                    </option>{" "}
                    <option key="CA" value="CA">
                      CA
                    </option>
                    <option key="Masters" value="Masters">
                      Masters
                    </option>
                    <option key="Graduate" value="Graduate">
                    Graduate
                    </option>
                    <option key="Non-graduate" value="Non-graduate">
                    Non-graduate
                    </option>
                  </Form.Control>
                  
                  {/* {errors["partnerType"] && (
                    <div className="alert alert-danger">
                      {errors["partnerType"]}
                    </div>
                  )} */}
                </Form.Group>


                <Form.Group as={Col} md="4" controlId="residenceOwnership">
                  <Form.Label>Residence Ownership*</Form.Label>
                  <Form.Control
                  required
                    as="select"
                    name="residenceOwnership"
                    value={residenceOwnership}
                    onChange={(e) =>setResidenceOwnership(e.target.value)}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    <option key="rent" value="Rented">
                      Rented
                    </option>{" "}
                    <option key="owned" value="Owned">
                      Owned
                    </option>
                   
                  </Form.Control>
                  
                  {/* {errors["partnerType"] && (
                    <div className="alert alert-danger">
                      {errors["partnerType"]}
                    </div>
                  )} */}
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="pincode">
            <Form.Label>Pincode*</Form.Label>
            <Form.Control
            required
              type="number"
              placeholder="Enter your pincode"
              name="pincode"
              value={pincode}
              onChange={e => setPincode(e.target.value)}
              autoComplete="off"
              // maxLength="10"
              disabled={false}
              // onBlur={this.handlePANChange}
              style={{ textTransform: "uppercase" }}
              // disabled={panDisabled}
            />
            {/* {errors["panNo"] && (
              <div className="alert alert-danger">{errors["panNo"]}</div>
            )} */}
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="panNo">
            <Form.Label>PAN Number*</Form.Label>
            <Form.Control
            required
              type="text"
              placeholder="Enter your PAN number"
              name="panNo"
              value={pan}
              onChange={e => setPan(e.target.value)}
              autoComplete="off"
              maxLength="10"
              disabled={false}
              // onBlur={this.handlePANChange}
              style={{ textTransform: "uppercase" }}
              // disabled={panDisabled}
            />
            {/* {errors["panNo"] && (
              <div className="alert alert-danger">{errors["panNo"]}</div>
            )} */}
          </Form.Group>


             
                  
                  {/* {errors["partnerType"] && (
                    <div className="alert alert-danger">
                      {errors["partnerType"]}
                    </div>
                  )} */}
             
          
        </Form.Row>
        <Form.Row>
       
          <Form.Group as={Col} md="4" controlId="adhaarNo">
            <Form.Label>Adhaar Number*</Form.Label>
            <Form.Control
            required
              type="text"
              placeholder="Enter your Adhaar number"
              name="adhaarNo"
              value={adhaar}
              onChange={e => setAdhaar(e.target.value)}
              autoComplete="off"
              // maxLength="10"
              disabled={false}
              // onBlur={this.handlePANChange}
              style={{ textTransform: "uppercase" }}
              // disabled={panDisabled}
            />
            {/* {errors["panNo"] && (
              <div className="alert alert-danger">{errors["panNo"]}</div>
            )} */}
          </Form.Group>
          {/* <Form.Group as={Col} md="4" controlId="userName">
                  <Form.Label>Username*</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      placeholder="Enter your username"
                      aria-describedby="inputGroupPrepend"
                      name="userName"
                      value={data["userName"]}
                      onChange={this.handleChange}
                      onBlur={this.handleUserNameChange}
                      autoComplete="off"
                    />
                  </InputGroup>
                  {errors["userName"] && (
                    <div className="alert alert-danger">{errors["userName"]}</div>
                  )}
                  {info["userName"] && (
                    <div className="alert alert-success">{info["userName"]}</div>
                  )}
                </Form.Group> */}
          <Form.Group as={Col} md="4" controlId="email">
            <Form.Label>Email Id*</Form.Label>{" "}
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
              required
                type="text"
                placeholder="Enter your email address"
                name="email"
                value={emailId}
                onChange={e => setEmailId(e.target.value)}
                // onBlur={this.handleEmailChange}
                autoComplete="off"
                maxLength="100"
                disabled={false}
              />
            </InputGroup>
            {/* {errors["email"] && (
              <div className="alert alert-danger">{errors["email"]}</div>
            )}
            {info["email"] && (
              <div className="alert alert-success">{info["email"]}</div>
            )} */}
          </Form.Group>
          {/* 
                 <Form.Group as={Col} md="4" controlId="typeOfEntity">
                  <Form.Label>Occupation</Form.Label>
             
                  <Form.Control
                    as="select"
                    name="typeOfEntity"
                    value={data["typeOfEntity"]}
                    onChange={this.handleChange}
                  >
                    {" "}
                    <option key="select" value="">
                      ---Select---
                    </option>
                    {OccupationData.map((item, i) => (
                      <option key={i} value={item.type}>
                        {item.type}
                      </option>
                    ))}
                  </Form.Control>
                  {errors["typeOfEntity"] && (
                    <div className="alert alert-danger">
                      {errors["typeOfEntity"]}
                    </div>
                  )}
                </Form.Group> */}
          {/* <Form.Group as={Col} md="4" controlId="doi">
                  <Form.Label>Date of Incorporation</Form.Label>
                  <DatePicker
                    name="doi"
                    id="doi"
                    value={data["doi"]}
                    max={moment()}
                    inputFormat="DD/MM/YYYY"
                    isGregorian={true}
                    className="form-control"
                    inputReadOnly={true}
                    showTodayButton={false}
                    timePicker={false}
                    onChange={(value) => this.handleDoiChange(value)}
                    placeholder="Enter date of incoporation"
                  />
                  {errors["doi"] && (
                    <div className="alert alert-danger">{errors["doi"]}</div>
                  )}
                </Form.Group> */}


<Form.Group as={Col} md="4" controlId="loanAmount">
            <Form.Label>Loan Amount*</Form.Label>
            <Form.Control
            required
              type="number"
              placeholder="Enter your loan Amount"
              name="loanAmount"
              value={loanAmount}
              onChange={e => setLoanAmount(e.target.value)}
              autoComplete="off"
              // maxLength="10"
              disabled={false}
              // onBlur={this.handlePANChange}
              style={{ textTransform: "uppercase" }}
              // disabled={panDisabled}
            />
            {/* {errors["panNo"] && (
              <div className="alert alert-danger">{errors["panNo"]}</div>
            )} */}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md="4" controlId="mobileNo">
            <Form.Label>Mobile No*</Form.Label>
            <Form.Control
            required
              type="text"
              pattern="[0-9]*"
              placeholder="Enter your mobile number"
              name="mobileNo"
              value={mobileNo}
              onChange={e => setMobileNo(e.target.value)}
              // onBlur={this.handleMobileChangePersonalLoan}
              autoComplete="off"
              maxLength="10"
              disabled={false}
            />
            {/* {errors["mobileNo"] && (
              <div className="alert alert-danger">{errors["mobileNo"]}</div>
            )}
            {info["mobileNo"] && (
              <div className="alert alert-success">{info["mobileNo"]}</div>
            )} */}
          </Form.Group>
          {/* {isValidMobile && (
                  <Form.Group as={Col} md="4" controlId="otp">
                    <Form.Label>OTP*</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter your OTP"
                      name="otp"
                      value={data["otp"]}
                      onChange={this.handleChange}
                      autoComplete="off"
                      disabled={isValidOTP}
                      onKeyUp={this.handleOTPChange}
                      autoFocus={isValidMobile}
                      maxLength="6"
                    />
                    {errors["otp"] && (
                      <div className="alert alert-danger">{errors["otp"]}</div>
                    )}
                    {info["otp"] && (
                      <div className="alert alert-success">{info["otp"]}</div>
                    )}
                    {isRegenerateText && (
                      <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                    )}
                    {isRegenerateLink && (
                      <Nav.Item id="regenerateOTP">
                        <Nav.Link
                          className="underline"
                          onClick={this.handleRegenerateOTP}
                          style={{
                            padding: 0,
                            float: "right",
                            textDecoration: "underline",
                          }}
                        >
                          Re-generate OTP
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Form.Group>
                )} */}
        </Form.Row>
        {/* <Form.Row> */}
          {/* <Form.Group as={Col} md="4" controlId="password">
                  <Form.Label>Password*</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    value={data["password"]}
                    onChange={this.handleChange}
                    onBlur={this.onBlurPassword}
                    autoComplete="off"
                    autoFocus={isValidOTP}
                  />
                  {errors["password"] && (
                    <div className="alert alert-danger">{errors["password"]}</div>
                  )}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="confirmPassword">
                  <Form.Label>Re-enter Password*</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Re-enter password"
                    name="confirmPassword"
                    value={data["confirmPassword"]}
                    onChange={this.handleChange}
                    autoComplete="off"
                  />
                  {errors["confirmPassword"] && (
                    <div className="alert alert-danger">
                      {errors["confirmPassword"]}
                    </div>
                  )}
                </Form.Group> */}
        {/* </Form.Row> */}
        {/* <Form.Group controlId="tnc"> */}
          {/* <Form.Check
            type="checkbox"
            // label={tncLabel}
            feedback="You must agree before submitting."
            // checked={tnc}
            // onChange={() => this.handleTnc(tnc)}
            color="red"
          />{" "} */}
          {/* {errors["tnc"] && (
            <div className="alert alert-danger">{errors["tnc"]}</div>
          )} */}
        {/* </Form.Group>{" "} */}
        <Button
          className="ff-button"
          type="submit"
          // disabled={data.isSubmitted}
        >
          Submit
        </Button>
        {/* <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
          {errors["globalError"] && (
            <div className="alert alert-danger">{errors["globalError"]}</div>
          )}
        </Form.Group> */}
      </Form>
      {/* <Modal
        size="lg"
        show={showTnc}
        onHide={this.handleClose}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
          <Tnc />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={showPrivacyPolicy}
        onHide={this.handleClose}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
          <PrivacyPolicy />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showBorrowerPopup} onHide={this.handlePopupClose}>
        <Modal.Header closeButton>
          <Modal.Title>Borrowing Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are an enterprise with retail and business customers and want to
          setup a lending program to meet the financing needs of customers.
          Fundfina will provide seamless financing experience through APIs to
          improve customer profitability and maximize retention.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handlePopupClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showLenderPopup} onHide={this.handlePopupClose}>
        <Modal.Header closeButton>
          <Modal.Title>Lending Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are a Bank or NBFC or financial institution exploring means of
          digitizing MSME loan and personal loan origination process. Fundfina
          will help you achieve favorable business outcomes by providing
          superior customer experience and deep analytic insights.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handlePopupClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </React.Fragment>
  );
}


export default PersonalLoanDetails;
