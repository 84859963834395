import React from 'react';
import { Redirect } from "react-router-dom";
import { Form, Col, Button, Table, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import TrueScoreTransactionsModel from './../../models/trueScore/trueScoreTransactionsModel';
import Tnc from "./../companyInfo/tnc";
class TrueScoreTransactions extends TrueScoreTransactionsModel {

    render() {
        const { data, showSpinner, errors, info, tnc, showTnc } = this.state;
        const tncLabel = (<React.Fragment>I have read, understood {<a
            href="#"
            style={{ textDecoration: "underline" }}
            onClick={this.handleViewTnc}
        >Terms and Conditions</a>} and agree that TrueScore is being offered on best effort basis and the Consumer agrees and acknowledges that Fundfina will not be responsible for any kind of damage arising directly or indirectly what so ever incurred by the Consumer availing this facility.</React.Fragment>);
        return (
            <React.Fragment>
                <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
                    TrueScore
                </h2>
                <SpinnerComponent showSpinner={showSpinner} />
                <br />
                <br />
                <Form onSubmit={this.handleSubmit} style={{ paddingTop: 30 }}>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan" className="row">
                            <Form.Group as={Col} md="5">
                                <Form.Label>Please provide your PAN number (Only for borrower)</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="5">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your pan number"
                                    name="pan"
                                    value={data["pan"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                    ref="pan"
                                />
                                {errors && errors["pan"] && (
                                    <div className="alert alert-danger">{errors["pan"]}</div>
                                )}
                                {info && info["pan"] && (
                                    <div className="alert alert-success">{info["pan"]}</div>
                                )}
                            </Form.Group>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="businessDuration" className="row">
                            <Form.Group as={Col} md="5">
                                <Form.Label>* How long have you/entity operated current business?</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="5">
                                <Form.Control
                                    type="text"
                                    placeholder="In months"
                                    name="businessDuration"
                                    ref="businessDuration"
                                    value={data["businessDuration"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="4"
                                    onKeyPress={this.handleKeyPressNumeric}
                                />
                                {errors && errors["businessDuration"] && (
                                    <div className="alert alert-danger">{errors["businessDuration"]}</div>
                                )}
                                {info && info["businessDuration"] && (
                                    <div className="alert alert-success">{info["businessDuration"]}</div>
                                )}
                            </Form.Group>
                        </Form.Group>
                    </Form.Row>
                    {data.transactionList.map((item) => (
                        <Form.Row key={"row" + item.month + "_" + item.year}>
                            <Form.Group as={Col} md="12" controlId={item.month + "_" + item.year} className="row">
                                <Form.Group as={Col} md="2" style={{ maxWidth: "10%" }}><Form.Label>{item.monthName + " " + item.year}</Form.Label></Form.Group>
                                <Form.Group as={Col} md="5" >
                                    <Form.Control
                                        type="number"
                                        placeholder="Transaction Value – Total amount of sales in a month in ₹"
                                        name={"tranValue_" + item.month + "_" + item.year}
                                        maxLength="50"
                                        onBlur={this.handleTranValueBlur}
                                        onChange={this.handleTranValueChange}
                                        onKeyPress={this.handleKeyPressDecimal}
                                        value={item.tranValue}
                                        pattern="^(\d*\.)?\d+$"
                                        ref={"tranValue_" + item.month + "_" + item.year}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="5">
                                    <Form.Control
                                        type="number"
                                        placeholder="Transaction Count – Total number of sales in a month"
                                        name={"tranCount_" + item.month + "_" + item.year}
                                        maxLength="50"
                                        onBlur={this.handleTranCountBlur}
                                        onChange={this.handleTranCountChange}
                                        onKeyPress={this.handleKeyPressNumeric}
                                        value={item.tranCount}
                                        ref={"tranCount_" + item.month + "_" + item.year}
                                    />
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>
                    ))}
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="pan">
                            <Button
                                className="ff-button"
                                style={{ marginTop: 30, marginLeft: 10 }}
                                onClick={this.handleAddRow}
                            >
                                Add Row
                            </Button>
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12">
                            <Form.Check
                                type="checkbox"
                                feedback="You must agree before submitting."
                                id="tnc"
                                checked={tnc}
                                label={tncLabel}
                                name="tnc"
                                onChange={() => this.handleTnc(tnc)}
                                color="red"
                                ref="tnc"
                            />
                            {errors["tnc"] && (
                                <div className="alert alert-danger">{errors["tnc"]}</div>
                            )}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="globalError" style={{ marginTop: 30, textAlign: "center" }}>
                            {errors["globalError"] && (
                                <div className="alert alert-danger">{errors["globalError"]}</div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="12" style={{ textAlign: "center" }}>
                            <Button
                                className="ff-button"
                                style={{ marginTop: 30, marginLeft: 10 }}
                                type="submit"
                            >
                                Submit
                            </Button>

                        </Form.Group>
                    </Form.Row>
                </Form>
                <Modal
                    size="lg"
                    show={showTnc}
                    onHide={this.handleClose}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
                        <Tnc />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        );
    }
}

export default TrueScoreTransactions;