import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import { toast } from "react-toastify";

class CreditGrids extends InputDataForm {
  state = {
    data: {
      pinCodes : []
    },
    showSpinner: false,
    pinCode : ""
  };
  schema = {
    minMonthlySalesVol : Joi.any(),
    minLoanAmt : Joi.any(),
    maxLoanAmt : Joi.any(),
    merchantVintage :Joi.any(),
    minLoanTenure : Joi.any(),
    maxLoanTenure : Joi.any(),
    minBureauScore : Joi.any(),
    minTrueScore : Joi.any(),
    pinCodes : Joi.any(),
    pinCode : Joi.any()
    // name : Joi.any()
  };
  
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    this.getPartnerConfigurations()

    let { data } = this.state;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

  };

  removePinCode = (index)=>{
    let data = this.state.data;
    data.pinCodes.splice(index ,1)
    this.setState({data})
  }

  addPinCode =()=> {
    
    if(!this.state.pinCode)
      return
      
    let data = this.state.data
    data.pinCodes.push(this.maskPinCode(this.state.pinCode))
    this.setState({ data ,pinCode : ""})
  }

  handleChangePinCode =(event)=>{

    if(event.target.value.length > 6)
      return;

    this.setState({ pinCode : event.target.value })
  }

  maskPinCode = (code)=>{

    let remainingX = ""

   for(let i= 0 ; i < 6 - code.length ; i++){
    remainingX += 'X'
   }
   return code + remainingX
  }

  validatePayload = (payload)=>{

    if(payload.minLoanTenure < 0 || 
      payload.maxLoanTenure < 0 || 
      payload.minLoanAmt < 0 ||
      payload.maxLoanAmt < 0 || 
      payload.minMonthlySalesVol < 0 ||
      payload.minTrueScore < 0 ||
      payload.merchantVintage < 0 ||
      payload.minBureauScore < 0){
      toast.error("Negative values not allowed");
      return false
    }

      return true
  }

  getPartnerConfigurations = async()=>{

    try{
    let partnerId = this.props.match && this.props.match.params && this.props.match.params.id
      if(!partnerId)
      return

      let response = await commonService.getPartnerConfigurations(partnerId);

      if(response && response.data && response.data.data && response.data.data.creditGrid){
        this.setState({data : response.data.data.creditGrid})
      }
     }catch(error){

    }
  }

  onClickSave = async ()=>{
    let isValid = this.validatePayload(this.state.data)

    if(!isValid){
      return
    }
    
    let partnerId = this.props.match && this.props.match.params && this.props.match.params.id
    if(!partnerId)
      return

    this.state.data.partnerId = partnerId;

    try{

    this.setState({showSpinner : true})
    let response = await commonService.updatePartnerConfigurations(this.state.data);

    this.setState({showSpinner : false})
    if(response.success)
    toast.success("Updated Successfully");
    else 
    toast.error("Update Failure");
    }catch(error){
    toast.error("Oops something went wrong");
    }

  }

  onChangeCheckBox =(key)=>{
    let data = this.state.data
    data[key] =  !data[key]
    this.setState({ data })
  }
}

export default CreditGrids;
