import InputDataForm from "../../components/common/form";
import * as kycFormService from "../../services/borrow/kycFormService";
import auth from "../../services/common/authService";
import { proofimages } from "../../config.json";
import * as analytics from "../../utils/analytics";

class MyProfileFormModel extends InputDataForm {
  state = {
    data: {
      userId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      fullName: "",
      employment: "",
      panNo: "",
      add1: "",
      add2: "",
      add3: "",
      addressType: "Correspondence",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      poaNo: "",
      poaType: "",
      isRetailer: false,
      alternateEmail: "",
      alternateMobile: "",
      emailId: "",
      mobileNo: "",
      kycStatus: "",
      entityName: "",
      bankList: [],
      gstList: [],
      userRole: "",
      partnerName: "",
      udyogAadhaarNo: "",
      udyogAadhaarFileName: "",
      udyogAadhaarFilePath: "",
      poaFileName: "",
      poaFilePath: "",
      fullAddress: "",
      panFileName: "",
      panFilePath: "",
      profileFileName: "",
      profileFilePath: "",
      kycStatusColor: "black",
    },
    showSpinner: false,
    optionalDocumentList: [],
    errors: {},
    popUpPath: "",
    showPopUp: false,
    mandateList: [],
    showErrorModal: false,
    errorMessage: "",
    errorHeader: "",
    umrn: "",
    showMandateConfirmation: false
  };
  async handleCustomErrors(errors) {
    return errors;
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    analytics.track();

    await this.getUserData();
  }
  async mapDataToState(userData) {
    let data = { ...this.state.data };
    let files = { ...this.state.files };

    let { user } = userData;

    let {
      userDetails,
      addressDetails,
      bankDetails,
      gstDetails,
      poaDetails,
      panDocDetails,
      additionalDocuments,
      partnerDetails,
      udyogAadhaar,
      profileDocDetails,
    } = user;

    //User Details
    if (user._id) data.userId = user._id;
    if (userDetails.firstName) data.firstName = userDetails.firstName;
    if (userDetails.middleName) data.middleName = userDetails.middleName;
    if (userDetails.lastName) data.lastName = userDetails.lastName;
    if (userDetails.role) data.userRole = userDetails.role;
    if (userDetails.kycStatus) data.kycStatus = userDetails.kycStatus;
    if (userDetails.kycStatus) {
      if (userDetails.kycStatus == "Approved") data.kycStatusColor = "green";
      if (userDetails.kycStatus == "Rejected") data.kycStatusColor = "red";
      if (userDetails.kycStatus == "Under Review")
        data.kycStatusColor = "orange";
      if (userDetails.kycStatus == "Review") data.kycStatusColor = "orange";
      if (userDetails.kycStatus == "Inititated" || userDetails.kycStatus == "ReInitiated") data.kycStatusColor = "black";
    }
    else
      data.kycStatusColor = "black";

    if (partnerDetails.partnerName)
      data.partnerName = partnerDetails.partnerName;
    let middleName = data.middleName ? ` ${data.middleName}` : "";
    data.fullName = `${data.firstName}${middleName} ${data.lastName}`;

    if (userDetails.employment) data.employment = userDetails.employment;
    if (user.isRetailer) data.isRetailer = user.isRetailer;

    if (udyogAadhaar && udyogAadhaar.aadhaarNo) {
      data.udyogAadhaarNo = udyogAadhaar.aadhaarNo;
      data.udyogAadhaarFileName = udyogAadhaar.docDetails?.[0]?.fileName;
      data.udyogAadhaarFilePath = udyogAadhaar.docDetails?.[0]?.filePath;

      let url = data.udyogAadhaarFilePath ? await this.getSignedURLToDownload(data.udyogAadhaarFilePath) : ""
      data.udyogAadhaarFileSrc = url

    }
    //Email and Mobile

    if (userDetails.emailId && userDetails.emailId[0]) {
      data.emailId = userDetails.emailId[0];
    }
    if (userDetails.mobileNo && userDetails.mobileNo[0] && userDetails.mobileNo[0].mobileNumber) {
      data.mobileNo = userDetails.mobileNo[0].mobileNumber;
    }
    if (userDetails.emailId && userDetails.emailId[1]) {
      data.alternateEmail = userDetails.emailId[1];
    }
    if (userDetails.mobileNo && userDetails.mobileNo[1] && userDetails.mobileNo[1].mobileNumber) {
      data.alternateMobile = userDetails.mobileNo[1].mobileNumber;
    }
    //Address Details
    if (addressDetails[0] && addressDetails[0].add1)
      data.add1 = addressDetails[0].add1;
    if (addressDetails[0] && addressDetails[0].add2)
      data.add2 = addressDetails[0].add2;
    if (addressDetails[0] && addressDetails[0].add3)
      data.add3 = addressDetails[0].add3;
    if (addressDetails[0] && addressDetails[0].city) {
      data.city = addressDetails[0].city;
    }
    if (addressDetails[0] && addressDetails[0].state) {
      data.state = addressDetails[0].state;
    }
    if (addressDetails[0] && addressDetails[0].pincode)
      data.pincode = addressDetails[0].pincode;
    if (user.panNo) {
      data.panNo = user.panNo;
    }
    if (data.add2) data.add2 = ` ${data.add2}`;
    data.fullAddress = `${data.add1}${data.add2} ${data.add3}`;
    //Bank Details
    for (const item of bankDetails) {
      let bank = {
        accountNo: item.accountNo,
        ifsc: item.ifsc,
        bankName: item.bankName,
        branch: item.branch,
        accountType: item.accountType
      };

      data.bankList.push(bank);
    }

    //GST Details
    // for (const item of gstDetails) {

    //   let gst = {
    //     gstNo: item.gstNo,
    //     gstFile: item.docDetails[0].fileName,
    //     gstFilePath: item.docDetails[0].filePath,

    //   };
    //   let url = gst.gstFilePath ? await this.getSignedURLToDownload(gst.gstFilePath) : ""
    //   gst.gstFileSrc = url
    //   data.gstList.push(gst);
    // }

    //POA Details
    if (poaDetails[0] && poaDetails[0].poaNo) {
      data.poaNo = poaDetails[0].poaNo;
    }
    if (poaDetails[0] && poaDetails[0].poaType)
      data.poaType = poaDetails[0].poaType;

    if (panDocDetails[0] && panDocDetails[0].fileName) {
      data.panFileName = panDocDetails[0].fileName;
      data.panFilePath = panDocDetails[0].filePath;
      let url = data.panFilePath ? await this.getSignedURLToDownload(data.panFilePath) : ""
      data.panFileSrc = url
    }

    if (
      poaDetails[0] &&
      poaDetails[0].docDetails[0] &&
      poaDetails[0].docDetails[0].fileName
    ) {
      data.poaFileName = poaDetails[0].docDetails[0].fileName;
      data.poaFilePath = poaDetails[0].docDetails[0].filePath;

      let url = data.poaFilePath ? await this.getSignedURLToDownload(data.poaFilePath) : ""
      data.poaFileSrc = url
    }

    if (
      profileDocDetails &&
      profileDocDetails[0] &&
      profileDocDetails[0].fileName
    ) {
      data.profileFileName = profileDocDetails[0].fileName;
      data.profileFilePath = profileDocDetails[0].filePath;

      let url = data.profileFilePath ? await this.getSignedURLToDownload(data.profileFilePath) : ""
      data.profileFileSrc = url
    }

    let optionalDocumentList = [];

    if (additionalDocuments && additionalDocuments.length > 0) {
      let filePaths = []
      for (const item of additionalDocuments) {
        if (item.docDetails[0].filePath) {
          filePaths.push(item.docDetails[0].filePath)
        }
      }

      if (filePaths?.length) {
        let urls = await this.getBulkSignedURLToDownload(filePaths)
        optionalDocumentList = urls
      }

    }

    this.setState({
      data,
      files,
      optionalDocumentList,
    });

    // await this.getMandateList();
  }
  async getUserData() {
    let data = { ...this.state.data };

    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location = "/login");

    const partnerUser = (currentUser.role === "Lending Partner" || currentUser.role === "Borrowing Partner") ? true : false;

    const currentPartner = auth.getCurrentPartner();

    let partnerId = null;

    if (currentPartner && currentPartner._id)
      partnerId = currentPartner._id;

    try {
      const { data: userDetails } = await kycFormService.getUserDetails(
        currentUser.userId, partnerUser, partnerId, null
      );
      if (userDetails.success) {
        await this.mapDataToState(userDetails.data);
      }
    } catch (ex) { }
  }
  cancelMandate = async () => {
    let { mandateList, data, umrn } = this.state;

    const list = [...this.state.mandateList];

    let tranIndex = list.findIndex(x => x.umrn === umrn);

    mandateList[tranIndex].isCancelButtonDisabled = true;

    mandateList = list;

    this.setState({ showSpinner: true, mandateList })

    try {
      let response = await kycFormService.cancelMandate(data.userId, umrn);

      mandateList[tranIndex].isCancelButtonDisabled = false;

      if (response.success) {
        this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Success!", showSpinner: false, mandateList });
        // await this.getMandateList();
      }

      else
        this.setState({ errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", showSpinner: false, mandateList });

    }
    catch (ex) {
      this.setState({ errorMessage: ex.response.data.message, showErrorModal: true, errorHeader: "Note!", showSpinner: false, mandateList });
    }

  }
  cancelMandateConfirmation = async (e) => {
    const list = [...this.state.mandateList];
    let recordFields = e.target.name.split('_');

    let tranIndex = list.findIndex(x => x.umrn === recordFields[1]);

    let umrn = list[tranIndex].umrn;

    this.setState({ umrn: umrn, showMandateConfirmation: true });

  }
  async getMandateList() {
    const { data } = this.state;
    try {
      const mandateList = await kycFormService.getMandateList(data.userId);

      this.setState({ mandateList: mandateList.data });
    } catch (ex) {

    }
  }
  closeErrorModal = () => {
    this.setState({
      showErrorModal: false,
      showMandateConfirmation: false
    });
  };
  doSubmit = async () => { };
}

export default MyProfileFormModel;
