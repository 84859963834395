import InputDataForm from "../../components/common/form";
import * as reconService from "../../services/recon/reconService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import { toast } from "react-toastify";

class SettlementMISModel extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      partner: "all"
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 10,
    partners: [],
    showUploader: false
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    partner: Joi.any(),
    page: Joi.any(),
    misList: Joi.any()
  };
  async getMISLogList() {

    this.state.data.page = this.state.currentPage

    const data = { ...this.state.data };

    this.setState({ showSpinner: true });
    try {
      const { data: misList, totalPages } = await reconService.misLogList(data);

      data.misList = misList;

      this.setState({ data, showSpinner: false, pageSize: totalPages });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async componentDidMount() {
    await this.getMISLogList();
    await this.getBorroingPartners();
  };
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getMISLogList();
  };

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getMISLogList()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getMISLogList()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getMISLogList()
    })
  }

  exportExcel = () => {
    this.getMISLogList(true)
  }

  onClickUploadFile = () => {
    this.setState({ showUploader: true })
  }

  onClose = () => {
    this.setState({ showUploader: false })
    this.getMISLogList()
  }

  download = async (url) => {
    try {
      let singedUrlToDownload = await commonService.getSignedUrlToDownload(url)

      if(!singedUrlToDownload || !singedUrlToDownload.data)
        return;

      let urlSplit = url.split('/')
      var link = document.createElement("a");
      link.download = urlSplit[urlSplit.length - 1]
      link.href = singedUrlToDownload.data;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Oops something went wrong");
    }
  }
}

export default SettlementMISModel;
