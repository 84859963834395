import InputDataForm from "../../components/common/form";
import * as disbursalService from '../../services/disbursal/disbursalService';
import auth from "../../services/common/authService";
import { moment } from "moment";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import { async } from './../../services/upload/excelUploadService';

class DisbursalDetailsModel extends InputDataForm {
    state = {
        data: {
            loanId: "",
            bankReferenceNo: "",
            paymentMode: "",
            bankCode: "",
            dateOfTransaction: momentJalaali(),
            trancheNo: "",
            trancheAmount: "",
            disbursedAmount: "",
            availableAmount: "",
            balanceAmount: "",
            isSaved: false
        },
        showSpinner: false,
        errors: {},
        info: {},
        eNachDetails: {
            eNachStatus: "",
            isEnachRequired: false,
            eNachErrorCode: "",
            eNachErrorMessage: "",
            eNachNo: "",
            proceed: false
        },
        showPopup: false
    };

    schema = {
        loanId: Joi.string().required(),
        bankReferenceNo: Joi.string().required().label("Bank Reference No"),
        paymentMode: Joi.string().required().label("Payment Mode"),
        bankCode: Joi.string().required().label("Bank"),
        dateOfTransaction: Joi.any(),
        isSaved: Joi.any(),
        trancheNo: Joi.any(),
        trancheAmount: Joi.any(),
        disbursedAmount: Joi.any(),
        availableAmount: Joi.any(),
        balanceAmount: Joi.any()
    }

    async componentDidMount() {
        const data = { ...this.state.data };
        data.loanId = this.props.loanId;

        this.setState({ data });

        if (!auth.getCurrentUser() || auth.getCurrentUser().role.toLowerCase() !== "admin") return;

        await this.getDisbursalDetails(data);
        await this.getTrancheDetails(data);
    }

    async getDisbursalDetails(data) {
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });

        const disbursalDetails = await disbursalService.getDisbursalDetails(data.loanId);

        if (disbursalDetails.success) {
            if (disbursalDetails.data) {
                if (disbursalDetails.data.bankReferenceNo != "") {
                    data.bankReferenceNo = disbursalDetails.data.bankReferenceNo;
                }
                if (disbursalDetails.data.paymentMode != "") {
                    data.paymentMode = disbursalDetails.data.paymentMode;
                }
                if (disbursalDetails.data.bankCode != "") {
                    data.bankCode = disbursalDetails.data.bankCode;
                }
                if (disbursalDetails.data.doD != "") {
                    data.dateOfTransaction = momentJalaali(disbursalDetails.data.doD);
                }
            } else {
                errors.globalError = disbursalDetails.message;
            }
        }
        this.setState({ data, errors, showSpinner: false });
    }

    async getTrancheDetails(data) {
        //const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        let { eNachDetails } = this.state;
        this.setState({ showSpinner: true });

        const trancheDetails = await disbursalService.getTrancheDetails(data.loanId);

        if (trancheDetails.success) {
            data.trancheNo = trancheDetails.data.trancheNo;
            data.balanceAmount = trancheDetails.data.availableAmount;

            if (trancheDetails.eNachResponse) {
                eNachDetails.eNachStatus = trancheDetails.eNachResponse && trancheDetails.eNachResponse.eNachStatus ? trancheDetails.eNachResponse.eNachStatus : "";
                eNachDetails.eNachErrorCode = trancheDetails.eNachResponse && trancheDetails.eNachResponse.eNachErrorCode ? trancheDetails.eNachResponse.eNachErrorCode : "";
                eNachDetails.eNachErrorMessage = trancheDetails.eNachResponse && trancheDetails.eNachResponse.eNachErrorMessage ? trancheDetails.eNachResponse.eNachErrorMessage : "";
                eNachDetails.eNachNo = trancheDetails.eNachResponse && trancheDetails.eNachResponse.eNachNo ? trancheDetails.eNachResponse.eNachNo : "";
                eNachDetails.isEnachRequired = trancheDetails.eNachResponse && trancheDetails.eNachResponse.isEnachRequired ? trancheDetails.eNachResponse.isEnachRequired : false;
                eNachDetails.proceed = trancheDetails.eNachResponse && trancheDetails.eNachResponse.proceed ? trancheDetails.eNachResponse.proceed : false;
            }

            data.trancheAmount = trancheDetails.data.trancheAmount;
            data.disbursedAmount = trancheDetails.data.disbursedAmount;
            data.availableAmount = trancheDetails.data.availableAmount;
        } else {
            errors.globalError = trancheDetails.message;
        }
        this.setState({ data, errors, showSpinner: false, eNachDetails });
    }

    async addTranche() {
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };

        const trancheDetails = await disbursalService.addTranche(data.loanId);
        this.setState({ showSpinner: true });
        if (trancheDetails.success) {
            data.trancheNo = trancheDetails.data.trancheNo;
            data.trancheAmount = trancheDetails.data.trancheAmount;
            data.disbursedAmount = trancheDetails.data.disbursedAmount;
            data.availableAmount = trancheDetails.data.availableAmount;
        } else {
            errors.globalError = trancheDetails.message;
        }
        this.setState({ data, errors, showSpinner: false });
    }


    async handleCustomErrors(errors) {
        return errors;
    }

    async doSubmit() {
        const data = { ...this.state.data };
        this.setState({ showSpinner: true });
        try {
            await this.getTrancheDetails(data);

            if (data.availableAmount <= 0) {
                this.setState({ showPopup: true });
            } else {
                this.setState({ showPopup: false });
                await this.addTranche();
                await this.handleDisbursalSubmit();
            }
        }
        catch (ex) {
            const errors = { ...this.state.errors };
            errors.globalError = ex.message;
            this.setState({ errors, showSpinner: false });
        }
    }

    async handleDisbursalSubmit() {
        const errors = { ...this.state.errors };
        const data = { ...this.state.data };
        const info = { ...this.state.info };
        this.setState({ showSpinner: true });
        try {
            const { success, message } = await disbursalService.saveDisbursalv2(data);
            data.isSaved = success;

            if (success) {
                info.globalInfo = message;
            }
            else {
                errors.globalError = message;
            }
            this.setState({ data, errors, info, showSpinner: false });
        }
        catch (ex) {
            errors.globalError = ex.message;
            this.setState({ data, errors, info, showSpinner: false });
        }
    }

    handleTransactionDateChange = (value) => {
        const { data } = this.state;

        data.dateOfTransaction = value;
        this.setState({ data });
    };

    handlePopupClose = () => {
        this.setState({ showPopup: false });
    };

    handlePopupOk = async () => {
        this.setState({ showPopup: false });
        await this.addTranche();
        await this.handleDisbursalSubmit();
    };
}

export default DisbursalDetailsModel;