import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/partner";

const apiAdminEndpoint = apiUrl + "/ops/admin";

const apiNocEndpoint = apiUrl + "/ops/noc";

export async function getLoanApplicationsForBorrowingPartner(model, sortBy) {
    const { data } = await http.post(
        `${apiEndpoint}/getLoanApplicationsForBorrowingPartner`,
        {
            // userId: model.userId,
            fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
            toDate: moment(model.toDate).format("DD/MM/yyyy"),
            status: model.status,
            productId: model.productId,
            name: model.name,
            merchantCode: model.merchantCode,
            mobileNo: model.mobileNo,
            sortBy: sortBy,
            page: model.page
        }
    );
    return data;
}

export async function downloadLoanApplicationsForBorrowingPartner(model, sortBy) {
    const { data } = await http.post(
        `${apiEndpoint}/downloadLoanApplicationsForBorrowingPartner`,
        {
            // userId: model.userId,
            fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
            toDate: moment(model.toDate).format("DD/MM/yyyy"),
            status: model.status,
            productId: model.productId,
            name: model.name,
            merchantCode: model.merchantCode,
            mobileNo: model.mobileNo,
            sortBy: sortBy,
        }
    );
    return data;
}

export async function sendReview(loanId) {
    const { data } = await http.post(`${apiEndpoint}/sendReviewLink`, { loanId: loanId, });

    return data;
}

export async function sendLoanContract(loanId) {
    const { data } = await http.post(`${apiAdminEndpoint}/sendLoanContract`, {
        loanId: loanId,
    });
    return data;
}

export async function sendNewMandateOrUpdateBankListRequest(loanId, status) {
    const { data } = await http.post(`${apiAdminEndpoint}/sendEnachAndBankModificationLink`, {
        loanId: loanId,
        status: status
    });
    return data;
}
export async function sendEngagementAppLink(loanId, status) {
    const { data } = await http.post(`${apiAdminEndpoint}/sendEngagementAppLink`, {
        loanId: loanId,
        status: status
    });
    return data;
}


export async function updateLoanMeta(payload) {
    const { data } = await http.put(`${apiAdminEndpoint}/updateLoanMeta`, payload);
    return data;
}

export async function regenerateLoanContract(loanNo) {
    const { data } = await http.post(`${apiAdminEndpoint}/regenerateLoanContract`, {
        loanNo: loanNo
    });
    return data;
}

export async function sendNocNotification(loanNo) {
    const { data } = await http.post(`${apiNocEndpoint}/sendNocNotifications`, {
        loanNo: loanNo
    });
    return data;
}