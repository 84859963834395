import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import CollectionOutcomeDetailsModel from "../../models/collectionOutcomeDetailsModel/collectionOutcomeDetailsModel";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Col, Button, Tabs, Tab } from "react-bootstrap";
import DatePicker from "react-datepicker2";
import ReconciliationModal from '../reconcliationModal/reconcliationModal'
import ReactExport from "react-export-excel";

class ReconTable extends CollectionOutcomeDetailsModel {

  exportExcel = () => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let allActionsList = this.state.allActionsList;
    return (<ExcelFile
      hideElement={true}
      filename={`CollectionsOutCome_${moment(new Date()).format("DDMMYYYY")}`}
    >
      <ExcelSheet data={allActionsList} name="collectionQueue">
        <ExcelColumn
          label="Loan No"
          value="loanNo"
        ></ExcelColumn>

        <ExcelColumn
          label="Lender"
          value="lender"
          hideElement={true}
        ></ExcelColumn>

        <ExcelColumn
          label="City"
          value="city"
        ></ExcelColumn>

        <ExcelColumn
          label="State"
          value="state"
        ></ExcelColumn>

        <ExcelColumn
          label="Agent"
          value="calledBy"
          hideElement={true}
        ></ExcelColumn>

        <ExcelColumn
          label="Outstanding Amount"
          value="totalOutstanding"
        ></ExcelColumn>

        <ExcelColumn
          label="Outstadning EDI"
          value="pendingInstallments"
        ></ExcelColumn>

        <ExcelColumn
          label="Call Connected"
          value="outcome"
        ></ExcelColumn>

<ExcelColumn
          label="Call Outcome"
          value="outcomeResponse"
        ></ExcelColumn>

      </ExcelSheet>
    </ExcelFile>)
  }

  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      successMsg,
      errors,
      outcomeDropDown,
      actionsList
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>
          Collections Outcome ({this.state.partnerName})
        </h2>

        <div className="mt-3">

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="3" controlId="fromDate">
                <Form.Label>From Date <small className="text-success">(Max Date Range 7 days)</small></Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3">
                <Form.Label>Outcome</Form.Label>
                <Form.Control
                  as="select"
                  name="outcome"
                  value={data["outcome"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="all" value="">
                    All
                  </option>
                  {outcomeDropDown && outcomeDropDown.map((item) => (
                    <option key={item.title} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="2">
                <Form.Label> </Form.Label>
                <br />
                <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Go
                </Button>

                <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                  onClick={() => !this.state.showSpinner && this.downloadExcel()}
                >
                  Export
                </Button>
              </Form.Group>
              {this.state.download && this.exportExcel()}


            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}

          <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", borderLeft: "1px solid", textAlign: "center" }}><b>Loan No</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Lender</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>City</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>State</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Agent</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Outstanding Amount</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Outstadning EDI</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Call Connected</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Call Outcome</b></Cell>
              </Row>
              {actionsList && actionsList &&
                actionsList.map((action, i) => (
                  <Row key={action._id} className='cursor-pointer'>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{action.loanNo}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{action.lender || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{action.city || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{action.state || "-"}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{action.calledBy}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>₹{action.totalOutstanding}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{action.pendingInstallments}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{action.outcome}</Cell>
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{action.outcomeResponse}</Cell>
                  </Row>
                ))}
              {(!actionsList || !actionsList.length) && (
                <td colSpan={9} className='text-center pt-2 pb-2 border'>No Records Found</td>
              )}

            </StickyTable>
          </div >
          <div style={{ width: "100%", overflowX: "scroll" }}>
            {pageSize && <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              onPageChange={this.onPageChange}
              onPagePrevious={this.onPagePrevious}
              onPageNext={this.onPageNext}
            /> || ""}
          </div>
        </div>

        {this.state.showReconModal && <ReconciliationModal close={this.closeReconModal} utrNo={this.state.selectedUtrNo}></ReconciliationModal>}
      </React.Fragment>
    );
  }
}

export default ReconTable;
