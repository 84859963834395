import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/lendingpartner";

export async function getLenderAccountSummaryList() {
  const response = await http.get(
    `${apiEndpoint}/getLenderAccountSummaryList`
  );
  return response.data;
}

export async function getAccountSummaryJobStatus() {
  const response = await http.get(
    `${apiEndpoint}/getAccountSummaryJobStatus`
  );
  return response.data;
}

export async function refreshLenderAccountSummary(lenderId, accountNumber) {
  const response = await http.post(`${apiEndpoint}/refreshLenderAccountSummary`, {
    lenderId: lenderId,
    accountNumber: accountNumber
  });
  return response;
}

export async function accountSummaryRefresh() {
  const response = await http.post(`${apiEndpoint}/accountSummaryRefresh`, {});
  return response;
}