import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class PartnerConfigurationsModel extends InputDataForm {
  state = {
    data: {
      partnerType : "",
      name : ""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    loanStatusList: [],
    lastStepList : [],
    partners: [],
    evaluatorRole: "",
    tabs : [
      { title :"All" },
      { title :"Due Today" },
      { title :"Up Coming" },
      { title : "Called"},
      { title : "Not Connected" }
    ],
    activeTab : "All",
    allLoans : [],
    downloadExcel : false,
    partnerTypes : [
      {
        title : "Borrowing Partner",
        value : "Borrowing Partner"
      },
      {
        title : "Lending Partner",
        value : "Lending Partner"
      }
    ]
  };
  schema = {
    name : Joi.any(),
    partnerTypes : Joi.any(),
    partnerType : Joi.any(),
    page : Joi.any()
  };
  async loadDropdowns() {
    let { loanStatusList } = this.state;
    try {
      const {
        data: loanListResponse,
      } = await commonService.getRoleWiseDefinitionList("teleCallingLoanStatus");

      loanStatusList = loanListResponse.data;

      this.setState({
        loanStatusList,
      });
    } catch (ex) { }
  };
  
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    let { data } = this.state;

    const currentUser = auth.getCurrentUser();

    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    await this.getPartnersList()
  };
  async getPartnersList() {

    this.state.data.page = this.state.currentPage
    
    try {

      this.setState({ showSpinner: true });

      const { data: partnerList ,totalPages} = await commonService.getAllPartnerListV2(this.state.data);
      this.setState({ partners: partnerList , pageSize : totalPages ,showSpinner : false });
    }
    catch (ex) {
      this.setState({ partners: [] ,showSpinner : false});
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getPartnersList();
  };
  async handleSendReview(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendReview(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }
    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };
  async handleSendContract(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendLoanContract(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  resetPage =()=>{
    this.state.data.page = 1
    this.setState({ currentPage :1  ,pageSize :1 })
  }

  onPageNext =()=> {

    if(this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage : this.state.currentPage + 1 },()=>{
      this.getPartnersList()
    })
  }

  onPageChange =(page)=> {
    this.setState({ currentPage : page },()=>{
      this.getPartnersList()
    })
  }

  onPagePrevious =()=> {

    if(this.state.currentPage <= 1) 
      return

    this.setState({ currentPage : this.state.currentPage - 1 },()=>{
      this.getPartnersList()
    })
  }
}

export default PartnerConfigurationsModel;
