import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";

class ScrutinyListModel extends InputDataForm {
  constructor(props) {
    super(props);
  }
  state = {
    showSpinner: false,
  };
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    const user = auth.getCurrentUser();
    if (user.role !== "Admin") return (window.location = "/");
  }
}

export default ScrutinyListModel;
