import React from "react";
import { Form, Col, Button, Table, ButtonGroup, ToggleButton } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker2";
import ProductMasterModel from "../../models/productMaster/productMasterModel";

class ProductMasterForm extends ProductMasterModel {

    render() {

        return (
            <React.Fragment>
                <div style={{ textAlign: "right" }}>
                    <Button
                        className="ff-button"
                        type="button"
                        style={{ marginRight: 10 }}
                        onClick={this.goBack}
                    >
                        Back
                    </Button>
                    <Button
                        className="ff-button"
                        type="button"
                        style={{ marginRight: 10 }}
                    >
                        Edit
                    </Button>
                    <Button
                        className="ff-button"
                        type="button"
                        style={{ marginRight: 10 }}
                    >
                        Delete
                    </Button>
                    <Button
                        className="ff-button"
                        type="button"
                        style={{ marginRight: 10 }}
                    >
                        Clone
                    </Button>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    <div style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}>
                        <h6>Properties</h6>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="productName">
                                <Form.Label>Product Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="productName"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="productType">
                                <Form.Label>Product Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="productType"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="productTenure">
                                <Form.Label>Tenure</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="productTenure"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="startDate">
                                <Form.Label>Start Date</Form.Label>
                                <DatePicker
                                    name="startDate"
                                    id="startDate"
                                    max={moment()}
                                    inputFormat="DD/MM/YYYY"
                                    isGregorian={true}
                                    className="form-control"
                                    inputReadOnly={true}
                                    showTodayButton={false}
                                    timePicker={false}
                                    placeholder="Enter Start Date"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="endDate">
                                <Form.Label>End Date</Form.Label>
                                <DatePicker
                                    name="endDate"
                                    id="endDate"
                                    max={moment()}
                                    inputFormat="DD/MM/YYYY"
                                    isGregorian={true}
                                    className="form-control"
                                    inputReadOnly={true}
                                    showTodayButton={false}
                                    timePicker={false}
                                    placeholder="Enter End Date"
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="lender">
                                <Form.Label>Lender</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="lender"
                                >
                                    {" "}
                                    <option key="select" value="">
                                        Select Lender
                                    </option>

                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="minLoanAmount">
                                <Form.Label>Min Loan Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="minLoanAmount"
                                    autoComplete="off"
                                    style={{ textTransform: "uppercase" }}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="maxLoanAmount">
                                <Form.Label>Max Loan Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="maxLoanAmount"
                                    autoComplete="off"
                                    style={{ textTransform: "uppercase" }}
                                />
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="isAmortising">
                                <Form.Control
                                    as="select"
                                    name="isAmortising"
                                >
                                    {" "}
                                    <option key="AMORTISING" value="AMORTISING">
                                        Amortising
                                    </option>
                                    <option key="NON_AMORTISING" value="NON_AMORTISING">
                                        Non Amortising
                                    </option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="typeSelect">
                                <Form.Check
                                    type="radio"
                                    id="DL"
                                    label="Daily Loans"
                                />
                                <Form.Check
                                    type="radio"
                                    id="CL"
                                    label="Credit Line"
                                />
                                <Form.Check
                                    type="radio"
                                    id="TL"
                                    label="Term Loan"
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="processingFeesLabel">
                                <Form.Label>Processing Fees</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "space-between" }} controlId="processingFees">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="FLAT"
                                    label="Flat"
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PERCENTAGE"
                                    label="Percentage"
                                />
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="processingFeesValue"
                                    placeholder="Value"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 4, offset: 4 }} style={{ display: "flex", justifyContent: "space-between" }} controlId="processingFees">
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    id="SUBSCRIPTION"
                                    label="Subscription"
                                />
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="SubscriptionValue"
                                    placeholder="Tenure"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="interestFeesLabel">
                                <Form.Label>Interest Fees</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="8" style={{ display: "flex" }} controlId="interestFees">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PRE"
                                    label="Pre"
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="POST"
                                    label="Post"
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="subventionEnterprisePartnerLabel">
                                <Form.Label>Subvention - Enterprise Partner</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "space-between" }} controlId="subventionEnterprisePartner">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="FULL"
                                    label="Full"
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PARTLY"
                                    label="Partly"
                                />
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="subventionEnterprisePartnerValue"
                                    placeholder="Value"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 8, offset: 4 }} style={{ display: "flex", justifyContent: "space-between" }} controlId="borneUpto">
                                <Form.Label inline="true">Borne Upto</Form.Label>
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="borneUptoValue"
                                    placeholder="Days"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    id="RETURN"
                                    label="Return in case of Foreclosure"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="subventionLendingPartnerLabel">
                                <Form.Label>Subvention - Lending Partner</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "space-between" }} controlId="subventionLendingPartner">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="FULL"
                                    label="Full"
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PARTLY"
                                    label="Partly"
                                />
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="subventionLendingPartnerValue"
                                    placeholder="Value"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="subventionFundfina">
                                <Form.Label>Subvention - Fundfina</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "space-between" }} controlId="subventionFundfina">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="FULL"
                                    label="Full"
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PARTLY"
                                    label="Partly"
                                />
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="subventionFundfinaValue"
                                    placeholder="Value"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="collectionsLabel">
                                <Form.Label>Collections</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "space-between" }} controlId="collections">
                                <Form.Control
                                    as="select"
                                    name="collectionType"
                                    // value={data["partner"]}
                                    // onChange={this.handleChange}
                                    style={{ width: "50%" }}
                                >
                                    {" "}
                                    <option key="DAILY" value="DAILY">
                                        Daily
                                    </option>
                                    <option key="WEEKLY" value="WEEKLY">
                                        Weekly
                                    </option>
                                    <option key="MONTHLY" value="MONTHLY">
                                        Monthly
                                    </option>
                                    <option key="ADHOC" value="ADHOC">
                                        Adhoc
                                    </option>
                                </Form.Control>
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="adhocDaysValue"
                                    placeholder="Days in case of Ad hoc"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 8, offset: 4 }} style={{ display: "flex", justifyContent: "space-between" }} controlId="collections">
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    id="INTEREST"
                                    label="Interest"
                                />
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    id="PRINCIPAL"
                                    label="Principal"
                                />
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    id="PRINCIPAL_END"
                                    label="Principal at End of Tenure"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 8, offset: 4 }} style={{ display: "flex", justifyContent: "space-between" }} controlId="collections">
                                <Form.Control
                                    as="select"
                                    name="invoiceOrDrawDown"
                                    style={{ width: "30%" }}
                                >
                                    {" "}
                                    <option key="INVOICE" value="INVOICE">
                                        Invoice(I)
                                    </option>
                                    <option key="DRAW_DOWN" value="DRAW_DOWN">
                                        Draw Down(D)
                                    </option>
                                </Form.Control>
                                <Form.Control
                                    as="select"
                                    name="installmentsOrNextID"
                                    style={{ width: "30%" }}
                                >
                                    {" "}
                                    <option key="INSTALLMENTS" value="INSTALLMENTS">
                                        Installments
                                    </option>
                                    <option key="NEXT_I/D" value="NEXT_I/D">
                                        Next I/D
                                    </option>
                                </Form.Control>
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="NoOfInstallmentsValue"
                                    placeholder="# of Installments"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 4, offset: 8 }} style={{ display: "flex", justifyContent: "flex-end" }} controlId="collections">
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="maxNoOfDaysValue"
                                    placeholder="Max. # of Days"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="latePaymentsLabel">
                                <Form.Label>Late Payments</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "space-between" }} controlId="latePayments">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="FLAT"
                                    label="Flat"
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PERCENTAGE"
                                    label="Percentage"
                                />
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="latePaymentsValue"
                                    placeholder="Value"
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 4, offset: 4 }} style={{ display: "flex", justifyContent: "space-between" }} controlId="latePayments">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PER"
                                    label="Per Installment"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 4, offset: 4 }} style={{ display: "flex", justifyContent: "space-between" }} controlId="latePayments">
                                <Form.Check
                                    inline
                                    type="radio"
                                    id="PENDING_AMOUNT"
                                    label="Pending Amount"
                                />
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    id="PRINCIPAL"
                                    label="Principal"
                                />
                                <Form.Check
                                    inline
                                    type="checkbox"
                                    id="INTEREST"
                                    label="Interest"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md={{ span: 4, offset: 4 }} controlId="graceDays">
                                <Form.Label>Grace Days</Form.Label>
                                <Form.Control
                                    style={{ width: "auto" }}
                                    type="text"
                                    name="graceDaysValue"
                                    placeholder="Value"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <div style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="lateAfter">
                                <Form.Label>Late After</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="lateAfterDays">
                                <Form.Control
                                    type="text"
                                    name="lateAfterDays"
                                    placeholder="Days"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="delinquentAfter">
                                <Form.Label>Delinquent After</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="delinquentAfterDays">
                                <Form.Control
                                    type="text"
                                    name="delinquentAfterDays"
                                    placeholder="Days"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="defaultAfter">
                                <Form.Label>Default After</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="defaultAfterDays">
                                <Form.Control
                                    type="text"
                                    name="defaultAfterDays"
                                    placeholder="Days"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="4" controlId="writeOffAfter">
                                <Form.Label>WriteOff After</Form.Label>
                            </Form.Group>
                            <Form.Group as={Col} md="4" controlId="writeOffAfterDays">
                                <Form.Control
                                    type="text"
                                    name="writeOffAfterDays"
                                    placeholder="Days"
                                    // value={data["productName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Form>
                <Button
                    className="ff-button"
                    type="button"
                    // disabled={isSubmitted}
                    style={{ marginRight: 10 }}
                >
                    Save Draft
                </Button>
                <Button
                    className="ff-button"
                    type="button"
                    // disabled={isSubmitted}
                    style={{ marginRight: 10 }}
                >
                    Save
                </Button>
            </React.Fragment >
        )
    }

}

export default ProductMasterForm;