import * as kycFormService from "../../services/borrow/kycFormService";
import * as partnerKycFormService from "../../services/partner/partnerRegistrationFormService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import React from "react";
import InputForm from "./../../components/common/form";
import auth from "../../services/common/authService";
import * as analytics from "../../utils/analytics";

class PartnerKycFormModel extends InputForm {
  constructor(props) {
    super(props);
  }
  state = {
    data: {
      userId: "",
      entityName: "",
      panNo: "",
      add1: "",
      add2: "",
      add3: "",
      addressType: "Correspondence",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      udyogAadhaarNo: "",
      gstNo: "",
      accountNo: "",
      accountName: "",
      ifsc: "",
      accountType: "",
      kycStatus: "",
      kycRemarks: "",
    },
    files: {
      panFile: "",
      panFileStream: "",
      panFileLabel: "",
      udyogAadhaarFile: "",
      udyogAadhaarFileStream: "",
      udyogAadhaarFileLabel: "",
      gstFile: "",
      gstFileStream: "",
      gstFileLabel: ""
    },
    isValidCity: false,
    isValidState: false,
    isValidIfsc: false,
    isValidGstNumber: false,
    isValidPanNumber: false,
    isValidUdyogAadhaarNo: false,
    isBankSelected: false,
    showSpinner: false,
    showBankSearch: false,
    showIfscSearch: false,
    stateOptions: [],
    cityOptions: [],
    ifscOptions: [],
    bankOptions: [],
    branchOptions: [],
    bankList: [],
    errors: {},
    isDisabled: false,
    fileErrors: {},
    optional: {
      searchBankName: "",
      searchBranchName: "",
    },
    optionalErrors: {},
    partnerId: "",
    isGstDisabled: false
  };
  schema = {
    userId: Joi.string().required(),
    entityName: Joi.string().min(1).max(100).required().label("first name"),
    panNo: Joi.string().required().label("pan number"),
    add1: Joi.string().min(1).max(100).required().label("address line 1"),
    add2: Joi.string().max(100).allow("").required().label("address line 2"),
    add3: Joi.string().min(1).max(100).required().label("address line 3"),
    addressType: Joi.string().min(1).max(100).required().label("address type"),
    city: Joi.string().required().label("city"),
    state: Joi.string().required().label("state"),
    pincode: Joi.number().required().label("pincode"),
    country: Joi.string().required().label("country"),
    udyogAadhaarNo: Joi.string()
      .min(1)
      .max(100)
      .required()
      .allow("")
      .label("udyog aadhaar number"),
    gstNo: Joi.string().min(1).max(100).allow("").required().label("gst no"),
    accountNo: Joi.string().min(8).max(18).required().label("account number"),
    ifsc: Joi.string().required().label("ifsc code"),
    accountType: Joi.string().min(1).max(20).required().label("account type"),
    accountName: Joi.string().min(1).max(20).required().label("account holder name"),
    kycStatus: Joi.any(),
    kycRemarks: Joi.any(),
  };
  async mapDataToState(userData) {
    let data = { ...this.state.data };
    let files = { ...this.state.files };
    let { user } = userData;
    let {
      isDisabled,
      isValidCity,
      isValidIfsc,
      isValidState,
      isValidGstNumber,
      isValidPanNumber,
      isValidUdyogAadhaarNo,
    } = this.state;
    let {
      userDetails,
      addressDetails,
      bankDetails,
      gstDetails,
      panDocDetails,
      udyogAadhaar
    } = user;

    const partner = auth.getCurrentPartner();
    let isGstDisabled = false;
    let partnerId = "";

    if (partner)
      data.entityName = partner.partnerName;

    if (partner && partner._id)
      partnerId = partner._id;

    //User Details
    if (user._id) data.userId = user._id;
    // if (partnerDetails.partnerName)
    //   data.entityName = partnerDetails.partnerName;
    isDisabled = userDetails.kycStatus === "Approved" ? true : false;

    if (userDetails.kycStatus) data.kycStatus = userDetails.kycStatus;
    if (userDetails.kycRemarks) data.kycRemarks = userDetails.kycRemarks;

    //Address Details
    if (addressDetails[0] && addressDetails[0].add1)
      data.add1 = addressDetails[0].add1;
    if (addressDetails[0] && addressDetails[0].add2)
      data.add2 = addressDetails[0].add2;
    if (addressDetails[0] && addressDetails[0].add3)
      data.add3 = addressDetails[0].add3;
    if (addressDetails[0] && addressDetails[0].city) {
      data.city = addressDetails[0].city;
      isValidCity = true;
    }
    if (addressDetails[0] && addressDetails[0].state) {
      data.state = addressDetails[0].state;
      isValidState = true;
    }
    if (addressDetails[0] && addressDetails[0].pincode)
      data.pincode = addressDetails[0].pincode;
    if (user.panNo) {
      data.panNo = user.panNo;
      isValidPanNumber = true;
    }
    //Bank Details
    if (bankDetails[0] && bankDetails[0].accountNo)
      data.accountNo = bankDetails[0].accountNo;

    if (bankDetails[0] && bankDetails[0].accountName)
      data.accountName = bankDetails[0].accountName;

    if (bankDetails[0] && bankDetails[0].accountType)
      data.accountType = bankDetails[0].accountType;

    if (bankDetails[0] && bankDetails[0].ifsc) {
      data.ifsc = bankDetails[0].ifsc;
      isValidIfsc = true;
    }
    //GST Details
    if (gstDetails[0] && gstDetails[0].gstNo) {
      data.gstNo = gstDetails[0].gstNo;
      isValidGstNumber = true;
    }
    //POA Details
    if (udyogAadhaar && udyogAadhaar.aadhaarNo) {
      data.udyogAadhaarNo = udyogAadhaar.aadhaarNo;
      isValidUdyogAadhaarNo = true;
    }

    if (panDocDetails[0] && panDocDetails[0].fileName) {
      files.panFile = panDocDetails[0].fileName;
      files.panFileLabel = panDocDetails[0].fileName;

      let url = await this.getSignedURLToDownload(panDocDetails[0].filePath)
      files.panFileSrc = url;
    }

    if (gstDetails[0] && gstDetails[0].gstNo && gstDetails[0].docVerification && gstDetails[0].docVerification.isGstVerified)
      isGstDisabled = true;

    if (
      udyogAadhaar &&
      udyogAadhaar.docDetails[0] &&
      udyogAadhaar.docDetails[0].fileName
    ) {
      files.udyogAadhaarFile = udyogAadhaar.docDetails[0].fileName;
      files.udyogAadhaarFileLabel = udyogAadhaar.docDetails[0].fileName;

      let url = await this.getSignedURLToDownload(udyogAadhaar.docDetails[0].filePath)
      files.udyogAadhaarFileSrc = url;
    }

    if (
      gstDetails[0] &&
      gstDetails[0].docDetails[0] &&
      gstDetails[0].docDetails[0].fileName
    ) {
      files.gstFile = gstDetails[0].docDetails[0].fileName;
      files.gstFileLabel = gstDetails[0].docDetails[0].fileName;

      let url = await this.getSignedURLToDownload(gstDetails[0].docDetails[0].filePath)
      files.gstFileSrc = url;
    }


    this.setState({
      data,
      files,
      isDisabled,
      isValidCity,
      isValidIfsc,
      isValidState,
      isValidGstNumber,
      isValidPanNumber,
      isValidUdyogAadhaarNo,
      partnerId: partnerId,
      isGstDisabled
    });
  }
  async insertUpdatePartnerKyc() {
    let { data, errors, files, partnerId } = this.state;
    this.setState({ showSpinner: true });
    try {
      const {
        data: response,
      } = await partnerKycFormService.insertUpdatePartnerKyc(data, files, partnerId);
      if (response.success) {
        delete errors["globalError"];
        this.setState({ errors, showSpinner: false });
        window.location = "/myprofile";
      } else {
        errors.globalError = response.message;
        this.setState({ errors, showSpinner: false });
      }
    } catch (ex) {
      errors.globalError = ex.response.message;
      this.setState({ errors, showSpinner: false });
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    analytics.track();

    await this.getUserData();
  }
  async getUserData() {
    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location = "/login");

    const partnerUser = (currentUser.role === "Lending Partner" || currentUser.role === "Borrowing Partner") ? true : false;

    const currentPartner = auth.getCurrentPartner();

    let partnerId = null;

    if (currentPartner && currentPartner._id)
      partnerId = currentPartner._id;

    try {
      const { data: userDetails } = await kycFormService.getUserDetails(
        currentUser.userId, partnerUser, partnerId, null
      );
      if (userDetails.success) {
        await this.mapDataToState(userDetails.data);
      }
    } catch (ex) { }
  }
  handlePANChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });

    try {
      if (input.value.match(/(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/)) {
        const { data: response } = await kycFormService.isValidPanNumber(
          data.userId,
          input.value
        );

        if (!response.success) {
          errors["panNo"] = response.message;
          this.setState({
            errors,
            isValidPanNumber: false,
            showSpinner: false,
          });
        } else {
          delete errors["panNo"];
          this.setState({
            errors,
            isValidPanNumber: true,
            showSpinner: false,
          });
        }
      } else {
        errors["panNo"] = "Invalid PAN number";
        this.setState({
          errors,
          isValidPanNumber: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidPanNumber: false, showSpinner: false });
    }
  };
  handleUdyogAadhaarChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };

    if (input.value && input.value.length !== 12) {
      errors["udyogAadhaarNo"] = "Invalid Udyog Aadhaar No";
      this.setState({ errors, isValidUdyogAadhaarNo: false });
      return;
    } else {
      delete errors["udyogAadhaarNo"];
      this.setState({ errors });
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await kycFormService.isValidUdyogAadhaarNo(
        data.userId,
        input.value
      );

      if (!response.success) {
        errors["udyogAadhaarNo"] = response.message;
        this.setState({
          errors,
          isValidUdyogAadhaarNo: false,
          showSpinner: false,
        });
      } else {
        delete errors["udyogAadhaarNo"];
        this.setState({
          errors,
          isValidUdyogAadhaarNo: true,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidUdyogAadhaarNo: false, showSpinner: false });
    }
  };
  handleGSTChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    if (input.value === "") {
      delete errors["gstNo"];
      this.setState({ errors });
      return;
    }
    try {
      if (
        input.value.match(
          /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/
        )
      ) {
        this.setState({ showSpinner: true });

        const { data: response } = await kycFormService.isValidGstNumber(
          data.userId,
          input.value
        );

        if (!response.success) {
          errors["gstNo"] = response.message;
          this.setState({
            errors,
            isValidGstNumber: false,
            showSpinner: false,
          });
        } else {
          delete errors["gstNo"];
          this.setState({
            errors,
            isValidGstNumber: true,
            showSpinner: false,
          });
        }
      } else {
        errors["gstNo"] = "Invalid GST number";
        this.setState({
          errors,
          isValidGstNumber: false,
          showSpinner: false,
        });
      }
    } catch (ex) {
      this.setState({ isValidGstNumber: false, showSpinner: false });
    }
  };
  handleClose = () => {
    this.setState({ showBankSearch: false, showIfscSearch: false });
  };
  async handleCustomErrors(errors) {
    const data = { ...this.state.data };
    const files = { ...this.state.files };
    const {
      isValidState,
      isValidCity,
      isValidGstNumber,
      isValidPanNumber,
      isValidUdyogAadhaarNo,
      isValidIfsc,
    } = this.state;
    let fileErrors = { ...this.state.fileErrors };

    if (!files.panFile) fileErrors["panFile"] = "Please upload PAN document";
    else delete fileErrors["panFile"];

    // if (data.gstNo) {
    //   if (!files.gstFile) fileErrors["gstFile"] = "Please upload GST document";
    //   else delete fileErrors["gstFile"];
    // } else {
    //   if (files.gstFile) errors["gstNo"] = "Please provide GST number";
    // }

    if (data.gstNo) {
      // if (!files.gstFile) fileErrors["gstFile"] = "GST certificate required if GST number is entered";
      // else delete fileErrors["gstFile"];
      delete fileErrors["gstFile"];
    } else {
      if (files.gstFile) errors["gstNo"] = "GST number required if GST document is uploaded";
      else {
        delete fileErrors["gstFile"];
        delete errors["gstNo"];
      }
    }

    if (data.udyogAadhaarNo) {
      if (!files.udyogAadhaarFile)
        fileErrors["udyogAadhaarFile"] = "Please upload Udyog Aadhaar document";
      else delete fileErrors["udyogAadhaarFile"];
    } else {
      if (files.udyogAadhaarFile)
        errors["udyogAadhaarFile"] = "Please provide Udyog Aadhaar number";
    }

    if (data.pincode.length !== 6 || isNaN(data.pincode))
      errors["pincode"] = "Invalid pincode";
    else delete errors["pincode"];

    if (!errors["state"] && data.state) {
      if (!isValidState) errors["state"] = "Invalid State";
      else delete errors["state"];
    }
    if (!errors["city"] && data.city) {
      if (!errors["city"] && !isValidCity) errors["city"] = "Invalid City";
      else delete errors["city"];
    }
    if (!errors["ifsc"] && data.ifsc) {
      if (!isValidIfsc) errors["ifsc"] = "Invalid IFSC Code";
      else delete errors["ifsc"];
    }
    if (!errors["panNo"] && data.panNo) {
      if (!errors["panNo"] && !isValidPanNumber)
        errors["panNo"] = "Invalid PAN Number/PAN already exists";
      else delete errors["panNo"];
    }
    if (!errors["udyogAadhaarNo"] && data.udyogAadhaarNo) {
      if (!errors["udyogAadhaarNo"] && !isValidUdyogAadhaarNo)
        errors["udyogAadhaarNo"] =
          "Invalid Udyog Aadhaar Number/Udyog Aadhaar already exists";
      else delete errors["udyogAadhaarNo"];
    }
    if (!errors["gstNo"] && data.gstNo) {
      if (!errors["gstNo"] && !isValidGstNumber)
        errors["gstNo"] = "Invalid GST Number/GST already exists";
      else delete errors["gstNo"];
    }

    this.setState({ errors, fileErrors });

    return errors;
  }
  doSubmit = async () => {
    await this.insertUpdatePartnerKyc();
  };
  onIfscChange = async (event, { newValue }) => {
    let errors = { ...this.state.errors };
    let data = { ...this.state.data };

    data.ifsc = newValue.trim().toUpperCase();
    this.setState({ data, isValidIfsc: false });

    if (newValue.length !== 11) {
      errors["ifsc"] = "Invalid Ifsc Code";
      this.setState({ errors, isValidIfsc: false });
      return;
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await commonService.isValidIfscCode(newValue);

      if (response.success) {
        delete errors["ifsc"];
        this.setState({
          isValidIfsc: response.success,
          errors,
          showSpinner: false,
        });
      } else {
        errors["ifsc"] = "Invalid Ifsc Code";
        this.setState({
          isValidIfsc: response.success,
          errors,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors["ifsc"] = "Invalid Ifsc Code";
      this.setState({
        isValidIfsc: false,
        errors,
        showSpinner: false,
      });
    }
  };
  onIfscSuggestionsRequested = async ({ value }) => {
    try {
      const { data: response } = await commonService.getIfscCode(value);
      this.setState({
        ifscOptions: response.data,
      });
    } catch (ex) { }
  };
  onBankChange = async (event, { newValue }) => {
    const optional = { ...this.state.optional };
    optional["searchBankName"] = newValue.toUpperCase();
    this.setState({ optional });
  };
  onBankSuggestionsRequested = async ({ value }) => {
    const inputValue = value.trim().toUpperCase();
    try {
      const { data: response } = await commonService.getBankList(inputValue);
      this.setState({
        bankOptions: response.data,
      });
    } catch (ex) { }
  };
  onBranchChange = async (event, { newValue }) => {
    const optional = { ...this.state.optional };
    optional["searchBranchName"] = newValue.trim().toUpperCase();
    this.setState({ optional });
  };
  onBranchSuggestionsRequested = async ({ value }) => {
    const inputValue = value.trim();
    const optional = { ...this.state.optional };
    try {
      const { data: response } = await commonService.getBranchList(
        optional.searchBankName,
        inputValue
      );
      this.setState({
        branchOptions: response.data,
      });
    } catch (ex) { }
  };
  showBankSearch = async () => {
    this.setState({
      showBankSearch: true,
      showIfscSearch: false,
      isBankSelected: false,
    });
  };
  onBankSelect = (ifsc) => {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };
    data.ifsc = ifsc;
    delete errors["ifsc"];
    this.setState({ errors, data, isValidIfsc: true, isBankSelected: true });
  };
  getIfscList = async () => {
    const optional = { ...this.state.optional };
    const optionalErrors = { ...this.state.optionalErrors };
    const bankList = [];

    if (!optional.searchBankName)
      optionalErrors["searchBankName"] = "Enter bank name";
    else delete optionalErrors["searchBankName"];

    if (!optional.searchBranchName)
      optionalErrors["searchBranchName"] = "Enter branch name";
    else delete optionalErrors["searchBranchName"];

    this.setState({ optionalErrors });

    if (!optional.searchBankName || !optional.searchBranchName) return;

    try {
      const { data: response } = await commonService.getIfscList(
        optional.searchBankName,
        optional.searchBranchName
      );

      for (const item of response.data) {
        let obj = {
          ifsc: item.ifsc,
          bank: item.bank,
          branch: item.branch,
        };
        bankList.push(obj);
      }
      this.setState({ bankList, showBankSearch: false, showIfscSearch: true });
    } catch (ex) { }
  };
  onStateChange = async (event, { newValue }) => {
    let errors = { ...this.state.errors };
    let data = { ...this.state.data };

    data.state = newValue.trim().toUpperCase();
    data.city = "";
    this.setState({ data, showSpinner: true });

    try {
      const { data: response } = await commonService.isValidState(newValue);

      if (response.success) {
        delete errors["state"];
        this.setState({
          isValidState: response.success,
          errors,
          showSpinner: false,
        });
      } else {
        errors["state"] = response.message;
        this.setState({
          isValidState: response.success,
          isValidCity: false,
          showSpinner: false,
          errors,
        });
      }
    } catch (ex) {
      this.setState({
        isValidState: false,
        isValidCity: false,
        showSpinner: false,
      });
    }
  };
  onStateSuggestionsRequested = async ({ value }) => {
    let state = value.trim();

    try {
      const { data: response } = await commonService.getStates(state);

      this.setState({
        stateOptions: response.data,
      });
    } catch (ex) { }
  };
  onCityChange = async (event, { newValue }) => {
    let errors = { ...this.state.errors };
    let data = { ...this.state.data };
    let city = newValue.trim().toUpperCase();

    data.city = newValue.trim();

    if (!this.state.isValidState) {
      errors["city"] = "Please enter a valid state";
      this.setState({ data, errors });
      return;
    } else {
      delete errors["city"];
      this.setState({ data, errors });
    }

    this.setState({ showSpinner: true });

    try {
      const { data: response } = await commonService.isValidCity(
        data.state,
        city
      );

      if (response.success) {
        delete errors["city"];
        this.setState({
          isValidCity: response.success,
          errors,
          showSpinner: false,
        });
      } else {
        errors["city"] = city ? "City doesn't belong to state" : "Please enter city";
        this.setState({
          isValidCity: response.success,
          errors,
          showSpinner: false,
        });
      }
    } catch (ex) {
      errors["city"] = "City doesn't belong to state";
      this.setState({
        isValidIfsc: false,
        errors,
        showSpinner: false,
      });
    }
  };
  onCitySuggestionsRequested = async ({ value }) => {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };

    if (!this.state.isValidState) {
      errors["city"] = "Please select valid state before city";
      this.setState({ errors });
      return;
    } else {
      delete errors["city"];
      this.setState({ errors });
    }

    try {
      const { data: response } = await commonService.getCities(
        data.state,
        value
      );

      this.setState({
        cityOptions: response.data,
      });
    } catch (ex) { }
  };
  onStateSuggestionsClearRequested = () => {
    this.setState({
      stateOptions: [],
    });
  };
  onCitySuggestionsClearRequested = () => {
    this.setState({
      cityOptions: [],
    });
  };
  onIfscSuggestionsClearRequested = () => {
    this.setState({
      ifscOptions: [],
    });
  };
  onBankSuggestionsClearRequested = () => {
    this.setState({
      bankOptions: [],
    });
  };
  onBranchSuggestionsClearRequested = () => {
    this.setState({
      branchOptions: [],
    });
  };
  getSuggestionValue = (suggestion) => suggestion;
  renderSuggestion = (suggestion) => <div>{suggestion}</div>;
}

export default PartnerKycFormModel;
