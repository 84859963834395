import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi, { errors, schema } from "joi-browser";
import _ from "lodash";
import * as trancheService from "../../services/tranche/trancheService";
import * as commonService from  '../../services/common/commonService'

class TrancheMISModel extends InputDataForm {
    state = {
        data: {
            lendingPartner: "",
            fromDate: momentJalaali().subtract(10, "days"),
            toDate: momentJalaali(),
            status: "Open",
        },
        showSpinner: false,
        errors: {},
        info: {},
        lendingPartnerList: [],
        list: [],
        tranches: [],
        currentPage: 1,
        pageSize: 12,
        role: "",
        lendingPartnerName: ""
    };
    schema = {
        lendingPartner: Joi.any().required(),
        status: Joi.any(),
        fromDate: Joi.any().required(),
        toDate: Joi.any().required()
    };

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");
        //console.log("auth", auth);
        let { role, lendingPartnerName } = { ...this.state };

        const currentUser = auth.getCurrentUser();

        if (currentUser.role === "Admin" || currentUser.role === "Lending Partner") {
            role = currentUser.role;
            lendingPartnerName = currentUser.name;
            await this.setState({ role, lendingPartnerName });
            await this.getLenderList();
            await this.getTrancheMIS();
        }
        else {
            return (window.location = "/");
        }
    };

    async getTrancheMIS() {
        const data = { ...this.state.data };
        let list = { ...this.state.list };
        let tranches = { ...this.state.tranches };
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        try {
            errors["globalError"] = null;
            const model = {
                partnerId: data.lendingPartner,
                status: data.status,
                fromDate: data.fromDate,
                toDate: data.toDate
            }
            const trancheList = await trancheService.getTrancheMIS(model);
            if (trancheList) {
                if (trancheList.success) {
                    list = trancheList.data;
                    tranches = trancheList.tranches;
                    this.setState({ list, tranches, errors, successMsg: trancheList.message, showSpinner: false });
                }
                else {
                    list = [];
                    errors["globalError"] = trancheList.message;
                    this.setState({ list, tranches, errors, successMsg: null, showSpinner: false });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ list, tranches, errors, successMsg: null, showSpinner: false });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ list, tranches, errors, successMsg: null, showSpinner: false });
        }
    }

    async getLenderList() {
        let lendingPartnerList = { ...this.state.lendingPartnerList };
        let { role, lendingPartnerName, errors } = { ...this.state };

        this.setState({ showSpinner: true });
        try {
            errors["globalError"] = null;
            // const partnerList = await trancheService.getPartnerList("Lending Partner");
            const partnerList = await commonService.getLendingParterList();

            if (partnerList) {
                if (partnerList.success) {
                    lendingPartnerList = partnerList.data;
                    if (role === "Lending Partner") {
                        const partner = lendingPartnerList.filter(x => x.partnerName == lendingPartnerName);

                        if (partner) {
                            let data = { ...this.state.data };
                            data.lendingPartner = partner[0]._id;
                            this.setState({ data });
                        }
                    }
                    this.setState({ lendingPartnerList, errors, successMsg: partnerList.message, showSpinner: false });
                } else {
                    lendingPartnerList = [];
                    errors["globalError"] = partnerList.message;
                    this.setState({ lendingPartnerList, errors, successMsg: null, showSpinner: false });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null, showSpinner: false });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null, showSpinner: false });
        }
    }

    async handleCustomErrors(errors) {
        console.log(errors);
        return errors;
    };

    async doSubmit() {
        await this.getTrancheMIS();
    };
}

export default TrancheMISModel;