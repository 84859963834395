import React, { Component } from "react";
import auth from "../../services/common/authService";
import { Form, Col, Nav, Row, Button } from "react-bootstrap";
import SpinnerComponent from "./../../components/common/spinner";
import _ from "lodash";
import CreditLineSummaryModel from './../../models/partner/creditLineSummaryModel';
import NumberFormat from 'react-number-format';

class CreditLineSummary extends CreditLineSummaryModel {
    render() {
        const {
            data,
            errors,
            showSpinner,
            isSubmitted,
            isSigned,
            info,
            isRegenerateLink,
            isRegenerateText,
            counter,
            isValidForSigning,
            approvedCreditLimit,
            availableCreditLimit,
            successMessage,
            errorMessage
        } = this.state;

        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <Form
                    onSubmit={this.handleSubmit}
                    autoComplete="off"
                >
                    <Form.Row>
                        <h4>Loan Consent</h4>{" "}
                    </Form.Row>
                    {/* <Form.Row>
                        <Form.Label column sm="4">
                            Approved Credit Limit:{" "}
                            <span style={{ color: "black" }}>{approvedCreditLimit && <NumberFormat value={approvedCreditLimit} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</span>
                        </Form.Label>

                        <Form.Label column sm="4">
                        </Form.Label>
                        <Form.Label column sm="4">
                            Available Credit Limit:{" "}
                            <span style={{ color: "black" }}>
                                {availableCreditLimit && <NumberFormat value={availableCreditLimit} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}
                            </span>
                        </Form.Label>
                    </Form.Row> */}
                    <Form.Row>
                        <Form.Group
                            as={Col}
                            md="3"
                            controlId="date"
                        >
                            <Form.Label>Date*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the date"
                                name="date"
                                value={data["date"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                disabled={true}
                            />
                            {errors["date"] && (
                                <div className="alert alert-danger">{errors["date"]}</div>
                            )}
                        </Form.Group>{" "}
                        <Form.Group as={Col} md="3" controlId="invoiceId">
                            <Form.Label>Invoice Id*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your Invoice Id"
                                name="invoiceId"
                                value={data["invoiceId"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                disabled={true}
                            />
                            {errors["invoiceId"] && (
                                <div className="alert alert-danger">{errors["invoiceId"]}</div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="amount">
                            <Form.Label>Invoice Amount*</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter Invoice amount"
                                name="amount"
                                value={data["amount"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                disabled={true}
                            />
                            {errors["amount"] && (
                                <div className="alert alert-danger">{errors["amount"]}</div>
                            )}
                        </Form.Group>
                        {data["agentName"] && (
                            <Form.Group as={Col} md="3" controlId="agentName">
                                <Form.Label>Delivery Agent Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter delivery agent name"
                                    name="agentName"
                                    value={data["agentName"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    style={{ textTransform: "uppercase" }}
                                    disabled={true}
                                />
                                {errors["agentName"] && (
                                    <div className="alert alert-danger">{errors["agentName"]}</div>
                                )}
                            </Form.Group>)}
                        {data["agentNumber"] && (
                            <Form.Group as={Col} md="3" controlId="agentNumber">
                                <Form.Label>Delivery Agent Mobile Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    pattern="[0-9]*"
                                    placeholder="Enter delivery mobile number"
                                    name="agentNumber"
                                    value={data["agentNumber"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="10"
                                    disabled={true}
                                />
                                {errors["agentNumber"] && (
                                    <div className="alert alert-danger">{errors["agentNumber"]}</div>
                                )}
                            </Form.Group>)}
                        <Form.Group as={Col} md="3" controlId="repaymentDate">
                            <Form.Label>Repayment Date</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter repayment date"
                                name="repaymentDate"
                                value={data["repaymentDate"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                                disabled={true}
                            />
                            {errors["repaymentDate"] && (
                                <div className="alert alert-danger">{errors["repaymentDate"]}</div>
                            )}
                        </Form.Group>
                    </Form.Row>
                    {!isSigned && isValidForSigning && (
                        <React.Fragment>
                            <Form.Row>
                                <Form.Group as={Col} md="3" controlId="otp">
                                    <Form.Label>Enter OTP*</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter OTP"
                                        name="otp"
                                        value={data["otp"]}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                        maxLength="6"
                                        autoFocus={true}
                                    />{" "}
                                    {info["otp"] && (
                                        <Form.Text className="text-muted" style={{ color: "green" }}>
                                            {info["otp"]}
                                        </Form.Text>
                                    )}
                                    {isRegenerateLink && (
                                        <Nav.Item id="regenerateOTP">
                                            <Nav.Link
                                                className="underline"
                                                onClick={this.sendOtp}
                                                style={{
                                                    padding: 0,
                                                    float: "right",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                Re-generate OTP
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}
                                    {errors["otp"] && (
                                        <div className="alert alert-danger">{errors["otp"]}</div>
                                    )}{" "}
                                    {isRegenerateText && (
                                        <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                                    )}
                                </Form.Group>
                            </Form.Row>{" "}

                            <Button className="ff-button" type="submit" disabled={isSubmitted}>
                                Confirm
                            </Button>
                            <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
                                {errors["globalError"] && (
                                    <div className="alert alert-danger">
                                        {errors["globalError"]}
                                    </div>
                                )}
                            </Form.Group>
                        </React.Fragment>
                    )}
                </Form>
                {successMessage && (
                    <div className="alert alert-success">{successMessage}</div>
                )}
                {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                )}
            </React.Fragment>
        );
    }
}
export default CreditLineSummary;
