import React from "react";
import { Form, Col, Button, } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import ExcelUploadModel from './../../models/upload/excelUploadModel';


class ExcelUpload extends ExcelUploadModel {
    refreshPage = () => {
        window.location.reload();
    }

    render() {
        const { data, errors, role, borrowingPartnerList, lendingPartnerList, showSpinner, successMsg, partnerType } = this.state;

        return (<React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>Excel Upload</h2>
            {" "}
            <br />
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId="uploadType">
                        <Form.Label>Upload Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="uploadType"
                            value={data["uploadType"]}
                            onChange={this.handleUploadTypeChange}
                        >
                            {" "}
                            <option key="select" value="">
                                ---Select---
                            </option>
                            {<option key="Users/Merchants Data Old" value="Users_Merchants Data">
                                Users/Merchants Data Old
                            </option>}
                            <option key="Users/Merchants Data" value="Users_Merchants_Data_DI">
                                Users/Merchants Data
                            </option>
                            {role === "Admin" && (
                                <React.Fragment>
                                    <option key="Lending Partner Settelement" value="Lender_Settelement">
                                        Settelement
                                    </option>
                                    <option key="Crif Score" value="Crif_Score">
                                        Crif Score
                                    </option>
                                    <option key="Received Emis" value="Upload_Received_Emis">
                                        Received Emis
                                    </option>
                                    <option key="Merchants Mobile" value="Upload_Merchants_Mobile">
                                        Merchants Mobile
                                    </option>
                                    <option key="Offers" value="Upload_Offers">
                                        Offers
                                    </option>
                                    <option key="Loan Creations" value="Loans_Creations">
                                        Loan Creations
                                    </option>
                                    <option key="Discount Transfer" value="Dis_Transfer">
                                        Discount Transfer
                                    </option>

                                    <option key="Payment Recon" value="Payment_Recon">
                                        Payment Recon
                                    </option>
                                </React.Fragment>)
                            }
                        </Form.Control>
                        {errors["uploadType"] && (
                            <div className="alert alert-danger">
                                {errors["uploadType"]}
                            </div>
                        )}
                    </Form.Group>
                    {(partnerType === "Borrowing Partner") &&
                        <Form.Group as={Col} md="4" controlId="borrowingPartner">
                            <Form.Label>Partner</Form.Label>
                            <Form.Control
                                as="select"
                                name="partnerId"
                                value={data["partnerId"]}
                                onChange={this.handleChange}
                                disabled={(role === "Admin" && (!data["uploadType"] || data["uploadType"] != "Payment_Recon")) ? "" : "disabled"}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {borrowingPartnerList.map((item, i) => (
                                    <option key={i} value={item._id}>
                                        {item.displayName}
                                    </option>
                                ))}
                            </Form.Control>
                            {errors["borrowingPartner"] && (
                                <div className="alert alert-danger">
                                    {errors["borrowingPartner"]}
                                </div>
                            )}
                        </Form.Group>
                    }
                    {(partnerType === "Lending Partner") &&
                        <Form.Group as={Col} md="4" controlId="LendingPartner">
                            <Form.Label>Partner</Form.Label>
                            <Form.Control
                                as="select"
                                name="partnerId"
                                value={data["partnerId"]}
                                onChange={this.handleChange}
                                disabled={(role === "Admin") ? "" : "disabled"}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {lendingPartnerList.map((item, i) => (
                                    <option key={i} value={item._id}>
                                        {item.displayName}
                                    </option>
                                ))}
                            </Form.Control>
                            {errors["lendingPartner"] && (
                                <div className="alert alert-danger">
                                    {errors["lendingPartner"]}
                                </div>
                            )}
                        </Form.Group>
                    }
                    <Form.Group as={Col} md="4" controlId="uploadFile">
                        <Form.Group>
                            <Form.Label>Upload Excel File</Form.Label>
                            <Form.File
                                label={data.fileName}
                                onChange={this.onExcelFileChange}
                                id="uploadFile"
                                name="uploadFile"
                                accept=".xlsx,.xls"
                                key={data.fileName || ''}
                                custom
                            />{" "}
                            {errors["uploadFile"] && (
                                <div className="alert alert-danger">
                                    {errors["uploadFile"]}
                                </div>
                            )}
                            {data["uploadType"] == "Payment_Recon" ?
                                <div className="row">
                                    <div className="col-9">
                                        <strong className="text-danger"><small>**Date Format should be MM/DD/YYYY** </small></strong>
                                    </div>
                                    <div className="col-3">
                                        <small className="text-primary cursor-pointer">
                                            <a className="text-primary" href="https://uat-recon.s3.ap-south-1.amazonaws.com/sample_payment_recon_file.xlsx" download>Sample <i className="fa fa-download fa-sm"></i></a>
                                        </small>
                                    </div>
                                </div>
                                : <></>}
                        </Form.Group>
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                        <Button
                            className="ff-button"
                            type="submit"
                            style={{ marginTop: 30, marginLeft: 10 }}
                        >
                            Upload
                        </Button>
                        <Button
                            className="ff-button"
                            type="button"
                            style={{ marginTop: 30, marginLeft: 10 }}
                            onClick={this.download}
                            disabled={data["uploadType"] == "Payment_Recon"}
                        >
                            Download
                        </Button>
                        <Button
                            className="ff-button"
                            type="button"
                            style={{ marginTop: 30, marginLeft: 10 }}
                            onClick={this.refreshPage}
                        >
                            Clear
                        </Button>

                    </Form.Group>
                </Form.Row>
            </Form>
            <br />
            {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
            {successMsg && <div className="alert alert-success">{successMsg}</div>}
            <br />
        </React.Fragment>);
    }
}

export default ExcelUpload;
