import React, { Component } from "react";
import InputDataForm from "../../components/common/form";
import _ from "lodash";
import MerchantAuthenticate from "./merchantAuthenticate";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import leadInfoService from "../../services/partner/leadInfoService";
import { browserName, browserVersion, fullBrowserVersion, osName, osVersion, mobileVendor, mobileModel } from 'mobile-device-detect';
import { environment } from "../../config.json";

class LeadInfo extends InputDataForm {
  state = {
    data: {
      loanId: "",
      otp: "",
      loanStatus: "",
      kycStatus: "",
      isDraft: false,
      isWhatsApp: "checked"
    },
    properties: {
      userId: "",
      token: "",
      amount: "",
      trxnId: "",
      logo: "",
      partnerId: ""
    },
    errors: {},
    info: {},
    redirect: false,
  };

  async componentDidMount() {
    let merchantCode = this.props.match.params.merchantCode;
    let token = decodeURIComponent(this.props.match.params.token);
    let amount = this.props.match.params.loanAmount;
    let trxnId = this.props.match.params.trxnId;
    let hideLogo = this.props.match.params.ffLogo;
    let properties = { ...this.state.properties };

    properties.merchantCode = merchantCode;
    properties.token = token;
    properties.amount = amount;
    properties.trxnId = trxnId;
    properties.logo = hideLogo;

    const query = new URLSearchParams(this.props.location.search);
    let signature = query.get("signature");
    properties.signature = signature

    // if (!hideLogo)
    //   hideLogo = "N";

    try {
      // this.errorHandling({ merchantCode, token, amount, trxnId })
      // return;
      const { partner } = await auth.getPartnerCodeFromToken(token);

      if (!partner) {
        this.errorHandling({ merchantCode, token, amount, trxnId })
        return;
      }

      properties.partnerId = partner.data._id.toString();

      const userData = await this.getUserData(merchantCode, token, amount, trxnId, properties.partnerId)

      if (!userData) {
        this.errorHandling({ merchantCode, token, amount, trxnId })
        return;
      }

      let response = await auth.loanOfferSTPLogin({
        merchantCode,
        amount,
        offerId: trxnId,
        shortAuthKey: token,
        signature
      });

      if (response.user && response.partner)
        auth.setLogin(response.user, response.partner, "RedirectAuthLink");

      await auth.getMenu();

      sessionStorage.setItem("lead", auth.encrypt(JSON.stringify({
        merchantCode,
        token,
        amount,
        trxnId,
        partnerId: properties.partnerId
      })));

      let data = userData.data;

      if (response) {
        if (data.isDraft || (data.loanStatus === "Initiated" || data.loanStatus === "Draft") || (data.kycStatus === "Review" && (data.loanStatus === "Under Review" || data.loanStatus === "Loan Contract Accepted" || data.loanStatus === "Recourse Pending")))
          window.location.href = `/applyLoan/${data.loanId}`;
        else if (data.loanStatus == "Approved By Admin")
          window.location.href = `/loanOffer/${data.loanId}`;
        else window.location.href = `/borrow`;
      }
    } catch (error) {
      this.errorHandling({ merchantCode, token, amount, trxnId })
    }

  }

  async getUserData(merchantCode, token, amount, trxnId, partnerId) {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };

    this.setState({ showSpinner: true });

    if (!merchantCode || !token || !amount || !trxnId)
      return null;

    try {
      let response = await leadInfoService.getUserDetails(
        merchantCode,
        trxnId,
        amount,
        token,
        true
      );

      await leadInfoService.saveDeviceInfo(
        partnerId,
        merchantCode,
        browserName,
        browserVersion,
        fullBrowserVersion,
        osName,
        osVersion,
        mobileVendor,
        mobileModel
      );

      if (response.success) {
        data.loanId = response.data.loanId;
        data.loanStatus = response.data.loanStatus;
        data.isDraft = response.data.isDraft;
        data.kycStatus = response.data.kycStatus;

        const maskedMobileNo = response.contactDetails.maskedMobileNo;

        this.setState({ data, showSpinner: false, maskedMobileNo });
      } else {
        errors["globalError"] = response.message;
        this.setState({
          errors,
          showSpinner: false,
        });
      }

      return response
    } catch (ex) {
      return null
    }

  }

  errorHandling = (payload) => {
    let { merchantCode, token, amount, trxnId } = payload;

    if (!merchantCode || !token || !amount || !trxnId) {
      window.location.href = "/";
      return;
    }

    window.location.href = `/leadInfo/${merchantCode}/${trxnId}/${amount}/${token}`;
  }

  render() {
    const { properties, redirect } = this.state;
    return (
      <div>Loading...</div>
      // redirect && (
      //   <Redirect
      //     to={{
      //       pathname: `/${properties.partnerId}/merchantAuthenticate`,
      //       state: { userData: properties },
      //     }}
      //   />
      // )
    );
  }
}
export default LeadInfo;
