import React, { Component } from "react";
import InputDataForm from "../../components/common/form";
import _ from "lodash";
import MerchantAuthenticate from "./merchantAuthenticate";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";

class LeadInfo extends InputDataForm {
  state = {
    properties: {
      userId: "",
      token: "",
      amount: "",
      trxnId: "",
      logo: "",
      partnerId: ""
    },
    errors: {},
    info: {},
    redirect: false,
  };

  async componentDidMount() {
    let merchantCode = this.props.match.params.merchantCode;
    let token = decodeURIComponent(this.props.match.params.token);
    let amount = this.props.match.params.loanAmount;
    let trxnId = this.props.match.params.trxnId;
    let hideLogo = this.props.match.params.ffLogo;
    let properties = { ...this.state.properties };

    properties.merchantCode = merchantCode;
    properties.token = token;
    properties.amount = amount;
    properties.trxnId = trxnId;
    properties.logo = hideLogo;

    if (!hideLogo)
      hideLogo = "N";

    const { partner } = await auth.getPartnerCodeFromToken(token);

    if (partner) {
      properties.partnerId = partner.data._id.toString();
      this.setState({ properties, redirect: true });
    }

  }
  render() {
    const { properties, redirect } = this.state;
    return (
      redirect && (
        <Redirect
          to={{
            pathname: `/${properties.partnerId}/merchantAuthenticate`,
            state: { userData: properties },
          }}
        />
      )
    );
  }
}
export default LeadInfo;
