import InputDataForm from "../../components/common/form";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import * as updateEmailMobileService from "../../services/profile/updateAlternateEmailService";
import auth from "../../services/common/authService";
import Joi from "joi-browser";
import momentJalaali from "moment-jalaali";
import * as analytics from "../../utils/analytics";
import axios from "axios";
import moment from "moment";

class LoanApplicationsModel extends InputDataForm {
    state = {
        data: {
            fromDate: momentJalaali().startOf('month'),
            toDate: momentJalaali(),
            status: "",
            productId: "",
            name: "",
            merchantCode: "",
            mobileNo: "",
            userId: ""
        },
        showSpinner: false,
        errors: {},
        userRole: "",
        list: [],
        repaymentList: [],
        showRepaymentModal: false,
        loanNo: "",
        summary: {},
        sortBy: "-updatedOn",
        isDisabled: false,
        currentPage: 1,
        pageSize: 1,
        showErrorModal: false,
        errorHeader: "",
        errorMessage: ""
    };
    schema = {
        fromDate: Joi.any(),
        toDate: Joi.any(),
        status: Joi.any(),
        productId: Joi.any(),
        name: Joi.any(),
        merchantCode: Joi.any(),
        mobileNo: Joi.any(),
        userId: Joi.any(),
    };

    async getLoanApplications(sortBy, page) {
        const data = { ...this.state.data };

        let dateDiff = moment(data.toDate).diff(data.fromDate, "days")

        if (dateDiff > 30) {
            let errors = this.state.errors
            errors.globalError = "The date difference should be less than or equal to 30 days"
            this.setState({ errors })
            return
        }

        data.page = page || this.state.currentPage
        this.setState({ showSpinner: true, currentPage: data.page, errors: {} });
        try {
            const { data: loanList, totalPages } = await loanApplicationServices.getLoanApplicationsForBorrowingPartner(data, sortBy);

            this.setState({ data, showSpinner: false, list: loanList, pageSize: totalPages });
        } catch (ex) {
            this.setState({ data, showSpinner: false, list: [] });
        }
    };

    async onClickExcelExport() {
        const data = { ...this.state.data };

        let dateDiff = moment(data.toDate).diff(data.fromDate, "days")

        if (dateDiff > 30) {
            let errors = this.state.errors
            errors.globalError = "The date difference should be less than or equal to 30 days"
            this.setState({ errors })
            return
        }

        this.setState({ showSpinner: true, errors: {} });
        try {
            let {
                data: excelList
            } = await loanApplicationServices.downloadLoanApplicationsForBorrowingPartner(data);

            this.setState({ data, showSpinner: false, excelList, downloadExcel: true }, () => {
                this.setState({ downloadExcel: false })
            });
        } catch (ex) {
            this.setState({ data, showSpinner: false, excelList: [], });
        }
    }


    async componentDidMount() {
        const currentUser = auth.getCurrentUser();
        if (!currentUser) return (window.location.href = "/login");
        if (currentUser.role !== "Borrowing Partner") return (window.location.href = "/login");
        analytics.track();

        let { data, sortBy } = this.state;
        data.userId = currentUser.userId;

        this.setState({ userRole: currentUser.role, data });

        await this.getLoanApplications(sortBy);
    };

    async handleSendReview(loanId) {
        this.setState({ showSpinner: true });
        const errors = { ...this.state.errors };
        errors["globalError"] = null;
        try {
            let response = await loanApplicationServices.sendReview(loanId);

            if (response.success) {
                this.setState({ showSpinner: false, successMsg: response.message, errors });
            } else {
                errors["globalError"] = response.message;
                this.setState({ showSpinner: false, errors, successMsg: null });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ showSpinner: false, errors, successMsg: null });
        }
    };

    async handleSendContract(loanId) {
        this.setState({ showSpinner: true });
        const errors = { ...this.state.errors };
        errors["globalError"] = null;
        try {
            let response = await loanApplicationServices.sendLoanContract(loanId);

            if (response.success) {
                this.setState({ showSpinner: false, successMsg: response.message, errors });
            } else {
                errors["globalError"] = response.message;
                this.setState({ showSpinner: false, errors, successMsg: null });
            }

        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ showSpinner: false, errors, successMsg: null });
        }
    };


    async handleCustomErrors(errors) {
        return errors;
    };

    submitLoanApplicationFilter = async (e) => {
        e.preventDefault();
        let { userRole, sortBy } = this.state;
        await this.getLoanApplications(sortBy, 1);
    };

    sortByDate = async () => {
        let { sortBy } = this.state;
        sortBy = sortBy == "-updateOn" ? "updateOn" : "-updateOn";
        await this.getLoanApplications(sortBy);
        this.setState({ sortBy: sortBy }, 1);
    };

    sortByMerchantCode = async () => {
        let { sortBy } = this.state;
        sortBy = sortBy == "-merchantCode" ? "merchantCode" : "-merchantCode";
        await this.getLoanApplications(sortBy);
        this.setState({ sortBy: sortBy }, 1);
    };

    sortByName = async () => {
        let { sortBy } = this.state;
        sortBy = sortBy == "-userDetails.firstName" ? "userDetails.firstName" : "-userDetails.firstName";
        await this.getLoanApplications(sortBy, 1);
        this.setState({ sortBy: sortBy });
    };

    sortByAmount = async () => {
        let { sortBy } = this.state;
        sortBy = sortBy == "-amount" ? "amount" : "-amount";
        await this.getLoanApplications(sortBy);
        this.setState({ sortBy: sortBy }, 1);
    };


    onPageNext = () => {

        if (this.state.pageSize < this.state.currentPage)
            return

        let { sortBy } = this.state;

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getLoanApplications(sortBy);
        })
    }

    onPageChange = (page) => {

        let { sortBy } = this.state;

        this.setState({ currentPage: page }, () => {
            this.getLoanApplications(sortBy);

        })
    }

    onPagePrevious = () => {

        if (this.state.currentPage <= 1)
            return

        let { sortBy } = this.state;

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {
            this.getLoanApplications(sortBy);
        })
    }
    generatePhysicalNachFile = async (e) => {
        const list = [...this.state.list];
        const recordFields = e.target.name.split('_');
        const tranIndex = list.findIndex(x => x.loanId === recordFields[1]);
        list[tranIndex].nachBtnDisabled = true;
        this.setState({ showSpinner: true, list });

        try {
            const nachResponse = await updateEmailMobileService.generatePhysicalNachFile(list[tranIndex].loanId);
            list[tranIndex].nachBtnDisabled = false;

            if (nachResponse.success) {

                this.setState({ showSpinner: false, list });
                const url = await this.getSignedURLToDownload(nachResponse.data.pNachPath);

                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nachResponse.data.pNachFileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            }
            else {
                this.setState({ showSpinner: false, list, errorMessage: nachResponse.message, showErrorModal: true, errorHeader: "Error!" });
            }
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                this.setState({ showSpinner: false, list, errorMessage: ex.message, showErrorModal: true, errorHeader: "Error!" });
            }
        }
    }
    uploadPhysicalNachFile = async (e) => {
        const list = [...this.state.list];
        const recordFields = e.target.name.split('_');
        const tranIndex = list.findIndex(x => x.loanId === recordFields[1]);
        try {
            if (!list[tranIndex].files.pNachFile) {
                this.setState({ errorMessage: "Please select file to upload", showErrorModal: true, errorHeader: "Error!" });
                return;
            }
            else
                this.setState({ errorMessage: "", showErrorModal: false, errorHeader: "" });

            if (list[tranIndex].files.pNachFile.split('.').pop() !== "jpg" && list[tranIndex].files.pNachFile.split('.').pop() !== "jpeg" && list[tranIndex].files.pNachFile.split('.').pop() !== "png") {
                this.setState({ errorMessage: "File should be an image", showErrorModal: true, errorHeader: "Error!" });
                return;
            }

            list[tranIndex].isPnachUploadDisabled = true;

            this.setState({ showSpinner: true, list });

            const response = await updateEmailMobileService.uploadPhysicalNachFile(list[tranIndex].loanId, list[tranIndex].files);

            if (response.success)
                this.setState({ showSpinner: false, errorMessage: response.message, showErrorModal: true, errorHeader: "Success!" });
            else {
                list[tranIndex].isPnachUploadDisabled = false;
                this.setState({ showSpinner: false, errorMessage: response.message, showErrorModal: true, errorHeader: "Error!", list });
            }


        } catch (ex) {
            list[tranIndex].isPnachUploadDisabled = false;
            this.setState({ showSpinner: false, errorMessage: ex.message, showErrorModal: true, errorHeader: "Error!", list });
        }
    }
    closeModal = () => {
        this.setState({
            showErrorModal: false
        });
    };

}

export default LoanApplicationsModel;
