import InputDataForm from "../../components/common/form";

class ReconciliationWrapperModel extends InputDataForm {
  state = {
    headerTabs: [
      {
        title : "Settlement Pending"
      },
      {
        title: "Settlement Transactions"
      },
      {
        title: "Settlement MIS UTR"
      },
      // {
      //   title : "Settlement Report"
      // },
      {
        title: "Settlement MIS"
      },
      {
        title: "Settlement Alert"
      }
    ],
    headerActiveTab: "Settlement Pending"
  };

  onChangeHeaderActiveTab = (tab) => {
    this.setState({ headerActiveTab: tab })
  }
}

export default ReconciliationWrapperModel;
