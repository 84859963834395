import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import moment from "moment";
import Joi from "joi-browser";
import { toast } from "react-toastify";

class BankStatementsModel extends InputDataForm {
  state = {
    statements: [],
    data: {
      type: "CREDIT",
      valueStartDate: momentJalaali(),
      valueEndDate: momentJalaali(),
      amount: "",
      utrNo: "",
      narration: "",
      account: "FNDFCC"
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    evaluatorRole: "",
    transactionTypes: [
      {
        name: "Credit",
        value: "CREDIT"
      },
      {
        name: "Debit",
        value: "DEBIT"
      }
    ],
    accounts: [
      {
        name: "FNDFCC",
        value: "FNDFCC"
      },
      {
        name: "FNDFREV",
        value: "FNDFREV"
      }
    ]
  };
  schema = {
    page: Joi.any(),
    type: Joi.any(),
    valueStartDate: Joi.any(),
    valueEndDate: Joi.any(),
    amount: Joi.any(),
    utrNo: Joi.any(),
    narration: Joi.any(),
    account: Joi.any()
  };

  async componentDidMount() {
    console.log(this.state)
    if (!auth.getCurrentUser()) return;

    let { data } = this.state;

    const currentUser = auth.getCurrentUser();

    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    await this.getBankStatement()
  };
  async getBankStatement() {

    this.state.data.page = this.state.currentPage

    try {

      this.setState({ showSpinner: true });

      let reqObj = { ...this.state.data }

      if (reqObj.valueStartDate)
        reqObj.valueStartDate = moment(reqObj.valueStartDate).format("MM/DD/YYYY");

      if (reqObj.valueEndDate)
        reqObj.valueEndDate = moment(reqObj.valueEndDate).format("MM/DD/YYYY");

      let daysDiff = commonService.numberOfDaysDifferece(new Date(reqObj.valueStartDate), new Date(reqObj.valueEndDate));

      if (daysDiff > 60) {
        toast.error("Max Date Range 60 days");
        this.setState({ showSpinner: false });
        return
      }
      const { data: statements, totalPages } = await commonService.getBankStatements(reqObj);
      this.setState({ statements: statements, pageSize: totalPages ? totalPages : this.state.pageSize, showSpinner: false });
    }
    catch (ex) {
      this.setState({ statements: [], showSpinner: false });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getBankStatement();
  };

  handleValueFromDateChange = (value) => {
    const { data } = this.state;

    data.valueStartDate = value;
    this.setState({ data, showDateFilterNote: false });
  }

  handleValueToDateChange = (value) => {
    const { data } = this.state;

    data.valueEndDate = value;
    this.setState({ data, showDateFilterNote: false });
  }

  resetPage = () => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getBankStatement()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getBankStatement()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getBankStatement()
    })
  }

  getBankStatementDownload = async () => {
    try {

      let reqObj = { ...this.state.data }

      if (reqObj.valueStartDate)
        reqObj.valueStartDate = moment(reqObj.valueStartDate).format("MM/DD/YYYY");

      if (reqObj.valueEndDate)
        reqObj.valueEndDate = moment(reqObj.valueEndDate).format("MM/DD/YYYY");

      let daysDiff = commonService.numberOfDaysDifferece(new Date(reqObj.valueStartDate), new Date(reqObj.valueEndDate));

      if (daysDiff > 60) {
        toast.error("Max Date Range 60 days");
        return
      }

      const response = await commonService.getBankStatementsDownload(reqObj);

      if (response && response.success)
        toast.success("Download Requested Successfully, Please check your email");
    }
    catch (ex) {
      toast.error("Download Requested Failure");
    }
  };

  moveBankStatementToManualQueueApi = async (statement) => {
    try {

      let payload = {
        id: statement._id
      }

      this.setState({ showSpinner: true })
      const response = await commonService.moveBankStatementToManualQueue(payload);

      this.setState({ showSpinner: false })
      if (response && response.success) {
        this.getBankStatement()
        toast.success("Transaction Moved to Manual Queue");
      } else
        toast.error("Moving Transaction To Manual Queue Failed");
    } catch (ex) {
      this.setState({ showSpinner: false })
      toast.error("Moving Transaction To Manual Queue Failed");
    }
  }
}



export default BankStatementsModel;
