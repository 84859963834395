import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { creditApiUrl } from "../../config.json";
import moment from "moment";
import { mode } from "crypto-js";

const apiEndpoint = apiUrl + "/ops/admin";
const creditApiEndpoint = creditApiUrl + "admin";

export async function getLoanList(model) {
  const { data } = await http.post(`${apiEndpoint}/getLoanList`, {
    status: model.loanStatus,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    mobileNo: model.mobileNo,
    name: model.name,
    loanNo: model.loanNo,
    merchantCode: model.merchantCode,
    panNo: model.panNo,
    partner: model.partner,
    postDisbursalStatus: model.postDisbursalStatus,
    page: model.page
  });
  return data;
}

export async function getPersonalLoanList(model) {
  const { data } = await http.post(`${apiEndpoint}/getPersonalLoanList`, {
    status: model.loanStatus,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    mobileNo: model.mobileNo,
    name: model.name,
    loanNo: model.loanNo,
    merchantCode: model.merchantCode,
    panNo: model.panNo,
    partner: model.partner,
    postDisbursalStatus: model.postDisbursalStatus,
    page: model.page
  });
  return data;
}

export async function getRenewalListForTeleCalling(model) {
  const { data } = await http.post(`${apiEndpoint}/getRenewalListForTeleCalling`, {
    status: model.loanStatus,
    lastStepCompleted: model.lastStepCompleted,
    mobileNo: model.mobileNo,
    merchantCode: model.merchantCode,
    page: model.page,
    partner: model.partner,
    tab: model.tab,
    email: model.email,
    pincode: model.pincode,
    state: model.state,
    city: model.city,
    allLoans: model.isAllLoans,
    activeLoans: model.activeLoans,
    lastEmiDateSort: model.lastEmiDateSort
  });
  return data;
}

export async function getLoanListForTeleCalling(model) {
  const { data } = await http.post(`${apiEndpoint}/getLoanListForTeleCalling`, {
    status: model.loanStatus,
    lastStepCompleted: model.lastStepCompleted,
    mobileNo: model.mobileNo,
    merchantCode: model.merchantCode,
    page: model.page,
    partner: model.partner,
    tab: model.tab,
    email: model.email,
    pincode: model.pincode,
    state: model.state,
    city: model.city,
    allLoans: model.isAllLoans
  });
  return data;
}

export async function getLoanDetailsForTeleCalling(loanId) {
  const { data } = await http.post(`${apiEndpoint}/getLoanDetailsTeleCalling`, {
    loanId
  });
  return data;
}

export async function getLoanDetailsForTeleCallingCollectionQueue(loanId) {
  const { data } = await http.post(`${apiEndpoint}/getLoanDetailsTeleCallingCollectionQueue`, {
    loanId
  });
  return data;
}




export async function getLoanData(loanId) {
  const response = await http.get(
    `${apiEndpoint}/getLoanInformation/${loanId}`
  );
  return response;
}

export async function getEmi(staticData, data) {
  const response = await http.post(`${apiEndpoint}/getEmi`, {
    amount: staticData.amount,
    tenure: data.tenure,
    interestRate: data.interestRate,
    repaymentFrequency: data.repaymentFrequency,
    interestType: data.interestType,
    productType: staticData.productType,
    adhocDays: data.adhocDays
  });
  return response;
}

export async function updateLoanStatus(loanId, model, ipAddress = "") {
  let payload = {
    status: model.adminStatus,
    tenure: model.tenure,
    maturity: model.maturity,
    interestRate: model.interestRate,
    remarks: model.remarks,
    repaymentFrequency: model.repaymentFrequency,
    partnerName: model.partnerName,
    rejectReason: model.rejectReason,
    interestType: model.interestType,
    adhocDays: model.adhocDays,
    trancheType: model.trancheType,
    lenderComments: model.lenderComments,
    ipAddress: ipAddress,
    reviewReason: model.reviewReason
  }
  if (model.photoMatchStatus) {
    payload.photoMatchStatus = model.photoMatchStatus
    payload.photoMatchRemarks = model.photoMatchRemarks
  }
  const response = await http.put(`${apiEndpoint}/updateLoanStatus/${loanId}`, payload);
  return response;
}

export async function updateTeleCallingActionsCollectionQueue(loanId, model) {
  const response = await http.put(`${apiEndpoint}/updateTeleCallingActionsCollectionQueue`, {
    loanId,
    outcome: model.callOutcome,
    borrowerResponse: model.borrowerResponse,
    date: model.date,
    note: model.note,
    notConnectedReason: model.notConnectedReason,
    amount: model.amount,
    mobileNo: model.mobileNo
  });
  return response;
}

export async function updateTeleCallingActions(loanId, model) {
  const response = await http.put(`${apiEndpoint}/updateTeleCallingActions`, {
    loanId,
    outcome: model.callOutcome,
    borrowerResponse: model.borrowerResponse,
    //callbackOn: model.borrowerResponse && model.borrowerResponse == 'Callback' ? model.date : "",
    //willApplyOn: model.borrowerResponse && model.borrowerResponse == 'Will Apply' ? model.date : "",
    note: model.note,
    date: model.date,
    notConnectedReason: model.notConnectedReason,
    isRenewals: model.isRenewals
  });
  return response;
}

export async function getLendingPartnerList() {
  const response = await http.get(`${apiEndpoint}/getLendingPartnerList`);
  return response;
}

export async function getEmiCalculatorData(model) {
  const { data } = await http.post(`${apiEndpoint}/getEmiCalculatorData`, {
    amount: model.amount,
    tenure: parseInt(model.tenure),
    interestRate: model.interest,
    repaymentFrequency: model.frequency,
    productType: "TL"
  });
  return data;
}

export async function getPartnerUserTrueScores(model) {
  const response = await http.post(
    `${creditApiEndpoint}/getPartnerUserTrueScores`, {
    partnerId: model.partnerId,
    merchantCode: model.merchantCode
  }
  );
  return response;
}

export async function getPartnerUserTransactions(model, optional) {
  const response = await http.post(
    `${creditApiEndpoint}/getPartnerUserTransactions`, {
    partnerId: model.partnerId,
    merchantCode: model.merchantCode,
    currentYear: optional.year,
    currentMonth: optional.month
  }
  );
  return response;
}

export async function getRejectReasonList(type) {
  const { data } = await http.post(`${apiEndpoint}/getRejectReasonList`, { type: type });
  return data;
}

export async function sendNewMandateRequest(loanId) {
  const response = await http.post(`${apiEndpoint}/sendNewMandate`, {
    loanId: loanId
  });
  return response;
}

export async function getPendingEmis(model, sortBy) {
  const { data } = await http.post(`${apiEndpoint}/getPendingEmis`, {
    partnerId: model.partnerId,
    mobileNo: model.mobileNo.trim(),
    merchantName: model.name.trim(),
    loanNo: model.loanNo.trim(),
    sortBy: sortBy,
    overdueBy: model.overdueBy,
    page: model.page,
    isAllLoans: model.isAllLoans,
    lendingPartnerId: model.lendingPartnerId
  });
  return data;
}

export async function getPartnerUserTrueScoresGraph(partnerId, merchantCode, optional) {
  const response = await http.post(
    `${creditApiEndpoint}/getTrueScoreForGraph`, {
    partnerId: partnerId,
    merchantCode: merchantCode,
    fromMonth: optional.tsFromMonth,
    fromYear: optional.tsFromYear,
    toMonth: optional.tsToMonth,
    toYear: optional.tsToYear
  }
  );
  return response;
}

export async function getFundfinaLoans(userId) {
  const response = await http.post(
    `${apiEndpoint}/getFundfinaLoans`, {
    userId: userId
  }
  );
  return response;
}

export async function sendNewMandateOrUpdateBankListRequest(loanId, status) {
  const response = await http.post(`${apiEndpoint}/sendEnachAndBankModificationLink`, {
    loanId: loanId,
    status: status
  });
  return response;
}

export async function loanAllocationValidation(loanId, lendingPartnerName) {
  const response = await http.post(`${apiEndpoint}/loanAllocationValidation`, {
    loanId: loanId,
    lendingPartnerName: lendingPartnerName
  });
  return response;
}

export async function updateLoanStatusByLender(status, selectedLoans, ipAddress = "") {
  const { data } = await http.post(`${apiEndpoint}/updateLoanStatusByLender`, {
    status: status,
    loanIds: selectedLoans,
    ipAddress: ipAddress
  });
  return data;
}

export async function updateLoanContractImage(payload) {
  const response = await http.put(`${apiEndpoint}/updateLoanContractImage`, payload);
  return response;
}

export async function getUserCentricData(loanId) {
  const response = await http.get(`${apiEndpoint}/userCentricData?loanId=${loanId}`);
  return response;
}

export async function getRollbackLoanList(model) {
  const { data } = await http.post(`${apiEndpoint}/getRollbackLoanList`, {
    mobileNo: model.mobileNo,
    name: model.name,
    loanNo: model.loanNo,
    merchantCode: model.merchantCode,
    panNo: model.panNo,
    partner: model.partner,
    page: model.page
  });
  return data;
}

export async function rollbackLoan(selectedLoans) {
  const { data } = await http.post(`${apiEndpoint}/rollbackLoan`, {
    loans: selectedLoans
  });
  return data;
}

export async function getPayoutTransactionList(model) {
  const { data } = await http.post(`${apiEndpoint}/payouttransactions`, {
    borrowingPartner: model.borrowingPartner,
    partner: model.partner == "all" ? "" : model.partner,
    tab: model.tab,
    repaymentDateFrom: moment(model.fromDate).format("MM/DD/YYYY"),
    repaymentDateTo: moment(model.toDate).format("MM/DD/YYYY"),
    page: model.page
  });
  return data;
}

export async function getPayoutTransactionDetails(model) {
  const { data } = await http.post(`${apiEndpoint}/payouttransactionsDetails`, {
    id: model.id
  });
  return data;
}

export async function getInvoiceList(model) {
  const { data } = await http.post(`${apiEndpoint}/getInvoiceList`, {
    status: model.status,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    partnerId: model.partnerId,
    page: model.page,
    amount: model.amount,
    invoiceType: model.invoiceType,
    invoiceNo: model.invoiceNo
  });
  return data;
}

export async function approveInvoice(status, invoiceNo, comments) {
  const { data } = await http.post(`${apiEndpoint}/approveInvoice`, {
    status: status,
    invoiceNo: invoiceNo,
    comments: comments
  });
  return data;
}

export async function getLenderSettlementInformation(model) {
  const { data } = await http.post(`${apiEndpoint}/lenderSettlementInformation`, {
    lendingPartnerId: model.lendingPartnerId
  });
  return data;
}

export async function getInvoiceDetails(invoiceNo) {
  const { data } = await http.get(`${apiEndpoint}/getInvoiceDetails/${invoiceNo}`);
  return data;
}

export async function getLoansListForInvoice(model) {
  const { data } = await http.post(`${apiEndpoint}/getLoansListForInvoice`, {
    invoiceType: model.invoiceType,
    partnerId: model.partnerId,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    uploadType: model.uploadType.toUpperCase()
  });
  return data;
}

export async function createInvoice(model, loanNos) {
  const { data } = await http.post(`${apiEndpoint}/createInvoice`, {
    invoiceType: model.invoiceType,
    partnerId: model.partnerId,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    loanNos,
    invoiceRefNo: model.invoiceRefNo
  });
  return data;
}

export async function getPartnerListByInvoiceType(invoiceType, partnerId) {
  const { data } = await http.post(`${apiEndpoint}/getPartnerListByInvoiceType`, {
    invoiceType, partnerId
  });
  return data;
}

export async function getInvoiceListByPartnerId(invoiceType, partnerId) {
  const { data } = await http.post(`${apiEndpoint}/getInvoiceListByPartnerId`, {
    invoiceType, partnerId
  });
  return data;
}

export async function createInvoiceByExcel(fileData) {
  const { data } = await http.post(`${apiEndpoint}/createInvoiceByExcel`, fileData);
  return data;
}

export async function initiatePayment(invoiceNo) {
  const { data } = await http.post(`${apiEndpoint}/initiateInvoicePayment`, {
    invoiceNo: invoiceNo
  });
  return data;
}

export async function reInitiatePayment(transactionId) {
  const { data } = await http.post(`${apiEndpoint}/reInitiateInvoicePayment`, {
    transactionId
  });
  return data;
}

export async function downloadLoanList(model) {
  const { data } = await http.post(`${apiEndpoint}/downloadLoanList`, {
    status: model.loanStatus,
    fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
    toDate: moment(model.toDate).format("DD/MM/yyyy"),
    mobileNo: model.mobileNo,
    name: model.name,
    loanNo: model.loanNo,
    merchantCode: model.merchantCode,
    panNo: model.panNo,
    partner: model.partner,
    postDisbursalStatus: model.postDisbursalStatus
  });
  return data;
}

export async function gstBasedCreditLimit(userId) {
  const response = await http.get(`${apiUrl}/ops/gst/gstBasedCreditLimit/${userId}`);
  return response;
}

export async function bankStatementBasedCreditLimit(userId) {
  const response = await http.get(`${apiUrl}/ops/bankstatement/bankStatementBasedCreditLimit/${userId}`);
  return response;
}

export async function fetchBankStatement(userId) {
  const { data } = await http.get(`${apiUrl}/ops/bankstatement/fetchBankStatement/${userId}`);
  return data;
}

export async function getPreApprovalAmount(partnerId , merchantCode) {
  const {data} = await http.post(`${apiUrl}/ops/lendingpartner/getPreApprovalAmount`,{
    partnerId: partnerId,
    merchantCode: merchantCode
  });
  return data;
}