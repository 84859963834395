import React from "react";
import { Form, Col, Row, Image, Button, Container, Tab, Tabs, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import MyProfileFormModel from "../../models/profile/myProfileFormModel";
import { Link } from "react-router-dom";
import { StickyTable, Row as Row1, Cell } from 'react-sticky-table';
import NumberFormat from 'react-number-format';

class MyProfile extends MyProfileFormModel {

  render() {
    const { data, showSpinner, optionalDocumentList, mandateList, showErrorModal, errorMessage, errorHeader, showMandateConfirmation } = this.state;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <Tabs defaultActiveKey="myProfile" id="uncontrolled-tab-example">
          <Tab eventKey="myProfile" title="My Profile">
            <React.Fragment>
              <br />
              <h5 className="fundfinaColor">
                {data.kycStatus && (
                  <span>
                    Kyc Status:{" "}
                    <span style={{ color: data.kycStatusColor, fontWeight: "bold" }}>
                      {data.kycStatus}
                    </span>{" "}
                  </span>
                )}
              </h5>
              <br />
              <Form>
                <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
                  Basic Information
                </h5>{" "}
                {(data.userRole == "User" || data.userRole == "Admin") && (
                  <Form.Group as={Row} controlId="name">
                    <Form.Label column lg="12">
                      Full Name:{"  "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.fullName}
                      </span>
                    </Form.Label>
                  </Form.Group>
                )}
                {data.userRole != "User" && data.userRole != "Admin" && (
                  <Form.Group as={Row} controlId="entityName">
                    <Form.Label column lg="12">
                      Entity Name:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.partnerName}
                      </span>
                    </Form.Label>
                  </Form.Group>
                )}
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Email Address:{" "}
                    <span style={{ color: "black" }}>{data.emailId}</span>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Mobile Number:{" "}
                    <span style={{ color: "black" }}>{data.mobileNo}</span>
                    <Link to="/updateMobile"> Update</Link>
                  </Form.Label>
                </Form.Group>{" "}
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="4">
                    Alternate Email:{" "}
                    <span style={{ color: "black" }}>{data.alternateEmail}</span>
                    <Link to="/updateAltEmail"> Update</Link>
                  </Form.Label>
                  <Form.Label column sm="4">
                    Alternate Mobile:{" "}
                    <span style={{ color: "black" }}>{data.alternateMobile}</span>
                    <Link to="/updateAltMobile"> Update</Link>
                  </Form.Label>
                </Form.Group>
                {data.panNo && (
                  <Form.Group as={Row} controlId="pan">
                    <Form.Label column sm="6">
                      PAN Number:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.panNo ? data.panNo.substr(0, 3) + "XXXXXXX" : ""}
                      </span>{" "}

                      <div>
                        {
                          data.panFilePath ?
                            this.renderThumbnail(data.panFileSrc) : ""
                        }
                      </div>
                      {/* (
                        <a href="#" onClick={() => this.openFile(data.panFilePath)}>
                          {" "}
                          View
                        </a>
                      )} */}
                    </Form.Label>
                  </Form.Group>
                )}
                {(data.userRole == "User" || data.userRole == "Admin") && data.poaNo && (
                  <Form.Group as={Row} controlId="poa">
                    <Form.Label column sm="4">
                      POA Number:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.poaNo}
                      </span>{" "}

                      <div>
                        {
                          data.poaFilePath ?
                            this.renderThumbnail(data.poaFileSrc) : ""
                        }
                      </div>
                      {/* {data.poaFilePath && (
                        <a href="#" onClick={() => this.openFile(data.poaFilePath)}>
                          {" "}
                          View
                        </a>
                      )} */}
                    </Form.Label>
                    <Form.Label column sm="4">
                      POA Type: <span style={{ color: "black" }}>{data.poaType}</span>{" "}
                    </Form.Label>
                  </Form.Group>
                )}
                {data.userRole != "User" &&
                  data.userRole != "Admin" &&
                  data.udyogAadhaarNo && (
                    <Form.Group as={Row} controlId="udyogAadhaar">
                      <Form.Label column sm="6">
                        Udyog Aadhaar Number:{" "}
                        <span style={{ color: "black", textTransform: "uppercase" }}>
                          {data.udyogAadhaarNo}
                        </span>{" "}

                        <div>
                        {
                          data.udyogAadhaarFilePath ?
                            this.renderThumbnail(data.udyogAadhaarFileSrc) : ""
                        }
                      </div>
                      </Form.Label>
                    </Form.Group>
                  )}
                <Form.Group as={Row} controlId="employment">
                  {data.employment && (
                    <Form.Label column sm="4">
                      Employment:{" "}
                      <span style={{ color: "black" }}>{data.employment}</span>{" "}
                    </Form.Label>
                  )}
                </Form.Group>
                {data.city && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Address Details
                    </h5>{" "}
                    <Form.Group as={Row} controlId="address">
                      <Form.Label column sm="12">
                        Address:{" "}
                        <span style={{ color: "black", textTransform: "uppercase" }}>
                          {data.fullAddress}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="city">
                      <Form.Label column sm="4">
                        City:{" "}
                        <span style={{ color: "black", textTransform: "uppercase" }}>
                          {data.city}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        State:{" "}
                        <span style={{ color: "black", textTransform: "uppercase" }}>
                          {data.state}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        Pincode:{" "}
                        <span style={{ color: "black", textTransform: "uppercase" }}>
                          {data.pincode}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                  </React.Fragment>
                )}
                {data.bankList.length > 0 && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Bank Details
                    </h5>
                    {data.bankList.map((bank) => (
                      <React.Fragment key={bank.bankName}>
                        <Form.Group as={Row} controlId="bank">
                          <Form.Label column sm="12">
                            Bank Name:{" "}
                            <span
                              style={{ color: "black", textTransform: "uppercase" }}
                            >
                              {bank.bankName}
                            </span>{" "}
                            {bank.bankFilePath && (
                              <a
                                href="#"
                                onClick={() => this.openFile(bank.bankFilePath)}
                              >
                                {" "}
                                View
                              </a>
                            )}
                            {/* <Link to="/updateBankDetail"> Edit</Link> */}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="branch">
                          <Form.Label column sm="12">
                            Branch Name:{" "}
                            <span
                              style={{ color: "black", textTransform: "uppercase" }}
                            >
                              {bank.branch}
                            </span>{" "}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="accountNo">
                          <Form.Label column sm="4">
                            Account No:{" "}
                            <span
                              style={{ color: "black", textTransform: "uppercase" }}
                            >
                              {bank.accountNo}
                            </span>{" "}
                          </Form.Label>
                          <Form.Label column sm="4">
                            IFSC Code:{" "}
                            <span
                              style={{ color: "black", textTransform: "uppercase" }}
                            >
                              {bank.ifsc}
                            </span>{" "}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="accountType">
                          <Form.Label column sm="4">
                            Account Type:{" "}
                            <span
                              style={{ color: "black", textTransform: "uppercase" }}
                            >
                              {bank.accountType}
                            </span>{" "}
                          </Form.Label>
                        </Form.Group>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
                {data.gstList.length > 0 && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      GST Details
                    </h5>
                    {data.gstList.map((gst) => (
                      <React.Fragment key={gst.gstNo}>
                        <Form.Group as={Row} controlId="gstNo">
                          <Form.Label column sm="4">
                            Gst No:{" "}
                            <span
                              style={{ color: "black", textTransform: "uppercase" }}
                            >
                              {gst.gstNo}
                            </span>{" "}
                            <div>
                              {
                                gst.gstFilePath ?
                                  this.renderThumbnail(gst.gstFileSrc) : ""
                              }
                            </div>

                          </Form.Label>
                        </Form.Group>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
                {optionalDocumentList.length > 0 && (
                  <React.Fragment>
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Additional Documents
                    </h5>{" "}
                    <Form.Group as={Row} className="px-2" controlId="additionalDocuments">
                      {
                        optionalDocumentList?.length ?
                          <div className="mt-2 horizontal-scrollable">
                            {
                              optionalDocumentList?.map((item, index) => (
                                <div className="d-inline-block position-relative mt-2 pr-2">
                                  {this.renderThumbnail(item)}
                                </div>
                              ))
                            }
                          </div> : ""
                      }
                    </Form.Group>
                  </React.Fragment>
                )}
                <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
                  Profile Picture
                </h5>{" "}
                <Form.Group as={Row} controlId="profilePic">
                  <Form.Label column sm="12">
                    <Image
                      src={
                        data.profileFileSrc
                          ? data.profileFileSrc
                          : require("../../images/userProfile.png")
                      }
                      fluid
                      style={{ maxHeight: 100 }}
                    />{" "}
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Row} controlId="profilePic">
                  <Form.Label column sm="12">
                    <Link to="/uploadProfilePic">Change Picture</Link>
                  </Form.Label>
                </Form.Group>
              </Form>

              {/* <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
                Existing Mandates:
          </h5>{" "}
              <div>
                <div style={{ width: '100%' }}>
                  <StickyTable stickyHeaderCount={0} rightStickyColumnCount={1} leftStickyColumnCount={2} >
                    <Row1>
                      <Cell><b>Loan #</b></Cell>
                      <Cell><b>Mandate #</b></Cell>
                      <Cell><b>UMRN</b></Cell>
                      <Cell><b>Amount</b></Cell>
                      <Cell><b>Loan Status</b></Cell>
                      <Cell style={{ minWidth: 200 }}><b>Bank Name</b></Cell>
                      <Cell style={{ minWidth: 130, textAlign: "right" }}><b>Account #</b></Cell>
                      <Cell><b>Mandate Registered On</b></Cell>
                      <Cell style={{ minWidth: 130, textAlign: "right" }}><b>Status</b></Cell>
                      <Cell><b>Is Cancelled</b></Cell>
                      <Cell style={{ minWidth: 150 }}><b>Total Installments</b></Cell>
                      <Cell style={{ minWidth: 130 }}><b>Pending Installments</b></Cell>
                      <Cell style={{ minWidth: 100 }}><b>Cancel</b></Cell>
                    </Row1>
                    {mandateList &&
                      mandateList.map((mandate, i) => (
                        <Row1>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "" }}>
                            {mandate.loanNo}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "" }}>{mandate.mandateId}</Cell>
                          <Cell style={{ backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>{mandate.umrn}</Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            <NumberFormat value={mandate.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.loanStatus}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.bank}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.accountNo}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.mandateRegisteredOn}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.isActive ? "Active" : "Inactive"}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.cancellationConfirmed ? "Yes" : "No"}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.totalEmis}
                          </Cell>
                          <Cell style={{ textAlign: "right", backgroundColor: i % 2 == 0 ? "#E8E8E8" : "transparent" }}>
                            {mandate.pendingEmis}
                          </Cell>
                          <Cell style={{ backgroundColor: i % 2 == 0 ? "#E8E8E8" : "" }}>  <Button
                            className="ff-button"
                            type="button"
                            name={"btnCancel_" + mandate.umrn}
                            onClick={this.cancelMandateConfirmation}
                            disabled={mandate.isCancelButtonDisabled}
                          >
                            Cancel
              </Button></Cell>
                        </Row1>))}
                    {mandateList.length === 0 && <Row1>
                      <Cell cellspan={13}>

                        No record found
                      </Cell></Row1>}
                  </StickyTable>
                </div>
              </div> */}
            </React.Fragment>
          </Tab>
          {/* <Tab eventKey="trueScore" title="TrueScore">
          </Tab> */}
        </Tabs>
        <Modal
          show={showErrorModal}
          onHide={this.closeErrorModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>{errorHeader}</b>
          </Modal.Header>
          <Modal.Body>
            {errorMessage}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeErrorModal} className="ff-button">
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showMandateConfirmation}
          onHide={this.closeErrorModal}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <b>Are you sure?</b>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to cancel this mandate?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.cancelMandate} className="ff-button">
              Yes
            </Button>
            <Button onClick={this.closeErrorModal} className="ff-button">
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    );
  }
}

export default MyProfile;
