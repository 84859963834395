import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/";

export async function updateEmailMobile(value, type) {
  const { data } = await http.post(`${apiEndpoint}users/updateDetails`, {
    value,
    type,
  });
  return data;
}

export async function updateMobile(value, type , otp) {
  const { data } = await http.post(`${apiEndpoint}users/updateMobileNo`, {
    mobileNo : value,
    type,
    otp
  });
  return data;
}

export async function insertUpdateBankDetails(dataModel, partnerId, loanId) {
  const { data } = await http.put(
    apiEndpoint + `loan/insertUpdateBankDetails/${dataModel.userId}`,
    {
      accountNo: dataModel.accountNo ? dataModel.accountNo.trim() : "",
      ifsc: dataModel.ifsc ? dataModel.ifsc.trim() : "",
      accountType: dataModel.accountType ? dataModel.accountType.trim() : "",
      accountName: dataModel.accountName ? dataModel.accountName.trim() : "",
      partnerId: partnerId ? partnerId : "",
      merchantCode: dataModel.merchantCode ? dataModel.merchantCode.trim() : "",
      loanId: loanId ? loanId : null
    }
  );
  return data;
}

export async function createMandateForLoanByUser(dataModel, loanId) {
  const { data } = await http.post(
    apiEndpoint + `loan/createMandateForLoanByUser/${dataModel.userId}`,
    {
      mandateType: dataModel.mandateType ? dataModel.mandateType.trim() : "",
      loanId: loanId ? loanId : null
    }
  );
  return data;
}

export async function generatePhysicalNachFile(loanId) {
  const { data } = await http.post(
    apiEndpoint + `digio/generatePhysicalNachFile`,
    {
      loanId: loanId ? loanId : null
    }
  );
  return data;
}

export async function uploadPhysicalNachFile(loanId, files) {
  const { data } = await http.post(
    apiEndpoint + `digio/uploadPhysicalNachFile`,
    {
      loanId: loanId ? loanId : null,
      pNachFile: files.pNachFile,
      pNachFileStream: files.pNachFileStream,
    }
  );
  return data;
}

export async function sendPhysicalNACHLink(loanId) {
  const { data } = await http.post(
    apiEndpoint + `digio/sendPhysicalNACHLink`,
    {
      loanId: loanId ? loanId : null
    }
  );
  return data;
}

export default {
  updateEmailMobile,
  insertUpdateBankDetails
};

