import http from "../common/httpService";
import { apiUrl } from "../../config.json";

const apiEndpoint = apiUrl + "/ops/admin";

export async function getLenderPayoutList(model) {

    let url = apiEndpoint + "/lender/payout/list";
    let keys = Object.keys(model);
    for (let i = 0; i < keys.length; i++) {

        if (model[keys[i]]) {
            if (i == 0)
                url = `${url}?${keys[i]}=${model[keys[i]]}`;
            else
                url = `${url}&${keys[i]}=${model[keys[i]]}`;
        }
    }

    const response = await http.get(url).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return response;
}

export async function lenderPayoutInit(payload) {
    const { data } = await http.post(apiEndpoint + "/lender/payout/init", payload);
    return data;
}

export async function lenderPayoutReDisburse(payload) {
    const { data } = await http.post(apiEndpoint + "/lender/payout/redisburse", payload);
    return data;
}

export async function getLenderPayoutListV2(model) {

    let url = apiEndpoint + "/lender/payout/listv2";
    let keys = Object.keys(model);
    for (let i = 0; i < keys.length; i++) {

        if (model[keys[i]]) {
            if (i == 0)
                url = `${url}?${keys[i]}=${model[keys[i]]}`;
            else
                url = `${url}&${keys[i]}=${model[keys[i]]}`;
        }
    }

    const response = await http.get(url).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return response;
}

export async function lenderPayoutInitV2(payload) {
    const { data } = await http.post(apiEndpoint + "/lender/payout/initv2", payload);
    return data;
}


export async function getOrderSplitDetails(model) {

    let url = apiEndpoint + "/lender/payout/ordersplit/list";
    let keys = Object.keys(model);
    for (let i = 0; i < keys.length; i++) {

        if (model[keys[i]]) {
            if (i == 0)
                url = `${url}?${keys[i]}=${model[keys[i]]}`;
            else
                url = `${url}&${keys[i]}=${model[keys[i]]}`;
        }
    }

    const response = await http.get(url).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return response;
}

export async function lenderPayoutReDisburseV2(payload) {
    const { data } = await http.post(apiEndpoint + "/lender/payout/redisbursev2", payload);
    return data;
}


