import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as manualQueue from "../../services/manualQueue/manualQueue";
import momentJalaali from "moment-jalaali";
import moment from "moment";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import * as commonService from "../../services/common/commonService";

class ManualQueueModel extends InputDataForm {
  state = {
    statements: [],
    data: {
      type: "CREDIT",
      // valueStartDate: momentJalaali(),
      // valueEndDate: momentJalaali(),
      amount: "",
      utrNo: "",
      narration: ""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    evaluatorRole: "",
    tabs: [
      {
        title: "Pending",
        value: "PENDING"
      },
      {
        title: "Completed",
        value: "COMPLETED"
      }
    ],
    activeTab: "PENDING",
    actions: [
      {
        title: "Settlement",
        value: "SETTLEMENT"
      },
      {
        title: "Invoice",
        value: "INVOICE"
      },
      {
        title: "Loan Repayments",
        value: "LOAN_REPAYMENTS"
      },
      // {
      //   title: "ForeClosure",
      //   value: "FORE_CLOSURE"
      // },
      // {
      //   title: "Reversal",
      //   value: "REVERSAL"
      // }
    ]
  };
  schema = {
    page: Joi.any(),
    type: Joi.any(),
    valueStartDate: Joi.any(),
    valueEndDate: Joi.any(),
    amount: Joi.any(),
    utrNo: Joi.any(),
    narration: Joi.any()
  };

  async componentDidMount() {
    console.log(this.state)
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });
    await this.getManualQueue()
  };
  async getManualQueue() {

    this.state.data.page = this.state.currentPage

    try {

      this.setState({ showSpinner: true });

      let reqObj = { ...this.state.data }

      if (reqObj.valueStartDate)
        reqObj.fromDate = moment(reqObj.valueStartDate).format("MM/DD/YYYY");

      if (reqObj.valueEndDate)
        reqObj.toDate = moment(reqObj.valueEndDate).format("MM/DD/YYYY");

      reqObj.status = this.state.activeTab;

      const { data: statements, totalPages } = await manualQueue.getManualQueue(reqObj);
      this.setState({ statements: statements, pageSize: totalPages ? totalPages : this.state.pageSize, showSpinner: false });
    }
    catch (ex) {
      this.setState({ statements: [], showSpinner: false });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getManualQueue();
  };

  handleValueFromDateChange = (value) => {
    const { data } = this.state;

    data.valueStartDate = value;
    this.setState({ data, showDateFilterNote: false });
  }

  handleValueToDateChange = (value) => {
    const { data } = this.state;

    data.valueEndDate = value;
    this.setState({ data, showDateFilterNote: false });
  }

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 0 }, () => {
      callback && callback()
    })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getManualQueue()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getManualQueue()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getManualQueue()
    })
  }

  onChangeTab = (tab) => {
    this.setState({ activeTab: tab }, () => {
      this.resetPage(() => {
        this.getManualQueue()
      })
    })
  }

  onChangeAction = ({ currentTarget: input }, item) => {
    let data = this.state.data;
    data.action = input.value;

    if (input.value == "SETTLEMENT") {
      this.state.showManualQueueSettlement = true
    }

    if (input.value == "INVOICE") {
      this.state.showManualQueueInvoice = true
    }

    if (input.value == "LOAN_REPAYMENTS") {
      this.state.showManualQueueLoanRepayments = true
    }
    this.setState({ data, selectedItem: item })
  }

  closeSettlementModal = () => {
    this.setState({ showManualQueueSettlement: false })
  }

  closeInvoiceModal = () => {
    this.setState({ showManualQueueInvoice: false })
  }

  closeLoanRepayments = () => {
    this.setState({ showManualQueueLoanRepayments: false });
  }

  onSuccessAction = () => {
    this.resetPage()
    this.setState({ showManualQueueSettlement: false });
    this.getManualQueue()
  }

  onSuccessActionInvoice = () => {
    this.resetPage()
    this.setState({ showManualQueueInvoice: false });
    this.getManualQueue()
  }

  onSuccessLoanRepayments = () => {
    this.resetPage()
    this.setState({ showManualQueueLoanRepayments: false });
    this.getManualQueue()
  }
}

export default ManualQueueModel;
