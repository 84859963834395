import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import Joi from "joi-browser";
import * as trueScoreService from "../../services/upload/excelUploadService";
import * as disbursalService from '../../services/disbursal/disbursalService';
import * as trancheService from "../../services/tranche/trancheService";
import axios from "axios";
import { url, diUrl } from "../../config.json";
import * as commonService from "../../services/common/commonService"
class ExcelUploadModel extends InputDataForm {
    state = {
        data: {
            userId: "",
            partnerId: "",
            uploadType: "",
            selectedFile: null,
            fileName: "",
            loaded: 0
        },
        errors: {},
        borrowingPartnerName: "",
        role: "",
        partnerType: "Borrowing Partner",
        borrowingPartnerList: [],
        lendingPartnerList: [],
        showSpinner: false,
        successMsg: ""
    };

    schema = {
        userId: Joi.string().required(),
        partnerId: Joi.any(),
        uploadType: Joi.string().required().label("Upload Type"),
        selectedFile: Joi.any().required(),
        fileName: Joi.any().required(),
        loaded: Joi.any().required(),
    };

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");
        let { role, borrowingPartnerName } = { ...this.state };

        const currentUser = auth.getCurrentUser();

        if (currentUser.role === "Admin" || currentUser.role === "Borrowing Partner") {
            role = currentUser.role;
            borrowingPartnerName = currentUser.name;
            const data = { ...this.state.data };
            data.userId = currentUser.userId;
            await this.setState({ data: data, role, borrowingPartnerName });

            await this.getPartnerList();
        } else {
            return (window.location = "/");
        }
    };

    async handleCustomErrors(errors) {
        const data = { ...this.state.data };
        if (data.uploadType == "") {
            errors["uploadType"] = "Please select Upload Type";
        }
        if (data.fileName == "") {
            errors["uploadFile"] = "Please select Upload Excel File";
        }
        if (data.uploadType != "Dis_Transfer" && data.uploadType != "Payment_Recon" && data.partnerId == "") {
            errors["borrowingPartner"] = "Please select Partner";
        }

        this.setState({ errors, successMsg: "" });
        //console.table(errors);
        return errors;
    };

    async getPartnerList() {
        let borrowingPartnerList = { ...this.state.borrowingPartnerList };
        let lendingPartnerList = { ...this.state.lendingPartnerList };

        let { role, borrowingPartnerName, errors } = { ...this.state };

        this.setState({ showSpinner: true });
        try {
            errors["globalError"] = null;
            // const partnerList = await trancheService.getPartnerList("Borrowing Partner");
            const partnerList = await commonService.getBorrowingPartnerList();

            if (partnerList) {
                if (partnerList.success) {
                    borrowingPartnerList = partnerList.data;
                    if (role === "Borrowing Partner") {
                        const partner = borrowingPartnerList.filter(x => x.partnerName == borrowingPartnerName);

                        if (partner) {
                            let data = { ...this.state.data };
                            data.partnerId = partner[0]._id;
                            this.setState({ data });
                        }
                    }
                    this.setState({ borrowingPartnerList, errors, successMsg: "", showSpinner: false });
                } else {
                    borrowingPartnerList = [];
                    errors["globalError"] = partnerList.message;
                    this.setState({ borrowingPartnerList, errors, successMsg: null, showSpinner: false });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null, showSpinner: false });
            }

            const lendingPartners = await trancheService.getPartnerList("Lending Partner");
            if (lendingPartners) {
                if (lendingPartners.success) {
                    lendingPartnerList = lendingPartners.data;
                    this.setState({ lendingPartnerList, errors, successMsg: "", showSpinner: false });
                } else {
                    lendingPartnerList = [];
                    errors["globalError"] = lendingPartners.message;
                    this.setState({ lendingPartnerList, errors, successMsg: null, showSpinner: false });
                }
            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ errors, successMsg: null, showSpinner: false });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null, showSpinner: false });
        }
    };

    async uploadDIFile(payload) {

        let url = diUrl + "/api/signedurl?fileName=" + payload.fileName;
        let data = await axios.get(url);

        let res = await axios.put(data.data.signedUrl, payload.selectedFile, {

            headers: {
                "Content-Type": payload.selectedFile.type,
                "content-length": payload.selectedFile.size
            }
        });

        console.log("res", res);
        if (res.status == 200) {

            let jobUrl = diUrl + "/api/process/file";
            let body = {
                partnerId: payload.partnerId,
                name: payload.fileName,
                uploadType: payload.uploadType
            };


            console.log("body", body);

            let res = await axios.post(jobUrl, body);
            console.log("res", res.status);
            if (res.status == 200) {
                return {
                    success: true,
                    fileName: data.data.name,
                    message: "Uploaded Successfully. File Under Process."
                }
            }

            return {
                success: false,
                fileName: data.data.name
            }
        }

        return {
            success: false,
            fileName: data.data.name
        }

    }

    async doSubmit() {
        let data = { ...this.state.data };
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        try {
            let fileData = new FormData();
            let result = null;

            if (data.uploadType == "Users_Merchants_Data_DI") {
                result = await this.uploadDIFile(data);
            } else if (data.uploadType == "Lender_Settelement") {
                data.uploadType = "Lender_Settelement";
                result = await this.uploadDIFile(data);
            } else if (data.uploadType == "Crif_Score") {
                console.log("Crif")
                result = await this.uploadDIFile(data);
            }
            else if (data.uploadType == "Upload_Received_Emis") {
                fileData.append('file', data.selectedFile);
                fileData.append('type', data.uploadType);
                fileData.append('fileName', data.fileName);
                fileData.append('userId', data.userId);
                fileData.append('partnerId', data.partnerId);

                result = await trueScoreService.uploadEmiCollectionExcel(fileData);
            } else if (data.uploadType == "Dis_Transfer") {
                fileData.append('file', data.selectedFile);
                fileData.append('type', "disbursalDiscountUpload");
                fileData.append('fileName', data.fileName);

                result = await disbursalService.uploadDisbursalExcel(fileData, data);
            } else if (data.uploadType == "Payment_Recon") {
                fileData.append('file', data.selectedFile);
                fileData.append('type', data.uploadType);
                fileData.append('fileName', data.fileName);

                result = await disbursalService.uploadPaymentReconExcel(fileData, data);
            } else {
                fileData.append('file', data.selectedFile);
                fileData.append('type', data.uploadType);
                fileData.append('fileName', data.fileName);
                fileData.append('userId', data.userId);
                fileData.append('partnerId', data.partnerId);

                result = await trueScoreService.uploadExcel(fileData);
            }
            //result = { success: true, message: "Uploaded Successfully" };

            data.uploadType = "";
            data.selectedFile = null;
            data.fileName = "";
            data.partnerId = "";
            data.loaded = 0;
            if (result.success) {
                this.setState({ data, errors, successMsg: result.message, showSpinner: false });
            } else {
                errors["globalError"] = result.message;

                this.setState({ data, errors, successMsg: null, showSpinner: false });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, successMsg: null, showSpinner: false });
        }
    };

    download = async () => {
        const { data } = this.state;
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        errors["uploadType"] = null;
        errors["globalError"] = null;
        errors["uploadFile"] = null;
        errors["borrowingPartner"] = null;

        if (data.uploadType) {
            let urlPath = "";
            let templateName = "";
            if (data.uploadType == "Users_Merchants_Data_DI") {
                urlPath = url + "/Templates/Fundfina_Inbound_Merchant_Data_v1.xlsx";
                templateName = "Fundfina_Inbound_Merchant_Data_v1.xlsx";
            } else if (data.uploadType == "Upload_Received_Emis") {
                urlPath = url + "/Templates/Upload_Received_Emis.xlsx";
                templateName = "Upload_Received_Emis.xlsx";
            } else if (data.uploadType == "Upload_Merchants_Mobile") {
                urlPath = url + "/Templates/Upload_Merchants_Mobile.xlsx";
                templateName = "Upload_Merchants_Mobile.xlsx";
            } else if (data.uploadType == "Upload_Offers") {
                urlPath = url + "/Templates/Upload_Offers.xlsx";
                templateName = "Upload_Offer.xlsx";
            } else if (data.uploadType == "Loans_Creations") {
                urlPath = url + "/Templates/Loan_Creation.xlsx";
                templateName = "Loan_Creation.xlsx";
            } else if (data.uploadType == "Lender_Settelement") {
                urlPath = url + "/Templates/Settlement.xlsx";
                templateName = "Settlement.xlsx";
            } else if (data.uploadType == "Crif_Score") {
                urlPath = url + "/Templates/Crif_Score.xlsx";
                templateName = "CrifScore.xlsx";
            }


            if (urlPath != "") {
                axios({
                    url: urlPath,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', templateName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
                this.setState({ errors, showSpinner: false });
            } else {
                errors["globalError"] = "No Template Found";
                this.setState({ errors, showSpinner: false });
            }
        } else {
            errors["uploadType"] = "Please select Upload Type";
            this.setState({ errors, showSpinner: false });
        }
    };

    handleUploadTypeChange = async ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        let partnerType = { ...this.state.partnerType };

        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;
        partnerType = input.value == "Lender_Settelement" || input.value == "Crif_Score" ? "Lending Partner" : "Borrowing Partner";
        this.setState({ data, errors, partnerType });
    };
}
export default ExcelUploadModel;