import { Pie } from "react-chartjs-2";
import React, { Component } from "react";

// constructor({ principalPerc, interestPerc }) {
//   super();
//   alert()
//   this.state = {
//     labels: ["Principal Loan Amount", "Total Interest"],
//     datasets: [
//       {
//         data: [principalPerc, interestPerc],
//         backgroundColor: ["orange", "green"],
//       },
//     ],
//   };
// }
const PieChartComponent = ({ principalPerc, interestPerc }) => {
  let labels = ["Principal Loan Amount %", "Total Interest %"];
  let datasets = [
    {
      data: [principalPerc, interestPerc],
      backgroundColor: ["orange", "green"],
      borderColor: "#fff",
    },
  ];
  return (
    <div>
      <h4>Break-up of total payment</h4>
      <Pie
        data={{
          labels: labels,
          datasets: datasets,
        }}
        width={100}
        height={90}
      ></Pie>
      <br />
    </div>
  );
};

export default PieChartComponent;
