
import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import leadInfoService from "../../services/partner/leadInfoService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import _ from "lodash";
import { browserName, browserVersion, fullBrowserVersion, osName, osVersion, mobileVendor, mobileModel } from 'mobile-device-detect';
import SharedCache from "../../SharedCache";

class ConfirmMobileNumberModel extends InputDataForm {
    state = {
        data: {
            loanId: "",
            otp: "",
            loanStatus: "",
            kycStatus: "",
            isDraft: false,
            isWhatsApp: "checked"
        },
        errors: {},
        info: {},
        showSpinner: false,
        isRegenerateLink: false,
        isRegenerateText: false,
        counter: 0,
        timerId: 0,
        timer: "",
        isSubmitted: false,
        messageText: "",
        otpSent: false,
        otpAutoFill: false,
        otpType: "otp_confirmation",
        maskedMobileNo: "",
        showConfirmMobilePopup: false,
        popUpShowedForMobileNo: "",
        redirectionUrl: ""
    };
    schema = {
        loanId: Joi.string().required(),
        otp: Joi.number().required(),
        isWhatsApp: Joi.string().required(),
        mobileNo: Joi.string()
    };
    async componentDidMount() {
        if (sessionStorage.getItem("lead")) {
            const leadInfo = sessionStorage.getItem("lead");
            const model = JSON.parse(auth.decrypt(leadInfo));

            await this.getUserData(model.merchantCode, model.token, model.amount, model.trxnId, model.partnerId);
        }
    }
    async getUserData(merchantCode, token, amount, trxnId, partnerId) {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true });

        if (!merchantCode || !token || !amount || !trxnId) {
            errors["globalError"] = "Invalid Details provided";
            this.setState({
                errors,
                showSpinner: false,
            });
        } else {
            try {
                let response = await leadInfoService.getUserDetails(
                    merchantCode,
                    trxnId,
                    amount,
                    token
                );

                await leadInfoService.saveDeviceInfo(
                    partnerId,
                    merchantCode,
                    browserName,
                    browserVersion,
                    fullBrowserVersion,
                    osName,
                    osVersion,
                    mobileVendor,
                    mobileModel
                );

                if (response.success) {
                    data.loanId = response.data.loanId;
                    data.loanStatus = response.data.loanStatus;
                    data.isDraft = response.data.isDraft;
                    data.kycStatus = response.data.kycStatus;

                    const maskedMobileNo = response.contactDetails.maskedMobileNo;

                    this.setState({ data, showSpinner: false, maskedMobileNo });
                } else {
                    errors["globalError"] = response.message;
                    this.setState({
                        errors,
                        showSpinner: false,
                    });
                }
            } catch (ex) {
                alert(ex.message);
            }
        }
    }
    async getDetailsBasedOnLoanId(loanId, partnerId, redirectionUrl) {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true });

        if (!loanId) {
            errors["globalError"] = "Invalid Details provided";
            this.setState({
                errors,
                showSpinner: false,
            });
        } else {
            try {
                let response = await leadInfoService.getMaskedMobileNumber(
                    loanId
                );

                if (response.success) {
                    await leadInfoService.saveDeviceInfo(
                        partnerId,
                        response.data.merchantCode,
                        browserName,
                        browserVersion,
                        fullBrowserVersion,
                        osName,
                        osVersion,
                        mobileVendor,
                        mobileModel
                    );

                    data.loanId = loanId;
                    const maskedMobileNo = response.data.maskedMobileNo;

                    this.setState({ data, showSpinner: false, maskedMobileNo, redirectionUrl });
                } else {
                    errors["globalError"] = response.message;
                    this.setState({
                        errors,
                        showSpinner: false,
                    });
                }
            } catch (ex) {
                alert(ex.message);
            }
        }
    }
    sendOtp = async (loanId) => {
        const { data, errors, info, otpType, popUpShowedForMobileNo, maskedMobileNo } = this.state;

        if (data.isWhatsApp === "checked") {
            if (!popUpShowedForMobileNo) {
                this.setState({ popUpShowedForMobileNo: maskedMobileNo, showConfirmMobilePopup: true });
                return;
            }
            else if (popUpShowedForMobileNo !== maskedMobileNo) {
                this.setState({ popUpShowedForMobileNo: maskedMobileNo, showConfirmMobilePopup: true });
                return;
            }
        }

        delete errors["globalError"];
        delete info["otp"];

        this.setState({ showSpinner: true, errors, showConfirmMobilePopup: false });

        try {
            let response = {}

            if (this.state.showUpdateMobile) {
                response = await leadInfoService.generateOTPForMerchantChangeMobileNumber(
                    otpType,
                    loanId,
                    data.isWhatsApp,
                    data.mobileNo,
                    true
                );
            } else {
                response = await leadInfoService.generateOTPForMerchant(
                    otpType,
                    loanId,
                    data.isWhatsApp,
                    true
                );
            }

            if (response.success) {
                data.otp = "";

                this.setState({
                    data,
                    info,
                    errors,
                    showSpinner: false,
                    messageText: response.message,
                    otpSent: true
                });

                this.stopTimer();
                this.startTimer();

                //OTP Auto-Fill
                if ("OTPCredential" in window) {
                    const ac = new AbortController();

                    navigator.credentials
                        .get({
                            otp: { transport: ["sms"] },
                            signal: ac.signal,
                        })
                        .then((otp) => {
                            let { data } = this.state;
                            data.otp = otp.code;
                            this.setState({ data, otpAutoFill: true });
                            this.verifyOTP();
                            ac.abort();
                        })
                        .catch((err) => {
                            ac.abort();
                            console.log(err);
                        });
                }
            } else {
                errors["globalError"] = response.message;
                this.setState({
                    errors,
                    showSpinner: false,
                });
            }
        } catch (ex) {
            errors["globalError"] = ex.response.message;
            delete info["otp"];
            this.setState({
                errors,
                info,
                showSpinner: false,
            });
        }
    };
    verifyOTP = async () => {
        const { data, errors, info, otpType, otpAutoFill, redirectionUrl } = this.state;
        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            const verifyOtpResponse = await leadInfoService.verifyCustomOTPForMerchant(
                data.otp,
                otpType,
                data.loanId,
                otpAutoFill,
                data.isWhatsApp,
                this.state.showUpdateMobile,
                true,
                data.mobileNo,
                true
            );

            console.log("verifyOtpResponse", verifyOtpResponse);

            if (verifyOtpResponse.success) {
                this.props.handleMobileConfirmationResponse && this.props.handleMobileConfirmationResponse()
            } else {
                errors["otp"] = verifyOtpResponse.message;
                delete info["otp"];
                this.setState({ errors, data, showSpinner: false, isSubmitted: false });
            }
        } catch (ex) {
            this.stopTimer();
            this.startTimer();
            delete info["otp"];
            this.setState({ data, errors, showSpinner: false, isSubmitted: false });
        }
    };


    async handleConfirmMobileNumber(loanId) {

        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            const {
                success,
                message,
            } = await leadInfoService.confirmMerchantMobileNumber(loanId);

            if (success) {
                this.props.handleMobileConfirmationResponse && this.props.handleMobileConfirmationResponse()
            } else {
                this.setState({ showSpinner: false, isSubmitted: false });
            }
        } catch (ex) {

            this.setState({ showSpinner: false, isSubmitted: false });
        }
    }

    async handleCustomErrors() {
        const data = { ...this.state.data };
        let errors = { ...this.state.errors };

        const { otpAutoFill, otpType } = this.state;

        delete errors["globalError"];
        delete errors["otp"];

        if (!data.otp) {
            errors["otp"] = "Please enter OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (data.otp.length !== 4) {
            errors["otp"] = "OTP must me 4-digit";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (isNaN(data.otp)) {
            errors["otp"] = "Invalid OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else delete errors["otp"];

        this.setState({ errors });

        return errors;
    }
    handleWhatsAppConsent = async (isWhatsApp) => {
        const data = { ...this.state.data };

        data.isWhatsApp = isWhatsApp ? "checked" : "";

        this.setState({ data, otpType: isWhatsApp ? "otp_confirmation" : "Merchant_Authentication_OTP2" });
    };

    handleCloseFinalPopUp = () => {
        const data = { ...this.state.data };
        data.isWhatsApp = false;
        this.setState({ showConfirmMobilePopup: false, data, otpType: "Merchant_Authentication_OTP2" });
    };

    showUpdateMobile() {
        const data = { ...this.state.data };
        data.mobileNo = "";
        this.setState({ showUpdateMobile: true, data, otpSent: false })
    }

    doSubmit = async (e) => {
        e.preventDefault();
        let errors = await this.handleCustomErrors();

        if (_.isEmpty(errors)) await this.verifyOTP();
    };
};

export default ConfirmMobileNumberModel;
