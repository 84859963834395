import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { creditApiUrl } from "../../config.json";

export async function assignPersonalLoan(loanId, lenderName) {
    try {
        const response = await http.post(`${apiUrl}/assignPersonalLoan`, {
            loanId, lenderName
        });
        return response;
    } catch (e) {
        return e;
    }
}
