import React from "react";
import { Form, Col, InputGroup, Button, Nav } from "react-bootstrap";
import ForgotPasswordModel from "../../models/login/forgotPasswordModel";
import SpinnerComponent from "../../components/common/spinner";
import { Link } from "react-router-dom";

class ForgotPasswordForm extends ForgotPasswordModel {
  render() {
    const {
      data,
      errors,
      showSpinner,
      info,
      buttonText,
      isValidUserName,
      isValidOTP,
      isPasswordChanged,
      counter,
      isRegenerateLink,
      isRegenerateText,
      errors: {},
    } = this.state;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>Change Password</h2> <br />
        {!isPasswordChanged && (
          <Form onSubmit={this.doSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="5" controlId="username">
                <Form.Label>Email*</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email Id"
                    name="userName"
                    value={data["userName"]}
                    onChange={this.handleChange}
                    autoComplete="off"
                    disabled={isValidUserName}
                    autoFocus={true}
                  />{" "}
                </InputGroup>
                <Form.Text className="text-muted">
                  An OTP will be sent on your registered mobile number / email
                  address
                </Form.Text>
                {errors["userName"] && (
                  <div className="alert alert-danger">{errors["userName"]}</div>
                )}
              </Form.Group>
            </Form.Row>{" "}
            {isValidUserName && (
              <Form.Row>
                <Form.Group as={Col} md="5" controlId="otp">
                  <Form.Label>Enter OTP*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    value={data["otp"]}
                    onChange={this.handleChange}
                    autoComplete="off"
                    maxLength="6"
                    disabled={isValidOTP}
                    autoFocus={isValidUserName && !isValidOTP}
                  />{" "}
                  {info["otp"] && (
                    <Form.Text
                      className="text-muted"
                      style={{ color: "green" }}
                    >
                      {info["otp"]}
                    </Form.Text>
                  )}
                  {isRegenerateLink && (
                    <Nav.Item id="regenerateOTP">
                      <Nav.Link
                        className="underline"
                        onClick={this.sendOtp}
                        style={{
                          padding: 0,
                          float: "right",
                          textDecoration: "underline",
                        }}
                      >
                        Re-generate OTP
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {errors["otp"] && (
                    <div className="alert alert-danger">{errors["otp"]}</div>
                  )}{" "}
                  {isRegenerateText && (
                    <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                  )}
                </Form.Group>
              </Form.Row>
            )}
            {isValidOTP && (
              <React.Fragment>
                <Form.Row>
                  <Form.Group as={Col} md="5" controlId="password">
                    <Form.Label>Enter New Password*</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      name="password"
                      value={data["password"]}
                      onChange={this.handleChange}
                      autoComplete="off"
                      autoFocus={isValidUserName && isValidOTP}
                    />{" "}
                    {errors["password"] && (
                      <div className="alert alert-danger">
                        {errors["password"]}
                      </div>
                    )}{" "}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="5" controlId="confirmPassword">
                    <Form.Label>Re-enter New Password*</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      name="confirmPassword"
                      value={data["confirmPassword"]}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />{" "}
                    {errors["confirmPassword"] && (
                      <div className="alert alert-danger">
                        {errors["confirmPassword"]}
                      </div>
                    )}{" "}
                  </Form.Group>
                </Form.Row>
              </React.Fragment>
            )}
            <Button className="ff-button" type="submit">
              {buttonText}
            </Button>
            <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
              {errors["globalError"] && (
                <div className="alert alert-danger">
                  {errors["globalError"]}
                </div>
              )}
            </Form.Group>
          </Form>
        )}
        {isPasswordChanged && (
          <Form>
            <Form.Row>
              <Form.Group as={Col} md="5" controlId="message">
                <Form.Label style={{ fontWeight: "bold", fontSize: 15 }}>
                  Your password has been changed. Click{" "}
                  <Link
                    to="/login"
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                  >
                    here
                  </Link>{" "}
                  to Login.
                </Form.Label>
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </React.Fragment>
    );
  }
}

export default ForgotPasswordForm;
