import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import React from "react";
import queryString from 'query-string'
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";

class DigilockerResponse extends InputDataForm {
    state = {
        digio_doc_id: "",
        status: "",
        message: "",
        showSpinner: false,
        redirectPath: false,
        state: null
    }
    async componentDidMount() {

        const values = queryString.parse(this.props.location.search);
        const digio_doc_id = values.digio_doc_id ? values.digio_doc_id : "";
        const status = values.status ? values.status : "";
        const message = values.message ? values.message : "";
        this.setState({ digio_doc_id: digio_doc_id, showSpinner: true, status: status });

        if (!digio_doc_id) {
            this.setState({ redirectPath: "/invalidData", state: { message: "Invalid parameters provided" } });
        } else {
            const result = await auth.updateDigilockerResponse(digio_doc_id, status, message);
            if (result.success) {
                if (!auth.getCurrentUser())
                    this.setState({ redirectPath: `/${result.partnerId}/merchantAuthenticate`, state: { loanData: { loanId: result.loanId, partnerId: result.partnerId, redirectionUrl: `/applyLoan/${result.loanId}` } } });
                else{
                    if(result.key==="eKyc"){
                        this.setState({ redirectPath: `/applyLoan/${result.loanId}`, state: { activeTab: result.isForceMandate ? "" : "kycTab" } });
                    }
                    else{
                        this.setState({ redirectPath: `/verifyLoanContract/${result.loanId}`, state: {  adharLinkedMobileOtpVerify:"success" , message: "Adhaar OTP varification Successfull"} })
                    }      
                }
            }    
            else
              {
                if(result.key==="eSign"){
                    this.setState({ redirectPath: `/verifyLoanContract/${result.loanId}`, state: {  adharLinkedMobileOtpVerify:"pending", message:result.message } })
                 }
                 else{
                    this.setState({ redirectPath: "/invalidData", state: { message: result.message } });
                 }
             }
               
        }
    }
    render() {
        const { redirectPath, showSpinner, status, state } = this.state;
        return (
            <>
                <SpinnerComponent showSpinner={showSpinner} />
                {status === "success" && <p style={{ fontSize: 15, fontWeight: "bold" }}>Please wait as we fetch information from Digilocker...</p>}
                {redirectPath && (
                    <Redirect
                        to={{
                            pathname: redirectPath,
                            state
                        }}
                    />
                )}
            </>
        );
    }
}
export default DigilockerResponse;
