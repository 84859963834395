import InputDataForm from "../../components/common/form";
import * as disbursalService from '../../services/disbursal/disbursalService';
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class DisbursalTransactionsModel extends InputDataForm {
  state = {
    data: {
      loanNo: "",
      fromDate: momentJalaali().subtract(1, "months"),
      toDate: momentJalaali(),
      status: "",
      customerRefNo: "",
      loanId: "",
      type: "",
    },
    showSpinner: false,
    errors: {},
    transactionList: [],
    sortBy: "-insertedOn",
    showConfirmation: false,
    itemsCount: 0,
    pageSize: 10,
    currentPage: 1
  };

  schema = {
    loanNo: Joi.any(),
    fromDate: Joi.any(),
    toDate: Joi.any(),
    status: Joi.any(),
    type: Joi.any()
  }

  resetData() {
    let data = { ...this.state.data };
    data.customerRefNo = "";
    data.loanId = "";

    this.setState({ data });
  }

  async getTransactionList() {
    let data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      data.status = data.status == "ALL" ? null : data.status;
      data.type = data.type == "ALL" ? null : data.type;
      data.pageSize = this.state.pageSize;
      data.currentPage = this.state.currentPage;

      let response = await disbursalService.disbursalTransactionList(data);
      let transactionList = response && response.data ? response.data.transactions : [];
      let total = response && response.data ? response.data.total : 0;

      this.setState({ showSpinner: false, transactionList: transactionList, itemsCount: total });

    } catch (ex) {
      this.setState({ showSpinner: false, transactionList: [] });
    }
  }

  submitPortfolio = async (e) => {
    e.preventDefault();
    this.setState({ pageSize: 10, currentPage: 1,  errors: {},  successMsg: null}, () => { this.getTransactionList() });
  };

  async componentDidMount() {

    if (!auth.getCurrentUser()) return (window.location = "/login");

    const user = auth.getCurrentUser();
    if (user.role !== "Admin") return (window.location = "/");

    await this.getTransactionList();
  }

  async handleCustomErrors(errors) {
    return errors;
  }

  toggleConfirmationModal = () => {
    this.setState({ showConfirmation: !this.state.showConfirmation })
  }

  showReInitiateModel(transaction) {
    let data = { ...this.state.data };
    data.loanId = transaction.loanId;
    data.customerRefNo = transaction.customerRefNo;
    this.setState({ data, showConfirmation: true });
  }

  async handleReInitiate() {

    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    const info = { ...this.state.info };
    this.setState({ showSpinner: true });
    try {
      const { success, message } = await disbursalService.reInitiateTransaction(data);

      if (success) {
        info.globalInfo = message;
        await this.getTransactionList();
      }
      else {
        errors.globalError = message;
      }
      this.setState({ errors, showSpinner: false, successMsg: message, showConfirmation: false });
    }
    catch (ex) {
      errors.globalError = ex.message;
      this.setState({ errors, successMsg: null, showSpinner: false, showConfirmation: false });
    }
  }

  handleTransactionPageChange = (page) => {
    this.setState({ currentPage: page }, () => { this.getTransactionList(); });
  };

  handleTransactionPagePrevious = () => {
    let { currentPage } = this.state;
    this.setState({ currentPage: currentPage - 1 }, () => { this.getTransactionList(); });
  };

  handleTransactionPageNext = () => {
    let { currentPage } = this.state;
    this.setState({ currentPage: currentPage + 1 }, () => { this.getTransactionList(); });

  };



}

export default DisbursalTransactionsModel;
