import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PortfolioModel from "../../models/portfolio/portfolioModel";
import { Form, Col, Button, Table, Row, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import moment from "moment";
import DatePicker from "react-datepicker2";
import ReactExport from "react-export-excel";
import NumberFormat from 'react-number-format';
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";

class Portfolio extends PortfolioModel {
  getPagedBorrowerData = () => {
    const { pageSize, currentPage, loanList } = this.state;

    const filteredLoans = paginate(loanList, currentPage, pageSize);

    return { totalCount: loanList.length, data: filteredLoans, allborrowerList: loanList };
  };

  renderExcel() {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let excelList = this.state.excelList || []
    return (
      <ExcelFile hideElement={true} filename={`Portfolio_${moment(new Date()).format("DDMMYYYY")}`}>
        <ExcelSheet data={excelList} name="loanList">
          <ExcelColumn
            label="Disbursed On"
            value="disbursedOn"
          ></ExcelColumn>
          <ExcelColumn
            label="Loan No"
            value="loanNo"
          ></ExcelColumn>
          {
            this.state.userRole == "Borrowing Partner" ?
              <ExcelColumn
                label="Merchant Code"
                value="merchantCode"
              ></ExcelColumn> :
              <ExcelColumn
                label="Pan"
                value="pan"
              ></ExcelColumn>
          }
          <ExcelColumn
            label="Name"
            value="name"
          ></ExcelColumn>
          <ExcelColumn
            label="Loan Amount"
            value="amount"
          ></ExcelColumn>
          <ExcelColumn
            label="Principal Outstanding"
            value="currentInvestment"
          ></ExcelColumn>
          <ExcelColumn
            label="Principal Recovered"
            value="principalRecovered"
          ></ExcelColumn>
          <ExcelColumn
            label="Interest Recovered"
            value="intersetRecovered"
          ></ExcelColumn>
          {
            this.state.userRole == "Borrowing Partner" ?
              <ExcelColumn
                label="Product Id"
                value="productId"
              ></ExcelColumn> : <ExcelColumn
                label="Loan Ref Id"
                value="lenderLoanId"
              ></ExcelColumn>
          }
          <ExcelColumn
            label="Disbursal Amount"
            value="disbursalAmount"
          ></ExcelColumn>
        </ExcelSheet>
      </ExcelFile>
    )
  }


  render() {
    const {
      data,
      showSpinner,
      showRepaymentModal,
      repaymentList,
      loanNo,
      userRole,
      summary,
      sortBy,
      isDisabled,
      pageSize,
      currentPage,
      loanList,
      loanList: allborrowerList,
      errors
    } = this.state;

    let borrowingPartnerMaxEndDate = moment(data["fromDate"]).add(30, "days")
    let lendingPartnerMaxEndDate = moment(data["fromDate"]).add(90, "days")
    let currentDate = moment()

    // const { totalCount, data: loanList, allborrowerList: allborrowerList } = this.getPagedBorrowerData();

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 style={{ textAlign: "center" }}>My Portfolio</h2>{" "}
        {userRole !== "Borrowing Partner" && (
          <React.Fragment>
            {/* <Form>
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                Summarized Data
              </h5>{" "}
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Total Lifetime Investment:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.totalLifeTimeInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>
                <Form.Label column sm="4">
                  Payout Amount:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.payout} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>{" "}
                <Form.Label column sm="4">
                  Active Investment:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.activeInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>
              </Form.Group>{" "}
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="4">
                  Current Investment:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.currentInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>
                <Form.Label column sm="4">
                  Principal Recovered:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.totalPrincipleRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>{" "}
                <Form.Label column sm="4">
                  Interest Recovered:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.totalInterestRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Amount in Virtual Account:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.totalAmtInVA} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>
                <Form.Label column sm="4">
                  Delayed Payment 30 days:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.repaymentDelay30Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>{" "}
                <Form.Label column sm="4">
                  Delayed Payment 60 days:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.repaymentDelay60Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>
              </Form.Group>{" "}
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="4">
                  Delayed Payment 90 days:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.repaymentDelay90Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>
                <Form.Label column sm="4">
                  Delayed Payment beyond 90 days:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat value={summary.repaymentDelay120Days} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </span>
                </Form.Label>{" "}
                <Form.Label column sm="4">
                  Return on Investment:{" "}
                  <span style={{ color: "black" }}>
                    {summary.roi}
                    {summary.roi && (
                      <i className="fa fa-percent" aria-hidden="true"></i>
                    )}
                  </span>
                </Form.Label>
              </Form.Group>{" "}
            </Form>{" "} */}
            <h5
              className="fundfinaColor"
              style={{ textDecoration: "underline" }}
            >
              Report
            </h5>{" "}
            <Form>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="fromDate">
                  <Form.Label>From Date</Form.Label>
                  <DatePicker
                    name="fromDate"
                    id="fromDate"
                    value={data["fromDate"]}
                    max={moment()}
                    inputFormat="DD/MM/YYYY"
                    isGregorian={true}
                    className="form-control"
                    inputReadOnly={true}
                    showTodayButton={true}
                    timePicker={false}
                    onChange={(value) => this.handleFromDateChange(value)}
                    placeholder="Enter From Date"
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="toDate">
                  <Form.Label>To Date</Form.Label>
                  <DatePicker
                    name="toDate"
                    id="toDate"
                    value={data["toDate"]}
                    max={lendingPartnerMaxEndDate > currentDate ? currentDate : lendingPartnerMaxEndDate}
                    inputFormat="DD/MM/YYYY"
                    isGregorian={true}
                    className="form-control"
                    inputReadOnly={true}
                    showTodayButton={false}
                    timePicker={false}
                    onChange={(value) => this.handleToDateChange(value)}
                    placeholder="Enter To Date"

                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={data["name"]}
                    onChange={this.handleChange}
                    autoComplete="off"
                    maxLength="100"
                  />
                </Form.Group>{" "}
                <Form.Group as={Col} md="4" controlId="loanNo">
                  <Form.Label>Loan No.</Form.Label>
                  <Form.Control
                    type="text"
                    name="loanNo"
                    value={data["loanNo"]}
                    onChange={this.handleChange}
                    autoComplete="off"
                    maxLength="100"
                  />
                </Form.Group>{" "}
                <Form.Group as={Col} md="4">
                  <Button
                    className="ff-button"
                    type="submit"
                    onClick={this.submitPortfolio}
                    style={{ marginTop: 30, marginLeft: 10 }}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    className="ff-button"
                    type="button"
                    style={{ marginTop: 30, marginLeft: 10 }}
                    onClick={() => this.onClickExcelExport(true)}
                  >
                    Export to Excel
                  </Button>
                  {
                    this.state.downloadExcel ? this.renderExcel() : ""
                  }
                </Form.Group>
              </Form.Row>
            </Form>
          </React.Fragment>
        )}{" "}
        {userRole === "Borrowing Partner" && (
          <Form onSubmit={this.submitPortfolio}>
            <Form.Row>
              {" "}
              <Form.Group as={Col} md="4" controlId="fromDate">
                <Form.Label>From Date</Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={borrowingPartnerMaxEndDate > currentDate ? currentDate : borrowingPartnerMaxEndDate}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={data["name"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>{" "}
              <Form.Group as={Col} md="4" controlId="loanNo">
                <Form.Label>Loan No.</Form.Label>
                <Form.Control
                  type="text"
                  name="loanNo"
                  value={data["loanNo"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>{" "}
              <Form.Group as={Col} md="4" controlId="productId">
                <Form.Label>Product Id</Form.Label>
                <Form.Control
                  type="text"
                  name="productId"
                  value={data["productId"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>{" "}
              <Form.Group as={Col} md="4" controlId="merchantCode">
                <Form.Label>Merchant Code</Form.Label>
                <Form.Control
                  type="text"
                  name="merchantCode"
                  value={data["merchantCode"]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  maxLength="100"
                />
              </Form.Group>{" "}
              <Form.Group as={Col} md="4">
                <Button
                  className="ff-button"
                  type="submit"
                  style={{ marginTop: 30, marginLeft: 10 }}
                >
                  Apply Filters
                </Button>

                <Button
                  className="ff-button"
                  type="button"
                  style={{ marginTop: 30, marginLeft: 10 }}
                  onClick={() => this.onClickExcelExport()}
                >
                  Export to Excel
                </Button>
                {
                  this.state.downloadExcel ? this.renderExcel() : ""
                }
              </Form.Group>
            </Form.Row>
          </Form>
        )}

        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}

        {userRole === "Borrowing Partner" && (
          <>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Disbursal Date</th>

                  <th>
                    Loan No
                  </th>
                  <th>
                    Merchant Code
                  </th>
                  <th>
                    Name{" "}
                    {sortBy == "userDetails.firstName" && (
                      <i
                        class="fa fa-sort-asc"
                        aria-hidden="true"
                        onClick={this.sortByName}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy == "-userDetails.firstName" && (
                      <i
                        class="fa fa-sort-desc"
                        aria-hidden="true"
                        onClick={this.sortByName}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy != "-userDetails.firstName" &&
                      sortBy != "userDetails.firstName" && (
                        <i
                          class="fa fa-sort"
                          aria-hidden="true"
                          onClick={this.sortByName}
                          style={{ cursor: "pointer" }}
                        ></i>
                      )}
                  </th>
                  <th style={{ minWidth: 100 }}>
                    Loan Amount{" "}
                    {sortBy == "amount" && (
                      <i
                        class="fa fa-sort-asc"
                        aria-hidden="true"
                        onClick={this.sortByAmount}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy == "-amount" && (
                      <i
                        class="fa fa-sort-desc"
                        aria-hidden="true"
                        onClick={this.sortByAmount}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy != "-amount" && sortBy != "amount" && (
                      <i
                        class="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortByAmount}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </th>

                  <th>Disbursal Amount</th>
                  <th style={{ minWidth: 120 }}>
                    Principal Outstanding {" "}
                    {sortBy == "principalAmount.outstanding" && (
                      <i
                        class="fa fa-sort-asc"
                        aria-hidden="true"
                        onClick={this.sortByPrincipal}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy == "-principalAmount.outstanding" && (
                      <i
                        class="fa fa-sort-desc"
                        aria-hidden="true"
                        onClick={this.sortByPrincipal}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy != "-principalAmount.outstanding" && sortBy != "principalAmount.outstanding" && (
                      <i
                        class="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortByPrincipal}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </th>
                  <th>Principal Recovered</th>
                  <th>Interest Recovered</th>
                  <th>Product Id</th>
                </tr>
              </thead>
              <tbody>
                {loanList &&
                  loanList.map((loan) => (
                    <tr key={loan.loanId}>
                      <td>{loan.disbursedOn}</td>
                      <td>
                        <a
                          key={loan.loanId}
                          href="#"
                          onClick={() => this.getRepaymentDetails(loan.loanId)}
                        >
                          {loan.loanNo}
                        </a>
                      </td>
                      <td>{loan.merchantCode}</td>
                      <td>{loan.name}</td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>

                      <td> {loan.disbursalAmount && <NumberFormat value={loan.disbursalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>

                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.currentInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.principalRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.intersetRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td>{loan.productId}</td>
                    </tr>
                  ))}
                {!loanList ||
                  (loanList.length == 0 && (
                    <tr key="no">
                      <td colSpan="11">No record found</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div style={{ width: "100%", overflowX: "scroll" }}>
              <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={this.onPageChange}
                onPagePrevious={this.onPagePrevious}
                onPageNext={this.onPageNext}
              />
            </div>
          </>
        )}
        {userRole !== "Borrowing Partner" && (
          <React.Fragment>
            <h5
              className="fundfinaColor"
              style={{ textDecoration: "underline" }}
            >
              Portfolio (Loan-wise)
            </h5>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Disbursal Date</th>

                  <th>
                    Loan No
                  </th>
                  <th>
                    Pan No
                  </th>
                  <th>
                    Name{" "}
                    {sortBy == "userDetails.firstName" && (
                      <i
                        class="fa fa-sort-asc"
                        aria-hidden="true"
                        onClick={this.sortByName}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy == "-userDetails.firstName" && (
                      <i
                        class="fa fa-sort-desc"
                        aria-hidden="true"
                        onClick={this.sortByName}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy != "-userDetails.firstName" &&
                      sortBy != "userDetails.firstName" && (
                        <i
                          class="fa fa-sort"
                          aria-hidden="true"
                          onClick={this.sortByName}
                          style={{ cursor: "pointer" }}
                        ></i>
                      )}
                  </th>
                  <th style={{ minWidth: 100 }}>
                    Loan Amount{" "}
                    {sortBy == "amount" && (
                      <i
                        class="fa fa-sort-asc"
                        aria-hidden="true"
                        onClick={this.sortByAmount}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy == "-amount" && (
                      <i
                        class="fa fa-sort-desc"
                        aria-hidden="true"
                        onClick={this.sortByAmount}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy != "-amount" && sortBy != "amount" && (
                      <i
                        class="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortByAmount}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </th>

                  <th>Disbursal Amount</th>
                  <th style={{ minWidth: 120 }}>
                    Principal Outstanding {" "}
                    {sortBy == "principalAmount.outstanding" && (
                      <i
                        class="fa fa-sort-asc"
                        aria-hidden="true"
                        onClick={this.sortByPrincipal}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy == "-principalAmount.outstanding" && (
                      <i
                        class="fa fa-sort-desc"
                        aria-hidden="true"
                        onClick={this.sortByPrincipal}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                    {sortBy != "-principalAmount.outstanding" && sortBy != "principalAmount.outstanding" && (
                      <i
                        class="fa fa-sort"
                        aria-hidden="true"
                        onClick={this.sortByPrincipal}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </th>
                  <th>Principal Recovered</th>
                  <th>Interest Recovered</th>
                  <th>Loan Ref Id</th>
                </tr>
              </thead>
              <tbody>
                {loanList &&
                  loanList.map((loan) => (
                    <tr key={loan.loanId}>
                      <td>{loan.disbursedOn}</td>
                      <td>
                        <a
                          key={loan.loanId}
                          href="#"
                          onClick={() => this.getRepaymentDetails(loan.loanId)}
                        >
                          {loan.loanNo}
                        </a>
                      </td>
                      <td>{loan.pan}</td>
                      <td>{loan.name}</td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>

                      <td> {loan.disbursalAmount && <NumberFormat value={loan.disbursalAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</td>

                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.currentInvestment} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.principalRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={loan.interestRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td>{loan.lenderDetails ? loan.lenderDetails.lenderLoanId : ""}</td>
                    </tr>
                  ))}
                {!loanList ||
                  (loanList.length == 0 && (
                    <tr key="no">
                      <td colSpan="11">No record found</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <div style={{ width: "100%", overflowX: "scroll" }}>
              <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={this.onPageChange}
                onPagePrevious={this.onPagePrevious}
                onPageNext={this.onPageNext}
              />
            </div>
          </React.Fragment>
        )}
        <Modal
          show={showRepaymentModal}
          onHide={this.closeModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="example-modal-sizes-title-xl"
          centered
          style={{ width: "auto !important" }}
        >
          <Modal.Header closeButton>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-3">
                  <span class="fundfinaColor">Loan No:</span>
                  {loanNo}
                </div>
              </div>
              <div class="row">
                <div class="col-md-10">
                  <ExcelFile
                    element={
                      <Button
                        className="ff-button"
                        type="button"
                        style={{ marginTop: 10 }}
                      >
                        Export to Excel
                      </Button>
                    }
                  >
                    <ExcelSheet data={repaymentList} name="RepaymentData">
                      <ExcelColumn
                        label="EMI No."
                        value="emiNumber"
                      ></ExcelColumn>
                      <ExcelColumn
                        label="EMI Amount"
                        value="rePaymentAmount"
                      ></ExcelColumn>
                      <ExcelColumn
                        label="Due On"
                        value={(col) =>
                          col.rePaymentDate
                            ? moment(col.rePaymentDate).format("DD/MM/yyyy")
                            : ""
                        }
                      ></ExcelColumn>
                      <ExcelColumn
                        label="Status"
                        value="rePaymentStatus"
                      ></ExcelColumn>
                      <ExcelColumn
                        label="Paid On"
                        value={(col) =>
                          col.rePaymentReceivedDate
                            ? moment(col.rePaymentReceivedDate).format(
                              "DD/MM/yyyy"
                            )
                            : ""
                        }
                      ></ExcelColumn>
                      <ExcelColumn
                        label="Interest Recovered"
                        value="interestAmount"
                      ></ExcelColumn>
                      <ExcelColumn
                        label="Principal Outstanding"
                        value="principalOutstanding"
                      ></ExcelColumn>
                      <ExcelColumn
                        label="Principal Recovered"
                        value="principalRecovered"
                      ></ExcelColumn>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflow: "scroll" }}>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>EMI No.</th>
                  <th>EMI Amount</th>
                  <th>Due On</th>
                  <th>Status</th>
                  <th>Paid On</th>
                  <th>Interest Recovered</th>
                  <th>Principal Outstanding</th>
                  <th>Principal Recovered</th>
                </tr>
              </thead>
              <tbody>
                {repaymentList &&
                  repaymentList.map((emi) => (
                    <tr key={emi._id}>
                      <td>{emi.emiNumber}</td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={emi.rePaymentAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td>{moment(emi.rePaymentDate).format("DD/MM/yyyy")}</td>
                      <td style={{ textAlign: "right" }}>
                        {emi.rePaymentStatus}
                      </td>
                      <td>
                        {emi.rePaymentReceivedDate &&
                          moment(emi.rePaymentReceivedDate).format(
                            "DD/MM/yyyy"
                          )}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={emi.interestAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={emi.principalOutstanding} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <NumberFormat value={emi.principalRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                    </tr>
                  ))}
                {!repaymentList ||
                  (repaymentList.length == 0 && (
                    <tr key="no">
                      <td colSpan="10">No record found</td>
                    </tr>
                  ))}
              </tbody>
            </Table>{" "}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Portfolio;
