import React from "react";
import { Link, Redirect } from "react-router-dom";
import CollectionsQueue from './collectionsQueue';
import CollectionsQueueLoanWise from './collectionsQueueLoanWise';
import CollectionsQueueMainModel from '../../models/collectionsQueue/collectionsQueueMainModel';
import { Form, Col, Button, Table, Modal, FormCheck } from "react-bootstrap";

class CollectionsQueueMain extends CollectionsQueueMainModel {
    render() {
        const { emiView, loanNo } = this.state;
        return (
            <React.Fragment>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="switch">
                            <FormCheck
                                id="switchEnabled"
                                type="switch"
                                checked={emiView}
                                onChange={this.toggleChange}
                                label="EMI View"
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>

                {emiView &&
                    <CollectionsQueue
                        handler={this.handler}
                        loanNo={loanNo}
                    />}
                {!emiView &&
                    <CollectionsQueueLoanWise
                        handler={this.handler}
                    />}
            </React.Fragment>
        );
    }
}

export default CollectionsQueueMain;
