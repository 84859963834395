import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/loan";

export async function isValidForNewLoan(partnerId) {
  const response = await http.post(apiEndpoint + "/isValidForNewLoan", {
    partnerId: partnerId ? partnerId : null
  });
  return response;
}

export async function borrowerLoanList(partnerId) {
  const { data } = await http.post(apiEndpoint + "/borrowerLoanList", {
    partnerId: partnerId ? partnerId : null
  });
  return data;
}

export default {
  isValidForNewLoan,
  borrowerLoanList,
};
