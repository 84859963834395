import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import PaymentLinkWrapperModel from "../../models/paymentLinksWrapperModel/paymentLinksWrapperModel";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import { Tabs, Tab } from "react-bootstrap";
import PaymentLink from '../paymentLinks/paymentLinks';
import ENachList from '../enachList/enachList';

class paymentLinksWrapper extends PaymentLinkWrapperModel {

  renderTabs = () => {
    let { tabs, activeTab } = this.state;

    let elements = []
    for (let i = 0; i < tabs.length; i++) {
      elements.push(
        <Tab eventKey={tabs[i].title} title={tabs[i].title}></Tab>
      )
    }
    return (<Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.onChangeTab} className="tab-fixed-top">
      {elements}
    </Tabs>
    )
  }

  render() {
    const {
      pageSize,
      currentPage,
      showSpinner,
      successMsg,
      errors
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}

        {this.renderTabs()}
        {this.state.activeTab == "Upi" ? <PaymentLink {...this.props}></PaymentLink> : ""}

        {this.state.activeTab == "ENach" ? <ENachList {...this.props}></ENachList> : ""}
        <div style={{ width: "100%", overflowX: "scroll" }}>
          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
        </div>
      </React.Fragment >
    );
  }
}

export default paymentLinksWrapper;
