class SharedCache {

    static tokenKey = "token";
    static user = "user";
    static partner = "partner";
    static hideFFLogo = "hideFFLogo";
    static app = null;

    static cache = null;

    static init(data) {

        if (this.cache == null) {

            this.cache = data;
        }
        else {
            this.cache = {...this.cache, ...data}
        }
    }

    static get(key) {

        if (this.cache)
            return this.cache[key];

        return null;
    }

    static set(key, value) {

        if (this.cache == null) {

            this.cache = {};
            this.cache[key] = value;
        }
        else {

            this.cache[key] = value;
        }
    }
}

export default SharedCache;