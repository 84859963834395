import InputDataForm from "../../components/common/form";
import * as kycFormService from "../../services/borrow/kycFormService";
import * as scrutinyUserService from "../../services/scrutiny/scrutinyUserService";
import * as commonService from "../../services/common/commonService";
import auth from "../../services/common/authService";
import moment from "moment";
import Joi from "joi-browser";
import _ from "lodash";
import momentJalaali from "moment-jalaali";

class ScrutinyInfoModel extends InputDataForm {
  state = {
    data: {
      userId: "",
      userRole: "",
      clientCode: "",
      dob: "",
      firstName: "",
      middleName: "",
      lastName: "",
      fullName: "",
      employment: "",
      panNo: "",
      add1: "",
      add2: "",
      add3: "",
      addressType: "Correspondence",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      isRetailer: false,
      alternateEmail: "",
      alternateMobile: "",
      emailId: "",
      mobileNo: "",
      kycStatus: "",
      entityName: "",
      bankList: [],
      gstList: [],
      userRole: "",
      partnerName: "",
      udyogAadhaar:{},
      udyamAadhaar:{},
      fullAddress: "",
      panFileName: "",
      panFilePath: "",
      profileFileName: "",
      profileFilePath: "",
      typeOfEntity: "",
      questionnairre: {},
      adminStatus: "",
      remarks: "",
      merchantCode: "",
      poaDetails: [],
      kycStatusColor: "black",
      poaDocType: "",
      reviewDoc: "",
      detailsMismatch: "",
      poiName: "",
      poaName: "",
      poiDob: null,
      poaDob: null
    },
    showSpinner: false,
    optionalDocumentList: [],
    errors: {},
    popUpPath: "",
    showPopUp: false,
    isSubmitted: false,
    bankDocDisplay: false,
    evaluatorRole: "",
    bankDoc: {
      bankName: "",
      branch: "",
      accountNo: "",
      ifsc: "",
      isVerified: "",
      docPath: "",
      nameWithBank: "",
      details: "",
      code: "",
      message: ""
    },
    gstDocDisplay: false,
    gstDoc: {
      gstNo: "",
      isVerified: "",
      corporateName: "",
      tradeName: "",
      legalName: "",
      address: "",
      ctb: "",
      rgdt: "",
      status: "",
      dty: "",
      docPath: "",
      details: "",
      code: "",
      message: ""
    },
    panDocDisplay: false,
    panDoc: {
      panNo: "",
      type: "",
      isDocVerified: "",
      isNumberVerified: "",
      isDocMatch: "",
      is_pan_dob_valid: "",
      name_matched: "",
      name: "",
      fathers_name: "",
      id_no: "",
      dob: "",
      docPath: "",
      api: {
        details: "",
        code: "",
        message: ""
      },
      ocr: {
        details: "",
        code: "",
        message: ""
      }
    },
    poaDocDisplay: false,
    poaDoc: {
      poaNo: "",
      poaType: "",
      poaDoc: "",
      poaBackDoc: "",
      docVerification: {
        isDocVerified: "",
        isNumberVerified: "",
        isDocMatch: "",
        type: "",
        docType: "",
        dl: {
          ocr: {
            name: "",
            id_no: "",
            dob: "",
            id_type: "",
          },
          verificationResponse: {
            verified: "",
            Date_Of_Issue: "",
            NT: "",
            Old_New_DL_No: "",
            Holders_Name: "",
            Hazardous_Valid_Till: "",
            NonTransport: "",
            Transport: "",
            CurrentStatus: "",
            LastTransactionAt: ""
          }
        },
        passport: {
          ocr: {
            name: "",
            id_no: "",
            dob: "",
            id_type: "",
            gender: "",
            country_code: "",
            sur_name: "",
            nationality: "",
            place_of_birth: "",
            place_of_issue: "",
            photoPath: ""
          },
        },
        uid: {
          ocr: {
            name: "",
            id_no: "",
            dob: "",
            id_type: "",
            gender: "",
            address: "",
            photoPath: "",
            address_information: {
              address: "",
              locality_or_post_office: "",
              district_or_city: "",
              state: "",
              pincode: ""
            }
          }
        },
        voterId: {
          ocr: {
            name: "",
            id_no: "",
            fathers_name: "",
            id_type: "",
            gender: "",
            address: "",
            date_of_issue: "",
            photoPath: "",
            address_information: {
              address: "",
              locality_or_post_office: "",
              district_or_city: ""
            }
          },
          verificationResponse: {
            verified: "",
            result_id_no: "",
            result_name: "",
            pc_name: "",
            st_code: "",
            ps_lat_long_1_coordinate: "",
            gender: "",
            rln_name_v2: "",
            rln_name_v1: "",
            rln_name_v3: "",
            name_v1: "",
            epic_no: "",
            ac_name: "",
            name_v2: "",
            name_v3: "",
            ps_lat_long: "",
            pc_no: "",
            last_update: "",
            id: "",
            dist_no: "",
            ps_no: "",
            pc_name: "",
            ps_name_v1: "",
            st_name: "",
            dist_name: "",
            rln_type: "",
            pc_name_v1: "",
            part_name_v1: "",
            ac_name_v1: "",
            part_no: "",
            dist_name_v1: "",
            ps_lat_long_0_coordinate: "",
            _version_: "",
            name: "",
            section_no: "",
            ac_no: "",
            slno_inpart: "",
            rln_name: "",
            age: "",
            part_name: "",
            id_no: ""
          }
        }
      },
      api: {
        details: "",
        code: "",
        message: ""
      },
      ocr: {
        details: "",
        code: "",
        message: ""
      }
    },
    panDocVerification: {},
    userData: {},
    edit: false,
    activeTab: "scrutinyTab",
    poaVerificationDocList: [],
    reviewDocList: [],
    isPartnerUser: false,
    partnerId: null,
    merchantCode: null,
    detailsMismatchList: []
  };
  schema = {
    adminStatus: Joi.string().required(),
    remarks: Joi.string().allow(),
    poaDocType: Joi.string().allow(),
    reviewDoc: Joi.string().allow(),
    detailsMismatch: Joi.string().allow(""),
    poiName: Joi.string().allow(""),
    poaName: Joi.string().allow(""),
    poiDob: Joi.string().allow("", null),
    poaDob: Joi.string().allow("", null)
  };
  async loadDropdowns() {
    let { poaVerificationDocList, reviewDocList, detailsMismatchList } = this.state;
    try {

      const {
        data: poaTypeListResponse,
      } = await commonService.getDefinitionDetailsMaster("poa doc verification type");

      const {
        data: reviewDocResponse,
      } = await commonService.getDefinitionDetailsMaster("review reasons");

      const {
        data: detailsDocResponse,
      } = await commonService.getDefinitionDetailsMaster("kyc details mismatch");

      poaVerificationDocList = poaTypeListResponse.data;
      reviewDocList = reviewDocResponse.data;
      detailsMismatchList = detailsDocResponse.data;

      this.setState({
        poaVerificationDocList,
        reviewDocList,
        detailsMismatchList
      });
    } catch (ex) { }
  }
  async mapDataToState(userData, isPartnerUser, partnerId) {
    let data = { ...this.state.data };
    let files = { ...this.state.files };

    let { user, typeOfEntity, doi } = userData;

    let {
      userDetails,
      addressDetails,
      bankDetails,
      gstDetails,
      poaDetails,
      panDocDetails,
      additionalDocuments,
      partnerDetails,
      udyogAadhaar,
      udyamAadhaar,
      profileDocDetails,
      questionnairreDetails,
      panDocVerification,
      merchantCode,
      issues
    } = user;

    const currentUser = auth.getCurrentUser();

    //User Details
    if (user._id) data.userId = user._id;
    if (userDetails.firstName) data.firstName = userDetails.firstName;
    if (userDetails.middleName) data.middleName = userDetails.middleName;
    if (userDetails.lastName) data.lastName = userDetails.lastName;
    if (userDetails.role) data.userRole = userDetails.role;
    if (userDetails.kycStatus) data.kycStatus = userDetails.kycStatus;
    if (userDetails.clientCode) data.clientCode = userDetails.clientCode;
    if (questionnairreDetails) data.questionnairre = questionnairreDetails;
    if (userDetails.role) data.userRole = userDetails.role;
    if (userDetails.dob)
      data.dob = moment(userDetails.dob).format("DD/MM/yyyy");
    if (doi) data.doi = moment(doi).format("DD/MM/yyyy");
    if (typeOfEntity) data.typeOfEntity = typeOfEntity;

    if (userDetails.role && userDetails.role === "User" && (userDetails.loan && userDetails.loan.lastUpdatedByPartner))
      data.partnerName = userDetails.loan.lastUpdatedByPartner;
    else if (partnerDetails.partnerName) {
      data.partnerName = partnerDetails.partnerName;
    }

    let genderError = false

    if (issues && issues.length) {
      let genderIssue = issues.find(i => i.code === 701)
      genderError = !!genderIssue
    }

    let middleName = data.middleName ? ` ${data.middleName}` : "";
    data.fullName = `${data.firstName}${middleName} ${data.lastName}`;

    if (userDetails.employment) data.employment = userDetails.employment;
    if (user.isRetailer) data.isRetailer = user.isRetailer;

    if (udyogAadhaar && udyogAadhaar?.aadhaarNo && udyogAadhaar?.addressDetails) {
      data.udyogAadhaar.udyogAadhaarNo = udyogAadhaar?.aadhaarNo;
      data.udyogAadhaar.addressDetails=udyogAadhaar?.addressDetails;
    }
    if (udyamAadhaar && udyamAadhaar?.aadhaarNo && udyamAadhaar?.officialAddress) {
      data.udyamAadhaar.udyamAadhaarNo = udyamAadhaar?.aadhaarNo;
      data.udyamAadhaar.officialAddress=udyamAadhaar?.officialAddress;
    }

    if (userDetails.kycStatus) {
      if (userDetails.kycStatus === "Approved") data.kycStatusColor = "green";
      if (userDetails.kycStatus === "Rejected") data.kycStatusColor = "red";
      if (userDetails.kycStatus === "Under Review")
        data.kycStatusColor = "orange";
      if (userDetails.kycStatus === "Review") data.kycStatusColor = "orange";
    }

    if (merchantCode) data.merchantCode = merchantCode;
    // //Email and Mobile

    if (userDetails.emailId && userDetails.emailId[0]) {
      data.emailId = userDetails.emailId[0];
    }
    if (userDetails.mobileNo && userDetails.mobileNo[0] && userDetails.mobileNo[0].mobileNumber) {
      data.mobileNo = userDetails.mobileNo[0].mobileNumber;
    }
    if (userDetails.emailId && userDetails.emailId[1]) {
      data.alternateEmail = userDetails.emailId[1];
    }
    if (userDetails.mobileNo && userDetails.mobileNo[1] && userDetails.mobileNo[0].mobileNumber) {
      data.alternateMobile = userDetails.mobileNo[1].mobileNumber;
    }
    // //Address Details
    if (addressDetails[0] && addressDetails[0].add1)
      data.add1 = addressDetails[0].add1;
    if (addressDetails[0] && addressDetails[0].add2)
      data.add2 = addressDetails[0].add2;
    if (addressDetails[0] && addressDetails[0].add3)
      data.add3 = addressDetails[0].add3;
    if (addressDetails[0] && addressDetails[0].city) {
      data.city = addressDetails[0].city;
    }
    if (addressDetails[0] && addressDetails[0].state) {
      data.state = addressDetails[0].state;
    }
    if (addressDetails[0] && addressDetails[0].pincode)
      data.pincode = addressDetails[0].pincode;
    if (user.panNo) {
      data.panNo = user.panNo;
    }
    if (data.add2) data.add2 = ` ${data.add2}`;
    data.fullAddress = `${data.add1}${data.add2} ${data.add3}`;
    // //Bank Details
    let bankList = [];

    for (const item of bankDetails) {
      let bank = {
        accountNo: item.accountNo ? item.accountNo : "",
        ifsc: item.ifsc ? item.ifsc : "",
        bankName: item.bankName ? item.bankName : "",
        branch: item.branch ? item.branch : "",
        accountType: item.accountType ? item.accountType : "",
        docVerification: item.docVerification ? item.docVerification : null,
        errorDetails: item.errorDetails ? item.errorDetails : null
      };

      bankList.push(bank);
    }

    data.bankList = bankList;

    let evaluatorRole = "";

    if (currentUser.role) evaluatorRole = currentUser.role;

    // //GST Details
    let gstList = [];
    for (const item of gstDetails) {
      let gst = {
        gstNo: item.gstNo ? item.gstNo : "",
        gstFile:
          item.docDetails[0] && item.docDetails[0].fileName
            ? item.docDetails[0].fileName
            : "",
        gstFilePath:
          item.docDetails[0] && item.docDetails[0].filePath
            ? item.docDetails[0].filePath
            : "",
        docVerification: item.docVerification ? item.docVerification : null,
        errorDetails: item.errorDetails ? item.errorDetails : null
      };

      gstList.push(gst);
    }

    data.gstList = gstList;

    data.poaDetails = [];

    let poaVerificationType = "";

    // //POA Details
    if (poaDetails && poaDetails.length > 0) {
      for (const item of poaDetails) {
        let poaNo = item.poaNo ? item.poaNo : "";
        let poaType = item.poaType ? item.poaType : "";
        poaVerificationType = item.poaVerificationDocType ? item.poaVerificationDocType : "";

        let obj = {
          poaNo: poaNo,
          poaType: poaType,
          poaDocType: poaVerificationType,
          docDetails: [],
          docVerification: item.docVerification ? item.docVerification : null,
          errorDetails: item.errorDetails ? item.errorDetails : null
        };

        for (const docItem of item.docDetails) {
          let poaFileName = docItem.fileName ? docItem.fileName : "";
          let poaFilePath = docItem.filePath ? docItem.filePath : "";
          let poaDocPassword = docItem.documentPassword ? docItem.documentPassword : "";
          obj.docDetails.push({
            poaFileName: poaFileName,
            poaFilePath: poaFilePath,
            poaDocPassword: poaDocPassword
          });
        }
        data.poaDetails.push(obj);
      }
    }

    data.poaDocType = poaVerificationType;

    // if (poaDetails[0] && poaDetails[0].poaNo) {
    //   data.poaNo = poaDetails[0].poaNo;
    // }
    // if (poaDetails[0] && poaDetails[0].poaType)
    //   data.poaType = poaDetails[0].poaType;

    if (panDocDetails[0] && panDocDetails[0].fileName) {
      data.panFileName = panDocDetails[0].fileName;
      data.panFilePath = panDocDetails[0].filePath;
    }

    // if (
    //   poaDetails[0] &&
    //   poaDetails[0].docDetails[0] &&
    //   poaDetails[0].docDetails[0].fileName
    // ) {
    //   data.poaFileName = poaDetails[0].docDetails[0].fileName;
    //   data.poaFilePath = poaDetails[0].docDetails[0].filePath;
    // }

    if (
      profileDocDetails &&
      profileDocDetails[0] &&
      profileDocDetails[0].fileName
    ) {
      data.profileFileName = profileDocDetails[0].fileName;
      data.profileFilePath = profileDocDetails[0].filePath;

      let url = data.profileFilePath ? await this.getSignedURLToDownload(data.profileFilePath) : "";
      data.profileFileSrc = url
    }

    let optionalDocumentList = [];

    if (additionalDocuments && additionalDocuments.length > 0) {
      for (const item of additionalDocuments) {
        if (item.docDetails[0].fileName) {
          const addDocData = {
            fileName:
              item.docDetails[0] && item.docDetails[0].fileName
                ? item.docDetails[0].fileName
                : "",
            filePath:
              item.docDetails[0] && item.docDetails[0].filePath
                ? item.docDetails[0].filePath
                : "",
          };

          optionalDocumentList.push(addDocData);
        }
      }
    }

    //PanDocVerification

    let panDoc = {}

    if (panDocVerification) {

      panDoc = {
        panNo: user.panNo,
        type: panDocVerification.type ? panDocVerification.type : "",
        isDocVerified: panDocVerification.isDocVerified ? "Yes" : "No",
        isNumberVerified: panDocVerification.isNumberVerified ? "Yes" : "No",
        isDocMatch: panDocVerification.isDocMatch ? "Yes" : "No",
        name: panDocVerification.type && panDocVerification.details && panDocVerification.details.name ? panDocVerification.details.name : "",
        fathers_name: panDocVerification.type && panDocVerification.details && panDocVerification.details.fathers_name ? panDocVerification.details.fathers_name : "",
        id_no: panDocVerification.type && panDocVerification.details && panDocVerification.details.docNo ? panDocVerification.details.docNo : "",
        dob: panDocVerification.type && panDocVerification.details && panDocVerification.details.dob ? panDocVerification.details.dob : "",
        docPath: data.panFilePath ? data.panFilePath : "",
        api: {
          details: panDocVerification.errorDetails && panDocVerification.errorDetails.api && panDocVerification.errorDetails.api.details ? panDocVerification.errorDetails.api.details : "",
          code: panDocVerification.errorDetails && panDocVerification.errorDetails.api && panDocVerification.errorDetails.api.code ? panDocVerification.errorDetails.api.code : "",
          message: panDocVerification.errorDetails && panDocVerification.errorDetails.api && panDocVerification.errorDetails.api.message ? panDocVerification.errorDetails.api.message : ""
        },
        ocr: {
          details: panDocVerification.errorDetails && panDocVerification.errorDetails.ocr && panDocVerification.errorDetails.ocr.details ? panDocVerification.errorDetails.ocr.details : "",
          code: panDocVerification.errorDetails && panDocVerification.errorDetails.ocr && panDocVerification.errorDetails.ocr.code ? panDocVerification.errorDetails.ocr.code : "",
          message: panDocVerification.errorDetails && panDocVerification.errorDetails.ocr && panDocVerification.errorDetails.ocr.message ? panDocVerification.errorDetails.ocr.message : ""
        }
      }
    }

    if (userDetails.detailsMismatch && userDetails.detailsMismatch.details && userDetails.detailsMismatch.details.poiName)
      data.poiName = userDetails.detailsMismatch.details.poiName;

    if (userDetails.detailsMismatch && userDetails.detailsMismatch.details && userDetails.detailsMismatch.details.poaName)
      data.poaName = userDetails.detailsMismatch.details.poaName;

    if (userDetails.detailsMismatch && userDetails.detailsMismatch.details && userDetails.detailsMismatch.details.poiDob)
      data.poiDob = momentJalaali(userDetails.detailsMismatch.details.poiDob);

    if (userDetails.detailsMismatch && userDetails.detailsMismatch.details && userDetails.detailsMismatch.details.poaDob)
      data.poaDob = momentJalaali(userDetails.detailsMismatch.details.poaDob);


    if (userDetails.detailsMismatch && userDetails.detailsMismatch.type)
      data.detailsMismatch = userDetails.detailsMismatch.type;

    this.setState({
      data,
      files,
      optionalDocumentList,
      panDoc: panDoc,
      panDocVerification: panDocVerification ? panDocVerification : null,
      evaluatorRole,
      isPartnerUser: isPartnerUser,
      partnerId: partnerId,
      genderError
    });
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    const currentUser = auth.getCurrentUser();
    if (currentUser.role !== "Admin") return (window.location = "/");

    if (this.props.location.state.userData) {
      let {
        userId,
        isPartnerUser,
        partnerId,
        merchantCode
      } = this.props.location.state.userData;

      if (!userId) {
        return (window.location = "/scrutinyList");
      } else {
        await this.getUserData(userId, isPartnerUser, partnerId, merchantCode);
        await this.loadDropdowns();
      }
    }
  }
  async getUserData(userId, isPartnerUser, partnerId, merchantCode) {

    this.setState({ showSpinner: true });

    try {
      const { data: userDetails } = await scrutinyUserService.getScrutinyInfo(userId, isPartnerUser, partnerId, merchantCode);
      if (userDetails.success) {
        this.setState({ userData: userDetails.data, edit: true, merchantCode: merchantCode });

        await this.mapDataToState(userDetails.data, isPartnerUser, partnerId);
      }

      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }
  async handleCustomErrors() {
    const data = { ...this.state.data };
    let errors = { ...this.state.errors };

    delete errors["globalError"];

    if (!data.adminStatus) errors["adminStatus"] = "Please select kyc status";
    else delete errors["adminStatus"];

    if (!data.poaDocType && data.userRole && data.userRole.toLowerCase() === "user" && data.adminStatus === "Approved")
      errors["poaDocType"] = "Please select poa doc type";
    else delete errors["poaDocType"];

    if (data.adminStatus) {
      if (
        (data.adminStatus === "Rejected" || data.adminStatus === "Review") &&
        data.remarks.trim() === ""
      ) {
        errors["remarks"] = "Please enter remarks";
      } else {
        delete errors["remarks"];
      }

      if (
        data.adminStatus === "Review" &&
        data.reviewDoc.trim() === "" && data.userRole && data.userRole.toLowerCase() === "user"
      ) {
        errors["reviewDoc"] = "Please select document";
      } else {
        delete errors["reviewDoc"];
      }

      if (data.adminStatus === "Approved" && data.userRole && data.userRole.toLowerCase() === "user" &&
        !data.detailsMismatch)
        errors["detailsMismatch"] = "Please select an option";
      else
        delete errors["detailsMismatch"];

      if (data.adminStatus === "Approved" && data.userRole && data.userRole.toLowerCase() === "user" &&
        data.detailsMismatch.trim() !== "None") {
        if (data.detailsMismatch.trim() == "Name" || data.detailsMismatch.trim() == "Name & DOB") {
          if (!data.poiName)
            errors["poiName"] = "Please enter Name 1";
          else
            delete errors["poiName"];

          if (!data.poaName)
            errors["poaName"] = "Please enter Name 2";
          else
            delete errors["poaName"];
        }

        if (data.detailsMismatch.trim() == "DOB" || data.detailsMismatch.trim() == "Name & DOB") {
          if (!errors["poiDob"] && data.poiDob) {
            let age = moment().diff(data.poiDob, "years");
            if (age < 18) errors["poiDob"] = "Age must be greater than or equal to 18";
            else delete errors["poiDob"];
          }
          else if (!data.poiDob) {
            errors["poiDob"] = "Enter date of birth";
          }
          else
            delete errors["poiDob"];

          if (!errors["poaDob"] && data.poaDob) {
            let age = moment().diff(data.poaDob, "years");
            if (age < 18) errors["poaDob"] = "Age must be greater than or equal to 18";
            else delete errors["poaDob"];
          }
          else if (!data.poaDob) {
            errors["poaDob"] = "Enter date of birth";
          }
          else
            delete errors["poaDob"];
        }

      } else {
        delete errors["poiName"];
        delete errors["poaName"];
        delete errors["poiDob"];
        delete errors["poaDob"];
      }
    }
    this.setState({ errors });

    return errors;
  }
  async updateKycStatus() {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };
    let { partnerId, merchantCode } = this.state;

    this.setState({ showSpinner: true, isSubmitted: true });

    try {
      const { data: response } = await scrutinyUserService.updateKycStatus(
        data, partnerId, merchantCode
      );

      if (response.success) {
        this.setState({ showSpinner: false, isSubmitted: false });
        window.location.href = "/scrutiny";
      } else {
        errors["globalError"] = response.message;
        this.setState({ errors });

        this.setState({ showSpinner: false, isSubmitted: false, errors });
      }
    } catch (ex) {
      errors["globalError"] = ex.response.data.message;
      this.setState({ showSpinner: false, isSubmitted: false, errors });
    }
  }
  showBankDocInfo = (bank) => {

    let bankDoc = {
      bankName: bank.bankName ? bank.bankName : "",
      branch: bank.branch ? bank.branch : "",
      accountNo: bank.accountNo ? bank.accountNo : "",
      ifsc: bank.ifsc ? bank.ifsc : "",
      isVerified: bank.docVerification && bank.docVerification.isBankVerified ? "Yes" : "No",
      docPath: bank.bankFilePath ? bank.bankFilePath : "",
      nameWithBank: bank.docVerification && bank.docVerification.beneficiary_name_with_bank ? bank.docVerification.beneficiary_name_with_bank : "",
      details: bank.errorDetails && bank.errorDetails.details ? bank.errorDetails.details : "",
      code: bank.errorDetails && bank.errorDetails.code ? bank.errorDetails.code : "",
      message: bank.errorDetails && bank.errorDetails.message ? bank.errorDetails.message : ""
    };

    this.setState({ bankDoc: bankDoc, bankDocDisplay: true })

  }
  hideBankDocInfo = () => {
    this.setState({ bankDocDisplay: false });
  };
  showGstDocInfo = async (gst) => {
    let url = gst?.gstFilePath ? await this.getSignedURLToDownload(gst?.gstFilePath) : "";

    let floor = gst.docVerification && gst.docVerification.addr && gst.docVerification.addr.flno ? gst.docVerification.addr.flno + ", " : "";
    let block = gst.docVerification && gst.docVerification.addr && gst.docVerification.addr.bno ? gst.docVerification.addr.bno + ", " : "";
    let sector = gst.docVerification && gst.docVerification.addr && gst.docVerification.addr.st ? gst.docVerification.addr.st + ", " : "";
    let location = gst.docVerification && gst.docVerification.addr && gst.docVerification.addr.loc ? gst.docVerification.addr.loc + ", " : "";
    let city = gst.docVerification && gst.docVerification.addr && gst.docVerification.addr.dst ? gst.docVerification.addr.dst + ", " : "";
    let state = gst.docVerification && gst.docVerification.addr && gst.docVerification.addr.stcd ? gst.docVerification.addr.stcd + ", " : "";
    let pincode = gst.docVerification && gst.docVerification.addr && gst.docVerification.addr.pncd ? gst.docVerification.addr.pncd : "";

    let address = floor + block + sector + location + city + state + pincode;

    let gstDoc = {
      gstNo: gst.gstNo ? gst.gstNo : "",
      isVerified: gst.docVerification && gst.docVerification.isGstVerified ? "Yes" : "No",
      corporateName: gst.docVerification && gst.docVerification.corporate_name ? gst.docVerification.corporate_name : "",
      tradeName: gst.docVerification && gst.docVerification.trade_nam ? gst.docVerification.trade_nam : "",
      legalName: gst.docVerification && gst.docVerification.details && gst.docVerification.details.lgnm ? gst.docVerification.details.lgnm : "",
      address: address ? address : "",
      ctb: gst.docVerification && gst.docVerification.details && gst.docVerification.details.ctb ? gst.docVerification.details.ctb : "",
      rgdt: gst.docVerification && gst.docVerification.details && gst.docVerification.details.rgdt ? gst.docVerification.details.rgdt : "",
      status: gst.docVerification && gst.docVerification.sts ? gst.docVerification.sts : "",
      dty: gst.docVerification && gst.docVerification.details && gst.docVerification.details.dty ? gst.docVerification.details.dty : "",
      docPath: gst.gstFilePath ? gst.gstFilePath : "",
      details: gst.errorDetails && gst.errorDetails.details ? gst.errorDetails.details : "",
      code: gst.errorDetails && gst.errorDetails.code ? gst.errorDetails.code : "",
      message: gst.errorDetails && gst.errorDetails.message ? gst.errorDetails.message : "",
      gstDoc: url
    };

    this.setState({ gstDoc: gstDoc, gstDocDisplay: true })

  }
  hideGstDocInfo = () => {
    this.setState({ gstDocDisplay: false });
  };

  showPanDocInfo = async () => {
    let { panDoc } = this.state
    let url = panDoc?.docPath ? await this.getSignedURLToDownload(panDoc?.docPath) : "";
    panDoc.panDoc = url
    this.setState({ panDocDisplay: true, panDoc })
  }

  hidePanDocInfo = () => {
    this.setState({ panDocDisplay: false });
  };

  showPoaDocInfo = async (poa) => {
    let url1 = poa.docDetails && poa.docDetails[0] && poa.docDetails[0].poaFilePath ? await this.getSignedURLToDownload(poa.docDetails[0].poaFilePath) : "";
    let url2 = poa.docDetails && poa.docDetails[1] && poa.docDetails[1].poaFilePath ? await this.getSignedURLToDownload(poa.docDetails[1].poaFilePath) : "";

    if (poa.docVerification?.verificationType === "DRIVING_LICENSE") {

      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified: poa.docVerification && poa.docVerification.isDocVerified ? "Yes" : "No",
          isNumberVerified: poa.docVerification && poa.docVerification.isNumberVerified ? "Yes" : "No",
          isDocMatch: poa.docVerification && poa.docVerification.isDocMatch ? "Yes" : "No",
          type: poa.docVerification && poa.docVerification.type ? poa.docVerification.type : "",
          docType: poa.docVerification && poa.docVerification.verificationType ? poa.docVerification.verificationType : "",
          dl: {
            ocr: {
              name: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.ocr && poa.docVerification.dl.ocr.name ? poa.docVerification.dl.ocr.name : "",
              id_no: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.ocr && poa.docVerification.dl.ocr.id_no ? poa.docVerification.dl.ocr.id_no : "",
              dob: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.ocr && poa.docVerification.dl.ocr.dob ? poa.docVerification.dl.ocr.dob : "",
              id_type: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.ocr && poa.docVerification.dl.ocr.id_type ? poa.docVerification.dl.ocr.id_type : ""
            },
            verificationResponse: {
              verified: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.verified ? "Yes" : "No",
              Date_Of_Issue: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.Date_Of_Issue ? poa.docVerification.dl.verificationResponse.Date_Of_Issue : "",
              NT: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.NT ? poa.docVerification.dl.verificationResponse.NT : "",
              Old_New_DL_No: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.Old_New_DL_No ? poa.docVerification.dl.verificationResponse.Old_New_DL_No : "",
              Holders_Name: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.Holders_Name ? poa.docVerification.dl.verificationResponse.Holders_Name : "",
              Hazardous_Valid_Till: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.Hazardous_Valid_Till ? poa.docVerification.dl.verificationResponse.Hazardous_Valid_Till : "",
              NonTransport: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.NonTransport ? poa.docVerification.dl.verificationResponse.NonTransport : "",
              Transport: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.Transport ? poa.docVerification.dl.verificationResponse.Transport : "",
              CurrentStatus: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.CurrentStatus ? poa.docVerification.dl.verificationResponse.CurrentStatus : "",
              LastTransactionAt: poa.docVerification && poa.docVerification.dl && poa.docVerification.dl.verificationResponse && poa.docVerification.dl.verificationResponse.LastTransactionAt ? poa.docVerification.dl.verificationResponse.LastTransactionAt : ""
            }
          }
        },
        api: {
          details: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.details ? poa.errorDetails.api.details : "",
          code: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.code ? poa.errorDetails.api.code : "",
          message: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.message ? poa.errorDetails.api.message : ""
        },
        ocr: {
          details: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.details ? poa.errorDetails.ocr.details : "",
          code: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.code ? poa.errorDetails.ocr.code : "",
          message: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.message ? poa.errorDetails.ocr.message : ""
        }
      }

      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });

    }
    else if (poa.docVerification?.verificationType === "PASSPORT") {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified: poa.docVerification && poa.docVerification.isDocVerified ? "Yes" : "No",
          isNumberVerified: poa.docVerification && poa.docVerification.isNumberVerified ? "Yes" : "No",
          isDocMatch: poa.docVerification && poa.docVerification.isDocMatch ? "Yes" : "No",
          type: poa.docVerification && poa.docVerification.type ? poa.docVerification.type : "",
          docType: poa.docVerification && poa.docVerification.verificationType ? poa.docVerification.verificationType : "",
          passport: {
            ocr: {
              name: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.name ? poa.docVerification.passport.ocr.name : "",
              id_no: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.id_no ? poa.docVerification.passport.ocr.id_no : "",
              dob: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.dob ? poa.docVerification.passport.ocr.dob : "",
              id_type: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.id_type ? poa.docVerification.passport.ocr.id_type : "",
              gender: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.gender ? poa.docVerification.passport.ocr.gender : "",
              country_code: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.country_code ? poa.docVerification.passport.ocr.country_code : "",
              sur_name: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.sur_name ? poa.docVerification.passport.ocr.sur_name : "",
              nationality: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.nationality ? poa.docVerification.passport.ocr.nationality : "",
              place_of_birth: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.place_of_birth ? poa.docVerification.passport.ocr.place_of_birth : "",
              place_of_issue: poa.docVerification && poa.docVerification.passport && poa.docVerification.passport.ocr && poa.docVerification.passport.ocr.place_of_issue ? poa.docVerification.passport.ocr.place_of_issue : ""
            }
          }
        },
        api: {
          details: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.details ? poa.errorDetails.api.details : "",
          code: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.code ? poa.errorDetails.api.code : "",
          message: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.message ? poa.errorDetails.api.message : ""
        },
        ocr: {
          details: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.details ? poa.errorDetails.ocr.details : "",
          code: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.code ? poa.errorDetails.ocr.code : "",
          message: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.message ? poa.errorDetails.ocr.message : ""
        }
      }
      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    }
    else if (poa.docVerification?.verificationType === "AADHAAR") {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified: poa.docVerification && poa.docVerification.isDocVerified ? "Yes" : "No",
          isNumberVerified: poa.docVerification && poa.docVerification.isNumberVerified ? "Yes" : "No",
          isDocMatch: poa.docVerification && poa.docVerification.isDocMatch ? "Yes" : "No",
          type: poa.docVerification && poa.docVerification.type ? poa.docVerification.type : "",
          docType: poa.docVerification && poa.docVerification.verificationType ? poa.docVerification.verificationType : "",
          uid: {
            ocr: {
              name: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.name ? poa.docVerification.uid.details.name : "",
              id_no: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.docNo ? poa.docVerification.uid.details.docNo : "",
              dob: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.dob ? poa.docVerification.uid.details.dob : "",
              id_type: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.document_type ? poa.docVerification.uid.details.document_type : "",
              gender: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.gender ? poa.docVerification.uid.details.gender : "",
              address: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.current_address ? poa.docVerification.uid.details.current_address : "",
              address_information: {
                address: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.current_address_details && poa.docVerification.uid.details.current_address_details.address ? poa.docVerification.uid.details.current_address_details.address : "",
                locality_or_post_office: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.current_address_details && poa.docVerification.uid.details.current_address_details.locality_or_post_office ? poa.docVerification.uid.details.current_address_details.locality_or_post_office : "",
                district_or_city: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.current_address_details && poa.docVerification.uid.details.current_address_details.district_or_city ? poa.docVerification.uid.details.current_address_details.district_or_city : "",
                state: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.current_address_details && poa.docVerification.uid.details.current_address_details.state ? poa.docVerification.uid.details.current_address_details.state : "",
                pincode: poa.docVerification && poa.docVerification.uid && poa.docVerification.uid.details && poa.docVerification.uid.details.current_address_details && poa.docVerification.uid.details.current_address_details.pincode ? poa.docVerification.uid.details.current_address_details.pincode : ""
              }
            }
          }
        },
        api: {
          details: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.details ? poa.errorDetails.api.details : "",
          code: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.code ? poa.errorDetails.api.code : "",
          message: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.message ? poa.errorDetails.api.message : ""
        },
        ocr: {
          details: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.details ? poa.errorDetails.ocr.details : "",
          code: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.code ? poa.errorDetails.ocr.code : "",
          message: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.message ? poa.errorDetails.ocr.message : ""
        }
      }

      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    }
    else if (poa.docVerification?.verificationType === "VOTER_ID") {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified: poa.docVerification && poa.docVerification.isDocVerified ? "Yes" : "No",
          isNumberVerified: poa.docVerification && poa.docVerification.isNumberVerified ? "Yes" : "No",
          isDocMatch: poa.docVerification && poa.docVerification.isDocMatch ? "Yes" : "No",
          type: poa.docVerification && poa.docVerification.type ? poa.docVerification.type : "",
          docType: poa.docVerification && poa.docVerification.verificationType ? poa.docVerification.verificationType : "",
          voterId: {
            ocr: {
              name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.name ? poa.docVerification.voterId.ocr.name : "",
              id_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.id_no ? poa.docVerification.voterId.ocr.id_no : "",
              fathers_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.fathers_name ? poa.docVerification.voterId.ocr.fathers_name : "",
              id_type: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.id_type ? poa.docVerification.voterId.ocr.id_type : "",
              gender: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.gender ? poa.docVerification.voterId.ocr.gender : "",
              address: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.address ? poa.docVerification.voterId.ocr.address : "",
              date_of_issue: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.date_of_issue ? poa.docVerification.voterId.ocr.date_of_issue : "",
              address_information: {
                address: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.address_information && poa.docVerification.voterId.ocr.address ? poa.docVerification.voterId.ocr.address : "",
                locality_or_post_office: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.address_information && poa.docVerification.voterId.ocr.locality_or_post_office ? poa.docVerification.voterId.ocr.locality_or_post_office : "",
                district_or_city: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.ocr && poa.docVerification.voterId.ocr.address_information && poa.docVerification.voterId.ocr.district_or_city ? poa.docVerification.voterId.ocr.district_or_city : ""
              }
            },
            verificationResponse: {
              verified: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.verified ? "Yes" : "No",
              result_id_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.result_id_no ? poa.docVerification.voterId.verificationResponse.result_id_no : "",
              result_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.result_name ? poa.docVerification.voterId.verificationResponse.result_name : "",
              pc_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.pc_name ? poa.docVerification.voterId.verificationResponse.pc_name : "",
              st_code: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.st_code ? poa.docVerification.voterId.verificationResponse.st_code : "",
              ps_lat_long_1_coordinate: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ps_lat_long_1_coordinate ? poa.docVerification.voterId.verificationResponse.ps_lat_long_1_coordinate : "",
              gender: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.gender ? poa.docVerification.voterId.verificationResponse.gender : "",
              rln_name_v2: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.rln_name_v2 ? poa.docVerification.voterId.verificationResponse.rln_name_v2 : "",
              rln_name_v1: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.rln_name_v1 ? poa.docVerification.voterId.verificationResponse.rln_name_v1 : "",
              rln_name_v3: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.rln_name_v3 ? poa.docVerification.voterId.verificationResponse.rln_name_v3 : "",
              name_v1: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.name_v1 ? poa.docVerification.voterId.verificationResponse.name_v1 : "",
              epic_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.epic_no ? poa.docVerification.voterId.verificationResponse.epic_no : "",
              ac_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ac_name ? poa.docVerification.voterId.verificationResponse.ac_name : "",
              name_v2: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.name_v2 ? poa.docVerification.voterId.verificationResponse.name_v2 : "",
              name_v3: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.name_v3 ? poa.docVerification.voterId.verificationResponse.name_v3 : "",
              ps_lat_long: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ps_lat_long ? poa.docVerification.voterId.verificationResponse.ps_lat_long : "",
              pc_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.pc_no ? poa.docVerification.voterId.verificationResponse.pc_no : "",
              last_update: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.last_update ? poa.docVerification.voterId.verificationResponse.last_update : "",
              id: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.id ? poa.docVerification.voterId.verificationResponse.id : "",
              dist_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.dist_no ? poa.docVerification.voterId.verificationResponse.dist_no : "",
              ps_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ps_no ? poa.docVerification.voterId.verificationResponse.ps_no : "",
              pc_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.pc_name ? poa.docVerification.voterId.verificationResponse.pc_name : "",
              ps_name_v1: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ps_name_v1 ? poa.docVerification.voterId.verificationResponse.ps_name_v1 : "",
              st_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.st_name ? poa.docVerification.voterId.verificationResponse.st_name : "",
              dist_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.dist_name ? poa.docVerification.voterId.verificationResponse.dist_name : "",
              rln_type: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.rln_type ? poa.docVerification.voterId.verificationResponse.rln_type : "",
              pc_name_v1: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.pc_name_v1 ? poa.docVerification.voterId.verificationResponse.pc_name_v1 : "",
              part_name_v1: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.part_name_v1 ? poa.docVerification.voterId.verificationResponse.part_name_v1 : "",
              ac_name_v1: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ac_name_v1 ? poa.docVerification.voterId.verificationResponse.ac_name_v1 : "",
              part_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.part_no ? poa.docVerification.voterId.verificationResponse.part_no : "",
              dist_name_v1: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.dist_name_v1 ? poa.docVerification.voterId.verificationResponse.dist_name_v1 : "",
              ps_lat_long_0_coordinate: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ps_lat_long_0_coordinate ? poa.docVerification.voterId.verificationResponse.ps_lat_long_0_coordinate : "",
              _version_: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse._version_ ? poa.docVerification.voterId.verificationResponse._version_ : "",
              name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.name ? poa.docVerification.voterId.verificationResponse.name : "",
              section_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.section_no ? poa.docVerification.voterId.verificationResponse.section_no : "",
              ac_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.ac_no ? poa.docVerification.voterId.verificationResponse.ac_no : "",
              slno_inpart: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.slno_inpart ? poa.docVerification.voterId.verificationResponse.slno_inpart : "",
              rln_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.rln_name ? poa.docVerification.voterId.verificationResponse.rln_name : "",
              age: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.age ? poa.docVerification.voterId.verificationResponse.age : "",
              part_name: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.part_name ? poa.docVerification.voterId.verificationResponse.part_name : "",
              id_no: poa.docVerification && poa.docVerification.voterId && poa.docVerification.voterId.verificationResponse && poa.docVerification.voterId.verificationResponse.id_no ? poa.docVerification.voterId.verificationResponse.id_no : ""
            }
          }
        },
        api: {
          details: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.details ? poa.errorDetails.api.details : "",
          code: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.code ? poa.errorDetails.api.code : "",
          message: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.message ? poa.errorDetails.api.message : ""
        },
        ocr: {
          details: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.details ? poa.errorDetails.ocr.details : "",
          code: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.code ? poa.errorDetails.ocr.code : "",
          message: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.message ? poa.errorDetails.ocr.message : ""
        }
      }
      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    }
    else {
      let poaDoc = {
        poaNo: poa.poaNo ? poa.poaNo : "",
        poaType: poa.poaType ? poa.poaType : "",
        poaDoc: url1,
        poaBackDoc: url2,
        docVerification: {
          isDocVerified: poa.docVerification && poa.docVerification.isDocVerified ? "Yes" : "No",
          isNumberVerified: poa.docVerification && poa.docVerification.isNumberVerified ? "Yes" : "No",
          isDocMatch: poa.docVerification && poa.docVerification.isDocMatch ? "Yes" : "No",
          type: poa.docVerification && poa.docVerification.type ? poa.docVerification.type : "",
          docType: poa.docVerification && poa.docVerification.verificationType ? poa.docVerification.verificationType : ""
        },
        api: {
          details: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.details ? poa.errorDetails.api.details : "",
          code: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.code ? poa.errorDetails.api.code : "",
          message: poa.errorDetails && poa.errorDetails.api && poa.errorDetails.api.message ? poa.errorDetails.api.message : ""
        },
        ocr: {
          details: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.details ? poa.errorDetails.ocr.details : "",
          code: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.code ? poa.errorDetails.ocr.code : "",
          message: poa.errorDetails && poa.errorDetails.ocr && poa.errorDetails.ocr.message ? poa.errorDetails.ocr.message : ""
        }
      }

      this.setState({ poaDoc: poaDoc, poaDocDisplay: true });
    }
  }
  hidePoaDocInfo = () => {
    this.setState({ poaDocDisplay: false });
  };

  showGenderModal = () => {
    this.setState({ showGenderMismatchError: true })
  }

  closeGenderModal = () => {
    this.setState({ showGenderMismatchError: false })
  }

  handleSelect = async (tab) => {
    let { data, isPartnerUser, partnerId, merchantCode } = this.state;

    if (tab === "scrutinyTab")
      await this.getUserData(data.userId, isPartnerUser, partnerId, merchantCode);

    this.setState({ activeTab: tab });


  }
  handleDob1Change = (value) => {
    const { data, errors } = this.state;

    data.poiDob = value;
    delete errors["poiDob"];
    this.setState({ data, errors });
  };
  handleDob2Change = (value) => {
    const { data, errors } = this.state;

    data.poaDob = value;
    delete errors["poaDob"];
    this.setState({ data, errors });
  };
  doSubmit = async (e) => {
    e.preventDefault();

    if (this.state.genderError && this.state.data.adminStatus === "Approved") {
      this.showGenderModal()
      return
    }

    let errors = await this.handleCustomErrors();

    if (_.isEmpty(errors)) await this.updateKycStatus();
  };
}

export default ScrutinyInfoModel;
