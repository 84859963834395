import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import axios from "axios";

// const ip = require("ip");
const publicIp = require('public-ip');
const apiEndpoint = apiUrl + "/ops/";
const apiEndpoint1 = apiUrl + "/ops/partner";

export async function getIpAddress() {
  return await publicIp.v4();
}

export async function getDefinitionDetailsMaster(definitionType) {
  const response = await http.post(
    apiEndpoint + "masters/getDefinitionMaster",
    {
      definitionType,
    }
  );
  return response;
}

export async function getRoleWiseDefinitionList(definitionType) {
  const response = await http.post(
    apiEndpoint + "masters/getRoleWiseDefinitionList",
    {
      definitionType,
    }
  );
  return response;
}

export async function getStates(state, pincode) {
  const response = await http.get(apiEndpoint + `masters/getStates/${state}?pincode=${pincode || ""}`);
  return response;
}

export async function getStatesByPincode(pincode) {
  const response = await http.get(apiEndpoint + `masters/getStatesByPincode?pincode=${pincode || ""}`);
  return response;
}

export async function getCities(state, city, pincode) {
  const response = await http.get(
    apiEndpoint + `masters/getCities/${state}/${city}?pincode=${pincode || ""}`
  );
  return response;
}

export async function getIfscCode(ifscCode) {
  const response = await http.get(
    apiEndpoint + `masters/getIfscCode?ifsc=${ifscCode}`
  );
  return response;
}

export async function isValidCity(state, city) {

  if (city) {
    const response = await http.get(
      apiEndpoint + `masters/isValidCity/${state}/${city}`
    );
    return response;
  }
  else {
    return {
      data: "",
      success: false,
      message: "City is InValid",
      status: 200,
    };
  }

}

export async function isValidState(state, pincode) {
  const response = await http.get(
    apiEndpoint + `masters/isValidState/${state}?pincode=${pincode || ""}`
  );
  return response;
}

export async function isValidIfscCode(ifscCode) {
  const response = await http.get(
    apiEndpoint + `masters/isValidIfsc/${ifscCode}`
  );
  return response;
}

export async function getBankList(bankName) {
  const response = await http.get(
    apiEndpoint + `masters/getBankNames?bankName=${bankName}`
  );
  return response;
}

export async function getBranchList(bankName, branchName) {
  const response = await http.get(
    apiEndpoint + `masters/getBranchNames/${bankName}/${branchName}`
  );
  return response;
}

export async function getIfscList(bankName, branchName) {
  const response = await http.get(
    apiEndpoint + `masters/getBankfromBranch/${bankName}/${branchName}`
  );
  return response;
}

export async function getBorrowingPartners() {
  const response = await http.post(
    apiEndpoint + `partner/borrowerPartnerList`
  );
  return response;
}

export async function getBorrowingPartnerList() {
  const response = await http.post(apiEndpoint + "admin/getBorrowingPartners", {}).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}


export async function getLendingParterList() {
  const response = await http.post(apiEndpoint + "admin/getLendingPartners", {}).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}

export async function getAllPartnerList() {
  const response = await http.post(apiEndpoint + "admin/getPartners", {}).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}

export async function getAllPartnerListV2(model) {
  const response = await http.post(apiEndpoint + "admin/getPartnerListV2", model).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}


export async function sendReview(loanNo) {
  const { data } = await http.post(`${apiEndpoint1}/sendReviewLink`, { loanNo: loanNo, });
  return data;
}

export async function logUserOtp(loanId = null, otp = null, otpMatch = null, otpType = null, otpAutoFill = false) {
  const response = await http.post(
    apiEndpoint + "masters/logUserOtp",
    {
      loanId,
      otp,
      otpMatch,
      otpType,
      otpAutoFill
    }
  );
  return response;
}

export async function getImagePath(url) {
  const { data } = await http.post(
    apiUrl + "/image/encrypt",
    {
      url
    }
  );

  return data;

}

export async function getMandateMethodByIFSC(ifsc) {
  const response = await http.get(
    apiEndpoint + `masters/getSupportedMandateMethod/${ifsc}`
  );
  return response;
}

export async function getConfiguration(channel) {
  const response = await http.get(
    apiEndpoint + `masters/configuration?channel=${channel}`
  );
  return response;
}

export async function getMandateRequireStatus(loanId) {
  const response = await http.get(
    apiEndpoint + `loan/getMandateRequireStatus/${loanId}`
  );
  return response;
}

export async function getSignedUrl(payload = {}) {
  let params = [];

  if (payload) {
    Object.keys(payload).forEach(key => {
      params.push(`${key}=${payload[key]}`);
    });
  }

  let url = apiEndpoint + `aws/getSignUrl`

  if (params && params.length) {
    url = url + "?" + params.join('&');
  }

  const response = await http.get(url);
  return response;
}

export async function getSignedUrlToDownload(url) {
  const { data } = await http.post(
    apiEndpoint + "aws/getSignUrlToDownload",
    {
      url
    }
  );

  return data;

}

export async function getBulkSignedUrlToDownload(url) {
  const { data } = await http.post(
    apiEndpoint + "aws/getBulkSignUrlToDownload",
    {
      url
    }
  );

  return data;

}

export function uploadFileAwsS3(signedUrl, file, progressCallback) {
  let options = {
    headers:
    {
      "Content-Type": file.type
    },
    onUploadProgress: progressCallback
  }
  return axios.put(signedUrl, file, options);
}

export async function updatePartnerConfigurations(model) {
  const response = await http.put(apiEndpoint + "admin/updatePartnerConfigurations", model).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}

export async function getPartnerConfigurations(id) {
  const response = await http.get(
    apiEndpoint + `admin/getPartnerConfigurations?id=${id}`
  );
  return response;
}

export async function downloadAllDocument(loanId) {
  const response = await http.get(
    apiEndpoint + `admin/downloadAllDocument?loanId=${loanId}`
  );
  return response;
}

export async function downloadFileByUrl(url, options) {
  const response = await http.get(url, options);
  return response;
}

export async function getBankStatements(model) {

  let url = apiEndpoint + "admin/bankstatements";
  let keys = Object.keys(model);
  for (let i = 0; i < keys.length; i++) {

    if (model[keys[i]]) {
      if (i == 0)
        url = `${url}?${keys[i]}=${model[keys[i]]}`;
      else
        url = `${url}&${keys[i]}=${model[keys[i]]}`;
    }

  }

  const response = await http.get(url).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}

export async function getBankStatementsDownload(model) {

  let url = apiEndpoint + "admin/bankstatementsDownload";
  let keys = Object.keys(model);
  for (let i = 0; i < keys.length; i++) {

    if (model[keys[i]]) {
      if (i == 0)
        url = `${url}?${keys[i]}=${model[keys[i]]}`;
      else
        url = `${url}&${keys[i]}=${model[keys[i]]}`;
    }

  }

  const response = await http.get(url).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}

export function numberOfDaysDifferece(date1, date2) {
  date1 = new Date(date1);
  date2 = new Date(date2);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays
}

export async function getSignedInvoiceUrlToDownload(url) {
  const { data } = await http.post(
    apiEndpoint + "aws/getSignedInvoiceUrlToDownload",
    {
      url
    }
  );

  return data;

}

export async function getInvoiceTypeList() {
  const response = await http.post(
    apiEndpoint + "masters/getInvoiceTypeList"
  );
  return response;
}

export async function generateUpiLink(model) {
  const response = await http.post(apiEndpoint + "admin/generateUpiLink", model).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}

export async function moveBankStatementToManualQueue(model) {
  const response = await http.post(apiEndpoint + "admin/manualqueue/move/bankstatement", model).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
  return response;
}

