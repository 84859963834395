import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { getIpAddress } from "../common/commonService";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/";

export async function isValidPartnerName(partnerName) {
  const { data } = await http.get(
    `${apiEndpoint}partner/isValidPartnerName/${partnerName.trim()}`
  );
  return data;
}

export async function registerPartner({
  partnerName,
  partnerType,
  typeOfEntity,
  email,
  mobileNo,
  password,
  doi,
  userName,
  otp
}) {
  const { data } = await http.post(`${apiEndpoint}partner`, {
    emailId: email.trim(),
    mobileNo: mobileNo.trim(),
    password: password.trim(),
    partnerName: partnerName.trim().toUpperCase(),
    userName: email.trim(),
    doi: moment(doi).format("DD/MM/yyyy"),
    ipAddress: await getIpAddress(),
    partnerType,
    typeOfEntity,
    otp
  });
  return data;
}

export async function insertUpdatePartnerKyc(data, files, partnerId) {
  const response = await http.put(
    apiEndpoint + `users/insertUpdatePartnerKyc`,
    {
      add1: data.add1,
      add2: data.add2,
      add3: data.add3,
      city: data.city,
      state: data.state,
      country: data.country,
      addressType: data.addressType,
      pincode: data.pincode,
      panNo: data.panNo,
      udyogAadhaarNo: data.udyogAadhaarNo,
      gstNo: data.gstNo,
      accountNo: data.accountNo,
      ifsc: data.ifsc,
      accountType: data.accountType,
      panFile: files.panFile,
      panFileStream: files.panFileStream,
      udyogAadhaarFile: files.udyogAadhaarFile,
      udyogAadhaarFileStream: files.udyogAadhaarFileStream,
      gstFile: files.gstFile,
      gstFileStream: files.gstFileStream,
      partnerId: partnerId ? partnerId : "",
      accountName: data.accountName ? data.accountName : ""
    }
  );
  return response;
}

export default {
  registerPartner,
  isValidPartnerName
};
