import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import CollectionOutcomeModel from "../../models/collectionOutcomeModel/collectionOutcomeModel";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Form, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker2";
import ReactExport from "react-export-excel";

class SettlementPending extends CollectionOutcomeModel {

  exportExcel = () => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let allActionsList = this.state.allActionsList;
    return (<ExcelFile
      hideElement={true}
      filename={`CollectionsOutCome_${moment(new Date()).format("DDMMYYYY")}`}
    >
      <ExcelSheet data={allActionsList} name="collectionQueue">
        <ExcelColumn
          label="Partner"
          value="partnerName"
        ></ExcelColumn>

        <ExcelColumn
          label="Date"
          value={(col) => {
            return col.date && moment(col.date).format("DD/MM/YYYY") || "-"
          }}
          hideElement={true}
        ></ExcelColumn>

        <ExcelColumn
          label="Connected"
          value="connectedCount"
        ></ExcelColumn>


        <ExcelColumn
          label="Not Connected"
          value="notConnectedCount"
        ></ExcelColumn>

        <ExcelColumn
          label="Total Calls"
          value={(col) => {
            return (col.connectedCount || 0) + (col.notConnectedCount || 0)
          }}
          hideElement={true}
        ></ExcelColumn>

      </ExcelSheet>
    </ExcelFile>)
  }

  render() {
    const {
      data,
      showSpinner,
      successMsg,
      errors,
      partners,
      actionsList
    } = this.state;



    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />

        <h2 className="mb-3">
          Collections Outcome
        </h2>

        <div className="mt-3">

          <Form onSubmit={this.handleSubmit}>
            <Form.Row>

              <Form.Group as={Col} md="2" controlId="fromDate">
                <Form.Label>Partner<span>*</span></Form.Label>
                <Form.Control
                  as="select"
                  name="partner"
                  value={data["partner"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="all" value="all">
                    All
                  </option>
                  {partners && partners.map((partner) => (
                    <option key={partner.partnerName} value={partner._id}>
                      {partner.displayName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="fromDate">
                <Form.Label>From Date <small className="text-success">(Max Date Range 7 days)</small></Form.Label>
                <DatePicker
                  name="fromDate"
                  id="fromDate"
                  value={data["fromDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleFromDateChange(value)}
                  placeholder="Enter From Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  name="toDate"
                  id="toDate"
                  value={data["toDate"]}
                  max={moment()}
                  inputFormat="DD/MM/YYYY"
                  isGregorian={true}
                  className="form-control"
                  inputReadOnly={true}
                  showTodayButton={false}
                  timePicker={false}
                  onChange={(value) => this.handleToDateChange(value)}
                  placeholder="Enter To Date"
                />
              </Form.Group>

              <Form.Group as={Col} md="2">
                <Form.Label> </Form.Label>
                <br />
                {data["partner"] && data["partner"] != "all" && <Button
                  className="ff-button mt-2 mr-2"
                  type="submit"
                >
                  Go
                </Button>}
                <Button
                  className="ff-button mt-2 mr-2"
                  onClick={() => !this.state.showSpinner && this.downloadExcel()}
                >
                  Export
                </Button>
                {this.state.download && this.exportExcel()}
              </Form.Group>

            </Form.Row>
          </Form>

          <br />
          {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
          {successMsg && <div className="alert alert-success">{successMsg}</div>}

          <div style={{ width: '100%' }}>
            <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
              <Row>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Partner</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Date</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Connected</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Not Connected</b></Cell>
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Calls</b></Cell>
              </Row>
              {actionsList && actionsList &&
                actionsList.map((action, i) => (
                  <Row key={action._id} onClick={() => this.handleDetailsPage(action)} className="cursor-pointer">
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: `${actionsList.length <= i + 1 ? '1px solid' : ""}` }}>{action.partnerName || "-"}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${actionsList.length <= i + 1 ? '1px solid' : ""}` }}>{action.date && moment(action.date).format("DD/MM/YYYY") || "-"}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${actionsList.length <= i + 1 ? '1px solid' : ""}` }}>{action.connectedCount || 0}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${actionsList.length <= i + 1 ? '1px solid' : ""}` }}>{action.notConnectedCount || 0}</Cell>
                    <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${actionsList.length <= i + 1 ? '1px solid' : ""}` }}>{(action.connectedCount || 0) + (action.notConnectedCount || 0)}</Cell>
                  </Row>
                ))}
              {(!actionsList || !actionsList.length) && (
                <td colSpan={10} className='text-center pt-2 pb-2 border'><small>{'No Records Found'}</small></td>
              )}

            </StickyTable>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SettlementPending;
