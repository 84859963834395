import React, { Component } from "react";
import UserContext from "../../context/userContext";
import DemoCarousel from "../../components/common/carousal";
import { Button, Card, CardDeck } from "react-bootstrap";
import { Link } from "react-router-dom";
import InputDataForm from "../../components/common/form";
import EmiCalculator from './../../components/common/emiCalculator';
import TestimonialSlider from './../../components/common/testimonialSlider';
// import Twitter from "../../components/common/twitter";
import * as analytics from "../../utils/analytics";

class Home extends InputDataForm {
  static contextType = UserContext;

  render() {

    analytics.track();

    return (
      <React.Fragment>
        <DemoCarousel /> <br />
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumHeader">
            <li className="breadcrumListItem">
              <label className="breadcrumListItemLink">
                <i
                  className="fa fa-check-square-o"
                  aria-hidden="true"
                  style={{ marginRight: 2 }}
                ></i>
                Transaction based loans ₹10k - ₹3 lacs
              </label>
            </li>
            <li className="breadcrumListItem">
              <label className="breadcrumListItemLink">
                <i
                  className="fa fa-check-square-o"
                  aria-hidden="true"
                  style={{ marginRight: 2 }}
                ></i>
                Rates from 11% per year
              </label>
            </li>
            <li className="breadcrumListItem">
              <label className="breadcrumListItemLink">
                <i
                  className="fa fa-check-square-o"
                  aria-hidden="true"
                  style={{ marginRight: 2 }}
                ></i>
                Flexible Repayments
              </label>
            </li>
            <li className="breadcrumListItem">
              <label className="breadcrumListItemLink">
                <i
                  className="fa fa-check-square-o"
                  aria-hidden="true"
                  style={{ marginRight: 2 }}
                ></i>
                No credit score required
              </label>
            </li>
          </ol>
        </nav>
        <CardDeck>
          <Card>
            <Card.Img
              variant="top"
              src={require("../../images/home.png")}
              className="cardsImg"
            />
            <Card.Body>
              <Card.Title className="cardHeader">
                MSME Loan - MerchantBrisk
              </Card.Title>
              <Card.Text style={{ minHeight: 180 }}>
                Self employed, running their own shop in addition to acting as
                distributor for money transfer companies or mobile wallet
                companies or business correspondent. Yearly transaction of as
                low as ₹12 lacs.
              </Card.Text>
              <Link to="/msme">
                <Button variant="primary" className="ff-button">
                  Know More
                </Button>
              </Link>
              <Link to="/borrow" className="pull-right">
                <Button className="ff-button" type="button">
                  Apply Now
                </Button>
              </Link>
            </Card.Body>
          </Card>
          <Card>
            {/* <Card.Img
              variant="top"
              src={require("../../images/farmer.jpg")}
              className="cardsImgFarmer"
            /> */}
            <Card.Body>
              {/* <Card.Title className="cardHeader">Farmer Loans</Card.Title> */}
              <Card.Title className="cardHeader">TrueScore™</Card.Title>
              <Card.Text style={{ minHeight: 180 }}>
                {/* Farmer with landholding ranging between 0.1 acres to 11 acres;
                looking to utilize services/products of Agritech firms thus
                requiring financial assistance in any part of cultivation cycle. */}

                TrueScore™ provides the transaction uncertainty expected in sales turnover of small businesses. Most micro-businesses have inherent volatility in turnovers in the business and their demand for cash-flow based working capital is the most suitable source of financing.  However, a reliable way to score credit worthiness of MSMEs remains a far cry. Fundfina data analysts crunched through data for 150k MSMEs over 2 years to come up with measures of cash-flow based credit worthiness.
              </Card.Text>              <Link to="/truescore" className="pull-left" style={{ marginRight: 10 }}>
                <Button variant="primary" className="ff-button">
                  Get your TrueScore™
                </Button>
              </Link> {" "}
              <a href="https://us18.campaign-archive.com/?u=e09ba49760486caa5c4edc5de&id=b1d624d62d" target="_blank">
                <Button variant="primary" className="ff-button">
                  Know More
                </Button>
              </a>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src={require("../../images/woman.png")}
              className="cardsImg"
            />
            <Card.Body>
              <Card.Title className="cardHeader">
                Personal Loan - FutureSecure
              </Card.Title>
              <Card.Text style={{ minHeight: 180 }}>
                Salaried or professional or self employed with annual income
                starting from ₹ 1 Lac with no legal evidence of occupation or
                income; Urban and rural poor employed in the service sector
              </Card.Text>
              <Link to="/personalLoan">
                <Button variant="primary" className="ff-button">
                  Know More
                </Button>
              </Link>
              <Link to="/borrow" className="pull-right">
                <Button className="ff-button" type="button">
                  Apply Now
                </Button>
              </Link>
            </Card.Body>
          </Card>
        </CardDeck>
        <EmiCalculator />
        <TestimonialSlider />
        {/* <Twitter /> */}
        {/* <Testimonials /> */}
      </React.Fragment>
    );
  }
}

// Movies.contextType = UserContext;

export default Home;
