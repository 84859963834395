import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";
const apiEndpoint = apiUrl;

export async function getCibilReport(model) {
    const options = {
        fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
        toDate: moment(model.toDate).format("DD/MM/yyyy"),
        // partnerId: model.partnerId,
        version: model.version,
    };
    console.log(options);
    const data = await http.post(apiEndpoint + "/ops/reports/exportCibilReport", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response : { fileName: "", fileURL: "", success: false, message: error }; });
    return data;
}