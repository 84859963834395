import React from "react";
import { Link } from "react-router-dom";
import { Form, Col, Button, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import DatePicker from "react-datepicker2";
import TrancheModel from "../../models/tranche/trancheModel";
import ReactExport from "react-export-excel";

class Tranche extends TrancheModel {
    getPagedData = () => {
        const { pageSize, currentPage, list } = this.state;
        const filteredList = paginate(list, currentPage, pageSize);

        return { totalCount: list.length, data: filteredList };
    };
    render() {
        const { data, showSpinner, errors, pageSize, currentPage, successMsg, lendingPartnerList, exportList, loansExportList } = this.state;
        const { totalCount, data: list } = this.getPagedData();
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        let i = pageSize * (currentPage - 1);
        return (<React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textDecoration: "underline", textAlign: "center" }}>Tranche List</h2>
            {" "}
            <Form onSubmit={this.handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} md="4" controlId="lendingPartner">
                        <Form.Label>Leanding Partner</Form.Label>
                        <Form.Control
                            as="select"
                            name="lendingPartner"
                            value={data["lendingPartner"]}
                            onChange={this.handleChange}
                        >
                            {" "}
                            <option key="all" value="">
                                ---All---
                            </option>
                            {lendingPartnerList.map((item, i) => (
                                <option key={i} value={item._id}>
                                    {item.displayName}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="fromDate">
                        <Form.Label>From Date</Form.Label>
                        <DatePicker
                            name="fromDate"
                            id="fromDate"
                            value={data["fromDate"]}
                            max={moment()}
                            inputFormat="DD/MM/YYYY"
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleFromDateChange(value)}
                            placeholder="Enter From Date"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="toDate">
                        <Form.Label>To Date</Form.Label>
                        <DatePicker
                            name="toDate"
                            id="toDate"
                            value={data["toDate"]}
                            max={moment()}
                            inputFormat="DD/MM/YYYY"
                            isGregorian={true}
                            className="form-control"
                            inputReadOnly={true}
                            showTodayButton={false}
                            timePicker={false}
                            onChange={(value) => this.handleToDateChange(value)}
                            placeholder="Enter To Date"
                        />
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Button
                            className="ff-button"
                            type="submit"
                            style={{ marginTop: 30, marginLeft: 10 }}
                        >
                            Apply Filters
                        </Button>

                        {
                            <ExcelFile element={<Button
                                className="ff-button"
                                type="button"
                                style={{ marginTop: 30, marginLeft: 10 }}
                            >
                                Export to Excel
                            </Button>}
                                filename={`Tranche_${moment(new Date()).format("DDMMYYYY")}`}
                            >
                                <ExcelSheet data={exportList} name="Tranche List">
                                    <ExcelColumn label="Partner" value="partner" />
                                    <ExcelColumn label="Tranche No" value="trancheNo" />
                                    <ExcelColumn label="Amount" value="amount" />
                                    <ExcelColumn label="Transaction Date" value={(col) => col.transactionDate === null ? "" : moment(col.transactionDate).locale('en').format('DD/MM/YYYY hh:mm a')} />
                                    <ExcelColumn label="Deposit Date" value={(col) => col.depositDate === null ? "" : moment(col.depositDate).locale('en').format('DD/MM/YYYY hh:mm a')} />
                                    <ExcelColumn label="FLDG Amount" value="fldgAmount" />
                                    <ExcelColumn label="Transaction Expiry Date" value={(col) => col.expiryDate === null ? "" : moment(col.expiryDate).locale('en').format('DD/MM/YYYY hh:mm a')} />
                                    <ExcelColumn label="Status" value={(col) => (col.isOpen == null || col.isOpen == 1 || col.isOpen == true) ? "Open" : "Close"} />
                                </ExcelSheet>
                            </ExcelFile>
                        }

                        <Button
                            className="ff-button"
                            type="button"
                            name="Export&all"
                            onClick={this.handleExport}
                            style={{ marginTop: 30, marginLeft: 10 }}
                        >
                            Export to Excel Tranche Loans
                        </Button>
                        {loansExportList.length > 0 ?
                            <ExcelFile element={<button>Download Data</button>} hideElement={true}>
                                <ExcelSheet data={loansExportList} name="Tranche Loans">
                                    <ExcelColumn label="Loan No" value="loanNo" />
                                    <ExcelColumn label="Name" value="name" />
                                    <ExcelColumn label="Emis Collected" value="emisCollected" />
                                    <ExcelColumn label="Loan Amount" value="loanAmount" />
                                    <ExcelColumn label="Emi" value="emi" />
                                    <ExcelColumn label="Principal" value="principle" />
                                    <ExcelColumn label="Interest" value="interest" />
                                    <ExcelColumn label="Tranche No" value="trancheNo" />
                                    <ExcelColumn label="Tranche Date" value={(col) => col.trancheDate === null ? "" : moment(col.trancheDate).locale('en').format('DD/MM/YYYY hh:mm a')} />
                                </ExcelSheet>
                            </ExcelFile>
                            :
                            null}
                    </Form.Group>
                </Form.Row>
            </Form>
            <br />
            {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
            {successMsg && <div className="alert alert-success">{successMsg}</div>}
            <br />
            <Table responsive striped bordered hover>
                <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>Partner</th>
                        <th>Tranche No</th>
                        <th>Amount</th>
                        <th>Transaction Date</th>
                        <th>Deposit Date</th>
                        <th>FLDG Amount</th>
                        <th>Transaction Expiry Date</th>
                        <th>Status</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {list && list.map((item) => {
                        i += 1;
                        return (
                            <tr key={item.trancheId} >
                                <th>{i}</th>
                                <td>{item.partner === "INDITRADE FINCORP LIMITED" ? "INDITRADE" : item.partner}</td>
                                <td>{item.trancheNo}</td>
                                <td>{item.amount}</td>
                                <td>{item.transactionDate === null ? "" : moment(item.transactionDate).locale('en').format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{item.depositDate === null ? "" : moment(item.depositDate).locale('en').format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{item.fldgAmount}</td>
                                <td>{item.expiryDate === null ? "" : moment(item.expiryDate).locale('en').format('DD/MM/YYYY hh:mm a')}</td>
                                <td>{(item.isOpen === null || item.isOpen === 1 || item.isOpen === true) ? "Open" : "Close"}</td>
                                <td><Link to={`/trancheUpdate/${item.trancheId}`}>Update</Link> | <Link name={"Export&" + item.trancheNo} onClick={this.handleExport}>Download</Link></td>
                            </tr>
                        );
                    })}
                    {!list && (
                        <tr key="no">
                            <td colSpan="10">No record found</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <div>
                <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                    onPagePrevious={this.handlePagePrevious}
                    onPageNext={this.handlePageNext}
                />
            </div>
        </React.Fragment>
        )
    }
}
export default Tranche;