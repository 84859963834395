import InputDataForm from "../../components/common/form";
import * as analytics from "../../utils/analytics";
import * as contractService from "../../services/contract/contractService";
import Joi from "joi-browser";
import leadInfoService from "../../services/partner/leadInfoService";
import * as commonService from "../../services/common/commonService";
import _ from "lodash";
import moment from "moment";
import auth from "../../services/common/authService";
// import SharedCache from "../../SharedCache";

class CreditLineSummaryModel extends InputDataForm {
    state = {
        data: {
            userId: "",
            loanId: "",
            date: "",
            invoiceId: "",
            amount: "",
            agentName: "",
            agentNumber: "",
            repaymentDate: "",
            loanStatus: "",
            otp: "",
            shortauthKey: ""
        },
        errors: {},
        info: {},
        showSpinner: false,
        isSubmitted: false,
        approvedCreditLimit: "",
        availableCreditLimit: "",
        isRegenerateLink: false,
        isRegenerateText: false,
        counter: 0,
        timerId: 0,
        timer: "",
        validForSigning: false,
        isSigned: false,
        successMessage: "",
        errorMessage: "",
        otpAutoFill: false,
        callBackUrl: ""
    };
    schema = {
        userId: Joi.string().required(),
        loanId: Joi.string().required(),
        date: Joi.string().required(),
        invoiceId: Joi.string().required(),
        amount: Joi.number().min(1).required().label("Invoice Amount"),
        agentName: Joi.string().allow("").required().label("Delivery Agent Name"),
        agentNumber: Joi.number().allow("").required().label("Delivery Agent Mobile Number"),
        repaymentDate: Joi.string().required(),
        otp: Joi.number().required(),
    };
    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");
        analytics.track();
        if (this.props.location.state.userData) {
            let {
                loanId, agentName, agentNumber, shortauthKey
            } = this.props.location.state.userData;

            let { data } = this.state;

            data.loanId = loanId ? loanId : "";
            data.agentName = agentName ? agentName : "";
            data.agentNumber = agentNumber ? agentNumber : "";
            data.shortauthKey = shortauthKey ? shortauthKey : "";

            const partnerId = this.props.match.params.id;

            // const userData = await leadInfoService.userData(partnerId, false);

            // SharedCache.init(userData);
            // SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
            // SharedCache.app.refresh();

            this.setState({ data });

            //OTP Auto-Fill
            if ("OTPCredential" in window) {
                const ac = new AbortController();

                navigator.credentials
                    .get({
                        otp: { transport: ["sms"] },
                        signal: ac.signal,
                    })
                    .then((otp) => {
                        let { data } = this.state;
                        data.otp = otp.code;
                        this.setState({ data, otpAutoFill: true });
                        this.verifyOTP();
                        ac.abort();
                    })
                    .catch((err) => {
                        ac.abort();
                        console.log(err);
                    });
            }

            await this.getLoanData();
        }
    }
    async getLoanData() {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true });

        try {
            let response = await leadInfoService.getChildLoanDetails(
                data
            );

            if (response.success) {
                data.userId = response.data.userId;
                data.loanStatus = response.data.status;
                data.repaymentDate = response.data.campaignTime;
                data.loanId = response.data.loanId;
                data.date = response.data.date ? moment(response.data.date).format("DD/MM/yyyy") : "";
                data.invoiceId = response.data.invoiceId;
                data.amount = response.data.amount;

                auth.getMenu();

                let isValidForSigning = false;

                let successMessage = "";
                let errorMessage = "";

                if (response.data.eNachRequired && !response.data.eNachSigned)
                    errorMessage = "Please complete e-Nach process before loan consent";
                else if (response.data.isSigned)
                    successMessage = "Loan consent has been provided";
                else if (data.loanStatus === "Under Review" || data.loanStatus === "Approved By Admin" || data.loanStatus === "Accepted By Client")
                    errorMessage = "Loan not in stage for consent";
                else if (data.loanStatus === "Loan Is Funding")
                    isValidForSigning = true;

                const currentPartner = auth.getCurrentPartner();

                this.setState({
                    data,
                    showSpinner: false,
                    approvedCreditLimit: response.data.approvedCreditLimit,
                    availableCreditLimit: response.data.availableCreditLimit,
                    isSigned: response.data.isSigned,
                    isValidForSigning: isValidForSigning,
                    successMessage,
                    errorMessage,
                    callBackUrl: currentPartner && currentPartner.callBackUrl ? currentPartner.callBackUrl : ""
                });

                if ((!response.data.eNachRequired || (response.data.eNachRequired && response.data.eNachSigned)) && !response.data.isSigned && response.data.status.toLowerCase() === "loan is funding") await this.sendOtp();

            } else {
                errors["globalError"] = response.message;
                this.setState({
                    errors,
                    showSpinner: false,
                });
            }
        } catch (ex) {
            alert(ex.message);
        }
    }
    sendOtp = async () => {
        const { data, errors, info } = this.state;

        this.setState({ showSpinner: true });

        try {

            if (data.loanId !== "") {
                const { data: response } = await contractService.getOTPForCreditLineConsent(
                    data.loanId
                );

                if (response.success) {
                    delete errors["otp"];

                    this.setState({
                        data,
                        info,
                        errors,
                        showSpinner: false,
                    });
                    this.startTimer();
                } else {
                    errors["otp"] = response.message;
                    this.setState({
                        errors,
                        showSpinner: false,
                        isValidUserName: false,
                    });
                }
            }

        } catch (ex) {
            errors["otp"] = ex.response.message;
            delete info["otp"];
            this.setState({
                errors,
                info,
                showSpinner: false,
            });
        }
    };
    async eSignLoanContract() {
        let { data, callBackUrl, otpAutoFill } = this.state;
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            const ipAddress = await commonService.getIpAddress();

            const { data: loanDetails } = await contractService.signCreditLineContract(
                data.loanId,
                data.otp,
                data.invoiceId,
                data.amount,
                data.agentName,
                data.agentNumber,
                ipAddress,
                otpAutoFill
            );

            if (loanDetails.success) {
                this.setState({
                    isSigned: true, isValidForSigning: false,
                    successMessage: "Loan consent has been provided", errorMessage: "",
                    approvedCreditLimit: loanDetails.approvedCreditLimit,
                    availableCreditLimit: loanDetails.availableCreditLimit,
                    isSubmitted: false,
                    showSpinner: false,
                    errors
                });

                if (loanDetails.success && callBackUrl)
                    setTimeout(this.redirect(callBackUrl), 5000);
            }
            else this.setState({
                errorMessage: loanDetails.message,
                isSubmitted: false,
                showSpinner: false,
                errors
            });

        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ showSpinner: false, errors, isSubmitted: false });
        }
    }
    async handleCustomErrors() {
        const { data, errors } = this.state;

        delete errors["globalError"];

        if (!errors["otp"]) {
            if (data.otp === "") errors["otp"] = "Please enter OTP";
            else if (data.otp !== "" && (data.otp.length !== 6 || isNaN(data.otp)))
                errors["otp"] = "Invalid OTP";
            else delete errors["otp"];
        }

        this.setState({ errors });

        return errors;
    }
    doSubmit = async (e) => {
        let { errors } = await this.handleCustomErrors();

        if (_.isEmpty(errors)) await this.eSignLoanContract();
    };
    redirect(callBackUrl) {
        auth.logout();
        window.location = callBackUrl;
    }
}

export default CreditLineSummaryModel;
