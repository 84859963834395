import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class InvoiceDashboardModel extends InputDataForm {
    state = {
        data: {
            status: "",
            fromDate: momentJalaali().startOf('month'),
            toDate: momentJalaali(),
            invoiceNo: "",
            invoiceList: [],
            partnerId: "all",
            amount: "",
            invoiceType: "",
            page: 1,
            actionStatus: "",
            actionComment: ""
        },
        showSpinner: false,
        errors: {},
        currentPage: 1,
        pageSize: 10,
        invoiceStatusList: [],
        invoiceTypeList: [],
        partners: [],
        evaluatorRole: "",
        showActionPopup: false,
        invoiceNo: "",
        invoiceStatus: "",
        invoiceType: "",
        transactionId: "",
        paymentError: "",
        isValidForPaymentInitiation: false,
        isValidForPaymentReInitiation: false,
        accountName: "",
        accountNo: "",
        ifscCode: "",
        bankName: ""
    };
    schema = {
        fromDate: Joi.any(),
        toDate: Joi.any(),
        status: Joi.any(),
        invoiceNo: Joi.any(),
        invoiceList: Joi.any(),
        partnerId: Joi.any(),
        page: Joi.any(),
        amount: Joi.any(),
        invoiceType: Joi.any(),
        actionStatus: Joi.any(),
        actionComment: Joi.any()
    };
    async loadDropdowns() {
        let { invoiceStatusList, invoiceTypeList } = this.state;
        try {
            const {
                data: invoiceStatusListResponse,
            } = await commonService.getRoleWiseDefinitionList("Invoice Status");

            invoiceStatusList = invoiceStatusListResponse.data;

            const {
                data: getInvoiceTypeList,
            } = await commonService.getInvoiceTypeList();

            invoiceTypeList = getInvoiceTypeList.data;

            this.setState({
                invoiceStatusList,
                invoiceTypeList
            });
        } catch (ex) { }
    };
    async getInvoiceList() {

        this.state.data.page = this.state.currentPage;

        const data = { ...this.state.data };

        this.setState({ showSpinner: true });
        try {
            const { data: invoiceList, totalPages } = await loanService.getInvoiceList(data);

            data.invoiceList = invoiceList;

            this.setState({ data, showSpinner: false, pageSize: totalPages });
        } catch (ex) {
            this.setState({ data, showSpinner: false });
        }
    };
    approveInvoice = async () => {
        const { invoiceNo, data, errors } = this.state;

        if (!data.actionStatus)
            errors["actionStatus"] = "Please select an option";
        else
            delete errors["actionStatus"];

        if (!data.actionComment)
            errors["actionComment"] = "Please enter comments";
        else
            delete errors["actionComment"];

        if (errors["actionStatus"] || errors["actionComment"]) {
            this.setState({ errors });
            return;
        }

        this.setState({ showSpinner: true, showActionPopup: false });

        try {
            const { success, message } = await loanService.approveInvoice(data.actionStatus, invoiceNo, data.actionComment);

            if (success) {
                data["actionStatus"] = "";
                data["actionComment"] = "";

                this.setState({ invoiceNo: "", data });
                await this.getInvoiceList();
            }
            else {
                errors["globalError"] = message;
                this.setState({ errors, showSpinner: false, showActionPopup: true });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, showActionPopup: true });
        }
    };
    initiatePayment = async () => {
        const { invoiceNo, data, errors } = this.state;

        this.setState({ showSpinner: true, showActionPopup: false });

        try {
            const { success, message } = await loanService.initiatePayment(invoiceNo);

            if (success) {
                data["actionStatus"] = "";
                data["actionComment"] = "";

                this.setState({ invoiceNo: "", data, invoiceStatus: "", invoiceType: "", transactionId: "", showActionPopup: false });
                await this.getInvoiceList();
            }
            else {
                errors["globalError"] = message;
                this.setState({ errors, showSpinner: false, showActionPopup: true });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, showActionPopup: true });
        }
    };
    reInitiatePayment = async () => {
        const { data, errors, transactionId } = this.state;

        this.setState({ showSpinner: true, showActionPopup: false });

        try {
            const { success, message } = await loanService.reInitiatePayment(transactionId);

            if (success) {
                data["actionStatus"] = "";
                data["actionComment"] = "";

                this.setState({ invoiceNo: "", data, invoiceStatus: "", invoiceType: "", transactionId: "", showActionPopup: false });
                await this.getInvoiceList();
            }
            else {
                errors["globalError"] = message;
                this.setState({ errors, showSpinner: false, showActionPopup: true });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, showActionPopup: true });
        }
    };
    async componentDidMount() {
        if (!auth.getCurrentUser()) return;

        const currentUser = auth.getCurrentUser();
        if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

        await this.getInvoiceList();
        await this.loadDropdowns();
        await this.getPartnerList();
    };
    async getPartnerList() {
        try {
            const { data: partnerList } = await commonService.getAllPartnerList();
            this.setState({ partners: partnerList });
        }
        catch (ex) {
            this.setState({ partners: [] });
        }
    };
    async handleCustomErrors(errors) {
        return errors;
    };
    async doSubmit() {
        this.resetPage()
        await this.getInvoiceList();
    };
    onPageNext = () => {
        if (this.state.pageSize < this.state.currentPage)
            return

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getInvoiceList();
        })
    }
    onPageChange = (page) => {
        this.setState({ currentPage: page }, () => {
            this.getInvoiceList();
        })
    }
    onPagePrevious = () => {
        if (this.state.currentPage <= 1)
            return

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {
            this.getInvoiceList();
        })
    }
    resetPage = () => {
        this.state.data.page = 1
        this.setState({ currentPage: 1, pageSize: 0 })
    }
    showActionPopUp = async (model) => {
        const { evaluatorRole } = this.state;

        if (evaluatorRole === "Admin")
            this.setState({
                invoiceNo: model.invoiceNo,
                invoiceStatus: model.status,
                invoiceType: model.invoiceType,
                transactionId: model.transactionId,
                paymentError: model.paymentError,
                isValidForPaymentInitiation: model.isValidForPaymentInitiation,
                isValidForPaymentReInitiation: model.isValidForPaymentReInitiation,
                ifscCode: model.bankDetails.ifsc ? model.bankDetails.ifsc : "",
                accountName: model.bankDetails.name ? model.bankDetails.name : "",
                bankName: model.bankDetails.bankName ? model.bankDetails.bankName : "",
                accountNo: model.bankDetails.no ? model.bankDetails.no : "",
                showActionPopup: true
            });
    };
    handleClose = () => {
        const { data, errors } = this.state;

        data["actionStatus"] = "";
        data["actionComment"] = "";
        errors["globalError"] = "";

        this.setState({ showActionPopup: false, invoiceNo: "", data, errors });
    };
}

export default InvoiceDashboardModel;
