import Joi from "joi-browser";
import InputForm from "../../components/common/form";
import * as updateEmailMobileService from "../../services/profile/updateAlternateEmailService";
import * as forgotPasswordFormService from "../../services/login/forgotPasswordFormService";
import * as registFormService from "../../services/login/registerFormService";
import * as kycFormService from "../../services/borrow/kycFormService";
import auth from "../../services/common/authService";
import * as analytics from "../../utils/analytics";
import * as myProfileService from '../../services/profile/myProfile'
import { toast } from "react-toastify";

class UpdateMobileFormModel extends InputForm {
  state = {
    data: {
      mobileNo: "",
      updatedMobileNo: "",
      otp: "",
    },
    errors: {},
    info: {},
    showSpinner: false,
    counter: 0,
    timerId: 0,
    timer: "",
    otpSent: false,
    isValidMobile: false,
    isValidOTP: false,
  };
  schema = {
    mobileNo: Joi.number().required(),
    otp: Joi.string().required().min(6),
    updatedMobileNo: Joi.number().required().label("New Mobile No")
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");
    analytics.track();
    await this.getUserData();
  }

  sendOTP = async () => {

    const { data, errors } = this.state;

    if (data.mobileNo == data.updatedMobileNo) {
      toast.error("New Mobile Number should not same with current mobile Number")
      return
    }

    this.setState({ showSpinner: true });


    try {
      const { success, message } = await myProfileService.changeMobileNo(data.updatedMobileNo);

      if (success) {
        delete errors["updatedMobileNo"];
        delete errors["otp"];
        this.setState({
          errors,
          data,
          otpSent: true,
          showSpinner: false,
        });
        this.startTimer();
      } else {
        errors["updatedMobileNo"] = message;
        this.setState({
          errors,
          data,
          showSpinner: false,
          otpSent: false,
        });
      }
    } catch (ex) {
      errors["updatedMobileNo"] = ex.response.data.message;
      this.setState({ data, errors, showSpinner: false });
    }
  };

  async handleCustomErrors(errors) {
    const {
      data,
      // isValidMobile,
      isValidOTP,
    } = this.state;

    if (!errors["otp"] && data.otp) {
    }

    this.setState({ errors });

    return errors;
  }

  async getUserData() {
    let data = { ...this.state.data };

    const currentUser = auth.getCurrentUser();
    if (!currentUser) return (window.location = "/login");

    const partnerUser = (currentUser.role === "Lending Partner" || currentUser.role === "Borrowing Partner") ? true : false;

    const currentPartner = auth.getCurrentPartner();

    let partnerId = null;

    if (currentPartner && currentPartner._id)
      partnerId = currentPartner._id;

    try {
      const { data: userDetails } = await kycFormService.getUserDetails(
        currentUser.userId, partnerUser, partnerId, null
      );
      if (userDetails.success) {
        data.mobileNo = userDetails.data.user.userDetails.mobileNo[0] ? userDetails.data.user.userDetails.mobileNo[0].mobileNumber : "";
        this.setState({ data });
      }
    } catch (ex) { }
  }

  handleMobileChange = async ({ currentTarget: input }) => {
    const { errors } = this.state;

    if (input.value.length !== 10 || isNaN(input.value)) {
      errors.updatedMobileNo = "Invalid mobile number";
      this.setState({ errors });
      return;
    } else {
      delete errors.updatedMobileNo;
      this.setState({ errors });
    }
  };

  doSubmit = async () => {
    const errors = { ...this.state.errors };
    const data = { ...this.state.data };
    this.setState({ showSpinner: true, errors: {} });

    try {
      const {
        success,
        message,
      } = await updateEmailMobileService.updateMobile(
        data.updatedMobileNo,
        "mobile",
        data.otp
      );

      if (success) window.location = "/myprofile";
      else {
        errors.globalError = message;
        this.setState({ data, errors, showSpinner: false });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        errors.globalError = ex.response.data.message;
        this.setState({ data, errors, showSpinner: false });
      }
    }
  };
}

export default UpdateMobileFormModel;
