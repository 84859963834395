import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import auth from "../../services/common/authService";
import Joi from "joi-browser";
import _ from "lodash";

class InvoiceDetailsModel extends InputDataForm {

    constructor(props) {
        super(props)
    }
    state = {
        data: {
            invoiceNo: "",
            status: "",
            comments: ""
        },
        staticData: {
            invoiceDetails: {
                invoiceNo: "",
                status: "",
                invoiceDate: "",
                billedTo: "",
                billedBy: "",
                totalAmount: "",
                totalTaxAmount: "",
                totalInvoiceAmount: "",
                invoiceType: "",
                filePath: ""
            },
            itemList: []
        },
        showSpinner: false,
        errors: {},
        isSubmitted: false,
        evaluatorRole: ""
    };
    schema = {
        invoiceNo: Joi.string().required(),
        status: Joi.string().required(),
        comments: Joi.string().required()
    };
    async mapDataToState(userData) {
        let staticData = { ...this.state.staticData };
        let data = { ...this.state.data };

        let {
            invoiceNo,
            invoiceDate,
            billedTo,
            billedBy,
            listItems,
            status,
            totalAmount,
            totalTaxAmount,
            totalInvoiceAmount,
            invoiceType,
            filePath
        } = userData;

        const currentUser = auth.getCurrentUser();

        if (invoiceNo)
            staticData.invoiceDetails.invoiceNo = invoiceNo;

        if (invoiceNo)
            data.invoiceNo = invoiceNo;

        if (invoiceDate)
            staticData.invoiceDetails.invoiceDate = invoiceDate;

        if (billedBy)
            staticData.invoiceDetails.billedBy = billedBy;

        if (billedTo)
            staticData.invoiceDetails.billedTo = billedTo;

        if (invoiceType)
            staticData.invoiceDetails.invoiceType = invoiceType;

        if (listItems)
            staticData.itemList = listItems;

        if (status)
            staticData.invoiceDetails.status = status;

        if (totalAmount)
            staticData.invoiceDetails.totalAmount = totalAmount;

        if (totalTaxAmount)
            staticData.invoiceDetails.totalTaxAmount = totalTaxAmount;

        if (totalInvoiceAmount)
            staticData.invoiceDetails.totalInvoiceAmount = totalInvoiceAmount;

        if (filePath)
            staticData.invoiceDetails.filePath = filePath;

        this.setState({
            data,
            staticData,
            evaluatorRole: currentUser.role
        });
    }
    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();

        if (currentUser.role !== "Admin" && currentUser.role !== "Lending Partner" && currentUser.role !== "Borrowing Partner")
            return (window.location = "/");

        let invoiceNo = this.props.match.params.id;

        if (!invoiceNo)
            return window.location.href = "/invoiceDashboard";
        else
            await this.getInvoiceDetails(invoiceNo);

    }
    async getInvoiceDetails(invoiceNo) {
        this.setState({ showSpinner: true });
        try {
            const { data: invoiceDetails, success } = await loanService.getInvoiceDetails(invoiceNo);

            if (success) {
                await this.mapDataToState(invoiceDetails);
                this.setState({ showSpinner: false });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            this.setState({ showSpinner: false });
        }
    }
    approveInvoice = async () => {
        const { data, errors } = this.state;

        this.setState({ showSpinner: true, showActionPopup: false });

        try {
            const { success, message } = await loanService.approveInvoice(data.status, data.invoiceNo, data.comments);

            if (success)
                window.location.href = "/invoiceDashboard";
            else {
                errors["globalError"] = message;
                this.setState({ errors, showSpinner: false });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false });
        }
    };
    async handleCustomErrors() {
        const data = { ...this.state.data };
        let errors = { ...this.state.errors };

        delete errors["globalError"];

        if (!data.status)
            errors["status"] = "Please select an option";
        else
            delete errors["status"];

        if (!data.comments)
            errors["comments"] = "Please enter comments";
        else
            delete errors["comments"];

        this.setState({ errors });

        return errors;
    }
    doSubmit = async (e) => {
        e.preventDefault();
        let errors = await this.handleCustomErrors();

        if (_.isEmpty(errors)) await this.approveInvoice();
    };
    initiatePayment = async () => {
        const { data, errors } = this.state;

        this.setState({ showSpinner: true, showActionPopup: false });

        try {
            const { success, message } = await loanService.initiatePayment(data.status, data.invoiceNo);

            if (success)
                window.location.href = "/invoiceDashboard";
            else {
                errors["globalError"] = message;
                this.setState({ errors, showSpinner: false });
            }
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false });
        }
    };
}

export default InvoiceDetailsModel;
