import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "font-awesome/css/font-awesome.css";
import ScrollIntoView from "./components/common/scrollToTop";
import { unregister } from './registerServiceWorker';
// import ReactGA from 'react-ga';

// ReactGA.initialize('G-0MN4V55ZQG');
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <BrowserRouter>
    <ScrollIntoView>
      <App />
    </ScrollIntoView>
  </BrowserRouter>,
  document.getElementById("root")
);

unregister();
