import React from "react";
import { Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import ReconcliationModel from "../../models/ReconcliationModal/ReconcliationModal";
import SpinnerComponent from "../../components/common/spinner";
import { Row, Cell, StickyTable } from 'react-sticky-table';
import { Modal } from "react-bootstrap";


class ReconcliationModal extends ReconcliationModel {
    getPagedData = () => {
        const { data } = this.state;
        let transactions = data.transactions;
        return { totalCount: transactions.length, data: transactions };
    };
    render() {
        const {
            showSpinner
        } = this.state;

        const { data: transactions } = this.getPagedData();

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <Modal
                size="lg"
                show={true}
                onHide={this.props.close}
                aria-labelledby="example-custom-modal-styling-title"
                dialogClassName="settlement-model"
            >

                <Modal.Header closeButton>
                    <Modal.Title> <span className="text-danger">Settlement Transaction Details</span></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <SpinnerComponent showSpinner={showSpinner} />
                    <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0} leftStickyColumnCount={0}>
                            <Row>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderLeft: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Transaction Date</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Principal Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Interest Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Fee Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Total Maratorium Interest</b></Cell>
                            </Row>
                            {transactions &&
                                transactions.map((transaction, i) => (
                                    <Row key={transaction._id}>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.transactionDate && new Date(transaction.transactionDate).toLocaleDateString()}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.totalAmount}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.installmentPrincipal}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.interestAmount}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.feeAmount}</Cell>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: `${transactions.length - 1 <= i ? '1px solid' : ""}` }}>{transaction.moratoriumInterest}</Cell>
                                    </Row>
                                ))}
                            {!transactions.length && (
                                <td colSpan={6} className='text-center'>No Record Found</td>
                            )}

                        </StickyTable>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ReconcliationModal;
