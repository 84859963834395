import { apiUrl } from "../../config.json";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../common/httpService";

const apiEndpoint = apiUrl + "/ops/";

// axios.interceptors.response.use(null, (error) => {
//   const expectedError =
//     error.response &&
//     error.response.status >= 400 &&
//     error.response.status < 500;

//   if (!expectedError) {
//     toast.error("An unexpected error occurrred.");
//   }

//   return Promise.reject(error);
// });

export async function getUserDetails(userId, trxnId, loanAmount, token, isAutoLogin) {
  axios.defaults.headers.common["auth-key"] = token;
  const { data } = await axios.post(`${apiEndpoint}partner/getUserDetails`, {
    userId,
    trxnId,
    loanAmount,
    isAutoLogin
  });

  return data;
}

export async function downloadImage(
  docNo,
  url,
  imageType,
  fileName,
  filePath,
  userId
) {
  let appUrl = `${apiEndpoint}partner/downloadImage`;
  //axios.defaults.headers.common["auth-key"] = token;
  const response = await axios({
    url: appUrl,
    method: "POST",
    responseType: "application",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      docNo,
      url,
      imageType,
      fileName,
      filePath,
      userId,
    },
  });
  // const { data } = await http.post(`${apiEndpoint}partner/downloadImage`, {
  //   docNo,
  //   url,
  //   imageType,
  //   fileName,
  //   filePath,
  //   userId,
  // });
  alert(response);
  return response;
}

export async function generateOTPForMerchant(messageType, loanId, isWhatsApp = "checked", isVerifyMobileNumber) {
  const { data } = await http.post(
    `${apiEndpoint}verification/generateOTPForMerchant`,
    {
      messageType,
      loanId,
      isWhatsApp: isWhatsApp === "checked" ? true : false,
      isVerifyMobileNumber
    }
  );
  return data;
}

export async function verifyCustomOTPForMerchant(
  otp,
  messageType,
  loanId,
  otpAutoFill = false,
  isWhatsApp = "checked",
  isUpdate = false,
  confirmed = false,
  mobileNo,
  isVerifyMobileNumber,
  isAgent
) {
  const { data } = await http.post(
    `${apiEndpoint}verification/verifyCustomOTPForMerchant`,
    {
      otp,
      messageType,
      loanId,
      otpAutoFill,
      isWhatsApp: isWhatsApp === "checked" ? true : false,
      isUpdate,
      confirmed,
      mobileNo,
      isVerifyMobileNumber,
      isAgent
    },
    { headers: { loggedInFrom: "RedirectAuthLink" } }
  );
  return data;
}

export async function generateOTPForMerchantChangeMobileNumber(messageType, loanId, isWhatsApp = "checked", mobileNo, isVerifyMobileNumber) {
  const { data } = await http.post(
    `${apiEndpoint}verification/generateOTPForMerchantChangeMobileNumber`,
    {
      messageType,
      loanId,
      isWhatsApp: isWhatsApp === "checked" ? true : false,
      mobileNo,
      isVerifyMobileNumber
    }
  );
  return data;
}


export async function confirmMerchantMobileNumber(loanId,) {
  const { data } = await http.post(
    `${apiEndpoint}verification/confirmMerchantMobileNumber`,
    {
      loanId,
    }
  );
  return data;
}


export async function getChildLoanDetails({ loanId, shortauthKey }) {
  const { data } = await axios.post(`${apiEndpoint}loan/getChildLoanDetails`, {
    loanId,
    shortauthKey
  });

  return data;
}

export async function userData(partnerKey, is_encrypted) {
  const { data } = await axios.get(`${apiEndpoint}auth/userdata`, {
    headers: {
      partnerkey: partnerKey,
      isencrypted: is_encrypted
    }
  });

  return data;
}

export async function saveDeviceInfo(partnerId, merchantCode, browserName = null,
  browserVersion = null,
  fullBrowserVersion = null,
  osName = null,
  osVersion = null,
  mobileVendor = null,
  mobileModel = null) {
  const { data } = await axios.post(`${apiEndpoint}verification/saveDeviceInfo`, {
    partnerId,
    merchantCode,
    browserName,
    browserVersion,
    fullBrowserVersion,
    osName,
    osVersion,
    mobileVendor,
    mobileModel
  });

  return data;
}

export async function getMaskedMobileNumber(loanId) {
  const { data } = await axios.post(`${apiEndpoint}verification/getMaskedMobileNumber`, {
    loanId
  });

  return data;
}

export async function getPartnerId(loanId) {
  const { data } = await http.post(apiEndpoint + "verification/getPartnerId", {
    loanId
  });

  return data;
}

export async function allowMobileUpdate(loanId) {
  const { data } = await http.post(
    `${apiEndpoint}verification/allowMobileUpdate`,
    {
      loanId
    }
  );
  return data;
}

export async function generateOTPwithMobile(messageType, loanId, isWhatsApp = "checked", mobile) {
  const { data } = await http.post(
    `${apiEndpoint}verification/generateOTPwithMobile`,
    {
      messageType,
      loanId,
      isWhatsApp: isWhatsApp === "checked" ? true : false,
      mobile
    }
  );
  return data;
}

export async function verifyOTPwithMobile(
  otp,
  messageType,
  loanId,
  otpAutoFill = false,
  isWhatsApp = "checked",
  mobile
) {
  const { data } = await http.post(
    `${apiEndpoint}verification/verifyOTPwithMobile`,
    {
      otp,
      messageType,
      loanId,
      otpAutoFill,
      isWhatsApp: isWhatsApp === "checked" ? true : false,
      mobile
    },
    { headers: { loggedInFrom: "RedirectAuthLink" } }
  );
  return data;
}

export default {
  getUserDetails,
  generateOTPForMerchant,
  verifyCustomOTPForMerchant,
  confirmMerchantMobileNumber,
  generateOTPForMerchantChangeMobileNumber,
  downloadImage,
  getChildLoanDetails,
  userData,
  saveDeviceInfo,
  getMaskedMobileNumber,
  getPartnerId,
  generateOTPwithMobile,
  verifyOTPwithMobile,
  allowMobileUpdate
};
