import React from 'react';
import { Redirect, Link } from "react-router-dom";
import { Form, Col, Button, Table } from "react-bootstrap";
import TrueScoreResultModel from './../../models/trueScore/trueScoreResultModel';

class TrueScoreResult extends TrueScoreResultModel {

    render() {
        const data = { ...this.state.data };
        return (
            <React.Fragment>
                <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
                    TrueScore
                </h2>
                <br />
                <br />
                <Form onSubmit={this.handleSubmit} style={{ paddingTop: 30 }}>
                    <Form.Row>
                        <Form.Group as={Col} md="12" style={{ textAlign: "center" }}>
                            <Form.Label>TrueScore of applicant/individual with PAN {data.pan} is <span style={{ fontSize: 22 }}>{data.trueScore}</span> out of 1000</Form.Label>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="2" style={{ textAlign: "center", fontSize: 14 }}> </Form.Group>
                        <Form.Group as={Col} md="8" style={{ textAlign: "center", fontSize: 14 }}>
                            <Table responsive striped bordered hover className="fundfinaColor" >
                                <thead>
                                    <tr>
                                        <th>TrueScore</th>
                                        <th>Risk Parameter</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Above 570</td>
                                        <td>You will be accepted for credit most of the times</td>
                                    </tr>
                                    <tr>
                                        <td>541-569</td>
                                        <td>You may sometimes be rejected for credit</td>
                                    </tr>
                                    <tr>
                                        <td>Below 540</td>
                                        <td>You will be rejected for credit most of the times</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className="form-group">
                                <div className="col-lg-12">
                                    <ul type="none" style={{ textAlign: "left" }} className="txtlabel fundfinaColor">
                                        <li><u><b><Link to="/borrow">Apply Now</Link></b></u> using your TrueScore</li>
                                        <li><Link to="/home"><b><u>Register</u></b></Link> for our premium service for detailed interpretation of our TrueScore model</li>
                                    </ul>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="2" style={{ textAlign: "center", fontSize: 14 }}> </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" style={{ fontSize: 14 }}>
                            <Form.Label class="txtlabel fundfinaColorBold">How does Fundfina calculate your TrueScore™?</Form.Label><br />
                            <Form.Label>Your TrueScore™ is calculated on a scale of 0 to 1000. It is updated every month on basis of transaction data provided by partnering enterprises. TrueScore is the starting point of cashflow based lending program.</Form.Label><br />
                            <Form.Label class="txtlabel fundfinaColorBold">The score is Fundfina’s interpretation of creditworthiness of your business. Your TrueScore is calculated using –</Form.Label><br />
                            <ul>
                                <li className="fundfinaColor">Transaction values</li>
                                <li className="fundfinaColor">Transaction counts</li>
                                <li className="fundfinaColor">Business vintage</li>
                                <li className="fundfinaColor">Loan history and current outstanding with other lenders</li>
                                <li className="fundfinaColor">Payment history, including any missed payments</li>
                            </ul>
                            <Form.Label class="txtlabel fundfinaColorBold">Tips to Improve TrueScore – DOs</Form.Label><br />
                            <ol>
                                <li className="fundfinaColor">No transaction is too small – Encourage your customers to opt for digital payment</li>
                                <ol type="a">
                                    <li className="fundfinaColor">Make transactions using online medium. The more digital transactions you make the more valuable your business becomes. Avoid accepting cash transactions.</li>
                                </ol>
                                <li className="fundfinaColor">No transaction is too small – Increase your transaction count</li>
                                <li className="fundfinaColor">Getting repeat customers shows stability of your business</li>
                                <li className="fundfinaColor">Your current address is same as your Aadhaar/ Driving License/ Voter Id</li>
                                <li className="fundfinaColor">Avoid maintaining multiple business banking accounts</li>
                                <ol type="a">
                                    <li className="fundfinaColor">Maintaining multiple accounts is a drain on your financial resources. Minimum balance accounts tie up liquid funds which is a bad business choice to make.</li>
                                    <li className="fundfinaColor">Close any unused or seldom used accounts to give a better picture of business. Diverting transactions away from primary banking account creates gaps in business evaluation</li>
                                </ol>
                            </ol>
                            <Form.Label class="txtlabel fundfinaColorBold">Tips to Improve TrueScore – DONTs</Form.Label><br />
                            <ol>
                                <li className="fundfinaColor">Do not miss your payment dates – setup a reminder in your smartphone, if you must, but avoid missing payments. It has adverse effect on your score.</li>
                                <li className="fundfinaColor">Do not apply for loans frequently – too many loan applications in a short span of time makes your business look desperate. It is a sign on weakness in business.</li>
                                <li className="fundfinaColor">Do not overestimate your creditworthiness – nowadays lending companies often ask borrowers for their expected loan amount. Our research has shown that people overestimate their loan amount by nearly 10x. It is a sign of financial indiscipline.</li>
                                <li className="fundfinaColor">Do not overleverage your business – restrict your outstanding business loans to two maximum. Too many loans to run a small business is a sign of financial imprudence.</li>
                            </ol>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </React.Fragment>
        );
    }
}

export default TrueScoreResult;