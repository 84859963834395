import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/loan";
const apiEndpoint1 = apiUrl + "/ops/admin";

export async function getLoanData(loanId) {
  const response = await http.get(
    `${apiEndpoint}/getContractInformation/${loanId}`
  );
  return response;
}

export async function getOTPForContract(loanId) {
  const response = await http.post(`${apiEndpoint}/sendOTPforLoanContract`, {
    loanId,
  });
  return response;
}

export async function signContract(loanId, otp, files, ipAddress, otpAutoFill = false, disbursalConsent = false, coordinates) {
  const response = await http.post(`${apiEndpoint}/verifyOTPforLoanContract`, {
    loanId,
    otp,
    fileName: files.photo,
    fileStream: files.photoStream,
    shopInternalPhoto: files.shopInternalPhoto,
    shopInternalPhotoStream: files.shopInternalPhotoStream,
    shopExternalPhoto: files.shopExternalPhoto,
    shopExternalPhotoStream: files.shopExternalPhotoStream,
    // shopVideo: files.shopVideo,
    // shopVideoStream: files.shopVideoStream,
    ipAddress: ipAddress ? ipAddress : "",
    otpAutoFill,
    disbursalConsent,
    coordinates,
    shopName: ""
  });

  return response;
}

export async function getVideoVerification(loanId) {
  const response = await http.get(`${apiEndpoint1}/getVerificationLink?loanId=${loanId}`);
  return response;
}

export async function checkVerificationResult(loanId) {
  const response = await http.get(`${apiEndpoint1}/videoVerificationResult?loanId=${loanId}`);
  return response;
}

export async function getOTPForCreditLineConsent(loanId) {
  const response = await http.post(`${apiEndpoint}/sendOTPforCreditLineConsent`, {
    loanId
  });
  return response;
}

export async function signCreditLineContract(loanId, otp, invoiceId, amount, agentName, agentNumber, ipAddress, otpAutoFill = false) {
  const response = await http.post(`${apiEndpoint}/verifyOTPforCreditLineContract`, {
    loanId,
    otp: otp ? otp : "",
    invoiceId: invoiceId ? invoiceId : "",
    amount: amount ? amount : 0,
    agentName: agentName ? agentName : "",
    agentNumber: agentNumber ? agentNumber : "",
    ipAddress: ipAddress ? ipAddress : "",
    otpAutoFill
  });
  return response;
}
