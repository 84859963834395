import React from "react";
import { Redirect, Link } from "react-router-dom";
import DatePicker from "react-datepicker2";

import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, FormCheck, Tooltip, OverlayTrigger } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/paginationV2";
import ReactExport from "react-export-excel";
import { StickyTable, Row, Cell } from 'react-sticky-table';
import moment from "moment";
import AgentReportModel from "../../models/agent/agent-report";
import LoanInfoForm from "../loan/loanInfoForm";

const channelConst = {
    UPI_COLLECTION: "UPI",
    VA_COLLECTION: "NEFT",
    WALLET: "WALLET",
}

class AgentReport extends AgentReportModel {


    getPagedData = () => {
        const { pageSize, currentPage, data, allCollections } = this.state;

        let collectionList = data.collectionList;

        return { totalCount: collectionList?.length, data: collectionList, allCollections };
    };

    formatAmount(value, n = 2) {
        let number = value ? +value : 0
        number = number.toLocaleString('en-IN', {
            maximumFractionDigits: n,
            style: 'currency',
            currency: 'INR'
        })
        return number
    }

    exportExcel = () => {
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        const { allCollections } = this.getPagedData();

        return (
            <ExcelFile hideElement={true} filename={`field_collection_${moment(new Date()).format("DDMMYYYY")}`} >
                <ExcelSheet data={allCollections} name="fieldCollection">
                    <ExcelColumn label="Loan No." value={col => col.meta?.loanNo} />
                    <ExcelColumn label="EDIs" value={col => col.emis?.length} />
                    <ExcelColumn label="Payment Link | VA" value={col => col?.paymentLink || col.accountInfo?.accountNumber} />
                    <ExcelColumn label="Ref No" value={col => col?.paymentReferenceNumber} />
                    <ExcelColumn label="Agent Name" value={col => col.agent?.name} />
                    <ExcelColumn label="Created Date" value={col => moment(col.insertedOn).format("DD/MM/yyyy")} />
                    <ExcelColumn label="Amount" value={col => col?.collectionDetail?.collectionAmount || 0} />
                    <ExcelColumn label="Posting Date" value={col => col?.postingDate ? moment(col?.postingDate).format("DD/MM/yyyy") : "-"} />
                    <ExcelColumn label="Status" value={col => col.status} />
                    <ExcelColumn label="Channel" value={col => channelConst[col?.meta?.channel] || ""} />
                    <ExcelColumn label="Partner Name" value={col => col?.borrowingPartner?.name} />
                    <ExcelColumn label="Lender Name" value={col => col?.lendingPartner?.name} />
                    <ExcelColumn label="Disbursal Date" value={col => moment(col?.disbursalDate).format("DD/MM/yyyy")} />
                    <ExcelColumn label="Written Off Date" value={col => moment(col?.writtenOffDate).format("DD/MM/yyyy")} />
                </ExcelSheet>
            </ExcelFile>)
    }

    tooltip = (id, emis) => {
        return <Tooltip id={id}>
            <div>Edi number: {emis.join(", ")}</div>
        </Tooltip>
    }

    tooltip1 = (id) => {
        return <Tooltip id={id}>
            <div>Click to copy</div>
        </Tooltip>
    }

    render() {
        const {
            pageSize,
            currentPage,
            data,
            showSpinner,
        } = this.state;

        const { totalCount, data: collectionList, allLoans } = this.getPagedData();

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>Field Collections Transactions</h2>
                <Form >
                    <Form.Row>
                        <Form.Group as={Col} md="3" controlId="fromDate">
                            <Form.Label>From Date</Form.Label>
                            <DatePicker
                                name="fromDate"
                                id="fromDate"
                                value={data["fromDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleFromDateChange(value)}
                                placeholder="Enter From Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="toDate">
                            <Form.Label>To Date</Form.Label>
                            <DatePicker
                                name="toDate"
                                id="toDate"
                                value={data["toDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleToDateChange(value)}
                                placeholder="Enter To Date"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="mobileNo">
                            <Form.Label>Agent Mobile No</Form.Label>
                            <Form.Control
                                type="text"
                                name="mobileNo"
                                value={data["mobileNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                style={{ textTransform: "uppercase" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="name">
                            <Form.Label>Agent Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={data["name"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="partnerName">
                            <Form.Label>Partner Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="partnerName"
                                value={data["partnerName"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="3" controlId="loanNo">
                            <Form.Label>Loan No.</Form.Label>
                            <Form.Control
                                type="text"
                                name="loanNo"
                                value={data["loanNo"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                                maxLength="100"
                            />
                        </Form.Group>{" "}
                        <Form.Group as={Col} md="3" controlId="amount">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="text"
                                name="amount"
                                value={data["amount"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={data["status"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="ALL" value="">
                                    All
                                </option>
                                <option key="Pending" value="Pending">
                                    Pending
                                </option>
                                <option key="Cancelled" value="Cancelled">
                                    Cancelled
                                </option>
                                <option key="Confirmation Received" value="Confirmation Received">
                                    Confirmation Received
                                </option>
                                <option key="Paid" value="Paid">
                                    Paid
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="channel">
                            <Form.Label>Channel</Form.Label>
                            <Form.Control
                                as="select"
                                name="channel"
                                value={data["channel"]}
                                onChange={this.handleChange}
                            >
                                {" "}
                                <option key="ALL" value="">
                                    All
                                </option>
                                <option key="UPI" value="UPI_COLLECTION">
                                    UPI
                                </option>
                                <option key="NEFT" value="VA_COLLECTION">
                                    NEFT
                                </option>
                                <option key="WALLET" value="WALLET">
                                    WALLET
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                            <Form.Label> &nbsp; </Form.Label>
                            <Button
                                onClick={this.doSubmit}
                                className="ff-button mr-2"
                                style={{ marginTop: 30 }}
                            >
                                Apply Filters
                            </Button>
                            <Button
                                onClick={this.downloadExcel}
                                className="ff-button"
                                style={{ marginTop: 30 }}
                            >
                                Export Excel
                            </Button>
                            {
                                this.state.downloadAll ? this.exportExcel() : ""
                            }
                        </Form.Group>
                    </Form.Row>
                </Form>
                <div className="pt-4">
                    <div style={{ width: '100%' }}>
                        <StickyTable stickyHeaderCount={0} leftStickyColumnCount={1} >
                            <Row>
                                <Cell style={{ borderColor: "lightgrey !important", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan #</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>EDIs</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Payment Link | VA</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Agent Name</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Created Date</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Amount</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Posting Date</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Status</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Channel</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Expected Date</b></Cell>
                                <Cell style={{ borderColor: "lightgrey !important", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Document</b></Cell>
                            </Row>
                            {collectionList &&
                                collectionList.map((collection, i) => (
                                    <Row key={collection._id}>
                                        <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>
                                            <a
                                                key={collection.loanId}
                                                href="#"
                                                onClick={() => this.openLoanDecision(collection.loanId)}
                                            >  {collection?.meta?.loanNo}</a>
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <OverlayTrigger placement="top" overlay={this.tooltip(collection._id, collection.emis)}>
                                                <div className="cursor-pointer" id={collection._id}> {collection.emis?.length || 0}</div>
                                            </OverlayTrigger>

                                        </Cell>
                                        <Cell style={{ textAlign: "", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                                            <OverlayTrigger placement="top" overlay={this.tooltip1(collection._id)}>
                                                <div className="cursor-pointer" onClick={() => this.copyContent(collection.paymentLink || collection.accountInfo?.accountNumber)}> {collection.paymentLink || collection?.accountInfo?.accountNumber}</div>
                                            </OverlayTrigger>
                                        </Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{collection?.agent?.name}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{collection.insertedOn && moment(collection.insertedOn).format("DD/MM/yyyy")}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{this.formatAmount(collection?.collectionDetail?.collectionAmount || 0)}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{collection?.postingDate ? moment(collection?.postingDate).format("DD/MM/yyyy") : "-"}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{collection.status}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{channelConst[collection?.meta?.channel] || ""}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{collection?.meta?.paymentDate ? moment(collection?.meta?.paymentDate).format("DD/MM/yyyy") : "-"}</Cell>
                                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{collection?.files?.length ? <Form.Label className="cursor-pointer" onClick={() => this.onClickFile(collection?.files)}>View</Form.Label> : "NA"}</Cell>
                                    </Row>
                                ))
                            }
                        </StickyTable>
                    </div>
                </div>
                <div style={{ width: "100%", overflowX: "scroll" }}>
                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPagePrevious={this.onPagePrevious}
                        onPageNext={this.onPageNext}
                    />
                </div>
                <Modal
                    show={this.state.showLoanDecisionModal}
                    onHide={this.closeModal}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    aria-labelledby="example-modal-sizes-title-xl"
                    centered
                    style={{ width: "auto !important" }}
                >

                    <Modal.Header closeButton>
                        <div class="col-md-10">
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 600, overflow: "scroll" }}>
                        <LoanInfoForm loanId={this.state.loanId} activeTab="loanTab" />
                    </Modal.Body>
                </Modal>
            </React.Fragment >
        );
    }
}

export default AgentReport;
