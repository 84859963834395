import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import CreateInvoiceModel from "../../models/invoice/createInvoiceModel";
import { Form, Col, Button, Modal, Table } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import NumberFormat from 'react-number-format';
import ReactExport from "react-export-excel";

class CreateInvoice extends CreateInvoiceModel {
    handleSelectALL = (e) => {
        var value = false;

        if (e.target.checked) {
            value = true;
        }

        this.setState({ selectAll: value, successMsg: "" });

        var checkboxes = document.querySelectorAll('input[name^="chkLoans"]');
        for (var checkbox of checkboxes) {

            checkbox.checked = value;
            const chkLoan = checkbox.name.split("_");
            const loanId = chkLoan[1];
            const selectedLoans = this.state.selectedLoans;
            if (!value && selectedLoans.includes(loanId, 0)) {
                const index = selectedLoans.indexOf(loanId);
                if (index != -1) {
                    selectedLoans.splice(index, 1);
                }
            } else if (value && !selectedLoans.includes(loanId, 0)) {
                selectedLoans.push(loanId);
            }
            this.setState({ selectedLoans });
        }
    }
    renderCheckBox = (loan) => {

        return (<Form.Check
            type="checkbox"
            name={"chkLoans_" + loan.loanNo}
            label=""
            onChange={this.handleChecked(loan.loanNo)}
            color="red"
        />)
    }
    renderExcelExport = () => {

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        let { data } = this.state;

        return (
            <ExcelFile hideElement={true} filename={`InvoiceList_${moment(new Date()).format("DDMMYYYY")}`}>
                <ExcelSheet data={data.loans} name="invoiceList">
                    <ExcelColumn
                        label="Loan_Number"
                        value="loanNo"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Disbursal_Date"
                        value={(col) => {
                            return col.disbursalDetails.disbursedOn && moment(col.disbursalDetails.disbursedOn).format("DD/MM/yyyy")
                        }}
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Loan_Status"
                        value="status"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Loan_Amount"
                        value="amount"
                    ></ExcelColumn>
                    <ExcelColumn
                        label="Partner"
                        value="partner"
                    ></ExcelColumn>
                </ExcelSheet>
            </ExcelFile>
        )

    }
    render() {
        const {
            invoiceTypeList,
            data,
            showSpinner,
            partners,
            evaluatorRole,
            successMsg,
            errors,
            selectAll,
            tableShow,
            downloadExcel,
            showExcel
        } = this.state;

        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>Create Invoice</h2>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} md="4" controlId="fromDate">
                            <Form.Label>From Date</Form.Label>
                            <DatePicker
                                name="fromDate"
                                id="fromDate"
                                value={data["fromDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleCustomFromDateChange(value)}
                                placeholder="Enter From Date"
                            />
                            {errors["fromDate"] && (
                                <div className="alert alert-danger">
                                    {errors["fromDate"]}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="toDate">
                            <Form.Label>To Date</Form.Label>
                            <DatePicker
                                name="toDate"
                                id="toDate"
                                value={data["toDate"]}
                                max={moment()}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleCustomToDateChange(value)}
                                placeholder="Enter To Date"
                            />
                            {errors["toDate"] && (
                                <div className="alert alert-danger">
                                    {errors["toDate"]}
                                </div>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="invoiceType">
                            <Form.Label>Invoice Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="invoiceType"
                                value={data["invoiceType"]}
                                onChange={this.handleInvoiceTypeChange}
                            >
                                {" "}
                                <option key="select" value="">
                                    ---Select---
                                </option>
                                {invoiceTypeList.map((item, i) => (
                                    <option key={i} value={item.invoiceType}>
                                        {item.invoiceText}
                                    </option>
                                ))}
                            </Form.Control>
                            {errors["invoiceType"] && (
                                <div className="alert alert-danger">
                                    {errors["invoiceType"]}
                                </div>
                            )}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        {evaluatorRole === "Admin" && (
                            <Form.Group as={Col} md="4" controlId="partnerId">
                                <Form.Label>Partner</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="partnerId"
                                    value={data["partnerId"]}
                                    onChange={this.handlePartnerChange}
                                >
                                    {" "}
                                    <option key="select" value="">
                                        --Select--
                                    </option>
                                    {partners && partners.map((partner) => (
                                        <option key={partner._id} value={partner._id}>
                                            {partner.displayName}
                                        </option>
                                    ))}
                                </Form.Control>
                                {errors["partnerId"] && (
                                    <div className="alert alert-danger">
                                        {errors["partnerId"]}
                                    </div>
                                )}
                            </Form.Group>)}
                        <Form.Group as={Col} md="4" controlId="uploadType" style={{ display: "none" }}>
                            <Form.Label>Upload Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="uploadType"
                                value={data["uploadType"]}
                                onChange={this.handlePartnerChange}
                            >
                                {" "}
                                <option key="select" value="">
                                    --Select--
                                </option>
                                <option key="selection" value="selection">
                                    Selection
                                </option>
                                <option key="excel" value="excel">
                                    Excel Upload
                                </option>
                            </Form.Control>
                            {errors["uploadType"] && (
                                <div className="alert alert-danger">
                                    {errors["uploadType"]}
                                </div>
                            )}
                        </Form.Group>
                    </Form.Row>
                    {showExcel && (<Form.Row>
                        <Form.Group as={Col} md="4" controlId="UploadFile">
                            <Form.Label>Upload Excel File</Form.Label>
                            <Form.File
                                label={data.fileName}
                                onChange={this.onExcelFileChange1}
                                id="uploadFile"
                                name="uploadFile"
                                accept=".xlsx"
                                key={data.fileName || ''}
                                custom
                            />{" "}
                            {errors["uploadFile"] && (
                                <div className="alert alert-danger">
                                    {errors["uploadFile"]}
                                </div>
                            )}
                        </Form.Group>
                        {(data.invoiceType === "DISBURSAL_CHARGES" || data.invoiceType === "COLLECTION_CHARGES") && (
                            <Form.Group as={Col} md="4" controlId="invoiceRefNo">
                                <Form.Label>Invoice Ref No.</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="invoiceRefNo"
                                    value={data["invoiceRefNo"]}
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    maxLength="20"
                                />
                                {errors["invoiceRefNo"] && (
                                    <div className="alert alert-danger">
                                        {errors["invoiceRefNo"]}
                                    </div>
                                )}
                            </Form.Group>
                        )}
                    </Form.Row>)}
                    <Form.Row>
                        <Form.Group as={Col} md="12">
                            {!showExcel && (
                                <Button
                                    className="ff-button"
                                    type="submit"
                                >
                                    Download Loan Data
                                </Button>
                            )}
                            {downloadExcel ? this.renderExcelExport() : ""}
                            {showExcel && (
                                <Button
                                    className="ff-button"
                                    type="button"
                                    onClick={() => this.handlePopupOpen()}
                                    disabled={this.state.selectedLoans.length > 0 || (showExcel && data.selectedFile) ? false : true}
                                >
                                    Create Invoice
                                </Button>
                            )}
                            {showExcel && (
                                <Button
                                    className="ff-button"
                                    type="button"
                                    onClick={() => this.clear()}
                                    style={{ marginLeft: 10 }}
                                >
                                    Clear
                                </Button>
                            )}
                        </Form.Group>
                    </Form.Row>
                </Form>
                <br />
                {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
                {successMsg && <div className="alert alert-success">{successMsg}</div>}
                <br />
                {tableShow && (
                    <div style={{ width: '100% !important' }}>
                        <Table>
                            <thead>
                                <tr>
                                    <td style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderTop: "1px solid" }}>
                                        <Form.Check
                                            type="checkbox"
                                            label=""
                                            onChange={this.handleSelectALL}
                                            color="red"
                                            checked={selectAll ? "checked" : ""}
                                        />
                                    </td>
                                    <td style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan No.</b></td>
                                    <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Disbursal Date</b></td>
                                    <td style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan Status</b></td>
                                    <td style={{ minWidCell: 100, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Loan Amount</b></td>
                                    <td style={{ minWidCell: 80, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Partner</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {data.loans &&
                                    data.loans.map((loan, i) => (
                                        <tr key={loan.loanId}>
                                            <td style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderLeft: "1px solid", borderBottom: i === data.loans.length - 1 ? "1px solid" : "" }}>
                                                {this.renderCheckBox(loan)}
                                            </td>
                                            <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: i === data.loans.length - 1 ? "1px solid" : "" }}>{loan.loanNo}</td>
                                            <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: i === data.loans.length - 1 ? "1px solid" : "" }}>{moment(loan.disbursalDetails.disbursedOn).format("DD/MM/YYYY")}</td>
                                            <td style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: i === data.loans.length - 1 ? "1px solid" : "" }}>{loan.status}</td>
                                            <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: i === data.loans.length - 1 ? "1px solid" : "" }}>
                                                <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                                            </td>
                                            <td style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid", borderBottom: i === data.loans.length - 1 ? "1px solid" : "" }}>
                                                {loan.partner}
                                            </td>
                                        </tr>
                                    ))}
                                {data.loans.length === 0 && (
                                    <tr>
                                        <td colSpan={10} style={{ border: "1px solid" }}>No record found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div >)}
                {/* <div style={{ width: "100%", overflowX: "scroll" }}>

                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={this.onPageChange}
                        onPagePrevious={this.onPagePrevious}
                        onPageNext={this.onPageNext}
                    />
                </div> */}
                <Modal show={this.state.showPopUp} onHide={this.handlePopupClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!showExcel && <span>Invoice will be created for selected {this.state.selectedLoans.length} loans.</span>}
                        {showExcel && <span>Invoice will be created for loans in excel sheet.</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="ff-button" onClick={this.handlePopupOk}>
                            Yes
                        </Button>
                        <Button variant="secondary" className="ff-button" onClick={this.handlePopupClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >

        );
    }
}

export default CreateInvoice;
