import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { creditApiUrl } from "../../config.json";
import moment from "moment";
import { mode } from "crypto-js";

const apiEndpoint = apiUrl + "/ops/admin";
const creditApiEndpoint = creditApiUrl + "admin";

export async function getLoanList(model) {
    const { data } = await http.post(`${apiEndpoint}/getLoanListForForeclosure`, {
        status: model.loanStatus,
        fromDate: moment(model.fromDate).format("DD/MM/yyyy"),
        toDate: moment(model.toDate).format("DD/MM/yyyy"),
        mobileNo: model.mobileNo,
        name: model.name,
        loanNo: model.loanNo,
        merchantCode: model.merchantCode,
        panNo: model.panNo,
        partner: model.partner,
        postDisbursalStatus: model.postDisbursalStatus,
        page: model.page
    });
    return data;
}

export async function getForeclosureDetails(loanNo) {
    const { data } = await http.post(`${apiEndpoint}/getForeclosureDetails`, { loanNo: loanNo });
    return data;
}

export async function initiateForeclosure(model) {
    const { data } = await http.post(`${apiEndpoint}/initiateForeclosure`, {
        loanNo: model.loanNo,
        isWaiveOff: model.isWaiveOff,
        foreclosureReason: model.foreclosureReason
    });

    return data;
}