import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import EarlyWarningSystemModel from "../../models/earlyWarningSystem/earlyWarningSystemModel";
import { Form, Col, Button, Table, Modal } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import NumberFormat from 'react-number-format';
import EmiList from "../common/emiList";

class EarlyWarningSystemTable extends EarlyWarningSystemModel {
  render() {
    const {
      data,
      showSpinner,
      showRepaymentModal,
      loanNo,
      loanContractDetails,
      loanId
    } = this.state;

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>Early Warning System</h2>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="loanNo">
              <Form.Label>Loan No</Form.Label>
              <Form.Control
                type="text"
                name="loanNo"
                value={data["loanNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />{" "}
            </Form.Group>{" "}
            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={data["name"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No</Form.Label>
              <Form.Control
                type="text"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Button className="ff-button" type="submit">
                Apply Filters
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
        <Table
          responsive
          striped
          bordered
          hover
          cellPadding="5"
          cellSpacing="5"
          style={{ width: 100, fontWeight: "bold" }}
        >
          <thead>
            <tr>
              <th>Loan No</th>
              <th style={{ minWidth: 100 }}>Amount</th>
              <th style={{ minWidth: 250 }}>Name</th>
              <th style={{ minWidth: 100 }}>Emi</th>
              <th style={{ minWidth: 100 }}>Amount As On Date</th>
              <th style={{ minWidth: 100 }}>Principal Recovered</th>
              <th style={{ minWidth: 100 }}>Interest Recovered</th>
              <th style={{ minWidth: 100 }}>Amount In Line</th>
              <th>Loan Type</th>
              <th>Enterprise</th>
              <th>Lender</th>
              <th>Status</th>
              <th>Status In Line</th>
              <th style={{ minWidth: 150 }}>Emi Overdue</th>
              <th>Pending Emi</th>
              <th>Missed Emi</th>
            </tr>
          </thead>
          <tbody>
            {data.loans &&
              data.loans.map((loan) => (
                <tr
                  key={loan.loanId}
                  style={{
                    backgroundColor: loan.color,
                    color: loan.color !== "orange" ? "white" : "black",
                  }}
                >
                  <td>
                    {
                      <a
                        key={loan.loanId}
                        href="#"
                        className="linkEWS"
                        onClick={() => this.getRepaymentDetails(loan.loanId)}
                        style={{
                          color:
                            loan.color !== "orange"
                              ? "white !important"
                              : "black !important",
                        }}
                      >
                        {loan.loanNo}
                      </a>
                    }
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ minWidth: 100 }}>{loan.name}</td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.emi} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.amountAsOnDate} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.principleRecovered} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.interestAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <NumberFormat value={loan.amountInLine} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </td>
                  <td>{loan.loanType}</td>
                  <td>{loan.enterprise}</td>
                  <td>{loan.lender}</td>
                  <td>{loan.status}</td>
                  <td>{loan.statusInLine}</td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {loan.emiOverDue && (
                      <i className="fa fa-inr" aria-hidden="true"></i>
                    )}
                    {parseFloat(loan.emiOverDue).toFixed(2)}
                  </td>
                  <td> <NumberFormat value={loan.pendingEmis} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" /></td>
                  <td> <NumberFormat value={loan.missedEmis} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" /></td>
                </tr>
              ))}
            {!data.loans && (
              <tr key="no">
                <td colSpan="16">No record found</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Modal
          show={showRepaymentModal}
          onHide={this.closeModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="example-modal-sizes-title-xl"
          centered
          style={{ width: "auto !important" }}
        >
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflow: "scroll" }}>
            <EmiList loanId={loanId}></EmiList>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default EarlyWarningSystemTable;
