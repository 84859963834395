import InputDataForm from "../../components/common/form";
import * as lenderPayoutService from "../../services/LenderPayout/LenderPayout";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";
import moment from "moment";
import { toast } from "react-toastify";

class LenderPayoutListModal extends InputDataForm {
  state = {
    data: {
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      partnerId: ""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    partners: [],
    lendingPartners: [],
    evaluatorRole: "",
    tabs: [
      { title: "Split Pending", value: "ORDER_SPLIT_PENDING" },
      { title: "Split Completed", value: "ORDER_SPLIT_COMPLETED" },
      { title: "Split Failed", value: "ORDER_SPLIT_FAILED" },
      { title: "Disbursal Processing", value: "DISBURSAL_PROCESSING" },
      { title: "Disbursal Completed", value: "DISBURSAL_COMPLETED" },
      { title: "Disbursal Failed", value: "DISBURSAL_FAILED" }
    ],
    activeTab: "ORDER_SPLIT_PENDING",
    payoutList: [],
    selectedHash: {},
    isSelectedAll: false,
    statusMapper: {
      PAYOUT_TRANSACTION_PROCESSING: "Payout Transaction Processing",
      PAYOUT_TRANSACTION_COMPLETED: "Payout Transaction Completed",
      ORDER_SPLIT_PROCESSING: "Order Split Processing",
      ORDER_SPLIT_COMPLETED: "Order Split Completed",
      FAILED: "Failed",
      PAYMENT_COMPLETED: "Payment Completed",
      PROCESSING: "Processing",
      COMPLETED: "Completed",
      PAYMENT_CONFIRMATION: "Payment Confirmation"
    },
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    lendingPartner: Joi.any(),
    borrowingPartner: Joi.any(),
    page: Joi.any(),
    tab: Joi.any(),
    partnerId: Joi.any(),
    payoutList: Joi.any(),
    borrowingPartnerId: Joi.any()
  };


  payoutReDirsburse = async (data) => {
    try {
      let ids = Object.keys(this.state.selectedHash)
      if (!ids.length)
        return

      let payload = {
        ids: ids
      }
      this.setState({ showSpinner: true });
      const payoutInitRes = await lenderPayoutService.lenderPayoutReDisburseV2(payload);
      this.setState({ showSpinner: false });
      this.getLenderPayoutList()
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  payoutInit = async (ids) => {

    try {

      let payload = {
        ids
      }
      this.setState({ showSpinner: true });
      const payoutInitRes = await lenderPayoutService.lenderPayoutInitV2(payload);
      this.setState({ showSpinner: false });
      this.getLenderPayoutList()
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }
  async getLenderPayoutList() {
    this.state.data.page = this.state.currentPage
    const data = { ...this.state.data }

    this.setState({ showSpinner: true });
    try {
      data.tab = this.state.activeTab;
      if (data.fromDate)
        data.fromDate = moment(data.fromDate).format("MM/DD/YYYY");

      if (data.toDate)
        data.toDate = moment(data.toDate).format("MM/DD/YYYY");

      if (this.state.activeTab)
        data.status = this.state.activeTab

      if ((this.state.activeTab == "ORDER_SPLIT_PENDING" || this.state.activeTab == "ORDER_SPLIT_COMPLETED" || this.state.activeTab == "ORDER_SPLIT_FAILED") && data.borrowingPartnerId) {
        data.partnerId = data.borrowingPartnerId
      }

      const { data: transactionsList, totalPages } = await lenderPayoutService.getLenderPayoutListV2(data);
      this.setState({ payoutList: transactionsList || [], showSpinner: false, pageSize: totalPages }, () => {
        if (this.isSelectedAll())
          this.setState({ isSelectedAll: true })
        else
          this.setState({ isSelectedAll: false })

        // this.selectAll(true)
      });
    } catch (ex) {
      this.setState({ showSpinner: false, payoutList: [] });
    }
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });
    await this.getLendingPartners();
    await this.getBorrowingPartner();
    this.getLenderPayoutList()
  };

  handlePartnerChange = ({ currentTarget: input }) => {

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  async getBorrowingPartner() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ borrowingPartners: partnerList });
    }
    catch (ex) {
      this.setState({ borrowingPartners: [] });
    }
  };

  async getLendingPartners() {
    try {
      const { data: partnerList } = await commonService.getLendingParterList();
      this.setState({ lendingPartners: partnerList });
    }
    catch (ex) {
      this.setState({ lendingPartners: [] });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    await this.getLenderPayoutList();
  };

  onChangeTab = (tab) => {
    const data = { ...this.state.data };
    data['tab'] = tab
    this.setState({ data, activeTab: tab, selectedHash: {}, isSelectedAll: false }, () => {
      this.resetPage(() => {
        this.getLenderPayoutList()
      })
    })
  }

  onChangeHeaderActiveTab = (tab) => {
    const data = { ...this.state.data };
    data['headerActiveTab'] = tab
    this.setState({ data, headerActiveTab: tab }, () => {

      if (tab == "Applications") {
        this.resetPage(() => {
          this.state.activeTab = "All"
          this.state.data.tab = "All"
          this.getLenderPayoutList()
        })
      }
    })
  }

  onPageNext = () => {

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getLenderPayoutList()
    })
  }

  onPageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      this.getLenderPayoutList()
    })
  }

  onPagePrevious = () => {

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getLenderPayoutList()
    })
  }

  resetPage = (callback) => {
    this.state.data.page = 1
    this.setState({ currentPage: 1, pageSize: 1 }, () => {
      callback && callback()
    })
  }

  showPayoutDetails = (data) => {
    this.setState({ showPayoutDetails: true, selectedObj: data })
  }

  hidePayoutDetails = () => {
    this.setState({ showPayoutDetails: false, selectedObj: null })
  }

  selectAll = (value) => {
    let payoutList = this.state.payoutList;
    let selectedHash = this.state.selectedHash;
    if (!value) {
      payoutList.map(item => {
        delete selectedHash[item._id]
      })
    } else {
      payoutList.map(item => {
        selectedHash[item._id] = item
      })
    }

    this.setState({ selectedHash, isSelectedAll: value })
  }

  isSelectedAll = () => {
    let payoutList = this.state.payoutList;
    let selectedHash = this.state.selectedHash;

    let isSelectedAll = true;
    for (let i = 0; i < payoutList.length; i++) {
      if (!selectedHash[payoutList[i]._id]) {
        isSelectedAll = false;
        break;
      }
    }

    if (!Object.keys(selectedHash).length)
      return false;

    return isSelectedAll;
  }

  onChangeCheckBox = (value, transaction) => {
    let selectedHash = this.state.selectedHash;
    if (value)
      selectedHash[transaction._id] = transaction;
    else
      delete selectedHash[transaction._id]


    this.setState({ selectedHash })

  }

  onClickInitiate = async () => {

    let ids = Object.keys(this.state.selectedHash)
    if (!ids.length)
      return

    await this.payoutInit(ids)
  }

}

export default LenderPayoutListModal;
