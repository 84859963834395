import InputDataForm from "../../components/common/form";
import * as contractService from "../../services/contract/contractService";
import Joi, { errors } from "joi-browser";
import auth from "../../services/common/authService";
import _ from "lodash";
import * as analytics from "../../utils/analytics";
import * as commonService from "../../services/common/commonService";

class VerifyLoanContractModel extends InputDataForm {
  state = {
    data: {
      loanId: "",
      otp: "",
    },
    files: {
      photo: "",
      photoStream: "",
      photoLabel: "",
      shopInternalPhoto: "",
      shopInternalPhotoStream: "",
      shopInternalPhotoLabel: "",
      shopExternalPhoto: "",
      shopExternalPhotoStream: "",
      shopExternalPhotoLabel: "",
      shopVideo: "",
      shopVideoStream: "",
      shopVideoLabel: "",
    },
    fileErrors: {},
    staticData: {
      loanId: "",
      loanNo: "",
      amount: "",
      roi: "",
      tenure: "",
      gstCharges: "",
      stampingCharges: "",
      disbursalAmount: "",
      discountAmount: "",
      emiAmount: "",
      processingCharges: "",
      isAccepted: false,
      contractFile: "",
      lendingPartner: "",
      isSigned: false,
      p_type: "",
      emiCount: "",
      totalInterestPayable: "",
      collectionType: "",
      disbursalType: "",
      borrowingPartner: "",
    },
    errors: {},
    info: {},
    numPages: null,
    pageNumber: 1,
    showSpinner: false,
    isRegenerateLink: false,
    isRegenerateText: false,
    counter: 0,
    timerId: 0,
    timer: "",
    showTnc: false,
    isSubmitted: false,
    displayContract: true,
    validForSigning: true,
    errorMessage: "",
    docConsent: true,
    showDocConsent: false,
    otpAutoFill: false,
    showKfs: false,
    disbursalConsent: true,
    isUserPhotoRequired: true,
    isShopInternalPhotoRequired: true,
    isShopVideoRequired: true,
    isShopExternalPhotoRequired: true,
    // videoVerificationUrl: "https://video-preproduction.signzy.tech/customer/6126212453f5188cc08d073a/token/n3PvmXjqJK4F4kUVHhDc",
    // showIframe: true
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  schema = {
    loanId: Joi.string().required(),
    otp: Joi.number().required(),
  };
  async mapDataToState(loan) {
    let staticData = { ...this.state.staticData };
    let data = { ...this.state.data };

    let displayContract = true;

    //User Details
    if (loan.id) staticData.loanId = loan.id;
    if (loan.id) data.loanId = loan.id;
    if (loan.loanNo) staticData.loanNo = loan.loanNo;
    if (loan.amount) staticData.amount = loan.amount;
    if (loan.roi) staticData.roi = loan.roi;
    if (loan.tenure) staticData.tenure = loan.tenure;

    if (loan.stampingCharges) staticData.stampingCharges = loan.stampingCharges;
    if (loan.disbursalAmount) staticData.disbursalAmount = loan.disbursalAmount;
    if (loan.discountAmount) staticData.discountAmount = loan.discountAmount;
    if (loan.emiAmount) staticData.emiAmount = loan.emiAmount;
    if (loan.partnerName) staticData.lendingPartner = loan.partnerName;
    if (loan.isSigned) staticData.isSigned = loan.isSigned;

    if (loan.gstCharges) {
      staticData.gstCharges = loan.gstCharges;
    } else {
      staticData.gstCharges = "0";
    }

    displayContract = loan.displayContract;

    if (loan.p_type) staticData.p_type = loan.p_type;

    if (loan.emiCount) staticData.emiCount = loan.emiCount;

    if (loan.totalInterestPayable)
      staticData.totalInterestPayable = loan.totalInterestPayable;

    if (loan.collectionType) staticData.collectionType = loan.collectionType;

    if (loan.disbursalType) staticData.disbursalType = loan.disbursalType;

    if (loan.borrowingPartner)
      staticData.borrowingPartner = loan.borrowingPartner;
    if (loan.photoMatch) staticData.photoMatch = loan.photoMatch;

    if (loan.processingCharges)
      staticData.processingCharges = loan.processingCharges;
    if (loan.contractDetails) {
      if (loan.contractDetails.isAccepted)
        staticData.isAccepted = loan.contractDetails.isAccepted;
      if (loan.contractDetails.contractFile)
        staticData.contractFile = loan.contractDetails.contractFile;
    }

    let isValidForSigning = true;
    let errorMessage = "";

    if (loan.contractDetails && loan.contractDetails.isAccepted)
      isValidForSigning = true;
    else if (
      loan.eNachRequired &&
      !loan.eNachSigned &&
      loan.isMandateRequired
    ) {
      if (!loan.mandateBankDetailsMatch) {
        isValidForSigning = false;
        errorMessage =
          "Current Mandate is not valid as bank details have been changed.";
      } else {
        isValidForSigning = false;
        errorMessage = "Please complete e-Nach process before contract signing";
      }
    } else if (loan.isStp) isValidForSigning = true;
    else if (
      !loan.isStp &&
      (loan.status.toLowerCase() === "draft" ||
        loan.status.toLowerCase() === "review")
    )
      isValidForSigning = true;
    else if (
      !loan.isStp &&
      (loan.status.toLowerCase() === "under review" ||
        loan.status.toLowerCase() === "loan contract accepted" ||
        loan.status.toLowerCase() === "recourse pending") &&
      loan.kycStatus.toLowerCase() === "review"
    )
      isValidForSigning = true;
    else if (
      loan.status.toLowerCase() !== "loan is funding" &&
      loan.status.toLowerCase() !== "loan contract accepted" &&
      loan.status.toLowerCase() !== "onboarding completed" &&
      loan.status.toLowerCase() !== "recourse pending"
    ) {
      isValidForSigning = false;
      errorMessage = "Loan application not valid for contract signing";
    }
    let isVideoVerificationCompleted = true;
    let enableResultCheck = false;
    //Removing video verification 
    // let isVideoVerificationCompleted = false;

    // if (loan.photoMatch) {
    //   isVideoVerificationCompleted = true;
    // }

    // let enableResultCheck = false;

    // if (!loan.photoMatch) {
    //   let search = window.location.search;
    //   let params = new URLSearchParams(search);
    //   let resultCheck = params.get("rc");
    //   enableResultCheck = resultCheck == "true";
    // }

    // if (enableResultCheck) {
    //   this.checkResultVideoVerification();
    // }

    let isUserPhotoRequired = true;
    let isShopInternalPhotoRequired = true;
    let isShopExternalPhotoRequired = true;

    if (loan.contractDetails && loan.contractDetails.photoFilePath)
      isUserPhotoRequired = false;

    if (loan.contractDetails && loan.contractDetails.shopDocuments) {
      if (
        loan.contractDetails.shopDocuments[0] &&
        loan.contractDetails.shopDocuments[0].shopInternalPhotoFilePath
      )
        isShopInternalPhotoRequired = false;

      if (
        loan.contractDetails.shopDocuments[0] &&
        loan.contractDetails.shopDocuments[0].shopExternalPhotoFilePath
      )
        isShopExternalPhotoRequired = false;

      // if (
      //   loan.contractDetails.shopDocuments[0] &&
      //   loan.contractDetails.shopDocuments[0].shopVideoFilePath
      // )
      //   isShopVideoRequired = false;
    }

    this.setState({
      staticData,
      data,
      displayContract,
      isValidForSigning: isValidForSigning,
      errorMessage: errorMessage,
      disbursalConsent:
        loan.disbursalType === "Partner" || loan.disbursalType === "Virtual"
          ? true
          : false,
      isVideoVerificationCompleted,
      enableResultCheck,
      isUserPhotoRequired,
      isShopInternalPhotoRequired,
      isShopExternalPhotoRequired,
      //isShopVideoRequired,
    });

    if (
      (!loan.eNachRequired ||
        (loan.eNachRequired && loan.eNachSigned) ||
        !loan.isMandateRequired) &&
      !loan.isSigned &&
      isValidForSigning
    ) {
      this.checkMediaAccess();
      !enableResultCheck &&
        isVideoVerificationCompleted &&
        (await this.sendOtp());
    }

    if (loan.isSigned && loan.contractSuccessCallBackUrl)
      setTimeout(this.redirectUser(loan.contractSuccessCallBackUrl), 5000);
  }

  redirectIframeToTab() {
    if (window.location !== window.parent.location) {
      window.parent.open(window.location.href, "_self");
    }
  }

  isMobile() {
    let standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (!standalone && safari) {
        return false;
      } else if (!standalone && !safari) {
        return true;
      }
    } else {
      if (userAgent.includes("wv")) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkMediaAccess() {
    let isMobile = this.isMobile();
    if (isMobile) {
      this.setState({ isVideoAndAudioAccess: true });
    } else {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        let videoAccess = false,
          audioAccess = true;
        devices.forEach((device) => {
          if (device.kind == "audioinput" && device.label) audioAccess = true;
          if (device.kind == "videoinput" && device.label) videoAccess = true;
        });

        let isVideoAndAudioAccess = videoAccess && audioAccess;
        if (!isVideoAndAudioAccess) {
          this.askPermission(this);
        } else {
          this.setState({ isVideoAndAudioAccess });
        }
      });
    }
  }

  askPermission(currentThis) {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        currentThis.setState({ isVideoAndAudioAccess: true });
        console.log("Allow Access");
      })
      .catch(function (err) {
        console.log("Block ACCESS");
      });
  }

  checkResultVideoVerification() {
    this.resultInterval = null;
    this.dotInterval = null;
    this.totalApiAttempt = 0;
    this.setState(
      { resultCheckInProgress: true, showSpinner: true, dots: "." },
      () => {
        this.resultInterval = setInterval(() => {
          if (this.state.resultCheckInProgress) this.checkVerificationResult();
          console.log("CHECK ", this.totalApiAttempt);
        }, 5000);

        this.dotInterval = setInterval(() => {
          let dots = this.state.dots;

          if (dots.length >= 4) dots = ".";
          else dots = `${dots}.`;
          this.setState({ dots });
          console.log("CHECK ", dots);
        }, 500);
      }
    );
  }

  sendOtp = async () => {
    const { data, errors, info } = this.state;

    this.setState({ showSpinner: true });

    try {
      if (data.loanId !== "") {
        const { data: response } = await contractService.getOTPForContract(
          data.loanId
        );

        if (response.success) {
          delete errors["otp"];

          this.setState({
            data,
            info,
            errors,
            showSpinner: false,
          });
          this.startTimer();

          // //OTP Auto-Fill
          // if ("OTPCredential" in window) {
          //   const ac = new AbortController();

          //   navigator.credentials
          //     .get({
          //       otp: { transport: ["sms"] },
          //       signal: ac.signal,
          //     })
          //     .then((otp) => {
          //       let { data } = this.state;
          //       data.otp = otp.code;
          //       this.setState({ data, otpAutoFill: true });
          //       ac.abort();
          //     })
          //     .catch((err) => {
          //       ac.abort();
          //       console.log(err);
          //     });
          // }
        } else {
          errors["otp"] = response.message;
          this.setState({
            errors,
            showSpinner: false,
            isValidUserName: false,
          });
        }
      }
    } catch (ex) {
      console.log("ex", ex);
      errors["otp"] = ex.response.message;
      delete info["otp"];
      this.setState({
        errors,
        info,
        showSpinner: false,
      });
    }
  };
  async handleCustomErrors() {
    const {
      data,
      errors,
      docConsent,
      files,
      fileErrors,
      staticData,
      disbursalConsent,
    } = this.state;

    if (!errors["otp"]) {
      if (data.otp === "") errors["otp"] = "Please enter OTP";
      else if (data.otp !== "" && (data.otp.length !== 6 || isNaN(data.otp)))
        errors["otp"] = "Invalid OTP";
      else delete errors["otp"];
    }

    if (!docConsent) errors["docConsent"] = "Please provide document consent";
    else delete errors["docConsent"];

    // if (this.state.isUserPhotoRequired && !files.photo)
    //   fileErrors["photo"] = "Please upload photo";
    // else delete fileErrors["photo"];

    // if (this.state.isShopInternalPhotoRequired && !files.shopInternalPhoto)
    //   fileErrors["shopInternalPhoto"] = "Please upload internal shop photo";
    // else delete fileErrors["shopInternalPhoto"];

    // if (this.state.isShopExternalPhotoRequired && !files.shopExternalPhoto)
    //   fileErrors["shopExternalPhoto"] = "Please upload external shop photo";
    // else delete fileErrors["shopExternalPhoto"];

    // if (this.state.isShopVideoRequired && !files.shopVideo)
    //   fileErrors["shopVideo"] = "Please upload shop video";
    // else delete fileErrors["shopVideo"];

    if (
      (staticData.disbursalType === "Partner" ||
        staticData.disbursalType === "Virtual") &&
      !disbursalConsent
    )
      errors["disbursalConsent"] = "Please provide disbursal consent";
    else delete errors["disbursalConsent"];

    delete errors["globalError"];

    this.setState({ errors, fileErrors });

    return { errors, fileErrors };
  }
  async componentDidMount() {
    this.redirectIframeToTab();
    analytics.track();

    const loanId = this.props.match.params.id;

    if (!loanId) {
      return (window.location = "/");
    } else {
      await this.getLoanData(loanId);
    }
    this.getGeoAddress();
  }

  getGeoAddress() {
    this.getGeoLocation((position) => {
      if (position?.coords) {
        let { latitude, longitude } = position.coords;
        this.coordinates = { latitude, longitude };
      }
    });
  }

  async getLoanData(loanId) {
    try {
      if (loanId !== "") {
        this.setState({ showSpinner: true });
        const { data: loanDetails } = await contractService.getLoanData(loanId);
        this.setState({ showSpinner: false });

        if (loanDetails.success) {
          await this.mapDataToState(loanDetails.data);
        }
      } else {
        this.setState({ showSpinner: false });
      }

      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }
  async eSignLoanContract() {
    let staticData = { ...this.state.staticData };
    let { data, otpAutoFill, disbursalConsent } = this.state;
    let errors = { ...this.state.errors };
    let files = { ...this.state.files };

    this.setState({ showSpinner: true, isSubmitted: true });

    try {
      const ipAddress = await commonService.getIpAddress();

      const { data: loanDetails } = await contractService.signContract(
        staticData.loanId,
        data.otp,
        files,
        ipAddress,
        otpAutoFill,
        disbursalConsent,
        this.coordinates
      );

      if (loanDetails.success) {
        await this.getLoanData(staticData.loanId);
      } else {
        errors["globalError"] = loanDetails.message;
      }

      this.setState({ showSpinner: false, errors, isSubmitted: false });
    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, isSubmitted: false });
    }
  }
  handleViewDocConsent = () => {
    this.setState({ showDocConsent: true });
  };
  handleDocConsent = async (docConsent) => {
    const errors = { ...this.state.errors };

    if (!docConsent) {
      delete errors["docConsent"];
    }

    this.setState({ docConsent: !docConsent, errors });
  };
  handleDocConsentClose = () => {
    this.setState({ showDocConsent: false });
  };
  onPhotoUploadChange = async (
    { currentTarget: input },
    isOnlineUpload,
    type
  ) => {
    const files = { ...this.state.files };
    const fileErrors = { ...this.state.fileErrors };

    if (input.files.length === 0) {
      files[input.name] = "";
      files[`${input.name}Stream`] = "";
      files[`${input.name}Label`] = "";
      this.setState({ files });
      return;
    }
    if (
      input.files[0].type !== "image/png" &&
      input.files[0].type !== "image/x-png" &&
      input.files[0].type !== "image/jpeg" &&
      input.files[0].type !== "image/pjpeg"
    ) {
      fileErrors[input.name] = "Only .jpg .png allowed";
      this.setState({ fileErrors });
      return;
    } else {
      delete fileErrors[input.name];
      this.setState({ fileErrors });
    }

    if (isOnlineUpload) {
      this.setState({ showSpinner: true });
      let filePath = await this.getSignedURL(input.files[0], type);
      this.setState({ showSpinner: false });

      files[input.name] = input.files[0].name;
      files[`${input.name}Stream`] = filePath;
      if (input.files[0].name.length > 20) {
        const name = `${input.files[0].name.substring(
          0,
          20
        )}${this.getExtension(input.files[0].name)}`;
        files[`${input.name}Label`] = name;
      } else files[`${input.name}Label`] = input.files[0].name;

      this.setState({ files });

      await this.getBase64(input.files[0], (result) => {
        files[`${input.name}Src`] = result;
        this.setState({ files });
      });
    } else {
      await this.getBase64(input.files[0], (result) => {
        files[input.name] = input.files[0].name;
        files[`${input.name}Stream`] = result;
        if (input.files[0].name.length > 20) {
          files[`${input.name}Label`] = `${input.files[0].name.substring(
            0,
            20
          )}${this.getExtension(input.files[0].name)}`;
        } else files[`${input.name}Label`] = input.files[0].name;
        this.setState({ files });
      });
    }
  };

  onVideoUploadChange = async (
    { currentTarget: input },
    isOnlineUpload,
    type
  ) => {
    const files = { ...this.state.files };
    const fileErrors = { ...this.state.fileErrors };

    if (input.files.length === 0) {
      files[input.name] = "";
      files[`${input.name}Stream`] = "";
      files[`${input.name}Label`] = "";
      this.setState({ files });
      return;
    }
    if (!input.files[0].type.includes("video")) {
      fileErrors[input.name] = "Please upload video file";
      this.setState({ fileErrors });
      return;
    } else {
      delete fileErrors[input.name];
      this.setState({ fileErrors });
    }

    let _size = input.files[0].size;

    _size = (_size / (1024 * 1024)).toFixed(2);

    alert(_size);

    if (_size > 5) {
      fileErrors[input.name] = "File size must be less than 5MB";
      this.setState({ fileErrors });
      return;
    } else {
      delete fileErrors[input.name];
      this.setState({ fileErrors });
    }

    if (isOnlineUpload) {
      this.setState({ showSpinner: true });
      let filePath = await this.getSignedURL(input.files[0], type);
      this.setState({ showSpinner: false });

      files[input.name] = input.files[0].name;
      files[`${input.name}Stream`] = filePath;
      if (input.files[0].name.length > 20) {
        const name = `${input.files[0].name.substring(
          0,
          20
        )}${this.getExtension(input.files[0].name)}`;
        files[`${input.name}Label`] = name;
      } else files[`${input.name}Label`] = input.files[0].name;

      this.setState({ files });

      await this.getBase64(input.files[0], (result) => {
        files[`${input.name}Src`] = result;
        this.setState({ files });
      });
    } else {
      await this.getBase64(input.files[0], (result) => {
        files[input.name] = input.files[0].name;
        files[`${input.name}Stream`] = result;
        if (input.files[0].name.length > 20) {
          files[`${input.name}Label`] = `${input.files[0].name.substring(
            0,
            20
          )}${this.getExtension(input.files[0].name)}`;
        } else files[`${input.name}Label`] = input.files[0].name;
        this.setState({ files });
      });
    }
  };

  uploadFile = (signedUrl, file, callback) => {
    commonService
      .uploadFileAwsS3(signedUrl, file)
      .then((response) => {
        let splittedSignedUrl = signedUrl.split("?");
        callback && callback(null, splittedSignedUrl[0]);
      })
      .catch((error) => {
        callback && callback(error, null);
        console.log(error);
      });
  };

  getSignedURL = async (file, type) => {
    let payload = {
      fileName: file.name,
      type,
    };

    this.setState({ uploadLoading: true });
    let response = await commonService.getSignedUrl(payload);

    if (response.data.data) {
      response = response.data.data;

      let singedUrlBuild = this.getSingedUrlBuild(response, file);

      let data = await this.uploadFileV2(response["url"], singedUrlBuild);

      if (data) {
        return this.getImageUrlFromSingedUrlResponse(response);
      }
      return data;
    }

    // return new Promise((resolve, reject) => {
    //   this.uploadFile(response?.data?.data?.signedUrl, file, (err, data) => {
    //     if (err) resolve("")
    //     else resolve(data)
    //   })
    // })
  };
  getExtension = function (path) {
    const lastIndex = path.lastIndexOf("?");
    path = lastIndex == -1 ? path : path.substring(0, lastIndex);
    const firstIndex = path.lastIndexOf(".");
    const extension = path.substring(firstIndex);
    return extension;
  };
  doSubmit = async (e) => {
    e.preventDefault();
    let { errors, fileErrors } = await this.handleCustomErrors();

    if (_.isEmpty(errors) && _.isEmpty(fileErrors))
      await this.eSignLoanContract();
  };
  redirectUser = function (url) {
    if (url) window.location = url;
  };
  handleViewKfs = () => {
    this.setState({ showKfs: true });
  };
  handleCloseKfs = () => {
    this.setState({ showKfs: false });
  };
  handleDisbursalConsent = async (disbursalConsent) => {
    const errors = { ...this.state.errors };

    if (!disbursalConsent) {
      delete errors["disbursalConsent"];
    }

    this.setState({ disbursalConsent: !disbursalConsent, errors });
  };

  startVideoVerification = async () => {
    this.setState({ showSpinner: true });

    const { data } = await contractService.getVideoVerification(
      this.state.staticData.loanId
    );

    if (data?.success && data?.message == "success") {
      this.setState({ showSpinner: false });

      let url = data?.data?.url;
      // if (url) window.open(url, "_self")
      this.setState({ videoVerificationUrl: url, showIframe: true });
    } else if (data?.success) {
      let errors = this.state.errors;
      errors.globalError = data?.message;
      this.setState({ errors, showSpinner: false });
    }
  };

  checkVerificationResult = async () => {
    this.setState({ showSpinner: true });
    let loanId = this.state.staticData.loanId;

    this.totalApiAttempt = (this.totalApiAttempt || 0) + 1;

    const { data } = await contractService.checkVerificationResult(loanId);

    if (data?.success && data?.message == "success") {
      this.getLoanData(loanId);
      this.setState({
        showSpinner: false,
        resultCheckInProgress: false,
        errors: {},
      });
      clearInterval(this.resultInterval);
      clearInterval(this.dotInterval);
    } else if (data?.success) {
      if (this.totalApiAttempt >= 5) {
        let errors = this.state.errors;
        errors.globalError = data?.message;
        this.setState({
          errors,
          showSpinner: false,
          resultCheckInProgress: false,
        });
        clearInterval(this.resultInterval);
        clearInterval(this.dotInterval);
      }
    }
  };
}
export default VerifyLoanContractModel;
