import React, { Component } from "react";
import { Form, Col, Nav, Row, Button, Modal, Image } from "react-bootstrap";
import SpinnerComponent from "./../../components/common/spinner";
import VerifyLoanContractModel from "./../../models/contract/verifyLoanContractModel";
import NumberFormat from "react-number-format";
import Kfs from "../common/kfs";

class VerifyLoanContract extends VerifyLoanContractModel {
  tutorialRef = React.createRef();
  render() {
    const {
      showSpinner,
      data,
      staticData,
      isRegenerateLink,
      isRegenerateText,
      errors,
      info,
      counter,
      isSubmitted,
      errorMessage,
      isValidForSigning,
      showDocConsent,
      docConsent,
      files,
      fileErrors,
      showKfs,
      disbursalConsent,
      isVideoVerificationCompleted,
      enableResultCheck,
      showIframe,
      videoVerificationUrl,
      isVideoAndAudioAccess,
    } = this.state;

    if (showIframe && videoVerificationUrl) {
      return (
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2 text-left">
            <ol>
              <li>
                To complete Video Verification please enable access to camera{" "}
                <i className="fa fa-camera" /> and microphone{" "}
                <i className="fa fa-microphone" /> to app/browser. You can
                provide the access by going to settings{" "}
                <i className="fa fa-cog" /> on your phone.
                <label
                  style={{ textDecoration: "underline" }}
                  className="vv-video-tutorial-mobile "
                  onClick={() => this.handleScroll(this.tutorialRef)}
                >
                  Click here to know how to do video verification?
                </label>
              </li>
            </ol>

            <div className="py-4 vv-video-tutorial-web">
              <div>Video tutorial :</div>
              <div className="pt-2">
                <iframe
                  width="300"
                  height="300"
                  src="https://www.youtube.com/embed/OIBFNKXRuy4"
                  title="Video Verification"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8">
            <div className="iframe-wrapper">
              <iframe
                src={videoVerificationUrl}
                height="100%"
                width="100%"
                allow="camera; microphone;"
              />
            </div>
            <div
              className="py-4 vv-video-tutorial-mobile"
              ref={this.tutorialRef}
            >
              <div>Video tutorial :</div>
              <div className="pt-2">
                <iframe
                  width="300"
                  height="300"
                  src="https://www.youtube.com/embed/OIBFNKXRuy4"
                  title="Video Verification"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const docConsentLabel = (
      <React.Fragment>
        I have read and agree to provide{" "}
        <a
          href="#"
          style={{ textDecoration: "underline" }}
          onClick={this.handleViewDocConsent}
        >
          Document Consent
        </a>{" "}
      </React.Fragment>
    );

    const disbursalConsentLabel = (
      <React.Fragment>
        I give consent to disburse loan amount to{" "}
        <b>{staticData.borrowingPartner}</b> account.
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {" "}
        {isValidForSigning && (
          <React.Fragment>
            <SpinnerComponent showSpinner={showSpinner} />
            <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
              eSign Loan Contract
            </h2>
            <br />
            <Form>
              <h4 className="fundfinaColor">Summary</h4>
              <Form.Group as={Row} controlId="amount">
                <Form.Label column sm="4">
                  Loan Amount:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat
                      value={staticData.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      prefix={"₹"}
                    />
                  </span>{" "}
                </Form.Label>

                <Form.Label column sm="4">
                  Processing Fee:{" "}
                  <span style={{ color: "black" }}>
                    <NumberFormat
                      value={staticData.processingCharges}
                      displayType={"text"}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      prefix={"₹"}
                    />
                  </span>{" "}
                </Form.Label>

                {staticData.discountAmount > 0 && (
                  <Form.Label column sm="4">
                    Discount:{" "}
                    <span style={{ color: "black" }}>
                      <NumberFormat
                        value={staticData.discountAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </span>{" "}
                  </Form.Label>
                )}

                {staticData.p_type !== "LC" && (
                  <Form.Label column sm="4">
                    GST Charges:{" "}
                    <span style={{ color: "black" }}>
                      <NumberFormat
                        value={staticData.gstCharges}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </span>{" "}
                  </Form.Label>
                )}

                {staticData.p_type !== "LC" && (
                  <Form.Label column sm="4">
                    Stamping Charges:{" "}
                    <span style={{ color: "black" }}>
                      <NumberFormat
                        value={staticData.stampingCharges}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </span>{" "}
                  </Form.Label>
                )}

                {staticData.disbursalAmount && (
                  <Form.Label column sm="4">
                    Disbursal Amount:{" "}
                    <span style={{ color: "black" }}>
                      <NumberFormat
                        value={staticData.disbursalAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </span>{" "}
                  </Form.Label>
                )}

                <Form.Label column sm="4">
                  Tenure:{" "}
                  <span style={{ color: "black" }}>{staticData.tenure}</span>{" "}
                </Form.Label>

                {staticData.p_type !== "LC" && staticData.emiAmount && (
                  <Form.Label column sm="4">
                    Estimated EMI:{" "}
                    <span style={{ color: "black" }}>
                      <NumberFormat
                        value={staticData.emiAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </span>{" "}
                  </Form.Label>
                )}

                {staticData.emiCount && (
                  <Form.Label column sm="4">
                    No. of Installments:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.emiCount}
                    </span>{" "}
                  </Form.Label>
                )}

                {staticData.totalInterestPayable && (
                  <Form.Label column sm="4">
                    Total Interest Payable:{" "}
                    <span style={{ color: "black" }}>
                      <NumberFormat
                        value={staticData.totalInterestPayable}
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                        prefix={"₹"}
                      />
                    </span>{" "}
                  </Form.Label>
                )}

                {staticData.collectionType && (
                  <Form.Label column sm="4">
                    Mode of Collection:{" "}
                    <span style={{ color: "black" }}>
                      {staticData.collectionType}
                    </span>{" "}
                  </Form.Label>
                )}
              </Form.Group>
            </Form>
            {staticData.isSigned && (
              <h5 style={{ color: "green" }}>Loan Contract has been signed.</h5>
            )}
            {!staticData.isSigned ? (
              true ? (
                <Form onSubmit={this.doSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="otp">
                      <Form.Label>Enter OTP*</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter OTP"
                        name="otp"
                        value={data["otp"]}
                        onChange={this.handleContractOTPKeyChange}
                        autoComplete="one-time-code"
                        maxLength="6"
                        autoFocus={true}
                        onKeyPress={this.handleKeyPressNumeric}
                      />{" "}
                      {info["otp"] && (
                        <Form.Text
                          className="text-muted"
                          style={{ color: "green" }}
                        >
                          {info["otp"]}
                        </Form.Text>
                      )}
                      {isRegenerateLink && (
                        <Nav.Item id="regenerateOTP">
                          <Nav.Link
                            className="underline"
                            onClick={this.sendOtp}
                            style={{
                              padding: 0,
                              float: "right",
                              textDecoration: "underline",
                            }}
                          >
                            Re-generate OTP
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {errors["otp"] && (
                        <div className="alert alert-danger">
                          {errors["otp"]}
                        </div>
                      )}{" "}
                      {isRegenerateText && (
                        <Form.Label>{`Re-generate OTP in ${counter} seconds.`}</Form.Label>
                      )}
                    </Form.Group>
                  </Form.Row>{" "}
                  {/* {this.state.isUserPhotoRequired ? (
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="photo">
                        <Form.Group>
                          <Form.Label>Upload Your Photo*</Form.Label>
                          <Form.File
                            label={files["photoLabel"]}
                            onChange={(e) =>
                              this.onPhotoUploadChange(e, true, "LOAN_CONTRACT")
                            }
                            id="photo"
                            name="photo"
                            accept="image/*"
                            capture="user"
                            custom
                            style={files["photo"] ? { display: "none" } : {}}
                          />
                          <div
                            style={!files["photo"] ? { display: "none" } : {}}
                            className="position-relative"
                          >
                            {this.renderThumbnail(files.photoSrc)}
                            {this.renderThumbnailAction(
                              "photo",
                              files.photoSrc
                            )}
                          </div>{" "}
                          {fileErrors["photo"] && (
                            <div className="alert alert-danger">
                              {fileErrors["photo"]}
                            </div>
                          )}
                        </Form.Group>
                      </Form.Group>
                    </Form.Row>
                  ) : (
                    ""
                  )} */}
                  {/* {this.state.isShopInternalPhotoRequired ? (
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="shopInternalPhoto">
                        <Form.Group>
                          <Form.Label>Upload Internal Shop photo*</Form.Label>
                          <Form.File
                            label={files["shopInternalPhotoLabel"]}
                            onChange={(e) =>
                              this.onPhotoUploadChange(e, true, "SHOP_INTERNAL_PHOTO")
                            }
                            id="shopInternalPhoto"
                            name="shopInternalPhoto"
                            accept="image/*"
                            capture="user"
                            custom
                            style={
                              files["shopInternalPhoto"]
                                ? { display: "none" }
                                : {}
                            }
                          />
                          <div
                            style={
                              !files["shopInternalPhoto"]
                                ? { display: "none" }
                                : {}
                            }
                            className="position-relative"
                          >
                            {this.renderThumbnail(files.shopInternalPhotoSrc)}
                            {this.renderThumbnailAction(
                              "shopInternalPhoto",
                              files.shopInternalPhotoSrc
                            )}
                          </div>{" "}
                          {fileErrors["shopInternalPhoto"] && (
                            <div className="alert alert-danger">
                              {fileErrors["shopInternalPhoto"]}
                            </div>
                          )}
                        </Form.Group>
                      </Form.Group>
                    </Form.Row>
                  ) : (
                    ""
                  )} */}
                  {/* {this.state.isShopExternalPhotoRequired ? (
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="shopExternalPhoto">
                        <Form.Group>
                          <Form.Label>Upload External Shop photo*</Form.Label>
                          <Form.File
                            label={files["shopExternalPhotoLabel"]}
                            onChange={(e) =>
                              this.onPhotoUploadChange(e, true, "SHOP_EXTERNAL_PHOTO")
                            }
                            id="shopExternalPhoto"
                            name="shopExternalPhoto"
                            accept="image/*"
                            capture="user"
                            custom
                            style={
                              files["shopExternalPhoto"]
                                ? { display: "none" }
                                : {}
                            }
                          />
                          <div
                            style={
                              !files["shopExternalPhoto"]
                                ? { display: "none" }
                                : {}
                            }
                            className="position-relative"
                          >
                            {this.renderThumbnail(files.shopExternalPhotoSrc)}
                            {this.renderThumbnailAction(
                              "shopExternalPhoto",
                              files.shopExternalPhotoSrc
                            )}
                          </div>{" "}
                          {fileErrors["shopExternalPhoto"] && (
                            <div className="alert alert-danger">
                              {fileErrors["shopExternalPhoto"]}
                            </div>
                          )}
                        </Form.Group>
                      </Form.Group>
                    </Form.Row>
                  ) : (
                    ""
                  )} */}
                  {/* {this.state.isShopVideoRequired ? (
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="shopVideo">
                        <Form.Group>
                          <Form.Label>Upload Shop video*</Form.Label>
                          <Form.File
                            label={files["shopVideoLabel"]}
                            onChange={(e) =>
                              this.onVideoUploadChange(e, false, "SHOP_VIDEO")
                            }
                            id="shopVideo"
                            name="shopVideo"
                            accept="video/*"
                            capture="user"
                            custom
                            style={
                              files["shopVideo"] ? { display: "none" } : {}
                            }
                          />
                          <div
                            style={
                              !files["shopVideo"] ? { display: "none" } : {}
                            }
                            className="position-relative"
                          >
                            {this.renderVideoThumbnail()}
                            {this.renderThumbnailAction(
                              "shopVideo",
                              files.shopVideoSrc
                            )}
                          </div>{" "}
                          {fileErrors["shopVideo"] && (
                            <div className="alert alert-danger">
                              {fileErrors["shopVideo"]}
                            </div>
                          )}
                        </Form.Group>
                      </Form.Group>
                    </Form.Row>
                  ) : (
                    ""
                  )} */}
                  <Form.Group controlId="docConsent">
                    <Form.Check
                      type="checkbox"
                      label={docConsentLabel}
                      feedback="You must agree before submitting."
                      checked={docConsent}
                      onChange={() => this.handleDocConsent(docConsent)}
                      color="red"
                    />{" "}
                    {errors["docConsent"] && (
                      <div className="alert alert-danger">
                        {errors["docConsent"]}
                      </div>
                    )}
                  </Form.Group>{" "}
                  {(staticData.disbursalType === "Partner" ||
                    staticData.disbursalType === "Virtual") && (
                    <Form.Group controlId="disbursalConsent">
                      <Form.Check
                        type="checkbox"
                        label={disbursalConsentLabel}
                        feedback="You must agree before submitting."
                        checked={disbursalConsent}
                        onChange={() =>
                          this.handleDisbursalConsent(disbursalConsent)
                        }
                        color="red"
                      />{" "}
                      {errors["disbursalConsent"] && (
                        <div className="alert alert-danger">
                          {errors["disbursalConsent"]}
                        </div>
                      )}
                    </Form.Group>
                  )}
                  <Form.Group controlId="kfs">
                    <Form.Label
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={this.handleViewKfs}
                    >
                      View Key Fact Sheet
                    </Form.Label>
                  </Form.Group>{" "}
                  <Button
                    className="ff-button"
                    type="submit"
                    disabled={isSubmitted}
                  >
                    Complete E-Sign
                  </Button>
                  <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
                    {errors["globalError"] && (
                      <div className="alert alert-danger">
                        {errors["globalError"]}
                      </div>
                    )}
                  </Form.Group>
                </Form>
              ) : (
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} md="12" controlId="otp">
                      {/* {
                          enableResultCheck ?
                            <Button className="ff-button mr-2 mb-2" type="button" disabled={isSubmitted} onClick={this.checkVerificationResult}>
                              Check Result
                            </Button>
                            : ""} */}
                      {!this.state.resultCheckInProgress ? (
                        <>
                          <p>
                            Please give access to Camera{" "}
                            <i className="fa fa-camera" /> and Microphone{" "}
                            <i className="fa fa-microphone" /> to complete
                            "Video Verification" process
                          </p>
                          <Button
                            className="ff-button mb-2"
                            type="button"
                            disabled={!isVideoAndAudioAccess}
                            onClick={this.startVideoVerification}
                          >
                            Start Video Verification{" "}
                            {enableResultCheck ? "Again" : ""}
                          </Button>

                          {!isVideoAndAudioAccess ? (
                            <div className="alert alert-danger">
                              Camera & Microphone access not enabled
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <p className="text-success">
                          Your video under processing {this.state.dots || "."}
                        </p>
                      )}

                      <Form.Group
                        controlId="globalError"
                        style={{ marginTop: 30 }}
                      >
                        {errors["globalError"] && (
                          <div className="alert alert-danger">
                            {errors["globalError"]}
                          </div>
                        )}
                      </Form.Group>
                    </Form.Group>
                  </Form.Row>
                </Form>
              )
            ) : (
              ""
            )}
          </React.Fragment>
        )}
        {!isValidForSigning && (
          <React.Fragment>
            <p style={{ fontSize: 15, color: "red", fontWeight: "bold" }}>
              {errorMessage}
            </p>
          </React.Fragment>
        )}
        <Modal
          size="lg"
          show={showDocConsent}
          onHide={this.handleDocConsentClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Document Consent</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <p>
              I hereby submit voluntarily at my own discretion, the soft copy of
              the document, to Fundfina for the purpose of establishing my
              identity / address proof.
            </p>
            <p>
              Fundfina has informed me that my document submitted to the company
              herewith shall not be used for any purpose other than mentioned
              above, or as per requirements of law. Fundfina has informed me
              that this consent and my uploaded documents will be stored along
              with my loan details within the company and the lending partner. I
              hereby declare that all the information voluntarily furnished by
              me is true, correct and complete. I will not hold Fundfina and the
              lending partner or any of its officials responsible in case of any
              incorrect information provided by me.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleDocConsentClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="xl"
          show={showKfs}
          onHide={this.handleCloseKfs}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Key Fact Sheet</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
            <Kfs loanId={data.loanId} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseKfs}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default VerifyLoanContract;
