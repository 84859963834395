import http from "../common/httpService";
import { apiUrl, creditApiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/trueScore";

const apiCreditEndPoint = creditApiUrl + "credit/";

export async function getTrueScoreCount() {
    const data = await http.post(apiCreditEndPoint + "getTrueScoreCount").then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    return data;
}

export async function getOTP(email, mobile) {
    const options = {
        purpose: "truescore verification",
        email: email,
        mobile: mobile ? mobile : null
    }
    const data = await http.post(apiCreditEndPoint + "generateOTP", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });

    return data;
}

export async function verifyOtp(email, mobile, otp) {
    const options = {
        purpose: "truescore verification",
        email: email,
        mobile: mobile ? mobile : null,
        otp: otp
    }
    const data = await http.post(apiCreditEndPoint + "verifyOTP", options).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });
    // console.log("data",data);
    return data;
}

export async function getTrueScore(model) {
    const data = await http.post(apiCreditEndPoint + "getTrueScore", model).then(response => { return response.data; }).catch(error => { return (error.response) ? error.response.data : error; });

    // console.log(data);
    return data;
}