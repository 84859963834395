import InputDataForm from "../../components/common/form";
import * as earlyWarningSystemService from "../../services/earlyWarningSystem/earlyWarningSystemService";
import auth from "../../services/common/authService";
import Joi from "joi-browser";

class EarlyWarningSystemModel extends InputDataForm {
  state = {
    data: {
      loanNo: "",
      name: "",
      mobileNo: "",
      loans: [],
    },
    showSpinner: false,
    errors: {},
    showRepaymentModal: false,
    loanNo: "",
    loanId: ""
  };
  schema = {
    loanNo: Joi.any(),
    name: Joi.any(),
    mobileNo: Joi.any(),
    loans: Joi.any(),
  };
  async getLoanList() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const { data: loanList } = await earlyWarningSystemService.getLoanList(
        data
      );

      data.loans = loanList;

      this.setState({ data, showSpinner: false, currentPage: 1 });
    } catch (ex) {
      this.setState({ data, showSpinner: false, currentPage: 1 });
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;
    const currentUser = auth.getCurrentUser();
    if (currentUser.role !== "Admin") return (window.location = "/");

    await this.getLoanList();
  }

  async handleCustomErrors(errors) {
    return errors;
  }
  async doSubmit() {
    await this.getLoanList();
  }
  async getRepaymentDetails(loanNo) {
    try {
      this.setState({
        showRepaymentModal: true,
        loanId: loanNo
      });
    } catch (ex) {

    }
  }
  closeModal = () => {
    this.setState({
      showRepaymentModal: false,
    });
  };
}

export default EarlyWarningSystemModel;
