import React from "react";
import { Form, Col, Row, Modal, Image, Button } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import UpdateAlternateMobileModel from "./../../models/profile/updateAlternateMobileModel";

class UpdateAlternateMobile extends UpdateAlternateMobileModel {
  render() {
    const { data, errors, showSpinner, info } = this.state;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2 style={{ textAlign: "center" }}>Update Alternate Mobile</h2> <br />
        <Form onSubmit={this.doSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Alternate Mobile No*</Form.Label>
              <Form.Control
                type="text"
                pattern="[0-9]*"
                placeholder="Enter your alternate mobile number"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="10"
                autoFocus={true}
              />
              {errors["mobileNo"] && (
                <div className="alert alert-danger">{errors["mobileNo"]}</div>
              )}
              {info["mobileNo"] && (
                <div className="alert alert-success">{info["mobileNo"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          <Button
            className="ff-button"
            type="submit"
            disabled={data.isSubmitted}
          >
            Submit
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}

export default UpdateAlternateMobile;
