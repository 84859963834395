import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Card, CardColumns } from "react-bootstrap";

class TestimonialSlider extends Component {
    render() {
        return (
            <React.Fragment>
                <h2 className="fundfinaColor" style={{ marginTop: "42%" }}>
                    Testimonials
      </h2>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={false}
                    showThumbs={false}
                    showIndicators={true}
                    transitionTime={1000}
                    interval={6000}
                    swipeable={false}
                    emulateTouch={false}
                    swipeScrollTolerance={100}
                    infiniteLoop={true}

                >
                    <div style={{ backgroundColor: "white" }}>
                        <CardColumns>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/sandeep.jpg")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="testimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "Best co-operation from the team I have ever experienced, dedication of the work is mind blowing. Really very very happy for the process and cooperation I received from you. Really very thankful"
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Sandeep Agarwal from <cite title="Source Title">Pune</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/amitKanojia.jpeg")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="testimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "VERY NICE FEEL WITH FUNDFINA TEAM SUPPORT"
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Amit Kannojia from <cite title="Source Title">Lucknow</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/woman.png")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="testimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "I like the service of Aphelion Finance Pvt Ltd. Within half hour they passed my loan. Special thanks to Rahul Tripathy who helped me fill online form"
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Nusrat Mohd Hasan Shaikh from <cite title="Source Title">Mumbai</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>

                        </CardColumns>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                        <CardColumns>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/chanchal2.jpeg")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="testimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "Fundfina company is the best loan company. It's process is very fast and I'm happy with this company."
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Chanchal Jha from <cite title="Source Title">Ghaziabad</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/nagardeep.jpg")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="testimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "I'm happy with Fundfina. It's documentation process is very simple. This company believes in their Retailers and Distributors. I thank them for the loan."
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Nagardeep Biswas from <cite title="Source Title">Papum Pare, Arunachal Pradesh</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/jangid2.jpeg")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="testimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "Best Company for Business loans."
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Ashish Jangid from <cite title="Source Title">Jaipur</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>

                            {/* <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/rahul_tripathy.png")}
                                    className="testimonialSliderImg"
                                    style={{ width: "30%" }}
                                />
                                <Card.Body>
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p>
                                            {' '}
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
        erat a ante."{' '}
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/rahul_tripathy.png")}
                                    className="testimonialSliderImg"
                                    style={{ width: "30%" }}
                                />
                                <Card.Body>
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p>
                                            {' '}
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
        erat a ante."{' '}
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card> */}
                        </CardColumns>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                        <CardColumns>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/harish.jpeg")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="testimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "Fundfina has a very fast working process with minimum document and quick distribution"
                                        </p>
                                        <footer className="blockquote-footer" style={{ color: "#b51b1f" }}>
                                            Harish Kumar from <cite title="Source Title">Mumbai</cite>
                                        </footer>
                                    </blockquote>
                                </Card.Body>
                            </Card>
                        </CardColumns>
                    </div>
                </Carousel></React.Fragment>
        );
    }
}

export default TestimonialSlider;
