import InputDataForm from "../../components/common/form";
import * as borrowFormService from "../../services/borrow/borrowFormService";
import auth from "../../services/common/authService";
import * as analytics from "../../utils/analytics";

class BorrowFormModel extends InputDataForm {
  state = {
    data: {
      user: {},
      loans: [],
      isValidForNewLoan: false,
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 5,
    loggedInFrom: ""
    // mobileCurrentPage: 1,
    // mobilePageSize: 3
  };
  async isValidForNewLoan(partnerId) {
    const data = { ...this.state.data };

    try {
      const {
        data: isValidForLoan,
      } = await borrowFormService.isValidForNewLoan(partnerId);
      data.isValidForNewLoan = isValidForLoan.success;
      this.setState({ data });
    } catch (ex) { }
  }
  async getBorrowerLoanList(partnerId) {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {

      const { data: loanList } = await borrowFormService.borrowerLoanList(partnerId);
      data.user = loanList.user;
      data.loans = loanList.loans;

      this.setState({ data, showSpinner: false });
    } catch (ex) {
      data.isValidForNewLoan = false;
      this.setState({ data, showSpinner: false });
    }
  }
  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    analytics.track();

    const currentUser = auth.getCurrentUser();

    const currentPartner = auth.getCurrentPartner();

    let partnerId = null;

    if (currentPartner && currentPartner._id)
      partnerId = currentPartner._id;

    this.setState({
      loggedInFrom: currentUser.loggedInFrom ? currentUser.loggedInFrom : "",
    });

    await this.isValidForNewLoan(partnerId);
    await this.getBorrowerLoanList(partnerId);
  }

  async handleCustomErrors(errors) {
    return errors;
  }
  // handleMobilePageChange = (page) => {
  //   this.setState({ mobileCurrentPage: page });
  // };
  // handleMobilePagePrevious = () => {
  //   let { mobileCurrentPage } = this.state;
  //   this.setState({ mobileCurrentPage: mobileCurrentPage - 1 });
  // };
  // handleMobilePageNext = () => {
  //   let { mobileCurrentPage } = this.state;
  //   this.setState({ mobileCurrentPage: mobileCurrentPage + 1 });
  // };
}

export default BorrowFormModel;
