import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

export async function getPaymentLinks(payload) {
    let url = `${apiUrl}/ops/admin/payment/link/list`;

    let payloadKeys = Object.keys(payload);

    if (payloadKeys.length) {
        payloadKeys.map((item, index) => {

            if (index == 0)
                url += "?" + item + '=' + payload[item]
            else
                url += "&" + item + "=" + payload[item]
        })
    }
    const { data } = await http.get(url);
    return data;
}

export async function cancelPaymentLink(payload) {
    const { data } = await http.post(`${apiUrl}/ops/admin/cancel/payment/link`, {
        ...payload
    });
    return data;
}

export async function resendPaymentLink(payload) {
    let url = `${apiUrl}/ops/admin/resend/payment/link`;

    let payloadKeys = Object.keys(payload);

    if (payloadKeys.length) {
        payloadKeys.map((item, index) => {

            if (index == 0)
                url += "?" + item + '=' + payload[item]
            else
                url += "&" + item + "=" + payload[item]
        })
    }
    const { data } = await http.get(url);
    return data;
}

export async function getEnachList(payload) {
    let url = `${apiUrl}/ops/admin/payment/enach/list`;

    let payloadKeys = Object.keys(payload);

    if (payloadKeys.length) {
        payloadKeys.map((item, index) => {

            if (index == 0)
                url += "?" + item + '=' + payload[item]
            else
                url += "&" + item + "=" + payload[item]
        })
    }
    const { data } = await http.get(url);
    return data;
}

