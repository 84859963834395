import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/";

export async function insertUpdateLoan(model, ipAddress, partnerId, isStp) {
  const response = await http.post(apiEndpoint + "loan/insertUpdateLoanInfo", {
    loanId: model.loanId,
    amount: model.amount,
    purpose: model.purpose,
    repaymentFrequency: model.repaymentFrequency,
    description: model.loanDescription ? model.loanDescription : model.purpose,
    campaignTime: model.campaignTime,
    ipAddress: ipAddress ? ipAddress : "",
    partnerId: partnerId ? partnerId : "",
    isStp: isStp ? true : false
  });
  return response;
}

export async function getLoanDetails(loanId) {
  const response = await http.get(apiEndpoint + `loan/${loanId}`);
  return response;
}

export async function updateLoanAmount(loanId, amount) {
  const { data } = await http.post(
    `${apiEndpoint}loan/updateLoanAmount`, {
    loanId,
    amount
  }
  );
  return data;
}

export async function getLoanStatus(loanId) {
  const response = await http.post(apiEndpoint + `loan/getLoanStatus`, {
    loanId
  });
  return response;
}

export async function getLoanEstimation(loanId, amount) {
  const { data } = await http.post(apiEndpoint + "loan/getLoanEstimation", {
    loanId: loanId,
    amount: amount ? parseInt(amount) : ""
  });
  return data;
}

export async function getLastCompletedStep(loanId) {
  const { data } = await http.post(apiEndpoint + "loan/getLastCompletedStep", {
    loanId: loanId
  });
  return data;
}