import React from "react";
import { Form } from "react-bootstrap";

const Input = ({ name, label, error, additionalText, ...rest }) => {
  return (
    // <div className="form-group">
    //   <label htmlFor={name}>{label}</label>
    //   <input {...rest} name={name} id={name} className="form-control" />
    //   {error && <div className="alert alert-danger">{error}</div>}
    // </div>
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control {...rest} name={name} autoComplete="off" />
      {error && <div className="alert alert-danger">{error}</div>}
      {additionalText && (
        <Form.Text className="text-muted">{additionalText}</Form.Text>
      )}
    </Form.Group>
  );
};

export default Input;
