import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as manualQueue from "../../services/manualQueue/manualQueue";
import momentJalaali from "moment-jalaali";
import moment from "moment";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import * as commonService from "../../services/common/commonService";

class ManualQueueSettlementModel extends InputDataForm {
  state = {
    data: {
      partnerId: "",
      utrNo: "",
      action: "SETTLEMENT",
      id: ""
    },
    partners: [],
    errors: ""
  };
  schema = {
    partnerId: Joi.any(),
    utrNo: Joi.any(),
    action: Joi.any(),
    id: Joi.any(),
    amount: Joi.any(),
    selectedMis: Joi.any()
  };

  async componentDidMount() {

    let data = this.props.data;
    if (data) {
      if (data._id)
        this.state.data.id = data._id;

      if (data.utrNo)
        this.state.data.utrNo = data.utrNo;

      if (data.amount)
        this.state.data.amount = data.amount;
    }

    this.getBorroingPartners();
    this.getSettlementTransaction()
  };

  saveManualQueueAction = async () => {
    try {
      let payload = this.state.data;
      let selectedMISPayload = this.state.selectedMisPayload

      if (selectedMISPayload) {

        let name = selectedMISPayload.info && selectedMISPayload.info.fileName
        let url = selectedMISPayload.info && selectedMISPayload.info.url
        if (!name || !url) {
          toast.error("Invalid File please select custom upload");
          return
        }

        payload.misFile = {
          name,
          url
        }

      } else if (payload.selectedFile) {
        let filePath = await this.getSignedURL(payload.selectedFile, "RECON")
        if (!filePath) {
          return;
        }

        let misFile = {
          name: this.state.data.fileName
        }

        let filePathSplit = filePath.split('.com/');

        if (!filePathSplit || filePathSplit.length < 1) {
          return;
        }
        misFile.url = filePathSplit[1];
        payload.misFile = misFile;
      }

      this.setState({ showSpinner: true })
      const response = await manualQueue.manualQueueAction(payload);
      toast.success("Settlement Updated Success")
      this.setState({ showSpinner: false });
      this.props.onSuccess && this.props.onSuccess();
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };

  getSettlementTransaction = async () => {
    try {

      let payload = {
        utrNo: this.state.data.utrNo
      }
      this.setState({ showSpinner: true })
      const response = await manualQueue.getSettlementTransactions(payload);

      this.setState({ showSpinner: false })
      if (response && response.data) {
        this.setState({ settlementTransaction: response.data })
      }
    } catch (error) {
      this.setState({ showSpinner: false })
    }
  }

  getIgnoredMISLog = async () => {
    try {

      let payload = {
        partnerId: this.state.data.partnerId
      }
      this.setState({ showSpinner: true })
      const response = await manualQueue.getIgnoredMISList(payload);

      this.setState({ showSpinner: false })
      if (response && response.data) {
        this.setState({ misList: response.data })
      }
    } catch (error) {
      this.setState({ showSpinner: false, misList: [] })
    }
  }

  handleChangePartners = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors }, () => {
      this.getIgnoredMISLog()
    });
  };

  handleChangeMISFile = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors }, () => {
      let selectedMisPayload = this.state.misList.filter(item => item._id == input.value);

      if (selectedMisPayload && selectedMisPayload.length) {
        this.setState({ selectedMisPayload: selectedMisPayload[0] })
      } else {
        this.setState({ selectedMisPayload: null })
      }
    });
  };
}

export default ManualQueueSettlementModel;
