
import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import leadInfoService from "../../services/partner/leadInfoService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import _ from "lodash";
import { browserName, browserVersion, fullBrowserVersion, osName, osVersion, mobileVendor, mobileModel } from 'mobile-device-detect';
import SharedCache from "../../SharedCache";

class MerchantAuthenticateModel extends InputDataForm {
    state = {
        data: {
            loanId: "",
            otp: "",
            loanStatus: "",
            kycStatus: "",
            isDraft: false,
            isWhatsApp: "checked",
            mobileNo: ""
        },
        errors: {},
        info: {},
        showSpinner: false,
        isRegenerateLink: false,
        isRegenerateText: false,
        counter: 0,
        timerId: 0,
        timer: "",
        isSubmitted: false,
        messageText: "",
        otpSent: false,
        otpAutoFill: false,
        otpType: "otp_confirmation",
        maskedMobileNo: "",
        showConfirmMobilePopup: false,
        popUpShowedForMobileNo: "",
        redirectionUrl: "",
        isMobileUpdated: false,
        mobileNo: "",
        showUpdateMobile: false,
        allowEdit: false
    };
    schema = {
        loanId: Joi.string().required(),
        otp: Joi.number().required(),
        isWhatsApp: Joi.string().required(),
        mobileNo: Joi.number().required().allow("")
    };
    async componentDidMount() {
        if (this.props.location.state && this.props.location.state.userData) {
            const model = this.props.location.state.userData;
            const partnerId = this.props.match.params.id;
            const userData = await leadInfoService.userData(partnerId, false);

            SharedCache.init(userData);
            SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
            if (SharedCache.app)
                SharedCache.app.refresh();

            sessionStorage.clear();
            sessionStorage.setItem("lead", auth.encrypt(JSON.stringify({
                merchantCode: model.merchantCode,
                token: model.token,
                amount: model.amount,
                trxnId: model.trxnId,
                partnerId: partnerId
            })));

            await this.getUserData(model.merchantCode, model.token, model.amount, model.trxnId, partnerId);
        }
        else if (sessionStorage.getItem("lead")) {
            const leadInfo = sessionStorage.getItem("lead");
            const model = JSON.parse(auth.decrypt(leadInfo));
            const userData = await leadInfoService.userData(model.partnerId, false);

            SharedCache.init(userData);
            SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
            if (SharedCache.app)
                SharedCache.app.refresh();

            await this.getUserData(model.merchantCode, model.token, model.amount, model.trxnId, model.partnerId);
        }
        else if (this.props.location.state && this.props.location.state.loanData) {
            const model = this.props.location.state.loanData;
            const partnerId = this.props.match.params.id;
            const userData = await leadInfoService.userData(partnerId, false);

            SharedCache.init(userData);
            SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
            if (SharedCache.app)
                SharedCache.app.refresh();

            sessionStorage.clear();
            sessionStorage.setItem("redirectionInfo", auth.encrypt(JSON.stringify({
                loanId: model.loanId,
                partnerId: partnerId,
                redirectionUrl: model.redirectionUrl
            })));

            await this.getDetailsBasedOnLoanId(model.loanId, partnerId, model.redirectionUrl);
        }
        else if (sessionStorage.getItem("redirectionInfo")) {
            const redirectionInfo = sessionStorage.getItem("redirectionInfo");
            const model = JSON.parse(auth.decrypt(redirectionInfo));
            const userData = await leadInfoService.userData(model.partnerId, false);

            SharedCache.init(userData);
            SharedCache.set("user", { loggedInFrom: "RedirectAuthLink" });
            if (SharedCache.app)
                SharedCache.app.refresh();

            await this.getDetailsBasedOnLoanId(model.loanId, model.partnerId, model.redirectionUrl);
        }
    }
    async getUserData(merchantCode, token, amount, trxnId, partnerId) {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true });

        if (!merchantCode || !token || !amount || !trxnId) {
            errors["globalError"] = "Invalid Details provided";
            this.setState({
                errors,
                showSpinner: false,
            });
        } else {
            try {
                let response = await leadInfoService.getUserDetails(
                    merchantCode,
                    trxnId,
                    amount,
                    token
                );

                await leadInfoService.saveDeviceInfo(
                    partnerId,
                    merchantCode,
                    browserName,
                    browserVersion,
                    fullBrowserVersion,
                    osName,
                    osVersion,
                    mobileVendor,
                    mobileModel
                );

                if (response.success) {
                    data.loanId = response.data.loanId;
                    data.loanStatus = response.data.loanStatus;
                    data.isDraft = response.data.isDraft;
                    data.kycStatus = response.data.kycStatus;

                    const maskedMobileNo = response.contactDetails.maskedMobileNo;

                    const isUpdateMobile = await leadInfoService.allowMobileUpdate(
                        response.data.loanId
                    );

                    this.setState({ data, showSpinner: false, maskedMobileNo, allowEdit: isUpdateMobile.success });
                } else {
                    errors["globalError"] = response.message;
                    this.setState({
                        errors,
                        showSpinner: false,
                    });
                }
            } catch (ex) {
                alert(ex.message);
            }
        }
    }
    async getDetailsBasedOnLoanId(loanId, partnerId, redirectionUrl) {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true });

        if (!loanId) {
            errors["globalError"] = "Invalid Details provided";
            this.setState({
                errors,
                showSpinner: false,
            });
        } else {
            try {
                let response = await leadInfoService.getMaskedMobileNumber(
                    loanId
                );

                if (response.success) {
                    await leadInfoService.saveDeviceInfo(
                        partnerId,
                        response.data.merchantCode,
                        browserName,
                        browserVersion,
                        fullBrowserVersion,
                        osName,
                        osVersion,
                        mobileVendor,
                        mobileModel
                    );

                    data.loanId = loanId;
                    const maskedMobileNo = response.data.maskedMobileNo;

                    this.setState({ data, showSpinner: false, maskedMobileNo, redirectionUrl });
                } else {
                    errors["globalError"] = response.message;
                    this.setState({
                        errors,
                        showSpinner: false,
                    });
                }
            } catch (ex) {
                alert(ex.message);
            }
        }
    }
    sendOtp = async (loanId) => {
        const { data, errors, info, otpType, popUpShowedForMobileNo, isMobileUpdated } = this.state;

        if (data.isWhatsApp === "checked") {
            if (!popUpShowedForMobileNo) {
                this.setState({ popUpShowedForMobileNo: this.state.maskedMobileNo, showConfirmMobilePopup: true });
                return;
            }
            else if (popUpShowedForMobileNo !== this.state.maskedMobileNo) {
                this.setState({ popUpShowedForMobileNo: this.state.maskedMobileNo, showConfirmMobilePopup: true });
                return;
            }
        }

        delete errors["globalError"];
        delete info["otp"];

        this.setState({ showSpinner: true, errors, showConfirmMobilePopup: false });

        try {
            let response = null;

            if (isMobileUpdated)
                response = await leadInfoService.generateOTPwithMobile(
                    otpType,
                    loanId,
                    data.isWhatsApp,
                    data.mobileNo
                );
            else
                response = await leadInfoService.generateOTPForMerchant(
                    otpType,
                    loanId,
                    data.isWhatsApp
                );

            if (response.success) {
                data.otp = "";

                this.setState({
                    data,
                    info,
                    errors,
                    showSpinner: false,
                    messageText: response.message,
                    otpSent: true,
                    mobileNo: data.mobileNo,
                    isMobileUpdated
                });

                this.stopTimer();
                this.startTimer();

                //OTP Auto-Fill
                if ("OTPCredential" in window) {
                    const ac = new AbortController();

                    navigator.credentials
                        .get({
                            otp: { transport: ["sms"] },
                            signal: ac.signal,
                        })
                        .then((otp) => {
                            let { data } = this.state;
                            data.otp = otp.code;
                            this.setState({ data, otpAutoFill: true });
                            this.verifyOTP();
                            ac.abort();
                        })
                        .catch((err) => {
                            ac.abort();
                            console.log(err);
                        });
                }
            } else {
                errors["globalError"] = response.message;
                this.setState({
                    errors,
                    showSpinner: false,
                });
            }
        } catch (ex) {
            errors["globalError"] = ex.response.message;
            delete info["otp"];
            this.setState({
                errors,
                info,
                showSpinner: false,
            });
        }
    };
    verifyOTP = async () => {
        const { data, errors, info, otpType, otpAutoFill, redirectionUrl, isMobileUpdated, mobileNo } = this.state;
        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            let verifyOtpResponse = null;

            if (isMobileUpdated)
                verifyOtpResponse = await leadInfoService.verifyOTPwithMobile(
                    data.otp,
                    otpType,
                    data.loanId,
                    otpAutoFill,
                    data.isWhatsApp,
                    mobileNo
                );
            else
                verifyOtpResponse = await leadInfoService.verifyCustomOTPForMerchant(
                    data.otp,
                    otpType,
                    data.loanId,
                    otpAutoFill,
                    data.isWhatsApp
                );

            if (verifyOtpResponse.success) {
                delete errors["otp"];
                info["otp"] = "OTP Matched";
                this.stopTimer();
                this.setState({
                    errors,
                    data,
                    showSpinner: false,
                    isRegenerateLink: false,
                    isRegenerateText: false,
                    isSubmitted: false
                });

                auth.setLogin(verifyOtpResponse.user, verifyOtpResponse.partner, "RedirectAuthLink");
                await auth.getMenu();

                if (redirectionUrl)
                    window.location.href = redirectionUrl;
                else if (data.isDraft || (data.loanStatus === "Initiated" || data.loanStatus === "Draft") || (data.kycStatus === "Review" && (data.loanStatus === "Under Review" || data.loanStatus === "Loan Contract Accepted" || data.loanStatus === "Recourse Pending")))
                    window.location.href = `/applyLoan/${data.loanId}`;
                else if (data.loanStatus == "Approved By Admin")
                    window.location.href = `/loanOffer/${data.loanId}`;
                else window.location.href = `/borrow`;
            } else {
                errors["otp"] = verifyOtpResponse.message;
                delete info["otp"];
                this.setState({ errors, data, showSpinner: false, isSubmitted: false });
            }
        } catch (ex) {
            this.stopTimer();
            this.startTimer();
            delete info["otp"];
            this.setState({ data, errors, showSpinner: false, isSubmitted: false });
        }
    };
    async sendOtpForMobile(loanId) {
        const { data, errors } = this.state;

        if (!data.mobileNo) {
            errors["mobileNo"] = "Please enter mobile number";
            this.setState({ errors });
            return;
        }
        else if (data.mobileNo.length !== 10) {
            errors["mobileNo"] = "Mobile number must be 10 digits";
            this.setState({ errors });
            return;
        }
        else if (isNaN(data.mobileNo)) {
            errors["mobileNo"] = "Invalid Mobile Number";
            this.setState({ errors });
            return;
        }
        else if (data.mobileNo.length !== 10)
            delete errors["mobileNo"];

        this.setState({ errors, isMobileUpdated: true, maskedMobileNo: data.mobileNo }, () => {
            this.sendOtp(loanId);
        });
    };
    async handleCustomErrors() {
        const data = { ...this.state.data };
        let errors = { ...this.state.errors };

        const { otpAutoFill, otpType } = this.state;

        delete errors["globalError"];
        delete errors["otp"];

        if (!data.otp) {
            errors["otp"] = "Please enter OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (data.otp.length !== 4) {
            errors["otp"] = "OTP must me 4-digit";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (isNaN(data.otp)) {
            errors["otp"] = "Invalid OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else delete errors["otp"];

        this.setState({ errors });

        return errors;
    }
    handleWhatsAppConsent = async (isWhatsApp) => {
        const data = { ...this.state.data };

        data.isWhatsApp = isWhatsApp ? "checked" : "";

        this.setState({ data, otpType: isWhatsApp ? "otp_confirmation" : "Merchant_Authentication_OTP2" });
    };
    handleCloseFinalPopUp = () => {
        const data = { ...this.state.data };
        data.isWhatsApp = false;
        this.setState({ showConfirmMobilePopup: false, data, otpType: "Merchant_Authentication_OTP2" });
    };
    doSubmit = async (e) => {
        e.preventDefault();
        let errors = await this.handleCustomErrors();

        if (_.isEmpty(errors)) await this.verifyOTP();
    };
    showUpdateMobile() {
        const data = { ...this.state.data };
        data.mobileNo = "";
        this.setState({ showUpdateMobile: true, data, otpSent: false, mobileNo: "" })
    }
};

export default MerchantAuthenticateModel;
