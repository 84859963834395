import InputDataForm from "../../components/common/form";
import Joi from "joi-browser";
import * as lenderPayoutService from "../../services/LenderPayout/LenderPayout";

class PayoutDetails extends InputDataForm {
  state = {
    data: {
    },
    showSpinner: false,
    date: "",
    lendingPartner: "",
    transactions :[]
  };
  schema = {
    transactions: Joi.any(),
    utrNo: Joi.any()
  };

  async getOrderSplitDetails() {
    const data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      let response = await lenderPayoutService.getOrderSplitDetails(data);
      let transactions = response && response.data || [];
      this.setState({ transactions, showSpinner: false }, () => {
      });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async componentDidMount() {
    if (this.props.data && this.props.data.utrNo) {
      this.state.data.utrNo = this.props.data.utrNo
    }

    if (this.props.data && this.props.data.disbursalTransactionId) {
      this.state.data.disbursalTransactionId = this.props.data.disbursalTransactionId
    }
     await this.getOrderSplitDetails();

  };
}

export default PayoutDetails;
