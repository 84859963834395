import React from "react";
import SpinnerComponent from "./../../components/common/spinner";
import MandateInfoModel from "../../models/borrow/mandateInfoModel";
import { Form, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { isMobile } from 'react-device-detect';

class MandateInfo extends MandateInfoModel {
    render() {
        const { showSpinner, isSubmitted, redirect, loanId, eNach, eNachType, accountNo, bankName } = this.state;

        return (
            <React.Fragment>
                {" "}
                <SpinnerComponent showSpinner={showSpinner} />
                <Form onSubmit={this.handleSubmit}>
                    <p>You are just one step from getting your money.</p>

                    <p>Please submit the Contract {eNach && (<span>and e-Nach</span>)} to complete the application form.</p>

                    {eNachType === "aadhaar" && (<p>Make sure you have the mobile number mapped to your Aadhaar Number</p>)}

                    {eNachType === "api" && (<p>Make sure your Debit Card or Net Banking is <b>active</b> for Bank A/C# <b>{accountNo}</b> of <b>{bankName}</b>.</p>)}

                    <br />
                    <Button
                        className="ff-button"
                        type="Next"
                        disabled={isSubmitted}
                        style={{ width: isMobile ? "100%" : 150 }}
                    >
                        Next
                    </Button>
                </Form>
            </React.Fragment >
        );
    }
}

export default MandateInfo;
