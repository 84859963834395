import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi, { errors, schema } from "joi-browser";
import _ from "lodash";
import * as lenderService from "../../services/lender/lenderService";

class lenderAccountSummaryModel extends InputDataForm {
    state = {
        data: {

        },
        showSpinner: false,
        errors: {},
        info: {},
        list: [],
        exportList: [],
        currentPage: 1,
        pageSize: 10,
        refreshAllEnabled: true,
        accountRefreshState: {}
    }

    async componentDidMount() {

        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();
        if (currentUser.role != "Admin") return (window.location = "/");
        else {
            this.getLenderAccountList();
            this.getJobStatus();
        }
    };

    async getLenderAccountList() {

        const data = { ...this.state.data };
        let exportList = { ...this.state.exportList };
        let list = { ...this.state.list };
        let errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        exportList = [];
        try {
            errors["globalError"] = null;
            const accountSummaryList = await lenderService.getLenderAccountSummaryList(data);

            if (accountSummaryList) {
                if (accountSummaryList.success) {
                    list = accountSummaryList.data;
                    this.setState({ exportList, list, errors, successMsg: "" });
                }
                else {
                    list = [];
                    errors["globalError"] = accountSummaryList.message;
                    this.setState({ exportList, list, errors });
                }

            } else {
                errors["globalError"] = "Some Error Occurred";
                this.setState({ exportList, errors, successMsg: null });
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ exportList, errors, showSpinner: false, successMsg: null });
        }
    }

    async getJobStatus() {
        this.setState({ showSpinner: true });
        let errors = { ...this.state.errors };

        try {

            errors["globalError"] = null;
            const jobStatus = await lenderService.getAccountSummaryJobStatus();
            if (jobStatus && jobStatus.data) {
                let status = jobStatus.data.status;
                if (status == "InProgress") {
                    this.setState({ refreshAllEnabled: false });
                } else {
                    this.setState({ refreshAllEnabled: true });
                    this.getLenderAccountList();
                }
            }
            this.setState({ showSpinner: false });

        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, refreshAllEnabled: true, successMsg: null });
        }
    }

    handleJobStatus = async (e) => {
        this.getJobStatus()
    }

    handleRefreshAll = async (e) => {

        this.setState({ showSpinner: true, refreshAllEnabled: false });
        let errors = { ...this.state.errors };

        try {
            errors["globalError"] = null;
            const refreshAllStatus = await lenderService.accountSummaryRefresh();
            this.setState({ showSpinner: false, errors });
        } catch (ex) {
            errors["globalError"] = ex.message;
            this.setState({ errors, showSpinner: false, successMsg: null });
        }
    }

    handleAccountRefresh = async (lenderId, accountNumber) => {

        let accountRefreshState = this.state.accountRefreshState;

        accountRefreshState[accountNumber] = true;

        this.setState({ showSpinner: true, accountRefreshState: accountRefreshState });
        let errors = { ...this.state.errors };
        try {
            errors["globalError"] = null;
            const refreshAllStatus = await lenderService.refreshLenderAccountSummary(lenderId, accountNumber);
            accountRefreshState[accountNumber] = false;
            this.setState({ showSpinner: true, errors, accountRefreshState: accountRefreshState });
            this.getLenderAccountList();

        } catch (ex) {
            errors["globalError"] = ex.message;
            accountRefreshState[accountNumber] = false;
            this.setState({ errors, showSpinner: false, successMsg: null, accountRefreshState: accountRefreshState });
        }

    };

}

export default lenderAccountSummaryModel;