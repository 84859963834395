
import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import Joi from "joi-browser";
import _ from "lodash";
import { browserName, browserVersion, fullBrowserVersion, osName, osVersion, mobileVendor, mobileModel } from 'mobile-device-detect';
import AgentService from '../../services/agent/agent'

class AgentAuthenticateModel extends InputDataForm {
    state = {
        data: {
            loanId: "",
            otp: "",
            loanStatus: "",
            kycStatus: "",
            isDraft: false,
            mobileNo: ""
        },
        errors: {},
        info: {},
        showSpinner: false,
        isRegenerateLink: false,
        isRegenerateText: false,
        counter: 0,
        timerId: 0,
        timer: "",
        isSubmitted: false,
        messageText: "",
        otpSent: false,
        otpAutoFill: false,
        maskedMobileNo: "",
        showConfirmMobilePopup: false,
        popUpShowedForMobileNo: "",
        redirectionUrl: "",
        isMobileUpdated: false,
        mobileNo: "",
        showUpdateMobile: false,
        allowEdit: false
    };
    schema = {
        mobileNo: Joi.number().required().allow(""),
        otp: Joi.string().allow(""),
    };
    async componentDidMount() {
    }

    sendOtp = async (loanId) => {
        const { data, errors, info, isMobileUpdated } = this.state;


        delete errors["globalError"];
        delete info["otp"];

        this.setState({ showSpinner: true, errors, showConfirmMobilePopup: false });

        try {
            let response = null;

            response = await AgentService.sendLoginOtp({ mobileNumber: data.mobileNo });

            if (response.success) {
                data.otp = "";

                this.setState({
                    data,
                    info,
                    errors,
                    showSpinner: false,
                    messageText: response.message,
                    otpSent: true,
                    mobileNo: data.mobileNo,
                });

                this.stopTimer();
                this.startTimer();

                //OTP Auto-Fill
                if ("OTPCredential" in window) {
                    const ac = new AbortController();

                    navigator.credentials
                        .get({
                            otp: { transport: ["sms"] },
                            signal: ac.signal,
                        })
                        .then((otp) => {
                            let { data } = this.state;
                            data.otp = otp.code;
                            this.setState({ data, otpAutoFill: true });
                            this.verifyOTP();
                            ac.abort();
                        })
                        .catch((err) => {
                            ac.abort();
                            console.log(err);
                        });
                }
            } else {
                errors["globalError"] = response.message;
                this.setState({
                    errors,
                    showSpinner: false,
                });
            }
        } catch (ex) {
            errors["globalError"] = ex.response.message;
            delete info["otp"];
            this.setState({
                errors,
                info,
                showSpinner: false,
            });
        }
    };
    verifyOTP = async () => {
        const { data, errors, info, otpType, otpAutoFill, redirectionUrl, isMobileUpdated, mobileNo } = this.state;
        this.setState({ showSpinner: true, isSubmitted: true });

        try {
            let verifyOtpResponse = null;

            verifyOtpResponse = await AgentService.verifyLoginOtp({ mobileNumber: data.mobileNo, otp: data.otp });

            if (verifyOtpResponse.success) {
                delete errors["otp"];
                info["otp"] = "OTP Matched";
                this.stopTimer();
                this.setState({
                    errors,
                    data,
                    showSpinner: false,
                    isRegenerateLink: false,
                    isRegenerateText: false,
                    isSubmitted: false
                });

                await auth.getMenu();
                window.location.href = `/loan-collection`;
            } else {
                errors["otp"] = verifyOtpResponse.message;
                delete info["otp"];
                this.setState({ errors, data, showSpinner: false, isSubmitted: false });
            }
        } catch (ex) {
            this.stopTimer();
            this.startTimer();
            delete info["otp"];
            this.setState({ data, errors, showSpinner: false, isSubmitted: false });
        }
    };
    async sendOtpForMobile(loanId) {
        const { data, errors } = this.state;

        if (!data.mobileNo) {
            errors["mobileNo"] = "Please enter mobile number";
            this.setState({ errors });
            return;
        }
        else if (data.mobileNo.length !== 10) {
            errors["mobileNo"] = "Mobile number must be 10 digits";
            this.setState({ errors });
            return;
        }
        else if (isNaN(data.mobileNo)) {
            errors["mobileNo"] = "Invalid Mobile Number";
            this.setState({ errors });
            return;
        }
        else if (data.mobileNo.length !== 10)
            delete errors["mobileNo"];

        this.setState({ errors, isMobileUpdated: true, maskedMobileNo: data.mobileNo }, () => {
            this.sendOtp();
        });
    };
    async handleCustomErrors() {
        const data = { ...this.state.data };
        let errors = { ...this.state.errors };

        const { otpAutoFill, otpType } = this.state;

        delete errors["globalError"];
        delete errors["otp"];

        if (!data.otp) {
            errors["otp"] = "Please enter OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (data.otp.length !== 6) {
            errors["otp"] = "OTP must me 4-digit";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else if (isNaN(data.otp)) {
            errors["otp"] = "Invalid OTP";
            commonService.logUserOtp(data.loanId, data.otp, false, otpType, otpAutoFill);
        }
        else delete errors["otp"];

        this.setState({ errors });

        return errors;
    }

    doSubmit = async (e) => {
        e.preventDefault();
        let errors = await this.handleCustomErrors();

        if (_.isEmpty(errors)) await this.verifyOTP();
    };

};

export default AgentAuthenticateModel;
