export function displayAmount(amount) {
    return '₹' + amount
}

export function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
}


export function getMobileNumberArray(mobileNumbersObj) {
    let arr = []
    if (mobileNumbersObj && mobileNumbersObj.length) {
        mobileNumbersObj.forEach(element => {
            arr.push(element.mobileNumber)
        })
    }

    return arr
}
