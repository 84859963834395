import React from "react";
import * as analytics from "../../utils/analytics";

function LendingPledge(props) {
    analytics.track();
    return (
        <React.Fragment>
            <h2 style={{ textAlign: "center" }}>Our Lending Pledge</h2>
            <p>Democratising finance is raison d'être for Fundfina.</p>
            <p>We pride in our unrelenting resolve to provide inclusive access to affordable and appropriate financing for small businesses and individuals and we pledge to uphold the principles through our conduct and work practices.</p>
            <p><i>Fundfina pledges to its customers that:</i></p>
            <ul>
                <li><i>We are committed to providing credit to businesses and individuals that do not have access to appropriate and affordable financial products and services.</i></li>
                <li><i>
                    We will provide all information about our products and services upfront and see to that it is clearly understood by customers to enable them to choose a product that meets their needs and that they know how to apply for that product.</i></li>
                <li><i>We will treat all our customers equally and fairly regardless of their sex, race, colour, nationality, caste, religion, sexual orientation or any other factor.</i></li>
                <li><i>If any application for our product or services gets declined, we will communicate to the customer the exact reason for the decline in a clear and concise manner.</i></li>
                <li><i>Every customer whose application has been declined has a right to appeal the decision.</i></li>
                <li><i>We will provide clear documentation confirming our interest charges, penalty charges, and repayment charges of our borrowings and will not collect any extra charges from the customer.</i></li>
                <li><i>We will provide a smooth and hassle-free customer journey for all our product and services, and for handling complaints and queries.</i></li>
                <li><i>We will provide customers with transparent options if they anticipate or enter into financial difficulty, giving them reasonable time to return their financial/business situation to better health</i></li>
            </ul>
        </React.Fragment>)
}

export default LendingPledge;