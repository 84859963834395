import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import BorrowFormModel from "../../models/borrow/borrowFormModel";
import { Table } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import Pagination from "../../components/common/pagination";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { isMobile } from 'react-device-detect';

class BorrowForm extends BorrowFormModel {
  getPagedData = () => {
    const { pageSize, currentPage, data } = this.state;

    let loans = data.loans;

    const filteredLoans = paginate(loans, currentPage, pageSize);

    return { totalCount: loans.length, data: filteredLoans };
  };
  // getPagedMobileData = () => {
  //   const { mobilePageSize, mobileCurrentPage, data } = this.state;

  //   let loans = data.loans;

  //   const filteredMobileLoans = paginate(loans, mobileCurrentPage, mobilePageSize);

  //   return { totalMobileCount: loans.length, mobileData: filteredMobileLoans };
  // };
  render() {
    const { isValidForNewLoan, user } = this.state.data;
    const { showSpinner, currentPage, pageSize, loggedInFrom, mobileCurrentPage, mobilePageSize } = this.state;

    const { totalCount, data: loans } = this.getPagedData();

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        {isMobile &&
          loans && loans.length > 0 && (
            <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
              My Loans
            </h2>)}
        {!isMobile && (
          <h2 className="fundfinaColor" style={{ textAlign: "center" }}>
            My Loans
          </h2>)}
        <h4>
          <Link
            to="/applyLoan"
            className={
              isValidForNewLoan && loggedInFrom !== "RedirectAuthLink" ? "" : "hidden"
            }
          >
            Create a new loan
          </Link>
        </h4>
        <br />
        {/* <h5>My Loans</h5> */}
        {!isMobile && (
          <React.Fragment>
            <Table responsive striped bordered hover>
              <thead>
                <tr style={{whiteSpace: "nowrap"}}>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Interest</th>
                  <th>Maturity</th>
                  <th>Purpose</th>
                  <th>KYC Status</th>
                  <th>Loan Status</th>
                  <th>Admin Remarks</th>
                  <th>Actions</th>
                  <th>Schedule</th>
                  <th>Bank Details</th>
                </tr>
              </thead>
              <tbody style={{whiteSpace: "nowrap"}}>
                {loans &&
                  loans.map((loan) => (
                    <tr key={loan._id}>
                      <td>{moment(loan.insertedOn).format("DD/MM/yyyy")}</td>
                      <td>
                        <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                      </td>
                      <td>
                        {loan.interestRate}
                        {loan.interestRate && (
                          <i className="fa fa-percent" aria-hidden="true"></i>
                        )}
                      </td>
                      <td>
                        {loan.maturity &&
                          loan.repaymentFrequency === "Daily" &&
                          `${loan.maturity} days`}
                        {loan.maturity &&
                          loan.repaymentFrequency === "Weekly" &&
                          `${loan.maturity} weeks`}
                        {loan.maturity &&
                          loan.repaymentFrequency === "Forthnightly" &&
                          `${loan.maturity} forthnights`}
                        {loan.maturity &&
                          loan.repaymentFrequency === "Monthly" &&
                          `${loan.maturity} months`}
                      </td>
                      <td>{loan.purpose}</td>
                      <td>{user.userDetails.kycStatus}</td>
                      <td> {loan.isDraft ? "In Draft" : loan.status}</td>
                      <td>
                        {user.userDetails.kycStatus === "Approved"
                          ? loan.adminRemarks
                          : user.userDetails.kycRemarks}
                      </td>
                      <td>
                        {(loan.isDraft || loan.status === "Review" || (user.userDetails.kycStatus === "Review" && (loan.status === "Under Review" || loan.status === "Loan Contract Accepted" || loan.status === "Recourse Pending"))) && (
                          <Link to={`/applyLoan/${loan._id}`}>Edit</Link>
                        )}
                        {loan.status === "Approved By Admin" && !loan.hideAcceptLink && (
                          <Link to={`/loanOffer/${loan._id}`}>Accept/Reject</Link>
                        )}
                        {loan.status === "Loan Is Funding" && (
                          <Link to={`/verifyLoanContract/${loan._id}`}>
                            Sign Loan Contract
                          </Link>
                        )}
                      </td>
                      <td>
                        {loan.status === "Loan In Progress" && (
                          <Link to={`/viewSchedule/${loan._id}`}>
                            View Schedule
                          </Link>
                        )}
                      </td>
                      <td>
                      {loan._id && !loan.disbursalDetails?.accountNo ? <Link to={`/updateBankDetail/${loan._id}?edit=true&borrow=true`}>
                            Edit
                          </Link> : <p></p>}
                      </td>
                    </tr>
                  ))}
                {loans.length == 0 && (
                  <tr key="no">
                    <td colSpan="10">No record found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div style={{ width: "100%", overflowX: "scroll" }}>
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
                onPagePrevious={this.handlePagePrevious}
                onPageNext={this.handlePageNext}
              />
            </div>
          </React.Fragment>
        )}
        <div style={{ maxHeight: 600, overflowY: "scroll" }}>
          <Table responsive striped bordered hover>
            {isMobile && (
              loans &&
              loans.map((loanItem, i) => (
                <tbody>
                  {i > 0 && <tr><td colSpan="2" className="trBreakColor"></td></tr>}
                  <tr>
                    <td style={{ width: "50%" }}><b>Date</b></td>
                    <td style={{ width: "50%" }}>{moment(loanItem.insertedOn).format("DD/MM/yyyy")}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}><b>Amount</b></td>
                    <td style={{ width: "50%" }}><NumberFormat value={loanItem.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} /></td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}><b>Maturity</b></td>
                    <td style={{ width: "50%" }}>{loanItem.maturity &&
                      loanItem.repaymentFrequency === "Daily" &&
                      `${loanItem.maturity} days`}
                      {loanItem.maturity &&
                        loanItem.repaymentFrequency === "Weekly" &&
                        `${loanItem.maturity} weeks`}
                      {loanItem.maturity &&
                        loanItem.repaymentFrequency === "Forthnightly" &&
                        `${loanItem.maturity} forthnights`}
                      {loanItem.maturity &&
                        loanItem.repaymentFrequency === "Monthly" &&
                        `${loanItem.maturity} months`}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "50%" }}><b>Loan Status</b></td>
                    <td style={{ width: "50%" }}>{loanItem.isDraft ? "In Draft" : loanItem.status}</td>
                  </tr>
                  {(user.userDetails.kycStatus !== "Initiated" && user.userDetails.kycStatus !== "ReInitiated" && user.userDetails.kycStatus !== "Under Review") && (
                    <tr>
                      <td style={{ width: "50%" }}><b>Comments</b></td>
                      <td style={{ width: "50%" }}>{user.userDetails.kycStatus === "Approved"
                        ? loanItem.adminRemarks
                        : user.userDetails.kycRemarks}</td>
                    </tr>
                  )}
                  {(loanItem.isDraft || user.userDetails.kycStatus === "Review" ||
                    loanItem.status === "Review" || loanItem.status === "Approved By Admin" || loanItem.status === "Loan Is Funding") && (
                      <tr>
                        <td style={{ width: "50%" }}><b>Actions</b></td>
                        <td style={{ width: "50%" }}>
                          {(loanItem.isDraft || loanItem.status === "Review" || (user.userDetails.kycStatus === "Review" && (loanItem.status === "Under Review" || loanItem.status === "Loan Contract Accepted" || loanItem.status === "Recourse Pending")) || loanItem.forceMandate) && (
                            <Link to={`/applyLoan/${loanItem._id}`}>Edit</Link>
                          )}
                          {loanItem.status === "Approved By Admin" && !loanItem.hideAcceptLink && (
                            <Link to={`/loanOffer/${loanItem._id}`}>Accept/Reject</Link>
                          )}
                          {loanItem.status === "Loan Is Funding" && (
                            <Link to={`/verifyLoanContract/${loanItem._id}`}>
                              Sign Loan Contract
                            </Link>
                          )}</td>
                      </tr>)}
                  {loanItem.status === "Loan In Progress" && (
                    <tr>
                      <td style={{ width: "50%" }}><b>Schedule</b></td>
                      <td style={{ width: "50%" }}> {loanItem.status === "Loan In Progress" && (
                        <Link to={`/viewSchedule/${loanItem._id}`}>
                          View Schedule
                        </Link>
                      )}</td>
                    </tr>)}
                </tbody>

              )))}
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default BorrowForm;
