import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import * as lenderPayoutService from "../../services/LenderPayout/LenderPayout";
import momentJalaali from "moment-jalaali";
import moment from "moment";
import Joi from "joi-browser";
import { toast } from "react-toastify";

class LenderPayoutModel extends InputDataForm {
  state = {
    statements: [],
    data: {},
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 0,
    evaluatorRole: "",
    transactions: [],
    partners: []
  };
  schema = {
    partnerId: Joi.any()
  };

  async componentDidMount() {
    this.getLendingPartners()
  };

  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    await this.getLenderPayout();
  };

  getLenderPayout = async () => {
    try {

      if (!this.state.data.partnerId) {
        toast.error("Please Select Partner")
        return
      }

      let reqObj = {
        partnerId: this.state.data.partnerId
      }

      this.setState({ showSpinner: true })
      const response = await lenderPayoutService.getLenderPayoutList(reqObj);
      this.setState({ showSpinner: false })

      if (response && response.data) {
        this.setState({ transactions: response.data });
      } else {
        this.setState({ transactions: [] })
      }
    }
    catch (ex) {
      this.setState({ showSpinner: false })
    }
  };

  async getLendingPartners() {
    try {
      const { data: partnerList } = await commonService.getLendingParterList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };


  async lenderPayoutInit(transaction) {
    try {

      let payload = {
        transactionDate: transaction.transactionDate,
        partnerId: this.state.data.partnerId
      }
      this.setState({ showSpinner: true })
      const response = await lenderPayoutService.lenderPayoutInit(payload);

      this.setState({ showSpinner: false })
      if (response.success) {
        toast.success("Payout Successfully initiated");
      } else {
        toast.error("Payout Initiate failed");
      }
    }
    catch (ex) {
      this.setState({ showSpinner: false })
      toast.error("Payout Initiate failed");
    }
  };
}



export default LenderPayoutModel;
