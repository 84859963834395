import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { creditApiUrl } from "../../config.json";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/agent";

export async function sendLoginOtp(payload) {
    const { data } = await http.post(apiEndpoint + "/login/send-otp", payload);
    return data;
}


export async function verifyLoginOtp(payload) {
    const { data } = await http.post(apiEndpoint + "/login/verify-otp", payload, { headers: { loggedInFrom: "Fundfina" } });
    return data;
}

export async function getLoanDetailForAgent(payload = {}) {
    let params = [];
    if (payload) {
        Object.keys(payload).forEach(key => {
            params.push(`${key}=${payload[key]}`);
        });
    }
    const response = await http.get(`${apiEndpoint}/getLoanDetail?${params.join("&")}`);
    return response;
}

export async function generatePaymentLink(payload) {
    const { data } = await http.post(apiEndpoint + "/sendPaymentLink", payload);
    return data;
}


export async function uploadPaymentDocument(payload) {
    const { data } = await http.put(apiEndpoint + "/uploadPaymentDocument", payload);
    return data;
}


export async function cancelPaymentLink(payload) {
    const { data } = await http.post(apiEndpoint + "/cancelPaymentLink", payload);
    return data;
}

export async function resendPaymentLink(payload) {
    const { data } = await http.post(apiEndpoint + "/resendPaymentLink", payload);
    return data;
}

export async function sendAgentIdentity(payload) {
    const { data } = await http.post(apiEndpoint + "/sendAgentIdentity", payload);
    return data;
}

export async function sendEngagementAppLink(payload) {
    const { data } = await http.post(`${apiEndpoint}/sendEngagementAppLink`, payload);
    return data;
}

export async function getCollectionLogs(payload = {}) {
    let params = [];
    if (payload) {
        Object.keys(payload).forEach(key => {
            params.push(`${key}=${payload[key]}`);
        });
    }
    const response = await http.get(`${apiEndpoint}/agentCollectionList?${params.join("&")}`);
    return response;
}



export default {
    sendLoginOtp,
    verifyLoginOtp,
    getLoanDetailForAgent,
    generatePaymentLink,
    cancelPaymentLink,
    resendPaymentLink,
    uploadPaymentDocument,
    sendEngagementAppLink,
    getCollectionLogs,
    sendAgentIdentity
};