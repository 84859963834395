import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class LoanTableModel extends InputDataForm {
  state = {
    data: {
      loanStatus: "",
      fromDate: momentJalaali().startOf('month'),
      toDate: momentJalaali(),
      mobileNo: "",
      name: "",
      loanNo: "",
      loans: [],
      merchantCode: "",
      panNo: "",
      partner: "all",
      postDisbursalStatus: "",
      tab : "All",
      page : 1,
      fromTeleCalling : true,
      email : "",
      pincode : "",
      city : "",
      state  :""
    },
    showSpinner: false,
    errors: {},
    currentPage: 1,
    pageSize: 10,
    loanStatusList: [],
    lastStepList : [],
    partners: [],
    evaluatorRole: "",
    tabs : [
      { title :"All" },
      { title :"Due Today" },
      { title :"Up Coming" },
      { title : "Called"},
      { title : "Not Connected" }
    ],
    headerTabs : [
      {
        title : "Applications"
      },
      {
        title : "Renewals"
      }
    ],
    activeTab : "All",
    allLoans : [],
    downloadExcel : false,
    headerActiveTab : "Applications"
  };
  schema = {
    fromDate: Joi.any(),
    toDate: Joi.any(),
    mobileNo: Joi.any(),
    name: Joi.any(),
    loanStatus: Joi.any(),
    loanNo: Joi.any(),
    loans: Joi.any(),
    partner: Joi.any(),
    panNo: Joi.any(),
    merchantCode: Joi.any(),
    postDisbursalStatus: Joi.any(),
    lastStepCompleted : Joi.any(),
    page : Joi.any(),
    tab : Joi.any(),
    email : Joi.any(),
    fromTeleCalling :Joi.any(),
    pincode : Joi.any(),
    state : Joi.any(),
    city : Joi.any(),
    headerActiveTab : Joi.any()
  };
  async loadDropdowns() {
    let { loanStatusList } = this.state;
    try {
      const {
        data: loanListResponse,
      } = await commonService.getRoleWiseDefinitionList("teleCallingLoanStatus");

      loanStatusList = loanListResponse.data;

      this.setState({
        loanStatusList,
      });
    } catch (ex) { }
  };
  async getLoanList(allLoans) {
    
    this.state.data.page = this.state.currentPage
    
    const data = { ...this.state.data };

    if(allLoans)
      data.isAllLoans = true
      
    this.setState({ showSpinner: true });
    try {
      const { data: loanList, totalPages } = await loanService.getLoanListForTeleCalling(data);

      if(allLoans){
        this.setState({allLoans : loanList , showSpinner : false , downloadExcel : true}, ()=>{
          this.setState({downloadExcel : false})
        })
        return
      }

      data.loans = loanList;

      this.setState({ data, showSpinner: false, pageSize : totalPages });
    } catch (ex) {
      this.setState({ data, showSpinner: false, });
    }
  };

  async loadLastStepDropdowns() {
    let { lastStepList } = this.state;
    try {
      const {
        data: lastStepListResponse,
      } = await commonService.getRoleWiseDefinitionList("kycSteps");

      lastStepList = lastStepListResponse.data;

      this.setState({
        lastStepList,
      });
    } catch (ex) { }
  };

  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    let { data } = this.state;

    const currentUser = auth.getCurrentUser();

    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    if (this.props.location.state) {
      data.merchantCode = this.props.location.state.merchantCode;

      this.setState({ data });
      await this.getLoanList();
      await this.loadDropdowns();
      await this.getBorroingPartners();
      await this.loadLastStepDropdowns()
    }
    else {
      await this.getLoanList();
      await this.loadDropdowns();
      await this.getBorroingPartners();
      await this.loadLastStepDropdowns()
    }
  };
  async getBorroingPartners() {
    try {
      const { data: partnerList } = await commonService.getBorrowingPartnerList();
      this.setState({ partners: partnerList });
    }
    catch (ex) {
      this.setState({ partners: [] });
    }
  };
  async handleCustomErrors(errors) {
    return errors;
  };
  async doSubmit() {
    this.resetPage()
    await this.getLoanList();
  };
  async handleSendReview(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendReview(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }
    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };
  async handleSendContract(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendLoanContract(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };
  async handSendBankLink(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendNewMandateOrUpdateBankListRequest(loanId, "BankDetails");

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  handleLoanStatusChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    if (input.value !== "" && input.value !== "Loan In Progress") {
      data["postDisbursalStatus"] = "";
    }
    this.setState({ data, errors });
  };

  resetPage =(callback)=>{
    this.state.data.page = 1
    this.setState({ currentPage :1  ,pageSize :1 },()=>{
      callback && callback()
    })
  }

  onChangeTab = (tab) =>{
    const data = { ...this.state.data };
    data['tab'] = tab
    this.setState({data, activeTab : tab  },()=>{
      this.resetPage(()=>{
        this.getLoanList()
      })
    })
  }

  handleLastStepListChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };


  onPageNext =()=> {

    if(this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage : this.state.currentPage + 1 },()=>{
      this.getLoanList()
    })
  }

  onPageChange =(page)=> {
    this.setState({ currentPage : page },()=>{
      this.getLoanList()
    })
  }

  onPagePrevious =()=> {

    if(this.state.currentPage <= 1) 
      return

    this.setState({ currentPage : this.state.currentPage - 1 },()=>{
      this.getLoanList()
    })
  }

  exportExcel =()=>{
    this.getLoanList(true)
  }

  onChangeHeaderActiveTab =(tab)=>{
    const data = { ...this.state.data };
    data['headerActiveTab'] = tab
    this.setState({data, headerActiveTab : tab  },()=>{

      if(tab == "Applications"){
        this.resetPage(()=>{
          this.state.activeTab = "All"
          this.state.data.tab = "All"
          this.getLoanList()
        })
      }
    })
  }
}

export default LoanTableModel;
