import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col, Button, Table, Modal, FormCheck } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import NotificationModel from "../../models/notification/notificationModel";

class Notification extends NotificationModel {
    render() {
        const { data, isExcelView, errors, errorMessage, successMessage, conditionsList, showSpinner, partnerList, showPopUp, popupMsg } = this.state;
        if (!auth.getCurrentUser()) return <Redirect to="/login" />;
        return (
            <React.Fragment>
                <SpinnerComponent showSpinner={showSpinner} />
                <h2>Offer Notifications</h2>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} md="6" controlId="switch">
                            <FormCheck
                                id="switchEnabled"
                                type="switch"
                                checked={isExcelView}
                                onChange={this.toggleSwitch}
                                label="Upload Excel"
                            />
                        </Form.Group>
                    </Form.Row>
                    {
                        !isExcelView ?
                            <Form.Row>
                                <Form.Group as={Col} md="4" controlId="partnerList">
                                    <Form.Label>Partners</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="selectedPartners"
                                        value={data["selectedPartners"]}
                                        multiple={data["selectedPartners"]}
                                        //onChange={e => setField([].slice.call(e.target.selectedOptions).map(item => item.value))}
                                        onChange={this.handleMultipleSelect}
                                    >
                                        {" "}
                                        {partnerList.map((item, i) => (
                                            <option key={i} value={item._id}>
                                                {item.displayName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="productType">
                                    <Form.Label>Product Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="selectedProductTypes"
                                        value={data["selectedProductTypes"]}
                                        multiple={data["selectedProductTypes"]}
                                        //onChange={e => setField([].slice.call(e.target.selectedOptions).map(item => item.value))}
                                        onChange={this.handleMultipleSelect}
                                    >
                                        {" "}
                                        <option key="TL" value="TL">TL</option>
                                        <option key="DL" value="DL">DL</option>
                                        <option key="CL" value="CL">CL</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="loanType">
                                    <Form.Label>Loan Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="selectedLoanTypes"
                                        value={data["selectedLoanTypes"]}
                                        multiple={data["selectedLoanTypes"]}
                                        //onChange={e => setField([].slice.call(e.target.selectedOptions).map(item => item.value))}
                                        onChange={this.handleMultipleSelect}
                                    >
                                        {" "}
                                        <option key="N" value="N">New</option>
                                        <option key="C" value="C">Callback</option>
                                        <option key="T" value="T">TopUp</option>
                                        <option key="R" value="R">Renewal</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            :
                            <Form.Row>  <Form.Group as={Col} md="4" controlId="uploadFile">
                                <Form.Group>
                                    <Form.Label>Upload Excel File</Form.Label>
                                    <Form.File
                                        label={data.fileName}
                                        onChange={this.onExcelFileChange}
                                        id="uploadFile"
                                        name="uploadFile"
                                        accept=".xlsx,.xls"
                                        key={data.fileName || ''}
                                        custom
                                    />{" "}
                                    {errors["uploadFile"] && (
                                        <div className="alert alert-danger">
                                            {errors["uploadFile"]}
                                        </div>
                                    )}
                                </Form.Group>
                            </Form.Group>
                            </Form.Row>
                    }
                </Form>
                {
                    !isExcelView &&
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>Criteria</th>
                                <th>Value</th>
                                <th>Operator</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {conditionsList &&
                                conditionsList.map((cList) => (
                                    <tr key={cList.id}>
                                        <td>{cList.criteria}</td>
                                        <td>{cList.value}</td>
                                        <td>{cList.operator}</td>
                                        <td>
                                            <Button onClick={() => this.handleRemoveConditions(cList.id)} className="ff-button">
                                                Remove
                                            </Button>

                                        </td>
                                    </tr>
                                ))}
                            <tr >
                                <td>
                                    <Form.Control
                                        as="select"
                                        name="criteria"
                                        onChange={this.handleChange}
                                        value={data["criteria"]}
                                    >
                                        {" "}
                                        <option key="select" value="">
                                            ---Select---
                                        </option>
                                        <option key="Amount" value="Amount">
                                            Amount
                                        </option>
                                        <option key="TrueScore" value="TrueScore">
                                            TrueScore
                                        </option>
                                        <option key="Interest Rate" value="Interest Rate">
                                            Interest Rate
                                        </option>
                                        <option key="Age" value="Age">
                                            Age
                                        </option>
                                    </Form.Control>
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        name="value"
                                        onKeyPress={this.handleKeyPressNumeric}
                                        onChange={this.handleChange}
                                        value={data["value"]}
                                        pattern="^(\d*\.)?\d+%$"
                                    />
                                </td>
                                <td> <Form.Control
                                    as="select"
                                    name="operator"
                                    value={data["operator"]}
                                    onChange={this.handleChange}
                                >
                                    {" "}
                                    <option key="select" value="">---Select---</option>
                                    <option key="=" value="=">=</option>
                                    <option key=">=" value=">=">{">="}</option>
                                    <option key="<=" value="<=">{"<="}</option>
                                    <option key=">" value=">">{">"}</option>
                                    <option key="<" value="<">{"<"}</option>
                                </Form.Control></td>
                                <td>
                                    <Button onClick={() => this.handleAddConditions()} className="ff-button">
                                        Add
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                }
                <Form>
                    <Form.Row>

                        <Form.Group as={Col} md="4" controlId="notificationType">
                            <Form.Label>Notification Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="selectedNotificationType"
                                value={data["selectedNotificationType"]}
                                onChange={this.handleChange}
                            >
                                <option key="Offer Notification" value="Offer Notification">Offer Notification</option>
                                <option key="Offer Expiry Notification" value="Offer Expiry Notification">Offer Expiry Notification</option>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="message">
                            {successMessage && (
                                <div className="alert alert-success">{successMessage}</div>
                            )}
                            {errorMessage && (
                                <div className="alert alert-danger">{errorMessage}</div>
                            )}
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12">
                            <Button onClick={() => this.handleOfferQualified()} className="ff-button">
                                Offer Qualified
                            </Button>{" "}
                            <Button onClick={() => this.handleSendNotifications()} className="ff-button">
                                Send Notifications
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
                <Modal show={showPopUp} onHide={this.handlePopupClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Qualified Offers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {popupMsg && popupMsg.map((item) => <div>
                            <div className='new-line'>{item.displayName} : {item.offers}</div>
                            <div className='new-line'>Eligible To Notify : {item.actualNotificationSendTo || 0}</div>
                        </div>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="ff-button" onClick={this.handlePopupClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        );
    }

}
export default Notification