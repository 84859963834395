import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import axios from "axios";
import _ from "lodash";
import * as portfolioService from "../../services/portfolio/portfolioService";
import Joi, { wait } from "joi-browser";
import * as commonService from "../../services/common/commonService";
import { getLoanDetailsForTeleCalling, updateTeleCallingActions } from "../../services/loan/loanService";
import { toast } from "react-toastify";
import * as loanApplicationServices from "../../services/loan/loanApplicationServices";
import * as updateEmailMobileService from "../../services/profile/updateAlternateEmailService";

class TeleCallingDetailsModel extends InputDataForm {
  state = {
    showSpinner: false,
    data: {
      callOutcome: "",
      borrowerResponse: "",
      note: "",
      callbackOn: "",
      willApplyOn: "",
      date: "",
      notConnectedReason: ""
    },
    callOutcomeList: [],
    borrowerResponseList: [],
    notConnectedReasonList: [],
    loan: {},
    userInfo: {},
    tabs: [
      {
        title: "Action",
        value: "ACTION"
      },
      {
        title: "History",
        value: "HISTORY"
      }
    ],
    activeTab: "ACTION",
    showDate: false,
    files: {
      pNachFile: "",
      pNachFileStream: "",
      pNachFileLabel: ""
    },
    showErrorModal: false,
    errorHeader: "",
    errorMessage: "",
    isDownloadPNachDisabled: false,
    isUploadPNachDisabled: false,
    isPNachSendDisabled: false,
    errors: {}
  };

  schema = {
    callOutcome: Joi.any(),
    borrowerResponse: Joi.any(),
    note: Joi.any(),
    callbackOn: Joi.any(),
    willApplyOn: Joi.any(),
    date: Joi.any(),
    notConnectedReason: Joi.any()
  };

  async updateLoanTeleCallingAction() {
    let loanId = this.props.loanId;

    if (!loanId)
      return

    if (!this.state.showDate && this.state.data.date) {
      this.state.data.date = ""
    }

    if (this.state.data.callOutcome == "Not Connected" && this.state.data.borrowerResponse) {
      this.state.data.borrowerResponse = ""
    }

    let isValid = this.validatePayload(this.state.data)

    if (!isValid)
      return

    this.setState({ showSpinner: true })
    try {
      let res = await updateTeleCallingActions(loanId, this.state.data)

      if (res && res.data && !res.data.success) {
        toast.error("Oops something went wrong");
        this.setState({ showSpinner: false })
        return
      }
      await this.getLoanDetails()
      this.resetPage()
      this.setState({ showSpinner: false })
    } catch (err) {
      this.setState({ showSpinner: false })
    }
  }

  resetPage = () => {

    let data = {
      callOutcome: "",
      borrowerResponse: "",
      note: "",
      callbackOn: "",
      willApplyOn: "",
      date: ""
    }
    this.setState({ data, reset: true }, () => {
      this.setState({ reset: false })
    })
  }

  async getLoanDetails() {
    let loanId = this.props.loanId;

    if (!loanId)
      return

    try {
      let loan = await getLoanDetailsForTeleCalling(loanId)
      loan = loan.data
      let data = { ...this.state.data }

      if (loan.note) {
        data.note = loan.note
        this.setState({ data })
      }
      this.setState({ loan, userInfo: loan && loan.userInfo ? loan.userInfo : {}, totalPhotoMatchAttempt: loan.totalPhotoMatchAttempt || 0, isDownloadPNachDisabled: loan.isPhysicalNachAvailable ? false : true, isUploadPNachDisabled: loan.isPhysicalNachAvailable ? false : true, isPNachSendDisabled: loan.isPhysicalNachAvailable ? false : true })
    } catch (err) {

    }
  }

  validatePayload = (payload) => {

    if (!payload.callOutcome) {
      toast.error("Please select call outcome");
      return false
    }

    if (payload.callOutcome == 'Connected Successfully' && !payload.borrowerResponse) {
      toast.error("Please select borrower response");
      return false
    }

    if (payload.borrowerResponse && (this.state.showDate) && !payload.date) {
      toast.error("Please select date");
      return false
    }

    if (payload.callOutcome == 'Not Connected' && !payload.notConnectedReason) {
      toast.error("Please select not connected reason");
      return false
    }


    return true
  }

  handleOnChangeNote = (e) => {

    let data = { ...this.state.data }
    data.note = e.target.value;
    this.setState({ data })
  }

  async getCallOutcomeList() {
    let { callOutcomeList } = this.state;
    try {
      const {
        data: callOutcomeListResponse,
      } = await commonService.getRoleWiseDefinitionList("callOutcome");

      callOutcomeList = callOutcomeListResponse.data;

      this.setState({
        callOutcomeList,
      });
    } catch (ex) { }
  };

  async getBorrowerResponseList() {
    let { borrowerResponseList } = this.state;
    try {
      const {
        data: borrowerListResponse,
      } = await commonService.getRoleWiseDefinitionList("borrowerResponse");

      borrowerResponseList = borrowerListResponse.data;

      this.setState({
        borrowerResponseList
      });
    } catch (ex) { }
  };

  async notConnectedReasonList() {
    let { notConnectedReasonList } = this.state;
    try {
      const {
        data: notConnectedReasonListResponse,
      } = await commonService.getRoleWiseDefinitionList("notConnectedReasons");

      notConnectedReasonList = notConnectedReasonListResponse.data;

      this.setState({
        notConnectedReasonList
      });
    } catch (ex) { }
  };

  onTabChange = (tab) => {
    this.setState({ activeTab: tab.value })
  }

  handleCalloutcomeChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    let index = null

    for (let i = 0; i < this.state.borrowerResponseList.length; i++) {

      if (this.state.borrowerResponseList[i].defination == input.value) {
        index = i;
      }
    }

    if (index != null) {
      let option = this.state.borrowerResponseList[index]

      if (option.showDate)
        this.state.showDate = true
      else
        this.state.showDate = false
    }

    this.setState({ data, errors });
  }

  handleNotConnectedReasonChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  }

  async componentDidMount() {
    console.log('user....', auth.getCurrentUser())
    if (!auth.getCurrentUser()) return (window.location = "/login");

    await this.getLoanDetails()
    await this.getCallOutcomeList()
    await this.getBorrowerResponseList()
    await this.notConnectedReasonList()
  }

  async doSubmit() {
    await this.updateLoanTeleCallingAction()
  }

  async handleCustomErrors() {
  }

  onChangePhotoMatchAttempt(value) {
    if (value) value = +value
    if (value < 0 || value > 3) return
    this.setState({ totalPhotoMatchAttempt: +value })
  }

  async onUpdatePhotoMatch(loanId) {

    let payload = {
      loanId,
      totalPhotoMatchAttempt: this.state.totalPhotoMatchAttempt || 0
    }

    const errors = { ...this.state.errors };

    this.setState({ showSpinner: true });
    errors["photoMatchError"] = null;
    try {
      let response = await loanApplicationServices.updateLoanMeta(payload);

      if (response.success) {
        this.setState({ showSpinner: false, photoMatchSuccess: response.message, errors }, () => {

          setTimeout(() => {
            this.setState({ photoMatchSuccess: "" })
          }, 3000)
        });
      } else {
        errors["photoMatchError"] = response.message;
        this.setState({ showSpinner: false, errors, photoMatchSuccess: null });
      }
    } catch (ex) {
      errors["photoMatchError"] = ex.message;
      this.setState({ showSpinner: false, errors, photoMatchSuccess: null });
    }
  };


  async handleSendReview(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendReview(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }
    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  async handleSendContract(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendLoanContract(loanId);

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  async handSendBankLink(loanId) {
    this.setState({ showSpinner: true });
    const errors = { ...this.state.errors };
    errors["globalError"] = null;
    try {
      let response = await loanApplicationServices.sendNewMandateOrUpdateBankListRequest(loanId, "BankDetails");

      if (response.success) {
        this.setState({ showSpinner: false, successMsg: response.message, errors });
      } else {
        errors["globalError"] = response.message;
        this.setState({ showSpinner: false, errors, successMsg: null });
      }

    } catch (ex) {
      errors["globalError"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null });
    }
  };

  handleChangeCheckbox = (callOutcome, value) => {

    if (this.state.showDate) {
      this.state.showDate = false
    }

    let data = { ...this.state.data }
    data.callOutcome = callOutcome
    this.setState({ data })
  }
  async generatePhysicalNachFile(loanId) {
    this.setState({ showSpinner: true, isDownloadPNachDisabled: true });
    const errors = { ...this.state.errors };
    errors["pNachFileDownload"] = null;

    try {
      const nachResponse = await updateEmailMobileService.generatePhysicalNachFile(loanId);

      if (nachResponse.success) {
        this.setState({ showSpinner: false, successMsg: nachResponse.message, errors, isDownloadPNachDisabled: false });

        const url = await this.getSignedURLToDownload(nachResponse.data.pNachPath);

        axios({
          url: url,
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nachResponse.data.pNachFileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
      else {
        errors["pNachFileDownload"] = nachResponse.message;
        this.setState({ showSpinner: false, errors, successMsg: null, isDownloadPNachDisabled: false });
      }
    } catch (ex) {
      errors["pNachFileDownload"] = ex.message;
      this.setState({ showSpinner: false, errors, successMsg: null, isDownloadPNachDisabled: false });
    }
  }
  async uploadPhysicalNachFile(loanId) {

    const errors = { ...this.state.errors };
    const files = { ...this.state.files };
    errors["pNachFile"] = null;
    try {

      if (!files.pNachFile) {
        errors["pNachFile"] = "Please upload physical NACH document";
        this.setState({ errors });
        return;
      }

      if (files.pNachFile.split('.').pop() !== "jpg" && files.pNachFile.split('.').pop() !== "jpeg" && files.pNachFile.split('.').pop() !== "png") {
        errors["pNachFile"] = "File should be an image";
        this.setState({ errors });
        return;
      }

      this.setState({ showSpinner: true, isUploadPNachDisabled: true, errors });

      const response = await updateEmailMobileService.uploadPhysicalNachFile(loanId, files);

      if (response.success)
        this.setState({ showSpinner: false, errorMessage: response.message, errors, errorHeader: "Success!", showErrorModal: true, isUploadPNachDisabled: true, isDownloadPNachDisabled: true, isPNachSendDisabled: true });
      else {
        errors["pNachFile"] = response.message;
        this.setState({ showSpinner: false, errors, isUploadPNachDisabled: false });
      }
    } catch (ex) {
      errors["pNachFile"] = ex.message;
      this.setState({ showSpinner: false, errors, isUploadPNachDisabled: false });
    }
  }
  async sendPhysicalNachLink(loanId) {
    this.setState({ showSpinner: true, isPNachSendDisabled: true });
    const errors = { ...this.state.errors };
    errors["pNachFileSend"] = null;

    try {
      const nachResponse = await updateEmailMobileService.sendPhysicalNACHLink(loanId);

      if (nachResponse.success) {
        this.setState({ showSpinner: false, errorMessage: nachResponse.message, errors, errorHeader: "Success!", showErrorModal: true, isPNachSendDisabled: false });
      }
      else {
        errors["pNachFileSend"] = nachResponse.message;
        this.setState({ showSpinner: false, errors, isPNachSendDisabled: false });
      }
    } catch (ex) {
      errors["pNachFileSend"] = ex.message;
      this.setState({ showSpinner: false, errors, isPNachSendDisabled: false });
    }
  }
  closeModal = () => {
    this.setState({
      showErrorModal: false
    });
  };
}

export default TeleCallingDetailsModel;
