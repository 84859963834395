import http from "../common/httpService";
import { apiUrl } from "../../config.json";
import { getIpAddress } from "../common/commonService";
import moment from "moment";

const apiEndpoint = apiUrl + "/ops/";

export async function checkUserName(userName) {
  const { data } = await http.post(apiEndpoint + "verification/checkUserName", {
    userName,
  });
  return data;
}

export async function checkEmail(emailId) {
  const { data } = await http.post(apiEndpoint + "verification/checkEmail", {
    emailId,
  });
  return data;
}

export async function checkMobile(mobileNo) {
  const { data } = await http.post(apiEndpoint + "verification/checkMobile", {
    mobileNo,
  });
  return data;
}

export async function checkMobileAndSendOTP(mobileNo , from) {
  const { data } = await http.post(
    apiEndpoint + "verification/checkMobileOTP",
    {
      mobileNo,
      from
    }
  );
  return data;
}

export async function authenticateOTP(mobileNo, otp , from) {
  const { data } = await http.post(
    apiEndpoint + "verification/authenticateOTP",
    {
      mobileNo,
      otp,
      from
    }
  );
  return data;
}

export async function sendOTP(mobileNo) {
  const { data } = await http.post(apiEndpoint + "verification/sendOTP", {
    mobileNo,
  });
  return data;
}

export async function registerUser({
  firstName,
  middleName,
  lastName,
  email,
  mobileNo,
  password,
  partnerName,
  dob,
  userName,
  otp
}) {
  const { data } = await http.post(apiEndpoint + "users", {
    firstName: firstName.trim(),
    middleName: "",
    lastName: lastName.trim(),
    emailId: email.trim(),
    mobileNo: mobileNo.trim(),
    password: password.trim(),
    partnerName,
    userName: email.trim(),
    //dob: moment(dob).format("DD/MM/yyyy"),
    ipAddress: await getIpAddress(),
    otp
  });
  return data;
}

export async function getPartnerUser(partnerId) {
  const { data } = await http.get(
    apiEndpoint + `partner/${partnerId}`
  );
  return data;
}

export default {
  checkUserName,
  checkEmail,
  checkMobileAndSendOTP,
  authenticateOTP,
  sendOTP,
  registerUser,
  getPartnerUser
};
