import Joi from "joi-browser";
import InputDataForm from "../../components/common/form";
import * as forgotPasswordFormService from "../../services/login/forgotPasswordFormService";
import * as analytics from "../../utils/analytics";
import * as commonUtils from '../../utils/common-utils';

class ForgotPasswordModel extends InputDataForm {
  state = {
    data: {
      userId: "",
      userName: "",
      otp: "",
      password: "",
      confirmPassword: "",
    },
    errors: {},
    info: {},
    buttonText: "Verify",
    showSpinner: false,
    isValidUserName: false,
    isValidOTP: false,
    isPasswordChanged: false,
    isRegenerateLink: false,
    isRegenerateText: false,
    counter: 0,
    timerId: 0,
    timer: "",
  };
  schema = {
    userId: "",
    userName: Joi.string().required(),
    otp: Joi.string().required(),
    password: Joi.string().min(8).required(),
    confirmPassword: Joi.string().min(8).required(),
  };
  async handleCustomErrors(errors) {
    return errors;
  }
  componentDidMount() {
    analytics.track();
  }
  sendOtp = async () => {
    const { data, errors, info } = this.state;

    if (!data.userName) {
      errors.userName = "Please enter username / email";
      this.setState({ errors });
      return;
    } else {
      delete errors["userName"];
      this.setState({ errors });
    }

    this.setState({ showSpinner: true });

    try {
      const {
        data: userId,
        success,
        message,
      } = await forgotPasswordFormService.generateOTPFromUsername(
        data.userName,
        "Change Password"
      );

      if (success) {
        info["otp"] = message;
        delete errors["userName"];
        data.userId = userId;
        this.setState({
          data,
          info,
          errors,
          showSpinner: false,
          isValidUserName: true,
          isValidOTP: false,
          buttonText: "Validate OTP",
        });
        this.startTimer();
      } else {
        errors["userName"] = message;
        this.setState({
          errors,
          showSpinner: false,
          isValidUserName: false,
          isValidOTP: false,
        });
      }
    } catch (ex) {
      errors["userName"] = ex.response.data.message;
      delete info["otp"];
      this.setState({
        errors,
        info,
        showSpinner: false,
        isValidUserName: false,
        isValidOTP: false,
      });
    }
  };
  handleOTPChange = async () => {
    const { data, errors, info } = this.state;

    if (data.otp.length !== 6 || isNaN(data.otp)) {
      errors["otp"] = "Invalid OTP";
      this.setState({ errors });
      return;
    } else {
      delete errors["otp"];
      this.setState({ errors });
    }
    this.setState({ showSpinner: true });

    try {
      const {
        success,
        message,
      } = await forgotPasswordFormService.verifyCustomOTP(
        data.userId,
        data.otp,
        "Change Password",
        data.userName
      );

      if (success) {
        delete errors["otp"];
        info["otp"] = "OTP Matched";
        this.stopTimer();
        this.setState({
          errors,
          data,
          isValidOTP: true,
          showSpinner: false,
          buttonText: "Submit",
          isRegenerateLink: false,
          isRegenerateText: false,
        });
      } else {
        errors["otp"] = message;
        delete info["otp"];
        this.setState({ errors, data, isValidOTP: false, showSpinner: false });
      }
    } catch (ex) {
      this.stopTimer();
      this.startTimer();
      errors["otp"] = ex.response.data.message;
      delete info["otp"];
      this.setState({ data, errors, isValidOTP: false, showSpinner: false });
    }
  };
  changePassword = async () => {
    let { data, errors } = this.state;

    if (!data.password) {
      errors.password = "Please enter password";
      this.setState({ errors });
      return;
    } else {
      delete errors["password"];
      this.setState({ errors });
    }

    if (data.password.trim().length < 8) {
      errors.password = "Password must be atleast 8 characters long";
      this.setState({ errors });
      return;
    } else {
      delete errors["password"];
      this.setState({ errors });
    }

    // if (!errors["password"] && data.password) {
    //   if (this.checkSpecialCharacters(data.password)) delete errors["password"];
    //   else {
    //     errors["password"] = "Password must contain a special character";
    //     return;
    //   }
    // } else {
    //   delete errors["password"];
    //   this.setState({ errors });
    // }

    if (!errors["password"] && data.password) {
      if (commonUtils.checkPassword(data.password)) delete errors["password"];
      else {
        errors["password"] = `Password should contain Minimum 8 Characters, Combination of Uppercase and Lowercase, Numbers and Special Characters`
        return;
      };
    } else {
      delete errors["password"];
      this.setState({ errors });
    }

    if (!data.confirmPassword) {
      errors.confirmPassword = "Please re-enter password";
      this.setState({ errors });
      return;
    } else {
      delete errors["confirmPassword"];
      this.setState({ errors });
    }

    if (
      data.password.trim().toLowerCase() !==
      data.confirmPassword.trim().toLowerCase()
    ) {
      errors.confirmPassword = "Password do not match";
      this.setState({ errors });
      return;
    } else {
      delete errors["confirmPassword"];
      this.setState({ errors });
    }

    this.setState({ showSpinner: true });

    try {
      const {
        success,
        message,
      } = await forgotPasswordFormService.changePassword(
        data.userId,
        data.password.trim(),
        data.otp,
        data.userName
      );

      if (success) {
        errors = {};
        this.setState({
          showSpinner: false,
          errors,
          isPasswordChanged: true,
        });
      } else {
        errors["globalError"] = message;
        this.setState({ errors, showSpinner: false });
      }
    } catch (ex) {
      let errors = { ...this.state.errors };
      errors.globalError = ex.response.data.message;
      this.setState({ errors, showSpinner: false });
    }
  };
  doSubmit = async (e) => {
    e.preventDefault();

    let { isValidOTP, isValidUserName } = this.state;

    if (!isValidUserName) await this.sendOtp();
    else if (!isValidOTP) await this.handleOTPChange();
    else if (isValidOTP && isValidUserName) await this.changePassword();
  };
}

export default ForgotPasswordModel;
