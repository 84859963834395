import InputDataForm from "../../components/common/form";
import * as loanService from "../../services/loan/loanService";
import auth from "../../services/common/authService";
import * as commonService from "../../services/common/commonService";
import momentJalaali from "moment-jalaali";
import Joi from "joi-browser";

class RollbackTableModel extends InputDataForm {
    state = {
        data: {
            fromDate: momentJalaali().startOf('month'),
            toDate: momentJalaali(),
            mobileNo: "",
            name: "",
            loanNo: "",
            loans: [],
            merchantCode: "",
            panNo: "",
            partner: "all",
            page: 1
        },
        showSpinner: false,
        errors: {},
        currentPage: 1,
        pageSize: 0,
        partners: [],
        evaluatorRole: "",
        selectedLoans: [],
        selectAll: false,
        showPopUp: false
    };
    schema = {
        fromDate: Joi.any(),
        toDate: Joi.any(),
        mobileNo: Joi.any(),
        name: Joi.any(),
        loanNo: Joi.any(),
        loans: Joi.any(),
        partner: Joi.any(),
        panNo: Joi.any(),
        merchantCode: Joi.any(),
        page: Joi.any()
    };
    async getLoanList() {

        this.state.data.page = this.state.currentPage

        const data = { ...this.state.data };
        this.setState({ showSpinner: true });
        try {
            const { data: loanList, totalPages } = await loanService.getRollbackLoanList(data);

            data.loans = loanList;

            this.setState({ data, showSpinner: false, pageSize: totalPages });
        } catch (ex) {
            this.setState({ data, showSpinner: false });
        }
    };
    async componentDidMount() {
        if (!auth.getCurrentUser()) return;

        const currentUser = auth.getCurrentUser();

        if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

        await this.getLoanList();
        await this.getBorroingPartners();
    };
    async getBorroingPartners() {
        try {
            const { data: partnerList } = await commonService.getBorrowingPartnerList();
            this.setState({ partners: partnerList });
        }
        catch (ex) {
            this.setState({ partners: [] });
        }
    };
    async handleCustomErrors(errors) {
        return errors;
    };
    async doSubmit() {
        this.resetPage()
        await this.getLoanList();
    };
    handleChecked = loanId => e => {
        const selectedLoans = this.state.selectedLoans;

        if (e.target.checked == false && selectedLoans.includes(loanId, 0)) {
            const index = selectedLoans.indexOf(loanId);
            if (index != -1) {
                selectedLoans.splice(index, 1);
            }
        } else if (e.target.checked == true && !selectedLoans.includes(loanId, 0)) {
            selectedLoans.push(loanId);
        }
        this.setState({ selectedLoans });
    }
    rollbackLoan = async () => {
        const selectedLoans = this.state.selectedLoans;
        const data = { ...this.state.data }
        const errors = { ...this.state.errors };
        this.setState({ showSpinner: true });
        errors["globalError"] = null;

        if (selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        } else {
            for (let i = 0; i < selectedLoans.length; i++) {
                const loan = data.loans.filter((x) => x._id == selectedLoans[i]);
                if (loan && loan.length > 0 && loan[0].status !== "Initiated Fund Transfer") {
                    i = selectedLoans.length;
                    errors["globalError"] = "Please select loans in Initiated Fund Transfer";
                    this.setState({ errors, showSpinner: false });
                }
            }
            try {
                if (errors["globalError"] == null) {
                    let result = await loanService.rollbackLoan(selectedLoans);
                    console.log("result", result);

                    if (result.success) {
                        await this.getLoanList();
                        this.setState({ errors, showSpinner: false, successMsg: result.message, selectAll: false, selectedLoans: [] });
                    } else {
                        errors["globalError"] = result.message;
                        this.setState({ errors, showSpinner: false });
                    }
                }
            }
            catch (ex) {
                errors["globalError"] = ex.message;
                this.setState({ errors, showSpinner: false });
            }

        }
    }
    onPageNext = () => {
        this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

        if (this.state.pageSize < this.state.currentPage)
            return

        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getLoanList();
        })
    }
    onPageChange = (page) => {
        this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

        this.setState({ currentPage: page }, () => {
            this.getLoanList();
        })
    }
    onPagePrevious = () => {
        this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

        if (this.state.currentPage <= 1)
            return

        this.setState({ currentPage: this.state.currentPage - 1 }, () => {
            this.getLoanList();
        })
    }
    resetPage = () => {
        this.state.data.page = 1
        this.setState({ currentPage: 1, pageSize: 0 })
    }
    handlePopupOpen = () => {
        const selectedLoans = this.state.selectedLoans;
        const errors = { ...this.state.errors };
        errors["globalError"] = null;
        if (selectedLoans.length === 0) {
            errors["globalError"] = "Please Select Loan";
            this.setState({ errors, showSpinner: false });
        }
        else
            this.setState({ showPopUp: true, errors });
    };
    handlePopupClose = () => {
        this.setState({ showPopUp: false });
    };
    handlePopupOk = async () => {
        this.setState({ showPopUp: false });
        await this.rollbackLoan();
    };
}

export default RollbackTableModel;
