import React from "react";
import UploadProfilePicFormModel from "../../models/profile/uploadProfilePicFormModel";
import auth from "../../services/common/authService";
import { Form, Col, Button, Modal, ListGroup } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import { Redirect } from "react-router-dom";

class UploadProfilePicForm extends UploadProfilePicFormModel {
  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    const { files, fileErrors, errors, showSpinner } = this.state;

    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} /> <br />
        <Form
          onSubmit={this.uploadPicture}
          autoCapitalize="on"
          autoComplete="off"
        >
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="profileFile">
              <Form.Label>Upload Profile Image*</Form.Label>
              <Form.File
                label={files["profileFileLabel"]}
                onChange={(e) => this.onFileChange(e, true, "PROFILE")}
                id="profileFile"
                name="profileFile"
                accept=".jpg,.png,.jpeg,.pdf"
                custom
              />{" "}
              {fileErrors["profileFile"] && (
                <div className="alert alert-danger">
                  {fileErrors["profileFile"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>

          <Button className="ff-button" type="submit">
            Upload
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}

export default UploadProfilePicForm;
