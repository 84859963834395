import InputDataForm from "../../components/common/form";
import * as analytics from "../../utils/analytics";
import Joi from "joi-browser";
import _ from "lodash";
import leadInfoService from "../../services/partner/leadInfoService";
import * as loanService from "../../services/borrow/loanFormService";
import auth from "../../services/common/authService";
class CreditLineModel extends InputDataForm {
    state = {
        data: {
            userId: "",
            loanId: "",
            date: "",
            invoiceId: "",
            amount: "",
            agentName: "",
            agentNumber: "",
            loanStatus: "",
            shortauthKey: ""
        },
        errors: {},
        showSpinner: false,
        isSubmitted: false,
        loanList: [],
        approvedCreditLimit: "",
        availableCreditLimit: "",
        currentPage: 1,
        pageSize: 5,
        oldInvoiceAmount: 0,
        validForSigning: false,
        isSigned: false,
        successMessage: "",
        errorMessage: ""
    };
    schema = {
        userId: Joi.string().required(),
        loanId: Joi.string().required(),
        date: Joi.string().required(),
        invoiceId: Joi.string().required(),
        amount: Joi.number().min(1).required().label("Invoice Amount"),
        agentName: Joi.string().allow("").required().label("Delivery Agent Name"),
        agentNumber: Joi.number().allow("").required().label("Delivery Agent Mobile Number"),
        loanStatus: Joi.string().required(),
        shortauthKey: Joi.string().required()
    };
    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");
        analytics.track();
        if (this.props.location.state.userData) {
            let {
                loanId, shortauthKey
            } = this.props.location.state.userData;

            let { data } = this.state;

            data.loanId = loanId;
            data.shortauthKey = shortauthKey;

            this.setState({ data });

            await this.getLoanData(loanId);
        }
    }
    async getLoanData(loanId) {
        let data = { ...this.state.data };
        let errors = { ...this.state.errors };

        this.setState({ showSpinner: true });

        if (!loanId) {
            errors["globalError"] = "Invalid Details provided";
            this.setState({
                errors,
                showSpinner: false,
            });
        } else {
            try {
                let response = await leadInfoService.getChildLoanDetails(
                    data
                );

                if (response.success) {
                    data.userId = response.data.userId;
                    data.loanId = loanId;
                    data.date = response.data.date;
                    data.invoiceId = response.data.invoiceId;
                    data.amount = response.data.amount;
                    data.agentName = response.data.agentName;
                    data.agentNumber = response.data.agentNumber;
                    data.loanStatus = response.data.status;

                    let isValidForSigning = false;

                    let successMessage = "";
                    let errorMessage = "";

                    if (response.data.eNachRequired && !response.data.eNachSigned)
                        errorMessage = "Please complete e-Nach process before loan consent";
                    else if (response.data.isSigned)
                        successMessage = "Loan consent has been provided";
                    else if (data.loanStatus === "Under Review" || data.loanStatus === "Approved By Admin" || data.loanStatus === "Accepted By Client")
                        errorMessage = "Loan not in stage for consent";
                    else if (data.loanStatus === "Loan Is Funding")
                        isValidForSigning = true;

                    this.setState({
                        data,
                        showSpinner: false,
                        approvedCreditLimit: response.data.approvedCreditLimit,
                        availableCreditLimit: response.data.availableCreditLimit,
                        loanList: response.data.loanList,
                        oldInvoiceAmount: response.data.amount,
                        isValidForSigning,
                        successMessage,
                        errorMessage,
                        isSigned: response.data.isSigned
                    });

                } else {
                    errors["globalError"] = response.message;
                    this.setState({
                        errors,
                        showSpinner: false,
                    });
                }
            } catch (ex) {
                alert(ex.message);
            }
        }
    }
    async handleCustomErrors() {
        const { data, oldInvoiceAmount } = this.state;
        let errors = { ...this.state.errors };

        delete errors["globalError"];

        if (data.agentNumber) {
            if (data.agentNumber.length !== 10)
                errors["agentNumber"] = "Invalid Mobile Number";
            else
                delete errors["agentNumber"];
        }
        else delete errors["agentNumber"];

        if (data.amount > oldInvoiceAmount) errors["amount"] = "Amount cannot be greater than Invoice amount";
        else delete errors["amount"];

        this.setState({ errors });

        return errors;
    }
    async submitContent() {
        const { data } = this.state;
        let errors = { ...this.state.errors };

        errors.globalError = "";

        if (data.loanId) {
            this.setState({ isSubmitted: true, showSpinner: true, errors });

            const response = await loanService.updateLoanAmount(data.loanId, data.amount);

            if (response.success)
                window.location = `/creditLineSummaryRedirect/${data.loanId}/${data.shortauthKey}/${data.agentName}/${data.agentNumber}`;
            else {
                errors.globalError = response.message;

                this.setState({ isSubmitted: false, showSpinner: false, errors });
            }
        }
        else {
            errors.globalError = "Loan Id cannot be empty";
            this.setState({ isSubmitted: false, showSpinner: false });
        }
    }
    doSubmit = async (e) => {
        let errors = await this.handleCustomErrors();

        if (_.isEmpty(errors)) this.submitContent();
    };
}

export default CreditLineModel;
