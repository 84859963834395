import InputDataForm from "../../components/common/form";
import * as manualQueue from "../../services/manualQueue/manualQueue";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import * as portfolioService from "../../services/portfolio/portfolioService";
import moment from "moment";

class ManualQueueLoanRepaymentsModel extends InputDataForm {
  state = {
    data: {
      utrNo: "",
      action: "LOAN_REPAYMENTS",
      id: "",
      loanNo: "",
      paidOnDate: null,
      channel :""
    },
    partners: [],
    errors: "",
    showSpinner: false,
    ediList: [],
    selectedItemHash: {},
    selectAll: false,
    channels: [
      {
        title: "CF_UPI",
        value: "CF_UPI"
      },
      {
        title: "FF_UPI",
        value: "FF_UPI"
      },
      {
        title: "VA",
        value: "VA"
      }
    ]
  };
  schema = {
    utrNo: Joi.any(),
    action: Joi.any(),
    id: Joi.any(),
    amount: Joi.any(),
    invoiceNo: Joi.any(),
    loanNo: Joi.any(),
    edis: Joi.any(),
    paidOnDate: Joi.any(),
    channel : Joi.any()
  };

  async componentDidMount() {

    let data = this.props.data;
    let stateData = this.state.data;
    if (data) {
      if (data._id)
        this.state.data.id = data._id;

      if (data.utrNo)
        this.state.data.utrNo = data.utrNo;

      if (data.amount)
        this.state.data.amount = data.amount;
    }
    this.setState({ stateData })
  };

  saveManualQueueAction = async () => {
    try {
      let payload = this.state.data;
      let edis = Object.keys(this.state.selectedItemHash);
      payload.edis = edis
      payload.paidOn = moment(payload.paidOnDate).format("MM/DD/YYYY")
      this.setState({ showSpinner: true })
      const response = await manualQueue.manualQueueAction(payload);
      toast.success("Settlement Updated Success")
      this.setState({ showSpinner: false });
      this.props.onSuccess && this.props.onSuccess();
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  getEdiList = async () => {

    let data = this.state.data;
    try {

      this.setState({ showSpinner: true })
      const {
        data: response,
      } = await portfolioService.getRepaymentDetails(null, this.props.showPaymentOptions ? true : false, data.loanNo);

      if (response && response.emiDetails) {
        let ediList = response.emiDetails.filter(item => item.rePaymentStatus == "Pending")
        this.setState({ showSpinner: false, ediList: ediList })
      }
    } catch (error) {
      this.setState({ showSpinner: false, ediList: [] })
    }
  }

  onPressGo = () => {
    this.getEdiList()
  }

  onPressClear = () => {
    this.setState({ ediList: [], selectedItemHash: {}, selectAll: false, paidOnDate: null })
  }

  onSelectEdis = (emiNumber, item) => {
    let selectedItemHash = this.state.selectedItemHash;

    if (selectedItemHash[emiNumber])
      delete selectedItemHash[emiNumber]
    else
      selectedItemHash[emiNumber] = item

    this.setState({ selectedItemHash })
  }

  toggleSelectAll = (value) => {
    let ediList = this.state.ediList;
    let selectedItemHash = this.state.selectedItemHash;
    if (value) {
      ediList.map(item => {
        selectedItemHash[item.emiNumber] = item
      })
    } else {
      selectedItemHash = {}
    }

    this.setState({ selectedItemHash, selectAll: value })
  }

  handleValueDateChange = (value) => {
    const { data } = this.state;

    data.paidOnDate = value;
    this.setState({ data });
  }

  calculateTotalAmount = () => {
    let selectedItemHash = this.state.selectedItemHash;
    console.log(selectedItemHash)

    let totalAmount = 0;
    let keys = Object.keys(selectedItemHash);
    keys.map(key => {
      totalAmount += selectedItemHash[key] && selectedItemHash[key].totalEmiAmount
    })

    return totalAmount;
  }

  onPressUpdate = () => {
    let totalAmount = this.calculateTotalAmount();

    if (this.state.data.amount != totalAmount) {
      toast.error("Transaction amount and selected Amount Not matching")
      return;
    }

    this.saveManualQueueAction()
  }

  handleChannelChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors }, () => {
    });
  };
}

export default ManualQueueLoanRepaymentsModel;
