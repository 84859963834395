import React from "react";
import SpinnerComponent from "../../components/common/spinner";
import { Form, Col, Button, Modal } from "react-bootstrap";
import ReviewFormModel from "../../models/borrow/reviewFormModel";
import auth from "../../services/common/authService";
import { Redirect } from "react-router-dom";
import Tnc from "../../views/companyInfo/tnc";
import PrivacyPolicy from "./../companyInfo/privacyPolicy";

class ReviewForm extends ReviewFormModel {
  render() {
    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    const {
      data,
      errors,
      showSpinner,
      isSubmitted,
      showTnc,
      showPrivacyPolicy,
      disabled
    } = this.state;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} /> <br />
        <p>1. Ready to Submit your Draft Loan Proposal?</p>
        <ol>
          <li>
            After clicking submit, our experts will review your proposal within
            one business day and come back with suggested interest rates. Read
            Fundfina's{" "}
            <React.Fragment>
              <a
                href="#"
                style={{ textDecoration: "underline" }}
                onClick={this.handleViewTnc}
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                href="#"
                style={{ textDecoration: "underline" }}
                onClick={this.handleViewPrivacyPolicy}
              >
                Privacy Policy
              </a>
            </React.Fragment>
          </li>
          <li>
            {" "}
            The feedback will be displayed in the box below, and you can make
            changes to the proposal based on this and resubmit the proposal
          </li>{" "}
          <li>
            Once we've approved any pending changes, we will set your loan
            campaign date
          </li>{" "}
          <li>
            Once the launch date is set you can begin active marketing to your
            network with a link of the loan page, investments can only start on
            campign date
          </li>{" "}
          <li>
            Once loan is full funded, we will execute your loan by providing you
            with loan contract for signing with schedule of payments
          </li>
        </ol>
        <p>2. Further Submission Comments for Admin Consideration</p>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="additionalDescription">
              <Form.Label>Additional Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="additionalDescription"
                value={data["additionalDescription"]}
                onChange={this.handleChange}
                disabled={disabled}
              />
              {errors["additionalDescription"] && (
                <div className="alert alert-danger">
                  {errors["additionalDescription"]}
                </div>
              )}
            </Form.Group>
          </Form.Row>
          {/* {isMobileDevice && ( */}
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label> Signature: Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={data["name"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
              {errors["name"] && (
                <div className="alert alert-danger">{errors["name"]}</div>
              )}
            </Form.Group>
          </Form.Row>
          {/* </Form.Row>)} */}

          <Button className="ff-button" type="button" style={{ marginRight: 10, width: 150 }} onClick={() => this.props.setActiveTab("activeTab", "questionairreTab")}>
            Back
          </Button>
          <Button className="ff-button" type="submit" disabled={isSubmitted} style={{ width: 150 }}>
            Submit
          </Button>
          <Form.Group controlId="globalError" style={{ marginTop: 30 }}>
            {errors["globalError"] && (
              <div className="alert alert-danger">{errors["globalError"]}</div>
            )}
          </Form.Group>
        </Form>
        <Modal
          size="lg"
          show={showTnc}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <Tnc />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={showPrivacyPolicy}
          onHide={this.handleClose}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <PrivacyPolicy />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ReviewForm;
