import InputDataForm from "../../components/common/form";
import auth from "../../services/common/authService";
import React from "react";
import { Redirect } from "react-router-dom";
import leadInfoService from "../../services/partner/leadInfoService";
class ContractRedirect extends InputDataForm {
  state = {
    redirectPath: false,
    state: null
  }
  async componentDidMount() {
    const loanId = this.props.match.params.id;

    if (!loanId)
      this.setState({ redirectPath: "/invalidData", state: { message: "Invalid parameters provided" } });
    else if (!auth.getCurrentUser()) {
      const result = await leadInfoService.getPartnerId(loanId);

      if (result.success)
        this.setState({ redirectPath: `/${result.data}/merchantAuthenticate`, state: { loanData: { loanId: loanId, partnerId: result.data, redirectionUrl: `/verifyLoanContract/${loanId}` } } });
      else
        this.setState({ redirectPath: "/invalidData", state: { message: result.message } });
    }
    else {
      // await auth.getMenu();
      this.setState({ redirectPath: `/verifyLoanContract/${loanId}` });
    }
  }
  render() {
    const { redirectPath, state } = this.state;
    return (
      redirectPath && (
        <Redirect
          to={{
            pathname: redirectPath,
            state
          }}
        />
      )
    );
  }
}
export default ContractRedirect;
