import React from "react";
import { Form, Col, Row, Image, Button, Modal, Tabs, Tab } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import ScrutinyInfoModel from "../../models/scrutiny/scrutinyInfoModel";
import { Link } from "react-router-dom";
import KycForm from "../borrow/kycForm";
import DatePicker from "react-datepicker2";
import moment from "moment";

class ScrutinyInfo extends ScrutinyInfoModel {
  render() {
    const {
      data,
      showSpinner,
      optionalDocumentList,
      errors,
      showMain,
      isSubmitted,
      bankDoc,
      bankDocDisplay,
      gstDoc,
      gstDocDisplay,
      panDoc,
      panDocDisplay,
      poaDoc,
      poaDocDisplay,
      panDocVerification,
      evaluatorRole,
      userData,
      edit,
      activeTab,
      poaVerificationDocList,
      reviewDocList,
      detailsMismatchList
    } = this.state; 
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <Tabs activeKey={activeTab} id="uncontrolled-tab-example" onSelect={this.handleSelect}>
          <Tab eventKey="scrutinyTab" title="Scrutiny">
            <br />
            <h2
              className="fundfinaColor"
              style={{ textDecoration: "underline", textAlign: "center" }}
            >
              KYC Scrutiny
            </h2>{" "}
            <Form>
              <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
                Basic Information
              </h5>{" "}
              {(data.userRole === "User" || data.userRole === "Admin") && (
                <Form.Group as={Row} controlId="name">
                  <Form.Label column lg="12">
                    Full Name:{"  "}
                    <span style={{ color: "black", textTransform: "uppercase" }}>
                      {data.fullName}
                    </span>
                  </Form.Label>
                </Form.Group>
              )}
              {data.userRole !== "User" && data.userRole !== "Admin" && (
                <Form.Group as={Row} controlId="entityName">
                  <Form.Label column lg="12">
                    Entity Name:{" "}
                    <span style={{ color: "black", textTransform: "uppercase" }}>
                      {data.partnerName}
                    </span>
                  </Form.Label>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Email Address:{" "}
                  <span style={{ color: "black" }}>{data.emailId}</span>
                </Form.Label>
                <Form.Label column sm="4">
                  Mobile Number:{" "}
                  <span style={{ color: "black" }}>{data.mobileNo}</span>
                </Form.Label>
              </Form.Group>{" "}
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="4">
                  Alternate Email:{" "}
                  <span style={{ color: "black" }}>{data.alternateEmail}</span>
                </Form.Label>
                <Form.Label column sm="4">
                  Alternate Mobile:{" "}
                  <span style={{ color: "black" }}>{data.alternateMobile}</span>
                </Form.Label>
              </Form.Group>
              {data.panNo && (
                <Form.Group as={Row} controlId="pan">
                  <Form.Label column sm="6">
                    PAN Number:{" "}
                    <span style={{ color: "black", textTransform: "uppercase" }}>
                      {evaluatorRole === "Admin" && data.panNo ? data.panNo : ""}
                      {evaluatorRole !== "Admin" && data.panNo ? data.panNo.substr(0, 3) + "XXXXXXX" : ""}
                    </span>{" "}

                    {panDocVerification && (panDocVerification.isDocVerified && panDocVerification.isNumberVerified && panDocVerification.isDocMatch) && (
                      <i className="fa fa-check" aria-hidden="true" style={{ color: "green", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>

                    )}

                    {panDocVerification && (!panDocVerification.isDocVerified && !panDocVerification.isNumberVerified && !panDocVerification.isDocMatch) && (
                      <i className="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>

                    )}

                    {panDocVerification && (!panDocVerification.isDocVerified && panDocVerification.isNumberVerified && panDocVerification.isDocMatch) && (
                      <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>
                    )}

                    {panDocVerification && (panDocVerification.isDocVerified && !panDocVerification.isNumberVerified && panDocVerification.isDocMatch) && (
                      <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>
                    )}

                    {panDocVerification && (panDocVerification.isDocVerified && panDocVerification.isNumberVerified && !panDocVerification.isDocMatch) && (
                      <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>
                    )}

                    {panDocVerification && (!panDocVerification.isDocVerified && !panDocVerification.isNumberVerified && panDocVerification.isDocMatch) && (
                      <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>
                    )}

                    {panDocVerification && (!panDocVerification.isDocVerified && panDocVerification.isNumberVerified && !panDocVerification.isDocMatch) && (
                      <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>
                    )}

                    {panDocVerification && (panDocVerification.isDocVerified && !panDocVerification.isNumberVerified && !panDocVerification.isDocMatch) && (
                      <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={this.showPanDocInfo}></i>
                    )}

                    {!panDocVerification && (
                      <i className="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em" }}></i>
                    )}

                    {data.panFilePath && (
                      <a href="#" onClick={() => this.openFileAsTab(data.panFilePath)}>
                        {" "}
                        View
                      </a>
                    )}
                  </Form.Label>
                </Form.Group>
              )}
              {(data.userRole == "User" || data.userRole == "Admin") &&
                data.poaDetails.map((item, i) => (
                  <React.Fragment key={`poaDetails-${i}`}>
                    <Form.Group as={Row} controlId="poa">
                      <Form.Label column sm="4">
                        POA Number:{" "}
                        <span style={{ color: "black" }}>
                          {item.poaNo}
                        </span>{" "}

                        {item.docVerification && (item.docVerification.isDocVerified && item.docVerification.isNumberVerified && item.docVerification.isDocMatch) && (
                          <i className="fa fa-check" aria-hidden="true" style={{ color: "green", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>

                        )}

                        {item.docVerification && (!item.docVerification.isDocVerified && !item.docVerification.isNumberVerified && !item.docVerification.isDocMatch) && (
                          <i className="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>

                        )}

                        {item.docVerification && (!item.docVerification.isDocVerified && item.docVerification.isNumberVerified && item.docVerification.isDocMatch) && (
                          <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>
                        )}

                        {item.docVerification && (item.docVerification.isDocVerified && !item.docVerification.isNumberVerified && item.docVerification.isDocMatch) && (
                          <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>
                        )}

                        {item.docVerification && (item.docVerification.isDocVerified && item.docVerification.isNumberVerified && !item.docVerification.isDocMatch) && (
                          <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>
                        )}

                        {item.docVerification && (!item.docVerification.isDocVerified && !item.docVerification.isNumberVerified && item.docVerification.isDocMatch) && (
                          <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>
                        )}

                        {item.docVerification && (!item.docVerification.isDocVerified && item.docVerification.isNumberVerified && !item.docVerification.isDocMatch) && (
                          <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>
                        )}

                        {item.docVerification && (item.docVerification.isDocVerified && !item.docVerification.isNumberVerified && !item.docVerification.isDocMatch) && (
                          <i className="fa fa-exclamation" aria-hidden="true" style={{ color: "orange", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showPoaDocInfo(item)}></i>
                        )}

                        {!item.docVerification && (
                          <i className="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em" }}></i>
                        )}

                        {item.docDetails.map(
                          (docItem, i) =>
                            docItem.poaFilePath && (
                              <a
                                href="#"
                                onClick={() => this.openFileAsTab(docItem.poaFilePath)}
                                key={`poa-${i}`}
                              >
                                <span>{` File ${i + 1}`}</span>
                              </a>
                            )
                        )}
                      </Form.Label>
                      <Form.Label column sm="4">
                        POA Type:{" "}
                        <span style={{ color: "black" }}>{item.poaType}</span>{" "}
                      </Form.Label>

                      <Form.Label column sm="4">
                        POA Verification Type:{" "}
                        <span style={{ color: "black" }}>{item.poaDocType}</span>{" "}
                      </Form.Label>
                    </Form.Group>
                    {item.docDetails && item.docDetails[0] && item.docDetails[0].poaDocPassword && (
                      <Form.Group as={Row} controlId="poaDocPassword">
                        <Form.Label column sm="4">
                          POA Document Password:{" "}
                          <span style={{ color: "black" }}>{item.docDetails[0].poaDocPassword}</span>{" "}
                        </Form.Label>
                      </Form.Group>)}
                  </React.Fragment>
                ))}
              {/* {data.userRole != "User" &&
                data.userRole != "Admin" &&
                data.udyogAadhaarNo && (
                  <Form.Group as={Row} controlId="udyogAadhaar">
                    <Form.Label column sm="6">
                      Udyog Aadhaar Number:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.udyogAadhaarNo}
                      </span>{" "}
                      {data.udyogAadhaarFilePath && (
                        <a
                          href="#"
                          onClick={() => this.openFile(data.udyogAadhaarFilePath)}
                        >
                          {" "}
                          View
                        </a>
                      )}
                    </Form.Label>
                  </Form.Group>
                )} */}
              {(data?.udyogAadhaar && data?.udyogAadhaar?.addressDetails) && <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Udhyog Details
                  </h5>
                  <React.Fragment >
                    <Form.Group as={Row} controlId="udyogAadhaarNo">
                      <Form.Label column sm="12">
                        Udyog Number:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyogAadhaar?.udyogAadhaarNo}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="address1">
                      <Form.Label column sm="4">
                        Name Of Premises:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyogAadhaar?.addressDetails?.nameOfPremises}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        City:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyogAadhaar?.addressDetails?.city}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        District:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyogAadhaar?.addressDetails?.district}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="address2">
                      <Form.Label column sm="4">
                        Pin:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyogAadhaar?.addressDetails?.pin}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        Road:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyogAadhaar?.addressDetails?.road}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        State:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyogAadhaar?.addressDetails?.state}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                  </React.Fragment>
                </React.Fragment>}
              {(data?.udyamAadhaar && data?.udyamAadhaar?.officialAddress) && <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Udyam Details
                  </h5>
                  <React.Fragment >
                    <Form.Group as={Row} controlId="udyamAadhaarNo">
                      <Form.Label column sm="12">
                        Udyam Number:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyamAadhaar?.udyamAadhaarNo}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="address1">
                      <Form.Label column sm="4">
                        Name Of Premises:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyamAadhaar?.officialAddress?.nameOfPremises}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        City:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyamAadhaar?.officialAddress?.city}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        District:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyamAadhaar?.officialAddress?.district}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="address2">
                      <Form.Label column sm="4">
                        Pin:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyamAadhaar?.officialAddress?.pin}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        Road:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyamAadhaar?.officialAddress?.road}
                        </span>{" "}
                      </Form.Label>
                      <Form.Label column sm="4">
                        State:{" "}
                        <span
                          style={{
                            color: "black",
                            textTransform: "uppercase",
                          }}
                        >
                          {data?.udyamAadhaar?.officialAddress?.state}
                        </span>{" "}
                      </Form.Label>
                    </Form.Group>
                  </React.Fragment>
                </React.Fragment>}
              <Form.Group as={Row} controlId="employment">
                {data.employment && (
                  <Form.Label column sm="4">
                    Employment:{" "}
                    <span style={{ color: "black" }}>{data.employment}</span>{" "}
                  </Form.Label>
                )}

                {data.kycStatus && (
                  <Form.Label column sm="4">
                    Kyc Status:{" "}
                    <span
                      style={{ color: data.kycStatusColor, fontWeight: "bold" }}
                    >
                      {data.kycStatus}
                    </span>{" "}
                  </Form.Label>
                )}
                {data.clientCode && (
                  <Form.Label column sm="4">
                    Client Code:{" "}
                    <span style={{ color: "black" }}>{data.clientCode}</span>{" "}
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group as={Row} controlId="role">
                {data.dob && (
                  <Form.Label column sm="4">
                    Date of Birth:{" "}
                    <span style={{ color: "black" }}>{data.dob}</span>{" "}
                  </Form.Label>
                )}
                {data.doi && (
                  <Form.Label column sm="4">
                    Date of Incorporation:{" "}
                    <span style={{ color: "black" }}>{data.doi}</span>{" "}
                  </Form.Label>
                )}
                {data.typeOfEntity && (
                  <Form.Label column sm="4">
                    Type of Entity:{" "}
                    <span style={{ color: "black" }}>{data.typeOfEntity}</span>{" "}
                  </Form.Label>
                )}
                {data.userRole && (
                  <Form.Label column sm="4">
                    User Role:{" "}
                    <span style={{ color: "black" }}>{data.userRole}</span>{" "}
                  </Form.Label>
                )}
              </Form.Group>
              {data.userRole == "User" && (
                <Form.Group as={Row} controlId="entityName">
                  <Form.Label column lg="8">
                    Initiated From:{" "}
                    <span style={{ color: "black", textTransform: "uppercase" }}>
                      {data.partnerName}
                    </span>
                  </Form.Label>
                  <Form.Label column lg="4">
                    Merchant Code:{" "}
                    <span style={{ color: "black", textTransform: "uppercase" }}>
                      {data.merchantCode}
                    </span>
                  </Form.Label>
                </Form.Group>
              )}
              {data.city && (
                <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Address Details
                  </h5>{" "}
                  <Form.Group as={Row} controlId="address">
                    <Form.Label column sm="12">
                      Address:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.fullAddress}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Row} controlId="city">
                    <Form.Label column sm="4">
                      City:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.city}
                      </span>{" "}
                    </Form.Label>
                    <Form.Label column sm="4">
                      State:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.state}
                      </span>{" "}
                    </Form.Label>
                    <Form.Label column sm="4">
                      Pincode:{" "}
                      <span style={{ color: "black", textTransform: "uppercase" }}>
                        {data.pincode}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                </React.Fragment>
              )}
              {data.bankList.length > 0 && (
                <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Bank Details
                  </h5>
                  {data.bankList.map((bank, i) => (
                    <React.Fragment key={`bank-${i}`}>
                      <Form.Group as={Row} controlId="bank">
                        <Form.Label column sm="12">
                          Bank Name:{" "}
                          <span
                            style={{ color: "black", textTransform: "uppercase" }}
                          >
                            {bank.bankName}
                          </span>{" "}
                          {bank.docVerification && bank.docVerification.isBankVerified && (
                            <i className="fa fa-check" aria-hidden="true" style={{ color: "green", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showBankDocInfo(bank)}></i>
                          )}

                          {(bank.docVerification && !bank.docVerification.isBankVerified) && (
                            <i className="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em", cursor: "pointer" }} onClick={() => this.showBankDocInfo(bank)}></i>
                          )}

                          {!bank.docVerification && (
                            <i className="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em" }}></i>
                          )}

                          {bank.bankFilePath && (
                            <a
                              href="#"
                              onClick={() => this.openFile(bank.bankFilePath)}
                            >
                              {" "}
                              View
                            </a>
                          )}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="branch">
                        <Form.Label column sm="12">
                          Branch Name:{" "}
                          <span
                            style={{ color: "black", textTransform: "uppercase" }}
                          >
                            {bank.branch}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="accountNo">
                        <Form.Label column sm="4">
                          Account No:{" "}
                          <span
                            style={{ color: "black", textTransform: "uppercase" }}
                          >
                            {bank.accountNo}
                          </span>{" "}
                        </Form.Label>
                        <Form.Label column sm="4">
                          IFSC Code:{" "}
                          <span
                            style={{ color: "black", textTransform: "uppercase" }}
                          >
                            {bank.ifsc}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>
                      <Form.Group as={Row} controlId="accountType">
                        <Form.Label column sm="4">
                          Account Type:{" "}
                          <span
                            style={{ color: "black", textTransform: "uppercase" }}
                          >
                            {bank.accountType}
                          </span>{" "}
                        </Form.Label>
                      </Form.Group>

                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
              {data.gstList.length > 0 && (
                <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    GST Details
                  </h5>
                  {data.gstList.map((gst, i) => (
                    <React.Fragment key={`gst-${i}`}>
                      <Form.Group as={Row} controlId="gstNo">
                        <Form.Label column sm="4">
                          Gst No:{" "}
                          <span
                            style={{ color: "black", textTransform: "uppercase" }}
                          >
                            {gst.gstNo}
                          </span>{" "}
                          {gst.docVerification && gst.docVerification.isGstVerified && (
                            <i class="fa fa-check" aria-hidden="true" style={{ color: "green", fontSize: "1.3em", cursor: "pointer" }}></i>

                          )}

                          {(gst.docVerification && !gst.docVerification.isGstVerified) && (
                            <i class="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em", cursor: "pointer" }}></i>
                          )}

                          {!gst.docVerification && (
                            <i class="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em" }}></i>
                          )}

                          {gst.gstFilePath && (
                            <a
                              href="#"
                              onClick={() => this.openFileAsTab(gst.gstFilePath)}
                            >
                              {" "}
                              View
                            </a>
                          )}
                        </Form.Label>
                      </Form.Group>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              )}
              
              {optionalDocumentList.length > 0 && (
                <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Additional Documents
                  </h5>{" "}
                  <Form.Group as={Row} controlId="additionalDocuments">
                    <Form.Label column sm="12">
                      {optionalDocumentList.map((file, i) => (
                        <React.Fragment>
                          <a
                            key={`file-${i}`}
                            href="#"
                            onClick={() => this.openFile(file.filePath, true)}
                            style={{
                              textDecoration: "underline",
                            }}
                            className="black"
                          >
                            {" "}
                            {file.fileName}
                          </a>{" "}
                          <br />
                        </React.Fragment>
                      ))}
                    </Form.Label>
                  </Form.Group>
                </React.Fragment>
              )}
              {data.userRole == "User" && (
                <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Questionnairre
                  </h5>{" "}
                  <Form.Group as={Row} controlId="questionnairre">
                    <Form.Label column sm="4">
                      WhatsApp in Mobile?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.whatsApp}
                      </span>{" "}
                    </Form.Label>
                    <Form.Label column sm="4">
                      Gender:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.gender}
                      </span>{" "}
                      {
                        this.state.genderError && <i class="fa fa-times" aria-hidden="true" style={{ color: "red", fontSize: "1.3em" }} ></i>
                      }

                    </Form.Label>
                    <Form.Label column sm="4">
                      Marital Status:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.maritalStatus}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Row} controlId="questionnairre">
                    <Form.Label column sm="4">
                      Have Children?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.children}
                      </span>{" "}
                    </Form.Label>
                    <Form.Label column sm="4">
                      Rent or own a home?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.homeOwnershipType}
                      </span>{" "}
                    </Form.Label>

                    <Form.Label column sm="4">
                      Own a vehicle?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.vehicle}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Row} controlId="questionnairre">
                    <Form.Label column sm="4">
                      Monthly Outgoings?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.monthlyOutgoing}
                      </span>{" "}
                    </Form.Label>

                    <Form.Label column sm="4">
                      Monthly Income?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.monthlyIncome}
                      </span>{" "}
                    </Form.Label>

                    <Form.Label column sm="4">
                      Public and/or Private Sector Job?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.jobType}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Row} controlId="questionnairre">
                    <Form.Label column sm="4">
                      Purchased TV/Fridge in past 1 year?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.purchasedInOneYear}
                      </span>{" "}
                    </Form.Label>

                    <Form.Label column sm="4">
                      Recommended By:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.recommendedBy}
                      </span>{" "}
                    </Form.Label>

                    <Form.Label column sm="4">
                      Highest Degree?:{" "}
                      <span style={{ color: "black" }}>
                        {data.questionnairre.degree}
                      </span>{" "}
                    </Form.Label>
                  </Form.Group>
                </React.Fragment>
              )}
              <h5 className="fundfinaColor" style={{ textDecoration: "underline" }}>
                Profile Picture
              </h5>{" "}
              <Form.Group as={Row} controlId="profilePic">
                <Form.Label column sm="12">
                  <Image
                    src={
                      data.profileFileSrc
                        ? data.profileFileSrc
                        : require("../../images/userProfile.png")
                    }
                    fluid
                    style={{ maxHeight: 100 }}
                  />{" "}
                </Form.Label>
              </Form.Group>
            </Form>
            {(data.kycStatus === "Under Review" || data.kycStatus === "Review") && (
              <Form onSubmit={this.doSubmit}>
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Scrutinize
                </h5>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="adminStatus">
                    <Form.Label>Select Action*</Form.Label>
                    <Form.Control
                      as="select"
                      name="adminStatus"
                      value={data["adminStatus"]}
                      onChange={this.handleChange}
                    >
                      <option key="select" value="">
                        ---Select---
                      </option>
                      <option key="approved" value="Approved">
                        Approve
                      </option>
                      <option key="rejected" value="Rejected">
                        Reject
                      </option>
                      {data.kycStatus !== "Review" && (
                        <option key="review" value="Review">
                          Review
                        </option>)
                      }
                    </Form.Control>
                    {errors["adminStatus"] && (
                      <div className="alert alert-danger">
                        {errors["adminStatus"]}
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>
                {(data.userRole === "User" && data.adminStatus === "Approved") && (
                  <>
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="poaDocType">
                        <Form.Label>POA Doc Type*</Form.Label>
                        <Form.Control
                          as="select"
                          name="poaDocType"
                          value={data["poaDocType"]}
                          onChange={this.handleChange}
                        >
                          <option key="select" value="">
                            ---Select---
                          </option>
                          {poaVerificationDocList.map((item, i) => (
                            <option key={i} value={item.defination}>
                              {item.defination}
                            </option>
                          ))}
                        </Form.Control>
                        {errors["poaDocType"] && (
                          <div className="alert alert-danger">
                            {errors["poaDocType"]}
                          </div>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="4" controlId="detailsMismatch">
                        <Form.Label>Details Mismatch*</Form.Label>
                        <Form.Control
                          as="select"
                          name="detailsMismatch"
                          value={data["detailsMismatch"]}
                          onChange={this.handleChange}
                        >
                          <option key="select" value="">
                            ---Select---
                          </option>
                          {detailsMismatchList.map((item, i) => (
                            <option key={i} value={item.defination}>
                              {item.defination}
                            </option>
                          ))}
                        </Form.Control>
                        {errors["detailsMismatch"] && (
                          <div className="alert alert-danger">
                            {errors["detailsMismatch"]}
                          </div>
                        )}
                      </Form.Group>
                    </Form.Row>
                    {(data.detailsMismatch === "Name" || data.detailsMismatch === "DOB" || data.detailsMismatch === "Name & DOB") && (
                      <Form.Row>
                        {(data.detailsMismatch === "Name" || data.detailsMismatch === "Name & DOB") && (
                          <>
                            <Form.Group as={Col} md="3" controlId="poiName">
                              <Form.Label>POI Name*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="POI Name"
                                name="poiName"
                                value={data["poiName"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                              {errors["poiName"] && (
                                <div className="alert alert-danger">
                                  {errors["poiName"]}
                                </div>
                              )}
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="poaName">
                              <Form.Label>POA Name*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="POA Name"
                                name="poaName"
                                value={data["poaName"]}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                              {errors["poaName"] && (
                                <div className="alert alert-danger">
                                  {errors["poaName"]}
                                </div>
                              )}
                            </Form.Group>
                          </>)}
                        {(data.detailsMismatch === "DOB" || data.detailsMismatch === "Name & DOB") && (
                          <>
                            <Form.Group as={Col} md="3" controlId="poiDob">
                              <Form.Label>POI DOB*</Form.Label>
                              <DatePicker
                                name="poiDob"
                                id="poiDob"
                                value={data["poiDob"]}
                                max={moment()}
                                selectedMonth={moment().subtract(100, "years").year}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleDob1Change(value)}
                                placeholder="POI DOB"
                              />
                              {errors["poiDob"] && (
                                <div className="alert alert-danger">
                                  {errors["poiDob"]}
                                </div>
                              )}
                            </Form.Group>
                            <Form.Group as={Col} md="3" controlId="poaDob">
                              <Form.Label>POA DOB*</Form.Label>
                              <DatePicker
                                name="poaDob"
                                id="poaDob"
                                value={data["poaDob"]}
                                max={moment()}
                                selectedMonth={moment().subtract(100, "years").year}
                                inputFormat="DD/MM/YYYY"
                                isGregorian={true}
                                className="form-control"
                                inputReadOnly={true}
                                showTodayButton={false}
                                timePicker={false}
                                onChange={(value) => this.handleDob2Change(value)}
                                placeholder="DOB 2"
                              />
                              {errors["poaDob"] && (
                                <div className="alert alert-danger">
                                  {errors["poaDob"]}
                                </div>
                              )}
                            </Form.Group>
                          </>
                        )}
                      </Form.Row>)}
                  </>
                )}
                {data.userRole === "User" && data.adminStatus === "Review" && (<Form.Row>
                  <Form.Group as={Col} md="4" controlId="reviewDoc">
                    <Form.Label>Issue with*</Form.Label>
                    <Form.Control
                      as="select"
                      name="reviewDoc"
                      value={data["reviewDoc"]}
                      onChange={this.handleChange}
                    >
                      <option key="select" value="">
                        ---Select---
                      </option>
                      {reviewDocList.map((item, i) => (
                        <option key={i} value={item.defination}>
                          {item.defination}
                        </option>
                      ))}
                    </Form.Control>
                    {errors["reviewDoc"] && (
                      <div className="alert alert-danger">
                        {errors["reviewDoc"]}
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>)}
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="remarks">
                    <Form.Label>Admin Comments</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="remarks"
                      value={data["remarks"]}
                      onChange={this.handleChange}
                    />
                    {errors["remarks"] && (
                      <div className="alert alert-danger">{errors["remarks"]}</div>
                    )}
                  </Form.Group>
                </Form.Row>
                <Button className="ff-button" type="submit" disabled={isSubmitted}>
                  Submit
                </Button>
                {errors["globalError"] && (
                  <div className="alert alert-danger">{errors["globalError"]}</div>
                )}
              </Form>
            )}

          </Tab>
          {data.userRole === "User" && edit && (
            <Tab eventKey="editKycTab" title="Edit KYC">
              {data.userRole === "User" && edit && (
                <KycForm
                  disabled={true}
                  userData={userData}
                  type="admin"
                />)}
            </Tab>)}
        </Tabs>
        <Modal
          show={bankDocDisplay}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.hideBankDocInfo}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="fundfinaColor">
              Bank Verification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
            <Form><Form.Row>
              {bankDoc.bankName && (
                <Form.Group as={Col} md="6" controlId="bank">
                  <Form.Label>Bank Name: <span style={{ color: "black" }}>{bankDoc.bankName}</span></Form.Label>
                </Form.Group>)}
              {bankDoc.branch && (
                <Form.Group as={Col} md="6" controlId="bank">
                  <Form.Label>Branch Name: <span style={{ color: "black" }}>{bankDoc.branch}</span></Form.Label>
                </Form.Group>)}
            </Form.Row>
              <Form.Row>
                {bankDoc.accountNo && (
                  <Form.Group as={Col} md="6" controlId="bank">
                    <Form.Label>Account No: <span style={{ color: "black" }}>{bankDoc.accountNo}</span></Form.Label>
                  </Form.Group>)}
                {bankDoc.ifsc && (
                  <Form.Group as={Col} md="6" controlId="bank">
                    <Form.Label>Ifsc Code: <span style={{ color: "black" }}>{bankDoc.ifsc}</span></Form.Label>
                  </Form.Group>)}
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="bank">
                  <Form.Label>Verified: <span style={{ color: bankDoc.isVerified == "Yes" ? "green" : "red", fontWeight: "bold" }}>{bankDoc.isVerified}</span></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="bank">
                  <Form.Label>Document: {bankDoc.docPath && (
                    <a
                      href="#"
                      onClick={() => this.openFile(bankDoc.docPath)}
                    >
                      {" "}
                      View
                    </a>
                  )}</Form.Label>
                </Form.Group>
              </Form.Row>
              {bankDoc.nameWithBank && (
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="bank">
                    <Form.Label>Name With Bank: <span style={{ color: "black" }}>{bankDoc.nameWithBank}</span></Form.Label>
                  </Form.Group>
                </Form.Row>)}
              {(bankDoc.code || bankDoc.message) &&
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Error Information
                </h5>}
              {bankDoc.code &&
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Error Code: <span style={{ color: "black" }}>{bankDoc.code}</span></Form.Label>
                  </Form.Group>
                </Form.Row>}
              {bankDoc.message &&
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Error Message: <span style={{ color: "black" }}>{bankDoc.message}</span></Form.Label>
                  </Form.Group>
                </Form.Row>}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideBankDocInfo}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={gstDocDisplay}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.hideGstDocInfo}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="fundfinaColor">
              GST Verification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>
            {
              gstDoc.gstDoc ? <>
                <div className="row text-center">
                  <div className={`col-12 text-center`}>
                    {
                      this.renderFilePreview(gstDoc.gstDoc)
                    }
                  </div>
                </div>
                <div className="border-dot-line">&nbsp; </div>
              </> : ""
            }

            <Form className="pt-4">
              {
                gstDoc.corporateName &&
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Corporate Name: <span style={{ color: "black" }}>{gstDoc.corporateName}</span></Form.Label>
                  </Form.Group>
                </Form.Row>}
              {gstDoc.legalName &&
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Legal Name: <span style={{ color: "black" }}>{gstDoc.legalName}</span></Form.Label>
                  </Form.Group>
                </Form.Row>
              }
              <Form.Row>
                {gstDoc.gstNo && (
                  <Form.Group as={Col} md="6" controlId="gst">
                    <Form.Label>GST No: <span style={{ color: "black" }}>{gstDoc.gstNo}</span></Form.Label>
                  </Form.Group>)}
                {/* <Form.Group as={Col} md="6" controlId="gst">
                  <Form.Label>Document: {gstDoc.docPath && (
                    <a
                      href="#"
                      onClick={() => this.openFile(gstDoc.docPath)}
                    >
                      {" "}
                      View
                    </a>
                  )}</Form.Label>
                </Form.Group> */}
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="gst">
                  <Form.Label>Verified:  <span style={{ color: gstDoc.isVerified == "Yes" ? "green" : "red", fontWeight: "bold" }}>{gstDoc.isVerified}</span></Form.Label>
                </Form.Group>
                {gstDoc.status && (
                  <Form.Group as={Col} md="6" controlId="gst">
                    <Form.Label>Status: <span style={{ color: "black" }}>{gstDoc.status}</span></Form.Label>
                  </Form.Group>)}
              </Form.Row>

              <Form.Row>
                {gstDoc.ctb && (
                  <Form.Group as={Col} md="6" controlId="gst">
                    <Form.Label>Constitution of Business: <span style={{ color: "black" }}>{gstDoc.ctb}</span></Form.Label>
                  </Form.Group>)}
                {gstDoc.dty && (
                  <Form.Group as={Col} md="6" controlId="gst">
                    <Form.Label>Type of Registration: <span style={{ color: "black" }}>{gstDoc.dty}</span></Form.Label>
                  </Form.Group>)}
              </Form.Row>
              <Form.Row>
                {gstDoc.rgdt && (
                  <Form.Group as={Col} md="6" controlId="gst">
                    <Form.Label>Valid From: <span style={{ color: "black" }}>{gstDoc.rgdt}</span></Form.Label>
                  </Form.Group>)}
              </Form.Row>
              {gstDoc.tradeName && (
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Trade Name: <span style={{ color: "black" }}>{gstDoc.tradeName}</span></Form.Label>
                  </Form.Group>
                </Form.Row>)}
              {gstDoc.address && (
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Address: <span style={{ color: "black" }}>{gstDoc.address}</span></Form.Label>
                  </Form.Group>
                </Form.Row>)}
              {(gstDoc.code || gstDoc.message) &&
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Error Information
                </h5>}
              {gstDoc.code &&
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Error Code: <span style={{ color: "black" }}>{gstDoc.code}</span></Form.Label>
                  </Form.Group>
                </Form.Row>}
              {gstDoc.message &&
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>Error Message: <span style={{ color: "black" }}>{gstDoc.message}</span></Form.Label>
                  </Form.Group>
                </Form.Row>}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideGstDocInfo}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={panDocDisplay}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.hidePanDocInfo}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="fundfinaColor">
              PAN Verification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>

            <div className="row text-center">
              <div className={`col-12 text-center`}>
                {
                  this.renderFilePreview(panDoc.panDoc)
                }
              </div>
            </div>
            <div className="border-dot-line">&nbsp; </div>

            <Form className="pt-4">
              <Form.Row>
                {panDoc.panNo && (
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>PAN Number: <span style={{ color: "black" }}>
                      {evaluatorRole === "Admin" && panDoc.panNo ? panDoc.panNo : ""}
                      {evaluatorRole !== "Admin" && panDoc.panNo ? panDoc.panNo.substr(0, 3) + "XXXXXXX" : ""}</span></Form.Label>
                  </Form.Group>)}
                {panDoc.type && (
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>Verification Type: <span style={{ color: "black", fontWeight: "bold" }}>{panDoc.type}</span></Form.Label>
                  </Form.Group>)}
                {panDoc.isNumberVerified && (
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>Number Verified: <span style={{ color: panDoc.isNumberVerified == "Yes" ? "green" : "red", fontWeight: "bold" }}>{panDoc.isNumberVerified}</span></Form.Label>
                  </Form.Group>)}
              </Form.Row>
              <Form.Row>
                {panDoc.isDocVerified && (
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>Document Verified: <span style={{ color: panDoc.isDocVerified == "Yes" ? "green" : "red", fontWeight: "bold" }}>{panDoc.isDocVerified}</span></Form.Label>
                  </Form.Group>)}
                {panDoc.isDocMatch && (
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>Document Match: <span style={{ color: panDoc.isDocMatch == "Yes" ? "green" : "red", fontWeight: "bold" }}>{panDoc.isDocMatch}</span></Form.Label>
                  </Form.Group>)}
                {/* {panDoc.docPath && (
                  <Form.Group as={Col} md="4" controlId="pan">
                    <Form.Label>Document: {panDoc.docPath && (
                      <a
                        href="#"
                        onClick={() => this.openFile(panDoc.docPath)}
                      >
                        {" "}
                        View
                      </a>
                    )}
                    </Form.Label>
                  </Form.Group>)} */}
              </Form.Row>
              {panDoc.isDocVerified == "Yes" &&
                <React.Fragment>
                  <h5
                    className="fundfinaColor"
                    style={{ textDecoration: "underline" }}
                  >
                    Information
                  </h5>{" "}
                  {panDoc.name && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="pan">
                        <Form.Label>Name: <span style={{ color: "black" }}>{panDoc.name}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {panDoc.fathers_name && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="pan">
                        <Form.Label>Father's Name: <span style={{ color: "black" }}>{panDoc.fathers_name}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  <Form.Row>
                    {panDoc.id_no && (
                      <Form.Group as={Col} md="4" controlId="pan">
                        <Form.Label>ID No: <span style={{ color: "black" }}>
                          {evaluatorRole === "Admin" && panDoc.id_no ? panDoc.id_no : ""}
                          {evaluatorRole !== "Admin" && panDoc.id_no ? panDoc.id_no.substr(0, 3) + "XXXXXXX" : ""}</span></Form.Label>
                      </Form.Group>)}
                    {panDoc.dob && (
                      <Form.Group as={Col} md="4" controlId="pan">
                        <Form.Label>DOB: <span style={{ color: "black" }}>{panDoc.dob}</span></Form.Label>
                      </Form.Group>)}
                  </Form.Row>
                </React.Fragment>}
              {(panDoc.api || panDoc.ocr) && (panDoc.api.code || panDoc.api.message || panDoc.ocr.code || panDoc.ocr.message) && (
                <h5
                  className="fundfinaColor"
                  style={{ textDecoration: "underline" }}
                >
                  Error Information
                </h5>)}
              {panDoc.api && panDoc.api.code && (
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>API Error Code: <span style={{ color: "black" }}>{panDoc.api && panDoc.api.code}</span></Form.Label>
                  </Form.Group>
                </Form.Row>)}
              {panDoc.api && panDoc.api.message && (
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>API Error Message: <span style={{ color: "black" }}>{panDoc.api && panDoc.api.message}</span></Form.Label>
                  </Form.Group>
                </Form.Row>)}
              {panDoc.ocr && panDoc.ocr.code && (
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>OCR Error Code: <span style={{ color: "black" }}>{panDoc.ocr && panDoc.ocr.code}</span></Form.Label>
                  </Form.Group>
                </Form.Row>)}
              {panDoc.ocr && panDoc.ocr.message && (
                <Form.Row>
                  <Form.Group as={Col} md="12" controlId="gst">
                    <Form.Label>OCR Error Message: <span style={{ color: "black" }}>{panDoc.ocr && panDoc.ocr.message}</span></Form.Label>
                  </Form.Group>
                </Form.Row>)}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hidePanDocInfo}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={poaDocDisplay}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={this.hidePoaDocInfo}
          className="custom-size-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="fundfinaColor">
              POA Verification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 500, overflowY: "scroll" }}>

            <div className="row text-center">
              <div className={`${poaDoc.poaBackDoc ? "col-6" : "col"}`}>
                {
                  this.renderFilePreview(poaDoc.poaDoc)
                }
              </div>
              {
                poaDoc.poaBackDoc ?
                  <div className="col-6">
                    {
                      this.renderFilePreview(poaDoc.poaBackDoc)
                    }
                  </div> : ""
              }
            </div>
            <div className="border-dot-line">&nbsp; </div>

            <Form className="pt-4">
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                Data Entered
              </h5>{" "}
              <Form.Row>
                <Form.Group as={Col} md="6" controlId="pan">
                  <Form.Label>POA Number: <span style={{ color: "black" }}>{poaDoc.poaNo}</span></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="pan">
                  <Form.Label>POA Type: <span style={{ color: "black", fontWeight: "bold" }}>{poaDoc.poaType}</span></Form.Label>
                </Form.Group>
              </Form.Row>
              {/* <Form.Row>
                <Form.Group as={Col} md="4" controlId="pan">
                  <Form.Label>Document: {poaDoc.poaDoc && (
                    <a
                      href="#"
                      onClick={() => this.openFile(poaDoc.poaDoc)}
                    >
                      {" "}
                      File 1
                    </a>
                  )}
                    {poaDoc.poaBackDoc && (
                      <a
                        href="#"
                        onClick={() => this.openFile(poaDoc.poaBackDoc)}
                      >
                        {" "}
                        File 2
                      </a>
                    )}
                  </Form.Label>
                </Form.Group>
              </Form.Row> */}
              <h5
                className="fundfinaColor"
                style={{ textDecoration: "underline" }}
              >
                Verification Information:
              </h5>{" "}
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="pan">
                  <Form.Label>Number Verified: <span style={{ color: poaDoc.docVerification && poaDoc.docVerification.isNumberVerified == "Yes" ? "green" : "red", fontWeight: "bold" }}>{poaDoc.docVerification && poaDoc.docVerification.isNumberVerified}</span></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="pan">
                  <Form.Label>Document Verified: <span style={{ color: poaDoc.docVerification && poaDoc.docVerification.isDocVerified == "Yes" ? "green" : "red", fontWeight: "bold" }}>{poaDoc.docVerification && poaDoc.docVerification.isDocVerified}</span></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="pan">
                  <Form.Label>Document Match: <span style={{ color: poaDoc.docVerification && poaDoc.docVerification.isDocMatch == "Yes" ? "green" : "red", fontWeight: "bold" }}>{poaDoc.docVerification && poaDoc.docVerification.isDocMatch}</span></Form.Label>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="pan">
                  <Form.Label>Type: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.type}</span></Form.Label>
                </Form.Group>
              </Form.Row>
              {poaDoc.docVerification && poaDoc.docVerification.docType && poaDoc.docVerification.docType == "DRIVING_LICENSE" && (
                <React.Fragment>
                  {poaDoc.docVerification.isDocVerified == "Yes" &&
                    <React.Fragment>
                      <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                      >
                        OCR Information:
                      </h5>{" "}
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.ocr && poaDoc.docVerification.dl.ocr.name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>ID No: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.ocr && poaDoc.docVerification.dl.ocr.id_no}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>ID Type: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.ocr && poaDoc.docVerification.dl.ocr.id_type}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>DOB: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.ocr && poaDoc.docVerification.dl.ocr.dob}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                    </React.Fragment>
                  }
                  {poaDoc.docVerification && (poaDoc.docVerification.isNumberVerified == "Yes" || poaDoc.docVerification.isDocVerified == "Yes" &&
                    <React.Fragment>
                      <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                      >
                        Data:
                      </h5>{" "}
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Holder's Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.Holders_Name}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Date of Issue: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.Date_Of_Issue}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>NT: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.NT}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Old_New_DL_No: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.Old_New_DL_No}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Hazardous_Valid_Till: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.Hazardous_Valid_Till}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>NonTransport: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.NonTransport}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Transport: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.Transport}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>CurrentStatus: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.CurrentStatus}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>LastTransactionAt: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification && poaDoc.docVerification.dl && poaDoc.docVerification.dl.verificationResponse && poaDoc.docVerification.dl.verificationResponse.LastTransactionAt}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                    </React.Fragment>)}
                  {(poaDoc.api || poaDoc.ocr) && (poaDoc.api.code || poaDoc.api.message || poaDoc.ocr.code || poaDoc.ocr.message) && (
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Error Information:
                    </h5>)}
                  {poaDoc.api && poaDoc.api.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Code: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.api && poaDoc.api.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Message: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Code: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Message: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                </React.Fragment>)}
              {poaDoc.docVerification && poaDoc.docVerification.docType && poaDoc.docVerification.docType == "PASSPORT" && (
                <React.Fragment>
                  {poaDoc.docVerification.type == "Ocr" &&
                    <React.Fragment>
                      <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                      >
                        OCR Information:
                      </h5>{" "}
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>ID No: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.id_no}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>ID Type: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.id_type}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>

                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>DOB: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.dob}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Gender: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.gender}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>

                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Country Code: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.country_code}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Surname: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.sur_name}</span>
                          </Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>

                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Nationality: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.nationality}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Place of Birth: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.place_of_birth}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Place of Issue: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.passport && poaDoc.docVerification.passport.ocr && poaDoc.docVerification.passport.ocr.place_of_issue}</span>
                          </Form.Label>
                        </Form.Group>
                      </Form.Row>
                    </React.Fragment>
                  }
                  {(poaDoc.api || poaDoc.ocr) && (poaDoc.api.code || poaDoc.api.message || poaDoc.ocr.code || poaDoc.ocr.message) && (
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Error Information
                    </h5>)}
                  {poaDoc.api && poaDoc.api.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Code: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.api && poaDoc.api.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Message: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Code: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Message: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                </React.Fragment>)}

              {poaDoc.docVerification && poaDoc.docVerification.docType && poaDoc.docVerification.docType == "AADHAAR" && (
                <React.Fragment>
                  {(poaDoc.docVerification.type === "Ocr" || poaDoc.docVerification.type === "digilocker") &&
                    <React.Fragment>
                      <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                      >
                        Information
                      </h5>{" "}
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>ID No: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.id_no}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>ID Type: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.id_type}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>DOB: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.dob}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="pan">
                          <Form.Label>Gender: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.gender}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                      </Form.Row>
                      {poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address && (
                        <Form.Row>
                          <Form.Group as={Col} md="12" controlId="pan">
                            <Form.Label>Address: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address}</span></Form.Label>
                          </Form.Group>
                        </Form.Row>)}
                      <Form.Row>
                        {poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.locality_or_post_office && (
                          <Form.Group as={Col} md="6" controlId="pan">
                            <Form.Label>Locality: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.locality_or_post_office}</span></Form.Label>
                          </Form.Group>)}
                        {poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.district_or_city && (
                          <Form.Group as={Col} md="6" controlId="pan">
                            <Form.Label>District: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.district_or_city}</span></Form.Label>
                          </Form.Group>)}
                      </Form.Row>
                      <Form.Row>
                        {poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.state && (
                          <Form.Group as={Col} md="6" controlId="pan">
                            <Form.Label>State: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.state}</span>
                            </Form.Label>
                          </Form.Group>)}
                        {poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.pincode && (
                          <Form.Group as={Col} md="6" controlId="pan">
                            <Form.Label>Pincode: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.uid && poaDoc.docVerification.uid.ocr && poaDoc.docVerification.uid.ocr.address_information && poaDoc.docVerification.uid.ocr.address_information.pincode}</span></Form.Label>
                          </Form.Group>)}
                      </Form.Row>
                    </React.Fragment>
                  }
                  {(poaDoc.api || poaDoc.ocr) && (poaDoc.api.code || poaDoc.api.message || poaDoc.ocr.code || poaDoc.ocr.message) && (
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Error Information
                    </h5>)}
                  {poaDoc.api && poaDoc.api.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Code: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.api && poaDoc.api.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Message: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Code: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Message: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                </React.Fragment>)}
              {poaDoc.docVerification && poaDoc.docVerification.docType && poaDoc.docVerification.docType == "VOTER_ID" && (
                <React.Fragment>
                  {poaDoc.docVerification.isDocVerified == "Yes" &&
                    <React.Fragment>
                      <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                      >
                        OCR Information:
                      </h5>{" "}
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.ocr && poaDoc.docVerification.voterId.ocr.name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Father's Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.ocr && poaDoc.docVerification.voterId.ocr.fathers_name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>Id No: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.ocr && poaDoc.docVerification.voterId.ocr.id_no}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>Document Type: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.ocr && poaDoc.docVerification.voterId.ocr.id_type}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>Gender: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.ocr && poaDoc.docVerification.voterId.ocr.gender}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>Issue date: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.ocr && poaDoc.docVerification.voterId.ocr.date_of_issue}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Address: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.ocr && poaDoc.docVerification.voterId.ocr.address}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                    </React.Fragment>
                  }
                  {poaDoc.docVerification && (poaDoc.docVerification.isNumberVerified == "Yes" || poaDoc.docVerification.isDocVerified == "Yes" &&
                    <React.Fragment>
                      <h5
                        className="fundfinaColor"
                        style={{ textDecoration: "underline" }}
                      >
                        Data:
                      </h5>{" "}
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Father's Name: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.rln_name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>Id No: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.id_no}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>Age: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.age}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>Gender: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.gender}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="12" controlId="pan">
                          <Form.Label>Address: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.part_name}</span></Form.Label>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>District: <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.dist_name}</span></Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="pan">
                          <Form.Label>State:  <span style={{ color: "black" }}>{poaDoc.docVerification && poaDoc.docVerification.voterId && poaDoc.docVerification.voterId.verificationResponse && poaDoc.docVerification.voterId.verificationResponse.st_name}</span></Form.Label>
                        </Form.Group></Form.Row>

                    </React.Fragment>)}
                  {(poaDoc.api || poaDoc.ocr) && (poaDoc.api.code || poaDoc.api.message || poaDoc.ocr.code || poaDoc.ocr.message) && (
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Error Information:
                    </h5>)}
                  {poaDoc.api && poaDoc.api.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Code: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.api && poaDoc.api.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Message: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Code: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Message: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                </React.Fragment>)}
              {poaDoc.docVerification && poaDoc.docVerification.docType == "" && (
                <React.Fragment>
                  {(poaDoc.api || poaDoc.ocr) && (poaDoc.api.code || poaDoc.api.message || poaDoc.ocr.code || poaDoc.ocr.message) && (
                    <h5
                      className="fundfinaColor"
                      style={{ textDecoration: "underline" }}
                    >
                      Error Information
                    </h5>)}
                  {poaDoc.api && poaDoc.api.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Code: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.api && poaDoc.api.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>API Error Message: <span style={{ color: "black" }}>{poaDoc.api && poaDoc.api.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.code && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Code: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.code}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                  {poaDoc.ocr && poaDoc.ocr.message && (
                    <Form.Row>
                      <Form.Group as={Col} md="12" controlId="gst">
                        <Form.Label>OCR Error Message: <span style={{ color: "black" }}>{poaDoc.ocr && poaDoc.ocr.message}</span></Form.Label>
                      </Form.Group>
                    </Form.Row>)}
                </React.Fragment>)}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hidePoaDocInfo}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.showGenderMismatchError}
          onHide={this.closeGenderModal}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "scroll" }}>
            <p>Gender Mismatch / Blank. Update it in Edit KYC tab and try to Approve again</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeGenderModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    );
  }
}

export default ScrutinyInfo;
