import http from "../common/httpService";
import { apiUrl, creditApiUrl } from "../../config.json";

const apiCreditEndpoint = creditApiUrl + "user";
const apiEndpoint = apiUrl + "/ops/user";

export async function submitMerchantShopTypeData(model) {
    let output = {
        success: false,
        message: "Internal Server Error"
    }

    try {
        console.log("model: ", model)
        const { data } = await http.post(`${apiEndpoint}/saveMerchantShopTypeData`,
            model
        );
        console.log("data: ", data);
        output.message = data.message;

        output.success = data.success;

    } catch (error) {
        console.log(error);
    }
    return output;
}

export async function validateMerchantPartnerMapping(model) {
    let output = {
        success: false,
        message: "Internal Server Error"
    }

    try {
        console.log("model: ", model)
        const { data } = await http.post(`${apiCreditEndpoint}/validateMerchantPartnerMapping`,
            model
        );
        console.log("data: ", data);
        output.message = data.message;

        output.success = data.success;

    } catch (error) {
        console.log(error);
    }

    // let output = {
    //     success: true,
    //     message: "Internal Server Error"
    // }

    return output;
}

export async function checkIfShopDataExists(model) {
    let output = {
        success: false,
        message: "Internal Server Error"
    }

    try {
        console.log("model: ", model)
        const { data } = await http.post(`${apiEndpoint}/checkIfShopDataExists`,
            model
        );
        console.log("data: ", data);
        output.message = data.message;

        output.success = data.success;

    } catch (error) {
        console.log(error);
    }

    // let output = {
    //     success: false,
    //     message: "Internal Server Error"
    // }

    return output;
}