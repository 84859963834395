import InputDataForm from "../../components/common/form";
import * as payInPayOutService from "../../services/payInPayOut/payInPayOutService";
import * as trancheInfoService from '../../services/trancheCRUD/trancheInfoService';
import auth from "../../services/common/authService";
import momentJalaali from "moment-jalaali";
import Joi, { errors } from "joi-browser";
import _ from "lodash";

class PayInPayOutInfoModel extends InputDataForm {
  state = {
    data: {
      //userId: "",
      partnerId: "",
      name: "",
      utrNo: "",
      paymentMode: "Payin",
      amount: "",
      transactionDate: momentJalaali(),
      depositDate: momentJalaali(),
    },
    showSpinner: false,
    errors: {},
    successMsg: "",
    isSubmitted: false,
    list: [],
    trancheList: [],
    editTrancheRecord: {},
    isTrancheRecordEdit: false,
    currentPage: 1,
    currenTrancheDetailsPage: 1,
    pageSize: 10,
    trancheListPageSize: 0,
    activeTab: "payInTab",
    confirmDelete:false,
    isFormEdit:false,
  };
  schema = {
    //userId: Joi.string().required(),
    partnerId: Joi.string().required(),
    name: Joi.string().required(),
    utrNo: Joi.string().required(),
    paymentMode: Joi.string().required(),
    amount: Joi.number().required().min(0),
    transactionDate: Joi.any().required(),
    depositDate: Joi.any().required(),
  };
  async handleCustomErrors(errors) {
    return errors;
  }

  handleSelect = (tab) => {
    
    if(tab=='trancheDetailsTab'){
      this.getUserTrancheData(this.state.data.partnerId);
    }
    this.setState({ activeTab: tab });
  }

  handleTrancheDetailsPageChange = async (page) => {
    // let {partnerId}=this.state.data.partnerId
    const data = {
      partnerId: this.state.data.partnerId,
      pageNumber: page
    }
    this.setState({ currenTrancheDetailsPage: page})
    const userTrancheDetails = await trancheInfoService.getUserTrancheRecords(data);
    this.setState({ trancheList: userTrancheDetails.data });
  };

  handleTrancheDetailsPagePrevious = async () => {
    let { currenTrancheDetailsPage } = this.state;
    const payload = {
      partnerId: this.state.data.partnerId,
      pageNumber: currenTrancheDetailsPage - 1
    }
    const userTrancheDetails = await trancheInfoService.getUserTrancheRecords(payload);
    this.setState({ currenTrancheDetailsPage: currenTrancheDetailsPage - 1, trancheList: userTrancheDetails.data });
  };

  handleTrancheDetailsPageNext = async () => {
    let { currenTrancheDetailsPage } = this.state;
    const payload = {
      partnerId: this.state.data.partnerId,
      pageNumber: currenTrancheDetailsPage + 1
    }
    const userTrancheDetails = await trancheInfoService.getUserTrancheRecords(payload);
    this.setState({ currenTrancheDetailsPage: currenTrancheDetailsPage + 1, trancheList: userTrancheDetails.data });
  };
  // async componentDidMount() {
  //   if (!auth.getCurrentUser()) return (window.location = "/login");

  //   const user = auth.getCurrentUser();
  //   if (user.role !== "Admin") return (window.location = "/");

  //   await this.getUserData();
  // }

  switchActiveTab = (activeTabValue) => {
    this.setState({
      activeTab: activeTabValue
    });
  }

  switchisTrancheRecordEdit = (isTrancheRecordEditValue) => {
    this.setState({
      isTrancheRecordEdit: isTrancheRecordEditValue
    });
  }

  async mapDataToState(userData) {
    let data = { ...this.state.data };
    let list = { ...this.state.list };

    //User Details
    if (userData.partnerId) data.partnerId = userData.partnerId;
    if (userData.name) data.name = userData.name;
    list = userData.data;


    this.setState({
      data,
      list,
    });
  }

  async mapUserTrancheDataToState(userTrancheDetails) {
    let trancheList = {};

    //User Tranche Details
    trancheList = userTrancheDetails.data;


    this.setState({
      trancheList,
      trancheListPageSize: userTrancheDetails.totalPages
    });
  }

  async componentDidMount() {
    if (!auth.getCurrentUser()) return (window.location = "/login");

    const currentUser = auth.getCurrentUser();
    if (currentUser.role != "Admin") return (window.location = "/");


    const partnerId = this.props.match.params.id;
    if (!partnerId) {
      return (window.location = "/payInPayOut");
    } else {
      await this.getUserData(partnerId);
      await this.getUserTrancheData(partnerId);
    }
  }

  async getUserData(partnerId) {
    let data = { ...this.state.data };
    this.setState({ showSpinner: true });
    try {
      const userDetails = await payInPayOutService.getUserInfo(partnerId);

      if (userDetails.success) {
        await this.mapDataToState(userDetails);
      }

      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  async getUserTrancheData(partnerId) {
    this.setState({ showSpinner: true });
    try {
      const data = {
        partnerId,
        pageNumber: this.state.currenTrancheDetailsPage
      }
      const userTrancheDetails = await trancheInfoService.getUserTrancheRecords(data);
      if (userTrancheDetails.success) {
        await this.mapUserTrancheDataToState(userTrancheDetails);
      }

      this.setState({ showSpinner: false });
    } catch (ex) {
      this.setState({ showSpinner: false });
    }
  }

  async handleCustomErrors() {
    const data = { ...this.state.data };
    let errors = { ...this.state.errors };

    this.setState({ errors });

    return errors;
  }

  async updatePayInPayOut() {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };

    this.setState({ showSpinner: true, isSubmitted: true });

    try {
      const response = await payInPayOutService.updatePayInPayOut(data);

      if (response.success) {
        data.amount = "";
        data.utrNo = "";
        data.transactionDate = momentJalaali();
        data.depositDate = momentJalaali();

        this.setState({
          data,
          successMsg: "Saved Successfully",
          showSpinner: false,
          isSubmitted: false,
        });

        await this.getUserData(data.partnerId);
      } else {
        errors["globalError"] = response.message;
        this.setState({
          errors,
          successMsg: "",
          showSpinner: false,
          isSubmitted: false,
        });
      }
    } catch (ex) {
      errors["globalError"] = ex.response.data.message;
      this.setState({
        showSpinner: false,
        isSubmitted: false,
        errors,
        successMsg: "",
      });
    }
  }

  updateTrancheUserData = (records) => {
    let { trancheList } = this.state;
    trancheList = records;
    this.setState({ trancheList });

  }

  handleTransactionDateChange = (value) => {
    const { data } = this.state;

    data.transactionDate = value;
    this.setState({ data });
  };

  handleDepositDateChange = (value) => {
    const { data } = this.state;

    data.depositDate = value;
    this.setState({ data });
  };

  async handleCustomErrors(errors) {
    delete errors["globalError"];

    this.setState({ errors, successMsg: "" });

    return errors;
  }

  goBack = () => {
    window.location.href = "/payInPayOut";
  };

  async handleRemove(id) {
    let data = { ...this.state.data };
    let errors = { ...this.state.errors };

    this.setState({ showSpinner: true });

    try {
      const response = await payInPayOutService.deletePayInPayOut(
        data.partnerId,
        id
      );

      if (response.success) {
        await this.getUserData(data.partnerId);
      } else {
        errors["globalError"] = response.message;
        this.setState({
          errors,
          successMsg: "",
          showSpinner: false,
          isSubmitted: false,
        });
      }
    } catch (ex) {
      errors["globalError"] = ex.response.data.message;
      this.setState({
        showSpinner: false,
        isSubmitted: false,
        errors,
        successMsg: "",
      });
    }
  }

  doSubmit = async () => {
    await this.updatePayInPayOut();
  };

  handleEditTrancheRecord = (e, trancheRecord) => {
    e.preventDefault();
    trancheRecord.startDate = momentJalaali(trancheRecord.startDate);
    trancheRecord.endDate = momentJalaali(trancheRecord.endDate);
    trancheRecord.fldg.transactionDate = momentJalaali(trancheRecord.fldg.transactionDate);
    let editTrancheRecord = trancheRecord;
    let isTrancheRecordEdit = true;
    this.setState({
      isTrancheRecordEdit,
      editTrancheRecord,
      isFormEdit:true,
    });
  }

  handleCreateTrancheRecord = (e) => {

    e.preventDefault();
    let isTrancheRecordEdit = true

    this.setState({
      editTrancheRecord: {},
      isTrancheRecordEdit
    });
  };

  handleConfirmDelete = async (e, trancheRecord) => {
    e.preventDefault();
    this.setState({
      confirmDelete:false
    })
    this.handleDeleteTrancheRecord(trancheRecord)
  }

  handleCloseDeleteToast=()=>{
    this.setState({
      confirmDelete:false
    })
  }

  handleToggleDeleteToast=(e)=>{
    e.preventDefault();
    let confirmDelete=this.state.confirmDelete;
    this.setState({
      confirmDelete:!confirmDelete
    })
  }

  handleDeleteTrancheRecord = async (trancheRecord) => {
    const response = await trancheInfoService.deleteUserTrancheRecord(trancheRecord);
    if (response.success) {
      let data = {
        partnerId: this.state.data.partnerId,
        pageNumber: this.state.currenTrancheDetailsPage
      }
      if (this.state.trancheList.length == 1) {
        data.pageNumber = 1
      }
      const userTrancheDetails = await trancheInfoService.getUserTrancheRecords(data);
      if (userTrancheDetails.success) {
        let trancheList = userTrancheDetails.data;
        this.setState({
          trancheList,
          currenTrancheDetailsPage: (data.pageNumber == 1 ? 1 : this.state.currenTrancheDetailsPage),
          trancheListPageSize: userTrancheDetails.totalPages
        });
      }
    }

  }
}

export default PayInPayOutInfoModel;
