import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import ReactPlayer from 'react-player';

class DemoCarousel extends Component {
  state = {
    currentSlide: 0,
    playing: false,
    autoPlay: true,
  };
  next = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide + 1,
    }));
  };
  prev = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide - 1,
    }));
  };
  changeAutoPlay = () => {
    this.setState((state) => ({
      autoPlay: !state.autoPlay,
    }));
  };
  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
        playing: false
      });
    }

  };
  start = () => {
    this.setState({ autoPlay: false, playing: true });
  };
  pause = () => {
    this.setState({ autoPlay: true, playing: false });
  };
  render() {

    return (
      <div>
        <Carousel
          autoPlay={this.state.autoPlay}
          selectedItem={this.state.currentSlide}
          onChange={this.updateCurrentSlide}
          infiniteLoop={true}
          showThumbs={false}
          showIndicators={false}
          dynamicHeight={true}
          {...this.props}

        >

          <div>
            <img src={require("../../images/fintech50banner.png")} alt="slide1" />

          </div>
          <div>
            <img src={require("../../images/slide_1.jpg")} alt="slide2" />
            {/* <p className="legend">Legend 2</p> */}
          </div>
          {/* <div>
            <ReactPlayer width="100%" url={require("../../images/ffIntro.mp4")} playing={this.state.playing} controls={true} onStart={this.start} onPause={this.pause} onPlay={this.start} />
          </div> */}
          <div>
            <img src={require("../../images/slide_2.jpg")} alt="slide3" />
            {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
            <img src={require("../../images/slide_3.jpg")} alt="slide4" />
            {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
            <img src={require("../../images/trueScoreBanner.png")} alt="slide5" />
            {/* <p className="legend">Legend 3</p> */}
          </div>
        </Carousel> </div>
    );
  }
}

export default DemoCarousel;
