import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Card, CardColumns } from "react-bootstrap";

class PartnerTestimonialSlider extends Component {
    render() {
        return (
            <React.Fragment>
                <h2 className="fundfinaColor">
                    Partner Testimonials
      </h2>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={false}
                    showThumbs={false}
                    showIndicators={true}
                    transitionTime={1000}
                    interval={6000}
                    swipeable={false}
                    emulateTouch={false}
                    swipeScrollTolerance={100}
                    infiniteLoop={true}

                >
                    <div style={{ backgroundColor: "white" }}>
                        <CardColumns>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/raj.png")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="partnertestimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "Our partnership with Fundfina, aside from being mutually beneficial, has been very professional. Backed by a smart, driven, versatile and agile team, they have been able to extend lending into new avenues. The reports we get as lenders are incredibly detailed, leaving no stone unturned when it comes to transparency. Our onboarding and operations process with Fundfina was the quickest and smoothest among the multiple fintech partnerships we have developed. The experience and zest to grow that Rahul and Nishant bring to the team is easily visible and palpable. We hope this proves to be a thriving partnership."
                                        </p>

                                    </blockquote>
                                </Card.Body>
                                <Card.Footer className="partnertestimonialFooter">
                                    <footer style={{ color: "#b51b1f" }}>
                                        Raj Parekh<cite title="Source Title"> - Joint MD, Aphelion Finance Pvt Ltd</cite>
                                    </footer>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/chirutha.png")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="partnertestimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "FundFina has been a true partner and asset for Pay1. Since the beginning, they have been committed to providing loans to our merchants at the best possible deals. Their USP - customised loan products for our merchants, combined with quick approvals and disbursals, have increased the merchants' faith in Pay1 as well as helped increase our business. They redefine dedication and professionalism. We are excited towards continuing this partnership with them."
                                        </p>
                                    </blockquote>
                                </Card.Body>
                                <Card.Footer className="partnertestimonialFooter">
                                    <footer style={{ color: "#b51b1f" }}>
                                        Chirutha Dalal<cite title="Source Title"> - Cofounder COO of Pay1</cite>
                                    </footer>
                                </Card.Footer>
                            </Card>
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={require("../../images/testimonials/anandKarwa.png")}
                                    className="testimonialSliderImg"

                                />
                                <Card.Body className="partnertestimonialText">
                                    <blockquote className="blockquote mb-0" style={{ color: "#b51b1f" }}>
                                        <p style={{ fontSize: 14 }}>
                                            {' '}
        "When we implemented Fundfina’s TrueScore in Eko, we saw our merchant loan acceptance rate go up along with our portfolio health. Fundfina not only pre-approved a large chunk of merchants with varying loan amount eligibility, but also helped us achieve customer delight with their super-fast disbursal process."
                                        </p>

                                    </blockquote>
                                </Card.Body>
                                <Card.Footer className="partnertestimonialFooter">
                                    <footer style={{ color: "#b51b1f" }}>
                                        Anand Karwa<cite title="Source Title">- Product Manager, Credit -EKO</cite>
                                    </footer>
                                </Card.Footer>
                            </Card>
                        </CardColumns>
                    </div>
                </Carousel></React.Fragment>
        );
    }
}

export default PartnerTestimonialSlider;
