import InputDataForm from "../../components/common/form";
import * as trueScoreService from "../../services/trueScore/trueScoreService";
import Joi from "joi-browser";
import * as analytics from "../../utils/analytics";

class TrueScoreDetailModel extends InputDataForm {
  state = {
    data: {
      partnerType: "Lender",
      email: "",
      mobile: "",
      otp: "",
    },
    isValidEmail: false,
    isValidMobile: true,
    isOTPGenerated: false,
    isOTPVerified: false,
    showSpinner: false,
    errors: {},
    info: {},
    tnc: false,
    showTnc: false,
  };

  schema = {
    partnerType: Joi.required().label("Selection"),
    email: Joi.any().label("Email"),
    mobile: Joi.number().allow(null, "").label("Mobile"),
    otp: Joi.number().allow(null, "").label("OTP"),
  };

  handleEmailBlur = async ({ currentTarget: input }) => {
    const { data, errors, info } = { ...this.state };

    const errorMessage = this.validateProperty(input);

    if (errorMessage) {
      this.setState({ isValidEmail: false });
      return;
    }

    this.setState({ showSpinner: true });

    try {
      const atpos = input.value.indexOf("@");
      const dotpos = input.value.lastIndexOf(".");

      if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= input.value.length) {
        errors[input.name] = "please enter valid email address";
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidEmail: false,
          showSpinner: false,
        });
        input.focus();

        return;
      } else if (data.partnerType === "Lender") {
        const mailRegex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        if (input.value.match(mailRegex)) {
          if (
            input.value.includes("gmail") ||
            input.value.includes("hotmail") ||
            input.value.includes("yahoo") ||
            input.value.includes("rediff") ||
            input.value.includes("ymail")
          ) {
            errors[input.name] =
              "email address with gmail,hotmail,yahoo,rediff and ymail are not allowed";
            delete info[input.name];
            this.setState({
              errors,
              data,
              isValidEmail: false,
              showSpinner: false,
            });
            input.focus();
            return;
          } else {
            delete errors[input.name];
            info[input.name] = "email address is valid";
            this.setState({
              errors,
              data,
              isValidEmail: true,
              showSpinner: false,
            });
            return;
          }
        } else {
          errors[input.name] = "please enter valid email address";
          delete info[input.name];
          this.setState({
            errors,
            data,
            isValidEmail: false,
            showSpinner: false,
          });
          input.focus();
          return;
        }
      } else {
        delete errors[input.name];
        info[input.name] = "email address is valid";
        this.setState({ errors, data, isValidEmail: true, showSpinner: false });
        return;
      }
    } catch (ex) {
      errors[input.name] = ex.message;
      this.setState({ errors, data, isValidEmail: false, showSpinner: false });
      input.focus();
    }
  };

  handleMobileBlur = async ({ currentTarget: input }) => {
    const { data, errors, info } = this.state;
    this.setState({ showSpinner: true });

    try {
      if (input.value.length > 0) {
        if (
          input.value.length !== 10 ||
          isNaN(input.value) ||
          (input.value.charAt(0) !== "7" &&
            input.value.charAt(0) !== "6" &&
            input.value.charAt(0) !== "8" &&
            input.value.charAt(0) !== "9" &&
            input.value !== "")
        ) {
          errors[input.name] = "invalid mobile number";
          delete info[input.name];
          this.setState({
            errors,
            data,
            isValidMobile: false,
            showSpinner: false,
          });
          input.focus();
          return;
        } else {
          delete errors[input.name];
          info[input.name] = "mobile number is valid";
          this.setState({
            errors,
            data,
            isValidMobile: true,
            showSpinner: false,
          });
          return;
        }
      } else {
        delete errors[input.name];
        delete info[input.name];
        this.setState({
          errors,
          data,
          isValidMobile: true,
          showSpinner: false,
        });
        return;
      }
      //this.startTimer();
    } catch (ex) {
      errors[input.name] = ex.response.data.message;
      delete info[input.name];
      this.setState({ errors, data, isValidMobile: false, showSpinner: false });
      input.focus();
      return;
    }
  };

  handleGenerateOTP = async () => {
    const { data, errors, info, isValidEmail, isValidMobile, tnc } = {
      ...this.state,
    };
    this.setState({ showSpinner: true });

    try {

      if (isValidEmail && isValidMobile && tnc) {
        //console.log("inside generate otp");
        delete errors["tnc"];
        delete errors["email"];
        delete errors["mobile"];
        const { success, message } = await trueScoreService.getOTP(
          data.email,
          data.mobile
        );
        if (success) {
          delete errors["otpGeneration"];
          info["otpGeneration"] = message;
          this.setState({
            errors,
            info,
            data,
            isOTPGenerated: true,
            showSpinner: false,
          });
          return;
        } else {
          delete info["otpGeneration"];
          errors["otpGeneration"] = message;
          this.setState({
            errors,
            info,
            data,
            isOTPGenerated: false,
            showSpinner: false,
          });
          return;
        }
      } else {
        delete info["otpGeneration"];
        if (!isValidEmail && data.email === "") {
          errors["email"] = "Please enter email";
          this.refs.email.focus();
        }
        else if (!isValidMobile) {
          errors["mobile"] = "Please enter valid mobile number";
          this.refs.mobile.focus();
        }
        else if (!tnc) {
          errors["tnc"] = "Please agree to terms and conditions";
          this.refs.tnc.focus();
        }
        this.setState({
          errors,
          info,
          data,
          isOTPGenerated: false,
          showSpinner: false,
        });
        return;
      }
    } catch (ex) {
      delete info["otpGeneration"];
      errors["otpGeneration"] = ex.message;
      this.setState({
        errors,
        info,
        data,
        isOTPGenerated: false,
        showSpinner: false,
      });
    }
  };

  async handleCustomErrors(errors) {
    return errors;
  }
  componentDidMount() {
    analytics.track();
  }

  // componentWillMount(){
  //     console.log("inside Mount");
  //     this.setState({ isValidEmail:true,isValidMobile:true,isOTPGenerated:true});
  // }

  doSubmit = async () => {
    const { data, errors, info, isValidEmail, isValidMobile } = {
      ...this.state,
    };
    // const options={
    //     partnerType:data.partnerType,
    //     email:data.email,
    //     mobile:data.mobile
    // }

    // this.props.history.push({
    //     pathname:"/trueScoreTransactions",
    //     state:options
    // });

    this.setState({ showSpinner: true });
    try {
      // if (data.otp.trim() === "") {
      //   delete info["otp"];
      //   errors["otp"] = "invalid otp";
      //   this.setState({ errors, info, data, showSpinner: false });
      // } else {
      delete errors["otp"];
      if (isValidEmail && isValidMobile) {
        // const { success, message } = await trueScoreService.verifyOtp(
        //   data.email,
        //   data.mobile,
        //   data.otp
        // );
        // if (success) {
        this.props.history.push({
          pathname: "/trueScoreTransactions",
          state: {
            partnerType: data.partnerType,
            email: data.email,
            mobile: data.mobile,
          },
        });
        // } else {
        //   delete info["otp"];
        //   errors["otp"] = message;
        //   this.setState({ errors, info, data, showSpinner: false });
        // }
      }
      // }
    } catch (ex) {
      delete info["otp"];
      errors["otp"] = ex.message;
      this.setState({ errors, info, data, showSpinner: false });
    }
  };
}

export default TrueScoreDetailModel;
